import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';

const AppealChangeFailureNote = ({ field }) => (
	<div className={styles.appealChangesFailureNote}>
		<i className='icon-error' />
		<span>{field}</span>
	</div>
);

AppealChangeFailureNote.propTypes = {
	field: PropTypes.string
};

export default AppealChangeFailureNote;
