import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';


const getLinkClassName = (link) => {
	let className = 'link';
	
	if ((!link.leaf && (!link.result || !link.result.length))) {
		className = cx('link', 'disabled');
	}
	
	return className;
};

const KnowledgeBaseList = ({ links, onClick }) => {
	if (!links) return null;
	
	return links.map((link, index) => {
		const selectedItem = link.leaf ? link.item.id : undefined;
		return (
			<div style={{ marginLeft: (link.level - 1) * 10 }}
				className={getLinkClassName(link)}
				key={link.id || link.item.id}
				onClick={() => onClick({ index: selectedItem ? null : index, text: link.text }, selectedItem)}
			>
				<span><i className={link.leaf ? 'icon-file' : 'icon-folder'} /></span>
				<span>{link.text} </span>
			</div>
		);
	});
};

KnowledgeBaseList.propTypes = {
	onClick: PropTypes.func.isRequired,
	links: PropTypes.array
};

export default KnowledgeBaseList;
