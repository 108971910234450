import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radio from 'components/Common/Radio';
import Rank from 'components/Common/Rank';
import TextArea from 'components/Common/TextArea';
import ComboBoxCreatable from 'components/Common/ComboBoxCreatable';
import ComboBox from 'components/Common/ComboBox';
import AjaxCombo from 'components/Common/AjaxCombo';
import CheckBox from 'components/Common/CheckBox';
import InputField from 'components/Common/InputField';
import { DatePicker } from 'ui-core-dashboard';
import { withRouter } from 'react-router-dom';
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import SimpleInput from '../../../components/CustomerPage/Parameters/components/SimpleInput';

const CACHED_OPTIONS = {};

@withTranslation()
class DynamicParams extends PureComponent {
    getWidgetOptions = (values = [], formValues, key) => {
		let options = values.map((value) => ({
            ...value,
            label: value.value,
            value: value.key,
            id: value.key,
		}));
		const value = formValues && formValues[key];
        // add value to options based on first option params if value is not exist in option
        const isValueInOptions = options.find((option) => value && typeof value === 'object' && 'value' in value ? option.value === value.value : option.value === value );
        if (!isValueInOptions && value) {
			// const firstOption = options[0] || {};
			let optionData = CACHED_OPTIONS[key];
			if (!optionData) {
				const isObject = typeof value === 'object';
				const optionData = isObject
					? {
						  id: value.key || value.value,
						  key: value.key || value.value,
						  label: value.label || value.value,
						  value: value.value,
					  }
					: { id: value, key: value, label: value, value: value };
				if (key) {
					CACHED_OPTIONS[key] = optionData;
				}
			}
			if (optionData) {
				options = [...options, optionData];
			}
		}
		// if (key === "bp@d5228@yur_attr@700@6692186") {
		// 	console.log({value, options, CACHED_OPTIONS, formValues: this.props.formValues});
		// 	// console.log({value, formValue: this.props.formValues && this.props.formValues[key]});
		// }
		return options;
    };

    renderItemByType(widget, index) {
        const {
            savedValue,
            isReq,
            blockKey,
            title,
            defaultValue,
            disabled,
            onlyLabelFilter,
            key,
            order,
            values,
            widgetType,
            placeholder,
            description,
            rule,
            maxLength,
        } = widget;
        const { t, saveOnChange, readOnly, isCollapsedHidden, formValues } = this.props;
        const existValue = _.get(formValues, `${key}`);
        const hidden = isCollapsedHidden && (!savedValue || !defaultValue || !existValue);
        const defaultProps = {
            id: hidden ? `hidden-${key}` : key,
            key,
            name: key,
            label: title,
            disabled: disabled || readOnly,
            required: isReq,
            order: order,
            placeholder: placeholder,
            value: savedValue || defaultValue || existValue, // || (this.props.formValues && this.props.formValues[key]),
            tabIndex: 0,
            rule,
            t,
        };
        if (saveOnChange && !disabled && widgetType !== 'multiline' && widgetType !== 'input') {
            defaultProps.onChange = (e) => {
				const value = e ? e.value : null;
                saveOnChange({ value, key });
            };
        }
        if (saveOnChange && !disabled && widgetType === 'multiline') {
            defaultProps.onBlur = debounce((e) => {
                const value = e ? e.target.value : null;
                saveOnChange({ value, key });
            }, 500);
        }
        if (saveOnChange && !disabled && (widgetType === 'date' || widgetType === 'datetime')) {
            defaultProps.onChange = (value) => {
                saveOnChange({ value: value || null, key });
            };
        }

        const defaultPropsDate = {
            saveFormat: 'x',
            popperClassName: 'datepicker-portal quality-calendar',
            locale: 'uk',
		};

        const findByLabel = (option, searchText) =>
            option.label.toLowerCase().includes(searchText.toLowerCase());

        switch (widgetType) {
            case 'radio':
                return (
                    <Field
                        {...defaultProps}
                        component={Radio}
                        description={description}
                        list={values}
                    />
                );

            case 'rank':
                return (
                    <Field
                        {...defaultProps}
                        component={Rank}
                        description={description}
                        values={[...values].reverse()}
                        rank={savedValue || defaultValue}
                    />
                );
            case 'input':
                return (
                    <Field
                        {...defaultProps}
                        component={saveOnChange ? SimpleInput : InputField}
                        maxLength={maxLength || 2000}
                        handleChangeValue={saveOnChange && debounce(saveOnChange, 500)}
                    />
                );
            case 'checkbox':
                return (
                    <Field
                        {...defaultProps}
                        component={CheckBox}
                        placeholder={widget.placeholder || t('dontSelect')}
                    />
                );
            case 'checkboxToggle':
                return <Field {...defaultProps} component={CheckBoxToggle} />;
            case 'select':
                return (
                    <Field
                        {...defaultProps}
                        component={ComboBox}
                        options={this.getWidgetOptions(widget.values)}
                        placeholder={widget.placeholder || t('dontSelect')}
                        ref="Select"
                        filterOption={onlyLabelFilter && findByLabel}
                    />
                );
            case 'date':
                return (
                    !hidden && (
                        <Field
                            {...defaultProps}
                            {...defaultPropsDate}
                            component={DatePicker}
                            hasTimePicker={false}
                            viewFormat="DD.MM.YYYY"
                        />
                    )
                );
            case 'datetime':
                return (
                    !hidden && (
                        <Field
                            {...defaultProps}
                            {...defaultPropsDate}
                            component={DatePicker}
                            hasTimePicker={true}
                            viewFormat="DD.MM.YYYY HH:mm"
                        />
                    )
                );
            case 'multiline':
                return (
                    <Field
                        {...defaultProps}
                        component={TextArea}
                        textAreaClassName="multiline"
                        maxLength={maxLength || 2000}
                    />
                );

            case 'combo':
                return (
                    <Field
                        {...defaultProps}
                        component={ComboBoxCreatable}
                        options={this.getWidgetOptions(widget.values)}
                        placeholder={widget.placeholder || t('dontSelect')}
                        maxLength={maxLength || 2000}
                        filterOption={onlyLabelFilter && findByLabel}
                    />
                );

            case 'searchbox':
                return (
                    <Field
                        {...defaultProps}
                        component={ComboBox}
                        options={this.getWidgetOptions(widget.values)}
                        searchable={true}
                        placeholder={widget.placeholder || t('dontSelect')}
                    />
                );
            case 'ajaxCombo':
                return (
                    <Field
                        {...defaultProps}
                        component={AjaxCombo}
                        options={this.getWidgetOptions(widget.values, formValues, key)}
                        optionsUrl="appeal_form_ajax"
                        attributeKey={key}
                        blockKey={blockKey}
                        id={key}
                        placeholder={widget.placeholder || t('dontSelect')}
                    />
                );

            default:
                return null;
        }
    }

    render() {
        const { widgets } = this.props;
        return (
            <Fragment>
                {widgets.map((widget, index) => this.renderItemByType(widget, index))}
            </Fragment>
        );
    }
}

DynamicParams.propTypes = {
    handleSubmit: PropTypes.func,
    widgets: PropTypes.array,
    blockKey: PropTypes.string,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
};

export default DynamicParams;
