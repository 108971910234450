import React from 'react';
import { connect } from 'react-redux';
import ModalPortal from 'components/ModalPortal';
import { closeObjectsLinkModal } from 'actions/ui';
import LinkForm from './LinkForm';
import './style.scss';

const mapStateToProps = (state) => ({
    showObjectsLinkModal: state.ui.showObjectsLinkModal,
});

const mapDispatchToProps = {
    closeObjectsLinkModal,
};

function LinkObjects(props) {
    const {
        closeObjectsLinkModal,
        id,
        customerId,
        showObjectsLinkModal,
        objectType,
        relObjectTypes,
        code,
        type,
        filterId,
        customGridKey,
        customConfigCode,
        onLinkEditSuccess = () => {},
    } = props;

    return (
        <>
            {showObjectsLinkModal ? (
                <ModalPortal
                    onClose={closeObjectsLinkModal}
                    className="modal-medium modal-center link-appeal"
                >
                    <LinkForm
                        id={id}
                        customerId={customerId}
                        objectType={objectType}
                        relObjectTypes={relObjectTypes}
                        code={code}
                        type={type}
                        filterId={filterId}
                        customGridKey={customGridKey}
                        customConfigCode={customConfigCode}
                        onLinkEditSuccess={onLinkEditSuccess}
                    />
                </ModalPortal>
            ) : null}
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkObjects);
