import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup'

class FilterActionsPopup extends React.Component {
	
	onOptionClick = action => () => {
		action();
		this.props.onClickOutside();
	};
	
	renderOption = ({ label, action }) => (
		<div className='popup-link' onClick={this.onOptionClick(action)}>
			{label}
		</div>
	);
	
	render () {
		const { isOpen, config, onClickOutside } = this.props;
		
		if (!isOpen) return null;
		
		return (
			<Popup place='grid-popup' onClickOutside={onClickOutside}>
				{config.map(this.renderOption)}
			</Popup>
		);
	}
}

FilterActionsPopup.propTypes = {
	isOpen: PropTypes.bool,
	config: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		action: PropTypes.func
	})),
	onClickOutside: PropTypes.func
};

export default FilterActionsPopup;
