import React from 'react';
import ReactDOM from 'react-dom';

export default class NotificationPortal extends React.Component {
	constructor (props) {
		super(props);
		this.notificationModalRoot = document.getElementById('notification-modal-root');
		this.el = document.createElement('div');
	}
	
	componentDidMount () {
		this.notificationModalRoot.appendChild(this.el);
	}
	
	componentWillUnmount () {
		this.notificationModalRoot.removeChild(this.el);
	}
	
	render () {
		return ReactDOM.createPortal(
			this.props.children,
			this.el,
		);
	}
}
