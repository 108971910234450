import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Field, formValueSelector, registerField, change, reduxForm } from 'redux-form';
import { CSSTransition } from 'react-transition-group';
// import { CheckBoxToggle, TextArea } from 'ui-core-dashboard';
import { CheckBoxToggle } from 'ui-core-dashboard';
import TextArea from "../../Ordering/common/TextArea";
import baseService from 'services/BaseService';

import { GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';


import 'pages/Appeal/styles.scss';

import Progressbar from 'components/Progressbar';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import {
	addComment,
	uploadFile,
	deleteFile,
} from 'actions/comments';
import { withTranslation } from 'react-i18next';
import { transformBreakLineToMarkDown } from 'helpers';
import cx from 'classnames';
import { createSelector } from 'reselect';
import ReactSVG from "react-svg";

const getInitialFormValues = createSelector(
	state => state.comments.comments,
	state => state.comments.editableCommentId,
	(comments, editableCommentId) => comments.find(comment => comment.id === editableCommentId)
);

function mapStateToProps (state, props) {
	let initialValues = getInitialFormValues(state);
	const { match : {params} } = props;
	const formSelector = formValueSelector(`comment-form-${params.appealId}`);
	if (initialValues) {
		initialValues = { ...initialValues, publish: initialValues.published };
	} else {
		initialValues = { publish: localStorage.getItem('isPublishComment') === 'true'};
	}
	
	return {
		initialValues,
		added: state.comments.added,
		attachment: state.comments.attachment.filter(({appealId}) => appealId === params.appealId),
		text: formSelector(state, 'text'),
		publish: formSelector(state, 'publish'),
		editableCommentId: state.comments.editableCommentId,
		editableFormFormCommentId: formSelector(state, 'id'),
		uploadCounter: state.comments.uploadCounter,
		uploadingError: state.comments.uploadingError,
		update: state.comments.update,
		adding: state.comments.adding,
		uploading: state.comments.uploading,
	};
}

const mapDispatchToProps = dispatch => ({
	addComment: (data, callback) => dispatch(addComment(data, callback)),
	registerNewField: (form, name, type) => dispatch(registerField(form, name, type)),
	uploadFile: (file, id, withNotification) => dispatch(uploadFile(file, id, withNotification)),
	deleteFile: (fileId) => dispatch(deleteFile(fileId)),
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({ enableReinitialize: false, destroyOnUnmount: false, keepDirtyOnReinitialize: true })
export default class AddCommentForm extends React.Component {
	constructor (props) {
		super(props);
		this.input = React.createRef();
		this.toggleCheckbox = this.toggleCheckbox.bind(this);
		this.openDeleteConfirm = this.openDeleteConfirm.bind(this);
		this.closeDeleteConfirm = this.closeDeleteConfirm.bind(this);
		this.onCloseConfirm = this.onCloseConfirm.bind(this);
		this.handlePaste = this.handlePaste.bind(this);
		this.state = {
			modalIsOpen: false,
			isDeleteConfirmOpen: false,
			isFocusedCommentArea: false,
		};
	}
	modalBody = <div />;
	// state = {
	// 	modalIsOpen: false,
	// 	isDeleteConfirmOpen: false,
	// };
	
	componentDidMount () {
		// document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('paste', this.handlePaste);
	}
	
	componentWillUnmount () {
		// document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('paste', this.handlePaste);
	}
	
	componentDidUpdate (prevProps) {
		const { refresh, dispatch, handleEditComment, uploadingError, update, match: { params } } = this.props;
		
		if (update) {
			dispatch(change(`comment-form-${params.appealId}`, 'text', ''));
			handleEditComment(null);
			refresh();
		}
		
		if (!prevProps.uploadingError && uploadingError) {
			this.openModal('uploadingError');
		}
	}

	handleFocus = () => {
		this.setState({isFocusedCommentArea: true});
	}

	handleBlur = () => {
		this.setState({isFocusedCommentArea: false});
	}

	handlePaste(ev) {
		const { isFocusedCommentArea } = this.state;
		if (!isFocusedCommentArea) {
			return;
		}
		for(const item of ev.clipboardData.items) { /// Clipboard may contain multiple elements of different type -- text, image, etc
			if(item.type.startsWith("image/")) { /// We are only interested in clipboard data that is an image
				/// Do something with the data, image available as `item.getAsFile()`
				const file = item.getAsFile();
				if (file) {
					this.handleLoadFiles({currentTarget: {files: [file]}}, true);
				}
			}
		}
	};
	
	handleSubmitForm = (values) => {
		const { attachment, editableCommentId, uploading, editableFormFormCommentId } = this.props;
		const { appealId } = this.props.match.params;
		const text = transformBreakLineToMarkDown(values.text);
		let data = {
			appealId,
			publish: values.publish,
			text,
			attachment: attachment.map(file => file.id)
		};
		
		if (uploading) return;
		
		if (editableCommentId || editableFormFormCommentId) {
			data = { ...data, commentId: editableCommentId || editableFormFormCommentId };
		}
		
		this.props.addComment(data, () => {
			this.props.refresh();
			this.props.closeForm();
		});
	};
	
	toggleCheckbox () {
		const isPublishComment = !(localStorage.getItem('isPublishComment') === 'true');
		this.props.change('publish', isPublishComment);
		localStorage.setItem('isPublishComment', isPublishComment.toString());
	};
	
	handleLoadFiles = (e, withNotification) => {
		const { uploadFile } = this.props;
		const newFiles = [...e.currentTarget.files];
		const { appealId } = this.props.match.params;

		const maxFileSize = GET_MAX_FILE_SIZE_FROM_DICT('comment');
		
		newFiles.forEach(file => {
			const fileSize = file.size / 1024 / 1024;
			if (fileSize > maxFileSize) {
				this.openModal('fileSize');
			} else {
				uploadFile(file, appealId, withNotification);
			}
		});
	};
	
	handleDeleteFile = (fileId) => {
		const { deleteFile } = this.props;
		deleteFile(fileId);
		this.input.current.value = '';
	};
	
	openModal = (type) => {
		this.renderModalBody(type);
		this.setState({ modalIsOpen: true });
	};

	onCloseConfirm = () => {
		const { editableCommentId, editableFormFormCommentId } = this.props;
		const editMode = !!editableCommentId || !!editableFormFormCommentId;
		if (editMode) {
			this.closeDeleteConfirm();
			this.props.closeForm();
			return;
		}
		
		this.closeDeleteConfirm();
		this.props.setIsCommentDeleting(true);
		const { text, publish } = this.props;

		const { attachment } = this.props;
		const { appealId } = this.props.match.params;
		const transformedText = text ? transformBreakLineToMarkDown(text) : "";
		let data = {
			appealId,
			publish,
			text: transformedText,
			attachment: attachment.map(file => file.id)
		};

		baseService
            .delete('comment_delete', { data, jsonType: true })
            .then(({ success }) => {
                if (success) {
					this.props.closeForm();
				}
				this.props.setIsCommentDeleting(false);
            })
            .catch((error) => {
				this.props.setIsCommentDeleting(false);
            });
	};

	openDeleteConfirm = () => {
		this.setState({isDeleteConfirmOpen: true});
	}

	closeDeleteConfirm = () => {
		this.setState({isDeleteConfirmOpen: false});
	}
	
	closeModal = () => this.setState({ modalIsOpen: false });
	
	renderModalBody = (type) => {
		const { t, uploadingError } = this.props;

		const maxFileSize = GET_MAX_FILE_SIZE_FROM_DICT('comment');

		switch (type) {
		case 'fileSize':
			return this.modalBody = (
				<ModalPortal onClose={this.closeModal} className='modal-medium modal-center'>
					<MessageModal
						danger
						titleModal={t('error')}
						contentModalText={t('errorFile', { size: maxFileSize })}
					/>
				</ModalPortal>
			);
		
		case 'uploadingError':
			return this.modalBody = (
				<ModalPortal onClose={this.closeModal} className='modal-small modal-center'>
					<MessageModal
						danger
						contentModalText={uploadingError || t('errorMessage')}
						onClickPrimaryButton={this.closeModal}
					/>
				</ModalPortal>
			);
		
		default:
			return null;
		}
	};
	
	renderFile = (file, index) => (
		// <div className='fileWrapper' key={index}>
		// 	<a className="file-preview" target="_blank" href={`../mw/file?fileId=${file.id}&download=0`} title={name}>
        //         {file.name}
		// 	</a>
		// 	<div className="kb-file-actions">
		// 		<a className="file-download" target="_blank" href={`../mw/file?fileId=${file.id}&download=1`} title={name}>
		// 			<ReactSVG className="svg-download" src="/data/svg/download.svg"/>
		// 		</a>
		// 		<ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" onClick={() => this.handleDeleteFile(file.id, index)} />
		// 	</div>
		// </div>
		<div className="kb-file-wrapper" key={index}>
			<a className="file-preview" target="_blank" href={`../mw/file?fileId=${file.id}&download=0`} title={file.name}>
				{file.name}
			</a>
			<div className="kb-file-actions">
				<a className="file-download" target="_blank" href={`../mw/file?fileId=${file.id}&download=1`} title={file.name}>
					<ReactSVG className="svg-download" src="/data/svg/download.svg"/>
				</a>
				<ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" onClick={() => this.handleDeleteFile(file.id, index)} />
			</div>
		</div>
	);
	
	render () {
		const { text, handleSubmit, attachment, editableCommentId, t, uploadCounter, adding, initialValues, editableFormFormCommentId, pristine } = this.props;
		const { isDeleteConfirmOpen } = this.state;
		const shortComment = !text || String(text).replace(/[\s\v\n]/gim, '').length < 1;
		const editMode = !!editableCommentId || !!editableFormFormCommentId;
		const disabledStateButton = pristine ? true : shortComment || adding;

		const isCloseConfirm = editMode ? !disabledStateButton : !disabledStateButton || attachment.length > 0;
		
		return (
			<>
				<Form
					onSubmit={handleSubmit(this.handleSubmitForm)}
					className={cx('comment-form', { 'hasFiles': attachment && attachment.length })}
				>
					<Field
						name='text'
						component={TextArea}
						placeholder={editMode ? t('comments.editComment') : t('comments.addComment')}
						rows={1}
						maxLength={2500}
						autosize
						props={{ autoFocus: true }}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						disabled={this.props.isLockedByOther}
					/>
					
					{attachment && attachment.length > 0 &&
						<div className='commentAttachments'>
							{attachment.map(this.renderFile)}
						</div>
					}
					
					{uploadCounter > 0 && <Progressbar />}
					
					<div className='new-comment-bottom-line'>
						<Field
							name='publish'
							component={CheckBoxToggle}
							onChange={this.toggleCheckbox}
							label={t('comments.publicComment')}
							disabled={initialValues.public !== undefined ? !initialValues.public : false }
						/>
						<div className='file-uploader-wrapper'>
							<label className='file-uploader'>
								<input
									type='file'
									multiple
									onChange={this.handleLoadFiles}
									ref={this.input}
									disabled={this.props.isLockedByOther}
								/>
								<i className='icon-attach' />
							</label>
							<span className='comment-divider'>|</span>
							<button
								type='submit'
								className={cx('btn-save', { disabled: this.props.isLockedByOther || disabledStateButton })} 
								disabled={this.props.isLockedByOther || disabledStateButton}
							>
								{editMode ? t('comments.editComment') : t('comments.send')}
							</button>
							<span className='comment-divider'>|</span>
							<button
								type='button'
								className='btn-save'
								onClick={isCloseConfirm ? this.openDeleteConfirm : this.props.closeForm}
							>
								{t('close')}
							</button>
						</div>
					</div>
					
					<CSSTransition
						in={this.state.modalIsOpen}
						classNames='fade'
						appear={true}
						enter={true}
						exit={true}
						timeout={500}
						mountOnEnter={true}
						unmountOnExit={true}
					>
						{this.modalBody}
					</CSSTransition>
				
				</Form>
				{isDeleteConfirmOpen && (
					<ModalPortal onClose={this.closeDeleteConfirm} className="modal-small">
						<MessageModal
							titleModal={t('attention')}
							contentModalText={t('modalWindowsLabels.notSavedWarning')}
							dangerButton={true}
							dangerButtonText={t('close')}
							onClickDangerButton={this.onCloseConfirm}
							secondaryButton={true}
							secondaryButtonText={t('cancel')}
							onClickSecondaryButton={this.closeDeleteConfirm}
						/>
					</ModalPortal>
				)}
			</>
		);
	}
}

AddCommentForm.propTypes = {
	handleSubmit: PropTypes.func,
	toggleForms: PropTypes.func,
	attachment: PropTypes.array,
	editableCommentId: PropTypes.number,
	update: PropTypes.bool
};

