import React from 'react';

import Popup from '../../Popup/index';
import { withTranslation } from 'react-i18next';

@withTranslation()
export default class Confirm extends React.Component {
	
	handleAction = () => {
		this.props.action();
		this.props.cancel();
	};
	
	render () {
		const { cancel, t } = this.props;
		return (
			<Popup
				place='confirm'
				onClickOutside={() => { }}
			>
				<div className='logo'>
					{t('areYouSure')}
				</div>
				<div>
					<button className='btn' onClick={this.handleAction}>{t('yes')}</button>
					<button className='btn btn-cancel' onClick={cancel}>{t('cancel')}</button>
				</div>
			</Popup>
		);
	}
}
