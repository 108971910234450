import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import 'pages/Appeal/styles.scss';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { extractAppealFromState } from '../../../../helpers';

function mapStateToProps (state, props) {
	const [appeal] = extractAppealFromState(state, props);
	
	return {
		appeal: appeal.currentAppeal,
	};
}

@withRouter
@withTranslation()
@connect(mapStateToProps)
class HeaderAppealInfo extends PureComponent {
	
	onUserClick = event => {
		event.preventDefault();
	};
	
	renderLink = () => {
		const { appeal, t } = this.props;
		let label;
		
		switch (appeal.childType) {
		case 'DOUBLET': {
			label = t('appeal.appealDoubletFor');
			break;
		}
		
		case 'REPET': {
			label = t('appeal.appealRepeatFor');
			break;
		}
		}
		
		if (!label) return <div className='link-placeholder'></div>;
		
		return (
			<span> (
				{label}
				<Link to={`/appeals/${appeal.rootRequestId}/`} className='appealLink'>
                    {appeal.rootRequestRegnum}
                </Link>
                )
            </span>
		);
	};
	
	render () {
		const { appeal, t } = this.props;
		
		const creationDate = moment(appeal.validFrom).format('DD.MM.YYYY HH:mm');
		
		return (
			<div className='headerAppealInfoBlock'>
				<div className='headerAppealInfoStatusRow'>
					<span>{t('tasks.createDate')} {creationDate}</span>
					{this.renderLink()}
				</div>
				<div className='headerAppealInfoDataRow'>
					{/* <span>{creationDate} / <span className='operatorName' title={appeal.operatorName}>{appeal.operatorName}</span></span> */}
					<span><span className='operatorName' title={appeal.operatorName}>{appeal.operatorName}</span></span>
				</div>
			</div>
		);
	};
}

HeaderAppealInfo.propTypes = {
	appeal: PropTypes.object
};

export default HeaderAppealInfo;

