import moment from 'moment';
import i18next from 'util/i18n';

export default function validate(values) {
    // const olderYear = Math.round(new Date().getTime()) - 31536000000;
    let errors = {};
    // const error = (field) => (`"${field} з" не може бути пізніше ніж "${field} по" aбо пізніше 1 року`);
    const errorLater = (field) => `"${field} з" не може бути пізніше ніж "${field} по"`;
    const errorEarlier = (field) => `"${field} по" не може бути раніше ніж "${field} з"`;
    const CORRECT_DATE = i18next.t('fillCorrectDateNoTime');

    if (
        values['CLEVENT_RANGE_START_DATE_NAV@TO'] &&
        !moment(values['CLEVENT_RANGE_START_DATE_NAV@TO']).isValid()
    ) {
        errors = { ...errors, 'CLEVENT_RANGE_START_DATE_NAV@TO': CORRECT_DATE };
    }
    if (
        values['CLEVENT_RANGE_START_DATE_NAV@FROM'] &&
        !moment(values['CLEVENT_RANGE_START_DATE_NAV@FROM']).isValid()
    ) {
        errors = { ...errors, 'CLEVENT_RANGE_START_DATE_NAV@FROM': CORRECT_DATE };
    }

    if (values['CLEVENT_RANGE_START_DATE_NAV@FROM'] && values['CLEVENT_RANGE_START_DATE_NAV@TO']) {
        if (
            moment(+values['CLEVENT_RANGE_START_DATE_NAV@TO']).isBefore(
                values['CLEVENT_RANGE_START_DATE_NAV@FROM'],
            ) /*|| +values['dateBegin'] >= olderYear*/
        ) {
            errors = {
                ...errors,
                'CLEVENT_RANGE_START_DATE_NAV@FROM': errorLater(i18next.t('workItemPeriod')),
                'CLEVENT_RANGE_START_DATE_NAV@TO': errorEarlier(i18next.t('workItemPeriod')),
            };
        }

        // if (moment(+values['CLEVENT_RANGE_START_DATE_NAV@TO']).isBefore(values['CLEVENT_RANGE_START_DATE_NAV@FROM']) /*|| +values['dateBegin'] >= olderYear*/) {
        // 	errors = {...errors, 'CLEVENT_RANGE_START_DATE_NAV@TO': errorEarlier(i18next.t('workItemPeriod'))}
        // }
    }

    return errors;
}
