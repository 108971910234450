import { useState } from "react";

export default (defaultStatus = false) => {
    const [isOpen, setModalVisibility] = useState(defaultStatus);
    const toggleModalVisibility = status => {
        const value = typeof status === 'boolean' ? status : !isOpen;
        setModalVisibility(value);
    };

    return { isModalOpen: isOpen, toggleModalVisibility };
};
