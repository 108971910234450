import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tab from 'components/Tab';
import { createCheckPropsFunction } from 'helpers';
import ContactPersonAppealList from './ContactPersonAppealList';

const tabNames = {
	appeals: 'appeals',
	link: 'link',
	history: 'history'
};

const mapStateToProps = state => ({ tabs: state.tabs });

function AppealsTabContent(props) {
	const { customerId, history, isContactsView, isHistoryView } = props;
	
	const renderTab = tab => (
		<Tab val={tab} key={tab} tab={tab} />
	);

	if (!isContactsView) {
		delete tabNames.contact;
	}
	if (!isHistoryView) {
		delete tabNames.history;
	}
	
	return (
			<div className='contentWrapper'>
				<div id='card-bottom-row' className='column'>
					<div className='top-right'>
						{Object.keys(tabNames).map(renderTab)}
					</div>
				</div>
				<div className='customer-ordering-grid'>
					{customerId && <ContactPersonAppealList
						selectItem={(item) => history.push(`/appeals/${item.id}`)}
						customerId={customerId}
					/>}
				</div>
			</div>
		);
}

export default withRouter((connect(mapStateToProps, null)(AppealsTabContent)));

AppealsTabContent.propTypes = {
	data: PropTypes.object,
	match: PropTypes.object,
	user: PropTypes.object
};

