import React, { memo, useMemo } from 'react';
import ReactSVG from 'react-svg';
import { withTranslation } from 'react-i18next';

function NotificationHistory(props) {
    const { t, indicator, streamState, onClick = () => {} } = props;
    return (
        <div
            className={`indicator ${streamState}`}
            title={streamState && t(`notificationHistory.${streamState}`)}
            onClick={onClick}
        >
            {indicator > 0 && (
                <span
                    title={streamState && t(`notificationHistory.${streamState}`)}
                    className="label"
                >
                    {indicator}
                </span>
            )}
            <ReactSVG
                title={streamState && t(`notificationHistory.${streamState}`)}
                svgClassName="bell"
                src="/data/svg/bell.svg"
            />
        </div>
    );
}

export default memo(withTranslation()(NotificationHistory));
