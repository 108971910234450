import React from 'react';
import PropTypes from 'prop-types';
import styles from './appealTypeSelect.module.scss';
import cx from 'classnames';

class FolderNode extends React.PureComponent {
	
	constructor (props) {
		super(props);
		
		this.onClick = this.onClick.bind(this);
	}
	
	onClick (event) {
		event.stopPropagation();
		this.props.onClick(this.props.node);
	}
	
	render () {
		const { node, childrenField, renderNode, labelField } = this.props;
		const haveChildren = Array.isArray(node[childrenField]) && node[childrenField].length > 0;
		return (
			<div className={cx(styles.node, styles.folder)}>
				<div className={styles.title} onClick={this.onClick}>
					<i className='icon-folder' />
					<label>{node[labelField]}</label>
				</div>
				{
					node.open && haveChildren &&
					<div className={styles.folderChildren}>
						{node[childrenField].map(renderNode)}
					</div>
				}
			</div>
		);
	}
}

FolderNode.propTypes = {
	open: PropTypes.bool,
	node: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	renderNode: PropTypes.func.isRequired
};

export default FolderNode;
