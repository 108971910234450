import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import styles from 'styles/modules/knowledgeBase.module.scss';
import SidebarHeader from './SidebarHeader';
import Loader from 'components/Loader';
import cn from 'classnames';
// import ReactMarkdown from "react-markdown";
import _ from 'lodash';

function Sidebar (props) {
	const { loading, issues, selectValue, setSelectValue, disabled } = props;
	const match = useRouteMatch('/issue/:id');
	
	return (
		<div className={cn(styles.sidebarBox, 'issues-sidebar')}>
			<SidebarHeader
				setSelectValue={setSelectValue}
				selectValue={selectValue}
				disabled={disabled}
			/>
			
			<div className='issues-container'>
				{loading && <Loader withContainer />}
				{
					!loading && issues && Array.isArray(issues) && issues.map((issue) => (
						<Link
							to={`/issue/${issue.problem_id}`}
							className={cn('issue',  _.get(issue, 'state_code.value'), {
								selected: _.get(match, 'params.id') === issue.problem_id,
							})}
							key={issue.problem_id}
							title={_.get(issue, 'problem_name')}
						>
							<span className={cn('indicator', _.get(issue, 'problem_type.value'))}
								  title={_.get(issue, 'problem_type.label')}/>
							{/* <ReactMarkdown source={issue.problem_name} /> */}
							{issue.problem_name}
						</Link>
					))
				}
			</div>
		</div>
	);
}


export default Sidebar;
