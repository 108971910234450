// ui
export const UI_MENU_CREATE_SHOW = 'UI_MENU_CREATE_SHOW';
export const UI_MENU_CREATE_HIDE = 'UI_MENU_CREATE_HIDE';
export const UI_MENU_APPS_LOAD = 'UI_MENU_APPS_LOAD';
export const UI_MENU_APPS_SHOW = 'UI_MENU_APPS_SHOW';
export const SHOW_TABS_HIDDEN_MENU = 'SHOW_TABS_HIDDEN_MEN';
export const SHOW_TABS_CLOSE_MENU = 'SHOW_TABS_CLOSE_MENU';
export const TOGGLE_LANG_MENU = 'TOGGLE_LANG_MENU';
export const OPEN_ASIDE_TOP_MENU = 'OPEN_ASIDE_TOP_MENU';
export const UI_ADD_SUCCESS_MESSAGE = 'UI_ADD_SUCCESS_MESSAGE';
export const UI_DEL_SUCCESS_MESSAGE = 'UI_DEL_SUCCESS_MESSAGE';
export const UI_TOGGLE_SIDE_MENU_ADDITIONS = 'UI_TOGGLE_SIDE_MENU_ADDITIONS';
export const UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON = 'UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON';
export const SUBMITTING_PROCESS_FORM = 'SUBMITTING_PROCESS_FORM';
export const SUBMIT_PROCESS_FORM_SUCCESS = 'SUBMIT_PROCESS_FORM_SUCCESS';
export const SUBMIT_PROCESS_FORM_ERROR = 'SUBMIT_PROCESS_FORM_ERROR';
export const UI_SET_TOOLTIP_TEXT = 'UI_SET_TOOLTIP_TEXT';
export const UI_SHOW_TOOLTIP = 'UI_SHOW_TOOLTIP';
export const UI_HIDE_TOOLTIP = 'UI_HIDE_TOOLTIP';
export const OPEN_MESSAGE_MODAL = 'OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION_PANEL = 'SHOW_NOTIFICATION_PANEL';
export const HIDE_NOTIFICATION_PANEL = 'HIDE_NOTIFICATION_PANEL';
export const OPEN_USER_POPUP = 'OPEN_USER_POPUP';
export const CLOSE_USER_POPUP = 'CLOSE_USER_POPUP';
export const SET_IS_HISTORY_PUSH_REQUIRE_SAVE_WITHOUT_CHANGE_CONFIRM = 'SET_IS_HISTORY_PUSH_REQUIRE_SAVE_WITHOUT_CHANGE_CONFIRM';

export const OPEN_SEARCH_EMAIL_MODAL = 'OPEN_SEARCH_EMAIL_MODAL';
export const CLOSE_SEARCH_EMAIL_MODAL = 'CLOSE_SEARCH_EMAIL_MODAL';
export const SET_SEARCH_MODAL_EMAIL_BINDERS = 'SET_SEARCH_MODAL_EMAIL_BINDERS';
export const CHECK_SEARCH_MODAL_EMAIL_BINDER = 'CHECK_SEARCH_MODAL_EMAIL_BINDER';
export const UNCHECK_SEARCH_MODAL_EMAIL_BINDER = 'UNCHECK_SEARCH_MODAL_EMAIL_BINDER';
export const CLEAR_SEARCH_MODAL_EMAIL_BINDERS = 'CLEAR_SEARCH_MODAL_EMAIL_BINDERS';

// customer
export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST';
export const CUSTOMER_REQUEST_SUCCESS = 'CUSTOMER_REQUEST_SUCCESS';
export const CUSTOMER_REQUEST_FAILURE = 'CUSTOMER_REQUEST_FAILURE';
export const CUSTOMER_MODIFY = 'CUSTOMER_MODIFY';
export const CLEAR_CUSTOMER_STATE = 'CLEAR_CUSTOMER_STATE';

export const CUSTOMER_TAB_CONTENT_UPDATE = 'CUSTOMER_TAB_CONTENT_UPDATE';
export const CLEAR_ENTIRE_CUSTOMER_STATE = 'CLEAR_ENTIRE_CUSTOMER_STATE';

export const UPDATE_CUSTOMER_NAME_SUCCESS = 'UPDATE_CUSTOMER_NAME_SUCCESS';
export const UPDATE_CUSTOMER_NAME_ERROR = 'UPDATE_CUSTOMER_NAME_ERROR';
export const OPEN_CREATE_NEW_CUSTOMER_MODAL = 'OPEN_CREATE_NEW_CUSTOMER_MODAL';
export const CLOSE_CREATE_NEW_CUSTOMER_MODAL = 'CLOSE_CREATE_NEW_CUSTOMER_MODAL';
export const OPEN_CREATE_NEW_ORDER_MODAL = 'OPEN_CREATE_NEW_ORDER_MODAL';
export const CLOSE_CREATE_NEW_ORDER_MODAL = 'CLOSE_CREATE_NEW_ORDER_MODAL';
export const OPEN_CREATE_APPEAL_FROM_FILE_MODAL = 'OPEN_CREATE_APPEAL_FROM_FILE_MODAL';
export const CLOSE_CREATE_APPEAL_FROM_FILE_MODAL = 'CLOSE_CREATE_APPEAL_FROM_FILE_MODAL';
export const OPEN_APPEAL_LINK_MODAL = 'OPEN_APPEAL_LINK_MODAL';
export const CLOSE_APPEAL_LINK_MODAL = 'CLOSE_APPEAL_LINK_MODAL';
export const OPEN_OBJECTS_LINK_MODAL = 'OPEN_OBJECTS_LINK_MODAL';
export const CLOSE_OBJECTS_LINK_MODAL = 'CLOSE_OBJECTS_LINK_MODAL';
export const OPEN_CUSTOMER_ADDRESS_MODAL = 'OPEN_CUSTOMER_ADDRESS_MODAL';
export const CLOSE_CUSTOMER_ADDRESS_MODAL = 'CLOSE_CUSTOMER_ADDRESS_MODAL';
export const OPEN_CUSTOMER_SERVICE_MODAL = 'OPEN_CUSTOMER_SERVICE_MODAL';
export const CLOSE_CUSTOMER_SERVICE_MODAL = 'CLOSE_CUSTOMER_SERVICE_MODAL';
export const OPEN_CUSTOMER_RESOURCE_MODAL = 'OPEN_CUSTOMER_RESOURCE_MODAL';
export const CLOSE_CUSTOMER_RESOURCE_MODAL = 'CLOSE_CUSTOMER_RESOURCE_MODAL';
export const ADDRESS_SAVE = 'ADDRESS_SAVE';
export const OPEN_CREATE_NEW_CONTACT_PERSON_MODAL = 'OPEN_CREATE_NEW_CONTACT_PERSON_MODAL';
export const CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL = 'CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL';
export const CREATE_NEW_CUSTOMER_SUCCESS = 'CREATE_NEW_CUSTOMER_SUCCESS';
export const CREATE_NEW_CUSTOMER_ERROR = 'CREATE_NEW_CUSTOMER_ERROR';

export const APPLICANTS_REQUEST = 'APPLICANTS_REQUEST';
export const APPLICANTS_REQUEST_SUCCESS = 'APPLICANTS_REQUEST_SUCCESS';
export const APPLICANTS_REQUEST_FAILURE = 'APPLICANTS_REQUEST_FAILURE';
export const CLEAR_APPLICANTS_STATE = 'CLEAR_APPLICANTS_STATE';

export const CUSTOMER_EDIT_BEGIN = 'CUSTOMER_EDIT_BEGIN';
export const CUSTOMER_EDIT_SUCCESS = 'CUSTOMER_EDIT_SUCCESS';
export const CUSTOMER_EDIT_ERROR = 'CUSTOMER_EDIT_ERROR';

export const CUSTOMER_ADDRESS_REQUEST = 'CUSTOMER_ADDRESS_REQUEST';
export const CUSTOMER_ADDRESS_REQUEST_SUCCESS = 'CUSTOMER_ADDRESS_REQUEST_SUCCESS';
export const CUSTOMER_ADDRESS_REQUEST_FAILURE = 'CUSTOMER_ADDRESS_REQUEST_FAILURE';
export const CUSTOMER_ADDRESS_CLEAR = 'CUSTOMER_ADDRESS_CLEAR';
export const CUSTOMER_ADDRESS_MODAL_OPEN = 'CUSTOMER_ADDRESS_MODAL_OPEN';
export const CUSTOMER_ADDRESS_MODAL_CLOSE = 'CUSTOMER_ADDRESS_MODAL_CLOSE';

export const GET_SEARCH_BANK = 'GET_SEARCH_BANK';
export const GET_SEARCH_BANK_SUCCESS = 'GET_SEARCH_BANK_SUCCESS';
export const GET_SEARCH_BANK_ERROR = 'GET_SEARCH_BANK_ERROR';

export const LOADING_CONTACT_PERSONS_LIST = 'LOADING_CONTACT_PERSONS_LIST';
export const SET_CONTACT_PERSONS_LIST = 'SET_CONTACT_PERSONS_LIST';
export const CLEAR_CONTACT_PERSONS_LIST = 'CLEAR_CONTACT_PERSONS_LIST';
export const CONTACT_PERSON_REQUEST = 'CONTACT_PERSON_REQUEST';
export const CONTACT_PERSON_REQUEST_SUCCESS = 'CONTACT_PERSON_REQUEST_SUCCESS';
export const CONTACT_PERSON_REQUEST_FAILURE = 'CONTACT_PERSON_REQUEST_FAILURE';
export const CLEAR_CONTACT_PERSON_STATE = 'CLEAR_CONTACT_PERSON_STATE';
export const PERSON_EDIT_BEGIN = 'PERSON_EDIT_BEGIN';
export const PERSON_EDIT_SUCCESS = 'PERSON_EDIT_SUCCESS';
export const PERSON_EDIT_ERROR = 'PERSON_EDIT_ERROR';
export const PERSON_SAVE_CONTACT_BEGIN = 'PERSON_SAVE_CONTACT_BEGIN';
export const PERSON_SAVE_CONTACT_SUCCESS = 'PERSON_SAVE_CONTACT_SUCCESS';
export const PERSON_SAVE_CONTACT_ERROR = 'PERSON_SAVE_CONTACT_ERROR';
export const PERSON_DELETE_CONTACT_BEGIN = 'PERSON_DELETE_CONTACT_BEGIN';
export const PERSON_DELETE_CONTACT_SUCCESS = 'PERSON_DELETE_CONTACT_SUCCESS';
export const PERSON_DELETE_CONTACT_ERROR = 'PERSON_DELETE_CONTACT_ERROR';
export const CLEAR_ALL_CUSTOMERS = 'CLEAR_ALL_CUSTOMERS';

export const APPLICANT_FILTERS_REQUEST = 'APPLICANT_FILTERS_REQUEST';
export const APPLICANT_FILTERS_REQUEST_SUCCESS = 'APPLICANT_FILTERS_REQUEST_SUCCESS';
export const APPLICANT_PRESET_FILTERS_REQUEST_SUCCESS = 'APPLICANT_PRESET_FILTERS_REQUEST_SUCCESS';
export const APPLICANT_FILTERS_REQUEST_FAILURE = 'APPLICANT_FILTERS_REQUEST_FAILURE';
export const APPLICANT_SET_ENTITY_TAB = 'APPLICANT_SET_ENTITY_TAB';
export const REQUEST_CUSTOMER_BILLING_CARDS = 'REQUEST_CUSTOMER_BILLING_CARDS';
export const SET_CUSTOMER_BILLING_CARDS = 'SET_CUSTOMER_BILLING_CARDS';
export const RESET_CUSTOMER_BILLING_CARDS = 'RESET_CUSTOMER_BILLING_CARDS';
export const SET_CUSTOMER_GRID_REFRESH = 'SET_CUSTOMER_GRID_REFRESH';

export const CONTACT_PERSON_FIELD_EDIT = 'CONTACT_PERSON_FIELD_EDIT';
export const CUSTOMER_FIELD_EDIT = 'CUSTOMER_FIELD_EDIT';

export const CUSTOMER_ADD_LIVE_SAVING = 'CUSTOMER_ADD_LIVE_SAVING';
export const CUSTOMER_REMOVE_LIVE_SAVING = 'CUSTOMER_REMOVE_LIVE_SAVING';


// search
export const SEARCH_HEADER_GET_SUPPOSE = 'SEARCH_GET_SUPPOSE';
export const SEARCH_HEADER_GET_SUPPOSE_SUCCESS = 'SEARCH_GET_SUPPOSE_SUCCESS';
export const SEARCH_HEADER_GET_SUPPOSE_FAIL = 'SEARCH_GET_SUPPOSE_FAIL';
export const SEARCH_HEADER_CLEAR_RESULT = 'SEARCH_CLEAR_RESULT';
export const SEARCH_HEADER_EXPAND = 'SEARCH_HEADER_EXPAND';
export const SEARCH_HEADER_REDUCE = 'SEARCH_HEADER_REDUCE';
export const SEARCH_LIVE_GET_SUPPOSE = 'SEARCH_LIVE_GET_SUPPOSE';
export const SEARCH_LIVE_GET_SUPPOSE_SUCCESS = 'SEARCH_LIVE_GET_SUPPOSE_SUCCESS';
export const SEARCH_LIVE_GET_SUPPOSE_FAIL = 'SEARCH_LIVE_GET_SUPPOSE_FAIL';
export const SEARCH_LIVE_CHANGE_QUERY = 'SEARCH_LIVE_CHANGE_QUERY';
export const SEARCH_LIVE_CLEAR_RESULT = 'SEARCH_LIVE_CLEAR_RESULT';
export const SEARCH_LIVE_OPEN = 'SEARCH_LIVE_OPEN';
export const SEARCH_LIVE_HIDE = 'SEARCH_LIVE_HIDE';

// TODO move to constants/index
export const SEARCH_TIMER_INTERVAL = 500;

// orders
export const ORDERS_REQUEST = 'ORDERS_REQUEST';
export const ORDERS_REQUEST_SUCCESS = 'ORDERS_REQUEST_SUCCESS';
export const ORDERS_REQUEST_FAILURE = 'ORDERS_REQUEST_FAILURE';
export const ORDERS_FILTERS_REQUEST = 'ORDERS_FILTERS_REQUEST';
export const ORDERS_FILTERS_REQUEST_SUCCESS = 'ORDERS_FILTERS_REQUEST_SUCCESS';
export const ORDERS_FILTERS_REQUEST_FAILURE = 'ORDERS_FILTERS_REQUEST_FAILURE';
export const ORDERS_SET_ACTIVE_FILTER = 'ORDERS_SET_ACTIVE_FILTER';
export const ORDERS_SET_ACTIVE_FILTER_PAGE = 'ORDERS_SET_ACTIVE_FILTER_PAGE';
export const ORDERS_FILTER_ATTRS_GET_SUPPOSE = 'ORDERS_FILTER_ATTRS_GET_SUPPOSE';
export const ORDERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS = 'ORDERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS';
export const ORDERS_FILTER_ATTRS_GET_SUPPOSE_FAIL = 'ORDERS_FILTER_ATTRS_GET_SUPPOSE_FAIL';
export const ORDERS_ADD_FILTER_ATTRS = 'ORDERS_ADD_FILTER_ATTRS';
export const ORDERS_SAVE_FILTER_SUCCESS = 'ORDERS_SAVE_FILTER_SUCCESS';
export const ORDERS_SAVE_FILTER_FAIL = 'ORDERS_SAVE_FILTER_FAIL';
export const ORDERS_SAVE_FILTER = 'ORDERS_SAVE_FILTER';
export const ORDERS_DELETE_FILTER = 'ORDERS_DELETE_FILTER';
export const ORDERS_DELETE_FILTER_SUCCESS = 'ORDERS_DELETE_FILTER_SUCCESS';
export const ORDERS_DELETE_FILTER_FAIL = 'ORDERS_DELETE_FILTER_FAIL';
export const ORDERS_FILTER_PARAMS_GET_SUPPOSE = 'ORDERS_FILTER_PARAMS_GET_SUPPOSE';
export const ORDERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS = 'ORDERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS';
export const ORDERS_FILTER_PARAMS_GET_SUPPOSE_FAIL = 'ORDERS_FILTER_PARAMS_GET_SUPPOSE_FAIL';
export const ORDERS_FILTER_ATTRS_CLEAR_RESULT = 'ORDERS_FILTER_ATTRS_CLEAR_RESULT';
export const ORDERS_CLEAR_FILTER_ATTRS = 'ORDERS_CLEAR_FILTER_ATTRS';
export const ORDERS_CLEAR_FILTER_INFO = 'ORDERS_CLEAR_FILTER_INFO';
export const ORDERS_FILTER_DATA_GET_SUPPOSE = 'ORDERS_FILTER_DATA_GET_SUPPOSE';
export const ORDERS_FILTER_DATA_GET_SUPPOSE_SUCCESS = 'ORDERS_FILTER_DATA_GET_SUPPOSE_SUCCESS';
export const ORDERS_FILTER_DATA_GET_SUPPOSE_FAIL = 'ORDERS_FILTER_DATA_GET_SUPPOSE_FAIL';
export const ORDERS_OPEN_FILTER_PARAMS = 'ORDERS_OPEN_FILTER_PARAMS';
export const ORDERS_CLOSE_FILTER_PARAMS = 'ORDERS_CLOSE_FILTER_PARAMS';
export const ORDERS_OPEN_NEW_FILTER_PARAMS = 'ORDERS_OPEN_NEW_FILTER_PARAMS';
export const ORDERS_CLOSE_NEW_FILTER_PARAMS = 'ORDERS_CLOSE_NEW_FILTER_PARAMS';
export const ORDERS_CREATE_NEW_FILTER = 'ORDERS_CREATE_NEW_FILTER';
export const ORDERS_FILTER_LIST_GET_SUPPOSE = 'ORDERS_FILTER_LIST_GET_SUPPOSE';
export const ORDERS_FILTER_LIST_GET_SUPPOSE_SUCCESS = 'ORDERS_FILTER_LIST_GET_SUPPOSE_SUCCESS';
export const ORDERS_FILTER_LIST_GET_SUPPOSE_FAIL = 'ORDERS_FILTER_LIST_GET_SUPPOSE_FAIL';
export const ORDERS_FILTER_LIST_CLEAR_RESULT = 'ORDERS_FILTER_LIST_CLEAR_RESULT';
export const ORDERS_ADD_FILTER_OPTION = 'ORDERS_ADD_FILTER_OPTION';
export const ORDERS_SET_OLD_FILTER_DATA  = 'ORDERS_SET_OLD_FILTER_DATA';
export const ORDERS_CLEAR_NEW_FILTER_OPTION   = 'ORDERS_CLEAR_NEW_FILTER_OPTION';
export const ORDERS_CLEAR_FILTER_DATA   = 'ORDERS_CLEAR_FILTER_DATA';
export const ORDERS_APPLY_EXISTING_FILTER   = 'ORDERS_APPLY_EXISTING_FILTER';
export const ORDERS_SET_SELECT_OPTIONS_FOR_FILTER   = 'ORDERS_SET_SELECT_OPTIONS_FOR_FILTER';


export const FILTER_LIST_SET_ACTIVE_FILTER_PAGE = 'FILTER_LIST_SET_ACTIVE_FILTER_PAGE';

// customers
export const CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST';
export const CUSTOMERS_REQUEST_SUCCESS = 'CUSTOMERS_REQUEST_SUCCESS';
export const CUSTOMERS_REQUEST_FAILURE = 'CUSTOMERS_REQUEST_FAILURE';
export const CUSTOMERS_FILTERS_REQUEST = 'CUSTOMERS_FILTERS_REQUEST';
export const CUSTOMERS_FILTERS_REQUEST_SUCCESS = 'CUSTOMERS_FILTERS_REQUEST_SUCCESS';
export const CUSTOMERS_FILTERS_REQUEST_FAILURE = 'CUSTOMERS_FILTERS_REQUEST_FAILURE';
export const CUSTOMERS_SET_ACTIVE_FILTER = 'CUSTOMERS_SET_ACTIVE_FILTER';
export const CUSTOMERS_SET_ACTIVE_FILTER_PAGE = 'CUSTOMERS_SET_ACTIVE_FILTER_PAGE';
export const CUSTOMERS_FILTER_ATTRS_GET_SUPPOSE = 'CUSTOMERS_FILTER_ATTRS_GET_SUPPOSE';
export const CUSTOMERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS = 'CUSTOMERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS';
export const CUSTOMERS_FILTER_ATTRS_GET_SUPPOSE_FAIL = 'CUSTOMERS_FILTER_ATTRS_GET_SUPPOSE_FAIL';
export const CUSTOMERS_ADD_FILTER_ATTRS = 'CUSTOMERS_ADD_FILTER_ATTRS';
export const CUSTOMERS_SAVE_FILTER_SUCCESS = 'CUSTOMERS_SAVE_FILTER_SUCCESS';
export const CUSTOMERS_SAVE_FILTER_FAIL = 'CUSTOMERS_SAVE_FILTER_FAIL';
export const CUSTOMERS_SAVE_FILTER = 'CUSTOMERS_SAVE_FILTER';
export const CUSTOMERS_DELETE_FILTER = 'CUSTOMERS_DELETE_FILTER';
export const CUSTOMERS_DELETE_FILTER_SUCCESS = 'CUSTOMERS_DELETE_FILTER_SUCCESS';
export const CUSTOMERS_DELETE_FILTER_FAIL = 'CUSTOMERS_DELETE_FILTER_FAIL';
export const CUSTOMERS_FILTER_PARAMS_GET_SUPPOSE = 'CUSTOMERS_FILTER_PARAMS_GET_SUPPOSE';
export const CUSTOMERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS = 'CUSTOMERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS';
export const CUSTOMERS_FILTER_PARAMS_GET_SUPPOSE_FAIL = 'CUSTOMERS_FILTER_PARAMS_GET_SUPPOSE_FAIL';
export const CUSTOMERS_FILTER_ATTRS_CLEAR_RESULT = 'CUSTOMERS_FILTER_ATTRS_CLEAR_RESULT';
export const CUSTOMERS_CLEAR_FILTER_ATTRS = 'CUSTOMERS_CLEAR_FILTER_ATTRS';
export const CUSTOMERS_CLEAR_FILTER_INFO = 'CUSTOMERS_CLEAR_FILTER_INFO';
export const CUSTOMERS_FILTER_DATA_GET_SUPPOSE = 'CUSTOMERS_FILTER_DATA_GET_SUPPOSE';
export const CUSTOMERS_FILTER_DATA_GET_SUPPOSE_SUCCESS = 'CUSTOMERS_FILTER_DATA_GET_SUPPOSE_SUCCESS';
export const CUSTOMERS_FILTER_DATA_GET_SUPPOSE_FAIL = 'CUSTOMERS_FILTER_DATA_GET_SUPPOSE_FAIL';
export const CUSTOMERS_OPEN_FILTER_PARAMS = 'CUSTOMERS_OPEN_FILTER_PARAMS';
export const CUSTOMERS_CLOSE_FILTER_PARAMS = 'CUSTOMERS_CLOSE_FILTER_PARAMS';
export const CUSTOMERS_OPEN_NEW_FILTER_PARAMS = 'CUSTOMERS_OPEN_NEW_FILTER_PARAMS';
export const CUSTOMERS_CLOSE_NEW_FILTER_PARAMS = 'CUSTOMERS_CLOSE_NEW_FILTER_PARAMS';
export const CUSTOMERS_CREATE_NEW_FILTER = 'CUSTOMERS_CREATE_NEW_FILTER';
export const CUSTOMERS_FILTER_LIST_GET_SUPPOSE = 'CUSTOMERS_FILTER_LIST_GET_SUPPOSE';
export const CUSTOMERS_FILTER_LIST_GET_SUPPOSE_SUCCESS = 'CUSTOMERS_FILTER_LIST_GET_SUPPOSE_SUCCESS';
export const CUSTOMERS_FILTER_LIST_GET_SUPPOSE_FAIL = 'CUSTOMERS_FILTER_LIST_GET_SUPPOSE_FAIL';
export const CUSTOMERS_FILTER_LIST_CLEAR_RESULT = 'CUSTOMERS_FILTER_LIST_CLEAR_RESULT';
export const CUSTOMERS_ADD_FILTER_OPTION = 'CUSTOMERS_ADD_FILTER_OPTION';
export const CUSTOMERS_SET_OLD_FILTER_DATA  = 'CUSTOMERS_SET_OLD_FILTER_DATA';
export const CUSTOMERS_CLEAR_NEW_FILTER_OPTION   = 'CUSTOMERS_CLEAR_NEW_FILTER_OPTION';
export const CUSTOMERS_CLEAR_FILTER_DATA   = 'CUSTOMERS_CLEAR_FILTER_DATA';
export const CUSTOMERS_APPLY_EXISTING_FILTER   = 'CUSTOMERS_APPLY_EXISTING_FILTER';
export const CUSTOMERS_SET_SELECT_OPTIONS_FOR_FILTER   = 'CUSTOMERS_SET_SELECT_OPTIONS_FOR_FILTER';


// filters
export const EDIT_FILTER = 'EDIT_FILTER';
export const EDIT_FILTER_NAME_SUCCESS = 'EDIT_FILTER_NAME_SUCCESS';
export const OPEN_FILTER_PARAMS = 'OPEN_FILTER_PARAMS';
export const CLOSE_FILTER_PARAMS = 'CLOSE_FILTER_PARAMS';
export const OPEN_NEW_FILTER_PARAMS = 'OPEN_NEW_FILTER_PARAMS';
export const CLOSE_NEW_FILTER_PARAMS = 'CLOSE_NEW_FILTER_PARAMS';
export const FILTER_ATTRS_GET_SUPPOSE = 'FILTER_ATTRS_GET_SUPPOSE';
export const FILTER_ATTRS_GET_SUPPOSE_SUCCESS = 'FILTER_ATTRS_GET_SUPPOSE_SUCCESS';
export const FILTER_ATTRS_GET_SUPPOSE_FAIL = 'FILTER_ATTRS_GET_SUPPOSE_FAIL';
export const FILTER_ATTRS_CLEAR_RESULT = 'FILTER_ATTRS_CLEAR_RESULT';
export const ADD_FILTER_ATTRS = 'ADD_FILTER_ATTRS';
export const CLEAR_FILTER_ATTRS = 'CLEAR_FILTER_ATTRS';
export const SAVE_FILTER = 'SAVE_FILTER';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_FAIL = 'SAVE_FILTER_FAIL';
export const DELETE_FILTER = 'DELETE_FILTER';
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';
export const DELETE_FILTER_FAIL = 'DELETE_FILTER_FAIL';
export const FILTER_PARAMS_GET_SUPPOSE = 'FILTER_PARAMS_GET_SUPPOSE';
export const FILTER_PARAMS_GET_SUPPOSE_SUCCESS = 'FILTER_PARAMS_GET_SUPPOSE_SUCCESS';
export const FILTER_PARAMS_GET_SUPPOSE_FAIL = 'FILTER_PARAMS_GET_SUPPOSE_FAIL';
export const FILTER_LIST_GET_SUPPOSE = 'FILTER_LIST_GET_SUPPOSE';
export const FILTER_LIST_GET_SUPPOSE_SUCCESS = 'FILTER_LIST_GET_SUPPOSE_SUCCESS';
export const FILTER_LIST_GET_SUPPOSE_FAIL = 'FILTER_LIST_GET_SUPPOSE_FAIL';
export const FILTER_LIST_CLEAR_RESULT = 'FILTER_LIST_CLEAR_RESULT';
export const CREATE_NEW_FILTER = 'CREATE_NEW_FILTER';
export const APPLY_EXISTING_FILTER = 'APPLY_EXISTING_FILTER';
export const CLEAR_FILTER_INFO = 'CLEAR_FILTER_INFO';
export const FILTER_DATA_GET_SUPPOSE = 'FILTER_DATA_GET_SUPPOSE';
export const FILTER_DATA_GET_SUPPOSE_SUCCESS = 'FILTER_DATA_GET_SUPPOSE_SUCCESS';
export const FILTER_DATA_GET_SUPPOSE_FAIL = 'FILTER_DATA_GET_SUPPOSE_FAIL';
export const CLEAR_FILTER_DATA = 'CLEAR_FILTER_DATA';
export const OPENED_FILTER = 'OPENED_FILTER';
export const ADD_FILTER_OPTION = 'ADD_FILTER_OPTION';
export const CLEAR_NEW_FILTER_OPTION = 'CLEAR_NEW_FILTER_OPTION';
export const SET_OLD_FILTER_DATA = 'SET_OLD_FILTER_DATA';
export const GET_APPEAL_TYPES_LIST_FOR_FILTER = 'GET_APPEAL_TYPES_LIST_FOR_FILTER';
export const GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS = 'GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS';
export const GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR = 'GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR';
export const CLEAR_APPEAL_TYPES_LIST_FOR_FILTER = 'CLEAR_APPEAL_TYPES_LIST_FOR_FILTER';
export const SET_SELECT_OPTIONS_FOR_FILTER = 'SET_SELECT_OPTIONS_FOR_FILTER';
export const CUSTOMER_SEARCH_REQUEST = 'CUSTOMER_SEARCH_REQUEST';
export const CUSTOMER_SEARCH_SUCCESS = 'CUSTOMER_SEARCH_SUCCESS';
export const CUSTOMER_SEARCH_FAILURE = 'CUSTOMER_SEARCH_FAILURE';

export const CUSTOMER = 'customer';

// content
export const CONTENT_UPDATE = 'CONTENT_UPDATE';
export const CONTENT_SET = 'CONTENT_SET';
export const CONTENT_TAB = 'CONTENT_TAB';
export const CONTENT_RESET = 'CONTENT_RESET';
export const CONTENT_LOADING = 'CONTENT_LOADING';
export const CONTENT_ERROR = 'CONTENT_ERROR';
export const REMOVE_PREVIOUS_TAB_DATA = 'REMOVE_PREVIOUS_TAB_DATA';

// client
export const CLIENT_LIVESEARCH_GET_SUPPOSE = 'CLIENT_LIVESEARCH_GET_SUPPOSE';
export const CLEAR_CLIENT_LIVESEARCH = 'CLEAR_CLIENT_LIVESEARCH';
export const CLIENT_GET_SUPPOSE = 'CLIENT_GET_SUPPOSE';
export const CLIENT_GET_SUPPOSE_SUCCESS = 'CLIENT_GET_SUPPOSE_SUCCESS';
export const CLIENT_GET_SUPPOSE_FAILURE = 'CLIENT_GET_SUPPOSE_FAILURE';
export const CLEAR_CLIENT_GET_SUPPOSE = 'CLEAR_CLIENT_GET_SUPPOSE';
export const OPEN_ADVANCED_SEARCH_MODAL = 'OPEN_ADVANCED_SEARCH_MODAL';
export const CLOSE_ADVANCED_SEARCH_MODAL = 'CLOSE_ADVANCED_SEARCH_MODAL';

// knowledge base
export const KNOWLEDGE_BASE_REQUEST = 'KNOWLEDGE_BASE_REQUEST';
export const KNOWLEDGE_BASE_REQUEST_SUCCESS = 'KNOWLEDGE_BASE_REQUEST_SUCCESS';
export const KNOWLEDGE_BASE_REQUEST_FAILURE = 'KNOWLEDGE_BASE_REQUEST_FAILURE';
export const KNOWLEDGE_BASE_SEARCH_QUERY_UPDATE = 'KNOWLEDGE_BASE_SEARCH_QUERY_UPDATE';
export const KNOWLEDGE_BASE_SEARCH_MODE_ENABLE = 'KNOWLEDGE_BASE_SEARCH_MODE_ENABLE';
export const KNOWLEDGE_BASE_SEARCH_MODE_DISABLE = 'KNOWLEDGE_BASE_SEARCH_MODE_DISABLE';
export const KNOWLEDGE_BASE_ITEM_CLEAR = 'KNOWLEDGE_BASE_ITEM_CLEAR';

export const KNOWLEDGE_BASE_ITEM_REQUEST = 'KNOWLEDGE_BASE_ITEM_REQUEST';
export const KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET = 'KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET';
export const KNOWLEDGE_BASE_ITEM_REQUEST_SUCCESS = 'KNOWLEDGE_BASE_ITEM_REQUEST_SUCCESS';
export const KNOWLEDGE_BASE_ITEM_REQUEST_FAILURE = 'KNOWLEDGE_BASE_ITEM_REQUEST_FAILURE';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_FORM_COLLAPSE = 'SET_FORM_COLLAPSE';
export const KNOWLEDGE_BASE_RESET = 'KNOWLEDGE_BASE_RESET';

// comments
export const COMMENTS_REQUEST = 'COMMENTS_REQUEST';
export const COMMENTS_REQUEST_SUCCESS = 'COMMENTS_REQUEST_SUCCESS';
export const COMMENTS_REQUEST_FAILURE = 'COMMENTS_REQUEST_FAILURE';
export const COUNTER_COMMENTS_UP = 'COUNTER_COMMENTS_UP';

export const APPEAL_COMMENTS_RESET = 'APPEAL_COMMENTS_RESET';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_ERROR = 'UPDATE_COMMENT_ERROR';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCES';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';
export const SET_EDITABLE_COMMENT = 'SET_EDITABLE_COMMENT';
export const UPLOAD_FILE_TO_COMMENT = 'UPLOAD_FILE_TO_COMMENT';
export const UPLOAD_FILE_TO_COMMENT_SUCCESS = 'UPLOAD_FILE_TO_COMMENT_SUCCESS';
export const UPLOAD_FILE_TO_COMMENT_ERROR = 'UPLOAD_FILE_TO_COMMENT_ERROR';
export const DELETE_FILE_FROM_COMMENTS = 'DELETE_FILE_FROM_COMMENTS';
export const DELETE_FILE_FROM_COMMENTS_SUCCESS = 'DELETE_FILE_FROM_COMMENTS_SUCCESS';
export const DELETE_FILE_FROM_COMMENTS_ERROR = 'DELETE_FILE_FROM_COMMENTS_ERROR';

//quality

export const SAVE_QUALITY = 'SAVE_QUALITY';
export const SAVE_QUALITY_SUCCESS = 'SAVE_QUALITY_SUCCESS';

// appeal

// tabs
export const TABS_REMOVE_TAB = 'TABS_REMOVE_TAB';
export const TABS_RESET = 'TABS_RESET';
export const CLOSE_ALL_EXCEPT_CURRENT = 'CLOSE_ALL_EXCEPT_CURRENT';
export const TABS_REVERSE = 'TABS_REVERSE';
export const TOGGLE_BLOCK_ADDING_POPUP = 'TOGGLE_BLOCK_ADDING_POPUP';
export const CLOSE_BLOCK_ADDING_POPUP = 'CLOSE_BLOCK_ADDING_POPUP';

export const ENABLE_TAB_CACHING = 'ENABLE_TAB_CACHING';
export const DISABLE_TAB_CACHING = 'DISABLE_TAB_CACHING';
export const SET_CURRENT_TAB_FIELD = 'SET_CURRENT_TAB_FIELD';
export const ADD_TABS = 'ADD_TABS';
export const SET_CURRENT_TAB_NAME_BLOCKED = 'SET_CURRENT_TAB_NAME_BLOCKED';
export const SET_TAB_NAME = 'SET_TAB_NAME';
export const SET_BREAD_CRUMBS = 'SET_BREAD_CRUMBS';
export const SET_CURRENT_SUPER_TAB_PATH = 'SET_CURRENT_SUPER_TAB_PATH';


// emails
export const EMAILS_REQUEST = 'EMAILS_REQUEST';
export const EMAILS_REQUEST_SUCCESS = 'EMAILS_REQUEST_SUCCESS';
export const EMAILS_REQUEST_FAILURE = 'EMAILS_REQUEST_FAILURE';
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';
export const TOGGLE_ALL_MAILS = 'TOGGLE_ALL_MAILS';
export const UPLOAD_EMAIL_FILE_REQUEST = 'UPLOAD_EMAIL_FILE_REQUEST';
export const UPLOAD_EMAIL_FILE_SUCCESS = 'UPLOAD_EMAIL_FILE_SUCCESS';
export const UPLOAD_EMAIL_FILE_FAILURE = 'UPLOAD_EMAIL_FILE_FAILURE';
export const UPLOAD_EMAIL_FILE_LIST_REQUEST = 'UPLOAD_EMAIL_FILE_LIST_REQUEST';
export const UPLOAD_EMAIL_FILE_LIST_SUCCESS = 'UPLOAD_EMAIL_FILE_LIST_SUCCESS';
export const UPLOAD_EMAIL_FILE_LIST_FAILURE = 'UPLOAD_EMAIL_FILE_LIST_FAILURE';
export const DELETE_EMAIL_FILE_REQUEST = 'DELETE_EMAIL_FILE_REQUEST';
export const DELETE_EMAIL_FILE_SUCCESS = 'DELETE_EMAIL_FILE_SUCCESS';
export const DELETE_EMAIL_FILE_FAILURE = 'DELETE_EMAIL_FILE_FAILURE';
export const CREATE_NEW_EMAIL_REQUEST = 'CREATE_NEW_EMAIL_REQUEST';
export const CREATE_NEW_EMAIL_SUCCESS = 'CREATE_NEW_EMAIL_SUCCESS';
export const CREATE_NEW_EMAIL_FAILURE = 'CREATE_NEW_EMAIL_FAILURE';
export const GET_TEMPLATE_EMAIL_REQUEST = 'GET_TEMPLATE_EMAIL_REQUEST';
export const GET_TEMPLATE_EMAIL_SUCCESS = 'GET_TEMPLATE_EMAIL_SUCCESS';
export const GET_TEMPLATE_EMAIL_FAILURE = 'GET_TEMPLATE_EMAIL_FAILURE';
export const GET_TEMPLATE_LIST_REQUEST = 'GET_TEMPLATE_LIST_REQUEST';
export const GET_TEMPLATE_LIST_SUCCESS = 'GET_TEMPLATE_LIST_SUCCESS';
export const GET_TEMPLATE_LIST_FAILURE = 'GET_TEMPLATE_LIST_FAILURE';
export const SET_FILES_LENGTH = 'SET_FILES_LENGTH';
export const SET_FORM_FORWARD = 'SET_FORM_FORWARD';
export const SET_FORM_REPLY = 'SET_FORM_REPLY';
export const SET_SIGNATURE = 'SET_SIGNATURE';
export const UPDATE_SIGNATURE_LIST = 'UPDATE_SIGNATURE_LIST';
export const CHANGE_EDIT_SIGN_MODE = 'CHANGE_EDIT_SIGN_MODE';
export const RESET_EMAIL_FORM = 'RESET_EMAIL_FORM';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const OPEN_EMAIL_SUCCESS = 'OPEN_EMAIL_SUCCESS';
export const EMAILS_SEARCH_QUERY_UPDATE = 'EMAILS_SEARCH_QUERY_UPDATE';
export const APPEAL_MAILING_RESET = 'APPEAL_MAILING_RESET';
export const SET_MAIL_BODY = 'SET_MAIL_BODY';
export const SET_REPLY_MAIL_BODY = 'SET_REPLY_MAIL_BODY';
export const SET_MAIL_STATE = 'SET_MAIL_STATE';
export const COUNTER_MAILING_UP = 'COUNTER_MAILING_UP';

// index
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const UPDATE_CURRENT_TAB_URL = 'UPDATE_CURRENT_TAB_URL';
export const USER_INIT = 'USER_INIT';

// user
export const ADD_CARD_TO_HISTORY = 'ADD_CARD_TO_HISTORY';
export const ADD_CARDS_TO_HISTORY = 'ADD_CARDS_TO_HISTORY';
export const UPDATE_PHONES_LIST = 'UPDATE_PHONES_LIST';
export const CHANGE_ACCOUNT_MODE = 'CHANGE_ACCOUNT_MODE';
export const CHANGE_MODAL_MODE = 'CHANGE_MODAL_MODE';

// app
export const START_REQUEST = 'START_REQUEST';
export const END_REQUEST = 'END_REQUEST';

// changeHistory
export const SAVE_FORM_CHANGES = 'SAVE_FORM_CHANGES';
export const CANCEL_FORM_CHANGE = 'CANCEL_FORM_CHANGE';
export const REPEAT_FORM_CHANGE = 'REPEAT_FORM_CHANGE';

// call
export const SET_CALL_RECEPTION_STATUS = 'SET_CALL_RECEPTION_STATUS';
export const OPEN_CALL_CONTROL_WIDGET = 'SHOW_CALL_CONTROL_WIDGET';
export const CLOSE_CALL_CONTROL_WIDGET = 'CLOSE_CALL_CONTROL_WIDGET';
export const SET_POSSIBLE_CUSTOMERS = 'SET_POSSIBLE_CUSTOMERS';
export const CLEAR_POSSIBLE_CUSTOMERS = 'CLEAR_POSSIBLE_CUSTOMERS';
export const SET_LAST_SSE_MESSAGE = 'SET_LAST_SSE_MESSAGE';
export const CLEAR_CALL_TEMP_STATE = 'CLEAR_CALL_TEMP_STATE';
export const ADD_CREATED_APPEAL = 'ADD_CREATED_APPEAL';
export const SET_AUTHORIZATION_SUCCESS = 'SET_AUTHORIZATION_SUCCESS';
export const ADD_NEW_INTERACTION = 'ADD_NEW_INTERACTION';
export const CLOSE_INTERACTION = 'CLOSE_INTERACTION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const SET_TIMER = 'SET_TIMER';
export const SET_OPERATOR_ACTIONS = 'SET_OPERATOR_ACTIONS';
export const OUT_OF_SERVICE = 'OUT_OF_SERVICE';
export const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG';
export const SET_IN_PHONE_ERROR_MSG = 'SET_IN_PHONE_ERROR_MSG';
export const SET_OUT_PHONE_ERROR_MSG = 'SET_OUT_PHONE_ERROR_MSG';
export const UPDATE_CREATED_APPEAL_NAME = 'UPDATE_CREATED_APPEAL_NAME';
export const SELECT_PHONE = 'SELECT_PHONE';

// audioplayer
export const SET_AUDIO_TRACK = 'SET_AUDIO_TRACK';
export const SHOW_AUDIO_PLAYER = 'SHOW_AUDIO_PLAYER';
export const HIDE_AUDIO_PLAYER = 'HIDE_AUDIO_PLAYER';
export const SAVE_AUDIO_TRACK_TIME = 'SAVE_AUDIO_TRACK_TIME';
export const SAVE_AUDIO_PLAYER_POSITION = 'SAVE_AUDIO_PLAYER_POSITION';

// knowledges
export const CHANGE_CHECKED_KNOWLEDGE = 'CHANGE_CHECKED_KNOWLEDGE';
export const UPDATE_FOUND_KNOWLEDGES = 'UPDATE_FOUND_KNOWLEDGES';
export const UPDATE_DIRECTORIES_STRUCTURE = 'UPDATE_DIRECTORIES_STRUCTURE';
export const ENABLE_SEARCH_MODE = 'ENABLE_SEARCH_MODE';
export const DISABLE_SEARCH_MODE = 'DISABLE_SEARCH_MODE';
export const UPDATE_KNOWLEDGES_SEARCH_QUERY = 'UPDATE_KNOWLEDGES_SEARCH_QUERY';
export const SUBMIT_KNOWLEDGE_SEARCH = 'SUBMIT_KNOWLEDGE_SEARCH';
export const CLEAR_KNOWLEDGE_BASE_TEMP_STATE = 'CLEAR_KNOWLEDGE_BASE_TEMP_STATE';

export const KNOWLEDGE_ITEM_REQUEST = 'KNOWLEDGE_ITEM_REQUEST';
export const KNOWLEDGE_ITEM_REQUEST_SUCCESS = 'KNOWLEDGE_ITEM_REQUEST_SUCCESS';
export const KNOWLEDGE_ITEM_DYNPARAMS_SET = 'KNOWLEDGE_ITEM_DYNPARAMS_SET';
export const KNOWLEDGE_ITEM_REQUEST_FAILURE = 'KNOWLEDGE_ITEM_REQUEST_FAILURE';
export const KNOWLEDGE_ITEM_MODIFY = 'KNOWLEDGE_ITEM_MODIFY';

export const SET_KNOWLEDGE_ITEM_TO_CREATE = 'SET_KNOWLEDGE_ITEM_TO_CREATE';
export const CLEAR_KNOWLEDGE_ITEM_TO_CREATE = 'CLEAR_KNOWLEDGE_ITEM_TO_CREATE';

export const KNOWLEDGES_REQUEST = 'KNOWLEDGES_REQUEST';
export const KNOWLEDGES_REQUEST_SUCCESS = 'KNOWLEDGES_REQUEST_SUCCESS';
export const KNOWLEDGES_REQUEST_FAILURE = 'KNOWLEDGES_REQUEST_FAILURE';

export const STATUS_LIST_REQUEST = 'STATUS_LIST_REQUEST';
export const STATUS_LIST_REQUEST_SUCCESS = 'STATUS_LIST_REQUEST_SUCCESS';
export const STATUS_LIST_REQUEST_FAILURE = 'STATUS_LIST_REQUEST_FAILURE';

// session
export const APPEAL_HISTORY_REQUEST = 'APPEAL_HISTORY_REQUEST';
export const APPEAL_HISTORY_REQUEST_SUCCESS = 'APPEAL_HISTORY_REQUEST_SUCCESS';
export const APPEAL_HISTORY_REQUEST_FAILURE = 'APPEAL_HISTORY_REQUEST_FAILURE';

export const APPEAL_HISTORY_FILTER_UPDATE = 'APPEAL_HISTORY_FILTER_UPDATE';
export const APPEAL_HISTORY_SEARCH_QUERY_UPDATE = 'APPEAL_HISTORY_SEARCH_QUERY_UPDATE';

export const APPEAL_HISTORY_RESET = 'APPEAL_HISTORY_RESET';

// settings
export const UPDATE_SETTINGS_BREADCRUMBS = 'UPDATE_SETTINGS_BREADCRUMBS';
export const SAVE_DOMAIN_RULES_INITIAL_FORM_OPTIONS = 'SAVE_DOMAIN_RULES_INITIAL_FORM_OPTIONS';
export const UPDATE_DOMAIN_RULES_ATTRIBUTE_OPTIONS = 'UPDATE_DOMAIN_RULES_ATTRIBUTE_OPTIONS';
export const UPDATE_RULE_ACCESS = 'UPDATE_RULE_ACCESS';
export const UPDATE_GROUP_DOMAIN_OPTIONS = 'UPDATE_GROUP_DOMAIN_OPTIONS';
export const SAVE_BREADCRUMB_PARENT_NAME = 'SAVE_BREADCRUMB_PARENT_NAME';
export const SAVE_BREADCRUMB_CHILD_NAME = 'SAVE_BREADCRUMB_CHILD_NAME';
export const UPDATE_BUISNESS_UNITS = 'UPDATE_BUISNESS_UNITS';
export const UPDATE_BUISNESS_UNIT_USER_SEARCH = 'UPDATE_BUISNESS_UNIT_USER_SEARCH';
export const UPDATE_BUISNESS_UNIT_SPACE_SEARCH = 'UPDATE_BUISNESS_UNIT_SPACE_SEARCH';
export const UPDATE_RESTRICTION_TYPES = 'UPDATE_RESTRICTION_TYPES';
export const UPDATE_RESTRICTION = 'UPDATE_RESTRICTION';

// searchFilter
export const SEARCH_FILTERS_REQUEST = 'SEARCH_FILTERS_REQUEST';
export const SEARCH_FILTERS_REQUEST_SUCCESS = 'SEARCH_FILTERS_REQUEST_SUCCESS';
export const SEARCH_FILTERS_REQUEST_FAILURE = 'SEARCH_FILTERS_REQUEST_FAILURE';

export const FILTER_FIELDS_REQUEST = 'FILTER_FIELDS_REQUEST';
export const FILTER_FIELDS_REQUEST_SUCCESS = 'FILTER_FIELDS_REQUEST_SUCCESS';
export const FILTER_FIELDS_REQUEST_FAILURE = 'FILTER_FIELDS_REQUEST_FAILURE';

export const ALL_FILTER_FIELDS_REQUEST = 'ALL_FILTER_FIELDS_REQUEST';
export const ALL_FILTER_FIELDS_REQUEST_SUCCESS = 'ALL_FILTER_FIELDS_REQUEST_SUCCESS';
export const ALL_FILTER_FIELDS_REQUEST_FAILURE = 'ALL_FILTER_FIELDS_REQUEST_FAILURE';

export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const SET_CURRENT_SEARCH_FILTER = 'SET_CURRENT_SEARCH_FILTER';
export const SET_SEARCH_FILTER_CRITERIA = 'SET_SEARCH_FILTER_CRITERIA';

export const APPEAL_TYPES_REQUEST = 'APPEAL_TYPES_REQUEST';
export const APPEAL_TYPES_REQUEST_SUCCESS = 'APPEAL_TYPES_REQUEST_SUCCESS';
export const APPEAL_TYPES_REQUEST_FAILURE = 'APPEAL_TYPES_REQUEST_FAILURE';

export const SEARCH_FILTERS_DYNAMIC_STATE_RESET = 'SEARCH_FILTERS_DYNAMIC_STATE_RESET';

// search
export const SEARCH_RESULTS_REQUEST = 'SEARCH_RESULTS_REQUEST';
export const SEARCH_RESULTS_REQUEST_SUCCESS = 'SEARCH_RESULTS_REQUEST_SUCCESS';
export const SEARCH_RESULTS_REQUEST_FAILURE = 'SEARCH_RESULTS_REQUEST_FAILURE';
export const SEARCH_QUERY_UPDATE = 'SEARCH_QUERY_UPDATE';
export const SEARCH_RESULTS_POPUP_OPEN = 'SEARCH_RESULTS_POPUP_OPEN';
export const SEARCH_RESULTS_POPUP_CLOSE = 'SEARCH_RESULTS_POPUP_CLOSE';
export const SEARCH_TAB_HASH_UPDATE = 'SEARCH_TAB_HASH_UPDATE';
export const SEARCH_PAGINATION_UPDATE = 'SEARCH_PAGINATION_UPDATE';
export const CLEAR_SEARCH_AND_SEARCH_FILTER = 'CLEAR_SEARCH_AND_SEARCH_FILTER';

// dashboard
export const DASHBOARD_FILTERS_REQUEST = 'DASHBOARD_FILTERS_REQUEST';
export const DASHBOARD_FILTERS_REQUEST_SUCCESS = 'DASHBOARD_FILTERS_REQUEST_SUCCESS';
export const DASHBOARD_FILTERS_REQUEST_FAILURE = 'DASHBOARD_FILTERS_REQUEST_FAILURE';

export const DASHBOARD_FILTER_OBJECT_UPDATE = 'DASHBOARD_FILTER_OBJECT_UPDATE';

export const DASHBOARD_APPEAL_TYPES_REQUEST = 'DASHBOARD_APPEAL_TYPES_REQUEST';
export const DASHBOARD_APPEAL_TYPES_REQUEST_SUCCESS = 'DASHBOARD_APPEAL_TYPES_REQUEST_SUCCESS';
export const DASHBOARD_APPEAL_TYPES_REQUEST_FAILURE = 'DASHBOARD_APPEAL_TYPES_REQUEST_FAILURE';

// dashboard2
export const DYNAMIC_DASHBOARD_CHART_REQUEST_SUCCESS = 'DYNAMIC_DASHBOARD_CHART_REQUEST_SUCCESS';
export const DYNAMIC_DASHBOARD_REQUEST = 'DYNAMIC_DASHBOARD_REQUEST';
export const DYNAMIC_DASHBOARD_REQUEST_SUCCESS = 'DYNAMIC_DASHBOARD_REQUEST_SUCCESS';
export const DYNAMIC_DASHBOARD_VIEW_LIST_REQUEST_SUCCESS = 'DYNAMIC_DASHBOARD_VIEW_LIST_REQUEST_SUCCESS';
export const DYNAMIC_DASHBOARD_SET_VIEW = 'DYNAMIC_DASHBOARD_SET_VIEW';
export const DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST = 'DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST';
export const DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_FAILURE = 'DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_FAILURE';
export const DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_SUCCESS = 'DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_SUCCESS';
export const DYNAMIC_DASHBOARD_PERIOD_PRESET_UPDATE = 'DYNAMIC_DASHBOARD_PERIOD_PRESET_UPDATE';
export const DYNAMIC_DASHBOARD_PERIOD_UPDATE_BETWEEN = 'DYNAMIC_DASHBOARD_PERIOD_UPDATE_BETWEEN';
export const DYNAMIC_DASHBOARD_FILTERS_REQUEST = 'DYNAMIC_DASHBOARD_FILTERS_REQUEST';
export const DYNAMIC_DASHBOARD_FILTERS_CLEAR = 'DYNAMIC_DASHBOARD_FILTERS_CLEAR';
export const DYNAMIC_DASHBOARD_FILTERS_REQUEST_SUCCESS = 'DYNAMIC_DASHBOARD_FILTERS_REQUEST_SUCCESS';
export const DYNAMIC_DASHBOARD_FILTERS_REQUEST_FAILURE = 'DYNAMIC_DASHBOARD_FILTERS_REQUEST_FAILURE';
export const DYNAMIC_DASHBOARD_REQUEST_FAILURE = 'DYNAMIC_DASHBOARD_REQUEST_FAILURE';

export const DYNAMIC_DASHBOARD_FILTER_OBJECT_UPDATE = 'DYNAMIC_DASHBOARD_FILTER_OBJECT_UPDATE';

