import React, {useState} from 'react';
import {connect} from "react-redux";
import {setSignature, changeEditSignMode} from "../../../actions/emails";
import Popup from "../../../components/Popup";
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = {
	setSignature, changeEditSignMode
};

function SignatureListActions(props) {
	const { signatureList, isOpen, onClickOutside, t, setSignature, appealId, changeEditSignMode } = props;
	const setupOption = { active: false, label: t('signature.edit'), callback: onOpenEditMode, value: null, id: null };
	const conf = [...signatureList.map( item => ({ ...item, callback: onSelectSignature }) ), setupOption ];
	
	function onOptionClick (e, callback, options){
		e.preventDefault();
		callback(options);
	}
	
	function onSelectSignature (options){
		setSignature(appealId, options.id);
	}
	
	function onOpenEditMode (){
		changeEditSignMode();
		onClickOutside();
	}
	
	
	function renderOption ({ label, active, callback, ...rest }, index) {
		return(
			<div
				className={cx('popup-link', {active})}
				key={index.toString()}
				onClick={(e) => onOptionClick(e, callback, rest)}
				title={label}
			>
				{label}
			</div>
		)
	}
	
	if (!isOpen) return null;
	
	return (
		<Popup place='signature-popup grid-popup' onClickOutside={props.onClickOutside}>
			{conf.map(renderOption)}
		</Popup>
	);
	
}

export default connect(null,mapDispatchToProps)(withTranslation()(SignatureListActions));
