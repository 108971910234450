import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/searchPage.module.scss';
import { safeQuery } from 'helpers';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { destroy } from 'redux-form';
import { KeyCodes } from 'constants/index';
import { updateSearchQuery } from 'actions/search';
import { setSearchFilterCriteria } from 'actions/searchFilter';

const mapStateToProps = (state, props) => ({
    // inputValue: state.search.searches[props.id],
    isAddingActive: state.tabs.isAddingActive,
});

const mapDispatchToProps = {
    updateSearchQuery,
    setSearchFilterCriteria,
    // destroy: (id, type) => destroy(`search-filters-${id}-${type}`),
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.isSearchableInputValue = this.isSearchableInputValue.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    state = {
        inputValue: this.props.query,
    };

    // componentDidUpdate(prevProps) {
    //     if (prevProps.query !== this.props.query) {
    //         this.setState({inputValue: this.props.query});
    //     }
    // }

    isSearchableInputValue() {
        const { inputValue } = this.state;
        return inputValue ? safeQuery(inputValue.trim()) : safeQuery('');
    }

    async onSearch(event) {
        const { match } = this.props;
        const value = this.isSearchableInputValue();
        if (event.keyCode === KeyCodes.ENTER && (value || value === '')) {
            this.props.updateSearchQuery(value, this.props.id);
            if (this.props.isAddingActive) {
                this.props.onSubmit({ query: value, page: 1, skipFilter: false });
                this.props.setSearchFilterCriteria([], `${match.params.hash}-${match.params.hash}`);
                // this.props.destroy(match.params.hash, match.params.entity);
                const url = `/search/${match.params.entity || 'customer'}/${match.params.hash}`;
                this.props.history.push(url);
            }
        }
    }

    handleChange(event) {
        const query = event.target.value;
        this.props.onInputChange(query);
        this.setState({
            inputValue: query,
        });
        // this.props.updateSearchQuery(query, this.props.id);
    }

    render() {
        const { t } = this.props;

        return (
            <input
                type="text"
                className={cx(styles.searchInput)}
                placeholder={t('Search')}
                onChange={this.handleChange}
                onKeyDown={this.onSearch}
                maxLength="255"
                autoFocus
                value={this.state.inputValue}
            />
        );
    }
}

SearchInput.propTypes = {
    onSubmit: PropTypes.func,
};

export default SearchInput;
