import React from 'react';
import './style.scss';

const FixedStatus = props => {
    const { t } = props;
    const { data: { status: mainStatus, taskStateCode: customerStatus, statusCode: servicesStatus } } = props;


    let status = mainStatus || customerStatus || servicesStatus;
    let { statusName } = props;
    if (typeof status === 'object') {
        statusName = status.name;
        status = status.code;
    } 

    const objectFull = statusName ? 'object-full' : '';

    return (
        <div className={`grid-fixed-status-component object-full ${status.toLowerCase()} ${objectFull}`} title={statusName || t(`order_status.${status.toLowerCase()}`)} />
    );
};

export default FixedStatus;
