import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Breadcrumbs extends Component {
	
	getItemStyle = item => {
		const classNameArray = item.className.split(' ');
		return classNameArray.map(className =>className).join(' ');
	};
	
	renderLink = (item, index) => (
		<div
			key={index}
			onClick={item.onClick}
			className={this.getItemStyle(item)}
		>
			{item.element}
		</div>
	);
	
	render () {
		const links = this.props.data;
		
		return (
			<div className='breadcrumbs'>
				{links.map(this.renderLink)}
			</div>
		);
	}
}

Breadcrumbs.propTypes = {
	data: PropTypes.array
};

export default Breadcrumbs;
