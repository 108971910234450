import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactSVG from 'react-svg';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import { withTranslation } from 'react-i18next';

import { getFirstLetters } from 'helpers';
import Popup from 'components/Popup';
import ClickOutsideHolder from 'components/ClickOutsideHolder';

@withTranslation()
class CommentItem extends React.Component {
	state = {
		popUpOpen: false,
	};
	
	openPopUp = () => {
		if (!this.state.popUpOpen) {
			this.setState({ popUpOpen: true });
		}
	};
	
	closePopUp = async () => {
		setTimeout(() => {
			this.setState({ popUpOpen: false });
		}, 200)
	};
	
	editComment = () => {
		this.props.handleEditComment(this.props.id);
		this.closePopUp();
	};
	
	render () {
		const {
			author, dateTime, text, attachments, t, id, editable_DATE, published_label, published,
			publish_DATE, editable,
		} = this.props;
		const { popUpOpen } = this.state;
		const timeFormat = 'DD.MM.YYYY HH:mm';
		const name = author && author.name;
		
		return (
			<div className='comment'>
				<div className='comment-header'>
					<div className='comment-title'>
						<div className='avatarWrapper'>
							{ this.props.image
								? <img src={this.props.image} alt='comment-avatar' />
								: getFirstLetters(name)
							}
						</div>
						<div className='author-date-wrapper'>
							<div className='comment-author'>{name}</div>
							{
								published && (
									<div
										className={cn('comment-public-status', { 'no-text': !published_label })}
										title={moment(publish_DATE).format(timeFormat)}
									>
										<ReactSVG className='check-circle' src='/data/svg/check-circle.svg' />
										{published_label}
									</div>
								)
							}
							<div className='comment-buttons'>
								{ editable && <i className='icon-kebab-hor' onClick={this.openPopUp} /> }
								{
									popUpOpen && (
										<ClickOutsideHolder onClickOutside={this.closePopUp}>
											<Popup place='grid-popup comment-popup'>
												<button onClick={this.editComment} className='popup-link'>{t('Edit')}</button>
											</Popup>
										</ClickOutsideHolder>
									)
								}
							</div>
						</div>
					</div>
				</div>
				<div className='mailText'>
					<ReactMarkdown source={text} />
				</div>
				<div className='comment-date'>
					{moment(dateTime).format(timeFormat)} {editable_DATE ? t('comments.edited', { edited: moment(editable_DATE).format(timeFormat) }) : null}
				</div>
				{attachments && attachments.length ? (
					<div>
						<hr className='fileSeparator' />
						<div className='filesWrapper'>
							<div className='filesList'>
								{attachments.map(({ name, id }) => (
									// <div className='fileLinkWrapper' key={id}>
									// 	<a target="_blank" href={`../mw/file?fileId=${id}&download=0`} className='fileLink'>{name}</a>
									// 	<a target="_blank" href={`../mw/file?fileId=${id}&download=1`} className='fileLink'>
									// 		<ReactSVG className="svg-download" src="/data/svg/download.svg"/></a>
									// </div>
									<div className="kb-file-wrapper" key={id}>
										<a className="file-preview" target="_blank" href={`../mw/file?fileId=${id}&download=0`} title={name}>
										{name}
										</a>
										<div className="kb-file-actions">
											<a className="file-download" target="_blank" href={`../mw/file?fileId=${id}&download=1`} title={name}>
												<ReactSVG className="svg-download" src="/data/svg/download.svg"/>
											</a>
										</div>
									</div>
								))}
							</div>
							<div className='filesButtons'>
								<a href={`../mw/appeal/file/getFiles?typeCode=COMMENT&id=${id}`}
								   className='filesButton'>{t('comments.uploadAll')}</a>
							</div>
						</div>
					</div>
				) : null}
			
			</div>
		);
	}
}

CommentItem.propTypes = {
	image: PropTypes.string,
	author: PropTypes.object,
	date: PropTypes.number,
	text: PropTypes.string,
	attachments: PropTypes.array
};

export default CommentItem;
