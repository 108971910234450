import React, { Fragment } from 'react';
import cx from 'classnames';
import ComboBox from 'components/Common/ComboBox';
import { appealHistoryFilters, HistoryItemTypes } from 'constants/index';

import { withRouter } from 'react-router-dom';
import ChangeItem from './ChangeItem';
import EmailItem from './EmailItem';
import MessageItem from './MessageItem';
import NotificationItem from './NotificationItem';
import moment from 'moment/moment';
import CommentItem from './CommentItem';
import debounce from 'lodash/debounce';
import Loader from 'components/Loader';
import Highlight from 'react-highlighter';
import { withTranslation } from 'react-i18next';
import Avatar from 'components/Avatar';
import CallItem from './CallItem';
import SelfCareItem from './SelfCareItem';
import DefaultItem from './DefaultItem';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { connect } from 'react-redux';
import * as sessionSelectors from 'reducers/session';
import {
	getAppealHistory,
	updateAppealHistoryFilter,
	updateSearchQuery,
	resetAppealHistory
} from 'actions/session';

const mapStateToProps = state => ({
	historyGroups: sessionSelectors.getHistoryGroups(state),
	query: sessionSelectors.getSearchQuery(state),
	filter: sessionSelectors.getFilter(state),
	highlightedText: sessionSelectors.getHighlightedText(state),
	isLoading: sessionSelectors.isHistoryLoading(state),
	requestedForAppeal: sessionSelectors.getRequestedForAppeal(state)
});

const mapDispatchToProps = dispatch => ({
	getAppealHistory: (requestData, appealId) => dispatch(getAppealHistory(requestData, appealId)),
	updateAppealHistoryFilter: newFilter => dispatch(updateAppealHistoryFilter(newFilter)),
	updateSearchQuery: newQuery => dispatch(updateSearchQuery(newQuery)),
	resetAppealHistory: () => dispatch(resetAppealHistory())
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AppealHistory extends React.Component {
	
	componentDidMount () {
		this.props.resetAppealHistory();
		this.getAppealHistory();
	}
	
	componentDidUpdate (prevProps) {
		if (this.props.match.params.appealId !== prevProps.match.params.appealId) {
			this.props.resetAppealHistory();
			this.getAppealHistory();
		}
	}
	
	getAppealHistory = (filter = 0, query = '') => {
		const appealId = this.props.match.params.appealId;
		const requestData = { appealId, filter, query };
		
		return this.props.getAppealHistory(requestData, appealId);
	};
	
	getFilterItem = () => appealHistoryFilters.find(item => item.value === this.props.filter);
	
	onFilterChange = filter => {
		if (filter){
			this.getAppealHistory(filter.value, this.props.query);
			this.props.updateAppealHistoryFilter(filter.value);
		}
	};
	
	makeSearchRequest = debounce(query => this.getAppealHistory(this.props.filter, query), SEARCH_TIMER_INTERVAL);
	
	onSearchChange = event => {
		const query = event.target.value;
		this.makeSearchRequest(query);
		this.props.updateSearchQuery(query);
	};
	
	highlight = text => (
		<Highlight search={this.props.highlightedText} matchClass='highlight' matchElement={'span'}>
			{text}
		</Highlight>
	);
	
	getHistoryItemComponent = type => {
		const componentMap = {
			[HistoryItemTypes.CHANGE]: ChangeItem,
			[HistoryItemTypes.EMAIL]: EmailItem,
			[HistoryItemTypes.MESSAGE]: MessageItem,
			[HistoryItemTypes.NOTIFICATION]: NotificationItem,
			[HistoryItemTypes.COMMENT]: CommentItem,
			[HistoryItemTypes.CALL]: CallItem,
			[HistoryItemTypes.PRECRM]: CallItem,
			[HistoryItemTypes.SELF_CARE]: SelfCareItem
		};
		
		return componentMap[type] || DefaultItem;
	};
	
	renderHistoryItem = ({ type, orderDate, item }, index) => {
		const ItemComponent = this.getHistoryItemComponent(type);
		if (ItemComponent) {
			return <ItemComponent orderDate={orderDate} data={item} key={index} highlight={this.highlight} />;
		} else {
			throw new Error(`Unknown type of appeal history item. Type: [${type}]`);
		}
	};
	
	getAuthorName = items => {
		const { t } = this.props;
		const historyItem = items.find(item => item.type === HistoryItemTypes.CHANGE || item.type === HistoryItemTypes.COMMENT);
		return historyItem && historyItem.item.author.name || t('systemMessage');
	};
	
	renderHistoryGroup = ([orderDate, items]) => {
		const { t } = this.props;
		const formattedDate = moment(parseInt(orderDate)).format('DD.MM.YYYY в HH:mm:ss');
		
		return (
			<div className='history-group' key={orderDate}>
				<div className='author-avatar-column'>
					{(this.getAuthorName(items) !== t('systemMessage')) ?
						<Avatar name={this.getAuthorName(items)} /> :
						<div className='system-author-avatar'>
							<i className='icon-logo' />
						</div>
					}
				</div>
				
				<div className='history-group-content'>
					<header className='groupHeader'>
						<span className='author-name'>{this.highlight(this.getAuthorName(items))}</span>
						<span className='history-date'>{formattedDate}</span>
					</header>
					
					<ul className='history-item-list'>
						{items.map(this.renderHistoryItem)}
					</ul>
				</div>
			</div>
		);
	};
	
	render () {
		const { t, isLoading, query, historyGroups } = this.props;
		return (
			<Fragment>
				<div className={cx('tabHeader', 'history')}>
					<ComboBox
						options={appealHistoryFilters}
						value={this.getFilterItem()}
						onChange={this.onFilterChange}
					/>
					<div className='searchInputWrapper tab-search-wrapper'>
						<input
							type='text'
							className='searchInput'
							placeholder={t('Search')}
							onChange={this.onSearchChange}
							value={query}
							autoFocus
						/>
						<i className='icon-search2' />
					</div>
				</div>
				{
					isLoading
						? <Loader withContainer={true} />
						: (
							<div className='scrollbox'>
								<div className='scrollbox-content'>
									<div className='historyContent'>
										{Object.entries(historyGroups).map(this.renderHistoryGroup)}
									</div>
								</div>
							</div>
						)
				}
			</Fragment>
		);
	}
}

export default AppealHistory;
