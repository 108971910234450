import React, { useState, useEffect, useMemo } from 'react';
import {
    getFormValues,
    getFormSyncErrors,
    isPristine,
    change,
    isDirty
} from 'redux-form';
import cx from 'classnames';
import 'pages/Appeal/styles.scss';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BaseService from '../../../services/BaseService';
import DynamicForm from '../../../pages/Ordering/common/DynamicForm';
import { setHistoryPushRequireSaveWithoutChangeConfirm } from "actions/ui";

import { GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';


// import FileInput from '../../../pages/Ordering/common/FileInput';

import { isEmpty, get } from 'lodash';

const SELECT_TYPES = ['list', 'attrList'];

const AJAX_TYPES = ['siteAdress'];

const INPUT_TYPES = ['manual'];

const INPUT_TYPE_MAP = {
    integer: 'number',
    string: 'text',
};

function getWidgetDataByPrimaryField(primaryField, type, fieldName) {
    if (primaryField) {
        if (type === 'condition') {
            let values = primaryField.condition.map((item) => ({
                key: item.key,
                value: item.label,
            }));

            let isValuesOk = true;

            if (values.length === 0) {
                isValuesOk = false;
            }
            return {
                values,
                isReq: isValuesOk ? primaryField.require : true,
                widgetType: 'select',
            };
        }
        if (type === 'saveValue') {
            if (SELECT_TYPES.includes(primaryField.dataClass)) {
                return {
                    values: primaryField.values,
                    widgetType: 'select',
                };
            }
            if (INPUT_TYPES.includes(primaryField.dataClass)) {
                return {
                    isReq: primaryField.require,
                    inputType: INPUT_TYPE_MAP[primaryField.dataType],
                    widgetType: 'input',
                };
            }
            if (AJAX_TYPES.includes(primaryField.dataClass)) {
                const customAjaxOptions = (value, cb, key, values) => {
                    let data = {};
                    if (fieldName === 'cityFilter') {
                        data = {
                            filter: [
                                {
                                    query: value,
                                    field: 'city',
                                },
                            ],
                            type: 'city',
                        };
                    } else {
                        // console.log({ key, values });
                        const parsedKey = key.split('.')[0];
                        const arrayValue = get(values, parsedKey);
                        let city = '';
                        if (arrayValue) {
                            city = get(arrayValue, 'city.label');
                        }
                        data = {
                            filter: [
                                {
                                    query: city,
                                    field: 'city',
                                },
                                {
                                    query: value,
                                    field: 'site',
                                },
                            ],
                            type: 'site',
                        };
                    }
                    BaseService.post('object_search', { data, jsonType: true })
                        .then((response) => {
                            cb(
                                response.result.map((i) => ({
                                    label: i.objectName,
                                    value: i.id,
                                })),
                            );
                        })
                        .catch(console.error);
                };
                return {
                    customAjaxOptions,
                    widgetType: 'ajaxCombo',
                };
            }
        }
    }
    return {
        disabled: true,
        values: [],
        widgetType: 'select',
    };
}

function IssueForm(props) {
    const {
        id,
        submitting,
        submitForm,
        issueTypes,
        isLoading,
        disabled,
        initialValues,
        formValues,
        formErrors,
        formDynErrors,
        staticFormKey,
        dynamicFormKey,
        isStaticFormPristine,
        isDynFormDirtyPristine,
        issueId,
        issue,
        requestIssue,
        isStaticFormDirty, 
        isDynFormDirtyDirty,
        setHistoryPushRequireSaveWithoutChangeConfirm
    } = props;

    const [issuesForm, setIssuesForm] = useState(null);
    const plan_start_date = _.get(formValues, 'plan_start_date');
    const plan_end_date = _.get(formValues, 'plan_end_date');
    const { t } = useTranslation();

    const isSaveButtonEditDisabled = useMemo(() => {
        if (id) {
            const isDisabled = isStaticFormPristine && isDynFormDirtyPristine;
            return isDisabled;
        }
        return false;
    }, [id, isStaticFormPristine, isDynFormDirtyPristine]);

    const maxFileSize = useMemo(() => GET_MAX_FILE_SIZE_FROM_DICT('issue'), []);

    const initIssueForm = (t) => [
        {
            view: '3column',
            collapsed: false,
            title: t('notes'),
            key: 'static-issue',
            widgets: [
                {
                    key: 'problem_type',
                    title: t('type'),
                    clearable: true,
                    disabled: id ? true : disabled,
                    values: issueTypes.map((item) => ({ key: item.value, value: item.label })),
                    isReq: true,
                    hasExValue: true,
                    widgetType: 'select',
                },
                {
                    key: 'chanel',
                    title: t('chanel'),
                    clearable: true,
                    disabled,
                    values: [
                        {
                            key: t('issues.interfaceOfSystem'),
                            value: t('issues.interfaceOfSystem'),
                        },
                    ],
                    isReq: true,
                    hasExValue: true,
                    widgetType: 'select',
                },
                {
                    key: 'state_code',
                    title: t('status'),
                    clearable: true,
                    disabled,
                    values: [
                        { value: t('active'), key: 'active' },
                        {
                            value: t('notActive'),
                            key: 'not_active',
                        },
                    ],
                    isReq: true,
                    hasExValue: true,
                    widgetType: 'select',
                },
                {
                    key: 'problem_name',
                    title: t('theme'),
                    clearable: false,
                    disabled,
                    isReq: true,
                    customWidth: 'full-width',
                    withMarkdown: true,
                    maxLength: 2000,
                    // autosize: true,
                    widgetType: 'multiline',
                },
                {
                    key: 'problem_description',
                    title: t('text'),
                    clearable: true,
                    disabled,
                    isReq: false,
                    customWidth: 'full-width',
                    withMarkdown: true,
                    maxLength: 20000,
                    autosize: true,
                    widgetType: 'multiline',
                },
                {
                    key: 'plan_start_date',
                    maxDateKey: 'plan_end_date',
                    title: t('activeFrom'),
                    clearable: true,
                    disabled,
                    isReq: false,
                    customWidth: 'half-width',
                    widgetType: 'datetime',
                },
                {
                    key: 'plan_end_date',
                    minDateKey: 'plan_start_date',
                    title: t('activeTo'),
                    clearable: true,
                    disabled,
                    isReq: false,
                    customWidth: 'half-width',
                    widgetType: 'datetime',
                },
                {
                    key: 'files',
                    title: t('file'),
                    clearable: true,
                    values: issue && issue.files.map(file => ({...file, value: file.fileName || "unnamed file", key: file.id})),
                    savedValue: issue && issue.files.map(file => file.id),
                    isUploadByCondition: !issueId,
                    uploadCondition: issueId,
                    // disabled: !id,
                    // isHidden: !id,
                    // isReq: true,
                    extensions: [".jpg", ".jpeg", ".png", ".bmp"],
                    widgetType: 'fileSingle',
                    onConditionFileUpload: requestIssue,
                },
            ],
        },
    ];

    useEffect(() => {
        if (plan_start_date === '') {
            props.change('plan_start_date', null);
        }
        if (plan_end_date === '') {
            props.change('plan_end_date', null);
        }
    }, [plan_start_date, plan_end_date, props]);

    const problemType = useMemo(
        () => formValues && formValues.problem_type && formValues.problem_type.value,
        [formValues],
    );

    const isDynFormValid = useMemo(() => {
        if (!formDynErrors || isEmpty(formDynErrors)) {
            return true;
        }
        if (formDynErrors) {
            if (Object.keys(formDynErrors).some((key) => !isEmpty(formDynErrors[key]))) {
                return false;
            }
            return true;
        }
    }, [formDynErrors]);

    // map it here to dynamic form structure
    const currentIssueForm = useMemo(() => {
        if (!issuesForm || !problemType) {
            return null;
        }
        const currentData = issuesForm.find((form) => form.value === problemType);
        const dynForm = currentData.objects.map((object) => {
            return {
                view: '3column',
                title: object.label,
                key: object.type,
                isFieldArray: true,
                description: object.tooltip,
                disabled: disabled || object.disabled,
                widgets: [
                    {
                        key: 'attribute',
                        title: t('settingsSection.rulesAccessForm.attribute'),
                        clearable: true,
                        disabled,
                        values: object.fields.map((field) => ({
                            key: field.key,
                            value: field.label,
                        })),
                        isReq: true,
                        hasExValue: true,
                        widgetType: 'select',
                    },
                    (values, index) => {
                        const selectedPrimaryValue =
                            values &&
                            values[object.type] &&
                            values[object.type][index] &&
                            values[object.type][index].attribute &&
                            values[object.type][index].attribute.value;

                        const primaryField = object.fields.find(
                            (field) => field.key === selectedPrimaryValue,
                        );

                        return {
                            key: 'condition',
                            disabled: disabled || !primaryField,
                            title: t('settingsSection.rulesAccessForm.condition'),
                            clearable: true,
                            isReq: primaryField ? primaryField.require : false,
                            hasExValue: true,
                            customWidth:
                                primaryField && primaryField.dataClass === 'siteAdress'
                                    ? 'quarter-width'
                                    : '',
                            resetKey: `${object.type}[${index}].attribute`,

                            ...getWidgetDataByPrimaryField(primaryField, 'condition'),
                        };
                    },
                    // optional site filter field
                    (values, index) => {
                        const selectedPrimaryValue =
                            values &&
                            values[object.type] &&
                            values[object.type][index] &&
                            values[object.type][index].attribute &&
                            values[object.type][index].attribute.value;

                        const primaryField = object.fields.find(
                            (field) => field.key === selectedPrimaryValue,
                        );

                        if (!primaryField || primaryField.dataClass !== 'siteAdress') {
                            return null;
                        }

                        return {
                            key: 'city',
                            disabled: disabled || !primaryField,
                            title: t('city'),
                            clearable: true,
                            isReq: false,
                            hasExValue: true,
                            customWidth: 'quarter-width',
                            resetKey: `${object.type}[${index}].attribute`,
                            ...getWidgetDataByPrimaryField(primaryField, 'saveValue', 'cityFilter'),
                        };
                    },
                    (values, index) => {
                        const selectedPrimaryValue =
                            values &&
                            values[object.type] &&
                            values[object.type][index] &&
                            values[object.type][index].attribute &&
                            values[object.type][index].attribute.value;

                        const primaryField = object.fields.find(
                            (field) => field.key === selectedPrimaryValue,
                        );

                        // handle saveValue param
                        return {
                            key: 'saveValue',
                            disabled: disabled || !primaryField,
                            title: t('settingsSection.rulesAccessForm.value'),
                            clearable: true,
                            isReq: primaryField ? primaryField.require : false,
                            hasExValue: true,
                            customWidth:
                                primaryField && primaryField.dataClass === 'siteAdress'
                                    ? 'quarter-width'
                                    : '',
                            resetKey:
                                primaryField && primaryField.dataClass === 'siteAdress'
                                    ? [
                                          `${object.type}[${index}].attribute`,
                                          `${object.type}[${index}].city`,
                                      ]
                                    : `${object.type}[${index}].attribute`,
                            ...getWidgetDataByPrimaryField(primaryField, 'saveValue'),
                        };
                    },
                ],
            };
        });

        return {
            label: currentData.label,
            key: currentData.value,
            public: currentData.public,
            tooltip: currentData.tooltip,
            dynForm: id ? dynForm : dynForm.filter((object) => !object.disabled),
        };
    }, [problemType, issuesForm]);

    const staticInitValues = useMemo(() => {
        if (initialValues && initialValues[0]) {
            const { objects, ...rest } = initialValues[0];
            // if ('files' in rest && Array.isArray(rest.files)) {
            //     rest.files = rest.files.map(file => file.id);
            // }
            return rest;
        }
        return initialValues;
    }, [initialValues]);

    const dynamicInitValues = useMemo(() => {
        if (currentIssueForm) {
            if (initialValues) {
                const { objects } = initialValues[0];
                const filteredObjects = objects.filter((obj) => obj.fields.length > 0);
                const convertedObjectsValues = filteredObjects.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [curr.type]: curr.fields.map(({ attrValue, attribute, condition }) => {
                            // console.log({ attrValue, attribute, condition });
                            let saveValue = attrValue.value;
                            if (attrValue.label !== attrValue.value) {
                                saveValue = {
                                    label: attrValue.label,
                                    value: attrValue.value,
                                };
                            }
                            return {
                                attribute: {
                                    label: attribute.label,
                                    value: attribute.value,
                                },
                                condition: {
                                    label: condition.label,
                                    value: condition.value,
                                },
                                saveValue,
                            };
                        }),
                    };
                }, {});
                return convertedObjectsValues;
            }
            return {};
        }
        return null;
        // return initialValues;
    }, [initialValues, currentIssueForm]);

    function fetchIssueForm() {
        BaseService.get('issues_form', {
            data: { topic: 'CRM', query: '' },
        })
            .then((response) => {
                setIssuesForm(response.result);
            })
            .catch((e) => {
                console.error('IssueForm::fetchIssueForm: Error: ', e);
            });
    }


    useEffect(() => {
        if (isStaticFormDirty || isDynFormDirtyDirty) {
            setHistoryPushRequireSaveWithoutChangeConfirm(true);
        }
    }, [isStaticFormDirty, isDynFormDirtyDirty]);

    useEffect(() => {
        fetchIssueForm();
    }, []);

    const isDynFormReadyToRender = id
        ? currentIssueForm && dynamicInitValues
        : currentIssueForm;

    return (
        <>
            <div className={cx('issue-form', 'ordering-component-ui-core-wrapper')}>
                <DynamicForm
                    t={t}
                    formName={staticFormKey}
                    forceInitialValues={staticInitValues}
                    dynData={initIssueForm(t)}
                    objectId={issueId}
                    objectType="ISSUE"
                    maxFileSize={maxFileSize}
                    withoutLiveSaver
                />
                {isDynFormReadyToRender && (
                    <div className="curent-issue-form">
                        <DynamicForm
                            t={t}
                            formName={dynamicFormKey}
                            forceInitialValues={dynamicInitValues}
                            dynData={currentIssueForm.dynForm}
                            maxFileSize={maxFileSize}
                            uploadFileSource="form"
                            withoutLiveSaver
                            key={currentIssueForm.key}
                        />
                    </div>
                )}
            </div>
            <div className="issue-submit-button-block">
                <button
                    className={cx('btn btn-primary', {
                        'btn-disabled':
                            disabled ||
                            isLoading ||
                            isSaveButtonEditDisabled ||
                            submitting ||
                            !isEmpty(formErrors) ||
                            !isDynFormValid,
                    })}
                    type="submit"
                    onClick={submitForm}
                >
                    {t('save')}
                </button>
            </div>
        </>
    );
}
const mapStateToProps = (state, props) => ({
    formValues: getFormValues(props.staticFormKey)(state),
    formErrors: getFormSyncErrors(props.staticFormKey)(state),
    isStaticFormPristine: isPristine(props.staticFormKey)(state),
    formDynValues: getFormValues(props.dynamicFormKey)(state),
    formDynErrors: getFormSyncErrors(props.dynamicFormKey)(state),
    isDynFormDirtyPristine: isPristine(props.dynamicFormKey)(state),
    isStaticFormDirty: isDirty(props.staticFormKey)(state),
    isDynFormDirtyDirty: isDirty(props.dynamicFormKey)(state),
});

export default connect(mapStateToProps, { change, setHistoryPushRequireSaveWithoutChangeConfirm })(IssueForm);
