import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

@withTranslation()
class FolderNode extends React.PureComponent {
	
	constructor (props) {
		super(props);
		
		this.onClick = this.onClick.bind(this);
		this.onCreateNewFolder = this.onCreateNewFolder.bind(this);
	}
	
	onClick (event) {
		event.stopPropagation();
		this.props.onClick(this.props.node);
	}
	
	onCreateNewFolder (event) {
		event.stopPropagation();
		this.props.onCreateNew(this.props.node);
	}
	
	render () {
		const { t, node, childrenField, renderNode, labelField, leafField, hideLeafs, onCreateNew } = this.props;
		
		let children = Array.isArray(node[childrenField]) ? node[childrenField] : [];
		
		if (hideLeafs) {
			children = children.filter(node => !node[leafField]);
		}
		
		const haveChildren = children.length > 0;
		const showCreateButton = node.selected && onCreateNew;
		
		const labelClassName = cx({
			[styles.selected]: node.selected
		});
		
		return (
			<div className={cx(styles.node, styles.folder)}>
				<div className={styles.title} onClick={this.onClick}>
					<i className='icon-folder' />
					<label className={labelClassName}>
						{node[labelField]}
					</label>
				</div>
				{
					node.open && haveChildren &&
					<div className={styles.folderChildren}>
						{node[childrenField].map(renderNode)}
					</div>
				}
				{
					showCreateButton &&
					<button className={styles.createButton} onClick={this.onCreateNewFolder}>
						{t('fileExplorer.createNewFolderLink')}
					</button>
				}
			</div>
		);
	}
}

FolderNode.propTypes = {
	hideLeafs: false,
};

FolderNode.propTypes = {
	open: PropTypes.bool,
	node: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	renderNode: PropTypes.func.isRequired,
	hideLeafs: PropTypes.bool,
	onCreateNew: PropTypes.func,
};

export default FolderNode;
