import {
  CLIENT_LIVESEARCH_GET_SUPPOSE,
  CLEAR_CLIENT_LIVESEARCH,
  CLIENT_GET_SUPPOSE,
  CLIENT_GET_SUPPOSE_SUCCESS,
  CLIENT_GET_SUPPOSE_FAILURE,
  CLEAR_CLIENT_GET_SUPPOSE,
  OPEN_ADVANCED_SEARCH_MODAL,
  CLOSE_ADVANCED_SEARCH_MODAL
} from 'constants/actions';
import baseService from '../services/BaseService';


export const getLiveSearchClientSuppose = query => ({
  type: CLIENT_LIVESEARCH_GET_SUPPOSE,
  query
});

export const clearLiveSearchClient = () => ({
  type: CLEAR_CLIENT_LIVESEARCH
});

const getClientSuppose = () => ({
  type: CLIENT_GET_SUPPOSE
});

const getClientSupposeSuccess = (clients, total) => ({
  type: CLIENT_GET_SUPPOSE_SUCCESS,
  clients,
  total
});

const getClientSupposeFailure = () => ({
  type: CLIENT_GET_SUPPOSE_FAILURE
});

export const clearClientGetSuppose = () => ({
  type: CLEAR_CLIENT_GET_SUPPOSE
});

export const getClientsSuppose = requestData => dispatch => {
  dispatch(getClientSuppose());

  return baseService.post('search', {data: requestData})
    .then(response => {
      if (response.success) {
        dispatch(getClientSupposeSuccess(response.result, response.total));
      } else {
        throw new Error('Applicants search was failed');
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(getClientSupposeFailure());
    });
};

export const getSearchResults = requestData => dispatch => {
	dispatch(getClientSuppose());
	
	const params = { data: requestData };
	const url = 'filter_apply';
		
		return baseService.post(url, params)
			.then(response => {
                if (response.success && Array.isArray(response.result)) {
                    dispatch(getClientSupposeSuccess(response.result, response.total));
				} else {
					throw new Error('Search results error');
				}
			})
			.catch(error => {
				console.error(error);
				dispatch(getClientSupposeFailure());
			});
};

export const openAdvancedSearchModal = (callback) => ({
  type: OPEN_ADVANCED_SEARCH_MODAL, callback
});

export const closeAdvancedSearchModal = () => ({
  type: CLOSE_ADVANCED_SEARCH_MODAL
});
