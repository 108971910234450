import {
	CLOSE_CREATE_NEW_CUSTOMER_MODAL,
	OPEN_ASIDE_TOP_MENU,
	OPEN_CREATE_NEW_CUSTOMER_MODAL,
	UI_TOGGLE_SIDE_MENU_ADDITIONS,
	UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON,
	SHOW_TABS_CLOSE_MENU,
	SHOW_TABS_HIDDEN_MENU,
	TOGGLE_LANG_MENU,
	UI_MENU_CREATE_SHOW,
	UI_MENU_CREATE_HIDE,
	OPEN_MESSAGE_MODAL,
	CLOSE_MESSAGE_MODAL,
	CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL,
	OPEN_CREATE_NEW_CONTACT_PERSON_MODAL,
	OPEN_CUSTOMER_ADDRESS_MODAL,
	CLOSE_CUSTOMER_ADDRESS_MODAL,
	OPEN_CUSTOMER_SERVICE_MODAL,
	CLOSE_CUSTOMER_SERVICE_MODAL,
	OPEN_CUSTOMER_RESOURCE_MODAL,
	CLOSE_CUSTOMER_RESOURCE_MODAL,
	SHOW_NOTIFICATION,
	HIDE_NOTIFICATION,
	SHOW_NOTIFICATION_PANEL,
	HIDE_NOTIFICATION_PANEL,
	ADDRESS_SAVE,
	OPEN_USER_POPUP,
	CLOSE_USER_POPUP,
	OPEN_CREATE_NEW_ORDER_MODAL,
	CLOSE_CREATE_NEW_ORDER_MODAL,
	OPEN_CREATE_APPEAL_FROM_FILE_MODAL,
	CLOSE_CREATE_APPEAL_FROM_FILE_MODAL,
	OPEN_APPEAL_LINK_MODAL,
	CLOSE_APPEAL_LINK_MODAL,
	OPEN_OBJECTS_LINK_MODAL,
	CLOSE_OBJECTS_LINK_MODAL,

	SET_IS_HISTORY_PUSH_REQUIRE_SAVE_WITHOUT_CHANGE_CONFIRM,

	OPEN_SEARCH_EMAIL_MODAL,
	CLOSE_SEARCH_EMAIL_MODAL,
	SET_SEARCH_MODAL_EMAIL_BINDERS,
	CHECK_SEARCH_MODAL_EMAIL_BINDER,
	UNCHECK_SEARCH_MODAL_EMAIL_BINDER,
	CLEAR_SEARCH_MODAL_EMAIL_BINDERS
} from 'constants/actions';

export const showNotification = ({ type, options, id }) => ({
	type: SHOW_NOTIFICATION,
	payload: { type, options },
	id,
});

export const hideNotification = (id) => ({
	type: HIDE_NOTIFICATION,
	payload: { id },
});

export const openUserPopup = () => ({
	type: OPEN_USER_POPUP,
});

export const closeUserPopup = () => ({
	type: CLOSE_USER_POPUP,
});

export const showNotificationPanel = () => ({
	type: SHOW_NOTIFICATION_PANEL,
});

export const hideNotificationPanel = () => ({
	type: HIDE_NOTIFICATION_PANEL,
});

export const showCreateMenu = () => ({
	type: UI_MENU_CREATE_SHOW,
});

export const hideCreateMenu = () => ({
	type: UI_MENU_CREATE_HIDE,
});

export const toggleHiddenTabs = () => ({
	type: SHOW_TABS_HIDDEN_MENU,
});

export const showMenu = ({ id }) => ({
	type: SHOW_TABS_CLOSE_MENU,
	payload: { id },
});

export const toggleLangMenu = ({ visible }) => ({
	type: TOGGLE_LANG_MENU,
	payload: { visible },
});

export const openAsideSubMenu = (id) => ({
	type: OPEN_ASIDE_TOP_MENU,
	payload: { id },
});

export const toggleSideMenuAdditions = (showState) => ({
	type: UI_TOGGLE_SIDE_MENU_ADDITIONS,
	showState
});

export const toggleSideMenuAdditionsPerson = () => ({
	type: UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON,
});

export const openCreateNewCustomerModal = (context) => ({
	type: OPEN_CREATE_NEW_CUSTOMER_MODAL,
	context,
});

export const closeCreateNewCustomerModal = () => ({
	type: CLOSE_CREATE_NEW_CUSTOMER_MODAL,
});

export const openCreateNewOrderModal = (context) => ({
	type: OPEN_CREATE_NEW_ORDER_MODAL,
	context
});

export const closeCreateNewOrderModal = () => ({
	type: CLOSE_CREATE_NEW_ORDER_MODAL,
});

export const openCreateAppealFromFileModal = (context) => ({
	type: OPEN_CREATE_APPEAL_FROM_FILE_MODAL,
	context
});

export const closeCreateAppealFromFileModal = () => ({
	type: CLOSE_CREATE_APPEAL_FROM_FILE_MODAL,
});

export const openAppealLinkModal = (context) => ({
	type: OPEN_APPEAL_LINK_MODAL,
	context
});

export const openObjectsLinkModal = (context) => ({
	type: OPEN_OBJECTS_LINK_MODAL,
	context
});

export const closeAppealLinkModal = () => ({
	type: CLOSE_APPEAL_LINK_MODAL,
});

export const closeObjectsLinkModal = () => ({
	type: CLOSE_OBJECTS_LINK_MODAL,
});

export const openCreateNewContactPersonModal = (context) => ({
	type: OPEN_CREATE_NEW_CONTACT_PERSON_MODAL,
	context,
});

export const closeCreateNewContactPersonModal = () => ({
	type: CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL,
});

export const openCustomerAddressModal = (address) => ({
	type: OPEN_CUSTOMER_ADDRESS_MODAL,
	payload: { address },
});

export const closeCustomerAddressModal = () => ({
	type: CLOSE_CUSTOMER_ADDRESS_MODAL,
});

export const openCustomerServiceModal = () => ({
	type: OPEN_CUSTOMER_SERVICE_MODAL,
});

export const closeCustomerServiceModal = () => ({
	type: CLOSE_CUSTOMER_SERVICE_MODAL,
});

export const openCustomerResourceModal = () => ({
	type: OPEN_CUSTOMER_RESOURCE_MODAL,
});

export const closeCustomerResourceModal = () => ({
	type: CLOSE_CUSTOMER_RESOURCE_MODAL,
});

export const addressSave = () => ({
	type: ADDRESS_SAVE,
});

export const openMessageModal = () => ({
	type: OPEN_MESSAGE_MODAL,
});

export const closeMessageModal = () => ({
	type: CLOSE_MESSAGE_MODAL,
});

export const setHistoryPushRequireSaveWithoutChangeConfirm = (isHistoryPushRequireSaveWithoutChangeConfirm) => ({
    type: SET_IS_HISTORY_PUSH_REQUIRE_SAVE_WITHOUT_CHANGE_CONFIRM,
    isHistoryPushRequireSaveWithoutChangeConfirm
});

export const openSearchEmailModal = (context) => ({
	type: OPEN_SEARCH_EMAIL_MODAL,
	context
});

export const closeSearchEmailModal = () => ({
	type: CLOSE_SEARCH_EMAIL_MODAL,
});

export function setSearchModalEmailBinders (checkedBinders) {
	return (dispatch) => {
		dispatch({
			type: SET_SEARCH_MODAL_EMAIL_BINDERS,
			checkedBinders,
		});
		// return true;
	};
}

export function checkSearchModalEmailBinder (binder) {
	return (dispatch) => {
		dispatch({
			type: CHECK_SEARCH_MODAL_EMAIL_BINDER,
			binder,
		});
		// return true;
	};
}

export function uncheckSearchModalEmailBinder (binder) {
	return (dispatch) => {
		dispatch({
			type: UNCHECK_SEARCH_MODAL_EMAIL_BINDER,
			binder,
		});
		// return true;
	};
}

export function clearSearchModalEmailBinders () {
	return (dispatch) => {
		dispatch({
			type: CLEAR_SEARCH_MODAL_EMAIL_BINDERS,
		});
		// return true;
	};
}