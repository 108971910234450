const mwPrefix = '../mw';
const snPrefix = '../sn';
const dbrbPrefix = '../dashboard';
const msPrefix = '../ms';
const exportPrefix = '../export';

function apiPath (path, prefix = mwPrefix) {
	return { url: `${prefix}${path}` };
}

function apiPathV2 (path, sn) {
    const prefix = sn ? snPrefix : mwPrefix;
    return {
        url: `${prefix}/v2${path}`
    };
}

module.exports = {
    /**
     *   Prefix path for root
     */
    prefix: '',

    // Core URLs
    /**
     * Core web services urls
     * @property lock
     */
    core: {
        /**
         * lock entity url
         * @property url
         */

        create_calendar_event: apiPathV2('/calendar/event/'),
        
        calendar_event: apiPathV2('/calendar/event/:eid'),
        
		get_calendar_rrules: apiPathV2('/calendar/rrule'),
		
		get_calendar_valarms: apiPathV2('/calendar/valarm'),
		
		get_calendar_attendees: apiPathV2('/calendar/attendees'),
		
        get_calendar_locations: apiPathV2('/calendar/location'),
        
        get_calendar_statuses: apiPathV2('/event/:eid/state'),
		
        get_calendars: apiPathV2('/calendar'),
		
		get_calendar_event_types: apiPath('/spec/list'),
		
		get_calendar_event_statuses: apiPathV2('/event/:eid/state'),
		
        billing_house: apiPath('/billing/housefinstatus'),
  
		billing_cards: apiPath('/billing/getaccaddress'),
		
        billing_finstatus: apiPath('/billing/abonentfinstatus'),
		
        billing_payments: apiPath('/billing/abonentpayments'),
		
        billing_recalculations: apiPath('/billing/abonentrecalculations'),
	
		calendar_events: apiPath('/request/filter/apply'),

        appeal: apiPath('/request/filter/apply'),
        
        appeal_preset: apiPath('/request/preset/apply'),

        contact_person: apiPath('/request/filter/apply'),
        
        contact_person_preset: apiPath('/request/preset/apply'),

        service: apiPath('/request/filter/apply'),

        service_preset: apiPath('/request/preset/apply'),

        resource: apiPath('/request/filter/apply'),

        resource_preset: apiPath('/request/preset/apply'),

        task: apiPath('/request/filter/apply'),

        task_preset: apiPath('/request/preset/apply'),

        customers: apiPath('/request/filter/apply'),

        customers_preset: apiPath('/request/preset/apply'),
	
		customer_calendar_events: apiPathV2('/calendar/site'),
        
        get_history_filters: apiPathV2('/changes/filter/:objectType'),
        
        get_data_history: apiPathV2('/changes/:objectType/:id'),
        
        config_grid: apiPathV2('/config/grid/:entityCode'),

        config_all_map_grid: apiPathV2('/config/all/map/:entityCode'),
		
        config_symbol_grid: apiPathV2('/config/symbol/map/:entityCode'),
       
        config_color_grid: apiPathV2('/config/color/map/:entityCode'),

        config_tabs: apiPathV2('/config/tabs/:objectType'),

        proxy_grid: {
            url: `${mwPrefix}/prx/:dataSource/:objectType/:objectID`
        },

        // /config/tab/customer/test_fi?lang=ua

        config_grid_tab: apiPathV2('/config/tab/:objectType/:entityCode'),

        // config_all_map_grid_tab: apiPathV2('/config/grid/:entityCode'),
        
        async_search_attributes: apiPath('/request/attributes/dict'),
	
		sn_logout: apiPath('/:pathPrefix/CallEvent/logout', snPrefix),
	
		customer_types: apiPath('/customer/allow/type'),
	
		object_search_old: apiPath('/ffm/item'),
		
        object_search: apiPathV2('/site/item'),
        
        apart_search: apiPathV2('/site/form/apart'),
		
		create_object: apiPath('/customer/create/serviceobject'),
	
		customer_address: apiPath('/customer/addresses'),
	
		customer_persons: apiPath('/customer/person'),
	
        attributes_ordering: apiPath('/filter/attributes/ORDER_TASK'),
  
		// save_orders_filter: apiPath('/order/task/preset/save'),
        
        save_orders_filter: apiPath('/request/preset/save'),
        
        save_customers_filter: apiPath('/request/preset/save'),
  
		info_filter: apiPath('/request/preset/info'),
		
        rename_filter: apiPath('/request/preset/:id/rename'),
  
        info_orders_filter: apiPath('/order/task/preset/info'),

		// info_customers_filter: apiPath('/order/task/preset/info'),
  
		delete_filter: apiPath('/request/filter/delete'),

        customer_appeals: apiPath('/appeal/customer'),

        person_appeals: apiPath('/appeal/customer'),
	
        get_appeals: apiPath('/request/preset/apply'),

        get_appeals_search: apiPath('/request/filter/apply'),

        contact_person_search: apiPath('/request/filter/apply'),
        
        get_appeals_for_link: apiPath('/request/filter/apply'),

        get_service_catalogue_objects_for_link: apiPath('/request/filter/apply'),

        // get_objects_for_link: apiPath('/request/filter/apply'),

        get_objects_for_link: apiPath('/spec/customer'),
		
        issues_list: apiPath('/appeal/problem/:type'),
  
		issue: apiPathV2('/problem/:id'),

        issueDelete: apiPath('/appeal/problem/:id'),
		
		issue_change: apiPathV2('/problem'),
		
        issues_types: apiPath('/appeal/problem/type'),

        issues_form: apiPathV2('/problem/form'),
        
        // issues_for_object: apiPath('/appeal/object/problems/'),
        issues_for_object: apiPathV2('/problem/object/'),
	
		get_customer_appeals_params: apiPath('/appeal/params'),
		
        get_dashboard_views: apiPath('/view', dbrbPrefix),
        
        get_dashboard_filters: apiPath('/filters/:type', dbrbPrefix),
        
        get_dashboard_panel: apiPath('/panel/:id', dbrbPrefix),
        
        get_dashboard_diagram: apiPath('/', dbrbPrefix),
        
        get_dashboard_period: apiPath('/dict/period/:value', dbrbPrefix),
        
        get_dashboard_period_list: apiPath('/dict/period/list', dbrbPrefix),
  
        get_detail_in_grid: apiPath('/params/:type/row/:id'),

        get_proxy_detail_in_grid: apiPath('/prx/detail/:source/:id'),
        
        get_proxy_detail_actions_in_grid: apiPath('/prx/actions/:source/:id'),
        
        post_proxy_detail_action_in_grid: apiPath('/prx/run/:source/:action/:id'),
		
        get_service_catalogue: apiPath('/spec'),
    
        get_service_catalogue_detail: apiPath('/spec/:id'),
        
        get_service_catalogue_files: apiPath('/file/list'),
        
        appeal_customer: apiPathV2('/appeal/:appealId/customer'),
        
        appeal_link_types: apiPathV2('/links/:objectType/types'),
        
		objects_links: apiPathV2('/links/:objectType/:id'),
		
		appeal_link_delete: apiPathV2('/links/:linkId'),
		
        appeal_subtabs: apiPathV2('/appeal/subtabs/:appealId'),
		
        get_notifications: apiPath('/notifications'),
        
        notification_mark_read: apiPath('/notifications/mark_read'),
	
		sn_alive_request: apiPath('/:pathPrefix/server/alive', snPrefix),
	
		phone_conf: apiPath('/phone/conf', snPrefix),
	
		sse_notifications: {
			url: `${msPrefix}/notifications/stream`
        },
        sse_notifications_ping: {
			url: `${msPrefix}/ping`
		},
		
        sse_auth_phone: apiPath('/CallEvent/auth', snPrefix),
  
		sse_phone_tream: {
            url: `${snPrefix}/CallEvent/stream`
		},
        
        notification_mark_delete: apiPath('/notifications/mark_delete'),
        
        save_dyn_param: apiPath('/param/:objectType'),

        save_dyn_param_customer: apiPath('/param/CUSTOMER'),

        save_dyn_param_contact_person: apiPath('/param/CONTACT_PERSON'),

        save_dyn_param_specification: apiPath('/param/SPECIFICATION'),
        
        phone_accounts: apiPath('/pbx/account'),
	
		edit_phone_account: apiPath('/pbx/account/:id'),
        
        get_call_location_list: apiPath('/pbx/location'),
        
        call_account: apiPath('/pbx/account/:id'),
		
		sign_create: apiPathV2('/user/template/'),
		
        sign_edit: apiPathV2('/user/template/:id'),
        
        signatures: apiPathV2('/user/template/signature'),
	
		sse_path_stream: {
            url:'CallEvent/stream'
		},
        
        sse_dynamic_auth: apiPath('/:pathPrefix/CallEvent/auth', snPrefix),

		sse_auth_messages: apiPath('/messevent/auth', msPrefix),
		
        sse_messages_stream: apiPath('/messevent/stream', msPrefix),

        phone_register: apiPath('/operator/set'),

        dialog_answer: apiPath('/Dialog/answer', snPrefix),

        dialog_hangup: apiPath('/:pathPrefix/Dialog/drop', snPrefix),

        dialog_hold: apiPath('/Dialog/hold', snPrefix),

        dialog_retrieve: apiPath('/Dialog/retrieve', snPrefix),

        dialog_call: apiPath('/Dialog/call', snPrefix),

        dialog_transfer: apiPath('/Dialog/transfer', snPrefix),
       
        set_operator_status: apiPath('/admin/state/set'),
        
		operator_status: apiPath('/admin/state/list'),

        article_delete: {
            url: `${mwPrefix}/kb/delete`,
        },

        get_appeal_actions: {
            url: `${mwPrefix}/appeal_label/action/list`,
        },

        set_appeal_action: {
            url: `${mwPrefix}/appeal/action/params`,
        },

        customer: {
            url: `${mwPrefix}/customer`,
        },

        create_individual_customer: {
            url: `${mwPrefix}/customer/create/individual`,
        },

        create_organization: {
            url: `${mwPrefix}/customer/create/organization`,
        },
	
		customer_address_type: {
			url: `${mwPrefix}/address/type/list`,
		},

        customer_edit: {
            url: `${mwPrefix}/customer/edit`,
        },

        customer_edit_multi: {
            url: `${mwPrefix}/customer/edit/mult`,
        },

        customer_save_service: {
            url: `${mwPrefix}/customer/specinst/add`,
        },

        specStatuses: {
            url: `${mwPrefix}/customer/specinst/states/:objectType/:objectId`
        },

        specSaveParam: {
            url: `${mwPrefix}/customer/specinst/action/:objectType`
        },

        specStatus: {
            url: `${mwPrefix}/customer/specinst/state/:objectType/:objectId`
        },

        specActions: {
            url: `${mwPrefix}/customer/specinst/actions/:objectType/:objectId`
        },

        person: {
            url: `${mwPrefix}/person`,
        },

        person_unlink: {
            url: `${mwPrefix}/customer/person/unlink`,
        },
        
        person_edit: {
            url: `${mwPrefix}/customer/person/edit`,
        },

        issues_edit: {
            url: `${mwPrefix}/customer/person/issues/save`,
        },

        person_edit_multi: {
            url: `${mwPrefix}/customer/person/edit/mult`,
        },

        person_save_contact: {
            url: `${mwPrefix}/person/contact/save`,
        },

        person_delete_contact: {
            url: `${mwPrefix}/person/contact/delete`,
        },

        person_contact: {
            url: `${mwPrefix}/person/contact`,
        },

        person_link: {
            url: `${mwPrefix}/person/link`,
        },

        persons_save: {
            url: `${mwPrefix}/customer/person/save`,
        },

        customer_appeals_info: {
            url: `${mwPrefix}/appeal/customer/info`,
        },

        // customer_services: {
        //     url: `${mwPrefix}/customer/services`,
        // },

        customer_services: apiPathV2('/customer/:objectType/:customerId'),

        customer_services_types: {
            url: `${mwPrefix}/customer/services/type/list`,
        },

        customer_accounts: {
            url: `${mwPrefix}/customer/accounts`,
        },

        // the same url as "customer_accounts"
        customer_contracts: {
            url: `${mwPrefix}/customer/accounts`,
        },

        customer_tasks: {
            url: `${mwPrefix}/item/instance`,
        },

        customer_info: {
            url: `${mwPrefix}/customer/info`,
        },

        customer_bank: {
            url: `${mwPrefix}/customer/bank`,
        },

        applicants_search: {
            url: `${mwPrefix}/CallHistory/search`,
        },

        customer_suggest: {
            url: `${mwPrefix}/CallHistory/search`,
        },

        call_history: {
            url: `${mwPrefix}/CallHistory/phone`,
        },

        user_info: {
            // return info about current user
            url: `${mwPrefix}/auth`,
        },

        user_get_phones_list: {
            url: `${mwPrefix}/user/get/phones/list`,
        },

        not_auth: {
            // server not_authenticated (for redirect)
            url: `${mwPrefix}/auth/not_auth`,
            redirect_url() {
                return `${this.url}?next=${encodeURI(window.location.href)}`;
            },
        },

        profile: {
            url: '../api/auth/profile',
            redirect: true,
        },

        logout: {
            url: `${mwPrefix}/auth/logout_idm`,
            redirect: true,
        },

        titles_menu: {
            url: '/data/precrmEntities.json',
        },

        apps_menu: {
            url: '/data/applications.json',
        },

        local_customer: {
            url: '/data/customer.json',
        },
        local_appeal: {
            url: '/data/appeal.json',
        },
        local_task: {
            url: '/data/task.json',
        },
        dictionary: {
            url: `${mwPrefix}/app/dictionaries`,
        },
        search: {
            url: `${mwPrefix}/search`,
        },
        search_csv: {
            url: `${mwPrefix}/search/csv`,
        },

        appeal_interaction_csv: {
            url: `${mwPrefix}/appeal/progress/interactions/csv`,
        },

        appeal_customer_csv: {
            url: `${mwPrefix}/appeal/customer/csv`,
        },

        filters: {
            url: `${mwPrefix}/request/presets`,
        },

        appeal_filters: {
            url: `${mwPrefix}/request/templates`,
        },

        filter_fields: {
            url: `${mwPrefix}/request/filter`,
        },
		filter_apply: {
			url: `${mwPrefix}/request/filter/apply`,
		},
        filter_apply_native: {
            url: `${mwPrefix}/request/filter/native`,
        },
		// TODO TO REMOVE AFTER CHANGE GRID used FROM CORE
		preset_apply: {
            url: `${mwPrefix}/request/preset/apply`,
        },
        preset_apply_csv: {
            url: `${mwPrefix}/request/filter/apply/csvOneStep`,
        },
        preset_apply_export_to_csv: {
            url: `${mwPrefix}/request/filter/apply/csvexport`,
        },
        preset_apply_export_to_xlsx: {
            url: `${mwPrefix}/request/filter/apply/xlsxexport`,
        },
        attributes: {
            url: `${mwPrefix}/request/attributes`,
        },

        save_filter: {
            url: `${mwPrefix}/request/preset/save`,
        },
        
        knowledge_base_tree: {
            url: `${mwPrefix}/kb/tree`,
        },
	
		knowledge_base: {
            url: `${mwPrefix}/kb/list`,
        },

        kb_status_list: {
            url: `${mwPrefix}/kb/status/list`,
        },

        kb_detail: {
            url: `${mwPrefix}/kb/detail`,
        },

        knowledge_base_save: {
            url: `${mwPrefix}/kb/save`,
        },

        comments: {
            url: `${mwPrefix}/appeal/comment`,
        },

        // Update comment text.
        comment_update: {
            url: `${mwPrefix}/appeal/comment/update`,
            method: 'post',
        },

        // Attach file to comment.
        comment_attach: {
            url: `${mwPrefix}/appeal/comment/attach`,
        },

        // Detach file from comment.
        comment_detach: {
            url: `${mwPrefix}/appeal/comment/detach`,
        },

        download_file: {
            url: `${mwPrefix}/file`,
        },

        delete_file: {
            url: `${mwPrefix}/file/delete`,
        },

        save_file: {
            url: `${mwPrefix}/file/upload`,
        },

        // Delete file
        file_delete: {
            url: `${mwPrefix}/appeal/file/delete`,
        },

        // Get all files attached to comment
        file_list: {
            url: `${mwPrefix}/appeal/file/list`,
        },
        comment_add: {
            url: `${mwPrefix}/appeal/comment`,
        },
        comment_delete: {
            url: `${mwPrefix}/appeal/comment`,
        },

        upload_file: {
            url: `${mwPrefix}/appeal/file/upload`,
        },

        upload_file_email: {
            url: `${mwPrefix}/email/attach/upload`,
        },
        delete_file_email: {
            url: `${mwPrefix}/email/attach/delete`,
        },

        get_files_list: {
            url: `${mwPrefix}/email/attach/list`,
        },

        interaction_create: {
            url: `${mwPrefix}/interaction/create`,
        },

        interaction_close: {
            url: `${mwPrefix}/interaction/close`,
        },

        interaction_appeal: {
            url: `${mwPrefix}/interaction/appeal`,
        },

        appeal_create: {
            url: `${mwPrefix}/interaction/appeal/v2/create`,
        },

        appeal_save: {
            url: `${mwPrefix}/interaction/appeal/save`,
        },

        appeal_interactions: {
            url: `${mwPrefix}/appeal/interactions`,
        },

        appeal_interaction_proof: {
            url: `${mwPrefix}/appeal/interactions/proof`,
        },

        appeal_type: {
            url: `${mwPrefix}/appeal/filteredType`,
        },

        appeal_form_params: {
            url: `${mwPrefix}/form/params`,
        },

        appeal_form_ajax: {
            url: `${mwPrefix}/form/values/search`,
        },

        appeal_by_id: {
            url: `${mwPrefix}/appeal`,
        },

        get_appeal_version: {
            url: `${mwPrefix}/appeal/version`,
        },

        appeal_status_list: {
            url: `${mwPrefix}/appeal/status/list`,
        },

        appeal_dest_list: {
            url: `${mwPrefix}/appeal/dest/list`,
        },

        appeal_priority_list: {
            url: `${mwPrefix}/appeal/priority/list`,
        },

        appeal_feedback: {
            url: `${mwPrefix}/appeal/contactPersons`,
        },

        appeal_feedback_save: {
            url: `${mwPrefix}/appeal/contacts/save`,
        },

        appeal_info_contacts: {
            url: `${mwPrefix}/v2/appeal/:appealId/contacts`,
        },
        

        appeal_customer_save: {
            url: `${mwPrefix}/interaction/customer/save`,
        },

        unlock_appeal_form: {
            url: `${mwPrefix}/appeal/lock`,
        },

        unlock_appeal: {
            url: `${mwPrefix}/appeal/unlock`,
        },

        appeal_update: {
            url: `${mwPrefix}/appeal/update`,
        },

        appeal_timer_save: {
            url: `${mwPrefix}/appeal/timer/save`,
        },

        mailing: {
            url: `${mwPrefix}/email/inbox/short`,
        },

        send_email: {
            url: `${mwPrefix}/email/reply`,
        },

        forward_email: {
            url: `${mwPrefix}/email/forward`,
        },

        get_template_list: {
            url: `${mwPrefix}/notificationTemplate/list`,
        },

        get_template_email: {
            url: `${mwPrefix}/notificationTemplate/test`,
        },

        create_new_email: {
            url: `${mwPrefix}/email/create`,
        },

        delete_email: {
            url: `${mwPrefix}/email/destroy`,
        },

        history: {
            url: `${mwPrefix}/appeal/progress/interactions/list`,
        },

        interaction: {
            url: `${mwPrefix}/interaction/create`,
        },

        callhistory_list: {
            url: `${mwPrefix}/order/task/filter/list`,
        },

        doc_template_default: {
            url: `${mwPrefix}/doctempl/default`,
        },

        doc_template_create: {
            url: `${mwPrefix}/request/document/make`,
        },

        doc_download: {
            url: `${mwPrefix}/doctempl/file`,
        },

        doc_templates: {
            url: `${mwPrefix}/doctempl/list`,
        },

        doc_template_generate: {
            url: `${mwPrefix}/doctempl/make`,
        },

        search_settlement: {
            url: `${mwPrefix}/geo/settlement`,
        },

        search_settlement_by_zip_code: {
            url: `${mwPrefix}/geo/zip/search`,
        },

        search_street: {
            url: `${mwPrefix}/geo/street`,
        },

        add_street: {
            url: `${mwPrefix}/addresses/street/add`,
        },

        search_building: {
            url: `${mwPrefix}/geo/build`,
        },

        search_zip_code: {
            url: `${mwPrefix}/geo/zip`,
        },
        
        quality_questions_list: {
            url: `${mwPrefix}/interaction/quality/list`,
        },

        dinamic_params: {
            url: `${mwPrefix}/form/params`,
        },

        quality_new_save: {
            url: `${mwPrefix}/interaction/item/add`,
        },

        quality_edit_save: {
            url: `${mwPrefix}/interaction/params/save `,
        },

        dashboard_templates: {
            url: `${mwPrefix}/dashboard/templates`,
        },
        dashboard_filter: {
            url: `${mwPrefix}/dashboard/filter`,
        },
        dashboard_kpi: {
            // url:  'data/dashboard/summary.json'
            url: `${mwPrefix}/dashboard/appealkpi`,
        },
        dashboard_map: {
            url: `${mwPrefix}/dashboard/filter/interactappealmap`,
        },
        dashboard_pie: {
            // url:  'data/dashboard/pieChart.json'
            url: `${mwPrefix}/dashboard/filter/appealtoptenq`,
        },
        dashboard_line: {
            // url:  'data/dashboard/lineChart.json'
            url: `${mwPrefix}/dashboard/filter/appealcount`,
        },
        dashboard_statuses_by_destination: {
            url: `${mwPrefix}/dashboard/filter/apealstatusesbydest`,
        },
        dashboard_statuses_by_type: {
            url: `${mwPrefix}/dashboard/filter/apealstatusesbytype`,
        },
        dashboard_appeal_top_ten_by_dest: {
            url: `${mwPrefix}/dashboard/filter/appealtoptendest`,
        },
        dashboard_appeal_top_ten_by_oper: {
            url: `${mwPrefix}/dashboard/filter/appealtoptenoper`,
        },

        get_blank_html: {
            url: `${mwPrefix}/request/document/getDocument`,
        },

        // Settings.

        admin_user_all: {
            url: `${mwPrefix}/admin/user/all`,
        },
        admin_group_all: {
            url: `${mwPrefix}/admin/group/all`,
        },
        admin_area_all: {
            url: `${mwPrefix}/admin/area/list`,
        },
        admin_domain_all: {
            url: `${mwPrefix}/admin/space/list`,
        },
        admin_access_rules: {
            url: `${mwPrefix}/admin/space/rules`,
        },
        add_access_rule: {
            url: `${mwPrefix}/admin/space/rule/add`,
        },
        delete_access_rule: {
            url: `${mwPrefix}/admin/space/rule/del`,
        },
        add_child_access_rule: {
            url: `${mwPrefix}/admin/child/rule/add`,
        },
        delete_child_access_rule: {
            url: `${mwPrefix}/admin/child/rule/del`,
        },
        get_access_rule_tables: {
            url: `${mwPrefix}/admin/space/table/list`,
        },
        get_access_rule_columns: {
            url: `${mwPrefix}/admin/space/column/list`,
        },
        get_access_rule_conditions: {
            url: `${mwPrefix}/admin/space/condition/list`,
        },
        get_rule_access: {
            url: `${mwPrefix}/admin/space/rule/info`,
        },
        get_child_rule_access: {
            url: `${mwPrefix}/admin/child/rules`,
        },
        save_rule_access: {
            url: `${mwPrefix}/admin/space/rule/set`,
        },
        get_group_domains: {
            url: `${mwPrefix}/admin/user/space/list`,
        },
        add_user_group: {
            url: `${mwPrefix}/admin/area/add`,
        },
        delete_user_group: {
            url: `${mwPrefix}/admin/area/del`,
        },
        // Settings/BuissnesUnits
        get_buisness_units: {
            url: `${mwPrefix}/order/task/bu/list`,
        },
        delete_buisness_unit: {
            url: `${mwPrefix}/order/task/bu/del`,
        },
        add_buisness_unit: {
            url: `${mwPrefix}/order/task/bu/add`,
        },
        edit_buisness_unit: {
            url: `${mwPrefix}/order/task/bu/set`,
        },
        get_buisness_unit_users: {
            url: `${mwPrefix}/order/task/bu/emp/list`,
        },
        search_buisness_unit_user: {
            url: `${mwPrefix}/order/task/bu/emp/search`,
        },
        delete_buisness_unit_user: {
            url: `${mwPrefix}/order/task/bu/emp/del`,
        },
        add_buisness_unit_user: {
            url: `${mwPrefix}/order/task/bu/emp/set`,
        },
        get_buisness_unit_spaces: {
            url: `${mwPrefix}/space/list`,
        },
        get_buisness_unit_spaces_all: {
            url: `${mwPrefix}/space/all`,
        },
        search_buisness_unit_space: {
            url: `${mwPrefix}/space/all`,
        },
        add_buisness_unit_space: {
            url: `${mwPrefix}/space/add`,
        },
        delete_buisness_unit_space: {
            url: `${mwPrefix}/space/del`,
        },
        get_buisness_unit_restrs: {
            url: `${mwPrefix}/admin/space/restr`,
        },
        get_buisness_unit_restr: {
            url: `${mwPrefix}/admin/space/restr/info`,
        },
        get_buisness_unit_restr_types: {
            url: `${mwPrefix}/admin/space/attr/restr`,
        },
        edit_buisness_unit_restr: {
            url: `${mwPrefix}/admin/space/restr/set`,
        },
        delete_buisness_unit_restr: {
            url: `${mwPrefix}/admin/space/restr/del`,
        },
        sync_buisness_unit_users: {
            url: `${mwPrefix}/admin/user/sync`,
        },
        sync_buisness_unit_domains: {
            url: `${mwPrefix}/admin/domain/sync`,
        },

        // Orders

        get_order_list: {
            url: `${mwPrefix}/order/task`,
        },
        get_order_bpmn: {
            url: `${mwPrefix}/order/flow/xml`
        },
        get_order_bpmn_progress: {
            url: `${mwPrefix}/order/flow/underline`
        },
        get_order_csv: {
            url: `${mwPrefix}/form/params/csv`,
        },
        get_order_type_options: {
            url: `${mwPrefix}/order/task/type/list`,
        },
        create_new_order: {
            url: `${mwPrefix}/order/task/new?version=111-`,
        },
        get_public_orders_filters: {
            url: `${mwPrefix}/request/presets`,
        },
        get_order_comments: {
            url: `${mwPrefix}/order/task/comment`,
        },
        get_history_all: {
            url: `${mwPrefix}/changes`
        },
        get_order_history_system: {
            url: `${mwPrefix}/changes/system`
        },
        get_order_comments_users_emails: {
            url: `${mwPrefix}/order/task/comment/email/user/search`,
        },
        get_order_comments_email_history: {
            url: `${mwPrefix}/order/task/comment/email/get`,
        },
        get_order_actions: {
            url: `${mwPrefix}/order/task/actions`,
        },
        set_order_action: {
            url: `${mwPrefix}/order/task/action`,
        },
        track_order: {
            url: `${mwPrefix}/track`
        },
        get_order_suspend_reason_list: {
            url: `${mwPrefix}/order/task/suspend/reason/list`
        },
        get_sub_orders: {
            url: `${mwPrefix}/order/task/subtasks/`
        },
        get_order_objects: {
            url: `${mwPrefix}/order/task/item/rows`
        },
        delete_order_object: {
            url: `${mwPrefix}/order/task/item/del`
        },
        get_order_objects_list: {
            url: `${mwPrefix}/order/task/item/type/list`
        },
        get_object_info: {
            url: `${mwPrefix}/order/task/item/content`
        },
        add_object: {
            url: `${mwPrefix}/order/task/item/add`
        },
        edit_object: {
            url: `${mwPrefix}/order/task/params/save`
        },
        get_object_processing_info: {
            url: `${mwPrefix}/order/task/item/instHistory`
        },
        set_order_beg_date: {
            url: `${mwPrefix}/order/task/planBegDate/set`
        },
        set_order_end_date: {
            url: `${mwPrefix}/order/task/planEndDate/set`
        },
        get_order_delegate: {
            url: `${mwPrefix}/order/task/delegate/list`
        },
        set_order_delegate: {
            url: `${mwPrefix}/order/task/delegate/set`
        },
        set_order_priority: {
            url: `${mwPrefix}/order/task/priority/set`
        },
        save_order_param: {
            url: `${mwPrefix}/order/task/param/save`
        },
        get_external_params: {
            url: `${mwPrefix}/form/ext`
        },
        export_form_csv: {
            url: `${exportPrefix}/form/csv`
        },
        export_form_excel: {
            url: `${exportPrefix}/form/xlsx`
        },
        // services
        // get_services_list: {
        //     url: `${mwPrefix}/spec/instance`,
        // },
        get_services_filters: {
            url: `${mwPrefix}/request/presets`,
        },
        get_service: {
            url: `${mwPrefix}/service`
        },
        get_service_orders: {
            url: `${mwPrefix}/service/instance`
        },
        get_service_appeals: apiPathV2('/appeal/SERVICE/:id'),

        get_resource: {
            url: `${mwPrefix}/service`
        },
        get_resource_appeals: apiPathV2('/appeal/RESOURCE/:id'),

        get_resource_orders: {
            url: `${mwPrefix}/resource/instance`
        },

        translations: {
            url: `${mwPrefix}/translations`
        },

        translationsVersions: {
            url: `${mwPrefix}/translations/versions`
        },

        linksRelations: {
            // url: apiPathV2('/links/type/relations'),
            url: `${mwPrefix}/v2/links/configure`
        },
        service_catalog_service: {
            url: `${mwPrefix}/v2/spec/SERVICE/:id`
        },

        // customers
        get_public_customers_filters: {
            url: `${mwPrefix}/request/presets`,
        },

        // bunits
        get_bunits_tree: {
            url: `${mwPrefix}/order/task/bunit/list`
        },
        get_emp_tree: {
            url: `${mwPrefix}/order/task/emp/list`
        },
        get_bunits_list: {
            url: `${mwPrefix}/order/task/bu/emp/list`
        },
        search_bunits: {
            url: `${mwPrefix}/order/task/bu/search`
        },
        save_bunit_dyn_param: {
            url: `${mwPrefix}/param/PARTY`
        },
        add_bunit: {
            url: `${mwPrefix}/order/task/bu/add`
        },
        add_emp: {
            url: `${mwPrefix}/order/task/bu/emp/add`
        },
        link_emp: {
            url: `${mwPrefix}/order/task/bu/emp/link`
        },
        delete_bunit: {
            url: `${mwPrefix}/order/task/businessunit/del`
        },
        delete_emp: {
            url: `${mwPrefix}/order/task/emp/del`
        },
        getAppealFileTemplate: {
            url: `${mwPrefix}/interaction/offline/get`
        },
        getAppealGroupTemplate: {
            url: `${mwPrefix}/interaction/offline/updating/get`
        },
        uploadAppealFileTemplate: {
            url: `${mwPrefix}/interaction/offline/upload`
        },
        uploadAppealGroupTemplate: {
            url: `${mwPrefix}/interaction/offline/updating/upload`
        },

        staticBlocks: {
            url: `${mwPrefix}/v2/form/static/blocks`
        },
        staticBlock: {
            url: `${mwPrefix}/v2/form/static/block`
        },
        // staticBlockVersions: {
        //     url: `${mwPrefix}/v2/form/static/blocks/versions`
        // }

        // erport wizard
        getExportTypes: {
            url: `${mwPrefix}/request/filter/export/type/:code`
        },
        getExportFormats: {
            url: `${mwPrefix}/request/filter/export/filetype/:code`
        },
        getExportFields: {
            url: `${mwPrefix}/request/filter/export/fields/:code/:type`
        },
        exportJob: {
            url: `${mwPrefix}/request/filter/export/job`
        },
        exportJobsFilter: {
            url: `${mwPrefix}/request/filter/export/job/filters/:code`
        },
        exportJobsList: {
            url: `${mwPrefix}/request/filter/export/jobs`
        },
        exportJobIdAction: {
            url: `${mwPrefix}/request/filter/export/job/:id/:action`
        },
        exportJobId: {
            url: `${mwPrefix}/request/filter/export/job/:id`
        },
        exportJobCheck: {
            url: `${mwPrefix}/request/filter/export/check`
        },
        bots: {
            url: `${mwPrefix}/v2/bot/:type`
        },
        addBot: {
            url: `${mwPrefix}/v2/bot/telegram/url/:botName`
        },
        deleteBot: {
            url: `${mwPrefix}/v2/bot/device/:botName/:id`
        },
        searchEmailContacts: {
            url: `${mwPrefix}/email/search/contacts`
        }
    }
};