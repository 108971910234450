import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ onClick, children }) => (
	<button className='btn btn-primary' onClick={onClick}>
		{children}
	</button>
);

Button.propTypes = {
	children: PropTypes.string,
	onClick: PropTypes.func
};

export default Button;
