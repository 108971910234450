import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from 'styles/modules/notification.module.scss';
import ReactMarkdown from 'react-markdown';

@withTranslation()
export default class HttpErrorNote extends Component {
	constructor (props) {
		super(props);
		this.state = {
			details: false,
		};
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}
	
	open () {
		this.setState({ details: true });
	}
	
	close () {
		this.setState({ details: false });
	}

	render () {
		const { message, t } = this.props;
		const re = /ORA-\d+:/;
		let title = message;
		let ora = false;
		if (re.test(message)) {
			const messageArray = message.split(re);
			title = messageArray[0] || messageArray[1];
			ora = true;
		}
		const { details } = this.state;
		return (
			<div className={styles.httpErrorNote}>
				<i className='icon-error' />
				<div className='m-t-8'><ReactMarkdown source={title}/></div>
				{
					(details && ora) && (
						<div className='m-t-10'>{<ReactMarkdown source={message}/>}</div>
					)
				}
				{
					(details && ora) && (
						<button onClick={this.close} type='button' className='error-toggle-button'>
							{t('notification.less')} <span className='top'>&#8963;</span>
						</button>
					)
				}
				
				{
					(!details && ora) && (
						<button onClick={this.open} type='button' className='error-toggle-button m-t-10'>
							{t('notification.more')} <span>&#8964;</span>
						</button>
					)
				}
			</div>
		);
	}
}

HttpErrorNote.propTypes = {
	message: PropTypes.string.isRequired,
};
