import React, { useState, useEffect, useCallback } from "react";
import { withTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import { SEARCH_TIMER_INTERVAL } from "constants/actions";

import { connect } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { reduxFormWrapper, buildThreePath } from "helpers";
import Loader from "components/Loader/index";
import { renderDateField } from "components/FiltersForm/helpers";
import CalendarContainer from "components/Common/DatePicker/CalendarContainer";
import ComboBox from "components/Common/ComboBox";
import { Input, DatePicker } from "ui-core-dashboard";
import MultiSelectWithSearch from "components/Common/MultiSelectWithSearch/ReduxFormDecorator";
import { configureFilterObject } from "../../../SearchFilters/helpers";

import baseService from "../../../../services/BaseService";

const mapStateToProps = (state, props) => {
    let form = props.id
        ? `customer-filters-${props.code}-${props.itemType}-${props.id}-${props.customerId}`
        : `customer-filters-${props.code}-${props.customerId}`;
    if (props.tabNum) {
        form = `${form}_${props.tabNum}`;
    }
    return {
        form,
        formValues: getFormValues(form)(state),
    };
};
const SpecFilters = props => {
    const [asyncState, setAsyncState] = useState({});
    const { formValues } = props;

    const {
        t,
        mainFilters,
        secondaryFilters,
        isLoading,
        gridFilters,
        setGridFilters,
        toggleSecondaryFilters,
        initialize,
        fixedFilterObject = [], // filter object which always is sent, field with value should be disabled
    } = props;

    const [initValues, setInitValues] = useState({});
    const debouncedSetGridFilters = useCallback(
        debounce(newFilters => setGridFilters(newFilters), SEARCH_TIMER_INTERVAL),
        []
    );

    const onGridsFiltersChange = newFilters => {
        debouncedSetGridFilters(newFilters);
    };

    const asyncParams = (search, code, id) => {
        if (!search) {
            setAsyncState({ [code]: [] });
        } else {
            baseService.post("async_search_attributes", { data: { id, search }, jsonType: true }).then(resp => {
                setAsyncState({
                    [code]: resp.result.map(field => ({
                        label: field.value || "",
                        value: field.id || "",
                    })),
                });
            });
        }
    };

    const defaultPropsDate = {
        saveFormat: "YYYY-MM-DDTHH:mm",
        locale: "uk",
        popperContainer: CalendarContainer,
        showMonthDropdown: true,
        showYearDropdown: true,
    };

    function getComponentByType(type, search) {
        let components = {
            LIST: ComboBox,
            NUMBER: Input,
            STRING: Input,
            DATE: DatePicker,
        };
        if (search) {
            components = {
                THREE: MultiSelectWithSearch,
                LIST: MultiSelectWithSearch,
            };
        }

        return components[type] || Input;
    }

    function convertFieldConfigToProps(fieldConfig) {
        const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
        const multi =
            (["LIST", "THREE"].includes(fieldConfig.type) && fieldConfig.multiset_allowed === "Y") || undefined;
        const options =
            fieldConfig.type === "THREE"
                ? { children: buildThreePath(fieldConfig.dict, false, { children: "children" }) }
                : getOptions(fieldConfig.dict || []);
        const searchable = !!fieldConfig.search;
        const isMultiset = fieldConfig.type === "THREE";
        const title = fieldConfig.type === "THREE" && fieldConfig.name;
        const clearValueText = t("clear");
        const clearAllText = t("clearValue");
        let config = {
            key: fieldConfig.id,
            component: getComponentByType(fieldConfig.type, fieldConfig.search, fieldConfig.multiset_allowed === "Y"),
            name: fieldConfig.code,
            label: fieldConfig.name,
            title,
            type: fieldConfig.type,
            options,
            multi,
            showValuesCount: multi,
            tree: fieldConfig.type === "THREE",
            errorPlaceholder: true,
            valueField: fieldConfig.type === "THREE" ? "value" : "key",
            keySelectField: "key",
            leafField: fieldConfig.type === "THREE" ? "leaf" : "children",
            searchable,
            isMultiset,
            clearValueText,
            clearAllText,
            placeholder: t("dontSelect"),
            noResultsText: t("noResultsFound"),
            disabled: fixedFilterObject ? fixedFilterObject.find(obj => obj.id === fieldConfig.id) : false,
            onBlur: e => {
                e.preventDefault();
                return false;
            },
        };

        if (fieldConfig.dict_search) {
            const { disallow_select_all } = fieldConfig;

            config = {
                ...config,
                disallow_select_all,
                notFilteredOptions: true,
                dict_search: true,
                options: asyncState[fieldConfig.code] || [],
                async: {
                    callback: asyncParams,
                    code: fieldConfig.code,
                    id: fieldConfig.id,
                },
            };
        }
        return config;
    }

    function renderFilterField(fieldConfig) {
        let fieldProps = convertFieldConfigToProps(fieldConfig);

        if (fieldConfig.type === "DATE") {
            fieldProps = { ...fieldProps, ...defaultPropsDate };
            return renderDateField(t, fieldConfig, fieldProps, formValues);
        }

        return <Field {...fieldProps} />;
    }

    function getInitValues(fields, objArray) {
        const initValues = {};
        objArray.forEach(obj => {
            const field = fields.find(item => item.id === obj.id);
            if (field) {
                const isMulti = ["LIST", "THREE"].includes(field.type) && field.multiset_allowed === "Y";
                let { value } = obj;
                if (isMulti && !Array.isArray(value)) {
                    value = [value];
                }
                initValues[field.code] = value;
            }
        });
        return initValues;
    }

    useEffect(() => {
        if (mainFilters && mainFilters.length !== 0) {
            if (formValues) {
                const { query, ...rest } = formValues;
                const filters = [...mainFilters, ...secondaryFilters];
                const newFilterObject = configureFilterObject(rest, filters);
                onGridsFiltersChange({ filterObject: [...fixedFilterObject, ...newFilterObject], query });
            } else if ((gridFilters.filterObject && gridFilters.filterObject.length > 0) || gridFilters.query) {
                onGridsFiltersChange({ filterObject: fixedFilterObject, query: "" });
            } else {
                onGridsFiltersChange({ filterObject: fixedFilterObject, query: "" });
            }
        }
    }, [formValues, mainFilters, secondaryFilters]);

    // init values by mainFilters
    useEffect(() => {
        if (mainFilters.length > 0) {
            const initVal = getInitValues(mainFilters, fixedFilterObject);
            setInitValues(prev => ({ ...prev, ...initVal }));
        }
    }, [mainFilters]);

    // initValues by secondaryFilters
    useEffect(() => {
        if (secondaryFilters.length > 0) {
            const initVal = getInitValues(secondaryFilters, fixedFilterObject);
            setInitValues(prev => ({ ...prev, ...initVal }));
        }
    }, [secondaryFilters]);

    useEffect(() => {
        if (fixedFilterObject && fixedFilterObject.length > 0) {
            initialize(initValues);
        }
    }, [initValues]);

    return (
        <div className="customer-filters-wrapper ordering-component-ui-core-wrapper">
            {isLoading && <Loader />}
            <div className={`main-filters total-filters-${mainFilters.length}`}>
                {mainFilters.map(renderFilterField)}
            </div>
            {secondaryFilters.length > 0 && (
                <div className="secondary-filters-toggle" onClick={toggleSecondaryFilters}>
                    <div className="separator" />
                    <i className="icon icon-down" />
                    <div className="separator" />
                </div>
            )}
            {secondaryFilters.length > 0 && (
                <div className={`secondary-filters total-filters-${secondaryFilters.length}`}>
                    {secondaryFilters.map(renderFilterField)}
                </div>
            )}
        </div>
    );
};

export default connect(
    mapStateToProps,
    null
)(
    reduxFormWrapper({
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
    })(withTranslation()(SpecFilters))
);
