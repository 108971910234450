import React, {useEffect, useState } from 'react';
import ModalPortal from "../../ModalPortal/index";
import MessageModal from 'components/MessageModal';
import AccountForm from './AccountForm';
import { CSSTransition } from 'react-transition-group';
import {KeyCodes} from "../../../constants";

export default function AccountModal (props) {
	const { t, isOpen, editPhoneId} = props;
	const [enableWarning, setEnableWarning] = useState(false);
	const [warningPopupOpen, setEnableWarningPopup] = useState(false);
	
	useEffect( ()=>{
		document.addEventListener('keydown', handlePress);
		return () => document.removeEventListener('keydown', handlePress);
	},[]);
	
	function handlePress(e) {
		if (e.keyCode === KeyCodes.ESCAPE) onClose();
	}
	
	function onClose() {
		if (enableWarning) {
			setEnableWarningPopup(true);
		} else {
			props.onClose();
		}
	}
	
	function handleCloseWarningPopup() {
		setEnableWarningPopup(false);
	}
	
	function onClickDangerButton() {
		setEnableWarning(false);
		props.onClose();
	}
	
	return <>
		<CSSTransition
					in={isOpen}
					classNames='fade'
					appear={true}
					enter={true}
					exit={true}
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					 <ModalPortal onClose={onClose} className='modal-small modal-center'>
						<AccountForm editPhoneId={editPhoneId} form={editPhoneId ? 'edit-call-account' : 'create-call-account'} setEnableWarning={setEnableWarning}/>
					</ModalPortal>
				</CSSTransition>
		{
			warningPopupOpen &&
			<ModalPortal
				onClose={handleCloseWarningPopup}
				className='modal-small'
			>
				<MessageModal
					titleModal={t('attention')}
					contentModalText={t('modalWindowsLabels.notSavedWarning')}
					dangerButton={true}
					dangerButtonText={t('close')}
					onClickDangerButton={onClickDangerButton}
					secondaryButton={true}
					secondaryButtonText={t('cancel')}
					onClickSecondaryButton={handleCloseWarningPopup}
				/>
			</ModalPortal>
		}
	</>
}
