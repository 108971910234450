import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';

function MainInputBox ({ hidden, children, icon }) {
	if (hidden) return null;
	
	return (
		<div className={styles.mainInputBox}>
			{icon && <div className={styles.mainInputBoxIcon}>{icon}</div>}
			<div className={styles.mainInputBoxInput}>{children}</div>
		</div>
	);
}

MainInputBox.propTypes = {
	hidden: PropTypes.bool,
	children: PropTypes.any,
	icon: PropTypes.any
};

export default MainInputBox;
