import React  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from 'styles/modules/filters.module.scss';
import {
    clearSearchFilters,
    getAllFilterFields,
    getAppealTypes,
    getSearchFilters,
    setCurrentSearchFilter,
    setSearchFilterCriteria,
} from '../../actions/searchFilter';
import FiltersHeader from './FiltersHeader';
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import SearchFiltersForm from './SearchFiltersForm';
import { Codes } from '../SearchPage/index';
import ComboBox from 'components/Common/ComboBox';
import Loader from 'components/Loader';
import FilterNameModal from './FilterNameModal';
import { configureFilterObject, configureFilterValues } from './helpers';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import { bindActionCreators } from 'redux';
import { initialize, getFormValues } from 'redux-form';
import baseService from '../../services/BaseService';
import CheckBoxToggle from '../Common/CheckBoxToggle';
import settingsService, { SORT_POSTFIX } from 'services/settingsService';
const EMPTY_FILTER_OPTION = { value: -1, label: '-' };

const getSelectFilterOptions = createSelector(
    state => state.searchFilter.filterList,
    filterList => {
        const convertedList = filterList.map(filter => ({ value: filter.value, label: filter.name }));
        return [EMPTY_FILTER_OPTION, ...convertedList];
    }
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getSearchFilters,
    getAllFilterFields,
    clearSearchFilters,
    getAppealTypes,
    setCurrentSearchFilter,
    setSearchFilterCriteria,
    initialize
}, dispatch);

function mapStateToProps (state,props){
	const { hash, entity } = props.match.params;
	const searchId = props.searchId || `${hash}-${entity}`;
    return {
		searchId: searchId,
        filterList: state.searchFilter.filterList,
        isFilterListLoading: state.searchFilter.isFilterListLoading,
        allFilterFields: _.get(state, `searchFilter.allFilterFields[#${entity}]`, []),
        areAllFilterFieldsLoading: state.searchFilter.areAllFilterFieldsLoading,
        currentFilter: state.searchFilter.prepopulatedFilters[searchId],
        selectFilterOptions: getSelectFilterOptions(state),
        appealTypes: state.searchFilter.appealTypes,
        formValues: getFormValues(`search-filters-${searchId}`)(state),
		filterObject:  _.get(state, `searchFilter.filters[${searchId}]`, []),
		currentSearchQuery: state.search.query,
	}
}

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SearchFilters extends React.Component {
    
    constructor () {
        super();
        
        this.state = {
            isFilterNameModalOpened: false,
            isFilterLoading: false,
            isPublic: false
        };
    }

    componentDidUpdate(prevProps) {
        const prevId = prevProps.searchId;
        const currId = this.props.searchId;
        if (prevId === currId) {
            const prevFormValues = prevProps.formValues;
            const currFormValues = this.props.formValues;
            const isFiltersChanged = prevFormValues !== currFormValues;
            if (isFiltersChanged) {
                this.onSubmit(currFormValues);
            } 
        }
    }
	
    requestAppealTypes = () => {
        if (this.props.appealTypes.length > 0) return;
        this.props.getAppealTypes();
    };
    
    requestAllFilterFields = () => {
        if (this.props.allFilterFields.length > 0) return;
        this.props.getAllFilterFields();
    };
    
    startFilterLoading = () => this.setState({ isFilterLoading: true });
    finishFilterLoading = () => this.setState({ isFilterLoading: false });
    
    requestFilter = filter => {
        const { allFilterFields, searchId, proxyCode } = this.props;
        const { entity } = this.props.match.params;
		
        this.startFilterLoading();
        const params = {
            data: {
                id: filter.value,
                code: proxyCode || Codes[entity]
            }
        };
        
        return baseService.get('info_filter', params)
            .then(response => {
                if (response.success && Array.isArray(response.result)) {
                    this.props.setCurrentSearchFilter(filter, searchId);
                    this.finishFilterLoading();

                    const filterValues = configureFilterValues(response.result, allFilterFields);
                    this.props.initialize(`search-filters-${searchId}`, filterValues);
                    console.log('FILTER VALUES: ', filterValues);
                    // console.log({searchId});
                } else {
                    throw new Error('Filter values request error');
                }
            })
            .catch(error => {
                console.error(error);
                this.finishFilterLoading();
            });
    };
    
    requestSearchFilters = () => {
        const requestData = { code: 'INTERACTION_REQUEST' };
        
        return this.props.getSearchFilters(requestData);
    };
    
    getSearchEntity = () => this.props.match.params.entity;
    
    onFilterChange = newFilter => {
        const { searchId } = this.props;
        if (newFilter && newFilter.value !== EMPTY_FILTER_OPTION.value) {
            this.requestFilter(newFilter);
		} else {
			this.props.initialize(`search-filters-${searchId}`, {});
            this.props.setCurrentSearchFilter(null, searchId);
        }
    };
    
    renderFilterSelect = () => {
        const { t } = this.props;
        
        return (
            <ComboBox
                options={this.props.selectFilterOptions}
                label={t('filters.useFilter')}
                name={'applyFilter'}
                value={this.props.currentFilter || EMPTY_FILTER_OPTION}
                onChange={this.onFilterChange}
                placeholder={'-'}
            />
        );
    };
    
    toggleIsPublicCheckBox = () => this.setState(state => ({ isPublic: !state.isPublic }));
    
    renderIsPublicCheckBox = () => {
        const { t } = this.props;
        
        return (
            <CheckBoxToggle
                id={'isPublic'}
                label={t('filters.isPublic')}
                checked={this.state.isPublic}
                onChange={this.toggleIsPublicCheckBox}
            />
        );
    };
    
    openFilterNameModal = () => this.setState({ isFilterNameModalOpened: true });
    closeFilterNameModal = () => this.setState({ isFilterNameModalOpened: false });
    
    getActionsPopupConfig = () => {
        const { t } = this.props;
        return [
            { label: t('filters.removeFilter'), action: console.log },
            { label: t('filters.changeName'), action: () => this.openFilterNameModal('changeName') }
        ];
    };
    
    onSubmit = values => {
        const { currentSearchQuery, match, filterObject, history, searchId, id, withoutSearchRedirect } = this.props;
        const newFilterObject = configureFilterObject(values, this.props.allFilterFields);

		const entity = this.getSearchEntity();

        console.log('%c FILTER CRITERIA: ', 'color: green', newFilterObject);
    
        const queryKey = this.props.match.params.entity;
        let sortSettings = [settingsService.get(`${queryKey}${SORT_POSTFIX}`)];
        this.props.onSearch({
            filterObject: newFilterObject,
            skipQueryCheck: true,
            type: entity,
            query: currentSearchQuery,
            sort: sortSettings
        }, 1);
        this.props.setSearchFilterCriteria(newFilterObject, `${id}-${entity}`);
        
        // this.props.initialize(`search-filters-${searchId}`, values);
        
        const urlQuery = decodeURIComponent(match.params.query || '');

        // console.log({currentSearchQuery, urlQuery, withoutSearchRedirect});
        if (!withoutSearchRedirect && (currentSearchQuery !== urlQuery)) {
            history.push(`/search/${entity}/${match.params.hash}/${currentSearchQuery || ''}`);
        }
    };
    
    createFilter = filterName => {
        console.log('NEW FILTER NAME: ', filterName);
		const entity = this.getSearchEntity();
        const params = {
            data: {
                name: filterName,
				type: entity,
                filterObject: configureFilterObject(this.props.formValues, this.props.allFilterFields),
                isPublic: this.state.isPublic
            }
        };
        
        return baseService.post('save_filter', params)
            .then(response => {
                console.log('FILTER SAVE RESPONSE', response);
            })
            .catch(console.error);
    };
    
    render () {
        const { t, id, areAllFilterFieldsLoading, match, customFormName } = this.props;
        const { isFilterNameModalOpened } = this.state;
		const idHashKeyRequest = `#${match.params.entity}`;
        return (
            <div className={cx(styles.wrapper)}>
				<FiltersHeader
					title={t('filters.extendedFilters')}
					popupConfig={this.getActionsPopupConfig()}
					showActions={false}
				/>
					<div className={styles.formWrapper}>
						{!areAllFilterFieldsLoading &&  this.renderFilterSelect()}
						{!areAllFilterFieldsLoading ?
                            <SearchFiltersForm
                                form={customFormName || `search-filters-${this.props.searchId}`}
                                // onSubmit={this.onSubmit}
                                openFilterNameModal={this.openFilterNameModal}
                                idHashKeyRequest={customFormName || idHashKeyRequest}
                            />
                        : <Loader withContainer/>
                    }
					</div>
                <FilterNameModal
                    isOpen={isFilterNameModalOpened}
                    onClose={this.closeFilterNameModal}
                    onSubmit={this.createFilter}
                />
            </div>
        );
    }
}

SearchFilters.propTypes = {
    filterList: PropTypes.array,
    isFilterListLoading: PropTypes.bool,
    onSearch: PropTypes.func
};

export default SearchFilters;
