import React from 'react';
import { connect } from 'react-redux';
import { closeCustomerServiceModal } from 'actions/ui';
import { ModalWindow } from 'ui-core-dashboard';
import { withTranslation } from 'react-i18next';
import Form from './Form';

const mapStateToProps = (state) => ({
    showCustomerServiceModal: state.ui.showCustomerServiceModal,
});

const mapDispatchToProps = (dispatch) => ({
    closeCustomerServiceModal: () => dispatch(closeCustomerServiceModal()),
});


@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class CustomerServiceModal extends React.Component {
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    onClose = () => this.props.closeCustomerServiceModal();

    handleKeyPress = (e) => {
        if (e.keyCode === 27) {
            this.onClose();
        }
    };

    render() {
        const { showCustomerServiceModal, t } = this.props;
        return (
            showCustomerServiceModal && <ModalWindow className="ordering-component-ui-core-wrapper" onClose={this.onClose}>
                <header className="modal-window-header">
                    <div className="modal-window-title">{t('newEntityButton.services')}</div>
                </header>
                <Form closeModal={this.onClose}/>
            </ModalWindow>
        );
    }
}

export default CustomerServiceModal;
