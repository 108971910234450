import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { createStructuredSelector } from 'reselect';

import { initProxy, setCurrentTab, updateCurrentTabUrl } from 'actions/index';
import { contentReset } from 'actions/content';

const ContentProxy = (WrappedComponent, options) => {
	class proxyClass extends React.Component {
		componentDidMount() {
			this.check(this.props);
		}
		
		componentDidUpdate() {
			this.check(this.props);
		}
		
		componentWillUnmount () {
			this.props.contentReset(options.storeName(options.params));
		}
		
		check ({ match, tabs, content, location }) {
			// console.log({match, tabs, content, location, options, tabType});
			const tabType = options.tabType(match.params);
			if (!tabs.current) {
				console.error("ContentTabProxy::check: No tabs.current: ", tabs);
			}
			if (tabs.current && tabs.current.type !== tabType) {
				this.props.setCurrentTab({ ...options, ...match, tabType, });
			} else {
				if (tabs.current && tabs.current.type === tabType && location && tabs.current.url !== location.pathname) {
					this.props.updateCurrentTabUrl(location.pathname, tabs.current.displayedName);
				}
				
				const isMatch = val => match.params[val];
				const queries = tabs.current && tabs.current.queries;
				
				if (options.queryKey) {
					this.queryKey = options.queryKey(match.params);
					this.queryData = this.queryKey && queries ? queries[this.queryKey] : null;
				}
				
				if ((content.update && content.update.some(isMatch)) || this.url !== match.url) {
					!options.noRequest && this.get(match, tabs);
				}
			}
		}
		
		get = (match, tabs) => {
			const api = options.api(match.params, this.props.location.pathname);
			const queryApi = tabs.current.api || {};
			
			queryApi.data = queryApi.key === api.key && queryApi.path === api.path ? queryApi.data : {};
			options.params = match.params;
			
			if (this.queryData) {
				const queryData = { page: 1, limit: 30, ...this.queryData };
				
				if (options.elasticEncode) {
					queryData.filter = options.elasticEncode(match.params, queryData.filter);
					
					if (queryApi.data && queryApi.data.filter) {
						queryData.page = isEqual(queryApi.data.filter, queryData.filter) ? queryData.page : 1;
					}
				}
				
				if (api.data && api.data.query && queryData.query !== api.data.query) {
					queryData.page = 1;
				}
				
				queryData.start = (queryData.page - 1) * queryData.limit;
				
				api.data = { ...queryData, ...api.data };
			}
			this.props.initProxy(api, { ...options }, match.url);
			this.url = match.url;
		};
		
		getData = () => {
			const { content, ...rest } = this.props;
			return {
				...rest,
				data: {
					queryKey: this.queryKey,
					queryData: this.queryData || {},
					success: options.emptyPage,
					...content.data
				}
			};
		};
		
		render () {
			const data = this.getData();
			return this.url || options.noRequest ? <WrappedComponent {...data} refresh={this.get} /> : null;
		}
	}
	
	const mapStateToProps = createStructuredSelector({
		content: createStructuredSelector({
			data: ({ content }) => content[options.storeName(options.params)],
			update: ({ content }) => content.update
		}),
		tabs: createStructuredSelector({
			current: ({ tabs }) => tabs.current
		})
	});
	
	return connect(mapStateToProps, { initProxy, setCurrentTab, contentReset, updateCurrentTabUrl })(proxyClass);
};

ContentProxy.propTypes = {
	match: PropTypes.object.isRequired,
	url: PropTypes.string.isRequired,
	content: PropTypes.object.isRequired,
	tabs: PropTypes.object.isRequired,
	initProxy: PropTypes.func,
	setCurrentTab: PropTypes.func,
	contentReset: PropTypes.func
};

export default ContentProxy;
