import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SearchInput from 'components/SearchPage/SearchInput';

import styles from 'styles/modules/searchPage.module.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { createCheckPropsFunction } from '../../helpers';
import isString from 'lodash/isString';

function mapStateToProps (state, props) {
	return {
		isAddingActive: state.tabs.isAddingActive,
		tabs: state.tabs
	};
}

@withRouter
@connect(mapStateToProps)
class InputBlock extends React.Component {
	
	constructor () {
		super();
		
		this.state = {
			results: []
		};
		
		this.searchNodeRef = React.createRef();
		this.isFirstRequest = false;
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		
		if (isPropChanged('match.params.entity') || isPropChanged('match.params.hash')) {
			this.setState({ results: [] });
			this.isFirstRequest = true;
		}
	}
	
	static getDecodedQuery (query) {
		if (query && isString(query)) return decodeURIComponent(query);
		
		return '';
	};
	
	getSearchEntity = () => this.props.match.params.entity;
	
	onInputChange = debounce(() => {
		this.setState({ results: [] });
	}, SEARCH_TIMER_INTERVAL);
	
	render () {
		const { onSubmit, id } = this.props;
		
		return (
			<div
				className={cx(styles.searchField)}
				ref={this.searchNodeRef}
			>
				<SearchInput
					onInputChange={this.onInputChange}
					onSubmit={onSubmit}
					id={id}
					query={this.props.query}
					setSearchFilterCriteria={this.props.setSearchFilterCriteria}
				/>
			</div>
		);
	}
}

InputBlock.propTypes = {
	hideLiveSearch: PropTypes.func,
	match: PropTypes.object,
	tabs: PropTypes.object,
	history: PropTypes.object,
	searchResults: PropTypes.array,
	openedSearch: PropTypes.bool,
	getLiveSearchSuppose: PropTypes.func,
	changeQuery: PropTypes.func,
	openLiveSearch: PropTypes.func,
	isAddingActive: PropTypes.bool,
};

export default InputBlock;
