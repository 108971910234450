import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/knowledgeBase.module.scss';
import ReactSVG from 'react-svg';

export default function ArticleFiles ({ hidden, title, files }) {
	if (hidden) return null;
	
	return (
		<div className={styles.articleBlock}>
			<div className={styles.articleTitle}>{title}</div>
			<div className={styles.articleDescription}>
				{files.map((file, key) => (
					<div className="kb-file-wrapper" key={`${file.id}-${key}`}>
						<a className="file-preview" target="_blank" href={`../mw/file?fileId=${file.id}&download=0`} title={file.name}>
						{file.name}
						</a>
						<div className="kb-file-actions">
							<a className="file-download" target="_blank" href={`../mw/file?fileId=${file.id}&download=1`} title={file.name}>
								<ReactSVG className="svg-download" src="/data/svg/download.svg"/>
							</a>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

ArticleFiles.propTypes = {
	hidden: PropTypes.bool,
	title: PropTypes.string,
	files: PropTypes.array
};
