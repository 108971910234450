import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import Tab from 'components/Tab';
import styles from 'styles/modules/searchPage.module.scss';
import { withTranslation } from 'react-i18next';
import GridActionsPopup from './GridActionsPopup';
import { updateCurrentTabField } from 'actions/tabs';
import { bindActionCreators } from 'redux';
import { queriesContentUpdate } from 'actions/content';
import { safeQuery } from 'helpers';
import permissions, { checkPermissions } from '../../config/permissions';
import EventsList from './EventsList';
import ResourcesList from './ResourcesList';
import ServicesList from './ServicesList';
import TasksList from './TasksList';
import CustomersList from './CustomersList';
import AppealsList from './AppealsList';
import ContactPersonList from './ContactPersonList';



import { updateCurrentTabUrl } from '../../actions';
import { setCurrentTab } from 'actions/index';

// appeal, contact_person grids are still not "dynamic"
const SUPPORTED_TABS = ['task', 'resource', 'spec', 'clevent', 'customer', 'appeal', 'contact_person'];

// const SUPPORTED_EXPORT = ['clevent', 'contact_person', 'customer', 'appeal'];

const tabContent = {
    task: 'task',
    resource: 'resource',
    spec: 'spec',
    clevent: 'clevent',
    contact_person: 'contact_person',
    customer: 'customer',
    appeal: 'appeal',
};

function mapStateToProps(state, props) {
    const { hash, entity } = props.match.params;
    const searchId = `${hash}-${entity}`;

    let total = state.search.total;

    // todo: all grids except clevent (calendar) could be unified into a single one configurable via props
    if (entity === 'task') {
        total = _.get(state, `grid.task_${hash}.result.total`, 0);
    }

    if (entity === 'resource') {
        total = _.get(state, `grid.resource_${hash}.result.total`, 0);
    }

    if (entity === 'spec') {
        total = _.get(state, `grid.service_${hash}.result.total`, 0);
    }

    if (entity === 'clevent') {
        total = _.get(state, `grid.calendar_events_${hash}.result.total`, 0);
    }

    if (entity === 'customer') {
        total = _.get(state, `grid.customers_${hash}.result.total`, 0);
    }

    if (entity === 'appeal') {
        total = _.get(state, `grid.appeal_${hash}.result.total`, 0);
    }

    if (entity === 'contact_person') {
        total = _.get(state, `grid.contact_person_${hash}.result.total`, 0);
    }

    return {
        tabs: state.tabs,
        results: state.search.results,
        total,
        canSearchCustomer: checkPermissions(permissions.SearchOperations.search_customer),
        canSearchContactPersons: checkPermissions(
            permissions.SearchOperations.search_contactPersons,
        ),
        canSearchEvents: checkPermissions(permissions.CalendarOperations.view_calendar_event),
        canSearchServices: checkPermissions(permissions.SearchOperations.search_spec),
		canSearchResource: checkPermissions(permissions.SearchOperations.search_res),
        canSearchTasks: checkPermissions(permissions.SearchOperations.search_order_task),
        currentSearchQuery: state.search.query,
        searchId: searchId,
        filterList: state.searchFilter.filterList,
        allFilterFields: _.get(state, `searchFilter.allFilterFields[#${entity}]`, []),
        currentFilter: state.searchFilter.prepopulatedFilters[searchId],
        // selectFilterOptions: getSelectFilterOptions(state),
        filterObject: _.get(state, `searchFilter.filters[${searchId}]`, []),
		isExportAllowed: checkPermissions(permissions.SearchOperations.search_export),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                updateCurrentTabField,
                queriesContentUpdate,
            },
            dispatch,
        ),
        setCurrentTab: (payload) => dispatch(setCurrentTab(payload)),
        updateCurrentTabUrl: (path) => dispatch(updateCurrentTabUrl(path)),
    };
}

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class TabContentNewGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopupOpen: false,
        };
        this.togglePopup = this.togglePopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.getPath = this.getPath.bind(this);
        this.renderTab = this.renderTab.bind(this);
    }

    componentDidMount() {
        const currentTab = this.props.tabs.current;
        const { hash, entity } = this.props.match.params;
        const urls = SUPPORTED_TABS.map((tab) => this.getPath(tab));
        if (!currentTab || !urls.includes(currentTab.url)) {
            this.updateCurrentTab();
        }
        if (currentTab && currentTab.type === `search_${hash}` && !urls.includes(currentTab.url)) {
            // console.log("*** UPDATE TAB URL ON MOUNT ***");
            this.props.updateCurrentTabUrl(this.getPath(entity));
        }
    }

    componentDidUpdate(prevProps) {
        const prevHash = prevProps.match.params.hash;
        const prevEntity = prevProps.match.params.entity;
        const { hash, entity } = this.props.match.params;
        if (prevHash !== hash) {
            // console.log("*** UPDATE CURRENT TAB ***");
            this.updateCurrentTab();
        }
        if (prevHash === hash && prevEntity !== entity) {
            // console.log("*** UPDATE CURRENT TAB ***");
            this.updateCurrentTab();
        }
    }

    updateCurrentTab() {
        const { hash, entity } = this.props.match.params;
        const url = this.getPath(entity);
        this.props.setCurrentTab({
            noRequest: true,
            isExact: true,
            params: this.props.match.params,
            path: this.props.match.path,
            url: url,
            name: () =>
                this.props.currentSearchQuery
                    ? decodeURIComponent(this.props.currentSearchQuery)
                    : 'search',
            queryKey: () => entity,
            storeName: () => entity,
            tabType: `search_${hash}`,
        });
    }

    togglePopup() {
        this.setState((prevState) => ({ isPopupOpen: !prevState.isPopupOpen }));
    }

    closePopup() {
        if (this.state.isPopupOpen) this.setState({ isPopupOpen: false });
    }

    getPath(val) {
        const aPath = this.props.history.location.pathname.split('/');
        aPath[2] = val;
        return safeQuery(aPath.join('/'));
    }

    renderTab(tab, index) {
        const url = this.getPath(tab);
        const onClick = (tab) => {
            this.props.actions.updateCurrentTabField('queryKey', tab);
            this.props.actions.updateCurrentTabField('url', url);
        };
        return <Tab val={tab} key={index} getPath={() => this.getPath(tab)} onClick={onClick} />;
    }

    // onSelectItem (item) {
    // 	const openedTab = this.props.match.params.entity;
    // 	let newPath;

    // 	switch (openedTab) {
    // 		case 'customer':
    // 			newPath = `/customer/${item.id}/appeals`;
    // 			break;
    // 		case 'appeal':
    // 			newPath = `/appeals/${item.id}/`;
    // 			break;
    // 		case 'contact_person':
    // 			newPath = `/customer/${item.customerId}/person/${item.id}/contact`;
    // 			break;
    // 		default:
    // 			newPath = '';
    // 	}

    // 	if (newPath) this.props.history.push(newPath);
    // };

    render() {
        const {
            results,
            total,
            match,
            tabs,
            t,
            canSearchContactPersons,
            canSearchCustomer,
            canSearchEvents,
            canSearchServices,
            canSearchResource,
            canSearchTasks,
            filterObject,
            searchId,
            isExportAllowed
        } = this.props;
        const { entity } = match.params;

        const tabData = {
            result: results[entity],
            total,
            tabName: entity,
        };

        const tabContentClone = { ...tabContent };

        const iconClass = cx('icon-kebab-vert', styles.actionButton, {
            [styles.hide]: results.length === 0,
        });

        if (!canSearchContactPersons) {
            delete tabContentClone.contact_person;
        }

        if (!canSearchCustomer) {
            delete tabContentClone.customer;
        }

        if (!canSearchEvents) {
            delete tabContentClone.clevent;
        }

        // if (!canSearchServices) {
        //     delete tabContentClone.resource;
        //     delete tabContentClone.spec;
        // }
        if (!canSearchServices) {
            delete tabContentClone.spec;
        }

        if (!canSearchResource) {
            delete tabContentClone.resource;
        }

        if (!canSearchTasks) {
            delete tabContentClone.task;
        }

        return (
            <Fragment>
                <div className={styles.searchHeader}>
                    <div className={styles.resultsTitle}>
                        <span className={styles.title}>{t('searchResult')}</span>
                        <span className={styles.total}>{total}</span>
                    </div>
                    <div className={styles.tabsWrapper}>
                        <div id="card-bottom-row" className="column">
                            <div className="top-right">
                                {Object.keys(tabContentClone).map(this.renderTab)}
                            </div>
                        </div>
                        {/* {SUPPORTED_EXPORT.includes(entity) && ( */}
                        {isExportAllowed && <button className={iconClass} onClick={this.togglePopup} />}
                        {/* )} */}
                        {this.state.isPopupOpen && <GridActionsPopup isExportAllowed={isExportAllowed} onClose={this.closePopup} />}
                    </div>
                </div>
                <div
                    className={cx(
                        'contentGridTab',
                        'gridWrapper',
                        'ordering-component-ui-core-wrapper',
                    )}
                >
                    {entity === 'resource' && (
                        <ResourcesList filterObject={filterObject} searchId={searchId} />
                    )}
                    {entity === 'spec' && (
                        <ServicesList filterObject={filterObject} searchId={searchId} />
                    )}
                    {entity === 'clevent' && (
                        <EventsList filterObject={filterObject} searchId={searchId} />
                    )}
                    {entity === 'task' && (
                        <TasksList filterObject={filterObject} searchId={searchId} />
                    )}
                    {entity === 'customer' && (
                        <CustomersList filterObject={filterObject} searchId={searchId} />
                    )}
                    {entity === 'appeal' && (
                        <AppealsList filterObject={filterObject} searchId={searchId} />
                    )}
                    {entity === 'contact_person' && (
                        <ContactPersonList filterObject={filterObject} searchId={searchId} />
                    )}
                </div>
            </Fragment>
        );
    }
}

export default TabContentNewGrid;
