import React, { useState, useEffect } from 'react';
import cx from "classnames";
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, submit, getFormValues } from 'redux-form';
import { withTranslation } from 'react-i18next';
import InputField from '../../Common/InputField';
import ComboBox from '../../Common/ComboBox';
import {ComboBox as ComboBoxCore} from 'ui-core-dashboard';
import validate from './validate';
import BaseService from '../../../services/BaseService';
import { getDynamicFieldsContactPerson, renderDynamicFields } from '../utils';
import { createNewContactPerson } from 'actions/customer';

import { convertDynamicFields } from 'helpers';
// import i18next from 'util/i18n';
import _, { isEmpty } from 'lodash';
import { getContactPerson, getCustomer } from 'actions/customer';
import { addCustomerToAppeal, getAppealFeedback, unlockAppealForm } from 'modules/appeal/actions';
import {initialBody, setContacts, setProps, setLanguage } from "../../CreateNewContactPersonModal/CreateNewContactPersonForm";
import { capitalizeProps, trimProps, } from 'util/form';
import { personalDataBlocks, searchCustomerByNameBlocks, getMergedStaticBlocks, getModalFormDataFromState } from "../config";
import Loader from "../../Loader";

function ContactPersonForm (props) {
	const { t, setIsLoading, setDynConfig, formValues, filterId, incomePhoneNumber, personalDataBlock, searchCustomerByNameBlock, modalFormData, formCode, customerTypeId, isSubmitDisabled } = props;

	useEffect(() => {
		if (!props.pristine) {
			props.enableWarning();
		}
	}, [props.pristine]);
	const partyType = formValues && _.get(formValues, 'customer.partyType', null);
	const customerId = formValues && _.get(formValues, 'customer.value', null);
	const [options, setOptions] = useState([]);
	const [dynParamsContactPerson, setDynParamsContactPerson] = useState(null);
	const [fetching, setFetching] = useState(false);
	
	useEffect( () => {
		if (partyType) {
		setFetching(true);
			async function fetchData() {
				const responseContactPerson = await getDynamicFieldsContactPerson(partyType, 'CONTACT_PERSON', customerId, customerTypeId);
				setDynParamsContactPerson(responseContactPerson && responseContactPerson);
				if (responseContactPerson){
					setFetching(false);
					if (Array.isArray(responseContactPerson)) {
					await setDynConfig(responseContactPerson);
					}
				}
			}
		fetchData();
		}
		return () => {
			setDynParamsContactPerson(null);
		}
	}, [partyType]);
	
	
	useEffect(() => {
		if (incomePhoneNumber) {
			props.change('workPhone', incomePhoneNumber);
		}
	}, [incomePhoneNumber]);


	// update hiddenValues
	useEffect(() => {
		if (formCode) {
			const hiddenValuesFields = Object.keys(modalFormData).flatMap(key => modalFormData[key].sortedAttrs).filter(field => field.hidden && field.hiddenValues);
			hiddenValuesFields.forEach(field => {
				props.change(field.field, field.hiddenValues);
			});
		}
	}, [formCode]);

	function convertDictionaryObject (dictionaryObject) {
		return Object.entries(dictionaryObject).map(([prop, value]) => ({
			value: prop,
			label: value
		}));
	}
	
	const getOptions = _.debounce((query, callback) => {
		if (query && query.trim().length > 2) {
			const data = {
				page: 1,
				limit: 10,
				start: 0,
				filterId: filterId,
				type:'customer',
				query,
			};
			BaseService.post('get_appeals', { data, jsonType: false } )
				.then(response => {
					callback(null, { options: response.result.map((i) => ({ value: i.id, label: i.name, partyType: i.partyType })) });
				})
				.catch(console.error);
		} else {
			callback(null, { options: [] });
		}
	}, 500);
	
	function getFieldPropsWithOptions (fieldProps) {
		switch (fieldProps.name) {
			case 'customer':
			return {
				...fieldProps,
				options: options,
				loadOptions: getOptions,
			};
		// case 'issues':
		// 	return {
		// 		...fieldProps,
		// 		options: convertDictionaryObject(t('dictionary:contactIssue', { returnObjects: true })).slice(1)
		// 	};
		case 'issues': {
			let options = convertDictionaryObject(t('dictionary:contactIssue', { returnObjects: true }));
			// if (fieldProps.hiddenValues && Array.isArray(fieldProps.hiddenValues)) {
			// 	options = options.filter(option => !fieldProps.hiddenValues.includes(option.value));
			// }
			return {
				...fieldProps,
				options,
				// options: options.slice(1)
				// options: convertDictionaryObject(
				//     t('dictionary:contactIssue', { returnObjects: true }),
				// ).slice(1),
			};
		}
		default:
			return fieldProps;
		}
	}
	
	function renderField (fieldConfig) {
		if (fieldConfig.hidden) {
            return (
                <div className="hidden">
                    <Field key={fieldConfig.name} {...getFieldPropsWithOptions(fieldConfig)} required={false} />
                </div>
            );
        }
		return (
			<Field key={fieldConfig.name} {...getFieldPropsWithOptions(fieldConfig)} />
		);
	}
	
	async function submit (values) {
		setIsLoading(true);
		const contacts = [];
		
		if (values.workPhone) {
			contacts.push({
				type: 'workPhone',
				val: values.workPhone
			});
		}
		
		if (values.email) {
			contacts.push({
				type: 'email',
				val: values.email
			});
		}
		
		let dynParams;
		
		if(dynParamsContactPerson) {
			//prepeared  & separation dyn params from form
			const dynParamsContactPersonConfig = _.flattenDeep(!dynParamsContactPerson ? [] : dynParamsContactPerson.map(item => item.widgets && item.widgets));
			const prepearedDynParamscontactPerson = _.pickBy(values, (item, key) => dynParamsContactPersonConfig.some(conf => conf.key === key));
			
			dynParams = convertDynamicFields(prepearedDynParamscontactPerson);
		}
		const data = { ...initialBody, customerId, dynParams };
		
		setProps({ data, values, props: ['lastName', 'firstName', 'patronymic', 'issues', 'validFrom'] });
		setLanguage({ data, values });
		setContacts({ data, values, props: ['workPhone', 'email'] });
		trimProps(data);
		capitalizeProps(data, ['lastName', 'firstName', 'patronymic']);
		
		const params = {
			data,
			path: 'save',
			jsonType: true
		};
		if (isSubmitDisabled) {
            return;
        }
		const contactPersonId = await props.createNewContactPerson(params, t);
		setIsLoading(false);
		if (contactPersonId) {
			await props.onClose();

			if (typeof (_.get(props.newCustomerModalContext, 'callback')) === 'function') {
				const callback = _.get(props.newCustomerModalContext, 'callback');
				await callback(customerId,contactPersonId);
			}
			if (props.openNewTab) {
				props.history.push(`/customer/${customerId}/person/${contactPersonId}/appeals`);
			} else {
				const appealId = _.get(props.match, 'params.appealId');
				
				props.addCustomerToAppeal(appealId, customerId, contactPersonId);
				props.getCustomer({ customerId }, customerId);
				props.getContactPerson({ id: contactPersonId }, customerId);
				props.getAppealFeedback({ appealId, customerId });
			}
		}
	}
	
	return (
		<form onSubmit={props.handleSubmit(submit)} className='ordering-component-ui-core-wrapper'>
			<div className='create-customer-form-container'>
				{/* <div className='form-wrapper customer-search'> */}
				<div className={cx("form-wrapper customer-search", { hidden: !searchCustomerByNameBlock })}>
					<header>{t(searchCustomerByNameBlock && searchCustomerByNameBlock.translationLabel)}</header>
					{props.objectConfig.map(renderField)}
				</div>
				<div className={cx("form-wrapper", { hidden: !personalDataBlock || personalDataBlock.hidden })}>
					<header>{t(personalDataBlock && personalDataBlock.translationLabel)}</header>
					{props.personConfig.map(renderField)}
					<div className="additional-data">
						{fetching && !dynParamsContactPerson && <div className='loader-box'> <Loader/> </div>}
						{dynParamsContactPerson && !fetching && dynParamsContactPerson.map(renderDynamicFields)}
					</div>
				</div>
			</div>
			
			<footer className='create-customer-footer'>
				<button type='submit' disabled={isSubmitDisabled} className={cx("btn btn-primary", { disabled: isSubmitDisabled })}>
					<i className='icon icon-check' />
					{t('create')}
				</button>
			</footer>
		</form>
	);
}

export const personConfig = (t, staticData) => {
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, personalDataBlocks, {}, "contactPerson");
	return mergedStaticBlocks;
};

export const customerConfig = (t, staticData) => {
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, searchCustomerByNameBlocks, {}, "contactPerson");
	return mergedStaticBlocks;
};

function mapStateToProps (state, ownProps) {
	const { t, dynConfig, formCode } = ownProps;
	const modalFormData = getModalFormDataFromState(state, formCode);
	// console.log({modalFormData, formBlocks, ownProps, formCode });
	const { custAddress: custAddressBlock, juridicalData: juridicalDataBlock, personalData: personalDataBlock, searchCustomerByName: searchCustomerByNameBlock } = modalFormData;
	
	const match = matchPath(window.location.hash.substring(1), {
		path: '/appeals/:appealId/:tab',
		exact: true,
		strict: false
	});
	const incomePhoneNumber = state.call.incomePhoneNumber;

	// const formBlocks = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM || {} ;
	// const { custAddress: custAddressBlock, juridicalData: juridicalDataBlock, personalData: personalDataBlock } = formBlocks;

	return {
		formValues: getFormValues('contact-person-form')(state),
		match,
		incomePhoneNumber,
		personConfig: personConfig(t, personalDataBlock),
		objectConfig: customerConfig(t, searchCustomerByNameBlock),
		config: [...customerConfig(t, searchCustomerByNameBlock), ...personConfig(t, personalDataBlock), ...customerConfig(t), ...dynConfig],
		newCustomerModalContext: state.ui.newCustomerModalContext,
		custAddressBlock,
		juridicalDataBlock,
		personalDataBlock,
		searchCustomerByNameBlock,
		modalFormData,
	};
}

const mapDispatchToProps = {
	submitForm: form => submit(form),
	getCustomer,
	getContactPerson,
	unlockAppealForm,
	addCustomerToAppeal,
	getAppealFeedback,
	createNewContactPerson
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'contact-person-form',
	validate
})(ContactPersonForm))));
