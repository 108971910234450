import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

function EmailItem ({data}){
		const { originator } = data;
		const originatorTitle = originator && originator.channelName;
		const originatorContent = originator && originator.originator;
	
		return (
			<li className='history-item'>
				<i className='icon-envelope' />
				<div className='history-item-content'>
					<span className='history-title-text'>{originatorTitle }: </span>
					<span className='history-content-text'>{originatorContent}</span>
				</div>
			</li>
		);
}

EmailItem.propTypes = {
	data: PropTypes.object,
};

export default EmailItem;
