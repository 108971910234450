import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../common.scss';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

function DateTimePickerCalendarRange(props) {
    const {
        changeOnBlur,
        onlyDate,
        label,
        disabled,
        meta,
        required,
        input,
        t,
        popperPlacement,
        popperClassName,
        dayFormat = 'DD.MM.YYYY',
        dayAndTimeFormat = 'DD.MM.YYYY HH:mm',
        inputClass,
        ...rest
    } = props;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [countClick, setCountClick] = useState(0);
    const handleChange = (date, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (!event) {
            setStartDate(moment(date).startOf('day'));
            setEndDate(null);
            return setCountClick(1);
        }
        if (countClick === 1 && moment(date).isBefore(startDate)) {
            setStartDate(moment(date).startOf('day'));
            // setEndDate(null);
            // return setCountClick(1);
            setEndDate(moment(date).endOf('day'));
            return setCountClick(1);
        }
        switch (countClick) {
            case 0:
                // setEndDate(null);
                setStartDate(moment(date).startOf('day'));
                setEndDate(moment(date).endOf('day'));
                setCountClick(1);
                break;
            case 1:
                setEndDate(moment(date).endOf('day'));
                setCountClick(0);
                break;
            default:
                setEndDate(null);
                setStartDate(null);
                setCountClick(0);
                break;
        }
    };

	const handleSelect = (data, event) => {
		// here handle some logic for selecting year / month etc
	};

    useEffect(() => {
        input.onChange({ startDate, endDate });
    }, [startDate, endDate]);

    const elementCls = cx('input-element datepicker', 'calendar-date-picker', {
        error: meta && meta.touched && (meta.error || meta.warning),
    });

    const inputClassName =
        inputClass ||
        cx('input-field', {
            'input-field__error': meta && meta.touched && (meta.error || meta.warning),
        });

    const popperCls = cx('datepicker-popper datepicker-portal', popperClassName);

    const selectedItem = moment(input.value).isValid() && input.value ? moment(input.value) : null;

    return (
        <div id={rest.id} className={elementCls}>
            {label && (
                <div className="input-label">
                    {t(`customerInfoLabel.${label}`, { defaultValue: label })}
                    {required && <span className="required-field">*</span>}
                </div>
            )}
            <DatePicker
                className={inputClassName}
                dateFormat={onlyDate ? dayFormat : dayAndTimeFormat}
                popperClassName={popperCls}
                popperPlacement={popperPlacement || 'bottom-end'}
                calendarClassName="calendar"
                disabled={disabled}
                name={input.name}
                onBlur={(e) => false}
                onSelect={handleSelect}
                onChange={handleChange}
                autoComplete="off"
                shouldCloseOnSelect={false}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={108}
                maxDate={moment().add(10, 'y')}
                minDate={moment().subtract(10, 'y')}
                selectsRange
                selected={input.value.startDate || startDate}
                openToDate={input.value.startDate}
                startDate={input.value.startDate || startDate}
                endDate={input.value.endDate || endDate}
                inline
                adjustDateOnChange
            />
        </div>
    );
}

DateTimePickerCalendarRange.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool,
};

export default withTranslation()(DateTimePickerCalendarRange);
