import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from 'styles/modules/knowledgeBase.module.scss';

export default function DisplayChangeData ({ label, timestamp, dateFormat = 'DD/MM/YYYY HH:mm', link }) {
	if (!timestamp) return null;
	return (
		<div className={styles.displayChangeData}>
			<label>{label}:</label>
			<span>{moment(timestamp).format(dateFormat)}</span>
			<span> / </span>
			<span className='operatorName'>{link}</span>
		</div>
	);
}

DisplayChangeData.propTypes = {
	label: PropTypes.string,
	timestamp: PropTypes.number,
	dateFormat: PropTypes.string,
	link: PropTypes.string,
	onClick: PropTypes.func
};
