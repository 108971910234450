import cloneDeep from 'lodash/cloneDeep';
import flatMap from 'lodash/flatMap';
import isObjectLike from 'lodash/isObjectLike';
import moment from 'moment';
// import { searchTree, buildThreePath } from 'helpers';

const DEFAULT_DATE_VIEW_FORMAT = "DD.MM.YYYY HH:mm";


/**********************************************************
 *
 *           CONFIGURE FILTER CRITERIA OBJECT
 *
 **********************************************************/

/**
 * @param values {Object} values from redux-form
 * @param attributes {Array} all field configs
 */
export function configureFilterObject (values, attributes, dateSaveFormat) {
	let data = cloneDeep(attributes);
	if (data && values) {
		Object.keys(values).forEach(fieldName => {
			
			const filterCode = getCodeFromFieldName(fieldName);
			const filterIndex = data.findIndex(filter => filter.code === filterCode);
			
			if (filterIndex !== -1) {
				const filterData = data[filterIndex];
				
				if (['DATE', 'DATENAVMO'].includes(filterData.type) && filterData.def_condition === 'BTW') {
					setDateValue(filterData, fieldName, values[fieldName], dateSaveFormat);
				}
				else if (filterData.type === 'DATECAL' && filterData.def_condition === 'BTW') {
					setRangeDateValue(filterData, fieldName, values[fieldName], dateSaveFormat);
				}
				else {
					filterData.value = values[fieldName];
				}
			}
		});
		data = data.filter(filter => Boolean(filter.value));
		
		return flatMap(data, getRequestFilterObject)
			.filter(filter => Boolean(filter.value));
	}
	return [];
}

function setDateValue (filterData, fieldName, fieldValue, dateSaveFormat) {
	let parsedFieldValue = fieldValue;
	if (dateSaveFormat) {
		// check if fieldValue is in the same format as dateSaveFormat, if not - then try to parse it
		const isValid = moment(fieldValue, dateSaveFormat, true).isValid();
		if (!isValid) {
			const isDefaultFormat = moment(fieldValue, DEFAULT_DATE_VIEW_FORMAT, true).isValid();
			if (isDefaultFormat) {
				// convert value to correct dateSaveFormat
				const correctValue = moment(fieldValue, DEFAULT_DATE_VIEW_FORMAT).format(dateSaveFormat);
				parsedFieldValue = correctValue;
			} else {
				// if (fieldName.endsWith('@FROM')) {
				// 	parsedFieldValue = filterData.value ? filterData.value.from : null;
				// }
				// if (fieldName.endsWith('@TO')) {
				// 	parsedFieldValue = filterData.value ? filterData.value.to : null;
				// }

				// send null value as input is not correct
				parsedFieldValue = null;
			}
		}
	}
	if (fieldName.endsWith('@FROM')) {
		filterData.value = { ...filterData.value, from: parsedFieldValue };
	}
	if (fieldName.endsWith('@TO')) {
		filterData.value = { ...filterData.value, to: parsedFieldValue };
	}
}

function setRangeDateValue (filterData, fieldName, fieldValue, dateSaveFormat) {
		// if (dateSaveFormat) {
		// 	console.log({filterData, fieldName, fieldValue, dateSaveFormat});
		// }
		filterData.value = { ...filterData.value, from: fieldValue.startDate, to: fieldValue.endDate };
}

function getRequestFilterObject (filterData) {
	
	if (['LIST','LIST_CHECKBOX'].includes(filterData.type) && Array.isArray(filterData.value)) {
			return filterData.value.map(filterValue => ({
			id: filterData.id,
			condition: filterData.def_condition,
			value: getFilterValue(filterValue),
			label: filterValue.label,
		}));
	}
	
	if (filterData.type === 'THREE' && Array.isArray(filterData.value)) {
		return filterData.value.map(filterValue => ({
			id: filterData.id,
			condition: filterData.def_condition,
			value: getFilterValue(filterValue),
			label: filterValue.label,
		}));
	}
	
	if (['DATE','DATECAL','DATENAVMO'].includes(filterData.type) && filterData.def_condition === 'BTW') {
		return [{
			id: filterData.id,
			condition: 'GEQ',
			value: getFilterValue(filterData.value.from)
		}, {
			id: filterData.id,
			condition: 'LEQ',
			value: getFilterValue(filterData.value.to)
		}];
	}
	
	return {
		id: filterData.id,
		condition: filterData.def_condition,
		value: getFilterValue(filterData.value)
	};
}

function getFilterValue (filterFieldValue) {
	if (moment.isMoment(filterFieldValue)) {
		return filterFieldValue.isValid() ? filterFieldValue.format('YYYY-MM-DDTHH:mm:ss') : null;
	}
	
	if (isObjectLike(filterFieldValue)) return filterFieldValue.value;
	
	return filterFieldValue;
}

/**
 * @param {String} fieldName
 */
function getCodeFromFieldName (fieldName) {
	if (fieldName.endsWith('@FROM')) {
		return getStringWithoutEnding(fieldName, '@FROM');
	}
	if (fieldName.endsWith('@TO')) {
		return getStringWithoutEnding(fieldName, '@TO');
	}
	
	return fieldName;
}

/**
 * @param {String} string
 * @param {String} ending
 */
function getStringWithoutEnding (string, ending) {
	const endingIndex = string.lastIndexOf(ending);
	
	if (endingIndex === -1) return string;
	
	return string.substring(0, endingIndex);
}

/**********************************************************
 *
 *               CONFIGURE FILTER VALUES
 *
 **********************************************************/

/**
 * @param {Array} filterFields
 * @return {Object} filterValues
 */
export function configureFilterValues (filterFields, allFilterAttr) {
	const convertedConfigFields = filterFields.map(itm => ({
		...allFilterAttr.find((item) => (item.id === itm.paramId) && item),
		...itm
	}));
	return setInitialValues(convertedConfigFields);
}

function setInitialValues (paramsNameArray) {
	const initObj = {};
	
	paramsNameArray.forEach(field => {
		const fieldName = field.code;
		
		if (field.type === 'THREE') {
			const prevData = initObj[fieldName] || [];
			initObj[fieldName] = [
				...prevData, 
				{ id: field.value, key: field.value, label: field.label, value: field.value, path: "" }
			];
			// const options = buildThreePath(field.dict, false, { children: 'children' });
			// if (paramsNameArray.length) {
			// 	const currentAppealType = [];
			// 	paramsNameArray.map((i) => {
			// 		const item = searchTree(options, 'children', result => {
			// 			return i.value === result.key;
			// 		});
					
			// 		if (item) {
			// 			currentAppealType.push({
			// 				id: item.key,
			// 				key: item.key,
			// 				value: item.key,
			// 				label: item.name,
			// 				path: item.path
			// 			});
			// 		}
			// 	});
				
			// 	if (currentAppealType) {
			// 		initObj[fieldName] = currentAppealType;
			// 	}
			// }
		} else if (field.type === 'DATE') {
			const foundValues = paramsNameArray.filter(elem => elem.paramId === field.id);
			
			if (field.def_condition === 'EQU' && foundValues.length > 0) {
				initObj[fieldName] = foundValues[0].value;
				
			} else if (field.def_condition === 'BTW' && foundValues.length > 0) {
				
				foundValues.forEach(date => {
					const ending = date.conditionCode === 'LEQ' ? '@TO' : '@FROM';
					initObj[`${fieldName}${ending}`] = date.value;
				});
			}
			
		} else if ((field.type === 'LIST') && (field.multiset_allowed === 'Y')) {
			const listValue = paramsNameArray.filter(elem => elem.paramId === field.id);
			initObj[fieldName] = listValue.map(el => el.value);
			if (field.search && !field.dict_search) {
				initObj[fieldName] = listValue.map(el => {
						const option = field.dict.find((item) => {
							return el.value === item.key;
						});
						return {
							value: option ? option.key : null,
							label: option ? option.value : '',
						};
					}
				);
			}
			
			if (field.dict_search) {
				initObj[fieldName] = listValue.map(el => {
					return {
						value: el.value || el.paramId,
						label: el.label,
					};
				});
			}
		} else {
			initObj[fieldName] = paramsNameArray.filter(elem => elem.paramId === field.id)[0].value;
		}
	});
	
	return { ...initObj };
}



