import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// @TODO move openCustomerAddressModal to the parent (drill it down to get rid of connect dependency)
import { openCustomerAddressModal } from 'actions/customer';
import cx from 'classnames';

const mapDispatchToProps = {
    openCustomerAddressModal,
};

@withTranslation()
@connect(null, mapDispatchToProps)
class AddressField extends React.Component {
    constructor(props) {
        super(props);
        this.openAddressModal = this.openAddressModal.bind(this);
    }

    openAddressModal() {
        if (!this.props.disabled) {
            this.props.openCustomerAddressModal();
        }
    }

    render() {
        const { input, label, t, disabled, savedValue } = this.props;
        
        return (
            <div className={cx('field-controller', { 'disabled': disabled })} >
                <div className="input-element">
                    <div className="input-label">{label}</div>
                    <div className="input-field-wrap" onClick={this.openAddressModal}>
                        <div
                            className={cx('input-field', { disabled, textarea: savedValue })}
                            title={savedValue}
                        >
                            {savedValue}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddressField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    openAddressModal: PropTypes.func,
};

export default AddressField;
