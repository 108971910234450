import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../util/i18n";
import { getFormValues, getFormSyncErrors } from "redux-form";

import { get } from "lodash";

import { clear as clearComponatorData } from "reducers/componator";

import baseService from "../../../../services/BaseService";

import Companator from "../../common/Componator";

import Loader from "../../../../components/Loader";

import DynamicForm from "../../common/DynamicForm";

import "./styles.scss";

// const CODE = 'INTERACTION_REQUEST';
// // const TYPE = 'LUCENE';

// const MOCK_FIELDS = {
//     "isOverdue": "Протерміновано",
//     "attr0": "Джерело",
//     "priorityId": "Пріоритет",
//     "regnum": "Номер",
//     "status": "Статус",
//     "startDate": "Створено",
//     "destinationName": "Виконавці",
//     "typeFullName": "Питання",
//     "lastModificationDate": "Змінено",
//     "customerName": "Клієнт",
//     "subject": "Тема",
//     "operatorName": "Автор",
//     "custContactPersonName": "Контактна особа",
//     "finishDate": "Закрито",
//     "endDate": "Вирішити до",
//     "lastInProgresDate": "Прийнято",
//     "durationOverdue": "SLA"
// };

const CONFIG_EXPORT_FORM = "wizard-export-config";

const mapStateToProps = (state, props) => {
    const searchQuery = get(state, "search.query");

    const pathname = get(props, "location.pathname", "");
    const isActiveSearch = pathname.startsWith("/search");
    const query = isActiveSearch ? searchQuery : "";

    // const gridKey = get(state, "grid.gridKey");
    // const grid = get(state, `grid.${gridKey}`);
    const grid = get(state, `grid.${props.wizardGridKey}`);
    const gridFields = get(grid, "params.fields");
    const selectedGridFields = Object.keys(gridFields)
        .filter(key => {
            const { hidden, notForExport } = gridFields[key];
            return !hidden && !notForExport;
        })
        .map(key => {
            return {
                value: key,
                name: gridFields[key].name,
            };
        });
    const gridApi = get(grid, "api");
    const code = get(gridApi, "code");
    const filterObject = get(gridApi, "filterObject");
    const filterId = get(gridApi, "filterId");
    const gridType = get(gridApi, "type");
    const sort = get(gridApi, "sort", []);

    const componatorKey = get(state, "componator.activeKey");
    const componatorOutputFields = get(state, `componator.${componatorKey}.outputFields`, []);

    return {
        formValues: getFormValues(CONFIG_EXPORT_FORM)(state),
        formErrors: getFormSyncErrors(CONFIG_EXPORT_FORM)(state),
        selectedGridFields,
        code,
        query,
        filterObject,
        sort,
        filterId,
        gridType,
        componatorOutputFields,
    };
};

const mapDispatchToProps = {
    // closeExportWizardModal,
    clearComponatorData,
};

const Configuration = props => {
    const {
        t,
        onCancel,
        formValues,
        formErrors,
        selectedGridFields,
        componatorOutputFields,
        code,
        query,
        filterObject,
        sort,
        filterId,
        setActiveTab,
        gridType,
        clearComponatorData,
    } = props;

    const [staticInitValues, setStaticInitValues] = useState({});
    const staticInitValuesLength = Object.keys(staticInitValues).length;

    const activeLanguage = i18n.language;

    const [isTypeLoading, setIsTypeLoading] = useState(false);
    const [isOnceInitialised, setIsOnceInitialised] = useState(false);
    const [typeOptions, setTypeOptions] = useState([]);

    const [isFormatLoading, setIsFormatLoading] = useState(false);
    const [formatOptions, setFormatOptions] = useState([]);

    const [isFieldsLoading, setIsFieldsLoading] = useState(false);

    const [isJobPosting, setIsJobPosting] = useState(false);

    const isLoading = isTypeLoading || isFormatLoading || isFieldsLoading || isJobPosting;

    const [initComponatorData, setInitComponatorData] = useState(null);
    const [componatorRefreshKey, setComponatorRefreshKey] = useState(null); // set manual componator remount

    const selectedType = useMemo(() => formValues && formValues.exportType, [formValues]);
    const selectedFormat = useMemo(() => formValues && formValues.exportFormat, [formValues]);

    const isStartExportDisabled = !componatorOutputFields.length || !selectedType || !selectedFormat;

    const componatorKey = `${code}_${selectedType}_export`;

    const configExportForm = t => [
        {
            view: "2column",
            collapsed: false,
            title: "export_config",
            key: "export-config",
            widgets: [
                {
                    key: "exportType",
                    title: t("wizard.exportFields"),
                    values: typeOptions,
                    isReq: true,
                    widgetType: "select",
                },
                {
                    key: "exportFormat",
                    title: t("wizard.exportFormat"),
                    values: formatOptions,
                    isReq: true,
                    widgetType: "select",
                },
            ],
        },
    ];

    function fetchExportTypes() {
        const params = { pathParams: { code } };
        setIsTypeLoading(true);
        baseService
            .get("getExportTypes", params)
            .then(({ result, success }) => {
                setIsTypeLoading(false);
                if (success) {
                    const options = result.map(item => ({ key: item.code, value: item.label }));
                    setTypeOptions(options);
                    const defaultOption = result.find(item => item.default);
                    if (defaultOption) {
                        setStaticInitValues(prev => ({ ...prev, exportType: defaultOption.code }));
                    }
                    if (!defaultOption && options.length > 0) {
                        // fallback to the first available option
                        setStaticInitValues(prev => ({ ...prev, exportType: result[0].code }));
                    }
                }
            })
            .catch(e => {
                setIsTypeLoading(false);
                console.log("ExportWizardModal/Config::fetchExportTypes: Error ", e);
            });
    }

    function fetchExportFormats() {
        const params = { pathParams: { code } };
        setIsFormatLoading(true);
        baseService
            .get("getExportFormats", params)
            .then(({ result, success }) => {
                setIsFormatLoading(false);
                if (success) {
                    const options = result.map(item => ({ key: item.code, value: item.label }));
                    setFormatOptions(options);
                    const defaultOption = result.find(item => item.default);
                    if (defaultOption) {
                        setStaticInitValues(prev => ({ ...prev, exportFormat: defaultOption.code }));
                    }
                    if (!defaultOption && options.length > 0) {
                        // fallback to the first available option
                        setStaticInitValues(prev => ({ ...prev, exportType: result[0].code }));
                    }
                }
            })
            .catch(e => {
                setIsFormatLoading(false);
                console.log("ExportWizardModal/Config::fetchExportFormats: Error ", e);
            });
    }

    function setComponatorData(fields) {
        let inputFields = Object.keys(fields).map(key => ({ value: key, name: fields[key] }));

        const outputFields = [];
        // filter out outputfields from grid which are not available in received input fields and map names as they come from config
        selectedGridFields.forEach(field => {
            // const inputField = inputFields.find(item => item.value === field.value);
            const inputField = inputFields.find(item => item.name === field.name);
            if (inputField) {
                outputFields.push(inputField);
            }
        });
        // console.log({outputFields});
        if (outputFields.length > 0) {
            // filter out selected outputFields
            inputFields = inputFields.filter(field => {
                // const isIncludesInOutput = outputFields.find(item => item.value === field.value);
                const isIncludesInOutput = outputFields.find(item => item.name === field.name);
                // console.log({field, isIncludesInOutput, outputFields});
                return !isIncludesInOutput;
            });
        }
        setInitComponatorData({
            inputFields,
            outputFields,
            displayedField: "name",
        });
    }

    function fetchExportFields(code, type) {
        const params = {
            data: { lang: activeLanguage.toUpperCase() },
            pathParams: { code, type },
        };
        setIsFieldsLoading(true);
        baseService
            .get("getExportFields", params)
            .then(({ result, success }) => {
                setIsFieldsLoading(false);
                if (success) {
                    setComponatorData(result);
                    setComponatorRefreshKey(new Date().getTime()); // set manual componator remount
                }
            })
            .catch(e => {
                setIsFieldsLoading(false);
                console.log("ExportWizardModal/Config::fetchExportFormats: Error ", e);
            });
    }

    function handleExport() {
        if (!isStartExportDisabled) {
            // process fields to save each field in format value: name
            const fields = componatorOutputFields.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.value]: curr.name,
                };
            }, {});
            const params = {
                data: {
                    query,
                    filterId,
                    fields,
                    filterObject,
                    sort,
                    code,
                    exportType: selectedType,
                    fileType: selectedFormat,
                    type: gridType,
                    lang: activeLanguage.toUpperCase(),
                    // type: "appeal", // could be also a customer, what to add in case of resource/service/event?
                },
                jsonType: true,
            };
            // console.log({params});
            setIsJobPosting(true);
            baseService
                .post("exportJob", params)
                .then(response => {
                    setIsJobPosting(false);

                    const { success, result } = response;
                    if (success) {
                        // change activa tab to jobs
                        setActiveTab("jobs");
                    }
                })
                .catch(e => {
                    setIsJobPosting(false);
                    console.log("ExportWizardModal/Configuration::handleExport: Error ", e);
                });
        }
    }

    useEffect(() => {
        if (selectedType) {
            // setInitComponatorData(null);
            fetchExportFields(code, selectedType);
            // set as initialised
            if (!isOnceInitialised) {
                setIsOnceInitialised(true);
            }
        }
    }, [selectedType]);

    // clear only if both type and isOnceInitialised are available
    useEffect(() => {
        if (selectedType && isOnceInitialised) {
            clearComponatorData();
        }
    }, [selectedType]);

    useEffect(() => {
        // console.log({code});
        if (code) {
            fetchExportTypes();
            fetchExportFormats();
        }
    }, [code]);

    // useEffect(() => {
    //     return () => {
    //         // console.log("*** CLEAR COMPONATOR ***");
    //         // clearComponatorData();
    //     };
    // }, []);
    return (
        <div className="export-wizard-configuration ordering-tab-wrapper">
            {isLoading && <Loader />}
            <div className="export-wizard-configuration__setup">
                <DynamicForm
                    t={t}
                    formName={CONFIG_EXPORT_FORM}
                    forceInitialValues={staticInitValues}
                    dynData={configExportForm(t)}
                    withoutLiveSaver
                    withoutCollapse
                    key={staticInitValuesLength}
                />
            </div>
            {selectedType && componatorRefreshKey && (
                <Companator
                    initData={initComponatorData}
                    t={t}
                    componatorKey={componatorKey}
                    withoutTranslation
                    key={componatorRefreshKey}
                />
            )}
            <div className="export-wizard-configuration__actions">
                <button type="submit" className={"btn btn-with-border"} onClick={onCancel}>
                    {t("cancel")}
                </button>
                <button
                    type="submit"
                    className={isStartExportDisabled ? "btn btn-primary disabled" : "btn btn-primary"}
                    onClick={handleExport}
                >
                    {t("wizard.startExport")}
                </button>
            </div>
        </div>
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Configuration)));
