import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import { CSSTransition } from 'react-transition-group';
import styles from 'styles/modules/appealPrint.module.scss';
import modalStyles from 'styles/modules/modal.module.scss';
import cx from 'classnames';
import Loader from 'components/Loader';
import ReactDOMServer from "react-dom/server";
import { useTranslation } from 'react-i18next';

export default function UniversalPrintPopup (props){
	const { content, onClose, headerTitle, PrintTemplate, injectedStyles, ...rest } = props;
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(true);
	const [overlay, setOverlay] = useState(true);
	
	useEffect(() => {
		if (content) {
			setLoading(false);
			setOverlay(false);
		}
	},[]);
	//
	function onPrint (){
		window.frames.universalFormPrint.focus();
		window.frames.universalFormPrint.print();
	}

		return (
			<CSSTransition
				in={true}
				classNames='fade'
				appear={true}
				enter={true}
				exit={true}
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<ModalPortal
					onClose={onClose}
					className='modal-print modal-center'
				>
					<div className={cx(modalStyles.modalHeader, 'no-print')}>
						<div className={modalStyles.modalTitle}>{headerTitle}</div>
					</div>
					{
						overlay && <div className='print-block-overlay' />
					}
					<div className={styles.appealPrintContent}>
						<div className={cx(styles.buttonSet, 'right', 'no-print')}>
							<button type='button' onClick={onPrint}>
								<i className='icon-print' />
								<span>{t('appeal.printAppeal')}</span>
							</button>
						</div>
						{
							loading ? <Loader />
								: (
									<iframe id='universalFormPrint' name='universalFormPrint' srcDoc={`
                                        <html>
                                            <head>
                                                <style>
                                                ${injectedStyles}
                                                </style>
                                            </head>
                                             ${ReactDOMServer.renderToStaticMarkup(<PrintTemplate data={content} t={t} {...rest}/>)}
                                        </html>
                                    `}
									/>
								)
						}
					</div>
				</ModalPortal>
			</CSSTransition>
		);
}

UniversalPrintPopup.propTypes = {
	content: PropTypes.object,
	headerTitle: PropTypes.string,
	PrintTemplate: PropTypes.func,
	injectedStyles: PropTypes.string,
	onClose: PropTypes.func,
};
