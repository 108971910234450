import React from 'react';
import PropTypes from 'prop-types';
import { escapeBaseTag } from 'helpers';

import { withTranslation } from 'react-i18next';

@withTranslation()
class NotificationItem extends React.Component {
	
	state = {
		open: false
	};
	
	toggleContent = () => this.setState(prevState => ({ open: !prevState.open }));
	
	getConfig = () => {
		const { t } = this.props;
		return [
			{ title: t('appealHistory.rule'), path: 'ruleName' },
			{ title: t('appealHistory.subject'), path: 'subject', innerHTML: true },
			{ title: t('appealHistory.notificationBody'), path: 'body', innerHTML: true },
			{ title: t('appealHistory.action'), path: 'actionName', shouldHighlight: true },
			{ title: t('appealHistory.status'), path: 'status' },
			{ title: 'ID', path: 'id' }
		];
	};
	
	createMarkup = content => ({ __html: escapeBaseTag(content) });
	
	renderRow = ({ title, path, innerHTML, shouldHighlight }) => {
		const { data, highlight } = this.props;
		return (
			<div className='notification-row' key={path}>
				<span className='row-title'>{title}:</span>
				{
					innerHTML
						? <div className='row-content' dangerouslySetInnerHTML={this.createMarkup(data[path])} />
						: <div
							className='row-content'>{shouldHighlight ? highlight(data[path]) : data[path]}</div>
				}
			</div>
		);
	};
	
	render () {
		const { data, t } = this.props;
		
		return (
			<li className='history-item'>
				<i className='icon-external-link-square' />
				<div className='history-item-content'>
					<span className='history-title-text'>Нотифікація: </span>
					<span className='history-content-text'>{`${data.notifierFrom} \<${data.notifierTo}\>`}</span>
					
					<div className='notification-content'>
						{
							!this.state.open && this.renderRow({ title: t('appealHistory.ruleName'), path: 'ruleName' })
						}
						{
							this.state.open &&
							<React.Fragment>
								{this.getConfig().map(this.renderRow)}
							</React.Fragment>
						}
						
						<button className='contentToggleButton' onClick={this.toggleContent}>
							<i className='icon-kebab-hor' />
						</button>
					
					</div>
				</div>
			</li>
		);
	}
}

NotificationItem.propTypes = {
	data: PropTypes.shape({
		actionName: PropTypes.string,
		body: PropTypes.string,
		id: PropTypes.number,
		subject: PropTypes.string,
		status: PropTypes.string,
		notifierFrom: PropTypes.string,
		notifierTo: PropTypes.string
	}),
	highlight: PropTypes.func
};

export default NotificationItem;
