import React, { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import cx from "classnames";
import { matchPath, withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError, formValueSelector, submit } from 'redux-form';
import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';
import baseService from 'services/BaseService';
import TextArea from 'components/Common/TextArea';
import AddressForm from '../AddressBlock';
import { StreetModes } from '../../../../../constants';
import validate from '../validate';
import { closeCreateNewCustomerModal } from 'actions/ui';
import { addCustomerToAppeal, getAppealFeedback, unlockAppealForm } from 'modules/appeal/actions';
import { getContactPerson, getCustomer } from 'actions/customer';
import { createAddressObject, physicalAddressValues, getDynamicFields, renderDynamicFields } from '../../../utils';
import { convertDynamicFields } from 'helpers';
import Loader from "../../../../Loader";
import { personalDataBlocks, custAddressBlocks, juridicalDataBlocks, getMergedStaticBlocks, getModalFormDataFromState } from "../../../config";
// import classNames from 'classnames';
// import i18next from 'util/i18n';

function Organization (props) {
	const {
        t,
        setIsLoading,
        customerTypeName,
		customerTypeId,
        setDynConfig,
        dynConfig,
        incomePhoneNumber,
        custAddressBlock,
        juridicalDataBlock,
        personalDataBlock,
		modalFormData,
		formCode,
		isSubmitDisabled,
    } = props;
	
	const [mode, setStreetMode] = useState(StreetModes.AUTO);
	const [dynParamsCustomer, setDynParamsCustomer] = useState(null);
	const [dynParamsContactPerson, setDynParamsContactPerson] = useState(null);
	const [fetchingCP, setFetchingCP] = useState(false);
	const [fetchingCust, setFetchingCust] = useState(false);

	useEffect( () => {
		return () => {
			setDynParamsContactPerson(null);
			setDynParamsCustomer(null);
		}
	}, [customerTypeName]);
	
	// handle customer dyn params
	useEffect( () => {
		async function fetchData() {
			setFetchingCust(true);
			const responseCustomer = await getDynamicFields(customerTypeName, 'CUSTOMER', customerTypeId);
			if(responseCustomer){
				setDynParamsCustomer(responseCustomer);
				setFetchingCust(false);
				if (Array.isArray(responseCustomer)) {
					setDynConfig(prev => prev ? [...prev, ...responseCustomer] : responseCustomer);
				}
			}
		}
		
		if (juridicalDataBlock && !fetchingCust && !dynParamsCustomer) {
			fetchData();
		}
	}, [customerTypeName, juridicalDataBlock]);

	// handle CP dyn params
	useEffect( () => {
		async function fetchData() {
			setFetchingCP(true);
			const responseContactPerson = await getDynamicFields(customerTypeName, 'CONTACT_PERSON', customerTypeId);

			if(responseContactPerson){
				setDynParamsContactPerson(responseContactPerson);
				setFetchingCP(false);
				if(Array.isArray(responseContactPerson)) {
					setDynConfig(prev => prev ? [...prev, ...responseContactPerson] : responseContactPerson);
				}
			}
		}
		
		if (personalDataBlock && !fetchingCP && !dynParamsContactPerson) {
			fetchData();
		}
	}, [customerTypeName, personalDataBlock]);
	
	useEffect(() => {
		if (!props.pristine) {
			props.enableWarning();
		}
	}, [props.pristine]);
	
	
	useEffect(() => {
		if (incomePhoneNumber) {
			props.change('workPhone', incomePhoneNumber);
		}
	}, [incomePhoneNumber]);

	// update hiddenValues
	useEffect(() => {
		if (formCode) {
			const hiddenValuesFields = Object.keys(modalFormData).flatMap(key => modalFormData[key].sortedAttrs).filter(field => field.hidden && field.hiddenValues);
			hiddenValuesFields.forEach(field => {
				props.change(field.field, field.hiddenValues);
			});
		}
	}, [formCode]);
	
	function convertDictionaryObject (dictionaryObject) {
		return Object.entries(dictionaryObject).map(([prop, value]) => ({
			value: prop,
			label: value
		}));
	}
	
	function getFieldPropsWithOptions (fieldProps) {
		switch (fieldProps.name) {
		case 'issues': {
			let options = convertDictionaryObject(t('dictionary:contactIssue', { returnObjects: true }));
			// if (fieldProps.hiddenValues && Array.isArray(fieldProps.hiddenValues)) {
			// 	options = options.filter(option => !fieldProps.hiddenValues.includes(option.value));
			// }
			return {
				...fieldProps,
				options,
				// options: options.slice(1)
			};
		}
		default:
			return fieldProps;
		}
	}
	
	function renderField(fieldConfig) {
		// console.log({fieldConfig});
        if (fieldConfig.hidden) {
            return (
                <div className="hidden">
                    <Field key={fieldConfig.name} {...getFieldPropsWithOptions(fieldConfig)} required={false} />
                </div>
            );
        }
        return <Field key={fieldConfig.name} {...getFieldPropsWithOptions(fieldConfig)} />;
    }
	
	async function submit (values) {
		const validation = validate(values, {
			...props,
			mode,
			config: [...naturalPersonConfig(t, personalDataBlock), ...props.addrConf, ...organizationConfig(t, juridicalDataBlock)],
		});
		const appealId = _.get(props.match, 'params.appealId');
		
		if (!_.isEmpty(validation)) {
			console.log("CreateCustomerForm::submit: Validation error ", validation);
			throw new SubmissionError(validation);
		} else {
			await setIsLoading(true);
			

			const contactsType = [];
			const contactsValue = [];
			if (values.workPhone) {
				contactsType.push('workPhone');
				contactsValue.push(values.workPhone);
			}
			
			if (values.email) {
				contactsType.push('email');
				contactsValue.push(values.email);
			}
			
			let dynParamsCust;
			let dynParamsCP;
			if(dynParamsCustomer || dynParamsContactPerson) {
				//prepeared  & separation dyn params from form
				const dynParamsCustomerConfig = _.flattenDeep(!dynParamsCustomer ? [] : dynParamsCustomer.map(item => item.widgets && item.widgets));
				const dynParamsContactPersonConfig = _.flattenDeep(!dynParamsContactPerson ? [] : dynParamsContactPerson.map(item => item.widgets && item.widgets));
				const prepearedDynParamsCustomer = _.pickBy(values, (item, key) => dynParamsCustomerConfig.some(conf => conf.key === key));
				const prepearedDynParamscontactPerson = _.pickBy(values, (item, key) => dynParamsContactPersonConfig.some(conf => conf.key === key));
				
				dynParamsCust = convertDynamicFields(prepearedDynParamsCustomer);
				dynParamsCP = convertDynamicFields(prepearedDynParamscontactPerson);
			}
			
			const params = {
				data: {
					contactPerson: {
						lastName: values.lastName,
						firstName: values.firstName,
						patronymic: values.patronymic,
						contactsType,
						contactsValue,
						issues: values.issues,
						dynParams: dynParamsCP,
					},
				},
				jsonType: true,
			};
			
			if (values.shortName) {
				params.data.party = {
					shortName: values.shortName,
					dynParams: dynParamsCust,
				};
			}
			
			if (Object.keys(values).some(v => physicalAddressValues.includes(v))) {
				params.data.address = [
					createAddressObject(values),
				];
			}
			
			if (isSubmitDisabled) {
				return;
			}
			const response = await baseService.post('create_organization', params);
			
			if (response.success && response.result) {
				const { customerId, personId } = response.result;
				setIsLoading(false);
				props.onClose();

				if (typeof (_.get(props.newCustomerModalContext, 'callback')) === 'function') {
					const callback = _.get(props.newCustomerModalContext, 'callback');
					await callback(customerId, personId);
				}
				
				if (_.get(props.newCustomerModalContext, 'openNewTab') === false) {
					props.addCustomerToAppeal(appealId, customerId, personId);
					props.getCustomer({ customerId: customerId }, customerId);
					props.getContactPerson({ id: personId }, customerId);
					props.getAppealFeedback({ appealId, customerId });
				}
				
				if (_.get(props.newCustomerModalContext, 'openNewTab') === true) {
					props.history.push(`/customer/${response.result.customerId}/appeals`);
				}
			} else {
				setIsLoading(false);
				throw new Error('Natural person creation error!');
			}
			
			await setIsLoading(false);
			await props.onClose();
		}
	}
	
	return (
		<form onSubmit={props.handleSubmit(submit)} className='ordering-component-ui-core-wrapper'>
			<div className='create-customer-form-container'>

				<div className={cx("form-wrapper", { hidden: !personalDataBlock || personalDataBlock.hidden })}>
					<header>{t(personalDataBlock && personalDataBlock.translationLabel)}</header>
					{naturalPersonConfig(t, personalDataBlock).map(renderField)}
					<div className="additional-data">
						{fetchingCP && !dynParamsContactPerson && <div className='loader-box'> <Loader/> </div>}
						{dynParamsContactPerson && !fetchingCP && dynParamsContactPerson.map(renderDynamicFields)}
					</div>
				</div>
               

		
                <div className={cx("form-wrapper", { hidden: !juridicalDataBlock || juridicalDataBlock.hidden })}>
					<header>{t(juridicalDataBlock && juridicalDataBlock.translationLabel)}</header>
					{organizationConfig(t, juridicalDataBlock).map(renderField)}
					{juridicalDataBlock && <div className="additional-data">
						{fetchingCust && !dynParamsCustomer && (
							<div className="loader-box">
								{' '}
								<Loader />{' '}
							</div>
						)}
						{dynParamsCustomer &&
							!fetchingCust &&
							dynParamsCustomer.map(renderDynamicFields)}
					</div>}
				</div>
                

				<div className={cx("form-wrapper", { hidden: !custAddressBlock || custAddressBlock.hidden  })}>
					<header>{t(custAddressBlock && custAddressBlock.translationLabel)}</header>
					<AddressForm {...props} mode={mode} changeMode={setStreetMode} />
				</div>
			</div>
			
			<footer className='create-customer-footer'>
				<button type='submit' disabled={isSubmitDisabled} className={cx("btn btn-primary", { disabled: isSubmitDisabled })}>
					<i className='icon icon-check' />
					{t('create')}
				</button>
			</footer>
		</form>
	);
}

function mapStateToProps (state, ownProps) {
	const { t, dynConfig, formCode } = ownProps;
	const modalFormData = getModalFormDataFromState(state, formCode);
	// console.log({modalFormData, formBlocks, ownProps, customerTypeName, formName: `modal_create_customer_${customerTypeName}_form` });
	const { custAddress: custAddressBlock, juridicalData: juridicalDataBlock, personalData: personalDataBlock } = modalFormData;
	// console.log({formCode, custAddressBlock, juridicalDataBlock, personalDataBlock });
	const match = matchPath(window.location.hash.substring(1), {
		path: '/appeals/:appealId/:tab',
		exact: true,
		strict: false
	});
	let conf = addressConf;
	const formSelector = formValueSelector('organization-form');
	const values = formSelector(state,
		'settlement', 'street', 'buildingNumber', 'buildingSecondNumber', 'zipCode', 'apartmentNumber', 'streetType',
		'streetName', 'description', 'buildingNumberInput', 'zipCodeInput',
	);
	const incomePhoneNumber = state.call.incomePhoneNumber;

	if (Object.keys(values).some((i) => values[i])) {
		conf = addressConfPristine;
	}

	
	return {
		...values,
		addrConf: conf(t, custAddressBlock) || [],
		incomePhoneNumber,
		match,
		config: [
            ...naturalPersonConfig(t, personalDataBlock),
            ...(conf(t, custAddressBlock) || []),
            ...organizationConfig(t, juridicalDataBlock),
            ...dynConfig,
        ],
		newCustomerModalContext: state.ui.newCustomerModalContext,
		custAddressBlock,
		juridicalDataBlock,
		personalDataBlock,
		modalFormData,
	};
}

const mapDispatchToProps = {
	submitForm: form => submit(form),
	onClose: closeCreateNewCustomerModal,
	getCustomer,
	getContactPerson,
	unlockAppealForm,
	addCustomerToAppeal,
	getAppealFeedback,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'organization-form',
	validate
})(Organization)));

const naturalPersonConfig = (t, staticData) => {
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, personalDataBlocks, {}, "organization");
	return mergedStaticBlocks;
};


export const organizationConfig = (t, staticData) => { 
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, juridicalDataBlocks, {}, "organization");
	return mergedStaticBlocks;
};

export const addressConf = (t, staticData) => { 
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, custAddressBlocks, {}, "organization");
	return mergedStaticBlocks;
};

const addressConfPristine = (t, staticData) => { 
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, custAddressBlocks, {}, "organization");
	return mergedStaticBlocks;
};
