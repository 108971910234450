import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Select from "react-select";
import SelectTreeOption from "./SelectTreeOption";
import { findParent, findType } from "./helpers";
import FieldController from "../FieldController";
import Loader from "../Loader";

import { connect } from "react-redux";
import { createSelector } from "reselect";
import { get, cloneDeep } from "lodash";


/**
 * clone deeply object and memoize result!
 */
const getInitialOptionsSelector = () =>
    createSelector(
        ownProps => ownProps.initialOptions,
        initialOptions => cloneDeep(initialOptions)
    );

/**
 * create factory of "mapStateToProps", because each component must have own selector with own memoized results
 */
const mapStateToPropsFactory = () => {
    const initialOptionsSelector = getInitialOptionsSelector();

    const mapStateToProps = (state, ownProps) => ({
        initialOptionsClone: initialOptionsSelector(ownProps),
    });

    return mapStateToProps;
};

function SelectTreeComponent(props) {
    const {
        t,
        label,
        input,
        meta,
        treeParam,
        required,
        valueField,
        textField,
        className,
        position,
        initialOptionsClone,
        placeholder,
        ...restProps
    } = props;
    let thisSelect = Select;
    const [placeholderInput, setPlaceholderInput] = useState(placeholder);
    const [options, setOptions] = useState(transformOptionsForSelect(initialOptionsClone));
    const [searching, setSearching] = useState(false);
    const [searchOptions, setSearchOptions] = useState(flatOptions(initialOptionsClone));

    const noResultsText = t("noResultsFound");
    const defaultOption = {
        id: 0,
        label: t("dontSelect"),
        name: t("dontSelect"),
        value: 0,
        placeholder: true,
    };
    defaultOption[treeParam] = null;
    let value = input.value;
    const selectOptions = options && [defaultOption, ...options];
    if (typeof value === "number" && initialOptionsClone.length > 0) {
        const selectFromTree = searchValue(value, initialOptionsClone);
        if (selectFromTree) {
            value = { ...selectFromTree, label: selectFromTree.text, value: selectFromTree.object.id };
        }
    }

    const error = typeof meta.error === "string" ? meta.error : undefined;
    const isTouched = meta && meta.touched;

    useEffect(() => {
        if (initialOptionsClone) {
            setOptions(transformOptionsForSelect(initialOptionsClone));
            setSearchOptions(flatOptions(initialOptionsClone));
        }
    }, [initialOptionsClone]);

    function onRemoveClick() {
        props.removeField(position);
        // props.unlockAppealForm();
        props.unlockAppealForm("form");
    }

    function flatOptions(initialOptions) {
        const searchableOptions = [...initialOptions];

        const addChildren = options => {
            options.forEach(element => {
                searchableOptions.push(element);
                element[treeParam] && addChildren(element[treeParam]);
            });
        };

        initialOptions.forEach(element => element[treeParam] && addChildren(element[treeParam]));

        return transformOptionsForSelect(searchableOptions);
    }

    function selectLink(link) {
        if (link.selectedLink) {
            const selectedType = findType(valueField(link), initialOptionsClone, true, treeParam, valueField);
            const parent = findParent(link, initialOptionsClone, treeParam, valueField);

            const newOptions = transformOptionsForSelect(selectedType);

            if (parent) {
                const backLink = {
                    ...parent,
                    label: t("appealForm.back"),
                    name: t("appealForm.back"),
                    // value: 0,
                    value: null,
                    selectedLink: true,
                    back: true,
                };
                newOptions.unshift({
                    ...parent,
                    value: valueField(parent),
                    label: parent[textField],
                    leaf: true,
                    group: false,
                    parent: true,
                });
                newOptions.unshift(backLink);
            }
            setOptions(newOptions);
        } else if (link[treeParam]) {
            const newLink = {
                ...link,
                value: valueField(link),
                label: link[textField],
                leaf: true,
                group: false,
                parent: true,
            };
            const backLink = {
                ...link,
                label: t("appealForm.back"),
                name: t("appealForm.back"),
                // value: 0,
                value: null,
                selectedLink: true,
                back: true,
            };
            const newOptions = transformOptionsForSelect(link[treeParam]);
            newOptions.unshift(newLink);
            newOptions.unshift(backLink);
            setOptions(newOptions);
        }
    }

    function transformOptionsForSelect(options) {
        return options.map(option => ({
            ...option,
            label: option[textField],
            value: props.valueField(option),
        }));
    }

    function arrowRender(values) {
        return values.isOpen ? <i className="icon-up" /> : <i className="icon-down" />;
    }

    function onOpen() {
        const select = document.querySelector(".Select-menu-outer");
        if (select.scrollIntoViewIfNeeded) {
            select.scrollIntoViewIfNeeded(false);
        } else {
            select.scrollIntoView(false);
        }

        if (restProps.onOpen && typeof restProps.onOpen === "function") {
            restProps.onOpen();
        }
    }

    function onSearch(selectedOption) {
        setOptions(getOptionsBySelectedOption(selectedOption));
    }

    function getOptionsBySelectedOption(selectedOption) {
        const parent = findParent(selectedOption, initialOptionsClone, treeParam, valueField);
        if (parent) {
            const newLink = {
                ...parent,
                value: valueField(parent),
                label: parent[textField],
                leaf: true,
                group: false,
                parent: true,
            };
            const backLink = {
                ...parent,
                label: t("appealForm.back"),
                name: t("appealForm.back"),
                // value: 0,
                value: null,
                selectedLink: true,
                back: true,
            };
            const newOptions = transformOptionsForSelect(parent[treeParam]);

            newOptions.unshift(newLink);
            newOptions.unshift(backLink);
            return newOptions;
        } else {
            return transformOptionsForSelect(initialOptionsClone);
        }
    }

    function onFocus() {
        setPlaceholderInput(t("Search"));
    }

    function onBlur(event) {
        setOptions(getOptionsBySelectedOption(input.value));
    }

    function onChange(item) {
        const value = _.get(item, "value");
        // const initValue = _.get(props, "meta.initial");
        input.onChange(value || null);
        // input.onChange(item);
        // if (value !== initValue) {
        //     // props.unlockAppealForm();
        //     props.unlockAppealForm("form");
        // }
        if (props.onFieldRemove && typeof props.onFieldRemove === 'function') {
            props.onFieldRemove({ change: value, position: position });
        }
    }

    function onInputChange(inputValue) {
        if (inputValue && !searching) {
            setSearching(true);
        }
        if (!inputValue && searching) {
            setSearching(false);
        }
        return inputValue;
    }

    function renderOption(prop) {
        return (
            <SelectTreeOption
                selectLink={selectLink}
                parentNode={thisSelect}
                {...prop}
                searching={searching}
                onSearch={onSearch}
                partyType={prop.option.object && prop.option.object.partyType}
            />
        );
    }

    function searchValue(id, options) {
        let queue = [...options];
        let result;
        let condition = true;
        while (condition) {
            const item = queue[0];
            if (item && item.object.id === id) {
                result = item;
                condition = false;
            }
            if (item && item.result) {
                queue.push(...item.result);
            }
            queue.shift();
            condition = condition === false ? false : queue.length;
        }
        return result;
    }

	function getFieldControllerProps() {
        const { meta, input, showNotification, disabled, isSaveOnlyValid, widgetType } = props;

        const initialValue = get(meta.initial, 'value') || meta.initial;
        const value = input.value;
        const error = meta.error;

        const controllerProps = {
            widgetType,
            fieldKey: input.name,
            initValue: initialValue,
            value,
            error,
            change: (key, value) => {
                input.onChange(value);
            },
            selectOptions: searchOptions,
            // handleLiveSubmit: this.submit,
            portalPlacement: 'right',
            loader: <Loader loaderContainerClass="container-loader" loaderClass="input-loader" />,
            autoSave: false,
            enterDisabled: false,
            disabled,
            showNotification,
            isSaveOnlyValid,
            handleBlur: (e) => {
                const fieldKey = input.name;
                let isFieldControllerParent = false;
                const path = e ? e.path || (e.composedPath && e.composedPath()) : [];
                for (let i = 0; i < path.length; i++) {
                    if (path[i].getAttribute && path[i].getAttribute("data-field-controller-submit")) {
                        isFieldControllerSubmit = path[i].getAttribute("data-field-controller-submit") === "true";
                        // break;
                    }
                    if (path[i].getAttribute && path[i].getAttribute("data-field-key")) {
                        isFieldControllerParent = path[i].getAttribute("data-field-key") === fieldKey;
                        // break;
                    }
                }
                // prevent if field controller element was clicked (copy, clear, submit etc)
                if (isFieldControllerParent) {
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                }
                return;
            },
            // handleFocus: onFocus,
        };
        return controllerProps;
    };

	const controllerProps = getFieldControllerProps();

    return (
        <div className="input-element">
            <div className="input-label">
                {label}
                {required && <span className="required-field">*</span>}
                {position > 0 && (
                    <span>
                        <button onClick={onRemoveClick} className="btn-danger text" type="button">
                            {t("remove")}
                        </button>
                    </span>
                )}
            </div>
            <FieldController {...controllerProps}>
                <Select
                    options={searching ? searchOptions : selectOptions}
                    {...restProps}
                    className={cx("container-comboBox", "select-tree", className, isTouched && error && "input-field__error")}
                    searchable={true}
                    clearable={false}
                    arrowRenderer={arrowRender}
                    placeholder={placeholderInput}
                    optionComponent={renderOption}
                    {...input}
                    {...meta}
                    onChange={onChange}
                    value={value}
                    required={required}
                    onOpen={onOpen}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    ref={node => {
                        thisSelect = node;
                    }}
                    onInputChange={onInputChange}
                    noResultsText={noResultsText}
                />
            </FieldController>
        </div>
    );
}
SelectTreeComponent.propTypes = {
    initialOptions: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    selectLink: PropTypes.func,
    required: PropTypes.bool,
    treeParam: PropTypes.string,
    textField: PropTypes.string,
    valueField: PropTypes.func,
    removable: PropTypes.bool,
    position: PropTypes.number,
};

const SelectTree = connect(mapStateToPropsFactory)(SelectTreeComponent);

SelectTreeComponent.defaultProps = {
    treeParam: "children",
    textField: "name",
    valueField: v => v.id,
};
export default SelectTree;
