import React, { useState, useEffect } from 'react';
import validate from "./validate";
import { ComboBox } from 'ui-core-dashboard';
import DatePicker from 'components/Common/DatePicker';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field, initialize, change } from 'redux-form';
import { withTranslation } from "react-i18next";
import moment from 'moment';
import './styles.scss';

const mapStateToProps = (state, ownProps) => ({
	accountNumbers: state.customer.billingCards,
});

const mapDispatchToProps = {

};

function BillingFiltersBlock(props) {
	const { t, accountNumbers, pApartId, tabKey, initialize, acc } = props;
	const [currentAccount, setCurrentAccount ] = useState(null);
	const disabled = !accountNumbers || accountNumbers && accountNumbers.length === (0 || 1);
	
	useEffect( () =>{
		setDefaultFilterParams();
	},[tabKey, pApartId, accountNumbers]);
	
	useEffect( () =>{
		setDefaultFilterParams();
	},[]);
	
	const setDefaultFilterParams = () => {
		if(accountNumbers && accountNumbers.length) {
			const acc = accountNumbers[0].value;
			const nowDate = new Date();
			
			switch (tabKey) {
				case 'billing_finstatus':
					initialize({
						acc,
						'dateBegin': moment(nowDate).startOf('month'),
						'dateEnd': moment(nowDate).endOf('month')
					});
					break;
				case 'billing_payments':
					initialize({
						acc,
						'dateBegin': moment(nowDate).add(-1, 'month').startOf('month'),
						'dateEnd': moment(nowDate).endOf('month')
					});
					break;
				case 'billing_recalculations':
					initialize({
						acc,
						'dateBegin': moment(nowDate).add(-1, 'year'),
						'dateEnd': nowDate
					});
					break;
			}
		}
		else {
			props.change('acc', null);
			props.change('dateBegin', null);
			props.change('dateEnd', null);
		}
	};
	
	const handleChange = value =>{
		setCurrentAccount(value)
	};
	
	return(
		<div className={'billing-filter-wrapper ordering-component-ui-core-wrapper'}>
				<div className='inline-wrapper'>
					<Field
						component={ComboBox}
						name='acc'
						placeholder={t('dontSelect')}
						noResultsText={t('noItems')}
						label={t('fullNumber')}
						searchable={false}
						clearable={false}
						options={accountNumbers}
						value={currentAccount}
						disabled={disabled}
						onChange={handleChange}
					/>
				</div>
				
				<div className='between-fields-wrapper'>
					<Field
						name='dateBegin'
						label={t('filter.periodFrom')}
						placeholder='01/01/2000'
						component={DatePicker}
						popperClassName='billing-date-from'
						onlyDate={true}
					/>
					<Field
						name='dateEnd'
						label={t('filter.periodTo')}
						placeholder='01/01/2000'
						component={DatePicker}
						popperClassName='billing-date-to'
						onlyDate={true}
					/>
				</div>
		</div>
	)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)
(reduxForm(
	{ validate, enableReinitialize: true})
(BillingFiltersBlock)));
