import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submit, isValid, getFormValues, getFormSyncErrors } from "redux-form";
import cx from "classnames";

import Loader from "components/Loader";

import { showNotification } from "actions/ui";

import { NotificationTypes } from "constants/index";

import NotificationPortal from "../../components/Notification/NotificationPortal";
import TabContent from "./TabContent";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import CheckBoxToggle from "components/Common/CheckBoxToggle";
import ButtonSelectPopup from "components/Common/ButtonSelectPopup";
import StatusSelect from "./StatusSelect";
import ActionSelect from "./ActionSelect";
import NewAppealForm from "./Form";
// import NewAppealForm from './Form/index_old.js';

import PersonPageContent from "components/PersonPage/MainContent";
import CustomerTabContent from "components/CustomerPage/MainContent";
import AppealPrintPopup from "./PrintPopup";
import AppealActionsPopup from "./AppealActionsPopup";
import ViewListQuality from "./CallQuality/ViewListQuality";
import LinkAppeal from "./LinkAppeal";
import LinkObjects from "./LinkObjects";
import {
    // unlockAppealForm,
    repeatAppeal,
    removeRefreshAppealModal,
    getAppealVersion,
    setSaveAndCreateNew,
    lockAppealForm,
    saveAppeal,
    setStatusModalVisibility,
    showValidationNotification,
    createNewAppeal,
    setRequiredCustomer,
    setAppealIsSaveAndCreateNew,
	saveFeedback,
	unlockAppealAndClearSourceWithoutForm
} from "modules/appeal/actions";
import downloadCSV from "actions/downloadCSV";
// import { updateCurrentTabUrl } from 'actions/index';

import notificationStyle from "styles/modules/notification.module.scss";
import { withTranslation } from "react-i18next";
import {
	changeFormListener, extractAppealFromState, extractCustomerFromState,
    getCustomerTabNames,
    isOrganization,
    isServiceobjectType,
    isIndividual,
    getValueOrNull,
    searchTree,
} from "helpers";
import permissions, { checkPermissions } from "config/permissions";
import { dynamicParamsFilter } from "./helpers";
import { isEmpty, get, isObjectLike } from "lodash";

const SUBMIT_LOCK_SOURCE_NOTIFICATION_ERRORS = ["email", "comment", "customer"];

const LOCK_SOURCE_FORM = "form";

const LOCK_SOURCE_DESTINATIONS = "destinations";

function mapStateToProps (state, props) {
	const [appeal, id] = extractAppealFromState(state, props);
	const customer = extractCustomerFromState(state, _.get(appeal, 'currentAppeal.customerId'));
	const contactPerson = get(customer, 'currentContactPerson');

	return {
		appeal: appeal.currentAppeal,
		checkReqDynParams: appeal.checkReqDynParams,
		saveAndCreateNew: appeal.saveAndCreateNew,
		appealFeedback: appeal.appealFeedback,
		refreshAppealModal: appeal.refreshAppealModal,
		selectedAppealAction: appeal.selectedAppealAction,
		priorityList: state.appeal.priorityList,
		destinations: appeal.destinations || [],
		appealDestinationId: get(appeal, 'currentAppeal.destinationId'),
		appealDestinationName:get(appeal, 'currentAppeal.destinationName'),
		loadingDestinations: appeal.loadingDestinations,
		
		formParams: appeal.formParams,
		formHeaderValues: getFormValues(`appeal-form-header-${id}`)(state),
		formHeaderErrors: getFormSyncErrors(`appeal-form-header-${id}`)(state),
		formValues: getFormValues(`appeal-form-${id}`)(state),
		formErrors: getFormSyncErrors(`appeal-form-${id}`)(state),
		modalStatusList: appeal.modalStatusList,
		appealTypes: state.appeal.appealTypes,
		unlockAppeal: appeal.unlockAppeal,
		unlockedAppeal: appeal.unlockedAppeal,
        appealLockSourceList: appeal.lockSourceList,
		closeAppealFlag: appeal.closeAppealFlag,
		
		customerId: _.get(customer, 'currentCustomer.id'),
		customer: customer.currentCustomer,
		contactPerson,
		customerType: get(customer, 'currentCustomer.party.partyType'),
		
		operations: state.user.operations,
		// generateFile: !checkPermissions(permissions.FileOperations.generate_file),
		canGenerateFile: checkPermissions(permissions.FileOperations.generate_file),
		canAddExecutors: checkPermissions(permissions.AppealOperations.action_createAppealDuplet),
		canCreateAppeal: checkPermissions(permissions.AppealOperations.action_createAppeal),
		contentLoading: state.content.contentLoading,
		
		incomePhoneNumber: state.call.incomePhoneNumber,
		
		isValid: isValid(`appeal-form-${id}`)(state),
		showAppealLinkModal: state.ui.showAppealLinkModal,
		showObjectsLinkModal: state.ui.showObjectsLinkModal,
		linkedObjects: state.appeal.linkedObjects,

		currentTab: state.tabs.current,
		appealStatusList: appeal.statusList,
		isSaveAndCreateNew: appeal.isSaveAndCreateNew,

	};
}

const mapDispatchToProps = {
	// unlockAppealForm,
	saveAppeal,
	getAppealVersion,
	removeRefreshAppealModal,
	setStatusModalVisibility,
	showValidationNotification,
	lockAppealForm,
	createNewAppeal,
	setSaveAndCreateNew,
	// updateCurrentTabUrl,
	setRequiredCustomer,
	setAppealIsSaveAndCreateNew,
	// submitForm: (id) => submit(`appeal-form-${id}`),
	showNotification,
	// touch,
	submit,
	saveFeedback,
	unlockAppealAndClearSourceWithoutForm
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class AppealMainContent extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			popupIsOpen: false,
			save: false,
			createNew: false,
			saveAndClose: localStorage.getItem('saveAndClose') === 'true',
			printPopupIsOpen: false,
			shouldntUnlock: false,
			callQuality: false,
			shouldScrollToError: false,
		};
		this.toggleCodeQuality = this.toggleCodeQuality.bind(this);
		this.toggleCheckbox = this.toggleCheckbox.bind(this);
		this.submit = this.submit.bind(this);
		this.refresh = this.refresh.bind(this);
		this.closeRefreshModal = this.closeRefreshModal.bind(this);
	}
	
	openPrintPopup = () => this.setState({ printPopupIsOpen: true, popupIsOpen: false });
	
	closePrintPopup = () => this.setState({ printPopupIsOpen: false });

	setShouldScrollToError = (value) => this.setState({ shouldScrollToError: value });
	
	toggleCheckbox () {
		const saveAndClose = !(localStorage.getItem('saveAndClose') === 'true');
		this.setState({ saveAndClose });
		localStorage.setItem('saveAndClose', saveAndClose.toString());
	};
	
	closePopup = (e) => {
		const actionButtonClick = this.actionButton.contains(e.target);
		return actionButtonClick ? null : this.togglePopup();
	};
	
	togglePopup = () => this.setState({ popupIsOpen: !this.state.popupIsOpen });
	
	onBreadCrumbsClick = (id) => {
		const { appealId } = this.props.match.params;
		const newUrl = `/appeals/${appealId}/customer/${id}/appeals`;
		this.props.history.push(newUrl);
		// this.props.updateCurrentTabUrl(newUrl, this.props.currentTab.displayedName);
	};
	
	onBreadCrumbsPersonClick = (id) => {
		const { appealId } = this.props.match.params;
		const newUrl = `/appeals/${appealId}/person/${id}/contact`;
		this.props.history.push(newUrl);
		// this.props.updateCurrentTabUrl(newUrl, this.props.currentTab.displayedName);
	};
	
	onBreadCrumbsAppealClick = () => {
		const { appealId } = this.props.match.params;
		const newUrl = `/appeals/${appealId}/`;
		this.props.history.push(newUrl);
		// this.props.updateCurrentTabUrl(newUrl, this.props.currentTab.displayedName);
	};
	
	getBreadCrumbs = () => {
		const { appeal, customer, contactPerson, location, customerType, t } = this.props;
		
		const currentTab = location.pathname.split('/')[3];
		const currentBreadcrumb = ['customer', 'person'].includes(currentTab) ? currentTab : 'appeal';
		
		const breadcrumbs = [];

		// console.log({customer, contactPerson});
		
		if (isOrganization(customerType) || isServiceobjectType(customerType)) {
			breadcrumbs.push({
				className: `breadcrumbsLink ${currentBreadcrumb === 'customer' ? 'currentTitle' : ''}`,
				element: <span>{customer.party.officialName}</span>,
				onClick: () => this.onBreadCrumbsClick(customer.id)
			});
			
			contactPerson && breadcrumbs.push({
				className: `breadcrumbsLink ${currentBreadcrumb === 'person' ? 'currentTitle' : ''}`,
				element: <span>{contactPerson.party.fullName}</span>,
				onClick: () => this.onBreadCrumbsPersonClick(contactPerson.id)
			});
		}
		
		if (customerType === 'individual') {
			const fullName = get(customer, 'party.fullName', '').trim();
			breadcrumbs.push({
				className: `breadcrumbsLink ${currentBreadcrumb === 'customer' ? 'currentTitle' : ''}`,
				element: <span>{fullName}</span>,
				onClick: () => this.onBreadCrumbsClick(customer.id)
			});
			contactPerson && breadcrumbs.push({
				className: `breadcrumbsLink ${currentBreadcrumb === 'person' ? 'currentTitle' : ''}`,
				element: <span>{contactPerson.party.fullName}</span>,
				onClick: () => this.onBreadCrumbsPersonClick(contactPerson.id)
			});
		}
		
		let appealStatusText = appeal.status.name;
		
		if (appeal.resolution.title) {
			appealStatusText = `${appeal.status.name}: ${appeal.resolution.title}`;
		}
		
		breadcrumbs.push({
			className: `breadcrumbsLink last ${appeal.hasTasks ? 'breadcrumbsTitle' : ''} ${currentBreadcrumb === 'appeal' ? 'currentTitle' : ''}`,
			element: (
				<span>
                    <span className={`sticker sticker-${appeal.status.code}`}>{appealStatusText}</span>
                    <span>{t('tabs.appeals')} {appeal.regnum}</span>
                </span>
			),
			onClick: () => this.onBreadCrumbsAppealClick()
		});
		
		return breadcrumbs;
	};
	
	onFormChange = (changedValues, dispatch, props, previousValues) => {
		if (this.state.createNew || this.state.save) {
			this.setState({ createNew: false, save: false });
		}
		changeFormListener(changedValues, dispatch, props, previousValues);
	};
	
	// unlockAppealForm = () => {
	// 	const { match, unlockedAppeal, unlockAppeal } = this.props;
		
	// 	if (!unlockedAppeal && !unlockAppeal) {
	// 		this.props.unlockAppealForm(match.params.appealId);
	// 	}
	// };
	
	checkRestrictions = (field) => {
		const { restriction } = this.props.appeal;
		return (restriction && restriction[field] && restriction[field] === 1);
	};
	
	shouldRenderField = (field) => {
		const fieldRestriction = get(this.props.appeal, `restriction[${field}]`, 0);
		return fieldRestriction !== 2 && fieldRestriction !== 0;
	};
	
	openRepeatAppeal = () => {
		const requestData = {
			repeatRequestId: this.props.appeal.id,
			appealId: this.props.appeal.id,
			typeCode: 'PRECRM'
		};
		const onSuccess = createdAppeal => this.props.history.push(`/appeals/${createdAppeal.id}/`);
		
		return repeatAppeal(requestData, onSuccess);
	};
	
	excelExport = () => {
		const requestData = { appealId: this.props.appeal.id, filter: 0, query: null };
		downloadCSV({
			requestData,
			key: 'appeal_interaction_csv',
		}, this.togglePopup);
	};
	
	toggleCodeQuality () {
		this.setState({ callQuality: !this.state.callQuality, popupIsOpen: false });
	}
	
	renderOperator = (operator) => {
		const shouldRender = operator.code === 'edit' && !operator.owner;
		if (!shouldRender) {
			return null;
		}
		
		const text = `${operator.codeName} ${operator.name}`;
		
		return (
			<span title={text} className={`${operator.code}Code`}>
                {text}
            </span>
		);
	};
	
	async submit (e, createNew) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		await this.props.setSaveAndCreateNew(this.props.appeal.id, createNew);
		this.submitForm(this.props.appeal.id);
	}

	submitForm = id => {
        const {
			t,
            appeal,
            formValues,
            formHeaderValues,
            formErrors: formMainErrors,
			formHeaderErrors,
            showValidationNotification,
            setRequiredCustomer,
            setStatusModalVisibility,
            customer,
            contactPerson,
            destinations,
            callPhoneNumber,
            modalStatusList,
            saveAppeal,
            postSave,
            saveAndCreateNew,
            removeAppealLockSource,
			unlockAppealAndClearSourceWithoutForm,
			formParams,
			linkedObjects
        } = this.props;
		const formErrors = { ...formMainErrors, ...formHeaderErrors };
		const renderedFormParams = formParams && formParams.blocks.filter(block => dynamicParamsFilter(block, this.shouldRenderField));
        
		if (!isEmpty(formErrors)) {
            this.props.submit(`appeal-form-${id}`);
			this.setShouldScrollToError(true);

            const keysTitles =
				renderedFormParams &&
				renderedFormParams
                    .flatMap(block => block.widgets)
                    .reduce(
                        (acc, curr) => ({
                            ...acc,
                            [curr.key]: curr.title,
                        }),
                        {}
                    );
			// make clear error message in format {widget title}: {formError}
            let parsedErrorMessage = [];
			Object.keys(formErrors).forEach(key => {
				const title = keysTitles[key] || t(`appeal.${key}`); // try to get title from dyn params title, or from dictionary for static fields
				let error = formErrors[key];
				// map object / array error keys
				if (typeof error === 'object') {
					if (Array.isArray(error)) {
						error = error.join(', ');
					} else {
						error = Object.values(error).map(value => value).join(", ");
					}
				}
				parsedErrorMessage.push(`${title} (${error})`);
			});
            showValidationNotification(parsedErrorMessage.join(", "));
        } else {
            const { resolveDate, priority } = formHeaderValues;
            const {
                subject,
                description,
                type,
                objectId,
                destinationFeedback,
                destination,
                solution,
				resolution,
				status,
				timer,
				nextState,
				statusComment,
                ...rest
            } = formValues;

			const dynamicKeys = renderedFormParams && renderedFormParams.flatMap(block => block.widgets).map(widget => widget.key);

			const dynamicValues = {};
			// map values which are actually exist in current form params
			dynamicKeys && dynamicKeys.forEach(key => {
				if (key in rest) {
					dynamicValues[key] = rest[key];
				}
			});
			
            const dynamicFields = JSON.parse(JSON.stringify(dynamicValues));

			// remove cached_file_values added to handle files from knowledgebase template fill
			const keysToDelete = Object.keys(dynamicFields).filter(key => key.includes("cached_file_values") || key.includes("cached_ajax_options"));
			keysToDelete.forEach(key => {
				delete dynamicFields[key];
			})
			
            Object.keys(dynamicFields).forEach(key => {
                if (Array.isArray(dynamicFields[key])) {
                    if (dynamicFields[key].length) {
                        dynamicFields[key] = dynamicFields[key].map(value => {
                            return value.id ? value.id.toString() : value;
                        });
                    } else {
                        dynamicFields[key] = [null];
                    }
                } else if (isObjectLike(dynamicFields[key]) && !moment.isMoment(dynamicFields[key])) {
                    dynamicFields[key] = [getValueOrNull(dynamicFields[key].key)];
                } else {
                    dynamicFields[key] = [getValueOrNull(dynamicFields[key])];
                }
				// else if (dynamicFields[key] && moment(dynamicFields[key], "YYYY-MM-DDThh:mm:ssZ", true).isValid()) {
                //     dynamicFields[key] = ["" + moment(dynamicFields[key]).unix() * 1000];
                // } 
				// else {
                //     dynamicFields[key] = [getValueOrNull(dynamicFields[key])];
                // }
            });
            const filteredDestination = destination.filter(value => value);
            const appealRoutes = [];
            filteredDestination.forEach(i => {
                searchTree(destinations, "result", item => {
                    if (parseInt(item.object.id) === parseInt(i)) {
                        appealRoutes.push(item.object.route);
                    }
                });
            });

            const saveAppealData = {
                appealId: id,
                destination,
                dynamicFields,
                subject,
                description: getValueOrNull(description),
                type: String(get(type, "id")),
                // resolveDate: Number(moment(resolveDate).format("x")),
                resolveDate,
                // priority: priority.value,
				priority,
                solution: getValueOrNull(solution),
                interactionId: appeal.interactionId,
                objectTypeName: null,
                objectType: "appeal",
				objectTypeId: appeal.interactionId,
                objectId: null,
                // status: props.firstLevelAppealAction
                //     ? props.firstLevelAppealAction.toStateName
                //     : get(status, "code"),
				status: get(status, "code"),
                resolution: get(resolution, "id", null),
                customerId: null,
                contactPersonId: null,
                route: appealRoutes,
            };

            if (timer && nextState) {
                saveAppealData.timer = timer;
                saveAppealData.nextState = nextState;
            }

            if (statusComment) {
                saveAppealData.statusComment = statusComment;
            }

            if (customer) {
                const contactsType = [];
                const contactsValue = [];

                saveAppealData.customerId = customer.id;

                if (
                    contactPerson &&
                    (isOrganization(customer.party.partyType) ||
                        isServiceobjectType(customer.party.partyType) ||
                        isIndividual(customer.party.partyType))
                ) {
                    saveAppealData.contactPersonId = contactPerson.id;
                }

                if (destinationFeedback && destinationFeedback.length) {
                    destinationFeedback.forEach((field, key) => {
                        if (field === 0 && callPhoneNumber) {
                            contactsType.push("homePhone");
                            contactsValue.push(callPhoneNumber);
                            destinationFeedback[key] = null;
                        } else {
                            contactsType.push(null);
                            contactsValue.push(null);
                        }
                    });
                    const saveFeedbackData = {
                        data: {
                            appealId: id,
                            customerId: customer.id,
                            contacts: destinationFeedback,
                            contactsType: contactsType,
                            contactsValue: contactsValue,
                            personId:
                                contactPerson &&
                                (isOrganization(customer.party.partyType) ||
                                    isServiceobjectType(customer.party.partyType))
                                    ? contactPerson.id
                                    : null,
                        },
                        jsonType: true,
                    };

                    saveFeedback(saveFeedbackData);
                }
            }

            _.forEach(saveAppealData, (value, key) => {
                if (value && moment(value, "YYYY-MM-DDThh:mm:ssZ", true).isValid()) {
                    saveAppealData[key] = "" + moment(value).unix() * 1000;
                }
            });

            const isRequiredCustomerByObjects =
                get(formValues, "type.requiredObjects") && get(formValues, "type.requiredObjects").includes("CUSTOMER");
            const isCustReq = get(formValues, "type.isCustReq");
            const isRequiredCustomer = isRequiredCustomerByObjects || isCustReq;
            if (isRequiredCustomer && !customer) {
                showValidationNotification("validation.selectCustomer");
                setRequiredCustomer(id, true);
                return;
            }

            if (get(formValues, "type.requiredObjects") && get(formValues, "type.requiredObjects").includes("SERVICE")) {
                const isLinkedObjects = linkedObjects && linkedObjects.length > 0;
                if (!isLinkedObjects) {
                    showValidationNotification("validation.selectServices");
                    return;
                }
            }

            if (modalStatusList.length > 0) {
                setStatusModalVisibility(true, id);
                return;
            }
            // unlock appeal after save (clearup lockList)
            const appealNotificationId = appeal ? appeal.regnum : id;
            saveAppeal(saveAppealData, appealNotificationId).then(success => {
                if (success) {
                    postSave({ saveAndCreateNew: saveAndCreateNew, appealId: id });
					// this.props.setNeedReinitialize(appeal.id, true);
                    if (removeAppealLockSource && typeof removeAppealLockSource === "function") {
                        // On saveAppeal remove both "form" and "destinations" locks on appeal
                        // destinations could still exist which caused not unlocking the appeal and triggering a prompt modal)
                        removeAppealLockSource(LOCK_SOURCE_FORM);
                        removeAppealLockSource(LOCK_SOURCE_DESTINATIONS);
						unlockAppealAndClearSourceWithoutForm(appeal.id);
                    }
                }
            });
        }
    };
	
	refresh () {
		this.props.unlockAppealAndClearSource(this.props.appeal.id);
		this.props.requestInitialData(true);
	}
	
	closeRefreshModal () {
		this.props.removeRefreshAppealModal(this.props.appeal.id);
	}
	
	render () {
		const {
			appeal, unlockedAppeal, t, contentLoading, customer, change,
			customerType, contactPerson, appealTypes, formParams,
			appealFeedback, customerId, canCreateAppeal, formValues, formErrors, formHeaderValues, showAppealLinkModal,
			showObjectsLinkModal, linkedObjects,
			isSaveAndCreateNew, setAppealIsSaveAndCreateNew, appealLockSourceList,
			operators,
			isLockedByOther,
			lockSourceList,
			canGenerateFile
		} = this.props;
		
		const { printPopupIsOpen, callQuality, saveAndClose } = this.state;
		
		if (!appeal) return null;
		
		const popupConfig = [
			{
				label: t('appeal.save'),
				clickHandler: (e) => {
					// is used in for proper submit via StatusSelect (to keep track of value regarding createNew appeal or not)
					// setAppealIsSaveAndCreateNew(false, appeal.id);
					// this.submit(e);
					btnSubmitClickHandler(e, false);
				}
			},
			{
				label: t('appeal.saveAndCreateNew'),
				clickHandler: (e) => {
					// is used in for proper submit via StatusSelect (to keep track of value regarding createNew appeal or not)
					// setAppealIsSaveAndCreateNew(true, appeal.id);
					// this.submit(e, true);
					btnSubmitClickHandler(e, true);
				},
				disabled: !canCreateAppeal
			}
		];

		// show corresponding notifications during saving if either comment / mail / customer lockSource are present
		const btnSubmitClickHandler = (e, isCreateNew) => {
			let isPreventSubmit = false;
			if (appealLockSourceList && appealLockSourceList.length > 0) {
				appealLockSourceList.forEach(source => {
					if (SUBMIT_LOCK_SOURCE_NOTIFICATION_ERRORS.includes(source)) {
						isPreventSubmit = true;
						this.props.showNotification({
							type: NotificationTypes.APPEAL_CHANGE_FAILURE,
							options: { field: t(`appealFinishBeforeSave.${source}`), },
							id: `${source}_${new Date().getTime()}`,
						});
					}
				});
			}
			if (!isPreventSubmit) {
				// setAppealIsSaveAndCreateNew(false, appeal.id);
				setAppealIsSaveAndCreateNew(isCreateNew, appeal.id);
				this.submit(e, isCreateNew);
			}
		}
		
		const customerName = (isOrganization(customerType) || isServiceobjectType(customerType)) ?
			get(customer, 'party.officialName') : get(customer, 'party.fullName');
		
		const personName = get(contactPerson, 'party.fullName', '');

		const shouldRenderOperator = operators.find(operator => operator.code === 'edit' && !operator.owner);


		const shouldRenderActionSelect = this.props.appeal.restriction.nextAction !== 0;
		
		// const initValues = initialValues(
		// 	this.props.appeal,
		// 	this.props.appealTypes,
		// 	this.props.formParams,
		// 	this.props.appealFeedback,
		// 	this.props.customer,
		// 	t,
		// 	formValues,
		// 	// this.props.appealStatusList
		// );

		// console.log({refreshAppealModal: this.props.refreshAppealModal, appealLockSourceList,  isLockedByOther, savingAppeal: this.props.savingAppeal});
		// console.log({savingAppeal: this.props.savingAppeal, isCentralPartLoading: this.props.isCentralPartLoading, loadingParams: this.props.loadingParams});
		
		return (
			<main className='main-appeal'>
				{this.props.savingAppeal && <Loader />}
				<header className='header'>
					<Breadcrumbs data={this.getBreadCrumbs()} />
					<div className='date-range-wrapper'>
						{/* <button className={cx('btn-save', { hidden: generateFile })}
								onClick={this.openPrintPopup}>{t('appeal.printPattern')}
						</button> */}
						<button
							className='btn-add'
							onClick={this.togglePopup}
							ref={(node) => (this.actionButton = node)}
						>
							<i className='icon-kebab-vert' />
						</button>
						
						<AppealActionsPopup
							open={this.state.popupIsOpen}
							isAnonymous={!this.props.customer}
							closePopup={this.closePopup}
							openRepeatAppeal={this.openRepeatAppeal}
							callQuality={this.toggleCodeQuality}
							excelExport={this.excelExport}
							history={this.props.history}
							canGenerateFile={canGenerateFile}
							openPrintPopup={this.openPrintPopup}
						/>
						
						{
							printPopupIsOpen &&
							<AppealPrintPopup
								onClose={this.closePrintPopup}
								typeId={appeal.typeId}
								appealId={appeal.id}
							/>
						}
					</div>
				</header>
				
				<Switch>
					<Route path='/appeals/:appealId/person/:id/:subTab?' render={(props) => {
						if (!contactPerson) return null;
						return (
							<PersonPageContent
								{...props}
								contactPerson={contactPerson}
								personName={personName}
								customerId={customerId}
							/>
						);
					}} />
					<Route path='/appeals/:appealId/customer/:id/:subTab?' render={(props) => {
						if (!customer) return null;
						return (
							<CustomerTabContent
								{...props}
								customer={customer}
								tabsNames={getCustomerTabNames(customerType)}
								customerName={customerName}
								customerLastName={customer.party.lastName}
								customerFirstName={customer.party.firstName}
								customerPatronymic={customer.party.patronymic}
								customerId={customer.id}
								customerType={customerType}
							/>
						);
					}
					} />
					<Route path='/appeals/:appealId/:tab?' render={(props) => (
						<Fragment>
							<div className='appeal-content-wrapper'>
								<NewAppealForm
									t={t}
									formParams={formParams}
									checkRestrictions={this.checkRestrictions}
									shouldRenderField={this.shouldRenderField}
									appeal={appeal}
									unlockAppealForm={this.props.unlockAppealForm}
									destinations={this.props.destinations}
									appealDestinationId={this.props.appealDestinationId}
									appealDestinationName={this.props.appealDestinationName}
									customer={customer}
									selectedAppealAction={this.props.selectedAppealAction}
									formValues={formValues}
									formErrors={formErrors}
									formHeaderValues={formHeaderValues}
									linkedObjects={linkedObjects}
									loadingDestinations={this.props.loadingDestinations}
									onDestinationOpen={this.props.onDestinationOpen}
									canAddExecutors={this.props.canAddExecutors}
									resetActions={this.props.resetActions}
									resetAppealType={this.props.resetAppealType}
									isLoading={this.props.isCentralPartLoading || this.props.loadingParams}
									priorityList={this.props.priorityList}
									needReinitialize={this.props.needReinitialize}
									setNeedReinitialize={this.props.setNeedReinitialize}
									shouldScrollToError={this.state.shouldScrollToError}
									setShouldScrollToError={this.setShouldScrollToError}
									key={appeal.id}
									// key={`${appeal.id}_${get(formValues, 'type.id', "")}`}

								/>
								
								{
									this.props.refreshAppealModal && (
										<NotificationPortal>
											<div className={notificationStyle.notification}>
												<i className='icon-close' onClick={this.closeRefreshModal} />
												<div className={notificationStyle.appealChangesFailureNote}>
													<i className='icon-error' />
													<span>{t('appeal.reloadAppeal', { num: appeal.num })}</span>
													<button
														onClick={this.refresh}
														className='btn btn-primary m-t-5'
													>
														{t('refresh')}
													</button>
												</div>
											</div>
										</NotificationPortal>
									)
								}
								
								<TabContent
									contentLoading={contentLoading}
									unlockAppealForm={this.props.unlockAppealForm}
									removeAppealLockSource={this.props.removeAppealLockSource}
									resetAppealType={this.props.resetAppealType}
									isLockedByOther={isLockedByOther || this.props.refreshAppealModal || this.props.lockError}
									shouldRenderField={this.shouldRenderField}
									{...props}
									key={`${appeal && appeal.id}_TabContent`}
								/>
							</div>
							
							<footer className='footer'>
								<div className='toggleWrapper'>
									<CheckBoxToggle
										id='out'
										label={t('appeal.footerCheckBoxLabel')}
										checked={saveAndClose}
										onChange={this.toggleCheckbox}
									/>
								</div>
								<div className={cx('operatorsBlock', {withActions: shouldRenderActionSelect})}>
									{shouldRenderOperator && operators.map(this.renderOperator)}
									{!shouldRenderOperator && this.props.lockError && (
										<span title={this.props.lockError} className={`editCode`}>
											{this.props.lockError}
										</span>
									)}
								</div>
								<div className='footer-control-buttons'>
									{shouldRenderActionSelect && (
										<ActionSelect
											change={change}
											disabled={this.checkRestrictions("statusId")}
											unlockAppealForm={this.props.unlockAppealForm}
											permission={this.props.appeal.restriction.nextAction}
										/>
									)}
									<div className="divider" />
									<StatusSelect
										change={change}
										disabled={this.checkRestrictions('statusId')}
										unlockAppealForm={this.props.unlockAppealForm}
										formValues={formValues}
										permission={this.props.appeal.restriction.statusId}
										onSubmitButtonClick={() => this.setState({ save: false })}
										onStatusModalConfirm={() => this.submit(null, isSaveAndCreateNew)}
										// key={appeal.id}
									/>
									<ButtonSelectPopup
										text={t('appeal.save')}
										popupConfig={popupConfig}
										onClickBtn={(e) => btnSubmitClickHandler(e, false)}
										disabled={
											this.props.refreshAppealModal ||
											!(appealLockSourceList && appealLockSourceList.includes("form")) ||
											isLockedByOther ||
											this.props.savingAppeal ||
											this.props.isCentralPartLoading ||
											this.props.loadingParams
										}
										up
									/>
								</div>
							</footer>
						
						</Fragment>
					)} />
				</Switch>
				{
					callQuality && <ViewListQuality onClose={this.toggleCodeQuality} appeal={appeal} />
				}
				{
					showAppealLinkModal && <LinkAppeal appeal={appeal} />
				}
				{
					showObjectsLinkModal && <LinkObjects id={appeal.id} customerId={appeal.customerId} />
				}
			</main>
		);
	}
}

AppealMainContent.propTypes = {
	appeal: PropTypes.object,
	customer: PropTypes.object,
};
