import {
	SAVE_QUALITY, SAVE_QUALITY_SUCCESS,
} from 'constants/actions';
import baseService from 'services/BaseService';
import { showNotification } from './ui';
import { NotificationTypes } from 'constants/index';
import i18next from 'util/i18n';

export function getListQualityItems (interactionId, callback) {
	return async (dispatch) => {
		try {
			const data = { interactionId: interactionId };
			const response = await baseService.get('quality_questions_list', { data });
			
			if (response.success) {
				const data = { listQualityItems: response.result, isLoad: true };
				if (callback) callback(data);
			}
		}
		catch (error) {
			console.error(error);
			dispatch(showNotification({
				type: NotificationTypes.HTTP_ERROR,
				options: { message: error },
				error: 'Something went wrong'
			}));
		}
	};
}

export function getQualityForm (itemId, itemType, interactionId, mode, callback) {
	return async (dispatch) => {
		try {
			const openMode = mode ? 'update' : 'read';
			const params = {
				data: {
					ctxObjectType: 'INTERACTION',
					ctxObjectId: interactionId,
					objectId: interactionId,
					objectType: itemType,
					objectTypeId: itemId,
					openMode: openMode,
					curStepNum: 1,
					overrideIsReq: '',
				}
			};
			
			const response = await baseService.get('dinamic_params', params);
			if (response.success) {
				let data = {
					isOneItemView: true,
					isLoad: false,
					question: response.result,
					objectType: itemType,
					objectTypeId: itemId,
					openMode: openMode,
				};
				(itemType === 'RATE@INST') ? data = { ...data, edit: true } : null;
				if (callback) callback(data);
			}
		}
		catch (error) {
			console.error(error);
			dispatch(showNotification({
				type: NotificationTypes.HTTP_ERROR,
				options: { message: error },
				error: 'Something went wrong'
			}));
		}
	};
}

let isSavingQualityForm = false;
export function saveQualityForm (requestData, isEdit, callback) {
	return async (dispatch) => {
		if (!isSavingQualityForm) {
			try {
				const data = { data: { ...requestData }, jsonType: true };
				let typeSave = isEdit ? 'quality_edit_save' : 'quality_new_save';
				isSavingQualityForm = true;
				const response = await baseService.post(typeSave, data);
				isSavingQualityForm = false;
				
				dispatch({
					type: SAVE_QUALITY
				});
				
				if (response.success) {
					dispatch({
						type: SAVE_QUALITY_SUCCESS,
						response
					});
					
					(callback) ? callback() : null;
					
					dispatch(showNotification({
						type: NotificationTypes.SUCCESS,
						options: { message: i18next.t('callQualitySuccess') }
					}));
				}
			}
			catch (error) {
				console.error(error);
				isSavingQualityForm = false;
				dispatch(showNotification({
					type: NotificationTypes.HTTP_ERROR,
					options: { message: error },
					error: 'Something went wrong'
				}));
			}
		}
	};
}
