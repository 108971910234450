import Services from './Services';
import Objects from './Objects';
import History from '../../../../components/HistoryTab';
import Comments from './Comments';

export const subTabsConfig = [
    {
        tab: 'services',
        component: Services,
        permission: true,
        translateName: 'navigation.services'
    },
    {
        tab: 'objects',
        component: Objects,
        permission: true,
        translateName: 'navigation.objects'
    },
    {
        tab: 'history',
        component: History,
        permission: true,
        translateName: 'order.history',
        objectType: 'SPECIFICATION',
    },
    {
        tab: 'comments',
        component: Comments,
        permission: true,
        translateName: 'order.comments'
    }
];