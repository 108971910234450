import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';


export default function BlockedAppeal () {
	const { t } = useTranslation();
	
	return (
		<div className='wrapper'>
			<div className='appealMask'>
				<div className='appealMaskMsgBlock'>
					<FontAwesomeIcon className='mask-msg-icon' icon={faExclamationTriangle} />
					<p className='title'>{t('appeal.blockedAppeal.title')}</p>
					<p>
						{t('appeal.blockedAppeal.permission')} <br />
						{t('appeal.blockedAppeal.youCanClose')}
					</p>
					<p>{t('appeal.blockedAppeal.ifError')}</p>
				</div>
			</div>
		</div>
	);
}
