import { get, isEmpty } from "lodash";

const loggedErrors = {};
/*
    INDIVIDUAL collapsed:
    dynParams (1-3) => filled or required
    phone numbers (if exists)
    dynParams (4-6) => filled or required
    emails (if exists)
    dynParams (7-9) => filled or required
    address (if exists)
    dynParams (10-999) => filled or required

    INDIVIDUAL expanded => display everything ignoring filled or required but in the same order as collapsed

    ORGANIZATION collapsed => exactly the same as individual, but without email:
    dynParams (1-3) => filled or required
    phone numbers (if exists)
    dynParams (4-6) => filled or required
    emails (if exists)
    dynParams (7-9) => filled or required
    address (if exists)
    dynParams (10-999) => filled or required

    ORGANIZATION expanded => display everything ignoring filled or required but in the same order as collapsed

    SERVICEOBJECT collapsed => exactly the same as individual, but without phone numbers, emails and address:
    dynParams (1-3) => filled or required
    phone numbers (if exists)
    dynParams (4-6) => filled or required
    emails (if exists)
    dynParams (7-9) => filled or required
    dynParams (10-999) => filled or required

    SERVICEOBJECT expanded => display everything ignoring filled or required but in the same order as collapsed


    CONTACT PERSON collapsed => exactly the same as individual, but with characteristic and  without address:
    dynParams (1-3) => filled or required
    phone numbers (if exists)
    dynParams (4-6) => filled or required
    emails (if exists)
    dynParams (7-9) => filled or required
    dynParams (10-999) => filled or required
    characteristic
    CONTACT PERSON expanded => display everything ignoring filled or required but in the same order as collapsed
*/

// handle staticData
const getStaticExtendData = (staticBlocks, clientType, type, fieldName) => {
    const staticFormOrigin = staticBlocks.origin[`left_panel_customer_${clientType.toLowerCase()}_form`] || {};
    const staticFormDefault = staticBlocks.default[`left_panel_customer_${clientType.toLowerCase()}_form`] || {};
    const staticFormStaticUI =
        staticBlocks.defaultStaticUI[`left_panel_customer_${clientType.toLowerCase()}_form`] || {};

    const isNoOriginConfig = !staticFormOrigin || isEmpty(staticFormOrigin);
    const isNoDefaultConfig = !staticFormDefault || isEmpty(staticFormDefault);
    const logKey = `left_panel_customer_${clientType.toLowerCase()}_form_${type}`;
    let staticBlock;
    // no origin and default - fallback to UI
    if (isNoOriginConfig && isNoDefaultConfig) {
        staticBlock = get(staticFormStaticUI, `blocks.${type}`);
        if (!loggedErrors[logKey]) {
            console.info(
                `⚠️ getStaticExtendData::${type}: Static blocks are not configured correctly (no FORM and FORM_DEFAULT for [left_panel_customer_${clientType.toLowerCase()}_form: ${type}], using STATIC UI FALLBACK)`
            );
            loggedErrors[logKey] = true;
        }
    // try to set origin first (FORM), if not available => FORM_DEFAULT
    } else {
        staticBlock = get(staticFormOrigin, `blocks.${type}`);
        if (!staticBlock || isEmpty(staticBlock)) {
            if (!loggedErrors[`${logKey}_default`]) {
                console.info(`⚠️ getStaticExtendData::${type}: FORM blocks are not configured, trying to fallback to FORM_DEFAULT for [left_panel_customer_${clientType.toLowerCase()}_form: ${type}]`);
                loggedErrors[`${logKey}_default`] = true;
            }
            staticBlock = get(staticFormDefault, `blocks.${type}`);
        }
    }

    // console.log({[type]: staticBlock});

    const staticAttr =
        staticBlock && staticBlock.sortedAttrs ? staticBlock.sortedAttrs.find(item => item.field === fieldName) : null;

    const staticExtendData = {};

    if (staticAttr) {
        staticExtendData.isReq = staticAttr.required;
        staticExtendData.isHidden = staticBlock.hidden || staticAttr.hidden;
        staticExtendData.isHiddenByConfig = staticBlock.hidden || staticAttr.hidden;
        staticExtendData.hiddenValues = staticAttr.hiddenValues;
    }

    // block is not provided - hide widget
    if (!staticAttr) {
        staticExtendData.isHidden = true;
        staticExtendData.isHiddenByConfig = true;
    }

    // if (fieldName === "lastName") {
    //     console.log({staticBlock, staticAttr, staticExtendData});
    // }

    return staticExtendData;
};

const convertDictionaryObject = dictionaryObject =>
    Object.entries(dictionaryObject).map(([prop, value]) => ({
        key: prop,
        value: value,
    }));

export const CONTACT_CONFIG = {
    homePhone: {
        widgetType: "contact",
        field: "contacts.homePhone",
        // field: 'homePhone',
        // required: true,
        key: "homePhone",
        type: "homePhone",
    },
    workPhone: {
        widgetType: "contact",
        field: "workPhone",
        // required: true,
        key: "workPhone",
        type: "workPhone",
    },
    email: { widgetType: "contact", field: "contacts.email", key: "email", type: "email" },
};

// prepare names widgets
const multiNames = ["contactPerson", "individual"];
const getNameWidget = ({ t, client, clientType, field }) => {
    const isReq = ["officialName", "firstName"].includes(field);
    let liveSaveUrlKey = "customer_edit";
    if (clientType === "contactPerson") {
        liveSaveUrlKey = "person_edit_multi";
    }
    if (clientType === "individual") {
        liveSaveUrlKey = "customer_edit_multi";
    }
    return {
        title: t(`customerInfoLabel.${field}`),
        key: field,
        isReq,
        savedValue: get(client, `party.${field}`) || null,
        widgetType: "input",
        customLiveSubmit: data => {
            const { attrValue, attrCode } = data.data;
            const newData = {
                ...data,
                data: {
                    personId: clientType === "contactPerson" ? client.id : null,
                    customerId: clientType !== "contactPerson" ? client.id : null,
                },
            };
            const isMulti = multiNames.includes(clientType);
            if (!isMulti) {
                newData.data = {
                    ...newData.data,
                    text: attrValue,
                    field: `party.${attrCode}`,
                };
            }
            if (isMulti) {
                newData.data.edit = [
                    {
                        text: attrValue,
                        field: `party.${attrCode}`,
                    },
                ];
            }
            return { data: newData, liveSaveUrlKey };
        },
    };
};
export const getNameWidgets = ({ client, clientType, t, staticBlocks }) => {
    // return miltinames widgets;
    // console.log({client, clientType, t, staticBlocks});
    if (multiNames.includes(clientType)) {
        return [
            {
                ...getNameWidget({
                    t,
                    client,
                    clientType,
                    field: "lastName",
                }),
                ...getStaticExtendData(staticBlocks, clientType, "personalData", "lastName"),
            },
            {
                ...getNameWidget({
                    t,
                    client,
                    clientType,
                    field: "firstName",
                }),
                ...getStaticExtendData(staticBlocks, clientType, "personalData", "firstName"),
            },
            {
                ...getNameWidget({
                    t,
                    client,
                    clientType,
                    field: "patronymic",
                }),
                ...getStaticExtendData(staticBlocks, clientType, "personalData", "patronymic"),
            },
        ];
    }
    // return singlename widget
    return [
        {
            ...getNameWidget({
                t,
                client,
                clientType,
                field: "officialName",
            }),
            ...getStaticExtendData(staticBlocks, clientType, "juridicalData", "name"),
        },
    ];
};

// prepare custom widgets
export const getHomePhoneWidget = ({ client, handleContactChange, clientType, t, staticBlocks }) => {
    const phoneType = clientType === "contactPerson" ? "workPhone" : "homePhone";
    // prepare phoneWidget
    const phoneWidget = {
        ...CONTACT_CONFIG[phoneType],
        title: t("customerInfoLabel.homePhone"),
        savedValue: null,
        widgetType: "contact",
        handleContactChange,
        ...getStaticExtendData(staticBlocks, clientType, "personalData", phoneType),
    };
    // console.log({phoneWidget});
    let contacts = [];
    if (clientType === "contactPerson") {
        contacts = get(client, "contacts").filter(contact => contact.type === "workPhone");
    } else {
        contacts = get(client, "contacts.homePhone");
    }
    if (contacts.length === 0) {
        contacts = [{ value: "", id: 0 }];
    }
    // console.log({ phones: contacts });
    phoneWidget.savedValue = contacts;
    return phoneWidget;
};

export const getEmailWidget = ({ client, handleContactChange, clientType, t, staticBlocks }) => {
    // prepare emailWidget
    const emailWidget = {
        ...CONTACT_CONFIG.email,
        title: t("customerInfoLabel.email"),
        key: "email",
        savedValue: null,
        widgetType: "contact",
        handleContactChange,
        ...getStaticExtendData(staticBlocks, clientType, "personalData", "email"),
    };
    let contacts = [];
    if (clientType === "contactPerson") {
        contacts = get(client, "contacts").filter(contact => contact.type === "email");
    } else {
        contacts = get(client, "contacts.email");
    }
    if (contacts.length === 0) {
        contacts = [{ value: "", id: 0 }];
    }
    // console.log({ email: contacts });
    emailWidget.savedValue = contacts;
    // console.log({ emails: contacts });
    return emailWidget;
};

export const getAddressWidget = ({ client, t, clientType, staticBlocks }) => {
    const type = "custAddress";
    const staticFormOrigin = staticBlocks.origin[`left_panel_customer_${clientType.toLowerCase()}_form`] || {};
    const staticFormDefault = staticBlocks.default[`left_panel_customer_${clientType.toLowerCase()}_form`] || {};
    const staticFormStaticUI =
        staticBlocks.defaultStaticUI[`left_panel_customer_${clientType.toLowerCase()}_form`] || {};

    const isNoOriginConfig = !staticFormOrigin || isEmpty(staticFormOrigin);
    const isNoDefaultConfig = !staticFormDefault || isEmpty(staticFormDefault);
    const logKey = `left_panel_customer_${clientType.toLowerCase()}_form_${type}`;
    let staticBlock;
    // no origin and default - fallback to UI
    if (isNoOriginConfig && isNoDefaultConfig) {
        staticBlock = get(staticFormStaticUI, `blocks.${type}`);
        if (!loggedErrors[logKey]) {
            console.info(
                `⚠️ getAddressWidget::${type}: Static blocks are not configured correctly (no FORM and FORM_DEFAULT for [left_panel_customer_${clientType.toLowerCase()}_form: ${type}], using STATIC UI FALLBACK)`
            );
            loggedErrors[logKey] = true;
        }
    // try to set origin first (FORM), if not available => FORM_DEFAULT
    } else {
        staticBlock = get(staticFormOrigin, `blocks.${type}`);
        if (!staticBlock || isEmpty(staticBlock)) {
            if (!loggedErrors[`${logKey}_default`]) {
                console.info(`⚠️ getAddressWidget::${type}: FORM blocks are not configured, trying to fallback to FORM_DEFAULT for [left_panel_customer_${clientType.toLowerCase()}_form: ${type}]`);
                loggedErrors[`${logKey}_default`] = true;
            }
            staticBlock = get(staticFormDefault, `blocks.${type}`);
        }
    }
    // console.log({addressBlock: staticBlock });
    // const staticBlock = staticForm.blocks && staticForm.blocks.custAddress;
    // console.log({staticForm, staticBlock});
    // prepare addressWidget
    const addressWidget = {
        title: t("address"),
        key: "primaryAddress",
        savedValue: null,
        widgetType: "address",
        isHidden: !staticBlock || staticBlock.hidden,
        isHiddenByConfig: !staticBlock || staticBlock.hidden,
    };
    // console.log({addressWidget});
    const address = get(client, "primaryAddress");
    if (address) {
        // console.log({ address });
        const savedValue = address;
        addressWidget.savedValue = savedValue;
    }
    return addressWidget;
};

export const getIssuesWidget = ({ client, clientType, customerId, t, staticBlocks }) => {
    const staticData = getStaticExtendData(staticBlocks, clientType, "personalData", "issues");
    const values = convertDictionaryObject(t("dictionary:contactIssue", { returnObjects: true }));
    const issuesWidget = {
        title: t("customerInfoLabel.issues"),
        key: "issues",
        isReq: true,
        savedValue: null,
        widgetType: "multiselect",
        values,
        customLiveSubmit: data => {
            const { attrValue: issues, id } = data.data;
            const newData = {
                ...data,
                data: {
                    issues,
                    personId: id,
                    entityid: customerId,
                    entity_type: "CUSTOMER",
                },
            };
            return { data: newData, liveSaveUrlKey: "issues_edit" };
        },
        ...staticData,
    };
    const links = get(client, "links");
    const link = links && links.find(link => +link.entityId === +customerId);
    const issues = get(link, "issues");
    if (issues && issues.length > 0) {
        const savedValues = issues;
        issuesWidget.savedValues = savedValues;
    }
    return issuesWidget;
};

export const getCustomWidgets = ({ client, handleContactChange, clientType, customerId, t, staticBlocks }) => ({
    name: getNameWidgets({ client, handleContactChange, clientType, customerId, t, staticBlocks }),
    phone: getHomePhoneWidget({
        client,
        handleContactChange,
        clientType,
        customerId,
        t,
        staticBlocks,
    }),
    email: getEmailWidget({ client, handleContactChange, clientType, customerId, t, staticBlocks }),
    address: getAddressWidget({
        client,
        handleContactChange,
        clientType,
        customerId,
        t,
        staticBlocks,
    }),
    issues: getIssuesWidget({
        client,
        clientType,
        customerId,
        t,
        staticBlocks,
    }),
});

export const clientScheme = {
    // check phone, email, characteristic and merge them with dynParams
    contactPerson: [
        "name",
        { min: 1, max: 3 },
        "phone",
        { min: 4, max: 6 },
        "email",
        { min: 7, max: 9 },
        { min: 10, max: 999 },
        "issues",
    ],
    // check phone, email, address and merge them with dynParams
    individual: [
        "name",
        { min: 1, max: 3 },
        "phone",
        { min: 4, max: 6 },
        "email",
        { min: 7, max: 9 },
        "address",
        { min: 10, max: 999 },
    ],
    // check phone, address and merge them with dynParams
    organization: [
        "name",
        { min: 1, max: 3 },
        "phone",
        { min: 4, max: 6 },
        "email",
        { min: 7, max: 9 },
        "address",
        { min: 10, max: 999 },
    ],
    // check phone, email, address and merge them with dynParams
    serviceobject: [
        "name",
        { min: 1, max: 3 },
        "phone",
        { min: 4, max: 6 },
        "email",
        { min: 7, max: 9 },
        { min: 10, max: 999 },
    ],
};
