import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import ReactMarkdown from 'react-markdown';


export default function PrintTemplate({data = {}, t}) {
	const date = get(data, 'date', '');
	const dateFormatted = moment(date).isValid() && moment(date).format('DD.MM.YYYY HH.mm');
	const attachments = get(data, 'attachments', []).map( it => it.name);
	const mailFrom = get(data, 'from',' - ');
	let mailTo = get(data, 'to',' - ');
	mailTo = Array.isArray(mailTo) ? mailTo.join(', ') : mailTo;
	let mailCopy = get(data, 'copyTo',' - ');
	mailCopy = Array.isArray(mailCopy) ? mailCopy.join(', ') : mailCopy;
	
	return (
		<div>
			<table>
				
				<tr>
					<td style={{textAlign:'right'}} colSpan='3'>
						<strong>{t('mailing.mailbox')}: {get(data, 'mailbox')}</strong>
					</td>
				</tr>
				
				<tr>
					<td style={{textAlign:'left', color:'#3f70c5'}} colSpan='3'>
						{t('mailing.subject')}: <strong>{get(data, 'subject')}</strong>
					</td>
				</tr>
				
				<tr>
					<td colSpan='2'>
						<ul style={{ listStyle: 'none', padding: 0, color:'#3f70c5'}}>
							<li>{t('mailing.to')}: <strong>{mailTo}</strong></li>
							<li>{t('mailing.from')}: <strong>{mailFrom}</strong></li>
							<li>{t('mailing.copy')}: <strong>{mailCopy}</strong></li>
						</ul>
					</td>
					<td >
						{t('mailing.date')}: {dateFormatted}
					</td>
				</tr>
				
				<tr>
					<td colSpan='3'>
						<ReactMarkdown source={get(data, 'mailBody','')} escapeHtml={false} unwrapDisallowed={true} />
					</td>
				</tr>
				
				{attachments.length ?
					<tr>
						<td colSpan='3'>
							{t('mailing.attachments')}: <br/><br/>
							<strong style={{color:'#3f70c5'}}>{attachments.join(',  ')}</strong>
						</td>
					</tr>
					: null
				}
				
			</table>
		</div>
	)
}

PrintTemplate.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func,
};
