import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import styles from 'styles/modules/knowledgeBase.module.scss';
import IssueDetail from './IssueDetail';
import cn from 'classnames';


export default function Main () {
	return (
		<div className={cn(styles.mainBoxIssue, 'issue-main')}>
			<Switch>
				<Route path='/issue/create' exact component={IssueDetail} />
				<Route path='/issue/:id' exact component={IssueDetail} />
			</Switch>
		</div>
	);
}

Main.propTypes = {
	knowledgeBase: PropTypes.array
};
