import React from 'react';
import PropTypes from 'prop-types';
import ComboBox from 'components/Common/ComboBox';
import InputField from 'components/Common/InputField';
import { Field } from 'redux-form';
import { reduxFormWrapper } from 'helpers';
import FormWrapper from '../FormWrapper';
import { withTranslation } from 'react-i18next';
import validate from './validate';
import i18next from 'util/i18n';

export const organizationConfig = [
	{ name: 'businessType', label: i18next.t('subjectType'), component: ComboBox, },
	{
		name: 'shortName', label: i18next.t('name'), component: InputField, required: true, maxLength: 255, errorPlaceholder: true
	},
	{
		name: 'ownershipType', label: i18next.t('ownershipType'), component: ComboBox, maxLength: 255,
	},
	{
		name: 'institutionId',
		label: i18next.t('institutionId'),
		component: InputField,
		required: true,
		maxLength: 255,
		errorPlaceholder: true
	},
	{
		name: 'taxid',
		label: i18next.t('taxId'),
		component: InputField,
		required: true,
		maxLength: 20,
		errorPlaceholder: true
	}
];

@withTranslation()
@reduxFormWrapper({ form: 'organization-form', validate })
class OrganizationForm extends React.Component {
	
	constructor (props) {
		super(props);
		this.businessTypes = this.convertDictionaryObject(props.t('dictionary:businessType', { returnObjects: true }));
		this.ownershipTypes = this.convertDictionaryObject(props.t('dictionary:ownershipType', { returnObjects: true }));
	}
	
	componentDidUpdate (prevProps) {
		if (!this.props.pristine && prevProps.pristine) {
			this.props.enableWarning({ organization: true });
		} else if (this.props.pristine && !prevProps.pristine) {
			this.props.enableWarning({ organization: false });
		}
	}
	
	convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
		value: prop,
		label: value
	}));
	
	getFieldPropsWithOptions = fieldProps => {
		switch (fieldProps.name) {
		case 'businessType':
			return { ...fieldProps, options: this.businessTypes };
		case 'ownershipType':
			return { ...fieldProps, options: this.ownershipTypes };
		
		default:
			return fieldProps;
		}
	};
	
	renderField = fieldConfig => {
		return (
			<Field key={fieldConfig.name} {...this.getFieldPropsWithOptions(fieldConfig)} />
		);
	};
	
	render () {
		const { handleSubmit, customerType, t } = this.props;
		return (
			<FormWrapper
				title={t('juridicalData')}
				onSubmit={handleSubmit}
				customerType={customerType}
			>
				{organizationConfig.map(this.renderField)}
			</FormWrapper>
		);
	}
}

OrganizationForm.propTypes = {
	customerType: PropTypes.string,
	onSubmit: PropTypes.func,
};

export default OrganizationForm;
