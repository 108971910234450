import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
	validateEmail,
	individualTaxpayerNumber,
	certificateIdValidation,
	arrayUniqueValues,
	PHONE_NUMBER_UNIQUE,
	PHONE_NUMBER,
	EMAIL,
	EMAIL_UNIQU,
} from 'util/form';
import i18next from 'util/i18n';

/**
 * @param {any} value
 * @param {string} name - field name
 * @return {boolean|void} isValid
 */
export function isValidRequiredRegExpWithMessage (value, name, rule, isRequired) {
	if (isRequired && isEmpty(value)) return i18next.t('validation.required');
	if (value && rule){
		const [ message, ...rest ] = rule.split('$');
		const regexp = rest.join("");
		const regex = new RegExp(`^${regexp}$`);
		if (!value) return;
		if (!regex.test(value)) {
			return message;
		}
	}
	return !isValid(value, name);
}

export function isValid (value, name, rule) {
	if (rule) {
		const [ message, ...rest ] = rule.split('$');
		const regexp = rest.join("");
		const regex = new RegExp(`^${regexp}$`);
		function validatorRule (value){
			if (!value) return;
			if (!regex.test(value)) {
				return message;
			}
		}
		
		fieldLevelValidation[name] = validatorRule;
	}
	
	const validator = fieldLevelValidation[name];

	if (!validator) {
		// console.log(`Validator for field "${name}" is not found`);
		return true;
	}
	
	return validator(value) === undefined;
}

function validateDate (value) {
	if (!value || !value._i) return undefined;
	
	if ((value && moment.isMoment(value) && !value.isValid()) || value._i.length < 10) {
		return i18next.t('validation.correctDate');
	}
}

const validateSite = value => /^((((http|https):(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)(\.\w{2,3})))/.test(value);

function validateArray (value, errorCondition, errorText) {
	if (!value) return undefined;
	const errors = value.reduce((acc, fieldValue, index) => {
		if (errorCondition(fieldValue)) {
			acc[index] = errorText || i18next.t('validation.contactFormat');
		}
		return acc;
	}, {});
	return isEmpty(errors) ? undefined : errors;
}

export function phoneValidator (value) {
	if (!Array.isArray(value)) return undefined;
	const values = value.map(contact => contact.value);
	
	const unique = arrayUniqueValues(values, PHONE_NUMBER_UNIQUE());
	const errorCondition = fieldValue => fieldValue && !/^[0-9]{3,12}$/.test(fieldValue);
	const number = validateArray(values, errorCondition, PHONE_NUMBER());
	
	if (number || unique) {
		return { ...number, ...unique };
	}
}

function faxValidator (value) {
	if (!Array.isArray(value)) return undefined;
	const errorCondition = fieldValue => fieldValue && !/^[0-9]{4,}$/.test(fieldValue);
	
	return validateArray(value.map(contact => contact.value), errorCondition);
}

function emailValidator (value) {
	if (!Array.isArray(value)) return undefined;
	const values = value.map(contact => contact.value);
	
	const unique = arrayUniqueValues(values, EMAIL_UNIQU());
	const errorCondition = fieldValue => fieldValue && !validateEmail(fieldValue);
	const email = validateArray(values, errorCondition, EMAIL());
	
	if (email || unique) {
		return { ...email, ...unique };
	}
}

function siteValidator (value) {
	const errorCondition = fieldValue => fieldValue && !validateSite(fieldValue);
	return validateArray(value.map(contact => contact.value), errorCondition);
}

export const fieldLevelValidation = {
	mobilePhone: phoneValidator,
	homePhone: phoneValidator,
	workPhone: phoneValidator,
	organizationPhone: phoneValidator,
	fax: faxValidator,
	email: emailValidator,
	site: siteValidator,
	institutionId: individualTaxpayerNumber,
	taxId: certificateIdValidation,
	
	passportNumber: (value) => {
		if (value && !/^[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}/.test(value)) {
			return i18next.t('validation.passport');
		}
		
		return undefined;
	},
	
	identificationCode: (value) => {
		if (value && !/^([0-9]{5,10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6})/.test(value)) {
			return i18next.t('validation.id');
		}
		
		return undefined;
	},
	
	docId: (value) => {
		if (value && !/^([0-9]{5,10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6})/.test(value)) {
			return i18next.t('validation.id');
		}
		
		return undefined;
	},
	
	bankName: (value) => {
		if (!value) {
			return i18next.t('validation.required');
		}
		
		return undefined;
	},
	
	serviceobjectType: (value) => {
		
		if (!value) {
			return i18next.t('validation.required');
		}
		
		return undefined;
	},
	
	issues: (value = '') => {
		if (value.length === 0) return i18next.t('validation.required');
	},
	
	birthDate: validateDate,
	externalDate: validateDate,
	passportIssuedDate: validateDate
};

function validate (values, props) {
	const errors = {};
	for (const fieldName in values) {
		if (values.hasOwnProperty(fieldName)) {
			const validator = fieldLevelValidation[fieldName];
			
			if (!validator) {
				continue;
			}
			
			const error = validator(values[fieldName]);
			if (error) {
				errors[fieldName] = error;
			}
		}
	}
	
	if (!values.taxId) {
		errors.taxId = i18next.t('validation.required');
	}
	
	if (!values.institutionId) {
		errors.institutionId = i18next.t('validation.required');
	}
	
	return errors;
}

export default validate;
