import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Pill (props) {
	const {
		remove,
		value,
		id,
		validate
	} = props;
	
	return (
		<div key={`${value}-${id}`} className={cx('pills', (validate && !validate(value)) ? 'error' : '')}>
			<span className='value' title={value}>{value}</span>
			<span className='close' onClick={() => {remove(id);}}> <i className='icon icon-close'></i> </span>
		</div>
	);
}

Pill.propTypes = {
	remove: PropTypes.func.isRequired,
	validate: PropTypes.func,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};
