import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import { connect } from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import {change, getFormValues, getFormSyncErrors, isValid} from 'redux-form';
import ModalPortal from "../../components/ModalPortal";
import MessageModal from "../../components/MessageModal";
import {CSSTransition} from "react-transition-group";
const filterFormName = 'calendar-filter-form';
import { useTranslation } from 'react-i18next';
import permissions, { checkPermissions } from 'config/permissions';
import get from "lodash/get";
import moment from "moment/moment";

const mapStateToProps = state => ({
	formValues: getFormValues(filterFormName)(state),
	filterSyncError: getFormSyncErrors(filterFormName)(state),
	isValid: isValid(filterFormName)(state),
});

const mapDispatchToProps = dispatch => ({
	updateApiGrid: (params, gridKey) => dispatch(setApi(params, gridKey)),
	change: (value) => dispatch(change('calendar-filter-form', 'CLEVENT_RANGE_START_DATE', value))
});

const EventsList = props => {
	const { updateApiGrid, filterObject, filterSyncError, formValues, needUpdateGrid, setNeedUpdateGrid, change } = props;
	const [loadingState, setLoadingState] = useState(false);
	const history = useHistory();
	const [modalEventData, setModalEventData] = useState(null);
	const [isMounted, setIsMounted] = useState(false);
	const { t } = useTranslation();
	const isCanEventsView = checkPermissions(permissions.CalendarOperations.view_calendar_event );
	
	const init = {
		key: 'calendar_events',
		query: '',
		code: 'FTOP_CLEVENT',
		method: 'post',
		// isDynamic: true,
		configUrlKey: "config_grid",
		configKey: 'calendar_events',
		configCode: 'FTOP_CLEVENT',
	};
	const gridKey = `${init.key}_${init.path || init.extra || ''}`;

	const isReady = !filterSyncError.length && get(formValues, 'CLEVENT_RANGE_START_DATE.endDate') && get(formValues, 'CLEVENT_RANGE_START_DATE.startDate');
	
	// const isReady = !filterSyncError.length && formValues;
	
	const onStart = () => setLoadingState(true);
	
	const onFinish = () => setLoadingState(false);
	
	useEffect(() => {
		const startDate = get(formValues, 'CLEVENT_RANGE_START_DATE.startDate');
		const endDate = get(formValues, 'CLEVENT_RANGE_START_DATE.endDate');
		if (startDate && !endDate){
			const endDate = moment(startDate).endOf('day');
			console.log(endDate)
			change({startDate, endDate});
		}
		
	},[formValues]);
	
	useEffect(() => {
		if (isReady && needUpdateGrid && isMounted) {
				setNeedUpdateGrid(false);
				onStart();
				updateApiGrid({ query: '', type: 'clevent', filterObject}, gridKey);
		}
	}, [formValues, needUpdateGrid]);
	
	useEffect(() => {
		if (isReady) {
			onStart();
			updateApiGrid({ start: 0, limit: 20, query: '', type: 'clevent', filterObject}, gridKey);
		}
		setIsMounted(true);
	}, []);
	
	const setOpenMode = (mode) => {
		if(!isCanEventsView) return;
		
		setModalEventData(null);
		return history.push(`${modalEventData.url}/${mode}`);
	};
	
	const getOpenModesForModal = () =>{
		if (modalEventData && modalEventData.allowModes) {
			switch (modalEventData.allowModes.length) {
				case 2:
					return {
						primaryButton: true,
						secondaryButton: true,
						primaryButtonText: modalEventData.allowModes[0].label,
						onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
						secondaryButtonText: modalEventData.allowModes[1].label,
						onClickSecondaryButton: () => setOpenMode(modalEventData.allowModes[1].code),
					};
				case 3:
					return {
						dangerButton: true,
						primaryButton: true,
						secondaryButton: true,
						primaryButtonText: modalEventData.allowModes[0].label,
						onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
						secondaryButtonText: modalEventData.allowModes[1].label,
						onClickSecondaryButton: () => setOpenMode(modalEventData.allowModes[1].code),
						dangerButtonText: modalEventData.allowModes[2].label,
						onClickDangerButton: () => setOpenMode(modalEventData.allowModes[2].code),
					};
			}
		}
	};
	
	
	const handleSelect = (event) =>{
		if(!isCanEventsView) return;
		
		if(event.allowModes && event.allowModes.length > 1 ){
			return setModalEventData({
				url: event.url,
				allowModes: event.allowModes
			});
		}
		return history.push(`${event.url}/${event.allowModes[0].code}`);
	};
	
	return (
		<>
			{isReady &&
			<div className='content-wrapper ordering-component-ui-core-wrapper with-fixed-status customer-ordering-grid'>
				<div className='content-wrapper'>
					{loadingState && <Loader />}
					<GridWrapper
						tuner={() => init}
						gridParams={{
							selectItem: handleSelect
						}}
						initUpdate={false}
						onStart={onStart}
						onFinish={onFinish}
						destroyOnUnmount
					/>
				</div>
			</div>}
			<CSSTransition
				in={modalEventData}
				classNames='fade'
				appear
				enter
				exit
				timeout={300}
				mountOnEnter
				unmountOnExit
			>
				<ModalPortal onClose={() => setModalEventData(null)} className='modal-small modal-center'>
					<MessageModal
						titleModal={t('calendar.askEventModalTitle')}
						contentModalText={t('calendar.askEventModalDescription')}
						{...getOpenModesForModal()}
					/>
				</ModalPortal>
			</CSSTransition>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventsList));

