import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import cx from 'classnames';
import get from 'lodash/get';
import i18n from 'i18next';

export default class TextArea extends Component {
	constructor (props) {
		super(props);
		this.textarea = React.createRef();
	}
	
	componentDidMount () {
		if (this.props.autosize) {
			autosize(this.textarea.current);
		}
		if (this.props.autoFocus) {
			this.textarea.current.focus();
		}
	}
	
	componentDidUpdate (prevProps) {
		const previousValue = get(prevProps, 'input.value');
		const currentValue = get(this.props, 'input.value');
		if (previousValue !== currentValue) {
			this.props.autosize && autosize.update(this.textarea.current);
		}
	}
	
	render () {
		const { label, input, meta, placeholder, required, textAreaClassName, maxLength, ...restProps } = this.props;


		const error = meta && meta.touched && (meta.error || meta.warning);
		const className = cx(
			'input-field',
			error && 'input-field__error',
			meta && meta.active && (!meta.error && !meta.warning) && 'active',
			textAreaClassName
		);
		
		return (
			<div className='input-element'>
				<div className='input-label'>{label}{required && <span className='required-field'>*</span>}</div>
				<textarea
					className={className}
					placeholder={placeholder || i18n.t('text')}
					ref={this.textarea}
					maxLength={maxLength}
					{...input}
					autosize={toString(restProps.autosize)}
					{...restProps}
				/>
				{error && <span className='error-text error-hint'>{meta.error}</span>}
			</div>
		);
	}
}

TextArea.propTypes = {
	label: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object,
	textAreaClassName: PropTypes.string
};

