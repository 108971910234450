import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import ModalPortal from 'components/ModalPortal';
import Loader from 'components/Loader';
import { getListQualityItems, getQualityForm } from 'actions/quality';
import styles from 'styles/modules/appealQuality.module.scss';
import AppealQuality from './AppealQuality';

@withTranslation()
export default class ViewListQuality extends PureComponent {
	
	constructor (props) {
		super(props);
		this.state = {
			listQualityItems: [],
			isOneItemView: false,
			onMin: true,
			objectType: null,
			objectTypeId: null,
			openMode: '',
			edit: false,
			isLoad: false
		};
		this.renderlistQualityItems = this.renderlistQualityItems.bind(this);
		this.handlerResponse = this.handlerResponse.bind(this);
		this.handlerGoBack = this.handlerGoBack.bind(this);
	}
	
	componentDidMount () {
		const { interactionId } = this.props.appeal;
		getListQualityItems(interactionId, this.handlerResponse)();
	}
	
	handlerResponse (response) {
		this.setState(response);
	}
	
	handlerGoBack () {
		const { interactionId } = this.props.appeal;
		
		this.setState({ isOneItemView: !this.state.isOneItemView, edit: false });
		getListQualityItems(interactionId, this.handlerResponse)();
	}
	
	renderlistQualityItems () {
		const { listQualityItems, isLoad } = this.state;
		const { interactionId } = this.props.appeal;
		const { t } = this.props;
		const isEmpty = (listQualityItems.length === 0 || !listQualityItems.length);
		
		return (
			<Fragment>
				{!isLoad && <Loader withContainer={false} />}
				{isEmpty && isLoad && <div style={{ textAlign: 'center' }}> {t('quality.Empty')}</div>}
				{listQualityItems.length > 0 && (listQualityItems.map(form =>
					<li className={styles.ListItem}>
						<div className={styles.ItemLabel}
							 onClick={() => getQualityForm(
								 form.itemId,
								 form.itemType,
								 interactionId,
								 true,
								 this.handlerResponse)()}
						>
							<svg className={styles.star} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.93 13.72'>
								<g id='Layer_2' data-name='Layer 2'>
									<g id='Layer_91' data-name='Layer 91'>
										<path fill='#f7d100'
											  d='M13.46,4.92l-4.2-.63L7.37.35C7.27.12,7.13,0,7,0s-.3.12-.41.35L4.67,4.29l-4.2.63C.16,5,0,5.1,0,5.32a.67.67,0,0,0,.21.41L3.26,8.79l-.72,4.32a.66.66,0,0,0,0,.17.52.52,0,0,0,.09.31.3.3,0,0,0,.26.13.74.74,0,0,0,.34-.11l3.75-2,3.76,2a.68.68,0,0,0,.34.11.3.3,0,0,0,.25-.13.52.52,0,0,0,.09-.31.81.81,0,0,0,0-.17l-.72-4.32,3-3.06a.62.62,0,0,0,.22-.41c0-.22-.16-.35-.47-.4Z' />
									</g>
								</g>
							</svg>
							{form.itemName}
						</div>
						{form.items && form.items.map(item => <div className={styles.ItemRank}>
							<div className={styles.appealFinal}
								 onClick={() => getQualityForm(
									 item.itemId,
									 /*item.itemType*/'RATE@INST',
									 interactionId,
									 item.editable,
									 this.handlerResponse)()}
							>
								<div className={styles.appealFinalContainer}>
									<span className={styles.appealFinalQuality}>{item.totalScore}</span>
									<span className={styles.appealFinalQualityLabel}>
										{`${item.ownerName} (${moment(item.validFrom).format('DD.MM.YYYY HH:mm')})`}
									</span>
								</div>
							</div>
						</div>)}
					</li>))}
			</Fragment>);
	}
	
	render () {
		const { t, onClose, appeal } = this.props;
		const { question, isOneItemView, onMin, objectType, objectTypeId, edit, openMode } = this.state;
		return (
			<CSSTransition
				in={true}
				classNames='fade'
				appear={true}
				enter={true}
				exit={true}
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				{
					isOneItemView ?
						<AppealQuality onClose={onClose}
									   appeal={appeal}
									   onMin={onMin}
									   questionParams={question}
									   objectType={objectType}
									   objectTypeId={objectTypeId}
									   edit={edit}
									   openMode={openMode}
									   goBack={this.handlerGoBack}
						/>
						: <ModalPortal
							onClose={onClose}
							className='modal-medium modal-center'
							onMin={onMin}
						>
							<div className={styles.appealWindowList}>
								<div className={styles.appealQualityWindowHeader}>
									<div className={styles.appealQualityWindowTitle}>{t('quality.ListTitle')}</div>
								</div>
								
								<div className={styles.appealQualityForm}>
									<ul className={styles.QualityList}>
										{this.renderlistQualityItems()}
									</ul>
								</div>
							
							</div>
						
						</ModalPortal>
				}
			</CSSTransition>
		);
	}
}

ViewListQuality.propTypes = {
	appeal: PropTypes.object.isRequired,
	onClose: PropTypes.func
};

