import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';

export default function Breadcrumbs ({ routes = [], onClick }) {
	return (
		<div className={styles.breadcrumbs}>
			{routes.map(route => (
				<span
					key={`${route.id}_${route.text}`}
					className={styles.breadcrumb}
					onClick={() => onClick(route)}
				>
          {route.text}
        </span>
			))}
		</div>
	);
}

Breadcrumbs.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string,
		id: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		])
	})),
	onCheck: PropTypes.func
};
