import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import cx from 'classnames';

class FolderNode extends Component {
	
	constructor (props) {
		super(props);
		
		this.onClick = this.onClick.bind(this);
		this.selectAllHandler = this.selectAllHandler.bind(this);
		this.renderCheckBoxes = this.renderCheckBoxes.bind(this);
	}
	
	onClick (event) {
		event.stopPropagation();
		this.props.onClick(this.props.node);
	}
	
	selectAllHandler (e) {
		const { node } = this.props;
		
		this.props.selectFolder(node, e.target.checked);
	}
	
	renderCheckBoxes () {
		const { node, childrenField } = this.props;
		const checked = node.selected ? node.selected : false;
		const halfSelect = node.semiSelectedParent ? node.semiSelectedParent : node[childrenField].some(item => {return item.semiSelected;});
		return (
			<div
				className='folder-checkbox'
				data-header-name='_checkbox'
			>
				<label className='container'>
					<input type='checkbox' checked={checked} onChange={this.selectAllHandler} />
					<span className={cx('check-mark', { 'semi-select': halfSelect })} />
				</label>
			</div>
		);
	}
	
	render () {
		const { node, childrenField, renderNode, labelField, multi } = this.props;
		const haveChildren = Array.isArray(node[childrenField]) && node[childrenField].length > 0;
		
		return (
			<div className={cx(styles.node, styles.folder, { multi: 'multi' })}>
				{multi && this.renderCheckBoxes()}
				<div className={cx(styles.title)} onClick={this.onClick}>
					<i className='icon-folder' />
					<label>{node[labelField]}</label>
				</div>
				{
					node.open && haveChildren &&
					<div className={styles.folderChildren}>
						{node[childrenField].map(renderNode)}
					</div>
				}
			</div>
		);
	}
}

FolderNode.propTypes = {
	open: PropTypes.bool,
	node: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	oncheckFolder: PropTypes.func,
	renderNode: PropTypes.func.isRequired,
	multi: PropTypes.bool
};

export default FolderNode;
