import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { closeCreateNewContactPersonModal } from "actions/ui";
import ModalPortal from "../ModalPortal";
import MessageModal from "../MessageModal";
import { CSSTransition } from "react-transition-group";
import { destroy, reset } from "redux-form";
import CreateNewContactPersonForm from "./CreateNewContactPersonForm";
import { withTranslation } from "react-i18next";
import { KeyCodes } from "constants/index";

const mapStateToProps = state => ({
    showCreateNewContactPersonModal: state.ui.showCreateNewContactPersonModal,
    values: getFormValues("create-new-contact-person")(state),
});

const mapDispatchToProps = dispatch => ({
    closeCreateNewContactPersonModal: () => dispatch(closeCreateNewContactPersonModal()),
    destroyForm: form => dispatch(destroy(form)),
});

const CreateNewContactPersonModal = props => {
    const { destroyForm, values, closeCreateNewContactPersonModal, showCreateNewContactPersonModal, t } = props;
    const [warningPopupOpen, setWarningPopupOpen] = useState(false);
    const [dynConfig, setDynConfig] = useState(null);

    function isFormValuesEmpty() {
        if (values) {
            return !Object.values(values).some(value => {
                if (typeof value === "string") {
                    return Boolean(value.trim());
                }
                return Boolean(value);
            });
        }
        return true;
    }

    function handleOpenWarningPopup() {
        setWarningPopupOpen(true);
    }

    function handleCloseWarningPopup() {
        setWarningPopupOpen(false);
    }

    function onClose() {
        isFormValuesEmpty() ? closeCreateNewContactPersonModal() : handleOpenWarningPopup();
    }

    function handleKeyPress(e) {
        if (e.keyCode === KeyCodes.ESCAPE) onClose();

        // navigate Tab key
        if (e.keyCode === KeyCodes.TAB) {
            e.preventDefault();
            const modal = document.querySelector(".modal-window");
            const inputs = modal.querySelectorAll("form .input-field");
            const focused = document.activeElement;

            if (focused.nodeName !== "BODY") {
                inputs.forEach((input, index) => {
                    if (focused === input) return inputs[index + 1].focus();
                    if (focused === inputs[inputs.length - 1]) return inputs[0].focus();
                });
            } else return inputs[0].focus();
        }
    }

    function onClickDangerButton() {
        handleCloseWarningPopup();
        closeCreateNewContactPersonModal();
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
            destroyForm("create-new-contact-person");
        };
    }, []);

    return (
        <>
            <CSSTransition
                in={showCreateNewContactPersonModal}
                classNames="fade"
                appear={true}
                enter={true}
                exit={true}
                timeout={500}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <ModalPortal className="ordering-component-ui-core-wrapper" onClose={onClose}>
                    <CreateNewContactPersonForm dynConfig={dynConfig} setDynConfig={setDynConfig} onClose={closeCreateNewContactPersonModal} />
                </ModalPortal>
            </CSSTransition>
            {warningPopupOpen && (
                <ModalPortal
                    onClose={handleCloseWarningPopup}
                    className="modal-small ordering-component-ui-core-wrapper"
                >
                    <MessageModal
                        titleModal={t("persons.attention")}
                        contentModalText={t("modalWindowsLabels.notSavedWarning")}
                        dangerButton={true}
                        dangerButtonText={t("close")}
                        onClickDangerButton={onClickDangerButton}
                        secondaryButton={true}
                        secondaryButtonText={t("cancel")}
                        onClickSecondaryButton={handleCloseWarningPopup}
                    />
                </ModalPortal>
            )}
        </>
    );
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateNewContactPersonModal));
