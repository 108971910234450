import React from 'react';
import { getFirstLetters, isOrganization, isServiceobjectType } from 'helpers';
import '../CustomerPage/styles.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

function Avatar (props) {

	const { handleClick = () => {} } = props;
	
	const formattedName = getFirstLetters(props.name);
	
	const organization = isOrganization(props.customerType);
	const serviceobject = isServiceobjectType(props.customerType);
	
	const avatarClassName = cx(
		'avatar', 'customerAvatar',{
			organization: organization || serviceobject,
			individual: !organization && !serviceobject
		},
		props.className
	);
	
	return (
		<div className={avatarClassName} onClick={handleClick}>
			{formattedName}
		</div>
	);
}

Avatar.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	customerType: PropTypes.string
};

export default Avatar;
