import {
	CONTENT_UPDATE,
	CONTENT_LOADING,
	CONTENT_SET,
	CONTENT_ERROR,
	CONTENT_TAB,
	CONTENT_RESET,
	REMOVE_PREVIOUS_TAB_DATA
} from 'constants/actions';

const initState = {
	data: null,
	update: false,
	bindData: null,
	contentLoading: false
};

export default (state = initState, action) => {
	let newState;
	
	switch (action.type) {
	case CONTENT_LOADING:
		return {
			...state,
			contentLoading: true
		};
	
	case CONTENT_ERROR:
		const { error, storeName } = action;
		
		return {
			...state,
			contentLoading: false,
			[storeName]: {
				error: error && error.message
			}
		};
	
	case CONTENT_UPDATE:
		return {
			...state,
			update: action.update || []
		};
	
	case CONTENT_SET:
		newState = {
			...state,
			contentLoading: false
		};
		
		newState[action.storeName] = {
			...(action.data || newState.data),
			// loading: false
		};
		
		newState.update = [];
		
		return newState;
	
	case CONTENT_TAB:
		return {
			...state,
			data: {
				...state.data,
				tab: action.tab
			},
			update: []
		};
	
	case CONTENT_RESET:
		newState = { ...state };
		newState[action.params || 'data'] = null;
		
		return newState;
	
	case REMOVE_PREVIOUS_TAB_DATA:
		return { ...state, [action.subTab]: undefined };
	
	default:
		return state;
	}
};
