import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import SearchFilters from 'components/SearchFilters';
import styles from 'styles/modules/searchPage.module.scss';
import { RouteWithTabs, safeQuery, createCheckPropsFunction } from 'helpers';
import {
	setSearchFilterCriteria,
	getAllFilterFields,
	getAppealTypes,
	getSearchFilters,
	resetSearchFilterDynamicState,
} from 'actions/searchFilter';
import {
	getSearchResults,
	setEmptySearchResults,
	updatePagination,
	updateSearchQuery,
	updateSearchTabHash,
} from 'actions/search';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, initialize } from 'redux-form';
import InputBlock from './InputBlock';
import TabContent from './TabContent';
import { queriesContentUpdate } from 'actions/content';
import settingsService, { SORT_POSTFIX } from 'services/settingsService';

const GRID_TABS = ["task", "resource", "spec", "clevent", "customer", "appeal", "contact_person"];


const CODES = {
	contact_person: 'CONTACT_PERSON',
	customer: 'CUSTOMER',
	appeal: 'INTERACTION_REQUEST'
}

function mapStateToProps (state, props) {
	const { hash, entity } = props.match.params;
    const searchId = `${hash}-${entity}`;
    return {
		searchId: searchId,
		query: state.search.searches[hash] || '',
        isResultsPopupOpen: state.search.isResultsPopupOpen,
		formValues: getFormValues(`search-filters-${searchId}`)(state),
		filterObject:  _.get(state, `searchFilter.filters[${searchId}]`, []),
        tabHash: state.search.tabHash,
        pagination:  _.get(state, `search.pagination[${searchId}]`, { start:0, limit:20, page:1 }),
        allFilterFields:  _.get(state, `searchFilter.allFilterFields[#${entity}]`, []),
        appealTypes: state.searchFilter.appealTypes,
		idHashKeyRequest: `#${entity}`,
		idHashKeyRequestFormResponse: state.search.idHashKeyRequest
	};
}

const mapDispatchToProps = {
	getSearchResults,
	updateSearchTabHash,
	updateSearchQuery,
	getSearchFilters,
	getAllFilterFields,
	getAppealTypes,
	updatePagination,
	resetSearchFilterDynamicState,
	setSearchFilterCriteria,
	setEmptySearchResults,
	initialize,
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SearchPage extends Component {
	constructor (props) {
		super(props);
		this.getSearchEntity = this.getSearchEntity.bind(this);
		this.requestSearchResults = this.requestSearchResults.bind(this);
		this.renderTabs = this.renderTabs.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.requestInitialData = this.requestInitialData.bind(this);
	}
	
	componentDidMount () {
		this.requestInitialData();
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		const { idHashKeyRequest, searchId, pagination } = this.props;
		if (isPropChanged('match.params.hash') && this.props.match.params.hash !== this.props.hash) {
			this.props.resetSearchFilterDynamicState();
			
			this.requestInitialData();
		}
		
		if (isPropChanged('match.params.entity') && !isPropChanged('match.params.hash')) {
			this.requestSearchResults({ page: pagination.page });
			
			if (prevProps.match.params.entity !== this.props.match.params.entity) {
				
				this.props.getSearchFilters({ code: Codes[this.getSearchEntity()] });
				this.props.getAllFilterFields({
					code: Codes[this.getSearchEntity()],
					idHashKeyRequest: idHashKeyRequest
				});
			}
		}
		
	}
	
	requestInitialData () {
		const { params } = this.props.match;
		const { query, idHashKeyRequest, pagination } = this.props;
		this.props.updateSearchTabHash(params.hash);
		this.props.updateSearchQuery(safeQuery(query, true));
		
		if (!params[0]) {
			this.props.setEmptySearchResults(params.entity);
		}
		
		this.requestSearchResults({ page: pagination.page });
		isEmpty(this.props.appealTypes) && this.props.getAppealTypes();
		
		this.props.getSearchFilters({ code: Codes[this.getSearchEntity()] });
		this.props.getAllFilterFields({
			code: Codes[this.getSearchEntity()],
			idHashKeyRequest: idHashKeyRequest
		});
	}
	
	
	getSearchEntity () {
		return this.props.match.params.entity;
	}
	
	requestSearchResults (params = { filterObject: [] }) {
		const entity = this.getSearchEntity();
		const { pagination, query, searchId, idHashKeyRequest } = this.props;
		const searchQuery = params.hasOwnProperty('query') ? params.query : query;
		const limit = 20;
		let { start } = pagination;
		let { page } = params;
		
		if (!page && params.filterObject && params.filterObject !== this.props.filterObject) {
			page = 1;
		}
		
		if (page && page !== pagination.page) {
			start = (page - 1) * limit;
			this.props.updatePagination(searchId, { start, page, limit });
		}
		let requestData = {
			start,
			limit: limit,
			query: safeQuery(searchQuery, true),
			idHashKeyRequest,
		};
		
		if (params.sort || settingsService.get(`${entity}${SORT_POSTFIX}`)) {
			this.sort = params.sort || settingsService.get(`${entity}${SORT_POSTFIX}`);
			requestData['sort'] = [{
				property: this.sort.property,
				direction: this.sort.direction
			}];
			queriesContentUpdate({
				sort: [{
					property: this.sort.property,
					direction: this.sort.direction
				}]
			});
		}
		requestData = {
			...requestData,
			type: entity,
			code: CODES[entity],
			filterObject: !params.skipFilter ? (params.filterObject || this.props.filterObject) : [],
		};
		
		// const isNewGidTab = NEW_GRID_TABS.includes(this.getSearchEntity());
		// if (!isNewGidTab) {
		// 	this.props.getSearchResults(requestData, entity);
		// }
	}
	
	onPageChange ({ page, sort }) {
		const entity = this.getSearchEntity();
		if (!sort && localStorage[`${entity}${SORT_POSTFIX}`]) {
			sort = JSON.parse(localStorage[`${entity}${SORT_POSTFIX}`]);
		}
		this.requestSearchResults({ skipQueryCheck: true, page, sort });
	}
	
	renderTabs (props) {
		const { idHashKeyRequest, idHashKeyRequestFormResponse } = this.props;

		return <TabContent
			{...props}
			match={this.props.match}
			location={this.props.location}
			onPageChange={this.onPageChange}
			pagination={this.props.pagination}
			idHashKeyRequest={idHashKeyRequest}
			idHashKeyRequestFormResponse={idHashKeyRequestFormResponse}
		/>
	}
	
	render () {
		const { match, location, isResultsPopupOpen, query, idHashKeyRequest, searchId } = this.props;
		const containerClassName = cx(styles.container, {
			[styles.openedSearch]: isResultsPopupOpen,
		});
		
		return (
			<div className={styles.search}>
				<div className={containerClassName}>
					<InputBlock
						query={query}
						id={match.params.hash}
						onSubmit={this.requestSearchResults}
						setSearchFilterCriteria={this.props.setSearchFilterCriteria}
						key={searchId}
					/>
					<div className={styles.searchResult}>
						<RouteWithTabs
							path='/search/:entity/:hash'
							render={this.renderTabs}
							tabs={GRID_TABS}
							tabPath='entity'
							match={match}
							location={location}
						/>
					</div>
				</div>
				<SearchFilters
					onSearch={this.requestSearchResults}
					id={match.params.hash}
					query={query}
					idHashKeyRequest={idHashKeyRequest}
				/>
			</div>
		);
	}
}

SearchPage.propTypes = {
	searchResults: PropTypes.object,
	openedSearch: PropTypes.bool,
	query: PropTypes.string,
	initialQuery: PropTypes.string,
	dataFilterResult: PropTypes.object,
};

export const Codes = {
	resource: "SPEC_RESOURCE",
	spec: "SPEC_INSTANCE",
	task: 'ORDER_TASK',
	customer: 'CUSTOMER',
	contact_person: 'CONTACT_PERSON',
	appeal: 'INTERACTION_REQUEST',
	callhistory: 'TASK',
	clevent: 'FTOP_SEARCH_CLEVENT',
	file: 'SERVICE',
};

export default SearchPage;
