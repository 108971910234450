import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import { getFormSyncErrors, isValid, getFormValues } from 'redux-form';
import { get } from 'lodash';
import { configureFilterObject } from '../SearchFilters/helpers';
// import Diagram from '../../pages/Ordering/Orders/Diagram';

const filterFormName = 'search-filters-';

const mapStateToProps = (state, props) => {
    const { hash, entity } = props.match.params;
    const { searchId } = props;
    return {
        query: state.search.searches[hash] || '',
        formValues: getFormValues(`${filterFormName}${searchId}`)(state),
        allFilterFields: get(state, `searchFilter.allFilterFields[#${entity}]`, []),
        filerSyncError: getFormSyncErrors(`${filterFormName}${searchId}`)(state),
        isValid: isValid(`${filterFormName}${searchId}`)(state),
    };
};

const code = "CONTACT_PERSON";

const ContactPersonList = (props) => {
    const { updateApiGrid, formValues, query, allFilterFields, history } = props;
    const { hash } = props.match.params;

    const [loadingState, setLoadingState] = useState(false);

    const init = {
        key: "contact_person",
        extra: hash,
        method: 'post',
        code,
        filterObject: [],
        configUrlKey: "config_grid",
        configKey:"contact_person",
        configCode: code,
        type: "contact_person",
    };
    const gridKey = `${init.key}_${init.path || init.extra || ''}`;

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
        if (formValues || ![null, undefined].includes(query)) {
            const filterObject = configureFilterObject(formValues || {}, allFilterFields);
            updateApiGrid({ query, type: 'contact_person', filterObject }, gridKey);
        }
    }, [formValues, query]);

    useEffect(() => {
        const filterObject = configureFilterObject(formValues || {}, allFilterFields);
        updateApiGrid({ start: 0, limit: 20, query, type: 'contact_person', filterObject }, gridKey);
    }, [hash]);

    const getParams = () => ({
        selectItem: (item) => history.push(`/customer/${item.customerId}/person/${item.id}/contact`),
    });

    return (
        <>
            <div className='content-wrapper ordering-component-ui-core-wrapper with-fixed-status customer-ordering-grid'>
                <div className='content-wrapper with-fixed-status'>
                    {loadingState && <Loader />}
                    <GridWrapper
                        tuner={() => init}
                        gridParams={getParams()}
                        initUpdate={false}
                        onStart={onStart}
                        onFinish={onFinish}
                    />
                </div>
            </div>
        </>
    );
};

export default withRouter(connect(mapStateToProps, { updateApiGrid: setApi })(ContactPersonList));
