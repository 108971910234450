import {
	EMAILS_REQUEST,
	EMAILS_REQUEST_SUCCESS,
	EMAILS_REQUEST_FAILURE,
	SEND_EMAIL_REQUEST,
	SEND_EMAIL_SUCCESS,
	SEND_EMAIL_FAILURE,
	TOGGLE_ALL_MAILS,
	UPLOAD_EMAIL_FILE_REQUEST,
	UPLOAD_EMAIL_FILE_SUCCESS,
	UPLOAD_EMAIL_FILE_FAILURE,
	DELETE_EMAIL_FILE_REQUEST,
	DELETE_EMAIL_FILE_SUCCESS,
	DELETE_EMAIL_FILE_FAILURE,
	UPLOAD_EMAIL_FILE_LIST_REQUEST,
	UPLOAD_EMAIL_FILE_LIST_SUCCESS,
	UPLOAD_EMAIL_FILE_LIST_FAILURE,
	CREATE_NEW_EMAIL_REQUEST,
	CREATE_NEW_EMAIL_SUCCESS,
	CREATE_NEW_EMAIL_FAILURE,
	GET_TEMPLATE_EMAIL_REQUEST,
	GET_TEMPLATE_EMAIL_SUCCESS,
	GET_TEMPLATE_EMAIL_FAILURE,
	GET_TEMPLATE_LIST_REQUEST,
	GET_TEMPLATE_LIST_SUCCESS,
	GET_TEMPLATE_LIST_FAILURE,
	SET_FILES_LENGTH,
	SET_FORM_FORWARD,
	SET_FORM_REPLY,
	SET_SIGNATURE,
	RESET_EMAIL_FORM,
	OPEN_EMAIL,
	OPEN_EMAIL_SUCCESS,
	EMAILS_SEARCH_QUERY_UPDATE,
	APPEAL_MAILING_RESET,
	SET_MAIL_BODY,
	SET_REPLY_MAIL_BODY,
	SET_MAIL_STATE,
	COUNTER_MAILING_UP,
	CHANGE_EDIT_SIGN_MODE,
	UPDATE_SIGNATURE_LIST,
} from 'constants/actions';
import baseService from '../services/BaseService';
import { showNotification } from './ui';
import { NotificationTypes } from 'constants/index';

export function setMailBody (id, body) {
	return (dispatch) => {
		dispatch({
			type: SET_MAIL_BODY,
			id,
			body
		});
	};
}

export function setReplyMailBody (id, replyMailBody) {
	return (dispatch) => {
		dispatch({
			type: SET_REPLY_MAIL_BODY,
			id,
			replyMailBody
		});
	};
}

export function setMailState (id, state) {
	return (dispatch) => {
		dispatch({
			type: SET_MAIL_STATE,
			id,
			state
		});
	};
}

export const openEmail = emailId => ({
	type: OPEN_EMAIL,
	payload: { id: emailId }
});

export const openEmailSuccess = () => ({
	type: OPEN_EMAIL_SUCCESS
});

/********  Emails request  ********/

const requestEmails = id => ({
	type: EMAILS_REQUEST,
	id
});

const requestEmailsSuccess = (id, emails, total) => ({
	type: EMAILS_REQUEST_SUCCESS,
	emails,
	id,
	total
});

const requestEmailsFailure = (id) => ({
	type: EMAILS_REQUEST_FAILURE,
	id
});

export const getEmails = (requestData, id) => dispatch => {
	dispatch(requestEmails(id));
	
	const params = { data: requestData };
	
	return baseService.get('mailing', params)
		.then(response => {
			if (response.success) {
				const sortedEmails = response.result ? response.result.sort((a, b) => b.date - a.date) : [];
				dispatch(requestEmailsSuccess(id, sortedEmails, response.total));
				dispatch(dispatch({ type: COUNTER_MAILING_UP }));
			}
			 else {
				dispatch(requestEmailsFailure(id));
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(requestEmailsFailure());
		});
};

/********  Search query update  ********/

export const updateSearchQuery = (id, query) => ({
	type: EMAILS_SEARCH_QUERY_UPDATE,
	query,
	id,
});

/********  Appeal mailing reset  ********/

export const resetAppealMailing = (id) => ({
	type: APPEAL_MAILING_RESET,
	id,
});

/**************************/

const sendEmailRequest = () => ({
	type: SEND_EMAIL_REQUEST
});

const sendEmailSuccess = (id, data) => ({
	type: SEND_EMAIL_SUCCESS,
	payload: data,
	id
});

const sendEmailFailure = () => ({
	type: SEND_EMAIL_FAILURE
});

export const sendEmail = (id, params) => dispatch => {
	dispatch(sendEmailRequest());
	
	return baseService.post(params.key, params)
		.then(data => {
			dispatch(sendEmailSuccess(id, data.success));
			dispatch(showNotification({
				type: NotificationTypes.SUCCESS,
				options: {
					message: 'Лист відправлений успішно'
				}
			}));
			return true;
		})
		.catch(err => dispatch(sendEmailFailure(err)));
};

export const toggleAllMails = (id) => ({
	type: TOGGLE_ALL_MAILS,
	id,
});

const getFilesListRequest = (id) => ({
	type: UPLOAD_EMAIL_FILE_LIST_REQUEST,
	id,
});

const getFilesListSuccess = (id, data) => ({
	type: UPLOAD_EMAIL_FILE_LIST_SUCCESS,
	payload: data,
	id
});

const getFilesListFailure = (id) => ({
	type: UPLOAD_EMAIL_FILE_LIST_FAILURE,
	id
});

export const getFilesList = (id, appealId) => (dispatch) => {
	dispatch(getFilesListRequest(id));
	
	const params = {
		data: {
			interactionId: appealId,
			page: 1,
			start: 0,
			limit: 25
		}
	};
	
	baseService.get('get_files_list', params)
		.then(data => dispatch(getFilesListSuccess(id, data.result)))
		.catch(err => dispatch(getFilesListFailure(err)));
};

const uploadFileRequest = (id) => ({
	type: UPLOAD_EMAIL_FILE_REQUEST,
	id,
});

const uploadFileSuccess = (id) => ({
	type: UPLOAD_EMAIL_FILE_SUCCESS,
	id
});

const uploadFileFailure = (id) => ({
	type: UPLOAD_EMAIL_FILE_FAILURE,
	id
});

export const uploadFile = (id, file, appealId, contentDisposition="attachment" ) => (dispatch) => {
	dispatch(uploadFileRequest(id));
	
	const params = {
		data: {
			data: {
				interactionId: appealId,
				fileName: file.name,
				contentDisposition
			},
			file
		},
		file: true
	};
	
	baseService.post('upload_file_email', params)
		.then(data => dispatch(uploadFileSuccess(id, data.result)))
		.catch(err => dispatch(uploadFileFailure(id, err)));
};

export function deleteFile (fileId, callback) {
	
	return (dispatch) => {
		
		const params = {
			data: {
				attachmentId: fileId,
			}
		};
		
		dispatch({ type: DELETE_EMAIL_FILE_REQUEST });
		
		baseService.post('delete_file_email', params)
			.then(data => {
				dispatch({ type: DELETE_EMAIL_FILE_SUCCESS });
				callback();
			})
			.catch(err => dispatch({ type: DELETE_EMAIL_FILE_FAILURE }));
	};
}

export const createNewEmail = (id, parentInteractionId, action, email) => dispatch => {
	dispatch({
		type: CREATE_NEW_EMAIL_REQUEST,
		id,
	});
	
	const params = {
		data: {
			parentInteractionId: parentInteractionId || 1,
			action,
			objectType: 'INTERACTION_REQUEST',
			objectId: id
		},
	};
	
	baseService.post('create_new_email', params)
		.then(data => dispatch({
				type: CREATE_NEW_EMAIL_SUCCESS,
				payload: data.result,
				action,
				email,
				id,
			})
		)
		.catch(err => dispatch({
			type: CREATE_NEW_EMAIL_FAILURE,
			payload: err
		}));
};

export const updateSignatureList = (id, action) => dispatch => {
	if (action) {
	return	baseService.get('signatures', { data: {action} })
			.then(data => dispatch({
					type: UPDATE_SIGNATURE_LIST,
					userSignature: data.result,
					id,
				})
			)
			.catch(err => console.log(err.message));
	}
	return	baseService.get('signatures')
		.then(data => dispatch({
				type: UPDATE_SIGNATURE_LIST,
				userSignature: data.result,
				id,
				action,
			})
		)
		.catch(err => console.log(err.message));
};

export const setSignature = (id, signId) => dispatch => {
	return dispatch({
		type: SET_SIGNATURE,
		id,
		signId,
	})
	
};
export const changeEditSignMode = () => dispatch => {
	return dispatch({ type: CHANGE_EDIT_SIGN_MODE })
};

const getTemplateEmailRequest = (id) => ({
	type: GET_TEMPLATE_EMAIL_REQUEST,
	id,
});

const getTemplateEmailFailure = (data) => ({
	type: GET_TEMPLATE_EMAIL_FAILURE,
	payload: data
});

export function getTemplateEmail (value, id, email) {
	return dispatch => {
		dispatch(getTemplateEmailRequest(id));
		
		const params = {
			data: {
				objectId: id,
				templateId: value
			}
		};
		
		baseService.post('get_template_email', params)
			.then(data => dispatch({
				type: GET_TEMPLATE_EMAIL_SUCCESS,
				email,
				id,
				payload: data.result,
			}))
			.catch(err => dispatch(getTemplateEmailFailure(id, err)));
	};
}

const getTemplateListRequest = (id) => ({
	type: GET_TEMPLATE_LIST_REQUEST,
	id,
});

const getTemplateListSuccess = (id, data) => ({
	type: GET_TEMPLATE_LIST_SUCCESS,
	payload: data,
	id,
});

const getTemplateListFailure = (id, data) => ({
	type: GET_TEMPLATE_LIST_FAILURE,
	payload: data,
	id
});

export const getTemplateList = (id) => dispatch => {
	dispatch(getTemplateListRequest(id));
	
	const params = {
		data: {
			templateObjectType: 'INTERACTION_REQUEST_MANUAL_TEMPLATE'
		}
	};
	
	baseService.get('get_template_list', params)
		.then(data => dispatch(getTemplateListSuccess(id, data.result)))
		.catch(err => dispatch(getTemplateListFailure(id, err)));
};

export const setFilesLength = (length) => ({
	type: SET_FILES_LENGTH,
	payload: length
});

export const setFormForward = (id, emailId) => (dispatch, getState) => {
	const email = getState().emails.emails[id].emails.find(item => item.id === emailId);
	
	dispatch({
		type: SET_FORM_FORWARD,
		payload: emailId,
		email,
		id
	});
};

export const setFormReply = (id, emailId, email) => (dispatch) => {
	dispatch({
		type: SET_FORM_REPLY,
		payload: emailId,
		email,
		id,
	});
};

export const resetEmailForm = (id) => ({
	type: RESET_EMAIL_FORM,
	id,
});
