import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/knowledgeBase.module.scss';

export default function ArticleHeader ({ title, buttonName, onClickButton, fileType, canEdit }) {
	
	return (
		<div className={cx(styles.articleLinkWrapperService, styles.articleBlockService)}>
			<span className={styles.articleHeader}>
				{fileType === '1' ? <i className={cx('icon-folder', styles.navigationFolder)} />  : <i className={cx('icon-file', styles.navigationFile)} /> }
		  		<span>{title}</span>
			</span>
			{
				canEdit && (
					<button className='btn-save' onClick={onClickButton}>
						{buttonName}
					</button>
				)
			}
		</div>
	);
}

ArticleHeader.propTypes = {
	title: PropTypes.string,
	buttonName: PropTypes.string,
	onClickButton: PropTypes.func,
	fileType: PropTypes.string
};
