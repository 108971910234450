import React from 'react';
import styles from 'styles/modules/filters.module.scss';
import FilterActionsPopup from './FilterActionsPopup';
import PropTypes from 'prop-types';

class FiltersHeader extends React.Component {
	
	state = {
		isPopupOpen: false
	};
	
	togglePopup = () => this.setState(prevState => ({ isPopupOpen: !prevState.isPopupOpen }));
	
	render () {
		const { isPopupOpen } = this.state;
		const { popupConfig, title, showActions } = this.props;
		return (
			<div className={styles.header}>
				<span className={styles.headerTitle}>{title}:</span>
				
				{
					showActions &&
					<div className='actions'>
						<div className='btn-add' onClick={this.togglePopup}>
							<i className='icon-kebab-vert' />
						</div>
						
						<FilterActionsPopup
							isOpen={isPopupOpen}
							onClickOutside={this.togglePopup}
							config={popupConfig}
						/>
					</div>
				}
			</div>
		
		);
	}
}

FiltersHeader.propTypes = {
	title: PropTypes.string,
	popupConfig: PropTypes.array,
	showActions: PropTypes.bool
};

export default FiltersHeader;
