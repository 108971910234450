import React, { useEffect, useState } from 'react';
import { updateAppealViewLinks } from 'modules/appeal/actions';

import permissions, { checkPermissions } from 'config/permissions';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import { connect } from 'react-redux';
import baseService from '../../../services/BaseService';
import ReactSVG from 'react-svg';
import moment from 'moment/moment';
import { Popup } from 'ui-core-dashboard';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { openObjectsLinkModal } from 'actions/ui';
import { setLinkedObjects } from '../../../modules/appeal/actions';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/Loader';
import FullStatus from '../../Ordering/common/FullStatus';

const OBJECT_TYPE = 'INTERACTION_REQUEST';
// const REL_OBJECT_TYPE = "SERVICE";

function mapStateToProps(state) {
    return {
        updateAppealLinks: state.appeal.updateAppealLinks,
        canViewLink: checkPermissions(permissions.LinkOperations.actionViewLinks),
        canDeleteLink: checkPermissions(permissions.LinkOperations.actionDeleteLinks),
        canEditLinkAppeal: checkPermissions(permissions.LinkOperations.actionEditLinks),
    };
}

const mapDispatchToProps = {
    updateAppealViewLinks,
    openObjectsLinkModal,
    setLinkedObjects,
};

function LinkObjectsBlock(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [total, setTotal] = useState(null);
    const [links, setLinks] = useState(false);
    const [load, setLoad] = useState(false);
    const [popup, setPopup] = useState(false);
    const [confirmModal, setConfirmModal] = useState({ isModal: false, linkId: null });
    const { isModal, linkId } = confirmModal;
    const {
        t,
        id,
        updateAppealLinks,
        updateAppealViewLinks,
        openObjectsLinkModal,
        canViewLink,
        canDeleteLink,
        canEditLinkAppeal,
        setLinkedObjects,
        visible,
        objectType,
        relObjectTypes,
        withoutCollapse,
        additionalLinkPath,
    } = props;

    const showAddButton = collapsed || total === 0;

    useEffect(() => {
        if (!links && !collapsed) {
            setLoad(true);
            getRequest();
        }
        return () => setTotal(null);
    }, []);

    useEffect(() => {
        if (!collapsed && links) {
            setLoad(true);
            getRequest();
        }
        setCollapsed(false);
    }, [id]);

    useEffect(() => {
        if (collapsed || updateAppealLinks) {
            setLoad(true);
            getRequest();
            updateAppealViewLinks(false);
        }
    }, [collapsed, updateAppealLinks, id]);

    const groupBy = (items, key) =>
        items.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [...(result[item[key]] || []), item],
            }),
            {},
        );

    async function getRequest() {
        const params = {
            pathParams: { objectType: objectType || OBJECT_TYPE, id },
            data: { relObjectTypes },
        };
        if (Array.isArray(relObjectTypes)) {
            params.paramsSerializerKey = "relObjectTypes";
        }
        const resp =
            canViewLink &&
            (await baseService.get('objects_links', params));
        let groppedLinks = [];
        if (resp.success) {
            groppedLinks = groupBy(resp.result, 'linkLabel');
            setTotal(resp.total);
            setLoad(false);
            const binders = resp.result.map((item) => ({
                object_id: item.objectId,
                object_type: item.objectType,
                regnum: item.objectId.toString(),
                checked: true,
            }));
            setLinkedObjects(binders);
        }
        setLinks(Object.entries(groppedLinks));
    }

    async function remove() {
        if (canDeleteLink) {
            const resp = await baseService.delete('appeal_link_delete', { pathParams: { linkId } });

            if (resp.success) {
                onCloseModal();
                updateAppealViewLinks(true);
            }
        }
    }

    function onCloseModal() {
        setConfirmModal({ isModal: false, linkId: null });
    }

    function onClickCollapsed() {
        if (!links.length) return setCollapsed(false);
        setCollapsed(!collapsed);
    }

    const onMouseEnter = (e) => {
        const { pageX, pageY } = e;
        const prop = (attr) => {
            return e.target.getAttribute(attr);
        };

        const parsedEmptyProp = (prop) => prop || ' - ';
        setPopup(
            <Popup point={{ pageX, pageY }} size={{ height: 240 }}>
                <div className="popup-name">
                    {/* <PriorityArrowsIcons id={prop('priorityId')} /> */}
                    <div className={cx('appeal-link', 'object-id')}>{parsedEmptyProp(prop('objectId'))}</div>
                    {/* <div className={cx(`sticker`, 'sticker-popup', `sticker-${prop('statusCode')}`)}>{prop('statusName')}</div> */}
                    <FullStatus
                        data={{
                            status: {
                                name: parsedEmptyProp(prop('statusName')),
                                code: parsedEmptyProp(prop('statusCode')),
                            },
                        }}
                    />
                </div>
                {/* NEW SPECS */}
                {prop('specTypeName') && (
                    <>
                        <div className="popup-title">{t('appealObjects.specTypeName')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('specTypeName'))}</div>
                    </>
                )}
                {prop('specComment') && (
                    <>
                        <div className="popup-title">{t('appealObjects.specComment')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('specComment'))}</div>
                    </>
                )}
                {prop('specCategoryName') && (
                    <>
                        <div className="popup-title">{t('appealObjects.specCategoryName')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('specCategoryName'))}</div>
                    </>
                )}
                {prop('specOwnerName') && (
                    <>
                        <div className="popup-title">{t('appealObjects.specOwnerName')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('specOwnerName'))}</div>
                    </>
                )}
                {prop('specClassName') && (
                    <>
                        <div className="popup-title">{t('appealObjects.specClassName')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('specClassName'))}</div>
                    </>
                )}
                {/* NEW SPECS */}
                
                {prop('customer') && (
                    <>
                        <div className="popup-title">{t('appealObjects.customer')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('customer'))}</div>
                    </>
                )}
                {prop('validFrom') && (
                    <>
                        <div className="popup-title">{t('appealObjects.startDate')}</div>
                        <div className="popup-value">
                            {moment(+parsedEmptyProp(prop('validFrom'))).format('DD.MM.YYYY HH:mm')}
                        </div>
                    </>
                )}
                {prop('operatorName') && (
                    <>
                        <div className="popup-title">{t('appealObjects.operatorName')}</div>
                        <div className="popup-value cut">{parsedEmptyProp(prop('operatorName'))}</div>
                    </>
                )}
                {/* <div className='popup-title'>{t('appealObjects.subject')}</div>
				<div className='popup-value cut'>{prop('subject')}</div>
				<div className='popup-title'>{t('appealObjects.startDate')}</div>
				<div className='popup-value'>{moment(+prop('startDate')).format('DD.MM.YYYY HH:mm')}</div>
				<div className='popup-title'>{t('appealObjects.operatorName')}</div>
				<div className='popup-value cut'>{prop('operatorName')}</div>
				<div className='popup-title'>{t('appealObjects.destination')}</div>
				<div className='popup-value cut'>{prop('destination')}</div> */}
            </Popup>,
        );
    };
    const onMouseLeave = () => setPopup(null);

    const renderLinks = () => {
        if (links.length)
            return (
                <>
                    {links.map((item) => (
                        <div className="link-type-block ">
                            <div className="link-type-title">{item[0]}</div>
                            {item[1].map((link) => {
                                const objectType = link.objectType.toLowerCase();
                                const linkPath = `/${objectType}/${link.objectId}/`;
                                const fullLinkPath = additionalLinkPath ? `${linkPath}${additionalLinkPath}` : linkPath;
                                return (
                                <div className="link-item">
                                    <div
                                        className="object-id"
                                        onClick={() => props.history.push(fullLinkPath)}
                                        onMouseEnter={onMouseEnter}
                                        onMouseLeave={onMouseLeave}
                                        statusName={link.status.name}
                                        statusCode={link.status.code}
                                        customer={link.customer && link.customer.name}
                                        validFrom={link.validFrom}
                                        objectId={link.objectId}
                                        objectLabel={link.objectLabel}
                                        // new labels for specifications
                                        specTypeName={link.specType && link.specType.name}
                                        specComment={link.comment}
                                        specCategoryName={link.category && link.category.name}
                                        specOwnerName={link.owner && link.owner.name}
                                        specClassName={link.class && link.class.name}
                                    >
                                        {link.objectId}
                                    </div>
                                    <FullStatus
                                        className="type-text"
                                        data={{ status: link.status }}
                                    />
                                    <div className="type-text" title={link.objectLabel}>
                                        {link.objectLabel}
                                    </div>
                                    <div className="type-text" title={link.specType && link.specType.name}>
                                        {link.specType && link.specType.name}
                                    </div>
                                    {canEditLinkAppeal && (
                                        <div
                                            className="remove-link"
                                            title={t('appealObjects.remove')}
                                            onClick={() =>
                                                setConfirmModal({
                                                    isModal: true,
                                                    linkId: link.linkId,
                                                })
                                            }
                                        >
                                            <ReactSVG
                                                svgClassName="tab-icon bin-red"
                                                src={`/data/svg/bin.svg`}
                                            />
                                        </div>
                                    )}
                                </div>
                            );})}
                            {popup}
                        </div>
                    ))}
                    {isModal && (
                        <ModalPortal onClose={onCloseModal} className="modal-small modal-center">
                            <MessageModal
                                titleModal={t('errors.alert')}
                                contentModalText={t('appealObjects.confirmDelete')}
                                primaryButton={true}
                                primaryButtonText={t('appeal.upperYes')}
                                onClickPrimaryButton={remove}
                                secondaryButton={true}
                                secondaryButtonText={t('appeal.upperNo')}
                                onClickSecondaryButton={onCloseModal}
                            />
                        </ModalPortal>
                    )}
                </>
            );
        return null;
    };

    if (!canViewLink) return null;

    return (
        <div className={cx('formBlock link-block', { hidden: !visible, withoutCollapse })}>
            {load && withoutCollapse && <Loader />}
            {!withoutCollapse && (
                <div className="link-title-wrapper" onClick={onClickCollapsed}>
                    <i className={cx(`icon-arrow-right`, { show: collapsed })} />
                    <div className="link-title">
                        {t('appealObjects.appealTitle')}{' '}
                        {!collapsed && links && !load && `(${total})`}
                    </div>
                    {load && (
                        <div className="loader-wrapper">
                            <Loader withContainer />
                        </div>
                    )}
                    {/* <button type='button' className='add-button' onClick={openObjectsLinkModal}>
					{t('add')}
				</button> */}
                    {showAddButton && (
                        <button type="button" className="add-button" onClick={openObjectsLinkModal}>
                            {t('add')}
                        </button>
                    )}
                </div>
            )}
            <div className={cx('collapsed', { show: withoutCollapse || collapsed })}>
                <div className={cx('view', 'view1column', 'tabs')}>
                    <div className="link-form-widgets ordering-tab-content">{renderLinks()}</div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkObjectsBlock)),
);
