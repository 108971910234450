import React from 'react';
import { CSSTransition } from 'react-transition-group';
import ModalPortal from 'components/ModalPortal';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from 'styles/modules/modal.module.scss';
import InputField from 'components/Common/InputField';
import cx from 'classnames';

@withTranslation()
class FilterNameModal extends React.Component {
	
	state = {
		filterName: ''
	};
	
	componentDidUpdate (prevProps) {
		if (prevProps.isOpen && !this.props.isOpen && this.state.filterName) {
			this.setState({ filterName: '' });
		}
	}
	
	onFilterNameChange = event => this.setState({ filterName: event.target.value });
	
	onSubmit = () => {
		this.props.onClose();
		this.props.onSubmit(this.state.filterName.trim());
	};
	
	getTitle = () => {
		const { t, type } = this.props;
		
		const names = {
			saveNew: t('filters.saveNew'),
			changeName: t('filters.changeName')
		};
		
		return names[type] || '';
	};
	
	render () {
		const { isOpen, onClose, t } = this.props;
		
		const isSubmitDisabled = !this.state.filterName.trim();
		
		const submitButtonClassName = cx('btn btn-primary', {
			['btn-disabled']: isSubmitDisabled
		});
		
		return (
			<CSSTransition
				in={isOpen}
				classNames='fade'
				appear={true}
				enter={true}
				exit={true}
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<ModalPortal onClose={onClose} className='modal-small modal-center'>
					<div className={styles.modalWrapper}>
						
						<div className={styles.modalHeader}>
							<div className={styles.modalTitle}>
								{t('filters.saveNew')}
							</div>
						</div>
						
						<div className={styles.modalContent}>
							<InputField label='Назва фільтру:' onChange={this.onFilterNameChange} />
							
							<div className={cx(styles.buttonsContainer, styles.right)}>
								<button
									className={submitButtonClassName}
									onClick={this.onSubmit}
									disabled={isSubmitDisabled}
								>
									{t('save')}
								</button>
							</div>
						</div>
					
					</div>
				</ModalPortal>
			</CSSTransition>
		);
	}
}

FilterNameModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	isOpen: PropTypes.bool,
	type: PropTypes.string
};

export default FilterNameModal;
