import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import { NotificationTypes, DEFAULT_NOTIFICATION_TIMEOUT } from 'constants/index';
import { hideNotification } from 'actions/ui';
import { connect } from 'react-redux';
import SmartNotification from './SmartNotification';
import SimpleNotification from './Notification';

const mapDispatchToProps = (dispatch) => ({
    hideNotification: (id) => dispatch(hideNotification(id)),
});

@connect(null, mapDispatchToProps)
class NotificationBox extends React.Component {

	constructor (props) {
		super(props);
		
		this.rootSelector = document.getElementById('modal-root');
		this.container = document.createElement('div');
	}

	componentDidMount () {
		this.rootSelector.appendChild(this.container);
	}
	
	componentWillUnmount () {
		this.rootSelector.removeChild(this.container);
	}

    onClose = (id) => () => this.props.hideNotification(id);

    getNotificationComponentByType = (type) => {
        if (type === NotificationTypes.CURRENT_APPEAL_CHANGE) {
            return SimpleNotification;
        }
        return SmartNotification;
    };

    renderNotification = (item) => {
        const NotificationComponent = this.getNotificationComponentByType(item.type);
        return (
            <NotificationComponent
                {...item}
                key={item.id}
                onClose={this.onClose(item.id)}
                timeout={NotificationTypes.HTTP_ERROR ? this.props.timeout * 2 : this.props.timeout}
            />
        );
    };

    render() {
        return ReactDOM.createPortal(
            <div id="notification-modal-root" className={styles.notificationContainer}>
                {this.props.notifications.map(this.renderNotification)}
            </div>,
            this.container,
        );
    }
}

NotificationBox.defaultProps = {
    timeout: DEFAULT_NOTIFICATION_TIMEOUT,
};

NotificationBox.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            options: PropTypes.object,
            id: PropTypes.number,
        }),
    ),
    timeout: PropTypes.number,
};

export default NotificationBox;
