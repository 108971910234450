import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/knowledgeBase.module.scss';

function ArticleBlockBox ({ hidden, children, withoutSeparator }) {
	if (hidden) return null;
	
	return (
		<div className={cx(styles.articleBlock, { [styles.articleBlockHideSeparator]: withoutSeparator })}>
			{children}
		</div>
	);
}

ArticleBlockBox.propTypes = {
	children: PropTypes.any,
	hidden: PropTypes.bool,
	withoutSeparator: PropTypes.bool
};

export default ArticleBlockBox;

