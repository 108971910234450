import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BPMNViewer from 'bpmn-js';
import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll';
import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas';
import 'bpmn-js/dist/assets/diagram-js.css';
import cx from 'classnames';

import { getRequest } from '../../../../actions/orders';

import ButtonSet from './ButtonSet';
import ButtonIcon from './ButtonIcon';

import './styles.scss';

let scale = 1;

const mapDispatchToProps = { getRequest };
const BPMNView = ({ id, getRequest }) => {
    const { t } = useTranslation();
    
    if (!id) return null;

    let viewer;
    useEffect(() => {
        viewer = new BPMNViewer({
            container: '#BPMView',
            height: '100%',
            additionalModules: [ZoomScrollModule, MoveCanvasModule]
        });
        getRequest(importXml, { key: 'get_order_bpmn', data: { taskId: id } });
    }, []);

    const importXml = xml => {
        viewer.importXML(xml, err => {
            if (err) {
                return console.error(' --- import xml error', err)
            }
            const canvas = viewer.get('canvas');
            canvas.resized();
            canvas.zoom('fit-viewport', 'auto');

            const overlays = viewer.get('overlays');
            const eventBus = viewer.get('eventBus');
            eventBus.on('element.hover', e => {
                const { businessObject, type, id: elementId } = e.element;
                if (type === 'label' || businessObject.$parent.$type === 'bpmn:Definitions') {
                    return;
                }

                const { id, name, conditionExpression } = businessObject;
                const toolTipProps = [{ label: t('bpmnTooltip.Id'), value: id }];
                if (name) {
                    toolTipProps.push({ label: t('bpmnTooltip.Name'), value: name })
                }
                if (conditionExpression) {
                    toolTipProps.push({
                        label: t('bpmnTooltip.Condition'),
                        value: conditionExpression.body,
                        normalSpace: true
                    })
                }

                let html = '<div class="bpm-tooltip">';
                toolTipProps.forEach(({ label, value, normalSpace }) =>
                    html += `<p class="${cx({ normalSpace })}"><strong>${label}: </strong><span>${value}</span></p>`
                );
                html += '</div>';

                overlays.add(elementId, {
                    position: {
                        bottom: 0,
                        left: 0
                    },
                    html
                });
            });
            eventBus.on('element.out', e => overlays.remove({ element: e.element.businessObject.id }));

            getRequest(handleHighlightProgress, { key: 'get_order_bpmn_progress', data: { taskId: id } });
        })
    };

    const handleHighlightProgress = ({ result }) => {
        const data = JSON.parse(result);
        const { activities = [], flows = [] } = data;
        if (!activities.length && !flows.length) {
            return;
        }

        const makeActiveObject = (acc, cur) => ({ ...acc, [cur]: 1 });
        const active = {
            elements: activities.reduce(makeActiveObject, {}),
            flows: flows.reduce(makeActiveObject, {})
        };

        const elementRegistry = viewer.get('elementRegistry');
        const graphicsFactory = viewer.get('graphicsFactory');
        const setColor = (element, styles) => {
            const { businessObject } = element;
            Object.keys(styles).forEach(prop => businessObject.di.set(prop, styles[prop]));
            const gfx = elementRegistry.getGraphics(element);
            const type = element.waypoints ? 'connection' : 'shape';
            graphicsFactory.update(type, element, gfx);
        };
        const activeColor = '#0065c1'; // #be0027
        elementRegistry
            .forEach(item => {
                if (active.elements[item.id]) {
                    return setColor(item, { stroke: activeColor })
                }
                if (active.flows[item.id]) {
                    return setColor(item, { stroke: activeColor, fill: activeColor })
                }
            })
    };

    const handleZoomScale = newScale => {
        if (!viewer) return;
        viewer.get('canvas').zoom(newScale);
        scale = newScale
    };
    const handleZoomIn = () => handleZoomScale(scale + 0.1);
    const handleZoomOut = () => scale <= 0.3 ? handleZoomScale(0.2) : handleZoomScale(scale - 0.1);
    const handleZoomReset = () => handleZoomScale(1);

    return (
        <div id='BPMView' >
            <div className="zoom-control">
                <ButtonSet>
                    <ButtonIcon onClick={handleZoomIn} icon="icon-plus" title={t('bpmnActions.zoomIn')} />
                    <ButtonIcon onClick={handleZoomOut} icon="icon-minus" title={t('bpmnActions.zoomOut')} />
                    <ButtonIcon onClick={handleZoomReset} icon="icon-enlarge2" title={t('bpmnActions.zoomReset')} />
                </ButtonSet>
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(BPMNView);
