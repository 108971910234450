import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Popup from 'components/Popup';
import { withTranslation } from 'react-i18next';

@withTranslation()
class CompactSelect extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isOpened: false
		};
		
		this.element = React.createRef();
		this.select = React.createRef();
	}
	
	componentDidMount () {
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	componentWillUnmount () {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	
	openDropdown = () => this.setState(({ isOpened: true }));
	
	closeDropdown = () => {
		if (this.state.isOpened) this.setState(({ isOpened: false }));
	};
	
	toggleDropdown = () => this.setState({ isOpened: !this.state.isOpened });
	
	handleClickOutside = (event) => {
		if (this.element && !this.element.current.contains(event.target)) {
			this.closeDropdown();
		}
	};
	
	arrowRender = isOpen => isOpen ? <i className='icon-up' /> : <i className='icon-down' />;
	
	renderOption = option => (
		<div
			className='compactSelectOption'
			key={option.value}
			onClick={() => this.handleChange(option)}
		>
			{option.label}
		</div>
	);
	
	handleChange = option => {
		this.props.input.onChange(option);
		this.closeDropdown();
	};
	
	handleRootChange = (event) => event.preventDefault();
	
	render () {
		const { label, meta, input, t, required, options, disabled } = this.props;
		const { isOpened } = this.state;
		return (
			<div className={cx('input-element input-combobox compactSelectInput', { disabled: disabled })}
				 onClick={this.toggleDropdown} ref={this.element}>
				<div className='compactSelectLabel'>
					{label}{required && <span className='requiredField'>*</span>}
				</div>
				<div className='compactSelectBox'>
					<input
						className={cx('input-field compactSelect', meta.touched && meta.error && 'input-field__error')}
						ref={this.select}
						autoComplete='off'
						autoFocus={false}
						style={{ caretColor: 'transparent' }}
						value={input.value.label}
						type='text'
						onFocus={this.focusHandler}
						onBlur={this.handleBlur}
						onChange={this.handleRootChange}
						readOnly
					/>
					<div className='compactSelectArrow'>
						{this.arrowRender(isOpened)}
					</div>
				</div>
				
				{isOpened && (
					<Popup
						place='compactSelect-popup'
						left={this.state.left}
						top={this.state.top}
					>
						<div className='popup-wrapper'>
							{options.map(this.renderOption)}
						</div>
					</Popup>
				)}
			</div>
		);
	}
}

CompactSelect.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	defaultValue: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object,
	disabled: PropTypes.bool
};

export default CompactSelect;
