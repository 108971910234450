import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import moment from "moment/moment";
import DatePicker from 'components/Common/DatePicker';
import i18next from 'util/i18n';

const BillingHouseList = props => {
	const { updateApiGrid, pApartId } = props;
	const nowDate = new Date();
	
	const [loadingState, setLoadingState] = useState(true);
	const [pMonth, setPMonth] = useState(nowDate);
	const init = {
		key: 'billing_house',
		query: '?'
	};
	const gridKey = `${init.key}_${init.path || init.extra || ''}`;
	
	const onStart = () => setLoadingState(true);
	
	const onFinish = () => setLoadingState(false);
	
	const handlerChangeDate = value => {
		if (value && moment(value).isValid()){
			setPMonth(value);
		}
	};

	const renderFilterBlock = () =>{
		const restProps = { input: { value: pMonth, onChange: handlerChangeDate, onBlur: handlerChangeDate } };
		return(
				<div className={'billing-filter-wrapper ordering-component-ui-core-wrapper'}>
						<div className='between-fields-wrapper'>
							<DatePicker
								name='pMonth'
								label={i18next.t('filter.date')}
								popperClassName='billing-date-from'
								{...restProps}
								dayFormat='DD.MM.YYYY'
								onlyDate={true}
							/>
						</div>
				</div>
			)
	};
	
	useEffect(() => {
		onStart();
		pApartId && updateApiGrid({ houseId: pApartId, pMonth: moment(pMonth).format('DD.MM.YYYY'), limit: 1000 }, gridKey);
	}, [pApartId, pMonth]);
	
	useEffect(() => {
		pApartId && updateApiGrid({ houseId: pApartId, pMonth: moment(pMonth).format('DD.MM.YYYY'), limit: 1000 }, gridKey);
	}, []);
	
	return (<>
			{renderFilterBlock()}
			<div className='content-wrapper ordering-component-ui-core-wrapper with-fixed-status'>
				{pApartId && loadingState && <Loader />}
				{pApartId && <GridWrapper
					tuner={() => init}
					initUpdate={false}
					onStart={onStart}
					onFinish={onFinish}
				/>}
			</div>
		</>
	);
};

export default connect(null, { updateApiGrid: setApi })(withRouter(BillingHouseList));
