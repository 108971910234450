import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';
import { ArticleBlockBox } from '../common/index';
import { escapeBaseTag } from 'helpers';

export default function ArticleBlock ({ hidden, title, content }) {
	if (hidden) return null;
	
	return (
		<ArticleBlockBox>
			<div className={styles.articleTitle}>{title}</div>
			<div className={styles.articleDescription} dangerouslySetInnerHTML={{ __html: escapeBaseTag(content) }} />
		</ArticleBlockBox>
	);
}

ArticleBlock.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
	hidden: PropTypes.bool
};
