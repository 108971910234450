import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, SubmissionError, submit, reduxForm, getFormValues } from "redux-form";
import { createNewContactPerson } from "actions/customer";
import { createNewContactPersonConfig } from "constants/index";
import "./styles.scss";
import { capitalize, fill, get } from "lodash";
import { withRouter } from "react-router-dom";
import useIsFocusedDebounced from "custom-hooks/useIsFocusedDebounced";
import { capitalizeProps, trimProps } from "util/form";
import validate from "./validate";
import moment from "moment";
import { withTranslation } from "react-i18next";
import cx from "classnames";

import styles from "styles/modules/createNewCustomer.module.scss";

import Loader from "../../components/Loader";
import { getDynamicFieldsContactPerson, renderDynamicFields } from "../CreateNewCustomerModal/utils";
import { convertDynamicFields } from "helpers";
import _, { isEmpty } from "lodash";

import { personalDataBlocks, getMergedStaticBlocks, getModalFormDataFromState } from "../CreateNewCustomerModal/config";

import SearchPreview from "../CreateNewCustomerModal/SearchPreview";

import useDebouncedPreviewValues from "../CreateNewCustomerModal/SearchPreview/useDebouncedPreviewValues";

const STATIC_BLOCK_CODE = "customer_grid_new_contactperson_modal";

export const initialBody = {
    lastName: "",
    firstName: "",
    patronymic: "",
    lang_code: "NA",
    validFrom: moment(),
    contactsType: [],
    contactsValue: [],
    // issues: ['undefined'],
    issues: [],
    personId: "",
    linkId: "",
    description: "",
    contactsId: [],
    customerId: "",
};

export const setLanguage = ({ data, values }) => {
    if ("lang_code" in values) {
        data.lang_code = values.lang_code.value;
    }
};

export const setProps = ({ data, values, props }) => {
    props.forEach(prop => {
        if (prop in values) {
            data[prop] = values[prop];
        }
    });
};

export const setContacts = ({ data, values, props }) => {
    data.contactsType = [];
    data.contactsValue = [];
    props.forEach(prop => {
        if (prop in values) {
            data.contactsType.push(prop);
            data.contactsValue.push(values[prop]);
        }
    });
    // data.contactsType.push('undefined');
    data.contactsId = fill(Array(data.contactsType.length), "");
};

const personConfig = (t, staticData) => {
    const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, personalDataBlocks, {}, "contactPerson");
    return mergedStaticBlocks;
};

const mapStateToProps = (state, ownProps) => {
    const { t, dynConfig: dynConfigInit } = ownProps;
    const dynConfig = dynConfigInit || [];
    const modalFormData = getModalFormDataFromState(state, STATIC_BLOCK_CODE);
    const { personalData: personalDataBlock, searchPreview: searchPreviewBlock } = modalFormData;
    return {
        formValues: getFormValues("create-new-contact-person")(state),
        personConfig: personConfig(t, personalDataBlock),
        config: [...personConfig(t, personalDataBlock), ...dynConfig],
        incomePhoneNumber: state.call.incomePhoneNumber,
        formContext: state.ui.newContactPersonModalContext,
        modalFormData,
        personalDataBlock,
        searchPreviewBlock,
    };
};

const mapDispatchToProps = { createNewContactPerson, submitForm: submit };

const INIT_SEARCH_DATA = { result: [], total: 0, isLoading: false };

const DEBOUNCE_TIMEOUT = 500;

function CreateNewContactPersonForm(props) {
    const {
        personConfig,
        personalDataBlock,
        dynConfig: dynConfigInit,
        modalFormData,
        t,
        onClose,
        formValues,
        searchPreviewBlock,
        history,
    } = props;

    const dynConfig = dynConfigInit || [];
    const [languages, setLanguages] = useState([]);
    const [issues, setIssues] = useState([]);
    const [wait, setWait] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dynParamsContactPerson, setDynParamsContactPerson] = useState(null);

    const [searchData, setSearchData] = useState(INIT_SEARCH_DATA);

    const modalRef = useRef(null);

    const staticSearchSearchAttrs = useMemo(() => {
        const mergedAttrs = Object.keys(modalFormData).flatMap(key => {
            return modalFormData[key].sortedAttrs;
        });
        return mergedAttrs.filter(attr => attr.searchPreview);
    }, [modalFormData]);

    const { debouncedPreviewValues, isSearchDataChecked } = useDebouncedPreviewValues(
        formValues,
        staticSearchSearchAttrs,
        2,
        DEBOUNCE_TIMEOUT,
        searchData
    );

    const isSubmitDisabled =
        (staticSearchSearchAttrs.length > 0 && !isSearchDataChecked) || searchData.isLoading || searchData.total > 0;

    function handleCardSelect(data) {
        const { type, customerId, id } = data;
        if (type === "contact_person") {
            // contact person /customer/6701607/person/82313/appeals
            history.push(`/customer/${customerId}/person/${id}/appeals`);
        } else {
            // others /customer/6701606/appeals
            history.push(`/customer/${id}/appeals`);
        }
        onClose();
    }

    useEffect(() => {
        if (isEmpty(debouncedPreviewValues)) {
            setSearchData(INIT_SEARCH_DATA);
            // setIsSearchDataChecked(false);
        }
    }, [debouncedPreviewValues]);

    useEffect(() => {
        setLanguages(convertDictionaryObject(props.t("dictionary:languages", { returnObjects: true })));
        setIssues(convertDictionaryObject(props.t("dictionary:contactIssue", { returnObjects: true })));
    }, []);

    useEffect(() => {
        return () => {
            setDynParamsContactPerson([]);
        };
    }, [props.formContext.customerTypeName]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const responseContactPerson = await getDynamicFieldsContactPerson(
                props.formContext.customerTypeName,
                "CONTACT_PERSON",
                props.formContext.customerId,
                props.formContext.customerTypeId
            );

            setIsLoading(false);

            setDynParamsContactPerson(responseContactPerson);
            if (Array.isArray(responseContactPerson)) {
                props.setDynConfig(responseContactPerson);
            }
        }
        if (personalDataBlock && !dynParamsContactPerson && !isLoading) {
            fetchData();
        }
    }, [props.formContext.customerTypeName, personalDataBlock]);

    useEffect(() => {
        if (props.incomePhoneNumber) {
            props.change("workPhone", props.incomePhoneNumber);
        }
    }, [props.incomePhoneNumber]);

    // update hiddenValues
    useEffect(() => {
        if (modalFormData) {
            // console.log({modalFormData});
            const hiddenValuesFields = Object.keys(modalFormData)
                .flatMap(key => modalFormData[key].sortedAttrs)
                .filter(field => field.hidden && field.hiddenValues);
            hiddenValuesFields.forEach(field => {
                // console.log({ field });
                props.change(field.field, field.hiddenValues);
            });
        }
    }, []);

    const convertDictionaryObject = dictionaryObject =>
        Object.entries(dictionaryObject).map(([prop, value]) => ({
            value: prop,
            label: value,
        }));

    const onSubmit = values => {
        // console.log({values});
        if (wait) return;

        const validation = validate(values, {
            ...props,
            mode: null,
            config: [...personConfig, ...dynConfig],
        });

        // console.log({values, validation});
        // const appealId = _.get(props.match, 'params.appealId');

        if (!_.isEmpty(validation)) {
            throw new SubmissionError(validation);
        } else {
            setWait(true);
            let dynParams;
            if (dynParamsContactPerson) {
                //prepeared  & separation dyn params from form
                const dynParamsContactPersonConfig = _.flattenDeep(
                    !dynParamsContactPerson ? [] : dynParamsContactPerson.map(item => item.widgets && item.widgets)
                );
                const prepearedDynParams = _.pickBy(values, (item, _key) =>
                    dynParamsContactPersonConfig.some(({ key }) => key === _key)
                );
                dynParams = convertDynamicFields(prepearedDynParams);
            }
            const data = { ...initialBody, customerId: props.formContext.customerId, dynParams };

            setProps({
                data,
                values,
                props: ["lastName", "firstName", "patronymic", "issues", "validFrom"],
            });
            setLanguage({ data, values });
            setContacts({ data, values, props: ["workPhone", "email"] });
            trimProps(data);
            capitalizeProps(data, ["lastName", "firstName", "patronymic"]);
            const params = {
                data,
                path: "save",
                jsonType: true,
            };

            if (isSubmitDisabled) {
                return;
            }
            setIsSubmitting(true);
            props
                .createNewContactPerson(params, t)
                .then(contactPersonId => {
                    setWait(false);
                    // console.log({contactPersonId});
                    if (contactPersonId) {
                        const onSuccess = props.formContext.onSuccess;
                        onSuccess && onSuccess(contactPersonId);
                    }
                    setIsSubmitting(false);
                })
                .catch(error => {
                    setIsSubmitting(false);
                    console.error("CreateNewContactPersonForm::onSubmit: Error: ", error);
                });
        }
    };

    // const onIssuesChange = (event, newValue, previousValue, name) => {
    // 	if (Array.isArray(newValue) && newValue.length === 0) {
    // 		setTimeout(() => props.change('issues', ['undefined']), 0);
    // 	}

    // 	if (Array.isArray(newValue) && newValue.length > 1 && newValue.includes('undefined')) {
    // 		setTimeout(() => props.change('issues', newValue.filter(value => value !== 'undefined')), 0);
    // 	}
    // };

    const getFieldPropsWithOptions = fieldProps => {
        switch (fieldProps.name) {
            case "lang_code":
                return { ...fieldProps, options: languages };
            case "issues":
                // return { ...fieldProps, options: issues.slice(1), onChange: onIssuesChange };
                return { ...fieldProps, options: issues.slice(1) };

            default:
                return fieldProps;
        }
    };

    const renderInputField = fieldProps => {
        if (fieldProps.hidden) {
            return (
                <div className="hidden">
                    <Field key={fieldProps.name} {...getFieldPropsWithOptions(fieldProps)} required={false} />
                </div>
            );
        }
        return <Field key={fieldProps.name} {...getFieldPropsWithOptions(fieldProps)} />;
    };

    return (
        <div className="create-new-customer-modal" ref={modalRef}>
            <div className={styles.createCustomerHeader}>
                <div className={styles.createCustomerTitle}>{props.t("newEntityButton.persons")}</div>
            </div>
            <div className="create-new-customer-modal__content">
                <form onSubmit={props.handleSubmit(onSubmit)} className="ordering-component-ui-core-wrapper">
                    <div
                        className="create-customer-form-container"
                        key={dynParamsContactPerson && dynParamsContactPerson.length}
                    >
                        {isSubmitting && <Loader />}
                        {/* {createNewContactPersonConfig.map(renderInputField)} */}
                        <div className={cx("form-wrapper", { hidden: !personalDataBlock || personalDataBlock.hidden })}>
                            {personConfig.map(renderInputField)}
                            <div className="additional-data">
                                {dynParamsContactPerson && dynParamsContactPerson.map(renderDynamicFields)}
                            </div>
                        </div>
                        {/* <div className="additional-data">
                            {dynParamsContactPerson && dynParamsContactPerson.map(renderDynamicFields)}
                        </div> */}
                    </div>
                    <footer className="create-customer-footer">
                        <button
                            type="submit"
                            disabled={isSubmitDisabled}
                            className={cx("btn btn-primary", { disabled: isSubmitDisabled })}
                        >
                            <i className="icon icon-check" />
                            {props.t("create")}
                        </button>
                    </footer>
                </form>
            </div>
            {searchPreviewBlock && !isEmpty(debouncedPreviewValues) && (
                <SearchPreview
                    parentRef={modalRef && modalRef.current}
                    searchPreviewValues={debouncedPreviewValues}
                    filterSet={searchPreviewBlock.filterSet}
                    onCardSelect={handleCardSelect}
                    onUpdate={setSearchData}
                />
            )}
        </div>
    );
}

CreateNewContactPersonForm.propTypes = {
    handleSubmit: PropTypes.func,
};

export default withRouter(
    withTranslation()(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(
            reduxForm({
                form: "create-new-contact-person",
                // destroyOnUnmount: false,
                validate,
                initialValues: initialBody,
            })(CreateNewContactPersonForm)
        )
    )
);
