import DEFAULT_FORM_BLOCKS from '../config/staticFormBlocks.json';

const processedFallbackBlocks = DEFAULT_FORM_BLOCKS.reduce((innerAcc, innerCurr) => {
    const filteredInnerBlocks = innerCurr.blocks.filter((block) => !block.hidden);
    // remove hidden attrs
    return {
        ...innerAcc,
        [innerCurr.form]: {
            ...innerCurr,
            blocks: filteredInnerBlocks.reduce((blockAcc, blockCurr) => {
                return {
                    ...blockAcc,
                    [blockCurr.name]: {
                        ...blockCurr,
                        attrs: blockCurr.attrs.reduce((attrAcc, attrCurr) => {
                            return {
                                ...attrAcc,
                                [attrCurr.field]: attrCurr,
                            };
                        }, {}),
                        sortedAttrs: blockCurr.attrs.sort((a, b) => a.order - b.order),
                    },
                };
            }, {}),
            // attrs: innerCurr.attrs.reduce((attrAcc, attrCurr) => {
            //     return {
            //         ...attrAcc,
            //         [attrCurr.name]: attrCurr
            //     };
            // }, {}),
            // sortedAttrs: innerCurr.attrs.sort((a, b) => a.order - b.order)
        },
    };
}, {});

const ACTIONS = {
    STATIC_BLOCKS_REQUEST_SUCCESS: 'STATIC_BLOCKS_REQUEST_SUCCESS',
    STATIC_BLOCKS_SAVE_BLOCKS: 'STATIC_BLOCKS_SAVE_BLOCKS',
};

const initialState = {
    blocks: [],
    formBlocks: {},
    fallbackFormBlocks: {
        FORM: processedFallbackBlocks,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.STATIC_BLOCKS_REQUEST_SUCCESS: {
            return { ...state, blocks: action.result };
        }

        case ACTIONS.STATIC_BLOCKS_SAVE_BLOCKS: {
            const blocks = state.blocks.map((block) => {
                if (block.code === action.blockData.code) {
                    return {
                        ...block,
                        blocks: action.blockData.blocks,
                    };
                }
                return block;
            });
            const formBlocks = blocks.reduce((acc, curr) => {
                // not yet blocks available
                if (!curr.blocks) {
                    return acc;
                }
                // filter hidden blocks
                // const filteredBlocks = curr.blocks.filter((block) => !block.hidden);
                return {
                    ...acc,
                    // [curr.code]: filteredBlocks.reduce((innerAcc, innerCurr) => {
                    [curr.code]: curr.blocks.reduce((innerAcc, innerCurr) => {
                        // remove hidden attrs
                        // const filteredInnerBlocks = innerCurr.blocks.filter(
                        //     (block) => !block.hidden,
                        // );
                        return {
                            ...innerAcc,
                            [innerCurr.form]: {
                                ...innerCurr,
                                // blocks: filteredInnerBlocks.reduce((blockAcc, blockCurr) => {
                                blocks: innerCurr.blocks.reduce((blockAcc, blockCurr) => {
                                    return {
                                        ...blockAcc,
                                        [blockCurr.name]: {
                                            ...blockCurr,
                                            attrs: blockCurr.attrs.reduce((attrAcc, attrCurr) => {
                                                return {
                                                    ...attrAcc,
                                                    [attrCurr.field]: attrCurr,
                                                };
                                            }, {}),
                                            sortedAttrs: blockCurr.attrs.sort(
                                                (a, b) => a.order - b.order,
                                            ),
                                        },
                                    };
                                }, {}),
                                // attrs: innerCurr.attrs.reduce((attrAcc, attrCurr) => {
                                //     return {
                                //         ...attrAcc,
                                //         [attrCurr.name]: attrCurr
                                //     };
                                // }, {}),
                                // sortedAttrs: innerCurr.attrs.sort((a, b) => a.order - b.order)
                            },
                        };
                    }, {}),
                };
            }, {});
            // console.log({ formBlocks });
            return {
                ...state,
                blocks,
                formBlocks,
            };
        }

        default: {
            return state;
        }
    }
};

export const setStaticBlocks = (result) => ({
    type: ACTIONS.STATIC_BLOCKS_REQUEST_SUCCESS,
    result,
});

export const saveBlocksToStaticBlock = (blockData) => ({
    type: ACTIONS.STATIC_BLOCKS_SAVE_BLOCKS,
    blockData,
});
