import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/Common/common.scss';
import File from './File';
import { withTranslation } from 'react-i18next';
import baseService from '../../../services/BaseService';
import ModalPortal from '../../ModalPortal';
import MessageModal from '../../MessageModal';

import { GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';

@withTranslation()
class FileInput extends Component {
	constructor (props) {
		super(props);
		this.input = React.createRef();
		this.state = {
			isOpen: false,
			maxFileSize: GET_MAX_FILE_SIZE_FROM_DICT('spec')
		};
	}
	
	handleChange = async event => {
		const { input, id } = this.props;
		const fileList = event.currentTarget.files;
		
		if (fileList.length > 0) {
			const convertedFiles = this.convertFileList(fileList);
			const data = await Promise.all(convertedFiles.map(async (file) => {
				const fileSize = file.size / 1024 / 1024;
				if (fileSize > this.state.maxFileSize) {
					this.setState({ isOpen: true });
				} else {
					const params = {
						data: {
							data: {
								objectType: 'SERVICE',
								objectId: id,
								fileName: file.name
							},
							file
						},
						file: true
					};
					
					const request = await baseService.post('save_file', params);
					file.id = request.result;
					
					return file;
				}
			}));
			
			const files = data.filter(i => i);
			
			if (files.length) {
				input.onChange([...input.value, ...files]);
			}
		}
		this.input.current.value = '';
	};
	
	convertFileList = fileList => {
		if (!fileList || fileList.length === 0) return [];
		
		const convertedFiles = [];
		
		for (const file of fileList) {
			convertedFiles.push(file);
		}
		
		return convertedFiles;
	};
	
	deleteFile = async fileIndex => {
		const { input } = this.props;
		const inputValue = input.value[fileIndex];
		const params = {
			data: {
				fileId: typeof inputValue.id === 'object' ? inputValue.id.id : inputValue.id,
			},
		};
		
		await baseService.post('file_delete', params);
		
		const updatedFiles = [
			...input.value.slice(0, fileIndex),
			...input.value.slice(fileIndex + 1)
		];
		
		this.input.current.value = '';
		
		input.onChange(updatedFiles);
	};
	
	renderFile = (file, index) => {
		return <File
			key={`${file.size}-${index}`}
			file={file.file || file}
			type={file.mimeType}
			index={index}
			onDelete={this.deleteFile}
		/>;
	};
	
	closeModal = () => {
		this.setState({ isOpen: false });
	};
	
	render () {
		const { label, input, t } = this.props;
		const { isOpen } = this.state;

		return (
			<div className='input-file'>
				<div className='input-label'>{label}</div>
				
				{input.value && input.value.map(this.renderFile)}
				
				<label className='fileUploader'>
					<input
						type='file'
						multiple
						accept='*'
						onChange={this.handleChange}
						ref={this.input}
					/>
					<div className='btn-save'>{t('add')}</div>
				</label>
				{
					isOpen && <ModalPortal onClose={this.closeModal} className='modal-medium modal-center'>
						<MessageModal
							danger
							titleModal={t('error')}
							contentModalText={t('errorFile', { size: this.state.maxFileSize })}
						/>
					</ModalPortal>
				}
			</div>
		);
	}
}

FileInput.propTypes = {
	label: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object
};

export default FileInput;
