import React, {useState, useEffect, useRef} from 'react';
import {reduxForm, Field, getFormValues, initialize, formValueSelector, change, reset, isDirty} from 'redux-form';
import { withRouter } from 'react-router-dom';
import FormBlock from '../../Appeal/Form/FormBlock';
import cx from 'classnames';
import 'pages/Appeal/styles.scss';
import 'pages/Ordering/common/DynamicForm/styles.scss';

import {Input} from 'ui-core-dashboard';
import validate from '../CalendarDetail/validate';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import { setHistoryPushRequireSaveWithoutChangeConfirm } from "actions/ui";
import ComboBoxUniversal from '../../../components/Common/ComboBoxUniversal';
import TextArea from '../../../components/Common/TextArea';
import {buildThreePath} from "../../../components/SearchFilters/helpers";
import {
	getCalendarEvent, createCalendarEvent, getCalendarRrules, getCalendarValarms,
	getEventTypes, getEventStatuses, getDynamicParams
} from '../../../modules/calendar/actions';
import moment from "moment/moment";
import {CompactDatePicker} from "../../Appeal/Form/components";
import CheckBox from "../../../components/Common/CheckBox";
import ReactSVG from 'react-svg';
import FileInput from "./FileInput";
import {useParams} from "react-router-dom";
import BaseService from "../../../services/BaseService";
import _ from 'lodash';
import Loader from "../../../components/Loader";
import StatusSelect from "./StatusSelect";
import get from "lodash/get";
import {DinamicParamsForm} from "../../../components/KnowledgeBase/Main/utils";
import {initializeDynamicParams} from "pages/Appeal/helpers";
import ClickOutsideHolder from "../../../components/ClickOutsideHolder";


function EventForm(props) {
	const {
		valid, pristine, getDynamicParams, dynParams, submitForm, getCalendarEvent, eventData, createCalendarEvent,
		types, getEventTypes, typesLoading, getCalendarRrules, rrules, rrulesLoading, formValues, destroy,
		eventLoading, getCalendarValarms, valarms, valarmsLoading, getEventStatuses, start, end, setBlockOffset,
		openedEid, isDirty, setHistoryPushRequireSaveWithoutChangeConfirm,
		match: { params: { eid: urlEid }}
	} = props;
	const {t} = useTranslation();
	let {eid, mode} = useParams();
	const eventTypeValue = get(eventData, 'eventType.value');
	const eventTypeId = get(formValues, 'eventType.id');
	const allDay = get(formValues, 'allDay');
	const eventId = get(eventData, 'eventId');
	const allowModes = get(eventData, 'allowModes', []);
	const dynDataLength = dynParams ? dynParams.blocks.length : 0;
	const refs = useRef(dynParams && dynParams.blocks.map(React.createRef));
	const titleInputRef = useRef(null);
	
	const [editableTitle, setEditableTitle] = useState(false);
	
	const [loadDynParams, setLoadDynParams] = useState(false);
	const [collapseState, setCollapseState] = useState({});
	const [heights, setHeights] = useState(null);
	const refStartDate = useRef(moment(start).valueOf());
	const refEndDate = useRef(moment(end));
	
	
	useEffect(() => {
		!eid && createCalendarEvent();
		!rrules && getCalendarRrules();
		!valarms && getCalendarValarms();
		!types && getEventTypes();
		
		return () => {
			refStartDate.current = null;
			props.reset();
			destroy(props.form);
		}
	}, []);
	
	useEffect(() => {
		if(eid) {
			props.reset();
			getCalendarEvent(eid, mode, null, 'update', () => setLoadDynParams(false));
			getEventStatuses(eid);
		}
	}, [eid]);

	useEffect(() => {
		// case when event is in create state and there is no eid in path url params
		if (openedEid && !eid) {
			// console.log({openedEid});
			getEventStatuses(openedEid);
		}
	}, [openedEid]);

	useEffect(() => {
		if (!pristine && allDay){
			props.change('start', moment(formValues.start).startOf('day').add(9,'hour'));
			props.change('end', moment(formValues.start).startOf('day').add(18,'hour'));
		}
	}, [allDay]);
	
	useEffect(() => {
		if (eventData) {
				props.setAllowModes(allowModes);
				props.initialize({...formatData(eventData, true)});
				}
			}, [eventId]);
	
	useEffect(() => {
		if (eventTypeId && formValues) {
			const openMode = !eid ? 'create' : null;
			setLoadDynParams(true);
			getDynamicParams(eventTypeId, eventId, openMode,() => setLoadDynParams(false));
			const unmergedBlock = dynParams ? initializeDynamicParams(dynParams) : {};
			const block = formValues.block ?  {...unmergedBlock, ...formValues.block} : unmergedBlock;
			props.change('block', block);
			props.setDynParams(dynParams);
		}
	},[eventTypeId]);
	
	
	useEffect(() => {
		if (dynParams) {
			const unmergedBlock = dynParams ? initializeDynamicParams(dynParams) : {};
			const block = formValues && formValues.block ?  {...unmergedBlock, ...formValues.block} : unmergedBlock;
			props.change('block', block);
			props.setDynParams(dynParams);
		}
	}, [dynParams]);
	
	useEffect(() => {
		// listen to refs ready state (if ref.current === null then return)
		if (!heights && refs.current && refs.current.some(ref => ref.current)) {
			const { current } = refs;
			setHeights(current.reduce((prev, curr) => {
				return {
					...prev,
					[curr.current.parentElement.getAttribute('data-key')]: curr.current.offsetHeight + 12
				};
			}, {}));
		}
	});
	
	// useEffect(() => {
	// 	if (blockOffsetKey) {
	// 		const filteredBlock = refs.current.filter(ref => {
	// 			return ref.current.parentElement.getAttribute('data-key') === blockOffsetKey;
	// 		});
	// 		const { current: { parentElement } } = filteredBlock[0];
	// 		setBlockOffset(parentElement.offsetTop + parentElement.clientHeight);
	// 	}
	// }, [blockOffsetKey]);
	
	useEffect( ()=>{
		if (!pristine && !allDay) {
			const range = moment(end).valueOf() - refStartDate.current;
			const newEnd = moment(start).valueOf() + range;
			
			props.change('end', newEnd);
			refStartDate.current = moment(start).valueOf();
		}
		
		return () => {
			if (start) refStartDate.current = moment(start).valueOf()
		}
	},[start]);
	
	const toggleCollapse = key => {
		const widgetsCollapsed = { ...collapseState }; // spread to clone collapseObj
		widgetsCollapsed[key] = !widgetsCollapsed[key]; // toggle value by key
		setCollapseState(widgetsCollapsed);
	};
	const getViewClass = view => {
		switch (view) {
			case '2column': {
				return 'two-column';
			}
			case '3column': {
				return 'three-column';
			}
			default: {
				return 'form';
			}
		}
	};
	
	const selectAllHandlerInput = (callback, options) => {
		callback(options);
	};
	
	const clearAllHandlerInput = (inputName) => {
		props.change(inputName, null);
	};
	
	const renderDynParams = (data, index) => {
		if (!collapseState) return;
		const { key, title, widgets, view, description, filter } = data;
		const blockClass = collapseState[key] ? 'block-wrapper' : 'block-wrapper open';
		const headerClass = title ? 'field-header' : 'field-header no-title';
		const fieldsClass = title ? `fields-wrapper ${getViewClass(view)}` : 'fields-wrapper open no-title';
		return (
			<div key={key} data-key={key} className={blockClass} style={{ zIndex: dynDataLength - index }}>
				<div key={`${key}_header`} className={headerClass} onClick={() => title && toggleCollapse(key)}>
					<div className='title'><i className='icon icon-down' />
						{filter === 'IN' && <ReactSVG src='/data/svg/params-in.svg' />}
						{filter === 'OUT' && <ReactSVG src='/data/svg/params-out.svg' />}
						{title !== '*' && title}
						<div className='description-block'>
							<span className='description-text'>{description}</span>
							<div className='field-border' />
						</div>
					</div>
				</div>
				<div className={fieldsClass} ref={refs.current && refs.current[index]} key={`${key}_content`}>
					<DinamicParamsForm
						blockKey={key}
						widgets={widgets}
						skipRestriction={true}
						formValues={dynParams}
					/>
				</div>
				
			</div>
		)
	};
	
	const getAttendees = _.debounce((query, callback) => {
		if (query && query.trim().length > 2) {
			BaseService.get('get_calendar_attendees', {data: {query}})
				.then(response => {
					if (Array.isArray(response.result) && response.result.length) {
						const options = response.result.map( (it, index) => { return {...it, index} });
						callback(null, {
							options: [
								{
									notVal: true,
									label: t('clear all results'),
									value: 'clear',
									action: () => clearAllHandlerInput('attendees')
								},
								...options]
						});
					}
					})
				.catch(console.error);
		} else {
			callback(null, {options: []});
		}
	}, 500);
	
	const getLocations = _.debounce((query, callback) => {
		if (query && query.trim().length > 2 && query.length < 101) {
			BaseService.get('get_calendar_locations', {data: {query}})
				.then(response => {
					if (Array.isArray(response.result) && response.result.length) {
						const options = response.result.map( (it, index) => { return {...it, index} });
						callback(null, {
							options: [
								{
									notVal: true,
									label: t('clear all results'),
									value: 'clear',
									action: () => clearAllHandlerInput('location')
								},
								...options]
						});
					}
				})
				.catch(console.error);
		} else {
			callback(null, {options: []});
		}
	}, 500);
	
	const creationDate = eventData && moment(eventData.create).format('DD.MM.YYYY HH:mm');
	const operatorLabel = _.get(eventData, 'owner.label', '');
	const checkRestrictions = (field) => {
		if (eventData) {
			const {restriction} = eventData;
			return (restriction && restriction[field] && restriction[field] === 1);
		}
	};
	
	const formatData = (item, needFormatFiles) => {
		if (needFormatFiles && item.files.length) {
			const files = item.files.map(file => ({
				type: 'remote',
				file: { ...file, name: file.fileName, size: file.fileSize }
			}));
			
			const initialValues = { ...item, files };
			
			return initialValues;
		}
		
		return item;
	};
	

	useEffect(() => {
		if (isDirty) {
            setHistoryPushRequireSaveWithoutChangeConfirm(true);
		}
	}, [isDirty]);

	return (
		<form onSubmit={(values) => submitForm(values)}
			  className={cx('event-form', 'ordering-component-ui-core-wrapper', {'no-data' : !eventData})}>
		
			{!eventLoading && !eventData && <div className='event-no-result' style={{margin:'40%',}}>{t('errors.noData')}</div>}
			{eventLoading ? <Loader withContainer/> :
				<FormBlock>
					
						<ClickOutsideHolder className={cx('event-header', {editableTitle})} onClickOutside={() => setEditableTitle(false)}>
							<div className='event-title-wrapper' onClick={() => !checkRestrictions('title') && setEditableTitle(true)} >
								<label className="event-title-label" title={formValues && formValues.title}>{formValues && formValues.title ? formValues.title : t('calendar.addName')}</label>
								<span className='required-field'>*</span>
								<i className='icon icon-edit' onClick={() => setEditableTitle(!editableTitle)}></i>
								<Field
									required={true}
									component={Input}
									ref={titleInputRef}
									name='title'
									placeholder={t('calendar.addName')}
									disabled={checkRestrictions('title')}
									maxLength='255'
								/>
							</div>
							
						</ClickOutsideHolder>
					
					<div className='header-block'>
						<div className='header-event-info-block'>
							<span className='created-label'>{t('tasks.createDate')}&nbsp;</span>
							<div className='header-event-info-data-row'>
								<span className='creation-date'>{creationDate} <i className='operatior-name-divider'>/</i> <span
									className='operatorName'>{operatorLabel}</span></span>
							</div>
						</div>
						<div className='header-select-block' >
							
							<Field
								name='eventType'
								required
								component={ComboBoxUniversal}
								label={t('type')}
								title={t('type')}
								placeholder={t('appeal.selectPlaceholder')}
								nodeArray={get(types, 'children', [])}
								isType
								isEvent
								initialValue={formValues && formValues.eventType}
								disabled={checkRestrictions('eventType')}
								className='combo-universal'
							/>
							
							<div className='status-field-wrapper'>
								<div className='input-label'>{t('status_name')}
									<span className='required-field'>*</span>
								</div>
								<Field
									component={StatusSelect}
									disabled={checkRestrictions('status')}
									formName='event-form'
									change={props.change}
								/>
							</div>
						</div>
					</div>
					<div className='params-block'>
						<div className='date-period-block'>
							<ReactSVG svgClassName='icon' src={`/data/svg/icon-time.svg`}/>
							<Field
								component={CompactDatePicker}
								name='start'
								disabled={allDay || checkRestrictions('start')}
								popperClassName={'event-form-datepicker'}
								popperPlacement={'bottom'}
								disabledKeyDown
								showTimeSelectProp
								showErrors
							/>
							<span className='date-divider'> - </span>
							<Field
								component={CompactDatePicker}
								name='end'
								disabled={allDay || checkRestrictions('end')}
								popperClassName={'event-form-datepicker'}
								popperPlacement={'bottom'}
								disabledKeyDown
								showTimeSelectProp
								showErrors
							/>
							<span className='icon-divider'>|</span>
							<Field
								component={CheckBox}
								label={t('calendar.allDay')}
								name='allDay'
								disabled={checkRestrictions('allDay')}
							/>
						</div>
						<div className='field-wrap'>
							<ReactSVG className='field-form-icon-wrap' svgClassName='icon'
									  src={`/data/svg/icon-repeat.svg`}/>
							<Field
								component={ComboBoxUniversal}
								name='rrule'
								placeholder={t('filters.noSelected')}
								noResultsText={t('noResultsFound')}
								options={rrules}
								isLoading={rrulesLoading}
								searchable={false}
								hasExValue={true}
								// disable 'rrule' field in case Event is editing 
								// urlEid in params path mean that event has received its dedicated id
								disabled={urlEid || checkRestrictions('rrule')} 
								clearable={false}
							/>
						</div>
						<div className='field-wrap'>
							<ReactSVG className='field-form-icon-wrap' svgClassName='icon'
									  src={`/data/svg/icon-bell.svg`}/>
							<Field
								component={ComboBoxUniversal}
								name='valarm'
								placeholder={t('filters.noSelected')}
								noResultsText={t('noResultsFound')}
								searchable={false}
								hasExValue={true}
								isLoading={valarmsLoading}
								options={valarms}
								disabled={checkRestrictions('valarm')}
								clearable={false}
							/>
						</div>
						<div className='field-wrap'>
							<ReactSVG className='field-form-icon-wrap mr-2' svgClassName='icon'
									  src={`/data/svg/icon-location.svg`}/>
							<Field
								component={ComboBoxUniversal}
								name='location'
								placeholder={t('filters.noSelected')}
								disabled={checkRestrictions('location')}
								loadOptions={getLocations}
								filterOptions={ options => options.filter(o => !/Create option/i.test(o.value))}
								noResultsText={t('noResultsFound')}
								clearable={false}
								searchPromptText={t('issues.startType')}
								loadingPlaceholder={t('issues.search')}
								promptTextCreator={(name) => `${t('filters.createNewOption')} "${name}"`}
								hasExValue={true}
								isCreatable={true}
								allowCreateWhileLoading={false}
								isAsync={true}
								searchable={true}
								inputProps={{maxLength: 2000}}
								className={'combo-universal full-width'}
								multi
							/>
						</div>
						<div className='field-wrap grey-input-wrap'>
							<ReactSVG className='field-form-icon-wrap mr-min-3' svgClassName='icon'
									  src={`/data/svg/icon-staff.svg`}/>
							<Field
								component={ComboBoxUniversal}
								name='attendees'
								placeholder={t('calendar.addAttendees')}
								disabled={checkRestrictions('attendees')}
								loadOptions={getAttendees}
								noResultsText={t('noResultsFound')}
								searchPromptText={t('issues.startType')}
								loadingPlaceholder={t('issues.search')}
								hasExValue={true}
								clearable={true}
								clearAllText={t('clearValue')}
								isAsync={true}
								searchable={true}
								inputProps={{maxLength: 50}}
								className={'combo-universal full-width'}
								multi
							/>
						</div>
						<div className='field-wrap grey-input-wrap'>
							<ReactSVG className='field-form-icon-wrap' svgClassName='icon'
									  src={`/data/svg/icon-description.svg`}/>
							<Field
								component={TextArea}
								name='description'
								placeholder={t('calendar.addDescription')}
								disabled={checkRestrictions('description')}
								maxLength='2000'
							/>
						</div>
						<div className='field-wrap attachment-wrapper'>
							<ReactSVG className='field-form-icon-wrap' svgClassName='icon'
									  src={`/data/svg/icon-attach.svg`}/>
							<Field
								name='files'
								component={FileInput}
								disabled={checkRestrictions('files')}
								eventId={eventId}
							/>
						</div>
					</div>
					<div className='dyn-params-wrapper'>
					{loadDynParams && <Loader withContainer/>}
					{dynParams && dynParams.blocks.map(renderDynParams)}
					
					</div>
				
				</FormBlock>
			}
		
		</form>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		formValues: getFormValues('event-form')(state),
		...formValueSelector('event-form')(state, 'start', 'end'),
		isDirty: isDirty('event-form')(state),
		eventData: state.calendar.openedEvent,
		rrules: state.calendar.rrules,
		rrulesLoading: state.calendar.rrulesLoading,
		valarms: state.calendar.valarms,
		valarmsLoading: state.calendar.valarmsLoading,
		types: state.calendar.types,
		dynParams: state.calendar.dynParams,
		typesLoading: state.calendar.typesLoading,
		openedEid: state.calendar.openedEvent && state.calendar.openedEvent.eid,
		config: ownProps.eventFormConfig,
	};
};

const mapDispatchToProps = dispatch => ({
	getCalendarEvent: (eid, mode, cid, openMode, callback) => dispatch(getCalendarEvent(eid, mode, cid, openMode, callback)),
	getEventStatuses: (eid) => dispatch(getEventStatuses(eid)),
	createCalendarEvent: (cid, start, end) => dispatch(createCalendarEvent(cid, start, end)),
	getDynamicParams: (eventTypeValue, eventId, mode, callback) => dispatch(getDynamicParams(eventTypeValue, eventId, mode, callback)),
	getCalendarRrules: () => dispatch(getCalendarRrules()),
	getCalendarValarms: () => dispatch(getCalendarValarms()),
	getEventTypes: () => dispatch(getEventTypes()),
	setBlockOffset: () => dispatch(null),
	reset: dispatch(reset('event-form')),
	setHistoryPushRequireSaveWithoutChangeConfirm: isRequired => dispatch(setHistoryPushRequireSaveWithoutChangeConfirm(isRequired))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'event-form',
	validate,
	destroyOnUnmount: true,
	// enableReinitialize: false,
	// keepDirtyOnReinitialize: false
})(EventForm)));
