import React from 'react';
import { getFirstLetters } from 'helpers';
import MessageItem from "../../pages/Appeal/History/MessageItem";
import DefaultItem from "../../pages/Appeal/History/DefaultItem";
import EmailItem from "../../pages/Appeal/History/EmailItem";
import CallItem from "../../pages/Appeal/History/CallItem";
import CommentItem from "../../pages/Appeal/History/CommentItem";
import NotificationItem from "../../pages/Appeal/History/NotificationItem";
import Collection from "../../pages/Appeal/History/Collection";
import ChangeItem from "../../pages/Appeal/History/ChangeItem";
import SelfCareItem from "../../pages/Appeal/History/SelfCareItem";
import Highlight from 'react-highlighter';
import {HistoryItemTypes} from "../../constants";
import i18next from 'util/i18n';

export const convertHistoryFilters = data => {
	return data.map( item => (
		{
			label: item.label,
			value: item.code,
			active: item.default
		} ));
};

export const getMarkdownProps = value => {
	return {
		source: value,
		unwrapDisallowed: true, // still place content of disallowedTypes
		disallowedTypes: ['paragraph'] // remove all paragraphs <p></p>render
	};
};

export const getHistoryItemComponent = type => {
	const componentMap = {
		[HistoryItemTypes.CHANGE]: ChangeItem,
		[HistoryItemTypes.EMAIL]: EmailItem,
		[HistoryItemTypes.MESSAGE]: MessageItem,
		[HistoryItemTypes.NOTIFICATION]: NotificationItem,
		[HistoryItemTypes.COLLECTION]: Collection,
		[HistoryItemTypes.COMMENT]: CommentItem,
		[HistoryItemTypes.CALL]: CallItem,
		[HistoryItemTypes.PRECRM]: CallItem,
		[HistoryItemTypes.SELF_CARE]: SelfCareItem
	};
	
	return componentMap[type] || null;
};

export const highlight = (text, query) => (
	<Highlight search={query} matchClass='highlight' matchElement={'span'}>
		{text}
	</Highlight>
);

export const getAuthorName = (items, checkCollection) => {
	let historyItem = items.find(item => item.type === HistoryItemTypes.CHANGE || item.type === HistoryItemTypes.COMMENT);
	if (checkCollection){
		historyItem = items.find(item => item.type === HistoryItemTypes.CHANGE || item.type === HistoryItemTypes.COMMENT || item.type === HistoryItemTypes.COLLECTION)
	}
	return historyItem && historyItem.item.author.name || i18next.t('systemMessage');
};
