import {
	APPEAL_HISTORY_REQUEST,
	APPEAL_HISTORY_REQUEST_SUCCESS,
	APPEAL_HISTORY_REQUEST_FAILURE,
	APPEAL_HISTORY_FILTER_UPDATE,
	APPEAL_HISTORY_SEARCH_QUERY_UPDATE,
	APPEAL_HISTORY_RESET,
} from '../constants/actions';

const initialState = {
	requestedForAppeal: '',
	
	filter: 0,
	query: '',
	highlightedText: '',
	
	historyGroups: {},
	isLoading: false,
};

const appealHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		/********  Appeal history request  ********/
	
	case APPEAL_HISTORY_REQUEST:
		return { ...state, isLoading: true, requestedForAppeal: action.appealId };
	
	case APPEAL_HISTORY_REQUEST_SUCCESS:
		return {
			...state,
			historyGroups: action.historyGroups,
			isLoading: false,
			highlightedText: state.query,
		};
	
	case APPEAL_HISTORY_REQUEST_FAILURE:
		return { ...state, historyGroups: {}, isLoading: false };
		
		/********  Appeal history filter update  ********/
	
	case APPEAL_HISTORY_FILTER_UPDATE:
		return { ...state, filter: action.filter };
		
		/********  Appeal history search query update  ********/
	
	case APPEAL_HISTORY_SEARCH_QUERY_UPDATE:
		return { ...state, query: action.query };
		
		/********  Appeal history reset  ********/
	
	case APPEAL_HISTORY_RESET:
		return initialState;
	
	default:
		return state;
	}
};

export default appealHistoryReducer;

export const getHistoryGroups = (state) => state.session.historyGroups;
export const getSearchQuery = (state) => state.session.query;
export const getFilter = (state) => state.session.filter;
export const getHighlightedText = (state) => state.session.highlightedText;
export const isHistoryLoading = (state) => state.session.isLoading;
export const getRequestedForAppeal = (state) => state.session.requestedForAppeal;
