const ACTIONS = {
	ORDER_CURRENT_REQUEST_SUCCESS: 'ORDER_CURRENT_REQUEST_SUCCESS',
	ORDER_REQUEST_FAILURE: 'ORDER_REQUEST_FAILURE',
};

const initialState = {
	currentData: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
	
	case ACTIONS.ORDER_CURRENT_REQUEST_SUCCESS: {
		return { ...state, currentData: action.result };
	}
	
	case ACTIONS.ORDER_REQUEST_FAILURE: {
		return { ...state };
	}
	
	default: {
		return state;
	}
	}
};

export const setCurrentData = result => ({
	type: ACTIONS.ORDER_CURRENT_REQUEST_SUCCESS,
	result
});

export const hasError = (error) => ({
	type: ACTIONS.ORDER_REQUEST_FAILURE,
	error,
});


