import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import AppealTypesTreeModal from './AppealTypesTreeModal';
import get from 'lodash/get';
import { getNodePath } from './helpers';

@withTranslation()
class AppealTypeSelect extends React.PureComponent {
	
	constructor (props) {
		super(props);
		
		this.state = {
			isModalOpened: false
		};
		
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.arrowRender = this.arrowRender.bind(this);
	}
	
	openModal () {
		if (this.props.disabled) return;
		
		const callback = () => this.props.onFocus && this.props.onFocus();
		this.setState({ isModalOpened: true }, callback);
	}
	
	closeModal () {
		const callback = () => this.props.onBlur && this.props.onBlur();
		this.setState({ isModalOpened: false }, callback);
	}
	
	onChange (selectedNode) {
		const { valueField, labelField, initialValue } = this.props;
		
		if (!initialValue || initialValue[valueField] !== selectedNode[valueField]) {
			const updatedValue = {
				...selectedNode,
				[valueField]: selectedNode[valueField],
				fullLabelPath: getNodePath(selectedNode, labelField).join(' / '),
				code: selectedNode.code
			};
			if (this.props.isEvent){
				this.props.input.onChange(updatedValue);
			}
			else this.props.onChange(updatedValue);
		}
		
		this.closeModal();
	}
	
	arrowRender = () => this.state.isModalOpened ? <i className='icon-up' /> : <i className='icon-down' />;
	
	
	render () {
		const {
			required,
			label,
			t,
			nodeArray,
			valueField,
			labelField,
			leafField,
			childrenField,
			initialValue,
			placeholder,
			disabled,
			error,
			className,
			loading,
			...rest
		} = this.props;
		
		const { isModalOpened } = this.state;
		
		const valueClassName = cx('input-field', {
			[styles.placeholder]: !initialValue,
			[styles.disabled]: disabled,
			['input-field__error']: Boolean(error)
		});

		const text = get(initialValue, 'fullLabelPath', get(initialValue, 'label', get(initialValue, 'label', error)));
		const placeholderText = placeholder || t('appeal.selectPlaceholder');
		
		return (
			<div className={cx('input-element', styles.appealTypeSelect, className)}>
				
				<div className='input-label'>
					{label}{required && <span className='required-field'>*</span>}
				</div>
				
				<div id={rest.id} className={valueClassName} onClick={this.openModal}>
					{text || placeholderText}
					{this.arrowRender()}
				</div>
				
				{
					isModalOpened &&
					<AppealTypesTreeModal
						nodeArray={nodeArray}
						valueField={valueField}
						labelField={labelField}
						leafField={leafField}
						childrenField={childrenField}
						initialValue={initialValue}
						loading={loading}
						onClose={this.closeModal}
						onSubmit={this.onChange}
						title={this.props.title}
					/>
				}
			</div>
		);
	}
}

AppealTypeSelect.defaultProps = {
	nodeArray: [],
	valueField: 'id',
	labelField: 'name',
	leafField: 'leaf',
	childrenField: 'children'
};

AppealTypeSelect.propTypes = {
	nodeArray: PropTypes.array,
	valueField: PropTypes.string,
	labelField: PropTypes.string,
	leafField: PropTypes.string,
	childrenField: PropTypes.string,
	label: PropTypes.string,
	initialValue: PropTypes.shape({
		id: PropTypes.number,
		fullLabelPath: PropTypes.string
	}),
	required: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	error: PropTypes.string,
	disabled: PropTypes.bool
};

export default AppealTypeSelect;
