import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader/index';
import GridWrapper from '../../../../../custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
// import Diagram from '../../../../Orders/Diagram';

const CODE = 'SERVICE_CATALOG_SPEC_INSTANCE';
const CONFIG_CODE = 'SERVICE_CATALOG_SERVICE';

// const TYPE = 'appeal';

const ServicesList = (props) => {
    const { id, filter, updateApiGrid, history } = props;

    const [loadingState, setLoadingState] = useState(true);

    const init = {
        key: 'service_catalog_service',
        code: CODE,
        type: 'spec',
        method: 'post',
        filterId: filter,
        filterObject: [],
        pathParams: {
            id,
        },
        extra: id,
        // isDynamic: true,
        configUrlKey: "config_grid",
        configKey: 'service_catalog_service',
        configCode: CONFIG_CODE,
    };

    const gridKey = `${init.key}_${init.path || init.extra || ''}`;

    const getParams = () => ({
        selectItem: (item) => {
            history.push(`/${item.detailObjectType.toLowerCase()}/${item.id}/`);
        },
    });

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
        id && updateApiGrid({ filterId: filter, page: 1 }, gridKey);
    }, [id, filter]);

    return (
        <div className="content-wrapper with-fixed-status">
            {loadingState && <Loader />}
            <GridWrapper
                tuner={() => init}
                initUpdate={false}
                gridParams={getParams()}
                onStart={onStart}
                onFinish={onFinish}
                destroyOnUnmount
            />
        </div>
    );
};

export default connect(null, { updateApiGrid: setApi })(withRouter(ServicesList));
