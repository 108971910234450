import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import './styles.scss';

const Tabs = (props) => {
    const {
        t,
        config,
        history,
        watchParam,
        activeTab,
        setActiveTab,
        className,
        isCollapsed,
        onToggleTabs,
        withoutHistory,
    } = props;
    const currentTab = activeTab || props.match.params[watchParam];
    const { url } = props.match;

    const handleTabClick = (tab) => {
        if (currentTab !== tab) {
            if (setActiveTab) {
                return setActiveTab(tab);
            }
            if (!withoutHistory) {
                history.push(url.replace(currentTab, tab));
            }
        }
    };

    const renderTabs = (conf) => {
        const { tab, permission, translateName } = conf;
        if (!permission) return;
        const isActive = tab.toLowerCase() === currentTab.toLowerCase();
        return (
            <div className={isActive ? 'active' : ''} key={tab} onClick={() => handleTabClick(tab)}>
                {t(translateName)}
            </div>
        );
    };

    const TabContent = (config.filter((tab) => tab.tab === currentTab)[0] || {}).component;

    return (
        <div className={className || 'subtabs-panel'}>
            <div className={isCollapsed ? 'tabs collapsed' : 'tabs'}>
                <div className="tab-nav">
                    {config.map(renderTabs)}
                    {onToggleTabs && (
                        <div className="tab-header-buttons">
                            <i className={isCollapsed ? "icon-left" : "icon-right"} onClick={onToggleTabs} />
                        </div>
                    )}
                </div>
                <TabContent {...props} />
            </div>
        </div>
    );
};

export default withTranslation()(withRouter(Tabs));
