import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

import useModalVisibility from '../../../custom-hooks/useModalVisibility';
import BPMNModal from '../common/BPMNModal';


const Diagram = ({ data, value, t }) => {
    const { isModalOpen, toggleModalVisibility } = useModalVisibility();

    const [activeDiagramId, setActiveDiagram] = useState(null);

    const handleDiagramClick = e => {
        e.stopPropagation();
        if (data && (data.id || data.taskId)) {
            setActiveDiagram(data.id || data.taskId);
            toggleModalVisibility(true);
        }
    };

    const diagramIconProps = {
        className: cx('data-cell', { disabled: !value }),
        title: value ? t('showDiagram') : t('No diagram found'),
        onClick: value ? handleDiagramClick : undefined
    };
    const DiagramIcon = (
        <div {...diagramIconProps} >
            <i className='icon-sitemap' />
        </div>
    );

    if (activeDiagramId) {
        return (
            <Fragment>
                {DiagramIcon}
                <BPMNModal id={activeDiagramId} isOpen={isModalOpen} toggleModalVisibility={toggleModalVisibility} />
            </Fragment>
        );
    }

    return DiagramIcon;
};

export default withTranslation()(Diagram);
