import React from 'react';
import Popup from 'components/Popup';
import ComboBox from 'components/Common/ComboBox';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bindActionCreators } from 'redux';
import { setCallReceptionStatus, subscribeToCalls, setAuthorizationSuccess, getOperatorStatus, closeEventStreamAndPopUp } from 'actions/call';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import URLS from 'config/urls';
import baseService from 'services/BaseService';
import { sessionStorageService } from 'services/settingsService';
import UserCallForm from './UserCallForm';
import ClickOutsideHolder from '../ClickOutsideHolder';
import permissions, { checkPermissions } from 'config/permissions';

import pcg from '../../../package.json';

const mapStateToProps = state => ({
	phoneNumber: state.call.phoneNumber,
	callReceptionStatus: state.call.callReceptionStatus,
	isAuthorized: state.call.isAuthorized,
	operatorActions: state.call.operatorActions,
	callStation: state.call.callStation,
	user: state.user,
	pathPrefix: state.call.pathPrefix,
	shouldReadStatus: checkPermissions(permissions.CallOperations.action_workPbx),
});

function mapDispatchToProps (dispatch) {
	return bindActionCreators({
		setCallReceptionStatus,
		subscribeToCalls,
		setAuthorizationSuccess,
		getOperatorStatus,
		closeEventStreamAndPopUp
	}, dispatch);
}

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class UserPopup extends React.Component {
	constructor (props) {
		super(props);
		this.authorizing = false;
		this.onStatusChange = this.onStatusChange.bind(this);

		this.state = {
			isDisconnected: false,
		};
	}
	
	componentDidMount () {
		const isOpenedBefore = sessionStorageService.get('isUserPopupOpenedBefore');
		const { operatorActions, user } = this.props;
		
		if (!isOpenedBefore) {
			sessionStorageService.set('isUserPopupOpenedBefore', true);
		}
		if (user.login && operatorActions.length === 0) {
			this.props.getOperatorStatus(this.props.user.login);
		}
	}
	
	componentDidUpdate (prevProps) {
		
		const { operatorActions, user, callReceptionStatus, pathPrefix } = this.props;
		const { isDisconnected } = this.state;
		const prevCallReceptionStatus = prevProps.callReceptionStatus;
		
		if (user.login && !prevProps.user.login && operatorActions.length === 0) {
			this.props.getOperatorStatus(this.props.user.login);
		}
		const phoneNumber = localStorage.getItem('phone');


		// only if prev state was empty - init phone_register
		if (!this.authorizing && !isDisconnected && !this.props.isAuthorized && phoneNumber && callReceptionStatus === 'returntowork' && prevCallReceptionStatus === 'empty') {
			this.authorizing = true;
			baseService.post('phone_register', { data: { phone: phoneNumber } }).then(() => {
				this.props.setAuthorizationSuccess(phoneNumber);
				this.props.subscribeToCalls(null, pathPrefix, phoneNumber, this.handleDisconnect);
				this.props.onClose();
				// localStorage.setItem('phone', phoneNumber);
				// localStorage.setItem('pathPrefix', pathPrefix);
				this.authorizing = false;
			}).catch (e => {
				this.authorizing = false;
			});
			// this.props.subscribeToCalls(() => {
			// 	if (!this.props.isAuthorized) {
			// 		baseService.post('phone_register', { data: { phone: phoneNumber } });
			// 		this.props.setAuthorizationSuccess(phoneNumber);
			// 		this.props.onClose();
			// 	}
			// }, pathPrefix);
		}
	}
	
	componentWillUnmount () {
		if (this.isActiveCallReceptionsStatus() && !this.props.isAuthorized) {
			this.props.setCallReceptionStatus('inactive');
		}
	}
	
	logOut = () => {
		const { pathPrefix, closeEventStreamAndPopUp } = this.props;
		sessionStorage.clear();
		closeEventStreamAndPopUp(pathPrefix);
		window.location.href = URLS.core.logout.url;
	};
	
	isActiveCallReceptionsStatus = () => this.props.callReceptionStatus === 'returntowork';
	
	getPhotoSource = () => `data:image/png;base64,${this.props.user.user_photo}`;
	
	getPopupAvatar = () => (this.props.user.user_photo
		? (
			<div className='popupAvatarWrapper'>
				<img
					alt=''
					className='popup-user-image'
					src={this.getPhotoSource()}
				/>
			</div>
		)
		: (
			<div className='popup-circle-wrapper'>
				<div className='icon-user-wrapper'>
					<i className='icon-user' />
				</div>
			</div>
		));
	
	onStatusChange ({ value }) {
		// console.log("*** PHONE IS REMOVED onStatusChange ***");
		localStorage.removeItem('pathPrefix');
        localStorage.removeItem('phone');
        localStorage.removeItem('callStation');
		const { user, setCallReceptionStatus } = this.props;
		setCallReceptionStatus(value, user.login);
	};
	
	handleClickOutside = (event) => {
		if (!this.authorizing) this.props.handleClickOutside(event);
	};

	handleDisconnect = () => {
		this.setState({isDisconnected: true});
	};
	
	onCallFormSubmit = ({ phoneNumber, agentName, agentPassword }) => {
		let { callStation, pathPrefix } = this.props;
		if (!callStation) {
			callStation = localStorage.getItem('callStation');
		}
		if (!pathPrefix) {
			pathPrefix = localStorage.getItem('pathPrefix');
		}
		if (!phoneNumber) {
			phoneNumber = localStorage.getItem('phone');
		}
		if (!pathPrefix || !callStation || !phoneNumber) {
			console.error(`UserPopup::onCallFormSubmit: Either/both pathPrefix: ${pathPrefix} or callStation: ${callStation} or phoneNumber: ${phoneNumber} param is empty`);
			return;
		}
		this.authorizing = true;
		const params = {
			data: {
				phone: phoneNumber,
				login: agentName,
				authType: 'Basic',
				authValue: agentPassword,
			},
			jsonType: true,
		};
		
		return baseService.post('sse_dynamic_auth', { pathParams: { pathPrefix }, ...params}, true)
			.then((response) => {
				if (response.success && response.result.confirmed) {
					this.authorizing = false;
					baseService.post('phone_register', { data: { phone: phoneNumber } }).then(() =>{
						this.props.setAuthorizationSuccess(phoneNumber);
						this.props.subscribeToCalls(null, pathPrefix, phoneNumber, this.handleDisconnect);
						this.props.onClose();
						localStorage.setItem('phone', phoneNumber);
						localStorage.setItem('pathPrefix', pathPrefix);
						this.setState({isDisconnected: false});
					});
				} else {
					throw new Error('SSE authentication error');
				}
			})
			.catch(() => {
				this.authorizing = false;
				return callStation === 'asterisk' ? 'Некоректний номер телефону'
					: 'Некоректний логін або пароль';
			});
	};
	
	render () {
		const {
			user, callReceptionStatus, formRef, t, operatorActions, shouldReadStatus,
			isPopupOpen,
		} = this.props;
		return (
			<ClickOutsideHolder onClickOutside={this.handleClickOutside}>
				<Popup place={cx('user-info-popup', { none: !isPopupOpen })}>
					<div className='popup-main'>
						<div className='popup-wrapper'>
							{this.getPopupAvatar()}
							
							<div className='popup-text-wrapper'>
								<div className='popup-name'>{user.login}</div>
								<div className='popup-on-line' />
							</div>
						</div>
						
						<div className='popup-select' ref={formRef}>
							{
								shouldReadStatus ? (
									<div className='row'>
										<div className='input-element'>
											<div className='input-label'>{t('status_name')}</div>
											<ComboBox
												name='status'
												placeholder={t('call.chooseStatus')}
												options={operatorActions}
												value={callReceptionStatus}
												onChange={this.onStatusChange}
											/>
										</div>
									</div>
								) : null
							}
							
							{
								this.isActiveCallReceptionsStatus() && (
									<UserCallForm
										onSubmit={this.onCallFormSubmit}
										user={user}
										isDisconnected={this.state.isDisconnected}
										onDisconnect={this.handleDisconnect}
										key={callReceptionStatus}
									/>
								)
							}
						</div>
					</div>
					
					<div className='popup-footer exit'>
						<button type='button' className='menu-item' onClick={this.logOut}>
							<i className='icon-sign-out' />
							<span className='menu-item-text'>{t('userExit')}</span>
						</button>
					</div>
					
					<div className='popup-footer version'>
                        <span className='version-text'>
                            {/* {`${t('version')}: ${pcg.version} | ${pcg.date} @Priocom`} */}
                            {`${t('version')}: ${pcg.version} | ${pcg.date}`}
                        </span>
					</div>
				
				</Popup>
			</ClickOutsideHolder>
		);
	}
}

UserPopup.propTypes = {
	handleClickOutside: PropTypes.func,
	user: PropTypes.object,
	formRef: PropTypes.object,
	onClose: PropTypes.func,
	closeEventStreamAndPopUp: PropTypes.func,
};

export default UserPopup;
