import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader/index';

import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import { getFormValues } from 'redux-form';

import { get } from 'lodash';
import { getAllFilterFields, getSearchFilters } from 'actions/searchFilter';
import SearchFilters from 'components/SearchFilters';
import { configureFilterObject } from '../../../SearchFilters/helpers';

const mapStateToProps = (state, props) => {
    // console.log({props});
    const path = props.match.url.split('/');

    const proxyTabFiltersId =
    props.itemId
            ? `proxy-tab-filter-customer-${props.config.code}-${props.itemType}-${props.itemId}-${props.id}`
            : `proxy-tab-filter-customer-${props.config.code}-${props.id}`;

    return {
        grid: state.grid,
        filterValues: getFormValues(proxyTabFiltersId)(
            state,
        ),
        allFilterFields: get(
            state,
            `searchFilter.allFilterFields[${proxyTabFiltersId}]`,
            [],
        ),
        proxyTabFiltersId
    };
};

const ProxyList = (props) => {
    const {
        id,
        itemId,
        itemType,
        updateApiGrid,
        config,
        getSearchFilters,
        getAllFilterFields,
        filterValues,
        allFilterFields,
        proxyTabFiltersId,
    } = props;

    const [loadingState, setLoadingState] = useState(true);

    // console.log({props});

    // const path = props.match.url.split('/');
    // const customerIndex = path.findIndex((item) => item === 'customer');
    // console.log({customerIndex});

    // let subTabName = '';
    // let subTabId = '';

    // if (customerIndex !== 1) {
    //     subTabName = path[1];
    //     subTabId = path[2];
    // }

    const handleSearch = (data) => {
        // console.log({ data });
    };

    const init = {
        method: 'post',
        key: 'proxy_grid',
        pathParams: {
            dataSource: config.params.source,
            objectType: 'customer',
            objectID: id,
        },
        // expander arrow down config => source + url key
        expanderSource: config.params.source,
        expanderUrlKey: 'get_proxy_detail_in_grid',
        expanderActionsUrlKey: 'get_proxy_detail_actions_in_grid',
        extra:
            itemId 
                ? `customer_${config.code}_${itemType}_${itemId}.${id}`
                : `customer_${config.code}_${id}`,
        configUrlKey: 'config_grid_tab',
        configKey: 'proxy_grid',
        configCode: config.params.applyCode,
        configTabCode: 'customer',
        filterObject: configureFilterObject(filterValues, allFilterFields),
        sort: [],
    };

    const gridKey = `${init.key}_${init.path || init.extra || ''}`;

    // console.log({gridKey});

    // const proxyTabFiltersId =
    //     customerIndex !== 1
    //         ? `proxy-tab-filter-customer-${config.code}-${subTabName}-${subTabId}-${id}`
    //         : `proxy-tab-filter-customer-${config.code}-${id}`;

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
        getSearchFilters({ code: config.params.applyCode });
        getAllFilterFields({
            code: config.params.applyCode,
            idHashKeyRequest: proxyTabFiltersId,
        });
    }, []);

    useEffect(() => {
        const newFilterObject = configureFilterObject(filterValues, allFilterFields);
        updateApiGrid({ filterObject: newFilterObject }, gridKey);
    }, [filterValues]);

    return (
        <>
            <div className="content-wrapper ordering-component-ui-core-wrapper with-fixed-status">
                <GridWrapper
                    tuner={() => init}
                    initUpdate={false}
                    onStart={onStart}
                    onFinish={onFinish}
                />
            </div>
            {loadingState && <Loader />}
            <SearchFilters
                onSearch={handleSearch}
                proxyCode={config.params.applyCode}
                id={itemId ? `${itemId}-${id}` : id}
                searchId={proxyTabFiltersId}
                idHashKeyRequest={proxyTabFiltersId}
                customFormName={proxyTabFiltersId}
                withoutSearchRedirect // prevent from redirect on /search/undefined/undefined/
            />
        </>
    );
};

export default withRouter(connect(mapStateToProps, {
    updateApiGrid: setApi,
    getSearchFilters,
    getAllFilterFields,
})(ProxyList));
