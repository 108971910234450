export const APPEAL_REQUEST = 'APPEAL_REQUEST';
export const APPEAL_REQUEST_SUCCESS = 'APPEAL_REQUEST_SUCCESS';
export const APPEAL_REQUEST_FAILURE = 'APPEAL_REQUEST_FAILURE';
export const  CLEAR_APPEAL_STATE = 'CLEAR_APPEAL_STATE';

export const CREATE_NEW_APPEAL = 'CREATE_NEW_APPEAL';
export const CREATE_NEW_APPEAL_SUCCESS = 'CREATE_NEW_APPEAL_SUCCESS';
export const CREATE_NEW_APPEAL_ERROR = 'CREATE_NEW_APPEAL_ERROR';
export const GET_APPEAL_TYPES_LIST_SUCCESS = 'GET_APPEAL_TYPES_LIST_SUCCESS';
export const GET_APPEAL_TYPES_LIST_ERROR = 'GET_APPEAL_TYPES_LIST_ERROR';
export const GET_DINAMIC_FORM_PARAMS = 'GET_DINAMIC_FORM_PARAMS';
export const GET_DINAMIC_FORM_PARAMS_SUCCESS = 'GET_DINAMIC_FORM_PARAMS_SUCCESS';
export const GET_DINAMIC_FORM_PARAMS_ERROR = 'GET_DINAMIC_FORM_PARAMS_ERROR';
export const GET_APPEAL_STATUS_LIST = 'GET_APPEAL_STATUS_LIST';
export const GET_APPEAL_STATUS_LIST_SUCCESS = 'GET_APPEAL_STATUS_LIST_SUCCESS';
export const GET_APPEAL_STATUS_LIST_ERROR = 'GET_APPEAL_STATUS_LIST_ERROR';
export const SET_APPEAL_STATUS_LIST_TEMPLATE_DATA = 'SET_APPEAL_STATUS_LIST_TEMPLATE_DATA';
export const CLEAR_APPEAL_STATUS_LIST_TEMPLATE_DATA = 'CLEAR_APPEAL_STATUS_LIST_TEMPLATE_DATA';
export const SET_APPEAL_STATUS_LIST_INITIALIZED = 'SET_APPEAL_STATUS_LIST_INITIALIZED';
export const SAVE_APPEAL = 'SAVE_APPEAL';
export const SAVE_APPEAL_SUCCESS = 'SAVE_APPEAL_SUCCESS';
export const SAVE_APPEAL_ERROR = 'SAVE_APPEAL_ERROR';
export const APPEAL_DESTINATIONS_REQUEST = 'APPEAL_DESTINATIONS_REQUEST';
export const APPEAL_DESTINATIONS_NEED_REFRESH = 'APPEAL_DESTINATIONS_NEED_REFRESH';
export const APPEAL_DESTINATIONS_REQUEST_SUCCESS = 'APPEAL_DESTINATIONS_REQUEST_SUCCESS';
export const APPEAL_DESTINATIONS_REQUEST_FAILURE = 'APPEAL_DESTINATIONS_REQUEST_FAILURE';
export const LOCK_APPEAL_FORM = 'LOCK_APPEAL_FORM';
export const UNLOCK_APPEAL_FORM = 'UNLOCK_APPEAL_FORM';
export const UNLOCK_APPEAL_FORM_SUCCESS = 'UNLOCK_APPEAL_FORM_SUCCESS';
export const UNLOCK_APPEAL_FORM_ERROR = 'UNLOCK_APPEAL_FORM_ERROR';
export const GET_APPEAL_PRIORITY_LIST = 'GET_APPEAL_PRIORITY_LIST';
export const GET_APPEAL_PRIORITY_LIST_SUCCESS = 'GET_APPEAL_PRIORITY_LIST_SUCCESS';
export const GET_APPEAL_PRIORITY_LIST_ERROR = ' GET_APPEAL_PRIORITY_LIST_ERROR';
export const CLEAR_APPEAL_FEEDBACK = 'CLEAR_APPEAL_FEEDBACK';
export const GET_APPEAL_FEEDBACK = 'GET_APPEAL_FEEDBACK';
export const GET_APPEAL_FEEDBACK_SUCCESS = 'GET_APPEAL_FEEDBACK_SUCCESS';
export const GET_APPEAL_FEEDBACK_ERROR = 'GET_APPEAL_FEEDBACK_ERROR';
export const GET_APPEAL_ACTIONS = 'GET_APPEAL_ACTIONS';
export const SET_APPEAL_ACTIONS = 'SET_APPEAL_ACTIONS';
export const SET_FIRST_LEVEL_ACTION = 'SET_FIRST_LEVEL_ACTION';
export const SET_RESOLUTION_ACTION = 'SET_RESOLUTION_ACTION';
export const SET_APPEAL_VERSION = 'SET_APPEAL_VERSION';
export const REMOVE_REFRESH_APPEAL_MODAL = 'REMOVE_REFRESH_APPEAL_MODAL';
export const SET_MODAL_STATUS_LIST = 'SET_MODAL_STATUS_LIST';
export const SET_STATUS_MODAL_VISIBILITY = 'SET_STATUS_MODAL_VISIBILITY';
export const APPEAL_FETCHING = 'APPEAL_FETCHING';
export const REMOVE_CUSTOMER_FROM_APPEAL = 'REMOVE_CUSTOMER_FROM_APPEAL';
export const ADD_CUSTOMER_TO_APPEAL = 'ADD_CUSTOMER_TO_APPEAL';
export const CLEAR_ALL_APPEALS = 'CLEAR_ALL_APPEALS';
export const SAVE_AND_CREATE_NEW = 'SAVE_AND_CREATE_NEW';
export const SET_REQ_DYN_PARAMS = 'SET_REQ_DYN_PARAMS';
export const SET_REQUIRED_CUSTOMER = 'SET_REQUIRED_CUSTOMER';
export const UPDATE_VIEW_LINKS = 'UPDATE_VIEW_LINKS';
export const SET_LINKED_APPEALS = 'SET_LINKED_APPEALS';
export const SET_LINK_СHECKED_BINDERS = 'SET_LINK_BINDERS';
export const CHECK_LINK_BINDER = 'CHECK_LINK_BINDER';
export const UNCHECK_LINK_BINDER = 'UNCHECK_LINK_BINDER';
export const CLEAR_LINK_BINDERS = 'CLEAR_LINK_BINDERS';
export const SET_LINKED_OBJECTS = 'SET_LINKED_OBJECTS';
export const SET_OBJECT_LINK_СHECKED_BINDERS = 'SET_OBJECT_LINK_BINDERS';
export const CHECK_OBJECT_LINK_BINDER = 'CHECK_OBJECT_LINK_BINDER';
export const UNCHECK_OBJECT_LINK_BINDER = 'UNCHECK_OBJECT_LINK_BINDER';
export const CLEAR_OBJECT_LINK_BINDERS = 'CLEAR_OBJECT_LINK_BINDERS';
export const LINK_FILTERS_REQUEST_FAILURE = 'LINK_FILTERS_REQUEST_FAILURE';
export const LINK_FILTERS_REQUEST_SUCCESS = 'LINK_FILTERS_REQUEST_SUCCESS';
export const SET_APPEAL_GRID_REFRESH = 'SET_APPEAL_GRID_REFRESH';

export const SET_APPEAL_IS_SAVE_AND_CREATE_NEW = 'SET_APPEAL_IS_SAVE_AND_CREATE_NEW';

export const REMOVE_APPEAL_LOCK_SOURCE = 'REMOVE_APPEAL_LOCK_SOURCE';
export const UNLOCK_APPEAL_AND_CLEAR_SOURCE = 'UNLOCK_APPEAL_AND_CLEAR_SOURCE';
export const UNLOCK_APPEAL_AND_CLEAR_SOURCE_SUCCESS = 'UNLOCK_APPEAL_AND_CLEAR_SOURCE_SUCCESS';
export const UNLOCK_APPEAL_AND_CLEAR_SOURCE_ERROR = 'UNLOCK_APPEAL_AND_CLEAR_SOURCE_ERROR';
export const UNLOCK_APPEAL_FORM_ALREADY_UNLOCKED = 'UNLOCK_APPEAL_FORM_ALREADY_UNLOCKED';


export const SET_APPEAL_ID_CENTRAL_PART_LOADING = 'SET_APPEAL_ID_CENTRAL_PART_LOADING';

export const SET_FORM_FORCE_INIT_VALUES = 'SET_FORM_FORCE_INIT_VALUES';


