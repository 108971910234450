const ACTIONS = {
	RESOURCE_CURRENT_REQUEST_SUCCESS: 'RESOURCE_CURRENT_REQUEST_SUCCESS',
	RESOURCE_REQUEST_FAILURE: 'RESOURCE_REQUEST_FAILURE',
	SET_RESOURCE_NEED_REFRESH: 'SET_RESOURCE_NEED_REFRESH',
};

const initialState = {
	currentData: {},
	isNeedRefresh: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
	
	case ACTIONS.RESOURCE_CURRENT_REQUEST_SUCCESS: {
		return { ...state, currentData: action.result };
	}

	case ACTIONS.SET_RESOURCE_NEED_REFRESH: {
		return { ...state, isNeedRefresh: action.isNeedRefresh };
	}
	
	case ACTIONS.RESOURCE_REQUEST_FAILURE: {
		return { ...state };
	}
	
	default: {
		return state;
	}
	}
};

export const setCurrentData = result => ({
	type: ACTIONS.RESOURCE_CURRENT_REQUEST_SUCCESS,
	result
});

export const setResourceNeedRefresh = isNeedRefresh => ({
	type: ACTIONS.SET_RESOURCE_NEED_REFRESH,
	isNeedRefresh
});

export const hasError = (error) => ({
	type: ACTIONS.RESOURCE_REQUEST_FAILURE,
	error,
});


