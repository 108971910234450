import React, { useState, useEffect } from "react";
import useDebounce from "custom-hooks/useDebounce";

import { isEqual } from "lodash";

const DEBOUNCE_TIMEOUT = 500;

export default function useDebouncedPreviewValues(
    currentValues,
    attrs,
    minValLength = 2,
    timeout = DEBOUNCE_TIMEOUT,
    isSearchDataCheckedTrigger, // will set isSearchDataChecked to true
) {
    const [searchPreviewValues, setSearchPreviewValues] = useState({});

    const [isSearchDataChecked, setIsSearchDataChecked] = useState(false);

    const { debouncedValue: debouncedPreviewValues, setDebouncedValue } = useDebounce(searchPreviewValues, timeout);

    function setOuterDebounceValue(data) {
        setDebouncedValue(data);
        setSearchPreviewValues(data);
    }

    // isSearchDataCheckedTrigger updated (searchData) => set checked to true
    useEffect(() => {
        setIsSearchDataChecked(true);
    }, [isSearchDataCheckedTrigger]);

    // set checked to false until search data is not updated
    useEffect(() => {
        setIsSearchDataChecked(false);
    }, [searchPreviewValues]);

    // setup searchPreviewValues
    useEffect(() => {
        // if values - work with them
        if (currentValues) {
            let newSearchPreviewValues = { ...searchPreviewValues };
            attrs.forEach(attr => {
                let value = currentValues[attr.field];
                // parse value from objects, try to get value from label and value properties
                if (value && typeof value === "object") {
                    value = value.label || value.value || value.key || value.id || "";
                }
                const oldValue = searchPreviewValues[attr.field] && searchPreviewValues[attr.field].value;
                // only add value if it has more than 3 length
                if (value && value.length >= minValLength && value !== oldValue) {
                    newSearchPreviewValues = {
                        ...newSearchPreviewValues,
                        [attr.field]: {
                            value,
                        },
                    };
                }

                if (value && value.length < minValLength) {
                    delete newSearchPreviewValues[attr.field];
                }

                if ([null, undefined, ""].includes(value)) {
                    delete newSearchPreviewValues[attr.field];
                }
            });
            if (!isEqual(searchPreviewValues, newSearchPreviewValues)) {
                setSearchPreviewValues(newSearchPreviewValues);
            } else {
                const isFocused = document.activeElement && document.activeElement.tagName !== "BODY";
                if (isFocused) {
                    // set search data is checked
                    // handle issue with redux form field bluring and evaluating the same form values but button is disabled since no check is trigerred
                    setTimeout(() => setIsSearchDataChecked(true), 0);
                }
            }
        }
        // otherwise reset searchPreviewValues
        if (!currentValues) {
            setSearchPreviewValues({});
            setDebouncedValue({});
        }
    }, [currentValues, attrs]);

    return { debouncedPreviewValues, setDebouncedPreviewValues: setOuterDebounceValue, isSearchDataChecked };
}
