import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { KnowledgeItemTypes } from 'constants/index';
import { connect } from 'react-redux';
import { getKnowledgeItem } from 'actions/knowledges';
import { createCheckPropsFunction } from 'helpers';
import Loader from '../../Loader';

const mapStateToProps = state => ({
	knowledgeItem: state.knowledges.knowledgeItem,
	isLoading: state.knowledges.knowledgeItemLoading,
	modificationCount: state.knowledges.knowledgeItemModificationCount
});

const mapDispatchToProps = dispatch => ({
	getKnowledgeItem: requestData => dispatch(getKnowledgeItem(requestData))
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class MainContentWrapper extends PureComponent {
	
	componentDidMount () {
		this.getKnowledgeItemId(this.props) && this.requestKnowledgeItem();
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		
		const isKnowledgeIdChanged = isPropChanged('match.params.knowledgeId');
		const isKnowledgeModified = isPropChanged('modificationCount') && !isPropChanged('match.params.knowledgeId');
		
		if (this.getKnowledgeItemId(this.props) && (isKnowledgeIdChanged || isKnowledgeModified)) {
			this.requestKnowledgeItem();
		}
	}
	
	getKnowledgeItemId = props => props.match.params.knowledgeId;
	
	requestKnowledgeItem = () => {
		const itemId = Number.parseInt(this.props.match.params.knowledgeId);
		const requestData = {
			type: this.getKnowledgeItemType(itemId, this.props.knowledgeBase),
			id: itemId
		};
		
		return this.props.getKnowledgeItem(requestData);
	};
	
	getKnowledgeItemType = (itemId, items) => {
		for (let element of items) {
			const type = element.leaf
				? this.checkLeaf(element, itemId)
				: this.checkFolder(element, itemId);
			
			if (type) {
				return type;
			}
		}
		
		return null;
	};
	
	checkFolder = (element, itemId) => {
		if (element.id === itemId) {
			return KnowledgeItemTypes.FOLDER;
		}
		
		if (element.result.length > 0) {
			return this.getKnowledgeItemType(itemId, element.result);
		}
	};
	
	checkLeaf = (element, itemId) => element.item.id === itemId ? KnowledgeItemTypes.LEAF : null;
	
	render () {
		const { children, isLoading } = this.props;
		return isLoading ? <Loader withContainer /> : children;
	}
}

MainContentWrapper.propTypes = {
	getKnowledgeItem: PropTypes.func,
	knowledgeBase: PropTypes.array
};

export default MainContentWrapper;
