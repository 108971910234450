import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Field, getFormValues, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, AtomicBlockUtils } from 'draft-js';

import InputField from 'components/Common/InputField';
import { validateEmail } from 'util/form';
import { setMailBody } from 'actions/emails';
import { openSearchEmailModal, setSearchModalEmailBinders } from "actions/ui";
import { reduxFormWrapper } from 'helpers';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { withTranslation } from 'react-i18next';
import Progressbar from 'components/Progressbar';
import PropTypes from 'prop-types';
import EmailInput from '../../../components/Common/ArrayPillsInput';
import { extendedBlockRenderMap, toHtml } from './utils';
import validate from './validate';
import SignatureListActions from './SignatureListActions';
import { editorToolbar, getFileBase64 } from 'constants/index';
import ReactSVG from 'react-svg';
import Loader from 'components/Loader';
import permissions, { checkPermissions } from 'config/permissions';

import baseService from '../../../services/BaseService';

import {
    convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';



function mapStateToProps(state, props) {
    const email = _.get(state, `emails.emails[${props.id}]`, {});

    return {
        formValues: getFormValues(props.form)(state),
        sendEmailSuccess: email.sendEmailSuccess,
        uploadingFile: email.uploadingFile,
        templateList: email.templateList,
        signatureList: email.signatureList,
        canSendMail: checkPermissions(permissions.EmailOperations.action_sendEmail),
    };
}

const mapDispatchToProps = {
    setMailBody,
    openSearchEmailModal,
    setSearchModalEmailBinders,
    // change,
};

@withRouter
@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
})
export default class MailingSendForm extends PureComponent {
    componentDidCatch() {
        this.forceUpdate();
    }

    constructor(props) {
        super(props);
        // const interactionId = props.id;

        this.editorToolbar = {
            ...editorToolbar,
            image: {
                ...editorToolbar.image,
                uploadCallback: (file) => {
                    const { interactionId, newEmailInteractionId } = this.props;
                    console.log({ interactionId, newEmailInteractionId });
                    const params = {
                        data: {
                            data: {
                                // interactionId,
                                interactionId: interactionId || newEmailInteractionId,
                                fileName: file.name,
                                contentDisposition: 'inline',
                            },
                            file,
                        },
                        file: true,
                    };
                    return baseService
                        .post('upload_file_email', params)
                        .then((data) => ({
                            data: { link: `/mw/file?fileId=${data.result}&download=0` },
                            // data: { link: `https://crm-newuidev.lab/crm/data/images/logo.png` },

                        }))
                        .catch((err) => console.log(`MailingSendForm::uploadCallback: Error`, err));
                },
            },
        };

        this.state = {
            toolbar: false,
            selectedOption: null,
            isFocused: false,
            isOpenSignaturePopup: false,
            submitting: false,
            // showCopy: false,
        };
        this.togglePopup = this.togglePopup.bind(this);
    }

    focus = () => this.setState({ isFocused: true });

    blur = () => this.setState({ isFocused: false });

    renderInputField = ({ name, label, placeholder }) => (
        <Field
            component={InputField}
            key={name}
            name={name}
            label={label}
            placeholder={placeholder}
            errorPlaceholder={true}
        />
    );

    handleLoadFiles = (e) => {
        const { onUploadFiles, interactionId } = this.props;
        const newFiles = [...e.currentTarget.files];

        // console.log({ interactionId });

        onUploadFiles(newFiles, interactionId);
        e.target.value = null;
    };

    handleDeleteFile(fileId) {
        const { deleteFile, onDeleteFile, attachmentFiles } = this.props;

        deleteFile(fileId, function() {
            onDeleteFile(attachmentFiles);
        });
    }

    onEditorStateChange = (editorState) => {
        this.props.setMailBody(this.props.id, editorState);
    };

    renderHiddenWidgets = (withCopy) => {
        // const { showCopy } = this.state;
        const { t, showCopy, setShowCopy } = this.props;
        return (
            <>
                {!showCopy ? (
                    <label
                        className={cx('hiddenCopy', {})}
                        // onClick={() => this.setState((prev) => ({ showCopy: !prev.showCopy }))}
                        onClick={() => setShowCopy(true)}
                    >
                        {t('mailing.copy')}
                    </label>
                ) : null}
            </>
        );
    };

    onShowEditorToolbar = () => {
        this.setState((prevState) => ({ toolbar: !prevState.toolbar }));
    };

    renderAttachment = (file, index) => (
        // <div className='fileWrapper' key={index}>
        // 	<a className="file-preview" target="_blank" href={`../mw/file?fileId=${file.id}&download=0`} title={name}>
        //         {file.name}
		// 	</a>
		// 	<div className="kb-file-actions">
		// 		<a className="file-download" target="_blank" href={`../mw/file?fileId=${file.id}&download=1`} title={name}>
		// 			<ReactSVG className="svg-download" src="/data/svg/download.svg"/>
		// 		</a>
		// 		<ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" onClick={() => this.handleDeleteFile(file.id, index)} />
		// 	</div>
		// </div>
		<div className="kb-file-wrapper" key={index}>
			<a className="file-preview" target="_blank" href={`../mw/file?fileId=${file.id}&download=0`} title={file.name}>
				{file.name}
			</a>
			<div className="kb-file-actions">
				<a className="file-download" target="_blank" href={`../mw/file?fileId=${file.id}&download=1`} title={file.name}>
					<ReactSVG className="svg-download" src="/data/svg/download.svg"/>
				</a>
				<ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" onClick={() => this.handleDeleteFile(file.id, index)} />
			</div>
		</div>
	);

    togglePopup = () => {
        this.setState((prevState) => ({ isOpenSignaturePopup: !prevState.isOpenSignaturePopup }));
    };

    // pasteImageToEditor = (link) => {
    //     const { width, height } = this.editorToolbar.image.defaultSize;
    //     const contentState = this.props.mailBody.getCurrentContent();
    //     const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
    //         src: link,
    //         height,
    //         width,
    //     });
    //     const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    //     const newEditorState = EditorState.set(this.props.mailBody, {
    //         currentContent: contentStateWithEntity,
    //     });

    //     const finalEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

    //     this.onEditorStateChange(finalEditorState);
    // };

    insertImage = url => {
        const { width, height } = this.editorToolbar.image.defaultSize;
        const contentState = this.props.mailBody.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url, height, width, alignment: "left" });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(this.props.mailBody, { currentContent: contentStateWithEntity });
        const finalEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
        this.onEditorStateChange(finalEditorState);
    };

    handlePastedFiles = (files) => {
        const file = files[0];
        const { interactionId, newEmailInteractionId } = this.props;
        const params = {
            data: {
                data: {
                    interactionId: interactionId || newEmailInteractionId,
                    fileName: file.name,
                    contentDisposition: 'inline',
                },
                file,
            },
            file: true,
        };
        return baseService
            .post('upload_file_email', params)
            .then((data) => {
                const link = `/mw/file?fileId=${data.result}&download=0`;
                // const link = `https://crm-newuidev.lab/crm/data/images/logo.png`;
                // this.pasteImageToEditor(link);
                this.insertImage(link);
            })
            .catch((err) => console.log(`MailingSendForm::handlePastedFiles: Error`, err));
    };

    handleContactSuggestion = async query => {
        const { withContactSearch } = this.props;
        // prevent suggestions if no contact search is available
        if (!withContactSearch) {
            return [];
        }
        // contact search is available, handle search
        let trimmedQuery = query && query.trim();
        if (!trimmedQuery) {
            return [];
        };
        try {
            const params = {
                data: {
                    // query: "",
                    query: trimmedQuery,
                    limit: 20,
                    start: 0,
                    category: "ALL",
                }
            }
            const response = await baseService.get('searchEmailContacts', params);
            if (response.success) {
                return response.result;
            }
            return [];
        } catch (e) {
            console.error("MailingSendForm::handleContactSuggestion: Error during contacts query suggestion ", e);
            return [];
        }
    }

    addEmailAddressFromGrid = (fieldName, emails) => {
        const { change, formValues } = this.props;
        const prevEmails = formValues[fieldName] || [];
        const mergedEmails = [...new Set([...prevEmails, ...emails])];
        change(fieldName, mergedEmails);
    };

    handleSearchEmailModalOpen = (fieldName) => {
        const { setSearchModalEmailBinders, openSearchEmailModal, formValues} = this.props;
        const prevEmails = formValues[fieldName] || [];
        setSearchModalEmailBinders(prevEmails);
        openSearchEmailModal({ field: fieldName });
    }

    handleSubmitClick = async () => {
        const { formValues, onSendForm, mailBody, replyMailBody } = this.props;
        if (!_.isEmpty(validate(formValues))) {
            throw new SubmissionError(validate(formValues));
        } else {
            this.setState({submitting: true});
            if (replyMailBody) {
                const rawContentState = convertToRaw(mailBody.getCurrentContent());
                const mailBodyHTML = draftToHtml(rawContentState);
                const fullHtml = mailBodyHTML + replyMailBody;
                const isSuccess = await onSendForm(formValues, fullHtml);
                if (!isSuccess) {
                    this.setState({submitting: false});
                }
            } else {
                const isSuccess = await onSendForm(formValues, toHtml(mailBody));
                if (!isSuccess) {
                    this.setState({submitting: false});
                }
            }
        }
    }
	
	render () {
		const {
			attachmentFiles, t, maximized, minimized, formValues,
			onMinimize, onMaximize, onRestore, uploadingFile, mailBody, onClickClose, modalMode,
			signatureList,  canSendMail, showCopy,
            withContactSearch
		} = this.props;
		const { toolbar, isFocused, isOpenSignaturePopup, submitting } = this.state;
		if (this.props.mailBody) {
			return (
				<div
					className='formBody'
					// onSubmit={handleSubmit((value) => {
                    //     console.log({value});
                    //     // if (!_.isEmpty(validate(value))) {
                    //     //     throw new SubmissionError(validate(value));
                    //     // } else {
                    //     //     if (replyMailBody) {
                    //     //         const rawContentState = convertToRaw(mailBody.getCurrentContent());
                    //     //         const mailBodyHTML = draftToHtml(rawContentState);
                    //     //         const fullHtml = mailBodyHTML + replyMailBody;
                    //     //         return onSendForm(value, fullHtml);
                    //     //     } else {
                    //     //         return onSendForm(value, toHtml(mailBody));
                    //     //     }
                    //     // }
                    // })}
				>
                    {submitting && <Loader />}
					<div className='mailEditorButtons'>
						<div className='mail-form-head'>
							{!minimized ? <>
								<button disabled={submitting || this.props.isLockedByOther} className={cx('btn-save', 'btn-save', {disabled: !canSendMail || this.props.isLockedByOther})} onClick={this.handleSubmitClick}>{t('send')}</button>
								<ReactSVG className={cx('wrapper-hovered', {active: toolbar})} svgClassName = 'icon'
									src='data/svg/icon-text.svg' onClick={this.onShowEditorToolbar}
								    title={t('signature.onFormatting')}
									/>
								<label className={cx('fileUploader')} title={t('signature.onAttach')}>
									<ReactSVG className='wrapper-hovered' svgClassName='icon icon-attach' src='data/svg/attach.svg' />
										<input
										type='file'
										multiple
										onChange={this.handleLoadFiles}
										accept='*'
									/>
								</label>
								<ReactSVG className='wrapper-hovered' svgClassName='icon' src='data/svg/icon-pen.svg' onClick={this.togglePopup} title={t('signature.signatures')} />
									{isOpenSignaturePopup ?
										<SignatureListActions
											signatureList={signatureList}
											isOpen={isOpenSignaturePopup}
											onClickOutside={this.togglePopup}
											appealId={this.props.id}
										/> :
										null}
								{/*<ReactSVG className='wrapper-hovered' svgClassName='icon' src='data/svg/icon-template.svg' onClick={() => console.log('select template')} title={t('signature.templates')}/>*/}
								</> :
								<>
									<ReactSVG className='icon-head' svgClassName='' src='data/svg/email-open.svg'/>
									<div className='subject-head'>{_.get(formValues, 'subject', '')}</div>
								</>
								}
						</div>
						{!maximized ?
							<div>
								{!minimized ? <ReactSVG className='wrapper-hovered' svgClassName='icon' src='data/svg/minimize.svg' onClick={onMinimize} title={t('signature.onMinimize')}/> :
								<ReactSVG className='wrapper-hovered icon icon-restore' svgClassName=' ' src='data/svg/icon-restore.svg' onClick={onRestore} title={t('signature.onRestore')}/>}
								<div className='wrapper-hovered' onClick={onMaximize} title={t('signature.onMaximize')}> <i className='icon-enlarge2'  /></div>
								<div className='wrapper-hovered' onClick={onClickClose} title={t('signature.onClose')}> <i className='icon-close'  title={t('close')} /></div>
							</div> :
							null}
					</div>
                    {/* <div className='to-copy-wrapper'> */}
                        <div className='inlineFields emailInputField'>
                            <div className={cx("inputWrapper input-email-wrapper input-email-with-copy", {'with-copy-button': !showCopy, 'with-search-button': withContactSearch})}>
                                <Field
                                    component={EmailInput}
                                    name='to'
                                    placeholder={t('toWhom')}
                                    validatePill={validateEmail}
                                    containerClass='input-field-container email-input-container'
                                    wrapperClass='input-element'
                                    // withContactSearch={!withContactSearch}
                                    onQuerySuggestion={this.handleContactSuggestion}
                                    // {...emailsInputStylesAdd}
                                />
                                {(withContactSearch || !showCopy) && <div className="input-email-actions-wrapper" data-field-key="search-email-modal-to">
                                    {withContactSearch && (
                                        <ReactSVG
                                            className="wrapper-hovered"
                                            svgClassName="icon"
                                            src="data/svg/search.svg"
                                            // onClick={() => this.addEmailAddressFromGrid('to')}
                                            // onClick={() => this.props.openSearchEmailModal({field: 'to'})}
                                            onClick={() => this.handleSearchEmailModalOpen('to')}
                                            title={t("emailSearchAddress")}
                                        />
                                    )}
                                    {this.renderHiddenWidgets()}
                                </div>}
                            </div>
                            
                        </div>
                        {/* <div className={cx('inlineFields', 'hide', { showCopy })}> */}
                        {showCopy && <div className={cx('inlineFields emailInputField')}>
                            {/* <div className='inputWrapper input-email-wrapper'> */}
                            <div className={cx("inputWrapper input-email-wrapper", {'with-search-button': withContactSearch})}>
                                <Field
                                    component={EmailInput}
                                    name='copy'
                                    placeholder={t('mailing.copy')}
                                    validatePill={validateEmail}
                                    containerClass='input-field-container email-input-container'
                                    wrapperClass='input-element'
                                    onQuerySuggestion={this.handleContactSuggestion}
                                    // onContactSearchApiKey={!withContactSearch && 'searchEmailContacts'}
                                />
                                {withContactSearch && (
                                    <div className="input-email-actions-wrapper" data-field-key="search-email-modal-copy">
                                        <ReactSVG
                                            className="wrapper-hovered"
                                            svgClassName="icon"
                                            src="data/svg/search.svg"
                                            // onClick={() => this.addEmailAddressFromGrid('copy')}
                                            // onClick={() => this.props.openSearchEmailModal({field: 'to'})}
                                            onClick={() => this.handleSearchEmailModalOpen('copy')}
                                            title={t("emailSearchAddress")}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>}
                    {/* </div> */}
					
					<div className='inlineFields'>
						<div className='inputWrapper'>
							{this.renderInputField({
								name: 'subject',
								placeholder: t('theme')
							})}
						</div>
					</div>
					
					
					<div className={cx('inputWrapper', 'textArea')}>
						{/* {submitting ? <Loader withContainer/> : */}
							<Editor
								editorState={mailBody}
								wrapperClassName={cx(
                                    'draft-wrapper',
                                    { 'draft-wrapper--toolbar': toolbar },
                                    'draftWrapperToolbar',
                                )}
                                editorClassName={cx('draft-editor', 'draftEditor', {
                                    focused: isFocused,
                                })}
								toolbarClassName={'draftToolbar'}
                                // handlePastedFiles={this.handlePastedFiles}
                                handlePastedFiles={this.handlePastedFiles}  
								onEditorStateChange={this.onEditorStateChange}
								blockRenderMap={extendedBlockRenderMap}
								// toolbar={editorToolbar}
                                toolbar={this.editorToolbar}
								onFocus={this.focus}
								onBlur={this.blur}
							/>
						{/* } */}
					</div>
					
					{attachmentFiles && attachmentFiles.length > 0 && (
						<div className='commentAttachments'>
							{attachmentFiles.map(this.renderAttachment)}
						</div>
					)}
					
					{uploadingFile && <Progressbar className='progressBar' />}
					
					
				</div>
			);
		}
		return null;
		
	}
}

MailingSendForm.propTypes = {
    refresh: PropTypes.func,
    onUploadFiles: PropTypes.func,
    onSendForm: PropTypes.func,
    onClickTemplate: PropTypes.func,
    onMaximize: PropTypes.func,
    initialValues: PropTypes.object,
    mailBody: PropTypes.string,
    attachmentFiles: PropTypes.array,
    sendFormOpening: PropTypes.bool,
    expand: PropTypes.bool,
    onClickClose: PropTypes.func,
};
