import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import cx from 'classnames';

import modalStyles from 'styles/modules/modal.module.scss';
import { Field } from 'redux-form';
import DatePicker from 'components/Common/DatePicker';
import { reduxFormWrapper } from 'helpers';
import validate from './validate';
import ComboBox from 'components/Common/ComboBox';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import TextArea from 'components/Common/TextArea';
import { ModalFields } from './constants';

const formatters = {
	[ModalFields.TIMER_DATE]: value => value.valueOf(),
	[ModalFields.NEXT_STATUS]: status => status.value,
};

@withTranslation()
@withRouter
@reduxFormWrapper({ form: 'appeal-status-form', validate })
class StatusModal extends React.Component {
	
	constructor (props) {
		super(props);
		
		this.onSubmit = this.onSubmit.bind(this);
		this.getStatusOptions = this.getStatusOptions.bind(this);
	}
	
	onSubmit (values) {
		const formattedValues = {};
		
		this.props.fieldList.forEach(field => {
			const formatter = formatters[field];
			formattedValues[field] = formatter ? formatter(values[field]) : values[field];
		});
		
		this.props.saveChanges(formattedValues);
	};
	
	getStatusOptions () {
		return this.props.nextStatusList
			.filter(status => !['wait', 'pause'].includes(status.code)) // dunno why it was hardcoded by ademchenko
			.map(status => ({ label: status.name, value: status.code }));
	}
	
	render () {
		const { handleSubmit, t, onClose, fieldList, invalid, pristine } = this.props;
		
		const renderTimerFields = fieldList.includes(ModalFields.TIMER_DATE) && fieldList.includes(ModalFields.NEXT_STATUS);
		const renderCommentField = fieldList.includes(ModalFields.COMMENT);
		
		const submitButtonClassName = cx('btn', 'btn-primary', 'submitButton', {
			['btn-disabled']: invalid || pristine
		});
		
		return (
			<ModalPortal
				onClose={onClose}
				className='modal-medium'
			>
				<header className={modalStyles.modalHeader}>
					<div className={modalStyles.modalTitle}>
						{t('appealStatuses.statusChange')}
					</div>
				</header>
				<form className='deferredModalContent' onSubmit={handleSubmit(this.onSubmit)}>
					{
						renderTimerFields &&
						<div className='deferredFormRow'>
							<div className='formInputWrapper'>
								<Field
									name={ModalFields.TIMER_DATE}
									component={DatePicker}
									label={t('appealStatuses.validTo')}
									required
								/>
							</div>
							<div className='formInputWrapper'>
								<Field
									name={ModalFields.NEXT_STATUS}
									component={ComboBox}
									label={t('appealStatuses.nextStatus')}
									options={this.getStatusOptions()}
									required
								/>
							</div>
						</div>
					}
					{
						renderCommentField &&
						<div className='deferredFormRow'>
							<Field
								name={ModalFields.COMMENT}
								component={TextArea}
								label={t('appealStatuses.comment')}
								maxLength={1000}
								required
							/>
						</div>
					}
					
					<button className={submitButtonClassName} disabled={invalid || pristine}>
						{t('appealStatuses.submit')}
					</button>
				</form>
			
			</ModalPortal>
		);
	}
}

StatusModal.propTypes = {
	onClose: PropTypes.func,
	saveChanges: PropTypes.func,
	showNotification: PropTypes.func,
	nextStatusList: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string,
		name: PropTypes.string,
		isDefault: PropTypes.bool
	})),
	fieldList: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default StatusModal;
