import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from 'styles/modules/notification.module.scss';

const AppealChangeSuccessNote = ({ appealId }) => {
	const { t } = useTranslation();
	
	return (
		<div className={styles.appealChangesSuccessNote}>
			<i className='icon-check-circle' />
			<span>{t('notification.changes')}
				<Link
					className={styles.appealID}
					to={`/appeals/${appealId}/`}
				>
					{appealId}
				</Link> {t('notification.saved')}
			</span>
		</div>
	)
};

AppealChangeSuccessNote.propTypes = {
	appealId: PropTypes.number,
	customerId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

export default AppealChangeSuccessNote;
