import React from 'react';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
// import TabContent from './TabContent';
import Avatar from 'components/Avatar';
import EditCustomerNameForm from './EditCustomerNameForm';
import Loader from 'components/Loader';
import Popup from 'components/Popup';
import {
    customerEdit,
    getCustomer,
    updateCustomerTabContent,
    getBillingCards,
    resetBillingCards,
    setCustomerGridRefresh,
} from 'actions/customer';
import { createNewAppeal } from 'modules/appeal/actions';
import {
    openCreateNewContactPersonModal,
    openCustomerAddressModal,
    openCustomerServiceModal,
    openCustomerResourceModal,
    openCreateNewOrderModal,
    openCreateAppealFromFileModal,
} from 'actions/ui';
import NewEntityButton from './NewEntityButton';
import CustomerAddressModal from 'components/CustomerAddressModal';
import { RouteWithTabs, isOrganization, isServiceobjectType } from 'helpers';
import CustomerServiceModal from 'components/CustomerServiceModal';
import CustomerResourceModal from 'components/CustomerResourceModal';
import Tab from 'components/Tab';
import TabContent from './TabContent/index';
import permissions, { checkPermissions } from 'config/permissions';
import tabsParams from 'constants/tabsParams';
import downloadCSV from 'actions/downloadCSV';
import { setTabName } from 'actions/tabs';
import { extractCustomerFromState } from 'helpers';
// import HistoryTab from '../HistoryTab/';
import baseService from '../../services/BaseService';
import { getLanguage } from '../../util/i18n';

const HIDE_SENSITIVE_TABS = false;

const SENSITIVE_TABS = [
    'billing_house',
    'billing_cards',
    'billing_finstatus',
    'billing_payments',
    'billing_recalculations',
];

const LS_TAB_KEY = (type) => `config_tabs_customer_${type}`;

const storedConfigTabs = type => JSON.parse(localStorage.getItem(LS_TAB_KEY(type)));

function getTabsWithPermissions(tabs) {
    if (!tabs) {
        return [];
    }
    return tabs.filter(tab => {
        // console.log({operations: tab.viewOperations});
        if (!tab.viewOperations || tab.viewOperations.length === 0) {
            return tab;
        }
        const isPermissionOk = tab.viewOperations.some(permission => checkPermissions(permission));
        return isPermissionOk;
    });
}

function mapStateToProps(state, props) {
    const customer = extractCustomerFromState(state, props.match.params.id || props.id);
    // const {match: {
    //     params: { serviceId, appealId, orderId, resourceId, id },
    // }} = props;

    // const itemId = serviceId || appealId || orderId || resourceId;
    // const gridId = `${itemId}.${id}`;
    const gridId = state.grid && state.grid.gridKey;

    const accountNumbersCount =
        Array.isArray(state.customer.billingCards) && state.customer.billingCards.length;

    return {
        id: props.match.params.id || props.id,
        showCustomerAddressModal: state.ui.showCustomerAddressModal,
        showCustomerServiceModal: state.ui.showCustomerServiceModal,
        showCustomerResourceModal: state.ui.showCustomerResourceModal,
        contactPersons: customer.contactPersons,
        pApartId: customer.currentCustomer && customer.currentCustomer.externalId,
        customerType: props.customerType || customer.currentCustomer.party.partyType,
        isCustomerManageContactPersons: checkPermissions(
            permissions.ContactPersonOperations.action_create_contactPerson,
        ),
        isViewAddress: checkPermissions(permissions.CustomerOperations.view_addresses),
        isEditAddress: checkPermissions(permissions.CustomerOperations.action_manageAddresses),
        isEditCustomer: checkPermissions(permissions.CustomerOperations.action_editCustomer),
        isCustomerCreationAllowed: checkPermissions(
            permissions.CustomerOperations.action_createCustomer,
        ),
        currentTab: state.tabs.current,
        isOrderCreate: checkPermissions(permissions.OrderOperations.action_createOrder),
        isOrderView: checkPermissions(permissions.CustomerOperations.view_Item),
        isServiceView: checkPermissions(permissions.ServiceOperations.view_service),
        isServiceCreate: checkPermissions(permissions.ServiceOperations.add_service),
        isResourceView: checkPermissions(permissions.ServiceOperations.view_resource),
        isResourceCreate: checkPermissions(permissions.ServiceOperations.add_resource),
        isBillingView: checkPermissions(permissions.BillOperations.view_BillInfo),
        isEventsView: checkPermissions(permissions.CalendarOperations.view_calendar_event),
        // gridSort: _.get(state, `grid[customer_appeals_${props.match.params.id || props.id}].params.sort`),
        grid: _.get(state.grid, gridId),
        // gridSort: _.get(state, `grid[customer_appeals_${gridId}].params.sort`),
        isContactsPersonView: checkPermissions(permissions.CustomerOperations.view_contactPersons),
        isCreateAppealFromExcel: checkPermissions(
            permissions.AppealOperations.action_createAppealGroupFromClient,
        ),
        accountNumbersCount,
        customerGridRefresh: state.customer.gridRefresh,
        isCustomerUpdating: customer.editing || customer.customerLoading
    };
}

const mapDispatchToProps = {
    customerEdit,
    getCustomer,
    createNewAppeal,
    openCreateNewContactPersonModal,
    openCustomerAddressModal,
    openCustomerServiceModal,
    openCustomerResourceModal,
    updateCustomerTabContent,
    setTabName,
    openCreateNewOrderModal,
    openCreateAppealFromFileModal,
    getBillingCards,
    resetBillingCards,
    setCustomerGridRefresh,
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gridPopupIsOpen: false,
            showEntityButton: false,
            configTabs: getTabsWithPermissions(storedConfigTabs(this.props.customerType) || []),
            isEditingName: false,
        };
        // console.log({stored: storedConfigTabs(this.props.customerType) || []});
        this.wait = false;
        this.export = this.export.bind(this);
        this.getConfigTabs = this.getConfigTabs.bind(this);
        this.checkBillingCards = this.checkBillingCards.bind(this);
        this.setIsEditingName = this.setIsEditingName.bind(this);
    }

    componentDidMount() {
        const {
            currentTab,
            setTabName,
            customerName,
            isBillingView,
            pApartId,
            customerId,
        } = this.props;
        const { configTabs } = this.state;
        if (!currentTab.displayedName) {
            setTabName(currentTab.name, customerName);
        }

        this.checkSensitiveTabs();


        if (!configTabs || configTabs.length === 0) {
            this.getConfigTabs();
        }

        // check if billing is inside of config;
        if (configTabs && configTabs.length > 0) {
            this.checkBillingCards(isBillingView, configTabs, pApartId, customerId);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isBillingView,
            pApartId,
            getBillingCards,
            resetBillingCards,
            customerId,
        } = this.props;

        if (prevProps.pApartId !== pApartId) {
            if (isBillingView) return getBillingCards(pApartId, customerId);

            return resetBillingCards();
        }

        this.checkSensitiveTabs();
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        const {
            // customerType,
            match,
            // isCustomerCreationAllowed,
            // isEditAddress,
            // isServiceCreate,
            // isResourceCreate,
            // isCustomerManageContactPersons,
        } = nextProps;
        const tabName = match.params.subTab;

        const { configTabs } = nextState;

        const activeTab = configTabs.find((tab) => tab.code === tabName);

        // new entity button is currently supported by appeals, billing, history, events and contracts tabs
        if (!activeTab) {
            return { showEntityButton: false };
        }
        // hide entity buttons for proxyGrid since it is not yet supported dynamic entity stuff
        if (activeTab && activeTab.type === 'proxyGrid') {
            return { showEntityButton: false };
        }
        // if there is no buttonName do not display new entity button
        if (activeTab && !activeTab.rightUpButtonName) {
            return { showEntityButton: false };
        }
        const isPermissionOk = activeTab.rightUpButtonOperations.some(permission => checkPermissions(permission));
        // console.log({activeTab, isPermissionOk});
        if (isPermissionOk) {
            return { showEntityButton: true };
        }
        return { showEntityButton: false };
        // old logic
        // if (customerType && tabName) {
        //     if (
        //         ((isOrganization(customerType) || isServiceobjectType(customerType)) &&
        //             tabName === 'appeals') ||
        //         tabName.includes('billing') ||
        //         tabName === 'history' ||
        //         tabName === 'events' ||
        //         tabName === 'contracts'
        //     ) {
        //         return { showEntityButton: false };
        //     } else if (tabName === 'address') {
        //         return { showEntityButton: isEditAddress };
        //     } else if (tabName === 'services') {
        //         return { showEntityButton: isServiceCreate };
        //     } else if (tabName === 'resources') {
        //         return { showEntityButton: isResourceCreate };
        //     } else {
        //         return { showEntityButton: isCustomerManageContactPersons };
        //     }
        // }
        // return null;
    }

    checkBillingCards(isBillingView, tabs,  pApartId, customerId) {
        const isBillingInConfig = tabs.find(tab => tab.code === 'billing_cards');
        if (isBillingView && isBillingInConfig) {
            this.props.getBillingCards(pApartId, customerId);
        }
    }

    getConfigTabs() {
        baseService
            .get('config_tabs', {
                pathParams: { objectType: 'customer' },
                data: { lang: getLanguage(), specCode: this.props.customerType },
            })
            .then(({ success, result }) => {
                if (success) {
                    const sortedResult = result.sort((a, b) => {
                        return a.order - b.order;
                    });
                    const { isBillingView, pApartId, customerId } = this.props;
                    this.checkBillingCards(isBillingView, sortedResult, pApartId, customerId);
                    localStorage.setItem(LS_TAB_KEY(this.props.customerType), JSON.stringify(sortedResult));
                    this.setState({ configTabs: getTabsWithPermissions(sortedResult) });
                }
            });
    }

    checkSensitiveTabs() {
        if (HIDE_SENSITIVE_TABS) {
            const params = this.props.match && this.props.match.params;
            if (params && params.subTab && SENSITIVE_TABS.includes(params.subTab)) {
                const path = this.props.history.location.pathname;
                let redirectTab = null;

                if (params.orderId) {
                    redirectTab = 'tasks';
                }

                if (params.serviceId) {
                    redirectTab = 'services';
                }

                if (!params.orderId && !params.serviceId) {
                    redirectTab = 'appeals';
                }

                if (redirectTab && this.props.history) {
                    const newPath = path.replace(/[^/]*$/, redirectTab);
                    this.props.history.push(newPath);
                }
            }
        }
    }

    toggleGridPopup = () => this.setState({ gridPopupIsOpen: !this.state.gridPopupIsOpen });

    // organization doesn't have "new appeal button"
    onNewAppealClick = () => {
        if (this.wait) return;
        this.wait = true;

        const appealRequestData = { customerId: this.props.customerId };

        this.props.createNewAppeal({ appealRequestData }).then((createdAppeal) => {
            if (createdAppeal) this.props.history.push(`/appeals/${createdAppeal.id}`);
            this.wait = false;
        });
    };

    handleNewEntityClick = () => {
        const {
            match,
            openCreateNewContactPersonModal,
            openCustomerAddressModal,
            openCustomerServiceModal,
            openCustomerResourceModal,
            openCreateNewOrderModal,
            isOrderCreate,
            customerType,
            customerId,
            customerTypeId
        } = this.props;
        const { subTab: subTabFull, id } = match.params;

        const [subTab, tabNum] = subTabFull.split("_");
    

        if (subTab === 'persons') {
            return openCreateNewContactPersonModal({
                customerId: customerId,
                customerTypeName: customerType,
                customerTypeId: customerTypeId,
                onSuccess: () => this.props.updateCustomerTabContent(customerId),
            });
        } else if (subTab === 'appeals') {
            this.onNewAppealClick();
        } else if (subTab === 'address') {
            openCustomerAddressModal();
        } else if (subTab === 'services') {
            openCustomerServiceModal();
        } else if (subTab === 'resources') {
            openCustomerResourceModal();
        } else if (subTab === 'tasks') {
            if (isOrderCreate) openCreateNewOrderModal({ openNewTab: true, customer: customerId });
        }
    };

    handleSubmit = async values => {
        const { customerEdit, getCustomer, customerId, customerType, id, currentTab, setTabName } = this.props;

        // update tab name on success customer data change
        const onGetCustomerSuccess = (result) => {
            const [, mainTab] = this.props.match.path.split("/");
            // only update tab name for customer tabs
            if (mainTab === 'customer') {
                setTabName(currentTab.name, result.name);
            }
        }
        const onSuccess = () => getCustomer({ customerId, }, customerId, true, onGetCustomerSuccess);
        if (isOrganization(customerType) || isServiceobjectType(customerType)) {
            customerEdit({
                data: {
                    customerId,
                    field: 'party.officialName',
                    text: values.name,
                },
                id,
                onSuccess,
            });
        } else {
            await customerEdit({
                data: {
                    personId: null,
                    customerId,
                    edit: [
                        { text: values.lastName, field: 'party.lastName' },
                        { text: values.firstName, field: 'party.firstName' },
                        { text: values.patronymic, field: 'party.patronymic' },
                    ],
                },
                id,
                isMulti: true,
                onSuccess,
            });
        }
    };

    export() {
        const { customerId } = this.props;
        const appealFields = Object.entries(tabsParams(this.props.t).filter).reduce(
            (acc, [key, value]) => {
                const nameParts = [key];
                if (value.objectField) nameParts.push(value.objectField);

                acc[nameParts.join('.')] = value.name;

                return acc;
            },
            {},
        );

        const requestData = {
            fields: appealFields,
            customerId: customerId,
            start: null,
            limit: null,
        };

        const gridSort = _.get(this.props.grid, 'params.sort');

        if (gridSort.field && gridSort.order) {
            requestData['sort'] = [
                {
                    property: gridSort.field,
                    direction: gridSort.order.toUpperCase(),
                },
            ];
        }
        // if (this.props.gridSort.field && this.props.gridSort.order) {
        //     requestData['sort'] = [{
        // 		property: this.props.gridSort.field,
        //         direction: this.props.gridSort.order.toUpperCase()
        //     }]
        // }

        downloadCSV({ requestData, key: 'appeal_customer_csv' }, this.toggleGridPopup);
    }

    setIsEditingName(value) {
        this.setState({isEditingName: value});
    }

    render() {
        const {
            location,
            customerName,
            customerPatronymic,
            customerLastName,
            customerFirstName,
            customerType,
            showCustomerAddressModal,
            showCustomerServiceModal,
            showCustomerResourceModal,
            t,
            contactPersons,
            match,
            isEditCustomer,
            isEditAddress,
            id,
            customerId,
            isCreateAppealFromExcel,
            accountNumbersCount,
            match: {
                url,
                params: { serviceId, appealId, orderId, resourceId },
            },
            customerGridRefresh,
            setCustomerGridRefresh,
            openCustomerAddressModal,
            isCustomerUpdating,
            pApartId,
        } = this.props;
        const tabName = match.params.subTab;
        const { gridPopupIsOpen, showEntityButton, configTabs, isEditingName } = this.state;
        const titleButton =
            (isOrganization(customerType) || isServiceobjectType(customerType)) &&
            contactPersons && contactPersons.length === 0 &&
            tabName === 'appeals'
                ? 'persons'
                : tabName;
        // const tabs = ['appeals', 'address', 'contracts', 'persons', 'history','tasks', 'services', 'resources', 'billing_house', 'contracts', 'billing_cards', 'billing_finstatus', 'billing_payments', 'billing_recalculations', 'events'];

        const activeTab = configTabs.find((tab) => tab.code === tabName);
        const itemId = serviceId || appealId || orderId || resourceId;
        const path = url.split("/");
        const itemIdIndex = path.findIndex(item => +item === +itemId);
        // console.log({path, itemId, itemIdIndex});
        const itemType = itemIdIndex ? path[itemIdIndex - 1] : "";

        const defaultTab = configTabs && configTabs[0] && configTabs[0].code;

        // if no tabName and defaultTab available from configTabs
        if (!tabName && defaultTab) {
            const newPath = location.pathname.endsWith('/')
                ? `${location.pathname}${defaultTab}`
                : `${location.pathname}/${defaultTab}`;
            return <Redirect to={newPath} />;
        }
        return (
            <main className='customerContent'>
                <header className='customerContentHeader'>
                    <div className='headerLeft'>
                        {isCustomerUpdating && <Loader />}
                        <Avatar name={customerName} customerType={customerType} handleClick={() => this.setIsEditingName(true)}/>
                        <EditCustomerNameForm
                            form={`${customerId}-edit-customer-name`}
                            isInEdit={isEditingName}
                            setIsInEdit={this.setIsEditingName}
                            onSubmit={this.handleSubmit}
                            customerType={customerType}
                            initialValues={{
                                lastName: customerLastName,
                                firstName: customerFirstName,
                                patronymic: customerPatronymic,
                                name: customerName,
                            }}
                            managedContactPerson={isEditCustomer}
                            isEditAddress={isEditAddress}
                            key={customerName}
                        />
                    </div>
                    <div className="actions">
                        {showEntityButton && (
                            <NewEntityButton
                                title={activeTab.rightUpButtonName}
                                onClick={this.handleNewEntityClick}
                            />
                        )}
                        <div className="btn-add" onClick={this.toggleGridPopup}>
                            <i className="icon-kebab-vert" />
                        </div>
                        {gridPopupIsOpen && 'appeals' === tabName && (
                            <Popup
                                place="customer-grid-popup"
                                onClickOutside={this.toggleGridPopup}
                            >
                                <button onClick={this.export} className="popup-link">
                                    {t('filters.exportExcel')}
                                </button>
                                {isCreateAppealFromExcel && (
                                    <button
                                        onClick={() =>
                                            this.props.openCreateAppealFromFileModal({ customerId })
                                        }
                                        className="popup-link"
                                    >
                                        {t('filters.createFromFile')}
                                    </button>
                                )}
                            </Popup>
                        )}
                    </div>
                </header>
                <div className="contentWrapper">
                    <div id="card-bottom-row" className="column">
                        <div className="top-right-overflow">
                            {configTabs.map((tab) => {
                                if (tab.code === 'billing_cards' && accountNumbersCount > 0)
                                    return (
                                        <Tab
                                            val={tab.code}
                                            key={tab.code}
                                            tab={tab.code}
                                            counter={`(${accountNumbersCount})`}
                                        />
                                    );
                                return (
                                    <Tab
                                        val={tab.code}
                                        tab={tab.code}
                                        name={tab.name}
                                        key={tab.code}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    {activeTab && (
                        <TabContent
                            config={activeTab}
                            id={id}
                            itemId={itemId}
                            itemType={itemType}
                            customerGridRefresh={customerGridRefresh}
                            setCustomerGridRefresh={setCustomerGridRefresh}
                            openCustomerAddressModal={openCustomerAddressModal}
                            pApartId={pApartId}
                            key={tabName}
                        />
                    )}
                </div>
                {isEditAddress && showCustomerAddressModal && <CustomerAddressModal />}
                {showCustomerServiceModal && <CustomerServiceModal />}
                {showCustomerResourceModal && <CustomerResourceModal />}
            </main>
        );
    }
}

MainContent.propTypes = {
    // ownProps
    customer: PropTypes.object,
    tabsNames: PropTypes.object,
    customerName: PropTypes.string,
    customerType: PropTypes.string,
    customerId: PropTypes.number,
    customerPatronymic: PropTypes.string,
    customerFirstName: PropTypes.string,
    customerLastName: PropTypes.string,
    // from connect mapDispatchToProps
    customerEdit: PropTypes.func,
    getCustomer: PropTypes.func,
    openGridPopUp: PropTypes.func,
};
