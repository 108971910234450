import React, { useEffect, useState, useContext, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors, formValues } from "redux-form";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import IssueForm from "./IssueForm";
import { Context } from "../index";
import BaseService from "../../../services/BaseService";
import Loader from "../../Loader";
import styles from "styles/modules/knowledgeBase.module.scss";
import permissions, { checkPermissions } from "../../../config/permissions";
import HeaderMenu from "./HeaderMenu";
import { setHistoryPushRequireSaveWithoutChangeConfirm } from "actions/ui";


const ISSUE_FORM = "issue-form";
const ISSUE_DYN_FORM = "issue-dynamic-form";

function IssueDetail(props) {
    const { formValues, formDynValues, t, setHistoryPushRequireSaveWithoutChangeConfirm } = props;
    const requestIssues = useContext(Context);
    const history = useHistory();
    // const [issue, setIssue] = useState({ objects: [{}] });
    const [issue, setIssue] = useState(null);
    const [issueIDs, setIssueIDs] = useState(null);
    const [updateCount, setUpdateCount] = useState(0);
    const [issueTypes, setIssueTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    // to prevent double issue request on path change
    // issue will be requested by the end of conditional files upload
    const [isIssueInitedAfterFileUpload, setIsIssueInitedAfterFileUpload] = useState(false);

    const issueId = issueIDs ? issueIDs.id : issue && issue[0] && issue[0].id;
    const issueProblemId = issueIDs ? issueIDs.problem_id : issue && issue[0] && issue[0].problem_id;

    const canEdit = checkPermissions(permissions.AppealOperations.action_editGlobalProblems);

    async function requestIssue(id) {
        // console.log("**** REQUEST ISSUE ****", props, id);
        setIsIssueInitedAfterFileUpload(false);
        setIssueTypes([]);
        setIssue(null);
        setLoading(true);
        const typesRequest = await BaseService.get("issues_types");
        await setIssueTypes(typesRequest.result);

        if (id) {
            // console.log("**** GET ISSUE ****");
            const issueRequest = await BaseService.get("issue", {
                // pathParams: { id: props.match.params.id },
                pathParams: { id },
            });
            // console.log("**** SET ISSUE ****");
            await setIssue({
                ...issue,
                ...issueRequest.result,
            });
        }

        setLoading(false);
    }

    async function onSubmit(e) {
        e.preventDefault();
        const {
            state_code,
            files,
            // problem_id,
            problem_name,
            problem_type,
            problem_description,
            plan_start_date,
            plan_end_date,
            chanel,
        } = formValues;

        const problem_id = issue && issue[0] && issue[0].problem_id;

        const requestData = {
            state_code,
            problem_id: problem_id || null,
            problem_name,
            problem_type,
            problem_description,
            plan_start_date,
            plan_end_date,
            chanel,
        };

        let objects = [];
        Object.keys(formDynValues).forEach(key => {
            const objectValue = formDynValues[key];
            if (Array.isArray(objectValue) && objectValue.length > 0) {
                const newObject = {
                    type: key,
                    fields: objectValue.map(objVal => {
                        // console.log({ objVal });
                        return Object.keys(objVal).reduce((acc, curr) => {
                            const val = objVal[curr];
                            // object => return default {label, value} provided in saved values
                            if (typeof val === "object") {
                                return {
                                    ...acc,
                                    [curr]: val,
                                };
                            }
                            // primitive value => provide val as both label and value
                            return {
                                ...acc,
                                [curr]: {
                                    label: val,
                                    value: val,
                                },
                            };
                        }, {});
                    }),
                };
                objects.push(newObject);
            }
        });

        // add objects to the request data
        requestData.objects = objects;

        // handle try-catch there
        try {
            const { result, success, errorMessage } = await BaseService.put("issue_change", {
                data: requestData,
                jsonType: true,
            });
            if (success && result) {
                setHistoryPushRequireSaveWithoutChangeConfirm(false);
                requestIssues();
                if (problem_id) {
                    await requestIssue(problem_id);
                    setUpdateCount(prev => prev + 1);
                } else {
                    if (result) {
                        if (files && files.length > 0) {
                            // to prevent double issue request on path change
                            // issue will be requested by the end of conditional files upload
                            setIsIssueInitedAfterFileUpload(true);
                            // setLoading(true);
                        }
                        setIssueIDs(result);
                    }
                }
            }
            if (!success) {
                throw new Error(errorMessage || `Result is ${result}`);
            }
        } catch (e) {
            console.error("IssueDetail::onSubmit: Error: ", e);
        }
    }

    // complicated logic to trigger correctly conditioned files upload to the issue
    useEffect(() => {
        if (issueIDs) {
            history.push(`/issue/${issueIDs.problem_id}`);
            setIssueIDs(null);
        }
    }, [issueIDs]);

    useEffect(() => {
        if (!isIssueInitedAfterFileUpload) {
            requestIssue(props.match.params.id);
        }
    }, [props.match.params.id]);

    if (loading) return <Loader withContainer />;

    return (
        <Fragment>
            <div className={cn(styles.mainHeader, "issue-form-header")}>
                <span>{issue ? issue.problem_name : t("issues.create")}</span>
                {props.match.params.id && <HeaderMenu id={props.match.params.id} canEdit={canEdit} setHistoryPushRequireSaveWithoutChangeConfirm={setHistoryPushRequireSaveWithoutChangeConfirm} />}
            </div>
            <IssueForm
                id={props.match.params.id}
                issue={issue && issue[0]}
                issueId={issueId}
                isLoading={loading}
                initialValues={issue}
                issueTypes={issueTypes}
                submitForm={onSubmit}
                staticFormKey={ISSUE_FORM}
                dynamicFormKey={ISSUE_DYN_FORM}
                disabled={!canEdit}
                requestIssue={() => requestIssue(issueProblemId)}
                key={updateCount}
            />
        </Fragment>
    );
}

const mapStateToProps = state => ({
    formValues: getFormValues(ISSUE_FORM)(state),
    formErrors: getFormSyncErrors(ISSUE_FORM)(state),
    formDynValues: getFormValues(ISSUE_DYN_FORM)(state),
    formDynErrors: getFormSyncErrors(ISSUE_DYN_FORM)(state),
});

export default withTranslation()(connect(mapStateToProps, { setHistoryPushRequireSaveWithoutChangeConfirm })(IssueDetail));
