import { connect } from 'react-redux';
import AudioPlayer from 'components/Common/AudioPlayer';
import { hideAudioPlayer } from 'actions/audioplayer';

const mapStateToProps = state => ({
	source: state.audioplayer.source,
	title: state.audioplayer.title,
	currentSecond: state.audioplayer.currentSecond,
	autoplay: state.audioplayer.autoplay,
	hidden: state.audioplayer.hidden,
	position: state.audioplayer.position
});

const mapDispatchToProps = dispatch => ({
	onClose: () => dispatch(hideAudioPlayer())
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
