import React from 'react';

export default function PriorityArrowsIcons ({ id, title }) {
	switch (String(id)) {
	case '1':
		//red "↑"
		return (
			<div className='grid-icon-level' title={title}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='11px' height='13px'>
					<path fillRule='evenodd' fill='rgb(222, 76, 35)'
						  d='M10.149,5.137 C10.149,5.137 5.982,-0.020 5.300,0.000 C4.683,0.018 0.557,5.122 0.557,5.122 C0.454,5.243 0.402,5.363 0.402,5.484 C0.402,5.625 0.449,5.741 0.542,5.832 C0.635,5.922 0.753,5.968 0.898,5.968 L3.242,6.016 L3.242,11.030 C3.242,11.415 3.654,11.983 4.271,12.033 L6.329,12.033 C6.932,12.016 7.392,11.504 7.358,11.030 L7.358,6.016 L9.792,5.968 C10.019,5.968 10.174,5.867 10.257,5.666 C10.340,5.474 10.304,5.298 10.149,5.137 L10.149,5.137 Z' />
				</svg>
			</div>
		);
	case '2':
		//orange "↗"
		return (
			<div className='grid-icon-level' title={title}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='11px' height='11px'>
					<path fillRule='evenodd' fill='rgb(243, 140, 49)'
						  d='M10.004,7.573 C10.004,7.573 10.701,0.979 10.205,0.510 C9.757,0.087 3.247,0.777 3.247,0.777 C3.089,0.789 2.968,0.838 2.882,0.924 C2.783,1.023 2.734,1.138 2.736,1.268 C2.737,1.398 2.789,1.514 2.891,1.616 L4.510,3.309 L0.975,6.854 C0.703,7.126 0.594,7.820 0.994,8.291 L2.446,9.747 C2.883,10.162 3.569,10.126 3.879,9.766 L7.414,6.220 L9.166,7.908 C9.327,8.069 9.507,8.108 9.707,8.024 C9.901,7.947 9.999,7.797 10.004,7.573 L10.004,7.573 Z' />
				</svg>
			</div>
		);
	case '3':
		//grey "→"
		return (
			<div className='grid-icon-level' title={title}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='12px' height='10px'>
					<path fillRule='evenodd' fill='rgb(162, 170, 174)'
						  d='M6.877,9.854 C6.877,9.854 12.020,5.686 12.000,5.003 C11.982,4.386 6.892,0.258 6.892,0.258 C6.772,0.155 6.651,0.103 6.531,0.103 C6.390,0.103 6.275,0.150 6.184,0.243 C6.094,0.336 6.049,0.455 6.049,0.600 L6.000,2.944 L1.000,2.944 C0.616,2.944 0.049,3.357 -0.000,3.974 L-0.000,6.033 C0.016,6.637 0.527,7.097 1.000,7.062 L6.000,7.062 L6.049,9.498 C6.049,9.725 6.149,9.880 6.350,9.963 C6.541,10.046 6.716,10.010 6.877,9.854 L6.877,9.854 Z' />
				</svg>
			</div>
		);
	case '4':
		//black "↘"
		return (
			<div className='grid-icon-level' title={title}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='11px' height='10px'>
					<path fillRule='evenodd' fill='rgb(74, 74, 74)'
						  d='M3.237,9.298 C3.237,9.298 9.813,9.997 10.280,9.500 C10.702,9.051 10.014,2.523 10.014,2.523 C10.002,2.364 9.953,2.242 9.868,2.157 C9.769,2.057 9.654,2.008 9.525,2.010 C9.395,2.012 9.279,2.064 9.177,2.166 L7.490,3.790 L3.954,0.244 C3.682,-0.028 2.991,-0.138 2.521,0.263 L1.069,1.719 C0.655,2.158 0.691,2.845 1.050,3.156 L4.586,6.702 L2.902,8.458 C2.742,8.619 2.704,8.800 2.787,9.001 C2.864,9.194 3.014,9.294 3.237,9.298 L3.237,9.298 Z' />
				</svg>
			</div>
		);
	case '5':
		//blue "↓"
		return (
			<div className='grid-icon-level' title={title}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='11px' height='13px'>
					<path fillRule='evenodd' fill='rgb(83, 146, 215)'
						  d='M0.851,7.377 C0.851,7.377 5.018,12.534 5.700,12.514 C6.317,12.496 10.443,7.392 10.443,7.392 C10.546,7.271 10.598,7.150 10.598,7.029 C10.598,6.888 10.551,6.773 10.458,6.682 C10.365,6.591 10.247,6.546 10.102,6.546 L7.758,6.497 L7.758,1.484 C7.758,1.098 7.346,0.530 6.729,0.481 L4.671,0.481 C4.068,0.497 3.608,1.009 3.642,1.484 L3.642,6.497 L1.208,6.546 C0.981,6.546 0.826,6.647 0.743,6.848 C0.660,7.039 0.696,7.216 0.851,7.377 L0.851,7.377 Z' />
				</svg>
			</div>
		);
	default:
		return (
			<div className='grid-icon-level' title={title} />
		);
	}
};
