import React from 'react';
import PropTypes from 'prop-types';
import SearchOption from './SearchOption';
import { createCheckPropsFunction } from 'helpers';
import debounce from 'lodash/debounce';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';

@withTranslation()
class OptionsListContainer extends React.Component {
	
	constructor (props) {
		super(props);
		
		this.state = {
			searchQuery: '',
			filteredOptions: this.filterOptions(),
		};
		this.handleSelectAllOptions = this.handleSelectAllOptions.bind(this);
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		if (isPropChanged('options')) {
			this.setState({ filteredOptions: this.filterOptions(this.state.searchQuery) });
		}
		
		if (this.props.selectedOptions.length !== prevProps.selectedOptions.length) {
			this.setState({ filteredOptions: this.filterOptions(this.state.searchQuery) });
		}
	}
	
	filterOptions (searchQuery = '') {
		return this.props.options.filter(option => {
			let isMatched = option.label.toLowerCase();
			
			if (!this.props.notFilteredOptions){
				isMatched = option.label.toLowerCase().includes(searchQuery.toLowerCase());
			}
			
			if (isMatched) {
				const foundIndex = this.props.selectedOptions.findIndex(selectedOption => selectedOption.value === option.value);
				return foundIndex === -1;
			}
			
			return false;
		});
		};
		
		onQueryChange = searchQuery => {
		this.setState({ searchQuery });
		const filteredOptions = this.filterOptions(searchQuery);
		
		this.setState({ filteredOptions });
		this.debouncedTriggerFilterOptions(searchQuery);
	};
	
	triggerFilterOptions = query => {
		const { async } = this.props;
		
		if (async && query.trim()) {
			async.callback(query, async.code, async.id);
		}
		
	};
	
	debouncedTriggerFilterOptions = debounce(this.triggerFilterOptions, SEARCH_TIMER_INTERVAL);
	
	onSelect = option => {
		const foundIndex = this.props.selectedOptions.findIndex(selectedOption => selectedOption.value === option.value);
		
		if (foundIndex !== -1) return;
		this.props.onSelect({ label: option.label, value: option.value });
	};
	
	renderOption = (option, idx) => {
		const onClick = () => this.onSelect(option);
		return (
			<div
				className={'option'}
				key={`${option.value}${idx}`}
				onClick={onClick}
				title={option.label}
			>
				{option.label}
			</div>
		);
	};
	
	handleSelectAllOptions (event) {
		event.preventDefault();
		event.stopPropagation();
		const { filteredOptions } = this.state;
		const { onMultiSelect } = this.props;
		
		Boolean(filteredOptions.length) ? onMultiSelect(filteredOptions) : null;
	}
	
	render () {
		const { isOpen, containerRef, t, disallow_select_all } = this.props;
		const { searchQuery, filteredOptions } = this.state;
		const selectAllBtnClassName = cx('btn btn-primary select-all', { 'disabled': !Boolean(filteredOptions.length) });
		if (!isOpen) return null;
		
		return (
			<div className={'options-container'} ref={containerRef}>
				<SearchOption
					query={searchQuery}
					onChange={this.onQueryChange}
				/>
				<div className={'options-wrapper'}>
					<div className='option-btn'>
							{this.props.renderClearButton}
					</div>
					{!disallow_select_all &&
					<div className='option-btn'>
						<button
							className={selectAllBtnClassName}
							onClick={this.handleSelectAllOptions}
						>
							{t('select all results')}
						</button>
					</div>
					}
					{filteredOptions.map(this.renderOption)}
				</div>
			</div>
		);
	}
}

OptionsListContainer.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		label: PropTypes.string
	})),
	isOpen: PropTypes.bool,
	containerRef: PropTypes.object,
	selectedOptions: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		label: PropTypes.string
	})),
	onSelect: PropTypes.func,
};

export default OptionsListContainer;
