import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { change, destroy } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { get } from 'lodash';
import BaseService from '../../../services/BaseService';

import Loader from 'components/Loader';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';

import IssuesWidgetList from './IssuesWidgetList';
import CustomerAddressModal from './CustomerAddressModal';
import NotDefinedClient from './NotDefinedClient';
import AdvancedSearchModal from './AdvancedSearchModal';
import { getCustomer, getContactPerson, clearEntireCustomerState } from 'actions/customer';
import { setTabName } from 'actions/tabs';
import {
    getAppealFeedback,
    clearAppealFeedback,
    removeCustomerFromAppeal,
    addCustomerToAppeal,
} from 'modules/appeal/actions';
import { openAdvancedSearchModal, closeAdvancedSearchModal } from 'actions/client';
import ClientInfo from './ClientInfo/';

import {
    extractCustomerFromState,
    extractAppealFromState,
    searchCustomersInAppeals,
    customerFormNameBuilder,
} from 'helpers';

import permissions, { checkPermissions } from 'config/permissions';

import 'pages/Appeal/styles.scss';
import './styles.scss';
import '../styles/styles.scss';


const LOCK_SOURCE = "customer";

const mapStateToProps = (state, props) => {
    let {
        match: { url },
    } = props;
    // get customer and contactPerson ids from path;
    let customerId;
    let contactPersonId;
    const splittedUrl = url.split('/');
    const urlCustomerIndex = splittedUrl.findIndex((item) => item === 'customer');
    if (urlCustomerIndex !== -1) {
        customerId = splittedUrl[urlCustomerIndex + 1];
    }
    const urlContactPersonIndex = splittedUrl.findIndex((item) => item === 'person');
    if (urlContactPersonIndex !== -1) {
        contactPersonId = splittedUrl[urlContactPersonIndex + 1];
    }

    const [appealState] = extractAppealFromState(state, props);
    const appeal = get(appealState, 'currentAppeal');
    if (appeal) {
        customerId = appeal.customerId;
        contactPersonId = appeal.contactPersonId;
    }

    const contactPerson = extractCustomerFromState(state, contactPersonId);
    const customer = extractCustomerFromState(state, customerId);

    const appealRestriction = get(appeal, 'restriction');

    // console.log({ customer });

    // if (customer) {
    //     console.log({
    //         contactPersonFetching: customer.contactPersonFetching,
    //         contactPersonLoading: customer.contactPersonLoading,
    //         customerLoading: customer.customerLoading,
    //     });
    // }

    return {
        // contactPerson
        contactPersonId,
        contactPerson: get(customer, 'currentContactPerson'),
        contactPersonLoading: get(customer, 'contactPersonLoading'),
        contactPersonModificationCount: get(contactPerson, 'contactPersonModificationCount'),

        // customer
        customerId,
        customer: get(customer, 'currentCustomer'),
        customerLoading: get(customer, 'customerLoading'),
        customerModificationCount: get(customer, 'modificationCount'),

        // appeals part
        appeals: state.appeal.appeals,
        appeal,
        showAdvancedSearchModal: state.client.showAdvancedSearchModal,
        showCreateNewCustomerModal: get(state, 'ui.showCreateNewCustomerModal'),

        incomePhoneNumber: state.call.incomePhoneNumber,
        createdAppeals: state.call.createdAppeals,

        isHideCustomerPanel: appealRestriction && appealRestriction.customerId === 0,
        isReadOnlyCustomerPanel: appealRestriction && appealRestriction.customerId === 1,
        isRestrictedChangeCustomer: appealRestriction && appealRestriction.remove_customer === 0,

        // issues
        // isIssuesEnabled: checkPermissions(permissions.AppealOperations.action_viewGlobalProblems),
        isIssuesEnabled: checkPermissions(permissions.CustomerOperations.action_viewGlobalProblems),
        isEditCustomer: checkPermissions(permissions.CustomerOperations.action_editCustomer),
        isEditContactPerson: checkPermissions(
            permissions.ContactPersonOperations.action_edit_contactPerson,
        ),

        currentTab: state.tabs.current,

        customerLiveSavingInProcess: state.customer.liveSavingInProcess[customerId],
        contactPersonLiveSavingInProcess: state.customer.liveSavingInProcess[contactPersonId],
    };
};

const mapDispatchToProps = {
    getCustomer,
    getContactPerson,
    openAdvancedSearchModal,
    closeAdvancedSearchModal,
    change,
    destroy,
    clearEntireCustomerState,
    clearAppealFeedback,
    removeCustomerFromAppeal,
    addCustomerToAppeal,
    getAppealFeedback,
    setTabName,
};

/* PERMISSIONS
        isViewAddress: checkPermissions(permissions.CustomerOperations.view_addresses),
        isEditAddress: checkPermissions(permissions.CustomerOperations.action_manageAddresses),
        isEditCustomer: checkPermissions(permissions.CustomerOperations.action_editCustomer),
*/


const CustomerPanel = (props) => {
    const {
        t,
        history,

        contactPersonId,
        contactPerson,
        contactPersonLoading,
        contactPersonModificationCount,
        getContactPerson,

        customerId,
        customer,
        customerLoading,
        customerModificationCount,
        getCustomer,

        openAdvancedSearchModal,
        closeAdvancedSearchModal,
        change,
        destroy,
        clearEntireCustomerState,
        clearAppealFeedback,
        removeCustomerFromAppeal,
        addCustomerToAppeal,
        showAdvancedSearchModal,
        showCreateNewCustomerModal,

        appeal,
        appeals,
        incomePhoneNumber,
        createdAppeals,
        getAppealFeedback,
        isIssuesEnabled,

        isHideCustomerPanel,
        isReadOnlyCustomerPanel,
        isRestrictedChangeCustomer,
        setTabName,
        currentTab,

        isEditCustomer,
        isEditContactPerson,

        onAppealDestinationsReload,

        unlockAppealForm,
        removeAppealLockSource,

        isLockedByOther,
        isLockingAppeal,

        customerLiveSavingInProcess,
        contactPersonLiveSavingInProcess
    } = props;

    const [warningPopupOpen, setWarningPopupOpen] = useState(false);
    const [isCustomerAdding, setIsCustomerAdding] = useState(false);

    const [issues, setIssues] = useState({});

    const showIssueWidget = isIssuesEnabled && customerId && issues[customerId];

    const handleCloseAdvancedSearchModal = () => {
        if (removeAppealLockSource && typeof removeAppealLockSource === 'function') {
            removeAppealLockSource(LOCK_SOURCE);
        }
        closeAdvancedSearchModal();
    };

    const getIssuesForObject = (id) => {
        if (isIssuesEnabled) {
            BaseService.get('issues_for_object', { data: { object_uid: `customer.${id}` } })
                .then(({ success, result }) => {
                    if (success) {
                        if (result && result.length > 0) {
                            setIssues((prev) => ({ ...prev, [id]: result }));
                        }
                    }
                })
                .catch((e) => {
                    console.error('CustomerPanel::getIssuesForObject: error', e);
                });
        }
    };

    const openCustomerWarningPopUp = () => {
        setWarningPopupOpen(true);
    };
    const closeCustomerWarningPopUp = () => {
        if (removeAppealLockSource && typeof removeAppealLockSource === 'function') {
            removeAppealLockSource(LOCK_SOURCE);
        }
        setWarningPopupOpen(false);
    };

    const redirectToAppealFromCustomer = () => {
        const splittedHistory = props.history.location.pathname.split('/');
        const url = props.match.url.split('/')[2];
        if (splittedHistory[4]) {
            props.history.push(`/appeals/${url}`);
        }
    };

    const unlinkCustomer = async () => {
        const appealId = appeal.id;

        const deleteResult = await BaseService.delete('appeal_customer', {
            pathParams: { appealId },
        });
        if (deleteResult.success) {
            redirectToAppealFromCustomer();
            if (searchCustomersInAppeals(appeals, customerId).length === 1) {
                clearEntireCustomerState(customerId);
                setTimeout(() => {
                    customerFormNameBuilder(customerId).forEach((name) => destroy(name));
                }, 1000);
            }
            clearAppealFeedback(appealId);
            removeCustomerFromAppeal(appealId);
            change(`appeal-form-${appealId}`, 'destinationFeedback', []);
        }
        closeCustomerWarningPopUp();
    };

    const onSelectCustomer = async (applicant) => {
        setIsCustomerAdding(true);
        const appealId = appeal.id;
        const { id, partyType, type } = applicant;
        let customerId = id;
        let contactPersonId = 0;
        if (type === 'contact_person') {
            customerId = applicant.customerId;
            contactPersonId = applicant.id;
        }

        const data = { customerId, contactPersonId };
        if (type === 'customer' && partyType !== 'individual') {
            return null;
        }
        const addCustomerResult = await BaseService.post('appeal_customer', {
            pathParams: { appealId },
            data,
            jsonType: true,
        });

        if (addCustomerResult.success) {
            if (removeAppealLockSource && typeof removeAppealLockSource === 'function') {
                removeAppealLockSource(LOCK_SOURCE);
            }

            // redirect back to appeals once new customer is linked
            redirectToAppealFromCustomer();

            addCustomerToAppeal(appealId, customerId, contactPersonId);

            getCustomer({ customerId }, customerId);
            if (contactPersonId) {
                // getContactPerson({ id: contactPersonId }, contactPersonId);
                getContactPerson({ id: contactPersonId }, customerId);
            }

            const feedback = await getAppealFeedback({ appealId, customerId: customerId });
            const appealInCallInteraction = createdAppeals.find(
                (i) => parseInt(i.id) === parseInt(appealId),
            );
            let selectedContacts = feedback.flatMap((i) => i.selectedContacts);

            if (incomePhoneNumber && appealInCallInteraction) {
                const contact = feedback
                    .flatMap((i) => i.contacts)
                    .find((contact) => contact.value === incomePhoneNumber);

                if (contact) {
                    selectedContacts = [...selectedContacts, contact.id];
                }
            }

            change(`appeal-form-${appealId}`, 'destinationFeedback', selectedContacts);
        }
        setIsCustomerAdding(false);
    };

    const onCreateCustomer = async (customerId, personId = 0) => {
        setIsCustomerAdding(true);
        // clear old customers
        if (searchCustomersInAppeals(appeals, customerId).length === 1) {
            clearEntireCustomerState(customerId);
            setTimeout(() => {
                customerFormNameBuilder(customerId).forEach((name) => destroy(name));
            }, 1000);
        }
        // redirect back to appeals once new customer is linked
        redirectToAppealFromCustomer();
        const appealId = appeal.id;
        const onSuccessData = { customerId, contactPersonId: personId };
        const addCustomerResult = await BaseService.post('appeal_customer', {
            pathParams: { appealId },
            data: onSuccessData,
            jsonType: true,
        });
        if (addCustomerResult.success) {
            // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
            if (removeAppealLockSource && typeof removeAppealLockSource === 'function') {
                removeAppealLockSource(LOCK_SOURCE);
            }
        }
        if (personId && addCustomerResult.success) {
            addCustomerToAppeal(appealId, customerId, personId);
        }
        setIsCustomerAdding(false);
    };

    const getMainCustomerActions = () => {
        const actions = [];
        const isCustReq = appeal && appeal.requiredObjects.includes('CUSTOMER');
        const isNotRestricted = !isRestrictedChangeCustomer && !isReadOnlyCustomerPanel;
        if (appeal && isNotRestricted && !isLockedByOther) {
            actions.push({
                title: t('linkOtherClient'),
                action: () => {
                    if (unlockAppealForm && typeof unlockAppealForm === 'function') {
                        // lock appeal form on starting customer search
                        unlockAppealForm(LOCK_SOURCE);
                    }
                    openAdvancedSearchModal(onCreateCustomer);
                },
            });
        }
        if (appeal && isNotRestricted && !isCustReq && !isLockedByOther) {
            actions.push({
                title: t('unlinkClient'),
                action: () => {
                    if (unlockAppealForm && typeof unlockAppealForm === 'function') {
                        // lock appeal form on starting customer search
                        unlockAppealForm(LOCK_SOURCE);
                    }
                    openCustomerWarningPopUp();
                },
            });
        }
        return actions;
    };

    useEffect(() => {
        // refetch either contactPerson or customer depending on type
        if (customer && customerModificationCount > 0) {
            getCustomer({ customerId: customer.id }, customer.id, true);
        }
    }, [customerModificationCount]);

    useEffect(() => {
        // refetch either contactPerson or customer depending on type
        if (contactPerson && contactPersonModificationCount > 0) {
            getContactPerson({ id: contactPersonId }, customerId, true);
        }
    }, [contactPersonModificationCount]);

    // handle appeal customer/contact persons on mounted
    useEffect(() => {
        // clear customer state on contactPersonId change
        return () => {
            clearEntireCustomerState(customerId);
        };
    }, [contactPersonId]);

    useEffect(() => {
        // console.log({customerId, issues});
        if (customerId && !issues[customerId]) {
            getIssuesForObject(customerId);
        }
        return () => {
            const clonedIssues = { ...issues };
            delete clonedIssues[customerId];
            setIssues(clonedIssues);
            // clear customer state on customerId change;
            clearEntireCustomerState(customerId);
        };
    }, [customerId]);

    useEffect(() => {
        // handle appeal/customer customers on customerId change
        if (customerId && !customer) {
            // make additional check to not fetching a customer until some liveSaving is in process
            const isSomeFieldLiveSaving = customerLiveSavingInProcess && customerLiveSavingInProcess.length > 0;
            if (isSomeFieldLiveSaving) {
                return;
            }
            getCustomer({ customerId: customerId }, customerId, true);
        }
        if (customer) {
            // only update tab name for customer tabs
            const [, mainTab] = props.match.path.split('/');
            if (mainTab === 'customer') {
                const customerName =
                    get(customer, 'party.fullName') || get(customer, 'party.officialName');
                if (
                    customerName &&
                    currentTab.type === `customer_${customerId}` &&
                    currentTab.displayName !== customerName
                ) {
                    setTabName(currentTab.name, customerName);
                }
            }
        }
    }, [customerId, customer, customerLiveSavingInProcess]);

    useEffect(() => {
        // handle appeal/customer contact persons on contactPersonId change
        // if (contactPersonId && !contactPerson) {
        if (contactPersonId && !contactPerson) {
            // make additional check to not fetching a contact person until some liveSaving is in process
            const isSomeFieldLiveSaving = contactPersonLiveSavingInProcess && contactPersonLiveSavingInProcess.length > 0;
            if (isSomeFieldLiveSaving) {
                return;
            }
            getContactPerson({ id: contactPersonId }, customerId, true);
        }
        // update contact person of customer on id change
        if (contactPersonId && contactPerson && +contactPersonId !== +contactPerson.id) {
            getContactPerson({ id: contactPersonId }, customerId, true);
        }
        if (contactPerson) {
            const [, mainTab] = props.match.path.split('/');
            // only update tab name for customer tabs
            if (mainTab === 'customer') {
                const contactPersonName = get(contactPerson, 'party.fullName');
                if (
                    contactPersonName &&
                    currentTab.type === `person_${contactPersonId}` &&
                    currentTab.displayName !== contactPersonName
                ) {
                    setTabName(currentTab.name, contactPersonName);
                }
            }
        }
    }, [contactPersonId, contactPerson, contactPersonLiveSavingInProcess]);

    useEffect(() => {
        if (isLockedByOther) {
            handleCloseAdvancedSearchModal();
            closeCustomerWarningPopUp();
        }
    }, [isLockedByOther]);

    if (isHideCustomerPanel) {
        return null;
    }

    return (
        <div className="customer-panel-wrapper ordering-component-ui-core-wrapper">
            {showIssueWidget ? (
                <IssuesWidgetList t={t} issues={issues[customerId]} history={history} />
            ) : null}
            <div
                className={cx('customer-panel', {
                    'not-defined-client':
                        !customer && appeal && !appeal.customerId && !isCustomerAdding,
                })}
            >
                {!customer && (!appeal || isCustomerAdding) && <Loader />}
                {!customer && appeal && !appeal.customerId && !isCustomerAdding && (
                    <NotDefinedClient
                        onSelectApplicant={onSelectCustomer}
                        customerRemoveRestriction={get(appeal, 'restriction.remove_customer')}
                        unlockAppealForm={unlockAppealForm}
                        removeAppealLockSource={removeAppealLockSource}
                        showAdvancedSearchModal={showAdvancedSearchModal}
                        showCreateNewCustomerModal={showCreateNewCustomerModal}
                        isLockedByOther={isLockedByOther || isLockingAppeal}
                    />
                )}
                {contactPerson && (
                    <ClientInfo
                        client={contactPerson}
                        clientType="contactPerson"
                        dynFormObjectType="CONTACT_PERSON"
                        customerId={customerId}
                        formName={`customer-panel-main-dynamic-form-${contactPerson.id}`}
                        actions={getMainCustomerActions()}
                        outerLoading={contactPersonLoading}
                        readonly={!isEditContactPerson || isReadOnlyCustomerPanel}
                        onAppealDestinationsReload={onAppealDestinationsReload}
                        onCustomerProblemReload={getIssuesForObject}
                        // isLockedByOther={isLockedByOther}
                    />
                )}
                {customer && (
                    <ClientInfo
                        client={customer}
                        clientType={customer.party.partyType}
                        dynFormObjectType="CUSTOMER"
                        customerId={customer.id}
                        formName={`customer-panel-main-dynamic-form-${customer.id}`}
                        actions={!contactPersonId ? getMainCustomerActions() : []}
                        outerLoading={customerLoading}
                        readonly={!isEditCustomer || isReadOnlyCustomerPanel}
                        onAppealDestinationsReload={onAppealDestinationsReload}
                        onCustomerProblemReload={getIssuesForObject}
                    />
                )}

                <CustomerAddressModal id={customerId} />

                {/* MODAL FOR UNLINKING CUSTOMER */}
                {warningPopupOpen && !isLockingAppeal && !isLockedByOther && (
                    <ModalPortal
                        onClose={closeCustomerWarningPopUp}
                        className="modal-small modal-center"
                    >
                        <MessageModal
                            contentModalText={t('removeCustomer')}
                            primaryButton={true}
                            primaryButtonText={t('yes')}
                            onClickPrimaryButton={unlinkCustomer}
                            secondaryButton={true}
                            secondaryButtonText={t('no')}
                            onClickSecondaryButton={closeCustomerWarningPopUp}
                        />
                    </ModalPortal>
                )}

                {/* MODAL FOR ADDING NEW OR LINK NEW CUSTOMER */}
                <CSSTransition
                    in={!isLockingAppeal && !isLockedByOther && showAdvancedSearchModal}
                    classNames="fade"
                    appear={true}
                    enter={true}
                    exit={true}
                    timeout={500}
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <ModalPortal
                        onClose={handleCloseAdvancedSearchModal}
                        className={cx('modal-large', 'modal-center')}
                    >
                        <AdvancedSearchModal onSelect={onSelectCustomer} onCreateNewClose={() => removeAppealLockSource(LOCK_SOURCE)} isLockedByOther={isLockedByOther} />
                    </ModalPortal>
                </CSSTransition>
            </div>
        </div>
    );
};

export default withRouter(
    withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerPanel)),
);

// INDIVIDUAL, ORGANIZATION, SERVICEOBJECT
// CUSTOMER CUSTOM EDITS (NOT DYN PARAMS)
// custom edit: /mw/customer/edit
// payload example phone: {"customerId":6692646,"field":"contacts.homePhone","id":[0,75403,75043],"value":["111111","1212","380973346495"]}
// payload example email: {"customerId":6692646,"field":"contacts.email","id":[0],"value":["test@test.test"]}

// customer edit address: /mw/customer/addresses
// payload example address (via address modal): customerId=6692646&streetId=1855739&type=physical&settlementId=30006&street=1855739&buildingNumber=6&apartmentNumber=&description=&kind=urban&zipCode=01001&buildingSecondNumber=&intercomNumber=&floor=&latitude=&longitude=
// customerId: 6692646
// streetId: 1855739
// type: physical
// settlementId: 30006
// street: 1855739
// buildingNumber: 6
// apartmentNumber:
// description:
// kind: urban
// zipCode: 01001
// buildingSecondNumber:
// intercomNumber:
// floor:
// latitude:
// longitude:

// DYNPARAMS:
// /param/CUSTOMER
// payload: {"id":6692646,"attrCode":"bp@d1339@customer_block@700@6692646","attrValue":"50","change_num":"66281"}

// CONTACT PERSON:
// phone save: /person/contact/save
// payload: {"personId":72532,"id":0,"type":"workPhone","value":"1212"}
// email save: /person/contact/save
// payload: {"personId":72532,"id":83563,"type":"email","value":"test@test.test1"}
// characteristic (issues): /customer/person/issues/save
// payload: {"entity_type":"CUSTOMER","personId":72532,"entityid":"6692646","issues":["additional_contact","on_legal_issues","main_contact","another","urgent_contact","on_accounting_issues"]}

// DYNPARAMS:
// /param/CONTACT_PERSON
// payload: {"id":72532,"attrCode":"bp@d5118@individual@699@72532","attrValue":"2","change_num":"73798"}

/*
    Customer structure:
    Without Contact Person: 
    
        Notifications (if available) (probably will be attached to ClientInfo block and displayed within it)
        ClientInfo (If only either  individual (фізична особа), organization (юридична особа), serviceobject (обʼєкт) available)

    With Contact Person:

        Notifications (if available)  (probably will be attached to ClientInfo block and displayed within it)
        ClientInfo (Contact Person) 
        ClientInfo (individual (фізична особа) OR organization (юридична особа) OR serviceobject (обʼєкт) available)

        * appeal => show Contact Person + Client
        * customer page => show Contact Person + all clients from "links" param (fetch all clients dara)

    Without customer:
    probably appeal without customer => show not defined customer block to link customer to appeal
*/
