import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "components/Loader/index";
import CheckBoxGrid from "components/Common/CheckBoxGrid";
// import { configureFilterObject } from "components/SearchFilters/helpers";

import GridWrapper from "../../../custom-hooks/GridWrapper";
import { setApi } from "../../../reducers/grid";
// import { setCurrentTab } from "../../../actions/index";
// import { getFormSyncErrors, getFormValues } from "redux-form";
// import isEmpty from "lodash/isEmpty";

// function mapStateToProps(state) {
//     return {
//         // filterValues: getFormValues("modal-link-filter-search")(state),
//         // linkFilterList: state.appeal.linkFilterList,
//         // checkedBinders: state.appeal.checkedBinders,
//         // filterFormErrors: getFormSyncErrors("modal-link-filter-search")(state),
//     };
// }

const init = {
    key: "searchEmailContacts",
    method: "get",
    limit: 20,
    // code: "INTERACTION_REQUEST_LINKS",
};

const gridKey = `${init.key}_${init.path || ""}`;

function SearchEmailListGrid(props) {
    const [loadingState, setLoadingState] = useState(true);
    const { updateApiGrid, gridSearch, onCheck } = props;

    const getParams = () => ({
        classname: "checkbox-field",
        fields: {
            checkbox: {
                component: params => (
                    <CheckBoxGrid
                        type="search-email-modal"
                        params={params}
                        onChange={handleCheck}
                        loadingState={loadingState}
                    />
                ),
            },
        },
    });

    const handleCheck = (checked, params) => {
        const { data } = params;
        if (data && data.email) {
            onCheck({ email: data.email, checked });
        }
    };

    useEffect(() => {
        updateApiGrid({ query: gridSearch }, gridKey);
    }, [gridSearch]);

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    return (
        <div className="link-grid content-wrapper with-fixed-status">
            {loadingState && <Loader />}
            <GridWrapper
                tuner={() => init}
                initUpdate={false}
                gridParams={getParams()}
                gridSearch={gridSearch}
                onStart={onStart}
                onFinish={onFinish}
                destroyOnUnmount
            />
        </div>
    );
}

export default connect(null, { updateApiGrid: setApi })(withRouter(SearchEmailListGrid));
