import React, { Fragment } from 'react';
import Popup from 'components/Popup';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import baseService from 'services/BaseService';
import { connect } from 'react-redux';
import get from 'lodash/get';
import settingsService, { FIELDS_POSTFIX, SORT_POSTFIX } from 'services/settingsService';
import tabsParams from 'constants/tabsParams';
import { exportAppealGridToExcel, exportUniversalCsv } from 'actions/filter';

// import permissions, { checkPermissions } from 'config/permissions';


import {deleteCalendarEvent, getUserCalendars} from "../../modules/calendar/actions";

import { openExportWizardModal } from '../../reducers/exportWizard';


function mapStateToProps (state, props) {
	const { hash, entity } = props.match.params;
	
	return {
		query: state.search.searches[hash] || '',
		filterObject: _.get(state, `searchFilter.filters[${hash}-${entity}]`),
		queryKey: get(state.tabs, 'current.queryKey'),
		pagination: state.search.pagination,
		// isExportAllowed: checkPermissions(permissions.SearchOperations.search_export),
	};
}

const mapDispatchToProps = dispatch => ({
	exportUniversalCsv: (params) => dispatch(exportUniversalCsv(params)),
	openExportWizardModal: () => dispatch(openExportWizardModal())
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class GridActionsPopup extends React.Component {
	
	constructor () {
		super();
		this.exportAllToExcel = this.exportAllToExcel.bind(this);
		this.shouldDisplayExportSelected = this.shouldDisplayExportSelected.bind(this);
		this.exportSelectedToExcel = this.exportSelectedToExcel.bind(this);
		this.getSearchEntity = this.getSearchEntity.bind(this);
		this.exportCustomersAndContatPersons = this.exportCustomersAndContatPersons.bind(this);
		this.exportCleventsToExcel = this.exportCleventsToExcel.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.handleExportClick = this.handleExportClick.bind(this);
	}
	
	getSearchEntity () {
		return this.props.match.params.entity;
	}
	
	exportAllToExcel () {
		const appealFields = Object.entries(tabsParams(this.props.t).filter)
			.reduce((acc, [key, value]) => {
				const nameParts = [key];
				if (value.objectField) nameParts.push(value.objectField);
				
				acc[nameParts.join('.')] = value.name;
				
				return acc;
			}, {});
		const requestData = {
			query: this.props.query,
			fields: appealFields,
			filterObject: this.props.filterObject || []
		};
		
		const { queryKey } = this.props;
		
		let sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
		if (sortSettings && !_.isEmpty(sortSettings)) {
			requestData['sort'] = [{
				property: sortSettings.property,
				direction: sortSettings.direction.toUpperCase()
			}];
		}
		
		this.props.onClose();
		return exportAppealGridToExcel(requestData);
	};
	
	exportSelectedToExcel () {
		const { queryKey, filterObject } = this.props;
		const fields = settingsService.get(`${queryKey}${FIELDS_POSTFIX}`);
		
		const appealFields = Object.entries(fields)
			.reduce((acc, [key, value]) => {
				
				if (!value.hidden) {
					const nameParts = [key];
					if (value.objectField) nameParts.push(value.objectField);
					
					acc[nameParts.join('.')] = value.name;
				}
				
				return acc;
			}, {});
		
		const requestData = {
			query: this.props.query,
			fields: appealFields,
			filterObject: filterObject || []
		};
		
		let sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
		if (sortSettings && !_.isEmpty(sortSettings)) {
			requestData['sort'] = [{
				property: sortSettings.property,
				direction: sortSettings.direction.toUpperCase()
			}];
		}
		
		this.props.onClose();
		return exportAppealGridToExcel(requestData);
	};
	
	exportCleventsToExcel () {
		const { exportUniversalCsv, query, filterObject } = this.props;
		const params = {
			type: 'clevent',
			query,
		};
		
		this.props.onClose();
		return exportUniversalCsv(params);
	};
	
	shouldDisplayExportSelected () {
		const { queryKey } = this.props;
		if (!queryKey) return false;
		
		const fields = settingsService.get(`${queryKey}${FIELDS_POSTFIX}`);
		
		return Boolean(fields);
	};
	
	exportCustomersAndContatPersons () {
		const { t, queryKey } = this.props;
		let fields = null;
		
		const customerFields = {
			id: t('customer.id'),
			type: t('customer.partyType'),
			fullName: t('customer.fullName'),
			found: t('customer.fieldValue'),
			actualAddress: t('customer.factAddress')
		};
		
		const contactPersonFields = {
			id: t('customer.id'),
			contactName: t('customer.pib'),
			customerName: t('customer.fullName'),
			found: t('customer.fieldValue'),
			content: t('customer.contactName'),
		};
		queryKey === 'customer' ? fields = customerFields : fields = contactPersonFields;
		if (fields) {
			delete  fields.found;
		}
		
		const requestData = {
			query: this.props.query,
			fields: fields,
			type: this.props.match.params.entity,
			filterObject: this.props.filterObject || [],
		};
		
		const params = {
			data: requestData,
			jsonType: true,
			waitFile: true,
			fullResp: true
		};
		
		let sortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
		if (sortSettings && !_.isEmpty(sortSettings)) {
			requestData['sort'] = [{
				property: sortSettings.property,
				direction: sortSettings.direction.toUpperCase()
			}];
		}
		
		this.props.onClose();
		
		return baseService.post('search_csv', params)
			.then(this.downloadFile)
			.catch(console.error);
	};
	
	downloadFile (resp) {
		const url = window.URL.createObjectURL(new Blob([resp]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${this.getSearchEntity()}s.csv`);
		document.body.appendChild(link);
		link.click();
	};

	handleExportClick() {
		this.props.onClose();
		this.props.openExportWizardModal();
	}
	
	render () {
		const { t, onClose, isExportAllowed } = this.props;
		return (
			<Popup
				onClickOutside={onClose}
				place='grid-popup'
			>
				{isExportAllowed && <button className='popup-link' onClick={this.handleExportClick}>
					{t('filters.export')}
				</button>}
			</Popup>
		
		);
	}
}

GridActionsPopup.propTypes = {
	onClose: PropTypes.func
};

export default GridActionsPopup;
