import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { customerLinks, settingsLink } from 'config/links';
import 'styles/modules/navigation.module.scss';
import permissions, { checkPermissions } from 'config/permissions';
import { setCurrentSuperTabPath } from "../../actions/tabs";
import Control from './Control';
import ReactSVG from 'react-svg';

const superTabs = [...customerLinks.map(link => link.name), settingsLink.name];

function mapStateToProps (state) {
	return {
		isSettingsButtonEnabled: checkPermissions(permissions.AdminGrantOperations.view_permissions),
		isKnowledgeBaseButtonEnabled: checkPermissions(permissions.UserOperations.action_clientPartViewKnowledgeBase),
		isDashboardEnabled: checkPermissions(permissions.DashboardOperations.dashboard_workWithDashboard),
        isIdentityEnabled: checkPermissions(permissions.AdminGrantOperations.action_manage_access),
		isBuEnabled: checkPermissions(permissions.BuOperations.view_bu),
		isOrdersEnabled: checkPermissions(permissions.OrderOperations.view_order),
		isServicesEnabled: checkPermissions(permissions.ServiceOperations.view_service),
		isIssuesEnabled: checkPermissions(permissions.AppealOperations.action_viewGlobalProblems),
        specification: checkPermissions(permissions.CliSpecOperations.view_Spec),
		isResourseEnabled: checkPermissions(permissions.ServiceOperations.view_resource),
        isCalendarEnabled: checkPermissions(permissions.CalendarOperations.view_calendar),
        // isCustomersEnabled: checkPermissions(permissions.CustomerOperations.view_customer),
        isCustomersEnabled: checkPermissions(permissions.SearchOperations.search_customer),
		identity: state.user.identity.host,
        currentSuperTabPath: state.tabs.currentSuperTabPath
	};
}


const Navigation = props => {
    const {
        isKnowledgeBaseButtonEnabled,
        isSettingsButtonEnabled,
        isDashboardEnabled,
        // isIdentityEnabled,
        isBuEnabled,
        isOrdersEnabled,
        isServicesEnabled,
        isResourseEnabled,
        isIssuesEnabled,
        specification,
        isCalendarEnabled,
        isCustomersEnabled,
        location,
        setCurrentSuperTabPath,
        currentSuperTabPath
    } = props;

    useEffect(() => {
        const currentPath = location && location.pathname;
        const isSuperTab = superTabs.find(tab => {
            // do not match "appeal" as a supper tab if some appeal is opened via /appeals/:id path
            if (tab === 'appeal' && currentPath.startsWith("/appeals")) {
                return;
            }
            return currentPath.startsWith(`/${tab}`);
        });
        if (isSuperTab) {
            setCurrentSuperTabPath(currentPath);
        }

        // config to handle when page init with some active tab
        // mark appropriate superTab as active in such case (and provide correct path for it)
        // /appeals/12345/ => superTab /appeal (special case)
        // /order/12345/ => superTab /orders
        if (!isSuperTab && !currentSuperTabPath) {
            const splittedCurrentPath = currentPath.split("/");
            const activeTabPath = splittedCurrentPath && splittedCurrentPath[1];
            // appeals is special case, active tab is plural "appeals" and supertab is single "appeal";
            if (activeTabPath) {
                if(activeTabPath === "appeals") {
                    setCurrentSuperTabPath(`/appeal`);
                } else {
                    // otherwise simple add "s" to the path end for proper superTab path
                    setCurrentSuperTabPath(`/${activeTabPath}s`);
                }
            }
        }
    }, [location]);

    return (
        <aside>
            <div>
                <div className='logo-container'>
                    <div className='container-menu'>
                        <ReactSVG src='/data/svg/logo.svg' />
                    </div>
                </div>
                <div className='sections-block'>
                    {customerLinks.map((item) => {
                        if (item.name === 'knowledge_base' && !isKnowledgeBaseButtonEnabled) return null;
                        if (item.name === 'customers' && !isCustomersEnabled) return null;
                        if (item.name === 'dashboard' && !isDashboardEnabled) return null;
                        if (item.name === 'orders' && !isOrdersEnabled) return null;
                        if (item.name === 'services' && !isServicesEnabled) return null;
                        if (item.name === 'resources' && !isResourseEnabled) return null;
                        if (item.name === 'issue' && !isIssuesEnabled) return null;
                        if (item.name === 'specification' && !specification) return null;
                        if (item.name === 'calendar' && !isCalendarEnabled) return null;
                        if (item.name === 'bunits' && !isBuEnabled) return null; 
                        return (
                            <Control item={{ ...item, link: `/${item.name}` }} currentSuperTabPath={currentSuperTabPath} key={item.name} />
                        );
                    })}
                </div>
            </div>
            {
                isSettingsButtonEnabled
                && (
                    <div>
                        <div className='sections-block'>
                            <Control key='settings' onClick={(e) => { e.preventDefault(); }} item={settingsLink} currentSuperTabPath={currentSuperTabPath} />
                        </div>
                    </div>
                )
            }
        </aside>
    );
}

Navigation.defaultProps = {
	isKnowledgeBaseButtonEnabled: false,
	isSettingsButtonEnabled: false,
};

Navigation.propTypes = {
	isKnowledgeBaseButtonEnabled: PropTypes.bool,
	isSettingsButtonEnabled: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, { setCurrentSuperTabPath })(Navigation));