export const leftTrimProps = (data, propsNames) => {
	if (propsNames && Array.isArray(propsNames)) {
		propsNames.forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = data[prop].trimLeft();
			}
		});
	} else {
		Object.keys(data).forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = data[prop].trimLeft();
			}
		});
	}
};

export function fieldLevelValidation (values, rules, errors, t) {
	// console.log({values, rules, errors});

	for (const fieldName in values) {
		// console.log({fieldName, values, rules});
		if (values.hasOwnProperty(fieldName)) {
			const validator = rules[fieldName];
			
			if (!validator) continue;
			
			const error = validator(values[fieldName], t);
			if (error) {
				errors[fieldName] = error;
			}
		}
	}
}