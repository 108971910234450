import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import ReactSVG from 'react-svg';
import Loader from '../Loader';
import { getRequest } from '../../actions/orders';
import Tab from 'components/Tab';
import { HistoryItemTypes } from 'constants/index';
import * as sessionSelectors from 'reducers/session';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import ComboBox from 'components/Common/ComboBox';
import Avatar from 'components/Avatar';

// import '../../pages/Ordering/Order/OrderContent/Tabs/History/styles.scss';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import { withTranslation } from 'react-i18next';

import { convertHistoryFilters, getHistoryItemComponent, highlight, getAuthorName } from './utils';
import './styles.scss';
import Pagination from '../Common/Pagination';

const mapStateToProps = (state, props) => ({
    tabs: state.tabs,
    query: sessionSelectors.getSearchQuery(state),
    filter: sessionSelectors.getFilter(state),
});
const mapDispatchToProps = (dispatch) => ({
    getRequest: (callback, params) => dispatch(getRequest(callback, params)),
});

function HistoryTab(props) {
    const { getRequest, id, objectType, tabProps, t, isModal } = props;
    const [isLoading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);
    const [history, setHistory] = useState({});
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const tabNames = tabProps && tabProps.tabsNames;
    const historyGroupsClass = total ? 'history-groups' : 'empty-history-group';
    const limit = 20;

    useEffect(() => {
        objectType && getFilters();
        return () => {
            setFilters([]);
        };
    }, [objectType]);

    useEffect(() => {
        if (id && filters.length) getHistory();
        return () => {
            setLoading(true);
            setHistory({});
        };
    }, [id]);

    useEffect(() => {
        if (filters.length) {
            if (page !== 1) {
                return setPage(1);
            }
            getHistory();
        }
    }, [filters, query]);

    useEffect(() => {
        if (filters.length) {
            getHistory();
        }
    }, [page]);

    const renderTab = (tab) => <Tab val={tab} key={tab} tab={tab} />;

    const getHistory = () => {
        const activeFilter = getFilterItem();
        const start = page === 1 ? 0 : (page - 1) * limit;
        const params = {
            key: 'get_data_history',
            method: 'get',
            data: {
                limit,
                start,
                filter: activeFilter ? activeFilter.value : '',
                query,
            },
            pathParams: { objectType, id },
        };
        setLoading(true);
        getRequest(handleHistoryResponse, params);
    };

    const getFilters = () => {
        const params = {
            key: 'get_history_filters',
            method: 'get',
            pathParams: { objectType },
        };
        getRequest(({ success, result }) => {
            success && setFilters(convertHistoryFilters(result));
        }, params);
    };

    const handleHistoryResponse = ({ success, result, total }) => {
        if (success) {
            setTotal(total);
            const res = result.filter((item) =>
                Object.values(HistoryItemTypes).includes(item.type),
            );
            setHistory(groupBy(res, 'orderDate'));
        } else {
            // handle error here
        }
        setLoading(false);
    };

    const renderHistoryItem = ({ type, orderDate, item }, index) => {
        const isComment = item.contentType === 'comment';
        const ItemComponent = getHistoryItemComponent(isComment ? item.contentType : type);
        if (ItemComponent) {
            return (
                <ItemComponent
                    orderDate={orderDate}
                    data={item}
                    key={index}
                    highlight={(text) => highlight(text, query)}
                />
            );
        } else {
            throw new Error(`Unknown type of appeal history item. Type: [${type}]`);
        }
    };

    const renderHistoryGroup = ([orderDate, items]) => {
        const formattedDate = moment(parseInt(orderDate)).format('DD.MM.YYYY в HH:mm:ss');
        const checkCollection = true;

        return (
            <div className="history-group" key={orderDate}>
                <div className="author-avatar-column">
                    {getAuthorName(items) !== t('systemMessage') ? (
                        <Avatar name={getAuthorName(items)} />
                    ) : (
                        <div className="system-author-avatar">
                            <i className="icon-logo" />
                        </div>
                    )}
                </div>

                <div className="history-group-content">
                    <header className="groupHeader">
                        <span className="author-name">
                            {highlight(getAuthorName(items, checkCollection), query)}
                        </span>
                        <span className="history-date">{formattedDate}</span>
                    </header>

                    <ul className="history-item-list">{items.map(renderHistoryItem)}</ul>
                </div>
            </div>
        );
    };

    const debouncedSetSearch = debounce((query) => setQuery(query), SEARCH_TIMER_INTERVAL);

    const onSearchChange = (event) => {
        const query = event.target.value;
        debouncedSetSearch(query);
    };

    const getFilterItem = () => filters.find((item) => item.active);

    const onFilterChange = (filter) => {
        if (filter) {
            setFilters(
                filters.map((item) => {
                    if (filter.value === item.value) {
                        return { ...item, active: true };
                    }
                    return { ...item, active: false };
                }),
            );
        }
    };

    const onPageChange = ({ page }) => setPage(page);

    return (
        <div className="contentWrapper">
            {tabNames && (
                <div id="card-bottom-row" className="column">
                    <div className="top-right">{Object.keys(tabNames).map(renderTab)}</div>
                </div>
            )}

            <div className="contentWrapper">
                <div className="contentWrapper">
                    <div className="history-wrapper">
                        {isLoading && (
                            <Loader
                                withContainer
                                loaderClass={cx({ 'history-modal-loader': isModal })}
                            />
                        )}
                        <div className={historyGroupsClass}>
                            <div className={cx('tabHeader', 'history')}>
                                <ComboBox
                                    options={filters}
                                    value={getFilterItem()}
                                    onChange={onFilterChange}
                                />
                                <div className="searchInputWrapper tab-search-wrapper">
                                    <input
                                        type="text"
                                        className="searchInput"
                                        placeholder={t('Search')}
                                        onChange={onSearchChange}
                                        autoFocus
                                    />
                                    <i className="icon-search2" />
                                </div>
                                <button
                                    title={t('refresh')}
                                    className="add-button btn-refresh"
                                    type="button"
                                    onClick={getHistory}
                                >
                                    <ReactSVG src="/data/svg/refresh.svg" />
                                </button>
                            </div>
                            {Object.entries(history).map(renderHistoryGroup)}
                            {total ? (
                                <Pagination
                                    page={page}
                                    total={total}
                                    limit={limit}
                                    onChange={onPageChange}
                                    containerClassName="history-pagination"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HistoryTab));
