const actions = ['CMP_INIT', 'CMP_UPDATE', 'CLEAR_ALL_SETTINGS'];

const initData = {
    inputFields: [],
    outputFields: [],
    inputSelected: [],
    outputSelected: [],
    displayedField: null,
    indexedField: null
};

/* selectors */
export const getData = (data, key) => data[key] || initData;

/* updaters */

/* reducer */
export default function reducer(state = {}, action) {
    if (actions.includes(action.type)) {
        const { type, key, ...rest } = action;
        const activeKey = key || state.activeKey;
        const prevData = state[activeKey];

        switch (type) {
            case 'CMP_INIT': {
                const newData = prevData || rest;

                newData.indexedField = newData.indexedField || newData.displayedField;
                newData.inputSelected = [];
                newData.outputSelected = [];

                return { ...state, activeKey, [activeKey]: { ...(prevData || initData), ...newData } };
            }

            case 'CMP_UPDATE': {
                const newData = JSON.parse(JSON.stringify({ ...prevData, ...rest }));
                // return { ...state, [activeKey]: { ...prevData, ...rest } };
                return { ...state, [activeKey]: newData };

            }

            case 'CLEAR_ALL_SETTINGS': {
                return {};
            }

            default:
                return state;
        }
    }

    return state;
}

/* action creators */
export const init = ({ key, ...rest }) => ({ type: 'CMP_INIT', key, ...rest });

export const update = data => ({ type: 'CMP_UPDATE', ...data });

export const clear = () => ({type: 'CLEAR_ALL_SETTINGS'});
