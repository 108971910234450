import { requiredFieldsValidation, fieldLevelValidation, fieldValidation, REQUIRED_FIELD } from 'util/form';
import { leftTrimProps } from 'util/form';

import _ from 'lodash';
import moment from 'moment';
import i18next from 'util/i18n';


export default function validate (values, { config = [], dynParams }) {
	// console.log({values, config, dynParams});
	let errors = {};
	const widgets = [];
	
	leftTrimProps(values);
	
	if(values['start'] && values['end']){
		if (moment(values['end']).isBefore(values['start'])) {
			errors['end'] = i18next.t('calendar.endDateInValid');
		}
	}
	
	requiredFieldsValidation(values, config, errors);
	
	for (let key in values.blocks) {

		if (moment.isMoment(values.block[key]) && !values.block[key].isValid()) {
			errors = { ...errors, block: { ...errors.block, [key]: i18next.t('validation.correctDate') } };
		}
	}
	const required = widgets.map(widget => (!widget.disabled && { name: `${widget.key}`, required: widget.isReq }));
	requiredFieldsValidation(values, [...config, {block: required}], errors);
	dynParams && dynParams.blocks.forEach(item => {
		if (item && item.widgets && item.action !== 'del') {
			item.widgets.forEach(widget => {
				if (widget && widget.rule && values.block && values.block[widget.key]) {
					const [ hint, ...rest ] = widget.rule.split('$');
					const regexp = rest.join("");
					const formattedRegexp = new RegExp(`^${regexp}$`);
					if (!formattedRegexp.test(values.block[widget.key])) {
						errors = {...errors, block: {...errors.block, [widget.key]: hint}};
					}
				}

				if (_.get(widget, `isReq`)) {
					// console.log({widget});
					// if (!_.get(values, `block[${widget.key}]`) && _.get(values, `block[${widget.key}]`) !== null) {
					// 	errors = {
					// 		...errors,
					// 		block: {...errors.block, [widget.key]: i18next.t('validation.required')}
					// 	};
					// }

					if (_.isEmpty(_.get(values, `block[${widget.key}]`))) {
						errors = {
							...errors,
							block: {...errors.block, [widget.key]: i18next.t('validation.required')}
						};
					}

					if (widget && (widget.widgetType === 'date' || widget.widgetType === 'datetime') &&
						values.block && values.block[widget.key] && !moment(values.block[widget.key]).isValid()) {
						errors = {
							...errors,
							block: {...errors.block, [widget.key]: i18next.t('validation.correctDate')}
						};
					}
				}

			});
		}
	});
	// console.log({errors});
	return errors;
}
