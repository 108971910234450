import {
    TABS_REMOVE_TAB,
    TABS_RESET,
    CLOSE_ALL_EXCEPT_CURRENT,
    TABS_REVERSE,
    TOGGLE_BLOCK_ADDING_POPUP,
    CLOSE_BLOCK_ADDING_POPUP,
    SET_CURRENT_TAB_FIELD,
    SET_TAB_NAME,
    SET_BREAD_CRUMBS,
    SET_FORM_COLLAPSE,
    SET_CURRENT_SUPER_TAB_PATH
} from 'constants/actions';

export function setBreadCrumbs (breadcrumbs) {
    return (dispatch) => {
        dispatch({
            type: SET_BREAD_CRUMBS,
            breadcrumbs
        });
    };
}

export function setFormCollapse (id, collapse) {
    return (dispatch) => {
        dispatch({
            type: SET_FORM_COLLAPSE,
            collapse,
            id
        });
    };
}

export function setTabName (tab, name) {
    return (dispatch) => {
        dispatch({
            type: SET_TAB_NAME,
            tab,
            name,
        });
    }
}

export function removeTab (payload, redirectConfig) {

    // redirectConfig need to handle appeal saveAndClose case
    //  SDSUI-6985 #1 white screen issue (redux crashes due to history.replace in reducer)
	if (redirectConfig) {
		let tabs = [...redirectConfig.tabs];
		let current = { ...redirectConfig.current };

        const currentSuperTabPath = redirectConfig.currentSuperTabPath || '/appeal';

		const removedIndex = tabs.findIndex(v => v.type === payload.tabType);
		const currentIndex = tabs.findIndex(tab => tab.type === current.type);
		tabs.splice(removedIndex, 1);

		if (!tabs.length) {
			redirectConfig.history.push(currentSuperTabPath);
		} else {
			if (removedIndex === currentIndex) {
				current = tabs[tabs.length - 1];
			}
			redirectConfig.history.push(current.url);
		}
	}

    return (dispatch) => {
        dispatch({
			type: TABS_REMOVE_TAB,
			payload,
            redirectConfig
		});
    };
}

export const updateCurrentTabField = (field, value) => ({
    type: SET_CURRENT_TAB_FIELD,
    field,
    value
});

export const resetTab = () => ({
    type: TABS_RESET
});

export const closeAllExceptCurrent = ({ url }) => ({
    type: CLOSE_ALL_EXCEPT_CURRENT,
    payload: { url }
});

export const tabsReverse = ({ url }) => ({
    type: TABS_REVERSE,
    payload: { url }
});

export const toggleBlockAddingPopup = () => ({
    type: TOGGLE_BLOCK_ADDING_POPUP
});

export const closeBlockAddingPopup = () => ({
    type: CLOSE_BLOCK_ADDING_POPUP
});

export const setCurrentSuperTabPath = (superTabPath) => ({
    type: SET_CURRENT_SUPER_TAB_PATH,
    superTabPath
});
