import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderRow ({ children }) {
	return <div className='headerRow'>{children}</div>;
}

HeaderRow.propTypes = {
	children: PropTypes.any
};
