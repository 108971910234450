import InputField from "components/Common/InputField";
import ComboBox from "components/Common/ComboBox";
import { ComboBox as ComboBoxCore } from "ui-core-dashboard";
import TextArea from "components/Common/TextArea";

import { isEmpty } from "lodash";

const loggedErrors = {};

export const getModalFormDataFromState = (state, formCode) => {
    if (!formCode) {
        return {};
    }
    // const staticBlocks = state.staticBlocks.blocks && state.staticBlocks.blocks.find(block => block.code === 'FORM');
    const formBlocksOrigin = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM;
    const formBlocksDefault =
        state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM_DEFAULT;
    const formBlocksDefaultStaticUI =
        (state.staticBlocks && state.staticBlocks.fallbackFormBlocks && state.staticBlocks.fallbackFormBlocks.FORM) ||
        {};
    const isNoOriginConfig = !formBlocksOrigin || isEmpty(formBlocksOrigin);
    const isNoDefaultConfig = !formBlocksDefault || isEmpty(formBlocksDefault);
    const logKey = `getModalFormDataFromState_${formCode}`;
    if (isNoOriginConfig && isNoDefaultConfig) {
        if (!loggedErrors[logKey]) {
            console.info(
                "⚠️ getModalFormDataFromState:: Static blocks are not configured correctly (no FORM and FORM_DEFAULT, using STATIC UI FALLBACK)"
            );
            loggedErrors[logKey] = true;
        }
    }
    let modalFormData = formBlocksOrigin[formCode] && formBlocksOrigin[formCode].blocks;
    if (!modalFormData || isEmpty(modalFormData)) {
        if (!loggedErrors[`${logKey}_default`]) {
            console.info(
                "⚠️ getModalFormDataFromState:: FORM blocks are not configured, trying to fallback to FORM_DEFAULT"
            );
            loggedErrors[`${logKey}_default`] = true;
        }
        modalFormData = formBlocksDefault[formCode] && formBlocksDefault[formCode].blocks;
    }
    if (!modalFormData || isEmpty(modalFormData)) {
        if (!loggedErrors[`${logKey}_static_ui`]) {
            console.info(
                "⚠️ getModalFormDataFromState:: FORM_DEFAULT blocks are not configured, fallback to static UI config"
            );
            loggedErrors[`${logKey}_static_ui`] = true;
        }
        modalFormData = formBlocksDefaultStaticUI[formCode] && formBlocksDefaultStaticUI[formCode].blocks;
    }
    // console.log({modalFormData});
    return modalFormData;
};

export const getMergedStaticBlocks = (t, staticConfigData, uiBlocksData, formValues, clientType) => {
    if (!staticConfigData || staticConfigData.hidden) {
        return [];
    }

    const uiConfiguredFields =
        typeof uiBlocksData === "function" ? uiBlocksData(t, formValues, clientType) : uiBlocksData;

    if (!uiConfiguredFields || isEmpty(uiConfiguredFields)) {
        return [];
    }

    const mergedStaticBlocks = Object.keys(uiConfiguredFields)
        .map(fieldName => {
            const uiField = uiConfiguredFields[fieldName];
            const externalField = staticConfigData.attrs[fieldName] || {};
            // return null for empty field
            if (!externalField || isEmpty(externalField)) {
                const logKey = `${fieldName}_${staticConfigData.name}_${staticConfigData.description}`;
                if (!loggedErrors[logKey]) {
                    console.info(
                        `⚠️ No external static config found: ${fieldName} | ${staticConfigData.name} | ${staticConfigData.description}`
                    );
                    loggedErrors[`${fieldName}_${staticConfigData.name}_${staticConfigData.description}`] = true;
                }
                // console.warn(
                //     `%c ⚠️ UI cannot find match for static config\nFIELD: ${fieldName}\nBLOCK NAME: ${staticConfigData.name}\nBLOCK DESCRIPTION: ${staticConfigData.description}`
                // );
                // externalField.component = () => null;
                // return null;
            }
            return {
                ...uiField, // set default ui field values
                ...externalField, // override default ui values with externally configured values
                name: uiField.name, // name should match static field param => by that key value is being saved
                label: t(externalField.name) || t(uiField.label), // label to be displayed over the field input
            };
        })
        // filter hidden items
        .filter(item => !item.hidden)
        // sort by order param
        .sort((a, b) => {
            if (a.order && b.order) {
                return a.order - b.order;
            }
            return 1;
        });

    return mergedStaticBlocks;
};

const WORK_PHONE_TYPES = ["contactPerson", "organization", "serviceobject"];

export const personalDataBlocks = (t, formValues, clientType) => {
    const data = {
        lastName: {
            label: "persons.lastName",
            name: "lastName",
            field: "lastName",
            component: InputField,
            maxLength: 50,
        },
        firstName: {
            label: "persons.firstName",
            name: "firstName",
            field: "firstName",
            component: InputField,
            maxLength: 50,
            required: true,
        },
        patronymic: {
            label: "persons.patronymic",
            name: "patronymic",
            field: "patronymic",
            component: InputField,
            maxLength: 50,
        },
        issues: {
            label: "persons.issues",
            name: "issues",
            field: "issues",
            component: ComboBox,
            multi: true,
            required: true,
        },
        workPhone: {
            label: "persons.phone",
            name: "workPhone",
            field: "workPhone",
            component: InputField,
            maxLength: 19,
            required: true,
        },
        homePhone: {
            label: "persons.phone",
            name: "homePhone",
            field: "homePhone",
            component: InputField,
            maxLength: 19,
            required: true,
        },
        email: { label: "persons.email", name: "email", field: "email", component: InputField, maxLength: 255 },
    };

    // const phoneType = clientType === 'contactPerson' ? 'workPhone' : 'homePhone';

    // workPhone => remove homePhone and vice versa
    if (WORK_PHONE_TYPES.includes(clientType)) {
        delete data.homePhone;
    } else {
        delete data.workPhone;
    }

    return data;
};

export const custAddressBlocks = {
    settlement: {
        label: "district",
        name: "settlement",
        field: "settlement",
        component: ComboBox,
        searchable: true,
        required: true,
    },
    street: { label: "street", name: "street", field: "street", component: ComboBox, searchable: true, required: true },
    buildingNumber: {
        label: "buildingNumber",
        name: "buildingNumber",
        field: "buildingNumber",
        component: ComboBox,
        searchable: true,
        maxLength: 16,
        required: true,
    },
    // new
    buildingSecondNumber: {
        label: "buildingSecondNumber",
        name: "buildingSecondNumber",
        field: "buildingSecondNumber",
        component: InputField,
        maxLength: 32,
        required: true,
    },
    apartmentNumber: {
        label: "apartmentNumber",
        name: "apartmentNumber",
        field: "apartmentNumber",
        component: InputField,
        maxLength: 32,
        required: true,
    },
    zipCode: {
        label: "zipCode",
        name: "zipCode",
        field: "zipCode",
        component: ComboBox,
        searchable: true,
        maxLength: 5,
        required: true,
    },
    description: {
        label: "description",
        name: "description",
        field: "description",
        component: TextArea,
        autosize: true,
        maxLength: 1500,
        textAreaClassName: "description",
    },
};

export const juridicalDataBlocks = {
    shortName: {
        label: "name",
        name: "shortName",
        field: "shortName",
        component: InputField,
        maxLength: 255,
        required: true,
    },
};

export const objectDataBlocks = (t, formValues) => {
    return {
        city: {
            label: "city",
            name: "city",
            field: "city",
            component: ComboBox,
            searchable: true,
            inputProps: { maxLength: 255 },
            maxLength: 255,
        },
        // address
        object: {
            label: "address",
            name: "object",
            field: "object",
            component: ComboBoxCore,
            searchable: true,
            maxLength: 255,
            inputProps: { maxLength: 255 },
            placeholder: t("dontSelect"),
            noResultsText: t("issues.nothing"),
            searchPromptText: t("issues.startType"),
            loadingPlaceholder: t("issues.search"),
            hasExValue: true,
            isAsync: true,
            options: [],
            key: _.get(formValues, "city.label", "object"),
            required: true,
        },
        apartment: {
            label: "apartment",
            name: "apartment",
            field: "apartment",
            component: InputField,
            maxLength: 32,
            disabled: true,
            key: _.get(formValues, "city.object", "apartment"),
            required: true,
        },
    };
};

export const searchCustomerByNameBlocks = t => ({
    customer: {
        label: "name",
        name: "customer",
        field: "customer",
        id: "customer-search",
        placeholder: t("dontSelect"),
        noResultsText: t("issues.nothing"),
        searchPromptText: t("issues.startType"),
        loadingPlaceholder: t("issues.search"),
        component: ComboBoxCore,
        hasExValue: true,
        isAsync: true,
        searchable: true,
        inputProps: { maxLength: 255 },
        options: [],
        required: true,
    },
});
