import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import SearchEmailListGrid from "./SearchEmailListGrid";
import { connect } from "react-redux";
import Input from "components/Common/InputField";
import { closeAppealLinkModal } from "actions/ui";
import { Form, reduxForm, Field, getFormSyncErrors, getFormValues, initialize } from "redux-form";
import useDebounce from "../../../custom-hooks/useDebounce";
import isEmpty from "lodash/isEmpty";
import { getRequest } from "../../../actions/orders";
import {
    setSearchModalEmailBinders,
    checkSearchModalEmailBinder,
    uncheckSearchModalEmailBinder,
    clearSearchModalEmailBinders,
} from "../../../actions/ui";
import { updateAppealViewLinks } from "modules/appeal/actions";
import permissions, { checkPermissions } from "config/permissions";
import Pill from "components/Common/ArrayPillsInput/Pill";
import cx from "classnames";

const mapStateToProps = (state, props) => ({
    newLinkModalContext: state.ui.newLinkModalContext,
    formValues: getFormValues("link-appeals-form")(state),
    linkFilterList: state.appeal.linkFilterList,
    filterValues: getFormValues("modal-link-filter-search")(state),
    formErrors: getFormSyncErrors("link-appeals-form")(state),
    canEditLinkAppeal: checkPermissions(permissions.LinkOperations.actionEditLinks),
    checkedBinders: state.ui.checkedSearchModalEmailBinders,
});

const mapDispatchToProps = {
    closeAppealLinkModal,
    updateAppealViewLinks,
    getRequest,
    setSearchModalEmailBinders,
    checkSearchModalEmailBinder,
    uncheckSearchModalEmailBinder,
    clearSearchModalEmailBinders,
    initialize,
};

function EmailSearchForm(props) {
    const {
        t,
        // formValues,
        // formErrors,
        // pristine,
        checkedBinders,
        checkSearchModalEmailBinder,
        uncheckSearchModalEmailBinder,
        clearSearchModalEmailBinders,
        closeModal,
        searchEmailModalContext,
    } = props;

    const field = searchEmailModalContext && searchEmailModalContext.field;
    const translatedField = t(`mailing.${field}`)
    const [searchQuery, setSearchQuery] = useState("");

    // const [readyToSubmit, setReadyToSubmit] = useState(false);
    const { debouncedValue: debouncedSearchQuery } = useDebounce(searchQuery, 300);

    // useEffect(() => {
    //     if (isEmpty(formErrors) && checkedBinders.length) {
    //         setReadyToSubmit(true);
    //     } else {
    //         setReadyToSubmit(false);
    //     }
    // }, [checkedBinders, formErrors, formValues, pristine]);

    const onCheckHandler = data => {
        const { checked, email } = data;
        if (checked) {
            return checkSearchModalEmailBinder(email);
        }
        return uncheckSearchModalEmailBinder(email);
    };

    const setQuery = (e, query) => {
        // start search from at least one symbol and clear query if not
        if (!query || !query.length) return setSearchQuery("");

        // set new appealQuery so grid will update
        setSearchQuery(query);
    };

    function removeBinder(binder, index) {
        uncheckSearchModalEmailBinder(binder);
    }

    const onSave = e => {
        e.preventDefault();
        closeModal();
        if (props.onSave && typeof props.onSave === "function") {
            props.onSave(checkedBinders);
        }
    };

    useEffect(() => {
        return () => {
            clearSearchModalEmailBinders();
        };
    }, []);

    return (
        <div className="search-email-modal-window">
            <div className="search-email-modal-window-header">
                <div className="search-email-modal-window-title">{t("emailSearchAddress")} {translatedField ? `(${translatedField})` : ""}</div>
            </div>

            <div className="search-email-modal-window-form-wrapper ordering-component-ui-core-wrapper">
                <div className="search-wrapper">
                    <Field
                        key="appealQuery"
                        name="appealQuery"
                        label={t("Search")}
                        component={Input}
                        placeholder={t("Search")}
                        onBlur={e => props && props.input && props.input.onBlur && props.input.onBlur(undefined)}
                        onChange={setQuery}
                    />
                </div>
                <div className="customer-ordering-grid">
                    <SearchEmailListGrid gridSearch={debouncedSearchQuery} onCheck={onCheckHandler} />
                </div>
                <div className="search-email-modal-bottom-block">
                    <div className="inputWrapper">
                        <div className="input-label">{t("emailSearchSelected")}</div>
                        <div className="email-input-container">
                            {checkedBinders.map((email, idx) => (
                                <Pill value={email} id={idx} remove={() => removeBinder(email, idx)} />
                            ))}
                        </div>
                    </div>
                    <button
                        className={cx("btn", "btn-primary", { "btn-disabled": checkedBinders.length === 0 })}
                        onClick={onSave}
                        disabled={checkedBinders.length === 0}
                    >
                        {t("add")}
                    </button>
                </div>
            </div>
        </div>
    );
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: "search-emails-form" })(withTranslation()(EmailSearchForm)));
