import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { formValueSelector, change, destroy, getFormValues } from "redux-form";

import "./styles.scss";
import AppealMainContent from "./AppealMainContent";
import Proxy from "components/HOC/ContentTabProxy";
import CustomerPanel from "../Ordering/CustomerPanel";
import Loader from "components/Loader";
import BlockedAppeal from "./BlockedAppeal";

import {
    clearCustomerState,
    getCustomer,
    getContactPerson,
    clearContactPersonState,
    clearEntireCustomerState,
    clearAllCustomers,
} from "actions/customer";
import { removeTab, setTabName } from "actions/tabs";
import { setCurrentTab } from "actions";
import {
    getAppealTypesList,
    getAppealStatusList,
    getAppealPriorityList,
    getAppeal,
    getDynamicFormParams,
    getAppealFeedback,
    lockAppealForm,
    appealFetching,
    getAppealActions,
    getAppealDestinations,
    createNewAppeal,
    setSaveAndCreateNew,
    clearAppealState,
    clearAllAppeals,
    setRequireDynParams,
    setRequiredCustomer,
    unlockAppealForm,
    unlockAppealAndClearSource,
    removeAppealLockSource,
    // unlockAppealAndClearSourceWithoutForm,
    setAppealIdCentralPartLoading,
    setFormForceInitValues
} from "modules/appeal/actions";
import { updateCreatedAppealName } from "actions/call";
import { extractAppealFromState, extractCustomerFromState, closeAppeal, searchTree } from "helpers";
import { getTabs } from "reducers/tabs";
import { get } from "lodash";
// import { updateCurrentTabUrl } from 'actions/index';

function Appeal(props) {
    const {
        appeal,
        appealIsBlocked,
        appealTypes,
        priorityList,
        id,
        fetching,
        match,
        history,
        incomePhoneNumber,
        currentTab,
        setTabName,
        formValues,
        statusListInitializedType,
        // customerId,
        isDestinationsNeedRefresh,
        destination,
        appealDestination,
        destinations,
        unlockAppealForm,
        // unlockAppeal,
        isLockingAppeal,
        unlockAppealAndClearSource,
        unlockedAppeal,
        appealLockSourceList,
        removeAppealLockSource,
        // unlockAppealAndClearSourceWithoutForm,
        appealLockError,
        savingAppeal,
        loadingParams,
        isRefreshAppealNeeded,
        isUnlockingAppeal,
        isCentralPartLoading,
        setAppealIdCentralPartLoading,
        setFormForceInitValues
    } = props;

    const operators = get(appeal, "openMode.operators", []);

    const isLockedByOperator = operators.some(operator => operator.code === "edit" && !operator.owner);

    const isLockedByOther = isLockedByOperator || appealLockError || isRefreshAppealNeeded;

    // const [isAppealInitialized, setIsAppealInitialized] = useState(false);
    const [isDestInitialized, setIsDestInitialized] = useState(false);
    const [isNeededRefresh, setIsNeededRefresh] = useState(false);
    const [needReinitialize, setNeedReinitialize] = useState({}); // set in format id: boolean
    // const [needUpdate, setNeedUpdate] = useState({}); // set in format id: boolean
    // const [isCentralPartLoading, setIsCentralPartLoading] = useState({}); // object in format id: boolean
    // console.log({needReinitialize, needUpdate, isCentralPartLoading});
    const appealTypeId = useMemo(() => formValues && formValues.type && formValues.type.id);
    const appealStatusFromTemplate = useMemo(
        () => formValues && formValues.type && formValues.type.isStatusFromTemplate
    );
    const appealResolutionFromTemplate = useMemo(
        () => formValues && formValues.type && formValues.type.isResolutionFromTemplate
    );

    useEffect(() => {
        // setIsAppealInitialized(false);
        if (!fetching) {
            requestInitialData();
        }
        return () => {
            setIsDestInitialized(false);
        };
    }, [id]);

    useEffect(() => {
        const queryKey = `appeals_${id}`;
        if (appeal && currentTab.queryKey === queryKey && !currentTab.displayedName) {
            // console.log({appeal, currentTab, queryKey});
            setTabName(currentTab.name, appeal.regnum);
        }
    }, [appeal, currentTab]);

    // refetsh list for <StatusList> component on appeal type change (based on formValues)
    // refetch only if it was initialized and cached type is different from current one
    useEffect(() => {
        if (appealTypeId && statusListInitializedType && appealTypeId !== statusListInitializedType) {
            props.getAppealStatusList(id, appealTypeId, appealStatusFromTemplate, appealResolutionFromTemplate);
        }
    }, [appealTypeId]);

    // to refresh appeal status list once isNeededRefresh is true and both appeal and formValues are ready
    useEffect(() => {
        if (appeal && formValues && isNeededRefresh) {
            props.getAppealStatusList(id, appealTypeId, appealStatusFromTemplate, appealResolutionFromTemplate);
            setIsNeededRefresh(false);
        }
    }, [appeal, formValues, isNeededRefresh]);

    // make init list fetch once appealTypeId is available and no statusListInitializedType is provided yet
    useEffect(() => {
        if (appealTypeId && id && !statusListInitializedType) {
            props.getAppealStatusList(id, appealTypeId, appealStatusFromTemplate, appealResolutionFromTemplate);
        }
    }, [id, appealTypeId]);

    useEffect(() => {
        if (!isDestInitialized && destinations && destinations.length > 0) {
            setIsDestInitialized(true);
        }
    }, [destinations]);

    useEffect(() => {
        if (isDestinationsNeedRefresh) {
            reloadDestinations();
        }
    }, [isDestinationsNeedRefresh]);

    useEffect(() => {
        if (appeal && !isUnlockingAppeal && unlockedAppeal && appealLockSourceList && appealLockSourceList.length === 0) {
            unlockAppealAndClearSource(appeal.id);
        }
    }, [isUnlockingAppeal, unlockedAppeal, appealLockSourceList]);


    // all locking processed could be simple handling there via such logic, so other places are only changing "lockSource" list
    useEffect(() => {
        // used to handle again locking appeal, when for example customer was closed by clicking "open comment" or "mailing form"
        // appeal was unlocked during that process, but not locked again
        if (!unlockedAppeal && !isLockingAppeal && appealLockSourceList && appealLockSourceList.length > 0) {
            // console.log("**** LOCK APPEAL FROM APPEAL ****", appealLockSourceList);
            // do not lock appeal in case of only destinations lock source
            if (appealLockSourceList.length === 1 && appealLockSourceList[0] === 'destinations') {
                return;
            }
            // do not lock appeal if it is locked by other (lockError, operator lock, refreshNeeded)
            if (isLockedByOther) {
                return;
            }
            handleLockAppealForm(appealLockSourceList[0]);
        }
    }, [isLockingAppeal, unlockedAppeal, appealLockSourceList]);

    function handleNeedReinitialize(appealId, value) {
        setNeedReinitialize(prev => ({...prev, [appealId]: value}));
    }

    // function handleNeedUpdate(appealId, value) {
    //     setNeedUpdate(prev => ({...prev, [appealId]: value}));
    // }

    function handleLockAppealForm(unlockType, onSuccess, onFailure) {
        if (appeal && appeal.id) {
            unlockAppealForm(appeal.id, unlockType, unlockedAppeal, onSuccess, onFailure);
        }
    }

    function handleRemoveAppealLockSource(lockSource) {
        if (appeal && appeal.id) {
            removeAppealLockSource(appeal.id, lockSource);
        }
    }

    async function handleDestinationOpen() {
        if (appeal && !destinations && !isDestInitialized) {
            return reloadDestinations();
        }
    }

    function getDestination(newDestinations, selectedDestinations) {
        const newSelectedDestByValue = []; // to be founded by value
        const newSelectedDestByDefault = []; // to be founded by object.isDefault
        const newRoutesByValue = []; // to be founded by value
        const newRoutesByDefault = []; // to be founded by object.isDefault
        // search by value
        selectedDestinations.forEach(i => {
            const isFoundedByValue = searchTree(newDestinations, "result", item => {
                return parseInt(item.object.id) === parseInt(i);
            });

            const isFoundedByDefault = searchTree(newDestinations, "result", item => {
                return item.object.isDefault;
            });

            if (isFoundedByValue) {
                // newSelectedDest = isFoundedByValue;
                newSelectedDestByValue.push(isFoundedByValue.object.id);
                newRoutesByValue.push(isFoundedByValue.object.id);
                // return;
            }
            // check if there is existing another one isDefault or etc destination
            let isFoundedByDefaultSecond = false;
            if (isFoundedByDefault) {
                isFoundedByDefaultSecond = searchTree(newDestinations, "result", item => {
                    return +isFoundedByDefault.object.id !== +item.object.id && item.object.isDefault;
                });
            }

            // only select new destination in case there is only one founded
            if (isFoundedByDefault && !isFoundedByDefaultSecond) {
                // newSelectedDest = isFoundedByDefault;
                newSelectedDestByDefault.push(isFoundedByDefault.object.id);
                newRoutesByDefault.push(isFoundedByDefault.object.route);
            }
        });
        const newSelectedDest = newSelectedDestByValue.length > 0 ? newSelectedDestByValue : newSelectedDestByDefault;
        const newRoutes = newRoutesByValue.length > 0 ? newRoutesByValue : newRoutesByDefault;
        // console.log({newSelectedDest, newSelectedDestByValue, newSelectedDestByDefault});
        return {
            isDefault: newSelectedDestByValue.length === 0,
            newDest: [...new Set(newSelectedDest)],
            newRoutes: [...new Set(newRoutes)],
        };
    }

    function getNewSelectedDestinationAndRoutes(newDestinations) {
        const formDestination = props.destination || [];
        // check either formDestination if available or appealDestination (saved destination inside of appeal)
        // const destinationsToCheck = formDestination.length > 0 ? formDestination : [appealDestination];
        // const newSelectedDestAndRoutes = getDestination(newDestinations, destinationsToCheck);
        const newSelectedByUser = getDestination(newDestinations, formDestination);
        const newSelectedByAppeal = getDestination(newDestinations, [appealDestination]);

        // selected by user is not default and has some values => return it
        if (!newSelectedByUser.isDefault && newSelectedByUser.newDest.length > 0) {
            // console.log("*** RETURNED BY USER ***");
            return newSelectedByUser;
        }
        // selected by user is default and selected by appeal is empty => return default values;
        if (newSelectedByUser.isDefault && newSelectedByAppeal.newDest.length === 0) {
            // console.log("*** RETURNED BY USER ***");
            return newSelectedByUser;
        }
        // selected by appal is not empty and selcted by user returned only default values => return selected by appeal
        return newSelectedByAppeal;
    }

    function changeFormDestination(id, newDest) {
        const newDestination = newDest.length > 0 ? newDest : [""];
        props.change(id, "destination", newDestination);
        // old logic caused reinitialization of DynamicForm, which triggered the scrollbar position to the top
        // setFormForceInitValues(id, { destination: newDestination });
    }

    // to check what is route?
    function changeFormRoute(id, newRoutes) {
        // console.log({id, newRoutes});
        newRoutes.forEach(route => {
            props.change(id, "route", route);
        });
        // props.change(id, 'route', get(newDest, 'object.route'));
    }

    function updateFormRouteAndDest(destinations, id) {
        const { newDest, newRoutes } = getNewSelectedDestinationAndRoutes(destinations);
        const isSomeInitMissed = destination.some(item => !newDest.includes(item));
        const isSomeAdded = newDest.some(item => !destination.includes(item));
        const isChanged = isSomeInitMissed || isSomeAdded;
        if (isChanged) {
            handleLockAppealForm("form");
        }
        changeFormRoute(id, newRoutes); // to check what is route?
        changeFormDestination(id, newDest);
        // console.log("*** UNLOCK DESTINATIONS ***");
        handleRemoveAppealLockSource("destinations");
    }

    function reloadDestinations() {
        if (!appeal) {
            return;
        }
        console.log(
            'Appeal::reloadDestinations: Either customer or refresh actions received, or "destination" initially opened => refresh destinations.'
        );
        if (appeal.id && appealTypeId) {
            handleLockAppealForm("destinations");
            props.getAppealDestinations(appeal.id, appealTypeId)
            .then(result => {
                updateFormRouteAndDest(result, appeal.id);
            })
            .catch(e => {
                handleRemoveAppealLockSource("destinations");
                console.error("Appeal::refetchDestinations: Error: ", e);
            });
        }
    }

    async function requestInitialData(isRefresh) {
        if (isRefresh) {
            props.destroyForm(`appeal-form-${id}`);
            setIsNeededRefresh(true);
        }

        const appeal = await props.getAppeal({ id }, false, !!incomePhoneNumber);

        if (!appeal) {
            return;
        }

        let appealDataSet = [
            // props.getAppealStatusList(appeal.id, appeal.typeId), // getAppealStatusList is now called within [appealTypeId] useEffect
            // props.getAppealDestinations(appeal.id, appeal.typeCode),
            props.getDynamicFormParams({
                ctxObjectId: appeal.id,
                objectType: "appeal",
                objectTypeId: appeal.typeId,
                objectId: appeal.id,
                objectTypeCode: appeal.typeCode,
                openMode: "create",
            }),
            props.getAppealActions(appeal, [appeal.destinationId], true),
        ];

        if (!priorityList) {
            appealDataSet = [...appealDataSet, props.getAppealPriorityList(id)];
        }

        if (appeal.customerId > 0) {
            appealDataSet = [
                ...appealDataSet,
                // props.getCustomer({ customerId: appeal.customerId }, appeal.customerId),
                props.getAppealFeedback({ appealId: appeal.id, customerId: appeal.customerId }),
            ];

            if (appeal.contactPersonId > 0) {
                appealDataSet = [...appealDataSet];
            }
        }

        const dataSetReady = await Promise.all(appealDataSet);

        // check destinations
        if (dataSetReady) {
            props.appealFetching(id);
        }
    }

    async function postSave({ saveAndCreateNew, appealId }) {
        const saveAndClose = localStorage.getItem("saveAndClose") === "true";
        setAppealIdCentralPartLoading(appealId, true);

        if (saveAndClose) {
            setAppealIdCentralPartLoading(appealId, false);
            closeAppeal({ ...props, id: appealId, lastTab: props.tabs.length === 1 });
            props.removeTab(
                { tabType: props.currentTab.type, history },
                { history, tabs: props.tabs, current: props.currentTab, currentSuperTabPath: props.currentSuperTabPath }
            );
        } 

        if (!saveAndClose || saveAndCreateNew) {
            const updatedAppealData = await props.getAppeal({ id: appealId }, true);
            if (saveAndCreateNew) {
                const appealRequestData = {};
    
                if (updatedAppealData.customerId) {
                    appealRequestData.customerId = updatedAppealData.customerId;
                }
    
                if (updatedAppealData.contactPersonId) {
                    appealRequestData.contactPersonId = updatedAppealData.contactPersonId;
                }
                
                props.createNewAppeal({ appealRequestData }).then(newAppeal => {
                    props.history.push(`/appeals/${newAppeal.id}/`);
                });
            }
            // if saveAndClose then it doesnt make sence to update data for closed appeal
            if (!saveAndClose) {
                await Promise.all([
                    props.setRequireDynParams(appealId, false),
                    props.setRequiredCustomer(appealId, false),
                    props.change(appealId, "destination", [updatedAppealData.destinationId]),
                    props.change(appealId, "resolveDate", updatedAppealData.resolveDate.resolveDate),
                    props.getAppealActions(updatedAppealData, [updatedAppealData.destinationId], true),
                    props.getAppealStatusList(updatedAppealData.id, updatedAppealData.typeId),
                    props.setTabName(`appeals_${updatedAppealData.id}`, updatedAppealData.regnum),
                    props.updateCreatedAppealName({ id: updatedAppealData.id, name: updatedAppealData.regnum }),
                ]);
    
                const res = await props.getDynamicFormParams({
                    ctxObjectId: appealId,
                    objectType: "appeal",
                    objectTypeId: updatedAppealData.typeId,
                    objectId: appealId,
                    objectTypeCode: appeal.typeCode,
                    openMode: "create",
                });
                if (res) {
                    handleNeedReinitialize(appealId, true);
                }
            }
            setAppealIdCentralPartLoading(appealId, false);
        }
    }

    async function resetAppealType(appealType, previousType) {
        // console.log({appealType, previousType});
        const [newDestinations, newFormParams] = await Promise.all([
            // props.getAppealDestinations(appeal.id, appealType.code),
            props.getAppealDestinations(appeal.id, appealType.id),

            props.getDynamicFormParams({
                ctxObjectId: appeal.id,
                objectType: "appeal",
                objectTypeId: appealType.id,
                objectId: appeal.id,
                objectTypeCode: appealType.code,
                prevObjectTypeId: previousType.id,
                openMode: "create",
            }),
        ]);

        handleNeedReinitialize(appeal.id, true);

        updateFormRouteAndDest(newDestinations, appeal.id);

        return [newDestinations, newFormParams];
    }

    function resetActions({ remove, change, position }) {
        const requestDestination = props.destination && Array.isArray(props.destination) ? [...props.destination] : [];

        if (requestDestination[remove]) {
            props.getAppealActions(appeal, requestDestination.splice(remove, 1));
        }

        if (change) {
            requestDestination[position] = change;
            props.getAppealActions(appeal, requestDestination);
        }
    }

    if (appealIsBlocked) {
        return <BlockedAppeal />;
    }

    if (!props.fetching || !appeal) {
        return (
            <div className="wrapper">
                <Loader withContainer />
            </div>
        );
    }

    return (
        <div className="wrapper">
            <CustomerPanel
                onAppealDestinationsReload={reloadDestinations}
                unlockAppealForm={handleLockAppealForm}
                removeAppealLockSource={handleRemoveAppealLockSource}
                isLockedByOther={isLockedByOther}
                isLockingAppeal={isLockingAppeal}
            />
            <AppealMainContent
                resetAppealType={resetAppealType}
                needReinitialize={needReinitialize[id]}
                setNeedReinitialize={handleNeedReinitialize}
                requestInitialData={requestInitialData}
                resetActions={resetActions}
                postSave={postSave}
                onDestinationOpen={handleDestinationOpen}
                unlockAppealForm={handleLockAppealForm}
                removeAppealLockSource={handleRemoveAppealLockSource}
                unlockAppealAndClearSource={unlockAppealAndClearSource}
                operators={operators}
                isLockedByOther={isLockedByOther}
                lockSourceList={appealLockSourceList}
                lockError={appealLockError}
                savingAppeal={savingAppeal}
                isCentralPartLoading={isCentralPartLoading}
                // isCentralPartLoading={isCentralPartLoading[id]}
                loadingParams={loadingParams}
                unlockedAppeal={unlockedAppeal}
            />
        </div>
    );
}

function mapStateToProps(state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const customer = extractCustomerFromState(state, _.get(appeal, "currentAppeal.customerId"));

    return {
        id,
        statusListInitializedType: appeal.statusListInitializedType,
        destinations: appeal.destinations,
        appeal: appeal.currentAppeal,
        isLockingAppeal: appeal.unlockAppeal,
        isUnlockingAppeal: appeal.isUnlocking,
        unlockedAppeal: appeal.unlockedAppeal,
        appealLockSourceList: appeal.lockSourceList,
        appealLockError: appeal.lockError,
        appeals: state.appeal.appeals,
        saveAndCreateNew: appeal.saveAndCreateNew,
        fetching: appeal.fetching,
        appealLoading: appeal.appealLoading,
        appealIsBlocked: appeal.appealIsBlocked,
        appealTypes: state.appeal.appealTypes,
        isRefreshAppealNeeded: appeal.isRefreshAppealNeeded,
        priorityList: state.appeal.priorityList,

        savingAppeal: appeal.savingAppeal,
        isCentralPartLoading: appeal.isCentralPartLoading,
        loadingParams: appeal.loadingParams,

        destination: formValueSelector(`appeal-form-${id}`)(state, "destination"),
        appealDestination: get(appeal, "currentAppeal.destinationId"),
        route: formValueSelector(`appeal-form-${id}`)(state, "route.key"),

        formValues: getFormValues(`appeal-form-${id}`)(state),

        forms: state.form,

        tabs: getTabs(state),
        currentTab: state.tabs.current,

        customer: customer.currentCustomer,
        // customerId: get(customer, 'currentCustomer.id'),
        // customerId: get(appeal, 'currentAppeal.customerId'),
        isDestinationsNeedRefresh: get(appeal, "isDestinationsNeedRefresh"),

        isCustomerLoading: customer.customerLoading,

        incomePhoneNumber: state.call.incomePhoneNumber,
        currentSuperTabPath: state.tabs.currentSuperTabPath
    };
}

const mapDispatchToProps = {
    getAppeal,
    getAppealTypesList,
    lockAppealForm,
    getAppealStatusList,
    getAppealPriorityList,
    appealFetching,
    getAppealFeedback,
    getDynamicFormParams,
    getAppealDestinations,
    getAppealActions,
    createNewAppeal,
    setSaveAndCreateNew,
    clearAppealState,
    clearAllAppeals,
    setRequireDynParams,
    setRequiredCustomer,

    getCustomer,
    clearCustomerState,
    getContactPerson,
    clearContactPersonState,
    clearEntireCustomerState,
    clearAllCustomers,

    setTabName,
    setCurrentTab,
    removeTab,

    updateCreatedAppealName,

    // updateCurrentTabUrl,

    unlockAppealForm,
    unlockAppealAndClearSource,
    removeAppealLockSource,
    // unlockAppealAndClearSourceWithoutForm,
    setAppealIdCentralPartLoading,
    setFormForceInitValues,
    // unlockAppeal,

    change: (id, field, value) => change(`appeal-form-${id}`, field, value),
    destroyForm: form => destroy(form),
};

// export default connect(mapStateToProps, mapDispatchToProps)(Appeal);

export default Proxy(connect(mapStateToProps, mapDispatchToProps)(Appeal), {
    noRequest: true,
    tabType: data => data && `appeals_${data.appealId}`,
    name: data => data && `appeals_${data.appealId}`,
    queryKey: data => data && `appeals_${data.appealId}`,
    storeName: () => "appeals",
});

// export default Proxy(TabContent, {
// 	noRequest: true,
// 	tabType: data => data && `appeals_${data.appealId}`,
// 	name: data => data && `appeals_${data.appealId}`,
// 	queryKey: data => data && `appeals_${data.appealId}_${data.tab}`,
// 	storeName: data => data && data.tab
// });
