import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, formValueSelector, getFormValues } from 'redux-form';
import 'styles/shared.scss';

import { withTranslation } from 'react-i18next';
// import { createSelector } from 'reselect';
import KnowledgeBaseItemField from './KnowledgeBaseItemField';
import get from 'lodash/get';
// import StatusModal from '../StatusSelect/StatusModal';
import { ModalFields } from '../StatusSelect/constants';
import { setModalStatusLIst, setAppealStatusListTemplateData, setFormForceInitValues } from 'modules/appeal/actions';
import { searchTree } from 'helpers';
import { extractAppealFromState } from '../../../helpers';

const LOCK_SOURCE_FORM = "form";

function mapStateToProps(state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const formSelector = formValueSelector(`appeal-form-${id}`);
    const dynParamFormValues = getFormValues(`kb-dynparam-form-${id}`)(state);
    const appealFormValues = getFormValues(`appeal-form-${id}`)(state);

    return {
        ...formSelector(state, 'subject', 'description', 'solution', 'type'),
        id,
        dynParamFormValues,
        appealFormValues,
        statusList: appeal.statusList,
        appealRestriction: appeal.currentAppeal.restriction,
        destinations: appeal.destinations,
        destinationHash: appeal.destinationHash,
        appealTypes: state.appeal.appealTypes,
		formParams: appeal.formParams,
    };
}

const mapDispatchToProps = {
    change: (field, value, id) => change(`appeal-form-${id}`, field, value),
    setModalStatusLIst,
    setAppealStatusListTemplateData,
    setFormForceInitValues
};

@withRouter
@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class KnowledgeBaseItem extends React.Component {
    constructor() {
        super();

        this.state = {
            updateExecutor: true,
        };

        this.resolveStatusModal = null;
        this.rejectStatusModal = null;

        this.getNewFormValue = this.constructor.getNewFormValue;
        this.renderItemField = this.renderItemField.bind(this);
        this.canFillField = this.canFillField.bind(this);
        this.fillForm = this.fillForm.bind(this);
        this.getFieldsConfig = this.getFieldsConfig.bind(this);
        this.shouldRenderFillFormButton = this.shouldRenderFillFormButton.bind(this);
        this.getNextStatusList = this.getNextStatusList.bind(this);
        this.dynamicParamsFilter = this.dynamicParamsFilter.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { destinations, destinationHash, id } = this.props;
        const { updateExecutor } = this.state;
        if (destinationHash !== prevProps.destinationHash && updateExecutor && destinations) {
            try {
                const result = searchTree(destinations, 'result', (i) => i.object.isDefault);
                if (result) {
                    this.props.change('destination[0]', result.object.id, id);
                } 
            } catch(e) {
                console.error("KnowledgeBaseItem::componentDidUpdate: Search tree probably failed ", e);
            }
        }
    }

    static getNewFormValue(current, next) {
        if (current && next) {
            return `${current}  ${next}`;
        } else {
            return current || next;
        }
    }

    canFillField(path) {
        const restrictionValue = get(this.props.appealRestriction, path);
        return restrictionValue === 3;
    }

    dynamicParamsFilter(block, shouldRenderField) {
        return (
            block.action !== "del" &&
            block.widgets &&
            block.widgets.length &&
            block.widgets.some(widget => shouldRenderField(widget.key))
        );
    }

    async fillForm() {
        const {
            item,
            description: desc,
            subject: sbj,
            solution: sltn,
            id,
            dynParamFormValues,
            appealFormValues,
            statusList,
            formParams,
            unlockAppealForm
        } = this.props;
        const solution = sltn || '';
        const description = desc || '';
        const subject = sbj || '';

        let dynFormParams = formParams;
        if (this.canFillField('description')) {
            this.props.change(
                'description',
                this.getNewFormValue(description, item.description || '').substring(0, 4000),
                id,
            );
            unlockAppealForm(LOCK_SOURCE_FORM);
        }
        if (this.canFillField('subject')) {
            this.props.change(
                'subject',
                this.getNewFormValue(subject, item.subject || '').substring(0, 255),
                id,
            );
            unlockAppealForm(LOCK_SOURCE_FORM);
        }

        if (this.canFillField('solution')) {
            this.props.change(
                'solution',
                this.getNewFormValue(solution, item.solution || '').substring(0, 2000),
                id,
            );
            unlockAppealForm(LOCK_SOURCE_FORM);
        }

        if (this.canFillField('type')) {
            if (item.requestTypeId) {
                const previosId = _.get(this.props, 'type.id', null);
                const newType = {
                    ...item,
                    code: item.requestTypeCode,
                    fullLabelPath: (_.get(item, 'requestTypeName') || '')
                        .split('\\')
                        .filter((i) => i !== '')
                        .join(' / '),
                    id: item.requestTypeId,
                    isCustReq: item.requiredObjects && item.requiredObjects.includes('CUSTOMER') || item.isCustReq,

                    isStatusFromTemplate: item.status && item.status.code,
                    isResolutionFromTemplate: item.status && item.status.resolution
                };

                // if (previosId !== newType.id) {
                this.props.change('type', newType, id);
                const [newDests, newParams] = await this.props.resetAppealType(newType, { id: previosId });
                if (newParams) {
                    dynFormParams = newParams;
                }
            }
        }
        if (item.status) {
            const code = item.status.code;
            let isExist = false;
            statusList.forEach((status) => {
                if (status.code === code) {
                    isExist = true;
                }
                if (status.nextState) {
                    status.nextState.forEach((next) => {
                        if (next.code === code) {
                            isExist = true;
                        }
                    });
                }
            });
            if (isExist) {
                const foundStatus = statusList.find((status) => status.code === item.status.code);
                if (foundStatus) {

                    let isFillOk = true;
                    if (this.canFillField('type')) {
                        // prevent from status/res fill on type change (types are differ)
                        if (item.requestTypeId && this.props.type && item.requestTypeId !== this.props.type.id) {
                            isFillOk = false;
                        }
                    }
                    if (isFillOk) {
                        unlockAppealForm(LOCK_SOURCE_FORM);
                        this.props.setAppealStatusListTemplateData(id, item.status.code, item.status.resolution);
                    }
                }
            }
        }



        if (this.canFillField('destination') && item.setDefaultDestination) {
            this.setState({ updateExecutor: true });
        }

        if (item.requestTypeId && dynParamFormValues) {
            // filter blocks by isCanFilled and filter out empty params (such as empty files array)
            const restrictedBlocks = _.pickBy(dynParamFormValues.block, (item, key) => {
                    const isCanFilled = this.canFillField(key);
                    const isEmpty = _.isEmpty(item);
                    return isCanFilled && !isEmpty;
                }
            );
            if (!_.isEmpty(restrictedBlocks)) {
                const kbBlocks = _.pickBy(restrictedBlocks, _.identity);
                const newBlocksAdapted = { ...kbBlocks };
                
                const filteredBlocks = dynFormParams.blocks.filter(block =>
                    this.dynamicParamsFilter(block, this.props.shouldRenderField)
                );

                const appealFormWidgets = filteredBlocks.flatMap(block => block.widgets).map(widget => widget);
                // filter out fileSingle fileMultiple
                const appealFormWidgetsWithoutFiles = appealFormWidgets.filter(widget => !widget.widgetType.startsWith("file"));
                const appealFormKeysWithoutFiles  = appealFormWidgetsWithoutFiles.map(widget => widget.key);

                // handle no files widgets values fill
                appealFormKeysWithoutFiles.forEach(key => {
                    if (Array.isArray(kbBlocks[key])) {
                        // console.log({key, kbBlocksKey: kbBlocks[ket]});
                        const prevValue = appealFormValues[key] || [];
                        // if array is being set - make sure that it makes a set (to not duplicate values in case of multiselect)
                        newBlocksAdapted[key] = [...new Set([...prevValue, ...kbBlocks[key]])];
                    } else {
                        newBlocksAdapted[key] = kbBlocks[key] || appealFormValues[key];
                    }
                });

                // fileSingle fileMultiple
                const appealFormWidgetsFiles = appealFormWidgets.filter(widget => widget.widgetType.startsWith("file"));
                const appealFormKeysFiles = appealFormWidgetsFiles.map(widget => widget.key);

                // handle files widgets values fill
                appealFormKeysFiles.forEach(key => {
                    const appealValues = appealFormValues[key] || [];
                    const value = kbBlocks[key];
                    if (value) {
                        const kbValues = value.map(file => file.id);
                        // newBlocksAdapted[key] = value.map(file => file.id);
                        newBlocksAdapted[key] = [...kbValues, ...appealValues];
                        // to extend current 'values' widget param based on kb file 
                        const values = value.map(file => {
                            if (typeof file === 'object') {
                                let key = file.key;
                                if ('id' in file) {
                                    key = file.id;
                                }
                                let value = file.value;
                                if ('name' in file) {
                                    value = file.name;
                                }
                                return {key, value};
                            }
                            return file;
                        });
                        const appealCachedValues = appealFormValues[`${key}_cached_file_values`] || [];
                        newBlocksAdapted[`${key}_cached_file_values`] = [...appealCachedValues, ...values];
                    }
                });
                
                // handle date datetime correct initialisation (to be removed once knowledgebase form will be migrated to ordering dynamic form)
                const appealFormWidgetsDate = appealFormWidgets.filter(widget => widget.widgetType.startsWith("date"));
                const appealFormKeysDates = appealFormWidgetsDate.map(widget => widget.key);
                const kbDynParamsWidgetsDate =
                    item &&
                    item.dynParams &&
                    item.dynParams
                        .flatMap(block => block.widgets)
                        .filter(widget => widget.widgetType.startsWith("date"))
                        .reduce((acc, curr) => ({...acc, [curr.key]: curr.savedValue}), {});
                // handle dates widgets values fill
                appealFormKeysDates.forEach(key => {
                    newBlocksAdapted[key] = kbDynParamsWidgetsDate[key];
                });

                this.props.setFormForceInitValues(id, newBlocksAdapted);
            }
        }
    }

    saveStatusInfo(values) {
        const saveValue = (field) => {
            const value = values[field] || null;

            if (this.props[field] !== value) {
                this.props.change(field, value, this.props.id);
            }
        };

        Object.values(ModalFields).forEach(saveValue);
    }

    renderItemField(item, index) {
        const { id } = this.props;
        if (!item.value) return null;

        return <KnowledgeBaseItemField field={item} form={`kb-dynparam-form-${id}`} key={index}/>;
    }

    getFieldsConfig() {
        const { item, t } = this.props;

        const config = [
            { label: t('knowledgeBase.text'), value: item.answer, name: 'answer' },
            { label: t('knowledgeBase.materials'), value: item.material, name: 'material' },
        ];

        if (item.files && item.files.length) {
            config.push({ label: t('knowledgeBase.file'), value: item.files, name: 'file' });
        }

        config.push({ label: t('knowledgeBase.subject'), value: item.subject, name: 'subject' });
        config.push({
            label: t('knowledgeBase.description'),
            value: item.description,
            name: 'description',
        });
        config.push({ label: t('knowledgeBase.solution'), value: item.solution, name: 'solution' });

        if (item.requestTypeName) {
            config.push({
                label: t('knowledgeBase.type'),
                value: item.requestTypeName
                    .split('\\')
                    .filter((i) => i !== '')
                    .join(' / '),
                name: 'requestTypeName',
            });
        }
        if (item.dynParams) {
            config.push({ label: '', value: item.dynParams, name: 'dynParams' });
        }

        if (item.status && item.status.code) {
            config.push({ label: t('knowledgeBase.status'), value: item.status, name: 'status' });
        }
        config.push({
            checkBoxLabel: t('knowledgeBase.setDefaultDestination'),
            value: item.setDefaultDestination,
            name: 'checkbox',
        });

        return config;
    }

    shouldRenderFillFormButton() {
        return (
            this.canFillField('statusId') ||
            this.canFillField('description') ||
            this.canFillField('solution') ||
            this.canFillField('subject')
        );
    }

    getNextStatusList() {
        const foundStatus = this.props.statusList.find(
            (status) => status.code === this.props.item.status.code,
        );

        return foundStatus ? foundStatus.nextState : [];
    }

    render() {
        const { item, t } = this.props;

        const config = this.getFieldsConfig();

        return (
            <div className="knowledgeBaseItem">
                <div className="linkWrapper">
                    <span className="link">
                        <i className="icon-file" />
                        <span>{item.name}</span>
                    </span>
                    {this.shouldRenderFillFormButton() && (
                        <button className="btn-save" onClick={this.fillForm}>
                            <i className="icon-out" />
                            {t('knowledgeBase.setFromTemplate')}
                        </button>
                    )}
                </div>

                {config.map(this.renderItemField)}
            </div>
        );
    }
}

KnowledgeBaseItem.propTypes = {
    item: PropTypes.object,
    unlockAppealForm: PropTypes.func,
    resetAppealType: PropTypes.func,
};

export default KnowledgeBaseItem;
