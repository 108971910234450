import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "components/CustomerPage/styles.scss";
import tabsParams from "constants/tabsParams";
import downloadCSV from "actions/downloadCSV";

import { openCreateAppealFromFileModal } from "actions/ui";

import { createNewAppeal } from "modules/appeal/actions";
import { personEdit, getContactPerson } from "actions/customer";
import { setTabName } from "actions/tabs";
import Avatar from "components/Avatar";
import Loader from "components/Loader";
import EditCustomerNameForm from "components/CustomerPage/EditCustomerNameForm";
import TabContent from "./TabContent";
import AppealsTabContent from "./AppealsTabContent";
import Popup from "components/Popup";
import NewEntityButton from "components/CustomerPage/NewEntityButton";
import { RouteWithTabs } from "helpers";
import { CustomerTypes } from "constants/index";
import permissions, { checkPermissions } from "config/permissions";
import HistoryTab from "../../HistoryTab";

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createNewAppeal,
            personEdit,
            setTabName,
            getContactPerson,
            openCreateAppealFromFileModal,
        },
        dispatch
    );
}

function mapStateToProps(state, props) {
    // const { customerId, id } = props;
    // const gridId = id ? `${customerId}.${id}` : customerId;
    const gridId = state.grid && state.grid.gridKey;
    // editingPerson
    const personId = props.contactPerson.id;
    const persons = state.customer.persons;
    const personData = personId && persons && persons[personId];
    return {
        managedContactPerson: checkPermissions(permissions.ContactPersonOperations.action_edit_contactPerson),
        createAppeal: checkPermissions(permissions.AppealOperations.action_createAppeal),
        isContactsView: checkPermissions(permissions.CustomerOperations.view_contacts),
        isHistoryView: checkPermissions(permissions.HistoryOperations.view_history),
        tabs: state.tabs,
        currentTab: state.tabs.current,
        grid: _.get(state.grid, gridId),
        // grid: state.grid,
        isCreateAppealFromExcel: checkPermissions(permissions.AppealOperations.action_createAppealGroupFromClient),
        isContactPersonUpdating: personData && personData.editingPerson,
    };
}

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gridPopupIsOpen: false,
            isEditingName: false,
        };

        this.wait = false;
        this.tabNamesCP = {
            tabsNames: {
                appeals: "appeals",
                link: "link",
                history: "history",
            },
        };
        this.setIsEditingName = this.setIsEditingName.bind(this);
    }

    componentDidMount() {
        const { tabs, personName, setTabName } = this.props;
        if (!tabs.current.displayedName) {
            setTabName(tabs.current.name, personName);
        }
    }

    componentDidUpdate(prevProps) {
        const { tabs, personName, setTabName } = this.props;
        // if (!tabs.current.displayedName  && personName !== prevProps.personName && _.get(tabs, 'current.type').includes('person')) {
        //     setTabName(tabs.current.name, personName);
        // }
        if (!tabs.current.displayedName && _.get(tabs, "current.type").includes("person")) {
            setTabName(tabs.current.name, personName);
        }
    }

    getEditFormInitialValues = () => {
        const { name } = this.props.contactPerson;
        const fields = ["surname", "name", "patronymic"];
        const initialValues = {};
        name.split(" ").forEach((item, index) => {
            const value = fields[index];
            initialValues[value] = item;
        });
        return initialValues;
    };

    toggleGridPopup = () => this.setState(prevState => ({ gridPopupIsOpen: !prevState.gridPopupIsOpen }));

    handleSubmit = async values => {
        const { personEdit, contactPerson, getContactPerson, currentTab, setTabName, customerId } = this.props;

        await personEdit({
            data: {
                edit: [
                    { text: values.lastName, field: "party.lastName" },
                    { text: values.firstName, field: "party.firstName" },
                    { text: values.patronymic, field: "party.patronymic" },
                ],
                personId: contactPerson.id,
            },
            id: contactPerson.id,
            isMulti: true,
            isUpdateCustomer: false,
        });

        // update tab name on success customer data change
        const onGetContactPersonSuccess = result => {
            const [, mainTab] = this.props.match.path.split("/");
            // only update tab name for customer tabs
            if (mainTab === "customer") {
                setTabName(currentTab.name, name);
            }
        };

        // await getContactPerson({ id: contactPerson.id }, customerId);
        // console.log({contactPersonId: contactPerson.id, customerId: customerId});
        await getContactPerson({ id: contactPerson.id }, customerId, true, onGetContactPersonSuccess);
    };

    export = () => {
        const {
            customerId,
            contactPerson: { id: contactPersonId },
        } = this.props;
        const appealFields = Object.entries(tabsParams(this.props.t).filter).reduce((acc, [key, value]) => {
            const nameParts = [key];
            if (value.objectField) nameParts.push(value.objectField);

            acc[nameParts.join(".")] = value.name;

            return acc;
        }, {});

        const requestData = {
            fields: appealFields,
            customerId,
            contactPersonId,
            start: null,
            limit: null,
        };

        const gridSort = _.get(this.props.grid, "params.sort");

        if (gridSort.field && gridSort.order) {
            requestData["sort"] = [
                {
                    property: gridSort.field,
                    direction: gridSort.order.toUpperCase(),
                },
            ];
        }

        // if (this.props.gridSort.field && this.props.gridSort.order) {
        //     requestData['sort'] = [
        //         {
        //             property: this.props.gridSort.field,
        //             direction: this.props.gridSort.order.toUpperCase(),
        //         },
        //     ];
        // }

        downloadCSV({ requestData, key: "appeal_customer_csv" }, this.toggleGridPopup);
    };

    onNewAppealClick = () => {
        if (this.wait) return;
        this.wait = true;

        const { match, contactPerson } = this.props;
        const newCustomerId = match.params.customerId || contactPerson.links[0].entityId;

        const appealRequestData = {
            customerId: newCustomerId,
            contactPersonId: contactPerson.id,
        };

        this.props.createNewAppeal({ appealRequestData }).then(appeal => {
            if (appeal) this.props.history.push(`/appeals/${appeal.id}/`);
            this.wait = false;
        });
    };

    setIsEditingName(value) {
        this.setState({ isEditingName: value });
    }

    renderTabs = props => {
        const { match, location, customerId, isHistoryView, isContactsView } = this.props;
        switch (props.match.params.subTab) {
            case "appeals":
                return (
                    <AppealsTabContent
                        match={match}
                        customerId={customerId}
                        isHistoryView={isHistoryView}
                        isContactsView={isContactsView}
                    />
                );
            case "link":
                return <TabContent isContactsView match={match} location={location} isHistoryView={isHistoryView} />;
            case "history":
                return <HistoryTab id={match.params.id} tabProps={this.tabNamesCP} objectType={"CONTACT_PERSON"} />;
            default:
                return null;
        }
    };

    render() {
        const {
            t,
            personName,
            contactPerson,
            match,
            location,
            managedContactPerson,
            createAppeal,
            isContactsView,
            isHistoryView,
            customerId,
            id,
            isCreateAppealFromExcel,
            isContactPersonUpdating,
        } = this.props;

        const { gridPopupIsOpen, isEditingName } = this.state;
        let tabs = ["link"];

        if (isHistoryView) {
            tabs = ["history", ...tabs];
        }
        if (isContactsView) {
            tabs = ["appeals", ...tabs];
        }

        return (
            <main className="customerContent">
                <header className="customerContentHeader">
                    <div className="headerLeft">
                        {isContactPersonUpdating && <Loader />}
                        <Avatar
                            name={personName}
                            customerType={CustomerTypes.NATURAL_PERSON}
                            handleClick={() => this.setIsEditingName(true)}
                        />
                        <EditCustomerNameForm
                            form={`${customerId}-edit-person-name`}
                            onSubmit={this.handleSubmit}
                            isInEdit={isEditingName}
                            setIsInEdit={this.setIsEditingName}
                            initialValues={{
                                name: personName.trim(),
                                firstName: contactPerson.party.firstName,
                                lastName: contactPerson.party.lastName,
                                patronymic: contactPerson.party.patronymic,
                            }}
                            managedContactPerson={managedContactPerson}
                            key={personName.trim()}
                        />
                    </div>
                    <div className="actions">
                        {createAppeal ? (
                            <NewEntityButton title="newEntityButton.appeals" onClick={this.onNewAppealClick} />
                        ) : null}

                        <div className="btn-add" onClick={this.toggleGridPopup}>
                            <i className="icon-kebab-vert" />
                        </div>
                        {gridPopupIsOpen && contactPerson && match.params.subTab === "appeals" && (
                            <Popup place="customer-grid-popup" onClickOutside={this.toggleGridPopup}>
                                <button onClick={this.export} className="popup-link">
                                    {t("filters.exportExcel")}
                                </button>
                                {isCreateAppealFromExcel && (
                                    <button
                                        onClick={() =>
                                            this.props.openCreateAppealFromFileModal({
                                                customerId,
                                                contactPersonId: contactPerson && contactPerson.id,
                                            })
                                        }
                                        className="popup-link"
                                    >
                                        {t("filters.createFromFile")}
                                    </button>
                                )}
                            </Popup>
                        )}
                    </div>
                </header>

                <Switch>
                    <RouteWithTabs
                        path="/customer/:customerId/person/:id/:subTab?"
                        render={this.renderTabs}
                        tabs={tabs}
                        tabPath={"subTab"}
                        match={match}
                        location={location}
                    />
                    <RouteWithTabs
                        path="/appeals/:appealId/person/:id/:subTab?"
                        render={this.renderTabs}
                        tabs={tabs}
                        tabPath={"subTab"}
                        match={match}
                        location={location}
                    />
                    <Route
                        exact
                        strict
                        path="/appeals/:customerId/:appealId/person/:id"
                        render={props => <Redirect to={`${props.location.pathname}/appeals`} />}
                    />
                </Switch>
            </main>
        );
    }
}

MainContent.propTypes = {
    contactPerson: PropTypes.object.isRequired,
    personName: PropTypes.string,
};

export default MainContent;
