import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import { getFormSyncErrors, isValid, getFormValues } from 'redux-form';
import ModalPortal from '../../components/ModalPortal';
import MessageModal from '../../components/MessageModal';
import { CSSTransition } from 'react-transition-group';
const filterFormName = 'search-filters-';
import { useTranslation } from 'react-i18next';
import permissions, { checkPermissions } from 'config/permissions';
import { configureFilterObject } from '../SearchFilters/helpers';
import { get } from 'lodash';

const mapStateToProps = (state, props) => {
    const { hash, entity } = props.match.params;
    const { searchId } = props;
    return {
        query: state.search.searches[hash] || '',
        formValues: getFormValues(`${filterFormName}${searchId}`)(state),
        allFilterFields: get(state, `searchFilter.allFilterFields[#${entity}]`, []),
        filerSyncError: getFormSyncErrors(`${filterFormName}${searchId}`)(state),
        isValid: isValid(`${filterFormName}${searchId}`)(state),
    };
};

const EventsList = (props) => {
    const { updateApiGrid, formValues, query, allFilterFields } = props;
    const { hash } = props.match.params;
    const [loadingState, setLoadingState] = useState(false);
    const history = useHistory();
    const [modalEventData, setModalEventData] = useState(null);
    const { t } = useTranslation();
    const isCanEventsView = checkPermissions(permissions.CalendarOperations.view_calendar_event);


    const init = {
        key: 'calendar_events',
        extra: hash,
        query,
        code: 'FTOP_SEARCH_CLEVENT',
        method: 'post',
        // isDynamic: true,
        configUrlKey: "config_grid",
        configKey: 'calendar_events',
        configCode: 'FTOP_CLEVENT',
    };
    const gridKey = `${init.key}_${init.path || init.extra || ''}`;

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
        if (formValues || ![null, undefined].includes(query)) {
            const filterObject = configureFilterObject(formValues || {}, allFilterFields);
            updateApiGrid({ query, type: 'clevent', filterObject }, gridKey);
        }
    }, [formValues, query]);

    useEffect(() => {
        const filterObject = configureFilterObject(formValues || {}, allFilterFields);
        updateApiGrid({ start: 0, limit: 20, query, type: 'clevent', filterObject }, gridKey);
    }, [hash]);

    const setOpenMode = (mode) => {
        if (!isCanEventsView) return;

        setModalEventData(null);
        return history.push(`${modalEventData.url}/${mode}`);
    };

    const getOpenModesForModal = () => {
        if (modalEventData && modalEventData.allowModes) {
            switch (modalEventData.allowModes.length) {
                case 2:
                    return {
                        primaryButton: true,
                        secondaryButton: true,
                        primaryButtonText: modalEventData.allowModes[0].label,
                        onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
                        secondaryButtonText: modalEventData.allowModes[1].label,
                        onClickSecondaryButton: () =>
                            setOpenMode(modalEventData.allowModes[1].code),
                    };
                case 3:
                    return {
                        dangerButton: true,
                        primaryButton: true,
                        secondaryButton: true,
                        primaryButtonText: modalEventData.allowModes[0].label,
                        onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
                        secondaryButtonText: modalEventData.allowModes[1].label,
                        onClickSecondaryButton: () =>
                            setOpenMode(modalEventData.allowModes[1].code),
                        dangerButtonText: modalEventData.allowModes[2].label,
                        onClickDangerButton: () => setOpenMode(modalEventData.allowModes[2].code),
                    };
            }
        }
    };

    const handleSelect = (event) => {
        if (!isCanEventsView) return;

        if (event.allowModes && event.allowModes.length > 1) {
            return setModalEventData({
                url: event.url,
                allowModes: event.allowModes,
            });
        }
        return history.push(`${event.url}/${event.allowModes[0].code}`);
    };

    return (
        <>
            <div className="content-wrapper ordering-component-ui-core-wrapper with-fixed-status customer-ordering-grid">
                <div className="content-wrapper">
                    {loadingState && <Loader />}
                    <GridWrapper
                        tuner={() => init}
                        gridParams={{
                            selectItem: handleSelect,
                        }}
                        initUpdate={false}
                        onStart={onStart}
                        onFinish={onFinish}
                    />
                </div>
            </div>
            <CSSTransition
                in={modalEventData}
                classNames="fade"
                appear
                enter
                exit
                timeout={300}
                mountOnEnter
                unmountOnExit
            >
                <ModalPortal
                    onClose={() => setModalEventData(null)}
                    className="modal-small modal-center"
                >
                    <MessageModal
                        titleModal={t('calendar.askEventModalTitle')}
                        contentModalText={t('calendar.askEventModalDescription')}
                        {...getOpenModesForModal()}
                    />
                </ModalPortal>
            </CSSTransition>
        </>
    );
};

export default withRouter(connect(mapStateToProps, { updateApiGrid: setApi })(EventsList));
