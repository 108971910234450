import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';

import cx from 'classnames';
import get from 'lodash/get';
import CheckBoxToggle from '../../../components/Common/CheckBoxToggle';
import { DinamicParamsForm } from '../../../components/KnowledgeBase/Main/utils';
import { reduxForm, initialize } from 'redux-form';
import { createCheckPropsFunction } from '../../../helpers';
import { initializeDynamicParams } from 'pages/Appeal/helpers';
import { escapeBaseTag } from 'helpers';

@reduxForm()
class KnowledgeBaseItemField extends React.Component {
    componentDidUpdate(prevProps) {
        const { field, initialize } = this.props;
        const isPropChanged = createCheckPropsFunction(prevProps.field, field);

        if (field.name === 'dynParams' && field.value && isPropChanged('value')) {
            const block = initializeDynamicParams({ blocks: field.value });
            initialize({ block });
        }
    }

    getFiles = () => {
        const { field } = this.props;

        const files = field.value.map((file) => (
            // <div className="fileLinkWrapper" key={file.id}>
            //     <a
            //         target="_blank"
            //         href={`../mw/file?fileId=${file.id}&download=0`}
            //         className="fileLink"
            //     >
            //         {file.fileName}
            //     </a>
            //     <a
            //         target="_blank"
            //         href={`../mw/file?fileId=${file.id}&download=1`}
            //         className="fileLink"
            //     >
            //         <ReactSVG className="svg-download" src="/data/svg/download.svg" />
            //     </a>
            // </div>
            <div className="kb-file-wrapper" key={file.id}>
                <a className="file-preview" target="_blank" href={`../mw/file?fileId=${file.id}&download=0`} title={file.fileName}>
                {file.fileName}
                </a>
                <div className="kb-file-actions">
                    <a className="file-download" target="_blank" href={`../mw/file?fileId=${file.id}&download=1`} title={file.fileName}>
                        <ReactSVG className="svg-download" src="/data/svg/download.svg"/>
                    </a>
                </div>
            </div>
        ));

        return <div className="filesWrapper">{files}</div>;
    };

    getStatusContent = () => {
        const { value: status } = this.props.field;

        const wrapperClassName = cx('status-select-wrapper', 'inline', 'disabled');

        const resolutionName = get(status, 'resolution.title', '');

        return (
            <div className={wrapperClassName} onClick={this.openStatusPopup}>
                {status.name && (
                    <span className={`sticker sticker-${status.code}`}>{status.name}</span>
                )}
                {resolutionName && (
                    <Fragment>
                        <span className="status-divider">|</span>
                        <span className="resolution">{resolutionName}</span>
                    </Fragment>
                )}
            </div>
        );
    };

    renderDynParams = (block) => {
        let widgets = block.widgets;

        return (
            <div className="formBlock viewMode" key={block.key}>
                {block.title && block.title !== '*' && (
                    <div className="formBlockTitle">{block.title}</div>
                )}
                {block.description && <div className="formBlockDescription">{block.description}</div>}
                <div className={cx('view', `view${block.view}`)}>
                    <DinamicParamsForm
                        blockKey={block.key}
                        widgets={widgets}
                        skipRestriction={true}
                        viewMode={true}
                    />
                </div>
            </div>
        );
    };

    renderFieldContent = () => {
        const { field } = this.props;
        switch (field.name) {
            case 'status':
                return this.getStatusContent();

            case 'file':
                return <div className="description">{this.getFiles()}</div>;

            case 'checkbox':
                return (
                    <div className="description">
                        <CheckBoxToggle
                            input={{ value: field.value }}
                            label={field.checkBoxLabel}
                        />
                    </div>
                );

            case 'dynParams':
                return field.value && field.value.map(this.renderDynParams);

            default:
                return <div dangerouslySetInnerHTML={{ __html: escapeBaseTag(field.value) }} />;
        }
    };

    render() {
        const { field } = this.props;
        return (
            <div className="block" key={field.label}>
                <div className="title">{field.label && field.label}</div>
                {this.renderFieldContent()}
            </div>
        );
    }
}

KnowledgeBaseItemField.propTypes = {
    field: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
        name: PropTypes.string,
    }),
};

export default KnowledgeBaseItemField;
