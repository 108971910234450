import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import get from 'lodash/get';

/* helpers for ComboBoxCreatable*/
/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler (func) {
	return function handleSingleChange (option) {
		func(option ? option.value : '');
	};
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler (func) {
	return function handleMultiHandler (values) {
		func(values.map(value => value.value));
	};
}

function ComboBoxCreatable(props) {
	const [createdOptions, setCreatedOptions] = useState([]);
	const {
		label,
		meta,
		input,
		options,
		multi,
		placeholder,
		newOptionCreator,
		isValidNewOption,
		t,
		...restProps
	} = props;
	const noResultsText = t('noResultsFound');
	const { value, onChange, name } = input;
	const allOptions = [
		...createdOptions,
		...options
	];
	const transformedValue = transformValue();
	
	useEffect(() => {
		if (!options.length && !createdOptions.length) {
			setCreatedOptions([]);
		}
	}, [options]);

	function arrowRender (values) {
		if (values.isOpen) {
			return <i className='icon-up' />;
		}
		
		return <i className='icon-down' />;
	}
	
	/**
	 * For single select, Redux Form keeps the value as a string, while React Select
	 * wants the value in the form { value: "grape", label: "Grape" }
	 *
	 * * For multi select, Redux Form keeps the value as array of strings, while React Select
	 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
	 */
	function transformValue () {
		if (multi && typeof value === 'string') return [];
		const defValue = { value: value, label: value };
		
		if(allOptions.length) {
			const filteredOptions = allOptions.filter(option => {
				return multi
					? value.indexOf(option.value) !== -1
					: option.value === value;
			});
			 if (!filteredOptions.length){
				return defValue;
			 }
			return multi ? filteredOptions : filteredOptions[0];
		}
		return defValue;
		
	}
	/**
	 * Keep created options in local state or they will be lost
	 * on re-render
	 */
	function onNewOptionClick (option) {
		const { props, select } = Select.Creatable.creatable;
		const { options } = props;
		
		options.unshift(option);
		select.selectValue(option);
		setCreatedOptions([option])
	}
	
	function onBlur () {
		if (get(props, 'input.onBlur')) {
			input.onBlur(value);
		}
	}
	
	return (
		<div id={restProps.id} className='input-element'>
			<div className='input-label'>{label}</div>
			<Select.Creatable
				className={cx('container-comboBox', meta && meta.touched && (meta.error || meta.warning) && 'input-field__error')}
				arrowRenderer={arrowRender}
				isValidNewOption={isValidNewOption}
				noResultsText={noResultsText}
				multi={multi}
				name={name}
				placeholder={placeholder}
				newOptionCreator={newOptionCreator}
				onSelectResetsInput={false}
				onBlurResetsInput={false}
				options={allOptions}
				onChange={multi ? multiChangeHandler(onChange): singleChangeHandler(onChange)}
				onBlur={onBlur}
				onFocus={input && input.onFocus}
				onNewOptionClick={onNewOptionClick}
				promptTextCreator={(name) => `${t('filters.createNewOption')} "${name}"`}
				value={transformedValue}
				valueKey='value'
				ref={node => Select.Creatable.creatable = node}
				inputProps={restProps}
				{...restProps}
				{...meta}
			/>
		</div>
	);
}

ComboBoxCreatable.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	input: PropTypes.object,
	multi: PropTypes.bool,
	meta: PropTypes.object,
	onNewOptionClick: PropTypes.func
};

export default ComboBoxCreatable;
