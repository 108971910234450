import moment from 'moment';
import i18next from 'util/i18n';

export default function validate(values) {
	// const olderYear = Math.round(new Date().getTime()) - 31536000000;
	let errors = {};
	// const error = (field) => (`"${field} з" не може бути пізніше ніж "${field} по" aбо пізніше 1 року`);
	const error = (field) => (`"${field} з" не може бути пізніше ніж "${field} по"`);
	const CORRECT_DATE = i18next.t('validation.correctDate');
	
	if (values['dateBegin'] && !moment(+values['dateBegin']).isValid() ){
		errors = {...errors, 'dateBegin': CORRECT_DATE}
	}
	if (values['dateEnd'] && !moment(+values['dateEnd']).isValid() ){
		errors = {...errors, 'dateEnd': CORRECT_DATE}
	}
	
	if (values['dateBegin'] && values['dateEnd']) {
		if (moment(+values['dateEnd']).isBefore(+values['dateBegin']) /*|| +values['dateBegin'] >= olderYear*/) {
			errors = {...errors, 'dateBegin': error(i18next.t('workItemPeriod'))}
		}
		
	}
	
	return errors;
}
