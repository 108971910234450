import React from 'react';
import ComboBox from 'components/Common/ComboBox';
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import InputField from 'components/Common/InputField';
import styles from 'styles/modules/modal.module.scss';
import PropTypes from 'prop-types';

@withTranslation()
class ManualStreetField extends React.Component {
	
	constructor (props) {
		super(props);
		this.streetTypeOptions = this.getStreetTypeOptions();
		
		this.onClose = this.onClose.bind(this);
		this.getStreetTypeOptions = this.getStreetTypeOptions.bind(this);
	}
	
	componentWillUnmount () {
		this.props.change('streetType', null);
		this.props.change('streetName', '');
	}
	
	getStreetTypeOptions () {
		const streetTypes = this.props.t('dictionary:streetType', { returnObjects: true });
		return Object.keys(streetTypes).map(key => ({ value: key, label: streetTypes[key] }));
	}
	
	onClose (event) {
		event.preventDefault();
		this.props.onClose();
	}
	
	render () {
		const { inputProps, required, t } = this.props;
		return (
			<div className={styles.manualStreetContainer}>
				<Field
					name='streetType'
					component={ComboBox}
					options={this.streetTypeOptions}
					label={t('type')}
					required={required}
				/>
				<Field
					name='streetName'
					label={t('productName')}
					component={InputField}
					required={required}
					onClose={this.onClose}
					maxLength={inputProps && inputProps.maxLength}
				/>
			</div>
		);
	}
}

ManualStreetField.propTypes = {
	onClose: PropTypes.func,
	change: PropTypes.func
};

export default ManualStreetField;
