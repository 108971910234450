export default {
	icons: {
		'none': 'battery-unknown',
		customer: 'customer-icon',
		person: 'client',
		appeal: 'printing',
		appeals: 'ticket',
		order: 'file-alt',
		service: 'star-circle',
		calendar: 'calendar',
		
		public: 'person',
		admin: 'shield',
		
		create: 'add',
		
		apps: 'apps',
		
		site: 'place',
		keys: 'vpn-key',
		equipment: 'devices',
		request: 'work',
		map: 'map',
		
		party: 'people-outline',
		report: 'report',
		report2: 'report',
		report3: 'report',
		report4: 'report',
		settings: 'settings2',
		
		groups: 'group',
		
		spec_dict: 'library-books',
		act_process: 'settings-applications',
		act_task: 'work',
		act_scheduler: 'timer',
		// search: 'search-maginfer' // old icon dropped after ui-core-dashboard icons connection
		search: 'search2'
	},
};

export const SUMY = 'sumycity';
export const UABET = 'uabet';
