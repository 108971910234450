import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import modalStyles from 'styles/modules/modal.module.scss';
import styles from 'styles/modules/knowledgeBase.module.scss';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import FolderNode from '../Sidebar/FolderNode';
import get from 'lodash/get';

@withTranslation()
class FileExplorer extends PureComponent {
	constructor (props) {
		super(props);
		
		this.config = {
			valueField: 'id',
			labelField: 'name',
			leafField: 'leaf',
			childrenField: 'children'
		};
		
		const { nodes } = this.getNormalizedNodes({ nodeArray: props.catalogList });
		
		this.state = {
			nodes,
			selectedNode: null
		};
		
		this.renderNode = this.renderNode.bind(this);
		this.getNormalizedNodes = this.getNormalizedNodes.bind(this);
		this.selectFolder = this.selectFolder.bind(this);
		this.onCreateDirectory = this.onCreateDirectory.bind(this);
		this.submitSelect = this.submitSelect.bind(this);
	}
	
	getNormalizedNodes ({ nodeArray, selectedNodeId }) {
		const { childrenField, valueField } = this.config;
		
		let selectedNode = null;
		
		function openParents (node) {
			let currentNode = node.parent;
			while (currentNode) {
				currentNode.open = true;
				currentNode = currentNode.parent;
			}
		}
		
		const normalizeNodes = (array, parent) => {
			if (!Array.isArray(array) || array.length === 0) return null;
			
			return array.map(node => {
				const normalizedNode = {
					...node,
					...node.item,
					parent,
					open: false
				};
				
				if (selectedNodeId && normalizedNode[valueField] === selectedNodeId) {
					normalizedNode.open = true;
					normalizedNode.selected = true;
					openParents(normalizedNode);
					selectedNode = normalizedNode;
				}
				
				normalizedNode[childrenField] = normalizeNodes(node[childrenField], normalizedNode);
				
				return normalizedNode;
			});
		};
		
		return {
			nodes: normalizeNodes(nodeArray, null) || [],
			selectedNode
		};
	}
	
	selectFolder (node) {
		const { valueField } = this.config;
		
		const isSameNode = get(node, valueField) === get(this.state.selectedNode, valueField);
		
		const { nodes, selectedNode } = this.getNormalizedNodes({
			nodeArray: this.props.catalogList,
			selectedNodeId: isSameNode ? undefined : node[this.config.valueField]
		});
		
		this.setState({ nodes, selectedNode });
	}
	
	onCreateDirectory (node) {
		this.props.onCreateDirectory(node);
		this.props.onClose();
	};
	
	submitSelect () {
		this.props.onSubmitSelect(this.state.selectedNode);
		this.props.onClose();
	};
	
	renderNode (node) {
		const { valueField, leafField } = this.config;
		
		if (node[leafField]) return null;
		
		return (
			<FolderNode
				key={node[valueField]}
				{...this.config}
				node={node}
				onClick={this.selectFolder}
				renderNode={this.renderNode}
				onCreateNew={this.onCreateDirectory}
				hideLeafs
			/>
		);
	}
	
	render () {
		const { t, onClose } = this.props;
		const { nodes, selectedNode } = this.state;
		
		return (
			<ModalPortal onClose={onClose} className='modal-medium'>
				<div className={modalStyles.modalWrapper}>
					
					<div className={modalStyles.modalHeader}>
						<div className={modalStyles.modalTitle}>
							{t('create')}
						</div>
					</div>
					
					<div className={modalStyles.fileExplorer}>
                        <span className={modalStyles.contentHeader}>
                            {t('fileExplorer.selectFileTarget')}
                        </span>
						
						<div className={modalStyles.directoryTree}>
							<div className={cx(styles.treeContent, styles.noTopPadding)}>
								{nodes.map(this.renderNode)}
								{
									!selectedNode &&
									<button
										className={cx(styles.createButton, styles.rootButton)}
										onClick={this.onCreateDirectory}
									>
										{t('fileExplorer.createNewFolderLink')}
									</button>
								}
							</div>
						</div>
						
						<footer className={modalStyles.fileExplorerFooter}>
							<button className='btn btn-primary' onClick={this.submitSelect}>
								{t('fileExplorer.createArticle')}
							</button>
						</footer>
					</div>
				
				</div>
			</ModalPortal>
		);
	}
}

FileExplorer.propTypes = {
	catalogList: PropTypes.array,
	onSubmitSelect: PropTypes.func,
	onClose: PropTypes.func,
	onCreateDirectory: PropTypes.func
};

export default FileExplorer;
