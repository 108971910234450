import moment from 'moment';
import { ModalFields } from './constants';
import i18next from 'util/i18n';

function validateDate (value) {
	const isInvalidMoment = moment.isMoment(value) && !value.isValid();
	if (!value || isInvalidMoment) return i18next.t('validation.required');
	
	const duration = moment.duration(value - Date.now());
	
	if (duration.asMinutes() < 5) {
		return i18next.t('validation.time');
	}
	
	return null;
}

function validateComment (value = '', t) {
	return !value.trim() ? i18next.t('validation.required') : null;
}

function validateStatus (value, t) {
	return !value ? i18next.t('validation.required') : null;
}

const validations = {
	[ModalFields.TIMER_DATE]: validateDate,
	[ModalFields.NEXT_STATUS]: validateStatus,
	[ModalFields.COMMENT]: validateComment
};

function validate (values, props) {
	const errors = {};
	
	if (!props.anyTouched) {
		return errors;
	}
	
	props.fieldList.forEach(field => {
		const error = validations[field](values[field]);
		if (error) {
			errors[field] = error;
		}
	});
	
	return errors;
}

export default validate;
