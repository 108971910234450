import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import htmlToDraft from 'html-to-draftjs';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    DefaultDraftBlockRenderMap,
    EditorState,
    genKey,
    ContentBlock,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Map } from 'immutable';
import { extractAppealFromState } from 'helpers';

import { setMailBody, setReplyMailBody } from '../../../actions/emails';

function mapStateToProps(state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const email = get(state, `emails.emails[${id}]`, {});
    return {
        id,
        mailBody: email.mailBody,
        replyMailBody: email.replyMailBody,
    };
}
function SpoilerComponent({ setMailBody, setReplyMailBody, id, mailBody, replyMailBody }) {
    // add replyMailBody to mailBody
    function spoilerOpen(e) {
        // console.log('*** SPOILER OPEN ***');
        // e.stopPropagation();
        // e.preventDefault();
        const rawContentState = convertToRaw(mailBody.getCurrentContent());
        const mailBodyHTML = draftToHtml(rawContentState);
        // console.log(mailBodyHTML);
        const fullHtml = mailBodyHTML + replyMailBody;
        // console.log(fullHtml);
        const parsedNewState = fromHtml(fullHtml, false, true);
        setMailBody(id, parsedNewState);
        setReplyMailBody(id, '');
    }

    return (
        <div className="email-spoiler" data-block="false" onClick={spoilerOpen}>
            <div className="email-spoiler--button">
                <i className="dot" />
                <i className="dot" />
                <i className="dot" />
            </div>
        </div>
    );
}

const Spoiler = withRouter(
    connect(mapStateToProps, { setMailBody, setReplyMailBody })(SpoilerComponent),
);

const Signature = ({ children }) => <div className="email-signature">{children}</div>;

const blockRenderMap = Map({
    spoiler: {
        element: 'spoiler',
        wrapper: <Spoiler />,
    },
    signature: {
        element: 'signature',
        wrapper: <Signature />,
    },
});

export const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

export function toHtml(mailBody, newSign, oldSign = '<p></p>') {
    let newSignRows = { blocks: [{ type: 'unstyled', key: 'signKey', text: '' }] };
    let oldSignRows = { blocks: [{ type: 'unstyled', key: 'signKey', text: '' }] };
    let oldSignText = [];
    let joinedArrayOldSignText = [];
    if (newSign) {
        const blocksFromHTML = convertFromHTML(newSign, undefined, extendedBlockRenderMap);

        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        const signState = EditorState.createWithContent(state);

        const oldBlocksFromHTML = convertFromHTML(oldSign, undefined, extendedBlockRenderMap);

        const oldState = ContentState.createFromBlockArray(
            oldBlocksFromHTML.contentBlocks,
            oldBlocksFromHTML.entityMap,
        );
        const oldSignState = EditorState.createWithContent(oldState);

        newSignRows = { ...convertToRaw(signState.getCurrentContent()) };
        oldSignRows = { ...convertToRaw(oldSignState.getCurrentContent()) };
        oldSignText = oldSignRows.blocks.map((item) => item.text);
        joinedArrayOldSignText = oldSignText.join('');
        newSignRows.blocks.forEach((block) => {
            block.key = 'signKey';
            block.type = 'unstyled';
            block.code = 'signKey';
        });
    }

    const newRow = { ...convertToRaw(mailBody.getCurrentContent()) };
    newRow.blocks.forEach((block) => {
        if (block.type === 'spoiler') {
            block.type = 'unstyled';
            block.key = 'spoiler';
        }
        if (block.type === 'signature') {
            block.type = 'unstyled';
            block.key = 'signKey';
            // block.code = 'signKey';
        }
    });
    if (newSign) {
        const prepearedBlocks = newRow.blocks.filter(
            (item) => joinedArrayOldSignText !== item.text,
        );
        const withoutSpoiler = prepearedBlocks.filter((item) => item.key !== 'spoiler');
        const spoiler = prepearedBlocks.filter((item) => item.key === 'spoiler');
        newRow.blocks = [
            ...withoutSpoiler.filter((item) => !oldSignText.includes(item.text)),
            ...newSignRows.blocks,
            ...spoiler,
        ];
    }
    return draftToHtml(newRow);
}

export function fromHtml(mailBody = '', addBlock, withHtmlToDraft) {
    const blocksFromHTML = withHtmlToDraft
        ? htmlToDraft(mailBody)
        : convertFromHTML(mailBody, undefined, extendedBlockRenderMap);
    const newBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
    });

    if (addBlock) {
        blocksFromHTML.contentBlocks.splice(-1, 0, newBlock);
    }
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );

    try {
        return EditorState.createWithContent(state);
    } catch (e) {
        return EditorState.createEmpty();
    }
}

export function forwardTemplate({ mailBody, subject, date, from, to }) {
    return `<p>---------- Forwarded message ---------</p>
	<p>Від: ${from}</p>
	<p>Дата: ${date}</p>
	<p>Тема: ${subject}</p>
	<p>Кому: ${to.join(', ')}</p>
	<br>${mailBody}`;
}

export function replyTemplate({ mailBody }) {
    return `<spoiler>${mailBody}</spoiler>`;
}

export function addSignature(signature) {
    return `<br/><signature>${signature}</signature>`;
}

export function changeSignature({ mailBody, sign, oldSign }) {
    const blocksFromHTML = convertFromHTML(
        toHtml(mailBody, sign, oldSign),
        undefined,
        extendedBlockRenderMap,
    );

    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );
    try {
        return EditorState.createWithContent(state);
    } catch (e) {
        return EditorState.createEmpty();
    }
}

export const EMAILNEW = 'EMAILNEW';
export const EMAILREPLY = 'EMAILREPLY';
export const EMAILREPLYALL = 'EMAILREPLYALL';
export const EMAILFORWARD = 'EMAILFORWARD';
export const INTERACTION_REQUEST = 'INTERACTION_REQUEST';
