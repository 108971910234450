import React from 'react';
import PropTypes from 'prop-types';
import { destroy } from 'redux-form';

import Popup from 'components/Popup';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { appointAppeal, clearAppealState, clearAllAppeals } from 'modules/appeal/actions';
import { clearEntireCustomerState, clearAllCustomers } from 'actions/customer';

import { withRouter } from 'react-router-dom';
import { removeTab } from 'actions/tabs';
import { TabTypeGetters } from 'helpers';
import permissions, { checkPermissions } from 'config/permissions';
import get from 'lodash/get';
import { closeAppeal } from 'helpers';
import { getTabs } from '../../reducers/tabs';
import { openAppealLinkModal, openObjectsLinkModal } from 'actions/ui';

function mapStateToProps(state, props) {
    const { appealId } = props.match.params;
    const appeal = get(state, `appeal.appeals[${appealId}]`) || {};
    const canCreateRepeatAppeal = checkPermissions(
        permissions.AppealOperations.action_createAppealRepeat,
    );
    let beingCreatedAppeal = {};

    if (canCreateRepeatAppeal) {
        beingCreatedAppeal = Object.keys(state.appeal.appeals)
            .map((k) => state.appeal.appeals[k])
            .filter((k) => _.get(k, 'currentAppeal.statusAss') === 'beingCreated')
            .sort(
                (appealA, appealB) =>
                    _.get(appealB, 'currentAppeal.validFrom') -
                    _.get(appealA, 'currentAppeal.validFrom'),
            );
    }

    return {
        appeal: appeal.currentAppeal,
        appeals: state.appeal.appeals,
        forms: state.form,
        beingCreatedAppeal: _.get(beingCreatedAppeal, '[0].currentAppeal'),
        tabs: getTabs(state),
        canCreateRepeatAppeal,
        canCallQuality: checkPermissions(permissions.InteractionOperations.action_AddAssessments),
        canEditLinkAppeal: checkPermissions(permissions.LinkOperations.actionEditLinks),
		showAppealLinkModal: state.ui.showAppealLinkModal,
		showObjectLinkModal: state.ui.showObjectLinkModal,
    };
}

const mapDispatchToProps = {
    removeTab,
    destroyForm: (form) => destroy(form),
    clearAppealState,
    clearEntireCustomerState,
    clearAllAppeals,
    clearAllCustomers,
	openAppealLinkModal,
	openObjectsLinkModal
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AppealActionsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            duplicateAppealIsBlocked: false,
        };
        this.callLinkModal = this.callLinkModal.bind(this);
    }

    appointAppeal = () => {
        const { beingCreatedAppeal, appeal, tabs, history } = this.props;

        const requestData = {
            appealId: beingCreatedAppeal.id,
            repeatRequestId: appeal.id,
        };

        const onSuccess = () => this.props.history.push(`/appeals/${beingCreatedAppeal.id}/`);
        closeAppeal({ ...this.props, id: beingCreatedAppeal.id, lastTab: tabs.length === 1 });
        this.props.removeTab({
            tabType: TabTypeGetters.appeal(beingCreatedAppeal.id),
            history
        });
        return appointAppeal(requestData, onSuccess);
    };

    wrapHandler = (handler) => (event) => {
        this.setState({ duplicateAppealIsBlocked: true });
        this.props.closePopup(event);
        handler();
    };

    callLinkModal(type) {
        return (event) => {
            const { showAppealLinkModal, openAppealLinkModal, showObjectLinkModal, openObjectsLinkModal} = this.props;
			this.props.closePopup(event);
			if (type === "appeal" && !showAppealLinkModal) {
				openAppealLinkModal();
			};
			if (type === "object" && !showObjectLinkModal) {
				openObjectsLinkModal();
			};
        };
    }

    render() {
        const {
            t,
            appeal,
            open,
            openRepeatAppeal,
            canCallQuality,
            canEditLinkAppeal,
            closePopup,
            match,
            excelExport,
            callQuality,
            canCreateRepeatAppeal,
            beingCreatedAppeal,
            isAnonymous,
            canGenerateFile,
            openPrintPopup
        } = this.props;
        const { duplicateAppealIsBlocked } = this.state;

        const isNewAppeal = appeal.statusAss === 'beingCreated';
        const canCreateRepeatedAppealFromSource =
            !isNewAppeal &&
            beingCreatedAppeal &&
            Number(match.params.appealId) !== Number(beingCreatedAppeal.id);

        if (!open) return null;
        return (
            <Popup onClickOutside={closePopup} place="grid-popup">
                {canGenerateFile && (
                    <button className="popup-link" onClick={openPrintPopup}>
                        {t("appeal.printPattern")}
                    </button>
                )}
                {!isNewAppeal && canCreateRepeatAppeal && appeal.customerId ? (
                    <button
                        className={`popup-link ${duplicateAppealIsBlocked ? 'disabled' : ''}`}
                        onClick={this.wrapHandler(openRepeatAppeal)}
                    >
                        {t('appeal.secondAppeal')}
                    </button>
                ) : null}
                {canCreateRepeatedAppealFromSource &&
                canCreateRepeatAppeal &&
                _.get(beingCreatedAppeal, 'status.code') === 'beingCreated' &&
                appeal.customerId ? (
                    <button className="popup-link" onClick={this.wrapHandler(this.appointAppeal)}>
                        {t('appeal.secondAppealFromSource') + beingCreatedAppeal.regnum}
                    </button>
                ) : null}
                <div onClick={excelExport} className="popup-link">
                    {t('filters.exportExcelHistory')}
                </div>
                {canEditLinkAppeal && (
                    <div onClick={this.callLinkModal("appeal")} className="popup-link">
                        {t('appealForm.linkAppeal')}
                    </div>
                )}
                {canEditLinkAppeal && !isAnonymous && (
                    <div onClick={this.callLinkModal("object")} className="popup-link">
                        {t('appealForm.linkObject')}
                    </div>
                )}
                {canCallQuality && (
                    <div onClick={callQuality} className="popup-link">
                        {t('quality.Title')}
                    </div>
                )}
            </Popup>
        );
    }
}

AppealActionsPopup.propTypes = {
    open: PropTypes.bool,
    openPrintPopup: PropTypes.func,
    closePopup: PropTypes.func,
    openRepeatAppeal: PropTypes.func,
    openSpamDialog: PropTypes.func,
    excelExport: PropTypes.func,
    callQuality: PropTypes.func,
};

export default AppealActionsPopup;
