import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import cx from "classnames";
// import ReactSVG from "react-svg";
import baseService from "services/BaseService";

import Loader from "../Loader";

import "./styles.scss";

const mapStateToProps = (state, ownProps) => {
    return {
        filters: state[ownProps.storeKey].filters || state[ownProps.storeKey].filterList, // .filterList used by appeal
        // activeFilter should be always be an int (thats why Number is always used upon setting the value)
        activeFilter: state[ownProps.storeKey].openedFilter || state[ownProps.storeKey].activeFilter, // .activeFilter used by Service / Recource
    };
};

const mapDispatchToProps = {};

function getUpdatedFilterData(filters, filterId, subFiltersData) {
    return filters.map(filter => {
        if (filter.filterId === filterId) {
            return {
                ...filter,
                ...subFiltersData,
            };
        }
        return filter;
    });
}

const SidebarFilters = props => {
    const {
        t,
        history,
        filters,
        setFilters,
        setActiveFilter,
        activeFilter,
        needUpdate,
        setNeedUpdate,
        historyKey,
        titleKey,
        filtersCode,
        match: {
            params: { filterId },
        },
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    function toggleExpand() {
        setIsExpanded(prev => !prev);
    }
    // GET /mw/request/presets?code=<код обєкту>&parentId=<id фільтру на якому клікнули></id>
    function toggleFilterFolder(filterId) {
        const prevFilterValue = filters.find(filter => filter.filterId === filterId) || {};
        const subFilterData = {
            ...prevFilterValue,
            isLoading: !prevFilterValue.filters,
            isExpanded: !prevFilterValue.isExpanded,
        };
        setFilters(getUpdatedFilterData(filters, filterId, subFilterData));

        // fetch sub filters
        if (!prevFilterValue.filters) {
            baseService
                .get("filters", { data: { code: filtersCode, parentId: filterId } })
                .then(response => {
                    setIsLoading(false);
                    const allFilters = response.result.map(filter => ({
                        ...filter,
                        filterId: filter.value,
                        filterName: filter.name,
                        custom: filter.custom,
                        predefined: filter.predefined,
                        page: 1,
                    }));
                    const subFilterData = {
                        ...prevFilterValue,
                        filters: allFilters,
                        isLoading: false,
                        isExpanded: true,
                    };
                    setFilters(getUpdatedFilterData(filters, filterId, subFilterData));
                })
                .catch(e => {
                    const subFilterData = {
                        ...prevFilterValue,
                        isLoading: false,
                    };
                    setFilters(getUpdatedFilterData(filters, filterId, subFilterData));
                    console.error("SidebarFilters::toggleFilterFolder: Error during sub filters request: ", e);
                });
        }
    }

    function handleFilterSelect(filterId) {
        // true => with page reset
        setActiveFilter(Number(filterId), true); // add page reset on filter change
        history.push(`/${historyKey}/filter/${filterId}`);
        // clear up match params filter id to triggers grids remount (and refresh the grid by the clicking on the same filter again)
        if (activeFilter === filterId) {
            history.push(`/${historyKey}`);
        }
    }

    useEffect(() => {
        if (!filters || filters.length === 0 || !Array.isArray(filters) || needUpdate) {
            setNeedUpdate(false);
            setIsLoading(true);
            baseService
                .get("filters", { data: { code: filtersCode } })
                .then(response => {
                    setIsLoading(false);
                    const allFilters = response.result.map(filter => ({
                        ...filter,
                        filterId: filter.value,
                        filterName: filter.name,
                        custom: filter.custom,
                        predefined: filter.predefined,
                        page: 1,
                    }));
                    setFilters(allFilters);
                })
                .catch(e => {
                    setIsLoading(false);
                    console.error("SidebarFilters:: Error during filters request: ", e);
                });
        }
        // handle history when user clicking a tab again, no filterId is available, but activeFilterId is available
        if (!filterId && activeFilter && filters && Array.isArray(filters) && filters.length > 0) {
            const activeFilterId = +activeFilter;
            history.push(`/${historyKey}/filter/${activeFilterId}`);
        }
    }, [filters, needUpdate, filterId]);

    // handle history
    useEffect(() => {
        const activeFilterId = filterId ? +filterId : +activeFilter;
        if (filters && filters.length > 0) {
            let existingFilter;
            filters.forEach(filter => {
                if (+activeFilterId === +filter.filterId) {
                    existingFilter = filter;
                    return;
                }
                // check active filters in subfilters
                if (filter.filters) {
                    filter.filters.forEach(subFilter => {
                        if (+activeFilterId === +subFilter.filterId) {
                            existingFilter = subFilter;
                        }
                    });
                }
            });
            if (existingFilter) {
                setActiveFilter(Number(existingFilter.filterId));
                history.push(`/${historyKey}/filter/${existingFilter.filterId}`);
            } else {
                setActiveFilter(Number(filters[0].filterId));
                history.push(`/${historyKey}/filter/${filters[0].filterId}`);
            }
        }
    }, [activeFilter, filters]);

    // useEffect(() => {
    //     if (activeFilter && grid && grid.task_preset_) {
    //         const currentPage = grid.task_preset_.api.page || 1;
    //         if (currentActiveFilter && +currentActiveFilter.page !== +currentPage) {
    //             setActiveFilterPage(+activeFilter, +currentPage);
    //         }
    //     }
    //     activeFilter && history.push(`/orders/filter/${activeFilter}`);
    // }, [grid, filters, activeFilter, history, currentActiveFilter, setActiveFilterPage]);

    const renderFilter = data => {
        const { filterId, filterName, isFolder, isExpanded, filters, isLoading } = data;
        const isActiveSubfilter = filters && filters.find(subFilter => +subFilter.filterId === +activeFilter);

        return (
            <>
                <div
                    className={cx("filter", {
                        active: activeFilter && +filterId === +activeFilter,
                        expanded: isExpanded,
                        isFolder,
                        subActive: isActiveSubfilter,
                    })}
                    key={`${filterId}`}
                    title={filterName}
                >
                    <div className="filter-wrapper">
                        <div className="filter-name" onClick={() => handleFilterSelect(+filterId)}>
                            {filterName}
                        </div>
                        {!!isFolder && <i className="icon-down" onClick={e => toggleFilterFolder(filterId)} />}
                    </div>
                    {isExpanded && (
                        <div className="sub-filters">
                            <>
                                {isLoading && (
                                    <Loader loaderContainerClass="container-loader" loaderClass="input-loader" />
                                )}
                            </>
                            {filters && filters.length > 0 && filters.map(renderFilter)}
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className={cx("sidebar-filters-wrapper", { expanded: isExpanded })}>
            <div className="sidebar-filter-header">
                {t(titleKey)}{" "}
                <i
                    className={cx("expand-icon", { "icon-qwarter": !isExpanded, "icon-half": isExpanded })}
                    onClick={toggleExpand}
                />
            </div>
            <div className="sidebar-filters-overflow">
                {isLoading && <Loader />}
                <div className="sidebar-filters">
                    {filters &&
                        Array.isArray(filters) &&
                        filters.map(filter => filter.predefined && renderFilter(filter))}
                </div>
                <div className="sidebar-filters">
                    {filters &&
                        Array.isArray(filters) &&
                        filters.map(filter => !filter.predefined && renderFilter(filter))}
                </div>
            </div>
        </div>
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SidebarFilters)));
