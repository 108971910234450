import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckBoxToggle = (props) => {
	const { label, input, id, checked, disabled } = props;
	const inputId = id || `input_${_.get(props, 'input.name')}`;
	return (
		<div className='input-element container-checkbox-toggle'>
			<input
				id={inputId}
				type='checkbox'
				checked={input ? input.value : checked}
				onChange={props.onChange}
				{...input}
				disabled={disabled}
			/>
			<label className='checkbox-label' htmlFor={inputId}>
				<div className='checkbox-toggle'>
					<span className={classNames('checkbox-toggle-slider round', { checked })} />
				</div>
				<div className='checkbox-toggle-title'>{label}</div>
			</label>
		</div>
	);
};

CheckBoxToggle.propTypes = {
	label: PropTypes.string,
	input: PropTypes.object
};

export default CheckBoxToggle;
