import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/Common/common.scss';
import File from './File';
import { withTranslation } from 'react-i18next';
import baseService from 'services/BaseService';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

@withTranslation()
@withRouter
class FileInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sizeValidationError: false,
        };
        this.input = React.createRef();
    }

    handleFilesUpload = (fileRequests, convertedFiles) => {
        const { input } = this.props;
        Promise.all(fileRequests)
            .then((responses) => {
                const fileIdArray = responses.map((response) => response.result);
                const savedFiles = convertedFiles.map((file, index) => ({
                    name: file.name,
                    id: fileIdArray[index].id,
                }));
                input.onChange([...input.value, ...savedFiles]);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChange = (event) => {
        const fileList = event.currentTarget.files;
        let sizeValidation = true;
        let isReadyToUpload = true;
        if (isReadyToUpload && fileList.length > 0) {
            const convertedFiles = this.convertFileList(fileList);
            convertedFiles.forEach((i) => {
                // 5 mb check
                if (i.size > 1024 * 1024 * 5) {
                    sizeValidation = false;
                }
            });
            if (sizeValidation) {
                // handle filePreloadAction
                if (this.props.filePreloadAction && typeof this.props.filePreloadAction === 'function') {
                    this.props.filePreloadAction().then(data => {
                        const fileRequests = convertedFiles.map(this.saveLocalFile);
                        this.handleFilesUpload(fileRequests, convertedFiles);
                    }).catch(e => {
                        console.error("FileInput::handleChange: Error during isReadyToUploadCheck:", e);
                        event.currentTarget.value = null;
                        return;
                    });
                } else {
                    const fileRequests = convertedFiles.map(this.saveLocalFile);
                    this.handleFilesUpload(fileRequests, convertedFiles);
                }
            } else {
                this.setState({ sizeValidationError: true });
                setTimeout(() => {
                    this.setState({ sizeValidationError: false });
                }, 5000);
            }
        }
        if (event && event.currentTarget) {
            event.currentTarget.value = null;
        } else {
            console.log("FileInput::handleChange: event.currentTarget.value is not found");
        }
    };

    convertFileList = (fileList) => {
        if (!fileList || fileList.length === 0) return [];

        return [...fileList];
    };

    deleteFile = (fileIndex) => {
        const { input } = this.props;

        const updatedFiles = [
            ...input.value.slice(0, fileIndex),
            ...input.value.slice(fileIndex + 1),
        ];
        this.input.current.value = '';

        input.onChange(updatedFiles);
    };

    renderFile = (data, index) => (
        <File
            key={data ? data.id : null}
            index={index}
            name={data ? data.name : null}
            id={data ? data.id : null}
            disabled={this.props.disabled}
            onDelete={this.deleteFile}
        />
    );

    saveLocalFile = (file) => {
        const {
            match: {
                params: { appealId },
            },
            objectType,
            currentId,
            id,
        } = this.props;
        const params = {
            data: {
                data: {
                    objectId: appealId || currentId,
                    objectType,
                    fileName: file.name,
                    source: 'form',
                    attrCode: id,
                },
                file,
            },
            file: true,
        };

        return baseService.post('save_file', params);
    };

    render() {
        const { label, input, t, multiple, disabled, required, meta, ...rest } = this.props;
        const inputValueArray = Array.isArray(input.value);

        return (
            <div
                id={rest.id}
                className={cx('input-file', {
                    // disabled: disabled,
                    error: meta && meta.touched && (meta.error || meta.warning),
                })}
            >
                <div className="input-label">
                    {label}
                    {required && <span className="required-field">*</span>}
                </div>

                {inputValueArray && input.value.map(this.renderFile)}

                <label className="fileUploader">
                    {!disabled && (
                        <>
                            <input
                                type="file"
                                multiple={multiple}
                                accept="*"
                                onChange={this.handleChange}
                                ref={this.input}
                            />
                            <div className="btn-save">{t('add')}</div>
                        </>
                    )}
                </label>
                {this.state.sizeValidationError ? (
                    <div className="error-text error-hint">Максимальний розмір файлу 5MB</div>
                ) : null}
            </div>
        );
    }
}

FileInput.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    dinName: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default FileInput;
