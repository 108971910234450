import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from "classnames";
import 'moment/locale/uk';
import Header from './components/Header';
import Loader from './components/Loader';
import Navigation from './components/Navigation';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { cancelFormChange, repeatFormChange } from 'actions/changeHistory';
import { killEvent } from 'helpers';
import AudioPlayer from './pages/Appeal/History/AudioPlayer';
import NotificationBox from './components/Notification';
import NotificationPanel from './components/NotificationPanel';
import baseService from './services/BaseService';
import i18n from './util/i18n';
import { setUserSettings } from './actions';
import { openUserPopup, setHistoryPushRequireSaveWithoutChangeConfirm } from './actions/ui';
import { setStaticBlocks, saveBlocksToStaticBlock } from './reducers/staticBlocks';
import { sessionStorageService } from './services/settingsService';
import { setCallReceptionStatus } from './actions/call';
import * as pj from '../package.json';
import { UABET } from 'config/conf';
import CreateNewContactPersonModal from './components/CreateNewContactPersonModal';
import ExportWizardModal from './pages/Ordering/ExportWizardModal';
import CreateNewContactPersonModalCommon from 'components/CreateNewCustomerModal/common';
import CreateNewContactPersonModalUabet from 'components/CreateNewCustomerModal/uabet';
import RouteLeavingGuard from 'components/RouteLeavingGuard';

import permissions, { checkPermissions } from './config/permissions';
import './styles/shared.scss';
import './styles/style.scss';
import 'react-select/dist/react-select.css';
import 'styles/modules/cardContent.module.scss';
import AppealList from 'components/AppealList';
import Appeal from 'pages/Appeal';
import Issue from 'components/Issue';
import Calendar from 'pages/Calendar/index';
import Specification from 'components/Specification';
import { preloadLazy } from 'helpers';

// async components
const Dashboard = preloadLazy(import(/* webpackPreload: true */ 'components/Dashboard'));
const KnowledgeBase = preloadLazy(import(/* webpackPreload: true */ 'components/KnowledgeBase'));
const Settings = preloadLazy(import(/* webpackPreload: true */ 'components/Settings'));
const SearchPage = preloadLazy(import(/* webpackPreload: true */ 'components/SearchPage'));
const CustomerPage = preloadLazy(import(/* webpackPreload: true */ 'components/CustomerPage'));
const PersonPage = preloadLazy(import(/* webpackPreload: true */ 'components/PersonPage'));
const Orders = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Orders })));
const Order = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Order })));
const Services = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Services })));
const Service = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Service })));
const Resources = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Resources })));
const Resource = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Resource })));
const CreateNewOrderModal = lazy(() => import('pages/Ordering/').then(module => ({ default: module.CreateNewOrderModal })));
const CreateAppealFromFileModal = lazy(() => import('pages/Ordering/').then(module => ({ default: module.CreateAppealFromFileModal })));

const Customers = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Customers })));
const Bunits = lazy(() => import('pages/Ordering/').then(module => ({ default: module.Bunits })));


const isFirefox = navigator && navigator.userAgent && typeof navigator.userAgent === 'string' && navigator.userAgent.toLowerCase().includes("firefox");


let CreateNewCustomerModal = CreateNewContactPersonModalCommon;
if (ENVIRONMENT === UABET) {
	CreateNewCustomerModal = CreateNewContactPersonModalUabet;
}

const mapStateToProps = (state) => ({
	lang: get(state, 'user.lang_default'),
	errors: get(state, 'ui.errors'),
	successMessage: get(state, 'ui.successMessage'),
	showCreateNewCustomerModal: get(state, 'ui.showCreateNewCustomerModal'),
	showCreateNewOrderModal: get(state, 'ui.showCreateNewOrderModal'),
	showCreateAppealFromFileModal: get(state, 'ui.showCreateAppealFromFileModal'),
	showExportWizardModal: get(state, 'exportWizard.isModalOpened'),
	data: get(state, 'content.data'),
	notifications: state.ui.notifications,
	showNotificationPanel: state.ui.showNotificationPanel,
	showCreateNewContactPersonModal: state.ui.showCreateNewContactPersonModal,
	operations: state.user.operations,
	userId: state.user.id,
	isHistoryPushRequireSaveWithoutChangeConfirm: state.ui.isHistoryPushRequireSaveWithoutChangeConfirm,
});

const mapDispatchToProps = (dispatch) => ({
	setUserSettings: setUserSettings,
	cancelFormChange: () => dispatch(cancelFormChange()),
	repeatFormChange: () => dispatch(repeatFormChange()),
	openUserPopup: () => dispatch(openUserPopup()),
	setCallReceptionStatus: status => dispatch(setCallReceptionStatus(status)),
	setStaticBlocks: (blocks) => dispatch(setStaticBlocks(blocks)),
	saveBlocksToStaticBlock: (blockData) => dispatch(saveBlocksToStaticBlock(blockData)),
	setHistoryPushRequireSaveWithoutChangeConfirm: isRequired => dispatch(setHistoryPushRequireSaveWithoutChangeConfirm(isRequired))
});

@connect(mapStateToProps, mapDispatchToProps)
export default class App extends React.Component {
	constructor (props) {
		super(props);
		this.constructor.checkVersion();
		this.requestDictionary();
		this.requestStaticBlocks();
		this.requestTranslation();
		this.state = { isTranslationsReceived: false, isOuterStaticBlocksReceived: false, isInnerStaticBlocksReceived: false};
	}
	

	componentDidMount () {
		// Custom Ctrl+Z and Ctrl+Shift+Z handlers.
		document.addEventListener('keyup', debounce(event => {
			if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
				if (event.shiftKey) {
					this.props.repeatFormChange();
				} else {
					this.props.cancelFormChange();
				}
				killEvent(event);
			}
		}, 50));

		// Preventing default browser handlers for Ctrl+Z, Ctrl+Shift+Z and Ctrl+Cmd+Z.
		document.addEventListener('keydown', event => {
			if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
				killEvent(event);
			}
		});
		document.addEventListener('keypress', event => {
			if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
				killEvent(event);
			}
		});
		/**** save user settings ****/
		window.addEventListener('beforeunload', ev => {
			ev.preventDefault();
			this.props.setUserSettings();
		});

		const isOpenedBefore = sessionStorageService.get('isUserPopupOpenedBefore');
		if (!isOpenedBefore) {
			this.props.setCallReceptionStatus('active');
		}
	}

	requestDictionary = () => {
		baseService.get('dictionary')
			.then(response => i18n.addResourceBundle('uk', 'dictionary', response.result))
			.catch(console.error);
	};

	requestStaticBlocks = () => {
		baseService.get('staticBlocks')
			.then(response => {
				if (response.result) {
					const blocks = response.result;
					this.props.setStaticBlocks(blocks);
					const promises = blocks.map(block => {
						return baseService.get('staticBlock', { data: { code: block.code}});
					});
					Promise.all(promises).then(responses => {
						responses.forEach(response => {
							this.props.saveBlocksToStaticBlock(response.result);
						});
						this.setState({ isInnerStaticBlocksReceived: true });
						console.info('App::requestStaticBlocks: Inner blocks received.');
					}).catch(error => {
						this.setState({ isInnerStaticBlocksReceived: true });
						console.error('App::requestStaticBlocks: Promise.all error: ', error);
					}) 
				}
				this.setState({ isOuterStaticBlocksReceived: true });
				console.info('App::requestStaticBlocks: Outer blocks received.');
			})
			.catch(error => {
				this.setState({ isOuterStaticBlocksReceived: true });
				console.error('App::requestStaticBlocks: Error: ', error);
			});
	};

	// TODO: in case translations is not received - maybe set some timer to refetch translations until they are not received
	requestTranslation = () => {
		const activeLanguage = i18n.language;
		baseService.get('translations', { data: { lang: activeLanguage.toUpperCase() } })
			.then(({success, result}) => {
				if (success && result) {
					i18n.addResourceBundle(activeLanguage, 'translations', result.translation);
				}
				this.setState({ isTranslationsReceived: true });
				console.info('App::requestTranslation: Translations received.');
			})
			.catch(error => {
				this.setState({ isTranslationsReceived: true });
				console.error('App::requestTranslation: Error: ', error);
			})
	};

	static checkVersion () {
		const storageVersion = localStorage.getItem('version');
		if (storageVersion !== pj.version) {
			localStorage.clear();
			sessionStorage.clear();
			localStorage.setItem('version', pj.version);
		}
	}

	render () {
		const {
			showCreateNewCustomerModal,
			showCreateNewContactPersonModal,
			showCreateNewOrderModal,
			showCreateAppealFromFileModal,
			showExportWizardModal,
			showNotificationPanel,
			notifications,
			userId,
			isHistoryPushRequireSaveWithoutChangeConfirm,
			setHistoryPushRequireSaveWithoutChangeConfirm
		} = this.props;
		const { isTranslationsReceived, isOuterStaticBlocksReceived, isInnerStaticBlocksReceived } = this.state;
		const isStaticBlocksReceived = isOuterStaticBlocksReceived && isInnerStaticBlocksReceived;
		const isInitDataReceived = isTranslationsReceived && isStaticBlocksReceived;

		return (
			<HashRouter>
				{/* used to show confirm modal when some form is being dirty and user try to change a route */}
				<RouteLeavingGuard
					when={isHistoryPushRequireSaveWithoutChangeConfirm}
					navigate={(history, path) => {
						history.push(path);
						setHistoryPushRequireSaveWithoutChangeConfirm(false);
					}}
					shouldBlockNavigation={location => {
						if (isHistoryPushRequireSaveWithoutChangeConfirm) {
							return true;
						}
						return false;
					}}
				/>
				{isInitDataReceived ? 
				(<div className={cx('main', { isFirefox })}>
					<Navigation />
					<section className='main-block'>
						<Header />
						{userId && (
							<Suspense fallback=''>
								<Switch>
									<Route exact path='/'>
										<Redirect to='/appeal' />
									</Route>
									<Route path='/search/:entity/:hash' component={SearchPage} />
									<Route path='/appeal/:filter?/:filterId?' component={AppealList} />
									<Route path='/appeals/:appealId' component={Appeal} />
									<Route path='/customers/:filter?/:filterId?' component={Customers} />
									<Route path='/customer/:customerId/person/:id/:subTab?' component={PersonPage} />
									<Route path='/customer/:id/:subTab?' component={CustomerPage} />
									<Route path='/knowledge_base/:knowledgeId?' component={KnowledgeBase} />
									<Route path='/specification/:id?/:type?' component={Specification} />
									{/* <Route path='/bunits/:id?/:type?' component={Bunits} /> */}
									<Route path='/bunits/:unitType?/:id?/:type?/:unitId?' component={Bunits} />
									<Route path='/settings/:group?/:section?/:id?/:part?/:childId?' component={Settings} />
									<Route path='/dashboard' component={Dashboard} />
									<Route path='/orders/:filter?/:filterId?' component={Orders} />
									<Route path='/order/:orderId/:subTab?/:subTabId?' component={Order} />
									<Route path="/services/:filter?/:filterId?" component={Services} />
									<Route path='/service/:serviceId/:subTab?/:subTabId?' component={Service} />
									<Route path='/resources/:filter?/:filterId?' component={Resources} />
									<Route path='/resource/:resourceId/:subTab?/:subTabId?' component={Resource} />
									<Route path='/issue' component={Issue} />
									<Route path='/calendar' component={Calendar} />
								</Switch>
							</Suspense>
						)}
					</section>

					{showCreateNewContactPersonModal && <CreateNewContactPersonModal />}
					{showCreateNewCustomerModal && <CreateNewCustomerModal />}
					{showCreateNewOrderModal && <Suspense fallback=''><CreateNewOrderModal /></Suspense>}
					{showCreateAppealFromFileModal && <Suspense fallback=''><CreateAppealFromFileModal /></Suspense>}
					{showExportWizardModal && <Suspense fallback=''><ExportWizardModal /></Suspense>}
					<AudioPlayer />
					<NotificationBox notifications={notifications} />
					{showNotificationPanel && <NotificationPanel />}
				</div>) : <Loader /> }
			</HashRouter>
		);
	}
}

App.propTypes = {
	requestDictionary: PropTypes.func,
	repeatFormChange: PropTypes.func,
	cancelFormChange: PropTypes.func,
	showCreateNewCustomerModal: PropTypes.bool
};


