import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isPristine } from 'redux-form';
import Popup from 'components/Popup/index';

import { connect } from 'react-redux';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import {
	getAppealActions,
	setAppealAction,
	setFirstLevelActions,
	unlockAppealForm,
	setResolutionActions,
} from 'modules/appeal/actions';
import { extractAppealFromState } from 'helpers';
import { withRouter } from 'react-router-dom';

function mapStateToProps (state, props) {
	const [appeal, id] = extractAppealFromState(state, props);
	
	return {
		appeal: appeal.currentAppeal,
		appealAction: appeal.appealAction,
		firstLevelAppealAction: appeal.firstLevelAppealAction,
		selectedAction: appeal.selectedAction,
		// pristine: isPristine(`appeal-form-${id}`)(state),
		pristine: isPristine(`appeal-form-${id}`)(state) && isPristine(`appeal-form-header-${id}`)(state),

	};
}

const mapDispatchToProps = {
	getAppealActions,
	setAppealAction,
	setFirstLevelActions,
	unlockAppealForm,
	setResolutionActions,
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class StatusSelect extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			actionsPopupIsOpen: false,
			resolutionPopupIsOpen: false,
			firstLevelActions: null,
			firstRequest: true,
		};
		this.renderResolutionLink = this.renderResolutionLink.bind(this);
		this.selectResolution = this.selectResolution.bind(this);
		this.renderStatusLink = this.renderStatusLink.bind(this);
		this.selectFistLevel = this.selectFistLevel.bind(this);
		this.openStatusPopup = this.openStatusPopup.bind(this);
		this.closePopups = this.closePopups.bind(this);
	}
	
	selectFistLevel (appealAction) {
		const { appeal, setAppealAction, setFirstLevelActions, unlockAppealForm } = this.props;
		return (e) => {
			e.stopPropagation();
			if (appealAction.resolution.length) {
				this.setState({
					actionsPopupIsOpen: false,
					resolutionPopupIsOpen: true,
					firstLevelActions: appealAction,
				});
			} else {
				this.setState({
					actionsPopupIsOpen: false,
					resolutionPopupIsOpen: false,
					firstLevelActions: appealAction,
				});
				setAppealAction({
					appealId: appeal.id,
					statusCode: appealAction.toState,
					action: appealAction.action,
				});
				setFirstLevelActions(appealAction, appeal.id);
				// unlockAppealForm(appeal.id, "action-select");
				unlockAppealForm(appeal.id, "form");

			}
		};
	}
	
	selectResolution (resolution) {
		const { firstLevelActions } = this.state;
		const { appeal } = this.props;
		return (e) => {
			e.stopPropagation();
			this.setState({ resolutionPopupIsOpen: false });
			this.props.setAppealAction({
				appealId: appeal.id,
				statusCode: firstLevelActions.toState,
				action: firstLevelActions.action,
				resolutionId: resolution.id,
			});
			this.props.setFirstLevelActions(firstLevelActions, appeal.id);
			this.props.setResolutionActions(resolution, appeal.id);
			// this.props.unlockAppealForm(appeal.id, "action-select");
			this.props.unlockAppealForm(appeal.id, "form");
			if (firstLevelActions.action === 'toForward') {
				document.getElementById('executor').scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		};
	}
	
	closePopups (e) {
		e.stopPropagation();
		this.setState({ actionsPopupIsOpen: false, resolutionPopupIsOpen: false });
	}
	
	openStatusPopup () {
		this.setState({ actionsPopupIsOpen: true, resolutionPopupIsOpen: false });
	}
	
	renderStatusLink (status) {
		return (
			<div key={status.action} className='popup-status-link-wrapper' onClick={this.selectFistLevel(status)}>
				<div className='popup-status-link without-dots'>
					{status.label}
				</div>
				{status.resolution.length > 0 && <i className='icon-arrow-right' />}
			</div>
		);
	}
	
	renderResolutionLink (resolution) {
		return (
			<div
				key={resolution.id}
				className='popup-status-link without-dots'
				onClick={this.selectResolution(resolution)}
			>
				{resolution.name}
			</div>
		);
	}
	
	render () {
		const { t, appealAction, selectedAction, permission } = this.props;
		const { resolutionPopupIsOpen, actionsPopupIsOpen, firstLevelActions } = this.state;
		const actionTitle = appealAction.length > 0 ? (selectedAction || t('appeal.availableActions')) : t('appeal.unavailableActions');
		
		const titleClassName = cx('resolution', {
			'selected': actionTitle === selectedAction,
		});
		
		// if (permission !== 0) {
			return (
				<Fragment>
					<span className={cx('m-r-16 action-select-title', (permission === 1 ? 'disabled' : ''))}>{t('appeal.availableActions')}</span>
					<div className={cx('status-select-wrapper','status-action-wrapper', (permission === 1 ? 'disabled' : ''))}
						 onClick={this.openStatusPopup}>
						<span className={titleClassName}>{actionTitle}</span>
						{
							appealAction.length > 0 && <i className='icon-down' />
						}
						{
							actionsPopupIsOpen && appealAction.length > 0
							&& (
								<Popup
									place='status-popup'
									onClickOutside={this.closePopups}
								>
									{appealAction.map(this.renderStatusLink)}
								</Popup>
							)
						}
						
						{
							resolutionPopupIsOpen
							&& (
								<Popup
									onClickOutside={this.closePopups}
									place='status-popup'
								>
									<div className='resolutionBackLink' onClick={this.openStatusPopup}>
										<i className='icon-arrow-left' />
										<div key='back' className='popup-link'>{t('back')}</div>
									</div>
									{firstLevelActions ? firstLevelActions.resolution.map(this.renderResolutionLink) : null}
								</Popup>
							)
						}
					</div>
				</Fragment>
			);
		// }
		// return null;
	}
}

StatusSelect.propTypes = {
	status: PropTypes.object,
	unlockAppealForm: PropTypes.func,
	change: PropTypes.func,
	checkRestrictions: PropTypes.func,
};

export default StatusSelect;
