import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";

import { clearEntireCustomerState, clearAllCustomers } from "actions/customer";
import { clearSearchAndSearchFilters } from "actions/search";
import { setCurrentSearchFilter } from "actions/searchFilter";
import { getTabs } from "../../reducers/tabs";
import { reset } from "../../reducers/grid";
import ModalPortal from "../ModalPortal";
import MessageModal from "../MessageModal";
import "./styles.scss";
import {
    cropTabTypeToIconName,
    customerFormNameBuilder,
    searchCustomersInAppeals,
    destroyFormsAppealsAndCustomers,
    closeAppeal,
} from "helpers";
import { closeMessageModal, openMessageModal, showMenu } from "actions/ui";
import { closeAllExceptCurrent, removeTab, resetTab } from "actions/tabs";
import { resetAppealMailing } from "actions/emails";
import { clearAppealState, clearAllAppeals } from "modules/appeal/actions";
import { addCardsToHistory, addCardToHistory } from "actions/user";
import TabCloseMenu from "./TabCloseMenu";
import HiddenTabs from "./HiddenTabs";
import NewTab from "./NewTab";
import { destroy } from "redux-form";

function mapStateToProps(state) {
    return {
        tabs: getTabs(state),
        currentTab: state.tabs.current,
        isAddingActive: state.tabs.isAddingActive,
        showMessageModal: state.ui.showMessageModal,
        ui: state.ui,
        isAppealUnlocked: state.appeal.unlockedAppeal,
        appeals: state.appeal.appeals,
        isRequestLoading: state.app.requests.length > 0,
        forms: state.form,
        grid: state.grid,
        searches: state.search.searches,
        currentSuperTabPath: state.tabs.currentSuperTabPath
    };
}

const mapDispatchToProps = {
    addCardToHistory,
    addCardsToHistory,
    removeTab,
    resetTab,
    closeAllExceptCurrent,
    showMenu,
    openMessageModal,
    closeMessageModal,
    clearAppealState,
    clearEntireCustomerState,
    clearAllCustomers,
    clearAllAppeals,
    clearSearchAndSearchFilters,
    resetAppealMailing,
    setCurrentSearchFilter,
    resetGrid: reset,
    destroyForm: name => destroy(name),
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class MainTabs extends React.Component {
    state = {
        width: null,
        showMessageModal: false,
        modalTitle: "",
        closeContextMenuUrl: null,
        tabsToCloseWithConfirm: [] // this part will trigger confirm modal to be opened until this array will not be empty
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        if (this.props.tabs.length === 0 && sessionStorage.length > 0) {
            sessionStorage.clear();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    };

    updateDimensions = () => this.setState({ width: window.innerWidth });

    getMainTabMaxLength = () => {
        const { width } = this.state;

        if (width > 1420) {
            return 5;
        }
        if (width > 1300) {
            return 4;
        }
        if (width > 1200) {
            return 3;
        }
        if (width > 1110) {
            return 2;
        }
        return 1;
    };

    removeMultipleTabs = (e, tabs) => {
        const tabsToCloseWithConfirm = [];
        const tabsToClose = [];
        tabs.forEach(tab => {
            const path = tab.url.split("/");
            const appeal = tab.name.split("_");
            if (path[1] && path[1] === "appeals") {
                const appealData = _.get(this.props.appeals, `${appeal[1]}`);
                const isInRegistrationProcess = _.get(appealData, "currentAppeal.status.code") === "beingCreated";
                const isUnlocked = appealData && appealData.unlockedAppeal;
                if (isUnlocked || isInRegistrationProcess) {
                    tabsToCloseWithConfirm.push(tab);
                } else {
                    tabsToClose.push(tab);
                }
            } else {
                tabsToClose.push(tab);
            }
        });
        if (tabsToClose.length > 0) {
            tabsToClose.forEach(tab => this.removeTab(e, tab));
        }
        this.setState({ tabsToCloseWithConfirm });
    };

    removeTab = (e, tab, forceWithoutConfirm) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const { history, tabs } = this.props;
        this.tab = tab;

        const path = tab.url.split("/");
        const appeal = tab.name.split("_");
        const lastTab = tabs.length === 1;

        const { name, url, type } = tab;

        if (path[1] && path[1] === "appeals") {
            if (!path.includes("customer")) {
                // clear appeals grid upon appeal close (not appeal customer)
                const appealIndex = path.findIndex(item => item === "appeals");
                const appealId = path[appealIndex + 1];
                this.clearAppealGrids(appealId);
            }
            if (!forceWithoutConfirm) {
                const appealData = _.get(this.props.appeals, `${appeal[1]}`);
                const isInRegistrationProcess = _.get(appealData, "currentAppeal.status.code") === "beingCreated";
                const isUnlocked = appealData && appealData.unlockedAppeal;
                if (isUnlocked || isInRegistrationProcess) {
                    return this.openMessageModal(tab.displayedName || tab.regnum);
                }
            }
        }

        if (path.includes("customer")) {
            const customerIndex = path.findIndex(item => item === "customer");
            const customerId = path[customerIndex + 1];
            let subTabName = "";
            let subTabId = "";

            const personIndex = path.findIndex(item => item === "person");
            const personId = path[personIndex + 1];


            // get id and tab before customer
            if (customerIndex !== 1) {
                subTabName = path[1];
                subTabId = path[2];
            }
            if (personId) {
                subTabName = "person";
                subTabId = personId;
            }
            this.closeCustomer(customerId, lastTab, subTabName, subTabId);
        }

        if (path[1] && path[1] !== "search") {
            this.props.addCardToHistory({ url, name });
        }

        if (path[1] && path[1] === "search") {
            this.closeSearch(path[3]);
        }

        if (type !== "home") {
            this.props.removeTab({ tabType: type, history });

            if (appeal[0] === "appeals") {
                closeAppeal({ ...this.props, id: appeal[1], lastTab });
                this.props.resetAppealMailing(appeal[1]);
            }

            if (tabs.length === 1) {
                const redirects = {
                    service: "services",
                    appeals: "appeal",
                    order: "orders",
                    customer: "customers",
                    resource: "resources",
                };
                // console.log({type, redirects});
                if (this.props.currentSuperTabPath) {
                    // console.log({history, currentSuperTabPath: this.props.currentSuperTabPath});
                    const location = history.location && history.location.pathname;
                    if (location !== this.props.currentSuperTabPath) {
                        history.push(this.props.currentSuperTabPath);
                    }
                } else {
                    history.push(`/${redirects[type.split("_")[0]] || "appeal"}`);
                }
            }
        }
    };

    closeSearch = id => {
        // TODO: move out this hardcode, replace it by getting search form names from config or somewhere ele
        const { destroyForm, clearSearchAndSearchFilters, resetGrid, grid } = this.props;

        const formSet = [
            `search-filters-${id}-task`,
            `search-filters-${id}-resource`,
            `search-filters-${id}-spec`,
            `search-filters-${id}-clevent`,
            `search-filters-${id}-appeal`,
            `search-filters-${id}-customer`,
            `search-filters-${id}-contact_person`,
        ];
        const gridsSet = [
            `calendar_events_${id}`,
            `resource_${id}`,
            `service_${id}`,
            `task_${id}`,
            `appeal_${id}`,
            `customers_${id}`,
            `contact_person_${id}`
        ];
        // const grids = Object.keys(grid);
        // const filteredGrids = grids.filter(grid => grid.includes(id));

        const filterObjects = [
            `${id}-task`,
            `${id}-resource`,
            `${id}-spec`,
            `${id}-clevent`,
            `${id}-appeal`,
            `${id}-contact_person`,
            `${id}-customer`,
        ];
        // setTimeout(() => {
            formSet.forEach(name => destroyForm(name));
            gridsSet.forEach(grid => resetGrid(grid));
            filterObjects.forEach(name => clearSearchAndSearchFilters(name, id));
        // }, 1000);
        // }, 3000);
    };

    clearAppealGrids = (appealId) => {
        const {
            grid,
            resetGrid
        } = this.props;
        const grids = Object.keys(grid);
        const filteredGrids = grids.filter(grid => {
            return grid.includes("appeals") && grid.includes(appealId);
        });
        // console.log({filteredGrids});
        // setTimeout(() => {
            filteredGrids.forEach(grid => resetGrid(grid));
        // }, 1000);
        // }, 3000);
    };

    closeCustomer = (id, lastTab, subTabType, subTabId) => {
        const {
            destroyForm,
            clearEntireCustomerState,
            appeals,
            forms,
            clearAllAppeals,
            clearAllCustomers,
            grid,
            resetGrid,
            setCurrentSearchFilter,
        } = this.props;

        // possible form namings for specs (service + resource)
        // customer-filters-${service || resource}-${itemId}-${customerId}
        // customer-filters-${service || resource}-${customerId}
        // let formSet = [`${id}-edit-customer-name`, `${id}-edit-person-name`, `customer-panel-main-dynamic-form-${id}`, `customer-panel-main-dynamic-form-${subTabId}`];
        let formSet = [`${id}-edit-customer-name`, `${id}-edit-person-name`];

        const formKeys = Object.keys(this.props.forms).filter(key => !key.includes("search-filters-"));
        const proxyFilters = formKeys.filter(key => {
            if (subTabId) {
                return (
                    key.includes("proxy-tab-filter-customer-") &&
                    key.includes(subTabType) &&
                    key.includes(subTabId) &&
                    key.includes(id)
                );
            }
            return key.includes("proxy-tab-filter-customer") && key.includes(id);
        });
        if (subTabId) {
            formSet = [
                ...formSet,
                `customer-filters-service-${subTabType}-${subTabId}-${id}`,
                `customer-filters-resource-${subTabType}-${subTabId}-${id}`,
                ...proxyFilters,
            ];
        } else {
            formSet = [
                ...formSet,
                `customer-filters-service-${id}`,
                `customer-filters-resource-${id}`,
                ...proxyFilters,
            ];
        }

        proxyFilters.forEach(filter => {
            setCurrentSearchFilter(null, filter);
        });

        const grids = Object.keys(grid);
        const filteredGrids = grids.filter(grid => {
            if (subTabId) {
                return (
                    grid.includes("customer") &&
                    grid.includes(subTabType) &&
                    grid.includes(subTabId) &&
                    grid.includes(id)
                );
            }
            return !grid.includes(".") && grid.includes("customer") && grid.includes(id);
        });

        // setTimeout(() => {
            filteredGrids.forEach(grid => resetGrid(grid));
        // }, 3000);
        // }, 1000);


        if (lastTab) {
            destroyFormsAppealsAndCustomers({
                forms: formSet,
                clearAllAppeals,
                destroyForm,
                clearAllCustomers,
            });
        } else {
            if (searchCustomersInAppeals(appeals, id).length === 0) {
                formSet = [...formSet, ...customerFormNameBuilder(id, "customer")];
                clearEntireCustomerState(id);
            }

            // setTimeout(() => {
                formSet.forEach(name => name !== `${id}-customer-filled` && destroyForm(name));
            // }, 1000);
            // }, 3000);
        }
    };

    removeAllTabs = e => {
        try {
            // if (e) {
            e.preventDefault();
            e.stopPropagation();
            // }
            const tabsToClose = this.props.tabs.filter(item => !item.isSuperTab);
            if (tabsToClose && Array.isArray(tabsToClose)) {
                this.removeMultipleTabs(e, tabsToClose);
                // tabsToClose.forEach(tab => this.removeTab(e, tab));
            }    
            this.removeCloseContextMenuUrl();
        } catch(e) {
            console.error("MainTabs::removeAllTabsExceptCurrent: Error: ", e);
        }
    };

    removeAllTabsExceptCurrent = (e, tab) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            const tabsToClose = this.props.tabs.filter(item => !item.isSuperTab && item.url !== tab.url);
            if (tabsToClose && Array.isArray(tabsToClose)) {
                this.removeMultipleTabs(e, tabsToClose);
            }  
            const isSuperTabActive = this.props.currentTab.isSuperTab;
            // some tab was active => go to that tab
            if (!isSuperTabActive) {
                this.goToTab(tab);
            }
            this.removeCloseContextMenuUrl();
        } catch(e) {
            console.error("MainTabs::removeAllTabsExceptCurrent: Error: ", e);
        }
    };

    showMenu = id => e => {
        if (!id) return;
        e.preventDefault();
        const { showMenu } = this.props;
        showMenu && showMenu({ id });
    };

    closeModal = (e) => {
        // multiple case
        const tabToCloseWithConfirm = this.state.tabsToCloseWithConfirm[0];
        if (tabToCloseWithConfirm) {
            // handle multiple case
            // console.log({e, tabToCloseWithConfirm});
            this.removeTab(e, tabToCloseWithConfirm, true);
            this.closeMessageModal();
        } else {
            // handle single case
            const { history, tabs } = this.props;
            const lastTab = tabs.length === 1;

            this.closeMessageModal();
            const appeal = this.tab.name.split("_");
            if (appeal[0] === "appeals") {
                closeAppeal({ ...this.props, id: appeal[1], lastTab });
            }
            this.props.removeTab({ tabType: this.tab.type, history });
        }
    };

    translateName = name => {
        const names = ["search", "appeals", "customer", "person"];
        const firstPart = name.split("_")[0];
        if (names.indexOf(firstPart) !== -1) {
            const secondPart = name
                .split("_")
                .slice(1)
                .join("_");
            return secondPart ? `${secondPart}` : "";
        }
        return name;
    };

    getTabText = tab => {
        const { searches } = this.props;
        if (tab.type.startsWith("search")) {
            if (!searches[tab.type.split("_")[1]]) {
                return this.props.t("Search");
            }
            return searches[tab.type.split("_")[1]];
        }

        if (tab.type.startsWith("appeals")) {
            // try to also parse id
            const appealId = tab.name && tab.name.split("_")[1];
            return tab.regnum || tab.displayedName || appealId;
        }

        if (tab.displayedName) {
            return tab.displayedName;
        }

        return tab.name !== "SEARCH" ? this.translateName(tab.name) : "";
    };

    getTabTitle = tab => {
        if (tab.type.startsWith("appeals")) {
            return tab.regnum || "";
        }
        return tab.name.length > 10 && tab.name !== "SEARCH" ? this.translateName(tab.name) : "";
    };

    openMessageModal = modalTitle => this.setState({ showMessageModal: true, modalTitle });

    closeMessageModal = () => {
        const { tabsToCloseWithConfirm } = this.state;
        // multiple case
        const tabToCloseWithConfirm = tabsToCloseWithConfirm[0];
        if (tabToCloseWithConfirm) {
            // handle multiple case
            // filter the first tab
            this.setState({ tabsToCloseWithConfirm: tabsToCloseWithConfirm.filter((item, index) => index !== 0) });
        } else {
            // single case
            this.setState({ showMessageModal: false, modalTitle: "" })
        }
    };

    goToTab = nextTab => {
        const { history } = this.props;
        history.push(nextTab.url);
    };

    setCloseContextMenuUrl = (event, val) => {
        // check if there are more than 1 tabs existing
        if (this.props.tabs && Array.isArray(this.props.tabs) && this.props.tabs.length > 1) {
            event.preventDefault();
            this.setState({closeContextMenuUrl: val.url});
        }
    }

    removeCloseContextMenuUrl = (event) => {
        this.setState({closeContextMenuUrl: null});
    }

    renderTab = (val, key) => {
        const { ui, currentTab } = this.props;
        const { closeContextMenuUrl } = this.state;
        const icon = cropTabTypeToIconName(val.type);

        const isActive = currentTab && val && val.url === currentTab.url && this.props.location.pathname.includes(val.url);

        return (
            <div
                key={key}
                onClick={() => this.goToTab(val)}
                // className={cx("main-tab", { active: val.type === currentTab.type })}
                className={cx("main-tab", { active: isActive })}
                title={this.getTabTitle(val)}
                onContextMenu={event => this.setCloseContextMenuUrl(event, val)}
            >
                <ReactSVG svgClassName="tab-icon" src={`/data/svg/navigation/${icon}.svg`} />
                <span className="main-tab-text">{this.getTabText(val)}</span>
                <TabCloseMenu
                    removeTab={this.removeTab}
                    removeAll={this.removeAllTabs}
                    removeAllTabsExceptCurrent={this.removeAllTabsExceptCurrent}
                    val={val}
                    closeContextMenuUrl={closeContextMenuUrl}
                    withContextMenu={this.props.tabs && Array.isArray(this.props.tabs) && this.props.tabs.length > 1}
                    removeCloseContextMenuUrl={this.removeCloseContextMenuUrl}
                />
            </div>
        );
    };

    render() {
        const { ui, user, tabs, isAddingActive, t, searches } = this.props;
        const { modalTitle, closeContextMenuUrl, tabsToCloseWithConfirm } = this.state;
        let dropDownTabs = [];
        let mainTabs = [];
        const tabsLength = this.getMainTabMaxLength();
        const checkTabsOverflow = tabs.length > tabsLength;
        const tabsCount = tabs.length;

        if (checkTabsOverflow) {
            dropDownTabs = [...tabs.slice(0, tabs.length - tabsLength)];
            mainTabs = [...tabs.slice(tabs.length - tabsLength)];
        } else {
            mainTabs = [...tabs];
        }

        const tabToCloseWithConfirm = tabsToCloseWithConfirm[0];
        const tabToCloseModalTitle = tabToCloseWithConfirm && (tabToCloseWithConfirm.displayedName || tabToCloseWithConfirm.regnum);

        return (
            <nav className="headTabs">
                {checkTabsOverflow && (
                    <HiddenTabs
                        hiddenTabs={dropDownTabs}
                        removeTab={this.removeTab}
                        removeAll={this.removeAllTabs}
                        removeAllTabsExceptCurrent={this.removeAllTabsExceptCurrent}
                        ui={ui}
                        tabs={tabs}
                        translateName={this.translateName}
                        t={t}
                        setCloseContextMenuUrl={this.setCloseContextMenuUrl}
                        closeContextMenuUrl={closeContextMenuUrl}
                        removeCloseContextMenuUrl={this.removeCloseContextMenuUrl}
                    />
                )}
                {mainTabs.map(this.renderTab)}
                <NewTab ui={ui} user={user} isAddingActive={isAddingActive} tabsEmpty={!tabsCount} />
                <CSSTransition
                    in={this.state.showMessageModal || tabToCloseWithConfirm}
                    classNames="fade"
                    appear
                    enter
                    exit
                    timeout={500}
                    mountOnEnter
                    unmountOnExit
                >
                    <ModalPortal onClose={this.closeMessageModal} className={cx("modal-small", "modal-center")}>
                        <MessageModal
                            contentModalText={t("appeal.closeModalText")}
                            titleModal={modalTitle || tabToCloseModalTitle}
                            primaryButton
                            primaryButtonText={t("appeal.yes")}
                            onClickPrimaryButton={this.closeModal}
                            secondaryButton
                            secondaryButtonText={t("appeal.no")}
                            onClickSecondaryButton={this.closeMessageModal}
                        />
                    </ModalPortal>
                </CSSTransition>
            </nav>
        );
    }
}

export default withRouter(MainTabs);

MainTabs.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    staticContext: PropTypes.bool,
    ui: PropTypes.object.isRequired,
    user: PropTypes.object,
    tabs: PropTypes.object,
    isAddingActive: PropTypes.bool,
    addCardToHistory: PropTypes.func,
    addCardsToHistory: PropTypes.func,
    removeTab: PropTypes.func,
    resetTab: PropTypes.func,
    closeAllExceptCurrent: PropTypes.func,
    showMenu: PropTypes.func, // for TabCloseMenu component
    currentTab: PropTypes.object,
    isRequestLoading: PropTypes.bool,
};
