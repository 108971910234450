import React, { useState, useEffect, useMemo, useRef, useLayoutEffect } from "react";
import { getFormSyncErrors, submit, getFormValues } from "redux-form";
import { withRouter, matchPath } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isEmpty, get } from "lodash";
import useIsFocusedDebounced from "custom-hooks/useIsFocusedDebounced";

import styles from "styles/modules/createNewCustomer.module.scss";
import ObjectForm from "../../ObjectForm";
import ContactPersonForm from "../../ContactPersonForm";
import Person from "./Person";
import Organization from "./Organization";
import ComboBox from "components/Common/ComboBox";
import { connect } from "react-redux";
import baseService from "services/BaseService";
import { closeCreateNewCustomerModal } from "actions/ui";
import { getCustomer, getContactPerson } from "actions/customer";
import { unlockAppealForm, addCustomerToAppeal, getAppealFeedback } from "modules/appeal/actions";
import Loader from "components/Loader/index";
import SearchPreview from "../../SearchPreview";

import useDebouncedPreviewValues from "../../SearchPreview/useDebouncedPreviewValues";

import "./styles.scss";

const mapFormCode = {
    modal_create_customer_individual_form: "individual",
    modal_create_customer_objectservice_form: "serviceobject",
    modal_create_customer_organization_form: "organization",
    modal_create_customer_contactperson_form: "contactPerson",
};

const forms = {
    individual: Person,
    serviceobject: ObjectForm,
    organization: Organization,
    contactPerson: ContactPersonForm,
};

const INIT_SEARCH_DATA = { result: [], total: 0, isLoading: false };
const DEBOUNCE_TIMEOUT = 500;

function CustomerFormManager(props) {
    const {
        personValues,
        organizationValues,
        objectValues,
        contactPersonValues,
        staticFormBlocks,
        history,
        onClose,
        newCustomerModalContext,

        addCustomerToAppeal,
        getCustomer,
        getContactPerson,
        getAppealFeedback,
    } = props;
    const [customerType, setCustomerType] = useState();
    const [filterId, setFilterId] = useState(null);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dynConfig, setDynConfig] = useState([]);
    const [formCode, setFormCode] = useState();
    const [customerTypeId, setCustomerTypeId] = useState();

    const [searchData, setSearchData] = useState(INIT_SEARCH_DATA);
    
    const currentValues = useMemo(() => {
        if (customerType === "individual") {
            return personValues;
        }

        if (customerType === "contactPerson") {
            return contactPersonValues;
        }

        if (customerType === "organization") {
            return organizationValues;
        }

        return objectValues;
    }, [customerType, personValues, organizationValues, objectValues, contactPersonValues]);

    const modalRef = useRef(null);

    const staticFormBlock = useMemo(() => {
        return staticFormBlocks[formCode];
    }, [formCode, staticFormBlocks]);

    const staticSearchPreviewBlock = useMemo(() => {
        return staticFormBlock && staticFormBlock.blocks && staticFormBlock.blocks.searchPreview;
    }, [staticFormBlock]);

    const staticFormData = useMemo(() => {
        return staticFormBlock ? staticFormBlock.blocks : {};
    }, [formCode, staticFormBlocks]);

    const staticSearchSearchAttrs = useMemo(() => {
        const mergedAttrs = Object.keys(staticFormData).flatMap(key => {
            return staticFormData[key].sortedAttrs;
        });
        return mergedAttrs.filter(attr => attr.searchPreview);
    }, [staticFormData]);

    const { debouncedPreviewValues, setDebouncedPreviewValues, isSearchDataChecked } = useDebouncedPreviewValues(
        currentValues,
        staticSearchSearchAttrs,
        2,
        DEBOUNCE_TIMEOUT,
        searchData
    );

    const isSubmitDisabled = (staticSearchSearchAttrs.length > 0 && !isSearchDataChecked) || searchData.isLoading || searchData.total > 0;


    async function getCustomerTypes() {
        setIsLoading(true);
        const response = await baseService.get("customer_types");

        if (response.result) {
            const types = response.result.map(i => ({
                label: i.partyName,
                value: i.partyType,
                isDefault: i.isDefault,
                formCode: i.formCode,
                filterId: i.filterId,
                id: i.id,
            }));

            setCustomerTypes(types);
            setCustomerType(types.find(type => type.isDefault).value);
            setCustomerTypeId(types.find(type => type.isDefault).id);
            setFormCode(types.find(type => type.isDefault).formCode);
            setFilterId(types.find(type => type.isDefault).filterId);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isEmpty(debouncedPreviewValues)) {
            setSearchData(INIT_SEARCH_DATA);
        }
    }, [debouncedPreviewValues]);

    useEffect(() => {
        setDynConfig([]);
        setDebouncedPreviewValues({});
        setSearchData(INIT_SEARCH_DATA);
    }, [customerType]);

    useEffect(() => {
        getCustomerTypes();
    }, []);

    function handleChangeCustomerType(option) {
        if (option) {
            setCustomerType(option.value);
            setCustomerTypeId(option.id);
            setFormCode(option.formCode);
            setFilterId(option.filterId);

            // setDynConfig([]);
            // setDebouncedPreviewValues({});
            // setSearchData(INIT_SEARCH_DATA);
        }
    }

    async function handleCardSelect(data) {
        const { type } = data;
        let customerId = data.id;
        let contactPersonId;
        if (type === "contact_person") {
            customerId = data.customerId;
            contactPersonId = data.id;
        }
        if (newCustomerModalContext.openNewTab) {
            if (type === "contact_person") {
                // contact person /customer/6701607/person/82313/appeals
                history.push(`/customer/${customerId}/person/${contactPersonId}/appeals`);
            } else {
                // others /customer/6701606/appeals
                history.push(`/customer/${customerId}/appeals`);
            }
        }
        // handle adding customer to appeal
        if (!newCustomerModalContext.openNewTab) {
            setIsLoading(true);
            const path = props.location.pathname;
            const splittedPath = path.split("/");
            const appealsIndex = splittedPath.findIndex(item => item === "appeals");
            const appealId = splittedPath[appealsIndex + 1];
            const callback = get(newCustomerModalContext, "callback");
            if (typeof callback === "function") {
                await callback(customerId, contactPersonId);
            }
            addCustomerToAppeal(appealId, customerId, contactPersonId);
            getCustomer({ customerId }, customerId);
            if (contactPersonId) {
                getContactPerson({ id: contactPersonId }, customerId);
            }
            getAppealFeedback({ appealId, customerId });
            setIsLoading(false);
        }
        onClose();
    }

    const { t, enableWarning } = props;

    // if (!customerType) return null;
    let CustomerForm = forms[customerType];

    if (!CustomerForm) {
        // try to map CustomerForm based on formCode
        const mappedFormCode = mapFormCode[formCode];
        CustomerForm = forms[mappedFormCode];
    }

    return (
        <div className="create-new-customer-modal" ref={modalRef}>
            {isLoading && <Loader />}

            <header className={styles.createCustomerHeader}>
                <div className={styles.createCustomerTitle}>{t("newCustomer")}</div>
            </header>
            <div className="create-new-customer-modal__content">
                <div className={styles.customerTypeZone}>
                    <ComboBox
                        options={customerTypes}
                        label={t("customerType")}
                        value={customerType}
                        onChange={handleChangeCustomerType}
                    />
                </div>

                {CustomerForm && (
                    <CustomerForm
                        t={t}
                        openNewTab={props.openNewTab}
                        setIsLoading={setIsLoading}
                        enableWarning={enableWarning}
                        onClose={props.onClose}
                        customerTypeName={customerType}
                        customerTypeId={customerTypeId}
                        formCode={formCode}
                        filterId={filterId}
                        setDynConfig={setDynConfig}
                        dynConfig={dynConfig}
                        isSubmitDisabled={isSubmitDisabled}
                        key={customerType}
                    />
                )}
            </div>
            {staticSearchPreviewBlock && !isEmpty(debouncedPreviewValues) && (
                <SearchPreview
                    parentRef={modalRef && modalRef.current}
                    searchPreviewValues={debouncedPreviewValues}
                    filterSet={staticSearchPreviewBlock.filterSet}
                    onCardSelect={handleCardSelect}
                    onUpdate={setSearchData}
                />
            )}
        </div>
    );
}

function mapStateToProps(state) {
    const match = matchPath(window.location.hash.substring(1), {
        path: "/appeals/:appealId/:tab",
        exact: true,
        strict: false,
    });

    // const staticBlocks = state.staticBlocks.blocks && state.staticBlocks.blocks.find(block => block.code === 'FORM');
    let formBlocks = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM;
    // set fallbackFormBlocks
    if (!formBlocks || isEmpty(formBlocks)) {
        console.log(
            "CreatNewCustomerModal::mapStateToProps: Static blocks are not configured correctly, fallback to static UI confgig"
        );
        formBlocks =
            (state.staticBlocks &&
                state.staticBlocks.fallbackFormBlocks &&
                state.staticBlocks.fallbackFormBlocks.FORM) ||
            {};
    }

    return {
        contactPersonValues: getFormValues("contact-person-form")(state),
        personValues: getFormValues("person-form")(state),
        organizationValues: getFormValues("organization-form")(state),
        objectValues: getFormValues("object-form")(state),
        staticFormBlocks: formBlocks,
        newCustomerModalContext: state.ui.newCustomerModalContext,
    };
}

const mapDispatchToProps = {
    submitForm: form => submit(form),
    onClose: closeCreateNewCustomerModal,
    getCustomer,
    getContactPerson,
    unlockAppealForm,
    addCustomerToAppeal,
    getAppealFeedback,
    getContactPerson,
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerFormManager)));
