import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './notificationHistory.scss';
import NotificationIndicator from './Indicator';
import Notifications from './Notifications';
import BaseService from '../../services/BaseService';
import { setNotificationHistoryTotal, toggleNotificationHistoryStream } from '../../reducers/notificationHistory';

const LIMIT = 20;

function NotificationHistory(props) {
    const { setNotificationHistoryTotal, toggleNotificationHistoryStream, sourceState, totalHistory } = props;
    const [open, setOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [notifications, setNotifications] = useState(null);
    const [start, setStart] = useState(0);
    const [notificationsLeft, setNotificationsLeft] = useState(0);

    useEffect(() => {
        getData({isInit: true});
    }, []);

    useEffect(() => {
        // if (open && !notifications) {
        //     getData();
        // }
        if (open) {
            getData({isInit: true});
        }
        if (!open) {
            setNotifications(null);
            setStart(0);
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [start]);

    async function getData(options = {}) {
        const { isInit } = options;
        setIsFetching(true);
        const res = await BaseService.get('get_notifications', { data: { limit: LIMIT, start } });
        if (!isInit && notifications) {
            setNotifications([...notifications, ...res.result]);
        } else {
            setNotifications(res.result);
            setNotificationHistoryTotal(res.total);
        }
        const notificationsCount = notifications
            ? notifications.length + res.result.length
            : res.result && res.result.length;
        const notificationsTotal = res.total;
        setNotificationsLeft(notificationsTotal - notificationsCount);
        setIsFetching(false);
    }

    async function toggleReadStatus(msgUserLogId = null, unread) {
        const data = { msgUserLogId, unread };
        const res = await BaseService.post('notification_mark_read', { data, jsonType: true });
        if (res.success) {
            setNotifications(
                notifications.map((notification) => {
                    if (!msgUserLogId) {
                        return { ...notification, marked_read: !msgUserLogId };
                    }
                    if (msgUserLogId === notification.msgUserLogId) {
                        return { ...notification, marked_read: !notification.marked_read };
                    }
                    return notification;
                }),
            );
        }
    }
    async function onDelete(msgUserLogId = null, undelete) {
        const data = { msgUserLogId, undelete };
        const res = await BaseService.post('notification_mark_delete', { data, jsonType: true });
        if (res.success) {
            if (msgUserLogId) {
                setNotifications(
                    notifications.filter(
                        (notification) => notification.msgUserLogId !== msgUserLogId,
                    ),
                );
                setNotificationHistoryTotal(totalHistory - 1);
                // setTotal(total - 1);
            }
            if (!msgUserLogId) {
                setNotificationHistoryTotal(0);
                // setTotal(0);
                setNotifications([]);
            }
        }
    }

    function toggle(e) {
        setOpen((value) => !value);
    }
    function close(e) {
        setOpen(false);
    }

    function updateStart() {
        const newStart = start + LIMIT;
        if (notificationsLeft > 0 && !isFetching && newStart <= totalHistory + notificationsLeft) {
            setStart(newStart);
        }
    }

    return (
        <div className="notification-history">
            <NotificationIndicator onClick={toggle} indicator={totalHistory} streamState={sourceState} />
            <Notifications
                close={close}
                open={open}
                notifications={notifications}
                total={totalHistory}
                toggleReadStatus={toggleReadStatus}
                onDelete={onDelete}
                onScrollDown={updateStart}
                isFetching={isFetching}
                streamState={sourceState}
                toggleNotificationHistoryStream={toggleNotificationHistoryStream}
            />
        </div>
    );
}

const mapStateToProps = ({ notificationHistory }) => ({
    totalHistory: notificationHistory.total,
    sourceState: notificationHistory.eventSourceState,
});

const mapDispatchToProps = {
    setNotificationHistoryTotal,
    toggleNotificationHistoryStream
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHistory);
