export const CALENDAR_REQUEST = 'CALENDARCALENDAR_REQUEST';
export const CALENDAR_REQUEST_SUCCESS = 'CALENDAR_REQUEST_SUCCESS';
export const CALENDAR_REQUEST_FAILURE = 'CALENDAR_REQUEST_FAILURE';
export const CALENDAR_FILTERS_REQUEST = 'CALENDAR_FILTERS_REQUEST';
export const CALENDAR_FILTERS_REQUEST_SUCCESS = 'CALENDAR_FILTERS_REQUEST_SUCCESS';
export const CALENDAR_FILTERS_REQUEST_FAILURE = 'CALENDAR_FILTERS_REQUEST_FAILURE';
export const CALENDAR_SET_ACTIVE_FILTER = 'CALENDAR_SET_ACTIVE_FILTER';
export const CALENDAR_SET_ACTIVE_FILTER_PAGE = 'CALENDAR_SET_ACTIVE_FILTER_PAGE';
export const CALENDAR_FILTER_ATTRS_GET_SUPPOSE = 'CALENDAR_FILTER_ATTRS_GET_SUPPOSE';
export const CALENDAR_FILTER_ATTRS_GET_SUPPOSE_SUCCESS = 'CALENDAR_FILTER_ATTRS_GET_SUPPOSE_SUCCESS';
export const CALENDAR_FILTER_ATTRS_GET_SUPPOSE_FAIL = 'CALENDAR_FILTER_ATTRS_GET_SUPPOSE_FAIL';
export const CALENDAR_ADD_FILTER_ATTRS = 'CALENDAR_ADD_FILTER_ATTRS';
export const CALENDAR_SAVE_FILTER_SUCCESS = 'CALENDAR_SAVE_FILTER_SUCCESS';
export const CALENDAR_SAVE_FILTER_FAIL = 'CALENDAR_SAVE_FILTER_FAIL';
export const CALENDAR_SAVE_FILTER = 'CALENDAR_SAVE_FILTER';
export const CALENDAR_DELETE_FILTER = 'CALENDAR_DELETE_FILTER';
export const CALENDAR_DELETE_FILTER_SUCCESS = 'CALENDAR_DELETE_FILTER_SUCCESS';
export const CALENDAR_DELETE_FILTER_FAIL = 'CALENDAR_DELETE_FILTER_FAIL';
export const CALENDAR_FILTER_PARAMS_GET_SUPPOSE = 'CALENDAR_FILTER_PARAMS_GET_SUPPOSE';
export const CALENDAR_FILTER_PARAMS_GET_SUPPOSE_SUCCESS = 'CALENDAR_FILTER_PARAMS_GET_SUPPOSE_SUCCESS';
export const CALENDAR_FILTER_PARAMS_GET_SUPPOSE_FAIL = 'CALENDAR_FILTER_PARAMS_GET_SUPPOSE_FAIL';
export const CALENDAR_FILTER_ATTRS_CLEAR_RESULT = 'CALENDAR_FILTER_ATTRS_CLEAR_RESULT';
export const CALENDAR_CLEAR_FILTER_ATTRS = 'CALENDAR_CLEAR_FILTER_ATTRS';
export const CALENDAR_CLEAR_FILTER_INFO = 'CALENDAR_CLEAR_FILTER_INFO';
export const CALENDAR_FILTER_DATA_GET_SUPPOSE = 'CALENDAR_FILTER_DATA_GET_SUPPOSE';
export const CALENDAR_FILTER_DATA_GET_SUPPOSE_SUCCESS = 'CALENDAR_FILTER_DATA_GET_SUPPOSE_SUCCESS';
export const CALENDAR_FILTER_DATA_GET_SUPPOSE_FAIL = 'CALENDAR_FILTER_DATA_GET_SUPPOSE_FAIL';
export const CALENDAR_OPEN_FILTER_PARAMS = 'CALENDAR_OPEN_FILTER_PARAMS';
export const CALENDAR_CLOSE_FILTER_PARAMS = 'CALENDAR_CLOSE_FILTER_PARAMS';
export const CALENDAR_OPEN_NEW_FILTER_PARAMS = 'CALENDAR_OPEN_NEW_FILTER_PARAMS';
export const CALENDAR_CLOSE_NEW_FILTER_PARAMS = 'CALENDAR_CLOSE_NEW_FILTER_PARAMS';
export const CALENDAR_CREATE_NEW_FILTER = 'CALENDAR_CREATE_NEW_FILTER';
export const CALENDAR_FILTER_LIST_GET_SUPPOSE = 'CALENDAR_FILTER_LIST_GET_SUPPOSE';
export const CALENDAR_FILTER_LIST_GET_SUPPOSE_SUCCESS = 'CALENDAR_FILTER_LIST_GET_SUPPOSE_SUCCESS';
export const CALENDAR_FILTER_LIST_GET_SUPPOSE_FAIL = 'CALENDAR_FILTER_LIST_GET_SUPPOSE_FAIL';
export const CALENDAR_FILTER_LIST_CLEAR_RESULT = 'CALENDAR_FILTER_LIST_CLEAR_RESULT';
export const CALENDAR_ADD_FILTER_OPTION = 'CALENDAR_ADD_FILTER_OPTION';
export const CALENDAR_SET_OLD_FILTER_DATA = 'CALENDAR_SET_OLD_FILTER_DATA';
export const CALENDAR_CLEAR_NEW_FILTER_OPTION = 'CALENDAR_CLEAR_NEW_FILTER_OPTION';
export const CALENDAR_CLEAR_FILTER_DATA = 'CALENDAR_CLEAR_FILTER_DATA';
export const CALENDAR_APPLY_EXISTING_FILTER = 'CALENDAR_APPLY_EXISTING_FILTER';
export const CALENDAR_SET_SELECT_OPTIONS_FOR_FILTER = 'CALENDAR_SET_SELECT_OPTIONS_FOR_FILTER';
export const SEARCH_GET_CALENDAR_SUPPOSE = 'SEARCH_GET_CALENDAR_SUPPOSE';
export const SEARCH_GET_CALENDAR_SUPPOSE_SUCCESS = 'SEARCH_GET_CALENDAR_SUPPOSE_SUCCESS';
export const SEARCH_GET_CALENDAR_SUPPOSE_FAILURE = 'SEARCH_GET_CALENDAR_SUPPOSE_FAILURE';
export const CLEAR_SEARCH_GET_CALENDAR_SUPPOSE = 'CLEAR_SEARCH_GET_CALENDAR_SUPPOSE';
export const CEVENT_GET_SUPPOSE = 'CEVENT_GET_SUPPOSE';
export const CEVENT_GET_SUPPOSE_SUCCESS = 'CEVENT_GET_SUPPOSE_SUCCESS';
export const CEVENT_GET_SUPPOSE_FAILURE = 'CEVENT_GET_SUPPOSE_FAILURE';
export const RRULES_CEVENT_GET_SUPPOSE = 'RRULES_CEVENT_GET_SUPPOSE';
export const RRULES_CEVENT_SUCCESS = 'RRULES_CEVENT_SUCCESS';
export const RRULES_CEVENT_FAILURE = 'RRULES_CEVENT_FAILURE';
export const VALARM_CEVENT_GET_SUPPOSE = 'VALARM_CEVENT_GET_SUPPOSE';
export const VALARM_CEVENT_SUCCESS = 'VALARM_CEVENT_SUCCESS';
export const VALARM_CEVENT_FAILURE = 'VALARM_CEVENT_FAILURE';
export const TYPES_CEVENT_GET_SUPPOSE = 'TYPES_CEVENT_GET_SUPPOSE';
export const TYPES_CEVENT_SUCCESS = 'TYPES_CEVENT_SUCCESS';
export const TYPES_CEVENT_FAILURE = 'TYPES_CEVENT_FAILURE';
export const USER_CEVENTS_GET_SUPPOSE = 'USER_CEVENTS_GET_SUPPOSE';
export const USER_CEVENTS_SUCCESS = 'USER_CEVENTS_SUCCESS';
export const USER_CEVENTS_FAILURE = 'USER_CEVENTS_FAILURE';
export const DELETE_CEVENTS_SUPPOSE = 'DELETE_CEVENTS_SUPPOSE';
export const DELETE_CEVENT_SUCCESS = 'DELETE_CEVENT_SUCCESS';
export const DELETE_CEVENT_FAILURE = 'DELETE_CEVENT_FAILURE';
export const STATUSES_CEVENTS_GET_SUPPOSE = 'STATUSES_CEVENTS_GET_SUPPOSE';
export const STATUSES_CEVENTS_SUCCESS = 'STATUSES_CEVENTS_SUCCESS';
export const STATUSES_CEVENTS_FAILURE = 'STATUSES_CEVENTS_FAILURE';
export const GET_DYN_PARAMS_CEVENT_GET_SUPPOSE = 'GET_DYN_PARAMS_CEVENT_GET_SUPPOSE';
export const GET_DYN_PARAMS_CEVENT_SUCCESS = 'GET_DYN_PARAMS_CEVENT_SUCCESS';
export const CEVENT_CLEAR_OPENED_EVENT = 'CEVENT_CLEAR_OPENED_EVENT';
