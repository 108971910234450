import React from 'react';

function FormBlock ({ children }) {
	if (!children) return null;
	
	return (
		<div className='formBlock'>
			{children}
		</div>
	);
}

export default FormBlock;
