import React, { useEffect, useState } from "react";
import { Prompt, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import cx from "classnames";

import ModalPortal from "../ModalPortal";
import MessageModal from "../MessageModal";

export const RouteLeavingGuard = props => {
    const { t, navigate, when, shouldBlockNavigation, history } = props;
    const [modalVisible, updateModalVisible] = useState(false);
    const [lastLocation, updateLastLocation] = useState();
    const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

    const showModal = location => {
        updateModalVisible(true);
        updateLastLocation(location);
    };

    const closeModal = cb => {
        updateModalVisible(false);
        if (cb && typeof cb === "function") {
            cb();
        }
    };

    const handleBlockedNavigation = nextLocation => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        closeModal(() => {
            if (lastLocation) {
                updateConfirmedNavigation(true);
            }
        });
    };

    useEffect(() => {
        if (confirmedNavigation) {
            navigate(history, lastLocation.pathname);
            updateConfirmedNavigation(false);
        }
    }, [confirmedNavigation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            {modalVisible && (
                <ModalPortal onClose={closeModal} className={cx("modal-small", "modal-center")}>
                    <MessageModal
                        contentModalText={t("saveWithoutChangeConfirm.text")}
                        titleModal={t("saveWithoutChangeConfirm.title")}
                        primaryButton
                        primaryButtonText={t("saveWithoutChangeConfirm.yes")}
                        onClickPrimaryButton={handleConfirmNavigationClick}
                        secondaryButton
                        secondaryButtonText={t("saveWithoutChangeConfirm.no")}
                        onClickSecondaryButton={closeModal}
                    />
                </ModalPortal>
            )}
        </>
    );
};

export default withRouter(withTranslation()(RouteLeavingGuard));
