import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { change } from 'redux-form';

import ClickOutsideHolder from 'components/ClickOutsideHolder';
import LiveSearch from 'components/LiveSearch/LiveSearch';
import { openCreateNewCustomerModal, openCreateNewContactPersonModal, closeCreateNewCustomerModal } from 'actions/ui';
import {
    getApplicants,
    clearApplicantsState,
    getCustomer,
    getContactPerson,
} from 'actions/customer';
import {
    openAdvancedSearchModal,
    // closeAdvancedSearchModal,
    getLiveSearchClientSuppose,
    clearLiveSearchClient,
} from 'actions/client';
import { addCustomerToAppeal, getAppealFeedback } from 'modules/appeal/actions';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import styles from 'styles/modules/appealsContent.module.scss';
import permissions, { checkPermissions } from 'config/permissions';
import { extractAppealFromState } from 'helpers';
import { getFormValues } from 'redux-form';
import BaseService from 'services/BaseService';

const LOCK_SOURCE = "customer";

function mapStateToProps(state, props) {
    const [appeal] = extractAppealFromState(state, props);
    const appealId = appeal.currentAppeal.id;

    return {
        query: state.client.query,
        requiredCustomer: appeal.requiredCustomer,
        // showAdvancedSearchModal: state.client.showAdvancedSearchModal,
        applicants: state.customer.applicants,
        // appealFormValues: getFormValues(`appeal-form-${appealId}`)(state),
        appealId,
        customerButtonRestriction: get(appeal, 'currentAppeal.restriction.customerId'),
        isCustomerCreationAllowed: checkPermissions(
            permissions.CustomerOperations.action_createCustomer,
        ),
        isContactPersonCreationAllowed: checkPermissions(
            permissions.ContactPersonOperations.action_create_contactPerson,
        ),
        incomePhoneNumber: state.call.incomePhoneNumber,
        createdAppeals: state.call.createdAppeals,
    };
}

const mapDispatchToProps = {
    openCreateNewCustomerModal,
    openCreateNewContactPersonModal,
    openAdvancedSearchModal,
    // closeAdvancedSearchModal,
    getLiveSearchClientSuppose,
    getApplicants,
    clearApplicantsState,
    clearLiveSearchClient,
    getCustomer,
    // unlockAppealForm,
    getContactPerson,
    addCustomerToAppeal,
	getAppealFeedback,
	change,
    // change: (form, field, value) => change(form, field, value),
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class NotDefinedClient extends React.Component {
    state = {
        value: '',
        showButton: false,
    };

    componentDidMount() {
        if (isEmpty(this.props.applicants)) {
            this.props.clearLiveSearchClient();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.query !== prevState.value) {
            return { value: nextProps.query };
        }
        return null;
    }

    componentDidUpdate({ match }) {
        if (match.params.appealId !== this.props.match.params.appealId) {
            if (this.search) {
                this.search.value = null;
            }
            this.props.clearLiveSearchClient();
        }
    }

    handleAdvencedSearchModalOpen = () => {
        // lock appeal form on starting customer search
        this.props.unlockAppealForm(LOCK_SOURCE);
        this.props.openAdvancedSearchModal(this.onCreateCustomer);
    }

    handleChange = (evt) => {
        const value = evt.currentTarget.value;
        this.setState({ value });
        this.triggerChange(value);
        this.props.getLiveSearchClientSuppose(value);
    };

    triggerChange = debounce((query) => {
        if (query.length > 2) {
            // lock appeal form on starting customer search
            this.props.unlockAppealForm(LOCK_SOURCE);
            this.props.getApplicants({
                query: query,
                page: 1,
                start: 0,
                limit: 8,
                filterSet: 'customers',
            });
        } else if (this.props.applicants.length > 0) {
            this.props.clearApplicantsState();
        }
    }, SEARCH_TIMER_INTERVAL);

    onSearchKeyUp = (e) => {
        if (e.keyCode === 13) {
            // this.props.openAdvancedSearchModal(this.onCreateCustomer);
            this.handleAdvencedSearchModalOpen();
        } else if (e.keyCode === 27) {
            this.search.value = null;
        }
    };

    onFocus = (e) => {
        if (
            this.props.customerButtonRestriction &&
            [1, 3].includes(Number(this.props.customerButtonRestriction))
        ) {
            this.setState({ showButton: true });
        }
        this.handleChange(e);
    };

    onSelectApplicant = async (applicant) => {
        const { appealId, incomePhoneNumber, createdAppeals } = this.props;
        const { id, type } = applicant;
        const customerType = applicant.partyType;
        let customerId = id;
        let contactPersonId = 0;
        if (type === 'contact_person') {
            customerId = applicant.customerId;
            contactPersonId = applicant.id;
        }
        const data = { customerId, contactPersonId };

        const setCustomer = async () => {
            const addCustomerResult = await BaseService.post('appeal_customer', {
                pathParams: { appealId },
                data,
                jsonType: true,
            });

            if (addCustomerResult.success) {
                this.props.addCustomerToAppeal(appealId, customerId, id);
                this.props.getCustomer({ customerId }, customerId);
                this.props.getContactPerson({ id: id }, customerId);

                // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
                this.props.removeAppealLockSource(LOCK_SOURCE);
            }
        };

        const onSuccess = async (cpId) => {
            const onSuccessData = { customerId, contactPersonId: cpId };
            const addCustomerResult = await BaseService.post('appeal_customer', {
                pathParams: { appealId },
                data: onSuccessData,
                jsonType: true,
            });

            if (addCustomerResult.success) {
                this.props.addCustomerToAppeal(appealId, id, cpId);
                this.props.getCustomer({ customerId }, customerId);
                this.props.getContactPerson({ id: cpId }, customerId);

                // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
                this.props.removeAppealLockSource(LOCK_SOURCE);
            }
        };

        if (customerType !== 'individual' && type !== 'contact_person') {
            if (this.props.isContactPersonCreationAllowed && contactPersonId === 0) {
                return this.props.openCreateNewContactPersonModal({
                    customerId,
                    customerTypeName: customerType,
                    onSuccess,
                });
            }
            setCustomer();
        }

        if (type === 'contact_person') {
            setCustomer();
        }

        if (customerType === 'individual') {
            const addCustomerResult = await BaseService.post('appeal_customer', {
                pathParams: { appealId },
                data,
                jsonType: true,
            });

            if (addCustomerResult.success) {
                // this.props.addCustomerToAppeal(appealId, id, contactPersonId);
                // this.props.getCustomer({customerId: id}, id);
                this.props.addCustomerToAppeal(appealId, customerId, contactPersonId);
                this.props.getCustomer({ customerId }, customerId);

                // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
                this.props.removeAppealLockSource(LOCK_SOURCE);
            }
        }

        const feedback = await this.props.getAppealFeedback({ appealId, customerId: customerId });
        const appealInCallInteraction = createdAppeals.find(
            (i) => parseInt(i.id) === parseInt(appealId),
        );
        let selectedContacts = feedback.flatMap((i) => i.selectedContacts);

        if (incomePhoneNumber && appealInCallInteraction) {
            const contact = feedback
                .flatMap((i) => i.contacts)
                .find((contact) => contact.value === incomePhoneNumber);

            if (contact) {
                selectedContacts = [...selectedContacts, contact.id];
            }
        }

        this.props.change(`appeal-form-${appealId}`, 'destinationFeedback', selectedContacts);
    };

    onClick = (event, selectedApplicant) => {
        if (this.props.isLockedByOther) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        const applicant = this.props.applicants[selectedApplicant.index];
        this.onSelectApplicant(applicant);

        this.props.clearApplicantsState();
    };

    handleClickOutside = (e) => {
        const inputClick = this.search && this.search.contains(e.target);
        const newCustomerButtonClick =
            this.newCustomerButton && this.newCustomerButton.contains(e.target);

        if (!inputClick && !newCustomerButtonClick) {
            if (this.state.showButton) this.setState({ showButton: false });
            if (this.props.applicants.length !== 0) { 
                this.props.clearApplicantsState();
            }
            if (!this.props.showCreateNewCustomerModal && !this.props.showAdvancedSearchModal) {
                // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
                this.props.removeAppealLockSource(LOCK_SOURCE);
            }
        }
    };
    // const applicantsEnum = {};
    convertApplicants = (applicants) =>
        applicants.slice(0, 8).map((elem, index) => ({
            id: elem.id,
            type: elem.type,
            name: elem.customerName || elem.fullName,
            content: elem.phyAddress || elem.contactName,
            code: 'notDefinedClient',
            applId: elem.id,
            index,
        }));

    onCreateCustomer = async (customerId, personId = 0) => {
        // console.log({ customerId, personId });
        const { appealId } = this.props;
        const onSuccessData = { customerId, contactPersonId: personId };
        const addCustomerResult = await BaseService.post('appeal_customer', {
            pathParams: { appealId },
            data: onSuccessData,
            jsonType: true,
        });
        if (addCustomerResult.success) {
            // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
            this.props.removeAppealLockSource(LOCK_SOURCE);
        }
        if (!addCustomerResult.success)
            console.warn(`error add customer on request (error: ${addCustomerResult.errorMessage}`);
    };

    handleCreateCustomerClick = () => {
        // lock appeal form on starting customer search
        const onSuccess = () => {
            this.props.openCreateNewCustomerModal({
                openNewTab: false,
                callback: this.onCreateCustomer,
                onClose: () => this.props.removeAppealLockSource(LOCK_SOURCE)
            });
        }
        this.props.unlockAppealForm(LOCK_SOURCE, onSuccess);
    }

    render() {
        const {
            applicants,
            query,
            // showAdvancedSearchModal,
            t,
            isCustomerCreationAllowed,
			isContactPersonCreationAllowed,
			openAdvancedSearchModal,
            customerRemoveRestriction,
        } = this.props;
        const permittedShowCreateBtn = isCustomerCreationAllowed || isContactPersonCreationAllowed;
        const searchResults = this.convertApplicants(applicants);

        return (
            <div className={styles.notDefinedClientWrapper}>
                <div className={styles.headerTitle}>{t('searchClient.notDefinedClient')}</div>

                {customerRemoveRestriction === 3 ? (
                    <div
                        className={cx(styles.liveSearchClient, {
                            inputFocus: this.state.showButton,
                            error: this.props.requiredCustomer,
                            disabled: this.props.isLockedByOther
                        })}
                    >
                        <ClickOutsideHolder onClickOutside={this.handleClickOutside}>
                            <input
                                name="client"
                                autoComplete="off"
                                className={cx(styles.clientInput, {
                                    inputVal: this.state.value || query,
                                })}
                                onChange={this.handleChange}
                                onKeyUp={this.onSearchKeyUp}
                                ref={(node) => (this.search = node)}
                                value={this.state.value || query || ''}
                                placeholder={t('searchclient.search')}
                                onFocus={this.onFocus}
                            />
                            <span className="icon icon-search2 not-defined" />

                            <button
                                onClick={this.handleAdvencedSearchModalOpen}
                                className={styles.iconButton}
                                title={t('searchClient.advanceSearch')}
                            >
                                <span className="icon icon-advance" />
                            </button>

                            {searchResults && (
                                <LiveSearch
                                    searchResults={searchResults}
                                    className={'liveSearch-appeal'}
                                    onClick={this.onClick}
                                />
                            )}
                        </ClickOutsideHolder>
                    </div>
                ) : null}

                {(applicants.length > 0 || this.state.showButton) && permittedShowCreateBtn && (
                    <button
                        onClick={this.handleCreateCustomerClick}
                        className={styles.createNewButton}
                        ref={(node) => (this.newCustomerButton = node)}
                        disabled={this.props.isLockedByOther}
                    >
                        {t('searchClient.createNew')}
                    </button>
                )}
            </div>
        );
    }
}

NotDefinedClient.propTypes = {
    getApplicants: PropTypes.func,
    openCreateNewCustomerModal: PropTypes.func,
    applicants: PropTypes.array,
    query: PropTypes.string,
    showAdvancedSearchModal: PropTypes.bool,
    openAdvancedSearchModal: PropTypes.func,
    getLiveSearchClientSuppose: PropTypes.func,
    clearLiveSearchClient: PropTypes.func,
    // closeAdvancedSearchModal: PropTypes.func,
    clearApplicantsState: PropTypes.func,
    definedCustomer: PropTypes.func,
    addAppealCustomerId: PropTypes.func,
    appealId: PropTypes.number,
    appealRestriction: PropTypes.number,
    customerButtonRestriction: PropTypes.string,
    isCustomerCreationAllowed: PropTypes.bool,
};
