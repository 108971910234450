import {
    SEARCH_FILTERS_REQUEST,
    SEARCH_FILTERS_REQUEST_SUCCESS,
    SEARCH_FILTERS_REQUEST_FAILURE,

    FILTER_FIELDS_REQUEST,
    FILTER_FIELDS_REQUEST_SUCCESS,
    FILTER_FIELDS_REQUEST_FAILURE,

    ALL_FILTER_FIELDS_REQUEST,
    ALL_FILTER_FIELDS_REQUEST_SUCCESS,
    ALL_FILTER_FIELDS_REQUEST_FAILURE,

    SET_CURRENT_SEARCH_FILTER,
    CLEAR_SEARCH_FILTERS,
    SET_SEARCH_FILTER_CRITERIA,

    APPEAL_TYPES_REQUEST,
    APPEAL_TYPES_REQUEST_SUCCESS,
    APPEAL_TYPES_REQUEST_FAILURE,
    SEARCH_FILTERS_DYNAMIC_STATE_RESET
} from 'constants/actions';
import baseService from 'services/BaseService';

/********  Search filters request  ********/

export const requestSearchFilters = () => ({
    type: SEARCH_FILTERS_REQUEST
});

export const requestSearchFiltersSuccess = filterList => ({
    type: SEARCH_FILTERS_REQUEST_SUCCESS,
    filterList
});

export const requestSearchFiltersFailure = () => ({
    type: SEARCH_FILTERS_REQUEST_FAILURE
});

export const getSearchFilters = requestData => dispatch => {
    dispatch(requestSearchFilters());

    const params = { data: requestData };
    return baseService.get('filters', params)
        .then(response => {
            if (response.success && Array.isArray(response.result)) {
                dispatch(requestSearchFiltersSuccess(response.result));
            } else {
                throw new Error('Search filters request error');
            }
        })
        .catch(error => {
            console.error(error);
            dispatch(requestSearchFiltersFailure());
        });
};

/********  Set current search filter  ********/

export const setCurrentSearchFilter = (currentFilter, id) => ({
    type: SET_CURRENT_SEARCH_FILTER,
    currentFilter,
	id,
});

/********  All filter fields request  ********/

export const requestAllFilterFields = (idHashKeyRequest) => ({
    type: ALL_FILTER_FIELDS_REQUEST,
    idHashKeyRequest,
});

export const requestAllFilterFieldsSuccess = response => ({
    type: ALL_FILTER_FIELDS_REQUEST_SUCCESS,
	allFilterFields: response.result,
	idHashKeyRequest: response.idHashKeyRequest
});

export const requestAllFilterFieldsFailure = () => ({
    type: ALL_FILTER_FIELDS_REQUEST_FAILURE
});

export const getAllFilterFields = (requestData) => dispatch => {
	dispatch(requestAllFilterFields({ idHashKeyRequest: requestData.idHashKeyRequest}));
	const params = { data: requestData };

	return baseService.get('attributes',params)
        .then(response => {
	
			if (response.success && Array.isArray(response.result)) {
                dispatch(requestAllFilterFieldsSuccess(response));
            } else {
                throw new Error('All filter fields request error');
            }
        })
        .catch(error => {
            console.error(error);
            dispatch(requestAllFilterFieldsFailure());
        });
};

/********  Clear search filters  ********/

export const clearSearchFilters = () => ({
    type: CLEAR_SEARCH_FILTERS
});

/********  Set search filter criteria  ********/

export const setSearchFilterCriteria = (filterCriteria, id) => ({
    type: SET_SEARCH_FILTER_CRITERIA,
    filterCriteria,
	id,
});

/********  Appeal types request  ********/

const requestAppealTypes = () => ({
    type: APPEAL_TYPES_REQUEST
});

const requestAppealTypesSuccess = appealTypes => ({
    type: APPEAL_TYPES_REQUEST_SUCCESS,
    appealTypes
});

const requestAppealTypesFailure = () => ({
    type: APPEAL_TYPES_REQUEST_FAILURE
});

export const getAppealTypes = () => dispatch => {
    const params = {
        data: {
            classCode: 0,
            node: 'root'
        }
    };

    dispatch(requestAppealTypes());

    return baseService.get('appeal_type', params)
        .then(response => {
            if (response.success && Array.isArray(response.children)) {
                dispatch(requestAppealTypesSuccess(response.children));
            } else {
                throw new Error('Appeal types request error');
            }
        })
        .catch(error => {
            console.error(error);
            dispatch(requestAppealTypesFailure());
        });
};

/********  Reset dynamic part of state  ********/

export const resetSearchFilterDynamicState = () => ({
    type: SEARCH_FILTERS_DYNAMIC_STATE_RESET,
});
