import React from 'react';
import moment from 'moment';
import ReactSVG from 'react-svg';
import CircleStatus from '../components/CircleStatus';
import UnlinkPersonCell from '../components/Common/GridCells/UnlinkPersonCell';
import Expander from '../components/Expander';
import FullStatus from '../pages/Ordering/common/FullStatus';
import FixedStatus from '../pages/Ordering/common/FixedStatus';
import PriorityArrowsIcons from 'components/SVG/PriorityArrowsIcons';
import format from '../constants/tabsFormats';
import i18next from 'i18next';
import { getFormattedSLA } from '../helpers';

export default {
    get_order_list_: {
        fields: {
            fixedStatus: {
                permanent: true,
                width: 4,
                classWidth: 'fixed',
                noDnD: true,
                component: FixedStatus,
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                component: Expander,
                classWidth: 'removed-resizer',
            },
            status: {
                name: 'status',
                permanent: true,
                width: 200,
                component: FullStatus,
            },
            code: {
                name: 'code',
                permanent: true,
                classWidth: '1',
            },
            type: {
                name: 'type',
                permanent: true,
                classWidth: '1',
                value: (data, val) => val.name,
            },
            customer: {
                name: 'customerName',
                permanent: true,
                classWidth: '1',
                value: (data, val) => val.name,
            },
            taskPriority: {
                name: 'priority',
                permanent: true,
                classWidth: '1',
            },
            owner: {
                name: 'responsible',
                classWidth: '1',
                value: (data, val) => val.name,
            },
            creator: {
                name: 'creator',
                classWidth: '1',
                value: (data, val) => val.name,
            },
            createDate: {
                name: 'creationDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            startDate: {
                name: 'factStartDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            endDate: {
                name: 'factEndDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            planStartDate: {
                name: 'plannedStartDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            planEndDate: {
                name: 'plannedEndDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            taskInfo: {
                name: 'additionalInfo',
                classWidth: '1',
            },
            hasFlow: {
                name: 'processDiagram',
                noSorted: true,
                classWidth: '1',
            },
        },
    },
    search_: {
        fields: {
            partyType: {
                name: 'partyType',
                noSorted: true,
                permanent: true,
                width: 50,
            },
            fullName: {
                name: 'name',
                noSorted: true,
                permanent: true,
                width: 225,
            },
            phyAddress: {
                name: 'physicalAddress',
                noSorted: true,
                permanent: true,
                width: 245,
            },
        },
    },
    searchEmailContacts_: {
        fields: {
            checkbox: {
                noSorted: true,
                width: 10,
                classWidth: '2',
                noDnD: true,
            },
            name: {
                name: 'emailSearchPIB',
                width: 100,

            },
            email: {
                name: 'email',
                permanent: true,
                width: 100,
            },
            position: {
                name: 'emailSearchPosition',
                width: 100,
                // permanent: true,
            },
        },
    },
    get_order_comments_email_history_: {
        fields: {
            mailDate: {
                name: 'mailDate',
                noSorted: true,
                width: 100,
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            subject: {
                name: 'mailSubject',
                noSorted: true,
                width: 100,
            },
            message: {
                name: 'mailMessage',
                noSorted: true,
                width: 100,
            },
            mailToUsers: {
                name: 'mailUsers',
                noSorted: true,
                width: 100,
                value: (data, val) => val && val.map((v) => v.userEmail).join(', '),
            },
            mailToOther: {
                name: 'mailOthers',
                noSorted: true,
                width: 100,
            },
        },
    },
    get_order_objects_: {
        fields: {
            keyValue: {
                permanent: true,
                component: Expander,
                width: 20,
            },
            state: {
                name: 'status',
                permanent: true,
                component: CircleStatus,
                width: 20,
            },
            objectTypeCode: {
                name: 'type',
                permanent: true,
                width: 50,
            },
            specInstanceId: {
                name: 'id',
                width: 35,
            },
            specInstanceName: {
                name: 'name',
                width: 60,
            },
            actionType: {
                name: 'actionType',
                width: 40,
            },
            validFrom: {
                name: 'dateAdded',
                width: 95,
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm:ss'),
            },
            processingInfo: {
                name: 'status',
                width: 65,
            },
            rowButtons: {
                width: 50,
            },
        },
        defaultId: 'specInstanceId',
    },
    get_object_processing_info_: {
        fields: {
            code: {
                name: 'code',
                permanent: true,
                width: 53,
            },
            typeName: {
                name: 'typeName',
                permanent: true,
                width: 53,
            },
            status: {
                name: 'status',
                permanent: true,
                width: 53,
            },
            factBegDate: {
                name: 'factBegDateProcessing',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm:ss'),
                permanent: true,
                width: 53,
            },
            factEndDate: {
                name: 'factEndDateProcessing',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm:ss'),
                permanent: true,
                width: 53,
            },
        },
    },
    customer_tasks_: {
        fields: {
            fixedStatus: {
                permanent: true,
                width: 4,
                classWidth: 'fixed',
                noDnD: true,
                component: FixedStatus,
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
            },
            taskStateCode: {
                name: 'status',
                permanent: true,
                width: 200,
                component: FullStatus,
            },
            taskCode: {
                name: 'code',
                permanent: true,
                classWidth: '1',
            },
            taskName: {
                name: 'type',
                permanent: true,
                classWidth: '1',
            },
            ownerName: {
                name: 'responsible',
                classWidth: '1',
            },
            servNames: {
                name: 'additionalInfo',
                classWidth: '1',
            },
            createDate: {
                name: 'creationDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            hasFlow: {
                name: 'processDiagram',
                noSorted: true,
                classWidth: '1',
            },
        },
        defaultId: 'taskId',
    },
    // customer_services_: {
    //     fields: {
    //         // fixedStatus: {
    //         //     permanent: true,
    //         //     width: 4,
    //         //     classWidth: 'fixed',
    //         //     noDnD: true,
    //         //     component: FixedStatus,
    //         //     noSorted: true,
    //         // },
    //         keyValue: {
    //             permanent: true,
    //             noDnD: true,
    //             // width: 25,
    //             noSorted: true,
    //             component: Expander,
    //         },
    //         statusCode: {
    //             permanent: true,
    //             name: 'status',
    //             // width: 200,
    //             component: FullStatus,
    //             noSorted: true,
    //         },
    //         'specType': {
    //             permanent: true,
    //             name: 'type',
    //             // width: 200,
    //             noSorted: true,
    //             value: (data, val) => val && val.name,
    //         },
    //         id: {
    //             name: 'serviceId',
    //             // width: 50,
    //             noSorted: true,
    //         },
    //         name: {
    //             name: 'name',
    //             // width: 150,
    //             noSorted: true,
    //         },
    //         customer: {
    //             permanent: true,
    //             name: 'customerName',
    //             noSorted: true,
    //             value: (data, val) => val && val.name,
    //         },
    //         client: {
    //             permanent: true,
    //             name: 'clientName',
    //             noSorted: true,
    //             value: (data, val) => val && val.name,
    //         },
    //         validFrom: {
    //             name: 'serviceFrom',
    //             // width: 100,
    //             value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
    //             noSorted: true,
    //         },
    //         validTill: {
    //             name: 'serviceTill',
    //             // width: 100,
    //             value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
    //             noSorted: true,
    //         }
    //     }
    // },
    get_services_list_: {
        fields: {
            fixedStatus: {
                permanent: true,
                width: 4,
                noDnD: true,
                classWidth: 'fixed',
                component: FixedStatus,
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
            },
            statusCode: {
                name: 'status',
                permanent: true,
                width: 200,
                component: FullStatus,
            },
            typeCode: {
                name: 'type',
                classWidth: '1',
            },
            id: {
                name: 'ID',
                classWidth: '1',
            },
            name: {
                name: 'name',
                classWidth: '1',
            },
            customerName: {
                name: 'customerName',
                classWidth: '1',
                component: ({ data: { customerName, clientId } }) => (
                    <a
                        className="data-cell"
                        href={`#/customer/${clientId}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {customerName}
                    </a>
                ),
            },
            validFrom: {
                name: 'serviceFrom',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            validTill: {
                name: 'serviceTill',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
        },
    },
    get_service_orders_: {
        fields: {
            fixedStatus: {
                permanent: true,
                width: 4,
                classWidth: 'fixed',
                noDnD: true,
                component: FixedStatus,
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
            },
            taskStateCode: {
                name: 'status',
                permanent: true,
                width: 200,
                component: FullStatus,
            },
            taskCode: {
                name: 'code',
                permanent: true,
                classWidth: '1',
            },
            taskName: {
                name: 'type',
                permanent: true,
                classWidth: '1',
            },
            ownerName: {
                name: 'creator',
                classWidth: '1',
            },
            createDate: {
                name: 'creationDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            hasFlow: {
                name: 'processDiagram',
                noSorted: true,
                classWidth: '1',
            },
        },
        defaultId: 'taskId',
    },
    get_resource_orders_: {
        fields: {
            fixedStatus: {
                permanent: true,
                width: 4,
                classWidth: 'fixed',
                noDnD: true,
                component: FixedStatus,
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
            },
            taskStateCode: {
                name: 'status',
                permanent: true,
                width: 200,
                component: FullStatus,
            },
            taskCode: {
                name: 'code',
                permanent: true,
                classWidth: '1',
            },
            taskName: {
                name: 'type',
                permanent: true,
                classWidth: '1',
            },
            ownerName: {
                name: 'creator',
                classWidth: '1',
            },
            createDate: {
                name: 'creationDate',
                classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            hasFlow: {
                name: 'processDiagram',
                noSorted: true,
                classWidth: '1',
            },
        },
        defaultId: 'taskId',
    },
    get_service_appeals_: {
        fields: {
            statusAss: {
                permanent: true,
                classWidth: 'fixed',
                noDnD: true,
                notForExport: true,
                component: ({ value }) => (
                    <div className={`grid-fixed-status-component object-full sticker-${value}`} />
                ),
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
                notForExport: true,
            },
            attr0: {
                name: i18next.t('filter.source'),
                width: 82,
                classWidth: '1',
                permanent: true,
                component: (val) => {
                    const value = val.value || val.data.attr0;
                    const source = `/data/svg/icon-source/source-key-${value.key}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={value.val} />;
                },
            },
            priority: {
                name: i18next.t('filter.priority'),
                width: 88,
                classWidth: '1',
                component: ({ data, value }) => (
                    <PriorityArrowsIcons id={data.priorityId} title={value} />
                ),
            },
            regnum: {
                name: i18next.t('filter.regnum'),
                permanent: true,
                classWidth: '1',
            },
            stateName: {
                name: i18next.t('filter.stateName'),
                classWidth: '1',
                component: ({ value, data }) => (
                    <span
                        title={data.status.name}
                        className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
                    >
                        {data.status.name}
                    </span>
                ),
            },
            subject: {
                name: i18next.t('filter.subject'),
                classWidth: '1',
            },
            startDate: {
                name: i18next.t('filter.startDate'),
                classWidth: '1',
                // format: format.date
                value: ({ startDate }) => {
                    return (
                        startDate &&
                        moment.unix(startDate / 1000).isValid() &&
                        moment.unix(startDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
            },
            destinationName: {
                name: i18next.t('filter.destinationName'),
                classWidth: '1',
            },
            typeFullName: {
                name: i18next.t('filter.typeFullName'),
                classWidth: '1',
            },
            lastModificationDate: {
                name: i18next.t('filter.lastModificationDate'),
                classWidth: '1',
                value: ({ lastModificationDate }) => {
                    return (
                        lastModificationDate &&
                        moment.unix(lastModificationDate / 1000).isValid() &&
                        moment.unix(lastModificationDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                width: 160,
            },
            customerName: {
                name: i18next.t('filter.customerName'),
                classWidth: '1',
            },
            custContactPersonName: {
                name: i18next.t('filter.custContactPersonName'),
                classWidth: '1',
                hidden: true,
            },
            resolution: {
                name: i18next.t('filter.resolution'),
                classWidth: '1',
                width: 90,
            },
            operatorName: {
                name: i18next.t('filter.operatorName'),
                classWidth: '1',
            },
            finishDate: {
                name: i18next.t('filter.finishDate'),
                classWidth: '1',
                value: ({ finishDate }) => {
                    return (
                        finishDate &&
                        moment.unix(finishDate / 1000).isValid() &&
                        moment.unix(finishDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            endDate: {
                name: i18next.t('filter.endDate'),
                classWidth: '1',
                value: ({ endDate }) => {
                    return (
                        endDate &&
                        moment.unix(endDate / 1000).isValid() &&
                        moment.unix(endDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            lastInProgresDate: {
                name: i18next.t('filter.lastInProgresDate'),
                classWidth: '1',
                value: ({ lastInProgresDate }) => {
                    return (
                        lastInProgresDate &&
                        moment.unix(lastInProgresDate / 1000).isValid() &&
                        moment.unix(lastInProgresDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            isOverdue: {
                name: i18next.t('filter.overdue'),
                permanent: true,
                classWidth: 1,
                width: 82,
                noSorted: false,
                hidden: false,
                component: ({ data }) => {
                    const { isOverdue } = data;
                    const title = isOverdue == 0 ? i18next.t('appeal.no') : i18next.t('appeal.yes');
                    const source = `/data/svg/icon-source/clock-${isOverdue}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={title} />;
                },
            },
            durationOverdue: {
                name: 'SLA',
                permanent: false,
                classWidth: 1,
                width: 182,
                noSorted: false,
                hidden: false,
                component: ({ data }) => {
                    const { durationOverdue } = data;
                    return getFormattedSLA(durationOverdue);
                },
            },
        },
        // defaultId: 'taskId'
    },
    get_resource_appeals_: {
        fields: {
            statusAss: {
                permanent: true,
                classWidth: 'fixed',
                noDnD: true,
                notForExport: true,
                component: ({ value }) => (
                    <div className={`grid-fixed-status-component object-full sticker-${value}`} />
                ),
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
                notForExport: true,
            },
            attr0: {
                name: i18next.t('filter.source'),
                width: 82,
                classWidth: '1',
                permanent: true,
                component: (val) => {
                    const value = val.value || val.data.attr0;
                    const source = `/data/svg/icon-source/source-key-${value.key}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={value.val} />;
                },
            },
            priority: {
                name: i18next.t('filter.priority'),
                width: 88,
                classWidth: '1',
                component: ({ data, value }) => (
                    <PriorityArrowsIcons id={data.priorityId} title={value} />
                ),
            },
            regnum: {
                name: i18next.t('filter.regnum'),
                permanent: true,
                classWidth: '1',
            },
            stateName: {
                name: i18next.t('filter.stateName'),
                classWidth: '1',
                component: ({ value, data }) => (
                    <span
                        title={data.status.name}
                        className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
                    >
                        {data.status.name}
                    </span>
                ),
            },
            subject: {
                name: i18next.t('filter.subject'),
                classWidth: '1',
            },
            startDate: {
                name: i18next.t('filter.startDate'),
                classWidth: '1',
                // format: format.date
                value: ({ startDate }) => {
                    return (
                        startDate &&
                        moment.unix(startDate / 1000).isValid() &&
                        moment.unix(startDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
            },
            destinationName: {
                name: i18next.t('filter.destinationName'),
                classWidth: '1',
            },
            typeFullName: {
                name: i18next.t('filter.typeFullName'),
                classWidth: '1',
            },
            lastModificationDate: {
                name: i18next.t('filter.lastModificationDate'),
                classWidth: '1',
                value: ({ lastModificationDate }) => {
                    return (
                        lastModificationDate &&
                        moment.unix(lastModificationDate / 1000).isValid() &&
                        moment.unix(lastModificationDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                width: 160,
            },
            customerName: {
                name: i18next.t('filter.customerName'),
                classWidth: '1',
            },
            custContactPersonName: {
                name: i18next.t('filter.custContactPersonName'),
                classWidth: '1',
                hidden: true,
            },
            resolution: {
                name: i18next.t('filter.resolution'),
                classWidth: '1',
                width: 90,
            },
            operatorName: {
                name: i18next.t('filter.operatorName'),
                classWidth: '1',
            },
            finishDate: {
                name: i18next.t('filter.finishDate'),
                classWidth: '1',
                value: ({ finishDate }) => {
                    return (
                        finishDate &&
                        moment.unix(finishDate / 1000).isValid() &&
                        moment.unix(finishDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            endDate: {
                name: i18next.t('filter.endDate'),
                classWidth: '1',
                value: ({ endDate }) => {
                    return (
                        endDate &&
                        moment.unix(endDate / 1000).isValid() &&
                        moment.unix(endDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            lastInProgresDate: {
                name: i18next.t('filter.lastInProgresDate'),
                classWidth: '1',
                value: ({ lastInProgresDate }) => {
                    return (
                        lastInProgresDate &&
                        moment.unix(lastInProgresDate / 1000).isValid() &&
                        moment.unix(lastInProgresDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            isOverdue: {
                name: i18next.t('filter.overdue'),
                permanent: true,
                classWidth: 1,
                width: 82,
                noSorted: false,
                hidden: false,
                component: ({ data }) => {
                    const { isOverdue } = data;
                    const title = isOverdue == 0 ? i18next.t('appeal.no') : i18next.t('appeal.yes');
                    const source = `/data/svg/icon-source/clock-${isOverdue}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={title} />;
                },
            },
            durationOverdue: {
                name: 'SLA',
                permanent: false,
                classWidth: 1,
                width: 182,
                noSorted: false,
                hidden: false,
                component: ({ data }) => {
                    const { durationOverdue } = data;
                    return getFormattedSLA(durationOverdue);
                },
            },
        },
        // defaultId: 'taskId'
    },
    customer_address_: {
        fields: {
            type: {
                name: 'addressTable.type',
                noSorted: true,
                permanent: true,
                component: ({ t, value }) => (
                    <span title={t(`dictionary:addressType.${value}`)}>
                        {t(`dictionary:addressType.${value}`)}
                    </span>
                ),
            },
            name: {
                name: 'addressTable.name',
                noSorted: true,
                permanent: true,
            },
        },
    },
    // customer_contracts_: {
    //     fields: {
    //         fullNumber: {
    //             name: 'contractsTable.fullNumber',
    //             // noSorted: true,
    //             // permanent: true,
    //         },
    //         status_label: {
    //             name: 'contractsTable.contractStatus',
    //             // noSorted: true,
    //             // permanent: true,
    //         },
    //         validFrom: {
    //             name: 'contractsTable.validFrom',
    //             value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
    //             // noSorted: true,
    //             // permanent: true,
    //         },
    //         validTill: {
    //             name: 'contractsTable.validTill',
    //             value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
    //             // noSorted: true,
    //             // permanent: true,
    //         },
    //         serviceGroups: {
    //             name: 'contractsTable.serviceGroups',
    //             value: (data, val) => {
    //                 return Array.isArray(val) ? val.join("; ").trim() : val;
    //             }
    //             // noSorted: true,
    //             // permanent: true,
    //         },
    //         contracts: {
    //             name: 'contractsTable.contractNumbers',
    //             value: (data, val) => {
    //                 return Array.isArray(val) ? val.join("; ").trim() : val;
    //             }
    //             // noSorted: true,
    //             // permanent: true,
    //         },
    //     },
    // },
    customer_persons_: {
        fields: {
            links: {
                name: 'persons.linkState',
                noSorted: true,
                width: 90,
                component: (data) => <UnlinkPersonCell {...data} gridKey="customer_persons" />,
            },
            lastName: {
                name: 'persons.lastName',
                noSorted: true,
                permanent: true,
                value: (data) => data.party.lastName,
            },
            firstName: {
                name: 'persons.firstName',
                noSorted: true,
                permanent: true,
                value: (data) => data.party.firstName,
            },
            patronymic: {
                name: 'persons.patronymic',
                noSorted: true,
                value: (data) => (data.party ? data.party.patronymic : null),
            },
            contacts: {
                name: 'persons.contacts',
                noSorted: true,
                width: 250,
                value: (data, val) => (val.map((i) => i.value) || []).join(', '),
            },
            language: {
                name: 'persons.language',
                noSorted: true,
                value: (data) => data.lang_name,
            },
            issues: {
                name: 'persons.issues',
                noSorted: true,
                width: 250,
                component: ({ data, t }) => {
                    const cellText =
                        data.links &&
                        data.links
                            .map((link) =>
                                link.issues.map((issue) => t(`dictionary:contactIssue.${issue}`)),
                            )
                            .join(', ');

                    return cellText ? (
                        <div className="data-cell" title={cellText}>
                            {cellText}
                        </div>
                    ) : null;
                },
            },
            validFrom: {
                name: 'persons.validFrom',
                noSorted: true,
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
        },
    },
    customer_appeals_: {
        fields: {
            statusAss: {
                permanent: true,
                classWidth: 'fixed',
                noDnD: true,
                notForExport: true,
                component: ({ value }) => (
                    <div className={`grid-fixed-status-component object-full sticker-${value}`} />
                ),
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
                notForExport: true,
            },
            attr0: {
                name: 'filter.source',
                width: 82,
                permanent: true,
                component: ({ value }) => {
                    const source = `/data/svg/icon-source/source-key-${value.key}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={value.val} />;
                },
            },
            priority: {
                name: 'filter.priority',
                width: 88,
                component: ({ data, value }) => (
                    <PriorityArrowsIcons id={data.priorityId} title={value} />
                ),
            },
            regnum: {
                name: 'filter.regnum',
                permanent: true,
            },
            stateName: {
                name: 'filter.stateName',
                component: ({ value, data }) => (
                    <span
                        title={data.status.name}
                        className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
                    >
                        {data.status.name}
                    </span>
                ),
            },
            subject: {
                name: 'filter.subject',
            },
            validFrom: {
                name: 'filter.validFrom',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            destinationName: {
                name: 'filter.destinationName',
            },
            typeFullName: {
                name: 'filter.typeFullName',
            },
            lastModificationDate: {
                name: 'filter.lastModificationDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            customerName: {
                name: 'filter.customerName',
            },
            custContactPersonName: {
                name: 'filter.custContactPersonName',
                hidden: true,
            },
            resolution: {
                name: 'filter.resolution',
                width: 90,
            },
            operatorName: {
                name: 'filter.operatorName',
            },
            finishDate: {
                name: 'filter.finishDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
                width: 90,
            },
            endDate: {
                name: 'filter.endDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
            },
            lastInProgresDate: {
                name: 'filter.lastInProgresDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
                width: 90,
            },
        },
    },
    person_appeals_: {
        fields: {
            statusAss: {
                permanent: true,
                classWidth: 'fixed',
                noDnD: true,
                notForExport: true,
                component: ({ value }) => (
                    <div className={`grid-fixed-status-component object-full sticker-${value}`} />
                ),
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
                notForExport: true,
            },
            attr0: {
                name: 'filter.source',
                width: 82,
                permanent: true,
                component: ({ value }) => {
                    const source = `/data/svg/icon-source/source-key-${value.key}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={value.val} />;
                },
            },
            priority: {
                name: 'filter.priority',
                width: 88,
                component: ({ data, value }) => (
                    <PriorityArrowsIcons id={data.priorityId} title={value} />
                ),
            },
            regnum: {
                name: 'filter.regnum',
                permanent: true,
            },
            stateName: {
                name: 'filter.stateName',
                component: ({ value, data }) => (
                    <span
                        title={data.status.name}
                        className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
                    >
                        {data.status.name}
                    </span>
                ),
            },
            subject: {
                name: 'filter.subject',
            },
            validFrom: {
                name: 'filter.validFrom',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            destinationName: {
                name: 'filter.destinationName',
            },
            typeFullName: {
                name: 'filter.typeFullName',
            },
            lastModificationDate: {
                name: 'filter.lastModificationDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            customerName: {
                name: 'filter.customerName',
            },
            custContactPersonName: {
                name: 'filter.custContactPersonName',
                hidden: true,
            },
            resolution: {
                name: 'filter.resolution',
                width: 90,
            },
            operatorName: {
                name: 'filter.operatorName',
            },
            finishDate: {
                name: 'filter.finishDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
                width: 90,
            },
            endDate: {
                name: 'filter.endDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
            },
            lastInProgresDate: {
                name: 'filter.lastInProgresDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
                width: 90,
            },
        },
    },
    get_appeals_: {
        fields: {
            statusAss: {
                permanent: true,
                classWidth: 'fixed',
                noDnD: true,
                notForExport: true,
                component: ({ value }) => (
                    <div className={`grid-fixed-status-component object-full sticker-${value}`} />
                ),
            },
            keyValue: {
                permanent: true,
                width: 15,
                noDnD: true,
                classWidth: 'removed-resizer',
                component: Expander,
                notForExport: true,
            },
            attr0: {
                name: i18next.t('filter.source'),
                width: 82,
                classWidth: '1',
                permanent: true,
                component: (val) => {
                    const value = val.value || val.data.attr0;
                    const source = `/data/svg/icon-source/source-key-${value.key}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={value.val} />;
                },
            },
            priority: {
                name: i18next.t('filter.priority'),
                width: 88,
                classWidth: '1',
                component: ({ data, value }) => (
                    <PriorityArrowsIcons id={data.priorityId} title={value} />
                ),
            },
            regnum: {
                name: i18next.t('filter.regnum'),
                permanent: true,
                classWidth: '1',
            },
            stateName: {
                name: i18next.t('filter.stateName'),
                classWidth: '1',
                component: ({ value, data }) => (
                    <span
                        title={data.status.name}
                        className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
                    >
                        {data.status.name}
                    </span>
                ),
            },
            subject: {
                name: i18next.t('filter.subject'),
                classWidth: '1',
            },
            startDate: {
                name: i18next.t('filter.startDate'),
                classWidth: '1',
                format: format.date,
            },
            destinationName: {
                name: i18next.t('filter.destinationName'),
                classWidth: '1',
            },
            typeFullName: {
                name: i18next.t('filter.typeFullName'),
                classWidth: '1',
            },
            lastModificationDate: {
                name: i18next.t('filter.lastModificationDate'),
                classWidth: '1',
                value: ({ lastModificationDate }) => {
                    return (
                        lastModificationDate &&
                        moment.unix(lastModificationDate / 1000).isValid() &&
                        moment.unix(lastModificationDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                width: 160,
            },
            customerName: {
                name: i18next.t('filter.customerName'),
                classWidth: '1',
            },
            custContactPersonName: {
                name: i18next.t('filter.custContactPersonName'),
                classWidth: '1',
                hidden: true,
            },
            resolution: {
                name: i18next.t('filter.resolution'),
                classWidth: '1',
                width: 90,
            },
            operatorName: {
                name: i18next.t('filter.operatorName'),
                classWidth: '1',
            },
            finishDate: {
                name: i18next.t('filter.finishDate'),
                classWidth: '1',
                value: ({ finishDate }) => {
                    return (
                        finishDate &&
                        moment.unix(finishDate / 1000).isValid() &&
                        moment.unix(finishDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            endDate: {
                name: i18next.t('filter.endDate'),
                classWidth: '1',
                value: ({ endDate }) => {
                    return (
                        endDate &&
                        moment.unix(endDate / 1000).isValid() &&
                        moment.unix(endDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            lastInProgresDate: {
                name: i18next.t('filter.lastInProgresDate'),
                classWidth: '1',
                value: ({ lastInProgresDate }) => {
                    return (
                        lastInProgresDate &&
                        moment.unix(lastInProgresDate / 1000).isValid() &&
                        moment.unix(lastInProgresDate / 1000).format('DD.MM.YYYY HH:mm')
                    );
                },
                hidden: true,
                width: 160,
            },
            isOverdue: {
                name: i18next.t('filter.overdue'),
                permanent: true,
                classWidth: 1,
                width: 82,
                noSorted: false,
                hidden: false,
                component: ({ data, t }) => {
                    const { isOverdue } = data;
                    const title = isOverdue == 0 ? t('appeal.no') : t('appeal.yes');
                    const source = `/data/svg/icon-source/clock-${isOverdue}.svg`;
                    return <ReactSVG svgClassName="grid-icon" src={source} title={title} />;
                },
            },
            durationOverdue: {
                name: 'SLA',
                permanent: false,
                classWidth: 1,
                width: 182,
                noSorted: false,
                hidden: false,
                component: ({ data }) => {
                    const { durationOverdue } = data;
                    return getFormattedSLA(durationOverdue);
                },
            },
        },
    },
    // get_appeals_search_: {
    //     fields: {
    //         statusAss: {
    //             permanent: true,
    //             classWidth: 'fixed',
    //             noDnD: true,
    //             notForExport: true,
    //             component: ({ value }) => (
    //                 <div className={`grid-fixed-status-component object-full sticker-${value}`} />
    //             ),
    //         },
    //         keyValue: {
    //             permanent: true,
    //             width: 15,
    //             noDnD: true,
    //             classWidth: 'removed-resizer',
    //             component: Expander,
    //             notForExport: true,
    //         },
    //         attr0: {
    //             name: i18next.t('filter.source'),
    //             width: 82,
    //             classWidth: '1',
    //             permanent: true,
    //             component: (val) => {
    //                 const value = val.value || val.data.attr0;
    //                 const source = `/data/svg/icon-source/source-key-${value.key}.svg`;
    //                 return <ReactSVG svgClassName="grid-icon" src={source} title={value.val} />;
    //             },
    //         },
    //         priority: {
    //             name: i18next.t('filter.priority'),
    //             width: 88,
    //             classWidth: '1',
    //             component: ({ data, value }) => (
    //                 <PriorityArrowsIcons id={data.priorityId} title={value} />
    //             ),
    //         },
    //         regnum: {
    //             name: i18next.t('filter.regnum'),
    //             permanent: true,
    //             classWidth: '1',
    //         },
    //         stateName: {
    //             name: i18next.t('filter.stateName'),
    //             classWidth: '1',
    //             component: ({ value, data }) => (
    //                 <span
    //                     title={data.status.name}
    //                     className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
    //                 >
    //                     {data.status.name}
    //                 </span>
    //             ),
    //         },
    //         subject: {
    //             name: i18next.t('filter.subject'),
    //             classWidth: '1',
    //         },
    //         startDate: {
    //             name: i18next.t('filter.startDate'),
    //             classWidth: '1',
    //             // format: format.date,
    //             value: ({ startDate }) => {
    //                 return (
    //                     startDate &&
    //                     moment.unix(startDate / 1000).isValid() &&
    //                     moment.unix(startDate / 1000).format('DD.MM.YYYY HH:mm')
    //                 );
    //             },
    //             width: 160,
    //         },
    //         destinationName: {
    //             name: i18next.t('filter.destinationName'),
    //             classWidth: '1',
    //         },
    //         typeFullName: {
    //             name: i18next.t('filter.typeFullName'),
    //             classWidth: '1',
    //         },
    //         lastModificationDate: {
    //             name: i18next.t('filter.lastModificationDate'),
    //             classWidth: '1',
    //             value: ({ lastModificationDate }) => {
    //                 return (
    //                     lastModificationDate &&
    //                     moment.unix(lastModificationDate / 1000).isValid() &&
    //                     moment.unix(lastModificationDate / 1000).format('DD.MM.YYYY HH:mm')
    //                 );
    //             },
    //             width: 160,
    //         },
    //         customerName: {
    //             name: i18next.t('filter.customerName'),
    //             classWidth: '1',
    //         },
    //         custContactPersonName: {
    //             name: i18next.t('filter.custContactPersonName'),
    //             classWidth: '1',
    //             hidden: true,
    //         },
    //         resolution: {
    //             name: i18next.t('filter.resolution'),
    //             classWidth: '1',
    //             width: 90,
    //         },
    //         operatorName: {
    //             name: i18next.t('filter.operatorName'),
    //             classWidth: '1',
    //         },
    //         finishDate: {
    //             name: i18next.t('filter.finishDate'),
    //             classWidth: '1',
    //             value: ({ finishDate }) => {
    //                 return (
    //                     finishDate &&
    //                     moment.unix(finishDate / 1000).isValid() &&
    //                     moment.unix(finishDate / 1000).format('DD.MM.YYYY HH:mm')
    //                 );
    //             },
    //             hidden: true,
    //             width: 160,
    //         },
    //         endDate: {
    //             name: i18next.t('filter.endDate'),
    //             classWidth: '1',
    //             value: ({ endDate }) => {
    //                 return (
    //                     endDate &&
    //                     moment.unix(endDate / 1000).isValid() &&
    //                     moment.unix(endDate / 1000).format('DD.MM.YYYY HH:mm')
    //                 );
    //             },
    //             hidden: true,
    //             width: 160,
    //         },
    //         lastInProgresDate: {
    //             name: i18next.t('filter.lastInProgresDate'),
    //             classWidth: '1',
    //             value: ({ lastInProgresDate }) => {
    //                 return (
    //                     lastInProgresDate &&
    //                     moment.unix(lastInProgresDate / 1000).isValid() &&
    //                     moment.unix(lastInProgresDate / 1000).format('DD.MM.YYYY HH:mm')
    //                 );
    //             },
    //             hidden: true,
    //             width: 160,
    //         },
    //         isOverdue: {
    //             name: i18next.t('filter.overdue'),
    //             permanent: true,
    //             classWidth: 1,
    //             width: 82,
    //             noSorted: false,
    //             hidden: false,
    //             component: ({ data, t }) => {
    //                 const { isOverdue } = data;
    //                 const title = isOverdue == 0 ? t('appeal.no') : t('appeal.yes');
    //                 const source = `/data/svg/icon-source/clock-${isOverdue}.svg`;
    //                 return <ReactSVG svgClassName="grid-icon" src={source} title={title} />;
    //             },
    //         },
    //         durationOverdue: {
    //             name: 'SLA',
    //             permanent: false,
    //             classWidth: 1,
    //             width: 182,
    //             noSorted: false,
    //             hidden: false,
    //             component: ({ data }) => {
    //                 const { durationOverdue } = data;
    //                 return getFormattedSLA(durationOverdue);
    //             },
    //         },
    //     },
    // },
    get_appeals_for_link_: {
        fields: {
            checkbox: {
                noSorted: true,
                width: 10,
                classWidth: '2',
                noDnD: true,
            },
            regnum: {
                name: 'filter.regnum',
                permanent: true,
            },
            stateName: {
                name: 'filter.stateName',
                component: ({ value, data }) => (
                    <span
                        title={data.status.name}
                        className={`sticker sticker-grid sticker-ellipsis sticker-${data.status.code}`}
                    >
                        {data.status.name}
                    </span>
                ),
            },
            typeFullName: {
                name: 'filter.typeFullName',
            },
        },
    },
    get_objects_for_link_: {
        fields: {
            checkbox: {
                noSorted: true,
                width: 10,
                classWidth: '2',
                noDnD: true,
            },
            // fixedStatus: {
            //     permanent: true,
            //     width: 4,
            //     noDnD: true,
            //     classWidth: 'fixed',
            //     component: FixedStatus,
            // },
            // keyValue: {
            //     permanent: true,
            //     width: 15,
            //     noDnD: true,
            //     classWidth: 'removed-resizer',
            //     component: Expander,
            // },
            status: {
                name: 'status',
                permanent: true,
                width: 200,
                component: FullStatus,
            },
            id: {
                name: '№',
                classWidth: '1',
            },
            // id: {
            //     name: 'ID',
            //     classWidth: '1',
            // },
            name: {
                name: 'name',
                classWidth: '1',
            },
            specType: {
                name: 'type',
                classWidth: '1',
                value: (data, val) => val && val.name,
            },
            // customerName: {
            //     name: 'customerName',
            //     classWidth: '1',
            //     component: ({ data: { customerName, clientId } }) => (
            //         <a
            //             className='data-cell'
            //             href={`#/customer/${clientId}`}
            //             onClick={e => e.stopPropagation()}
            //         >
            //             {customerName}
            //         </a>
            //     ),
            // },
            // validFrom: {
            //     name: 'serviceFrom',
            //     classWidth: '1',
            //     value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            // },
            // validTill: {
            //     name: 'serviceTill',
            //     classWidth: '1',
            //     value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            // }
        },
    },
    // get_service_catalogue_objects_for_link_: {
    //     fields: {
    //         checkbox: {
    // 			noSorted: true,
    // 			width: 10,
    // 			classWidth: '2',
    // 			noDnD: true,
    //         },
    //         // fixedStatus: {
    //         //     permanent: true,
    //         //     width: 4,
    //         //     noDnD: true,
    //         //     classWidth: 'fixed',
    //         //     component: FixedStatus,
    //         // },
    //         // keyValue: {
    //         //     permanent: true,
    //         //     width: 15,
    //         //     noDnD: true,
    //         //     classWidth: 'removed-resizer',
    //         //     component: Expander,
    //         // },
    //         status: {
    //             name: 'status',
    //             permanent: true,
    //             width: 200,
    //             component: FullStatus,
    //         },
    //         id: {
    //             name: '№',
    //             classWidth: '1',
    //         },
    //         // id: {
    //         //     name: 'ID',
    //         //     classWidth: '1',
    //         // },
    //         name: {
    //             name: 'name',
    //             classWidth: '1',
    //         },
    //         'specType': {
    //             name: 'type',
    //             classWidth: '1',
    //             value: (data, val) => val && val.name
    //         },
    //         // customerName: {
    //         //     name: 'customerName',
    //         //     classWidth: '1',
    //         //     component: ({ data: { customerName, clientId } }) => (
    //         //         <a
    //         //             className='data-cell'
    //         //             href={`#/customer/${clientId}`}
    //         //             onClick={e => e.stopPropagation()}
    //         //         >
    //         //             {customerName}
    //         //         </a>
    //         //     ),
    //         // },
    //         // validFrom: {
    //         //     name: 'serviceFrom',
    //         //     classWidth: '1',
    //         //     value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
    //         // },
    //         // validTill: {
    //         //     name: 'serviceTill',
    //         //     classWidth: '1',
    //         //     value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
    //         // }
    //     },
    // },
    customer_search_filter_: {
        fields: {
            keyValue: {
                width: 15,
                noSorted: true,
                noDnD: true,
                classWidth: 'removed-resizer',
                permanent: true,
            },
            partyType: {
                name: 'Тип',
                format: format.dictionary,
                dictionaryField: 'partyType',
                classWidth: '1',
            },
            fullName: {
                name: 'Назва',
                noSorted: false,
                classWidth: '1',
                permanent: true,
            },
            productStatusName: {
                name: 'Статус',
                noSorted: false,
                classWidth: '1',
            },
            contacts: {
                name: 'Контакти',
                classWidth: '1',
                html: true,
            },
            startDate: {
                name: 'Заведено',
                classWidth: '1',
                format: format.date,
            },
            phyAddress: {
                name: 'Фактична адреса',
                classWidth: '1',
            },
        },
    },
    // the same as for contact_person_search_filter
    // contact_person_search_: {
    //     fields: {
    //         keyValue: {
    //             width: 15,
    //             noSorted: true,
    //             noDnD: true,
    //             classWidth: 'removed-resizer',
    //             component: Expander,
    //             permanent: true,
    //         },
    //         contactName: {
    //             name: 'contactPerson.fullName',
    //             noSorted: false,
    //             // classWidth: '1',
    //             permanent: true,
    //         },
    //         customerName: {
    //             name: 'clientName',
    //             noSorted: false,
    //             // classWidth: '1',
    //         },
    //         contacts: {
    //             name: 'contacts',
    //             // classWidth: '1',
    //             html: true,
    //         },
    //         startDate: {
    //             name: 'contactPerson.since',
    //             // classWidth: '1',
    //             format: format.date,
    //         },
    //     },
    // },
    contact_person_search_filter_: {
        fields: {
            keyValue: {
                width: 15,
                noSorted: true,
                noDnD: true,
                classWidth: 'removed-resizer',
                permanent: true,
            },
            contactName: {
                name: 'contactPerson.fullName',
                noSorted: false,
                classWidth: '1',
                permanent: true,
            },
            customerName: {
                name: 'clientName',
                noSorted: false,
                classWidth: '1',
            },
            contacts: {
                name: 'contacts',
                classWidth: '1',
                html: true,
            },
            startDate: {
                name: 'contactPerson.since',
                classWidth: '1',
                format: format.date,
            },
        },
    },
    billing_house_: {
        fields: {
            service_name: {
                name: 'billings.service_name',
                noSorted: true,
                classWidth: '1',
                width: '300',
                noDnD: true,
                permanent: true,
            },
            balance_beg: {
                name: 'billings.balance_beg',
                noSorted: true,
                classWidth: '1',
                width: '200',
            },
            summ_accruals: {
                name: 'billings.summ_accruals',
                classWidth: '1',
                html: false,
                width: '200',
                noSorted: true,
            },
            summ_payments: {
                name: 'billings.summ_payments',
                classWidth: '1',
                html: false,
                width: '200',
                noSorted: true,
            },
            balance_end: {
                name: 'billings.balance_end',
                classWidth: '1',
                html: false,
                width: '200',
                noSorted: true,
            },
        },
    },
    billing_cards_: {
        fields: {
            address: {
                name: 'billings.address',
                noSorted: true,
                classWidth: '1',
                width: '300',
                permanent: true,
            },
            acc: {
                name: 'billings.acc',
                noSorted: true,
                classWidth: '1',
                width: '200',
                permanent: true,
            },
            fio: {
                name: 'billings.fio',
                classWidth: '1',
                html: false,
                noSorted: true,
            },
        },
    },
    billing_finstatus_: {
        fields: {
            plat_kod: {
                name: 'billings.plat_kod',
                noSorted: true,
            },
            idservice: {
                name: 'billings.idService',
                noSorted: true,
                width: 150,
                classWidth: '1',
            },
            nameservice: {
                name: 'billings.nameService',
                noSorted: true,
                classWidth: '1',
                permanent: true,
            },
            saldoinput: {
                name: 'billings.saldoInput',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            accrued: {
                name: 'billings.accrued',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            paid: {
                name: 'billings.paid',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            recalc: {
                name: 'billings.recalc',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            accruedprivilege: {
                name: 'billings.accruedPrivilege',
                noSorted: true,
                width: 150,
                classWidth: '1',
            },
            subsidiessum: {
                name: 'billings.subsidiesSum',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            saldooutput: {
                name: 'billings.saldoOutput',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            tariff: {
                name: 'billings.tariff',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            currentmonth: {
                name: 'billings.currentMonth',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            currentyear: {
                name: 'billings.currentYear',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
        },
    },
    billing_payments_: {
        fields: {
            idservice: {
                name: 'billings.idService',
                noSorted: true,
                width: 150,
                classWidth: '1',
            },
            nameservice: {
                name: 'billings.nameService',
                noSorted: true,
                classWidth: '1',
                noDnD: true,
                permanent: true,
            },
            sumofpay: {
                name: 'billings.sumOfPay',
                noSorted: true,
                width: 150,
                classWidth: '1',
            },
            dateofpay: {
                name: 'billings.dateOfPay',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            typeofpay: {
                name: 'billings.typeOfPpay',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            description: {
                name: 'billings.description',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            periodfrom: {
                name: 'billings.periodFrom',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            periodto: {
                name: 'billings.periodTo',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
        },
    },
    billing_recalculations_: {
        fields: {
            idservice: {
                name: 'billings.idService',
                noSorted: true,
                width: 150,
                classWidth: '1',
            },
            nameservice: {
                name: 'billings.nameService',
                noSorted: true,
                classWidth: '1',
                noDnD: true,
                permanent: true,
            },
            sumofrecalc: {
                name: 'billings.sumOfRecalc',
                noSorted: true,
                width: 150,
                classWidth: '1',
            },
            dateofrecalc: {
                name: 'billings.dateOfRecalc',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            volumeofrecalc: {
                name: 'billings.volumeOfRecalc',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            description: {
                name: 'billings.description',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            tariff: {
                name: 'billings.tariff',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            sumofprivelege: {
                name: 'billings.sumOfPrivelege',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            volumeofprivelege: {
                name: 'billings.volumeOfPrivelege',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            datebegin: {
                name: 'billings.dateBegin',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
            dateend: {
                name: 'billings.dateEnd',
                classWidth: '1',
                noSorted: true,
                width: 150,
                html: false,
            },
        },
    },
    objects_links_: {
        defaultId: 'linkId',
        fields: {
            // checkbox: {
            // 	noSorted: true,
            // 	width: 10,
            // 	classWidth: '2',
            // 	noDnD: true,
            // },
            // fixedStatus: {
            //     permanent: true,
            //     width: 4,
            //     noDnD: true,
            //     classWidth: 'fixed',
            //     component: FixedStatus,
            // },
            // keyValue: {
            //     permanent: true,
            //     width: 15,
            //     noDnD: true,
            //     classWidth: 'removed-resizer',
            //     component: Expander,
            // },
            linkId: {
                name: 'id',
                // classWidth: '1',
            },
            status: {
                name: 'status',
                permanent: true,
                // width: 200,
                component: FullStatus,
            },
            // id: {
            //     name: 'ID',
            //     classWidth: '1',
            // },
            objectLabel: {
                name: 'name',
                // classWidth: '1',
            },
            specType: {
                name: 'type',
                width: 80,
                // classWidth: '1',
                value: (data, val) => val && val.name,
            },
            customerName: {
                name: 'customerName',
                // classWidth: '1',
                component: ({ data: { customer } }) =>
                    customer ? (
                        <a
                            className="data-cell"
                            href={`#/customer/${customer.id}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {customer.name}
                        </a>
                    ) : null,
            },
            validFrom: {
                name: 'serviceFrom',
                // classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
            validTill: {
                name: 'serviceTill',
                // classWidth: '1',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
            },
        },
    },
    exportJobsList_: {
        // defaultId: 'linkId'
        fields: {
            createDate: {
                name: 'createDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm')
            },
            statusName: {
                name: 'statusName',
            },
            name: {
                name: 'name'
            },
            files: {
                name: 'file',
            },
            startDate: {
                name: 'startDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
            },
            endDate: {
                name: 'endDate',
                value: (data, val) => val && moment(val).format('DD.MM.YYYY HH:mm'),
                hidden: true,
            },
            btnStop: {
                name: "wizard.stop",
                permanent: true,
            },
            btnDelete: {
                name: "wizard.delete",
                permanent: true,
            },
        },
    }
};
