import React from 'react';
import { buildThreePath } from 'helpers';
import { getFormValues,change } from 'redux-form';
import { connect } from 'react-redux';
import moment from "moment";
import InputField from 'components/Common/InputField';
import DatePicker from 'components/Common/DatePicker';
import ComboBox from 'components/Common/ComboBox';
import { Form, Field, reduxForm} from 'redux-form';
import { withTranslation } from 'react-i18next';
import MultiSelectWithSearch from 'components/Common/MultiSelectWithSearch/ReduxFormDecorator';
import validate from './validate';
import BaseService from 'services/BaseService';

import { renderDateField } from "components/FiltersForm/helpers";

function mapStateToProps(state, props){
	const { idHashKeyRequest, form } = props;
	return {
		filterFields:  _.get(state, `searchFilter.allFilterFields[${idHashKeyRequest}]`, []),
		formValues: getFormValues(form)(state),
		appealTypes: state.searchFilter.appealTypes,
		idHashKeyRequestFormResponse: state.searchFilter.idHashKeyRequestFromResponse,
	}
}

@withTranslation()
@connect(mapStateToProps, { change })
@reduxForm({
	validate, enableReinitialize: false,
	keepDirtyOnReinitialize: true,
	destroyOnUnmount: false
})
class SearchFiltersForm extends React.Component {
	constructor (props) {
		super(props);
		this.state = {};
		this.asyncParams = this.asyncParams.bind(this);
	}
	
	componentDidMount () {
		let state = {};
		this.props.filterFields.filter((f) => f.dict_search).forEach((i) => {
			state = { ...state, [i.code]: [] };
		});
	}
	
	shouldComponentUpdate (nextProps) {
		return this.props.idHashKeyRequest === nextProps.idHashKeyRequestFormResponse;
	}
	
	asyncParams (search, code, id) {
		if (!search) {
			this.setState({ [code]: [] });
		} else {
			BaseService.post('async_search_attributes', { data: { id, search }, jsonType: true }).then((resp) => {
				this.setState({
					[code]: resp.result.map(field => ({
						label: field.value || '',
						value: field.id || ''
					}))
				});
			});
		}
	}
	
	getComponentByType = (type, search) => {
		let components = {
			LIST: ComboBox,
			NUMBER: InputField,
			STRING: InputField,
			DATE: DatePicker
		};
		
		if (search) {
			components = {
				THREE: MultiSelectWithSearch,
				LIST: MultiSelectWithSearch,
			};
		}
		
		return components[type] || InputField;
	};
	
	convertFieldConfigToProps = fieldConfig => {
		const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
		const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
		let options = fieldConfig.type === 'THREE' ? { children: buildThreePath(fieldConfig.dict, false, { children: 'children' }) } : getOptions(fieldConfig.dict || []);
        const title = fieldConfig.type === 'THREE' && fieldConfig.name;
		let config = {
			key: fieldConfig.id,
			component: this.getComponentByType(fieldConfig.type, fieldConfig.search),
			name: fieldConfig.code,
			label: fieldConfig.name,
			title,
			type: fieldConfig.type,
			options,
			multi,
			tree: fieldConfig.type === 'THREE',
			errorPlaceholder: true,
			valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
            keySelectField: 'key',
			leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
		};
		
		if (fieldConfig.dict_search) {
			const {disallow_select_all} = fieldConfig;
			
			config = {
				...config,
				disallow_select_all,
				notFilteredOptions:true,
				dict_search:true,
				options: this.state[fieldConfig.code] || [],
				async: {
					callback: this.asyncParams,
					code: fieldConfig.code,
					id: fieldConfig.id,
				}
			};
		}
		
		return config;
	};
	
	// renderDateField = (fieldConfig, fieldProps) => {
	// 	if (fieldConfig.def_condition === 'BTW') {
	// 		const fromName = `${fieldConfig.code}@FROM`;
	// 		const fromValueInitial = this.props.formValues && this.props.formValues[fromName];
	// 		let fromValue = fromValueInitial;
	// 		if (fromValue && !fromValue.isValid) {
	// 			// convert fromValue to the moment object (probably initialized from filters);
	// 			fromValue = moment(fromValueInitial);
	// 		}
	// 		const toName = `${fieldConfig.code}@TO`;
	// 		const toValueInitial = this.props.formValues && this.props.formValues[toName];
	// 		let toValue = toValueInitial;
	// 		if (toValueInitial && !toValueInitial.isValid) {
	// 			// convert toValue to the moment object (probably initialized from filters);
	// 			toValue = moment(toValueInitial);
	// 		}

	// 		const isSameDay = fromValue && toValue && fromValue.isSame(toValue, 'day');
	// 		const fromTime = isSameDay && fromValue;
	// 		const toTime = isSameDay && toValue;

	// 		const isMaxTimeBigger = isSameDay && toTime.diff(fromTime);
	// 		if (isMaxTimeBigger < 0) {
	// 			this.props.change(toName, fromTime);
	// 		}

	// 		const fromDateProps = {
	// 			...fieldProps,
	// 			name: fromName,
	// 			label: `${fieldProps.label} (З)`,
	// 			key: `${fieldConfig.id}@FROM`,
	// 			maxDate: toValue,
	// 			maxTime: toTime,
	// 			minTime: isSameDay && fromValue.clone().startOf('day')
	// 		};
	// 		const toDateProps = {
	// 			...fieldProps,
	// 			name: toName,
	// 			label: `${fieldProps.label} (По)`,
	// 			key: `${fieldConfig.id}@TO`,
	// 			minDate: fromValue,
	// 			minTime: fromTime,
	// 			maxTime: isSameDay && toValue.clone().endOf('day')
	// 		};
			
	// 		return [
	// 			<Field {...fromDateProps} />,
	// 			<Field {...toDateProps} />
	// 		];
	// 	}
		
	// 	return <Field {...fieldProps} />;
	// };
	
	renderField = fieldConfig => {
		const { t, formValues, change } = this.props;
		const fieldProps = this.convertFieldConfigToProps(fieldConfig);
		if (fieldConfig.type === 'DATE') {
			// return this.renderDateField(fieldConfig, fieldProps);
			return renderDateField(t, fieldConfig, fieldProps, formValues, change);
		}
		return (
			<Field {...fieldProps} />
		);
	};
	
	render () {
		const { filterFields, t } = this.props;
		return (
			<Form>
				{filterFields.map(this.renderField)}
			</ Form>
		);
	}
}

export default SearchFiltersForm;
