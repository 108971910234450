import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import i18next from 'util/i18n';

import 'styles/modules/modal.module.scss';
import { KeyCodes } from 'constants/index';

function ModalWindow (props) {
	const modal = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (!props.isOpen && !isOpen) {
			setIsOpen(true);
		}
	}, []);
	
	useEffect(() => {
		document.onkeydown = function (e) {
			if (e.key === 'Tab') {
				return false;
			}
		};
		
		return () => {
			document.onkeydown = null;
		}
	}, []);

	 function handleCloseModal (e) {
		if (props.onClose || (e.keyCode === KeyCodes.ESCAPE)) {
			return props.onClose();
		}

		setIsOpen(false)
	}

	function handleModalClick (e) {
		e.stopPropagation();
	}

	if (!isOpen) return null;

	return (
		<div className={cx('modal-window-overlay', { [props.className]: props.className })}>
			<div className='modal-window' onClick={handleModalClick}>
				<i className='modal-window-close icon-close no-print' onClick={handleCloseModal} title={i18next.t('close')} />
				{props.children}
			</div>
		</div>
	);
}

ModalWindow.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func
};

export default ModalWindow;
