import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';

export default function MainHeader ({ children, ...props }) {
	return (
		<div className={styles.mainHeaderService} {...props} >{children}</div>
	);
}

MainHeader.propTypes = {
	children: PropTypes.any,
	props: PropTypes.any
};
