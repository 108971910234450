import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import ComboBoxRemovable from '../Common/ComboBoxRemovable';
import { connect } from 'react-redux';
import { getUserPhones, changeOpenedAccountModal } from 'actions/user';
import { v4 } from 'node-uuid';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { atou, utoa } from 'helpers';
import { SseEvents } from '../../constants';
import UserCallPopupActions from './UserCallPopupActions';
import { setLastSseMessage, closeEventStreamAndPopUp, selectPhone } from '../../actions/call';
import AccountModal from "./AccountModal/AccountModal";
import baseService from 'services/BaseService';


const SUPPORTED_CALL_STATIONS = ['asterisk', 'binotel', 'vicidial'];


const mapStateToProps = state => ({
	phoneNumber: state.call.phoneNumber,
	phoneNumberList: state.user.userPhones,
	isOpenedModal: state.user.isOpenedModal,
	modalAccountEditMode: state.user.modalAccountEditMode,
	lastMessage: state.call.lastMessage,
	isAuthorized: state.call.isAuthorized,
	callStation: state.call.callStation,
	pathPrefix: state.call.pathPrefix,
	outPhoneErrorMSg: state.call.outPhoneErrorMSg,
});

const mapDispatchToProps = {
	getUserPhones,
	setLastSseMessage,
	closeEventStreamAndPopUp,
	changeOpenedAccountModal,
	selectPhone
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class UserCallForm extends React.Component {
	
	constructor (props) {
		super(props);
		const phoneOption = localStorage.getItem('phoneOption') || '[]';
		if (phoneOption) {
			this.props.selectPhone(JSON.parse(phoneOption));
		}
		this.state = {
			userCallPopupActionsOpen: false,
			phoneOption: JSON.parse(phoneOption),
			// phoneId: localStorage.getItem('phone'),
			phoneId: phoneOption && JSON.parse(phoneOption).value,
			ciscoLogin: this.getDecodedStringFromStorage('ciscoLogin'),
			ciscoPassword: this.getDecodedStringFromStorage('ciscoPassword'),
			error: '',
		};
		
		this.togglePopup = this.togglePopup.bind(this);
		this.wait = false;
	}
	
	componentDidMount () {
		this.props.getUserPhones();
		
		if (this.props.lastMessage.event === SseEvents.DISCONNECTION) {
			this.showDisconnectionError();
		}
	}
	
	componentDidUpdate (prevProps) {
		const { t } = this.props;
		if (!this.isDisconnected(prevProps.lastMessage.event) && this.isDisconnected(this.props.lastMessage.event)) {
			this.showDisconnectionError();
		}
		const prevDisconnected = prevProps.isDisconnected;
		if (!prevDisconnected && this.props.isDisconnected) {
			this.setState({ error: t('call.connectionLost') });
		}
	}
	
	showDisconnectionError = () => {
		// this.setState({ error: 'Зв\'язок розірвано' });
		// this.setState({ error: t('call.connectionLost') });
		// localStorage.removeItem('phoneOption');
		// localStorage.removeItem('phone');
		this.props.setLastSseMessage({});
		if(this.props.onDisconnect) {
			this.props.onDisconnect();
		}
	};
	
	isDisconnected = (event) => event === SseEvents.DISCONNECTION;
	
	encodeString = string => `${utoa(string)}?${v4()}`;
	
	updateCredentialsStorage = () => {
		const { ciscoLogin, ciscoPassword } = this.state;
		
		localStorage.setItem('ciscoLogin', this.encodeString(ciscoLogin));
		localStorage.setItem('ciscoPassword', this.encodeString(ciscoPassword));
	};
	
	getDecodedStringFromStorage = itemName => {
		const string = localStorage.getItem(itemName);
		
		if (string && string.indexOf('?') !== -1) {
			const questionPosition = string.indexOf('?');
			
			return atou(string.substring(0, questionPosition));
		}
		
		return '';
	};
	
	onCredentialsChange = event => {
		const value = event.target.value;
		this.setState({ [event.target.name]: value }, this.updateCredentialsStorage);
	};
	
	
	 removePhoneNumber = async({label, value}) => {
		const resp = await baseService.delete('call_account', { pathParams: { id: value } });
		if (resp.success) {
			this.props.getUserPhones();
		}
	};
	
	getPhoneNumbers = () => {
		const { phoneNumberList } = this.props;
		if(phoneNumberList) {
			return phoneNumberList.map(item => ({
				label: item.name,
				value: item.id,
				pathPrefix: item.pathPrefix,
				phoneNumber: item.phone,
				callStation: item.pbxName,
			}));
		}
		return [];
	};
	
	onPhoneNumberChange = option => {
		if (option) {
			this.props.selectPhone( option );
			return this.setState({ phoneId: option.value, ciscoLogin: '', ciscoPassword: '', error:'', phoneOption: option });
			
		}
		return	this.setState({ phoneId: '' , phoneOption: [] });
	};
	
	onNumberInputChange = inputValue => inputValue.replace(/[^0-9]/g, '');
	
	togglePopup = (event) => {
		event.preventDefault();
		this.setState( prevState => ({...prevState, userCallPopupActionsOpen: !prevState.userCallPopupActionsOpen }))
	};
	
	
	onSubmit = event => {
		event.preventDefault();
		if (this.wait) return;
		this.wait = true;
		
		if (this.state.error) {
			this.setState({ error: '' });
		}
		const values = {
			phoneNumber: this.props.phoneNumber,
			agentName: this.state.ciscoLogin,
			agentPassword: this.state.ciscoPassword
		};

		// update proper ls values to handle relogin without changing phoneNumber form select
		const phone = this.props.phoneNumber || localStorage.getItem("phone");
		const phoneNumberList = this.getPhoneNumbers();
		const cachedOption = phone && phoneNumberList && phoneNumberList.find(item => item.phoneNumber === phone);
		if (cachedOption) {
			localStorage.setItem('callStation', cachedOption.callStation);
			localStorage.setItem('phoneOption', JSON.stringify(cachedOption));
		}
		
		this.props.onSubmit(values)
			.then(error => {
				if (error) {
					this.setState({ error });
				}
				this.wait = false;
			});
	};
	
	isSubmitDisabled = () => {
		const { phoneId, ciscoLogin, ciscoPassword } = this.state;
		const callStation = this.props.callStation || localStorage.getItem('callStation');
		if (SUPPORTED_CALL_STATIONS.includes(callStation)) {
			return !phoneId;
		}
		return !(phoneId && ciscoLogin && ciscoPassword);
	};
	
	callLogout = () => {
		const { pathPrefix, closeEventStreamAndPopUp } = this.props;
		this.setState({ phoneId: '', ciscoLogin: '', ciscoPassword: '', error:'', phoneOption: [] });
		closeEventStreamAndPopUp(pathPrefix || localStorage.getItem('pathPrefix'));
	};
	
	render () {
		const { ciscoLogin, ciscoPassword, phoneId, error, userCallPopupActionsOpen, phoneOption } = this.state;
		const { t, isAuthorized, callStation, outPhoneErrorMSg, isOpenedModal, modalAccountEditMode } = this.props;
		const buttonClassName = cx('btn btn-primary full-width', {
			['btn-disabled']: this.isSubmitDisabled()
		});
		const inputFieldClassName = cx('input-field', {
			['input-field__error']: error,
		});


		return (
			<Fragment>
				<form onSubmit={this.onSubmit}>
					<div className='row'>
						<div className='input-element'>
							<div className='input-label'>{t('phone/account')}</div>
							<ComboBoxRemovable
								searchable={false}
								clearable={false}
								disabled={isAuthorized}
								name='phoneNumber'
								placeholder={t('dontSelect')}
								noResultsText={t('call.numbersNotFound')}
								options={this.getPhoneNumbers()}
								value={!this.isSubmitDisabled() && phoneOption.value}
							 	onRemoveOption={this.removePhoneNumber}
								onChange={this.onPhoneNumberChange}
								onInputChange={this.onNumberInputChange}
								error={error}
							/>
							<div className='actions'>
								<button
									className='btn-add'
									onClick={this.togglePopup}
								>
									<i className='icon-kebab-hor' />
								</button>
								{userCallPopupActionsOpen ?
									<UserCallPopupActions
										isAuthorized={isAuthorized}
										editPhoneId={phoneId}
										isOpen={userCallPopupActionsOpen}
										onClickOutside={this.togglePopup}
									/> :
									null}
							</div>
						</div>
					</div>
					{
						callStation && callStation === 'finesse' ? (
							<Fragment>
								<div className='row'>
									<div className='input-element'>
										<div className='input-label'>{t('login')}</div>
										<input
											className={inputFieldClassName}
											name='ciscoLogin'
											type='text'
											placeholder={t('call.enterLogin')}
											onChange={this.onCredentialsChange}
											value={ciscoLogin}
										/>
									</div>
								</div>
								
								<div className='row'>
									<div className='input-element'>
										<div className='input-label'>{t('access_password')}</div>
										<input
											className={inputFieldClassName}
											name='ciscoPassword'
											type='password'
											placeholder={t('call.enterPassword')}
											onChange={this.onCredentialsChange}
											value={ciscoPassword}
										/>
									</div>
								</div>
							</Fragment>
						) : null
					}
					{error && <span className='error-block'>{error}</span>}
					{outPhoneErrorMSg && <span className='error-block'>{outPhoneErrorMSg}</span>}
					{
						!isAuthorized ? (
							<button
								className={buttonClassName}
								type='submit'
								disabled={this.isSubmitDisabled()}
							>
								{t('call.enter')}
							</button>
						) : null
					}
					{isOpenedModal ?
						<AccountModal
							isOpen={isOpenedModal}
							editPhoneId={modalAccountEditMode}
							onClose={this.props.changeOpenedAccountModal}
							t={t}
						/>
						: null }
				</form>
				{
					isAuthorized && (
						<button
							className={cx(buttonClassName, 'm-t-10')}
							type='button'
							onClick={this.callLogout}
						>
							{t('call.logout')}
						</button>
					)
				}
			</Fragment>
		);
	}
}

UserCallForm.propTypes = {
	onSubmit: PropTypes.func,
	phoneNumber: PropTypes.string,
	phoneNumberList: PropTypes.array,
	getUserPhones: PropTypes.func,
	user: PropTypes.object
};

export default withTranslation()(UserCallForm);
