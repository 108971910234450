import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import ReactMarkdown from 'react-markdown';


export default function PrintTemplate({data = {}, t, dynParams = {}} ) {
	const create = get(data, 'create', '');
	const start = get(data, 'start',' ');
	const end = get(data, 'end',' ');
	const block = get(data, 'block',' ');
	
	const renderDynParam = (block, values) => {
		const { title, widgets, description } = block;
		const widgetValues = widgets.map(widget => { return {title: widget.title, value: values[widget.key]} });
		return widgetValues;
		// return {title, widgets, description};
	};
	
	const renderDynParams = (values, dynParams) => {
		const res = dynParams && dynParams.blocks.flatMap((block) => renderDynParam(block, values));
		if(res.length) {
			return (
				<ul>
					{res.map( ({value, title}, idx) => value &&
					<li key={idx.toString()}><strong>{title} :</strong> {value}</li> ) }
				</ul>
			)
		}
	};
	
	//  'url',
	const dateFormatted = moment(create).isValid() && moment(create).format('DD.MM.YYYY HH.mm');
	const startFormatted = moment(start).isValid() && moment(start).format('DD.MM.YYYY HH.mm');
	const endFormatted = moment(end).isValid() && moment(end).format('DD.MM.YYYY HH.mm');
	
	const attachments = get(data, 'files', []).map( it => it.fileName || it.file.fileName);
	const location = get(data, 'location') ? get(data, 'location', [{label:''}]).map( it => it.label ) : [];
	const attendees = get(data, 'attendees', [{label:''}]).map( it => it.label );
	const calendar = get(data, 'calendar.label','');
	const allDay = get(data, 'allDay', false);
	const title = get(data, 'title','');
	const author = get(data, 'ownDetails',[]).map( it => it.label).join(' ');
	const rrule = get(data, 'rrule.label','');
	const valarm = get(data, 'valarm.label','');
	const description = get(data, 'description',' ');
	const status = get(data, 'status.label', get(data, 'status.name', ''));
	const eventType = get(data, 'eventType.label', get(data, 'eventType.name', ''));
	
	
	return (
		<div>
			<table>
				
				<tr>
					<td style={{textAlign:'center'}} colSpan='3'>
						<strong>{calendar}</strong>
					</td>
				</tr>
				
				<tr>
					<td style={{textAlign:'left'}} colSpan='3'>
						<strong>{t('calendar.printTitle')}: {title}</strong>
					</td>
				</tr>
				<tr>
					<td style={{textAlign:'left', color:'#93b5c5'}} colSpan='3'>
						{t('calendar.printAttendees')}: <strong>{attendees.join(',  ')}</strong>
					</td>
				</tr>
				
				<tr>
					<td colSpan='2'>
						{t('calendar.printEventType')}: <strong>{eventType}</strong>
					</td>
					<td >
						{t('calendar.printStatus')}: <strong>{status}</strong>
					</td>
				</tr>
				
				<tr>
					<td colSpan='2'>
							<span>{t('calendar.author')}: <strong>{author}</strong></span>
						
					</td>
					<td >
						<span>{t('calendar.printLocation')}:</span> <br/>
						<span><strong>{location.length >1 ? location.join(';  ') : location}</strong></span>
					</td>
				</tr>
				
				<tr>
					<td colSpan='2'>
						<ul style={{ listStyle: 'none', padding: 0, color:'#3f70c5'}}>
						{t('calendar.printCreate')}: {dateFormatted} <br/><br/>
						{t('calendar.printStartEnd')}: {`${startFormatted} - ${endFormatted}`} <br/><br/>
						{t('calendar.allDay')}: {allDay ? 'ТАК' : 'НІ'} <br/><br/>
						
						</ul>
					</td>
					<td >
						<ul style={{ listStyle: 'none', padding: 0, color:'#3f70c5'}}>
							<li>{t('calendar.printRrule')}: <strong>{valarm}</strong></li><br/>
							<li>{t('calendar.printValarm')}: <strong>{rrule}</strong></li><br/>
						</ul>
					</td>
				</tr>
				
				<tr>
					<td colSpan='3'>
						<span><strong>{t('calendar.description')}</strong></span><br/>
						<ReactMarkdown source={description} escapeHtml={false} unwrapDisallowed={true} />
					</td>
				</tr>
				
				{attachments.length ?
					<tr>
						<td colSpan='3'>
							{t('mailing.attachments')}: <br/><br/>
							<strong style={{color:'#3f70c5'}}>{attachments.join(',  ')}</strong>
						</td>
					</tr>
					: null
				}
				{block ?
					<tr>
						<td colSpan='3'>
							{t('calendar.printDynParams')}: <br/><br/>
							{renderDynParams(block, dynParams)}
						</td>
					</tr>
					: null
				}
			
			</table>
		</div>
	)
}

PrintTemplate.propTypes = {
	data: PropTypes.object,
	t: PropTypes.func,
};
