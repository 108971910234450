import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

@withTranslation()
export default class NewEntityButton extends React.Component {
	render () {
		const { title, onClick, t } = this.props;
		return (
			<button className='btn btn-primary' onClick={onClick}>
				{/* {t(`newEntityButton.${title}`)} */}
				{t(title)}
			</button>
		);
	}
}

NewEntityButton.propTypes = {
	title: PropTypes.string
};
