import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ClickOutsideHolder from '../ClickOutsideHolder';
import ErrorBlock from '../ErrorBlock';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import Loader from '../Loader';

import './styles.scss';

@withTranslation()
class ComboBoxMultipleCheckbox extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        showOptions: false,
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.showOptions && this.state.showOptions) {
            const selectOptions = document.querySelector('.optionsWrapper');
            if (selectOptions.scrollIntoViewIfNeeded) {
                selectOptions.scrollIntoViewIfNeeded(false);
            } else {
                selectOptions.scrollIntoView(false);
            }
        }
    }

    getLabel = (value) => {
        const { options } = this.props;
        const option = options.find((option) => option.value === value);
        return option && option.label;
    };

    handleChange = (event, option) => {
        const { input } = this.props;
        const newValue = [...input.value];
        if (event.target.checked) {
            newValue.push(option);
        } else {
            newValue.splice(newValue.indexOf(option), 1);
        }
        input.onChange(newValue);
    };

    clearAll = (event) => {
        const { input } = this.props;
        // clear
        // console.log({event});
        input.onChange([]);
    };

    toggleSelectAll = (event) => {
        const { options, input } = this.props;
        const optionsLength = options.length;
        const valuesLength = input.value.length;
        if (optionsLength === valuesLength) {
            // clear
            input.onChange([]);
        } else {
            // select
            input.onChange(options.map((item) => item.value));
        }
    };

    toggleOptions = () => {
        const { disabled, input, onOpen } = this.props;
        if (!disabled) {
            if (this.state.showOptions) {
                input.onBlur();
            } else {
                input.onFocus();
            }
            if (onOpen && typeof onOpen === 'function') {
                onOpen();
            }
            this.setState((prevState) => ({ showOptions: !prevState.showOptions }));
        }
    };

    hideOptions = (e) => {

        const fieldKey = this.props.input && this.props.input.name;
        let isFieldControllerParent = false;
        let isFieldControllerSubmit = false; // is field controller submit button clicked

        if (fieldKey) {
            const path = e ? e.path || (e.composedPath && e.composedPath()) : [];
            for (let i = 0; i < path.length; i++) {
                if (path[i].getAttribute && path[i].getAttribute("data-field-key")) {
                    isFieldControllerParent = path[i].getAttribute("data-field-key") === fieldKey;
                    // break;
                }
                if (path[i].getAttribute && path[i].getAttribute("data-field-controller-submit")) {
                    isFieldControllerSubmit = path[i].getAttribute("data-field-controller-submit") === "true";
                    // break;
                }
            }
        }

        // make sure that not submit button was clicked, if submit button was clicked => options should be hidden
        if (isFieldControllerParent && !isFieldControllerSubmit) {
            return;
        }

        if (this.state.showOptions) {
            this.props.input.onBlur();
            this.setState({ showOptions: false });
        }
        const {
            input: { value, name },
            submitComboboxMulty,
            submitOnBlur,
            meta,
        } = this.props;
        if (submitOnBlur && meta.touched) {
            submitComboboxMulty(value, name);
        }
    };

    renderCheckboxGroup = () => {
        const { options, input, disabled, t } = this.props;
        const optionsLength = options.length;
        const valuesLength = input.value.length;
        
        return (
            <div className="optionsWrapper">
                <button type='button' className={cx("btn btn-add button-clear-all", { disabled: disabled })} onClick={this.clearAll}>
                    <ReactSVG className="button-icon" src={`/data/svg/remove_circle.svg`} />
                    {t('clear all results')}
                </button>
                <div className={cx("checkbox checkbox-select-all", { disabled })}>
                    <label htmlFor="selectAll">
                        <input
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            checked={optionsLength === valuesLength}
                            onChange={this.toggleSelectAll}
                        />
                        <span className="checkbox-text">
                            <ReactSVG
                                className="checkbox__unchecked-icon"
                                src={`/data/svg/checkbox_unchecked.svg`}
                            />
                            <ReactSVG
                                className="checkbox__checked-icon"
                                src={`/data/svg/checkbox_checked.svg`}
                            />
                            <span>{t('select all results')}</span>
                        </span>
                    </label>
                </div>
                {options.map((option, index) => (
                    <div className={cx("checkbox", { disabled: option.disabled || disabled })} key={index}>
                        <label htmlFor={option.value}>
                            <input
                                type="checkbox"
                                name={`${input.label}[${index}]`}
                                id={option.value}
                                checked={input.value.indexOf(option.value) !== -1}
                                onChange={(event) => this.handleChange(event, option.value)}
                                disabled={option.disabled || disabled}
                            />
                            <span className="checkbox-text">
                                {/* <div className="checkbox-square"> */}
                                <ReactSVG
                                    className="checkbox__unchecked-icon"
                                    src={`/data/svg/checkbox_unchecked.svg`}
                                />
                                <ReactSVG
                                    className="checkbox__checked-icon"
                                    src={`/data/svg/checkbox_checked.svg`}
                                />
                                {/* </div> */}
                                <span>{option.label}</span>
                            </span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    renderOptions = (values) => {
        if (this.props.showValuesCount) {
            const { t } = this.props;
            return (
                <div className="input-counted-values">
                    <>
                        {values.length === 1 && <span>{this.getLabel(values[0])}</span>}
                        {values.length > 1 && (
                            <span>
                                ({values.length}) {t('select all results')}
                            </span>
                        )}
                    </>
                </div>
            );
        }
        return values.map(this.renderOption);
    };

    renderOption = (item, index, arr) => {
        if (this.props.options.find((i) => item === i.value)) {
            return (
                <span key={index} className="input-value">
                    {this.getLabel(item)}
                    {arr.length > 1 && index !== arr.length - 1 && ', '}{' '}
                </span>
            );
        }
        return null;
    };

    render() {
        const { meta, input, placeholder, disabled, label, required, isLoading } = this.props;

        const wrapperClassName = cx('input-element comboBox-multi-checkbox', {
            isOpen: this.state.showOptions,
            disabled: disabled,
        });

        const inputClassName = cx('input-element', {
            'input-element__error': meta && meta.touched && meta.error,
        });
        const buttonClassName = cx('input-field', {
            'input-field__error': meta && meta.touched && meta.error,
        });

        const showErrorBlock = meta && meta.touched && !meta.active && meta.error;

        return (
            <div className={inputClassName}>
                <div className="input-label">
                    {label}
                    {required && <span className="required-field">*</span>}
                </div>
                <ClickOutsideHolder className={wrapperClassName} onClickOutside={this.hideOptions}>
                    <button className={buttonClassName} onClick={this.toggleOptions} type="button">
                        {isLoading && <Loader loaderContainerClass="container-loader container-loader-comboBox-multi-checkbox" loaderClass="input-loader" />}
                        {Array.isArray(input.value) && input.value.length > 0
                            ? this.renderOptions(input.value)
                            : placeholder}
                    </button>
                    {this.state.showOptions && this.renderCheckboxGroup()}
                </ClickOutsideHolder>
                {showErrorBlock && <ErrorBlock error={meta.error} className="m-r-10" />}
            </div>
        );
    }
}

ComboBoxMultipleCheckbox.propTypes = {
    options: PropTypes.array.isRequired,
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    meta: PropTypes.shape({
        error: PropTypes.string,
        label: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
};

export default ComboBoxMultipleCheckbox;
