import React from 'react';
import { Field } from 'redux-form';
import baseService from 'services/BaseService';
import DynamicParams from '../../pages/Appeal/CallQuality/DynamicParams';
import i18next from 'util/i18n';

export function renderField (fieldConfig) {
	if (fieldConfig.hidden) {
		return (
			<div className="hidden">
				<Field key={fieldConfig.name}  {...fieldConfig} required={false} />
			</div>
		);
	}
	return (
		<Field key={fieldConfig.name}  {...fieldConfig} />
	);
}

const OBJECTTYPEIDS = { organization: 1, individual: 2, serviceobject: 4 };

export async function getDynamicFields (customerTypeName, entity, customerTypeId) {
	const data = {
		objectType: `${entity}@spec`,
		// objectTypeId: OBJECTTYPEIDS[customerTypeName],
		objectTypeId: customerTypeId,
		objectTypeCode: customerTypeName,
		openMode: 'create',
		ctxObjectType: '',
		ctxObjectId: '',
		curStepNum: 1,
		overrideIsReq: ''
	};
	const response = await baseService.get('appeal_form_params', {data});
	
	return response.result && response.result.blocks;
}

export async function getDynamicFieldsContactPerson (customerTypeName, entity, customerId, customerTypeId) {
	const data = {
		objectType: `${entity}@spec`,
		// objectTypeId: OBJECTTYPEIDS[customerTypeName],
		objectTypeId: customerTypeId || OBJECTTYPEIDS[customerTypeName],
		objectTypeCode: customerTypeName,
		openMode: 'create',
		ctxObjectType: 'CUSTOMER',
		ctxObjectId: customerId,
		curStepNum: 1,
		overrideIsReq: ''
	};
	const response = await baseService.get('appeal_form_params', {data});
	
	return response.result && response.result.blocks;
}

export async function getDynamicFieldsInstanse (customerTypeName, entity, customerId, contactPersonId, appealId) {
	const data = {
		objectType: `${entity}@inst`,
		objectTypeId: contactPersonId || customerId,
		objectTypeCode: '',
		openMode:'update',
		ctxObjectType: (entity === 'CONTACT_PERSON') ? 'CUSTOMER' : appealId ? 'INTERACTION_REQUEST' : '',
		ctxObjectId: (entity === 'CONTACT_PERSON') ? customerId : appealId && appealId,
		curStepNum: 1,
		overrideIsReq: ''
	};
	const response = await baseService.get('appeal_form_params', {data});
	
	return response.result && response.result;
}

export function renderDynamicFields(block, callHandler, {readOnly, isCollapsedHidden, formValues}) {
	const saveOnChange = (typeof callHandler === 'function') && callHandler;
	
	const { key, title, widgets } = block;
	return <>
		{
			title && title !== '*' &&
			<header key={key}>{title}</header>
		}
		{
			<DynamicParams
				t={i18next.t}
				widgets={widgets}
				refSelect='Select'
				saveOnChange={saveOnChange}
				readOnly={readOnly}
				isCollapsedHidden={isCollapsedHidden}
				formValues={formValues}
			/>
		}
	</>
}

export function createAddressObject (value, type = 'physical') {
	return {
		'parentId': _.get(value, 'settlement.id'),
		'parentType': 'location',
		'streetId': _.get(value, 'street.value'),
		'name': _.get(value, 'street.label') || value.streetName,
		'streetTypeId': _.get(value, 'street.type') || _.get(value, 'streetType.value'),
		'addressType': type,
		'buildingNumber': _.get(value, 'buildingNumber.label') || value.buildingNumberInput,
		'apartmentNumber': value.apartmentNumber,
		'description': value.description,
		'zipCode': _.get(value, 'zipCode.label') || value.zipCodeInput,
		'buildingSecondNumber': value.buildingSecondNumber,
	};
}

export const physicalAddressValues = [
	'street', 'streetType', 'streetName', 'buildingNumber', 'buildingNumberInput', 'apartmentNumber', 'description', 'zipCode',
	'zipCodeInput', 'buildingSecondNumber'
];

