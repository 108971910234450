import React, { useState, useRef, useEffect, useCallback } from "react";
import cx from "classnames";
import Pill from "./Pill";
import ClickOutSideHolder from "../../../pages/Ordering/common/ClickOutsideHolder";
import Popup from "components/Popup";
import Portal from "../../../pages/Ordering/common/Portal";
import Loader from "components/Loader";
import useDebounce from "../../../custom-hooks/useDebounce";

import "./styles.scss";
import ReactSVG from "react-svg";

export default function ArrayPillsInput(props) {
    const {
        input,
        placeholder,
        validatePill,
        containerClass,
        wrapperClass,
        meta,
        actionKeys = [" ", ",", ";", "Enter"],
        onQuerySuggestion, // should return an array of suggested options
        ...rest
    } = props;

    const emails = input.value || [];

    const inputRef = useRef(null);
    const inputWrapperRef = useRef(null);
    const [fieldInput, setFieldInput] = useState("");
    const [isResized, setIsResized] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
    const [portalPosition, setPortalPosition] = useState({ top: 0, left: 0 });

    const { debouncedValue: debouncedQuery, setDebouncedValue: setDebouncedQuery } = useDebounce(fieldInput, 300);

    function getEmailsFromInput(input) {
        if (typeof input !== "string") {
            console.error("ArrayPillsInput::getEmailsFromInput: Input is wrong type:", typeof input);
            return [];
        }
        let newEmails = [];
        if (input.includes(";")) {
            // handle ;
            newEmails = input.split(";").map(email => email.trim());
        } else if (input.includes(",")) {
            // handle ,
            newEmails = input.split(",").map(email => email.trim());
        } else {
            // handle " " space
            newEmails = input.split(" ").map(email => email.trim());
        }
        // filter empty emails
        return newEmails.filter(email => email);
    }

    function keyPress(e) {
        if (actionKeys.includes(e.key) && fieldInput.trim()) {
            let trimmedInput = fieldInput;
            if (e.key !== "Enter") {
                trimmedInput = fieldInput.substring(0, fieldInput.length - 1);
            }
            const newEmails = getEmailsFromInput(trimmedInput);
            input.onChange([...new Set([...emails, ...newEmails])]);
            setFieldInput("");
        }
    }

    function remove(index) {
        if (isFocused) {
            focusRef();
        }
        const newEmails = [...emails];
        newEmails.splice(index, 1);
        input.onChange(newEmails);
    }

    function focusRef() {
        inputRef && inputRef.current && typeof inputRef.current.focus === "function" && inputRef.current.focus();
    }

    function onBlur() {
        if (fieldInput.trim()) {
            const newEmails = getEmailsFromInput(fieldInput);
            input.onChange([...new Set([...emails, ...newEmails])]);
            setFieldInput("");
        }
        setIsFocused(false);
    }

    function onFocus() {
        setIsFocused(true);
    }

    function onClickOutside(e) {
        let isSuggestionClicked = false;
        let isSuggestionModalTriggerClicked = false; // icon or button which triggers contact suggestions search via grid modal 
        const path = e ? e.path || (e.composedPath && e.composedPath()) : [];

        for (let i = 0; i < path.length; i++) {
            if (path[i].getAttribute && path[i].getAttribute("data-field-key")) {
                isSuggestionModalTriggerClicked = path[i].getAttribute("data-field-key") === `search-email-modal-${input.name}`;
                isSuggestionClicked = path[i].getAttribute("data-field-key") === `contact-suggestions-${input.name}`;
                break;
            }
        }
        if (isSuggestionModalTriggerClicked) {
            e.stopPropagation();
            e.preventDefault();
            setFieldInput("");
            return;
        }
        if (isSuggestionClicked) {
            focusRef();
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        onBlur();
    }

    function addSuggestedContact(contact) {
        input.onChange([...new Set([...emails, contact])]);
        setFieldInput("");
        setDebouncedQuery("");
    }

    function renderSuggestion(suggestion, index) {
        const selected = emails.includes(suggestion.email);
        return (
            <div
                className={cx("contact-suggestion", { selected })}
                onClick={() => !selected && addSuggestedContact(suggestion.email)}
                key={index}
            >
                <div className="contact-suggestion__title">
                    {suggestion.email} - {suggestion.name}
                </div>
                <div className="contact-suggestion__position">{suggestion.position || " "}</div>
                {selected && <ReactSVG className="selected-icon" src={`/data/svg/check-circle.svg`} />}
            </div>
        );
    }

    async function handleQuerySuggestion(query) {
        if (onQuerySuggestion && typeof onQuerySuggestion === "function") {
            setIsSuggestionsLoading(true);
            const newSuggestions = await onQuerySuggestion(query);
            setSuggestions(newSuggestions);
            // console.log({ newSuggestions });
            setIsSuggestionsLoading(false);
        }
    }

    const updatePortalPosition = () => {
        if (inputWrapperRef && inputWrapperRef.current) {
            const { top, right, width, height } = inputWrapperRef.current.getBoundingClientRect();
            setPortalPosition({ top: top + height + 5, left: right - width - 2 });
        }
    }

    const handleKeyUp = useCallback(
        e => {
            if (isFocused) {
                const key = e && e.key;
                if (key === "Tab") {
                    onBlur();
                }
            }
        },
        [isFocused, fieldInput]
    );

    const handleResize = useCallback((e) => {
        setIsResized(true);
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        if (isFocused) {
            document.addEventListener("keyup", handleKeyUp);
        }
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, [isFocused, fieldInput]);

    useEffect(() => {
        updatePortalPosition();
    }, [emails]);

    useEffect(() => {
        if (isResized) {
            updatePortalPosition();
            setIsResized(false);
        }
    }, [isResized]);

    useEffect(() => {
        handleQuerySuggestion(debouncedQuery);
    }, [debouncedQuery]);

    return (
        <ClickOutSideHolder onClickOutside={onClickOutside} className={wrapperClass}>
            <div
                ref={inputWrapperRef}
                className={cx(containerClass, meta.error && meta.touched && "error", isFocused && "is-focused")}
                {...rest}
            >
                {emails.map((email, idx) => (
                    <Pill value={email} id={idx} remove={remove} validate={validatePill} key={idx} />
                ))}

                <input
                    ref={inputRef}
                    className="input-field"
                    autoComplete="off"
                    type="text"
                    onKeyUp={keyPress}
                    value={fieldInput}
                    placeholder={placeholder}
                    onChange={e => {
                        setFieldInput(e.target.value);
                    }}
                    // onBlur={onBlur}
                    onFocus={onFocus}
                />
                {isFocused && suggestions && suggestions.length > 0 && (
                    <Portal
                        dataFieldKey={`contact-suggestions-${input.name}`}
                        className="contacts-suggestions-portal"
                        position={portalPosition}
                    >
                        <Popup place="contacts-suggestions-popup" onClickOutside={() => {}}>
                            {isSuggestionsLoading && <Loader />}
                            {suggestions.map(renderSuggestion)}
                        </Popup>
                    </Portal>
                )}
            </div>
        </ClickOutSideHolder>
    );
}
