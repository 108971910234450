import React from "react";
import PropTypes from "prop-types";
import styles from "./appealTypeSelect.module.scss";
import { withTranslation } from "react-i18next";
import cx from "classnames";
import TreeModal from "./TreeModal";
import baseService from "../../../../services/BaseService";
import get from "lodash/get";
import { getNodePath } from "./helpers";

import './styles.scss';

@withTranslation()
class TreeSelectModalWithSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpened: false,
            isNodeArrayOnceLoaded: false,
            // init an array and load it afterwards on modal open
            nodeArray: this.props.initNodeArray,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getNodeArray = this.getNodeArray.bind(this);
        this.onChange = this.onChange.bind(this);
        this.arrowRender = this.arrowRender.bind(this);
    }

    openModal() {
        if (this.props.disabled) return;
        const { nodeArrayApiKey, nodeArrayResultKey } = this.props;
        if (this.props.nodeArrayApiKey) {
            this.getNodeArray(nodeArrayApiKey, nodeArrayResultKey);
        }
        const callback = () => this.props.onOpen && this.props.onOpen();
        this.setState({ isModalOpened: true }, callback);
    }

    closeModal() {
        const callback = () => this.props.onBlur && this.props.onBlur();
        this.setState({ isModalOpened: false }, callback);
    }

    async getNodeArray(apiKey, resultKey = "children") {
        this.setState({ isLoading: true });
        try {
            const response = await baseService.get(apiKey);
            let result = get(response, resultKey);
            if (result && result.length === 1) {
                result = result[0][resultKey];
            }
            if (result && result.length > 0) {
                this.setState({ nodeArray: result, isLoading: false });
            }
        } catch (e) {
            console.error("TreeSelectModalWithSearch::getNodeArray: ", e);
            this.setState({ isLoading: false });
        }
    }

    onChange(selectedNode) {
        const { valueField, labelField, onValueChange, input } = this.props;

        const initialValue = input.value;

        if (!initialValue || initialValue[valueField] !== selectedNode[valueField]) {
            const { parent, ...restSelectedNode } = selectedNode;
            const updatedValue = {
                ...restSelectedNode,
                [valueField]: selectedNode[valueField],
                fullLabelPath: getNodePath(selectedNode, labelField).join(" / "),
                code: selectedNode.code,
            };

            this.props.input.onChange(updatedValue);

            if (this.props.onChange && typeof this.props.onChange === "function") {
                this.props.onChange(updatedValue);
            }

            if (onValueChange && typeof onValueChange === 'function') {
                onValueChange(updatedValue, initialValue);
            }
        }

        this.closeModal();
    }

    arrowRender = () => (this.state.isModalOpened ? <i className="icon-up" /> : <i className="icon-down" />);

    render() {
        const {
            required,
            label,
            t,
            // nodeArray,
            valueField,
            labelField,
            leafField,
            childrenField,
            // initialValue,
            placeholder,
            disabled,
            error,
            className,
			input,
            ...rest
        } = this.props;

        const { nodeArray, isLoading } = this.state;

        const { isModalOpened } = this.state;

		const initialValue = input.value;

        const valueClassName = cx("input-field", {
            [styles.placeholder]: !initialValue,
            [styles.disabled]: disabled,
            ["input-field__error"]: Boolean(error),
        });

        const text = get(initialValue, "fullLabelPath", get(initialValue, "label", get(initialValue, "label", error)));
        const placeholderText = placeholder || t("appeal.selectPlaceholder");

        const mergedClassName = `tree-select-modal-with-search ${className || ''} ${disabled ? 'is-disabled' : ''}`;

        return (
            <div className={cx("input-element", styles.appealTypeSelect, mergedClassName)}>
                <div className="input-label">
                    {label}
                    {required && <span className="required-field">*</span>}
                </div>

                <div id={rest.id} className={valueClassName} onClick={this.openModal}>
                    {text || placeholderText}
                    {this.arrowRender()}
                </div>

                {isModalOpened && (
                    <TreeModal
                        nodeArray={nodeArray}
                        valueField={valueField}
                        labelField={labelField}
                        leafField={leafField}
                        childrenField={childrenField}
                        initialValue={initialValue}
                        loading={isLoading}
                        onClose={this.closeModal}
                        onSubmit={this.onChange}
                        title={this.props.title}
                    />
                )}
            </div>
        );
    }
}

TreeSelectModalWithSearch.defaultProps = {
    nodeArray: [],
    valueField: "id",
    labelField: "name",
    leafField: "leaf",
    childrenField: "children",
};

TreeSelectModalWithSearch.propTypes = {
    nodeArray: PropTypes.array,
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    leafField: PropTypes.string,
    childrenField: PropTypes.string,
    label: PropTypes.string,
    initialValue: PropTypes.shape({
        id: PropTypes.number,
        fullLabelPath: PropTypes.string,
    }),
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
};

export default TreeSelectModalWithSearch;
