import React, { useState, useEffect } from 'react';
import cx from "classnames";
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, submit, getFormValues, SubmissionError } from 'redux-form';
import { withTranslation } from 'react-i18next';
import InputField from '../../Common/InputField';
import ComboBox from '../../Common/ComboBox';
import {ComboBox as ComboBoxCore} from 'ui-core-dashboard';
import validate from './validate';
import baseService from '../../../services/BaseService';
import { getDynamicFields, renderDynamicFields } from '../utils';
import Loader from "../../Loader";
import { convertDynamicFields } from 'helpers';
// import i18next from 'util/i18n';
import _, { isEmpty } from 'lodash';
import { getContactPerson, getCustomer } from 'actions/customer';
import { addCustomerToAppeal, getAppealFeedback, unlockAppealForm } from 'modules/appeal/actions';
import DynamicParams from 'pages/Appeal/CallQuality/DynamicParams';
import { personalDataBlocks, objectDataBlocks, getMergedStaticBlocks, getModalFormDataFromState } from "../config";

function ObjectForm (props) {
	const { t, setIsLoading, customerTypeName, customerTypeId,  setDynConfig, formValues, incomePhoneNumber, change, personalDataBlock, objectDataBlock, modalFormData, formCode, isSubmitDisabled } = props;

	useEffect(() => {
		if (!props.pristine) {
			props.enableWarning();
		}
	}, [props.pristine]);
	
	const [nameCityOptions, setCityNameOptions] = useState([]);
	const [nameOptions, setNameOptions] = useState([]);
	const [apartOptions, setApartOptions] = useState(null);
	const [dynParamsCustomer, setDynParamsCustomer] = useState(null);
	const [dynParamsContactPerson, setDynParamsContactPerson] = useState(null);
	const [fetchingCP, setFetchingCP] = useState(false);
	const [fetchingCust, setFetchingCust] = useState(false);
	const siteId = _.get(formValues, 'object', null);
	const widgetType = _.get(apartOptions, 'widgetType', null);

	useEffect( () => {
		return () => {
			setDynParamsContactPerson(null);
			setDynParamsCustomer(null);
			setApartOptions(null);
			props.change('apartment', null);
		}
	}, [customerTypeName]);
	
	// handle customer dyn params
	useEffect( () => {
		async function fetchData() {
			setFetchingCust(true);
			const responseCustomer = await getDynamicFields(customerTypeName, 'CUSTOMER', customerTypeId);
			if(responseCustomer){
				setDynParamsCustomer(responseCustomer);
				setFetchingCust(false);
				if (Array.isArray(responseCustomer)) {
					setDynConfig(prev => prev ? [...prev, ...responseCustomer] : responseCustomer);
				}
			}
		}
		
		if (objectDataBlock && !fetchingCust && !dynParamsCustomer) {
			fetchData();
		}
	}, [customerTypeName, objectDataBlock]);

	// handle CP dyn params
	useEffect( () => {
		async function fetchData() {
			setFetchingCP(true);
			const responseContactPerson = await getDynamicFields(customerTypeName, 'CONTACT_PERSON', customerTypeId);

			if(responseContactPerson){
				setDynParamsContactPerson(responseContactPerson);
				setFetchingCP(false);
				if(Array.isArray(responseContactPerson)) {
					setDynConfig(prev => prev ? [...prev, ...responseContactPerson] : responseContactPerson);
				}
			}
		}
		
		if (personalDataBlock && !fetchingCP && !dynParamsContactPerson) {
			fetchData();
		}
	}, [customerTypeName, personalDataBlock]);
	
	useEffect( ()=>{
		props.change('object', null);
		props.change('apartment', null);
		setNameOptions([]);
		setApartOptions(null);
	}, [nameCityOptions]);
	
	useEffect( ()=>{
		if(siteId){
			searchAppart(siteId.value);
		}
		setApartOptions(null);
	}, [siteId]);
	
	
	useEffect(() => {
		if (incomePhoneNumber) {
			props.change('workPhone', incomePhoneNumber);
		}
	}, [incomePhoneNumber]);

	// update hiddenValues
	useEffect(() => {
		if (formCode) {
			const hiddenValuesFields = Object.keys(modalFormData).flatMap(key => modalFormData[key].sortedAttrs).filter(field => field.hidden && field.hiddenValues);
			hiddenValuesFields.forEach(field => {
				props.change(field.field, field.hiddenValues);
			});
		}
	}, [formCode]);
	
	useEffect( ()=>{
		return () => {
			setDynParamsContactPerson(null);
			setDynParamsCustomer(null);
			setNameOptions([]);
			setApartOptions(null);
			setCityNameOptions([]);
		}
	}, []);
	
	
	function convertDictionaryObject (dictionaryObject) {
		return Object.entries(dictionaryObject).map(([prop, value]) => ({
			value: prop,
			label: value
		}));
	}
	
	const searchCityQuery = _.debounce(async query => {
		if (!query || query.length <= 1) return;
		const data = {
			filter:[{
				query: query,
				field: 'city'
			}],
			type:"city"
		};
		
		const resp = await baseService.post('object_search', { data, jsonType: true } );
		
		if (resp.result) {
			setCityNameOptions(resp.result.map(i => ({ value: i.id, label: i.objectName })));
		} else {
			setCityNameOptions([]);
		}
	}, 500);
	
	const getOptions = _.debounce((query, callback) => {
		if (query && query.trim().length > 2) {
			const data = {
				filter:[{
					query: _.get(formValues, 'city.label', ''),
					field: 'city'
				}, {
					query: query,
					field: 'site'
				}],
				type:"site"
			};
			baseService.post('object_search', { data, jsonType: true } )
				.then(response => {
					callback(null, { options: response.result.map((i) => ({ value: i.id, label: i.objectName })) });
				})
				.catch(console.error);
		} else {
			callback(null, { options: [] });
		}
	}, 500);
	
	const searchAppart = async siteId => {
		const resp = await baseService.get('apart_search', { data: { siteId }});
		
		if (resp.result) {
			return setApartOptions(resp.result);
		}
		setApartOptions(null);
	};
	
	
	function getFieldPropsWithOptions (fieldProps) {
		switch (fieldProps.name) {
			// console.log({fieldProps.name});
            case 'city':
                return {
                    ...fieldProps,
					options: nameCityOptions,
					onChange: () => {
						setTimeout(() => props.change("object", null), 0);
						setTimeout(() => props.change("apartment", null), 0);
					},
                    onInputChange: searchCityQuery,
                };
            case 'object':
                return {
                    ...fieldProps,
                    options: nameOptions,
					loadOptions: getOptions,
					onChange: () => {
						setTimeout(() => props.change("apartment", null), 0);
					},
                };
            case 'issues': {
				let options = convertDictionaryObject(t('dictionary:contactIssue', { returnObjects: true }));
				// if (fieldProps.hiddenValues && Array.isArray(fieldProps.hiddenValues)) {
				// 	options = options.filter(option => !fieldProps.hiddenValues.includes(option.value));
				// }
                return {
                    ...fieldProps,
					options,
					// options: options.slice(1)
                    // options: convertDictionaryObject(
                    //     t('dictionary:contactIssue', { returnObjects: true }),
					// ).slice(1),
                };
			}
            case 'apartment':
                return !widgetType && fieldProps;
            default:
                return fieldProps;
        }
	}

	function renderField (fieldConfig, index) {
		if (fieldConfig.hidden) {
            return (
                <div className="hidden">
                    <Field {...getFieldPropsWithOptions(fieldConfig)} required={false} key={fieldConfig.key ? `${index}_${fieldConfig.key}` : index} />
                </div>
            );
        }
		if(widgetType && fieldConfig.name === 'apartment'){
			const {key, rule, values, req: isReq, blockKey} = apartOptions;
			const title = t('apartment');
			// const widget = [{ widgetType, key: 'apartment', rule, values, isReq, title, blockKey, maxLength: fieldConfig.maxLength}];
			// set isReq based on fieldConfig
			const widget = [{ widgetType, key: 'apartment', rule, values, isReq: fieldConfig.required, title, blockKey, maxLength: fieldConfig.maxLength}];

			if (['combo', 'select'].includes(widgetType)) widget[0].onlyLabelFilter = true;

			return 	<DynamicParams
						t={t}
						widgets={widget}
						readOnly={false}
						// key={fieldConfig.key ? `${index}_${fieldConfig.key}` : index}
					/>
		}
		return (
			<Field {...getFieldPropsWithOptions(fieldConfig)} key={fieldConfig.key ? `${index}_${fieldConfig.key}` : index} />
		);
	}

	async function submit (values) {
		// const isReqApart = _.get(apartOptions, 'req', null);
		// console.log({apartOptions, values});
		// if (isReqApart && !values.apartment) {
		// 	throw new SubmissionError({
		// 		apartment: t('validation.required'),
		// 		error: t('addRequiredFields')
		// 	})
		// }
		
		await setIsLoading(true);
		const contacts = [];
		
		const attr_0 = { key: values.apartment, val: values.apartment } ;
		if (values.apartment && widgetType && widgetType !== 'input') {
			if (widgetType === 'combo') {
				const val = apartOptions.values.find(item => item.key === values.apartment);
				attr_0.key = values.apartment;
				attr_0.val = _.get(val, 'value', values.apartment);
			}
			else {
				attr_0.key = values.apartment.key;
				attr_0.val = values.apartment.label;
			}
		}
			
			if (values.workPhone) {
			contacts.push({
				type: 'workPhone',
				val: values.workPhone
			});
		}
		
		if (values.email) {
			contacts.push({
				type: 'email',
				val: values.email
			});
		}
		
		let dynParamsCust;
		let dynParamsCP;
		if(dynParamsCustomer || dynParamsContactPerson) {
			//prepeared  & separation dyn params from form
			const dynParamsCustomerConfig = _.flattenDeep(!dynParamsCustomer ? [] : dynParamsCustomer.map(item => item.widgets && item.widgets));
			const dynParamsContactPersonConfig = _.flattenDeep(!dynParamsContactPerson ? [] : dynParamsContactPerson.map(item => item.widgets && item.widgets));
			const prepearedDynParamsCustomer = _.pickBy(values, (item, key) => dynParamsCustomerConfig.some(conf => conf.key === key));
			const prepearedDynParamscontactPerson = _.pickBy(values, (item, key) => dynParamsContactPersonConfig.some(conf => conf.key === key));
			
			dynParamsCust = convertDynamicFields(prepearedDynParamsCustomer);
			dynParamsCP = convertDynamicFields(prepearedDynParamscontactPerson);
		}
		
		const data = {
			contactPerson: {
				lastName: values.lastName,
				firstName: values.firstName,
				patronymic: values.patronymic,
				contacts,
				issues: values.issues,
				dynParams: dynParamsCP
			},
			party: {
				shortName: values.object && values.object.label,
				params: {
					attr_0,
					attr_1: { key: values.object && values.object.value, val: values.object && values.object.label }
				},
				ext_uid: values.object && values.object.value,
				dynParams: dynParamsCust
			}
		};
		if (isSubmitDisabled) {
            return;
        }
		const resp = await baseService.post('create_object', { data, jsonType: true });
		await setIsLoading(false);
		if (resp.result) {
			const { customerId, personId } = resp.result;
			await props.onClose();
	
			if (typeof (_.get(props.newCustomerModalContext, 'callback')) === 'function') {
				const callback = _.get(props.newCustomerModalContext, 'callback');
				await callback(customerId, personId);
			}
			if (props.openNewTab) {
				props.history.push(`/customer/${resp.result.customerId}/appeals`);
			} else {
				const appealId = _.get(props.match, 'params.appealId');
				
				props.addCustomerToAppeal(appealId, customerId, personId);
				props.getCustomer({ customerId: customerId }, customerId);
				props.getContactPerson({ id: personId }, customerId);
				props.getAppealFeedback({ appealId, customerId });
			}
		}
	}

	return (
		<form onSubmit={props.handleSubmit(submit)} className='ordering-component-ui-core-wrapper'>
			<div className='create-customer-form-container'>
				<div className={cx("form-wrapper", { hidden: !objectDataBlock || objectDataBlock.hidden })}>
					<header>{t(objectDataBlock && objectDataBlock.translationLabel)}</header>
					
					{props.objectConfig.map(renderField)}
					{objectDataBlock && (
						<div className="additional-data">
							{fetchingCust && !dynParamsCustomer && <div className='loader-box'> <Loader/> </div>}
							{dynParamsCustomer && dynParamsCustomer.map(renderDynamicFields)}
						</div>
					)}
				</div>
				<div className={cx("form-wrapper", { hidden: !personalDataBlock || personalDataBlock.hidden })}>
					<header>{t(personalDataBlock && personalDataBlock.translationLabel)}</header>
					{props.personConfig.map(renderField)}
					{personalDataBlock && (
						<div className="additional-data">
							{fetchingCP && !dynParamsContactPerson && <div className='loader-box'> <Loader/> </div>}
							{dynParamsContactPerson && !fetchingCP && dynParamsContactPerson.map(renderDynamicFields)}
						</div>
					)}
				</div>
			</div>
			
			<footer className='create-customer-footer'>
				<button type='submit' disabled={isSubmitDisabled} className={cx("btn btn-primary", { disabled: isSubmitDisabled })}>
					<i className='icon icon-check' />
					{t('create')}
				</button>
			</footer>
		</form>
	);
}

export const personConfig = (t, staticData) => {
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, personalDataBlocks, {}, "serviceobject");
	return mergedStaticBlocks;
};

export const objectConfig = (t, staticData, formValues,) => {
	const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, objectDataBlocks, formValues, "serviceobject");
	return mergedStaticBlocks;
};


function mapStateToProps (state, ownProps) {
	const { t, dynConfig, formCode } = ownProps;
	const modalFormData = getModalFormDataFromState(state, formCode);
	// console.log({modalFormData, formBlocks, ownProps, formCode});
	const { custAddress: custAddressBlock, juridicalData: juridicalDataBlock, personalData: personalDataBlock, objectData: objectDataBlock } = modalFormData;
	
	const match = matchPath(window.location.hash.substring(1), {
		path: '/appeals/:appealId/:tab',
		exact: true,
		strict: false
	});
	const formValues = getFormValues('object-form')(state);
	const incomePhoneNumber = state.call.incomePhoneNumber;

	// const formBlocks = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM || {} ;
	// const { custAddress: custAddressBlock, juridicalData: juridicalDataBlock, personalData: personalDataBlock, objectData: objectDataBlock } = formBlocks;

	return {
		formValues,
		match,
		incomePhoneNumber,
		personConfig: personConfig(t, personalDataBlock),
		objectConfig: objectConfig(t, objectDataBlock, formValues),
		config: [...personConfig(t, personalDataBlock), ...objectConfig(t, objectDataBlock, formValues), ...dynConfig],
		newCustomerModalContext: state.ui.newCustomerModalContext,
		custAddressBlock,
		juridicalDataBlock,
		personalDataBlock,
		objectDataBlock,
		modalFormData
	};
}

const mapDispatchToProps = {
	submitForm: form => submit(form),
	getCustomer,
	getContactPerson,
	unlockAppealForm,
	addCustomerToAppeal,
	getAppealFeedback,
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'object-form',
	validate
})(ObjectForm))));
