import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import AddressList from "./AddressList";
import AppealsList from "./AppealsList";
import ContractsList from "./ContractsList";
import TasksList from "./TasksList";
import PersonsList from "./PersonsList";

import SpecList from "./SpecsList/index";

import BillingHouseList from "../BillingLists/BillingHouseList";
import BillingCardsList from "../BillingLists/BillingCardsList";
import BillingFinstatusList from "../BillingLists/BillingFinstatusList";
import BillingPaymentsList from "../BillingLists/BillingPaymentsList";
import BillingRecalculationsList from "../BillingLists/BillingRecalculationsList";

import EventsList from "../EventsList/EventsList";
import HistoryTab from "../../HistoryTab/";

import ProxyList from "./ProxyList/index";

import { extractCustomerFromState } from "../../../helpers";

import "pages/Ordering/styles/styles.scss";

// static grid with type: proprietary
const PROPRIETARY_CONFIG = {
    appeals: AppealsList,
    tasks: TasksList,

    services: SpecList,
    resources: SpecList,

    contracts: ContractsList,
    address: AddressList,
    persons: PersonsList,

    billinghouse: BillingHouseList,
    billingcards: BillingCardsList,
    billingfinstatus: BillingFinstatusList,
    billingpayments: BillingPaymentsList,
    billingrecalculations: BillingRecalculationsList,

    history: HistoryTab,
    events: EventsList,
};

// dynamic grids with type proxyGrid => Dynamic Grid + Filters on the right side
// {
//     "code": "test",
//     "name": "ТЕСТ",
//     "order": 130,
//     "type": "proxyGrid",
//     "params": {
//         "applyCode": "TEST_FI",
//         "source": "test"
//     }
// }

const CODE_MAP = {
    services: "service",
    resources: "resource",
};

function mapStateToProps(state, props) {
    const customer = extractCustomerFromState(state, props.match.params.id || props.id);

    return {
        addressModificationCount: state.ui.addressModificationCount,
        tabContentModificationCount: customer.tabContentModificationCount,
    };
}

function TabContent(props) {
    const {
        history,
        match,
        config,
        itemId,
        itemType,
        id,
        pApartId,
        customerGridRefresh,
        setCustomerGridRefresh,
        openCustomerAddressModal,
        // edited,
        addressModificationCount,
        tabContentModificationCount,
    } = props;

    const [gridRefresh, setGridRefresh] = useState(false);

    const [configCode, tabNum] = config.code.split("_");

    let fixedFilterObject;
    let initSort;
    let gridConfigCode;

    if (tabNum && config.params) {
        fixedFilterObject =
            typeof config.params.filterObject === "string"
                ? JSON.parse(config.params.filterObject)
                : config.params.filterObject;
        initSort = typeof config.params.sort === "string" ? JSON.parse(config.params.sort) : config.params.sort;
        gridConfigCode = config.params.gridConfig;
    }

    const ActiveList = PROPRIETARY_CONFIG[configCode];

    // move that logic to responsible grid
    function onSelectItem(item) {
        switch (configCode) {
            case "persons": {
                history.push(`/customer/${item.currentLink.entityId}/person/${item.id}/contact`);
                break;
            }
            case "appeals": {
                history.push(`/appeals/${item.id}`);
                break;
            }
            case "address":
                openCustomerAddressModal(item);
                break;

            default:
                null;
        }
    }

    const handleCustomerGridRefresh = useCallback(
        value => {
            if (config) {
                setCustomerGridRefresh(configCode, value);
            }
        },
        [config]
    );

    const mainClassName = configCode === "history" ? "contentWrapper" : `customer-ordering-grid ${config.type}`;

    useEffect(() => {
        if (match.params.subTab === "address") {
            setGridRefresh(true);
        }
    }, [addressModificationCount]);

    useEffect(() => {
        setGridRefresh(true);
    }, [tabContentModificationCount]);


    if (config && config.type === "proprietary" && !ActiveList) {
        console.error(`Proprietary list is not found, probably wrong configured customer tab ${configCode}. Usage of "_" allowed only for indixing the tab, for example billingcards_1, billing_cards_1 is not correct`);
        return  <div className='customer-list-not-found-error'>Proprietary List for <strong>{configCode}</strong> ({config.code}) is not found. Make sure to use <strong>_</strong> only for indexing existing tabs.</div>;
    }
    return (
        <div className={mainClassName}>
            {config.type === "proprietary" && (
                <ActiveList
                    selectItem={onSelectItem}
                    gridRefresh={customerGridRefresh[configCode] || gridRefresh}
                    setGridRefresh={customerGridRefresh[configCode] ? handleCustomerGridRefresh : setGridRefresh}
                    itemId={itemId}
                    itemType={itemType}
                    pApartId={pApartId}
                    id={id}
                    code={CODE_MAP[configCode] || configCode}
                    objectType="CUSTOMER"
                    fixedFilterObject={fixedFilterObject}
                    tabNum={tabNum}
                    initSort={initSort}
                    gridConfigCode={gridConfigCode}
                    key={id}
                />
            )}
            {config.type === "proxyGrid" && (
                <ProxyList config={config} id={id} itemId={itemId} itemType={itemType} key={id} />
            )}
        </div>
    );
}

export default withRouter(connect(mapStateToProps, null)(TabContent));
