import i18next from 'util/i18n';
import permissions, { checkPermissions } from 'config/permissions';

export const customerLinks = [
    {
        name: 'dashboard',
        icon: 'dashboard.svg',
        title: i18next.t('navigation.dashboardTitle'),
        description: i18next.t('navigation.dashboardTitle'),
    },
    {
        name: 'customers',
        icon: 'customers.svg',
        title: i18next.t('navigation.customersTitle'),
        description: i18next.t('navigation.customersTitle'),
    },
    {
        name: 'appeal',
        icon: 'appeals.svg',
        title: i18next.t('navigation.appealsTitle'),
        description: i18next.t('navigation.appealsTitle'),
    },
    {
        name: 'orders',
        icon: 'order.svg',
        title: i18next.t('navigation.orders'),
        description: i18next.t('navigation.orders'),
    },
    {
        name: 'knowledge_base',
        icon: 'knowledge_base.svg',
        title: i18next.t('navigation.knowledgeBase'),
        description: i18next.t('navigation.knowledgeBase'),
    },
    {
        name: 'services',
        icon: 'service.svg',
        title: i18next.t('navigation.services'),
        description: i18next.t('navigation.services'),
    },
    {
        name: 'resources',
        icon: 'resource.svg',
        title: i18next.t('navigation.resources'),
        description: i18next.t('navigation.resources'),
    },
    {
        name: 'specification',
        icon: 'specification.svg',
        title: i18next.t('navigation.specification'),
        description: i18next.t('navigation.specification'),
    },
    {
        name: 'issue',
        icon: 'issue.svg',
        title: i18next.t('navigation.issue'),
        description: i18next.t('navigation.issue'),
    },
    {
        name: 'calendar',
        icon: 'calendar.svg',
        title: i18next.t('navigation.calendar'),
        description: i18next.t('navigation.calendar'),
    },
    {
        name: 'bunits',
        icon: 'identity.svg',
        title: i18next.t('navigation.bunits'),
        description: i18next.t('navigation.bunits'),
    },
];

export const settingsLink = {
    name: 'settings',
    icon: 'settings.svg',
    description: '',
    // link: '/settings/controlUsers/domains',
    link: '/settings',

};

export const settingsNavigation = (staticBlocks) => {
    const controlUsers = {
        name: 'controlUsers',
        route: '/settings/controlUsers',
        id: 1,
        exact: true,
        children: [
            {
                name: 'domains',
                route: '/settings/controlUsers/domains',
                id: 101,
            },
            {
                name: 'groups',
                route: '/settings/controlUsers/groups',
                id: 102,
            },
            {
                name: 'users',
                route: '/settings/controlUsers/users',
                id: 103,
            },
            {
                name: 'roles',
                route: '/settings/controlUsers/roles',
                id: 104,
            },
            {
                name: 'bu',
                route: '/settings/controlUsers/bu',
                id: 105,
            },
        ],
    };


    // translations sysCode=CRM|SDSPORTAL|null   
    const translations_CRM = {
        name: 'translations_CRM',
        route: '/settings/translations_CRM/',
        id: 2,
        exact: true,
        children: [
            {
                name: 'uk',
                route: '/settings/translations_CRM/uk',
                id: 201,
            },
            {
                name: 'en',
                route: '/settings/translations_CRM/en',
                id: 202,
            },
        ],
    };

    const translations_SDSPORTAL = {
        name: 'translations_SDSPORTAL',
        route: '/settings/translations_SDSPORTAL/',
        id: 3,
        exact: true,
        children: [
            {
                name: 'uk',
                route: '/settings/translations_SDSPORTAL/uk',
                id: 301,
            },
            {
                name: 'en',
                route: '/settings/translations_SDSPORTAL/en',
                id: 302,
            },
            {
                name: 'pl',
                route: '/settings/translations_SDSPORTAL/pl',
                id: 303,
            },
        ],
    };

    const links = [];
    links.push(controlUsers);

    const isRoleAdmin = checkPermissions(permissions.AdminOperations.role_admin);

    if (isRoleAdmin) {
        // links.push(translations);
        links.push(translations_CRM);
        links.push(translations_SDSPORTAL);

    }

    const isManageStaticBlocks = checkPermissions(permissions.StaticParamOperations.action_manageBlockParam);


    if (isManageStaticBlocks && staticBlocks) {
        const blocks = {
            name: 'blocks',
            route: '/settings/blocks/',
            id: 4,
            exact: true,
            children: staticBlocks.map((block, index) => {
                return {
                    name: block.name,
                    route: `/settings/blocks/${block.code}`,
                    id: 401 + index,
                    isTranslated: true,
                };
            }),
        };
        links.push(blocks);
    }

    return links;
};
