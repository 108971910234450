import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader/index';
import allLocales from '@fullcalendar/core/locales-all';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import { connect } from 'react-redux';
import ModalPortal from '../../../components/ModalPortal';
import MessageModal from '../../../components/MessageModal';
import { CSSTransition } from 'react-transition-group';
import { change, formValueSelector } from 'redux-form';
import moment from 'moment';
import permissions, { checkPermissions } from 'config/permissions';

function CalendarDetail(props) {
    const { eventMode, events, loading, filterRangeDate, eventStartDate, t, change } = props;
    const [modalEventData, setModalEventData] = useState(null);
    const [prevArgStart, setPrevArgStart] = useState('');
    const [prevArgEnd, setPrevArgEnd] = useState('');
    const calendar = useRef(null);
    const history = useHistory();
    const fullCalendar = calendar.current && calendar.current.getApi();
    const calendarTypeView = localStorage.getItem('calendarTypeView');
    let { eid } = useParams();
    const isCanEventsView = checkPermissions(permissions.CalendarOperations.view_calendar_event);

    const headerToolbar = !eventMode && {
        start: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek',
    };

    const filterStartDate = moment(get(filterRangeDate, 'startDate')).format('YYYY-MM-DD HH:MM:SS');
    const filterEndDate = moment(get(filterRangeDate, 'endDate')).format('YYYY-MM-DD HH:MM:SS');
    const eventStart = moment(eventStartDate).isValid()
        ? moment(eventStartDate).format('YYYY-MM-DD')
        : null;

    const { prevFilterStartDate, prevFilterEndDate } = localStorage;

    const initialDate = moment(prevFilterStartDate).isValid()
        ? moment(prevFilterStartDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');

    const datesSet = (args) => {
        const {
            start,
            startStr,
            end,
            endStr,
            view: { type },
        } = args;

        setPrevArgStart(startStr);
        setPrevArgEnd(endStr);

        const startDate = moment(startStr).startOf('day');
        const endDate = moment(endStr)
            .subtract('1', 'day')
            .endOf('day');
        // arg includes data about current visible dates

        // handle type change updates
        if (!loading && type !== calendarTypeView && type !== 'timeGridThreeDay') {
            // console.log('SET PREV DATE');
            localStorage.setItem('prevFilterStartDate', startStr);
            localStorage.setItem('prevFilterEndDate', endStr);
            change({ startDate, endDate });
        }

        // handle initial update
        if (!loading && !prevArgStart && !prevArgEnd && !prevFilterStartDate) {
            // setTimeout(() => {
            // console.log('SET INITIAL DATE');
            change({ startDate, endDate });
            // }, 0);
        }
        const isStartEqual = prevArgStart === startStr;
        const isEndEqual = prevArgEnd === endStr;
        // handle live updates
        if (!loading && prevArgStart && prevArgEnd && !isStartEqual && !isEndEqual) {
            if (type !== 'timeGridThreeDay') {
                // console.log('SET PREV DATE');
                localStorage.setItem('prevFilterStartDate', startStr);
                localStorage.setItem('prevFilterEndDate', endStr);
            }
            // setTimeout(() => {
            change({ startDate, endDate });
            // }, 0);
        }

        // change({ startDate, endDate });
        if (type !== 'timeGridThreeDay') {
            localStorage.setItem('calendarTypeView', type);
        }
    };

    const initialView = eventMode ? 'timeGridThreeDay' : calendarTypeView || 'dayGridMonth';

    const calendarConfig = {
        id: 'fullCalendar',
        showNonCurrentDates: false,
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        eventContent: renderEventContent,
        datesSet,
        locale: 'uk',
        locales: allLocales,
        height: '100%',
        navLinks: true,
        // rerenderDelay: 500,
        // viewDidMount: function(data) {
        // 	let startDate = moment().startOf('month');
        // 	let endDate = moment().endOf('month');

        // 	if (data.view.type !== 'timeGridThreeDay' && prevFilterStartDate && prevFilterEndDate){
        // 		startDate = moment(prevFilterStartDate);
        // 		endDate = moment(prevFilterEndDate).subtract('1', 'day').endOf('day');
        // 	}
        // 	setTimeout(() => !fullCalendar && change({startDate, endDate}), 500);
        // },

        // validRange: { //not correct
        // start: moment().add(10, 'y').format('YYYY-MM-DD'),
        // start: '2020-01-01',
        // end: moment().subtract(10, 'y').format('YYYY-MM-DD')
        // end: '2022-12-31'
        // },
        initialDate,
        initialView,
        view: 'dayGridMonth,timeGridWeek,listWeek,timeGridThreeDay',
        views: {
            timeGridThreeDay: {
                type: 'timeGrid',
                duration: { days: 3 },
            },
        },
        headerToolbar,
        eventClick: (info) => handleEventClick(info),
    };

    // useEffect(() => {
    //     if (fullCalendar) {
    //         let startDate = moment(fullCalendar.getDate()).startOf('month');
    //         let endDate = moment(fullCalendar.getDate()).endOf('month');

    //         if (fullCalendar.view.type !== 'timeGridThreeDay') {
    //             startDate = moment(prevFilterStartDate);
    //             endDate = moment(prevFilterEndDate)
    //                 .subtract('1', 'day')
    //                 .endOf('day');
    //         }
    //         if (!loading) {
    //             setTimeout(() => {
    //                 console.log('useEffect');
    //                 change({ startDate, endDate });
    //             }, 0);
    //         }
    //     }
    // }, [fullCalendar]);

    useEffect(() => {
        if (fullCalendar && moment(prevFilterStartDate).isValid() && !eid) {
            // fullCalendar.changeView('dayGridMonth,dayGridWeek,timeGridDay')
            return fullCalendar.gotoDate(prevFilterStartDate);
        }
    }, []);

    useEffect(() => {
        if (fullCalendar && fullCalendar.view.type === 'timeGridThreeDay' && eventStart) {
            return fullCalendar.gotoDate(moment(eventStart).format('YYYY-MM-DD'));
        }
        if (fullCalendar && moment(filterStartDate).isValid() && !eid) {
            // fullCalendar.changeView('dayGridMonth,dayGridWeek,timeGridDay')
            return fullCalendar.gotoDate(filterStartDate);
        }
    }, [filterStartDate, filterEndDate, eventStart]);

    const handleEventClick = (info, fromListView) => {
        const { event } = info;
        !fromListView && info.jsEvent.preventDefault(); // don't let the browser navigate
        if (!isCanEventsView) return;
        if (event.url) {
            if (event.extendedProps.allowModes && info.event.extendedProps.allowModes.length > 1) {
                return setModalEventData({
                    url: event.url,
                    allowModes: event.extendedProps.allowModes,
                });
            }
            return history.push(`${event.url}/${event.extendedProps.allowModes[0].code}`);
        } else return console.log(event.url);
    };

    const setOpenMode = (mode) => {
        if (!isCanEventsView) return;
        setModalEventData(null);
        return history.push(`${modalEventData.url}/${mode}`);
    };

    const getOpenModesForModal = () => {
        if (modalEventData && modalEventData.allowModes) {
            switch (modalEventData.allowModes.length) {
                case 2:
                    return {
                        primaryButton: true,
                        secondaryButton: true,
                        primaryButtonText: modalEventData.allowModes[0].label,
                        onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
                        secondaryButtonText: modalEventData.allowModes[1].label,
                        onClickSecondaryButton: () =>
                            setOpenMode(modalEventData.allowModes[1].code),
                    };
                case 3:
                    return {
                        dangerButton: true,
                        primaryButton: true,
                        secondaryButton: true,
                        primaryButtonText: modalEventData.allowModes[0].label,
                        onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
                        secondaryButtonText: modalEventData.allowModes[1].label,
                        onClickSecondaryButton: () =>
                            setOpenMode(modalEventData.allowModes[1].code),
                        dangerButtonText: modalEventData.allowModes[2].label,
                        onClickDangerButton: () => setOpenMode(modalEventData.allowModes[2].code),
                    };
            }
        }
    };

    function renderEventContent(eventInfo) {
        const { timeText, event } = eventInfo;
        const style = { textOverflow: 'ellipsis', overflow: 'hidden' };
        return (
            <>
                {timeText && <b>{timeText} &nbsp;</b>}
                <p
                    style={style}
                    title={event.title}
                    onClick={() => handleEventClick(eventInfo, true)}
                >
                    {event.title}
                </p>
            </>
        );
    }

    return (
        <>
            {loading && <Loader />}
            <FullCalendar {...calendarConfig} events={events} ref={calendar} />
            <CSSTransition
                in={modalEventData}
                classNames="fade"
                appear
                enter
                exit
                timeout={300}
                mountOnEnter
                unmountOnExit
            >
                <ModalPortal
                    onClose={() => setModalEventData(null)}
                    className="modal-small modal-center"
                >
                    <MessageModal
                        titleModal={t('calendar.askEventModalTitle')}
                        contentModalText={t('calendar.askEventModalDescription')}
                        {...getOpenModesForModal()}
                    />
                </ModalPortal>
            </CSSTransition>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        events: get(state, 'calendar.data'),
        loading: get(state, 'calendar.dataLoading'),
        filterRangeDate: formValueSelector('calendar-filter-form')(
            state,
            'CLEVENT_RANGE_START_DATE',
        ),
        eventStartDate: get(state, 'calendar.openedEvent.start'),
    };
};

const mapDispatchToProps = (dispatch) => ({
    change: (value) => dispatch(change('calendar-filter-form', 'CLEVENT_RANGE_START_DATE', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarDetail));
