import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

function InputLabel ({ readOnly, label, onAddClick, loading = false, isTouched }) {
	const addButtonCondition = !readOnly && onAddClick;
	const labelView = i18next.exists(`customerInfoLabel.${label}`) ? i18next.t(`customerInfoLabel.${label}`) : label;
	return (
		<header className='header'>
			<label className='label'>
				{labelView}
			</label>
			{
				addButtonCondition && !(loading && isTouched) &&
				<button className='add-button' onClick={onAddClick}>
					{i18next.t('add')}
				</button>
			}
		</header>
	);
}

InputLabel.propTypes = {
	label: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
	onAddClick: PropTypes.func
};

export default InputLabel;
