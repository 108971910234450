import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, initialize } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cx from 'classnames';

import InputField from 'components/Common/InputField';
import validate from './validate';
import { reduxFormWrapper, isOrganization, isServiceobjectType } from 'helpers';
import { KeyCodes } from '../../../constants';

function EditCustomerNameForm(props) {
	const wrapperRef = useRef(null);
	let lastNameRef, firstNameRef, patronymicRef;
	// const [isInEdit, setIsInEdit] = useState(false);
	const {
		t, customerType, managedContactPerson,
		isInEdit, setIsInEdit,
		initialValues: { lastName, firstName, patronymic, name },
	} = props;
	
	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);
		
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
			props.reset();
			setIsInEdit(false);
			document.activeElement.blur();
		};
	}, []);
	
	useEffect(() => {
		props.reset();
		setIsInEdit(false);
		document.activeElement.blur();
	}, [name]);
	
	useEffect(() => {
		if (isInEdit){
			props.initialize({ lastName, firstName, patronymic, name })
		}
	}, [isInEdit]);
	
	const startEditHandler = () => {
		if (props.managedContactPerson) {
			setIsInEdit(true);
		}
	};
	
	const submitForm = (event) => {
		event.preventDefault();
		if (props.valid) {
			setIsInEdit(false);
			props.submit(props.handleSubmit);
		}
	};
	
	const handleKeyPress = (e) => {
		if (e.keyCode === KeyCodes.ESCAPE) {
			props.reset();
			setIsInEdit(false);
			document.activeElement.blur();
		} else if (e.keyCode === KeyCodes.ENTER) {
			// TODO: remove that block if nothing is broken
			// document.activeElement.blur();
		}
	};
	
	const onPatronymicKeyDown = (e) => {
		if (e.keyCode === KeyCodes.TAB) {
			e.preventDefault();
			if (lastName && lastName.focus) {
				lastName.focus();
			}
		} else if (e.keyCode === KeyCodes.BACKSPACE && !e.target.value) {
			if (firstName && firstName.focus) {
				firstName.focus();
			}
		}
	};
	
	const onFirstNameKeyDown = (e) => {
		if (e.keyCode === KeyCodes.BACKSPACE && !e.target.value) {
			e.preventDefault();
			if (lastName && lastName.focus) {
				lastName.focus();
			}
		}
	};
	
	return (
		<form
			onSubmit={submitForm}
			className='form-inline margin-right'
			ref={wrapperRef}
			onFocus={startEditHandler}
		>
			{!isInEdit && (
				<div onClick={startEditHandler}
					 className={cx('contact-full-name', { disabled: !managedContactPerson })}>
					{(isOrganization(customerType) || isServiceobjectType(customerType)) ? name : `${lastName || ''} ${firstName || ''} ${patronymic || ''}`}
				</div>
			)}
			{isInEdit && (isOrganization(customerType) || isServiceobjectType(customerType)) && (
				<Fragment>
					<Field
						component={InputField}
						name={'name'}
						additionalInputClassName='headerOfficialName'
						label={t('customerInfoLabel.officialName')}
						fullWidth
						autoComplete='off'
						autoFocus={true}
						errorPlaceholder
						maxLength='255'
					/>
				</Fragment>
			)}
			{isInEdit && (!isOrganization(customerType) && !isServiceobjectType(customerType)) && (
				<Fragment>
					<Field
						component={InputField}
						name={'lastName'}
						label={t('customerInfoLabel.lastName')}
						additionalInputClassName='headerLastName'
						autoComplete='off'
						autoFocus={true}
						errorPlaceholder
						maxLength='50'
						setFieldToBeFocused={ref => {lastNameRef = ref;}}
					/>
					<Field
						component={InputField}
						name={'firstName'}
						additionalInputClassName={'headerFirstName'}
						label={t('customerInfoLabel.firstName')}
						autoComplete='off'
						errorPlaceholder
						maxLength='50'
						onKeyDown={onFirstNameKeyDown}
						setFieldToBeFocused={ref => {firstNameRef = ref;}}
					/>
					<Field
						component={InputField}
						name={'patronymic'}
						additionalInputClassName={'headerPatronymic'}
						label={t('customerInfoLabel.patronymic')}
						autoComplete='off'
						errorPlaceholder
						maxLength='50'
						onKeyDown={onPatronymicKeyDown}
						setFieldToBeFocused={ref => {patronymicRef = ref;}}
					/>
				</Fragment>
			)}
			{isInEdit && (
				<button className='btn-save'>
					<i className='icon-check' />
					{t('save')}
				</button>
			)}
		</form>
	);
}

function mapStateToProps (state, props) {
	const selector = formValueSelector(props.form);
	
	return {
		values: selector(state, 'lastName', 'firstName', 'patronymic', 'name') || {}
	};
}

export default reduxFormWrapper({
	validate,
	enableReinitialize: true,
	destroyOnUnmount: false,
	keepDirtyOnReinitialize: false,
})(
	connect(mapStateToProps)(withTranslation()(EditCustomerNameForm))
);

EditCustomerNameForm.propTypes = {
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	submit: PropTypes.func,
	handleSubmit: PropTypes.func,
	customerType: PropTypes.string
};
