import React from 'react';

import {
	EMAILS_REQUEST,
	EMAILS_REQUEST_SUCCESS,
	EMAILS_REQUEST_FAILURE,
	TOGGLE_ALL_MAILS,
	UPLOAD_EMAIL_FILE_SUCCESS,
	UPLOAD_EMAIL_FILE_FAILURE,
	CREATE_NEW_EMAIL_SUCCESS,
	UPLOAD_EMAIL_FILE_LIST_SUCCESS,
	SET_FILES_LENGTH,
	CREATE_NEW_EMAIL_REQUEST,
	SEND_EMAIL_SUCCESS,
	RESET_EMAIL_FORM,
	GET_TEMPLATE_LIST_SUCCESS,
	UPLOAD_EMAIL_FILE_REQUEST,
	OPEN_EMAIL,
	OPEN_EMAIL_SUCCESS,
	EMAILS_SEARCH_QUERY_UPDATE,
	APPEAL_MAILING_RESET,
	SET_MAIL_BODY,
	SET_REPLY_MAIL_BODY,
	SET_MAIL_STATE,
	SET_SIGNATURE,
	CHANGE_EDIT_SIGN_MODE,
	UPDATE_SIGNATURE_LIST,
} from 'constants/actions';
import {
	fromHtml,
	toHtml,
	forwardTemplate,
	replyTemplate,
	addSignature,
	changeSignature,
	convertFromHTML,
	extendedBlockRenderMap,
	EMAILREPLY,
	EMAILREPLYALL,
	EMAILFORWARD,
	EMAILNEW
} from '../pages/Appeal/Mailing/utils';

const emailState = {
	requestedForAppeal: '',
	
	emails: [],
	loading: false,
	query: '',
	
	toggleAllMails: false,
	attachment: [],
	errorUpload: null,
	filesCount: 0,
	filesLength: 0,
	mailBody: null,
	replyMailBody: null,
	signature: null,
	signatureView: '',
	signatureList: [],
	formFields: null,
	sendEmailSuccess: false,
	upload: false,
	uploadingFile: false,
	templateList: [],
	shouldOpenEmail: null,
	sendFormOpening: false,
	forward: false,
	standartView: false,
	minimized: false,
	maximized: false,
};

const initialState = {
	emails: {},
	isEditSignMode: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		
		/********  Emails request  ********/
	
	case EMAILS_REQUEST:
		return setDataToEmail(action.id, state, {
			loading: true, requestedForAppeal: action.id
		});
	
	case EMAILS_REQUEST_SUCCESS:
		return setDataToEmail(action.id, state, {
			emails: action.emails, loading: false
		});
	
	case EMAILS_REQUEST_FAILURE:
		return setDataToEmail(action.id, state, {
			emails: [], loading: false
		});
		
		/********  Emails search query update  ********/
	
	case EMAILS_SEARCH_QUERY_UPDATE:
		return setDataToEmail(action.id, state, {
			query: action.query
		});
		
		/********  Emails reset  ********/
	
	case APPEAL_MAILING_RESET:
		const newState = { ...state };
		
		delete newState.emails[action.id];
		
		return newState;
		
		/********************************/
	
	case TOGGLE_ALL_MAILS:
		return setDataToEmail(action.id, state, {
			toggleAllMails: !state.emails[action.id].toggleAllMails
		});
	
	case UPLOAD_EMAIL_FILE_REQUEST:
		return setDataToEmail(action.id, state, {
			uploadingFile: true
		});
	
	case UPLOAD_EMAIL_FILE_FAILURE:
		return setDataToEmail(action.id, state, {
			errorUpload: action.error
		});
	
	case SET_SIGNATURE: {
		let sign = '';
		const oldSign = state.emails[action.id].signatureView;
		const userSignature = state.emails[action.id].signatureList.map( conf => {
			if (conf.id === action.signId){
				sign = conf.value;
				return {...conf, active: true }
			}
			return {...conf, active: false};
		});
		
		const maillBodyState = state.emails[action.id].mailBody;
		
		return setDataToEmail(action.id, state, {
			signatureView: sign,
			signatureList: userSignature,
			mailBody: changeSignature({mailBody: maillBodyState, oldSign, sign})
		});
	}
	
	case UPDATE_SIGNATURE_LIST: {
		const { userSignature } = action;
		let sign = '';
		const oldSign = state.emails[action.id].signatureView;
		const maillBodyState = state.emails[action.id].mailBody;

		const signatureList = userSignature.map(item => {
			if(item.byDefault){
				sign = item.value;
				return {...item, active: true}
			}
			return {...item, active: false}
		});
		
		return setDataToEmail(action.id, state, {
			signatureView: sign,
			signatureList: signatureList,
			mailBody: changeSignature({mailBody: maillBodyState, oldSign, sign})
		});
	}
	
	case CREATE_NEW_EMAIL_SUCCESS: {
		let emptyBlock = false;
		let sign = '';
		const { template, attachments, userSignature = [] } = action.payload;
		const signatureList = userSignature.map(item => {
			if(item.byDefault){
				sign = item.value;
				return {...item, active: true}
			}
			return {...item, active: false}
		});
		let mailBody = template.messageBodyTemplate;
		let replyMailBody = "";
		
		if (sign) mailBody += addSignature(sign);
		let formFields = { subject: '', to: [], copy: [] };
		
		if (EMAILNEW === action.action) {
			formFields = {
				...formFields,
				subject: template.messageSubjectTemplate,
			};
		}
		
		if (EMAILREPLY === action.action) {
			// emptyBlock = true;
			mailBody += replyTemplate(action.email);
			replyMailBody += action.email.mailBody;
			
			formFields = {
				...formFields,
				subject: action.email.subject ?
					`Re: ${action.email.subject} ${template.messageSubjectTemplate}` :
					template.messageSubjectTemplate,
				to: (action.email.emailType === 'outgoing' ? action.email.to : [action.email.from]) || [],
				copy: [],
			};
		}
		
		if (EMAILREPLYALL === action.action) {
			// emptyBlock = true;
			mailBody += replyTemplate(action.email);
			replyMailBody += action.email.mailBody;
			
			const copyTo = [...action.email.to, ...action.email.copyTo].filter((mail) => mail !== action.email.mailbox);
			
			formFields = {
				...formFields,
				subject: action.email.subject ? `Re: ${action.email.subject} ${template.messageSubjectTemplate}` : template.messageSubjectTemplate,
				to: (action.email.emailType === 'outgoing' ? action.email.to : [action.email.from]) || [],
				copy: (action.email.emailType === 'outgoing' ? action.email.copyTo : copyTo) || []
			};
		}
		
		if (action.action === EMAILFORWARD) {
			mailBody += forwardTemplate(action.email);
			
			formFields = {
				...formFields,
				subject: `Fwd: ${action.email.subject || ""} ${template.messageSubjectTemplate}`
			};
		}
		
		return setDataToEmail(action.id, state, {
			newEmailId: action.payload.interactionId,
			mailBody: fromHtml(mailBody, emptyBlock, action.action === EMAILFORWARD),
			replyMailBody,
			signature: template.messageSignatureTemplate,
			signatureView: sign,
			signatureList,
			attachment: attachments,
			formFields
		});
	}
	
	case UPLOAD_EMAIL_FILE_LIST_SUCCESS: {
		return setDataToEmail(action.id, state, {
			attachment: [...action.payload],
			filesCount: 0,
			filesLength: 0,
			upload: false,
			uploadingFile: false
		});
	}
	
	case UPLOAD_EMAIL_FILE_SUCCESS: {
		return setDataToEmail(action.id, state, {
			upload: true,
			filesCount: state.emails[action.id].filesCount + 1
		});
	}
	
	case SET_FILES_LENGTH:
		return setDataToEmail(action.id, state, {
			filesLength: action.payload, filesCount: 0
		});
	
	case CREATE_NEW_EMAIL_REQUEST: {
		return setDataToEmail(action.id, state, {
			formFields: null,
			attachment: [],
			mailBody: null
		});
	}
	
	case SEND_EMAIL_SUCCESS:
		return setDataToEmail(action.id, state, {
			sendEmailSuccess: action.payload
		});
	
	case RESET_EMAIL_FORM: {
		return setDataToEmail(action.id, state, {
			sendEmailSuccess: false,
			mailBody: null,
			attachment: [],
			formFields: null
		});
	}
	
	case GET_TEMPLATE_LIST_SUCCESS: {
		const options = action.payload.map(item => ({
			value: item.templateId,
			templateCode: item.templateCode,
			label: item.templateName,
		}));
		
		return setDataToEmail(action.id, state, {
			templateList: options
		});
	}
	
	case SET_MAIL_BODY: {
		return setDataToEmail(action.id, state, {
			mailBody: action.body,
		});
	}

	case SET_REPLY_MAIL_BODY: {
		return setDataToEmail(action.id, state, {
			replyMailBody: action.replyMailBody,
		});
	}
	
	case SET_MAIL_STATE: {
		return setDataToEmail(action.id, state, {
			...action.state,
		});
	}
	
	case OPEN_EMAIL:
		return setDataToEmail(action.id, state, {
			shouldOpenEmail: { id: action.payload.id }
		});
	
	case OPEN_EMAIL_SUCCESS:
		return setDataToEmail(action.id, state, {
			shouldOpenEmail: null
		});
		
		case CHANGE_EDIT_SIGN_MODE:
		return { ...state, isEditSignMode: !state.isEditSignMode };
	
	default:
		return state;
	}
};

function setDataToEmail (id, state, mailData, data = {}) {
	return {
		...state,
		...data,
		emails: {
			...state.emails,
			[id]: { ...emailState, ...state.emails[id], ...mailData },
		},
	};
}
