import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';
import TextArea from 'components/Common/TextArea';
import isNull from 'lodash/isNull';
import moment from 'moment';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
// import baseService from '../services/BaseService';
import { UABET } from '../config/conf';

import i18n from 'util/i18n';

export const KnowledgeItemTypes = {
    LEAF: 'leaf',
    FOLDER: 'folder',
};

export const StreetModes = {
    MANUAL: 'manual',
    AUTO: 'auto',
};

export const FieldTypes = {
    INPUT: 'input',
    ARRAY: 'array',
    SELECT: 'select',
    SELECT_POPUP_ARRAY: 'selectPopupArray',
    DATE: 'date',
    ADDRESS: 'address',
};

export const languages = [
    { label: 'Українська', value: 'UA' },
    { label: 'English', value: 'EN' },
    { label: 'Русский', value: 'RU' },
    { label: 'Не визначено', value: 'NA' },
];


let newContactPersonConfig = [
    { name: 'lastName', label: 'Прізвище', component: InputField, maxLength: 50 },
    { name: 'firstName', label: 'Ім\'я', component: InputField, required: true, maxLength: 50, errorPlaceholder: true },
    { name: 'patronymic', label: 'По батькові', component: InputField, maxLength: 50 },
    { name: 'workPhone', label: 'Телефон', component: InputField, required: true, maxLength: 19 },
    { name: 'email', label: 'Email', component: InputField, maxLength: 255 },
    { name: 'issues', label: 'Характеристика', component: ComboBox, required: true, multi: true }
];

const addressConfig = [
    {
        name: 'settlement',
        label: 'Населений пункт',
        component: ComboBox,
        required: true,
        searchable: true,
    },
    {
        name: 'street',
        label: 'Вулиця',
        component: ComboBox,
        required: true,
        searchable: true,
		inputProps: { maxLength: 255 },
		maxLength: 255
    },
    {
        name: 'buildingNumber',
        label: 'Будинок',
        component: ComboBox,
        required: true,
        searchable: true,
        inputProps: { maxLength: 16 },
		maxLength: 16
    },
    {
        name: 'buildingSecondNumber',
        label: 'Корпус',
        component: InputField,
        errorPlaceholder: true,
        maxLength: 32,
    },
    {
        name: 'apartmentNumber',
        label: 'Квартира/Офіс',
        component: InputField,
        errorPlaceholder: true,
        maxLength: 32,
    },
    {
        name: 'zipCode',
        label: 'Індекс',
        component: ComboBox,
        required: true,
        searchable: true,
        inputProps: { maxLength: 5 },
		maxLength: 5
    },
    {
        name: 'description',
        label: 'Коментар',
        component: TextArea,
        autosize: true,
        maxLength: 500,
        textAreaClassName: 'description',
    },
];

const typeAddress = { name: 'type', label: 'Тип адреси', component: ComboBox, required: true };


export const appealHistoryFilters = [
    { label: 'Уся історія', value: 0 },
    { label: 'Коментарі', value: 1 },
    { label: 'Мої зміни', value: 2 },
];

// export const advancedSearchClientFormConfig = [
//     {
//         name: 'query', label: 'ПІБ/Назва організації', type: 'input', placeholder: 'Не вибрано', maxLength: '255',
//     },
//     {
//         name: 'address', label: 'Адреса', type: 'input', placeholder: 'Не вибрано', maxLength: '255',
//     },
//     {
//         name: 'phone', label: 'Телефон заявника', type: 'input', placeholder: 'Не вибрано', maxLength: '15',
//     },
//     {
//         name: 'email', label: 'Email', type: 'input', placeholder: 'Не вибрано', maxLength: '255',
//     },
// ];

const categoryFormatter = value => {
    if (value && Array.isArray(value)) {
        return value.map(category => (isNull(category.id) ? '0' : category.id.toString()));
    }
    return [];
};

const dateFormatter = value => (value ? moment(new Date(value)) : '');

let customerFormConfigCommon = [
    {
        type: 'date', name: 'birthDate', field: 'party.birthDate', label: 'birthDate', popperPlacement:'bottom-end',
    },
    {
        type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
    },
    {
        type: 'array', name: 'homePhone', field: 'contacts.homePhone', label: 'homePhone', required: true,
    },
    {
        type: 'array', name: 'email', field: 'contacts.email', label: 'email',
    },
    {
        type: 'select',
        name: 'socialStatusName',
        field: 'party.socialStatusName',
        valueField: 'party.socialStatusId',
        label: 'socialStatusName',
        options: 'socialStatusName',
    },
    {
        type: 'selectPopupArray',
        name: 'custCategory',
        field: 'party.cust_category',
        label: 'custCategory',
        options: 'custCategory',
        formatter: categoryFormatter,
    },
    {
        type: 'date', name: 'since', field: 'since', label: 'since', disabled: true, formatter: dateFormatter,
    },
    {
        type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
    },
];

let serviceObjectEntityFormConfigCommon = [
	// {
	// 	type: 'select',
	// 	name: 'serviceobjectType',
	// 	field: 'party.serviceobjectType',
	// 	label: 'businessType',
	// 	options: 'serviceobjectType',
	// },
	{
		type: 'input', name: 'externalId', field: 'externalId', label: 'externalId', maxLength: 255, disabled: true,
	},
	{
		type: 'select', name: 'status', field: 'status', label: 'serviceObjectStatus', options: 'customerStatus', disabled: true,
	},
	{
		type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
	},
];
let customerEntityFormConfigCommon = [
    {
        type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
    },
    {
        type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
    },
];

export const personFormConfig = [
    {
        type: 'array', name: 'email', field: 'email', label: 'email',
    },
    {
        name: 'issues',
        label: 'issues',
        field: 'issues',
        multi: true,
        type: 'select',
        errorPlaceholder: true,
        submitOnBlur: true,
        options: 'contactIssue'
    },
    {
        type: 'array', name: 'workPhone', field: 'workPhone', label: 'workPhone', required: true,
    },
    {
        type: 'date',
        name: 'validFrom',
        field: 'validFrom',
        label: 'validFrom',
        disabled: true,
        formatter: dateFormatter,
    },
    {
        type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
    },
];

/**  Call constants  * */

export const SseEvents = {
    RINGING: 'ringing',
    ANSWER: 'answer',
    HANGUP: 'hangup',
    NOT_READY: 'user_state.not_ready',
    READY: 'user_state.ready',
    LOGOUT: 'user_state.logout',
    RESERVED: 'user_state.reserved',
    TALKING: 'user_state.talking',
    UNAVAILABLE: 'unavailable',
    RESTORED: 'restored',
    WORK: 'user_state.work',
    WORK_READY: 'user_state.work_ready',
    HOLD: 'user_state.hold',
    DISCONNECTION: 'server.DISCONNECTION',
    ALIVE: 'server.alive',
    HELLO: 'hello',
    NOTIFICATION: 'notification',
    ERROR: 'error',
    WARNING: 'warning',
    PONG: 'pong',
    INSTMESS: 'instmess'
};

export const CallStates = {
    DEFAULT: 'default',
    RINGING: 'ringing',
    TALKING: 'talking',
    WAITING: 'waiting',
    ALIVE: 'alive',
    WAITING_WITH_COUNTER: 'waiting_with_counter',
};

export const HoldStatuses = {
    ON: 'on',
    OFF: 'off',
    DISABLED: 'disabled',
};

export const playPauseIcons = {
    [HoldStatuses.ON]: faPlay,
    [HoldStatuses.OFF]: faPause,
};

/** **************** */

export const HistoryItemTypes = {
    CHANGE: 'change',
    EMAIL: 'email',
	MESSAGE: 'message',
    COMMENT: 'comment',
    NOTIFICATION: 'notification',
	COLLECTION: 'collection',
    CALL: 'call',
    PRECRM: 'precrm',
    SELF_CARE: 'self_care',
};

export const EmailTypes = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
};

export const FileTypes = {
    HTML: 'html',
    PDF: 'pdf',
    DOCX: 'docx',
    XLS: 'xls'
};

export const NotificationTypes = {
    NEW_APPEAL: 'NEW_APPEAL',
    APPEAL_CHANGE_SUCCESS: 'APPEAL_CHANGE_SUCCESS',
    APPEAL_CHANGE_FAILURE: 'APPEAL_CHANGE_FAILURE',
    CURRENT_APPEAL_CHANGE: 'CURRENT_APPEAL_CHANGE',
    NEW_COMMENT: 'NEW_COMMENT',
    HTTP_ERROR: 'HTTP_ERROR',
    SUCCESS: 'SUCCESS',
    SSE_NOTIFICATION: 'SSE_NOTIFICATION',
    SSE_ERROR: 'SSE_ERROR',
    SSE_WARNING: 'SSE_WARNING',
};

// export const getFileBase64 = (file, callback) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     // Since FileReader is asynchronous,
//     // we need to pass data back.
//     reader.onload = () => callback(reader.result);
//     // TODO: catch an error
//     reader.onerror = error => {};
//   };
  

export const editorToolbar = {
	defaultLanguage:'uk',
	language:'uk',
	extraPlugins: 'colorbutton,colordialog,font',
	removeButtons: 'Anchor',
	extraAllowedContent: '*(*)',
    allowedContent:true,
    enterMode: 2,
    image: {
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: false,
        // uploadCallback: (file, appealId) => new Promise((resolve) => {
        //     const params = {
        //         data: {
        //             data: {
        //                 interactionId: appealId,
        //                 fileName: file.name
        //             },
        //             file
        //         },
        //         file: true
        //     };
        //     return baseService.post('upload_file_email', params)
        //         .then(data => data.result)
        //         .catch(err => console.log({err}));

        //     // return getFileBase64(file, data => resolve({ data: { link: data } }));
        // }),
        previewImage: true,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: true, mandatory: false },
        defaultSize: {
          height: 'auto',
          width: '542px',
        },
      },
	toolbarGroups: [{
		"name": "basicstyles",
		"groups": ["basicstyles"]
	},
		{
			"name": "links",
			"groups": ["links"]
		},
		{
			"name": "paragraph",
			"groups": ["list", "blocks"]
		},
		{
			"name": "document",
			"groups": ["mode"]
		},
		{
			"name": "insert",
			"groups": ["insert"]
		},
			{ name: 'colors' },
			{ name: 'tools' },
			{ name: 'others' },
		{
			"name": "styles",
			"groups": ["styles"]
		},
	],
};

export const MAX_TABS_COUNT = 100;
export const MAX_TABS_IN_HISTORY = 5;
export const MAX_SHOW_HIDDEN_TABS = 8;
export const FORM_SAVE_CHANGES_INTERVAL = 500;
export const FORM_SAVE_CHANGES_LIMIT = 5;
export const MAX_FILE_SIZE = 25;
export const DEFAULT_NOTIFICATION_TIMEOUT = 2000;

export const GET_MAX_FILE_SIZE_FROM_DICT = (key, language = 'uk') => {
    const i18data = i18n.getDataByLanguage(language);
    const fileSizes = i18data && i18data.dictionary && i18data.dictionary.fileSize;
    const maxFileSize = fileSizes && parseInt(fileSizes[key]);
    const maxFileSizeFallback = fileSizes && parseInt(fileSizes.max) || MAX_FILE_SIZE;
    // return either received maxFileSize or the fallback one (the 'max' key from dict, or hardcoded MAX_FILE_SIZE constant)
    return [null, undefined, ''].includes(maxFileSize) ? maxFileSizeFallback : maxFileSize;
};

export const CustomerTypes = {
    NATURAL_PERSON: 'individual',
    ORGANIZATION: 'organization',
    SERVICEOBJECT: 'serviceobject',
};

export const KeyCodes = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CONTROL: 17,
    ALT: 18,
    ESCAPE: 27,
};

export const settingsHeaderMenuOptions = [
    { title: 'sync', value: 'sync' },
    { title: 'parametersFilter', value: 'parametersFilter' },
];

export const settingsHeaderMenuDomainOptions = [
    { title: 'sync', value: 'sync' },
];

if (ENVIRONMENT === UABET) {
	newContactPersonConfig[0] = {
		...newContactPersonConfig[0], required: false,
	};
    
    customerFormConfigCommon = [
		 
        {
            type: 'date', name: 'birthDate', field: 'party.birthDate', label: 'birthDate', popperPlacement:'bottom-end',
        },
        {
            type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
        },
        {
            type: 'array', name: 'homePhone', field: 'contacts.homePhone', label: 'homePhone', required: true,
        },
        {
            type: 'array', name: 'email', field: 'contacts.email', label: 'email',
        },
        {
            type: 'select', name: 'status', field: 'status', label: 'customerStatus', options: 'customerStatus', disabled: true,
        },
        {
            type: 'date', name: 'externalDate', field: 'externalDate', label: 'externalDate',
        },
        {
            type: 'date', name: 'since', field: 'since', label: 'since', disabled: true,
        },
        {
            type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
        },
    ];
    
    
    customerEntityFormConfigCommon = [
        {
            type: 'address', name: 'primaryAddress', field: 'primaryAddress', label: 'primaryAddress',
        },
        {
            type: 'input', name: 'id', field: 'id', label: 'id', disabled: true,
        }
    ];
}

export const customerAddressFormConfig = addressConfig;

export const createNewContactPersonConfig = newContactPersonConfig;

export const customerAddressFormConfigWithType = [ typeAddress, ...addressConfig ];

export const customerFormConfig = customerFormConfigCommon;

export const customerServiceFormConfig = serviceObjectEntityFormConfigCommon;

export const customerEntityFormConfig = customerEntityFormConfigCommon;

// export const mailEditorToolbar = {
// 	options: ['fontFamily', 'blockType', 'fontSize', 'inline', 'textAlign', 'list'],
// 	fontFamily: {
// 		options: ['Comic Sans MS', 'Garamond', 'Georgia', 'Tahoma', 'Trebuchet MS', 'Verdana'],
// 		className: 'draftBarDropdown',
// 	},
// 	blockType: {
// 		options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Section'],
// 		className: 'draftBarDropdown',
// 	},
// 	fontSize: {
// 		className: 'draftBarDropdown'
// 	},
// 	inline: {
// 		options: ['bold', 'italic', 'underline', 'strikethrough'],
// 		className: 'draftBarIcon',
// 	},
// 	textAlign: {
// 		inDropdown: true,
// 		options: ['left', 'center', 'right'],
// 		className: 'draftBarIcon',
// 	},
// 	list: {
// 		inDropdown: false,
// 		options: ['ordered', 'unordered', 'indent', 'outdent'],
// 		className: 'draftBarIcon',
// 	},
// };

export const LS_TAB_HISTORY_KEY = "tabs_history";
