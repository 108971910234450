import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import moment from 'moment';
import get from 'lodash/get';
import baseService from 'services/BaseService';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { showAudioPlayer } from 'actions/audioplayer';
import { setAudioTrack } from 'actions/audioplayer';
import permissions, { checkPermissions } from 'config/permissions';

const mapStateToProps = state => ({
    audioSource: state.audioplayer.source,
    audioTitle: state.audioplayer.title,
    operations: state.user.operations,
    recordListening: checkPermissions(permissions.CallOperations.record_listening),
});

const mapDispatchToProps = dispatch => ({
    showAudioPlayer: () => dispatch(showAudioPlayer()),
    setAudioTrack: audio => dispatch(setAudioTrack(audio))
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CallItem extends React.Component {
    
    state = {
        audioFile: null,
        createdAppeals: [],
        isCallRecordExist: false
    };
    
    componentDidMount () {
        this.getCreatedAppeals();
        this.getCallRecord();
    }
    
    getCreatedAppeals = () => {
        const params = {
            data: {
                interactionId: this.props.data.interactionid
            }
        };
        
        return baseService.post('interaction_appeal', params)
            .then(response => {
                if (response.success) {
                    this.setState({ createdAppeals: response.result });
                }
            })
            .catch(console.error);
    };
    
    getCallRecord = () => {
        const params = {
            data: {
                interaction_id: this.props.data.interactionid
            }
        };
        
        if (this.props.recordListening) {
            return baseService.get('appeal_interaction_proof', params)
                .then(response => {
                    if (response.success && response.result && response.result.length) {
                        this.setState({ audioFile: response.result[0] });
                    }
                })
                .catch(console.error);
        }
    };
    
    onPlay = () => {
        const { rootURL, title } = this.state.audioFile;
        this.props.setAudioTrack({ source: rootURL, title });
        this.props.showAudioPlayer();
    };
    
    dateParser = value => moment(value).format('DD.MM.YYYY HH:mm:ss');
    
    durationParser = value => {
        const { t } = this.props;
        const duration = moment.duration(value);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        
        const result = [];
        hours && result.push(`${hours} ${t('appealHistory.hours')}`);
        minutes && result.push(`${minutes} ${t('appealHistory.minutes')}`);
        result.push(`${seconds} ${t('appealHistory.seconds')}`);
        
        return result.join(' ');
    };
    
    getConfig = () => {
        const { t } = this.props;
        return [
            { title: t('appealHistory.beginning'), path: 'startDate', parser: this.dateParser },
            { title: t('appealHistory.numberFrom'), path: 'originator.originator' },
            { title: t('appealHistory.numberTo'), path: 'operatorPhone' },
        ];
    };
    
    renderAppeal = ({ id, num, theme }) => {
        return (
            <div className='created-appeal' key={id}>
                <Link to={`/appeals/${id}/`}>{num}</Link>
                <div className='appealContent'>{theme}</div>
            </div>
        );
    };
    
    renderRow = ({ title, path, parser }) => {
        const { data } = this.props;
        const value = get(data, path);
        return (
            <div className='notification-row' key={path}>
                <span className='row-title'>{title}:</span>
                <div className='row-content'>{parser ? parser(value) : value}</div>
            </div>
        );
    };
    
    renderAudio = () => {
        const { audioFile } = this.state;
        if (audioFile.type === 'link') {
            
            return <a className='linkToFile'
                      href={audioFile.rootURL}
                      target="_blank">
                <div className='audioFile'>
                    <button className='playButton'>
                        <i className='icon-circle-play'/>
                    </button>
                    <span className='fileName'>{audioFile.title}</span>
                </div>
            </a>;
        } else {
            return <div className='audioFile'>
                <button className='playButton' onClick={this.onPlay}>
                    <i className='icon-circle-play'/>
                </button>
                
                <span className='fileName'>{audioFile.title}</span>
                
                <a className='saveButton' href={audioFile.rootURL}
                   target="_blank" download={audioFile.title}>
                    <i className='icon-save'/>
                </a>
            </div>;
        }
    };
    
    render () {
        const { createdAppeals, audioFile } = this.state;
        const { t, recordListening } = this.props;
        
        return (
            <li className='history-item'>
                <i className='icon-call'/>
                <div className='history-item-content'>
                    <span className='history-title-text'>{t('appealHistory.processings')}</span>
                    
                    <div className='callInfo'>
                        {this.getConfig().map(this.renderRow)}
                    </div>
                    
                    {
                        createdAppeals.length > 0 &&
                        <div className='call-section'>
                            <span className='section-title'>{t('appealHistory.sessionAppeals')}:</span>
                            {createdAppeals.map(this.renderAppeal)}
                        </div>
                    }
                    
                    {
                        (audioFile && recordListening)
                        && this.renderAudio.bind(this)()
                    }
                
                </div>
            </li>
        );
    }
}

CallItem.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
        operatorName: PropTypes.string,
        originator: PropTypes.shape({
            channel: PropTypes.string,
            originator: PropTypes.string,
            channelName: PropTypes.string
        }),
        employee: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        duration: PropTypes.number,
        interactionid: PropTypes.number,
        
        callRecord: PropTypes.shape({
            rootURL: PropTypes.string,
            title: PropTypes.string,
            type: PropTypes.string,
            uniqueid: PropTypes.string
        })
    }),
    highlight: PropTypes.func
};

export default CallItem;
