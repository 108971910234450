import {
	KNOWLEDGE_BASE_ITEM_REQUEST,
	KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET,
	KNOWLEDGE_BASE_ITEM_REQUEST_SUCCESS,
	KNOWLEDGE_BASE_ITEM_REQUEST_FAILURE,
	SET_BREADCRUMBS,
	KNOWLEDGE_BASE_RESET,
	KNOWLEDGE_BASE_REQUEST,
	KNOWLEDGE_BASE_REQUEST_SUCCESS,
	KNOWLEDGE_BASE_REQUEST_FAILURE,
	KNOWLEDGE_BASE_SEARCH_QUERY_UPDATE,
	KNOWLEDGE_BASE_SEARCH_MODE_ENABLE,
	KNOWLEDGE_BASE_SEARCH_MODE_DISABLE,
	KNOWLEDGE_BASE_ITEM_CLEAR
} from 'constants/actions';

const initialState = {
	requestedForAppeal: '',
	
	knowledgeBase: [],
	knowledgeBaseLoading: false,
	query: '',
	searchMode: false,
	
	selectedItem: null,
	selectedItemLoading: false,
	
	breadcrumbs: [{ text: 'База знань', index: null, key: 'home' }],
};

export default (state = initialState, action) => {
	switch (action.type) {
		
		/********  Knowledge base request  ********/
	
	case KNOWLEDGE_BASE_REQUEST:
		return { ...state, knowledgeBaseLoading: true, requestedForAppeal: action.appealId };
	
	case KNOWLEDGE_BASE_REQUEST_SUCCESS:
		return { ...state, knowledgeBase: action.knowledgeBase, knowledgeBaseLoading: false };
	
	case KNOWLEDGE_BASE_REQUEST_FAILURE:
		return { ...state, knowledgeBase: [], knowledgeBaseLoading: false };
		
		/********  Knowledge base search query update  ********/
	
	case KNOWLEDGE_BASE_SEARCH_QUERY_UPDATE:
		return { ...state, query: action.query };
		
		/********  Knowledge base search mode update  ********/
	
	case KNOWLEDGE_BASE_SEARCH_MODE_ENABLE:
		return { ...state, searchMode: true };
	
	case KNOWLEDGE_BASE_SEARCH_MODE_DISABLE:
		return { ...state, searchMode: false };
		
		/********  Knowledge base breadcrumbs update  ********/
	
	case SET_BREADCRUMBS:
		return { ...state, breadcrumbs: action.breadcrumbs };
		
		/********  Knowledge base item request  ********/
	
	case KNOWLEDGE_BASE_ITEM_REQUEST:
		return { ...state, selectedItemLoading: true };
	
	case KNOWLEDGE_BASE_ITEM_REQUEST_SUCCESS:
		return { ...state, selectedItem: action.selectedItem, selectedItemLoading: false };
	
	case KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET:
		return { ...state, selectedItem: { ...state.selectedItem, dynParams: action.dynParams } };
	
	case KNOWLEDGE_BASE_ITEM_REQUEST_FAILURE:
		return { ...state, selectedItem: null, selectedItemLoading: false };
	
	case KNOWLEDGE_BASE_ITEM_CLEAR:
		return { ...state, selectedItem: null, selectedItemLoading: false };
		
		/********  Knowledge base reset  ********/
	
	case KNOWLEDGE_BASE_RESET:
		return initialState;
	
	default:
		return state;
	}
};
