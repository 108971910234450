import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({ label, input, meta, ...restProps }) => {
	const checked = input ? {checked : input.value } : undefined;
	return (
		<div className='input-element'>
			<label className='input-label' htmlFor={restProps.id || `checkbox-${label}`}>{label}</label>
			<label className='container-checkbox container'>
				<input id={`checkbox-${label}`}
					type='checkbox'
					{...checked}
					{...input}
					{...restProps}
				/>
				<span className='check-mark' />
			</label>
		</div>
	);
	
};

CheckBox.propTypes = {
	label: PropTypes.string
};

export default CheckBox;
