import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateSearchQuery, submitKnowledgeSearch } from 'actions/knowledges';
import { bindActionCreators } from 'redux';
import { disableSearchMode } from '../../../actions/knowledges';

const mapStateToProps = state => ({
	searchMode: state.knowledges.searchMode,
	searchQuery: state.knowledges.searchQuery,
	previousSearchQuery: state.knowledges.previousSearchQuery
});

const mapDispatchToProps = dispatch => bindActionCreators({
	submitKnowledgeSearch,
	updateSearchQuery,
	disableSearchMode,
}, dispatch);

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class ArticlesSearchInput extends React.PureComponent {

	componentWillUnmount = () => this.props.updateSearchQuery("");
	
	onInputChange = event => this.props.updateSearchQuery(event.target.value);
	
	isSearchableValue = query => typeof query === 'string' && this.props.previousSearchQuery !== query;
	
	onSearchSubmit = event => {
		event.preventDefault();
		const query = this.props.searchQuery.trim() || '';
		
		this.props.onSubmit(query);
	};
	
	render () {
		const { t, searchQuery } = this.props;
		return (
			<div className={styles.articlesSearchInputBox}>
				<form onSubmit={this.onSearchSubmit} className={styles.articlesSearchForm}>
					<input
						name='searchQuery'
						value={searchQuery}
						className={styles.articlesSearchInput}
						placeholder={t('specification.search')}
						onChange={this.onInputChange}
					/>
				</form>
			</div>
		);
	}
}

ArticlesSearchInput.propTypes = {
	onSubmit: PropTypes.func
};

export default ArticlesSearchInput;
