import Configuration from './Configuration/index.js'
import Jobs from './Jobs/index.js';

export const subTabsConfig = [
    {
        tab: 'configuration',
        component: Configuration,
        permission: true,
        translateName: 'wizard.config'
    },
    {
        tab: 'jobs',
        component: Jobs,
        permission: true,
        translateName: 'wizard.processing'
    },
];