import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Field, getFormValues, initialize, destroy } from 'redux-form';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { closeAdvancedSearchModal, clearClientGetSuppose, getSearchResults } from 'actions/client';
import { openCreateNewCustomerModal } from 'actions/ui';
import InputField from 'components/Common/InputField';
import Tab from 'components/Tab';
import { setEntityTab, Codes } from 'actions/customer';
import GRIDS from 'config/grids';

import GridWrapper from 'components/GridWrapper';
import styles from 'styles/modules/modal.module.scss';
import 'styles/components/search.scss';
import { withTranslation } from 'react-i18next';
import { reduxFormWrapper } from 'helpers';
import { configureFilterObject } from 'components/SearchFilters/helpers';
// import { advancedSearchClientFormConfig } from 'constants/index';
import permissions, { checkPermissions } from 'config/permissions';
import SearchModalFilter from "./SearchModalFilter";
import Expander from "../../../../components/Expander";
import {createCheckPropsFunction} from "../../../../helpers";
import Loader from "../../../../components/Loader";
import settingsService, { SORT_POSTFIX } from 'services/settingsService';

import CustomersList from "./CustomersList";

import './styles.scss';

const mapStateToProps = state => {

    const currentEntity = state.customer.tabEntity;
    // console.log({currentEntity});
    return {
        clients: state.client.clients,
		callbackOnCreateCustomer: _.get(state, 'client.callback'),
		loading: state.client.loading,
		tabEntity: state.customer.tabEntity,
		filterList: state.customer.filterList,
		total: state.customer.tabEntity === 'customer' ? get(state.grid, "customers_.result.total", 0) : state.client.total,
        formValues: getFormValues('customer-search-input')(state),
        filterValues: getFormValues(`modal-${currentEntity}-search-form`)(state) || [],
        tabs: state.tabs,
        query: state.client.query,
		isContactPersonCreationAllowed: checkPermissions(permissions.ContactPersonOperations.action_create_contactPerson),
        isCustomerCreationAllowed: checkPermissions(permissions.CustomerOperations.action_createCustomer),
        canSearchContactPersons: checkPermissions(permissions.SearchOperations.search_contactPersons,),
        canSearchCustomers: checkPermissions(permissions.SearchOperations.search_customer,),
    };
};

const mapDispatchToProps = dispatch => ({
    closeAdvancedSearchModal: () => dispatch(closeAdvancedSearchModal()),
    openCreateNewCustomerModal: context => dispatch(openCreateNewCustomerModal(context)),
    clearClientGetSuppose: () => dispatch(clearClientGetSuppose()),
	setEntityTab: (params, silent) => dispatch(setEntityTab(params, silent)),
	getSearchResults: params => dispatch(getSearchResults(params)),
    initialize,
    destroy
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'customer-search-input', enableReinitialize: true, destroyOnUnmount: true })
export default class AdvancedSearchModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            page: 1,
            gridFilters: null,
        };
        const tabNames = [];
        if (props.canSearchCustomers) {
            tabNames.push('customer');
        }
        if (props.canSearchContactPersons) {
            tabNames.push('contact_person');
        }
        // this.tabsNames = props.canSearchContactPersons ? ['customer', 'contact_person'] : ['customer'];
        this.tabsNames = tabNames;
        this.getFields = this.getFields.bind(this);
		this.selectCustomerFromButton = this.selectCustomerFromButton.bind(this);
	
	}

    componentWillMount() {
        // no customer entity available => select contact_person as entity tab
		const { tabEntity, canSearchCustomers, canSearchContactPersons, setEntityTab } = this.props;
        if (tabEntity === 'customer' && !canSearchCustomers && canSearchContactPersons) {
            // console.log("CONTACT_PERSON");
            setEntityTab('contact_person', true);
        }
    }

    componentDidMount () {
		const { tabEntity } = this.props;
		// this.props.initialize('customer-search-input',  {query: ''});
		this.props.initialize({query: ''});

        const requestData = { query: '', filterObject: [], page: 1, start: 0, limit: 10, type: tabEntity};
        if (tabEntity !== 'customer') {
            this.props.getSearchResults(requestData, tabEntity);
        }
        document.addEventListener('click', this.selectCustomerFromButton, false);
    }
    
    componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
        const isChangedFilterValues = !isEqual(prevProps.filterValues, this.props.filterValues);
        const isChangedFormValues = !isEqual(prevProps.formValues, this.props.formValues);
        const isChangedValues = isChangedFilterValues || isChangedFormValues;
        // console.log({props: this.props, isChangedList: isEqual(prevProps.filterValues, this.props.filterValues), isChangedValues: isPropChanged('filterValues')});
		if (isPropChanged('filterList') || isChangedValues && this.state.page !== 1) {
            // console.log("*** RESET ***");
			this.setState({ page: 1 });
			this.onPageChange({ page: 1 });
		}
    }
    
    componentWillUnmount () {
        document.removeEventListener('click', this.selectCustomerFromButton, false);
		this.tabsNames.forEach( tabEntity => {
            settingsService.remove(`modal_${tabEntity}${SORT_POSTFIX}`);
            this.props.destroy(`modal-${tabEntity}-search-form`); // clear form on modal unmount
        });
        this.props.clearClientGetSuppose();
    }
	
	selectCustomerFromButton = event => {
		if(event.target.tagName.toLowerCase() === 'a'
			&& !event.target.className && event.target.parentNode.tagName.toLowerCase() === 'p')
		{
			event.preventDefault();
			event.stopPropagation();
			const link = event.target.href;
			const hash = new URL(link).hash;
			const customerId = hash.split('/')[2];
			const contactPersonId = hash.split('/')[4];
			const applicant = {
				customerId,
				id: contactPersonId,
				type: "contact_person",
				partyType: 'contact_person'
			};
			this.props.closeAdvancedSearchModal();
			this.props.onSelect(applicant);
			this.props.clearClientGetSuppose();
		}
		
	};
 
	getFields = () => {
		const { tabEntity } = this.props;
        let conf = GRIDS[`${this.props.tabEntity}_search_filter_`].fields;
		 conf = {...conf, ...{keyValue:{ width: 15, component: Expander, props: { type: tabEntity }} } };
				 
	    return conf;
    };
    
    onPageChange = (params) => {
		const { filterValues, tabEntity, filterList, formValues: { query }, tabs:{ current: { name } } } = this.props;
        const start = (params.page - 1) * 10;
		const filterObject = configureFilterObject(filterValues, filterList);
		const savedSort = settingsService.get(`modal_${tabEntity}${SORT_POSTFIX}`) || {};
		const sort = params.sort ?
			[{ property: params.sort.property, direction: params.sort.direction}] :
			[{ property: savedSort.property, direction: savedSort.direction}];
        this.setState({...params, sort});
        if (tabEntity !== 'customer') {
            this.props.getSearchResults({
                sort,
                query,
                filterObject,
                start,
                limit: 10,
                type: tabEntity
            });
        }
    };
    
    onSelectItem = selectedApplicant => {
        if (this.props.tabEntity === "customer") {
            if (selectedApplicant.partyType !== 'individual' && selectedApplicant.type === 'customer') return null;
            this.props.closeAdvancedSearchModal();
            this.props.onSelect(selectedApplicant);
            this.props.clearClientGetSuppose();
            return;
        }
        const applicant = this.props.clients[Number.parseInt(selectedApplicant.index, 10)];
    	if (applicant.partyType !== 'individual' && applicant.type === 'customer') return null;
        
        this.props.closeAdvancedSearchModal();
        this.props.onSelect(applicant);
        this.props.clearClientGetSuppose();
    };
    
    handleInput = debounce(() => {
		const { filterValues, tabEntity, filterList,  formValues: { query } } = this.props;
		const filterObject = configureFilterObject(filterValues, filterList);
        const requestData = { ...this.state, limit: 10, query, filterObject, type: tabEntity };
        if (tabEntity === 'customer') {
            this.setState({...this.state, gridFilters: { filterObject, query } });
        } 
        // if (tabEntity === 'customer') {
        //     this.setState({...this.state, gridFilters: { filterObject, query } });
        // } else {
        //     this.props.getSearchResults(requestData);
        // }
        // this.setState({...this.state, gridFilters: { filterObject, query } });
        if (tabEntity !== 'customer') {
            this.props.getSearchResults(requestData);
        }
    }, SEARCH_TIMER_INTERVAL);
    
    createNewCustomer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { callbackOnCreateCustomer } = this.props;
		const callback = callbackOnCreateCustomer;
	
        this.props.closeAdvancedSearchModal();
        this.props.openCreateNewCustomerModal({ openNewTab: false, callback: callback, onClose: this.props.onCreateNewClose });
    };
    
    configureGridData = () => {
        const result = this.props.clients.map( (item, index) => {return {...item, index} });
        return { result, total: this.props.total };
    };
 
    render () {
        const { total, tabs, t, match, isCustomerCreationAllowed, isContactPersonCreationAllowed, tabEntity, setEntityTab, formValues, filterValues,  filterList} = this.props;
        const permittedShowCreateBtn = isCustomerCreationAllowed || isContactPersonCreationAllowed;

        // console.log({isLoading: this.props.loading});

        const { gridFilters } = this.state;

        return (
            <div className={styles.modalWrapper}>
                <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>{t('searchClient.searchResultClient')} ({total})</div>
                </div>
                <div className={cx(styles.modalContent, 'modal-content-clients')}>
                        <div className={cx('search-input-wrapper', 'search')}>
                            <Field
                                component={InputField}
                                className='search-input'
                                name='query'
                                placeholder={t('Search')}
                                onChange={this.handleInput}
								autoFocus={true}
                            />
                        </div>
                    <main className='main-wrapper'>
						<div id='card-bottom-row' className='grid-head-tab-row'>
                            <div className='top-right'>
								{this.tabsNames.map((val) => {
									return <Tab
                                            val={val}
                                            key={val}
                                            tab={val}
                                            onSelect={val => val !== tabEntity && setEntityTab(val)}
                                            classPropName={val === tabEntity && 'active'}/>;
								})}
							</div>
                            <div className='grid-column ordering-component-ui-core-wrapper customers-advanced-search-modal'>
                                    {this.props.loading && <Loader />}
                                    {tabEntity === 'customer' ? (
                                        <CustomersList gridFilters={gridFilters} onSelectItem={this.onSelectItem} />
                                    ) : (
                                        <GridWrapper
                                            data={this.configureGridData()}
                                            tabs={tabs}
                                            fields="searchclient"
                                            onPageChange={this.onPageChange}
                                            match={match}
                                            groups={{
                                                selectItem: this.onSelectItem,
                                                fields: this.getFields(),
                                            }}
                                            page={this.state.page}
                                            modalEntity={`modal_${tabEntity}`}
                                        />
                                    )}
                            </div>
						</div>
                        <div className='form-column'>
							<SearchModalFilter
								form={`modal-${tabEntity}-search-form`}
								tabEntity={tabEntity}
								handleSubmit={this.handleInput}
							/>
                        </div>
                    </main>
                    {
						permittedShowCreateBtn &&
                        <footer className='modal-footer'>
                            <button className='btn btn-primary btn-create-new' onClick={this.createNewCustomer}>
                                {t('searchClient.createNew')}
                            </button>
                        </footer>
                    }
                
                </div>
            </div>
        );
    }
}

AdvancedSearchModal.propTypes = {
    clients: PropTypes.array,
    total: PropTypes.number,
    onSelect: PropTypes.func,
    openCreateNewCustomerModal: PropTypes.func,
    closeAdvancedSearchModal: PropTypes.func,
    clearClientGetSuppose: PropTypes.func
};
