import React from 'react';
import PropTypes from 'prop-types';

export default function PlaybackProgress ({ currentTime, totalTime, onChange }) {
	let progress = Math.round(currentTime / (totalTime / 100));
	if (!isFinite(progress)) progress = 0;
	const progressBarStyle = { background: `linear-gradient(to right, rgb(255,255,255) ${progress}%, rgba(255,255,255,.3) ${progress}%)` };
	
	return (
		<input
			type='range'
			className='playbackProgress'
			value={currentTime}
			step='1'
			min='0'
			max={totalTime}
			onChange={onChange}
			style={progressBarStyle}
		/>
	);
}

PlaybackProgress.propTypes = {
	currentTime: PropTypes.number,
	totalTime: PropTypes.number,
	onChange: PropTypes.func
};
