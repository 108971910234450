import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
    setModalStatusLIst,
    setStatusModalVisibility,
    setRequireDynParams,
    clearAppealStatusListTemplateData,
    setInitializedStatusList,
} from 'modules/appeal/actions';
import { extractAppealFromState } from 'helpers';

import { ModalFields } from './constants';

import StatusModal from './StatusModal';

import Popup from 'components/Popup';

// (provide status list via props)
function mapStateToProps(state, props) {
    const [appeal, id] = extractAppealFromState(state, props);

    return {
        ...formValueSelector(`appeal-form-${id}`)(
            state,
            'status',
            'resolution',
            ...Object.values(ModalFields),
        ),
        customerId: get(state, 'customer.currentCustomer.id'),
        firstLevelAppealAction: appeal.firstLevelAppealAction,
        resolutionAppealAction: appeal.resolutionAppealAction,
        modalStatusList: appeal.modalStatusList,
        modalVisibility: appeal.modalVisibility,
        statusList: appeal.statusList,
        initializedStatusList: appeal.initializedStatusList,
        appealStatusFromTemplate: appeal.appealStatusFromTemplate,
        appealResolutionFromTemplate: appeal.appealResolutionFromTemplate,
        appealTemplateUpdateCount: appeal.appealTemplateUpdateCount,
        id,
        appeal: get(appeal, 'currentAppeal')
    };
}

const mapDispatchToProps = {
    setModalStatusLIst,
    setStatusModalVisibility,
    setRequireDynParams,
    clearAppealStatusListTemplateData,
    setInitializedStatusList,
    change: (id, field, value) => change(`appeal-form-${id}`, field, value),
};

const StatusSelect = (props) => {
    const {
        t,
        id,
        initializedStatusList,
        setInitializedStatusList,
        statusList,
        status,
        resolution,
        appealStatusFromTemplate,
        appealResolutionFromTemplate,
        clearAppealStatusListTemplateData,
        setModalStatusLIst,
        setRequireDynParams,
        unlockAppealForm,
        change,
        onSubmitButtonClick,
        onStatusModalConfirm,
        setStatusModalVisibility,
        disabled,
        permission,
        modalStatusList,
        modalVisibility,
        firstLevelAppealAction,
        resolutionAppealAction,
        appealTemplateUpdateCount,
        appeal,
        formValues,
    } = props;

    const statusCode = get(status, 'code', '');
    const statusName = get(status, 'name', '');
    const title = get(resolution, 'title');
    let permissionClass = '';
    if (permission === 0) {
        permissionClass = 'hidden';
    } else if (permission === 1) {
        permissionClass = 'disabled';
    }

    // const [isInitialized, setIsInitialized] = useState(false);
    const [statusPopupIsOpen, setStatusPopupIsOpen] = useState(false);
    const [resolutionPopupIsOpen, setResolutionPopupIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedResolution, setSelectedResolution] = useState(null);

    function selectStatus(status) {
        return (event) => {
            event.stopPropagation();
            if (status.resolution.length) {
                setSelectedStatus(status);
                setStatusPopupIsOpen(false);
                setResolutionPopupIsOpen(true);
            } else {
                setStatusPopupIsOpen(false);
                setResolutionPopupIsOpen(false);
                checkAndSaveChanges({ status });
            }
        };
    }

    function selectStatusOnly(event) {
        event.stopPropagation();
        setStatusPopupIsOpen(false);
        setResolutionPopupIsOpen(false);
        checkAndSaveChanges({ status: selectedStatus });
    }

    function selectResolution(resolution) {
        return (event) => {
            event.stopPropagation();
            setResolutionPopupIsOpen(false);
            checkAndSaveChanges({ status: selectedStatus, resolution });
        };
    }

    function checkAndSaveChanges({ status, resolution, disableModal, isInit }) {
        const fieldList = [];

        if (status && status.isNeedComment && !disableModal) {
            fieldList.push(ModalFields.COMMENT);
        }

        if (fieldList.length > 0) {
            const appealStatus = get(appeal, 'status.code');
            const statusCode = get(status, 'code');
            if (!isInit && appealStatus !== statusCode) {
                setModalStatusLIst(fieldList, id);
            }
            setSelectedResolution(resolution);
            setSelectedStatus(status);
        } else {
            setModalStatusLIst([], id);
        }

        setRequireDynParams(id, true);

        saveChanges(status, resolution, isInit);
    }

    function saveChanges(status, resolution, isInit) {
        if (!isInit) {
            unlockAppealForm("form");
        }
        change(id, 'status', status);
        change(id, 'resolution', resolution || null);

        saveModalValues();
    }

    function saveModalValues(values = {}) {
        const saveValue = (field) => {
            const value = values[field] || null;

            if (props[field] !== value) {
                change(id, field, value);
            }
        };

        Object.values(ModalFields).forEach(saveValue);
    }

    function onModalSubmit(values) {
        unlockAppealForm("form");
        change(id, 'status', selectedStatus || status);
        change(id, 'resolution', selectedResolution || resolution || null);

        saveModalValues(values);
        setModalStatusLIst([], id);
        onSubmitButtonClick();
        setStatusModalVisibility(false, id);
        
        // setTimeout here is used to submit the form on confim right after statusList is being cleared up => setModalStatusLIst([], id)
        // the form is not allow to submit it until status list is not empty, code part sample:  if (props.modalStatusList.length > 0) ...
        setTimeout(onStatusModalConfirm, 0);
    }

    function closeModal() {
        setStatusModalVisibility(false, id);
        onSubmitButtonClick();
    }

    function closePopups(event) {
        event.stopPropagation();

        setStatusPopupIsOpen(false);
        setResolutionPopupIsOpen(false);
    }

    function openStatusPopup(event) {
        event.stopPropagation();

        if (!disabled && statusList && statusList.length) {
            setStatusPopupIsOpen(true);
            setResolutionPopupIsOpen(false);
        }
    }

    // new logic

    useEffect(() => {
        // Кейс 0. простого открытия запроса без изменений
        // (Статус и резолюция (низ) и Статус и резолюция  (верх))
        // 0. Открываем запрос, запрашиваем список статусов
        // 1. Рисуем в низу первый по списку статус
        // 2. Анализируем есть ли резолюция сверху в списке для статуса который проставили внизу (шаг 1), если есть ставим, если нет не ставим
        // 3. Запоминаем конфигурацию статуса и резолюции внизу бля табы

        // console.log({initializedStatusList, statusList, status});

        const firstStatus = statusList && statusList[0];

        // init empty status (empty list received)
        if (!initializedStatusList && statusList && statusList.length === 0) {
            checkAndSaveChanges({ status: {}, isInit: true });
            // setIsInitialized(true);
            setInitializedStatusList(id);
        }
        // init - set saved or first statusFrom the list (case 0), check if resolution is available as well
        if (!initializedStatusList && statusList && statusList.length > 0) {
            const availableStatus = statusList.find((item) => item.code === statusCode);
            const newStatus = availableStatus || firstStatus;
            // check if resolution is available in first status, if so - set such resolution
            const isResolutionAvailable =
                resolution && newStatus.resolution.find((res) => res.id === resolution.id);
            checkAndSaveChanges({
                status: newStatus,
                resolution: isResolutionAvailable ? resolution : null,
                isInit: true,
            });
            // setIsInitialized(true);
            setInitializedStatusList(id);
        }

        // Кейс 1. редактирования тематики
        // кейс 0
        // 2.0. Меняем тематику запроса, запрашиваем список статусов
        // 2.1. Если запомненый статус (кейс 0 шаг 3) есть в списке оставляем его если нет, ставим первый по списку статус
        // 2.2. Анализируем список на наличие резолюции из памяти под стаус проставленый на шаге 2.1, если есть оставляем ее если нет оцищаем резолюцию
        // 2.3. Запоминаем конфигурацию статуса и резолюции внизу бля табы

        // update once isInitialized (handle type change which cause list update or template fill)
        if (initializedStatusList) {
            // empty status (empty list received)
            if (statusList && statusList.length === 0) {
                checkAndSaveChanges({ status: {}, isInit: true });
                return;
            }
            
            // check status and/or resolution from knowledgebase template
            const statusFromTemplate =
                appealStatusFromTemplate &&
                statusList.find((item) => item.code === appealStatusFromTemplate);
            const isResolutionFromTemplate =
                appealResolutionFromTemplate && appealResolutionFromTemplate.title;

            const isResolutionOk = isResolutionFromTemplate
                ? statusFromTemplate &&
                  statusFromTemplate.resolution.find(
                      (res) => res.id === appealResolutionFromTemplate.id,
                  )
                : statusFromTemplate;

            const isToFillFromTemplate = statusFromTemplate && isResolutionOk;

            if (isToFillFromTemplate) {
                // fill from the knowledgebase template (based on status/res from template fill)
                checkAndSaveChanges({
                    status: statusFromTemplate,
                    resolution: isResolutionFromTemplate ? appealResolutionFromTemplate : null,
                    isInit: true,
                });
            } else {
                // fill based on available status and resolution
                // check if current status is available in statusList
                // const availableStatus = statusList.find((item) => item.code === status.code);
                const availableStatus = statusList.find((item) => item.code === statusCode);
                const newStatus = availableStatus || firstStatus;
                const isResolutionAvailable =
                    resolution && newStatus.resolution.find((res) => res.id === resolution.id);

                // console.log({newStatus, isResolutionAvailable});
                checkAndSaveChanges({
                    status: newStatus,
                    resolution: isResolutionAvailable ? resolution : null,
                    isInit: true,
                });
            }
        }
    }, [
        statusList,
        appealStatusFromTemplate,
        appealResolutionFromTemplate,
        appealTemplateUpdateCount,
    ]);

    // set resolution and status init values in form
    useEffect(() => {
        if (formValues && appeal) {
            const { status, resolution } = appeal;
            if (!("resolution" in formValues)) {
                // init resolution formValue from appeal
                change(id, "resolution", resolution);
            }
            if (!("status" in formValues)) {
                // init status formValue from appeal
                change(id, "status", status);
            }
        }
    }, [appeal, formValues]);

    useEffect(() => {
        return () => {
            clearAppealStatusListTemplateData(id);
        };
    }, []);

    // some logic which is binded to appeal <ActionSelect /> component
    useEffect(() => {
        if (firstLevelAppealAction && id) {
            const status = statusList.find((i) => i.code === firstLevelAppealAction.toStateName);
            if (status) {
                checkAndSaveChanges({ status });
            }
        }

        if (resolutionAppealAction && id) {
            const status = statusList.find((i) => i.code === firstLevelAppealAction.toStateName);
            if (status) {
                const resolution =
                    status.resolution.length > 0
                        ? status.resolution.find((i) => i.id === resolutionAppealAction.id)
                        : null;
                checkAndSaveChanges({ status, resolution });
            }
        }
    }, [firstLevelAppealAction, id, resolutionAppealAction]);

    function renderStatusLink(status) {
        return (
            <div
                key={status.code}
                className={'popup-status-link-wrapper'}
                onClick={selectStatus(status)}
            >
                <div className={`popup-status-link ${status.code}`}>{status.name}</div>
                {status.resolution.length > 0 && <i className="icon-arrow-right" />}
            </div>
        );
    }

    function renderResolutionLink(resolution) {
        return (
            <div key={resolution.id} className="popup-link" onClick={selectResolution(resolution)}>
                {resolution.title}
            </div>
        );
    }

    return (
        <>
            <span className={cx('m-r-16 status-select-title', permissionClass)}>{t('appeal.appealTypeTitle')}</span>
            <div className={cx('status-select-wrapper', permissionClass)} onClick={openStatusPopup}>
                <span className={`sticker sticker-${statusCode}`}>{statusName}</span>
                {title && (
                    <>
                        <span className="status-divider">|</span>
                        <span className="resolution">{title}</span>
                    </>
                )}

                {statusList && statusList.length > 0 && <i className="icon-down" />}

                {statusPopupIsOpen && statusList && (
                    <Popup place="appeal-status-popup" onClickOutside={closePopups}>
                        {statusList.map(renderStatusLink)}
                    </Popup>
                )}

                {resolutionPopupIsOpen && (
                    <Popup onClickOutside={closePopups} place="appeal-status-popup">
                        <div className="resolutionBackLink" onClick={openStatusPopup}>
                            <i className="icon-arrow-left" />
                            <div key={'back'} className="popup-link">
                                {t('back')}
                            </div>
                        </div>
                        <div
                            key={selectedStatus.code}
                            className={`popup-status-link bold ${selectedStatus.code}`}
                            onClick={selectStatusOnly}
                        >
                            {selectedStatus.name}
                        </div>

                        {selectedStatus.resolution.map(renderResolutionLink)}
                    </Popup>
                )}
                {modalStatusList && modalStatusList.length > 0 && modalVisibility && (
                    <StatusModal
                        onClose={closeModal}
                        nextStatusList={selectedStatus && selectedStatus.nextState}
                        saveChanges={onModalSubmit}
                        fieldList={modalStatusList}
                    />
                )}
            </div>
        </>
    );
};

StatusSelect.propTypes = {
    statusList: PropTypes.array,
    status: PropTypes.object,
    unlockAppealForm: PropTypes.func,
    change: PropTypes.func,
    checkRestrictions: PropTypes.func,
};

export default withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusSelect)),
);
