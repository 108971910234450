import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import permissions, { checkPermissions } from 'config/permissions';

import Popup from 'components/Popup/index';
import {
	clearFilterInfo,
	getInfoFilterSuppose,
	exportAppealGridToExcel,
	exportAppealsGridToXLS,
	openFilterParams,
	editFilter,
	closeFilterParams,
	openNewFilterParams,
	closeNewFilterParams,
} from 'actions/filter';
import styles from 'styles/modules/appealsContent.module.scss';
import settingsService, { SORT_POSTFIX, FIELDS_POSTFIX } from 'services/settingsService';
import tabsParams from 'constants/tabsParams';
import {
    openCreateAppealFromFileModal
} from 'actions/ui';

import { openExportWizardModal } from '../../../reducers/exportWizard';


function Header (props) {
	const [gridPopupIsOpen, setGridPopupIsOpen] = useState();
	const [onRequestExportAllStatus, setOnRequestExportAllStatus] = useState(false);
	const { t, sort, gridFields, isCreateGroupAppeal, openCreateAppealFromFileModal, openExportWizardModal, isExportAllowed, isCreateAppealFromExcel } = props;
	
	function toggleGridPopup () {
		setGridPopupIsOpen(!gridPopupIsOpen);
	}
	
	function openFilterParams (e) {
		e.preventDefault();
		e.stopPropagation();
		
		props.closeNewFilterParams();
		toggleGridPopup();
		props.openFilterParams();
	}
	
	function openNewFilter (e) {
		e.preventDefault();
		e.stopPropagation();
		
		props.clearFilterInfo();
		props.closeFilterParams();
		toggleGridPopup();
		props.openNewFilterParams();
	}
	
	function filterName (data, id) {
		let name = '';
		if (data && Array.isArray(data)) {
			data.forEach(item => (+item.value === +id ? name = item.name : null));
		} else {
			console.warn("AppealList/Header::filterName: Data is empty!");
		}
		return name;
	}
	
	function exportAllColumnsToExcel () {
		const appealFields = Object.entries(gridFields)
			.reduce((acc, [key, value]) => {
				
				if (!value.notForExport) {
					const nameParts = [key];
					if (value.objectField) nameParts.push(value.objectField);
					
					acc[nameParts.join('.')] = t(value.name);
				}
				
				return acc;
			}, {});

		const requestData = {
			query: '',
			filterId: props.id,
			fields: appealFields,
			filterObject: [],
			//sort
			sort:  sort && sort.order && [{
				property: sort.field,
				direction: sort.order.toUpperCase()
			}]
		};
		
		toggleGridPopup();
		return exportAppealGridToExcel(requestData);
	}
	
	function exportToXlsAll () {
		setOnRequestExportAllStatus(true);
		const appealFields = Object.entries(tabsParams(props.t).filter)
			.reduce((acc, [key, value]) => {
				const nameParts = [key];
				if (value.objectField) nameParts.push(value.objectField);
				
				acc[nameParts.join('.')] = value.name;
				
				return acc;
			}, {});

		const requestData = {
			query: '',
			filterId: props.id,
			fields: appealFields,
			//sort
			sort:  sort && sort.order && [{
				property: sort.field,
				direction: sort.order.toUpperCase()
			}]
		};
		
		toggleGridPopup();
		exportAppealsGridToXLS(requestData, () => { setOnRequestExportAllStatus(false) });
	}
	
	function exportSelectedToExcel () {
		const { queryKey } = props;
		
		const appealFields = Object.entries(gridFields)
			.reduce((acc, [key, value]) => {
				
				if (!value.hidden && !value.notForExport) {
					const nameParts = [key];
					if (value.objectField) nameParts.push(value.objectField);
					
					acc[nameParts.join('.')] = t(value.name);
				}
				
				return acc;
			}, {});
		
		const requestData = {
			query: '',
			filterId: props.id,
			fields: appealFields,
			filterObject: [],
			//sort
			sort:  sort && sort.order && [{
				property: sort.field,
				direction: sort.order.toUpperCase()
			}]
		};
		
		
		toggleGridPopup();
		return exportAppealGridToExcel(requestData);
	}
	
	function renderRemoveButton (data, id) {
		const filter = data.find(item => +item.value === +id);
		if (filter) {
			return filter.removable;
		}
		return true;
	}
	
	function renderEditButton (data, id) {
		const filter = data.find(item => +item.value === +id);
		if (filter) {
			return filter.editable;
		}
		return true;
	}
	
	function onClickDelete () {
		toggleGridPopup();
		props.openModal('delete');
	}
	
	function onClickEdit () {
		toggleGridPopup();
		props.editFilter();
	}

	function openGroupAppealsModal() {
		toggleGridPopup();
		openCreateAppealFromFileModal({});
	}

	function handleOpenExportWizardModal() {
		toggleGridPopup();
		openExportWizardModal();
	}
	
	function openCreateAppealFromFile() {
		toggleGridPopup();
		openCreateAppealFromFileModal({isAnonym: true});
	}
	
	return (
		<header className='header-content'>
			<div className={styles.headerLeft}>
				{filterName(props.data, props.id)}
			</div>
			<div className='actions'>
				<div className='btn-add' onClick={toggleGridPopup}>
					<i className='icon-kebab-vert' />
				</div>
				{
					gridPopupIsOpen &&
					<Popup
						onClickOutside={toggleGridPopup}
						place='grid-popup'
					>
						<button className='popup-link' onClick={openNewFilter}>
							{t('filters.createNewFilter')}
						</button>
						<button className='popup-link' onClick={openFilterParams}>
							{t('filters.parametersFilter')}
						</button>
						{
							renderEditButton(props.data, props.id) &&
							<button className='popup-link' onClick={onClickEdit}>
								{t('filters.editFilter')}
							</button>
						}
						{
							renderRemoveButton(props.data, props.id) &&
							<button className='popup-link' onClick={onClickDelete}>
								{t('filters.removeFilter')}
							</button>
						}
						{/* <button className='popup-link' onClick={exportAllColumnsToExcel}>
							{t('filters.exportAllColumns')}
						</button> */}
						{isExportAllowed && <button className='popup-link' onClick={handleOpenExportWizardModal}>
							{t('filters.export')}
						</button>}
						{/* <button
							className={cx('popup-link', { 'disabled': onRequestExportAllStatus })}
							onClick={exportToXlsAll}
						>
							{t('filters.exportToXlsAll')}
						</button> */}
						{/* {
							props.isCustomGrid &&
							<button className='popup-link' onClick={exportSelectedToExcel}>
								{t('filters.exportSelected')}
							</button>
						} */}
						{
							isCreateGroupAppeal && 
							<button className='popup-link' onClick={openGroupAppealsModal}>
								{t('filters.groupWorkWithAppeals')}
							</button>
						}
						{
							isCreateAppealFromExcel && 
							<button className='popup-link' onClick={openCreateAppealFromFile}>
								{t('filters.createFromFile')}
							</button>
						}
					</Popup>
				}
			</div>
		</header>
	)
}

Header.propTypes = {
	data: PropTypes.array,
	openFilterParams: PropTypes.func,
	closeFilterParams: PropTypes.func,
	openNewFilterParams: PropTypes.func,
	closeNewFilterParams: PropTypes.func,
	getInfoFilterSuppose: PropTypes.func
};

// AppealOperations
function mapStateToProps (state) {
	const currentGrid = state.grid.gridKey;
	
	return {
		queryKey: get(state.tabs, 'current.queryKey'),
		isCustomGrid: currentGrid && state.grid[currentGrid] ? state.grid[currentGrid].custom : false,
		gridFields: currentGrid && state.grid[currentGrid] ? state.grid[currentGrid].params.fields : false,
		//TODO TEMP FIX
		// sort: currentGrid ? [state.grid[currentGrid].params.sort] : [],
		sort: currentGrid && state.grid[currentGrid] ? state.grid[currentGrid].params.sort : [],
		isCreateGroupAppeal: checkPermissions(permissions.AppealOperations.action_closeAppealGroup),
		isExportAllowed: checkPermissions(permissions.SearchOperations.search_export),
		isCreateAppealFromExcel: checkPermissions(
            permissions.AppealOperations.action_createAppealGroupFromClient,
        ),
	};
}

const mapDispatchToProps = {
	clearFilterInfo,
	openFilterParams,
	editFilter,
	closeFilterParams,
	closeNewFilterParams,
	openNewFilterParams,
	getInfoFilterSuppose,
	openCreateAppealFromFileModal,
	openExportWizardModal
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
