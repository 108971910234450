import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

@withTranslation()
@withRouter
class Tab extends React.Component {
	
	constructor (props) {
		super(props);
		
		this.onTabClick = this.onTabClick.bind(this);
	}
	
	onTabClick (e) {
		if (this.props.onSelect) {
			e.preventDefault();
			this.props.onSelect(this.props.val);
		}
		if (this.props.onClick) this.props.onClick(this.props.val);
	};
	
	render () {
		const { val, history, counter, getPath, t, classPropName, name } = this.props;
		const aPath = history.location.pathname.split('/');
		aPath.pop();
		aPath.push(val);
		const path = getPath ? getPath() : aPath.join('/');

		return (
			<NavLink exact to={path} className={cx('tab-vertical', classPropName)} onClick={this.onTabClick}>
				{name || t(`tabs.${val}`).toUpperCase()} {counter}
			</NavLink>
		);
	}
}

Tab.propTypes = {
	val: PropTypes.string.isRequired,
	card: PropTypes.object,
	role: PropTypes.object,
	onClick: PropTypes.func,
};

export default Tab;
