import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import ComboBoxMultipleCheckbox from './ComboBoxMultipleCheckbox';
import { withTranslation } from 'react-i18next';
import ErrorBlock from 'components/CustomerPage/Parameters/components/ErrorBlock.js';
import { isEmpty } from 'lodash';

@withTranslation()
class ComboBox extends Component {
    selectRef = React.createRef();

    componentDidMount () {
        if (this.props.onRefReady) {
            this.props.onRefReady(this.selectRef);
        }
    }
	
	componentDidUpdate (prevProps) {
    	const { refKey, formValues, input, options } = this.props;
    	
		const master = _.get(formValues, `blocks[${refKey}]`) || {};
		const masterPrev = _.get(prevProps.formValues, `blocks[${refKey}]`) || {};
		if (refKey && master && master.value !== masterPrev.value) {
			if (!options.filter((i) => i.refVal.includes(master.value || master)).find(i => i.value === (input.value.value || input.value))) {
                // input.onChange({});
				input.onChange(null);
			}
		}

        // check if refKey value is available in master options, if not - clearup field
        if (refKey && !isEmpty(master) && ![null, undefined, ""].includes(input.value)) {
            if (!options.filter((i) => i.refVal.includes(master.value || master)).find(i => i.value === (input.value.value || input.value))) {
				input.onChange(null);
			}
        }
	}

    toggleErrorPopup = () => {
        this.setPosition();
        setTimeout(() => this.setState({errorPopupIsOpen: !this.state.errorPopupIsOpen}), 100);
    };

    openErrorPopup = () => {
        this.setPositionError();
        setTimeout(() => this.setState({errorPopupIsOpen: true}), 100);
    };

    closeErrorPopup = () => {
        setTimeout(() => this.setState({errorPopupIsOpen: false}), 100);
    };

    setPositionError = () => {
        const rect = this.inputError.getBoundingClientRect();
        const left = window.scrollX + rect.left + rect.width;
        const top = window.scrollY + rect.top;

        if (top !== this.state.top || left !== this.state.left) {
            this.setState({left, top});
        }
    };

    arrowRender = values => {
        if (values.isOpen) {
            return <i className='icon-up'/>;
        }
        return <i className='icon-down'/>;
    };

    onOpen = () => {
        const select = document.querySelector('.Select-menu-outer');
        if (select.scrollIntoViewIfNeeded) {
            select.scrollIntoViewIfNeeded(false);
        } else {
            select.scrollIntoView(false);
        }
    };

    onFocus = event => {
        this.props.input && this.props.input.onFocus(event);
    };

    onBlur = () => {
        const {input} = this.props;
        // input && input.onBlur(input.value);
        input && input.onBlur(undefined);
    };

    render () {
        const {
            label,
            options,
            input,
            meta,
            placeholder = this.props.t('dontSelect'),
            multi,
            showValuesCount,
            id,
            required,
            searchable,
            filterOption,
            noborder,
            submitComboboxMulty,
            noResultsText = this.props.t('noResultsFound'),
			maxLength,
			refKey,
            formValues,
            clearAllText = this.props.t('clear all results'),
            ...restProps
        } = this.props;
        let selectOption = options;
        const selectClassName = cx(
            'container-comboBox',
            meta && meta.touched && (meta.error || meta.warning) && 'input-field__error',
            meta && meta.active && 'active', {
                'noborder': noborder
            });

        const showErrorBlock = meta && meta.touched && !meta.active && meta.error;
        
        const master = _.get(formValues, `blocks[${refKey}]`);
        
        if (refKey && master) {
			selectOption = selectOption.filter((i) => i.refVal.includes(master.value || master));
		}
        
        return (
            <div id={id} className='input-element'>
                <div className='input-label'>
                    {label}
                    {required && <span className='required-field'>*</span>}
                </div>
                {multi ?
                    (
                        <ComboBoxMultipleCheckbox
                            options={selectOption}
                            placeholder={placeholder}
                            input={input}
                            label={label}
                            meta={meta}
                            submitComboboxMulty={submitComboboxMulty}
                            showValuesCount={showValuesCount}
                            {...restProps}
                        />
                    ) :
                    (
                        <Select
                            {...restProps}
                            {...input}
                            {...meta}
							inputProps={{
								maxLength
							}}
                            options={selectOption}
                            className={selectClassName}
                            searchable={searchable}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            clearable={false}
                            arrowRenderer={this.arrowRender}
                            placeholder={placeholder}
                            optionClassName='select-option'
                            onOpen={this.onOpen}
                            noResultsText={noResultsText}
                            filterOption={filterOption}
                            ref={this.selectRef}
                        />
                    )
                }
                { showErrorBlock && <ErrorBlock error={meta.error} className='m-r-10' /> }
            </div>
        );
    }
}

ComboBox.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    multi: PropTypes.bool,
    searchable: PropTypes.bool,
    required: PropTypes.bool,
    onBlurResetsInput: PropTypes.bool,
    noResultsText: PropTypes.string,
    filterOption: PropTypes.func,
    noborder: PropTypes.func
};

export default ComboBox;

