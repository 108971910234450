import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {reduxForm, Form, getFormValues, getFormSyncErrors} from 'redux-form';
import { convertDynamicFields } from 'helpers';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import styles from 'styles/modules/appealQuality.module.scss';
import ModalPortal from 'components/ModalPortal';
import Loader from 'components/Loader';
import validate from './validate';
import { saveQualityForm } from 'actions/quality';
import DynamicParams from './DynamicParams';
import { showValidationNotification } from 'modules/appeal/actions';

const mapStateToProps = (state) => ({
	formAnswers: getFormValues('quality-form')(state),
	formErrors: getFormSyncErrors('quality-form')(state),
});

const mapDispatchToProps = dispatch => ({
	saveQualityForm: (data, edit, callback) => dispatch(saveQualityForm(data, edit, callback)),
	showValidationNotification: () => dispatch(showValidationNotification()),
});
@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
	form: 'quality-form',
	validate,
	// enableReinitialize: true
})
export default class AppealQuality extends PureComponent {
	
	constructor (props) {
		super(props);
		this.state = {
			answers: [],
			totalScore: 0,
			disabledSubmit: true,
			openMode: '',
			questionTypesToMath: [],
		};
		this.renderRanks = this.renderRanks.bind(this);
		this.getWidgetOptions = this.getWidgetOptions.bind(this);
		this.handleSubmit = this.constructor.handleSubmit.bind(this);
		this.handlerGoBack = this.handlerGoBack.bind(this);
	}
	
	static handleSubmit (e) {
		e.preventDefault();
		const { saveQualityForm, appeal, questionParams, formAnswers, objectType, objectTypeId, edit } = this.props;
		const { stepNum, totalSteps } = questionParams;
		const dynamicFields = convertDynamicFields(formAnswers);
		
		const requestData = {
			totalScore: this.state.totalScore,
			dynamicFields: dynamicFields
		};
		const params = {
			objectId: appeal.interactionId,
			objectType: objectType,
			objectTypeId: objectTypeId,
			objectTypeName: null,
			stepNum: stepNum,
			totalSteps: totalSteps,
			...requestData
		};
		
		return saveQualityForm(params, edit, this.handlerGoBack);
	}
	
	componentDidMount () {
		const { questionParams, initialize, edit } = this.props;
		const widgetTypes = ['rank', 'radio', 'select'];
		const isNotEmpty = questionParams && questionParams.blocks && questionParams.blocks[0] && questionParams.blocks[0].widgets.length;
		
		let questionTypesToMath = [];
		
		if (edit && isNotEmpty) {
			initialize(questionParams.blocks.reduce((prev, curr) => [...prev, ...curr.widgets], []).reduce((prev, curr) => ({
				...prev, // set initialValues in redux form in format { key: value }
				[curr.key]: curr.savedValue || curr.defaultValue
			}), {}));
		}
		isNotEmpty && questionParams.blocks.map(block => block.widgets.filter(widget =>
			widgetTypes.includes(widget.widgetType))
			.map(widget => questionTypesToMath = [...questionTypesToMath, widget.key]));
		
		this.setState({ questionTypesToMath: questionTypesToMath });
	}
	
	componentDidUpdate () {
		const { questionTypesToMath } = this.state;
		const { formAnswers, totalScore } = this.props;
		let sum = totalScore ? totalScore : 0;
		
		if (formAnswers) {
			this.setState({ disabledSubmit: false });
		}
		
		formAnswers && Object.keys(formAnswers).forEach(function (key) {
			
			if (!isNaN(+formAnswers[key]) && isFinite(formAnswers[key])) {
				if (questionTypesToMath.includes(key)) {
					sum += parseInt(+formAnswers[key]);
				}
			}
		});
		
		this.setState({ totalScore: sum });
	}
	
	handlerGoBack () {
		const { goBack } = this.props;
		
		this.setState({ disabledSubmit: true });
		return goBack();
	}
	
	renderRanks (item) {
		const { key, title, widgets } = item;
		const { t, appeal } = this.props;
		
		return (
			<Fragment>
				{(widgets.length === 0 || !widgets.length) && <Loader withContainer={true} />}
				{widgets.length > 0 &&
				<div key={key} className={styles.blockWidgetsWrap}>
					{
						title && title !== '*' &&
						<span className={styles.appealQualityFormHeader}>{t(title)}</span>
					}
					{
						<DynamicParams
							t={t}
							widgets={widgets}
							onChange={this.onChange}
							refSelect='Select'
							appealId={appeal.id}
						/>
					}
				</div>
				}
			</Fragment>
		);
		
	}
	
	getWidgetOptions = values => values.map(value => ({
		...value,
		label: value.value,
		value: value.key,
		id: value.key
	}));
	
	render () {
		const { appeal, t, onClose, onMin, questionParams, openMode, formErrors } = this.props;
		const { disabledSubmit } = this.state;
		const disabledSubmitBtn = !_.isEmpty(formErrors) || disabledSubmit || openMode === 'read';
		const isNotEmpty = questionParams && questionParams.blocks && questionParams.blocks[0] && questionParams.blocks[0].widgets.length;
		const buttonClasses = cx('btn btn-primary btn-right', { 'btn-disabled': disabledSubmitBtn });
		const onRequest = disabledSubmitBtn && { disabled: true };
		return (
			<CSSTransition
				in={true}
				classNames='fade'
				appear={true}
				enter={true}
				exit={true}
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<ModalPortal
					onClose={onClose}
					className={cx('modal-medium','modal-center', 'quality')}
					onMin={onMin}
				>
					<div className={styles.appealWindow}>
						<div className={styles.appealQualityWindowHeader}>
							<div className={styles.appealQualityWindowTitle}>{t('quality.Title')} ID {appeal.id}</div>
						</div>
						<div className={styles.appealQualityWindowForm}>
							<Form onSubmit={this.handleSubmit}>
								<div className={styles.appealQualityForm}>
									{isNotEmpty ? questionParams.blocks.map(this.renderRanks) :
										<div style={{ textAlign: 'center' }}> {t('quality.Empty')}</div>}
								</div>
								<div className={styles.appealFinal}>
									<div className={styles.appealFinalContainer}>
										<span
											className={styles.appealFinalQualityLabel}>{t('quality.TotalScore').toUpperCase()}</span>
										<span
											className={styles.appealFinalQuality}>{this.state.totalScore}</span>
									</div>
									<div className={styles.appealFinalContainer}>
										{isNotEmpty ?
											<button type='submit' className={buttonClasses} {...onRequest}>
												{t('quality.Success')}
											</button> :
											<button className='btn btn-primary btn-right'
													onClick={this.handlerGoBack}>{t('quality.Back')}</button>}
									</div>
								</div>
							</Form>
						</div>
					</div>
				</ModalPortal>
			</CSSTransition>
		);
	}
}

AppealQuality.propTypes = {
	appeal: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	edit: PropTypes.bool.isRequired
};

