import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';

export default function MainBox ({ children, ...props }) {
	return (
		<div className={styles.mainBoxService} {...props} >{children}</div>
	);
}

MainBox.propTypes = {
	children: PropTypes.any,
	props: PropTypes.any
};
