import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader/index';

import GridWrapper from '../../../../custom-hooks/GridWrapper';
import { setApi } from '../../../../reducers/grid';

const CustomersList = (props) => {
    const [loadingState, setLoadingState] = useState(true);
    const { history, updateApiGrid, gridFilters, onSelectItem} = props;

    const init = {
        key: 'customers',
        method: 'post',
        code: 'CUSTOMER',
        type: 'customer',
        query: gridFilters ? gridFilters.query : "",
        filterObject: gridFilters ? gridFilters.filterObject : [],
        // isDynamic: true,
        configUrlKey: "config_grid",
        configKey: 'customer',
        configCode: 'CUSTOMER',
    };
    const gridKey = `${init.key}_${init.path || ''}`;

    const getParams = () => ({
        selectItem: (item) => onSelectItem(item),
    });

    useEffect(() => {
        if (gridFilters) {
            updateApiGrid({ ...gridFilters, page: 1 }, gridKey);
        }
    }, [gridFilters]);

    const onStart = () => setLoadingState(true);

    const onFinish = () => {
        setLoadingState(false);
    };

    return (
        <div className="content-wrapper with-fixed-status">
            {loadingState && <Loader />}
            <GridWrapper
                tuner={() => init}
                initUpdate={false}
                gridParams={getParams()}
                onStart={onStart}
                onFinish={onFinish}
                destroyOnUnmount
            />
        </div>
    );
};

export default connect(null, { updateApiGrid: setApi })(withRouter(CustomersList));
