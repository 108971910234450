// Styling scrollbar into iframe
export default `
	@media print {
		@page {
			size: A4;
			margin: 20mm 15mm 20mm 30mm;
		}
	}

	::-webkit-scrollbar{width: 12px; border-radius: 12px;}
	::-webkit-scrollbar:horizontal{height: 12px}
	::-webkit-scrollbar-track{background-color: #fff; border-radius: 12px;border: 4px solid #fff;}
	::-webkit-scrollbar-track:horizontal{}
	::-webkit-scrollbar-thumb{background-color: #939394; border-radius:12px; border: 4px solid #fff;}
	::-webkit-scrollbar-button{}
	::-webkit-scrollbar-corner{display:none}

	.page {
		max-height: 700px;
		overflow: visible;
		font-family: 'SF Pro Regular', Arial, sans-serif;
	}

	.page table {
		margin-bottom: 20px;
	}

	.page > table:last-child {
		margin-bottom: 0px;
	}

	table,
	caption,
	thead td {
		border: 1px solid #626262;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		color: #191919;
		font-size: 12px;
		font-family: 'SF Pro Bold', Arial, sans-serif;
	}

	caption {
		background-color: #e8f0f6;
		font-size: 16px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		font-weight: bold;
		border-bottom: 0;
	}


	caption strong {
		font-size: 24px;
	}

	thead {
		background-color: #e8f0f6;
		text-align: center;
		font-weight: bold;
	}

	strong {
		font-weight: bold;
	}

	i {
		font-style: italic;
	}

	td {
		border: 1px solid #626262;
		height: 36px;
		padding: 5px 12px 5px 12px;
	}

	td.centered {
		text-align: center;
	}

	.w1-12 {
		width: calc(100%/12);  
	}

	.w2-12 {
		width: calc(100%/12*2);  
	}

	.w3-12 {
		width: calc(100%/12*3);  
	}

	.w4-12 {
		width: calc(100%/12*4);  
	}

	.w6-12 {
		width: calc(100%/12*6);  
	}
`;
