import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeCreateNewCustomerModal } from 'actions/ui';
import ModalPortal from 'components/ModalPortal/index';
import MessageModal from 'components/MessageModal/index';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { KeyCodes } from 'constants/index';
import CreateCustomerForm from './CreateCustomerForm/index';

const mapStateToProps = state => ({
	isOpen: state.ui.showCreateNewCustomerModal,
	modalContext: state.ui.newCustomerModalContext
});

const mapDispatchToProps = dispatch => ({
	closeCreateNewCustomerModal: () => dispatch(closeCreateNewCustomerModal())
});

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class CreateNewCustomerModal extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			warningPopupOpen: false,
			warningWillOpen: false,
		};
		this.onClose = this.onClose.bind(this);
		
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.enableWarning = this.enableWarning.bind(this);
		this.handleCloseNewCustomerModal = this.handleCloseNewCustomerModal.bind(this);
	}
	
	componentDidMount () {
		document.addEventListener('keydown', this.handleKeyPress);
	}
	
	componentWillUnmount () {
		document.removeEventListener('keydown', this.handleKeyPress);
	}


	handleCloseNewCustomerModal () {
		this.props.closeCreateNewCustomerModal();
		if (this.props.modalContext && this.props.modalContext.onClose && typeof this.props.modalContext.onClose === 'function') {
			this.props.modalContext.onClose();
		}
	}
	
	onClose () {
		if (this.state.warningWillOpen) {
			this.setState({ warningPopupOpen: true });
		} else {
			this.handleCloseNewCustomerModal();
		}
	};
	
	enableWarning () {
		this.setState({ warningWillOpen: true });
	};
	
	handleKeyPress (e) {
		
		if (e.keyCode === KeyCodes.ESCAPE) {
			this.onClose();
		}
		//navigate Tab key
		if (e.keyCode === KeyCodes.TAB) {
			e.preventDefault();
			const modal = document.querySelector(".modal-window");
			const inputs = modal.querySelectorAll("form .input-field");
			const focused = document.activeElement;
			
			if(focused.nodeName !== 'BODY') {
				inputs.forEach((input, index) => {
					if (focused === input) return inputs[index + 1].focus();
					if (focused === inputs[inputs.length - 1]) return inputs[0].focus();
				});
			}
			else return inputs[0].focus();
		}
	};
	
	handleCloseWarningPopup = () => this.setState({ warningPopupOpen: false });
	
	onClickDangerButton = () => {
		this.handleCloseWarningPopup();
		this.handleCloseNewCustomerModal();
	};
	
	render () {
		const { isOpen, t, modalContext } = this.props;
		return (
			<>
				<CSSTransition
					in={isOpen}
					classNames='fade'
					appear={true}
					enter={true}
					exit={true}
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					<ModalPortal className="ordering-component-ui-core-wrapper" onClose={this.onClose}>
						<CreateCustomerForm {...modalContext} enableWarning={this.enableWarning} onClose={this.onClose} />
					</ModalPortal>
				</CSSTransition>
				{
					this.state.warningPopupOpen &&
					<ModalPortal
						onClose={this.handleCloseWarningPopup}
						className='modal-small ordering-component-ui-core-wrapper'
					>
						<MessageModal
							titleModal={t('persons.attention')}
							contentModalText={t('modalWindowsLabels.notSavedWarning')}
							dangerButton={true}
							dangerButtonText={t('close')}
							onClickDangerButton={this.onClickDangerButton}
							secondaryButton={true}
							secondaryButtonText={t('cancel')}
							onClickSecondaryButton={this.handleCloseWarningPopup}
						/>
					</ModalPortal>
				}
			</>
		);
	}
}

CreateNewCustomerModal.propTypes = {
	closeCreateNewCustomerModal: PropTypes.func,
	modalContext: PropTypes.object,
	isOpen: PropTypes.bool
};

export default CreateNewCustomerModal;
