// 'label:label:label:value' => 'value'
export function getValueFromStr(stringValue) {
    if (!stringValue) return null;
    const arrayValue = stringValue.split(":");
    return arrayValue.slice(arrayValue.length - 1)[0]; // get last value
}

// export function getDefaultValuesFromWidgets(widgets) {
//     const defaultValues = widgets.reduce((prev, curr) => {
//         if (curr.widgetType === "customComponent") {
//             return prev;
//         }
//         return {
//             ...prev,
//             [curr.key]: curr.defaultValue,
//         };
//     }, {});
//     return defaultValues;
// }

// set initialValues in redux form in format { key: value }
export function getInitialValuesFromWidgets(widgets, isOnceInitialized) {
    const initialValues = widgets.reduce((prev, curr) => {
        // do not initialize for a customComponent
        if (curr.widgetType === "customComponent") {
            return prev;
        }

        // for multiselect always return 'savedValus' new field
        if (curr.widgetType === "multiselect") {
            return {
                ...prev,
                [curr.key]: curr.savedValues,
            };
        }
        // for files always return 'savedValus' new field in case it is not an empty fiels
        // could be probably combined with the logic upper for "multiselect"
        if (Array.isArray(curr.savedValues) && curr.savedValues.length > 0 && curr.widgetType.startsWith("file")) {
            return {
                ...prev,
                [curr.key]: curr.savedValues,
            };
        }

        // handle value init for the rest of the widgets
        let value = isOnceInitialized ? curr.savedValue : curr.savedValue || curr.defaultValue;
        // clear up value if there no such value is available in curr.values (select options)
        if (curr.values && Array.isArray(curr.values) && curr.values.length > 0 && value) {
            const isValueInValues = curr.values.find(val => val.key === value);
            // console.log({values: curr.values, value, isValueInValues});
            if (!isValueInValues) {
                value = null;
            }
        }
        // do not initialise a savedValue in the refKey widget, if refKey value is not available in values (options of select for example)
        if (curr.refKey && curr.values && Array.isArray(curr.values) && curr.values.length > 0) {
            const refWidget = widgets.find(widget => widget.key === curr.refKey);
            if (refWidget) {
                const refValue = refWidget.savedValue || refWidget.defaultValue;
                if (![null, undefined, ""].includes(refValue)) {
                    const refOptions = curr.values.filter(val => {
                        // added additional check for refVal options to be an array
                        if (val.refVal && Array.isArray(val.refVal)) {
                            return val.refVal.includes(refValue);
                        }
                        // othervise return value
                        return val;
                    });
                    const isValueExist = refOptions.find(option => option.key === value);
                    if (!isValueExist) {
                        value = null;
                    }
                }
            }
        }
        return {
            ...prev,
            [curr.key]: curr.rule === "#[callMethod]" ? getValueFromStr(value) : value,
        };
    }, {});

    return initialValues;
}
