import React, {useState} from 'react';
import {ComboBox} from 'ui-core-dashboard';
import MultiSelectWithSearch from 'components/Common/MultiSelectWithSearch/ReduxFormDecorator';
import AppealTypeSelect from "../../pages/Appeal/Form/components/AppealTypeSelect/AppealTypeSelect";
import { components } from 'react-select';

const CustomMenu = (props) => {
	const {isDisabledSelectAll, setIsDisabledSelectAll, title, value, index} = props.option;

	const handleMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDisabledSelectAll && setIsDisabledSelectAll(false);
		props.onSelect(props.option, event);
	};
	
	const handleMouseDownActionSelect = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (isDisabledSelectAll) return;
		
		setIsDisabledSelectAll && setIsDisabledSelectAll(true);
		
		props.option.action(props.onSelect);
	};
	
	const handleMouseDownActionClear = (event) => {
		event.preventDefault();
		event.stopPropagation();
		
		setIsDisabledSelectAll && setIsDisabledSelectAll(false);
		props.option.action();
	};
	
	const handleMouseEnter = (event) => {
		props.onFocus(props.option, event);
	};
	const handleMouseMove = (event) => {
		if (props.isFocused) return;
		props.onFocus(props.option, event);
	};
	
	if(value === 'clear' ){
		return (
			<div className={props.className}
				 onMouseDown={handleMouseDownActionClear}
				 onMouseEnter={handleMouseEnter}
				 onMouseMove={handleMouseMove}
				 style={{color:'#3F70C5'}}
				 title={title}>
				{props.children}
			</div>
		);
	}
	if(value === 'select'){
		return (
			<div className={props.className}
				 onMouseDown={handleMouseDownActionSelect}
				 onMouseEnter={handleMouseEnter}
				 onMouseMove={handleMouseMove}
				 style={{borderTop: '1px solid #DADCE0'}}
				 title={title}>
				{props.children}
			</div>
		);
	}
	return (
		<div className={props.className}
			 style={{borderTop: index === 0 ? '1px solid #DADCE0' : null}}
			 onMouseDown={handleMouseDown}
			 onMouseEnter={handleMouseEnter}
			 onMouseMove={handleMouseMove}
			 title={title}>
			{props.children}
		</div>
	);
};

export default function ComboBoxUniversal(props) {
	const {isMultiset, isType, searchable, dict_search, ...rest} = props;
	const [isDisabledSelectAll, setIsDisabledSelectAll] = useState(false);
	
	if(isMultiset || dict_search) return <MultiSelectWithSearch propperClassName={'combo-universal'} {...rest}/>;

	if(isType) return <AppealTypeSelect className={'combo-universal'} {...rest}/>;
	const {options: pureOptions = [], multi} = rest;

	const options = multi ? pureOptions.map( it => it.value === 'select' ? {...it, isDisabledSelectAll, setIsDisabledSelectAll} : {...it, setIsDisabledSelectAll}) : pureOptions;
	return <ComboBox
				className={'combo-universal'}
				searchable={searchable}
				optionComponent={CustomMenu}
				{...rest}
				options={options}
			/>
}
