import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Proxy from 'components/HOC/ContentTabProxy';
import Tab from 'components/Tab';
import GridWrapper from 'components/GridWrapper';
import { createCheckPropsFunction } from 'helpers';
import permissions, {checkPermissions} from "../../../config/permissions";

const tabNames = {
	appeals: 'appeals',
	link: 'link',
	history: 'history'
};

const mapStateToProps = (state, props) => {
	return {
		tabs: state.tabs,
		modificationCount: _.get(state, `customer.persons[${props.match.params.id}].contactPersonModificationCount`),
		isHistoryView: checkPermissions(permissions.HistoryOperations.view_history)
	};
};

@withRouter
@connect(mapStateToProps)
class TabContent extends React.Component {
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		
		if (isPropChanged('modificationCount')) {
			this.props.refresh(this.props.match, this.props.tabs);
		}
	}
	
	renderTab = tab => (
		<Tab val={tab} key={tab} tab={tab} />
	);
	
	onSelectItem = item => {
		const {subTab} = this.props.match.params;
		if (subTab === 'link'){
			return 	this.props.history.push(`/customer/${item.entityId}/person/${this.props.match.params.id}/link`);
		}
		else return null;
	};
	
	render () {
		const { data, match, tabs, isContactsView, isHistoryView } = this.props;

		if (!isContactsView) {
			delete tabNames.contact;
		}
		if (!isHistoryView) {
			delete tabNames.history;
		}

		if (!data) return null;

		return (
			<div className='contentWrapper'>
				<div id='card-bottom-row' className='column'>
					<div className='top-right'>
						{Object.keys(tabNames).map(this.renderTab)}
					</div>
				</div>
				<div className='gridWrapper'>
					<GridWrapper
						data={data}
						tabs={tabs}
						match={match}
						groups={{
							selectItem: (item) => this.onSelectItem(item)
						}}
					/>
				</div>
				
			</div>
		);
	}
}

export default Proxy(TabContent, {
	api: data => ({
		key: `person_${data.subTab}`,
		data: { personId: data.id }
	}),
	tabType: data => data && data.appealId ? `appeals_${data.appealId}` : `person_${data.id}`,
	name: data => data && data.appealId ? `appeals_${data.appealId}` : `person_${data.id}`,
	queryKey: data => data && data.subTab,
	storeName: data => data && data.subTab
});

TabContent.propTypes = {
	data: PropTypes.object,
	match: PropTypes.object,
	user: PropTypes.object
};

