import React, { useState, useEffect } from 'react';
import validate from "./validate";
import DatePicker from 'components/Common/DatePicker';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {reduxForm, Field, initialize, change, getFormValues} from 'redux-form';
import { withTranslation } from "react-i18next";
import moment from 'moment';
import './styles.scss';
import baseService from "../../../services/BaseService";
import CheckBoxGroup from "../../Common/CheckBoxGroup";
import ComboBoxUniversal from "../../Common/ComboBoxUniversal";
import { Input, ComboBox } from 'ui-core-dashboard';
import DatePickerCalendar from '../../Common/DatePickerCalendar';
import DateTimePickerCalendarRange from '../../Common/DatePickerCalendar/DatePickerCalendarRange';
import {buildThreePath} from "helpers";

const mapStateToProps = (state, ownProps) => ({
	accountNumbers: state.customer.billingCards,
	formValues: getFormValues(ownProps.form)(state),
});

const mapDispatchToProps = {

};

function EventFiltersBlock(props) {
	const {
		t, formValues, externalId, initialize, filters, setFilters
	} = props;
	const [asyncState, setAsyncState] = useState({});
	
	useEffect( () =>{
		getFilters()
	},[externalId]);
	
	const getFilters = async() => {
		const filterAttr = await baseService.get('attributes', { data: {code:'FTOP_CLEVENT_CUSTOMER_SITE' } });
		
		if (filterAttr && filterAttr.result){
			setFilters(filterAttr.result);
			setDefaultFilterParams(filterAttr.result);
		}
	};
	
	const setNextMonth = () => {
		initialize({
			'CLEVENT_RANGE_START_DATE_NAV@FROM': moment(formValues['CLEVENT_RANGE_START_DATE_NAV@FROM']).add(1, 'month').startOf('month'),
			'CLEVENT_RANGE_START_DATE_NAV@TO': moment(formValues['CLEVENT_RANGE_START_DATE_NAV@FROM']).add(1, 'month').endOf('month')
		});
	};
	
	const setPrevtMonth = () => {
		initialize({
			'CLEVENT_RANGE_START_DATE_NAV@FROM': moment(formValues['CLEVENT_RANGE_START_DATE_NAV@FROM']).subtract(1, 'month').startOf('month'),
			'CLEVENT_RANGE_START_DATE_NAV@TO': moment(formValues['CLEVENT_RANGE_START_DATE_NAV@FROM']).subtract(1, 'month').endOf('month')
		});
	};
	const setCurrentMonth = () => {
		initialize({
			'CLEVENT_RANGE_START_DATE_NAV@FROM': moment().startOf('month'),
			'CLEVENT_RANGE_START_DATE_NAV@TO': moment().endOf('month')
		});
	};
	
	const setDefaultFilterParams = (filters) => {
		if (filters && filters.find(it => it.code === "CLEVENT_RANGE_START_DATE_NAV")){
			initialize({
				'CLEVENT_RANGE_START_DATE_NAV@FROM': moment().startOf('month'),
				'CLEVENT_RANGE_START_DATE_NAV@TO': moment().endOf('month')
			});
		}
	};
	
	
	const asyncParams = (search, code, id) => {
		if (!search) {
			setAsyncState({ [code]: [] });
		} else {
			baseService.post('async_search_attributes', { data: { id, search }, jsonType: true }).then((resp) => {
				setAsyncState({
					[code]: resp.result.map(field => ({
						label: field.value || '',
						value: field.id || ''
					}))
				});
			});
		}
	};
	
	const getComponentByType = (type, search, multiset) => {
		let components = {
			LIST_CHECKBOX: CheckBoxGroup,
			LIST: ComboBoxUniversal,
			NUMBER: Input,
			STRING: Input,
			DATE: DatePicker,
			DATECAL: DatePickerCalendar,
			DATENAVMO: DatePicker,
		};
		
		if(multiset && type === 'DATECAL'){
			components = {
				DATECAL: DateTimePickerCalendarRange
			}
		}
		if (search) {
			components = {
				THREE: ComboBoxUniversal,
				LIST: ComboBoxUniversal,
			};
		}
		
		return components[type] || Input;
	};
	
	const convertFieldConfigToProps = fieldConfig => {
		const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
		const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
		const options = fieldConfig.type === 'THREE' ? { children: buildThreePath(fieldConfig.dict, false, { children: 'children' }) } : getOptions(fieldConfig.dict || []);
		const searchable = !!fieldConfig.search;
		const isMultiset = fieldConfig.type === 'THREE';
		const title = fieldConfig.type === 'THREE' && fieldConfig.name;
		const clearValueText = t('clear');
		const clearAllText = t('clearValue');
		let config = {
			key: fieldConfig.id,
			component: getComponentByType(fieldConfig.type, fieldConfig.search, fieldConfig.multiset_allowed === 'Y'),
			name: fieldConfig.code,
			label: fieldConfig.name,
			title,
			type: fieldConfig.type,
			options,
			multi,
			tree: fieldConfig.type === 'THREE',
			errorPlaceholder: true,
			// valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
			// leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
            keySelectField: 'key',
            leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			searchable,
			isMultiset,
			clearValueText,
			clearAllText,
			placeholder: t('dontSelect'),
			noResultsText:t('noResultsFound'),
			onBlur:(e) => {e.preventDefault(); return false;}
		};
		
		if (fieldConfig.dict_search) {
			const {disallow_select_all} = fieldConfig;
			
			config = {
				...config,
				disallow_select_all,
				notFilteredOptions:true,
				dict_search:true,
				options: asyncState[fieldConfig.code] || [],
				async: {
					callback: asyncParams,
					code: fieldConfig.code,
					id: fieldConfig.id,
				}
			};
		}
		return config;
	};
	
	const renderField = fieldConfig => {
		const fieldProps = convertFieldConfigToProps(fieldConfig);
		if ( ['DATE', 'DATENAVMO'].includes(fieldConfig.type) ) {
			return renderDateField(fieldConfig, fieldProps);
		}
		return (
			<Field {...fieldProps} />
		);
	};
	const renderNavs = () => {
		return(
			<div className='fc-toolbar-chunk'>
				<div className="fc-button-group">
					<button
						className="fc-prev-button fc-button fc-button-primary"
						type="button"
						aria-label="prev"
						title={t("calendar.prev")}
						onClick={setPrevtMonth}
					>
						<span className="fc-icon fc-icon-chevron-left"></span>
					</button>
					<button
						className="fc-next-button fc-button fc-button-primary"
						type="button"
						aria-label="next"
						title={t("calendar.next")}
						onClick={setNextMonth}
					>
						<span className="fc-icon fc-icon-chevron-right"></span>
					</button>
				</div>
				<button
					className="fc-today-button fc-button fc-button-primary"
					type="button"
					onClick={setCurrentMonth}
				>
					{t("calendar.todayMonth")}
				</button>
			</div>
		)
	};
	
	const renderDateField = (fieldConfig, fieldProps) => {
		if (fieldConfig.def_condition === 'BTW') {
			const fromDateProps = {
				...fieldProps,
				name: `${fieldConfig.code}@FROM`,
				label: `${fieldProps.label} (З)`,
				key: `${fieldConfig.id}@FROM`,
				onlyDate: true,
				showErrorHint: true,
				isAsync: true
			};
			const toDateProps = {
				...fieldProps,
				name: `${fieldConfig.code}@TO`,
				label: `${fieldProps.label} (По)`,
				key: `${fieldConfig.id}@TO`,
				onlyDate: true,
				showErrorHint: true,
				isAsync: true
			};
			if (fieldConfig.type === 'DATENAVMO'){
				return [
					<Field {...fromDateProps} />,
					<Field {...toDateProps} />,
					renderNavs()
				]
		}
			return [
				<Field {...fromDateProps} />,
				<Field {...toDateProps} />
			];
		}
		
		return <Field {...fieldProps} />;
	};
	
	return (
		<div className={'event-filter-wrapper ordering-component-ui-core-wrapper'}>
				{filters ? filters.map(renderField) : null}
		</div>
	
	);
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)
(reduxForm(
	{
		validate,
		enableReinitialize: false,
		keepDirtyOnReinitialize: true,
		destroyOnUnmount: true
	})
(EventFiltersBlock)));
