import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import 'styles/modules/popup.module.scss';

class Popup extends Component {
	onClickOutside = (event) => {
		const { onClickOutside } = this.props;
		onClickOutside && onClickOutside(event);
	};
	
	onWheel = (event) => {
		event.stopPropagation();
	};
	
	componentDidMount () {
		if (this.props.onMounted) {
			this.props.onMounted();
		}
	}
	
	render () {
		const { children, place, top, left } = this.props;
		return (
			<ClickOutsideHolder
				onClickOutside={this.onClickOutside}
				className={`popup ${place}`}
				top={top}
				left={left}
				onWheel={this.onWheel}
			>
				{children}
			</ClickOutsideHolder>
		);
	}
}

Popup.propTypes = {
	place: PropTypes.string,
	top: PropTypes.number,
	left: PropTypes.number,
	onClickOutside: PropTypes.func,
};

export default Popup;
