import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import ReactSVG from "react-svg";

@withTranslation()
class File extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			showMessageModal: false
		};
		this.input = React.createRef();
		this.closeMessageModal = this.closeMessageModal.bind(this);
		this.openMessageModal = this.openMessageModal.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.getFileLink = this.getFileLink.bind(this);
	}
	
	closeMessageModal () {
		this.setState({ showMessageModal: false });
	}
	
	onDeleteClick () {
		this.closeMessageModal();
		this.props.onDelete(this.props.index);
	}
	
	openMessageModal () {
		this.setState({ showMessageModal: true });
	}
	
	getFileSize = (fileSize, unit = 0) => {
		const SIZE_ORDER = 1024;
		const UNITS = ['Б', 'КБ', 'МБ'];
		
		if (fileSize === undefined || fileSize === null || fileSize.length === 0) {
			return '0 Кб';
		}
		else if (fileSize < SIZE_ORDER) {
			return `${fileSize}${UNITS[unit]}`;
		}
		else {
			const fixedFileSize = Number.parseInt((fileSize / SIZE_ORDER).toFixed(0), 10);
			return this.getFileSize(fixedFileSize, unit + 1);
		}
	};
	
	getFileLink (type) {
		const fileId = this.props.file.id;
		switch (this.props.type) {
		case 'remote':
			return `../mw/file?fileId=${fileId.id || fileId}${type === "download" ? "&download=1" : "&download=0"}`;
		case 'local': {
			// console.log({file: this.props.file, props: this.props});
			const blob = new Blob(
				[ this.props.file ], // Blob parts.
				{
					type : `${this.props.file.type};charset=utf-8`
				}
			);
			// console.log({blob});
			// return URL.createObjectURL(this.props.file);
			return URL.createObjectURL(blob);

		}
		
		default:
			return '';
		}
	};
	
	render () {
		const { file, t, disabled } = this.props;
		const { showMessageModal } = this.state;
		const fileLabel = `${file.name} (${this.getFileSize(file.size || file.fileSize)})`;
		return (
			<div className='kb-file-wrapper'>
				<CSSTransition
					in={showMessageModal}
					classNames='fade'
					appear
					enter
					exit
					timeout={500}
					mountOnEnter
					unmountOnExit
				>
					<ModalPortal onClose={this.closeMessageModal} className='modal-small modal-center'>
						<MessageModal
							titleModal={t('modalWindowsLabels.deleteFileTitle')}
							contentModalText={`${t('modalWindowsLabels.deleteFileMessage')}: "${fileLabel}"`}
							primaryButton
							primaryButtonText={t('modalWindowsLabels.allow')}
							onClickPrimaryButton={this.onDeleteClick}
							secondaryButton
							secondaryButtonText={t('modalWindowsLabels.disallow')}
							onClickSecondaryButton={this.closeMessageModal}
						/>
					</ModalPortal>
				</CSSTransition>
				{/* <a href={this.getFileLink()} download={file.name} title={fileLabel}>
					{fileLabel}
				</a>
				{!disabled && <ReactSVG className='icon-wrap' svgClassName='' src='data/svg/icon-remove-trash.svg' onClick={this.openMessageModal}/>} */}
				<a className="file-preview" target="_blank" href={this.getFileLink("preview")} title={file.name}>
                    {file.name}
                </a>
                <div className="kb-file-actions">
                    <a className="file-download" target="_blank" href={this.getFileLink("download")} title={file.name} download={file.name}>
                        <ReactSVG className="svg-download" src="/data/svg/download.svg"/>
                    </a>
                    {!disabled && <ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" onClick={this.openMessageModal} />}
                </div>
			</div>
		);
	}
}

File.propTypes = {
	type: PropTypes.oneOf(['local', 'remote']),
	file: PropTypes.object,
	index: PropTypes.number,
	onDelete: PropTypes.func
};

export default File;
