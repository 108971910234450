import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getData, init, update } from "../../../../reducers/componator";

import cx from "classnames";

import "./componator.scss";

class Componator extends React.Component {
    componentDidMount() {
        const { componatorKey: key, initData, initComponator } = this.props;
        initComponator({ ...initData, key });
    }

    getField = (field, key) => {
        const { t, displayedField, indexedField, updateComponator, withoutTranslation } = this.props;
        const selected = this.props[key];
        const other = key === "inputSelected" ? "outputSelected" : "inputSelected";

        const selectField = () => {
            const idx = this.findArrIndex(selected, field);
            idx === -1 ? selected.push(field) : selected.splice(idx, selected.length === 1 ? 0 : 1);
            updateComponator({ [key]: selected, [other]: [] });
        };

        const moveDbl = () => {
            const sourceKey = key === "inputSelected" ? "inputFields" : "outputFields";
            const targetKey = key === "inputSelected" ? "outputFields" : "inputFields";
            const sourceScope = this.props[sourceKey];
            const targetScope = this.props[targetKey];

            updateComponator({
                [sourceKey]: sourceScope.filter(v => v[indexedField] !== field[indexedField]),
                [targetKey]: targetScope.concat(field),
                [key]: selected.filter(v => v[indexedField] !== field[indexedField]),
            });
        };

        return (
            <div
                key={field[displayedField]}
                className={`field ${selected.find(v => v[indexedField] === field[indexedField]) ? "selected" : ""}`}
                onClick={selectField}
                onDoubleClick={moveDbl}
                // onDoubleClick={key === 'inputSelected' ? this.moveIn : this.moveOut}
            >
                {withoutTranslation ? field[displayedField] : t(field[displayedField])}
            </div>
        );
    };

    findArrIndex = (arr, val) => {
        const { indexedField } = this.props;
        return arr.findIndex(v => v[indexedField] === val[indexedField]);
    };

    moveAllIn = () => {
        const { inputFields, outputFields, updateComponator } = this.props;

        updateComponator({
            inputFields: [],
            outputFields: [...outputFields, ...inputFields],
            inputSelected: [],
            outputSelected: [],
        });
    };

    moveAllOut = () => {
        const { inputFields, outputFields, updateComponator } = this.props;

        updateComponator({
            inputFields: [...inputFields, ...outputFields],
            outputFields: [],
            inputSelected: [],
            outputSelected: [],
        });
    };

    moveIn = () => {
        const { inputFields, outputFields, inputSelected, indexedField, updateComponator } = this.props;
        const keys = inputSelected.map(v => v[indexedField]);

        updateComponator({
            inputFields: inputFields.filter(v => !keys.includes(v[indexedField])),
            outputFields: outputFields.concat(inputSelected),
            inputSelected: [],
            outputSelected: [],
        });
    };

    moveOut = () => {
        const { inputFields, outputFields, outputSelected, indexedField, updateComponator } = this.props;
        const keys = outputSelected.map(v => v[indexedField]);

        updateComponator({
            inputFields: inputFields.concat(outputSelected),
            outputFields: outputFields.filter(v => !keys.includes(v[indexedField])),
            inputSelected: [],
            outputSelected: [],
        });
    };

    moveUp = () => {
        const { outputFields, outputSelected, updateComponator } = this.props;
        const index = this.findArrIndex(outputFields, outputSelected[0]);
        outputFields.splice(index, 1);
        outputFields.splice(index - 1, 0, outputSelected[0]);
        updateComponator({ outputFields });
    };

    moveDown = () => {
        const { outputFields, outputSelected, updateComponator } = this.props;
        const index = this.findArrIndex(outputFields, outputSelected[0]);

        outputFields.splice(index, 1);
        outputFields.splice(index + 1, 0, outputSelected[0]);
        updateComponator({ outputFields });
    };

    render() {
        const { t, inputFields, outputFields, inputSelected, outputSelected } = this.props;
        const single = outputSelected.length === 1;
        let btnUpDisabled = true;
        let btnDownDisabled = true;

        if (single) {
            const index = this.findArrIndex(outputFields, outputSelected[0]);
            btnUpDisabled = [-1, 0].includes(index);
            btnDownDisabled = [-1, outputFields.length - 1].includes(index);
        }

        return (
            <div className="componator">
                <div className="componator-body">
                    <div className="body-wrapper">
                        <div className="input-label export-modal-title">{t("available_fields")}</div>
                        <div className="data-body">{inputFields.map(v => this.getField(v, "inputSelected"))}</div>
                    </div>

                    <div className="move-handlers">
                        <button
                            className={cx("btn btn-primary", { disabled: !inputFields.length })}
                            disabled={!inputFields.length}
                            onClick={this.moveAllIn}
                        >
                            <i className="icon-chevron-double-right" />
                        </button>
                        <button
                            className={cx("btn btn-primary", { disabled: !inputSelected.length })}
                            disabled={!inputSelected.length}
                            onClick={this.moveIn}
                        >
                            <i className="icon-arrow-right" />
                        </button>
                        <button
                            className={cx("btn btn-primary", { disabled: !outputSelected.length })}
                            disabled={!outputSelected.length}
                            onClick={this.moveOut}
                        >
                            <i className="icon-arrow-left" />
                        </button>
                        <button
                            className={cx("btn btn-primary", { disabled: !outputFields.length })}
                            disabled={!outputFields.length}
                            onClick={this.moveAllOut}
                        >
                            <i className="icon-chevron-double-left" />
                        </button>
                    </div>

                    <div className="body-wrapper">
                        <div className="input-label export-modal-title">{t("selected_fields")}</div>
                        <div className="data-body">{outputFields.map(v => this.getField(v, "outputSelected"))}</div>
                    </div>

                    <div className="move-handlers">
                        <button
                            className={cx("btn btn-primary", { disabled: btnUpDisabled })}
                            disabled={btnUpDisabled}
                            onClick={this.moveUp}
                        >
                            <i className="icon-up" />
                        </button>
                        <button
                            className={cx("btn btn-primary", { disabled: btnDownDisabled })}
                            disabled={btnDownDisabled}
                            onClick={this.moveDown}
                        >
                            <i className="icon-down" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

Componator.propTypes = {
    t: PropTypes.func,
    initData: PropTypes.object,
    displayedField: PropTypes.string,
    indexedField: PropTypes.string,
};

export default connect(
    (state, ownProps) => ({
        ...getData(state.componator, ownProps.componatorKey),
    }),
    { initComponator: init, updateComponator: update }
)(Componator);
