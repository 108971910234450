import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import ReactMarkdown from 'react-markdown';
// import { useTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

export default function NotificationNote(props) {
    const { message, subject, body, date } = props;
    // const { t } = useTranslation();

    const getMarkdownProps = (value) => {
        return {
            source: value,
            escapeHtml: false,
            unwrapDisallowed: true, // still place content of disallowedTypes
            disallowedTypes: ['paragraph'], // remove all paragraphs <p></p>render
            className: 'notification-title-text',
        };
    };

    return (
        <div className={styles.httpErrorNote}>
            <ReactSVG
                className="wrapper-hovered"
                svgClassName="icon"
                src={`/data/svg/icon-notify.svg`}
            />
            {subject || body ? (
                <>
                    {subject && (
                        <div className={styles.notificationSubject}>
                            {<ReactMarkdown {...getMarkdownProps(subject)} />}
                        </div>
                    )}
                    {body && (
                        <div className={styles.notificationBody}>
                            {<ReactMarkdown {...getMarkdownProps(body)} />}
                        </div>
                    )}
                    { date && <div className={styles.notificationDateSSE}>{date}</div> }
                </>
            ) : (
                <div className="m-t-8">
                    <ReactMarkdown {...getMarkdownProps(message)} />
                </div>
            )}
        </div>
    );
}

NotificationNote.propTypes = {
    message: PropTypes.string.isRequired,
};
