import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeCreateNewCustomerModal } from 'actions/ui';
import ModalPortal from 'components/ModalPortal/index';
import MessageModal from 'components/MessageModal/index';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { KeyCodes } from 'constants/index';
import CreateCustomerForm from './CreateCustomerForm/index';
import './styles.scss';

const mapStateToProps = state => ({
	isOpen: state.ui.showCreateNewCustomerModal,
	modalContext: state.ui.newCustomerModalContext
});

const mapDispatchToProps = dispatch => ({
	closeCreateNewCustomerModal: () => dispatch(closeCreateNewCustomerModal())
});

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
class CreateNewCustomerModal extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			warningPopupOpen: false,
			enableWarning: false,
		};
		this.onClose = this.onClose.bind(this);
		
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.enableWarning = this.enableWarning.bind(this);
	}
	
	componentDidMount () {
		document.addEventListener('keydown', this.handleKeyPress);
	}
	
	componentWillUnmount () {
		document.removeEventListener('keydown', this.handleKeyPress);
	}
	
	onClose () {
		const { enableWarning } = this.state;
		if (enableWarning) {
			this.setState({ warningPopupOpen: true });
		} else {
			this.props.closeCreateNewCustomerModal();
		}
	};
	
	enableWarning () {
		this.setState({ enableWarning: true });
	};
	
	handleKeyPress (e) {
		if (e.keyCode === KeyCodes.ESCAPE) {
			this.onClose();
		}
	};
	
	handleCloseWarningPopup = () => this.setState({ warningPopupOpen: false });
	
	onClickDangerButton = () => {
		this.handleCloseWarningPopup();
		this.props.closeCreateNewCustomerModal();
	};
	
	render () {
		const { isOpen, t, modalContext } = this.props;
		return (
			<Fragment>
				<CSSTransition
					in={isOpen}
					classNames='fade'
					appear={true}
					enter={true}
					exit={true}
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					<ModalPortal onClose={this.onClose}>
						<CreateCustomerForm {...modalContext} enableWarning={this.enableWarning} />
					</ModalPortal>
				</CSSTransition>
				{
					this.state.warningPopupOpen &&
					<ModalPortal
						onClose={this.handleCloseWarningPopup}
						className='modal-small'
					>
						<MessageModal
							titleModal={t('attention')}
							contentModalText={t('modalWindowsLabels.notSavedWarning')}
							dangerButton={true}
							dangerButtonText={t('close')}
							onClickDangerButton={this.onClickDangerButton}
							secondaryButton={true}
							secondaryButtonText={t('cancel')}
							onClickSecondaryButton={this.handleCloseWarningPopup}
						/>
					</ModalPortal>
				}
			</Fragment>
		);
	}
}

CreateNewCustomerModal.propTypes = {
	closeCreateNewCustomerModal: PropTypes.func,
	modalContext: PropTypes.object,
	isOpen: PropTypes.bool
};

export default CreateNewCustomerModal;
