import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import {
	ClosePlayerButton, ControlPanel, ControlPanelRow, SoundTitle, PlaybackButton, PlaybackProgress,
	PlaybackTimer, AudioPlayerBox, DownloadTrackButton
} from './components';

export default class AudioPlayer extends PureComponent {
	audioPlayer = new Audio();
	
	state = {
		source: this.props.source,
		title: this.props.title,
		autoplay: this.props.autoplay,
		playbackTime: this.props.currentSecond || 0,
		trackLength: null,
		nowPlaying: false,
		position: { bottom: 50, right: 100 },
		dragPlayerPosition: {}
	};
	
	componentDidMount = () => {
		// Check the existence of the player.
		if (!this.audioPlayer) return;
		
		// Initialize playing.
		const { hidden, autoplay } = this.props;
		const { playbackTime, position, source, title } = this.state;
		this.setSource(source, title);
		this.setTime(playbackTime);
		
		if (autoplay && !hidden) {
			this.play();
		}
		
		// Save default player position.
		if (position) {
			this.setState({ position });
		}
		
		// Add event handlers.
		this.audioPlayer.addEventListener('timeupdate', () => this.updatePlaybackTime(this.audioPlayer.currentTime));
		this.audioPlayer.addEventListener('pause', this.handleStopPlaying);
		this.audioPlayer.addEventListener('play', this.handleStartPlaying);
		this.audioPlayer.addEventListener('ended', this.handleEndOfTrack);
		this.audioPlayer.addEventListener('error', this.handleError);
	};
	
	componentDidUpdate (prevProps, prevState) {
		if (this.props.autoplay && !this.state.nowPlaying && !this.props.hidden && prevProps.hidden) {
			this.play();
		}
		
		if (this.props.source !== prevState.source) {
			this.setSource(this.props.source, this.props.title);
			this.setTime(this.props.currentSecond || 0);
		}
	}
	
	componentWillUnmount = () => {
		document.removeEventListener('mousemove', this.drag);
		document.removeEventListener('mouseup', this.drop);
	};
	
	// Audio control methods:
	
	setSource = (url, title) => {
		this.audioPlayer.src = url;
		this.audioPlayer.addEventListener('loadedmetadata', this.handleLoadSource, { once: true });
		this.setState({ source: url, title });
	};
	
	setTime = (seconds) => {
		this.audioPlayer.currentTime = seconds;
		this.setState({ playbackTime: seconds });
	};
	
	play = (seconds) => {
		if (seconds)
			this.setTime(seconds);
		
		this.audioPlayer.play().catch(error => this.handleError(error));
	};
	
	updatePlaybackTime = throttle((time) => {
		this.setState(state => {
			const playbackTime = Math.floor(time);
			return state.playbackTime === playbackTime ? null : { playbackTime };
		});
	}, 200);
	
	pause = () => {
		this.audioPlayer.pause();
	};
	
	// Event handlers:
	
	handleLoadSource = () => {
		this.setState({ trackLength: Math.floor(this.audioPlayer.duration) });
	};
	
	handleStartPlaying = () => {
		const { onPlay } = this.props;
		this.setState({ nowPlaying: true });
		onPlay && onPlay();
	};
	
	handleStopPlaying = () => {
		const { onStop } = this.props;
		const { playbackTime } = this.state;
		this.setState({ nowPlaying: false });
		onStop && onStop(playbackTime);
	};
	
	handleEndOfTrack = () => {
		this.setTime(0);
		this.handleStopPlaying();
	};
	
	handleChangePlaying = () => {
		const { nowPlaying } = this.state;
		if (nowPlaying) this.pause();
		else this.play();
	};
	
	handleChangePlaybackTime = (event) => {
		const newTime = Number(event.currentTarget.value);
		this.setTime(newTime);
		this.updatePlaybackTime(newTime);
	};
	
	handleClosePlayer = () => {
		const { onClose } = this.props;
		const { playbackTime } = this.state;
		this.pause();
		onClose && onClose(playbackTime);
	};
	
	handleError = (error) => {
		const { onError } = this.props;
		this.pause();
		this.setTime(0);
		onError && onError(error);
	};
	
	// Drag&Drop methods:
	
	dragStart = (event) => {
		document.addEventListener('mousemove', this.drag);
		document.addEventListener('mouseup', this.drop);
		document.addEventListener('selectstart', this.preventSelection);
		
		const dragPlayerPosition = { x: event.pageX, y: event.pageY };
		this.setState({ dragPlayerPosition });
	};
	
	drag = throttle((event) => {
		const { position, dragPlayerPosition } = this.state;
		const shiftX = dragPlayerPosition.x - event.pageX;
		const shiftY = dragPlayerPosition.y - event.pageY;
		
		this.setState({
			position: {
				bottom: position.bottom + shiftY,
				right: position.right + shiftX
			},
			dragPlayerPosition: {
				x: event.pageX,
				y: event.pageY
			}
		});
	}, 100);
	
	preventSelection = (event) => {
		event.preventDefault();
	};
	
	drop = () => {
		const { onChangePosition } = this.props;
		onChangePosition && onChangePosition(this.state.position);
		document.removeEventListener('mousemove', this.drag);
		document.removeEventListener('mouseup', this.drop);
		document.removeEventListener('selectstart', this.preventSelection);
	};
	
	// Render method:
	
	render = () => {
		const { hidden } = this.props;
		const { title, playbackTime, trackLength, nowPlaying, position, source } = this.state;
		
		return hidden ? null : (
			<AudioPlayerBox position={position}>
				<PlaybackButton nowPlaying={nowPlaying} onClick={this.handleChangePlaying} />
				<ControlPanel>
					<ControlPanelRow onMouseDown={this.dragStart}>
						<SoundTitle>{title} <DownloadTrackButton link={source} /></SoundTitle>
						<PlaybackTimer>{playbackTime}</PlaybackTimer>
						<ClosePlayerButton onClick={this.handleClosePlayer} />
					</ControlPanelRow>
					<ControlPanelRow>
						<PlaybackProgress currentTime={playbackTime} totalTime={trackLength}
										  onChange={this.handleChangePlaybackTime} />
					</ControlPanelRow>
				</ControlPanel>
			</AudioPlayerBox>
		);
	};
}

AudioPlayer.propTypes = {
	// Data.
	hidden: PropTypes.bool,
	source: PropTypes.string,
	title: PropTypes.string,
	currentSecond: PropTypes.number,
	autoplay: PropTypes.bool,
	position: PropTypes.shape({
		bottom: PropTypes.number,
		right: PropTypes.number
	}),
	// Callbacks.
	onStop: PropTypes.func,
	onPlay: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	onChangePosition: PropTypes.func
};
