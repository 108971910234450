import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from "react-svg";

import { withTranslation } from 'react-i18next';

function MailingHeader (props) {
	const { t, isToggleAllActive, onToggleAll, onQueryChange, searchQuery } = props;
	const icon = isToggleAllActive ? 'toggle-hide.svg' : 'toggle-show.svg';
	return (
		<div className='tabHeader tab-search-wrapper'>
			<input
				value={searchQuery}
				onChange={onQueryChange}
				type='text'
				className='searchInput'
				placeholder={t('searchByMail')}
			/>
			<i className="icon-search2"></i>
			
			<span className='iconsWrapper'>
				<div className='btn-add' onClick={onToggleAll}>
					<ReactSVG svgClassName='toggle-icon' src={`/data/svg/${icon}`} />
				</div>
			</span>
		</div>
	);
}

MailingHeader.propTypes = {
	isToggleAllActive: PropTypes.bool,
	onToggleAll: PropTypes.func,
	searchQuery: PropTypes.string,
	onQueryChange: PropTypes.func
};

export default withTranslation()(MailingHeader);
