import React from 'react';
import { editorToolbar } from 'constants/index';
import CKEditor from 'ckeditor4-react';

const uri = window.location.href.split(/\?|#/)[0];
CKEditor.editorUrl = `${uri}/ckeditor/ckeditor.js`;

function EditorHTML ({ input, label, required }) {
	
	function onChange (e) {
		input.onChange && input.onChange(e.editor.getData());
	}
	
	return (
		<div>
			<div className='draftEditorBox input-element'>
				{
					label &&
					<div className='input-label'>
						{label}{required && <span className='required-field'>*</span>}
					</div>
				}
				<CKEditor
					onChange={onChange}
					config={{ ...editorToolbar, height: 400 }}
					data={input.value}
					name={input.name}
				/>
			</div>
		</div>
	);
	
}

export default EditorHTML;
