import {
    ADD_COMMENT,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_ERROR,
    UPDATE_COMMENT,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_ERROR,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
    DELETE_FILE,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_ERROR,
    SET_EDITABLE_COMMENT,
    COMMENTS_REQUEST,
    COMMENTS_REQUEST_SUCCESS,
    COMMENTS_REQUEST_FAILURE,
    APPEAL_COMMENTS_RESET,
	COUNTER_COMMENTS_UP
} from 'constants/actions';
import baseService from '../services/BaseService';

import { NotificationTypes } from 'constants/index';
import { showNotification } from './ui';
import i18next from 'util/i18n';


/** ******  Appeal comments request  ******* */

const requestComments = appealId => ({
    type: COMMENTS_REQUEST,
    appealId,
});

const requestCommentsSuccess = (comments, total) => ({
    type: COMMENTS_REQUEST_SUCCESS,
    comments,
    total
});

const requestCommentsFailure = () => ({
    type: COMMENTS_REQUEST_FAILURE,
});

export const getComments = (requestData, appealId) => (dispatch) => {
    dispatch(requestComments(appealId));

    const params = { data: requestData };

    return baseService.get('comments', params)
        .then((response) => {
            if (response.success && response.result) {
                dispatch(requestCommentsSuccess(response.result, response.total));
				dispatch({ type: COUNTER_COMMENTS_UP });
            } else {
                dispatch(requestCommentsFailure());
            }
        })
        .catch((error) => {
            console.error(error);
            dispatch(requestCommentsFailure());
        });
};

/** ******  Appeal comments reset  ******* */

export const resetComments = () => ({
    type: APPEAL_COMMENTS_RESET,
});

/** ******************************* */

const addCommentError = error => ({
    type: ADD_COMMENT_ERROR,
    error,
});

const fireAddComment = () => ({
    type: ADD_COMMENT,
});

export const addComment = ({ text, appealId, publish, attachment, commentId }, callback) => (dispatch) => {
    const params = {
        data: {
            text,
            appealId,
            attachment,
            commentId,
            publish,
        },
        jsonType: true,
    };

    dispatch(fireAddComment());

    baseService.post('comment_add', params)
        .then(() => {
            dispatch({
                type: ADD_COMMENT_SUCCESS,
				appealId
            });
			if (callback) callback();
        })
        .catch(err => dispatch(addCommentError(err)));
};

export const updateCommentSuccess = (appealId, data) => ({
    type: UPDATE_COMMENT_SUCCESS,
	appealId
});

const updateCommentError = error => ({
    type: UPDATE_COMMENT_ERROR,
    error,
});

const fireUpdateComment = () => ({
    type: UPDATE_COMMENT,
});

export const updateComment = ({ id, text, attachment, publish }, callback) => (dispatch) => {
    const params = {
        data: {
            text,
            id,
            publish,
            attachment,
        },
        jsonType: true,
    };

    dispatch(fireUpdateComment());

    baseService.post('comment_update', params)
        .then((data) => {
            dispatch(updateCommentSuccess(appealId, data));
            if (callback) callback();
        })
        .catch(err => dispatch(updateCommentError(err)));
};


const uploadFileSuccess = (id, name, appealId) => ({
    type: UPLOAD_FILE_SUCCESS,
    id,
    name,
	appealId,
});

const uploadFileError = error => ({
    type: UPLOAD_FILE_ERROR,
    error: error.message,
});


const fireUploadFile = () => ({
    type: UPLOAD_FILE,
});

export const uploadFile = (file, appealId, withNotification) => (dispatch) => {
    const params = {
        data: {
            data: {
                appealId,
                fileName: file.name,
            },
            file,
        },
        file: true,
    };

    dispatch(fireUploadFile());

    baseService.post('upload_file', params)
        .then(data => {
            if (withNotification) {
                dispatch(showNotification({
                    type: NotificationTypes.SUCCESS,
                    options: {
                        message: `${i18next.t('file')} ${file.name} ${i18next.t('fileAddedToComment')}`
                    }
                }));
            }
            dispatch(uploadFileSuccess(data.result, file.name, appealId));
        })
        .catch(err => dispatch(uploadFileError(err)));
};

export const deleteFileSuccess = response => ({
    type: DELETE_FILE_SUCCESS,
    id: response,
});

export const deleteFileError = error => ({
    type: DELETE_FILE_ERROR,
    error,
});

const fireDeleteFile = () => ({
    type: DELETE_FILE,
});

export const deleteFile = fileId => (dispatch) => {
    const deleteParams = {
        data: {
            fileId,
        },
    };

    dispatch(fireDeleteFile());

    baseService.post('file_delete', deleteParams)
        .then(data => dispatch(deleteFileSuccess(data.result)))
        .catch(err => dispatch(deleteFileError(err)));
};

export const setEditableComment = (editableComment, appealId) => {
    const attachments = editableComment ? editableComment.attachments : [];
	attachments.forEach( item => item.appealId = appealId);
    return{
        type: SET_EDITABLE_COMMENT,
        editableCommentId: editableComment ? editableComment.id : null,
        attachment: attachments,
    }
};
