import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { closeUserPopup, openUserPopup } from 'actions/ui';
import UserPopup from './UserPopup';

const mapStateToProps = state => ({
	user: state.user,
	isPopupOpen: state.ui.isUserPopupOpen,
});

const mapDispatchToProps = dispatch => ({
	closeUserPopup: () => dispatch(closeUserPopup()),
	openUserPopup: () => dispatch(openUserPopup()),
});

@connect(mapStateToProps, mapDispatchToProps)
class UserMenu extends React.Component {
	constructor (props) {
		super(props);
		this.selectFormRef = React.createRef();
		this.userMenuRef = React.createRef();
	}
	
	getPhotoSource = () => `data:image/png;base64,${this.props.user.user_photo}`;
	
	handleClickOutside = (e) => {
		if (!this.userMenuRef.current.contains(e.target)
			&& !this.selectFormRef.current.contains(e.target)
			&& !e.target.classList.contains('Select-option')
			&& !e.target.classList.contains('Select-clear-zone')
			&& !e.target.classList.contains('Select-clear')
			&& this.props.isPopupOpen
		) {
			this.props.closeUserPopup();
		}
	};
	
	getHeaderAvatar = () => {
		if (this.props.user.user_photo) {
			return (
				<div className='avatarWrapper'>
					<img
						alt=''
						className='userImage'
						src={this.getPhotoSource()}
					/>
				</div>
			);
		}
		return (
			<div className='circle-wrapper'>
				<div className='icon-user-wrapper'>
					<i className='icon-user' />
				</div>
			</div>
		);
	};
	
	render () {
		const {
			user: { login }, isPopupOpen, openUserPopup, closeUserPopup,
		} = this.props;
		return (
			<div className='userBoxWrapper'>
				
				<div
					className={cx('header-block-item', 'userBox')}
					ref={this.userMenuRef}
					onClick={isPopupOpen ? closeUserPopup : openUserPopup}
				>
					{this.getHeaderAvatar()}
					<span className='userText'>{login}</span>
				</div>
				
				<UserPopup
					formRef={this.selectFormRef}
					user={this.props.user}
					handleClickOutside={this.handleClickOutside}
					onClose={closeUserPopup}
					isPopupOpen={isPopupOpen}
				/>
			</div>
		);
	}
}

UserMenu.propTypes = {
	user: PropTypes.object,
	onUserMenuClick: PropTypes.func,
};

export default UserMenu;
