import React from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';
import { openEmail } from 'actions/emails';
import { connect } from 'react-redux';
import { EmailTypes } from 'constants/index';
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = dispatch => ({
	openEmail: emailId => dispatch(openEmail(emailId))
});

@withTranslation()
@withRouter
@connect(null, mapDispatchToProps)
class MessageItem extends React.Component {
	
	getOptionalParts = () => {
		const { data: { emailType, mailFrom, from, mailTo, to, originator }, t } = this.props;
		const contentTo = Array.isArray(to) && to.join(', ');

		switch (emailType) {
			case EmailTypes.INCOMING:
				return { title: t('appealHistory.emailFrom'), content: mailFrom || from };
			case EmailTypes.OUTGOING:
				return { title: t('appealHistory.emailTo'), content: contentTo || mailTo };
			
			default:
				const originatorTitle = originator && originator.channelName;
				const originatorContent = originator && originator.originator;
				return { title: originatorTitle, content: originatorContent };
		}
	};
	
	getUrl = () => this.props.match.url.replace('history', 'mailing');
	
	openEmail = () => this.props.openEmail(this.props.data.id);
	
	render () {
		const { data, highlight, t } = this.props;
		const { originator, receiver, subject } = data;
		const { title, content } = this.getOptionalParts();

		return (
			<li className='history-item'>
				<i className='icon-envelope' />
				<div className='history-item-content'>
					<span className='history-title-text'>{title}: </span>
					<span className='history-content-text'>{content}</span>
					<div className='emailSubject'>
						 <Link to={this.getUrl()} onClick={this.openEmail}>
							{t('appealHistory.emailSubject')}: {highlight(subject)}
						</Link>
					</div>
				</div>
			</li>
		);
	}
}

MessageItem.propTypes = {
	data: PropTypes.object,
	highlight: PropTypes.func
};

export default MessageItem;
