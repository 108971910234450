import React from 'react';
import PropTypes from 'prop-types';
import AppealTypeSelect from './AppealTypeSelect';

function ReduxFormDecorator ({ input, meta, ...rest }) {
	return (
		<AppealTypeSelect
			initialValue={input.value || undefined}
			onChange={input.onChange}
			error={meta.touched ? (meta.error || meta.warning) : undefined}
			onFocus={input.onFocus}
			onBlur={input.onBlur}
			{...rest}
		/>
	);
}

ReduxFormDecorator.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object
};

export default ReduxFormDecorator;
