import { get } from "lodash";
import { dynamicParamsFilter } from "../helpers";

export function getAppealHeaderForm(data) {
    const { appeal, t, checkRestrictions, shouldRenderField, priorityList, appealInfoComponent } = data;


    const headerForm = [
        {
            view: "3column",
            collapsed: false,
            // title: " ",
            key: "appeal-static-block-1",
            widgets: [
                {   
                    component: appealInfoComponent,
                    widgetType: "customComponent",
                },
                {
                    key: "priority",
                    title: t("appeal.priority"),
                    // clearable: false,
                    isReq: checkRestrictions("priorityId"),
                    values: priorityList.map(item => ({
                        value: item.name,
                        key: item.id,
                    })),
                    savedValue: appeal && appeal.priority && appeal.priority.id,
                    disabled: checkRestrictions("priorityId"),
                    isHidden: !shouldRenderField("priorityId"),
                    widgetType: "select",
                },
                {
                    key: "resolveDate",
                    title: t("appeal.resolveDate"),
                    placeholder: t("text"),
                    disabled: checkRestrictions("resolveDateResolveDate"),
                    // clearable: false,
                    isReq: true,
                    savedValue: appeal && appeal.resolveDate && appeal.resolveDate.resolveDate,
                    isHidden: !shouldRenderField("resolveDateResolveDate"),
                    widgetType: "datetime",
                },
            ],
        },
    ];
    return headerForm;
}

export function getAppealForm(data) {
    const {
        t,
        formParams,
        shouldRenderField,
        subjectActions,
        checkRestrictions,
        descriptionActions,
        appeal,
        shouldRenderProductInstance,
        shouldRenderFeedback,
        infoContacts,
        getInfoContacts,

        destinationFeedbackValue,
        isDestinationFeedbackLoading,

        destinations,
        destinationActions,
        selectedDestinationOptions,
        onDestinationOpen,
        canAddExecutors,
        loadingDestinations,

        solutionActions,

        resetActions,
    } = data;

    if (!formParams) {
        return null;
    }

    // dynParams with order < 10)
    const dynParams1 = formParams.blocks.filter(block =>
        dynamicParamsFilter(block, shouldRenderField, order => order < 10)
    );

    // static block name="subject" with permission: shouldRenderField('subject') && ENVIRONMENT !== 'ukc'
    // static block name="description" with permission: shouldRenderField('description')
    // static block name="type" with permission: shouldRenderField('type') // NEW BLOCK !!!

    const id = appeal.typeId;
    const name = appeal.typeText;
    const fullLabelPath = appeal.typeFullName;
    const requiredObjects = appeal.requiredObjects || [];

    const savedAppealType = {
        id,
        name,
        fullLabelPath,
        requiredObjects,
    };

    const staticBlock1 = [
        {
            view: "1column",
            collapsed: false,
            // title: " ",
            key: "appeal-static-block-1",
            widgets: [
                {
                    key: "subject",
                    title: t("appeal.subject"),
                    isReq: subjectActions ? subjectActions.isReq : false,
                    savedValue: appeal && appeal.subject,
                    // disabled: subjectActions ? subjectActions.isDisable : checkRestrictions("subject"),
                    disabled: subjectActions && subjectActions.isDisable,
                    maxLength: 255,
                    autosize: true,
                    isHidden: !(shouldRenderField("subject") && ENVIRONMENT !== "ukc"),
                    textAreaClassName: "textarea-one-line-input",
                    widgetType: "multiline",
                },
                {
                    key: "description",
                    title: t("appeal.description"),
                    placeholder: t("text"),
                    // disabled: descriptionActions ? descriptionActions.isDisable : checkRestrictions("description"),
                    disabled: descriptionActions && descriptionActions.isDisable,
                    isReq: descriptionActions ? descriptionActions.isReq : false,
                    savedValue: appeal && appeal.description,
                    autosize: true,
                    // isHidden: !shouldRenderField("description"),
                    widgetType: "multiline",
                },
                // NEW COMPONENT selectTreeModalWithSearch (old AppealTypeSelect)
                {
                    key: "type",
                    clearable: false,
                    title: t("appeal.type"),
                    placeholder: t("appeal.selectPlaceholder"),
                    isReq: true,
                    savedValue: savedAppealType,
                    initNodeArray: [
                        {
                            id: appeal.typeId,
                            name: appeal.typeText,
                            isCustReq: appeal.requiredObjects.includes("CUSTOMER"),
                            leaf: true,
                        },
                    ],
                    nodeArrayApiKey: "appeal_type",
                    nodeArrayResultKey: "children",
                    // isHidden: !shouldRenderField("type"),
                    widgetType: "selectTreeModalWithSearch",
                },
            ],
        },
    ];

    // dynParams with order > 10 && order < 50
    const dynParams2 = formParams.blocks.filter(block =>
        dynamicParamsFilter(block, shouldRenderField, order => order > 10 && order < 50)
    );

    // static block name="objectId"
    // static block name="destinationFeedback"
    // static block name="destination" with permission: shouldRenderField('routeDestination') // NEW COMPONENT !!!
    // static block name="solution" with permission: shouldRenderField('solution')

    const selectedContacts = !infoContacts ? [] : infoContacts.filter((contact) => contact.selected).map(contact => contact.id);
    const staticBlock2 = [
        {
            view: "1column",
            collapsed: false,
            title: "",
            key: "appeal-static-block-2",
            widgets: [
                {
                    key: "objectId",
                    title: t("appeal.objectId"),
                    placeholder: "-",
                    values: [{ label: appeal.object, value: appeal.objectId }],
                    isHidden: !shouldRenderProductInstance,
                    widgetType: "select",
                },

                {
                    key: "destinationFeedback",
                    title: t("appeal.feedback"),
                    placeholder: "-",
                    disabled: isDestinationFeedbackLoading || get(appeal, "restriction.destinationFeedback") === 1,
                    savedValues: selectedContacts,
                    values: !infoContacts
                        ? []
                        : infoContacts.map(({ value, description, id, selectable }) => ({
                              value: `${value} ${description}`,
                              key: id,
                              disabled: !selectable && !destinationFeedbackValue.includes(id),
                          })),
                    onOpen: getInfoContacts,
                    isLoading: isDestinationFeedbackLoading,
                    isHidden: !(shouldRenderFeedback && infoContacts),
                    widgetType: "multiselect",
                },
                // NEW COMPONENT SelectTreeAdd
                {
                    key: "destination",
                    title: t("appeal.destination"),
                    placeholder: t("appeal.selectPlaceholder"),
                    clearable: true,
                    savedValue: [![null, undefined].includes(appeal.destinationId) ? appeal.destinationId : ""],
                    options: {
                        initialOptions:
                            destinations && destinations.length > 0 ? destinations : selectedDestinationOptions,
                        searchable: true,
                        treeParam: "result",
                        textField: "text",
                        valueField: v => {
                            return v.id !== undefined ? v.id : v.object !== undefined ? v.object.id : null;
                        },
                        onOpen: onDestinationOpen,
                    },
                    isReq: true,
                    // disabled: destinationActions ? destinationActions.isDisable : checkRestrictions("destination"),
                    disabled: destinationActions && destinationActions.isDisable,
                    onFieldRemove: resetActions,
                    additionDisabled: !canAddExecutors,
                    isLoading: loadingDestinations,
                    // isHidden: !shouldRenderField("routeDestination"),
                    rule: destination => {
                        let error;
                        if (destination && destination.length > 0) {
                            destination.forEach((value, index, self) => {
                                if (!value && !index) {
                                    error = { [index]: t("appeals.selectExecutor") };
                                } else if (self.indexOf(value) !== index) {
                                    error = { [index]: t("appeals.selectUniqueExecutor") };
                                }
                            });
                        }
                        return error;
                    },
                    widgetType: "selectTreeAdd",
                },
                {
                    key: "solution",
                    title: t("appeal.solution"),
                    placeholder: t("text"),
                    // disabled: solutionActions ? solutionActions.isDisable : checkRestrictions("solution"),
                    disabled: solutionActions && solutionActions.isDisable,
                    isReq: solutionActions ? solutionActions.isReq : false,
                    savedValue: appeal && appeal.solution,
                    maxLength: 2000,
                    autosize: true,
                    // isHidden: !shouldRenderField("solution"),
                    widgetType: "multiline",
                },
            ],
        },
    ];

    // dynParams with order >= 50
    const dynParams3 = formParams.blocks.filter(block =>
        dynamicParamsFilter(block, shouldRenderField, order => order >= 50)
    );

    // appeal form structure:

    // dynParams with order < 10)

    // static block name="subject" with permission: shouldRenderField('subject') && ENVIRONMENT !== 'ukc'
    // static block name="description" with permission: shouldRenderField('description')
    // static block name="type" with permission: shouldRenderField('type') // NEW BLOCK !!!

    // dynParams with order > 10 && order < 50

    // static block name="objectId"
    // static block name="destinationFeedback"
    // static block name="destination" with permission: shouldRenderField('routeDestination') // NEW BLOCK !!!
    // static block name="solution" with permission: shouldRenderField('solution')

    // dynParams with order >= 50

    const appealForm = [...dynParams1, ...staticBlock1, ...dynParams2, ...staticBlock2, ...dynParams3];
    // check appeal form for restrictions and shouldRenderField
    // shouldRenderField => restriction 1, 3 (not 0 or 2)
    // checkRestriction => restriction 1 => disabled field
    const checkedAppealForm = appealForm.map(block => ({
        ...block,
        widgets: block.widgets.map(widget => ({
            ...widget,
            disabled: checkRestrictions(widget.key) ||  widget.disabled,
            isHidden: !shouldRenderField(widget.key) ||  widget.isHidden,
        }))
    }));
    return checkedAppealForm;
}
