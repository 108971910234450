import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import CONF from '../../config/conf';
import { toggleHiddenTabs } from '../../actions/ui';
import { tabsReverse } from '../../actions/tabs';
import TabCloseMenu from './TabCloseMenu';
import Popup from '../Popup';
import { MAX_SHOW_HIDDEN_TABS } from '../../constants';
import './styles.scss';
import ReactSVG from 'react-svg';

const TAB_HEIGHT = 49;

const PORTAL_ID = 'portal-hidden-tabs-close-context';

@withRouter
@connect(null, { toggleHiddenTabs, tabsReverse })
export default class HiddenTabs extends React.Component {
	
	state = {
		popupIsOpen: false,
		isShowAllActive: false,
		contextMenuPosition: { left: 0, top: 0}
	};
	
	reverseTab = (e, tab) => this.props.tabsReverse({ url: tab.url });
	
	togglePopup = () => this.setState(prevState => ({ popupIsOpen: !prevState.popupIsOpen }));
	
	closePopup = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let isContextMenuPortal = false;
        const path = e ? e.path || (e.composedPath && e.composedPath()) : [];
        for (let i = 0; i < path.length; i++) {
            if (path[i].getAttribute && path[i].getAttribute('data-field-key')) {
                isContextMenuPortal = path[i].getAttribute('data-field-key') === PORTAL_ID;
                break;
            }
        }
        if (isContextMenuPortal) {
            return;
        }
		const toggleButtonClick = this.toggleButton.contains(e.target);
		this.hideAllTabs();
		if (this.props.removeCloseContextMenuUrl && typeof this.props.removeCloseContextMenuUrl === 'function') {
			this.props.removeCloseContextMenuUrl();
		}
		return toggleButtonClick ? null : this.togglePopup();
	};
	
	showAllTabsHandler = event => {
		event.stopPropagation();
		this.setState({ isShowAllActive: true });
	};

	handleContextMenu = (event, val, tabIndex) => {
		const index = tabIndex + 2;
		this.setState({contextMenuPosition: { left: 0, top: index * TAB_HEIGHT}});
		this.props.setCloseContextMenuUrl(event, val);
	}
	
	hideAllTabs = () => this.setState({ isShowAllActive: false });
	
	render () {
		const { removeTab, removeAll, removeAllTabsExceptCurrent, ui, hiddenTabs, t } = this.props;
		const { popupIsOpen, isShowAllActive } = this.state;
		const hiddenTabsCount = hiddenTabs.length;
		const showedTabs = hiddenTabsCount > MAX_SHOW_HIDDEN_TABS && !isShowAllActive ? MAX_SHOW_HIDDEN_TABS : hiddenTabsCount;
		
		return (
			<div
				className='hiddenTabs'
				onClick={this.togglePopup}
				ref={(node) => (this.toggleButton = node)}
			>
				<i className={cx('icon-arrow-right', { 'down': popupIsOpen })} />
				{popupIsOpen &&
					<Popup
						onClickOutside={this.closePopup}
						place='dropdown-tabs'
					>
						<div className='hiddenTabsWrapper'>
							{hiddenTabs && [...hiddenTabs].reverse().slice(0, showedTabs).map((val, key) => {
								const icon = val.url.split('/')[1];

								return (
									<div className='hiddenTabWrapper'>
										<NavLink
											exact
											to={`${val.url}`}
											key={key}
											className='hiddenTabsItem'
											onClick={(e) => this.reverseTab(e, val)}
											onContextMenu={event => this.handleContextMenu(event, val, key)}
										>
											<div className='hiddenTabsTextWrapper'>
												<ReactSVG svgClassName='tab-icon' src={`/data/svg/navigation/${icon}.svg`} />
												<span className='hiddenTabsText	'>
													{/* {val.name === 'search' ? searches[val.url.split('/').pop()] : val.regnum || val.displayedName} */}
													{val.name === 'search' ? `${t("Search")} ${t(`tabs.${val.queryKey}`)}` : val.regnum || val.displayedName}
												</span>
											</div>
											<TabCloseMenu
												removeTab={removeTab}
												removeAll={removeAll}
												removeAllTabsExceptCurrent={removeAllTabsExceptCurrent}
												val={val}
												// place='hidden-hidden-menu'
												closeContextMenuUrl={this.props.closeContextMenuUrl}
												withContextMenu={this.props.tabs && Array.isArray(this.props.tabs) && this.props.tabs.length > 1}
												removeCloseContextMenuUrl={this.props.removeCloseContextMenuUrl}
												withPortal
												portalRootId={PORTAL_ID}
												portalFieldKey={PORTAL_ID}
												portalPosition={this.state.contextMenuPosition}
											/>
										</NavLink>
									</div>
								);
							})}
						</div>
						
						{!isShowAllActive && hiddenTabsCount > MAX_SHOW_HIDDEN_TABS && (
							<div className='hiddenTabsMoreLink'>
								<i className='icon-kebab-hor' onClick={this.showAllTabsHandler} />
							</div>
						)}
					</Popup>
				}
				<div id={PORTAL_ID} />
			</div>
		);
	}
}

HiddenTabs.propTypes = {
	toggleHiddenTabs: PropTypes.func,
	tabsReverse: PropTypes.func,
	ui: PropTypes.object.isRequired,
	tabs: PropTypes.array,
	removeTab: PropTypes.func,
	removeAll: PropTypes.func,
	removeAllTabsExceptCurrent: PropTypes.func,
	hiddenTabs: PropTypes.array,
	translateName: PropTypes.func.isRequired
};

