import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from './CheckBox';

export default function CheckBoxGroup (props) {
	const { label, required, options, input, disabled, ...restProps } = props;

	const handleChange = (event, option) => {
		const newValue = [...input.value];
		if (event.target.checked) {
			newValue.push(option);
		} else {
			newValue.splice(newValue.indexOf(option), 1);
		}
		input.onChange(newValue);
	};
	
	const renderRadioItem = (checkbox, index) => {
	
		return (
			<CheckBox
				{...input}
				name={`${input.label}[${index}]`}
				id={checkbox.value}
				onChange={(event) => handleChange(event, checkbox.value)}
				onBlur={(e) => false}
				value={checkbox.key}
				title={checkbox.value}
				label={checkbox.label}
				checked={input.value.indexOf(checkbox.value) !== -1}
				disabled={disabled}
				{...required}
			/>
		);
	};
	
	
	return (
		<>
			<div className='checkbox-group-row input-element' {...restProps}>
				<p className='checkbox-group-label input-label'>{label}
					{required && <span className='required-field'>*</span>}
				</p>
				<fieldset className='checkbox-group-name'>
					{options.map(renderRadioItem)}
				</fieldset>
			</div>
		</>
	);
}

CheckBoxGroup.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array
};
