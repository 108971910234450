import {
	CALENDAR_REQUEST,
	CALENDAR_REQUEST_SUCCESS,
	CALENDAR_REQUEST_FAILURE,
	CALENDAR_FILTERS_REQUEST,
	CALENDAR_FILTERS_REQUEST_SUCCESS,
	CALENDAR_FILTERS_REQUEST_FAILURE,
	CALENDAR_SET_ACTIVE_FILTER,
	CALENDAR_SET_ACTIVE_FILTER_PAGE,
	CALENDAR_FILTER_ATTRS_GET_SUPPOSE,
	CALENDAR_FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	CALENDAR_FILTER_ATTRS_GET_SUPPOSE_FAIL,
	CALENDAR_ADD_FILTER_ATTRS,
	CALENDAR_SAVE_FILTER_SUCCESS,
	CALENDAR_SAVE_FILTER_FAIL,
	CALENDAR_SAVE_FILTER,
	CALENDAR_DELETE_FILTER,
	CALENDAR_DELETE_FILTER_SUCCESS,
	CALENDAR_DELETE_FILTER_FAIL,
	CALENDAR_FILTER_PARAMS_GET_SUPPOSE,
	CALENDAR_FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	CALENDAR_FILTER_PARAMS_GET_SUPPOSE_FAIL,
	CALENDAR_FILTER_ATTRS_CLEAR_RESULT,
	CALENDAR_CLEAR_FILTER_ATTRS,
	CALENDAR_CLEAR_FILTER_INFO,
	CALENDAR_FILTER_DATA_GET_SUPPOSE,
	CALENDAR_FILTER_DATA_GET_SUPPOSE_SUCCESS,
	CALENDAR_FILTER_DATA_GET_SUPPOSE_FAIL,
	CALENDAR_OPEN_FILTER_PARAMS,
	CALENDAR_CLOSE_FILTER_PARAMS,
	CALENDAR_OPEN_NEW_FILTER_PARAMS,
	CALENDAR_CLOSE_NEW_FILTER_PARAMS,
	CALENDAR_CREATE_NEW_FILTER,
	CALENDAR_FILTER_LIST_GET_SUPPOSE,
	CALENDAR_FILTER_LIST_GET_SUPPOSE_SUCCESS,
	CALENDAR_FILTER_LIST_GET_SUPPOSE_FAIL,
	CALENDAR_FILTER_LIST_CLEAR_RESULT,
	CALENDAR_ADD_FILTER_OPTION,
	CALENDAR_SET_OLD_FILTER_DATA,
	CALENDAR_CLEAR_NEW_FILTER_OPTION,
	CALENDAR_CLEAR_FILTER_DATA,
	CALENDAR_APPLY_EXISTING_FILTER,
	CALENDAR_SET_SELECT_OPTIONS_FOR_FILTER,
	SEARCH_GET_CALENDAR_SUPPOSE,
	SEARCH_GET_CALENDAR_SUPPOSE_SUCCESS,
	SEARCH_GET_CALENDAR_SUPPOSE_FAILURE,
	CLEAR_SEARCH_GET_CALENDAR_SUPPOSE,
	CEVENT_GET_SUPPOSE,
	CEVENT_GET_SUPPOSE_SUCCESS,
	CEVENT_GET_SUPPOSE_FAILURE,
	RRULES_CEVENT_GET_SUPPOSE,
	RRULES_CEVENT_SUCCESS,
	RRULES_CEVENT_FAILURE,
	VALARM_CEVENT_GET_SUPPOSE,
	VALARM_CEVENT_SUCCESS,
	VALARM_CEVENT_FAILURE,
	TYPES_CEVENT_GET_SUPPOSE,
	TYPES_CEVENT_SUCCESS,
	TYPES_CEVENT_FAILURE,
	USER_CEVENTS_GET_SUPPOSE,
	USER_CEVENTS_SUCCESS,
	USER_CEVENTS_FAILURE,
	DELETE_CEVENTS_SUPPOSE,
	DELETE_CEVENT_SUCCESS,
	DELETE_CEVENT_FAILURE,
	STATUSES_CEVENTS_GET_SUPPOSE,
	STATUSES_CEVENTS_SUCCESS,
	STATUSES_CEVENTS_FAILURE,
	GET_DYN_PARAMS_CEVENT_GET_SUPPOSE,
	GET_DYN_PARAMS_CEVENT_SUCCESS,
	CEVENT_CLEAR_OPENED_EVENT,
} from './constants';

import baseService from '../../services/BaseService';
import {showNotification} from "../../actions/ui";

export const getRequest = (callback, api = {}, gridKey) => dispatch => {
	const { key, method = 'get', ...rest } = api;
	return baseService
		[method](key, rest)
		.then((response) => (callback(response, gridKey)))
		.catch((error) => {
			console.error(error);
		});
};

/* action creators */
/* filters */
export const setFilters = filters => ({
	type: CALENDAR_FILTERS_REQUEST_SUCCESS,
	filters
});

export const setActiveFilterPage = (filterId, page) => ({
	type: CALENDAR_SET_ACTIVE_FILTER_PAGE,
	filterId,
	page
});


const getAttrsSuppose = (query) => ({
	type: CALENDAR_FILTER_ATTRS_GET_SUPPOSE,
	query
});

const getAttrsSupposeSuccess = (result) => ({
	type: CALENDAR_FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	result
});

const getAttrsSupposeFail = (error) => ({
	type: CALENDAR_FILTER_ATTRS_GET_SUPPOSE_FAIL,
	error
});

export const getFilterAttrsSuppose = (params) => (dispatch) => {
	dispatch(getAttrsSuppose());
	baseService.get('attributes', params)
		.then(data => {
			dispatch(getAttrsSupposeSuccess(data));
		})
		.catch(err => dispatch(getAttrsSupposeFail(err)));
};

export const addFilterAttrs = (result) => ({
	type: CALENDAR_ADD_FILTER_ATTRS,
	result
});

export const clearFilterAttrs = () => ({
	type: CALENDAR_CLEAR_FILTER_ATTRS
});

const saveFilterSuccess = (lastSaved) => ({
	type: CALENDAR_SAVE_FILTER_SUCCESS,
	lastSaved
});

const saveFilterFail = (error) => ({
	type: CALENDAR_SAVE_FILTER_FAIL,
	error
});

const fireSaveFilter = () => ({
	type: CALENDAR_SAVE_FILTER
});

export const saveFilter = (params, onSuccess) => (dispatch) => {
	dispatch(fireSaveFilter());
	
	baseService.post('save_orders_filter', params)
		.then(response => {
			if (onSuccess && response.result) onSuccess(response.result);
			dispatch(saveFilterSuccess(params.data.name));
		})
		.catch(err => dispatch(saveFilterFail(err)));
};

const deleteFilterSuccess = (id) => ({
	type: CALENDAR_DELETE_FILTER_SUCCESS,
	id
});

const deleteFilterFail = (error) => ({
	type: CALENDAR_DELETE_FILTER_FAIL,
	error
});

const fireDeleteFilter = () => ({
	type: CALENDAR_DELETE_FILTER
});

export const deleteFilter = (params) => (dispatch) => {
	dispatch(fireDeleteFilter());
	
	baseService.post('delete_filter', params)
		.then(data => {
			dispatch(deleteFilterSuccess(data.result))
		})
		.catch(err => {
			console.log(err);
			dispatch(deleteFilterFail(err))
		});
};

const getFilterParamsSuppose = (query) => ({
	type: CALENDAR_FILTER_PARAMS_GET_SUPPOSE,
	query
});

const getFilterParamsSupposeSuccess = (result) => ({
	type: CALENDAR_FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterParamsSupposeFail = (error) => ({
	type: CALENDAR_FILTER_PARAMS_GET_SUPPOSE_FAIL,
	error
});

export const clearFilterInfo = () => ({
	type: CALENDAR_CLEAR_FILTER_INFO
});

export const getInfoFilterSuppose = (params) => (dispatch) => {
	dispatch(openFilterParams());
	baseService.get('info_orders_filter', params)
		.then(data => dispatch(getFilterParamsSupposeSuccess(data)))
		.catch(err => dispatch(getFilterParamsSupposeFail(err)));
};

const getFilterDataSuppose = () => ({
	type: CALENDAR_FILTER_DATA_GET_SUPPOSE
});

const getFilterDataSupposeSuccess = (result) => ({
	type: CALENDAR_FILTER_DATA_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterDataSupposeFail = (error) => ({
	type: CALENDAR_FILTER_DATA_GET_SUPPOSE_FAIL,
	error
});

export const getDataFilterSuppose = (params) => (dispatch) => {
	dispatch(getFilterDataSuppose());
	
	baseService.post(params.key, params)
		.then(data => {
			dispatch(getFilterDataSupposeSuccess(data));
		})
		.catch(err => dispatch(getFilterDataSupposeFail(err)));
};


export const setActiveFilter = (openedFilter) => ({
	type: CALENDAR_SET_ACTIVE_FILTER,
	openedFilter
});

export const openFilterParams = () => ({
	type: CALENDAR_OPEN_FILTER_PARAMS
});

export const closeFilterParams = () => ({
	type: CALENDAR_CLOSE_FILTER_PARAMS
});

export const openNewFilterParams = () => ({
	type: CALENDAR_OPEN_NEW_FILTER_PARAMS
});

export const closeNewFilterParams = () => ({
	type: CALENDAR_CLOSE_NEW_FILTER_PARAMS
});

export const createNewFilter = () => ({
	type: CALENDAR_CREATE_NEW_FILTER
});


/* end filter*/
/* search */
const getSearchSuppose = () => ({
	type: SEARCH_GET_CALENDAR_SUPPOSE
});

const getSearchSupposeSuccess = (result, total) => ({
	type: SEARCH_GET_CALENDAR_SUPPOSE_SUCCESS,
	result,
	total
});

const getSearchSupposeFailure = () => ({
	type: SEARCH_GET_CALENDAR_SUPPOSE_FAILURE
});

export const clearSearchGetSuppose = () => ({
	type: CLEAR_SEARCH_GET_CALENDAR_SUPPOSE
});

export const getSearchResults = requestData => (dispatch, getState) => {
	const state = getState();
	const { mainDataLoading } = state.calendar;
	if (mainDataLoading) {
		return;
	}
	dispatch(getSearchSuppose());

	const params = { data: requestData };
	const url = 'filter_apply';
	
	return baseService.post(url, params)
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch(getSearchSupposeSuccess(response.result, response.total));
			} else {
				throw new Error('Search results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(getSearchSupposeFailure());
		});
};
/* end search */

/* calendarEvent */
const getCEventSuppose = () => ({
	type: CEVENT_GET_SUPPOSE
});

const getCEventSupposeSuccess = (event) => ({
	type: CEVENT_GET_SUPPOSE_SUCCESS,
	event
});

const getCEventSupposeFailure = () => ({
	type: CEVENT_GET_SUPPOSE_FAILURE
});

export const clearOpenedEvent = () => ({
	type: CEVENT_CLEAR_OPENED_EVENT
});

export const createCalendarEvent = (cid = null, start = null, end = null) => dispatch => {
	dispatch(getCEventSuppose());

	const params = { data: {cid, start, end}, jsonType: true };
	const url = 'create_calendar_event';
	return baseService.post(url, {...params})
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch(getCalendarEvent(response.result[0].eid));
			} else {
				throw new Error('Open event results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(getCEventSupposeFailure());
		});
};

export const getCalendarRrules = () => dispatch => {
	dispatch({ type: RRULES_CEVENT_GET_SUPPOSE });
	
	return baseService.get('get_calendar_rrules')
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch({ type: RRULES_CEVENT_SUCCESS, rrules: response.result });
			} else {
				throw new Error('Open event results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: RRULES_CEVENT_FAILURE });
		});
};

export const getCalendarValarms = () => dispatch => {
	dispatch({ type: VALARM_CEVENT_GET_SUPPOSE });
	
	return baseService.get('get_calendar_valarms')
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch({ type: VALARM_CEVENT_SUCCESS, valarms: response.result });
			} else {
				throw new Error('Open event results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: VALARM_CEVENT_FAILURE });
		});
};

export const getEventTypes = () => dispatch => {
	dispatch({ type: TYPES_CEVENT_GET_SUPPOSE });
	
	return baseService.post('get_calendar_event_types', {data: { filter: 'CLEVENT'}})
		.then(response => {
			if (response.success && Array.isArray(response.children)) {
				dispatch({ type: TYPES_CEVENT_SUCCESS, types: response.children[0] });
			} else {
				throw new Error('getEventTypes results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: TYPES_CEVENT_FAILURE });
		});
};

export const deleteCalendarEvent = (eid, cid, mode, callback) => dispatch => {
	dispatch({ type: DELETE_CEVENTS_SUPPOSE });

	return baseService.delete('calendar_event', {pathParams: {eid}, data: {cid, mode}, jsonType: true})
		.then(response => {
			if (response.success) {
				dispatch({ type: DELETE_CEVENT_SUCCESS });
				if (callback && typeof callback === 'function') callback();
			} else {
				throw new Error('delete event results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: DELETE_CEVENT_FAILURE });
		});
};

export const getUserCalendars = () => dispatch => {
	dispatch({ type: USER_CEVENTS_GET_SUPPOSE });
	
	return baseService.get('get_calendars')
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch({ type: USER_CEVENTS_SUCCESS, userCalendars: response.result });
			} else {
				throw new Error('Open event results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: USER_CEVENTS_FAILURE });
		});
};

export const getEventStatuses = (eid) => dispatch => {
	dispatch({ type: STATUSES_CEVENTS_GET_SUPPOSE });
	
	return baseService.get('get_calendar_statuses', { pathParams: {eid} })
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch({ type: STATUSES_CEVENTS_SUCCESS, eventStatuses: response.result });
			} else {
				throw new Error('Open event results error');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: STATUSES_CEVENTS_FAILURE });
		});
};

export function getDynamicParams (eventTypeValue, eventId, mode, callback) {
	return async (dispatch) => {
		dispatch({type: GET_DYN_PARAMS_CEVENT_GET_SUPPOSE})
		try {
			const openMode = mode ? mode : 'update' ;
			const params = {
				data: {
					objectType: 'CLEVENT',
					objectTypeId: eventTypeValue,
					objectTypeCode: '',
					openMode: openMode,
					ctxObjectType: 'CLEVENT',
					ctxObjectId: eventId,
					curStepNum: 1,
					overrideIsReq: '',
				}
			};
			
			const response = await baseService.get('dinamic_params', params);
			if (response.success) {
				if (callback) callback(response.result);
				dispatch({
					type: GET_DYN_PARAMS_CEVENT_SUCCESS,
					dynParams: response.result
				})
			}
		}
		catch (error) {
			console.error(error);
			dispatch(showNotification({
				type: NotificationTypes.HTTP_ERROR,
				options: { message: error },
				error: 'Something went wrong'
			}));
		}
	};
}

export const getCalendarEvent = (eid, mode = undefined, cid = null, openMode = 'create', callback) => (dispatch, getState) => {
	dispatch(getCEventSuppose());

	const params = { data: {mode, cid} };
	const url = 'calendar_event';
	return baseService.get(url, {pathParams: { eid }, ...params})
		.then(response => {
			if (response.success) {
				dispatch(getCEventSupposeSuccess(response.result));
				
			} else {
				throw new Error('Open event results error');
			}
		})
		.then( () =>{
			const eventTypeValue = getState().calendar.openedEvent.eventType.value;
			const eventId = getState().calendar.openedEvent.eventId;
			dispatch(getDynamicParams(eventTypeValue, eventId, openMode, callback));
		})
		.catch(error => {
			console.error(error);
			dispatch(getCEventSupposeFailure());
		});
};

/* end calendarEvent */
