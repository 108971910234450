import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { calculateSearchNumber } from 'helpers';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import { escapeBaseTag } from 'helpers';

function removeHtmlTagsFromString(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, "")
}

const iconsConfig = {
    task: <ReactSVG className="search-icon" src="/data/svg/navigation/order.svg" />,
    // spec: <ReactSVG className="search-icon" src="/data/svg/navigation/service.svg" />,
    resource: <ReactSVG className="search-icon" src="/data/svg/navigation/resource.svg" />,
    service: <ReactSVG className="search-icon" src="/data/svg/navigation/service.svg" />,
    customer: <ReactSVG className="search-icon" src="/data/svg/navigation/customer.svg" />,
    file: <ReactSVG className="search-icon" src="/data/svg/file.svg" />,
    appeal: <ReactSVG className="search-icon" src="/data/svg/navigation/appeals.svg" />,
    contact_person: <ReactSVG className="search-icon" src="/data/svg/navigation/person.svg" />,
    clevent: <ReactSVG className="search-icon" src="/data/svg/navigation/calendar.svg" />,
    specification: <ReactSVG className="search-icon" src="/data/svg/navigation/specification.svg" />,
};

// "spec": {
//     "offer": 0,
//     "product": 0,
//     "resource": 0,
//     "service": 0,
//     "document": 0,
//     "customer": 0
// },
const tabConfig = ['task', 'spec.resource', 'spec.service', 'clevent', 'contact_person', 'customer', 'appeal', 'callhistory', 'file']; 

@withRouter
@withTranslation()
export default class LiveSearch extends React.Component {
    static getLinkTo({ id, customerId, type, objectId, url, allowModes, detailObjectType }) {
        if (detailObjectType && ["SERVICE", "RESOURCE"].includes(detailObjectType)) {
            const path = detailObjectType.toLowerCase();
            return `/${path}/${id}/`;
        }
        switch (type) {
            case 'service':
                return `/service/${id}/`;
            case 'resource':
                return `/resource/${id}/`;
            case 'spec':
                return `/service/${id}/`;
            case 'task':
                return `/order/${id}/`;
            case 'customer':
                return `/customer/${id}/appeals`;
            case 'clevent':
                return this.eventClickHandler(url, allowModes);
            case 'appeal':
                return `/appeals/${id}/`;
            case 'file':
                return `/appeals/${objectId}/`;
            case 'contact_person':
                return `/customer/${customerId}/person/${id}/contact`;
            case 'specification': 
                return `/specification/${id}/0`;
            default:
                return '';
        }
    }

    constructor(props) {
        super(props);
        this.getLinkTo = this.constructor.getLinkTo;
        this.renderAssumption = this.renderAssumption.bind(this);
        this.renderTab = this.renderTab.bind(this);
    }

    eventClickHandler(url, allowModes) {
        if (allowModes && allowModes.length > 1) {
            // return setModalEventData({ url, allowModes });
        }
        return `${url}/${allowModes[0].code}`;
    }

    renderAssumption(data) {
        const { id, type, name, content, hideSearch, code, applId, contactPersonName } = data;

        let parsedType = type;
        if (type === 'spec') {
            parsedType = data.detailObjectType.toLowerCase();
        }

        // console.log({data});

        const onAssumptionClick = (event) => {
            if (code) {
                this.props.onClick(event, data);
            } else {
                hideSearch && hideSearch(event);
            }
        };
        return (
            <Link
                key={code ? applId : id}
                to={this.getLinkTo(data)}
                className={cx('assumption-wrapper', 'link')}
                onClick={onAssumptionClick}
            >
                {code ? (
                    <Fragment>
                        <span className="customer-name" title={name}>
                            {name}
                        </span>
                        <span className="customer-details">{content}</span>
                        <span className="customer-details">{contactPersonName}</span>
                    </Fragment>
                ) : (
                    <Fragment>
                        {iconsConfig[parsedType]}
                        {/* <div className='search-content' dangerouslySetInnerHTML={{ __html: escapeBaseTag(content) }} /> */}
                        {removeHtmlTagsFromString(content)}
                    </Fragment>
                )}
            </Link>
        );
    }

    renderTab(data) {
        const {
            tabs,
            query,
            hideSearch,
            t,
            facets,
            canSearchContactPersons,
            canSearchCustomer,
            updateSearchQuery,
            canSearchEvents,
            canSearchServices,
            canSearchResources,
            canSearchTasks
        } = this.props;

        let [value, subValue] = data.split(".");

        const hash = calculateSearchNumber(tabs);
        if (value === 'callhistory' || value === 'file') {
            return false;
        }

        if (value === 'contact_person' && !canSearchContactPersons) {
            return false;
        }

        if (value === 'customer' && !canSearchCustomer) {
            return false;
        }

        if (value === 'clevent' && !canSearchEvents) {
            return false;
        }

        if (value === 'spec' && !canSearchServices) {
            return false;
        }

        if (value === 'resource' && !canSearchResources) {
            return false;
        }

        if (value === 'task' && !canSearchTasks) {
            return false;
        }

        const facetsValue = subValue ? facets[value][subValue] : facets[value];

        // remap search => Послуги => /service/ => /spec/ as it was working before
        if (subValue === 'service') {
            subValue = 'spec';
        }

        // TODO: once there will be ready other tabs - return link to either search/value/ or to search/subvalue (spec tabs)
        return (
            <NavLink
                key={subValue || value}
                className={cx('tab-link', 'link')}
                to={`/search/${subValue || value}/${hash}`}
                onClick={() => {
                    hideSearch();
                    updateSearchQuery(query, hash);
                }}
            >
                {`${t(`tabs.${subValue || value}`).toUpperCase()} (${facetsValue})`}
            </NavLink>
        );
    }

    render() {
        const { className, searchResults, facets } = this.props;
        return searchResults.length > 0 ? (
            <div className={cx(className, 'search-wrapper')}>
                <div className='search-wrapper-assumptions'>
                    {searchResults.map(this.renderAssumption)}
                </div>
                {facets && <div className="tab-wrapper">{tabConfig.map(this.renderTab)}</div>}
            </div>
        ) : null;
    }
}

LiveSearch.propTypes = {
    className: PropTypes.string,
    searchResults: PropTypes.array,
    query: PropTypes.string,
    hideSearch: PropTypes.func,
    facets: PropTypes.object,
    tabs: PropTypes.object,
};
