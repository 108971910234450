

const ACTIONS = {
    OPEN_EXPORT_WIZARD_MODAL: 'OPEN_EXPORT_WIZARD_MODAL',
    CLOSE_EXPORT_WIZARD_MODAL: 'CLOSE_EXPORT_WIZARD_MODAL',
};

const initialState = {
    isModalOpened: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.OPEN_EXPORT_WIZARD_MODAL: {
            return { ...state, isModalOpened: true };
        }

        case ACTIONS.CLOSE_EXPORT_WIZARD_MODAL: {
            return { ...state, isModalOpened: false };
        }


        default: {
            return state;
        }
    }
};

export const openExportWizardModal = (payload) => ({
    type: ACTIONS.OPEN_EXPORT_WIZARD_MODAL,
    payload,
});

export const closeExportWizardModal = (payload) => ({
    type: ACTIONS.CLOSE_EXPORT_WIZARD_MODAL,
    payload,
});

