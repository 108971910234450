import i18next from 'util/i18n';

const validate = (values) => {
	const errors = {};
	const name = (values.name || '').trim();
	const firstName = (values.firstName || '').trim();
	
	if (!name) {
		errors.name = i18next.t('validation.required');
	}
	
	if (!firstName) {
		errors.firstName = i18next.t('validation.required');
	}
	
	return errors;
};

export default validate;
