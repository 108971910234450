import React, {useEffect, useState} from 'react';
import Popup from 'components/Popup';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changeOpenedAccountModal, setModalAccountEditMode } from '../../actions/user';

const mapDispatchToProps = {
	changeOpenedAccountModal,
	setModalAccountEditMode
};

function UserCallPopupActions(props) {
	const { editPhoneId, isOpen, t, changeOpenedAccountModal, setModalAccountEditMode, isAuthorized  } = props;
	const [config, setConfig] = useState([{
									label: t('call.editAccount'),
									action: editAccount,
								}]);
	
	useEffect( () =>{
		if (editPhoneId && !isAuthorized) {
			return setConfig([{
					label: t('call.editAccount'),
					action: editAccount,
				},
				{
					label: t('call.addAccount'),
					action: addAccount,
				}])
		}
		return setConfig([{
			label: t('call.addAccount'),
			action: addAccount,
		}])
	}, [editPhoneId, isAuthorized]);
	
	function editAccount() {
		changeOpenedAccountModal();
		setModalAccountEditMode(editPhoneId);
	}
	function addAccount() {
		changeOpenedAccountModal();
		setModalAccountEditMode(false);
	}
	
	function onOptionClick (e, action){
		e.preventDefault();
		action();
		props.onClickOutside(e);
	}
	
	function renderOption ({ label, action }, index) {
		return <div className='popup-link' key={index.toString()} onClick={(e) => onOptionClick(e, action)}>{label}</div>
	}
	
		if (!isOpen) return null;
		
		return (
			<Popup place='grid-popup' onClickOutside={props.onClickOutside}>
				{config.map(renderOption)}
			</Popup>
		);
}

export default connect(null,mapDispatchToProps)(withTranslation()(UserCallPopupActions));
