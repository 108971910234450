import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Footer extends Component {
	getPagination = () => {
		const { pagination } = this.props.params;
		return !pagination ? null : pagination;
	};
	
	getCancel = () => {
		const { params: { cancel }, strings } = this.props;
		if (!cancel) return null;
		return (
			<button type='button' className='cancel-btn' onClick={cancel}>{strings.cancel}</button>
		);
	};
	
	getSubmit = () => {
		const { selected, params: { submit }, strings } = this.props;
		if (!submit) return null;
		return selected ? (
			<button type='button' className='edit-btn' onClick={submit(selected)}>{strings.select}</button>
		) : (
			<button type='button' className='edit-btn disabled'>{strings.cancel}</button>
		);
	};
	
	render = () => {
		return (
			<div className='grid-footer'>
				<div className='center-pagination' />
				{this.getPagination()}
				<div className='grid-footer-control'>
					{this.getCancel()}
					{this.getSubmit()}
				</div>
			</div>
		);
	};
}

Footer.propTypes = {
	selected: PropTypes.bool,
	submit: PropTypes.func,
	cancel: PropTypes.func,
	params: PropTypes.object,
	strings: PropTypes.object
};
