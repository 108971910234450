import React, { useEffect } from "react";
import {
    CompactDatePicker,
    CompactSelect,
    HeaderAppealInfo,
    HeaderMainCell,
    HeaderRow,
    HeaderSecondaryCell,
    HeaderSeparator,
} from "./components";
import { Field, reduxForm, Form } from "redux-form";
import PropTypes from "prop-types";

const Header = props => {
    const { t, unlockAppealForm, checkRestrictions, shouldRenderField, priorityList, initialize, initialised, priority, resolveDate } = props;

    function getPriorityOptions(priorityList) {
        return priorityList.map(item => ({
            label: item.name,
            value: item.id,
        }));
    }

	useEffect(() => {
		initialize({priority, resolveDate});
	}, []);


    return (
        <header className="formHeader">
            <Form onSubmit={() => {}}>
                <HeaderRow>
                    <HeaderMainCell>
                        <HeaderAppealInfo />
                    </HeaderMainCell>

                    <HeaderSecondaryCell>
                        {shouldRenderField("priorityId") && (
                            <Field
                                component={CompactSelect}
                                name="priority"
                                label={t("appeal.priority")}
                                placeholder={t("appeal.selectPlaceholder")}
                                options={getPriorityOptions(priorityList)}
                                onChange={() => unlockAppealForm("form")}
                                required={checkRestrictions("priorityId")}
                                disabled={checkRestrictions("priorityId")}
                            />
                        )}
                    </HeaderSecondaryCell>

                    <HeaderSecondaryCell>
                        <HeaderSeparator />
                    </HeaderSecondaryCell>

                    <HeaderSecondaryCell>
                        {shouldRenderField("resolveDateResolveDate") && (
                            <Field
                                name="resolveDate"
                                component={CompactDatePicker}
                                label={t("appeal.resolveDate")}
                                required
                                disabled={checkRestrictions("resolveDateResolveDate")}
                                onChange={() => unlockAppealForm("form")}
                            />
                        )}
                    </HeaderSecondaryCell>
                </HeaderRow>
            </Form>
        </header>
    );
};

Header.propTypes = {
    unlockAppealForm: PropTypes.func,
    checkRestrictions: PropTypes.func,
    shouldRenderField: PropTypes.func,
    repeatRequestId: PropTypes.number,
    operatorName: PropTypes.string,
    stateName: PropTypes.string,
    creationDate: PropTypes.number,
    repeatCustomerId: PropTypes.number,
    author: PropTypes.string,
    priorityList: PropTypes.array,
};

export default reduxForm({})(Header);
