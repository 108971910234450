import moment from 'moment';
import i18next from 'util/i18n';

export default function validate(values) {
    let errors = {};

    const error = (field) => i18next.t('validation.fromTo', { field });
    const CORRECT_DATE = i18next.t('validation.correctDate');

    if (values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'] && values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO']) {
        if (moment(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO']).isBefore(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM': error(i18next.t('filter.finishDate'))}
        }
    }
    
    if (values['INTERACTION_REQUEST_FINISH_DATE@FROM'] && values['INTERACTION_REQUEST_FINISH_DATE@TO']) {
            if (moment(values['INTERACTION_REQUEST_FINISH_DATE@TO']).isBefore(values['INTERACTION_REQUEST_FINISH_DATE@FROM'])) {
                errors = {...errors, 'INTERACTION_REQUEST_FINISH_DATE@FROM': error(i18next.t('filter.finishDate'))}
            }
        }

    if (values['INTERACTION_START_DATE@FROM'] && values['INTERACTION_START_DATE@TO']) {
        if (moment(values['INTERACTION_START_DATE@TO']).isBefore(values['INTERACTION_START_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_START_DATE@FROM': error(i18next.t('filter.startDate'))}
        }
    }

    if (values['INTERACTION_END_DATE@FROM'] && values['INTERACTION_END_DATE@TO']) {
        if (moment(values['INTERACTION_END_DATE@TO']).isBefore(values['INTERACTION_END_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_END_DATE@FROM': error(i18next.t('filter.endDate'))}
        }
    }

    if (values['ATTR_8@FROM'] && values['ATTR_8@TO']) {
        if (moment(values['ATTR_8@TO']).isBefore(values['ATTR_8@FROM'])) {
            errors = {...errors, 'ATTR_8@FROM': error(i18next.t('filter.feedbackDate'))}
        }
    }
    if (values['INTERACTION_ACCEPTED_DATE@FROM'] && values['INTERACTION_ACCEPTED_DATE@TO']) {
        if (moment(values['INTERACTION_ACCEPTED_DATE@TO']).isBefore(values['INTERACTION_ACCEPTED_DATE@FROM'])) {
            errors = {...errors, 'INTERACTION_ACCEPTED_DATE@FROM': error(i18next.t('filter.lastInProgresDate'))}
        }
    }

    if (values['INTERACTION_END_DATE@FROM'] && !moment(values['INTERACTION_END_DATE@FROM']).isValid()){
        errors = {...errors, 'INTERACTION_END_DATE@FROM': CORRECT_DATE}
    }

    if (values['INTERACTION_END_DATE@TO'] && !moment(values['INTERACTION_END_DATE@TO']).isValid()){
        errors = {...errors, 'INTERACTION_END_DATE@TO': CORRECT_DATE}
    }

    if (values['INTERACTION_REQUEST_FINISH_DATE@TO'] && !moment(values['INTERACTION_REQUEST_FINISH_DATE@TO']).isValid() ){
        errors = {...errors, 'INTERACTION_REQUEST_FINISH_DATE@TO': CORRECT_DATE}
    }

    if (values['INTERACTION_REQUEST_FINISH_DATE@FROM'] && !moment(values['INTERACTION_REQUEST_FINISH_DATE@FROM']).isValid() ){
        errors = {...errors, 'INTERACTION_REQUEST_FINISH_DATE@FROM': CORRECT_DATE}
    }

    if (values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO'] && !moment(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO']).isValid() ){
        errors = {...errors, 'INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO': CORRECT_DATE}
    }

    if (values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'] && !moment(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM']).isValid() ){
        errors = {...errors, 'INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM': CORRECT_DATE}
    }

    if (values['INTERACTION_START_DATE@FROM'] && !moment(values['INTERACTION_START_DATE@FROM']).isValid() ){
        errors = {...errors, 'INTERACTION_START_DATE@FROM': CORRECT_DATE}
    }

    if (values['INTERACTION_START_DATE@TO'] && !moment(values['INTERACTION_START_DATE@TO']).isValid() ){
        errors = {...errors, 'INTERACTION_START_DATE@TO': CORRECT_DATE}
    }

    if (values['ATTR_8@TO'] && !moment(values['ATTR_8@TO']).isValid() ){
        errors = {...errors, 'ATTR_8@TO': CORRECT_DATE}
    }

    if (values['ATTR_8@FROM'] && !moment(values['ATTR_8@FROM']).isValid() ){
        errors = {...errors, 'ATTR_8@FROM': CORRECT_DATE}
    }
    if (values['INTERACTION_ACCEPTED_DATE@TO'] && !moment(values['INTERACTION_ACCEPTED_DATE@TO']).isValid()) {
        errors = {...errors, 'INTERACTION_ACCEPTED_DATE@TO': CORRECT_DATE}
    }

    if (values['INTERACTION_ACCEPTED_DATE@FROM'] && !moment(values['INTERACTION_ACCEPTED_DATE@FROM']).isValid()) {
        errors = {...errors, 'INTERACTION_ACCEPTED_DATE@FROM': CORRECT_DATE}
    }
    
    if (!(values.filterName || '').trim()) {
		errors = {...errors, 'filterName': i18next.t('validation.required')};
    }
    
    return errors;
}
