import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import permissions, { checkPermissions } from 'config/permissions';
import { personUnlinkContact } from 'actions/customer';
import { connect } from 'react-redux';
import { setApi } from '../../../reducers/grid';
import { withRouter } from 'react-router-dom';

function UnlinkPersonCell (props) {
	const { data, t, updateApiGrid, match } = props;
	const [checked, setChecked] = useState(_.get(data, 'actual'));
	const [modal, setModal] = useState(false);
	const canUnlink = !checkPermissions(permissions.CustomerOperations.action_unlinkContactPersons);
	
	useEffect(() => {
		setChecked(_.get(data, 'actual'));
	}, [_.get(data, 'actual')]);
	
	function closeModal () {
		setModal(false);
	}
	
	function click (e) {
		e.preventDefault();
		e.stopPropagation();

		if (!!checked && !canUnlink) {
			setModal(true);
		}
	}
	
	async function submit () {
		const { id, currentLink } = data;
		
		let params = {
			personId: id,
			customerId: currentLink.entityId,
			linkId: currentLink.id
		};
		
		await personUnlinkContact(params);
		await setModal(false);
		await setChecked(false);
		await updateApiGrid({ customerId: match.params.id }, `${props.gridKey}_${match.params.id}` )
	}
	
	return (
		<div onClick={click} className='crm-wrapper data-cell' title={checked ? 'Активний' : 'Неактивний'}>
			<CheckBoxToggle
				checked={checked}
				disabled={canUnlink}
			/>
			<CSSTransition
				in={modal}
				classNames='fade'
				appear
				enter
				exit
				timeout={300}
				mountOnEnter
				unmountOnExit
			>
				<ModalPortal onClose={closeModal} className='modal-small modal-center'>
					<MessageModal
						titleModal={t('persons.attention')}
						contentModalText={t('persons.linkModalMessage')}
						primaryButton
						primaryButtonText={t('persons.allow')}
						onClickPrimaryButton={submit}
						secondaryButton
						secondaryButtonText={t('persons.disallow')}
						onClickSecondaryButton={closeModal}
					/>
				</ModalPortal>
			</CSSTransition>
		</div>
	)
}

export default connect(null, { updateApiGrid: setApi })(withRouter(UnlinkPersonCell));
