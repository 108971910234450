import React, { useMemo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import i18n from "i18next";
import ReactMarkdown from "react-markdown";
import { withTranslation } from "react-i18next";
import autosize from "autosize";

import "./styles.scss";

const MARKDOWN_TABS = t => [
    {
        name: t("text"),
        type: "text",
    },
    {
        name: t("preview"),
        type: "preview",
    },
];

const TextArea = props => {
    const {
        t,
        label,
        input,
        meta,
        placeholder,
        required,
        textAreaClassName,
        maxLength,
        disabled,
        withMarkdown,
        autosize: isAutosize,
        ...restProps
    } = props;

    const textAreaRef = useRef(null);

    const markDownTabs = useMemo(() => {
        return MARKDOWN_TABS(t);
    }, []);

    const [activeTab, setActiveTab] = useState(markDownTabs[0].type);

    const currentValue = useMemo(() => {
        return input && input.value;
    }, [input]);

    useEffect(() => {
        if (isAutosize && textAreaRef.current) {
            autosize.update(textAreaRef.current);
        }
    }, [currentValue]);

    useEffect(() => {
        if (isAutosize && textAreaRef.current) {
            autosize(textAreaRef.current);
        }
    }, [activeTab]);

    const error = meta && (meta.error || meta.warning);
    const className = cx(
        "input-field input-field-textarea",
        error && "input-field__error",
        meta && meta.active && !meta.error && !meta.warning && "active",
        textAreaClassName
    );

    function handleTabSelect(tab) {
        setActiveTab(tab.type);
    }

    return (
        <div className={cx("input-element input-element-textarea", { isAutosize })}>
            <div className="input-label">
                {label}
                {required && <span className="required-field">*</span>}
            </div>
            <div className={cx("input-field-wrap", { withMarkdown })}>
                <textarea
                    ref={textAreaRef}
                    className={cx(className, { hidden: activeTab === "preview" })}
                    disabled={disabled || activeTab === "visual"}
                    placeholder={!disabled ? placeholder || i18n.t("text") : ""}
                    maxLength={maxLength}
                    autosize={toString(isAutosize)}
                    {...input}
                    {...restProps}
                />

                {withMarkdown && (
                    <div className={cx("textarea-markdown", { hidden: activeTab === "text" })}>
                        <ReactMarkdown source={input.value} />
                    </div>
                )}
            </div>
            {withMarkdown && (
                <div className="markdown-switcher">
                    {markDownTabs.map(tab => (
                        <div
                            data-controler-force-unfocus={tab.type === "preview"}
                            className={cx("switcher-tab", { active: tab.type === activeTab })}
                            onClick={() => handleTabSelect(tab)}
                        >
                            {tab.name}
                        </div>
                    ))}
                </div>
            )}
            {/* {error && <span className="error-text error-hint">{meta.error}</span>} */}
        </div>
    );
};

TextArea.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    textAreaClassName: PropTypes.string,
};

export default withTranslation()(TextArea);
