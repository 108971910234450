import React, {useEffect, useRef} from 'react';
import { reduxForm,  Field, initialize } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { ComboBox } from 'ui-core-dashboard';

function DefaultsForm(props) {
	const {t, signList, initialize, onSubmit} = props;
	const counterRender = useRef(0);

	useEffect( () =>{
		//need for use initialize at once when component give signList from props
		if (signList.length){
			counterRender.current += 1;
		}
	},[signList]);
	
	useEffect( () =>{
		//need for use initialize at once when component give signList from props
		if (counterRender.current === 1){
			initialize(getInitialValues(signList))
		}
	},[counterRender.current]);
	
	function getInitialValues(list) {
		const forNewMail = list.find( ({action}) => Array.isArray(action) && action.includes('EMAILNEW'));
		const forForward = list.find( ({action}) => Array.isArray(action) && action.includes("EMAILREPLYALL", "EMAILREPLY", "EMAILFORWARD"));
		return {forNewMail: forNewMail && forNewMail.id, forForwardEtc: forForward && forForward.id}
	}
	
	return(
		<form className='defaults-block ordering-component-ui-core-wrapper' onSubmit={onSubmit}>
			<div className='defaults-title'>{t('signature.signsDefault')}</div>
			<div className='inline-wrapper'>
				<Field
					component={ComboBox}
					options={signList.length && signList.map( ({id, label}) => { return {value: id, label: label } }) }
					label={t('signature.forNewMail')}
					name='forNewMail'
					placeholder={t('dontSelect')}
					noResultsText={t('noResultsFound')}
					clearValueText={t('clear')}
				/>
				<Field
					component={ComboBox}
					options={signList.length && signList.map( ({id, label}) => { return {value: id, label: label } }) }
					label={t('signature.forForwardEtc')}
					name='forForwardEtc'
					placeholder={t('dontSelect')}
					noResultsText={t('noResultsFound')}
					clearValueText={t('clear')}
				/>
			</div>
			<button className='btn btn-primary' onClick={onSubmit}>{t('save')}</button>
		</form>
	)
}

export default reduxForm({ form: 'modal-defaults-sign-form', destroyOnUnmount: true})(
	(withTranslation()(DefaultsForm))
);
