import React, { useEffect, useState, useMemo } from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Header from '../Header/index';
import FiltersForm from 'components/FiltersForm/index';
import AppealListGrid from './AppealListGrid';
import ModalPortal from 'components/ModalPortal/index';
import MessageModal from 'components/MessageModal/index';
import { buildThreePath, searchTree } from 'helpers';
import {
	getInfoFilterSuppose,
	openFilterParams,
	closeNewFilterParams,
	saveFilter,
	deleteFilter,
	openedFilterAppeal,
} from 'actions/filter';
import { openMessageModal, closeMessageModal } from 'actions/ui';
import { queriesContentUpdate } from 'actions/content';
import styles from 'styles/modules/appealsContent.module.scss';

const FILTERS_FORM_NAME = 'appeal-filters';

const mapStateToProps = (state, props) => ({
	showMessageModal: state.ui.showMessageModal,
	tabs: state.tabs,
	saved: state.filter.saved,
    activeFilter: state.filter.openedFilter,
	filter: state.filter,
	rootAppealType: get(state, 'filter.appealTypes[0]'),
	formValues: getFormValues(FILTERS_FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch) => ({
	getInfoFilterSuppose: (params) => dispatch(getInfoFilterSuppose(params)),
	closeNewFilterParams: () => dispatch(closeNewFilterParams()),
	openFilterParams: () => dispatch(openFilterParams()),
	queriesContentUpdate: (params) => dispatch(queriesContentUpdate(params)),
	openedFilterAppeal: (openedFilter) => dispatch(openedFilterAppeal(openedFilter)),
	openMessageModal: () => dispatch(openMessageModal()),
	closeMessageModal: () => dispatch(closeMessageModal()),
	deleteFilter: (params, onSuccess) => dispatch(deleteFilter(params, onSuccess)),
	saveFilter: (params, onSuccess) => dispatch(saveFilter(params, onSuccess))
});


function MainContent (props) {
	const {
		filter, match, closeNewFilterParams, openFilterParams, openedFilterAppeal, saveFilter, closeMessageModal,
		openMessageModal, t, deleteFilter, rootAppealType, filters = [], showMessageModal, tabs, formValues,
		activeFilter, needUpdate, setNeedUpdate, match: {
			params: { filterId },
		}, needPageReset, setNeedPageReset
	} = props;
	
	const { newFilter, showFilterParams } = filter;
	const contentCls = cx(styles.gridContent, { 'open-filter': showFilterParams });
	
	// const currentFilter = filters.find(el => el.value === match.params.filterId);
	// to move such logic somewhere outer to not duplicate
	let currentFilter;

	if (filters && activeFilter) {
		filters.forEach(filter => {
			// if (+filter.filterId === +match.params.filterId) {
			if (+filter.filterId === activeFilter) {
				currentFilter = filter;
				return;
			}
			// check active filters in subfilters
			if (filter.filters) {
				filter.filters.forEach(subFilter => {
					// if (+subFilter.filterId === +match.params.filterId) {
					if (+subFilter.filterId === activeFilter) {
						currentFilter = subFilter;
					}
				});
			}
		});
	}
	const predefined = currentFilter ? currentFilter.predefined : false;
	const [ modalBody, setModalBody ] = useState(null);


	const isFilterOpen = useMemo(() => {
		return filter && filter.showFilterParams;
	}, [filter]);


	const initialValues = useMemo(() => {
		if (!isFilterOpen) {
			return {};
		}
		const { newFilter, filterAttrsResults = [], infoFilterParams, checkedFilterAttrs } = filter;
		
		// const currentFilter = filters.find(el => el.value === match.params.filterId);
		// to move such logic somewhere outer to not duplicate
		let currentFilter;

		if (filters && activeFilter) {
			filters.forEach(filter => {
				if (+filter.filterId === +match.params.filterId) {
					currentFilter = filter;
					return;
				}
				// check active filters in subfilters
				if (filter.filters) {
					filter.filters.forEach(subFilter => {
						if (+subFilter.filterId === +match.params.filterId) {
							currentFilter = subFilter;
						}
					});
				}
			});
		}
		
		const nameFilter = () => {
			if (newFilter) {
				const count = filters.reduce((count, item) => !item.predefined ? count + 1 : count, 0);
				return `Фiльтр ${count + 1}`;
			}
			
			return currentFilter ? currentFilter.name : (formValues && formValues.filterName);
		};
		
		const setInitialValues = () => {
			if (newFilter && !filter.saving) {
				return {
					filterName: nameFilter(),
					isPublic: false,
					predefined: false
				};
			}
			
			const paramsIdArr = infoFilterParams && infoFilterParams.map(el => el.paramId);
			const checkParams = Object.keys(checkedFilterAttrs).filter(el => checkedFilterAttrs[el]);
			
			let paramsNameArray = [];
			
			if (checkParams.length) {
				paramsNameArray = filterAttrsResults.filter(el => paramsIdArr && paramsIdArr.includes(el.id) && checkParams.includes(el.code));
			} else {
				paramsNameArray = filterAttrsResults.filter(el => paramsIdArr && paramsIdArr.includes(el.id));
			}
			
			const initObj = {};
			
			paramsNameArray.forEach(field => {
				const fieldName = field.code;
				
				if (field.type === 'THREE') {
					const options = buildThreePath(field.dict, false, { children: 'children' });
					// console.log({infoFilterParams});
					if (infoFilterParams.length && rootAppealType) {
						const currentAppealType = [];
						infoFilterParams.forEach((i) => {
							const item = searchTree(options, 'children', result => {
								// return i.value === result.key;
								return i.value === result.value;
							});

							// let parent;
							// if (item && item.path) {
							// 	const [parentId] = item.path.split("/");
							// 	if (parentId) {
							// 		parent = searchTree(options, 'children', result => {
							// 			// return i.value === result.key;
							// 			return parentId.trim() === result.value;
							// 		});
							// 	}
							// }

							// console.log({item, parent});
							
							if (item) {
								currentAppealType.push({
									// value: item.key,
									// id: item.key,
									// key: item.key,
									value: item.value,
									id: item.value,
									key: item.value,
									label: item.name,
									name: item.name,
									path: item.path,
								});
							}
						});

						// console.log({currentAppealType});
						
						if (currentAppealType && currentAppealType.length > 0) {
							initObj[fieldName] = currentAppealType;
						}
					}
				} else if (field.type === 'DATE') {
					const foundValues = infoFilterParams.filter(elem => elem.paramId === field.id);
					
					if (field.def_condition === 'EQU' && foundValues.length > 0) {
						initObj[fieldName] = foundValues[0].value;
						
					} else if (field.def_condition === 'BTW' && foundValues.length > 0) {
						
						foundValues.forEach(date => {
							const ending = date.conditionCode === 'LEQ' ? '@TO' : '@FROM';
							initObj[`${fieldName}${ending}`] = date.value;
						});
					}
					
				} else if ((field.type === 'LIST') && (field.multiset_allowed === 'Y')) {
					const listValue = infoFilterParams.filter(elem => elem.paramId === field.id);
					initObj[fieldName] = listValue.map(el => el.value);
					if (field.search && !field.dict_search) {
						initObj[fieldName] = listValue.map(el => {
								const option = field.dict.find((item) => {
									return el.value === item.key;
								});
								return {
									value: option ? option.key : null,
									label: option ? option.value : '',
								};
							}
						);
					}
					
					if (field.dict_search) {
						initObj[fieldName] = listValue.map(el => {
							return {
								value: el.value,
								label: el.label,
							};
						});
					}
				} else {
					initObj[fieldName] = infoFilterParams.filter(elem => elem.paramId === field.id)[0].value;
				}
			});

			// console.log({formValues, currentFilter, filter, isPublic: currentFilter ? !currentFilter.custom : formValues && formValues.isPublic});
			
			return {
				filterName: nameFilter(),
				// isPublic: currentFilter ? !currentFilter.custom : true,
				isPublic: currentFilter ? !currentFilter.custom : (formValues && formValues.isPublic),
				...initObj
			};
		};
		return setInitialValues();
	}, [isFilterOpen, filter.infoFilterParams, match.params.filterId, rootAppealType]);

	
	// can use this "check" functionality in the future
	//const queryKey = get(tabs, 'current.queryKey');
	// const checkedItems = get(tabs, `current.queries.${queryKey}.checkedItems`) || [];
	
	
	useEffect(() => {
		if (filter.newFilter) {
			closeNewFilterParams();
			openFilterParams();
		}
		// openedFilterAppeal(+match.params.filterId);
	},[match.params.filterId]);
	
	
	function onSelectItem (item) { props.history.push(`/appeals/${item.id}`);}

	function onClickSave(data) {
        const onSuccess = savedFilter => {
            history.push(`/appeal/filter/${savedFilter}`);
        };
        saveFilter({ data }, onSuccess);
        closeMessageModal();
    }

    function onClickDelete() {
        const onSuccess = () => {
            closeMessageModal();
            setNeedUpdate(true);
        };
        deleteFilter({ data: { filterId: +match.params.filterId, code: 'appeal' } }, onSuccess);
    }
	
	function openModal (type, data) {
		setModalBody(renderModalBody(type, data));
		openMessageModal();
	}
	
	function renderModalBody (type, data) {
		switch (type) {
		case 'savePredefined': {
			return <MessageModal
				titleModal={t('filters.alreadyExisting')}
				contentModalText={t('filters.predefinedExisting')}
			/>;
		}
		case 'saveUser': {
			return <MessageModal
				titleModal={t('filters.alreadyExisting')}
				contentModalText={t('filters.saveModalQuestion')}
				primaryButton={true}
				primaryButtonText={t('filters.save')}
				onClickPrimaryButton={() => onClickSave(data)}
				secondaryButton={true}
				secondaryButtonText={t('filters.cancel')}
				onClickSecondaryButton={closeMessageModal}
			/>;
		}
		case 'delete': {
			return <MessageModal
				titleModal={t('filters.titleRemoveModal')}
				contentModalText={t('filters.titleRemoveFilter')}
				dangerButton={true}
				dangerButtonText={t('filters.remove')}
				onClickDangerButton={onClickDelete}
				secondaryButton={true}
				secondaryButtonText={t('filters.cancel')}
				onClickSecondaryButton={closeMessageModal}
			/>;
		}
		default: {
				return null;
			}
		}
	}

	return (
		<div className={cx('main-content', styles.mainContent)}>
			{
				filters &&
				<Header
					data={filters}
					// id={+match.params.filterId}
					id={+activeFilter}
					openModal={openModal}
				/>
			}
			<div className={styles.contentAppeal}>
				<div className={contentCls}>
					<div className={styles.gridWrapper}>
						<div className='gridWrapper'>
							<div className='customer-ordering-grid'>
								{activeFilter && currentFilter && filterId && <AppealListGrid activeFilter={+activeFilter} needPageReset={needPageReset} setNeedPageReset={setNeedPageReset} gridConfigCode={get(currentFilter, "grid")} selectItem={onSelectItem} key={get(currentFilter, "grid", "")} />}
							</div>
						</div>
					</div>
				</div>
				<CSSTransition
					in={showFilterParams}
					classNames='pageSliderRight'
					appear={true}
					enter={true}
					exit={true}
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					<div className={styles.filtersForm}>
						<FiltersForm
							form={FILTERS_FORM_NAME}
							initialValues={initialValues}
							filterId={newFilter ? null : activeFilter}
							predefined={predefined}
							filters={filters}
							openModal={openModal}
							setNeedUpdate={setNeedUpdate}
						/>
					</div>
				</CSSTransition>
				
				<CSSTransition
					in={showMessageModal}
					classNames='fade'
					appear={true}
					enter={true}
					exit={true}
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					<ModalPortal
						onClose={closeMessageModal}
						className={cx('modal-small','modal-center')}
					>
						{modalBody}
					</ModalPortal>
				</CSSTransition>
			
			</div>
		</div>
	);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainContent)));

MainContent.propTypes = {
	data: PropTypes.object,
	gridData: PropTypes.object,
	tabs: PropTypes.object,
	showMessageModal: PropTypes.bool,
	toggleAppealForm: PropTypes.func,
	saved: PropTypes.bool,
	filterAttrsResults: PropTypes.array,
	checkedFilterAttrs: PropTypes.object,
	getInfoFilterSuppose: PropTypes.func,
	closeNewFilterParams: PropTypes.func,
	openFilterParams: PropTypes.func,
	queriesContentUpdate: PropTypes.func,
	openedFilterAppeal: PropTypes.func,
	saveFilter: PropTypes.func,
	deleteFilter: PropTypes.func
};
