import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Sidebar from './Sidebar';
import Main from './Main';
import styles from 'styles/modules/knowledgeBase.module.scss';
import BaseService from '../../services/BaseService';
import permissions, { checkPermissions } from '../../config/permissions';

function Specification () {
	const [catalogList, setCatalogList] = useState([]);
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [selectedNode, setSelectedNode] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [parentFolderId, setParentFolderId] = useState(false);
	const canEdit = checkPermissions(permissions.SpecOperations.action_manageSpec);
	
	useEffect(() => {
		requestData();
	}, []);
	
	async function requestData () {
		await setIsLoading(true);
	 	const response = await BaseService.get('get_service_catalogue', { data: { filter: 'SERVICE' } });
	 	setCatalogList(normalizeTree(response.children));
		await setIsLoading(false);
		await setParentFolderId(_.get(response, 'children[0].id'));
	}
	
	return (
		<div className={styles.page}>
			<Sidebar
				catalogList={catalogList}
				breadcrumbs={breadcrumbs}
				setBreadcrumbs={setBreadcrumbs}
				setSelectedNode={setSelectedNode}
				canEdit={canEdit}
				selectedNode={selectedNode}
				isLoading={isLoading}
				parentFolderId={parentFolderId}
			/>
			<Main
				catalogList={catalogList}
				breadcrumbs={breadcrumbs}
				requestData={requestData}
				canEdit={canEdit}
				selectedNode={selectedNode}
				parentFolderId={parentFolderId}
			/>
		</div>
	);
}

export default Specification;


function normalizeTree (tree, level = 0, parentId) {
	tree.forEach((item) => {
		const isFolder = !item.leaf;
		item.level = level;
		item.parentId = parentId;
		
		if (isFolder && item.id) {
			normalizeTree(item.children || [], level + 1, item.id);
		}
	});
	
	return tree;
}
