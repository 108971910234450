import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../common.scss';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import CalendarContainer from './CalendarContainer';
import ErrorBlock from 'components/CustomerPage/Parameters/components/ErrorBlock.js';

const DEFAULT_DAY_FORMAT = 'DD.MM.YYYY';
const DEFAULT_DAY_AND_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

@withTranslation()
class DateTimePicker extends React.Component {
	state = {
		showTimeSelect: false,
		left: undefined,
		top: undefined,
	};
	
	componentDidUpdate (prevProps, prevState) {
		if (!prevState.showTimeSelect && this.state.showTimeSelect) {
			this.calendar.setOpen(true);
		} else if (prevState.showTimeSelect && !this.state.showTimeSelect) {
			this.calendar.setOpen(false);
			this.calendar.cancelFocusInput();
		}
	};
	
	handleChange = (date) => {
		const { onlyDate, input, showTimeSelectProp } = this.props;
		input.onChange(date);

		if (input.onFocus && typeof input.onFocus === 'function') {
			input.onFocus();
		}
		
		if (onlyDate) {
		}
		else if (showTimeSelectProp){
			// this.setState({ showTimeSelect: true });
		}
		else {
			this.setState({ showTimeSelect: !this.state.showTimeSelect });
		}
	};
	
	onClickOutside = (e) => {
		this.calendar.setOpen(false);
		this.calendar.cancelFocusInput();
		this.setState({ showTimeSelect: false });
	};
	
	onKeyDown = (e) => {
		if (e.keyCode === 27) {
			this.onClickOutside();
		}
	};
	

	// handle manual date change
	onChangeRaw = (date) => {
		const { value } = date.currentTarget;
		const { input, isAsync } = this.props;
		const trimmedValue = value.trim();
		date.currentTarget.value = trimmedValue;
		//TODO NEED TO FINISHED OLD FORMAT CHANGE ON NEW BOTTTOM

		let format = this.props.dayAndTimeFormat || DEFAULT_DAY_AND_TIME_FORMAT;
		if (this.props.onlyDate) {
			format = this.props.dayFormat || DEFAULT_DAY_FORMAT;
		}

		// only provide a valid 
		const isValid = moment(trimmedValue, format).format(format) === trimmedValue;
		const finalValue = isValid ? moment(trimmedValue, format) : trimmedValue;

		const isEqual = finalValue.toString() === input.value.toString();
		if (!isEqual) {
			return input.onChange(finalValue);
		}

		// const finalValue = isValid ? moment(trimmedValue, format) : trimmedValue;
		// console.log({finalValue, format, isValid, trimmedValue, inputValue: input.value, props: this.props});
		// return input.onChange(finalValue);
		// if(isAsync){
		// 	return	moment(trimmedValue).isValid() ? input.onChange(moment(trimmedValue) ) : null;
		// }
		// return input.onChange(value ? moment(trimmedValue) : trimmedValue);
	};
	
	onBlur = () => {
		const { input, changeOnBlur } = this.props;
		const value = input.value;
		if (changeOnBlur) {
			changeOnBlur(moment(value), input.name);
		}
		input.onBlur();
		this.setState({ showTimeSelect: false });
		this.calendar.setOpen(false);
		this.calendar.cancelFocusInput();
	};
	
	saveRef = (node) => {
		this.calendar = node;
	};
	
	render () {
		const {
			onlyDate, label, disabled, meta, required, input, t, popperPlacement, popperClassName, showTimeSelectProp,
			customInput, dayFormat = DEFAULT_DAY_FORMAT, dayAndTimeFormat = DEFAULT_DAY_AND_TIME_FORMAT, inputClass, maxDateCurrent, showErrorHint,
			maxDate, minDate, minTime, maxTime,
			...rest
		} = this.props;
		
		const elementCls = cx('input-element datepicker', {
			'error': meta && meta.touched && (meta.error || meta.warning)
		});
		
		const popperCls = cx('datepicker-popper datepicker-portal', popperClassName);
		
		const selectedItem = (moment(this.props.input.value).isValid() && this.props.input.value) ? moment(this.props.input.value) : null;
		
		const showError = meta && meta.touched && (meta.error || meta.warning);
		
		const showStyledError = meta && showErrorHint && (meta.error || meta.warning);
		
		const inputClassName = inputClass || cx('input-field', {
			'input-field__error': meta && (meta.touched || showStyledError) && (meta.error || meta.warning),
		});
		
		return (
			<div id={rest.id} className={elementCls} ref={(node) => this.calendarWrapper = node}>
				{label &&
				<div className='input-label'>
					{t(`customerInfoLabel.${label}`, { defaultValue: label })}
					{required && <span className='required-field'>*</span>}
				</div>
				}
				<DatePicker
					className={inputClassName}
					showTimeSelect={showTimeSelectProp || this.state.showTimeSelect}
					showTimeSelectOnly={this.state.showTimeSelect}
					dateFormat={onlyDate ? dayFormat : dayAndTimeFormat}
					timeFormat='HH:mm'
					selected={selectedItem}
					ref={this.saveRef}
					onClickOutside={this.onClickOutside}
					popperClassName={popperCls}
					popperPlacement={popperPlacement || 'bottom-end'}
					calendarClassName='calendar'
					onKeyDown={this.onKeyDown}
					timeCaption='час'
					timeIntervals={30}
					disabled={disabled}
					popperContainer={CalendarContainer}
					onChangeRaw={this.onChangeRaw}
					name={input.name}
					onBlur={this.onBlur}
					onChange={this.handleChange}
					autoComplete='off'
					showMonthDropdown
					showYearDropdown
					scrollableYearDropdown
					yearDropdownItemNumber={108}
					customInput={customInput}
					maxDate={maxDateCurrent ? moment() : maxDate}
					minDate={minDate || null}
					{...minTime && maxTime && ({
						minTime,
						maxTime,
				   })}
				/>
				{showStyledError && !showError && <div className='error-date-range error-text error-hint'>{meta.error}</div>}
				
				{showError && <ErrorBlock error={meta.error} />}
			</div>
		);
	}
}

DateTimePicker.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	label: PropTypes.string,
	required: PropTypes.bool
};

export default DateTimePicker;
