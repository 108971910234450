import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import get from 'lodash/get';
import cx from 'classnames';

export default class PencilTextInput extends PureComponent {
	textarea = React.createRef();
	
	state = {
		focused: false
	};
	
	componentDidMount = () => {
		if (this.props.autosize) {
			autosize(this.textarea.current);
		}
	};
	
	componentDidUpdate = (prevProps) => {
		const previousValue = get(prevProps, 'input.value');
		const currentValue = get(this.props, 'input.value');
		if (previousValue !== currentValue) {
			this.textarea.current.scrollTop = this.textarea.current.scrollHeight;
			this.props.autosize && autosize.update(this.textarea.current);
		}
	};
	
	handleMouseDown = (event) => {
		if (this.textarea) {
			this.setState({ focused: true });
		}
		
		this.props.onMouseDown && this.props.onMouseDown(event);
	};
	
	handleFocus = (event) => {
		this.setState({ focused: true });
		this.props.input && this.props.input.onFocus && this.props.input.onFocus(event);
	};
	
	handleBlur = (event) => {
		this.setState({ focused: false });
		this.props.input && this.props.input.onBlur && this.props.input.onBlur(event);
	};
	
	render = () => {
		const { placeholder, rows = 1, meta, input, required, disabled, autosize, hideEditIcon, ...props } = this.props;
		const { focused } = this.state;
		const viewPlaceholder = !(this.props.input && this.props.input.value) && !this.state.focused;
		const alert = meta && meta.touched && (meta.error || meta.warning);
		
		return (
			<div className={cx('pencilTextInputWrapper', { 'pencilTextInputError': alert })}>
				{
					!focused &&
						<span
							className={viewPlaceholder ?
								cx('pencilTextInputPlaceholder', { 'disabled': disabled }) :
								cx('pencilTextInputValue', { 'disabled': disabled })}
							onMouseDown={this.handleMouseDown}
						>
							{viewPlaceholder ? placeholder : input.value}
							{required && <span className={'required'}>*</span>}
							{meta && meta.touched && (meta.error || meta.warning) && <i className='icon icon-error' />}
							
							{!hideEditIcon && <i className='icon-edit' />}
						</span>
				}
				<textarea
					ref={this.textarea}
					className={cx('pencilTextInput', { 'pencilTextInputHidden': !focused })}
					placeholder={''}
					rows={rows}
					{...input}
					{...props}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					disabled={disabled}
				/>
			</div>
		);
	};
}

PencilTextInput.propTypes = {
	props: PropTypes.any,
	placeholder: PropTypes.string,
	rows: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	meta: PropTypes.object,
	input: PropTypes.object,
	autosize: PropTypes.bool,
	onMouseDown: PropTypes.func,
	hideEditIcon: PropTypes.bool
};
