import {
	SEARCH_RESULTS_REQUEST,
	SEARCH_RESULTS_REQUEST_SUCCESS,
	SEARCH_RESULTS_REQUEST_FAILURE,
	SEARCH_QUERY_UPDATE,
	SEARCH_PAGINATION_UPDATE,
	CLEAR_SEARCH_AND_SEARCH_FILTER,
	SEARCH_TAB_HASH_UPDATE,
} from 'constants/actions';
import baseService from 'services/BaseService';

export function getSearchResults (requestData, entityType) {
	return dispatch => {
		const params = { data: requestData };
		const url = 'filter_apply';
		dispatch({ type: SEARCH_RESULTS_REQUEST, entityType });
		return baseService.post(url, params)
			.then(response => {
				if (response.success && Array.isArray(response.result)) {
					dispatch({
						type: SEARCH_RESULTS_REQUEST_SUCCESS,
						results: response.result,
						total: response.total,
						idHashKeyRequest: response.idHashKeyRequest,
						entityType,
					});
				} else {
					throw new Error('Search results error');
				}
			})
			.catch(() => {
				dispatch({
					type: SEARCH_RESULTS_REQUEST_FAILURE
				});
			});
	};
}

export function clearSearchAndSearchFilters (idHash, id) {
	return dispatch => {
		dispatch({
			type: CLEAR_SEARCH_AND_SEARCH_FILTER,
			idHash,
			id,
		});
	};
}

export function setEmptySearchResults (entityType) {
	return dispatch => {
		dispatch({
			type: SEARCH_RESULTS_REQUEST_SUCCESS,
			results: [],
			total: 0,
			entityType,
		});
	};
}

export const updateSearchQuery = (query, id) => ({
	type: SEARCH_QUERY_UPDATE,
	query,
	id,
});

export const updatePagination = (id, pagination) => ({
	type: SEARCH_PAGINATION_UPDATE,
	pagination,
	id,
});

export const updateSearchTabHash = tabHash => ({
	type: SEARCH_TAB_HASH_UPDATE,
	tabHash
});
