import React, { Fragment } from 'react';
import styles from 'styles/modules/appealQuality.module.scss';
import PropTypes from 'prop-types';

import StyledRadio from 'components/Common/StyledRadio';

export default class Radio extends React.Component {
	
	renderRadioItem = radio => {
		const { input, required, disabled, meta } = this.props;
		const checked = radio.key == input.value;

		return (
			<StyledRadio
				{...input}
				input={input}
				name={input.name}
				onChange={input.onChange}
				value={radio.key}
				title={radio.value}
				checked={checked}
				disabled={disabled}
				error={meta && meta.error}
				{...required}
			
			/>
		);
	};
	
	render () {
		const { t, label, required, list, description, ...restProps } = this.props;
		return (
			<Fragment>
				<div className={styles.appealQualityRow} {...restProps}>
					<p className={styles.appealQualityFormQuestion}>{label}
						{required && <span className='required-field'>*</span>}
					</p>
					{description && <p className={styles.appealQualityFormDescription}>{description}</p>}
					<fieldset className={styles.appealQualityRadio}>
						{list.map(this.renderRadioItem)}
					</fieldset>
				</div>
			</Fragment>
		);
	}
}

Radio.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	list: PropTypes.array
};
