import {
	CUSTOMER_REQUEST,
	CUSTOMER_REQUEST_SUCCESS,
	CUSTOMER_REQUEST_FAILURE,
	CUSTOMER_MODIFY,
	CLEAR_CUSTOMER_STATE,
	
	APPLICANTS_REQUEST_SUCCESS,
	APPLICANTS_REQUEST_FAILURE,
	CLEAR_APPLICANTS_STATE,
	APPLICANTS_REQUEST,
	
	CUSTOMER_EDIT_BEGIN,
	CUSTOMER_EDIT_SUCCESS,
	CUSTOMER_EDIT_ERROR,
	
	GET_SEARCH_BANK,
	GET_SEARCH_BANK_SUCCESS,
	GET_SEARCH_BANK_ERROR,
	
	CUSTOMER_ADDRESS_REQUEST,
	CUSTOMER_ADDRESS_REQUEST_SUCCESS,
	CUSTOMER_ADDRESS_REQUEST_FAILURE,
	CUSTOMER_ADDRESS_CLEAR,
	CUSTOMER_ADDRESS_MODAL_OPEN,
	CUSTOMER_ADDRESS_MODAL_CLOSE,
	
	CLEAR_CONTACT_PERSONS_LIST,
	SET_CONTACT_PERSONS_LIST,
	LOADING_CONTACT_PERSONS_LIST,
	CONTACT_PERSON_REQUEST,
	CONTACT_PERSON_REQUEST_SUCCESS,
	CONTACT_PERSON_REQUEST_FAILURE,
	CLEAR_CONTACT_PERSON_STATE,
	PERSON_EDIT_BEGIN,
	PERSON_EDIT_SUCCESS,
	PERSON_EDIT_ERROR,
	PERSON_SAVE_CONTACT_BEGIN,
	PERSON_SAVE_CONTACT_SUCCESS,
	PERSON_SAVE_CONTACT_ERROR,
	PERSON_DELETE_CONTACT_BEGIN,
	PERSON_DELETE_CONTACT_SUCCESS,
	PERSON_DELETE_CONTACT_ERROR,
	CUSTOMER_TAB_CONTENT_UPDATE,
	CLEAR_ENTIRE_CUSTOMER_STATE,
	CLEAR_ALL_CUSTOMERS,
	
	APPLICANT_FILTERS_REQUEST,
	APPLICANT_FILTERS_REQUEST_SUCCESS,
	APPLICANT_PRESET_FILTERS_REQUEST_SUCCESS,
	APPLICANT_FILTERS_REQUEST_FAILURE,
	APPLICANT_SET_ENTITY_TAB,
	
	REQUEST_CUSTOMER_BILLING_CARDS,
	SET_CUSTOMER_BILLING_CARDS,
	RESET_CUSTOMER_BILLING_CARDS,
	SET_CUSTOMER_GRID_REFRESH,
	CONTACT_PERSON_FIELD_EDIT,
	CUSTOMER_FIELD_EDIT,

	CUSTOMER_ADD_LIVE_SAVING,
	CUSTOMER_REMOVE_LIVE_SAVING,
} from 'constants/actions';
import { closeCreateNewContactPersonModal, showNotification } from './ui';
import baseService from '../services/BaseService';
import { NotificationTypes } from 'constants/index';
import {requestAllFilterFieldsFailure, requestAllFilterFieldsSuccess} from "./searchFilter";

/********  Customer actions  ********/

export function clearCustomerState (id) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_CUSTOMER_STATE,
			id,
		});
	};
}

export function modifyCustomer (id) {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_MODIFY,
			id,
		});
	};
}

export function getCustomer (requestData, id, silent, onSuccess) {
	return (dispatch, getState) => {
		const { customer } = getState();
		const persons = customer && customer.persons;
		const person = persons[id];
		const customerLoading = person && person.customerLoading;
		if (customerLoading) {
			return;
		}

		dispatch({
			type: CUSTOMER_REQUEST,
			id,
			silent,
		});
		
		const params = { data: requestData };
		
		return baseService.get('customer', params)
			.then(response => {
				let result = null;
				if (response.success && response.result) {
					result = { ...response.result, timestamp: response.timestamp };
					dispatch({
						type: CUSTOMER_REQUEST_SUCCESS,
						customerData: result,
						id,
					});
					if (onSuccess && typeof onSuccess === 'function') {
						onSuccess(result);
					}
				} else {
					throw new Error('Customer request error');
				}
				return result;
			})
			.catch(() => {
				dispatch({
					type: CUSTOMER_REQUEST_FAILURE,
					id,
				});
				return null;
			});
	};
}

/********  Contact person request  ********/

export function clearContactPersonState (id) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_CONTACT_PERSON_STATE,
			id,
		});
	};
}

export function getContactPerson (requestData, id, silent, onSuccess) {
	return (dispatch) => {
		dispatch({
			type: CONTACT_PERSON_REQUEST,
			id,
			silent,
		});
		
		const params = { data: requestData };
		
		return baseService.get('person', params)
			.then(response => {
				let result = null;
				if (response.success && response.result) {
					result = { ...response.result, timestamp: response.timestamp };
					dispatch({
						type: CONTACT_PERSON_REQUEST_SUCCESS,
						contactPersonData: result,
						id,
					});
					if (onSuccess && typeof onSuccess === 'function') {
						onSuccess(result);
					}
				} else {
					throw new Error('Contact person request error');
				}
				return result;
			})
			.catch(() => {
				dispatch({
					type: CONTACT_PERSON_REQUEST_FAILURE,
					id,
				});
				return null;
			});
	};
}

/********  Customer editing  ********/

export const customerEdit = ({ data, isMulti, id, onError = () => {}, onSuccess = () => {} }) => {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_EDIT_BEGIN,
			id,
		});
		
		const editLink = isMulti ? 'customer_edit_multi' : 'customer_edit';
		baseService.post(editLink, { data, jsonType: true })
			.then(data => {
				onSuccess(data);
				dispatch({
					type: CUSTOMER_EDIT_SUCCESS,
					payload: data,
					id,
				});
			})
			.catch(err => {
				onError(err);
				dispatch({
					type: CUSTOMER_EDIT_ERROR,
					id,
				});
			});
	};
};

export const contactPersonFieldEdit = (payload) => ({
	type: CONTACT_PERSON_FIELD_EDIT,
	payload,
});

export const customerFieldEdit = (payload) => ({
	type: CUSTOMER_FIELD_EDIT,
	payload,
});

/********  Customer primary address request ********/

export function getCustomerAddress (requestData, id) {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_ADDRESS_REQUEST,
			id,
		});
		
		const params = { data: requestData };
		
		return baseService.get('customer_address', params)
			.then(response => {
				let primaryAddress;
				
				if (response.success && response.result && Array.isArray(response.result)) {
					primaryAddress = response.result.find(address => address.type === 'physical');
				}
				
				if (primaryAddress) {
					dispatch({
						type: CUSTOMER_ADDRESS_REQUEST_SUCCESS,
						primaryAddress,
						id,
					});
				} else {
					throw new Error('Customer have no physical address');
				}
			})
			.catch(() => {
				dispatch({
					type: CUSTOMER_ADDRESS_REQUEST_FAILURE,
					id,
				});
			});
	};
}

export function clearCustomerAddress (id) {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_ADDRESS_CLEAR,
			id,
		});
	};
}

/********  Customer primary address modal ********/

export function openCustomerAddressModal (id) {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_ADDRESS_MODAL_OPEN,
			id,
		});
	};
}

export function closeCustomerAddressModal (id) {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_ADDRESS_MODAL_CLOSE,
			id,
		});
	};
}

/********  Applicants request  ********/

export function clearApplicantsState (id) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_APPLICANTS_STATE,
			id,
		});
	};
}

export function getApplicants (requestData, id) {
	return (dispatch) => {
		dispatch({
			type: APPLICANTS_REQUEST,
			id,
		});
		
		const params = {
			data: requestData,
		};

		return baseService.post('search', params)
			.then(response => {
				if (response.success && response.result) {
					dispatch({
						type: APPLICANTS_REQUEST_SUCCESS,
						applicants: response.result,
						id
					});
				} else {
					dispatch({
						type: APPLICANTS_REQUEST_FAILURE,
						id
					});
				}
				return response.result || [];
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: APPLICANTS_REQUEST_FAILURE,
					id
				});
				return [];
			});
	};
}

/***********************************/

export function clearEntireCustomerState (id) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ENTIRE_CUSTOMER_STATE,
			id,
		});
	};
}

export function personEdit ({ data, isMulti, id, onError = () => {} }) {
	return (dispatch) => {
		dispatch({
			type: PERSON_EDIT_BEGIN,
			id
		});
		
		const editLink = isMulti ? 'person_edit_multi' : 'person_edit';
		return baseService.post(editLink, { data, jsonType: true })
			.then(data => dispatch({
				type: PERSON_EDIT_SUCCESS,
				payload: data,
				id,
			}))
			.catch(err => {
				onError(err);
				dispatch({
					type: PERSON_EDIT_ERROR,
					id,
				});
			});
	};
}

export function issuesEdit ({ data, isMulti, id, onError = () => {} }) {
	return (dispatch) => {
		dispatch({ type: PERSON_EDIT_BEGIN, id });
		
		baseService.post('issues_edit', { data, jsonType: true })
			.then(data => dispatch({
				type: PERSON_EDIT_SUCCESS,
				payload: data,
				id,
			}))
			.catch(err => {
				onError(err);
				dispatch({
					type: PERSON_EDIT_ERROR,
					id,
				});
			});
	};
}

export function personSaveContact ({ data, id, onError = () => {}, onSuccess = () => {} }) {
	return (dispatch) => {
		dispatch({ type: PERSON_SAVE_CONTACT_BEGIN, id });
		
		baseService
            .post('person_save_contact', { data, jsonType: true })
            .then((data) => {
				onSuccess(data);
                dispatch({
                    type: PERSON_SAVE_CONTACT_SUCCESS,
                    payload: data,
                    id,
                });
            })
            .catch((err) => {
                onError(err);
                dispatch({
                    type: PERSON_SAVE_CONTACT_ERROR,
                    id,
                });
            });
	};
}

export function personDeleteContact ({ data, id, onError = () => {}, onSuccess = () => {} }) {
	return (dispatch) => {
		dispatch({ type: PERSON_DELETE_CONTACT_BEGIN, id });
		
		const params = { data };
		
		baseService
		.post('person_delete_contact', params)
		.then((data) => {
			onSuccess(data);
			dispatch({
				type: PERSON_DELETE_CONTACT_SUCCESS,
				payload: data,
				id,
			});
		})
		.catch((err) => {
			onError(err);
			dispatch({ type: PERSON_DELETE_CONTACT_ERROR, id });
		});
	};
}

export const personUnlinkContact = (data, onSuccess) => {
	const params = { data };
	
	return baseService.post('person_unlink', params)
		.then(response => {
			if (response.success) {
				onSuccess && onSuccess();
			}
		})
		.catch(console.error);
	
};

export function createNewContactPerson (params, t) {
	// console.log({params, t});
	return (dispatch) => {
		return baseService.post('customer_persons', params)
			.then(response => {
				// dispatch(closeCreateNewContactPersonModal());
				
				if (response.success) {
					dispatch(closeCreateNewContactPersonModal());
					dispatch(showNotification({
						type: NotificationTypes.SUCCESS,
						options: {
							// message: t('Контактна особа створена успішно'),
							message: t('createContactPersonSuccess')
						}
					}));
					
					return response.result;
				} else {
					throw new Error('Contact person creation request error');
				}
			})
			.catch(error => {
				console.error(error);
				return null;
			});
	};
}

export function clearContactPersonsList (id) {
	return (dispatch) => {
		dispatch({ type: CLEAR_CONTACT_PERSONS_LIST, id });
	};
}

export function clearAllCustomers () {
	return (dispatch) => {
		dispatch({ type: CLEAR_ALL_CUSTOMERS });
	};
}

export const getContactPersonsList = (organizationId) => dispatch => {
	dispatch({
		type: LOADING_CONTACT_PERSONS_LIST,
		id: organizationId
	});
	
	const params = {
		data: { customerId: organizationId }
	};
	
	return baseService.get('customer_persons', params)
		.then(data => dispatch({
			type: SET_CONTACT_PERSONS_LIST,
			payload: data.result,
			id: organizationId,
		}))
		.catch(() => dispatch({
			type: CLEAR_CONTACT_PERSONS_LIST,
			id: organizationId,
		}));
};

export const getSearchBank = () => ({
	type: GET_SEARCH_BANK
});

export const getSearchBankSuccess = (payload) => ({
	type: GET_SEARCH_BANK_SUCCESS,
	payload
});

export const getSearchBankError = () => ({
	type: GET_SEARCH_BANK_ERROR
});

export const getBankList = (data) => (dispatch) => {
	dispatch(getSearchBank());
	
	const params = {
		data
	};
	
	baseService.get('customer_bank', params)
		.then(data => dispatch(getSearchBankSuccess(data)))
		.catch(err => dispatch(getSearchBankError(err)));
};

export function updateCustomerTabContent (id) {
	return (dispatch) => {
		dispatch({
			type: CUSTOMER_TAB_CONTENT_UPDATE,
			id,
		});
	};
}

const requestFilters = () => ({
	type: APPLICANT_FILTERS_REQUEST,
});

const requestFiltersFailure = () => ({
	type: APPLICANT_FILTERS_REQUEST_FAILURE,
});

export const getFilters = (type) => dispatch => {
	dispatch(requestFilters());
	dispatch(getPresetsFilters(type));
	
	return baseService.get('attributes', { data: {code: Codes[type]} })
		.then(response => {
			dispatch({
				type: APPLICANT_FILTERS_REQUEST_SUCCESS,
				filterList: response.result
			});
		})
		// .catch(error => {
		// 	console.error(error);
		// 	dispatch(requestFiltersFailure());
		// 	return null;
		// });
};
const getPresetsFilters = (type) => dispatch => {

	return baseService.get('filters', { data: {code: Codes[type]} })
		.then(response => {
			dispatch({
				type: APPLICANT_PRESET_FILTERS_REQUEST_SUCCESS,
				filterPresetList: response.result
			});
		})
		.catch(error => {
			console.error(error);
			dispatch(requestFiltersFailure());
			return null;
		});
};

export const setEntityTab = (type, silent) => dispatch => {
	// console.log({type, silent});
	if (!silent) {
		dispatch(getFilters(type));
	}

	return dispatch({
				type: APPLICANT_SET_ENTITY_TAB,
				tabEntity: type
			})
};

export const resetBillingCards = () => ({
	type: RESET_CUSTOMER_BILLING_CARDS
});

export const getBillingCards =(pApartId, customerId) => dispatch =>{
	dispatch({type: REQUEST_CUSTOMER_BILLING_CARDS});
	
	return baseService.get('billing_cards',{ data: { pApartId, customerId } })
		.then(response =>{
			if(response.success && response.result && Array.isArray(response.result)) {
				const options = response.result.map(({acc, fio, address}) => ({
					value: String(acc).trim(),
					label: String(fio).trim(),
					address: String(address).trim()
				}));
				return dispatch({
					type: SET_CUSTOMER_BILLING_CARDS,
					billingCards: options
				})
			}
			return dispatch(resetBillingCards())
		})
		.catch(err => {
			console.error(err);
			return dispatch(resetBillingCards())
		});
};

export const setCustomerGridRefresh = (gridName, isRefresh) => ({
	type: SET_CUSTOMER_GRID_REFRESH,
	gridName,
	isRefresh
});

export const customerAddLiveSaving = (id, liveSaveKey) => ({
	type: CUSTOMER_ADD_LIVE_SAVING,
	id, liveSaveKey
});

export const customerRemoveLiveSaving = (id, liveSaveKey) => ({
	type: CUSTOMER_REMOVE_LIVE_SAVING,
	id, liveSaveKey
});


export const Codes = {
	customer: 'CUSTOMER_MDLK',
	contact_person: 'CONTACT_PERSON_MDLK',
};
