import {
	emailValidation,
	fieldLevelValidation,
	phoneValidation,
	requiredFieldsValidation,
	zipCodeValidation,
	fieldValidation
} from 'util/form';

const rules = {
	email: emailValidation,
	phone: phoneValidation,
	workPhone: phoneValidation,
	homePhone: phoneValidation,
};

function validate (values, props) {
	const errors = {};
	const { dynConfig } = props;
	const widgets = [];
	dynConfig.forEach(param => widgets.push(...param.widgets));
	const required = widgets.map(widget => (!widget.disabled && { name: widget.key, required: widget.isReq }));
	const rulesDynamic = widgets.reduce((prev, curr) => {
		return {
			...prev,
			[`${curr.key}`]: !curr.disabled && fieldValidation(curr.rule, curr.widgetType)
		};
	}, {});
	
	let config = [...props.config].filter(staticWidget => !staticWidget.hidden);
	const streetIndex = config.findIndex((i) => (i.name === 'street'));
	
	if (config[streetIndex] && config[streetIndex].required && !values.street) {
		config = [...config, { name: 'streetName', required: true }, { name: 'streetType', required: true }];
	}
	
	if (values.streetName && values.streetType) {
		config[streetIndex] = { ...config[streetIndex], required: false };
	}
	
	requiredFieldsValidation(values, [...config, ...required], errors);
	fieldLevelValidation(values, {...rules, ...rulesDynamic}, errors);
	
	if (values.zipCodeInput) {
		if (zipCodeValidation(values.zipCodeInput)) {
			errors.zipCode = zipCodeValidation(values.zipCodeInput);
		} else {
			delete errors.zipCode;
		}
	}
	
	if (values.buildingNumberInput) {
		delete errors.buildingNumber;
	}
	
	// console.log({errors, required, config});
	return errors;
}

export default validate;
