import {
	CREATE_NEW_APPEAL,
	CREATE_NEW_APPEAL_SUCCESS,
	CREATE_NEW_APPEAL_ERROR,
	GET_APPEAL_TYPES_LIST_SUCCESS,
	GET_APPEAL_TYPES_LIST_ERROR,
	GET_DINAMIC_FORM_PARAMS,
	GET_DINAMIC_FORM_PARAMS_SUCCESS,
	GET_DINAMIC_FORM_PARAMS_ERROR,
	GET_APPEAL_STATUS_LIST,
	GET_APPEAL_STATUS_LIST_SUCCESS,
	GET_APPEAL_STATUS_LIST_ERROR,
	SET_APPEAL_STATUS_LIST_TEMPLATE_DATA,
	CLEAR_APPEAL_STATUS_LIST_TEMPLATE_DATA,
	SET_APPEAL_STATUS_LIST_INITIALIZED,
	SAVE_APPEAL,
	SAVE_APPEAL_SUCCESS,
	SAVE_APPEAL_ERROR,
	APPEAL_DESTINATIONS_REQUEST,
	APPEAL_DESTINATIONS_NEED_REFRESH,
	APPEAL_DESTINATIONS_REQUEST_SUCCESS,
	APPEAL_DESTINATIONS_REQUEST_FAILURE,
	LOCK_APPEAL_FORM,
	UNLOCK_APPEAL_FORM,
	UNLOCK_APPEAL_FORM_SUCCESS,
	UNLOCK_APPEAL_FORM_ERROR,
	GET_APPEAL_PRIORITY_LIST_SUCCESS,
	GET_APPEAL_PRIORITY_LIST_ERROR,
	GET_APPEAL_FEEDBACK,
	GET_APPEAL_FEEDBACK_SUCCESS,
	APPEAL_REQUEST,
	APPEAL_REQUEST_SUCCESS,
	APPEAL_REQUEST_FAILURE,
	CLEAR_APPEAL_STATE,
	GET_APPEAL_FEEDBACK_ERROR,
	CLEAR_APPEAL_FEEDBACK,
	GET_APPEAL_ACTIONS,
	SET_APPEAL_ACTIONS,
	SET_FIRST_LEVEL_ACTION,
	SET_RESOLUTION_ACTION,
	REMOVE_REFRESH_APPEAL_MODAL,
	SET_MODAL_STATUS_LIST,
	SET_STATUS_MODAL_VISIBILITY,
	APPEAL_FETCHING,
	REMOVE_CUSTOMER_FROM_APPEAL,
	ADD_CUSTOMER_TO_APPEAL,
	CLEAR_ALL_APPEALS,
	SAVE_AND_CREATE_NEW,
	SET_REQ_DYN_PARAMS,
	SET_REQUIRED_CUSTOMER,
	UPDATE_VIEW_LINKS,
	SET_LINKED_APPEALS,
	SET_LINK_СHECKED_BINDERS,
	CHECK_LINK_BINDER,
	UNCHECK_LINK_BINDER,
	CLEAR_LINK_BINDERS,
	SET_LINKED_OBJECTS,
	SET_OBJECT_LINK_СHECKED_BINDERS,
	CHECK_OBJECT_LINK_BINDER,
	UNCHECK_OBJECT_LINK_BINDER,
	CLEAR_OBJECT_LINK_BINDERS,
	LINK_FILTERS_REQUEST_FAILURE,
	LINK_FILTERS_REQUEST_SUCCESS,
	SET_APPEAL_GRID_REFRESH,
	SET_APPEAL_IS_SAVE_AND_CREATE_NEW,
	REMOVE_APPEAL_LOCK_SOURCE,
	UNLOCK_APPEAL_AND_CLEAR_SOURCE,
	UNLOCK_APPEAL_AND_CLEAR_SOURCE_SUCCESS,
	UNLOCK_APPEAL_AND_CLEAR_SOURCE_ERROR,
	UNLOCK_APPEAL_FORM_ALREADY_UNLOCKED,
	SET_APPEAL_ID_CENTRAL_PART_LOADING,
	SET_FORM_FORCE_INIT_VALUES
} from './constants';
import { EMAILS_REQUEST_SUCCESS, COMMENTS_REQUEST_SUCCESS, COUNTER_MAILING_UP, COUNTER_COMMENTS_UP } from 'constants/actions';
import { keyGen } from 'helpers';

const appealModel = {
	loadingParams: false,
	loadedParams: false,
	formParams: null,
	formParamsError: null,
	
	requiredCustomer: false,
	isCentralPartLoading: false,
	
	refreshAppealModal: false,
	isRefreshAppealNeeded: false,
	
	statusList: null,
	loadingStatusList: false,
	loadedStatusList: false,
	initializedStatusList: false,
	
	savingAppeal: false,
	savedAppeal: false,
	saveAppealError: null,
	
	loadingDestinations: false,
	destinationHash: null,
	// destinations: [],
	destinations: null,
	isDestinationsNeedRefresh: false,
	
	loadingAppealFeedback: false,
	appealFeedback: null,
	appealFeedbackError: null,
	
	unlockAppeal: false,
	unlockedAppeal: false,
	isUnlocking: false,
	lockSourceList: [],
	formForceInitValues: null,
	lockError: "",
	
	closeAppealFlag: false,
	
	currentAppeal: null,
	
	appealAction: [],
	selectedAppealAction: [],
	
	modalStatusList: [],
	modalVisibility: false,
	
	firstLevelAppealAction: null,
	resolutionAppealAction: null,
	selectedAction: '--',
	
	appealIsBlocked: false,
	saveAndCreateNew: false,
	appealKey: null,
	fetching: false,
	
	checkReqDynParams: false,
	
	updateAppealLinks: false,
	mailingUpTo: false,
	commentsUpTo: false,

	isSaveAndCreateNew: false,
};

const initialState = {
	appealTypes: null,
	priorityList: null,
	creating: false,
	error: null,
	appeals: {},
	checkedBinders: [],
	checkedObjectBinders: [],
	linkedAppeals: [],
	linkedObjects: [],
	linkFilterList: [],
	gridRefresh: false,
	isSaveAndCreateNew: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		/********  Creating Process  ********/
	
	case CREATE_NEW_APPEAL:
		return { ...state, creating: true };

	case CREATE_NEW_APPEAL_SUCCESS: {
		return { ...state, creating: false };
	}
	
	case CREATE_NEW_APPEAL_ERROR: {
		return {
			...state,
			creating: false,
			error: action.error,
		};
	}
		
		/********  Appeal request  ********/
	
	case APPEAL_REQUEST:
		return setDataToAppeal(action.id, state, { ...appealModel });
	
	case APPEAL_REQUEST_SUCCESS:
		return setDataToAppeal(action.appealData.id, state, {
			currentAppeal: action.appealData,
		});
	
	case APPEAL_REQUEST_FAILURE:
		
		return setDataToAppeal(action.id, state, {
			currentAppeal: null,
			appealIsBlocked: action.appealIsBlocked ? action.appealIsBlocked : false
		});
	
	case APPEAL_FETCHING:
		return setDataToAppeal(action.id, state, { fetching: true }, { creating: false });
		
		/********  Appeal saving  ********/
	
	case SAVE_APPEAL:
		return setDataToAppeal(action.id, state, {
			savingAppeal: true,
			savedAppeal: false,
			saveAppealError: null,
			// appeal should be unlocked by other method (when lockSourceList is empty)
			// unlockAppeal: false,
			// unlockedAppeal: false,
		});
	
	case SAVE_APPEAL_SUCCESS:
		return setDataToAppeal(action.id, state, {
			savingAppeal: false,
			savedAppeal: true,
			knowledgeBase: null,
		});
	
	case SAVE_APPEAL_ERROR:
		return setDataToAppeal(action.id, state, {
			savingAppeal: false,
			savedAppeal: false,
			saveAppealError: action.error && action.error.message,
		});
		
		/********  Appeal lock/unlock form  ********/
	
	case LOCK_APPEAL_FORM: {
		return setDataToAppeal(action.id, state, {
			unlockAppeal: false,
			unlockedAppeal: false,
			lockError: action.error,
		});
	}
	
	case UNLOCK_APPEAL_FORM:
		return setDataToAppeal(action.id, state, {
			unlockAppeal: true,
			unlockedAppeal: false,
		});

	case UNLOCK_APPEAL_FORM_ALREADY_UNLOCKED: {
		const prevLockSourceList = state.appeals[action.id] && state.appeals[action.id].lockSourceList || [];
		return setDataToAppeal(action.id, state, {
			lockSourceList: [...new Set([...prevLockSourceList, action.lockSource])]
		});
	}
	
	case UNLOCK_APPEAL_FORM_SUCCESS: {
		const refreshAppealModal = Number(action.version) > Number(state.appeals[action.id].currentAppeal.version);
		const prevLockSourceList = state.appeals[action.id] && state.appeals[action.id].lockSourceList || [];
		// console.log({currentVersion: state.appeals[action.id].currentAppeal.version, newVersion: action.version});
		return setDataToAppeal(action.id, state, {
			refreshAppealModal,
			unlockAppeal: false,
			unlockedAppeal: !refreshAppealModal,
			lockSourceList: [...new Set([...prevLockSourceList, action.lockSource])],
			lockError: "",
			isRefreshAppealNeeded: refreshAppealModal,
		});
	}

	case REMOVE_APPEAL_LOCK_SOURCE: {
		const prevLockSourceList = state.appeals[action.id] && state.appeals[action.id].lockSourceList || [];
		return setDataToAppeal(action.id, state, {
			lockSourceList: prevLockSourceList.filter(item => item !== action.lockSource)
		});
	}
	case UNLOCK_APPEAL_AND_CLEAR_SOURCE: {
		return setDataToAppeal(action.id, state, {
			isUnlocking: true,
		});
	}
	case UNLOCK_APPEAL_AND_CLEAR_SOURCE_SUCCESS: {
		// update appeal version
		const currentAppeal = {
			...state.appeals[action.id].currentAppeal,
			version:action.version
		};
		return setDataToAppeal(action.id, state, {
			unlockAppeal: false,
			unlockedAppeal: false,
			lockSourceList: action.forceClearSource ? [] : state.appeals[action.id].lockSourceList, // either clear source or set existing source
			lockError: "",
			currentAppeal,
			isRefreshAppealNeeded: false,
			isUnlocking: false,
			// version: action.version
		});
	}
	case UNLOCK_APPEAL_AND_CLEAR_SOURCE_ERROR: {
		return setDataToAppeal(action.id, state, {
			unlockAppeal: false,
			unlockedAppeal: true,
			isUnlocking: false,
		});
	}
	case UNLOCK_APPEAL_FORM_ERROR:
		return setDataToAppeal(action.id, state, {
			unlockAppeal: false,
			unlockedAppeal: true,
		});
		
		/********  Appeal destinations request  ********/
	
	case APPEAL_DESTINATIONS_NEED_REFRESH: {
		return setDataToAppeal(action.id, state, {
			isDestinationsNeedRefresh: true,
		});
	}
	
	case APPEAL_DESTINATIONS_REQUEST:
		return setDataToAppeal(action.id, state, {
			loadingDestinations: true,
			isDestinationsNeedRefresh: false,
		});
	
	case APPEAL_DESTINATIONS_REQUEST_SUCCESS:
		return setDataToAppeal(action.id, state, {
			loadingDestinations: false,
			destinations: action.destinations,
			destinationHash: keyGen(),
		});
	
	case APPEAL_DESTINATIONS_REQUEST_FAILURE:
		return setDataToAppeal(action.id, state, {
			loadingDestinations: false,
			destinations: [],
		});
	
	case GET_APPEAL_TYPES_LIST_SUCCESS: {
		const { result } = action;
		return {
			...state,
			appealTypes: _.get(result.children, '[0]'),
		};
	}
	
	case GET_APPEAL_TYPES_LIST_ERROR: {
		return {
			...state,
			loadingTypes: false,
			loadedTypes: false,
			appealTypes: null,
			appealTypesError: action.error,
		};
	}
	
	case GET_APPEAL_PRIORITY_LIST_SUCCESS: {
		const { result } = action.result;
		return {
			...state,
			priorityList: result,
		};
	}
	
	case GET_APPEAL_PRIORITY_LIST_ERROR: {
		return {
			...state,
			priorityList: null,
			priorityListError: action.error,
		};
	}
	
	case GET_DINAMIC_FORM_PARAMS:
		return setDataToAppeal(action.id, state, {
			loadingParams: true,
			loadedParams: false,
		});
	
	case GET_DINAMIC_FORM_PARAMS_SUCCESS: {
		const { result } = action.result;
		
		return setDataToAppeal(action.id, state, {
			loadingParams: false,
			loadedParams: true,
			formParams: result,
		});
	}
	
	case GET_DINAMIC_FORM_PARAMS_ERROR:
		return setDataToAppeal(action.id, state, {
			loadingParams: false,
			loadedParams: false,
			formParams: null,
			formParamsError: action.error,
		});
	
	case GET_APPEAL_STATUS_LIST:
		return setDataToAppeal(action.id, state, {
			loadingStatusList: true,
			loadedStatusList: false,
		});
	
	case GET_APPEAL_STATUS_LIST_SUCCESS:
		return setDataToAppeal(action.id, state, {
			loadingStatusList: false,
			loadedStatusList: true,
			statusList: action.result,
			appealStatusFromTemplate: action.appealStatusFromTemplate,
			appealResolutionFromTemplate: action.appealResolutionFromTemplate,
			statusListInitializedType: action.statusListInitializedType,
		});

	case SET_APPEAL_STATUS_LIST_TEMPLATE_DATA: {
		const appealTemplateUpdateCount = state.appeals[action.id] && state.appeals[action.id].appealTemplateUpdateCount;
		return setDataToAppeal(action.id, state, {
			appealStatusFromTemplate: action.appealStatusFromTemplate,
			appealResolutionFromTemplate: action.appealResolutionFromTemplate,
			appealTemplateUpdateCount: appealTemplateUpdateCount ? appealTemplateUpdateCount + 1 : 1,
		});
	};
	case CLEAR_APPEAL_STATUS_LIST_TEMPLATE_DATA:
		return setDataToAppeal(action.id, state, {
			appealStatusFromTemplate: null,
			appealResolutionFromTemplate: null,
		});

	case SET_APPEAL_STATUS_LIST_INITIALIZED:
		return setDataToAppeal(action.id, state, {
			initializedStatusList: true,
		});
	
	case GET_APPEAL_STATUS_LIST_ERROR:
		return setDataToAppeal(action.id, state, {
			loadingStatusList: false,
			loadedStatusList: true,
			statusList: null,
			statusListError: action.error,
		});
	
	case GET_APPEAL_FEEDBACK:
		return setDataToAppeal(action.id, state, {
			loadingAppealFeedback: true,
			appealFeedback: null,
			appealFeedbackError: null,
		});
	
	case GET_APPEAL_FEEDBACK_SUCCESS:
		return setDataToAppeal(action.id, state, {
			loadingAppealFeedback: false,
			appealFeedback: action.result,
			appealFeedbackError: null,
		});
	
	case GET_APPEAL_FEEDBACK_ERROR:
		return setDataToAppeal(action.id, state, {
			loadingAppealFeedback: false,
			appealFeedback: null,
			appealFeedbackError: action.error,
		});
	
	case CLEAR_APPEAL_FEEDBACK:
		return setDataToAppeal(action.id, state, {
			appealFeedback: null,
			loadingAppealFeedback: false,
			appealFeedbackError: null,
		});
	
	
	/********** customer link  ***********/
	
	case REMOVE_CUSTOMER_FROM_APPEAL: {
		const currentAppeal = {
			...state.appeals[action.id].currentAppeal,
			customerId: 0,
			contactPersonId: 0,
		};
		
		return setDataToAppeal(action.id, state, {
			currentAppeal,
		});
	}
	
	case ADD_CUSTOMER_TO_APPEAL: {
		const currentAppeal = {
			...state.appeals[action.id].currentAppeal,
			customerId: action.customerId,
			contactPersonId: action.contactPersonId,
		};
		
		return setDataToAppeal(action.id, state, {
			currentAppeal,
		});
	}
	
	case GET_APPEAL_ACTIONS:
		return setDataToAppeal(action.id, state, {
			appealAction: action.payload || [],
		});
	
	case SET_APPEAL_ACTIONS:
		let selectedActionLabel = {};
		if (action.label) {
			selectedActionLabel = { selectedAction: action.label };
		}
		
		return setDataToAppeal(action.id, state, {
			selectedAppealAction: action.payload,
			...selectedActionLabel,
		});
	
	case SET_FIRST_LEVEL_ACTION:
		return setDataToAppeal(action.id, state, {
			firstLevelAppealAction: { key: keyGen(), ...action.payload },
			selectedAction: action.payload.label,
		});
	
	case SET_RESOLUTION_ACTION:
		return setDataToAppeal(action.id, state, {
			resolutionAppealAction: { key: keyGen(), ...action.payload },
			selectedAction: action.payload.name,
		});
	
	case REMOVE_REFRESH_APPEAL_MODAL:
		return setDataToAppeal(action.id, state, {
			refreshAppealModal: false,
		});
	
	case SET_MODAL_STATUS_LIST:
		return setDataToAppeal(action.id, state, {
			modalStatusList: action.payload,
		});
	
	case SET_STATUS_MODAL_VISIBILITY:
		return setDataToAppeal(action.id, state, {
			modalVisibility: action.payload,
		});
		
		/*******************************/
	
	case SAVE_AND_CREATE_NEW: {
		return setDataToAppeal(action.id, state, {
			saveAndCreateNew: action.payload,
		});
	}
	
	case SET_REQ_DYN_PARAMS: {
		return setDataToAppeal(action.id, state, {
			checkReqDynParams: action.payload,
		});
	}
	
	case SET_REQUIRED_CUSTOMER: {
		return setDataToAppeal(action.id, state, {
			requiredCustomer: action.value,
		});
	}

	case SET_APPEAL_ID_CENTRAL_PART_LOADING: {
		return setDataToAppeal(action.id, state, {
			isCentralPartLoading: action.isCentralPartLoading,
		});
	}
	
	// is used in AppealMainContent for proper submit via StatusSelect (to keep track of value regarding createNew appeal or not)
	case SET_APPEAL_IS_SAVE_AND_CREATE_NEW: {
		return setDataToAppeal(action.id, state, {
			isSaveAndCreateNew: action.isSaveAndCreateNew,
		});
	}
	
	case CLEAR_APPEAL_STATE: {
		const appeals = { ...state.appeals };
		delete appeals[action.id];
		
		return {
			...state,
			appeals: {
				...appeals,
			},
		};
	}
	
	case CLEAR_ALL_APPEALS: {
		return {
			...state,
			appeals: {},
		};
	}
	
	case UPDATE_VIEW_LINKS: {
		return {
			...state,
			updateAppealLinks: action.state,
		};
	}
	case EMAILS_REQUEST_SUCCESS:
		return {
			...state,
			mailingUpTo: action.total,
	};
	
	case COMMENTS_REQUEST_SUCCESS:
		return {
			...state,
			commentsUpTo: action.total,
	};
	
	case COUNTER_MAILING_UP:
		return {
			...state,
			mailingUpTo: false,
	};
	
	case COUNTER_COMMENTS_UP:
		return {
			...state,
			commentsUpTo: null,
	};

	case SET_LINKED_APPEALS: {
		return {
			...state,
			linkedAppeals: action.appeals,
		};
	}

	case SET_LINK_СHECKED_BINDERS: {
		return {
			...state,
			checkedBinders: action.checkedBinders,
		};
	}
	
	case CHECK_LINK_BINDER: {
		return {
			...state,
			checkedBinders: [...state.checkedBinders, action.binder],
		};
	}
	
	case UNCHECK_LINK_BINDER: {
		return {
			...state,
			checkedBinders: state.checkedBinders.filter( item => item.object_id !== action.binder.object_id),
		};
	}
	
	case CLEAR_LINK_BINDERS: {
		return {
			...state,
			checkedBinders: [],
		};
	}


	case SET_LINKED_OBJECTS: {
		return {
			...state,
			linkedObjects: action.objects,
		};
	}

	case SET_OBJECT_LINK_СHECKED_BINDERS: {
		return {
			...state,
			checkedObjectBinders: action.checkedBinders,
		};
	}
	
	case CHECK_OBJECT_LINK_BINDER: {
		return {
			...state,
			checkedObjectBinders: [...state.checkedObjectBinders, action.binder],
		};
	}
	
	case UNCHECK_OBJECT_LINK_BINDER: {
		return {
			...state,
			checkedObjectBinders: state.checkedObjectBinders.filter( item => item.object_id !== action.binder.object_id),
		};
	}
	
	case CLEAR_OBJECT_LINK_BINDERS: {
		return {
			...state,
			checkedObjectBinders: [],
		};
	}
	
	case  LINK_FILTERS_REQUEST_FAILURE: {
		return {
			...state,
			linkFilterList: [],
		};
	}
	
	case  LINK_FILTERS_REQUEST_SUCCESS: {
		return {
			...state,
			linkFilterList: action.linkFilterList,
		};
	}

	case SET_APPEAL_GRID_REFRESH: {
		return {
			...state,
			gridRefresh: action.gridRefresh,
		};
	}

	case SET_FORM_FORCE_INIT_VALUES: {
		return setDataToAppeal(action.id, state, {
			formForceInitValues: action.values,
		});
	}
	
	default:
		return state;
	}
}

function setDataToAppeal (id, state, appealData, data = {}) {
	return {
		...state,
		...data,
		appeals: {
			...state.appeals,
			[id]: { ...state.appeals[id], ...appealData },
		},
	};
}
