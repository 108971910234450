// import { get, set } from "lodash";
import {
	CUSTOMER_REQUEST,
	CUSTOMER_REQUEST_SUCCESS,
	CUSTOMER_REQUEST_FAILURE,
	CUSTOMER_MODIFY,
	CLEAR_CUSTOMER_STATE,
	
	UPDATE_CUSTOMER_NAME_SUCCESS,
	UPDATE_CUSTOMER_NAME_ERROR,
	
	APPLICANTS_REQUEST,
	APPLICANTS_REQUEST_SUCCESS,
	APPLICANTS_REQUEST_FAILURE,
	CLEAR_APPLICANTS_STATE,
	
	CUSTOMER_EDIT_BEGIN,
	CUSTOMER_EDIT_SUCCESS,
	CUSTOMER_EDIT_ERROR,
	
	CUSTOMER_ADDRESS_REQUEST,
	CUSTOMER_ADDRESS_REQUEST_FAILURE,
	CUSTOMER_ADDRESS_REQUEST_SUCCESS,
	CUSTOMER_ADDRESS_CLEAR,
	CUSTOMER_ADDRESS_MODAL_OPEN,
	CUSTOMER_ADDRESS_MODAL_CLOSE,
	
	SET_CONTACT_PERSONS_LIST,
	CLEAR_CONTACT_PERSONS_LIST,
	LOADING_CONTACT_PERSONS_LIST,
	CONTACT_PERSON_REQUEST,
	CONTACT_PERSON_REQUEST_SUCCESS,
	CONTACT_PERSON_REQUEST_FAILURE,
	PERSON_EDIT_BEGIN,
	PERSON_EDIT_SUCCESS,
	PERSON_EDIT_ERROR,
	PERSON_SAVE_CONTACT_BEGIN,
	PERSON_SAVE_CONTACT_SUCCESS,
	PERSON_SAVE_CONTACT_ERROR,
	PERSON_DELETE_CONTACT_BEGIN,
	PERSON_DELETE_CONTACT_SUCCESS,
	PERSON_DELETE_CONTACT_ERROR,
	CLEAR_CONTACT_PERSON_STATE,
	CUSTOMER_TAB_CONTENT_UPDATE,
	CLEAR_ENTIRE_CUSTOMER_STATE,
	CLEAR_ALL_CUSTOMERS,
	
	APPLICANT_FILTERS_REQUEST,
	APPLICANT_FILTERS_REQUEST_SUCCESS,
	APPLICANT_PRESET_FILTERS_REQUEST_SUCCESS,
	APPLICANT_FILTERS_REQUEST_FAILURE,
	APPLICANT_SET_ENTITY_TAB,
	
	REQUEST_CUSTOMER_BILLING_CARDS,
	SET_CUSTOMER_BILLING_CARDS,
	RESET_CUSTOMER_BILLING_CARDS,
	SET_CUSTOMER_GRID_REFRESH,

	CONTACT_PERSON_FIELD_EDIT,
	CUSTOMER_FIELD_EDIT,

	CUSTOMER_ADD_LIVE_SAVING,
	CUSTOMER_REMOVE_LIVE_SAVING
} from 'constants/actions';

const customerModel = {
	nameUpdatedSuccess: false,
	nameUpdatedError: false,
	
	editing: false,
	edited: false,
	
	editingPerson: false,
	editedPerson: false,
	
	/********  Contact person list of organization  ********/
	loadingPersons: false,
	loadedPersons: false,
	contactPersons: [],
	billingCards: null,
	
	/********  Customer  ********/
	currentCustomer: null,
	customerLoading: false,
	modificationCount: 0,
	
	/********  Contact person  ********/
	currentContactPerson: null,
	contactPersonFetching: false,
	contactPersonLoading: false,
	contactPersonModificationCount: 0,
	
	tabContentModificationCount: 0,
	
	primaryAddress: null,
	primaryAddressLoading: false,
	isAddressModalOpen: false,
	
	/********  SearchFilter modal********/
	isFilterListLoading: false,
	filterList: [],
	filterPresetList: [],
	
	fetching: false,
};

const initialState = {
	persons: {},
	
	applicants: [],
	loadingApplicants: false,
	
	/********  Bank list of organization  ********/
	loadingBankList: false,
	loadedBankList: false,
	bankList: [],
	
	/********  SearchFilter modal********/
	tabEntity: 'customer',
	gridRefresh: {},

	liveSavingInProcess: {}, // in format [id]: [key1, key2] etc
};

export default (state = initialState, action) => {
	switch (action.type) {
		
		/********  Customer actions  ********/
	
	case CUSTOMER_REQUEST: {
		let data = {
			...customerModel,
			customerLoading: true,
		};
		
		if (action.silent) {
			data = { customerLoading: true };
		}
		
		return setCustomerData(action.id, state, data);
	}
	
	case CUSTOMER_REQUEST_SUCCESS:
		return setCustomerData(action.id, state, {
			currentCustomer: action.customerData,
			customerLoading: false,
			fetching: true,
		});
	
	case CUSTOMER_REQUEST_FAILURE:
		return setCustomerData(action.id, state, {
			currentCustomer: null,
			customerLoading: false,
		});
	
	case CUSTOMER_MODIFY: {
		let prevCount = state.persons[action.id].modificationCount;
		if (!prevCount) {
			prevCount = 0;
		}
		return setCustomerData(action.id, state, {
			modificationCount: prevCount + 1,
		});
	}
	case CLEAR_CUSTOMER_STATE:
		return setCustomerData(action.id, state, {
			currentCustomer: null,
			customerLoading: false,
			modificationCount: 0,
		});
		
		/********  Contact person request  ********/
	
	case CONTACT_PERSON_REQUEST:
		return setCustomerData(action.id, state, {
			contactPersonLoading: true,
		});
	
	case CONTACT_PERSON_REQUEST_SUCCESS:
		
		return setCustomerData(action.id, state, {
			currentContactPerson: action.contactPersonData,
			contactPersonFetching: true,
			contactPersonLoading: false,
		});
	
	case CONTACT_PERSON_REQUEST_FAILURE:
		return setCustomerData(action.id, state, {
			currentContactPerson: null,
			contactPersonLoading: false,
		});
	
	case CLEAR_CONTACT_PERSON_STATE:
		return setCustomerData(action.id, state, {
			currentContactPerson: null,
			contactPersonLoading: false,
			contactPersonModificationCount: 0
		});
		
		/********  Customer editing  ********/
	
	case CUSTOMER_EDIT_BEGIN:
		return setCustomerData(action.id, state, {
			editing: true,
			edited: false,
		});
	
	case CUSTOMER_EDIT_SUCCESS: {
		let prevCount = state.persons[action.id].modificationCount;
		if (!prevCount) {
			prevCount = 0;
		}
		return setCustomerData(action.id, state, {
			editing: false,
			edited: true,
			modificationCount: prevCount + 1,
		});
	}
	case CUSTOMER_EDIT_ERROR:
		return setCustomerData(action.id, state, {
			editing: false,
			edited: false,
		});
		
		/********  Customer person editing  ********/
	
	case PERSON_EDIT_BEGIN:
		return setCustomerData(action.id, state, {
			editingPerson: true,
			editedPerson: false,
		});
	
	case PERSON_EDIT_SUCCESS:
		return setCustomerData(action.id, state, {
			editingPerson: false,
			editedPerson: true,
			contactPersonModificationCount: (state.persons[action.id].contactPersonModificationCount || 0) + 1,
		});
	
	case PERSON_EDIT_ERROR:
		return setCustomerData(action.id, state, {
			editingPerson: false,
			editedPerson: false,
		});
		
		/********  Customer name update  ********/
	
	case UPDATE_CUSTOMER_NAME_SUCCESS:
		return setCustomerData(action.id, state, {
			nameUpdatedSuccess: true,
		});
	
	case UPDATE_CUSTOMER_NAME_ERROR:
		return setCustomerData(action.id, state, {
			nameUpdatedError: true,
		});
		
		/********  Applicants request  ********/
	
	case APPLICANTS_REQUEST:
		return { ...state, loadingApplicants: true };
	
	case APPLICANTS_REQUEST_SUCCESS:
		return { ...state, applicants: action.applicants, loadingApplicants: false };
	
	case APPLICANTS_REQUEST_FAILURE:
		return { ...state, applicants: [], loadingApplicants: false };
	
	case CLEAR_APPLICANTS_STATE:
		return { ...state, applicants: [], loadingApplicants: false };
		
		/********  Customer primary address request ********/
	
	case CUSTOMER_ADDRESS_REQUEST:
		return setCustomerData(action.id, state, {
			primaryAddressLoading: true,
		});
	
	case CUSTOMER_ADDRESS_REQUEST_SUCCESS:
		return setCustomerData(action.id, state, {
			primaryAddress: action.primaryAddress,
			primaryAddressLoading: false
		});
	
	case CUSTOMER_ADDRESS_REQUEST_FAILURE:
	case CUSTOMER_ADDRESS_CLEAR:
		return setCustomerData(action.id, state, {
			primaryAddress: null,
			primaryAddressLoading: false
		});
	
	case CUSTOMER_ADDRESS_MODAL_OPEN:
		return { ...state, isAddressModalOpen: true };
	
	case CUSTOMER_ADDRESS_MODAL_CLOSE:
		return { ...state, isAddressModalOpen: false };
		
		/********  Contact persons request  ********/
	
	case LOADING_CONTACT_PERSONS_LIST:
		return setCustomerData(action.id, state, {
			loadingPersons: true,
			loadedPersons: false,
		});
	
	case SET_CONTACT_PERSONS_LIST:
		return setCustomerData(action.id, state, {
			loadingPersons: false,
			loadedPersons: true,
			contactPersons: action.payload,
		});
	
	case CLEAR_CONTACT_PERSONS_LIST:
		return setCustomerData(action.id, state, {
			loadingPersons: false,
			loadedPersons: false,
			contactPersons: [],
		});
		
		/********  Saving of contact request  ********/
	
	case PERSON_SAVE_CONTACT_BEGIN:
		return setCustomerData(action.id, state, {
			editingPerson: true,
			editedPerson: false,
			contactPersonLoading: true,
		});
	
	case PERSON_SAVE_CONTACT_SUCCESS:
		return setCustomerData(action.id, state, {
			editingPerson: false,
			editedPerson: true,
			contactPersonModificationCount: (state.persons[action.id].contactPersonModificationCount || 0) + 1,
		});
	
	case PERSON_SAVE_CONTACT_ERROR:
		return setCustomerData(action.id, state, {
			editingPerson: false,
			editedPerson: false,
		});
		
		/********  Deletion of contact request  ********/
	
	case PERSON_DELETE_CONTACT_BEGIN:
		return setCustomerData(action.id, state, {
			editingPerson: true,
			editedPerson: false,
			contactPersonLoading: true,
		});
	
	case PERSON_DELETE_CONTACT_SUCCESS:
		return setCustomerData(action.id, state, {
			editingPerson: false,
			editedPerson: true,
			contactPersonModificationCount: (state.persons[action.id].contactPersonModificationCount || 0) + 1
		});
	
	case PERSON_DELETE_CONTACT_ERROR:
		return setCustomerData(action.id, state, {
			editingPerson: false,
			editedPerson: false,
		});

	case CUSTOMER_ADD_LIVE_SAVING: {
		// const liveSavingInProcess = state.persons[action.id] && state.persons[action.id].liveSavingInProcess || []
		const prevValue = state.liveSavingInProcess[action.id] || [];

		return {
			...state,
			liveSavingInProcess: {
				...state.liveSavingInProcess,
				[action.id]: [...new Set([...prevValue, action.liveSaveKey])]
			}
		};
	}

	case CUSTOMER_REMOVE_LIVE_SAVING: {
		const prevValue = state.liveSavingInProcess[action.id] || [];
		const filteredValue = prevValue.filter(key => key !== action.liveSaveKey);
		if (filteredValue.length === 0) {
			delete state.liveSavingInProcess[action.id];
			return {
				...state,
				liveSavingInProcess: {
					...state.liveSavingInProcess,
				}
			};
		}
		return {
			...state,
			liveSavingInProcess: {
				...state.liveSavingInProcess,
				[action.id]: prevValue.filter(key => key !== action.liveSaveKey)
			}
		};
	}
	
	case CUSTOMER_TAB_CONTENT_UPDATE:
		return setCustomerData(action.id, state, {
			tabContentModificationCount: (state.persons[action.id].tabContentModificationCount || 0) + 1,
		});
	
	case CLEAR_ENTIRE_CUSTOMER_STATE: {
		const persons = { ...state.persons };
		delete persons[action.id];
		
		return {
			...state,
			persons: {
				...persons,
			},
		};
	}
	
	case CLEAR_ALL_CUSTOMERS:
		return {
			...state,
			persons: {},
		};
		/********  Dashboard filter object update  ********/
		
	case APPLICANT_FILTERS_REQUEST:
		return {
			...state,
			isFilterListLoading: true,
		};
	
	case APPLICANT_FILTERS_REQUEST_SUCCESS:
		return {
			...state,
			isFilterListLoading: false,
			filterList: action.filterList,
		};
	
	case APPLICANT_PRESET_FILTERS_REQUEST_SUCCESS:
		
		return {
			...state,
			isFilterListLoading: false,
			filterPresetList: action.filterPresetList,
		};
	
	case APPLICANT_FILTERS_REQUEST_FAILURE:
		return {
			...state,
			isFilterListLoading: false,
			filterList: [],
		};
		
	case APPLICANT_SET_ENTITY_TAB:
		return {
			...state,
			isFilterListLoading: false,
			tabEntity: action.tabEntity,
		};
		
	case SET_CUSTOMER_BILLING_CARDS:
		return {
			...state,
			billingCards: action.billingCards,
		};
		
	case RESET_CUSTOMER_BILLING_CARDS:
		return {
			...state,
			billingCards: null,
		};

	case SET_CUSTOMER_GRID_REFRESH:
		return {
			...state,
			gridRefresh: {
				...state.gridRefresh,
				[action.gridName]: action.isRefresh
			}
		};

	// case CONTACT_PERSON_FIELD_EDIT: {
	// 	console.log({action, state});
	// 	return state;
	// }
	
	// case CUSTOMER_FIELD_EDIT: {
	// 	// console.log({action, state});
	// 	const { payload: { id, field, value } } = action;
	// 	const customer = state.persons[id];
	// 	if (customer) {
	// 		const clonedCustomer = JSON.parse(JSON.stringify(customer));
	// 		set(clonedCustomer, `currentCustomer.${field}`, value);
	// 		console.log({clonedCustomer});
	// 		// const currentValue = get(currentCustomer, field);
	// 		// const currentValue2 = currentCustomer['party.firstName'];
	// 		// const currentValue3 = currentCustomer.party.firstName;
	// 		// console.log({currentCustomer, currentValue, currentValue2, currentValue3, field});
	// 	}
	// 	return state;
	// }

	default:
		return state;
	}
};

function setCustomerData (id, state, data) {
	
	return {
		...state,
		persons: {
			...state.persons,
			[id]: { ...state.persons[id], ...data }
		},
	};
}

