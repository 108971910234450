import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Sidebar from './Sidebar/index';
import CalendarHeader from './CalendarHeader';
import permissions, { checkPermissions } from '../../config/permissions';
import './style.scss';
import get from 'lodash/get';
import { getSearchResults } from '../../modules/calendar/actions';
import { getFormValues } from 'redux-form';
import { configureFilterObject } from '../../components/SearchFilters/helpers';
import styles from 'styles/modules/knowledgeBase.module.scss';
import CalendarDetail from './CalendarDetail';
import Loader from '../../components/Loader/index';
import cx from 'classnames';
import EventsList from './EventsList';
import moment from 'moment';

const mapStateToProps = (state) => ({
    data: get(state, 'calendar.data'),
    notifications: state.ui.notifications,
    formValues: getFormValues('calendar-filter-form')(state),
    allowModes: get(state, 'calendar.openedEvent.allowModes', []),
    filterAttrsResults: get(state, 'calendar.filterAttrsResults'),
    loading: get(state, 'calendar.loading'),
});

const mapDispatchToProps = (dispatch) => ({
    change: (value) => dispatch(change('calendar-filter-form', 'CLEVENT_RANGE_START_DATE', value)),
    getSearchResults: (requestData) => dispatch(getSearchResults(requestData)),
});

export const Context = React.createContext();

function CalendarView(props) {
    const { formValues, getSearchResults, filterAttrsResults, loading, allowModes, change } = props;
    const canEdit = checkPermissions(permissions.AppealOperations.action_editGlobalProblems);
    const [needUpdateGrid, setNeedUpdateGrid] = useState(false);
    const [isGridView, setIsGridView] = useState(
        localStorage['isGridView'] ? JSON.parse(localStorage['isGridView']) : false,
    );

    const [isDeleting, setIsDeleting] = useState(false);


    const filterObject = formValues ? configureFilterObject(formValues, filterAttrsResults) : {};

    async function requestData() {
        if (isGridView) {
            setNeedUpdateGrid(true);
        } else {
            const params = {
                start: 0,
                limit: 500,
                query: '',
                type: 'clevent',
                sort: [{ property: '', direction: '' }],
                code: 'FTOP_CLEVENT',
                filterObject,
            };
            if (Array.isArray(filterObject) && filterObject.length) {
                getSearchResults(params);
            }
        }
    }

    useEffect(() => {
        const startDate = get(formValues, 'CLEVENT_RANGE_START_DATE.startDate');
        const endDate = get(formValues, 'CLEVENT_RANGE_START_DATE.endDate');
        const { prevFilterStartDate, prevFilterEndDate } = localStorage;
        if (!startDate && !endDate && prevFilterStartDate && prevFilterEndDate) {
            change({
                startDate: moment(prevFilterStartDate),
                endDate: moment(prevFilterEndDate),
            });
        }
        if (startDate && endDate) {
            localStorage.setItem('prevFilterStartDate', startDate.toString());
            localStorage.setItem('prevFilterEndDate', endDate.toString());
            formValues && requestData();
        }
    }, [formValues]);

    // trigger request data on returning from gridView to calendar view and when formValues are available
    useEffect(() => {
        if (!isGridView && formValues) {
            requestData();
        }
    }, [isGridView]);

    return (
        <>
            <CalendarHeader
                allowModes={allowModes}
                update={requestData}
                setIsGridView={setIsGridView}
                isGridView={isGridView}
                setIsDeleting={setIsDeleting}
            />
            <div className="main-calendar-content">
                {isDeleting && <Loader />}
                <Sidebar loading={loading} disabled={!canEdit} isGridView={isGridView} />
                <div
                    className={cx(styles.mainBoxCalendar, 'issue-main', 'calendar-main', {
                        'grid-view': isGridView,
                    })}
                >
                    {loading && <Loader />}
                    {!loading && isGridView && (
                        <EventsList
                            filterObject={filterObject}
                            needUpdateGrid={needUpdateGrid}
                            setNeedUpdateGrid={setNeedUpdateGrid}
                        />
                    )}
                    {!loading && !isGridView && <CalendarDetail loading={loading} />}
                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);
