import {
	FILTER_ATTRS_GET_SUPPOSE,
	FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	FILTER_ATTRS_GET_SUPPOSE_FAIL,
	ADD_FILTER_ATTRS,
	SAVE_FILTER_SUCCESS,
	SAVE_FILTER_FAIL,
	SAVE_FILTER,
	DELETE_FILTER,
	DELETE_FILTER_SUCCESS,
	DELETE_FILTER_FAIL,
	FILTER_PARAMS_GET_SUPPOSE,
	FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	FILTER_PARAMS_GET_SUPPOSE_FAIL,
	CLEAR_FILTER_ATTRS,
	CLEAR_FILTER_INFO,
	FILTER_LIST_GET_SUPPOSE,
	FILTER_LIST_GET_SUPPOSE_SUCCESS,
	FILTER_LIST_GET_SUPPOSE_FAIL,
	FILTER_LIST_SET_ACTIVE_FILTER_PAGE,
	FILTER_LIST_CLEAR_RESULT,
	FILTER_DATA_GET_SUPPOSE,
	FILTER_DATA_GET_SUPPOSE_SUCCESS,
	FILTER_DATA_GET_SUPPOSE_FAIL,
	CLEAR_FILTER_DATA,
	OPENED_FILTER,
	OPEN_FILTER_PARAMS,
	EDIT_FILTER,
	EDIT_FILTER_NAME_SUCCESS,
	CLOSE_FILTER_PARAMS,
	OPEN_NEW_FILTER_PARAMS,
	CLOSE_NEW_FILTER_PARAMS,
	CREATE_NEW_FILTER,
	APPLY_EXISTING_FILTER,
	ADD_FILTER_OPTION,
	CLEAR_NEW_FILTER_OPTION,
	SET_OLD_FILTER_DATA,
	GET_APPEAL_TYPES_LIST_FOR_FILTER,
	GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS,
	GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR,
	CLEAR_APPEAL_TYPES_LIST_FOR_FILTER,
	SET_SELECT_OPTIONS_FOR_FILTER
} from 'constants/actions';
import baseService from '../services/BaseService';
import downloadCSV from 'actions/downloadCSV';
import downloadXLSX from 'actions/downloadXLSX';
import i18next from 'util/i18n';

const startGetFilterSuppose = (query) => ({
	type: FILTER_LIST_GET_SUPPOSE,
	query
});

export const getFilterSupposeSuccess = (result) => ({
	type: FILTER_LIST_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterSupposeFail = (error) => ({
	type: FILTER_LIST_GET_SUPPOSE_FAIL,
	error
});

export const getFilterSuppose = (params) => (dispatch, getState) => {
	dispatch(startGetFilterSuppose());
	if (!getState().filter.filterList.length) {
		try {
			dispatch(getFilterSupposeSuccess(params))
		}
		catch (err) {
			dispatch(getFilterSupposeFail(params))
		}
	}
};

export const setActiveFilterPage = (filterId, page) => ({
	type: FILTER_LIST_SET_ACTIVE_FILTER_PAGE,
	filterId,
	page
});

const getAttrsSuppose = (query) => ({
	type: FILTER_ATTRS_GET_SUPPOSE,
	query
});

const getAttrsSupposeSuccess = (result) => ({
	type: FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	result
});

const getAttrsSupposeFail = (error) => ({
	type: FILTER_ATTRS_GET_SUPPOSE_FAIL,
	error
});

export const getFilterAttrsSuppose = (params) => (dispatch, getState) => {
	dispatch(getAttrsSuppose());
	
	baseService.get('attributes', params)
		.then(data => {
			dispatch(getAttrsSupposeSuccess(data));
			// TODO move appealTypes request
			!getState().filter.appealTypes && dispatch(getAppealTypesForFilter());
		})
		.catch(err => dispatch(getAttrsSupposeFail(err)));
};

export const addFilterAttrs = (result) => ({
	type: ADD_FILTER_ATTRS,
	result
});

export const clearFilterAttrs = () => ({
	type: CLEAR_FILTER_ATTRS
});

const saveFilterSuccess = (lastSaved, filterId) => ({
	type: SAVE_FILTER_SUCCESS,
	lastSaved,
	filterId
});

const saveFilterFail = (error) => ({
	type: SAVE_FILTER_FAIL,
	error
});

const fireSaveFilter = () => ({
	type: SAVE_FILTER
});

export const saveFilter = (params, onSuccess) => (dispatch) => {
	dispatch(fireSaveFilter());
	
	baseService.post('save_filter', params)
		.then(response => {
			if (onSuccess && response.result) onSuccess(response.result);
			return response.result && dispatch(saveFilterSuccess(params.data.name, response.result));
		})
		.catch(err => dispatch(saveFilterFail(err)));
};

const deleteFilterSuccess = (id) => ({
	type: DELETE_FILTER_SUCCESS,
	id
});

const deleteFilterFail = (error) => ({
	type: DELETE_FILTER_FAIL,
	error
});

const fireDeleteFilter = () => ({
	type: DELETE_FILTER
});

export const deleteFilter = (params, onSuccess) => (dispatch) => {
	dispatch(fireDeleteFilter());
	
	baseService.post('delete_filter', params)
		.then(data => {
			dispatch(deleteFilterSuccess(data.result));
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess(data.result);
			}
		})
		.catch(err => dispatch(deleteFilterFail(err)));
};

const getFilterParamsSuppose = (query) => ({
	type: FILTER_PARAMS_GET_SUPPOSE,
	query
});

const getFilterParamsSupposeSuccess = (result) => ({
	type: FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterParamsSupposeFail = (error) => ({
	type: FILTER_PARAMS_GET_SUPPOSE_FAIL,
	error
});

export const clearFilterInfo = () => ({
	type: CLEAR_FILTER_INFO
});

export const getInfoFilterSuppose = (params) => (dispatch) => {
	dispatch(getFilterParamsSuppose());
	
	baseService.get('info_filter', params)
		.then(data => dispatch(getFilterParamsSupposeSuccess(data)))
		.catch(err => dispatch(getFilterParamsSupposeFail(err)));
};

const getFilterDataSuppose = () => ({
	type: FILTER_DATA_GET_SUPPOSE
});

const getFilterDataSupposeSuccess = (result) => ({
	type: FILTER_DATA_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterDataSupposeFail = (error) => ({
	type: FILTER_DATA_GET_SUPPOSE_FAIL,
	error
});

export const getDataFilterSuppose = (params) => (dispatch) => {
	dispatch(getFilterDataSuppose());
	
	baseService.post(params.key, params)
		.then(data => {
			dispatch(getFilterDataSupposeSuccess(data));
		})
		.catch(err => dispatch(getFilterDataSupposeFail(err)));
};

export const exportAppealGridToExcel = requestData => {
	downloadCSV({ requestData, key: 'preset_apply_csv' });
};

export const exportAppealsGridToCSV = requestData => {
	downloadCSV({ requestData, key: 'preset_apply_export_to_csv' });
};

export const exportUniversalCsv = params => (dispatch, getState) =>{
	const gridState = getState().grid[getState().grid.gridKey];
	const {filterObject, sort} = gridState.api;
	const {fields} = gridState.params;
	
	const eventFields = Object.entries(fields)
		.reduce((acc, [key, value]) => {
			
			if (!value.hidden && !value.notForExport) {
				const nameParts = [key];
				if (value.objectField) nameParts.push(value.objectField);
				
				acc[nameParts.join('.')] = i18next.t(value.name);
			}
			
			return acc;
		}, {});
	
	const requestData = {
		query: params.query,
		// filterId: props.id,
		fields: eventFields,
		type: params.type,
		filterObject,
		//sort
		sort
	};
	
	downloadCSV({ requestData, key: 'search_csv' });
};

export const exportAppealsGridToXLS = (requestData, callback) => {
	downloadXLSX({ requestData, key: 'preset_apply_export_to_xlsx' }, callback);
};

export const openedFilterAppeal = (openedFilter) => ({
	type: OPENED_FILTER,
	openedFilter
});

export const openFilterParams = () => ({
	type: OPEN_FILTER_PARAMS
});

export const editFilter = () => ({
	type: EDIT_FILTER
});

export const editFilterNameSuccess = ({name, id: filterId}) => {
	return {
	type: EDIT_FILTER_NAME_SUCCESS,
	name,
	filterId
}};

export const closeFilterParams = () => ({
	type: CLOSE_FILTER_PARAMS
});

export const openNewFilterParams = () => ({
	type: OPEN_NEW_FILTER_PARAMS
});

export const closeNewFilterParams = () => ({
	type: CLOSE_NEW_FILTER_PARAMS
});

export const createNewFilter = () => ({
	type: CREATE_NEW_FILTER
});

const getAppealTypesListForFilterSuccess = result => {
	return {
		type: GET_APPEAL_TYPES_LIST_FOR_FILTER_SUCCESS,
		result
	};
};

const getAppealTypesListForFilterError = error => {
	return {
		type: GET_APPEAL_TYPES_LIST_FOR_FILTER_ERROR,
		error
	};
};

const fireGetAppealTypesListForFilter = () => ({
	type: GET_APPEAL_TYPES_LIST_FOR_FILTER
});

export const getAppealTypesForFilter = () => dispatch => {
	const params = {
		data: {
			classCode: 0,
			node: 'root'
		}
	};
	
	dispatch(fireGetAppealTypesListForFilter());
	
	baseService.get('appeal_type', params)
		.then(data => dispatch(getAppealTypesListForFilterSuccess(data)))
		.catch(err => dispatch(getAppealTypesListForFilterError(err)));
};
