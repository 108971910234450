import { validateEmail } from 'util/form';
import i18next from 'util/i18n';

export default function validate (values) {
	const errors = {};
	const { to = [], copy = [] } = values;
	
	if (!to || !to.length) {
		errors.to = i18next.t('validation.required');
	} else {
		to.forEach((email) => {
			if (!validateEmail(email)) {
				errors.to = i18next.t('validation.email');
			}
		});
	}
	
	if (!copy || !copy.length) {
	} else {
		copy.forEach((email) => {
			if (!validateEmail(email)) {
				errors.copy = i18next.t('validation.email');
			}
		});
	}
	
	return errors;
}
