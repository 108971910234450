import React from 'react';
import PropTypes from 'prop-types';
import { KeyCodes } from 'constants/index';

function SearchOption (props) {
	
	function onChange (event) {
		props.onChange(event.target.value);
	}
	
	function handleKeyDown (event) {
		if (event.keyCode === KeyCodes.ENTER) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	}
	
	return (
		<div className={'search-container'}>
			<input
				type={'text'}
				className={'search-text'}
				autoFocus
				value={props.query}
				onChange={onChange}
				// placeholder={'Почніть вводити назву'}
				onKeyDown={handleKeyDown}
			/>
			{/*<i className={'icon-search2'} />*/}
		</div>
	);
}

SearchOption.defaultProps = {
	query: ''
};

SearchOption.propTypes = {
	query: PropTypes.string,
	onChange: PropTypes.func.isRequired
};

export default SearchOption;
