import React from "react";
import { connect } from "react-redux";
import ModalPortal from "components/ModalPortal";
import EmailSearchForm from "./EmailSearchForm";

import { closeSearchEmailModal } from "actions/ui";
import "./style.scss";

const mapStateToProps = state => ({
    showSearchEmailModal: state.ui.showSearchEmailModal,
    searchEmailModalContext: state.ui.searchEmailModalContext,
});

const mapDispatchToProps = {
    closeSearchEmailModal,
};

function SearchEmailModal(props) {
    const { closeSearchEmailModal, showSearchEmailModal, onSave, searchEmailModalContext } = props;

    if (!showSearchEmailModal) {
        return null;
    }

    return (
        <ModalPortal onClose={closeSearchEmailModal} className="modal-medium modal-center search-email-modal">
            <EmailSearchForm
                searchEmailModalContext={searchEmailModalContext}
                onSave={onSave}
                closeModal={closeSearchEmailModal}
            />
        </ModalPortal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchEmailModal);
