const ACTIONS = {
    SERVICES_FILTERS_REQUEST_SUCCESS: 'SERVICES_FILTERS_REQUEST_SUCCESS',
    SERVICES_SET_ACTIVE_FILTER: 'SERVICES_SET_ACTIVE_FILTER'
};

/* initial state */
const initialState = {
    filters: null,
    activeFilter: null
};

/* reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SERVICES_FILTERS_REQUEST_SUCCESS:
            return { ...state, filters: action.filters };
        case ACTIONS.SERVICES_SET_ACTIVE_FILTER:
            return { ...state, activeFilter: action.filter };
        case ACTIONS.SERVICES_SET_ACTIVE_FILTER_PAGE: {
            return { 
                ...state, 
                filters: { 
                    ...state.filters, 
                    [action.filterId]: {
                        ...state.filters[action.filterId],
                        page: action.page
                    }
                }
            };
        }
        default:
            return state;
    }
};

/* action creators */
export const setFilters = filters => ({
    type: ACTIONS.SERVICES_FILTERS_REQUEST_SUCCESS,
    filters
});

export const setActiveFilter = filter => ({
    type: ACTIONS.SERVICES_SET_ACTIVE_FILTER,
    filter
});


