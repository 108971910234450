import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader/index';
import CheckBoxGrid from 'components/Common/CheckBoxGrid';
import { configureFilterObject } from 'components/SearchFilters/helpers';

import GridWrapper from '../../../custom-hooks/GridWrapper';
import { setApi } from '../../../reducers/grid';
import { setCurrentTab } from '../../../actions/index';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

function mapStateToProps(state) {
    return {
        filterValues: getFormValues('modal-link-filter-search')(state),
        linkFilterList: state.appeal.linkFilterList,
        // checkedBinders: state.appeal.checkedObjectBinders,
        filterFormErrors: getFormSyncErrors('modal-link-filter-search')(state),
    };
}

function LinkObjectsListGrid(props) {
    const [loadingState, setLoadingState] = useState(true);
    const {
        updateApiGrid,
        gridSearch,
        onCheck,
        filterValues,
        linkFilterList,
        filterFormErrors,
        customerId,
        code,
        type,
        filterId,
        customGridKey,
        customConfigCode,
    } = props;

    // console.log({ customGridKey, code, customConfigCode });

    const init = {
        key: customGridKey || 'get_objects_for_link',
        method: 'post',
        code: code || 'INTERACTION_REQUEST_SPEC_LINKS',
        type: type || 'spec',
        customerId,
        limit: 10,
        configUrlKey: customGridKey && 'config_grid',
        configKey: customGridKey || 'get_objects_for_link',
        configCode: code || 'INTERACTION_REQUEST_SPEC_LINKS',
    };

    if (![null, undefined].includes(filterId)) {
        init.filterId = filterId;
    }

    if (customConfigCode) {
        // init.configKey = init.key;
        init.configCode = customConfigCode;
        init.extra = customConfigCode;
    }

    if (!customerId) {
        delete init.customerId;
    }

    const gridKey = `${init.key}_${init.path || init.extra || ''}`;

    const getParams = () => ({
        classname: 'checkbox-field',
        fields: {
            checkbox: {
                component: (params) => (
                    <CheckBoxGrid
                        type="objects"
                        params={params}
                        onChange={handleCheck}
                        loadingState={loadingState}
                    />
                ),
            },
        },
    });

    const handleCheck = (checked, params) => {
        const {
            data: { detailObjectId, detailObjectType },
        } = params;
        onCheck({
            object_id: detailObjectId,
            object_type: detailObjectType,
            regnum: detailObjectId.toString(),
            checked,
        });
    };

    useEffect(() => {
        if (isEmpty(filterFormErrors)) {
            const filterObject = filterValues
                ? configureFilterObject(filterValues, linkFilterList)
                : [];

            updateApiGrid({ limit: 10, filterObject }, gridKey);
            if (!gridSearch) {
                updateApiGrid({ limit: 10, query: gridSearch, filterObject }, gridKey);
            }
        }
    }, [filterValues, gridSearch]);

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    return (
        <div className="link-grid content-wrapper with-fixed-status">
            {loadingState && <Loader />}
            <GridWrapper
                tuner={() => init}
                initUpdate={false}
                gridParams={customConfigCode ? {} : getParams()}
                gridSearch={gridSearch}
                onStart={onStart}
                onFinish={onFinish}
                handleRowCheck={handleCheck}
                checkRowType="objects"
                destroyOnUnmount
            />
        </div>
    );
}

export default connect(mapStateToProps, { updateApiGrid: setApi, setCurrentTab })(
    withRouter(LinkObjectsListGrid),
);
