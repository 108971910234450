import {
	ADD_COMMENT,
	ADD_COMMENT_SUCCESS,
	ADD_COMMENT_ERROR,
	UPLOAD_FILE,
	UPLOAD_FILE_SUCCESS,
	UPLOAD_FILE_ERROR,
	UPDATE_COMMENT_SUCCESS,
	DELETE_FILE_SUCCESS,
	DELETE_FILE_ERROR,
	DELETE_FILE,
	SET_EDITABLE_COMMENT,
	UPLOAD_FILE_TO_COMMENT,
	UPLOAD_FILE_TO_COMMENT_SUCCESS,
	UPLOAD_FILE_TO_COMMENT_ERROR,
	DELETE_FILE_FROM_COMMENTS,
	DELETE_FILE_FROM_COMMENTS_SUCCESS,
	DELETE_FILE_FROM_COMMENTS_ERROR,
	COMMENTS_REQUEST,
	COMMENTS_REQUEST_SUCCESS,
	COMMENTS_REQUEST_FAILURE,
	APPEAL_COMMENTS_RESET
} from 'constants/actions';

const initialState = {
	requestedForAppeal: '',
	
	comments: [],
	commentsLoading: false,
	
	adding: false,
	added: false,
	addingError: null,
	uploading: false,
	uploaded: false,
	uploadingError: null,
	attachment: [],
	attachmentForComment: [],
	editableCommentId: null,
	update: false,
	uploadCounter: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		
		/********  Appeal comments request  ********/
	
	case COMMENTS_REQUEST:
		return { ...state, commentsLoading: true, requestedForAppeal: action.appealId };
	
	case COMMENTS_REQUEST_SUCCESS:
		return { ...state, comments: action.comments, commentsLoading: false };
	
	case COMMENTS_REQUEST_FAILURE:
		return { ...state, comments: [], commentsLoading: false };
		
		/********  Appeal comments reset  ********/
	
	case APPEAL_COMMENTS_RESET:
		return {...initialState, attachment: [...state.attachment] };
		
		/**********************************/
	
	case ADD_COMMENT: {
		return {
			...state,
			adding: true,
			added: false
		};
	}
	
	case ADD_COMMENT_SUCCESS: {
		return {
			...state,
			adding: false,
			added: true,
			attachment: state.attachment.filter( ({appealId}) => appealId !== action.appealId)
		};
	}
	
	case ADD_COMMENT_ERROR: {
		return {
			...state,
			adding: false,
			added: false,
			error: action.error
		};
	}
	
	case UPLOAD_FILE: {
		return {
			...state,
			uploading: true,
			uploaded: false,
			uploadCounter: state.uploadCounter + 1
		};
	}
	
	case UPLOAD_FILE_SUCCESS: {
		const { appealId, id, name } = action;
		return {
			...state,
			uploading: false,
			uploaded: true,
			attachment: [...state.attachment, { appealId, id, name }],
			uploadCounter: state.uploadCounter - 1
		};
	}
	
	case UPLOAD_FILE_ERROR: {
		return {
			...state,
			uploading: false,
			uploaded: true,
			uploadingError: action.error,
			uploadCounter: state.uploadCounter - 1
		};
	}
	
	case DELETE_FILE: {
		return {
			...state,
			deleting: true,
			deleted: false
		};
	}
	
	case DELETE_FILE_SUCCESS: {
		const ids = [...state.attachment].filter(attachment => attachment.id !== action.id);
		return {
			...state,
			uploading: false,
			uploaded: true,
			attachment: ids
		};
	}
	
	case DELETE_FILE_ERROR: {
		return {
			...state,
			uploading: false,
			uploaded: true,
			error: action.error
		};
	}
	
	case UPLOAD_FILE_TO_COMMENT: {
		return {
			...state,
			uploading: true,
			uploaded: false,
			uploadCounter: state.uploadCounter + 1
		};
	}
	
	case UPLOAD_FILE_TO_COMMENT_SUCCESS: {
		return {
			...state,
			uploading: false,
			uploaded: true,
			attachment: [...state.attachment, { id: action.id, name: action.name }],
			uploadCounter: state.uploadCounter - 1
		};
	}
	
	case UPLOAD_FILE_TO_COMMENT_ERROR: {
		return {
			...state,
			uploading: false,
			uploaded: true,
			error: action.error,
			uploadCounter: state.uploadCounter - 1
		};
	}
	
	case DELETE_FILE_FROM_COMMENTS: {
		return {
			...state,
			uploading: true,
			uploaded: false
		};
	}
	
	case DELETE_FILE_FROM_COMMENTS_SUCCESS: {
		return {
			...state,
			uploading: false,
			uploaded: true,
			attachment: state.attachment.filter(attachment => attachment.id !== action.id)
		};
	}
	
	case DELETE_FILE_FROM_COMMENTS_ERROR: {
		return {
			...state,
			uploading: false,
			uploaded: true,
			error: action.error
		};
	}
	
	case SET_EDITABLE_COMMENT: {
		return {
			...state,
			editableCommentId: action.editableCommentId,
			attachment: action.editableCommentId === null ? state.attachment : action.attachment,
			update: false
		};
	}
	
	case UPDATE_COMMENT_SUCCESS: {
		return {
			...state,
			update: true,
			attachment: (action.appealId !== 'all') ? state.attachment.filter( ({appealId}) => appealId !== action.appealId) : []
		};
	}
	
	default:
		return state;
	}
};

