import React, { Fragment, Component } from 'react';
import cx from 'classnames';
import modalStyles from 'styles/modules/modal.module.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxFormWrapper, extractCustomerFromState, createCheckPropsFunction } from 'helpers';
import { customerAddressFormConfig, StreetModes } from 'constants/index';
import { Field, formValueSelector } from 'redux-form';
import { withTranslation } from 'react-i18next';
import validate from './validate';
import baseService from 'services/BaseService';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { modifyCustomer, getCustomerAddress } from 'actions/customer';
import StreetField from './StreetField';
import { addressSave } from 'actions/ui';
import { KeyCodes } from 'constants/index';
import { custAddressBlocks, getMergedStaticBlocks, getModalFormDataFromState } from "../../../../components/CreateNewCustomerModal/config"

// old config (customerAddressFormConfig)
// const ADDRESS_CONFIG = [
//     {
//         name: 'settlement',
//         label: 'Населений пункт',
//         component: ComboBox,
//         required: true,
//         searchable: true,
//     },
//     {
//         name: 'street',
//         label: 'Вулиця',
//         component: ComboBox,
//         required: true,
//         searchable: true,
// 		inputProps: { maxLength: 255 },
// 		maxLength: 255
//     },
//     {
//         name: 'buildingNumber',
//         label: 'Будинок',
//         component: ComboBox,
//         required: true,
//         searchable: true,
//         inputProps: { maxLength: 16 },
// 		maxLength: 16
//     },
//     {
//         name: 'buildingSecondNumber',
//         label: 'Корпус',
//         component: InputField,
//         errorPlaceholder: true,
//         maxLength: 32,
//     },
//     {
//         name: 'apartmentNumber',
//         label: 'Квартира/Офіс',
//         component: InputField,
//         errorPlaceholder: true,
//         maxLength: 32,
//     },
//     {
//         name: 'zipCode',
//         label: 'Індекс',
//         component: ComboBox,
//         required: true,
//         searchable: true,
//         inputProps: { maxLength: 5 },
// 		maxLength: 5
//     },
//     {
//         name: 'description',
//         label: 'Коментар',
//         component: TextArea,
//         autosize: true,
//         maxLength: 500,
//         textAreaClassName: 'description',
//     },
// ];

const getInitialValues = currentAddress => ({
	settlement: {
		label: currentAddress.settlement.name,
		value: currentAddress.settlement.id,
		fullName: currentAddress.settlement.fullName
	},
	street: {
		label: get(currentAddress.street, 'name', ''),
		value: get(currentAddress.street, 'id', '')
	},
	buildingNumber: {
		value: currentAddress.buildingNumber,
		label: currentAddress.buildingNumber
	},
	buildingSecondNumber: get(currentAddress, 'buildingSecondNumber', ''),
	apartmentNumber: get(currentAddress, 'apartmentNumber', ''),
	zipCode: {
		label: currentAddress.zipCode,
		value: currentAddress.zipCode
	},
	description: get(currentAddress, 'description', '')
});

const formSelector = formValueSelector('add-customer-address');

const mapStateToProps = (state, props) => {
	
	const customer = extractCustomerFromState(state, props.id);
	const clientType = get(customer, 'currentCustomer.party.partyType');
	const modalFormData = getModalFormDataFromState(state, `left_panel_customer_${clientType.toLowerCase()}_form`);
	// console.log({clientType, formBlocks, modalFormData});
	const { custAddress: custAddressBlock } = modalFormData;
	
	// const formBlocks = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM || {} ;
	// const { custAddress: custAddressBlock } = formBlocks;


	const mergedStaticBlocks = getMergedStaticBlocks(props.t, custAddressBlock, custAddressBlocks, {}, clientType);

	const result = {
		...formSelector(state, 'type', 'settlement', 'street', 'buildingNumber', 'zipCode'),
		mode: formSelector(state, 'mode') || StreetModes.AUTO,
		customerId: customer.currentCustomer.id,
		custAddressBlock,
		mergedStaticBlocks
	};
	
	const { primaryAddress } = customer;

	if (primaryAddress) {
		result.initialAddress = primaryAddress;
		result.initialValues = getInitialValues(primaryAddress);
	}
	
	return result;
};

const mapDispatchToProps = {
	modifyCustomer,
	addressSave,
	getCustomerAddress,
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ form: 'add-customer-address', validate })
class CustomerAddressForm extends Component {
	
	constructor (props) {
		super(props);
		
		this.state = {
			isEditing: Boolean(props.initialValues),
			
			settlementOptions: [],
			streetOptions: [],
			buildingNumberOptions: [],
			zipCodeOptions: [],
			addressTypes: null,
			
		};
		
		this.linkedFields = ['settlement', 'street', 'buildingNumber', 'zipCode'];
		this.addressTypes = this.convertDictionaryObject(props.t('dictionary:addressType', { returnObjects: true }));
		this.clearAllValuesWithoutSettlement = this.clearAllValuesWithoutSettlement.bind(this);
		
		this.wait = false;
	}
	
	componentDidMount () {
		this.props.change('mode', StreetModes.AUTO);
		
		if (this.props.initialValues) {
			this.requestInitialData();
			this.props.change('type', this.addressTypes.find(type => type.value === this.props.initialValues.type));
		}
		document.addEventListener('keydown', this.handleKeyPress);
	}
	
	componentWillUnmount () {
		document.removeEventListener('keydown', this.handleKeyPress);
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		const { mode } = this.props;
		
		if (isPropChanged('mode') && mode === StreetModes.MANUAL) {
			this.clearAllValuesWithoutSettlement();
		}
	}
	
	handleKeyPress = event => {
		if (event.keyCode === KeyCodes.ESCAPE) this.onClose();
		//navigate Tab key
		if (event.keyCode === KeyCodes.TAB) {
			event.preventDefault();
			const modal = document.querySelector(".modal-window");
			const inputs = modal.querySelectorAll("form .input-field");
			const focused = document.activeElement;
			
			if(focused.nodeName !== 'BODY') {
				inputs.forEach((input, index) => {
					if (focused === input) return inputs[index + 1].focus();
					if (focused === inputs[inputs.length - 1]) return inputs[0].focus();
				});
			}
			else return inputs[0].focus();
		}
	};
	
	clearAllValuesWithoutSettlement () {
		this.props.change('buildingSecondNumber', null);
		this.props.change('apartmentNumber', null);
		this.resetLinkedFields('street');
		this.resetLinkedFields('buildingNumber');
		this.resetLinkedFields('zipCode');
	}
	
	onStreetFieldRefReady = streetFieldRef => {
		this.streetFieldRef = streetFieldRef;
	};
	
	resetLinkedFields = field => {
		const fieldsForReset = this.linkedFields.slice(this.linkedFields.indexOf(field) + 1);
		fieldsForReset.forEach(field => this.props.change(field, null));
		
		const nextState = {};
		fieldsForReset.reduce((acc, field) => {
			acc[`${field}Options`] = [];
			return acc;
		}, nextState);
		this.setState({ ...nextState });
	};
	
	requestInitialData = () => {
		const { settlement, street, buildingNumber } = this.props.initialValues;
		return Promise.all([
			this.requestSettlements(settlement.label), // 0
			this.requestStreets(street.label, settlement.value), // 1
			this.requestBuildings(street.value) // 2
		])
			.then(responses => {
				const settlementOptions = responses[0].success ? this.convertSettlementResponse(responses[0].result) : [];
				this.props.change('settlement', settlementOptions.find(settlement => settlement.id === this.props.initialValues.settlement.value));
				const nextState = {
					settlementOptions,
					streetOptions: responses[1].success ? this.convertStreetResponse(responses[1].result) : [],
					buildingNumberOptions: responses[2].success ? this.convertBuildingResponse(responses[2].result) : []
				};
				this.setState({ ...nextState });
				
				const build = responses[2].success ? responses[2].result.find(building => building.name === buildingNumber.value) : null;
				
				if (build) {
					return this.requestZipCodes(street.value, build.id)
						.then(response => this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] }));
				}
			})
			.catch(error => console.log('Error in initial request: ', error));
	};
	
	onInputChange = field => inputValue => {
		
		const query = !inputValue && this.props[field] ? this.props[field].label : inputValue;
		
		switch (field) {
		case 'settlement':
			this.onSettlementChange(query);
			break;
		case 'street':
			this.onStreetChange(query);
			break;
		case 'buildingNumber':
			this.props.change(`${field}Input`, inputValue);
			break;
		
		case 'zipCode':
			this.props.change(`${field}Input`, inputValue);
			this.onZipCodeChange(query);
			break;
		}
		
		return inputValue;
	};
	
	onSettlementChange = debounce(query => {
		const { mode } = this.props;
		
		if (!query || query.length < 3) return;
		
		return this.requestSettlements(query)
			.then(response => {
				if (response.success) {
					if (mode === StreetModes.AUTO) {
						this.clearAllValuesWithoutSettlement();
					}
					this.setState({ settlementOptions: this.convertSettlementResponse(response.result) });
				} else {
					this.setState({ settlementOptions: [] });
				}
			});
	}, 500);
	
	onStreetChange = debounce(query => {
		if (!this.props.settlement || !query || query.length < 2 || get(this.props, 'street.label') === query) return;
		return this.requestStreets(query, this.props.settlement.id)
			.then(response => {
				if (response.success) {
					this.props.change('buildingSecondNumber', null);
					this.props.change('apartmentNumber', null);
					this.resetLinkedFields('buildingNumber');
					this.resetLinkedFields('zipCode');
					this.setState({ streetOptions: this.convertStreetResponse(response.result) });
					
				} else {
					this.setState({ streetOptions: [] });
				}
			});
	}, 500);
	
	onZipCodeChange = debounce(query => {
		
		const isQueryShort = !query || query.length < 5;
		const areSettlementAndStreetEmpty = !this.props.settlement && !this.props.street;
		
		if (isQueryShort || !areSettlementAndStreetEmpty || this.props.mode !== StreetModes.AUTO) {
			return;
		}
		
		return this.requestZipCodesSearch(query)
			.then(response => {
				if (response.success && response.result && response.result.length > 0) {
					this.setAddressFoundByIndex(response.result);
				}
			});
	}, 300);
	
	setAddressFoundByIndex = results => {
		const streetOptions = this.convertStreetResponse(results[0].street);
		const settlement = this.convertSettlementResponse(results.slice(0, 1))[0];
		
		const focusStreetInput = () => this.streetFieldRef.current.input.input.focus();
		
		this.props.change('street', streetOptions[0]);
		this.props.change('settlement', settlement);
		this.setState({ settlementOptions: [], streetOptions }, focusStreetInput);
	};
	
	convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
		value: prop,
		label: value
	}));
	
	convertSettlementResponse = result => result.map(settlement => {
		let cutString = settlement.fullName.indexOf('/', 1);
		let tempPartLabel = cutString >= 0 ? settlement.fullName.substr(cutString) : '';
		let modifiedLabel = settlement.name + tempPartLabel;
		
		return {
			label: modifiedLabel,
			value: settlement.fullName,
			id: settlement.id,
			fullName: settlement.fullName,
			title: modifiedLabel
		};
	});
	
	convertStreetResponse = result => result.map(street => ({ label: street.name, value: street.id }));
	
	convertBuildingResponse = result => result.map(building => ({ label: building.name, value: building.id }));
	
	convertZipCodeResponse = result => result.map(zipCode => ({ label: zipCode.zip_code, value: zipCode.id }));
	
	requestSettlements = query => {
		const data = { query, page: 1, start: 0, limit: 10 };
		return baseService.get('search_settlement', { data });
	};
	
	requestStreets = (query, settlementId) => {
		const data = { query, settlementId, page: 1, start: 0, limit: 10 };
		return baseService.get('search_street', { data });
	};
	
	requestBuildings = streetId => {
		const data = { streetId };
		return baseService.get('search_building', { data });
	};
	
	requestZipCodes = (streetId, buildId) => {
		const data = { streetId, buildId };
		return baseService.get('search_zip_code', { data });
	};
	
	requestZipCodesSearch = query => {
		const data = { query };
		return baseService.get('search_settlement_by_zip_code', { data });
	};
	
	getSettlementOptionComponent = () => props => {
		return (
			<div className={cx(props.className, 'settlement-option')} onClick={() => props.selectValue(props.option)}
				 title={props.option.label}>
				<div className={'settlementFullName'}>{props.option.label}</div>
			</div>
		);
	};
	
	handleChange = (event, newValue, previousValue, fieldName) => {
		this.resetLinkedFields(fieldName);
		
		if (fieldName === 'street' && newValue) {
			this.requestBuildings(newValue.value)
				.then(response => {
					this.setState({ buildingNumberOptions: response.success ? this.convertBuildingResponse(response.result) : [] });
				});
		}
		
		if (fieldName === 'buildingNumber' && newValue) {
			this.requestZipCodes(this.props.street.value, newValue.value)
				.then(response => {
					this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] });
				});
		}
	};
	
	onStreetModeChange = newStreetMode => this.props.change('mode', newStreetMode);
	
	addStreetRequest = values => {
		const params = {
			data: {
				parentId: values.settlement.id,
				parentType: 'location',
				name: values.streetName,
				type: 'street',
				streetTypeId: values.streetType.value,
				guid: '',
				sourceId: '',
				description: ''
			},
			jsonType: true
		};
		
		return baseService.post('add_street', params);
	};
	
	getFieldPropsWithOptions = fieldProps => {
		switch (fieldProps.name) {
		case 'settlement':
			return {
				...fieldProps,
				options: this.state.settlementOptions,
				onInputChange: this.onInputChange('settlement'),
				optionComponent: this.getSettlementOptionComponent(),
				onChange: this.handleChange
			};
		case 'street':
			return {
				mode: this.props.mode,
				onModeChange: this.onStreetModeChange,
				fieldConfig: fieldProps,
				options: this.state.streetOptions,
				onInputChange: this.onInputChange('street'),
				onChange: this.handleChange,
				disabled: !this.props.settlement,
				id: 'streetInput',
				openOnFocus: true,
				onRefReady: this.onStreetFieldRefReady
			};
		case 'buildingNumber':
			return {
				...fieldProps,
				options: this.state.buildingNumberOptions,
				onChange: this.handleChange,
				onInputChange: this.onInputChange('buildingNumber'),
				onBlurResetsInput: false,
				onCloseResetsInput: false
			};
		case 'zipCode':
			return {
				...fieldProps,
				options: this.state.zipCodeOptions,
				onInputChange: this.onInputChange('zipCode'),
				onBlurResetsInput: false,
				onCloseResetsInput: false
			};
		case 'description':
			return {
				...fieldProps,
				style: { overflow: 'auto', height: '85px' },
				
			};
		
		default:
			return fieldProps;
		}
	};
	
	handleSaveError = error => {
		console.error(error);
		this.wait = false;
	};
	
	saveAddress = data => {
		return baseService.post('customer_address', { data })
			.then(response => {
				if (response.success) {
					this.props.addressSave();
					this.props.modifyCustomer(this.props.id);
					this.props.getCustomerAddress({ customerId: this.props.customerId }, this.props.id);
					this.wait = false;
				} else {
					throw new Error('Address save request error');
				}
			})
			.catch(this.handleSaveError);
	};
	
	onSubmit = values => {
		if (this.wait) return;
		this.wait = true;
		
		const data = {
			customerId: this.props.customerId,
			streetId: get(values, 'street.value') || '',
			type: 'physical',
			settlementId: values.settlement && values.settlement.id,
			street: get(values, 'street.value') || '',
			buildingNumber: values.buildingNumberInput || get(values, 'buildingNumber.label') || '',
			apartmentNumber: get(values, 'apartmentNumber') || '',
			description: get(values, 'description') || '',
			kind: 'urban',
			zipCode: values.zipCodeInput || get(values, 'zipCode.label') || '',
			buildingSecondNumber: get(values, 'buildingSecondNumber') || '',
			intercomNumber: '',
			floor: '',
			latitude: '',
			longitude: ''
		};
		
		if (this.props.initialAddress) {
			data.id = this.props.initialAddress.id;
		}
		
		if (values.mode === StreetModes.MANUAL) {
			this.addStreetRequest(values)
				.then(response => {
					if (response.success && response.result) {
						const street = response.result[0];
						
						data.street = street.id;
						data.streetId = street.id;
						
						this.props.onClose();
						return this.saveAddress(data);
					}
				})
				.catch(this.handleSaveError);
		} else {
			this.props.onClose();
			return this.saveAddress(data);
		}
	};
	
	renderInputField = fieldProps => {
		if (fieldProps.name === 'type') return null;
		
		switch (fieldProps.name) {
		case 'street':
			return <StreetField key={fieldProps.name}{...this.getFieldPropsWithOptions(fieldProps)} />;
		
		default:
			return <Field key={fieldProps.name}{...this.getFieldPropsWithOptions(fieldProps)} />;
		}
	};

	preRenderInputField = fieldProps => {
		// console.log({fieldProps});
		if (fieldProps.hidden) {
			return (
				<div className='hidden'>
					{this.renderInputField(fieldProps)}
				</div>
			);
		}
		return this.renderInputField(fieldProps)
	}
	
	render () {
		const { handleSubmit, t, mergedStaticBlocks } = this.props;
		const { isEditing } = this.state;

		// console.log({mergedStaticBlocks});
		return (
			<Fragment>
				
				<div className={modalStyles.modalHeader}>
					<div className={modalStyles.modalTitle}>{isEditing ? t('editAddress') : t('newAddress')}</div>
				</div>
				
				<form className={modalStyles.modalContent} onSubmit={handleSubmit(this.onSubmit)}>
					{/* {customerAddressFormConfig.map(this.renderInputField)} */}
					{mergedStaticBlocks.map(this.preRenderInputField)}
					<button className={'btn btn-primary'}>
						<i className='icon icon-check' />
						{isEditing ? t('save') : t('create')}
					</button>
				</form>
			
			</Fragment>
		);
	}
}

export default CustomerAddressForm;
