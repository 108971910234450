import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from 'styles/modules/knowledgeBase.module.scss';
import cx from 'classnames';
import './style.scss';
import CalendarView from "./CalendarView";
import CalendarEvent from "./CalendarEvent";
import { Switch, Route } from 'react-router-dom';
import { destroy } from 'redux-form';

const mapDispatchToProps = (dispatch) => ({
	destroyForm: (formName) => dispatch(destroy(formName)),
});

const Calendar = (props) => {
	useEffect( ()=>{
		return () => {
			localStorage.removeItem('calendarTypeView');
			localStorage.removeItem('prevFilterStartDate');
			localStorage.removeItem('prevFilterEndDate');
			localStorage.removeItem('isGridView');
			props.destroyForm('calendar-filter-form');
		}
	},[]);
	
	return (
		<div className={cx(styles.page,'calendar-page')}>
					<Switch>
						<Route path='/calendar/event/:eid?/:mode?' exact component={CalendarEvent} />
						<Route path='/calendar/' exact component={CalendarView} />
					</Switch>
				</div>
	)
};

export default connect(null, mapDispatchToProps)(Calendar);
