import React from 'react';
import PropTypes from 'prop-types';

export default function DownloadTrackButton ({ link }) {
	return (
		<a href={link} download>
			<i className='downloadTrackButton icon-save' />
		</a>
	);
}

DownloadTrackButton.propTypes = {
	link: PropTypes.string
};
