import React, { useState, useEffect } from 'react';

import { Field, Form, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ComboBox } from 'ui-core-dashboard';
import Loader from 'components/Loader';
import baseService from 'services/BaseService';
import Popup from 'components/Popup/index';
import LinkObjects from '../../../../../pages/Appeal/LinkObjects';
import ObjectsList from './ObjectsList';
import { openObjectsLinkModal } from 'actions/ui';

const TOPIC = 'SERVICE_CATALOG';
const OBJECT_TYPE = 'SPECIFICATION';

const Objects = (props) => {
    const { t, initialize, id, showObjectsLinkModal, openObjectsLinkModal } = props;

    const [popupIsOpen, setPopupIsOpen] = useState();
    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState('');
    const [gridRefreshKey, setGridRefreshKey] = useState(null);
    const [selectedModalObject, setSelectedModalObject] = useState(null);

    function handleObjectAdd(object) {
        setSelectedModalObject(object);
        openObjectsLinkModal();
    }

    function togglePopup() {
        if (filters.length === 1) {
            handleObjectAdd(filters[0].value);
        } else {
            setPopupIsOpen(!popupIsOpen);
        }
    }

    const getFilters = () => {
        baseService.get('linksRelations', { data: { code: TOPIC } }).then(({ success, result }) => {
            if (success) {
                const filters = result.map((item) => ({ value: item, label: item.label }));
                setFilters(filters);
                if (result.length > 0 && !activeFilter) {
                    const defaultFilter = filters.find((filter) => filter.value.is_default);
                    setActiveFilter(defaultFilter ? defaultFilter.value : result[0]);
                    initialize({
                        filters: {
                            value: defaultFilter ? defaultFilter.value : result[0],
                            label: defaultFilter ? defaultFilter.label : result[0].label,
                        },
                    });
                }
            } else {
                console.log('Request objects filters failed');
            }
        });
    };

    const onLinkEditSuccess = () => {
        setGridRefreshKey(new Date().getTime());
    };

    useEffect(() => {
        if (!showObjectsLinkModal) {
            setSelectedModalObject(null);
        }
    }, [showObjectsLinkModal]);

    useEffect(() => {
        getFilters();
    }, []);

    return (
        <div className="ordering-tab-wrapper">
            {!activeFilter && <Loader />}
            <div className="ordering-tab-filter-custom">
                <Form onSubmit={() => {}}>
                    <Field
                        key="filters"
                        name="filters"
                        clearable={false}
                        options={filters}
                        onChange={(e, value) => setActiveFilter(value)}
                        placeholder={t('dontSelect')}
                        noResultsText={t('noResultsFound')}
                        component={ComboBox}
                    />
                </Form>
                <button className="btn btn-primary" onClick={togglePopup} type="button">
                    {t('addObject')}
                </button>
                {popupIsOpen && (
                    <Popup onClickOutside={togglePopup} place="tab-filter-popup">
                        {filters.map(({ label, value }) => (
                            <button
                                className="popup-link"
                                type="button"
                                onClick={() => handleObjectAdd(value)}
                                key={label}
                            >
                                {label}
                            </button>
                        ))}
                    </Popup>
                )}
            </div>
            {activeFilter && (
                <ObjectsList
                    customGridKey="get_service_catalogue_objects_for_link"
                    customConfigCode={activeFilter.grid}
                    objectType={OBJECT_TYPE}
                    id={id}
                    relObjectTypes={activeFilter.val}
                    key={`${activeFilter.val}_${gridRefreshKey}`}
                />
            )}
            {showObjectsLinkModal && selectedModalObject && (
                <LinkObjects
                    id={id}
                    customerId={null}
                    objectType={OBJECT_TYPE}
                    relObjectTypes={selectedModalObject.val}
                    code={selectedModalObject.code}
                    type={selectedModalObject.type}
                    filterId={selectedModalObject.filterId}
                    customConfigCode={selectedModalObject.grid}
                    customGridKey="get_service_catalogue_objects_for_link"
                    onLinkEditSuccess={onLinkEditSuccess}
                />
            )}
        </div>
    );
};

function mapStateToProps(state, props) {
    return {
        showObjectsLinkModal: state.ui.showObjectsLinkModal,
    };
}

export default connect(mapStateToProps, { openObjectsLinkModal })(
    reduxForm({ form: 'subappeals-filter' })(Objects),
);
