import React, { useState, useEffect, useRef } from 'react';
import ReactSVG from 'react-svg';
import ReactMarkdown from "react-markdown";
import cx from 'classnames';
import Popup from 'components/Popup';
import Portal from '../../common/Portal';
import moment from 'moment';

import './styles.scss';

export default function IssuesWidgetList(props) {
    const { issues, t, history } = props;

    const [isMounted, setIsMounted] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [portalPosition, setPortalPosition] = useState({ top: 0, left: 0 });

    const popupTriggerRef = useRef(null);

    const togglePopup = (e) => {
        // check that popup is toggled only when its button is clicked
        if (popupTriggerRef && popupTriggerRef.current && popupTriggerRef.current.contains(e.target)) {
            setIsPopupOpen((prev) => !prev);
        }
    };

    const closePopup = (e) => {
        // check that not popup trigger buttons is clicked on clickOutsideHandler
        // to prevent from closing and reopening popup
        if (popupTriggerRef && popupTriggerRef.current && !popupTriggerRef.current.contains(e.target)) {
            setIsPopupOpen(false);
        }
    };

    const linkToIssue = (id) => {
        history.push(`/issue/${id}`);
    };

    // useEffect(() => {
    //     if (isMounted) {
    //         setTimeout(() => {
    //             const { top, right, width } = popupTriggerRef.current.getBoundingClientRect();
    //             // console.log({top, right});
    //             setPortalPosition({ top: top - 29, left: right + 18 });
    //         }, 200);
    //     }
    // }, [isMounted]);

    useEffect(() => {
        const { top, right, width } = popupTriggerRef.current.getBoundingClientRect();
        // console.log({top, right});
        setPortalPosition({ top: top - 29, left: right + width + 7 });
        setTimeout(() => {
            setIsMounted(true);
        }, 0);
    }, []);

    function renderWidget(item, key) {
        return (
            <div className="customer-panel-issues-popup-issue">
                {/* <ReactMarkdown source={issue.problem_name} /> */}
                <div className="issue-info with-markdown issue-title">
                    {item.problem_type && <strong>{item.problem_type.value}</strong>}{' '}
                    {/* <span>{item.problem_name}</span> */}
                    <div className='issue-info-markdown-wrapper'>
                        <ReactMarkdown source={item.problem_name} />
                    </div>
                </div>
                {(item.plan_start_date || item.plan_end_date) && (
                    <div className="issue-info">
                        <strong>
                            {item.plan_start_date && item.plan_end_date
                                ? t('issueDates')
                                : t('issueDate')}
                            :{' '}
                        </strong>
                        {item.plan_start_date && (
                            <span>
                                {!item.plan_end_date && t('dateFrom')}{' '}
                                {moment
                                    .unix(item.plan_start_date / 1000)
                                    .format('DD.MM.YYYY HH:mm')}
                            </span>
                        )}
                        {item.plan_start_date && item.plan_end_date && ' - '}
                        {item.plan_end_date && (
                            <span>
                                {!item.plan_start_date && t('dateTo')}{' '}
                                {moment.unix(item.plan_end_date / 1000).format('DD.MM.YYYY HH:mm')}
                            </span>
                        )}
                    </div>
                )}
                <div className="issue-info with-markdown">
                    {/* <span>{item.problem_description}</span> */}
                    <div className='issue-info-markdown-wrapper'>
                        <ReactMarkdown source={item.problem_description} />
                    </div>
                </div>
                <div className="issue-link" onClick={() => linkToIssue(item.problem_id)}>
                    <span>{t('goToIssue')}</span>
                </div>
            </div>
        );
    }

    function render() {
        return (
            <div
                className={cx('customer-panel__issue', { mounted: isMounted, active: isPopupOpen })}
                onClick={togglePopup}
                ref={popupTriggerRef}
            >
                <ReactSVG className="issue-icon" src={`/data/svg/customer/lightbulb.svg`} />
                {isPopupOpen && (
                    <Portal className="customer-panel__issue-portal" position={portalPosition}>
                        <Popup place="customer-panel-issues-popup" onClickOutside={closePopup}>
                            {issues.length
                                ? issues.map((item, key) => renderWidget(item, key))
                                : null}
                        </Popup>
                    </Portal>
                )}
            </div>
        );
    }
    return render();
}
