import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import { NotificationTypes, DEFAULT_NOTIFICATION_TIMEOUT } from 'constants/index';
import NewAppealNote from './NewAppealNote';
import AppealChangeSuccessNote from './AppealChangeSuccessNote';
import AppealChangeFailureNote from './AppealChangeFailureNote';
import CurrentAppealChangeNote from './CurrentAppealChangeNote';
import NewCommentNote from './NewCommentNote';
import HttpErrorNote from './HttpErrorNote';
import SuccessNote from './SuccessNote';
import WarningNote from './WarningNote';
import NotificationNote from './NotificationNote';
import ErrorNote from './ErrorNote';

class Notification extends React.Component {
	static COMPONENTS_MAP = {
		[NotificationTypes.NEW_APPEAL]: NewAppealNote,
		[NotificationTypes.APPEAL_CHANGE_SUCCESS]: AppealChangeSuccessNote,
		[NotificationTypes.APPEAL_CHANGE_FAILURE]: AppealChangeFailureNote,
		[NotificationTypes.CURRENT_APPEAL_CHANGE]: CurrentAppealChangeNote,
		[NotificationTypes.NEW_COMMENT]: NewCommentNote,
		[NotificationTypes.HTTP_ERROR]: HttpErrorNote,
		[NotificationTypes.SUCCESS]: SuccessNote,
		[NotificationTypes.SSE_NOTIFICATION]: NotificationNote,
		[NotificationTypes.SSE_ERROR]: ErrorNote,
		[NotificationTypes.SSE_WARNING]: WarningNote,
	};
	
	constructor (props) {
		super(props);
		this.COMPONENTS_MAP = this.constructor.COMPONENTS_MAP;
		this.getNotificationComponent = this.constructor.getNotificationComponent;
		this.isRequireConfirmation = this.isRequireConfirmation.bind(this);
	}
	
	isRequireConfirmation () {
		const { type } = this.props;
		return type === NotificationTypes.CURRENT_APPEAL_CHANGE;
	}
	
	render () {
		const { onClose, options, type } = this.props;
		const NoteComponent = this.COMPONENTS_MAP[type];
		return (
			<div className={styles.notification}>
				{!this.isRequireConfirmation() && <i className='icon-close' onClick={onClose} />}
				<NoteComponent {...options} onClose={onClose} />
			</div>
		);
	}
}

Notification.defaultProps = {
	timeout: DEFAULT_NOTIFICATION_TIMEOUT,
};

Notification.propTypes = {
	type: PropTypes.string.isRequired,
	options: PropTypes.object.isRequired,
	timeout: PropTypes.number,
	onClose: PropTypes.func.isRequired,
};

export default Notification;
