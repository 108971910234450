import React, {useEffect, useState} from 'react';
import { updateAppealViewLinks } from "modules/appeal/actions";

import permissions, { checkPermissions } from 'config/permissions';
import { withTranslation } from 'react-i18next';
import cx from "classnames";
import {connect} from "react-redux";
import baseService from "../../../services/BaseService";
import PriorityArrowsIcons from '../../../components/SVG/PriorityArrowsIcons';
import ReactSVG from 'react-svg';
import moment from "moment/moment";
import { Popup } from 'ui-core-dashboard';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { openAppealLinkModal } from 'actions/ui';
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader";

const OBJECT_TYPE = "INTERACTION_REQUEST";

function mapStateToProps (state) {
	return {
		updateAppealLinks: state.appeal.updateAppealLinks,
		canViewLink: checkPermissions(permissions.LinkOperations.actionViewLinks),
		canDeleteLink: checkPermissions(permissions.LinkOperations.actionDeleteLinks),
		canEditLinkAppeal: checkPermissions(permissions.LinkOperations.actionEditLinks),
	}
}

const mapDispatchToProps  = {
	updateAppealViewLinks,
	openAppealLinkModal
};

function LinkAppealBlock(props) {
	const [collapsed, setCollapsed] = useState(false);
	const [total, setTotal] = useState(null);
	const [links, setLinks] = useState(false);
	const [load, setLoad] = useState(false);
	const [popup, setPopup] = useState(false);
	const [confirmModal, setConfirmModal] = useState({ isModal:false, linkId: null });
	const { isModal, linkId } = confirmModal;
	const { t, id, updateAppealLinks, canDeleteLink, canViewLink, updateAppealViewLinks, openAppealLinkModal, canEditLinkAppeal } = props;
	
	useEffect( () =>{
		
		if(!links && !collapsed) {
			setLoad(true);
			getRequest();
		}
		return () => setTotal(null);
	},[]);
	
	useEffect( () =>{
		
		if(!collapsed && links) {
			setLoad(true);
			getRequest();
		}
		setCollapsed(false);
	},[id]);
	
	useEffect( () =>{
		
		if(collapsed || updateAppealLinks){
			setLoad(true);
			getRequest();
			updateAppealViewLinks(false);
		}
		
	},[collapsed, updateAppealLinks, id]);
	
	const groupBy = (items, key) => items.reduce(
		(result, item) => ({
			...result,
			[item[key]]: [
				...(result[item[key]] || []),
				item,
			],
		}),
		{},
	);
	
	async function getRequest (){
		const resp = canViewLink && await baseService.get('objects_links', { 
			pathParams: { objectType: OBJECT_TYPE, id },
			data: { relObjectTypes: OBJECT_TYPE }  
		});
		let groppedLinks = [];
		if (resp.success){
			groppedLinks = groupBy(resp.result, 'linkLabel');
			setTotal(resp.total);
			setLoad(false);
		}
		setLinks(Object.entries(groppedLinks));
	}
	
	async function remove (){
		if (canDeleteLink) {
			const resp = await baseService.delete('appeal_link_delete', { pathParams: { linkId } });
		
			if(resp.success) {
				onCloseModal();
				updateAppealViewLinks(true);
			}
		}
	}
	
	function onCloseModal(){
		setConfirmModal({ isModal: false, linkId: null })
	}
	
	function onClickCollapsed() {
			if(!links.length) return setCollapsed(false);
			setCollapsed(!collapsed);
	}
	
	const onMouseEnter = e => {
		const { pageX, pageY } = e;
		const prop = (attr) => {return e.target.getAttribute(attr) || ' - '};
		setPopup((
			<Popup point={{ pageX, pageY }} size={{ height: 240 }}>
				<div className='popup-name'>
					<PriorityArrowsIcons id={prop('priorityId')} />
					<div className={cx('appeal-link', 'object-id')} >{prop('objectNum')}</div>
					<div className={cx(`sticker`, 'sticker-popup', `sticker-${prop('statusCode')}`)}>{prop('statusName')}</div>
				</div>
				<div className='popup-title'>{t('appealLink.typeText')}</div>
				<div className='popup-value cut'>{prop('typeText')}</div>
				<div className='popup-title'>{t('appealLink.subject')}</div>
				<div className='popup-value cut'>{prop('subject')}</div>
				<div className='popup-title'>{t('appealLink.startDate')}</div>
				<div className='popup-value'>{moment(+prop('startDate')).format('DD.MM.YYYY HH:mm')}</div>
				<div className='popup-title'>{t('appealLink.operatorName')}</div>
				<div className='popup-value cut'>{prop('operatorName')}</div>
				<div className='popup-title'>{t('appealLink.destination')}</div>
				<div className='popup-value cut'>{prop('destination')}</div>
			</Popup>
		));
	};
	const onMouseLeave = () => setPopup(null);
	
	const renderLinks = () => {
		if(links.length)
			return  (
					<>
						{links.map(item =>
						<div className='link-type-block '>
							<div className='link-type-title'>
								{item[0]}
							</div>
							{item[1].map( link =>
								<div className='link-item'>
									<PriorityArrowsIcons id={link.priority.id} />
									<div className='object-id'
										 onClick={ () => props.history.push(`/appeals/${link.objectId}/`) }
										 onMouseEnter={onMouseEnter}
										 onMouseLeave={onMouseLeave}
										 statusName={link.status.name}
										 statusCode={link.status.code}
										 destination={link.route.destinationTitle}
										 priorityId={link.priority.id}
										 {...link}
									>
										{link.objectNum}
									</div>
									<div className='sticker-wrapper'>
										<div className={cx('sticker', `sticker-${[link.status.code]}`)}> {link.status.name} </div>
									</div>
									<div className='type-text' title={link.objectLabel} >
										{link.objectLabel}
									</div>
									<div className='type-text'>{link.typeText || ' - '} </div>
									{canEditLinkAppeal && <div
										className='remove-link'
										title={t('appealLink.remove')}
										onClick={() =>setConfirmModal({isModal:true, linkId:link.linkId})}
									>
										<ReactSVG svgClassName='tab-icon bin-red' src={`/data/svg/bin.svg`} />
									</div>}
								</div> )}
							{popup}
							
							
						</div>)
						}
						{ isModal &&
						<ModalPortal
							onClose={onCloseModal}
							className='modal-small modal-center'
						>
							<MessageModal
								titleModal={t('errors.alert')}
								contentModalText={t('appealLink.confirmDelete')}
								primaryButton={true}
								primaryButtonText={t('appeal.upperYes')}
								onClickPrimaryButton={remove}
								secondaryButton={true}
								secondaryButtonText={t('appeal.upperNo')}
								onClickSecondaryButton={onCloseModal}
							/>
						</ModalPortal>
						}
						</>
		);
		return null;
	};
	
	if (!canViewLink || !total) return null;
	
	return(
		<div className='formBlock link-block'>
			<div className='link-title-wrapper' onClick={onClickCollapsed}>
				<i className={cx(`icon-arrow-right`, { show: collapsed })} />
				<div className='link-title'> {t('appealLink.appealTitle')} { !collapsed && links && !load && `(${total})`}</div>
				{load && <div className='loader-wrapper'> <Loader withContainer/></div>}
				{ canEditLinkAppeal && collapsed && <button className='add-button' onClick={openAppealLinkModal}>{t('add')}</button>}
			</div>
			
			<div className={cx('collapsed', { show: collapsed })}>
				<div className={cx('view', 'view1column', 'tabs')}>
					<div className='link-form-widgets ordering-tab-content'>
							{renderLinks()}
					</div>
				</div>
			</div>
		</div>
	)
	
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkAppealBlock)));
