import {
	CREATE_NEW_CUSTOMER_ERROR,
	CREATE_NEW_CUSTOMER_SUCCESS,
	OPEN_ASIDE_TOP_MENU,
	OPEN_CREATE_NEW_CUSTOMER_MODAL,
	CLOSE_CREATE_NEW_CUSTOMER_MODAL,
	OPEN_CUSTOMER_ADDRESS_MODAL,
	CLOSE_CUSTOMER_ADDRESS_MODAL,
	ADDRESS_SAVE,
	OPEN_CREATE_NEW_CONTACT_PERSON_MODAL,
	CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL,
	UI_TOGGLE_SIDE_MENU_ADDITIONS,
	UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON,
	SHOW_TABS_CLOSE_MENU,
	SHOW_TABS_HIDDEN_MENU,
	TOGGLE_LANG_MENU,
	UI_ADD_SUCCESS_MESSAGE,
	UI_DEL_SUCCESS_MESSAGE,
	UI_MENU_APPS_LOAD,
	UI_MENU_APPS_SHOW,
	UI_MENU_CREATE_SHOW,
	UI_MENU_CREATE_HIDE,
	SUBMITTING_PROCESS_FORM,
	SUBMIT_PROCESS_FORM_SUCCESS,
	SUBMIT_PROCESS_FORM_ERROR,
	UI_SHOW_TOOLTIP,
	UI_HIDE_TOOLTIP,
	UI_SET_TOOLTIP_TEXT,
	OPEN_MESSAGE_MODAL,
	CLOSE_MESSAGE_MODAL,
	SHOW_NOTIFICATION_PANEL,
	HIDE_NOTIFICATION_PANEL,
	SHOW_NOTIFICATION,
	HIDE_NOTIFICATION,
	OPEN_CUSTOMER_SERVICE_MODAL,
	CLOSE_CUSTOMER_SERVICE_MODAL,
	OPEN_CUSTOMER_RESOURCE_MODAL,
	CLOSE_CUSTOMER_RESOURCE_MODAL,
	OPEN_USER_POPUP,
	CLOSE_USER_POPUP,
	OPEN_CREATE_NEW_ORDER_MODAL,
	CLOSE_CREATE_NEW_ORDER_MODAL,
	OPEN_CREATE_APPEAL_FROM_FILE_MODAL,
	CLOSE_CREATE_APPEAL_FROM_FILE_MODAL,
	OPEN_APPEAL_LINK_MODAL,
	CLOSE_APPEAL_LINK_MODAL,
	OPEN_OBJECTS_LINK_MODAL,
	CLOSE_OBJECTS_LINK_MODAL,

	SET_IS_HISTORY_PUSH_REQUIRE_SAVE_WITHOUT_CHANGE_CONFIRM,
	OPEN_SEARCH_EMAIL_MODAL,
	CLOSE_SEARCH_EMAIL_MODAL,
	SET_SEARCH_MODAL_EMAIL_BINDERS,
	CHECK_SEARCH_MODAL_EMAIL_BINDER,
	UNCHECK_SEARCH_MODAL_EMAIL_BINDER,
	CLEAR_SEARCH_MODAL_EMAIL_BINDERS
} from '../constants/actions';

const initialState = {
	appsMenu: false,
	createMenu: false,
	expandedSearch: false,
	contentView: 'card',
	wide: false,
	loading: false,
	create: [],
	apps: [],
	showLangMenu: false,
	showHiddenTabs: false,
	showCloseTabsMenu: '',
	openAsideSubMenuId: null,
	showSideMenuAdditions: false,
	showSideMenuAdditionsPerson: false,
	successMessage: false,
	
	showCreateNewContactPersonModal: false,
	newContactPersonModalContext: null,
	
	showCreateNewCustomerModal: false,
	newCustomerModalContext: null,
	
	showCreateNewOrderModal: false,
	newOrderModalContext: null,

	showCreateAppealFromFileModal: false,
	newAppelFromFileModalContext: null,
	
	showAppealLinkModal: false,
	showObjectsLinkModal: false,
	newLinkModalContext: null,
	
	showCustomerServiceModal: false,

	showCustomerResourceModal: false,
	
	showCustomerAddressModal: false,
	address: null,
	addressModificationCount: 0,
	
	isAppealFormOpened: false,
	isFormSubmitting: false,
	isProcessFormSubmitted: false,
	submitError: null,
	showTooltip: false,
	tooltipText: '',
	showMessageModal: false,
	formsHistory: [],
	notifications: [],
	showNotificationPanel: false,
	
	isUserPopupOpen: false,


	isHistoryPushRequireSaveWithoutChangeConfirm: false,

	showSearchEmailModal: false,
	checkedSearchModalEmailBinders: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
	case OPEN_USER_POPUP:
		return { ...state, isUserPopupOpen: true };
	
	case CLOSE_USER_POPUP:
		return { ...state, isUserPopupOpen: false };
	
	case UI_MENU_CREATE_SHOW:
		return {
			...state,
			createMenu: !state.createMenu,
		};
	
	case UI_MENU_CREATE_HIDE:
		return {
			...state,
			createMenu: false,
		};
	
	case UI_MENU_APPS_LOAD:
		return {
			...state,
			appsMenu: !state.appsMenu,
			apps: action.apps,
		};
	
	case UI_MENU_APPS_SHOW:
		return {
			...state,
			appsMenu: !state.appsMenu,
		};
	
	case SHOW_TABS_HIDDEN_MENU:
		return {
			...state,
			showHiddenTabs: !state.showHiddenTabs,
		};
	
	case SHOW_TABS_CLOSE_MENU:
		let showCloseTabsMenu = '';
		
		if (state.showCloseTabsMenu !== action.payload.id) {
			showCloseTabsMenu = action.payload.id;
		}
		
		return {
			...state,
			createMenu: false,
			appsMenu: false,
			showCloseTabsMenu,
		};
	
	case TOGGLE_LANG_MENU:
		return {
			...state,
			showLangMenu: action.payload.visible,
		};
	
	case OPEN_ASIDE_TOP_MENU:
		return {
			...state,
			openAsideSubMenuId: action.payload.id,
		};
	
	case UI_ADD_SUCCESS_MESSAGE:
		return {
			...state,
			successMessage: true,
		};
	
	case UI_DEL_SUCCESS_MESSAGE:
		return {
			...state,
			successMessage: false,
		};
	
	case UI_TOGGLE_SIDE_MENU_ADDITIONS:
		let showSideMenuAdditions = !state.showSideMenuAdditions;
		if (action.showState){
			const {show} = action.showState;
			showSideMenuAdditions = show;
		}
		
		return {
			...state,
			showSideMenuAdditions,
		};
	
	case UI_TOGGLE_SIDE_MENU_ADDITIONS_PERSON:
		return {
			...state,
			showSideMenuAdditionsPerson: !state.showSideMenuAdditionsPerson,
		};
	
	case OPEN_CREATE_NEW_CUSTOMER_MODAL:
		return {
			...state,
			showCreateNewCustomerModal: true,
			newCustomerModalContext: action.context,
		};
	
	case CLOSE_CREATE_NEW_CUSTOMER_MODAL:
		return {
			...state,
			showCreateNewCustomerModal: false,
			newCustomerModalContext: null,
		};
	
	case OPEN_CREATE_NEW_ORDER_MODAL:
		return {
			...state,
			showCreateNewOrderModal: true,
			newOrderModalContext: action.context
		};
	
	case CLOSE_CREATE_NEW_ORDER_MODAL:
		return {
			...state,
			showCreateNewOrderModal: false,
			newOrderModalContext: null
		};

	case OPEN_CREATE_APPEAL_FROM_FILE_MODAL:
		return {
			...state,
			showCreateAppealFromFileModal: true,
			newAppelFromFileModalContext: action.context
		};
	
	case CLOSE_CREATE_APPEAL_FROM_FILE_MODAL:
		return {
			...state,
			showCreateAppealFromFileModal: false,
			newAppelFromFileModalContext: null
		};
		
	case OPEN_APPEAL_LINK_MODAL:
		return {
			...state,
			showAppealLinkModal: true,
			newLinkModalContext: action.context
		};
	
	case CLOSE_APPEAL_LINK_MODAL:
		return {
			...state,
			showAppealLinkModal: false,
			newLinkModalContext: null
		};

	case OPEN_OBJECTS_LINK_MODAL:
		return {
			...state,
			showObjectsLinkModal: true,
			newLinkModalContext: action.context
		};

	case CLOSE_OBJECTS_LINK_MODAL:
		return {
			...state,
			showObjectsLinkModal: false,
			newLinkModalContext: null
		};
	
	case OPEN_CREATE_NEW_CONTACT_PERSON_MODAL:
		return {
			...state,
			showCreateNewContactPersonModal: true,
			newContactPersonModalContext: action.context,
		};
	
	case CLOSE_CREATE_NEW_CONTACT_PERSON_MODAL:
		return {
			...state,
			showCreateNewContactPersonModal: false,
			newContactPersonModalContext: null,
		};
	
	case OPEN_CUSTOMER_SERVICE_MODAL:
		return {
			...state,
			showCustomerServiceModal: true,
		};
	
	case CLOSE_CUSTOMER_SERVICE_MODAL:
		return {
			...state,
			showCustomerServiceModal: false,
		};

	case OPEN_CUSTOMER_RESOURCE_MODAL:
		return {
			...state,
			showCustomerResourceModal: true,
		};
	
	case CLOSE_CUSTOMER_RESOURCE_MODAL:
		return {
			...state,
			showCustomerResourceModal: false,
		};
	
	case OPEN_CUSTOMER_ADDRESS_MODAL:
		return {
			...state,
			showCustomerAddressModal: true,
			address: action.payload.address,
		};
	
	case CLOSE_CUSTOMER_ADDRESS_MODAL:
		return {
			...state,
			showCustomerAddressModal: false,
			address: null,
		};
	
	case ADDRESS_SAVE:
		return {
			...state,
			addressModificationCount: state.addressModificationCount + 1,
		};
	
	case CREATE_NEW_CUSTOMER_SUCCESS:
		return {
			...state,
			showCreateNewCustomerModal: false,
			successMessage: true,
		};
	
	case CREATE_NEW_CUSTOMER_ERROR:
		return {
			...state,
			showCreateNewCustomerModal: false, // TODO add errorMessage
		};
	
	case SUBMITTING_PROCESS_FORM:
		return {
			...state,
			isFormSubmitting: true,
			isProcessFormSubmitted: false,
			submitError: null,
		};
	
	case SUBMIT_PROCESS_FORM_SUCCESS:
		return {
			...state,
			isFormSubmitting: false,
			isProcessFormSubmitted: true,
		};
	
	case SUBMIT_PROCESS_FORM_ERROR:
		return {
			...state,
			isFormSubmitting: false,
			isProcessFormSubmitted: false,
			submitError: action.error,
		};
	
	case UI_SET_TOOLTIP_TEXT:
		return {
			...state,
			tooltipText: action.payload,
		};
	
	case UI_SHOW_TOOLTIP:
		return {
			...state,
			showTooltip: true,
		};
	
	case UI_HIDE_TOOLTIP:
		return {
			...state,
			showTooltip: false,
		};
	
	case OPEN_MESSAGE_MODAL:
		return {
			...state,
			showMessageModal: true,
		};
	
	case CLOSE_MESSAGE_MODAL:
		return {
			...state,
			showMessageModal: false,
		};
	
	case SHOW_NOTIFICATION:
		return {
			...state,
			notifications: [...state.notifications, { ...action.payload, id: action.id || Date.now() }],
		};
	
	case HIDE_NOTIFICATION:
		return {
			...state,
			notifications: state.notifications.filter((note) => note.id !== action.payload.id),
		};
	
	case SHOW_NOTIFICATION_PANEL:
		return {
			...state,
			showNotificationPanel: true,
		};
	
	case HIDE_NOTIFICATION_PANEL:
		return {
			...state,
			showNotificationPanel: false,
		};

	case SET_IS_HISTORY_PUSH_REQUIRE_SAVE_WITHOUT_CHANGE_CONFIRM: {
		return {
			...state,
			isHistoryPushRequireSaveWithoutChangeConfirm: action.isHistoryPushRequireSaveWithoutChangeConfirm
		};
	}

	case OPEN_SEARCH_EMAIL_MODAL:
		return {
			...state,
			showSearchEmailModal: true,
			searchEmailModalContext: action.context
		};

	case CLOSE_SEARCH_EMAIL_MODAL:

		return {
			...state,
			showSearchEmailModal: false,
			searchEmailModalContext: null
		};

	case SET_SEARCH_MODAL_EMAIL_BINDERS: {
		return {
			...state,
			checkedSearchModalEmailBinders: [...new Set(action.checkedBinders)],
		};
	}
	
	case CHECK_SEARCH_MODAL_EMAIL_BINDER: {
		return {
			...state,
			checkedSearchModalEmailBinders: [...new Set([...state.checkedSearchModalEmailBinders, action.binder])],
		};
	}
	
	case UNCHECK_SEARCH_MODAL_EMAIL_BINDER: {
		return {
			...state,
			checkedSearchModalEmailBinders: state.checkedSearchModalEmailBinders.filter( item => item !== action.binder),
		};
	}
	
	case CLEAR_SEARCH_MODAL_EMAIL_BINDERS: {
		return {
			...state,
			checkedSearchModalEmailBinders: [],
		};
	}
	
	default:
		return state;
	}
};
