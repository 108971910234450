import React from 'react';
import cx from 'classnames';
import styles from 'styles/modules/progressbar.module.scss';
import PropTypes from 'prop-types';

class Progressbar extends React.Component {
	
	state = {
		completed: 0,
	};
	
	timer = null;
	
	componentDidMount () {
		this.timer = setInterval(this.progress, 500);
	}
	
	componentWillUnmount () {
		clearInterval(this.timer);
	}
	
	progress = () => {
		if (this.state.completed > 100) {
			this.setState({ completed: 0 });
		} else {
			this.setState(prevState => ({ completed: prevState.completed + 10 }));
		}
	};
	
	render () {
		const { completed } = this.state;
		const style = { width: `${completed}%` };
		return (
			<div className={cx(styles.wrapper, this.props.className)}>
				<div className={styles.progress} style={style} />
			</div>
		);
	}
}

Progressbar.propTypes = {
	className: PropTypes.string
};

export default Progressbar;
