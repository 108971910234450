import {
	SAVE_FORM_CHANGES,
	CANCEL_FORM_CHANGE,
	REPEAT_FORM_CHANGE
} from 'constants/actions';
import { FORM_SAVE_CHANGES_INTERVAL, FORM_SAVE_CHANGES_LIMIT } from '../constants';

const initialState = {
	history: [],
	pointer: -1,
	changed: false,
	lastChangeDate: Date.now()
};

export default (state = initialState, action) => {
	switch (action.type) {
	case CANCEL_FORM_CHANGE: {
		const pointer = state.pointer <= 0 ? -1 : state.pointer - 1;
		return { ...state, pointer, changed: true };
	}
	
	case REPEAT_FORM_CHANGE: {
		let pointer = state.pointer;
		const historyLast = state.history.length - 1;
		pointer = pointer >= historyLast ? pointer : pointer + 1;
		return { ...state, pointer, changed: true };
	}
	
	case SAVE_FORM_CHANGES: {
		const history = [...state.history];
		let { lastChangeDate, pointer } = state;
		
		if (!state.changed) {
			const current = history[pointer];
			if (current && current.form === action.payload.form && current.field === action.payload.field && (Date.now() - lastChangeDate < FORM_SAVE_CHANGES_INTERVAL)) {
				history.splice(pointer + 1);
				history[pointer].after = action.payload.after;
			} else {
				pointer++;
				history.splice(pointer, 1000, action.payload);
				
				if (history.length > FORM_SAVE_CHANGES_LIMIT) {
					history.shift();
					pointer--;
				}
			}
		}
		
		return { ...state, history, pointer, changed: false, lastChangeDate: Date.now() };
	}
	
	default:
		return state;
	}
};
