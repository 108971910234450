import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import baseService from 'services/BaseService';
import Loader from 'components/Loader';
import { createCheckPropsFunction } from 'helpers';
import styles from 'styles/modules/knowledgeBase.module.scss';
import LeafNode from './LeafNode';
import FolderNode from './FolderNode';
import {
	closeNodeChildren,
	getNodePath, updateNode, findNode,
} from '../../../pages/Appeal/Form/components/AppealTypeSelect/helpers';
import SidebarHeader from './SidebarHeader';

@withTranslation()
@withRouter
class Sidebar extends React.PureComponent {
	constructor (props) {
		super(props);
		
		this.config = {
			valueField: 'id',
			labelField: 'name',
			leafField: 'leaf',
			childrenField: 'children'
		};
		
		this.state = {
			navigationTree: [],
			nodes: [],
			selectedNode: null,
			isSearching: false,
			isSearchResult: false,
		};
		
		this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
		this.searchRequest = this.searchRequest.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
		this.getNormalizedNodes = this.getNormalizedNodes.bind(this);
		this.renderNode = this.renderNode.bind(this);
		this.selectNode = this.selectNode.bind(this);
		this.configureBreadcrumbs = this.configureBreadcrumbs.bind(this);
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		const isSearchModeTurnedOff = prevProps.searchMode && !this.props.searchMode;
		const { id, type } = this.props.match.params;
		
		if (isPropChanged('catalogList') || isSearchModeTurnedOff) {
			const { nodes, selectedNode } = this.getNormalizedNodes({ nodeArray: this.props.catalogList, id, type });
			this.setState({ nodes, selectedNode }, () => {
				this.props.setBreadcrumbs(this.configureBreadcrumbs());
			});
			this.props.setSelectedNode(selectedNode);
		}
		
		if ((isPropChanged('match.params.id') || isPropChanged('match.params.type')) && !this.state.isSearchResult) {
			const { nodes, selectedNode } = this.getNormalizedNodes({ nodeArray: this.props.catalogList, id, type });
			this.setState({ nodes, selectedNode }, () => {
				this.props.setBreadcrumbs(this.configureBreadcrumbs());
			});
			this.props.setSelectedNode(selectedNode);
		}
		
		if (this.props.match.url === '/specification' && prevProps.match.url !== '/specification') {
			const { nodes, selectedNode } = this.getNormalizedNodes({ nodeArray: this.props.catalogList, id, type });
			this.setState({ nodes, selectedNode }, () => {
				this.props.setBreadcrumbs(this.configureBreadcrumbs());
			});
			this.props.setSelectedNode(selectedNode);
			
			this.setState({ isSearchResult: false })
		}
	};
	
	configureBreadcrumbs (nodeList = this.state.nodes) {
		const { id, type } = this.props.match.params;
		const staticRoutes = {
			homeRoute: {
				text: this.props.t('specification.catalogue'),
				id: null
			},
			searchRoute: {
				text: this.props.t('knowledgeBase.searchBreadcrumb'),
				id: null
			}
		};
		const { valueField, childrenField, labelField } = this.config;
		const breadcrumbs = [];
		
		if (this.state.isSearchResult) {
			breadcrumbs.push(staticRoutes.homeRoute);
			breadcrumbs.push(staticRoutes.searchRoute);
			
			return breadcrumbs;
		}
		
		const foundNode = findNode(nodeList, childrenField, (node, parentId) => {
			return node.id.toString() === id && node.isFolder.toString() === type;
		});
		
		if (foundNode) {
			let currentNode = foundNode;
			
			while (currentNode) {
				breadcrumbs.push({ id: currentNode[valueField], text: currentNode[labelField], isFolder: currentNode.isFolder });
				currentNode = currentNode.parent;
			}
			
			breadcrumbs.push(staticRoutes.homeRoute);
			
			return breadcrumbs.reverse();
		} else {
			return [staticRoutes.homeRoute];
		}
	}
	
	getNormalizedNodes ({ nodeArray, id,  type, openAll = false }) {
		const { childrenField, valueField } = this.config;
		let selectedNode = null;
		
		function openParents (node) {
			let currentNode = node.parent;
			while (currentNode) {
				currentNode.open = true;
				currentNode = currentNode.parent;
			}
		}
		
		const normalizeNodes = (array, parent) => {
			if (!Array.isArray(array) || array.length === 0) return null;
			
			return array.map(node => {
				const normalizedNode = {
					...node,
					...node.item,
					parent,
					open: openAll
				};
				
				if (normalizedNode[valueField].toString() === id && normalizedNode.isFolder.toString() === type) {
					normalizedNode.open = true;
					normalizedNode.selected = true;
					openParents(normalizedNode);
					selectedNode = normalizedNode;
				}
				
				normalizedNode[childrenField] = normalizeNodes(node[childrenField], normalizedNode);
				
				return normalizedNode;
			});
		};
		
		return {
			nodes: normalizeNodes(nodeArray, null) || [],
			selectedNode
		};
	}
	
	handleSubmitSearch (searchQuery) {
		if (!searchQuery) {
			const { nodes } = this.getNormalizedNodes({
				nodeArray: this.props.catalogList
			});
			this.setState({ nodes: nodes, isSearching: false, isSearchResult: false }, () => { this.props.setBreadcrumbs(this.configureBreadcrumbs()); } );
			this.props.history.push('/specification');
			return;
		}
		
		this.setState({ isSearching: true });
		
		this.searchRequest(searchQuery)
			.then(response => {
				const searchResults = response.children || [];
				const { nodes } = this.getNormalizedNodes({ nodeArray: searchResults, openAll: true });
				
				this.setState({ nodes, isSearching: false, isSearchResult: true });
				this.props.setBreadcrumbs(this.configureBreadcrumbs());
			})
			.catch(error => {
				console.error(error);
				this.setState({ nodes: [], isSearching: false });
			});
	};
	
	searchRequest (query) {
		const params = {
			data: {
				query,
				filter: 'SERVICE',
				page: 1,
				start: 0,
				limit: 1000,
			}
		};
		
		return baseService.get('get_service_catalogue', params);
	};
	
	handleCreate (directory) {
		this.props.setBreadcrumbs(this.configureBreadcrumbs(directory.id));
	};
	
	selectNode (clickedNode) {
		const { nodes, selectedNode } = this.state;
		const { leafField, valueField, childrenField } = this.config;
		
		let updatedNodes = nodes;
		
		const isSameNode = get(clickedNode, valueField) === get(selectedNode, valueField);
		
		if (selectedNode) {
			const nodePath = getNodePath(selectedNode, valueField);
			
			function deselectNode (node) {
				const updatedNode = { ...node };
				if (!isSameNode) {
					updatedNode.selected = false;
				}
				if (!node[leafField] && isSameNode) {
					updatedNode.open = !node.open;
					return updatedNode.open ? updatedNode : closeNodeChildren(updatedNode, childrenField);
				}
				return updatedNode;
			}
			
			updatedNodes = updateNode(updatedNodes, nodePath, deselectNode, this.config);
		}
		
		if (clickedNode && !isSameNode) {
			const nodePath = getNodePath(clickedNode, valueField);
			
			function selectNode (node) {
				const updatedNode = { ...node, selected: true };
				if (!node[leafField]) {
					updatedNode.open = !node.open;
					return updatedNode.open ? updatedNode : closeNodeChildren(updatedNode, childrenField);
				}
				return updatedNode;
			}
			
			updatedNodes = updateNode(updatedNodes, nodePath, selectNode, this.config);
		}
		
		this.setState({ nodes: updatedNodes, selectedNode: clickedNode });
		if (!isSameNode) {
			this.props.history.push(
				`/specification/${clickedNode[valueField]}/${clickedNode.isFolder}`
			);
		}
	}
	
	renderNode (node) {
		const { valueField, leafField } = this.config;
		
		if (node[leafField]) {
			return (
				<LeafNode
					key={node[valueField]}
					{...this.config}
					node={node}
					onClick={this.selectNode}
				/>
			);
		} else {
			return (
				<FolderNode
					key={node[valueField]}
					{...this.config}
					node={node}
					onClick={this.selectNode}
					renderNode={this.renderNode}
				/>
			);
		}
	}
	
	addService = () => {
		let id = this.props.parentFolderId;
		
		if (this.state.selectedNode && !this.state.isSearchResult) {
			id = this.state.selectedNode.isFolder ? this.state.selectedNode.id : this.state.selectedNode.parentId;
		}
		if (this.props.history.location.pathname !== `/specification/${id}/1/add`) {
			this.props.history.push(`/specification/${id}/1/add`);
		}
	};
	
	render () {
		const { isLoading, canEdit } = this.props;
		const { nodes, isSearching } = this.state;
		
		const showLoader = isLoading || isSearching;
		
		return (
			<div className={styles.sidebarBox}>
				<SidebarHeader
					onSearchSubmit={this.handleSubmitSearch}
					addService={this.addService}
					canEdit={canEdit}
				/>
				
				<div className={styles.treeContent}>
					{showLoader && <Loader withContainer />}
					{!showLoader && nodes.map(this.renderNode)}
				</div>
			</div>
		);
	}
}

Sidebar.propTypes = {
	catalogList: PropTypes.array,
	isLoading: PropTypes.bool,
	breadcrumbs: PropTypes.array,
	searchMode: PropTypes.bool,
	directoriesStructure: PropTypes.array,
	onChangeCheckedKnowledge: PropTypes.func
};

export default Sidebar;
