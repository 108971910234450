import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import CONF from '../../config/conf';
import { openAsideSubMenu } from '../../actions/ui';
import Popup from '../Popup';

function mapStateToProps (state) {
	return {
		openAsideSubMenuId: state.ui.openAsideSubMenuId,
		isRequestLoading: state.app.requests.length > 0,
	};
}

@withTranslation()
@withRouter
@connect(mapStateToProps, { openAsideSubMenu })
class Section extends React.Component {
	constructor (props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.getSubMenuPopup = this.getSubMenuPopup.bind(this);
		this.checkLink = this.checkLink.bind(this);
		this.checkLastSelectedLink = this.checkLastSelectedLink.bind(this);
	}
	
	onClick (event) {
		const { onClick, history, item } = this.props;
		
		if (onClick) {
			onClick(event);
		}
		
		if (item.link && item.blank) {
			window.open(item.link, '_blank');
		} else {
			history.push(item.link);
		}
	}
	
	getSubMenuPopup () {
		const { openAsideSubMenuId, item: { name } } = this.props;
		if (openAsideSubMenuId !== name) return null;
		const { item: { link, items } } = this.props;
		return (
			<Popup place='aside-sub-menu'>
				{items.map(item => (
					<Link
						key={item.name}
						to={`${link}/${item.name}`}
						className='aside-sub-menu-link'
					>
						{item.title}
					</Link>
				))}
			</Popup>
		);
	}
	
	checkLink (url) {
		const { location: { pathname } } = this.props;
		return pathname === url || pathname.startsWith(`${url}/`);
	}

	checkLastSelectedLink (url) {
		const { currentSuperTabPath } = this.props;
		return currentSuperTabPath.startsWith(`${url}`);
	}
	
	handleClick (e) {
		e.stopPropagation();
		e.preventDefault();
		const { openAsideSubMenu, item: { name } } = this.props;
		if (openAsideSubMenu) {
			openAsideSubMenu(name);
		}
	}
	
	render () {
		const { item, t } = this.props;
		if (item.items && item.items.length) {
			return (
				<div
					className={cx('box', item.name)}
					onClick={this.handleClick}
					title={t(item.description || item.title || item.name)}
				>
					<i className={`icon-${CONF.icons[item.name] || 'battery-unknown'}`} />
					<span className='nav-title'>{t(item.title || item.title || item.name)}</span>
					<i className='icon-kebab-hor' />
					{this.getSubMenuPopup()}
				</div>
			);
		}
		
		return (
			<button
				onClick={this.onClick}
				className={cx('box', item.name, { active: this.checkLink(item.link), 'is-last-selected': this.checkLastSelectedLink(item.link)})}
				title={t(item.description || item.title || item.name)}
				type='button'
			>
				<ReactSVG svgClassName='grid-icon identity-icon-white' src={`/data/svg/navigation/${item.icon}`} />
			</button>
		);
	}
}

Section.defaultProps = {
	openAsideSubMenu: false,
	openAsideSubMenuId: 'default',
	onClick: false,
};

Section.propTypes = {
	item: PropTypes.object.isRequired,
	openAsideSubMenuId: PropTypes.string,
};

export default Section;
