import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import LeafNode from './LeafNode';
import FolderNode from './FolderNode';
import { createCheckPropsFunction } from 'helpers';
import Loader from 'components/Loader';

import {
    closeNodeChildren,
    findNodeByField,
    getNodePath,
    openNodeAndSelect,
    searchMatchedLeafs,
    updateNode,
    setNodeToNodeList,
} from './helpers';

class OptionTypesTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: OptionTypesTree.getNormalizedNodes(props),
            selectedNodes: [],
            searchResults: [],
            isLoad: false,
        };

        this.renderNode = this.renderNode.bind(this);
        this.renderSearchNode = this.renderSearchNode.bind(this);
        this.toggleFolder = this.toggleFolder.bind(this);
        this.selectNode = this.selectNode.bind(this);
        this.selectSearchLeaf = this.selectSearchLeaf.bind(this);
        this.selectInitialValue = this.selectInitialValue.bind(this);
        this.scrollIntoView = this.scrollIntoView.bind(this);
        this.checkSemiselected = this.checkSemiselected.bind(this);
    }

    componentDidMount() {
        this.selectInitialValue();
    }

    componentDidUpdate(prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if (isPropChanged('searchQuery')) {
            let nextSearchResults = [];

            if (this.props.searchQuery.length > 0) {
                nextSearchResults = searchMatchedLeafs(
                    this.state.nodes,
                    this.props.searchQuery,
                    this.props,
                );
            }

            this.setState({ searchResults: nextSearchResults });
        }
    }

    static getNormalizedNodes(props) {
        const { nodeArray, childrenField } = props;

        const normalizeNodes = (array, parent) => {
            if (!Array.isArray(array) || array.length === 0) return null;

            return array.map((node) => {
                const normalizedNode = { ...node, parent, open: false };
                normalizedNode[childrenField] = normalizeNodes(node[childrenField], normalizedNode);

                return normalizedNode;
            });
        };

        return normalizeNodes(nodeArray, null);
    }

    selectInitialValue() {
        const { childrenField, valueField, initialValue, onChange } = this.props;
        const { nodes } = this.state;
        let updatedNodes = nodes;

        if (initialValue) {
            initialValue.forEach((item) => {
                const foundNode = findNodeByField(nodes, 'id', item.id, childrenField);

                if (foundNode) {
                    updatedNodes = openNodeAndSelect(updatedNodes, foundNode, this.props);
                }
            });
            onChange(initialValue);
        }

        this.setState({
            nodes: updatedNodes,
            selectedNodes: initialValue,
            isLoad: true,
        });
    }

    scrollIntoView(nodeValue) {
        const htmlNode = document.getElementById(nodeValue);
        if (!htmlNode) return;

        if (htmlNode.scrollIntoViewIfNeeded) {
            htmlNode.scrollIntoViewIfNeeded(false);
        } else {
            htmlNode.scrollIntoView(false);
        }
    }

    checkSemiselected(node, childrenField) {
        if (Array.isArray(node[childrenField])) {
            const itemParentLenth = node[childrenField].length;
            let selectedParentLenth = 0;

            node[childrenField].forEach((current) => {
                if (current.selected) return ++selectedParentLenth;
            });
            return selectedParentLenth !== itemParentLenth;
        }
    }

    /**
     * If opened - close node and it's children, if closed - open
     * @param {Object} folderNode
     */
    toggleFolder(folderNode) {
        const { childrenField, valueField, keySelectField } = this.props;

        const nodePath = getNodePath(folderNode, keySelectField || valueField);

        const toggleNode = (node) => {
            const updatedNode = { ...node };
            updatedNode.open = !node.open;

            return updatedNode.open ? updatedNode : closeNodeChildren(updatedNode, childrenField);
        };

        const updatedNodes = updateNode(this.state.nodes, nodePath, toggleNode, this.props);

        this.setState({ nodes: updatedNodes });
    }

    selectNode(node, checked) {
        this.setState({ isLoad: false });

        const { nodes, selectedNodes } = this.state;
        const { onChange } = this.props;
        const initSelectedNodes = selectedNodes.map((node) => ({
            ...node,
            selected: true,
            value: node.value,
        }));

        let preparedSelectedArrayNodes = [...initSelectedNodes];

        function setAllNodesToSelect(tree) {
            return tree.map((item) => {
                if (item.children) {
                    item.children = setAllNodesToSelect(item.children);
                }

                item.selected = checked;

                let current = preparedSelectedArrayNodes.findIndex(
                    (it) => parseInt(it.id) === parseInt(item.id),
                );

                if (current !== -1) {
                    preparedSelectedArrayNodes[current] = item;
                } else {
                    preparedSelectedArrayNodes.push(item);
                }

                return item;
            });
        }

        const rebuildedNodes = setNodeToNodeList(nodes, setAllNodesToSelect([node])[0]);
        const selectedArrayNodes = preparedSelectedArrayNodes.filter(
            (currentItem) => currentItem.selected,
        );

        onChange(selectedArrayNodes);
        this.setState({
            isLoad: true,
            selectedNodes: selectedArrayNodes,
            nodes: rebuildedNodes,
        });
    }

    renderNode(node) {
        const { keySelectField, valueField, labelField, leafField, childrenField, onSubmit, multi } = this.props;

        const commonProps = { valueField, labelField, leafField, childrenField, node };
        const checked = node.selected ? node.selected : false;
		const semiSelected = this.checkSemiselected(node, childrenField);

        if (node[leafField]) {
            return (
                <LeafNode
                    key={node[keySelectField || valueField]}
                    {...commonProps}
                    onSelect={this.selectNode}
                    onSubmit={onSubmit}
                    checked={checked}
                    multi={multi}
                />
            );
        } else {
            return (
                <FolderNode
                    key={node[keySelectField || valueField]}
                    {...commonProps}
                    onClick={this.toggleFolder}
                    renderNode={this.renderNode}
                    multi={multi}
                    checked={checked}
                    semiSelected={semiSelected}
                    selectFolder={this.selectNode}
                />
            );
        }
    }

    selectSearchLeaf(leafNode) {
        const { valueField, keySelectField, onChange } = this.props;

        const updatedSearchResults = this.state.searchResults.map((searchNode) => {
            if (searchNode[keySelectField || valueField] === leafNode[keySelectField || valueField]) {
                return { ...searchNode, selected: !searchNode.selected };
            }

            if (searchNode.selected && searchNode[keySelectField || valueField] !== leafNode[keySelectField || valueField]) {
                return { ...searchNode, selected: false, checked: false };
            }

            return searchNode;
        });

        this.setState({ searchResults: updatedSearchResults });
        onChange(leafNode);
    }

    renderSearchNode(node) {
        const { keySelectField, valueField, labelField, leafField, childrenField, onSubmit, multi } = this.props;
        const commonProps = { valueField, labelField, leafField, childrenField, node };
        const checked = node.selected ? node.selected : false;
        const semiSelected = this.checkSemiselected(node, childrenField);

        if (node[leafField]) {
            return (
                <LeafNode
                    key={node[keySelectField || valueField]}
                    {...commonProps}
                    onSelect={this.selectNode}
                    onSubmit={onSubmit}
                    multi={multi}
                    checked={checked}
                    searchMode
                />
            );
        } else {
            return (
                <FolderNode
                    key={node[keySelectField || valueField]}
                    {...commonProps}
                    onClick={this.toggleFolder}
                    renderNode={this.renderNode}
                    multi={multi}
                    checked={checked}
                    semiSelected={semiSelected}
                    selectFolder={this.selectNode}
                    searchMode
                />
            );
        }
    }

    render() {
        const { searchQuery } = this.props;
        const { nodes, searchResults, isLoad } = this.state;
        const searchModeEnabled = searchQuery.length > 0;

        return (
            <div className={styles.content}>
                {!isLoad && <Loader withContainer />}
                {isLoad && !searchModeEnabled && Array.isArray(nodes) && nodes.map(this.renderNode)}
                {isLoad && searchModeEnabled && searchResults.map(this.renderSearchNode)}
            </div>
        );
    }
}

OptionTypesTree.defaultProps = {
    multi: false,
};

OptionTypesTree.propTypes = {
    nodeArray: PropTypes.array,
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    leafField: PropTypes.string,
    childrenField: PropTypes.string,
    searchQuery: PropTypes.string,
    initialValue: PropTypes.array,
    onChange: PropTypes.func,
    multi: PropTypes.bool,
};

export default OptionTypesTree;
