import React from 'react';
import PropTypes from 'prop-types';

import Popup from '../Popup';
import { withTranslation } from 'react-i18next';
import Portal from "../../pages/Ordering/common/Portal";



class TabCloseMenu extends React.Component {
	handleRemoveTab = (e) => {
		const { removeTab, val } = this.props;
		removeTab(e, val);
	};
	
	render () {
		const { removeAll, removeAllTabsExceptCurrent, val, place, t, closeContextMenuUrl, removeCloseContextMenuUrl, withContextMenu, withPortal, portalRootId, portalPosition, portalFieldKey } = this.props;
		// return with wrapped Portal
		if (withPortal) {
			return (
					<>
						<span className='icon-close hidden-tabs-close' onClick={this.handleRemoveTab} />
						{
							withContextMenu && closeContextMenuUrl && closeContextMenuUrl === val.url &&
							<Portal className="hidden-tabs-close-context-portal" rootId={portalRootId} position={portalPosition} dataFieldKey={portalFieldKey}>
								<Popup place={`hidden-menu ${place}`} onClickOutside={removeCloseContextMenuUrl}>
									<div
										className='hidden-menu-item'
										onClick={(e) => removeAllTabsExceptCurrent(e, val)}
									>
										{t('deleteAllTabsExceptOne')}
									</div>
									<div
										className='hidden-menu-item'
										onClick={(e) => removeAll(e, val)}
									>
										{t('deleteAllTabs')}
									</div>
								</Popup>
							</Portal>
						}
					</>
			)
		}
		return (
			<>
				<span className='icon-close hidden-tabs-close' onClick={this.handleRemoveTab} />
				{
					withContextMenu && closeContextMenuUrl && closeContextMenuUrl === val.url &&
					<Popup place={`hidden-menu ${place}`} onClickOutside={removeCloseContextMenuUrl}>
						<div
							className='hidden-menu-item'
							onClick={(e) => removeAllTabsExceptCurrent(e, val)}
						>
							{t('deleteAllTabsExceptOne')}
						</div>
						<div
							className='hidden-menu-item'
							onClick={(e) => removeAll(e, val)}
						>
							{t('deleteAllTabs')}
						</div>
					</Popup>
				}
			</>
		);
	}
}

TabCloseMenu.HiddenTabs = {
	history: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	tabs: PropTypes.array,
	removeTab: PropTypes.func,
	removeAll: PropTypes.func,
	place: PropTypes.string,
	removeAllTabsExceptCurrent: PropTypes.func,
	hideTabs: PropTypes.bool,
	val: PropTypes.object,
	showMenu: PropTypes.func.isRequired
};

export default withTranslation()(TabCloseMenu);
