import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import EventFiltersBlock from './EventFiltersBlock';
import { connect } from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import { getFormSyncErrors, getFormValues, isValid } from 'redux-form';
import moment from "moment/moment";
import {configureFilterObject} from "../../SearchFilters/helpers";
import ModalPortal from "../../ModalPortal";
import MessageModal from "../../MessageModal";
import {CSSTransition} from "react-transition-group";
const filterFormName = 'event_filter';
import { useTranslation } from 'react-i18next';
import permissions, { checkPermissions } from 'config/permissions';
// import { isEmpty } from 'lodash';

const mapStateToProps = state => ({
	filterValues: getFormValues(filterFormName)(state),
	filerSyncError: getFormSyncErrors(filterFormName)(state),
	isValid: isValid(filterFormName)(state),
});

const EventsList = props => {
    const { updateApiGrid, filterValues, filerSyncError, isValid, pApartId: externalId } = props;
	const [loadingState, setLoadingState] = useState(false);
	const [filters, setFilters] = useState(null);
	const history = useHistory();
	const [modalEventData, setModalEventData] = useState(null);
	const { t } = useTranslation();
	const isCanEventsView = checkPermissions(permissions.CalendarOperations.view_calendar_event );
	
	const filterObject = filterValues ? configureFilterObject(filterValues, filters) : [];

	const init = {
		key: 'customer_calendar_events',
		query: '',
		code: 'FTOP_CLEVENT_CUSTOMER_SITE',
		method: 'get',
		// isDynamic: true,
		configUrlKey: "config_grid",
		configKey: "customer_calendar_events",
		configCode: 'FTOP_CLEVENT_CUSTOMER_SITE',
	};
	const gridKey = `${init.key}_${init.path || init.extra || ''}`;
	const sites = externalId && (externalId.includes('[')) ? JSON.parse(externalId) : externalId;
	
	let domain = [];
	let siteId = [sites];
	if (Array.isArray(sites)){
		siteId = [];
		sites.forEach( it => {
			const rule = it && it.toLowerCase().split('@');
			const sitePart = rule ? rule[0] : '';
			const domPart = rule ? rule[1] : '';
			domain = [...domain, domPart];
			siteId = [...siteId, sitePart];
		})
		
	}
	else if (externalId && externalId.includes('@')) {
		const rule = externalId.toLowerCase().split('@');
		siteId[0] = rule[0] || '';
		domain[0] = rule[1] || '';
	}
	if(!domain.length){
		domain[0] = '';
	}
	// const isReady = isEmpty(filerSyncError) && externalId && filterObject.length;
	const isReady = !filerSyncError.length && externalId && filterObject.length;
	
	const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
		if (isReady && filterValues) {
			if (filterValues['CLEVENT_RANGE_START_DATE_NAV@FROM'] && filterValues['CLEVENT_RANGE_START_DATE_NAV@TO'] &&
				moment(+filterValues['CLEVENT_RANGE_START_DATE_NAV@TO']).isAfter(filterValues['CLEVENT_RANGE_START_DATE_NAV@FROM']) ) {
				onStart();
					updateApiGrid({domain: domain, siteId: siteId, filterObject}, gridKey);
			}
		}
	}, [filterValues]);
    
     useEffect(() => {
		 if (isReady) {
		 	onStart();
			updateApiGrid({ domain: domain, siteId: siteId, filterObject}, gridKey);
		 }
	
	 }, []);
	
	const setOpenMode = (mode) => {
		if(!isCanEventsView) return;
		
		setModalEventData(null);
		return history.push(`${modalEventData.url}/${mode}`);
	};
	
	const getOpenModesForModal = () =>{
		if (modalEventData && modalEventData.allowModes) {
			switch (modalEventData.allowModes.length) {
				case 2:
					return {
						primaryButton: true,
						secondaryButton: true,
						primaryButtonText: modalEventData.allowModes[0].label,
						onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
						secondaryButtonText: modalEventData.allowModes[1].label,
						onClickSecondaryButton: () => setOpenMode(modalEventData.allowModes[1].code),
					};
				case 3:
					return {
						dangerButton: true,
						primaryButton: true,
						secondaryButton: true,
						primaryButtonText: modalEventData.allowModes[0].label,
						onClickPrimaryButton: () => setOpenMode(modalEventData.allowModes[0].code),
						secondaryButtonText: modalEventData.allowModes[1].label,
						onClickSecondaryButton: () => setOpenMode(modalEventData.allowModes[1].code),
						dangerButtonText: modalEventData.allowModes[2].label,
						onClickDangerButton: () => setOpenMode(modalEventData.allowModes[2].code),
					};
			}
		}
	};
	
	
	const handleSelect = (event) =>{
		if(!isCanEventsView) return;
		
		if(event.allowModes && event.allowModes.length > 1 ){
			return setModalEventData({
				url: event.url,
				allowModes: event.allowModes
			});
		}
		return history.push(`${event.url}/${event.allowModes[0].code}`);
	};
	
    return (
        <>
			<EventFiltersBlock
				form={filterFormName}
				externalId={externalId}
				tabKey={init.key}
				filters={filters}
				setFilters={setFilters}
			/>
		{!!isReady &&
		<div className='content-wrapper ordering-component-ui-core-wrapper with-fixed-status customer-ordering-grid'>
                {loadingState && <Loader />}
				<GridWrapper
					tuner={() => init}
					gridParams={{
						selectItem: handleSelect
					}}
					initUpdate={false}
					onStart={onStart}
					onFinish={onFinish}
				/>
            </div>}
			<CSSTransition
				in={modalEventData}
				classNames='fade'
				appear
				enter
				exit
				timeout={300}
				mountOnEnter
				unmountOnExit
			>
				<ModalPortal onClose={() => setModalEventData(null)} className='modal-small modal-center'>
					<MessageModal
						titleModal={t('calendar.askEventModalTitle')}
						contentModalText={t('calendar.askEventModalDescription')}
						{...getOpenModesForModal()}
					/>
				</ModalPortal>
			</CSSTransition>
        </>
    );
};

export default connect(mapStateToProps, { updateApiGrid: setApi })(withRouter(EventsList));

