import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import OptionsListContainer from './OptionsListContainer';
import OptionsTreeContainer from './OptionsTreeContainer';
import { isEmpty, uniqBy } from 'lodash';
import { withTranslation } from 'react-i18next';

@withTranslation()
class MultiSelectWithSearch extends React.Component {
	
	constructor (props) {
		super(props);
		
		this.state = {
			isOpen: false,
		};
		
		this.optionsRef = React.createRef();
		this.closeOptions = this.closeOptions.bind(this);
		this.getSelectedOptionsAsArray = this.getSelectedOptionsAsArray.bind(this);
		this.handleClearAllOptions = this.handleClearAllOptions.bind(this);
		this.onMultiSelect = this.onMultiSelect.bind(this);
		this.renderClearButton = this.renderClearButton.bind(this);
	}
	
	componentDidUpdate (prevProps, prevState) {
		if (!prevState.isOpen && this.state.isOpen) {
			this.scrollIntoView();
			this.onFocus();
		}
		
		if (prevState.isOpen && !this.state.isOpen) {
			this.onBlur();
		}
	};
	
	scrollIntoView () {
		if (!this.props.tree) {
			const selectOptions = this.optionsRef.current;
			
			if (selectOptions.scrollIntoViewIfNeeded) {
				selectOptions.scrollIntoViewIfNeeded(false);
			} else {
				selectOptions.scrollIntoView(false);
			}
		}
	};
	
	toggleOptions = () => {
		if (!this.props.disabled) {
			this.setState(prevState => ({ isOpen: !prevState.isOpen }));
		}
	};
	
	closeOptions () {
		if (!this.props.tree && this.state.isOpen) {
			this.setState({ isOpen: false });
		}
		
	};
	
	renderPlaceholder () {
		if (!isEmpty(this.props.selectedOptions)) return null;
		
		return (
			<div className={'placeholder'}>
				{this.props.placeholder}
			</div>
		);
	};
	
	onSelectOption = selectedOption => {
		if (this.props.onChange) {
			const result = this.props.multi ? this.props.tree ? [...selectedOption] : [...this.props.selectedOptions, selectedOption] : selectedOption;
			this.props.onChange(result);
			this.props.tree ? this.toggleOptions() : null;
		}
		
		if (!this.props.multi) {
			this.closeOptions();
		}
	};
	
	onMultiSelect = selectedOptionArray => {
		const result = [...this.props.selectedOptions, ...selectedOptionArray];
		const unique = uniqBy(result, 'value');
		this.props.onChange(unique);
	};
	
	onRemoveOption = removedOption => {
		const result = this.props.multi
			? this.props.selectedOptions.filter(option => option.value !== removedOption.value)
			: null;
		
		if (this.props.onChange) {
			this.props.onChange(result);
		}
		
		if (!this.state.isOpen && this.props.blurOnRemoveWhileClosed && this.props.onBlur) {
			this.props.onBlur(result);
		}
	};
	
	onFocus = () => {
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	};
	
	onBlur = () => {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	};

	renderOptions = (values) => {
        if (this.props.showValuesCount) {
			const filteredValues = values; // values.filter(item => item[this.props.valueField] !== "-");
            return (
                <div className="input-counted-values">
                    <>
                        {filteredValues.length === 1 && this.renderOption(filteredValues[0])}
                        {filteredValues.length > 1 && <span>({filteredValues.length}) вибрано</span>}
                    </>
                </div>
            );
        }
        return values.map(this.renderOption);
    };
	
	renderOption = option => {
		const { removeDisabled, disabled, showValuesCount } = this.props;
		
		const onClick = event => {
			event.stopPropagation();
			this.onRemoveOption(option);
		};
		
		return (
			<div className={'selected-option'}>
                <span title={option.label}>
                    {option.label}
                </span>
				{option.path && <div className='path'>{option.path}</div>}
				{
					(!removeDisabled && !disabled) &&
					<i className={'icon-times'} onClick={onClick} />
				}
			</div>
		);
	};
	
	getSelectedOptionsAsArray = () => {
		const { selectedOptions, multi } = this.props;
		if (multi) {
			if (Array.isArray(selectedOptions)) {
				return [...selectedOptions];
			}
			return [...selectedOptions];
		} else {
			return isEmpty(selectedOptions) ? [] : [selectedOptions];
		}
	};
	
	handleClearAllOptions (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onChange([]);
	}
	
	renderClearButton () {
		return (
			<button className='btn btn-clear' onClick={this.handleClearAllOptions}>
				<svg className='clear-icon' width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 0.805714L7.19429 0L4 3.19429L0.805714 0L0 0.805714L3.19429 4L0 7.19429L0.805714 8L4 4.80571L7.19429 8L8 7.19429L4.80571 4L8 0.805714Z" fill="#3F70C5"/>
				</svg>
				
				{this.props.t('clear all results')}
			</button>
		);
	}
	
	renderSelectedOptions = () => {
		if (isEmpty(this.props.selectedOptions)) return null;
		const { isOpen } = this.state;
		const { tree } = this.props;
		
		const selectedOptionsClassName = cx('selected-options-wrapper', {
			'open': isOpen,
		});
		
		return (
			<div className={selectedOptionsClassName}>
				{this.renderOptions(this.getSelectedOptionsAsArray())}
				{/*<span className='option-btn'>*/}
                        {/*{isOpen && !tree && this.renderClearButton()}*/}
				{/*</span>*/}
			</div>
		);
	};
	
	render () {
		const { label, required, error, options, tree, inline, valueField, keySelectField, leafField, disabled, selectedOptions, async, propperClassName, ...rest } = this.props;
		const { isOpen } = this.state;
		// const rootClassName = cx('input-element multi-select-search', propperClassName, {
		// 	inline,
		// 	disabled
		// });
		const rootClassName = cx('input-element multi-select-search', propperClassName, {
			inline,
		});
		const innerWrapperClassName = cx('inner-wrapper', {
			'focus': isOpen,
		});
		
		const valueClassName = cx('input-field', {
			'input-field__error': error,
			'focus': isOpen,
			disabled
		});
		
		return (
			<div className={rootClassName}>
				
				<div className='input-label'>
					{label}
					{required && <span className='required-field'>*</span>}
				</div>
				
				{!tree ? <ClickOutsideHolder className='outer-wrapper' onClickOutside={this.closeOptions}>
						<div className={innerWrapperClassName}>
							
							<div className={valueClassName} onClick={this.toggleOptions}>
								{this.renderPlaceholder()}
								{this.renderSelectedOptions()}
								
								{!isOpen && isEmpty(selectedOptions) &&
								<div className={'icon-container'}>
									<i className='icon-down' />
								</div>
								}
							</div>
							
							<OptionsListContainer
								isOpen={isOpen}
								options={options}
								renderClearButton={isOpen && !tree && this.renderClearButton()}
								selectedOptions={this.getSelectedOptionsAsArray()}
								containerRef={this.optionsRef}
								onSelect={this.onSelectOption}
								onMultiSelect={this.onMultiSelect}
								onRemove={this.onRemoveOption}
								valueField={valueField}
								keySelectField={keySelectField}
								leafField={leafField}
								async={async}
								notFilteredOptions={this.props.notFilteredOptions}
								disallow_select_all={rest.disallow_select_all}
							/>
						
						</div>
					</ClickOutsideHolder>
					:
					<div className={innerWrapperClassName}>
						
						<div className={valueClassName} onClick={this.toggleOptions}>
							{this.renderPlaceholder()}
							{this.renderSelectedOptions()}
							{!isOpen && isEmpty(selectedOptions) &&
							<div className={'icon-container'}>
								<i className='icon-down' />
							</div>
							}
						</div>
						
						<OptionsTreeContainer
							isOpen={isOpen}
							options={options}
							selectedOptions={this.getSelectedOptionsAsArray()}
							onSelect={this.onSelectOption}
							onRemove={this.onRemoveOption}
							valueField={valueField}
							keySelectField={keySelectField}
							leafField={leafField}
							onClose={this.toggleOptions}
							async={async}
							{...rest}
					/>
					
					</div>
				}
			</div>
		);
	}
}

MultiSelectWithSearch.defaultProps = {
	selectedOptions: [],
	disabled: false,
	tree: false,
	multi: false,
	inline: false,
	blurOnRemoveWhileClosed: false,
};

MultiSelectWithSearch.propTypes = {
	options: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string
		})),
		PropTypes.object
	]).isRequired,
	tree: PropTypes.bool,
	selectedOptions: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	]),
	disabled: PropTypes.bool,
	label: PropTypes.string,
	error: PropTypes.string,
	required: PropTypes.bool,
	inline: PropTypes.bool,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	multi: PropTypes.bool,
	blurOnRemoveWhileClosed: PropTypes.bool,
	removeDisabled: PropTypes.bool,
};

export default MultiSelectWithSearch;
