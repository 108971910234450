import get from 'lodash/get';
import { findPropertyInArray, isOrganization, searchTree } from 'helpers';
import moment from 'moment';

export const findType = (typeId, parentArr, siblings, treeParam = 'children', valueField = (v) => v.id) => {
	let type;
	let siblingsArr;
	const findTypeById = (id, arr) => (
		arr.forEach(el => {
			if (valueField(el) === id) {
				type = el;
				siblingsArr = arr;
			} else {
				(el[treeParam]) && findTypeById(id, el[treeParam]);
			}
		}));
	findTypeById(typeId, parentArr);
	return (siblings ? siblingsArr : type);
};

export const findParent = (el, arr, treeParam = 'children', valueField = (v) => v.id) => {
	let parent;
	const findParentForElem = (elem, parentArr) => (
		parentArr.forEach(item => {
			if (item[treeParam]) {
				if (item[treeParam].find(child => valueField(child) === valueField(elem))) {
					parent = item;
				} else {
					findParentForElem(elem, item[treeParam]);
				}
			}
		}));
	findParentForElem(el, arr);
	return parent;
};

const getAppealType = (appeal, rootAppealType) => {
	if (!rootAppealType) return null;
	if (appeal.typeId === rootAppealType.id) return null;
	try {
		const getFullLabelPath = (typeFullName = '') => typeFullName.split('\\').filter(Boolean).join(' / ');
		const getCustomerStatus = searchTree(rootAppealType.children, 'children', i => i.id === appeal.typeId);
		
		return {
			id: appeal.typeId,
			fullLabelPath: getFullLabelPath(appeal.typeFullName),
			code: appeal.typeCode,
			// isCustReq: getCustomerStatus.isCustReq,
			isCustReq: getCustomerStatus.requiredObjects.includes("CUSTOMER")
		};
	}
	catch (error) {
		console.error(error);
	}
};

export function initialValues (appeal, appealType, formParams, appealFeedback, customer, t, formValues) {
	const resolveDate = appeal.resolveDate.resolveDate;
	const priority = { value: appeal.priority.id, label: appeal.priority.name };
	let status = formValues ? formValues.status : appeal.status;
	const destinationId = appeal.destinationId;
	const destinationName = appeal.destinationName;

	const isDestination = ![null, undefined, ""].includes(destinationId) && ![null, undefined, ""].includes(destinationName);
	
	const type = getAppealType(appeal, appealType);
	
	return {
		...appeal,
		resolveDate,
		type,
		status,
		timer: get(appeal.timer, 'date'),
		nextState: get(appeal.timer, 'nextState.code'),
		priority,
		blocks: initializeDynamicParams(formParams),
		destinationFeedback: appealFeedback && customer ? initializeAppealFeedBack(appealFeedback, customer, appeal) : [],
		destination: isDestination ? [destinationId] : ['']
	};
}

export function initializeDynamicParams (formParams) {

	const blocks = {};
	
	if (formParams && Array.isArray(formParams.blocks)) {
		formParams.blocks.forEach(block => {
			if (Array.isArray(block.widgets)) {
				block.widgets.forEach(widget => {
					const specificWidgetTypes = ['file', 'fileMultiple', 'date', 'datetime', 'ajaxCombo'];
					const defaultValue = widget.values.some( item => item.key === widget.defaultValue) ?
						widget.defaultValue :
						null;
					let value = widget.savedValue || defaultValue;
				
					// set common fields
					if (!specificWidgetTypes.includes(widget.widgetType)) {
						blocks[widget.key] = value;
					}
					
					// set to simple input fields
					if (widget.widgetType === 'input'){
						blocks[widget.key] = widget.savedValue || widget.defaultValue;
					}
					
					// set date fields
					if (widget.widgetType === 'date' || widget.widgetType === 'datetime') {
						
						if (moment(value, 'x', true).isValid()) {
							value = parseInt(value);
						}
						
						value = value ? moment(value) : value;
						
						blocks[widget.key] = value;
					}
					
					// set file fields
					if (widget.widgetType === 'file' || widget.widgetType === 'fileMultiple') {
						if (value && Array.isArray(widget.values)) {
							blocks[widget.key] = value.split('|').map(fileId => {
								const fileObject = widget.values.find(value => value.key === fileId);
								return fileObject && {
									name: fileObject.value,
									id: fileObject.key,
								};
							}).filter(Boolean);
						} else {
							blocks[widget.key] = [];
						}
					}
					
					// set ajaxCombo fields
					if (widget.widgetType === 'ajaxCombo') {
						if (value && Array.isArray(widget.values) && widget.values.length > 0) {
							const foundValue = widget.values.find(option => option.key === value);
							// value is found
							if (foundValue) {
								blocks[widget.key] = {
									value: foundValue.key,
									label: foundValue.value,
									key: foundValue.key
								};
							} else {
								// fallback to provided single value or "-"
								const isSingle = widget.values.length === 1;
								const single = widget.values[0];
								blocks[widget.key] = {
									value: isSingle ? single.key : null,
									label: isSingle ? single.value : "-",
									key: isSingle ? single.key : null
								};
							}
						}
					}

					// set to multiselect widget
					if (widget.widgetType === 'multiselect'){
						blocks[widget.key] = widget.savedValues || widget.defaultValue;
					}
				});
			}
		});
	}
	return blocks;
}

function initializeAppealFeedBack (appealFeedback, customer) {
	let initialContacts = [];
	
	const foundCustomer = findPropertyInArray(appealFeedback, 'type', 'customer');
	
	if (foundCustomer) {
		initialContacts = [...initialContacts, ...foundCustomer.selectedContacts];
	}
	
	if (isOrganization(customer.party.partyType)) {
		const gerContactPersons = appealFeedback.filter(i => (i.type === 'contactPerson' && foundCustomer.id !== i.id));
		if (gerContactPersons.length > 0) {
			gerContactPersons.forEach(contact => {
				initialContacts = [...initialContacts, ...contact.selectedContacts];
			});
		}
	}
	
	return [...new Set(initialContacts)];
}

export function dynamicParamsFilter(block, shouldRenderField, orderFilter) {
	if (orderFilter && typeof orderFilter === 'function') {
		return (
			block.action !== "del" &&
			block.widgets &&
			block.widgets.length &&
			orderFilter(block.order) &&
			block.widgets.some(widget => shouldRenderField(widget.key))
		);
	}
    return (
        block.action !== "del" &&
        block.widgets &&
        block.widgets.length &&
        block.widgets.some(widget => shouldRenderField(widget.key))
    );
}