import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import CommentItem from './CommentItem';
import CommentForm from './CommentForm';
import { setEditableComment, getComments, resetComments, updateCommentSuccess } from 'actions/comments';
import { getFormValues, destroy } from 'redux-form';

import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import { KeyCodes } from 'constants/index';

const LOCK_SOURCE = "comment";

const mapStateToProps = (state, props) => {
	const {match: {params: {appealId}}} = props;
	
	return {
		comments: state.comments.comments,
		commentsLoading: state.comments.commentsLoading,
		requestedForAppeal: state.comments.requestedForAppeal,
		formValues: getFormValues(`comment-form-${appealId}`)(state),
	}
};

const mapDispatchToProps = (dispatch) => ({
	setEditableComment: (comment, appealId) => dispatch(setEditableComment(comment, appealId)),
	getComments: (requestData, appealId) => dispatch(getComments(requestData, appealId)),
	resetComments: () => dispatch(resetComments()),
	destroyForm: (name) => dispatch(destroy(name)),
	updateCommentSuccess: (appealId) => dispatch(updateCommentSuccess(appealId)),
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class Comments extends React.Component {
	constructor (props){
		super(props);
		const isOpenForm = !!props.formValues;
		this.setIsCommentDeleting = this.setIsCommentDeleting.bind(this);
		this.state = {
			formIsOpen: isOpenForm,
			isCommentDeleting: false,
		};
	}
	
	componentDidMount () {
		this.props.resetComments();
		this.requestComments();

		if (this.state.formIsOpen) {
			// lock appeal form on starting comment editing
			this.props.unlockAppealForm(LOCK_SOURCE);
		}
	}
	
	componentDidUpdate (prevProps) {
		if (this.props.match.params.appealId !== prevProps.match.params.appealId) {
			const isOpenForm = !!this.props.formValues;
			
			this.setState({formIsOpen: isOpenForm});
			this.props.resetComments();
			this.requestComments();
		}
	}

	// componentWillUnmount() {
    //     // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
	// 	this.props.removeAppealLockSource(LOCK_SOURCE);
    // }
	
	requestComments = () => {
		const appealId = this.props.match.params.appealId;
		
		const requestData = {
			appealId,
			isChild: 0,
			limit: 25
		};
		
		return this.props.getComments(requestData, appealId);
	};
	
	handleEditComment = commentId => {
		const {setEditableComment, comments, match:{params:{ appealId }}} = this.props;
		const editableComment = commentId ? this.props.comments.find(comment => comment.id === commentId) : null;
		setEditableComment(editableComment, appealId);
		// lock appeal form on starting comment editing
		this.props.unlockAppealForm(LOCK_SOURCE);
		this.openForm();
	};
	
	openForm = () => this.setState({ formIsOpen: true });
	
	closeForm = () => {
		const { destroyForm, match : {params: { appealId } }, updateCommentSuccess } = this.props;
		this.setState({ formIsOpen: false });
		
		destroyForm(`comment-form-${appealId}`);
		updateCommentSuccess(appealId);
		// remove lock source once form is closed (once source array is empty - appeal will be unlocked)
		this.props.removeAppealLockSource(LOCK_SOURCE);
	};
	
	handleKeyDown = event => {
		if (event.keyCode === KeyCodes.ESCAPE) {
			this.closeForm();
		}
	};

	setIsCommentDeleting = value => this.setState({isCommentDeleting: value});
	
	render () {
		const { formIsOpen, isCommentDeleting } = this.state;
		const { t, commentsLoading, comments, match : {params: { appealId } } } = this.props;
		const footerClassName = cx('comment-footer', !formIsOpen && 'withButton');
		
		return (
			<div className='tabContent'>
				{isCommentDeleting && <Loader />}
				<div className={cx('commentContent')}>
					<div className={cx('commentContentScrollbox')}>
						<div className='comments'>
							{
								commentsLoading
									? <Loader withContainer={true} />
									: comments.map(comment => (
										<CommentItem
											key={comment.id}
											handleEditComment={this.handleEditComment}
											{...comment}
										/>
									))
							}
						</div>
					</div>
				</div>
				
				<div className={footerClassName}>
					{formIsOpen ? (
						<CommentForm
							form={`comment-form-${appealId}`}
							refresh={this.requestComments}
							handleEditComment={this.handleEditComment}
							toggleForms={this.toggleForms}
							closeForm={this.closeForm}
							setIsCommentDeleting={this.setIsCommentDeleting}
							isLockedByOther={this.props.isLockedByOther}
						/>
					) : (
						<button className={cx('btn-save', { disabled: this.props.isLockedByOther })} disabled={this.props.isLockedByOther} onClick={() => this.handleEditComment(null)}>
							{t('comments.add')}
						</button>
					)}
				</div>
			</div>
		);
	}
};

