import React, { Fragment } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { Field } from 'redux-form';

import { StreetModes } from 'constants/index';
import baseService from 'services/BaseService';
import StreetField from './StreetField/index';

class AddressForm extends React.Component {
	
	constructor (props) {
		super(props);
		
		this.state = {
			settlementOptions: [],
			streetOptions: [],
			buildingNumberOptions: [],
			zipCodeOptions: [],
		};
		
		this.linkedFields = ['settlement', 'street', 'buildingNumber', 'zipCode'];
	}
	
	onStreetFieldRefReady = streetFieldRef => {
		this.streetFieldRef = streetFieldRef;
	};
	
	onZipCodeFieldRefReady = zipCodeFieldRef => {
		this.zipCodeRef = zipCodeFieldRef;
	};
	
	onBuildingNumberFieldRefReady = buildingNumberFieldRef => {
		this.buildingNumberRef = buildingNumberFieldRef;
	};
	
	resetLinkedFields = field => {
		const fieldsForReset = this.linkedFields.slice(this.linkedFields.indexOf(field) + 1);
		fieldsForReset.forEach(field => this.props.change(field, null));
		
		const nextState = {};
		fieldsForReset.reduce((acc, field) => {
			acc[`${field}Options`] = [];
			return acc;
		}, nextState);
		this.setState({ ...nextState });
	};
	
	// *** onChange handlers ***
	
	onInputChange = field => inputValue => {
		
		const query = !inputValue && this.props[field] ? this.props[field].label : inputValue;
		
		switch (field) {
		case 'settlement':
			this.onSettlementChange(query);
			break;
		case 'street':
			this.onStreetChange(query);
			break;
		case 'buildingNumber':
			this.props.change(`${field}Input`, inputValue);
			break;
		
		case 'zipCode':
			this.props.change(`${field}Input`, inputValue);
			this.onZipCodeChange(query);
			break;
		}
		
		return inputValue;
	};
	
	onSettlementChange = debounce(query => {
		if (!query || query.length < 3) return;
		return this.requestSettlements(query)
			.then(response => {
				if (response.success) {
					this.setState({ settlementOptions: this.convertSettlementResponse(response.result) });
				} else {
					this.setState({ settlementOptions: [] });
				}
			});
	}, 500);
	
	onStreetChange = debounce(query => {
		if (!this.props.settlement || !query || query.length < 2 || get(this.props, 'street.label') === query) return;
		
		return this.requestStreets(query, this.props.settlement.id)
			.then(response => {
				if (response.success) {
					this.setState({ streetOptions: this.convertStreetResponse(response.result) });
					
				} else {
					this.setState({ streetOptions: [] });
				}
			});
	}, 500);
	
	onZipCodeChange = debounce(query => {
		
		const isQueryShort = !query || query.length < 5;
		const areSettlementAndStreetEmpty = !this.props.settlement && !this.props.street;
		
		if (isQueryShort || !areSettlementAndStreetEmpty || this.props.mode !== StreetModes.AUTO) {
			return;
		}
		
		return this.requestZipCodesSearch(query)
			.then(response => {
				if (response.success && response.result && response.result.length > 0) {
					this.setAddressFoundByIndex(response.result);
				}
			});
	}, 300);
	
	setAddressFoundByIndex = results => {
		const streetOptions = this.convertStreetResponse(results[0].street);
		const settlement = this.convertSettlementResponse(results.slice(0, 1))[0];
		
		const focusStreetInput = () => this.streetFieldRef.current.input.input.focus();
		
		this.props.change('street', streetOptions[0]);
		this.props.change('settlement', settlement);
		this.setState({ settlementOptions: [], streetOptions }, focusStreetInput);
	};
	
	convertSettlementResponse = result => result.map(settlement => ({
		label: settlement.name,
		value: settlement.fullName,
		id: settlement.id,
		fullName: settlement.fullName
	}));
	
	convertStreetResponse = result => result.map(street => ({
		label: street.name,
		value: street.id,
		type: street.type
	}));
	
	convertBuildingResponse = result => result.map(building => ({ label: building.name, value: building.id }));
	
	convertZipCodeResponse = result => result.map(zipCode => ({ label: zipCode.zip_code, value: zipCode.id }));
	
	// *** Requests ***
	
	requestSettlements = query => {
		const data = { query, page: 1, start: 0, limit: 10 };
		return baseService.get('search_settlement', { data });
	};
	
	requestStreets = (query, settlementId) => {
		const data = { query, settlementId, page: 1, start: 0, limit: 10 };
		return baseService.get('search_street', { data });
	};
	
	requestBuildings = streetId => {
		const data = { streetId };
		return baseService.get('search_building', { data });
	};
	
	requestZipCodes = (streetId, buildId) => {
		const data = { streetId, buildId };
		return baseService.get('search_zip_code', { data });
	};
	
	requestZipCodesSearch = query => {
		const data = { query };
		return baseService.get('search_settlement_by_zip_code', { data });
	};
	
	getSettlementOptionComponent = () => props => {
		return (
			<div className={cx(props.className, 'settlement-option')} onClick={() => props.selectValue(props.option)}>
				<div className={'settlementLabel'}>{props.option.label}</div>
				<div className={'settlementFullName'}>{props.option.fullName}</div>
			</div>
		);
	};
	
	handleChange = (event, newValue, previousValue, fieldName) => {
		this.resetLinkedFields(fieldName);
		
		if (fieldName === 'street' && newValue) {
			this.requestBuildings(newValue.value)
				.then(response => {
					this.setState({ buildingNumberOptions: response.success ? this.convertBuildingResponse(response.result) : [] });
				});
		}
		
		if (fieldName === 'buildingNumber' && newValue) {
			this.requestZipCodes(this.props.street.value, newValue.value)
				.then(response => {
					this.setState({ zipCodeOptions: response.success ? this.convertZipCodeResponse(response.result) : [] });
				});
		}
	};
	
	onStreetModeChange = newStreetMode => this.props.changeMode(newStreetMode);
	
	getFieldPropsWithOptions = fieldProps => {
		
		switch (fieldProps.name) {
		case 'settlement':
			return {
				...fieldProps,
				options: this.state.settlementOptions,
				onInputChange: this.onInputChange('settlement'),
				optionComponent: this.getSettlementOptionComponent(),
				onChange: this.handleChange
			};
		case 'street':
			return {
				mode: this.props.mode,
				onModeChange: this.onStreetModeChange,
				fieldConfig: fieldProps,
				options: this.state.streetOptions,
				onInputChange: this.onInputChange('street'),
				onChange: this.handleChange,
				changeField: this.props.change,
				id: 'streetInput',
				openOnFocus: true,
				onRefReady: this.onStreetFieldRefReady,
				required: fieldProps.required
			};
		case 'buildingNumber':
			return {
				...fieldProps,
				onRefReady: this.onBuildingNumberFieldRefReady,
				options: this.state.buildingNumberOptions,
				onChange: this.handleChange,
				onInputChange: this.onInputChange('buildingNumber'),
				onBlurResetsInput: false,
				onCloseResetsInput: false
			};
		case 'zipCode':
			return {
				...fieldProps,
				onRefReady: this.onZipCodeFieldRefReady,
				options: this.state.zipCodeOptions,
				onInputChange: this.onInputChange('zipCode'),
				onBlurResetsInput: false,
				onCloseResetsInput: false
			};
		
		default:
			return fieldProps;
		}
	};
	
	renderInputField = (fieldProps, index) => {
		// if (fieldProps.hidden) {
        //     return (
        //         <div className="hidden">
        //             <Field key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} required={false} />
        //         </div>
        //     );
        // }
		switch (fieldProps.name) {
		case 'street':
			if (fieldProps.hidden) {
				return (
					<div className="hidden">
						<StreetField tabIndex={index} key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} required={false} />
					</div>
				);
			}
			return <StreetField tabIndex={index} key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} />;
		default:
			if (fieldProps.hidden) {
				return (
					<div className="hidden">
						<Field key={fieldProps.name} {...this.getFieldPropsWithOptions(fieldProps)} required={false} />
					</div>
				);
			}
			return <Field key={fieldProps.name} tabIndex={index} {...this.getFieldPropsWithOptions(fieldProps)} />;
		}
	};
	
	render () {
		const { addrConf } = this.props;
		// console.log({addrConf});
		return (
			<Fragment>
				{addrConf.map(this.renderInputField)}
			</Fragment>
		);
	}
}

export default AddressForm;
