import React, { Fragment } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { formValueSelector, getFormValues } from 'redux-form';
import Popup from 'components/Popup';

import { connect } from 'react-redux';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';


const mapStateToProps = (state, props) => {
	const formSelector = formValueSelector(props.formName);
	return {
		...formSelector(state, 'status', 'resolution'),
		statusList: state.calendar.eventStatuses
	}
};

@withTranslation()
@withRouter
@connect(mapStateToProps)
class StatusSelect extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			statusPopupIsOpen: false,
			resolutionPopupIsOpen: false,
			selectedFirstLevel: null,
		};
	}
	
	selectStatus = status => e => {
		e.stopPropagation();
		
		if (status.resolution.length) {
			this.setState({
				selectedFirstLevel: status,
				statusPopupIsOpen: false,
				resolutionPopupIsOpen: true
			});
		} else {
			this.setState({
				selectedFirstLevel: null,
				statusPopupIsOpen: false,
				resolutionPopupIsOpen: false
			});
			this.saveChanges(status);
		}
	};
	
	selectStatusOnly = event => {
		event.stopPropagation();
		this.closePopups(event);
		this.saveChanges(this.state.selectedFirstLevel);
	};
	
	saveChanges = (status, resolution) => {
		this.props.change('status', status);
		this.props.change('resolution', resolution || null);
	};
	
	clearValue = event => {
		this.props.change('status', null);
		this.props.change('resolution', null);
		this.closePopups(event);
	};
	
	selectResolution = resolution => event => {
		event.stopPropagation();
		this.setState({ resolutionPopupIsOpen: false });
		this.saveChanges(this.state.selectedFirstLevel, resolution);
	};
	
	closePopups = event => {
		event.stopPropagation();
		this.setState({ statusPopupIsOpen: false, resolutionPopupIsOpen: false });
	};
	
	openStatusPopup = event => {
		event.stopPropagation();
		if (!this.state.disabled) {
			this.setState({ statusPopupIsOpen: true, resolutionPopupIsOpen: false });
		}
	};
	
	renderStatusLink = status => (
		<div key={status.code} className={'popup-status-link-wrapper'} onClick={this.selectStatus(status)}>
			<div className={`popup-status-link ${status.code}`}>
				{status.name}
			</div>
			{status.resolution.length > 0 && <i className='icon-arrow-right' />}
		</div>
	);
	
	renderResolutionLink = resolution => (
		<div
			key={resolution.id}
			className='popup-link'
			onClick={this.selectResolution(resolution)}
		>
			{resolution.title}
		</div>
	);
	
	render () {
		const { statusList, status, resolution, t } = this.props;
		const { selectedFirstLevel } = this.state;
		const statusCode = get(status, 'code', '') || get(status, 'value', '');
		const statusName = get(status, 'name', '') || get(status, 'label', '');
		const title = get(resolution, 'title') || get(status, 'resolution.title');
		const wrapperClassName = cx(
			'status-select-wrapper',
			'inline',
		);
		return (
			<div className={wrapperClassName} onClick={this.openStatusPopup}>
				{
					statusName &&
					<span className={`sticker sticker-${statusCode}`}>{statusName}</span>
				}
				{
					title &&
					<Fragment>
						<span className='status-divider'>|</span>
						<span className='resolution'>{title}</span>
					</Fragment>
				}
				
				<i className='icon-down' />
				
				{
					this.state.statusPopupIsOpen && statusList &&
					<Popup
						place='status-popup left'
						onClickOutside={this.closePopups}
					>
						{statusList.map(this.renderStatusLink)}
					</Popup>
				}
				
				{
					this.state.resolutionPopupIsOpen &&
					<Popup
						onClickOutside={this.closePopups}
						place='status-popup left'
					>
						<div className='resolutionBackLink' onClick={this.openStatusPopup}>
							<i className='icon-arrow-left' />
							<div key={'back'} className='popup-link'>{t('back')}</div>
						</div>
						<div
							key={selectedFirstLevel.code}
							className={`popup-status-link bold ${selectedFirstLevel.code}`}
							onClick={this.selectStatusOnly}
						>
							{selectedFirstLevel.name}
						</div>
						
						{selectedFirstLevel.resolution.map(this.renderResolutionLink)}
					</Popup>
				}
			</div>
		);
	}
}

StatusSelect.propTypes = {
	statusList: PropTypes.array,
	status: PropTypes.object,
	resolution: PropTypes.object,
	change: PropTypes.func,
	disabled: PropTypes.bool
};

export default StatusSelect;

