import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// import InputField from 'components/Common/InputField';
import {Input} from 'ui-core-dashboard';
import TextArea from 'components/Common/TextArea';
import ComboBox from 'components/Common/ComboBox';
import CheckBox from 'components/Common/CheckBox';
import Radio from 'components/Common/Radio';
import Rank from 'components/Common/Rank';
import FileInput from 'pages/Appeal/DinamicParamsForm/components/FileInput';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import DatePicker from 'components/Common/DatePicker';
import ComboBoxCreatable from 'components/Common/ComboBoxCreatable';
import AjaxCombo from 'components/Common/AjaxCombo';
import { withTranslation } from 'react-i18next';

@withTranslation()
export class DinamicParamsForm extends Component {

	getWidgetOptions = values => values.map(value => ({
		...value,
		label: value.value,
		value: value.key,
		id: value.key
	}));
	
	createWidget = widget => {
		const { key, widgetType } = widget;
		const label = widget.title;
		let required = widget.isReq;
		const { checkRestrictions, order, unlockAppealForm, blockKey, selectedAppealAction, t, skipRestriction, viewMode, currentId } = this.props;
		let disabled = viewMode ? true : skipRestriction ? false : checkRestrictions(key);
		// let disabled = !skipRestriction ? checkRestrictions(key) : false;
		const actionWidgetOptions = selectedAppealAction && selectedAppealAction.find(i => i.key === key);
		const defaultProps ={
			name: `block.${key}`,
			id: key,
			required: required,
			label: label,
			placeholder: widget.placeholder,
			component: Input,
			disabled: disabled,
			onChange: unlockAppealForm,
			order: order,
		};
		if (actionWidgetOptions) {
			required = actionWidgetOptions.isReq;
			disabled = actionWidgetOptions.isDisable;
		}
		
		switch (widgetType) {
			case 'input':
				return (
					<Field
						{...defaultProps}
						component={Input}
						maxLength='2000'
					/>
				);
			case 'checkbox':
				return (
					<Field
						{...defaultProps}
						component={CheckBox}
					/>
				);
			case 'checkboxToggle':
				return (
					<Field
						{...defaultProps}
						component={CheckBoxToggle}
					/>
				);
			case 'select':
				return (
					<Field
						{...defaultProps}
						options={this.getWidgetOptions(widget.values)}
						placeholder={widget.placeholder || t('dontSelect')}
						component={ComboBox}
						refKey={widget.refKey}
						formValues={this.props.formValues}
					/>
				);
			case 'date':
				return (
					<Field
						{...defaultProps}
						component={DatePicker}
						popperClassName='appealDate'
						onlyDate={true}
					/>
				);
			case 'datetime':
				return (
					<Field
						{...defaultProps}
						component={DatePicker}
						popperClassName='appealDate'
					/>
				);
			case 'multiline':
				return (
					<Field
						{...defaultProps}
						component={TextArea}
						textAreaClassName='multiline'
						maxLength='2000'
					/>
				);
			
			case 'combo':
				return (
					<Field
						{...defaultProps}
						options={this.getWidgetOptions(widget.values)}
						placeholder={widget.placeholder || 'Не вибрано'}
						component={ComboBoxCreatable}
						t={t}
					/>
				);
			
			case 'searchbox':
				return (
					<Field
						{...defaultProps}
						options={this.getWidgetOptions(widget.values)}
						placeholder={widget.placeholder || 'Не вибрано'}
						component={ComboBox}
						searchable={true}
					/>
				);
			case 'ajaxCombo':
				return (
					<Field
						{...defaultProps}
						optionsUrl='appeal_form_ajax'
						placeholder={widget.placeholder || 'Не вибрано'}
						component={AjaxCombo}
						attributeKey={key}
						blockKey={blockKey}
					/>
				);
			case 'file':
			case 'fileMultiple':
				if (currentId || viewMode) {
					return <Field
						{...defaultProps}
						objectType='KNOWLEDGE_BASE'
						placeholder={widget.placeholder}
						component={FileInput}
						currentId={currentId}
						multiple
					/>;
				}
				else return (
					<div className='input-wrapper'>
						<div className='input-element'>
							<label className='input-label'>Файл</label>
							<div className='input-field-container'>
								<span className='inpup-field'>необхідно зберігти документ</span>
							</div>
						</div>
					</div>);
			case 'radio':
				return <Field
					{...defaultProps}
					placeholder={widget.placeholder}
					component={Radio}
					description={widget.description}
					list={widget.values}
				/>;
			case 'rank':
				return <Field
					{...defaultProps}
					placeholder={widget.placeholder}
					component={Rank}
					disabled={disabled}
					description={widget.description}
					values={[...widget.values].reverse()}
				/>;
			case 'multiselect': {
				return (
					<Field
						{...defaultProps}
						options={this.getWidgetOptions(widget.values)}
						placeholder={widget.placeholder || 'Не вибрано'}
						component={ComboBox}
						t={this.props.t}
						multi
					/>
					
				);
			}
			
			default:
				return null;
		}
	};
	
	renderWidget = widget => {
		const shouldRender = this.props.shouldRenderField && this.props.shouldRenderField(widget.key);
		if (!shouldRender && !this.props.skipRestriction) return null;
		
		return (
			<div key={widget.key} className='inputWrapper'>
				{this.createWidget(widget)}
			</div>
		);
	};
	
	render () {
		const { widgets } = this.props;
		return (
			<Fragment>
				{widgets.map(this.renderWidget)}
			</Fragment>
		);
	}
}

DinamicParamsForm.propTypes = {
	handleSubmit: PropTypes.func,
	widgets: PropTypes.array,
	blockKey: PropTypes.string
};
