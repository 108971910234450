import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({
    children,
    className = 'root-portal',
    rootId,
    el = 'div',
    dataFieldKey,
    position,
}) => {
    const [container] = useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        const element = document.createElement(el);
        if (dataFieldKey) {
            element.setAttribute('data-field-key', dataFieldKey);
        }
        return element;
    });

    useEffect(() => {
        if (position) {
            const { left, top, right, bottom } = position;
            if (left) {
                container.style.left = `${left}px`;
            }
            if (top) {
                container.style.top = `${top}px`;
            }
            if (right) {
                container.style.right = `${right}px`;
            }
            if (bottom) {
                container.style.bottom = `${bottom}px`;
            }
        }
    }, [position]);

    useEffect(() => {
        container.classList.add(className);
        if (rootId && document.getElementById(rootId)) {
            document.getElementById(rootId).appendChild(container);
        } else {
            document.body.appendChild(container);
        }
        return () => {
            if (rootId && document.getElementById(rootId)) {
                document.getElementById(rootId).removeChild(container);
            } else {
                document.body.removeChild(container);
            }
        };
    }, []);

    return ReactDOM.createPortal(children, container);
};

export default Portal;
