import { subscribeToMessages } from '../actions/index';

const ACTIONS = {
    SET_NOTIFICATION_HISTORY_EVENT_SOURCE_STATE: 'SET_NOTIFICATION_HISTORY_EVENT_SOURCE_STATE',
    ENABLE_NOTIFICATION_HISTORY_STREAM: 'ENABLE_NOTIFICATION_HISTORY_STREAM',
    DISABLE_NOTIFICATION_HISTORY_STREAM: 'DISABLE_NOTIFICATION_HISTORY_STREAM',
    SET_NOTIFICATION_HISTORY_TOTAL: 'SET_NOTIFICATION_HISTORY_TOTAL',
    INCREMENT_NOTIFICATION_HISTORY_TOTAL: 'INCREMENT_NOTIFICATION_HISTORY_TOTAL',
};

const initialState = {
    // enabled: false,
    eventSourceState: 'not-connected', // "not-connected", "connecting", "connected", "error"
    eventSource: null,
    total: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_NOTIFICATION_HISTORY_EVENT_SOURCE_STATE: {
            return { ...state, eventSourceState: action.eventSourceState };
        }

        case ACTIONS.ENABLE_NOTIFICATION_HISTORY_STREAM: {
            return { ...state, enabled: true, eventSourceState: "connected", eventSource: action.eventSource };
        }

        case ACTIONS.DISABLE_NOTIFICATION_HISTORY_STREAM: {
            return { ...state, enabled: false, eventSource: null };
        }

        case ACTIONS.SET_NOTIFICATION_HISTORY_TOTAL: {
            return { ...state, total: action.total };
        }

        case ACTIONS.INCREMENT_NOTIFICATION_HISTORY_TOTAL: {
            return { ...state, total: state.total + 1 };
        }

        default: {
            return state;
        }
    }
};

export const setNotificationHistoryStreamState = (eventSourceState) => ({
    type: ACTIONS.SET_NOTIFICATION_HISTORY_EVENT_SOURCE_STATE,
    eventSourceState,
});

export const toggleNotificationHistoryStream = () => (dispatch, getState) => {
    const streamState = getState().notificationHistory.eventSourceState;
    if (["connected", "connecting"].includes(streamState)) {
        dispatch(disableNotificationHistoryStream());
    } else {
        // dispatch(enableNotificationHistoryStream());
        dispatch(subscribeToMessages());
    }
};

export const enableNotificationHistoryStream = (eventSource = null) => {
    return {
        type: ACTIONS.ENABLE_NOTIFICATION_HISTORY_STREAM,
        eventSource,
    };
};

export const disableNotificationHistoryStream = (eventSourceState = 'not-connected') => (
    dispatch,
    getState,
) => {
    const eventSource = getState().notificationHistory.eventSource;
    if (eventSource) {
        eventSource.removeEventListener("error", eventSource.$onErrorListener);
        eventSource.removeEventListener("message", eventSource.$onMessageListener);
        eventSource.close();
        clearInterval(eventSource.$pingInterval);
    }
    dispatch(setNotificationHistoryStreamState(eventSourceState));
    dispatch({ type: ACTIONS.DISABLE_NOTIFICATION_HISTORY_STREAM });
};

export const setNotificationHistoryTotal = (total) => ({
    type: ACTIONS.SET_NOTIFICATION_HISTORY_TOTAL,
    total,
});

export const incrementNotificationHistoryTotal = () => ({
    type: ACTIONS.INCREMENT_NOTIFICATION_HISTORY_TOTAL,
});
