import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import Popup from 'components/Popup';
import styles from 'styles/modules/knowledgeBase.module.scss';
import MessageModal from '../../MessageModal';
import ModalPortal from '../../ModalPortal';
import { useHistory } from 'react-router-dom';
import BaseService from '../../../services/BaseService';
import { Context } from '../index';

function HeaderMenu (props) {
	const { setHistoryPushRequireSaveWithoutChangeConfirm } = props;
	const { t } = useTranslation();
	const requestIssues = useContext(Context);
	const [opened, setOpened] = useState(false);
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const history = useHistory('/issue/:id');
	
	function toggleConfirm () {
		setConfirmIsOpen(!confirmIsOpen);
		setOpened(false);
	}
	
	function togglePopUp () {
		setOpened(!opened);
	}
	
	function closePopup () {
		setOpened(false);
	}
	
	function closeConfirm () {
		setConfirmIsOpen(false);
	}
	
	async function submitDelete () {
		const { success } = await BaseService.delete('issueDelete', { pathParams: { id: props.id } });
		if (success) {
			setHistoryPushRequireSaveWithoutChangeConfirm(false);
			requestIssues();
			history.push('/issue');
			closeConfirm();
		}
	}
	
	return (
		<div className={styles.headerMenu}>
			{
				props.canEdit && (
					<>
						<div className={styles.headerMenuButtonWrapper}>
							<button className={styles.headerMenuButton} onClick={togglePopUp}>
								<i className='icon-kebab-vert' />
							</button>
						</div>
						{opened && (
							<Popup
								onClickOutside={closePopup}
								place={cn(styles.headerPopup)}
							>
								<div onClick={toggleConfirm} className='popup-link danger'>{t(`knowledgeMenu.Remove`)}</div>
							</Popup>
						)}
					</>
				)
			}
			{
				confirmIsOpen ? (
					<ModalPortal
						onClose={closeConfirm}
						className='modal-small'
					>
						<MessageModal
							titleModal={t('filters.titleRemoveModal')}
							contentModalText={t('knowledgeBase.titleRemoveFilter')}
							dangerButton={true}
							dangerButtonText={t('filters.remove')}
							onClickDangerButton={submitDelete}
							secondaryButton={true}
							secondaryButtonText={t('filters.cancel')}
							onClickSecondaryButton={closeConfirm}
						/>
					</ModalPortal>
				) : null
			}
		</div>
	);
}

HeaderMenu.propTypes = {
	options: PropTypes.array,
	t: PropTypes.object,
};


export default HeaderMenu;
