import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'redux-form';
import cx from 'classnames';
import { isOrganization } from 'helpers';

function FormWrapper ({ title, onSubmit, customerType, children }) {
	const formClassName = cx('form-wrapper', isOrganization(customerType) && 'organization');
	return (
		<Form onSubmit={onSubmit} className={formClassName}>
			<header>{title}</header>
			{children}
		</Form>
	);
}

FormWrapper.propTypes = {
	title: PropTypes.string.isRequired,
	onSubmit: PropTypes.func,
	customerType: PropTypes.string
};

export default FormWrapper;
