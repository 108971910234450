import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { RouteWithTabs, buildThreePath } from 'helpers';
import { getFilters } from 'actions/customer';
const EMPTY_FILTER_OPTION = { value: -1, label: '-' };

import settingsService, { SORT_POSTFIX } from 'services/settingsService';

import CalendarContainer from 'components/Common/DatePicker/CalendarContainer';

import {Field, Form, getFormValues, reduxForm} from 'redux-form';
import { ComboBox, Input, DatePicker } from 'ui-core-dashboard';
import MultiSelectWithSearch from 'components/Common/MultiSelectWithSearch/ReduxFormDecorator';
import { renderDefaultFields, renderDateField } from 'components/FiltersForm/helpers';

import { bindActionCreators } from 'redux';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { withTranslation } from 'react-i18next';
import Loader from "components/Loader";

const mapDispatchToProps = dispatch => bindActionCreators({
	getFilters,
}, dispatch);

const mapStateToProps = (state, props) => ({
	form:props.form,
	filterList: state.customer.filterList,
	filterPresetList: state.customer.filterPresetList,
	tabEntity: state.customer.tabEntity,
	isFilterListLoading: state.customer.isFilterListLoading,
	formFilterValues: getFormValues(props.form)(state),
});


function SearchModalFilter (props){
	const { handleSubmit, t, formFilterValues, form, filterList, isFilterListLoading, tabEntity, filterPresetList  } = props;
	
	useEffect(() =>{
		!filterList && props.getFilters(tabEntity);
	},[filterList]);
	
	const defaultPropsDate = {
		saveFormat: 'YYYY-MM-DDTHH:mm', locale: 'uk', popperContainer: CalendarContainer,
		showMonthDropdown: true, showYearDropdown: true
	};
	function getComponentByType (type, search) {
		let components = {
			LIST: ComboBox,
			NUMBER: Input,
			STRING: Input,
			DATE: DatePicker
		};
		
		if (search) {
			components = {
				THREE: MultiSelectWithSearch,
				LIST: MultiSelectWithSearch,
			};
		}
		
		return components[type] || Input;
	}
	
	function convertFieldConfigToProps (fieldConfig) {
		
		const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
		const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
		let options = fieldConfig.type === 'THREE' ? { children: buildThreePath(fieldConfig.dict, false, { children: 'children' }) } : getOptions(fieldConfig.dict || []);
		
		let config = {
			key: fieldConfig.id,
			component: getComponentByType(fieldConfig.type, fieldConfig.search),
			name: fieldConfig.code,
			label: fieldConfig.name,
			type: fieldConfig.type,
			options,
			multi,
			tree: fieldConfig.type === 'THREE',
			errorPlaceholder: true,
			// valueField: 'key',
			valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
            keySelectField: 'key',
			leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			onChange: handleSubmit,
			onBlur: e => e.preventDefault() // prevent trigger form change on blur (with exact same value)
		};
		
		return config;
	}
	
	function onFilterChange (e) {
		console.log(e.target);
	}
	
	function renderFilterSelect () {

		return (
			<ComboBox
				options={filterPresetList.length && filterPresetList.map( ({name, value}) => { return {value: value, label: name } }) }
				label={t('filters.useFilter')}
				name={'applyFilter'}
				value={props.currentFilter || EMPTY_FILTER_OPTION}
				onChange={onFilterChange}
				placeholder={t('dontSelect')}
				noResultsText={t('noResultsFound')}
			/>
		);
	}
	
	function renderField (fieldConfig) {
		let fieldProps = convertFieldConfigToProps(fieldConfig);
		
		if (fieldConfig.type === 'DATE') {
			fieldProps = { ...fieldProps, ...defaultPropsDate };
			return renderDateField(t, fieldConfig, fieldProps, formFilterValues);
		}
		
		return (
			<Field {...fieldProps} />
		);
	}


	return (
		<div className={cx('filter-form-wrapper', 'ordering-component-ui-core-wrapper')}>
			<div className='filter-head'> {t('filters.filters')}
			</div>
			<div className='scrollbox-modal'>
				<div className='scrollbox-content'>
					
						<Form
							className={'form-wrapper'}
							onSubmit={handleSubmit}
						>
							{isFilterListLoading && <Loader withContainer/>}
							{!isFilterListLoading && filterPresetList && renderFilterSelect()}
							{!isFilterListLoading && filterList && filterList.map(renderField)}
						</Form>
				</div>
			</div>
		</div>
	)
	
}
SearchModalFilter.propTypes = {
	handleSubmit: PropTypes.func,
	topicCode: PropTypes.string,
	typeCode: PropTypes.string
};

export default reduxForm({ destroyOnUnmount: true })(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchModalFilter))
);

