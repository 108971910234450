import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cx from "classnames";
import Loader from "components/Loader";
import KnowledgeBaseItem from "./KnowledgeBaseItem";
import KnowledgeBaseList from "./KnowledgeBaseList";
import {
    setBreadcrumbs,
    getKnowledgeBaseItem,
    getKnowledgeBase,
    updateSearchQuery,
    enableSearchMode,
    disableSearchMode,
    clearKnowledgeBaseItem,
    resetKnowledgeBase,
} from "actions/knowledgeBase";

import { createCheckPropsFunction, flatThree } from "helpers";
import { KeyCodes } from "constants/index";
import "./styles.scss";

const mapStateToProps = state => ({
    knowledgeBase: state.knowledgeBase.knowledgeBase,
    knowledgeBaseLoading: state.knowledgeBase.knowledgeBaseLoading,
    breadcrumbs: state.knowledgeBase.breadcrumbs,
    searchMode: state.knowledgeBase.searchMode,
    selectedItem: state.knowledgeBase.selectedItem,
    selectedItemLoading: state.knowledgeBase.selectedItemLoading,
    query: state.knowledgeBase.query,
    requestedForAppeal: state.knowledgeBase.requestedForAppeal,
});

const mapDispatchToProps = dispatch => ({
    setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
    getKnowledgeBase: (requestData, appealId) => dispatch(getKnowledgeBase(requestData, appealId)),
    getKnowledgeBaseItem: requestData => dispatch(getKnowledgeBaseItem(requestData)),
    updateSearchQuery: newQuery => dispatch(updateSearchQuery(newQuery)),
    enableSearchMode: () => dispatch(enableSearchMode()),
    disableSearchMode: () => dispatch(disableSearchMode()),
    clearKnowledgeBaseItem: () => dispatch(clearKnowledgeBaseItem()),
    resetKnowledgeBase: () => dispatch(resetKnowledgeBase()),
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class KnowledgeBase extends React.Component {
    constructor(props) {
        super(props);
        this.homeBreadcrumb = { text: props.t("knowledgeBase.homeBreadcrumb"), index: null, key: "home" };
        this.searchBreadcrumb = { text: props.t("searchResult"), index: null, key: "search" };

        this.shouldMakeInitialRequest = this.shouldMakeInitialRequest.bind(this);
        this.requestKnowledgeBase = this.requestKnowledgeBase.bind(this);
        this.requestKnowledgeBaseItem = this.requestKnowledgeBaseItem.bind(this);
        this.handleChangeNode = this.handleChangeNode.bind(this);
        this.handleClickBreadcrumb = this.handleClickBreadcrumb.bind(this);
        this.findPath = this.findPath.bind(this);
        this.forEachTree = this.forEachTree.bind(this);
        this.buildLinks = this.buildLinks.bind(this);
        this.buildSearchLinks = this.buildSearchLinks.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onQueryChange = this.onQueryChange.bind(this);
        this.renderBreadcrumb = this.renderBreadcrumb.bind(this);

        this.state = {
            searchedQuery: "",
        };
    }

    componentDidMount() {
        this.props.resetKnowledgeBase();
        this.requestKnowledgeBase();
    }

    componentDidUpdate(prevProps, prevState) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if (isPropChanged("match.params.appealId")) {
            if (this.shouldMakeInitialRequest()) {
                this.props.resetKnowledgeBase();
                this.requestKnowledgeBase();
            }
        }
    }

    shouldMakeInitialRequest() {
        return !this.props.requestedForAppeal || this.props.match.params.appealId !== this.props.requestedForAppeal;
    }

    requestKnowledgeBase(query = "") {
        const requestData = {
            objectId: null,
            objectType: "REQUEST_TYPE",
            node: "root",
            query,
        };

        this.setState({ searchedQuery: query });

        return this.props.getKnowledgeBase(requestData, this.props.match.params.appealId);
    }

    requestKnowledgeBaseItem(itemId) {
        const requestData = {
            type: "leaf",
            id: itemId,
            page: 1,
            start: 0,
            limit: 25,
        };

        return this.props.getKnowledgeBaseItem(requestData);
    }

    handleChangeNode(node, selectedItemId) {
        if (this.props.selectedItemLoading) {
            return;
        }
        const { breadcrumbs } = this.props;
        if (selectedItemId) this.requestKnowledgeBaseItem(selectedItemId);
        this.props.setBreadcrumbs([...breadcrumbs, node]);
    }

    handleClickBreadcrumb(item, index) {
        if (this.props.selectedItemLoading) {
            return;
        }
        const newBreadcrumbs = this.props.breadcrumbs.slice(0, index + 1);

        this.props.setBreadcrumbs(newBreadcrumbs);

        // when click on root breadcrumb - load whole knowledge base
        if (item.key === this.homeBreadcrumb.key) {
            this.props.resetKnowledgeBase();
            this.requestKnowledgeBase();
        }
        if (this.props.selectedItem) this.props.clearKnowledgeBaseItem();
    }

    findPath(a, obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (a === obj[key]) {
                    return key;
                } else if (obj[key] && typeof obj[key] === "object") {
                    const path = this.findPath(a, obj[key]);
                    if (path) {
                        return key + "." + path;
                    }
                }
            }
        }
    }

    forEachTree(item, callback, level = 1) {
        for (const key in item) {
            if (item[key].leaf) {
                callback(key, item[key], level);
            } else {
                const result = item[key].result;
                if (result) {
                    const parent = Object.assign({}, item[key]);
                    delete parent.result;
                    for (const p in result) {
                        result[p].parent = parent;
                    }
                }

                this.forEachTree(result, callback, level + 1);
            }
        }
    }

    buildLinks(data, breadcrumbs = []) {
        if (!breadcrumbs || !breadcrumbs.length) {
            return data;
        }

        const path = breadcrumbs.filter(
            breadcrumb => ![this.homeBreadcrumb.index, this.searchBreadcrumb.index].includes(breadcrumb.index)
        );

        if (!path.length) {
            return data;
        }

        let res = data[path[0].index];
        for (let index = 1; index < path.length; index++) {
            res = res.result[path[index].index];
        }
        return res ? res.result : res;
    }

    buildSearchLinks(data, query, breadcrumbs) {
        if (!this.props.searchMode) {
            return data;
        }
        // if (!breadcrumbs || !breadcrumbs.length) {
        //     return data;
        // }

        const path = breadcrumbs.filter(
            breadcrumb => ![this.homeBreadcrumb.index, this.searchBreadcrumb.index].includes(breadcrumb.index)
        );
        // no folder is selected
        const articles = flatThree(data, [], { children: "result" });
        if (!path.length) {
            return articles.map(article => {
                // delete article.result;
                return article;
            });
        }

        // handle folder
        let res = articles[path[0].index];
        for (let index = 1; index < path.length; index++) {
            res = res.result[path[index].index];
        }
        return res ? res.result : res;
    }

    onSearch(event) {
        const query = event.target.value;
        if (event.keyCode === KeyCodes.ENTER) {
            if (query.length >= 3) {
                this.requestKnowledgeBase(query);
                this.props.enableSearchMode();
                this.props.setBreadcrumbs([this.homeBreadcrumb, this.searchBreadcrumb]);
                this.props.clearKnowledgeBaseItem();
            }
        }

        if (query.length === 0 && this.props.searchMode) {
            this.props.resetKnowledgeBase();
            this.requestKnowledgeBase();
        }
    }

    onQueryChange(event) {
        this.props.updateSearchQuery(event.target.value);
    }

    renderBreadcrumb(item, index) {
        const isLast = index === this.props.breadcrumbs.length - 1;

        const isLoading = this.props.selectedItemLoading;

        const breadcrumbClassName = isLast ? "link-knowledge-base-active" : "link-knowledge-base";
        const onBreadCrumbClick = () => this.handleClickBreadcrumb(item, index);

        return (
            <span
                key={index}
                title={item.text}
                className={cx(breadcrumbClassName, { disabled: !isLast && isLoading })}
                onClick={onBreadCrumbClick}
            >
                {item.text}
            </span>
        );
    }

    render() {
        const {
            breadcrumbs,
            selectedItem,
            query,
            t,
            knowledgeBaseLoading,
            selectedItemLoading,
            knowledgeBase,
            unlockAppealForm,
        } = this.props;
        const { searchedQuery } = this.state;

        const isEmptySelectedItemDynParams =
            selectedItem && selectedItem.dynParams && selectedItem.dynParams.length === 0;
        const isEmptyOnlyDynParams =
            isEmptySelectedItemDynParams && selectedItem && Object.keys(selectedItem).length === 1;
        return (
            <div className="tabContent">
                <div className="tabHeader tab-search-wrapper">
                    <input
                        type="text"
                        className="searchInput"
                        placeholder={t("knowledgeBase.searchInputPlaceholder")}
                        onKeyUp={this.onSearch}
                        onChange={this.onQueryChange}
                        value={query}
                    />
                    <i className="icon-search2"></i>
                </div>
                {knowledgeBaseLoading ? (
                    <Loader withContainer={true} />
                ) : (
                    <div className="scrollbox">
                        <div className="scrollbox-content">
                            <div className="knowledge-base-content">
                                <span className="breadcrumbs">{breadcrumbs.map(this.renderBreadcrumb)}</span>
                                <div className="knowledge-base-info">
                                    {selectedItem && !isEmptyOnlyDynParams ? (
                                        selectedItemLoading ? (
                                            <Loader withContainer={true} />
                                        ) : (
                                            <KnowledgeBaseItem
                                                item={selectedItem}
                                                unlockAppealForm={unlockAppealForm}
                                                resetAppealType={this.props.resetAppealType}
                                                shouldRenderField={this.props.shouldRenderField}
                                            />
                                        )
                                    ) : (
                                        <KnowledgeBaseList
                                            links={
                                                // query
                                                searchedQuery
                                                    ? this.buildSearchLinks(knowledgeBase, searchedQuery, breadcrumbs)
                                                    : this.buildLinks(knowledgeBase, breadcrumbs)
                                            }
                                            unlockAppealForm={unlockAppealForm}
                                            onClick={this.handleChangeNode}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

KnowledgeBase.defaultProps = {
    breadcrumbs: [],
};

KnowledgeBase.propTypes = {
    links: PropTypes.array,
    breadcrumbs: PropTypes.array,
    setBreadcrumbs: PropTypes.func,
    selectedItem: PropTypes.bool,
    searchMode: PropTypes.bool,
    query: PropTypes.string,
    t: PropTypes.object,
    knowledgeBase: PropTypes.array,
    unlockAppealForm: PropTypes.func,
};

export default KnowledgeBase;
