import React, { useState, useEffect } from 'react';
import {reduxForm, Field, getFormValues, change, formValueSelector} from 'redux-form';
import FormBlock from '../../Appeal/Form/FormBlock';
import cx from 'classnames';
import 'pages/Appeal/styles.scss';
import baseService from '../../../services/BaseService';

import { DatePicker, Input, ComboBox } from 'ui-core-dashboard';
import validate from '../CalendarDetail/validate';
import { useTranslation } from 'react-i18next';
import {connect} from "react-redux";
import ComboBoxUniversal from '../../../components/Common/ComboBoxUniversal';
import DatePickerCalendar from '../../../components/Common/DatePickerCalendar';
import DateTimePickerCalendarRange from '../../../components/Common/DatePickerCalendar/DatePickerCalendarRange';
import {buildThreePath} from "helpers";
import {getFilterAttrsSuppose} from '../../../modules/calendar/actions';
import CheckBoxGroup from 'components/Common/CheckBoxGroup';
import moment from "moment/moment";
import get from "lodash/get";
import {useLocation} from 'react-router-dom';

import { renderDateField } from "components/FiltersForm/helpers";


function CalendarFilterForm (props) {
	const {
		valid, pristine, submitting, handleSubmit, submitForm, formValues, filterFields, getFilterAttrsSuppose, change,
		filterRangeDate, isGridView
	} = props;
	const { t } = useTranslation();
	const [asyncState, setAsyncState] = useState({});
	const filterStartDate = moment(get(filterRangeDate, 'startDate')).format('YYYY-MM-DD');
	const filterEndDate = moment(get(filterRangeDate, 'endDate')).format('YYYY-MM-DD');
	const location = useLocation();

	useEffect( () =>{
		getFilterAttrsSuppose({data: {code: 'FTOP_CLEVENT'}});
	},[]);
	
	useEffect( () =>{
		if (isGridView) {
				// console.log({isGridView});
				localStorage.setItem('prevFilterStartDate', filterStartDate);
				localStorage.setItem('prevFilterEndDate', filterEndDate);
		}
	},[]);
	
	// dont know the purpose of such block, why changeFilters on location change?
	// useEffect(() => {
	// 	if(location && location.pathname === '/calendar' && isGridView){
	// 		console.log({props, location, startDateLS: localStorage['prevFilterStartDate'], endDateLS: localStorage['prevFilterEndDate'] });
	// 		props.changeFilterDates({startDate: moment(localStorage['prevFilterStartDate']), endDate: moment(localStorage['prevFilterEndDate'])});
	// 	}
	// }, [location]);
	
	const asyncParams = (search, code, id) => {
		if (!search) {
			setAsyncState({ [code]: [] });
		} else {
			baseService.post('async_search_attributes', { data: { id, search }, jsonType: true }).then((resp) => {
				setAsyncState({
					[code]: resp.result.map(field => ({
						label: field.value || '',
						value: field.id || ''
					}))
				});
			});
		}
	};
	
	const getComponentByType = (type, search, multiset) => {
		let components = {
			LIST_CHECKBOX: CheckBoxGroup,
			LIST: ComboBoxUniversal,
			NUMBER: Input,
			STRING: Input,
			DATE: DatePicker,
			DATECAL: DatePickerCalendar
		};
		
		if(multiset && type === 'DATECAL'){
			components = {
				DATECAL: DateTimePickerCalendarRange
			}
		}
		if (search) {
			components = {
				THREE: ComboBoxUniversal,
				LIST: ComboBoxUniversal,
			};
		}
		
		return components[type] || Input;
	};
	
	const selectAllHandlerInput = (callback, options) => {
		callback(options);
	};
	
	const clearAllHandlerInput = (inputName) => {
		change(inputName, null);
	};
	
	const convertFieldConfigToProps = fieldConfig => {
		const getOptions = dict => dict.map((field, index) => ({ label: field.value.trim(), value: field.key, index }));
		const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
		let options = [];
		if( fieldConfig.type === 'THREE' ){
			options = { children: buildThreePath(fieldConfig.dict, false, { children: 'children' }) }
		}
		else if (fieldConfig.dict && multi){
			let dict_options = getOptions(fieldConfig.dict);
			if (formValues && formValues[fieldConfig.code]){
				dict_options = dict_options.filter( item => {
					return !formValues[fieldConfig.code].includes(item.value)
				} )
			}
			options = [
				{ notVal: true, label: t('clear all results'), value: 'clear', action: () => clearAllHandlerInput(fieldConfig.code) },
				{ notVal: true, label: t('select all results'), value: 'select', action: (callback) => selectAllHandlerInput(callback, dict_options) },
				...getOptions(fieldConfig.dict)]
		}
		
		const searchable = !!fieldConfig.search;
		const isMultiset = fieldConfig.type === 'THREE';
		const title = fieldConfig.type === 'THREE' && fieldConfig.name;
		const clearValueText = t('clear');
		const clearAllText = t('clearValue');
		let config = {
			key: fieldConfig.id,
			component: getComponentByType(fieldConfig.type, fieldConfig.search, fieldConfig.multiset_allowed === 'Y'),
			name: fieldConfig.code,
			label: fieldConfig.name,
			title,
			type: fieldConfig.type,
			options,
			multi,
			tree: fieldConfig.type === 'THREE',
			errorPlaceholder: true,
			// valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
			// leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
            keySelectField: 'key',
            leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			searchable,
			isMultiset,
			clearValueText,
			clearAllText,
			placeholder: '-',
			noResultsText:t('noResultsFound'),
			onBlur:(e) => {e.preventDefault(); return false;}
		};
		
		if (fieldConfig.dict_search) {
			const {disallow_select_all} = fieldConfig;
			
			config = {
				...config,
				disallow_select_all,
				notFilteredOptions:true,
				dict_search:true,
				options: asyncState[fieldConfig.code] || [],
				async: {
					callback: asyncParams,
					code: fieldConfig.code,
					id: fieldConfig.id,
				}
			};
		}
		return config;
	};
	
	const renderField = fieldConfig => {
		const fieldProps = convertFieldConfigToProps(fieldConfig);
		if (fieldConfig.type === 'DATE') {
			// return renderDateField(fieldConfig, fieldProps);
			return renderDateField(t, fieldConfig, fieldProps, formValues);
		}
		return (
			<Field {...fieldProps} />
		);
	};
	
	// const renderDateField = (fieldConfig, fieldProps) => {
	// 	if (fieldConfig.def_condition === 'BTW') {
	// 		const fromDateProps = {
	// 			...fieldProps,
	// 			name: `${fieldConfig.code}@FROM`,
	// 			label: `${fieldProps.label} (З)`,
	// 			key: `${fieldConfig.id}@FROM`,
	// 		};
	// 		const toDateProps = {
	// 			...fieldProps,
	// 			name: `${fieldConfig.code}@TO`,
	// 			label: `${fieldProps.label} (По)`,
	// 			key: `${fieldConfig.id}@TO`,
	// 		};
			
	// 		return [
	// 			<Field {...fromDateProps} />,
	// 			<Field {...toDateProps} />
	// 		];
	// 	}
		
	// 	return <Field {...fieldProps} />;
	// };
	
	return (
		<form onSubmit={handleSubmit(submitForm)}
			  className={cx('calendar-filter-form', 'ordering-component-ui-core-wrapper')}>
			<FormBlock>
				
				{filterFields && filterFields.map(renderField)}
			
			</FormBlock>
			
		</form>
	);
}
const mapStateToProps = (state) => ({
	formValues: getFormValues('calendar-filter-form')(state),
	filterFields: state.calendar.filterAttrsResults,
	filterRangeDate: formValueSelector('calendar-filter-form')(state, 'CLEVENT_RANGE_START_DATE'),
	
});

const mapDispatchToProps = dispatch => ({
	getFilterAttrsSuppose: (param) => dispatch(getFilterAttrsSuppose(param)),
	changeFilterDates: (value) => dispatch(change('calendar-filter-form', 'CLEVENT_RANGE_START_DATE', value))
	
});

export default connect(mapStateToProps, mapDispatchToProps) (reduxForm({
	form: 'calendar-filter-form',
	validate,
	destroyOnUnmount: false,
})(CalendarFilterForm));
