import {
	DYNAMIC_DASHBOARD_REQUEST,
	DYNAMIC_DASHBOARD_REQUEST_SUCCESS,
	DYNAMIC_DASHBOARD_CHART_REQUEST_SUCCESS,
	DYNAMIC_DASHBOARD_SET_VIEW,
	DYNAMIC_DASHBOARD_VIEW_LIST_REQUEST_SUCCESS,
	DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST,
	DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_SUCCESS,
	DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_FAILURE,
	DYNAMIC_DASHBOARD_PERIOD_PRESET_UPDATE,
	DYNAMIC_DASHBOARD_PERIOD_UPDATE_BETWEEN,
	DYNAMIC_DASHBOARD_FILTERS_REQUEST,
	DYNAMIC_DASHBOARD_FILTERS_REQUEST_SUCCESS,
	DYNAMIC_DASHBOARD_FILTERS_REQUEST_FAILURE,
	DYNAMIC_DASHBOARD_REQUEST_FAILURE,
	DYNAMIC_DASHBOARD_FILTERS_CLEAR,
	DYNAMIC_DASHBOARD_APPEAL_TYPES_REQUEST,
	DYNAMIC_DASHBOARD_APPEAL_TYPES_REQUEST_FAILURE,
	DYNAMIC_DASHBOARD_APPEAL_TYPES_REQUEST_SUCCESS,
} from 'constants/actions';

/*#######   Initial state   #######*/

const initialState = {
	dashboardViews: [],
	activeView: null,
	periodList: [],
	filterList: [],
	rowsData: [],
	isDashboardLoading: false,
	isFilterListLoading: false,
	isResetFiterForm: false,
	presetPeriod: null,
	betweenDates: [],
};

/*#######   Helper functions   #######*/

/*#######   Reducer   #######*/

export default (state = initialState, { type, action }) => {
	
	switch (type) {
		
		case DYNAMIC_DASHBOARD_REQUEST:
			return { ...state, isDashboardLoading: true };
		case DYNAMIC_DASHBOARD_SET_VIEW: {
			const currentActiveView = state.activeView;
			let newRowsData = state.rowsData;
			// do not clear rowsData if the view is the same (same tab was click)
			if (currentActiveView && action.id !== currentActiveView.id) {
				// empty rowsData on view change
				newRowsData = [];
			}
			return { ...state, activeView: action, rowsData: newRowsData };
		}
		
		case DYNAMIC_DASHBOARD_PERIOD_PRESET_UPDATE:
			return { ...state, presetPeriod: action };
		
		/********  Dashboard request  ********/
		
		
		case DYNAMIC_DASHBOARD_REQUEST_SUCCESS:
			return { ...state, isDashboardLoading: false, rowsData: action };
		
		case DYNAMIC_DASHBOARD_VIEW_LIST_REQUEST_SUCCESS:
			return { ...state, dashboardViews: action.dashboardViews };
		
		case DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_SUCCESS:
			return { ...state, periodList: action.periodList };
		
		case DYNAMIC_DASHBOARD_PERIOD_UPDATE_BETWEEN:
			return { ...state, betweenDates: action.betweenDates };

		/********  Dashboard filter object update  ********/
		
		case DYNAMIC_DASHBOARD_FILTERS_REQUEST:
			return {
				...state,
				isFilterListLoading: true,
			};
		
		case DYNAMIC_DASHBOARD_FILTERS_REQUEST_SUCCESS:
			return {
				...state,
				isFilterListLoading: false,
				filterList: action,
			};
		
		case DYNAMIC_DASHBOARD_REQUEST_FAILURE:
		case DYNAMIC_DASHBOARD_FILTERS_REQUEST_FAILURE:
			return {
				...state,
				isFilterListLoading: false,
				filterList: null,
			};
		
		// case DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_SUCCESS:
		// 	return { ...state, isFilterListLoading: true };
		//
		// case DYNAMIC_DASHBOARD_PERIOD_LIST_REQUEST_FAILURE:
		// 	return { ...state, isFilterListLoading: true };
		//

		case DYNAMIC_DASHBOARD_FILTERS_CLEAR:
			return { ...state, isResetFiterForm: action, isDashboardLoading: false };
		
		default:
			return state;
	}
}
