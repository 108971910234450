import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import ReactSVG from 'react-svg';

import Popup from '../Popup';
import { openCreateNewCustomerModal, showCreateMenu, hideCreateMenu, openCreateNewOrderModal } from 'actions/ui';
import { toggleBlockAddingPopup, closeBlockAddingPopup } from 'actions/tabs';
import { createNewAppeal } from 'modules/appeal/actions';
import { calculateSearchNumber, cropTabTypeToIconName } from 'helpers';
import { withTranslation } from 'react-i18next';
import { get } from "lodash";

import './styles.scss';
import permissions, { checkPermissions } from 'config/permissions';

function mapStateToProps (state) {
	return {
		create: state.ui.create,
		tabs: state.tabs,
		isAddingActive: state.tabs.isAddingActive,
		blockAddingPopupIsShow: state.tabs.blockAddingPopupIsShow,
		// tabsHistory: state.tabs.tabsHistory,
		creatingAppeal: state.appeal.creating,
		callInteractionId: state.call.callInteractionId,
		incomePhoneNumber: state.call.incomePhoneNumber,
		isCustomerCreationAllowed: checkPermissions(permissions.CustomerOperations.action_createCustomer),
		isOrderCreate: checkPermissions(permissions.OrderOperations.action_createOrder),
		suggestedCustomers: state.call.customers,
	};
}

const mapDispatchToProps = {
	showCreateMenu,
	hideCreateMenu,
	openCreateNewCustomerModal,
	createNewAppeal,
	closeBlockAddingPopup,
	toggleBlockAddingPopup,
	openCreateNewOrderModal
};

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class NewTab extends React.Component {
	
	state = {
		popupIsOpen: false
	};
	
	togglePopup = () => {
		const { isAddingActive, toggleBlockAddingPopup } = this.props;
		if (isAddingActive) {
			this.setState(prevState => ({ popupIsOpen: !prevState.popupIsOpen }));
		} else {
			toggleBlockAddingPopup();
		}
	};
	
	closePopup = (e) => {
		const { closeBlockAddingPopup } = this.props;
		this.setState({ popupIsOpen: false });
		return this.createButton.contains(e.target) ? null : closeBlockAddingPopup();
	};
	
	openSearch = (e) => {
		e.stopPropagation();
		this.togglePopup();
		const { history, tabs, isAddingActive } = this.props;
		
		if (isAddingActive) {
			const hash = calculateSearchNumber(tabs);
			history.push(`/search/appeal/${hash}/`);
		}
	};
	
	renderButton = item => {
		if (!item || (item && !item.allowed)) return null;
		
		return (
			<button
				className='btn-text text-no-wrap'
				onClick={item.handleClick}
				key={item.title}
			>
				{item.title}
			</button>
		);
	};
	
	getButtonsConfig = (t) => {
		const { incomePhoneNumber, callInteractionId, createNewAppeal, history } = this.props;
		const buttons = [];
		
		async function createAppeal (interactionId, appealRequestData) {
			const data = appealRequestData ? { appealRequestData } : undefined;
			const appeal = await createNewAppeal(data, interactionId);
			if (appeal) history.push(`/appeals/${appeal.id}/`);
		}
		
		if (this.props.isCustomerCreationAllowed) {
			buttons.push({
				title: t('tabNames.customer'),
				handleClick: () => this.props.openCreateNewCustomerModal({ openNewTab: true }),
				allowed: this.props.isCustomerCreationAllowed
			});
		}
		
		if (incomePhoneNumber && callInteractionId) {
			const appealRequestData = { customerId: null, contactPersonId: null};
			const suggestedCustomers = this.props.suggestedCustomers;
			if (suggestedCustomers && suggestedCustomers.length === 1) {
				const suggestedCustomer = suggestedCustomers[0];
				const contactPersonId = get(suggestedCustomer, "appl.contactPersonId");
				const customerId = get(suggestedCustomer, "customer.customerId");
				if (contactPersonId) {
					appealRequestData.contactPersonId = contactPersonId || null;
				}
				if (customerId) {
					appealRequestData.customerId = customerId || null;
				}
			}
			buttons.push({
				title: `${t('appealTo')} ${incomePhoneNumber}`,
				handleClick: () => { createAppeal(callInteractionId, appealRequestData); },
				allowed: this.props.user.operations.includes(permissions.AppealOperations.action_createAppeal)
			});
		}
		
		if (this.props.user.operations.includes(permissions.AppealOperations.action_createAppeal)) {
			buttons.push({
				title: t('tabNames.appeal'),
				handleClick: () => { createAppeal(undefined); },
				allowed: this.props.user.operations.includes(permissions.AppealOperations.action_createAppeal)
			});
		}
		
		if (this.props.isOrderCreate) {
			buttons.push({
				title: t('tabNames.order'),
				handleClick: () => this.props.openCreateNewOrderModal({ openNewTab: true }),
				allowed: this.props.isOrderCreate,
			});
		}
		
		return buttons;
	};
	
	translateName = (name, displayedName) => {
		const preparedName = displayedName;
		
		if (!displayedName){
			const names = ['customer', 'person', 'order', 'service','search', 'resource'];
			const firstPart = name.split('_')[0];
			if (firstPart === 'search') {
				return this.props.t(`tabNames.${firstPart}`);
			}
			if (names.indexOf(firstPart) !== -1) {
				return name.split('_').slice(1).join('_');
			}
		}
		return preparedName;
	};
	
	renderTabHistoryItem = (tab, index) => {
		const icon = cropTabTypeToIconName(tab.type);
		const typeTitle =  this.props.t(`tabNames.${icon[0]}`);
		const itemText = this.translateName(tab.type, tab.displayedName);
		
		return (
			<Link className='new-tab-item' to={tab.url} key={index} title={typeTitle}>
				<ReactSVG svgClassName='tab-icon' src={`/data/svg/navigation/${icon}.svg`} />
				<span className='new-tab-item-text' title={itemText}>{itemText}</span>
			</Link>
		);
	};
	
	render () {
		const { blockAddingPopupIsShow, creatingAppeal, t, tabsEmpty, callInteractionId } = this.props;
		const { popupIsOpen } = this.state;
		const addButtonStyle = cx('icon-menu-cross add-tab-button', { 'disabled': creatingAppeal });
		
		return (
			<div className='btn-create' onClick={this.togglePopup} ref={(node) => (this.createButton = node)}>
				<button disabled={creatingAppeal} className={addButtonStyle} />
				{
					!creatingAppeal && popupIsOpen &&
					<Popup
						place={cx('new-tab', { 'first-tab': tabsEmpty, 'min-w-210': callInteractionId })}
						onClickOutside={this.closePopup}
					>
						{
							this.getButtonsConfig(t).length > 0 ?
								<Fragment>
									{this.getButtonsConfig(t).map(this.renderButton)}
									<hr className='new-tab-hr' />
								</Fragment> : null
						}
						<button className='new-tab-item' onClick={this.openSearch}>
							<i className='icon-search2' />
							<span className='new-tab-item-text'>
								{t('newSearch')}
							</span>
						</button>
						{/* {
							tabsHistory.length > 0 ?
								<Fragment>
									<hr className='new-tab-hr' />
									<div className='new-tab-item-list'>
										{tabsHistory.map(this.renderTabHistoryItem)}
									</div>
								</Fragment> : null
						} */}
					</Popup>
				}
				
				{
					blockAddingPopupIsShow &&
					<Popup
						place={cx('new-tab', { 'first-tab': tabsEmpty })}
						onClickOutside={this.closePopup}
					>
						<p>{t('blockNewSearch.newSearchBlocked')}</p>
						<Link
							to={'#'}
							className='new-tab-buttom-list-item'
						>
							<div>{t('blockNewSearch.closePopup')}</div>
						</Link>
					</Popup>
				}
			</div>
		);
	}
}

NewTab.propTypes = {
	// ownProps
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// from connect
	showCreateMenu: PropTypes.func,
	hideCreateMenu: PropTypes.func,
	openCreateNewCustomerModal: PropTypes.func,
	isAddingActive: PropTypes.bool,
	isCustomerCreationAllowed: PropTypes.bool
};
