import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeMessageModal } from 'actions/ui';
import styles from 'styles/modules/modal.module.scss';
import InputField from 'components/Common/InputField';
import cx from 'classnames';

const mapDispatchToProps = dispatch => ({
	closeMessageModal: () => dispatch(closeMessageModal())
});

@connect(null, mapDispatchToProps)
class MessageModal extends React.Component {
	
	state = {
		name: ''
	};
	
	onClickPrimary = () => {
		const { onClickPrimaryButton, contentModal } = this.props;
		
		if (contentModal) {
			onClickPrimaryButton(this.state.name);
			this.setState({ name: '' });
		} else {
			onClickPrimaryButton();
		}
	};
	
	onFilterChange = event => this.setState({ name: event.target.value });
	
	render () {
		const {
			titleModal,
			contentModalText,
			primaryButton,
			primaryButtonText,
			secondaryButton,
			secondaryButtonText,
			onClickSecondaryButton,
			dangerButton,
			dangerButtonText,
			onClickDangerButton,
			contentModal,
			contentModalDesc,
			danger
		} = this.props;
		
		return (
			<div className={styles.modalWrapper}>
				<div className={cx(styles.modalHeader, { [styles.modalHeaderDanger]: danger })}>
					<div className={styles.modalTitle}>
						{danger && <i className='icon-error' />} {titleModal}
					</div>
				</div>
				<div className={styles.modalContent}>
					{contentModalText && <strong className='heading'>{contentModalText}</strong>}
					{contentModalDesc && <span className='desc'>{contentModalDesc}</span>}
					{contentModal ?
						<InputField label='Назва фільтр' onChange={this.onFilterChange} />
						: null
					}
					{
						primaryButton &&
						<button className='btn btn-primary' onClick={this.onClickPrimary}>
							{primaryButtonText}
						</button>
					}
					{
						dangerButton &&
						<button className='btn btn-danger' onClick={onClickDangerButton}>
							{dangerButtonText}
						</button>
					}
					{
						secondaryButton &&
						<button className='btn btn-with-border' onClick={onClickSecondaryButton}>
							{secondaryButtonText}
						</button>
					}
				</div>
			</div>
		);
	}
}

MessageModal.protTypes = {
	closeMessageModal: PropTypes.func
};

export default MessageModal;
