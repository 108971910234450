import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { debounce } from "lodash";

import { withTranslation } from "react-i18next";
import baseService from "services/BaseService";
import Loader from "components/Loader/index";

import CustomerCard from "./CustomerCard";

import "./styles.scss";

function getConvertedSearchParams(data, filterSet, tableFilters) {
    const query = Object.keys(data)
        .map(key => data[key].value)
        .join(" ");
    const params = { data: { query, filterSet, ...tableFilters } };
    return params;
}

// const LIMIT = 10;

const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 48;
const MIN_CARD_HEIGHT = 56;

// const INIT_SEARCH_DATA = { total: 0, result: [] };
const INIT_SEARCH_DATA = { result: [] };

// calculate limit based on parentRef to always display more items to enable the scroll
function getInitTableFilters(parentRef) {
    const { height } = parentRef.getBoundingClientRect();
    const availableHeight = Math.floor(height - HEADER_HEIGHT - FOOTER_HEIGHT);
    const limit = Math.floor(availableHeight / MIN_CARD_HEIGHT);
    // console.log({ height, availableHeight, limit });
    return { page: 1, start: 0, limit: limit + 1 };
}

const SearchPreview = props => {
    const { t, searchPreviewValues, filterSet, parentRef, onCardSelect, onUpdate } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState(INIT_SEARCH_DATA);
    // const [lastSearchIsEmpty, setLastSearchIsEmpty] = useState(false); // if last search was empty - do not trigger "blinking", once there was some data displayed before - show block and spinnerм
    const [lastSearchIsEmpty, setLastSearchIsEmpty] = useState(true); // if last search was empty - do not trigger "blinking", once there was some data displayed before - show block and spinner
    const [tableFilters, setTableFilters] = useState(getInitTableFilters(parentRef));
    const [scrollDimensions, setScrollDimensions] = useState(null);

    // const [emptySearchQueries, setEmptySearchQueries] = useState([]); // perhaps it is not needed anymore

    const currentSearchParams = useMemo(() => {
        return getConvertedSearchParams(searchPreviewValues, filterSet, tableFilters);
    }, [searchPreviewValues, filterSet, tableFilters]);

    const debouncedSetScrollDimensions = useCallback(
        debounce(dimensions => setScrollDimensions(dimensions), 100),
        []
    );

    const customerSearchContentRef = useRef(null);

    const isSearchDataReady = !!(searchData && searchData.result && searchData.result.length);

    // const currentQuery = currentSearchParams.data.query;
    // const isEmptyCurrentQuery = emptySearchQueries.find(query => currentQuery.includes(query));

    // do not render window if total is 0,
    // or if there are no results before and currently
    // and new search query is longer than previous
    // and it starts with the same string
    // or last search was empty - do not trigger "blinking"
    // const isPreviewHidden = searchData.total === 0 || isEmptyCurrentQuery || lastSearchIsEmpty;
    const isPreviewHidden = searchData.total === 0 || lastSearchIsEmpty;


    const handleScroll = event => {
        const { scrollTop, scrollHeight } = customerSearchContentRef.current;
        const { height } = customerSearchContentRef.current.getBoundingClientRect();
        debouncedSetScrollDimensions({
            scrollTop: Math.floor(scrollTop),
            scrollHeight: Math.floor(scrollHeight - height - MIN_CARD_HEIGHT / 2),
        });
    };

    function fetchSearchPreview(params) {
        setIsLoading(true);
        baseService
            .post("search", params)
            .then(response => {
                setIsLoading(false);
                const { result, total, success } = response;
                if (success) {
                    // if (total === 0) {
                    //     const isEmptyQueryExist = emptySearchQueries.find(query => params.data.query.includes(query));
                    //     if (!isEmptyQueryExist) {
                    //         setEmptySearchQueries(prev => [...prev, params.data.query]);
                    //     }
                    // }
                    setLastSearchIsEmpty(total === 0);
                    setSearchData(prev => ({ result: [...prev.result, ...result], total }));
                }
            })
            .catch(err => {
                setIsLoading(false);
                console.error("SearchPreview::fetchSearchPreview: Error ", err);
            });
    }

    useEffect(() => {
        setSearchData(INIT_SEARCH_DATA);
        if (tableFilters.page !== 1) {
            setTableFilters(getInitTableFilters(parentRef));
        }
    }, [searchPreviewValues]);

    useEffect(() => {
        fetchSearchPreview(currentSearchParams);
    }, [currentSearchParams]);

    useEffect(() => {
        if (scrollDimensions) {
            const { scrollTop, scrollHeight } = scrollDimensions;
            if (scrollTop >= scrollHeight && !isLoading && searchData.total !== searchData.result.length) {
                setTableFilters(prev => ({ page: prev.page + 1, start: prev.start + prev.limit, limit: prev.limit }));
            }
        }
    }, [scrollDimensions]);

    useEffect(() => {
        if (onUpdate && typeof onUpdate === "function") {
            onUpdate({ ...searchData, isLoading, isHidden: isPreviewHidden });
        }
    }, [searchData, isLoading]);

    // do not render window if total is 0,
    // or if there are no results before and currently
    // and new search query is longer than previous
    // and it starts with the same string
    if (isPreviewHidden) {
        return null;
    }

    return (
        <div className="customer-search-preview">
            <header className="customer-search-preview__header">{t("selectExistingCard")}</header>
            {isLoading && <Loader loaderContainerClass="customer-search-loader" />}
            <div className="customer-search-preview__content" ref={customerSearchContentRef} onScroll={handleScroll}>
                {isSearchDataReady &&
                    searchData.result.map(data => (
                        <CustomerCard data={data} onCardSelect={onCardSelect} key={data.id} />
                    ))}
            </div>
            {isSearchDataReady && (
                <footer className="customer-search-preview__footer">
                    {t("foundAccordingToEnteredData")}: {searchData.result.length} / {searchData.total}
                </footer>
            )}
        </div>
    );
};

export default withTranslation()(SearchPreview);
