import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from 'ui-core-dashboard';
import GRIDS from '../config/grids';
import { getGridData, init, setApi, setFields, setData, reset } from '../reducers/grid';
import { getRequest } from '../actions/orders';
import { getLanguage } from '../util/i18n';
import baseService from '../services/BaseService';
import Expander from '../components/Expander';
import CheckBoxGrid from 'components/Common/CheckBoxGrid';
import moment from 'moment/moment';
import ReactSVG from 'react-svg';
import { getFormattedSLA, getGridsStorageFields } from '../helpers';
import _ from 'lodash';

const mapStateToProps = (state) => ({
    grid: state.grid,
    gridFields: state.user.settings.gridFields || {},
});

// const gridWithConfigKeys = ['get_appeals', 'customer_calendar_events', 'calendar_events', "service", "task", "resource", "service_catalog_service", "contracts", "get_service_catalogue_objects_for_link", "customer", "get_bunits_BUSINESSUNIT", "get_bunits_EMP", "get_bunits_SEARCH_PERS"];
// const gridTopicsWithConfig = ['INTERACTION_REQUEST', 'FTOP_CLEVENT_CUSTOMER_SITE', 'FTOP_CLEVENT', 'CLEVENT', "SERVICE", "RESOURCE", "TASK", "SERVICE_CATALOG_SERVICE", "CONTRACTS", "SERVICE_CATALOG_LINK", "CUSTOMER", "BUSINESSUNIT", "EMP", "SEARCH_PERS"];

const GridWrapper = (props) => {
    const {
        tuner,
        gridParams = {},
        initUpdate = true,
        gridSearch,
        onStart = () => {},
        onFinish = () => {},
        grid,
        gridFields,
        match,
        request,
        initGrid,
        updateApiGrid,
        updateDataGrid,
        updateFieldsGrid,
        resetGrid,
        destroyOnUnmount,
        handleRowCheck,
        checkRowType,
        preSelectedRowId
    } = props;

    const { t } = useTranslation();
    const initApi = { ...tuner(match.params) };
    const gridKey = `${initApi.key}_${initApi.path || initApi.extra || ''}`;
    const gridConf = `${initApi.key}_${initApi.path || ''}`;
    

    // part of logic for adding "selected" class to row which is equals to selectedRowId prop
    const [isRowManuallySelected, setIsRowManuallySelected] = useState(false);
    const [autoSelectedRowId, setAutoSelectedRowId] = useState(null);
    const isGridResultAvailable = useMemo(() => {
        return grid && grid[gridKey] && grid[gridKey].result && grid[gridKey].result.success;
    }, [grid]);

    const {
        configKey,
        configCode,
        configTabCode,
        configUrlKey,
        expanderSource,
        expanderUrlKey,
        expanderActionsUrlKey,
    } = initApi;
    // let mapConfigKey = gridConf;

    // if (initApi.configKey) {
    // 	configKey = `${initApi.configKey}_${initApi.path || ''}`;
    //     mapConfigKey = `${initApi.configKey}_${initApi.path || ''}`;
    // }
    let { api, params, result, update } = getGridData(grid, gridKey);


    function handleSelectItem(...args) {
        setIsRowManuallySelected(true);
        return gridParams.selectItem(...args);
    };

    if (preSelectedRowId) {
        params.selectItem = handleSelectItem;
    } else {
        params.selectItem = gridParams.selectItem;
    }

    // params.selectItem = gridParams.selectItem;
    // params.selectItem = handleSelectItem;

    const gridDefaultId = gridParams.defaultId;

    // console.log({ configKey, configCode });

    const [gridData, setGridData] = useState(null);
    const gridDynamicConfig = JSON.parse(localStorage.getItem(`config_${configKey}_${configCode}`));
    const gridDynamicMapConfig = JSON.parse(
        localStorage.getItem(`config_map_${configKey}_${configCode}`),
    );

    const getFields = (conf, saved) => {
        const fields = {};

        if (saved) {
            Object.keys(saved).forEach((k) => {
                if (conf[k]) {
                    fields[k] = { ...conf[k], ...saved[k], ...{ component: conf[k].component } };
                }
            });
        }

        Object.keys(conf).forEach((k) => {
            if (!fields[k]) fields[k] = { ...conf[k] };
        });

        return fields;
    };

    const getDynamicFields = (conf, saved, params, gridMap) => {
        const handleCheck = (checked, params) => {
            const {
                data: { detailObjectId, detailObjectType, regnum },
            } = params;
            // onCheck({ object_id: detailObjectId, object_type:detailObjectType, regnum, checked });
            // console.log({ object_id: detailObjectId, object_type:detailObjectType, regnum, checked });
        };

        let fields = {};
        if (saved) {
            Object.keys(saved).forEach((k) => {
                if (conf[k]) {
                    fields[k] = { ...conf[k], ...saved[k], ...{ component: conf[k].component } };
                }
            });
        }
        Object.values(conf).forEach(({ dataSource, dataValue, backgroundColor, viewDataType }) => {
            if (!fields[dataSource]) {
                fields[dataSource] = { ...conf.find((it) => it.dataSource === dataSource) };
            }
            const validDate = (date) => moment(date).isValid();
            switch (fields[dataSource].viewDataType) {
                case 'asCheckbox':
                    return (fields[dataSource] = {
                        ...conf.find((it) => it.dataSource === dataSource),
                        component: (params) => (
                            <CheckBoxGrid
                                type={checkRowType}
                                params={params}
                                onChange={handleRowCheck}
                            />
                        ),
                    });
                case 'asDateTime':
                    return (fields[dataSource] = {
                        ...conf.find((it) => it.dataSource === dataSource),
                        value: (data, val) => {
                            const parsedValue = dataValue ? val[dataValue] : val;
                            if (parsedValue) {
                                return moment(parseInt(parsedValue)).format('DD.MM.YYYY HH:mm');
                            }
                            return null;
                            // old logic
                            // return dataValue && validDate(val[`${dataValue}`])
                            // ? moment(val[`${dataValue}`]).format('DD.MM.YYYY HH:mm')
                            // : validDate(val)
                            // ? moment(val).format('DD.MM.YYYY HH:mm')
                            // : '';
                        }
                    });

                case 'asDate':
                    return (fields[dataSource] = {
                        ...conf.find((it) => it.dataSource === dataSource),
                        value: (data, val) => { 
                            const parsedValue = dataValue ? val[dataValue] : val;
                            if (parsedValue) {
                                return moment(parseInt(parsedValue)).format('DD.MM.YYYY');
                            }
                            return null;
                            // old logic
                            // return dataValue && validDate(val[`${dataValue}`])
                            //     ? moment(val[`${dataValue}`]).format('DD.MM.YYYY')
                            //     : validDate(val)
                            //     ? moment(val).format('DD.MM.YYYY')
                            //     : '';
                        }
                    });

                case 'asSymbol':
                    return (fields[dataSource] = {
                        ...conf.find((it) => it.dataSource === dataSource),
                        component: ({ data, value }) => {
                            let title = dataValue ? value.val : value;
                            if (dataSource === 'isOverdue') {
                                title = value == 0 ? t('appeal.no') : t('appeal.yes');
                            }
                            const svgItem = gridMap && gridMap.find(({ key, attr }) => {
                                if (dataValue)
                                    return key === String(value[dataValue]) && attr === dataSource;
                                return key === String(value) && attr === dataSource;
                            });
                            return svgItem ? (
                                <ReactSVG
                                    svgClassName="grid-icon"
                                    src={svgItem.val}
                                    title={title}
                                />
                            ) : (
                                <div className="grid-icon">
                                    {dataValue ? value[dataValue] : value.val || value}
                                </div>
                            );
                        },
                    });

                case 'asDurSLA':
                    return (fields[dataSource] = {
                        ...conf.find((it) => it.dataSource === dataSource),
                        value: (val) =>
                            getFormattedSLA(
                                val[`${dataSource}`] && val[`${dataSource}`].toString(),
                            ),
                    });

                case 'asLabel':
                    if (backgroundColor) {
                        return (fields[dataSource] = {
                            ...conf.find((it) => it.dataSource === dataSource),
                            component: ({ value }) => {
                                const color = gridMap && gridMap.find(({ key }) => key === String(value.id));
                                const style = {
                                    backgroundColor: color && color.val,
                                    color: color ? '#fff' : 'black',
                                };
                                return (
                                    <span
                                        className="sticker sticker-grid sticker-ellipsis"
                                        style={style}
                                        title={value[`${dataValue}`]}
                                    >
                                        {value[`${dataValue}`]}
                                    </span>
                                );
                            },
                        });
                    } else if (dataValue) {
                        // console.log({ dataValue, fields, conf });
                        // console.log({ dataFields: fields[dataSource] });
                        return (fields[dataSource] = {
                            ...conf.find((it) => it.dataSource === dataSource),
                            width: 100,
                            value: (val) => {
                                // console.log({ val, dataSource, dataValue });
                                return val[`${dataSource}`][`${dataValue}`];
                                // return val && val[dataSource] && val[dataSource][dataValue];
                            },
                        });
                    }
                    return null;
                default:
                    if (dataValue) {
                        return (fields[dataSource] = {
                            ...conf.find((it) => it.dataSource === dataSource),
                            value: (val) => val[`${dataSource}`][`${dataValue}`],
                        });
                    }
                    return (fields[dataSource] = {
                        ...conf.find((it) => it.dataSource === dataSource),
                    });
            }
        });

        // console.log({params});

        if (params.stripeItem) {
            fields = {
                keyValue: {
                    permanent: true,
                    width: 15,
                    noDnD: true,
                    classWidth: 'removed-resizer',
                    component: (data) => (
                        <Expander
                            {...data}
                            requestkey={params.stripeItem}
                            requestUrlKey={expanderUrlKey}
                            requestActionsUrlKey={expanderActionsUrlKey}
                            requestSource={expanderSource}
                        />
                    ),
                    notForExport: true,
                },
                ...fields,
            };
        }
        if (params.checkedItem) {
            fields = {
                checkbox: {
                    noSorted: true,
                    width: 10,
                    classWidth: '2',
                    noDnD: true,
                    component: (params) => (
                        <CheckBoxGrid params={params} onChange={handleCheck} loadingState={false} />
                    ),
                },
                ...fields,
            };
        }
        if (params.colorItem === 'stateId') {
            fields = {
                statusAss: {
                    permanent: true,
                    classWidth: 'fixed',
                    noDnD: true,
                    notForExport: true,
                    component: ({ value }) => (
                        <div
                            className={`grid-fixed-status-component object-full sticker-${value}`}
                        />
                    ),
                },
                ...fields,
            };
        }
        return fields;
    };

    const setInitGrid = (gridDynConf, gridMap) => {
        const { fields, ...rest } = gridParams;
        let needUpdate = gridDynConf ? true : initUpdate;
        const gridLastState = getGridsStorageFields(gridConf);
        if (gridDynConf) initApi.limit = gridDynConf.rowCount;
        const params = {
            ...rest,
            fields: !gridDynConf
                ? getFields(GRIDS[gridConf].fields, gridFields[gridKey])
                : getDynamicFields(gridDynConf.cells, gridFields[fields], gridDynConf, gridMap),
            defaultId: !gridDynConf ? GRIDS[gridConf].defaultId : gridDefaultId,
            t: (k) => t(k),
            pagination: {
                data: {},
                onChange: (data) => updateApiGrid(data, gridKey),
            },
            filter: { onChange: (data) => updateApiGrid(data, gridKey) },
            sort: { onChange: (data) => updateApiGrid(data, gridKey) },
            onChangeFields: (data) => updateFieldsGrid(data, gridKey),
        };
        if (fields) {
            Object.keys(fields).forEach((key) => {
                if (params.fields[key]) {
                    params.fields[key] = { ...params.fields[key], ...fields[key] };

                    if (params.fields[key].component) {
                        params.fields[key] = {
                            ...params.fields[key],
                            component:
                                fields[key].component || GRIDS[gridConf].fields[key].component,
                        };
                    }
                }
            });
        }
        if (gridLastState) {
            params.fields = _.merge(gridLastState, _.merge(params.fields, gridLastState));
        }
        initGrid({
            api: initApi,
            params,
            gridKey,
            update: needUpdate,
            custom: !!gridFields[gridKey],
        });
    };

    async function getDynamicConfig(gridKey, gridCode) {
        if (!gridDynamicConfig) {
            // console.log({entityCode, configUrlKey, configTabCode});
            // todo: Promise all
            // console.log("*** INIT DYNAMIC GRID ***", {entityCode, gridKey});
            const responseConf = await baseService.get(configUrlKey, {
                pathParams: { entityCode: gridCode, objectType: configTabCode },
                data: { lang: getLanguage() },
            });
            const responseConfMap = await baseService.get('config_all_map_grid', {
                pathParams: { entityCode: gridCode, objectType: configTabCode },
			});

            if (responseConf.result && responseConfMap.result) {
                localStorage.setItem(
                    `config_${gridKey}_${gridCode}`,
                    JSON.stringify(responseConf.result),
                );
                localStorage.setItem(
                    `config_map_${gridKey}_${gridCode}`,
                    JSON.stringify(responseConfMap.result),
                );
                return setInitGrid(responseConf.result, responseConfMap.result);
            }
        }
        // console.log("*** CAAAAAAACHED GRID ***", {gridDynamicConfig, gridDynamicMapConfig});

        return setInitGrid(gridDynamicConfig, gridDynamicMapConfig);
    }

    useEffect(() => {
        // console.log({gridStore: grid.gridKey, gridKey});
        // const { key, code, configKey, configCode, isDynamic } = props.tuner();

        if (configUrlKey) {
            // handle dynamic grid logic;
            getDynamicConfig(configKey, configCode);
        } else {
            setInitGrid();
        }
        // if (!!key && !!code ) getConf(code, `${key}_`);
        //TODO SDSUI-4707 task for use dynamic config grid for INTERACTION_REQUEST & get_appeals
        // if (!!key && gridWithConfigKeys.includes(configKey || key) && (!!code || !!configCode) && gridTopicsWithConfig.includes(configCode || code)) getConf(configCode || code, configKey ? `${configKey}_` : `${key}_`);
        // else setInitGrid();
        // async function getConf (entityCode, gridKey) {
        // 	if (!gridDynamicConfig){
        // 		const responseConf = await	baseService.get('config_grid', { pathParams: { entityCode }, data: {lang: getLanguage() } });
        // 		const responseConfMap = await	baseService.get('config_all_map_grid', { pathParams: { entityCode } });

        // 		if(responseConf.result && responseConfMap.result) {
        // 			localStorage.setItem(gridKey, JSON.stringify(responseConf.result));
        // 			localStorage.setItem(`config_map_${gridKey}`, JSON.stringify(responseConfMap.result));
        // 			return setInitGrid(responseConf.result, responseConfMap.result);
        // 		}
        // 	}
        // 	return setInitGrid(gridDynamicConfig, gridDynamicMapConfig);
        // }
        return () => destroyOnUnmount && resetGrid(gridKey); // reset grid to null
    }, [gridKey]);

    useEffect(() => {
        if (update && grid.gridKey === gridKey) {
            const { key, path, method, ...rest } = api;
            const apiPars = { key, path, method, data: { ...rest } };

            onStart();
            request(updateDataGrid, apiPars, gridKey);
        }
    }, [update, grid]);

    useEffect(() => {
        if (gridSearch && api.query !== gridSearch) {
            updateApiGrid({ query: gridSearch }, gridKey);
        }
    }, [gridSearch]);

    useEffect(() => {
        if (result && result.success) {
            if (!result.result.length && api.page > 1) {
                updateApiGrid({ page: api.page - 1 }, gridKey);
            } else {
                setGridData(result.result);
                onFinish();
            }
        }
    }, [result]);




    function handleGridDomRowSelection(nodeId, rowId, actionType) {
        // try to find a row and check if it is not selected
        // find tr with attribute "data-id" equal to preSelectedRowId
        const gridNode = document.getElementById(nodeId);
        if (gridNode) {
            const trNodes = gridNode.getElementsByTagName("tr");
            // make a timeout to run for loop after event loop cycle (to have an actual rows renderd)
            setTimeout(() => {
                for (let i = 0; i < trNodes.length; i++) {
                    const rowNode = trNodes[i];
                    const dataId = rowNode.getAttribute("data-id");
                    if (+dataId === +rowId) {
                        // check if row is selected
                        const isSelected = rowNode.classList.contains("selected");
                        // handle selection
                        if (actionType === "select") {
                            if (!isSelected) {
                                rowNode.classList.add("selected");
                                setAutoSelectedRowId(rowId);
                            }
                        }
                        // handle selection removal (once other row is manually selected);
                        if (actionType === "deselect") {
                            if (isSelected) {
                                rowNode.classList.remove("selected");
                                setAutoSelectedRowId(null);
                            }
                        }
                        break;
                    }
                }
            }, 0);
        }
    }
    // handle row "visual" selection
    // if selectedRowId is provided - search in dom tree needed row and add "selected" class to it
    // make sure to not traverse the tree if some row was already manually selected
    // using setTimeout to make a loop check right after rows are actually rendered in previous event-loop cycle
    useEffect(() => {
        // grid results available, row id is here, no manually selected row available
        if (isGridResultAvailable && preSelectedRowId && !isRowManuallySelected) {
            handleGridDomRowSelection(gridKey, preSelectedRowId, "select");
        }
    }, [preSelectedRowId, isGridResultAvailable]);

    // handle deselect of automatically selected row
    useEffect(() => {
        // manually selected row id which is differs from previous autoSelectedRowId
        if (isRowManuallySelected && autoSelectedRowId && autoSelectedRowId !== preSelectedRowId) {
            handleGridDomRowSelection(gridKey, autoSelectedRowId, "deselect");
        }
    }, [preSelectedRowId]);

    return (
        <div className="grid-wrapper" id={gridKey}>
            {gridData && params.fields && <Grid data={gridData} params={params} />}
        </div>
    );
};

export default connect(mapStateToProps, {
    request: getRequest,
    updateApiGrid: setApi,
    updateFieldsGrid: setFields,
    updateDataGrid: setData,
    initGrid: init,
    resetGrid: reset,
})(withRouter(GridWrapper));
