import {
	UPDATE_SETTINGS_BREADCRUMBS,
	SAVE_DOMAIN_RULES_INITIAL_FORM_OPTIONS,
	UPDATE_DOMAIN_RULES_ATTRIBUTE_OPTIONS,
	UPDATE_RULE_ACCESS,
	UPDATE_GROUP_DOMAIN_OPTIONS,
	SAVE_BREADCRUMB_PARENT_NAME,
	SAVE_BREADCRUMB_CHILD_NAME,
	UPDATE_BUISNESS_UNITS,
	UPDATE_BUISNESS_UNIT_USER_SEARCH,
	UPDATE_BUISNESS_UNIT_SPACE_SEARCH,
	UPDATE_RESTRICTION_TYPES,
	UPDATE_RESTRICTION
} from '../constants/actions';
import get from 'lodash/get';

const initialState = {
	breadcrumbs: [],
	tableOptions: [],
	attributeOptions: [],
	conditionOptions: [],
	ruleAccess: {},
	groupDomainOptions: [],
	parentName: '',
	childName: '',
	buisnessUnits: [],
	restriction: {},
	restrictionTypes: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
	case UPDATE_SETTINGS_BREADCRUMBS:
		return {
			...state,
			breadcrumbs: action.breadcrumbs
		};
	
	case SAVE_DOMAIN_RULES_INITIAL_FORM_OPTIONS:
		return {
			...state,
			tableOptions: action.tableOptions,
			conditionOptions: action.conditionOptions
		};
	
	case UPDATE_DOMAIN_RULES_ATTRIBUTE_OPTIONS:
		return {
			...state,
			attributeOptions: action.attributeOptions
		};
	
	case UPDATE_RULE_ACCESS:
		return {
			...state,
			ruleAccess: action.ruleAccess
		};
	
	case UPDATE_GROUP_DOMAIN_OPTIONS:
		return {
			...state,
			groupDomainOptions: action.groupDomainOptions
		};
	
	case SAVE_BREADCRUMB_PARENT_NAME:
		return {
			...state,
			parentName: action.parentName
		};
	
	case SAVE_BREADCRUMB_CHILD_NAME:
		return {
			...state,
			childName: action.childName
		};
	
	case UPDATE_BUISNESS_UNITS:
		return {
			...state,
			buisnessUnits: action.buisnessUnits
		};
	
	case UPDATE_BUISNESS_UNIT_USER_SEARCH:
		return {
			...state,
			userSearchResult: action.userSearchResult
		};
	
	case UPDATE_BUISNESS_UNIT_SPACE_SEARCH:
		return {
			...state,
			spaceSearchResult: action.spaceSearchResult
		};
	
	case UPDATE_RESTRICTION_TYPES:
		return {
			...state,
			restrictionTypes: action.restrictionTypes
		};
	
	case UPDATE_RESTRICTION:
		return {
			...state,
			restriction: action.restriction
		};
	
	default:
		return state;
	}
};

// selectors
export const getSelectedUnitName = (state, selectedUnitId) => {
	const buisnessUnits = state.settings.buisnessUnits;
	if (!Array.isArray(buisnessUnits)) return '';
	
	const selectedUnit = buisnessUnits.find(bu => bu.id === selectedUnitId);
	return get(selectedUnit, 'text', '');
};
