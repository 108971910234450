import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { reduxFormWrapper, escapeBaseTag } from 'helpers';
import baseService from 'services/BaseService';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Field, getFormValues, getFormSyncErrors } from 'redux-form';
import { ModalWindow } from 'ui-core-dashboard';
import DynamicForm from '../../pages/Ordering/common/DynamicForm';
import Loader from 'components/Loader/index';
// TODO: moved to some shared place and rename it as SingleSelectWithSearch (similar to multiselect)
import { ReduxFormDecorator as TypeSelect } from '../../pages/Appeal/Form/components/AppealTypeSelect';

import './styles.scss';

const FILTER = 'RESOURCE';
const TYPE_FORM = 'add-customer-resource';
const DYNAMIC_FORM = 'add-customer-resource-dynamic';

const mapStateToProps = (state, props) => {
    return {
        typeValues: getFormValues(TYPE_FORM)(state),
        dynValues: getFormValues(DYNAMIC_FORM)(state),
        dynErrors: getFormSyncErrors(DYNAMIC_FORM)(state),
    };
};

const Form = (props) => {
    const {
        t,
        typeValues,
        dynValues,
        dynErrors,
        history,
        closeModal,
        match: {
            params: { id: customerId },
        },
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [objectInfoModal, setObjectInfoModal] = useState({
        open: false,
        data: null,
        expanded: false,
    });
    const [dynParams, setDynParams] = useState(null);
    const [readyToSubmit, setReadyToSubmit] = useState(false);

    const requestServiceTypes = () => {
        const params = {
            data: {
                filter: FILTER,
                customerId,
            },
        };
        setIsLoading(true);
        baseService
            .get('get_service_catalogue', params)
            .then((response) => {
                const { children, success } = response;
                if (success && children) {
                    setServiceTypes(children);
                }
                setIsLoading(false);
            })
            .catch((e) => setIsLoading(false));
    };

    const requestDynParams = ({ id, type }) => {
        setDynParams(null);
        const params = {
            data: {
                ctxObjectType: 'customer',
                ctxObjectId: customerId,
                objectType: type,
                objectTypeId: id,
                openMode: 'create',
                curStepNum: 1,
            },
        };
        setIsLoading(true);
        baseService
            .get('appeal_form_params', params)
            .then((response) => {
                const { result, success } = response;
                if (success && result) {
                    const { blocks } = result;
                    setDynParams(blocks);
                }
                setIsLoading(false);
            })
            .catch((e) => setIsLoading(false));
    };

    const requestServiceDetails = ({ type, isFolder, id }) => {
        const params = {
            pathParams: {
                id,
            },
            data: {
                componentCode: 'description',
                objectType: type,
                mimeType: 'text',
                isFolder,
            },
            jsonType: true,
        };
        setIsDetailLoading(true);
        baseService
            .get('get_service_catalogue_detail', params)
            .then((response) => {
                const { result, success } = response;
                if (success && result.content) {
                    setObjectInfoModal((state) => ({ ...state, data: result.content }));
                } else {
                    setObjectInfoModal((state) => ({ ...state, data: null }));
                    console.log('Object additional info request failed');
                }
                setIsDetailLoading(false);
            })
            .catch((e) => setIsDetailLoading(false));
    };

    const handleObjectInfo = () => {
        setObjectInfoModal((state) => ({ ...state, open: true }));
    };

    const onTypeChange = (event, newValue) => {
        requestDynParams(newValue);
        requestServiceDetails(newValue);
    };

    const onSubmit = () => {
        if (readyToSubmit && !isLoading) {
            let dynParamsValues = [];

            if (dynValues) {
                dynParamsValues = Object.keys(dynValues).map(key => ({[key]: dynValues[key]}));
            }

            const params = {
                data: {
                    customerId,
                    specId: typeValues.type.id,
                    params: dynParamsValues,
                },
                jsonType: true,
            };
            setIsLoading(true);
            baseService
                .post('customer_save_service', params)
                .then((response) => {
                    setIsLoading(false);
                    const { result, success } = response;
                    if (success && result) {
                        const { specInstId } = result;
                        closeModal();
                        history.push(`/resource/${specInstId}`);
                    }
                })
                .catch((e) => setIsLoading(false));
        }
    };

    useEffect(() => {
        const isSomeDataLoading = isDetailLoading || isLoading;
        if (!isSomeDataLoading && typeValues && isEmpty(dynErrors)) {
            setReadyToSubmit(true);
        } else {
            setReadyToSubmit(false);
        }
    }, [typeValues, dynValues, dynErrors, isDetailLoading, isLoading]);

    useEffect(() => {
        requestServiceTypes();
    }, []);

    return (
        <>
            <div class="customer-service-form">
                {(isDetailLoading || isLoading) && <Loader />}
                <div className="customer-type-select">
                    <Field
                        name="type"
                        component={TypeSelect}
                        valueKey="id"
                        labelKey="name"
                        title="Ресурси"
                        nodeArray={serviceTypes}
                        onChange={onTypeChange}
                    />
                    <button
                        type="button"
                        className={objectInfoModal.data ? 'btn-save' : 'btn-save disabled'}
                        disabled={!objectInfoModal.data}
                        onClick={handleObjectInfo}
                    >
                        <ReactSVG className="info-icon" src="/data/svg/info.svg" />
                        {/* <i className='icon icon-info-circle' /> */}
                    </button>
                </div>
                {dynParams && (
                    <DynamicForm
                        formName={DYNAMIC_FORM}
                        dynData={dynParams}
                        t={t}
                    />
                )}
                <div className="customer-service-actions">
                    <button
                        className={readyToSubmit ? 'btn btn-primary' : 'btn btn-primary disabled'}
                        type="button"
                        onClick={onSubmit}
                    >
                        {t('save')}
                    </button>
                </div>
            </div>
            {objectInfoModal.open && (
                <ModalWindow
                    className={
                        objectInfoModal.expanded
                            ? 'ordering-component-ui-core-wrapper html-wrapper expanded'
                            : 'ordering-component-ui-core-wrapper html-wrapper'
                    }
                    onClose={() => setObjectInfoModal((state) => ({ ...state, open: false }))}
                >
                    <header className="modal-window-header">
                        <div className="modal-window-title">{t('additionalInfo')}</div>
                        <button
                            type="button"
                            className="btn-save expand"
                            onClick={() =>
                                setObjectInfoModal((state) => ({
                                    ...state,
                                    expanded: !state.expanded,
                                }))
                            }
                        >
                            {objectInfoModal.expanded ? (
                                <ReactSVG className="expand-icon" src="/data/svg/re-expand.svg" />
                            ) : (
                                <ReactSVG className="expand-icon" src="/data/svg/expand.svg" />
                            )}
                        </button>
                    </header>
                    <div className="object-info-html-wrapper">
                        <div dangerouslySetInnerHTML={{ __html: escapeBaseTag(objectInfoModal.data) }} />
                    </div>
                </ModalWindow>
            )}
        </>
    );
};

export default connect(
    mapStateToProps,
    null,
)(
    withRouter(
        reduxFormWrapper({
            form: TYPE_FORM,
        })(withTranslation()(Form)),
    ),
);
