import React, { Fragment } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { destroy, getFormValues, change } from 'redux-form';
import { withTranslation } from 'react-i18next';

import baseService from 'services/BaseService';
import Loader from 'components/Loader';
import MessageModal from 'components/MessageModal';
import MailingItem from './MailingItem';
import MailingSendForm from './MailingSendForm';
import SearchEmailModal from '../SearchEmailModal';
import './styles.scss';

import { openMessageModal, closeMessageModal } from 'actions/ui';
import ModalPortal from 'components/ModalPortal';
import { GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';
import {
    sendEmail,
    toggleAllMails,
    uploadFile,
    deleteFile,
    createNewEmail,
    getFilesList,
    setFilesLength,
    setFormForward,
    setFormReply,
    getTemplateEmail,
    getTemplateList,
    openEmailSuccess,
    getEmails,
    updateSearchQuery,
    resetEmailForm,
    setMailState,
    changeEditSignMode,
    updateSignatureList,
} from 'actions/emails';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import MailingHeader from './MailingHeader';
import { extractAppealFromState } from 'helpers';
import { EMAILFORWARD, EMAILNEW, EMAILREPLY, INTERACTION_REQUEST } from './utils';
import UniversalPrintPopup from '../../../components/Common/UniversalPrintPopup';
import injectedStyles from '../PrintPopup/injectedStyles';
import PrintTemplate from '../PrintPopup/PrintTemplate';
import EditSignModal from './EditSignModal';
import ReactSVG from 'react-svg';
import permissions, { checkPermissions } from 'config/permissions';

const LOCK_SOURCE = "email";

const GET_FORM_NAME = id => `send-new-mail-${id}`;

function mapStateToProps(state, props) {
    const [appeal, id] = extractAppealFromState(state, props);
    const email = _.get(state, `emails.emails[${id}]`, {});

    return {
        id,
        emails: email.emails || [],
        requestedForAppeal: email.requestedForAppeal,
        searchQuery: email.query,
        toggleMails: email.toggleAllMails,
        attachmentFiles: email.attachment,
        newEmailInteractionId: email.newEmailId,
        filesCount: email.filesCount,
        filesLength: email.filesLength,
        appealInteractionId: get(appeal, 'currentAppeal.interactionId'),
        initialValues: email.formFields,
        mailBody: email.mailBody,
        replyMailBody: email.replyMailBody,
        signature: email.signature,
        signatureView: email.signatureView,
        contentLoading: state.content.contentLoading,
        shouldOpenEmail: email.shouldOpenEmail,
        showMessageModal: state.ui.showMessageModal,
        templateList: email.templateList || [],
        sendFormOpening: email.sendFormOpening,
        standartView: email.standartView,
        maximized: email.maximized,
        minimized: email.minimized,
        forward: email.forward,
        isEditSignMode: state.emails.isEditSignMode,
        formValues: getFormValues(GET_FORM_NAME(id))(state),
        canCreateMail: checkPermissions(permissions.EmailOperations.action_createEmail),
        canSendMail: checkPermissions(permissions.EmailOperations.action_sendEmail),
        canSearchContacts: checkPermissions(permissions.SearchOperations.search_messageContacts),
        showSearchEmailModal: state.ui.showSearchEmailModal,
        searchEmailModalContext: state.ui.searchEmailModalContext
    };
}

const mapDispatchToProps = {
    getEmails,
    updateSearchQuery,
    sendEmail,
    toggleAllMails,
    uploadFile,
    deleteFile,
    createNewEmail,
    setFilesLength,
    getFilesList,
    setFormForward,
    setFormReply,
    getTemplateEmail,
    getTemplateList,
    openMessageModal,
    closeMessageModal,
    openEmailSuccess,
    resetEmailForm,
    setMailState,
    changeEditSignMode,
    updateSignatureList,
    destroy: (name) => destroy(name),
    change,
};

@withRouter
@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
export default class Mailing extends React.Component {
    constructor(props) {
        super(props);
        this.sendEmailForm = this.sendEmailForm.bind(this);
        this.onSendForm = this.onSendForm.bind(this);
        this.scrollRef = React.createRef();
        this.onClickClose = this.onClickClose.bind(this);
        this.handlerDeleteFile = this.handlerDeleteFile.bind(this);
        this.toggleHandlerPrintModal = this.toggleHandlerPrintModal.bind(this);
        this.closeDeleteConfirm = this.closeDeleteConfirm.bind(this);
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
        this.state = {
            isPrintOpen: false,
            mailModalContent: {},
            actionMailForm: EMAILNEW,
            isDeleteConfirmOpen: false,
            isEmailDeleting: false,
            showCopy: false,
        };
    }

    componentDidMount() {
        this.requestEmails();
        this.props.getTemplateList(this.props.id);

        if (this.props.sendFormOpening) {
            // lock appeal on start email writing
            this.props.unlockAppealForm(LOCK_SOURCE);
        }
    }

    componentDidUpdate(prevProps) {
        const { id, filesCount, newEmailInteractionId } = this.props;

        if (prevProps.shouldOpenEmail && !this.props.shouldOpenEmail) {
            const selector = this.scrollRef.current.querySelector(
                `div[data-id='${prevProps.shouldOpenEmail.id}']`,
            );

            if (selector) {
                selector.scrollIntoView();
            }
        }

        if (this.props.match.params.appealId !== prevProps.match.params.appealId) {
            this.requestEmails();
            this.props.getTemplateList(id);
        }

        if (filesCount > prevProps.filesCount) {
            this.props.getFilesList(id, newEmailInteractionId);
        }
    }

    // componentWillUnmount() {
    //     // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
	// 	this.props.removeAppealLockSource(LOCK_SOURCE);
    // }

    requestEmails = (query = '') => {
        const appealId = this.props.match.params.appealId;

        const requestData = {
            query,
            objectType: INTERACTION_REQUEST,
            objectId: appealId,
            sort: [{ property: 'date', direction: 'DESC' }],
        };

        this.props.getEmails(requestData, appealId);
    };

    async onSendForm(value, html) {
        const {
            sendEmail,
            initialValues,
            newEmailInteractionId,
            appealInteractionId,
            match,
            id,
            forward,
            signature,
            signatureView,
            canSendMail,
        } = this.props;
        if (!canSendMail) return;

        try {
            const data = {
                key: forward ? 'forward_email' : 'send_email',
                data: {
                    interactionId: newEmailInteractionId,
                    parentInteractionId: appealInteractionId,
                    objectId: match.params.appealId,
                    objectType: INTERACTION_REQUEST,
                    to: [...(value.to || [])],
                    cc: [...(value.copy || [])],
                    body: html,
                    subject: value.subject, // || (initialValues && initialValues.subject),
                    signature,
                },
                jsonType: true,
            };
    
            await sendEmail(id, data);
    
            // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
            this.props.removeAppealLockSource(LOCK_SOURCE);
    
            await this.props.setMailState(id, {
                sendFormOpening: false,
                forward: false,
                expand: false,
                mailBody: false,
                attachment: [],
                signature: null,
                formFields: null,
                maximized: false,
                minimized: false,
                standartView: false,
            });
    
            await this.props.destroy(GET_FORM_NAME(id));
    
            await this.requestEmails();
    
            return true;
        } catch(e) {
            console.error("Mailing::onSendForm: Error: ", e);
            return false;
        }
    }

    onToggleAllMails = () => this.props.toggleAllMails(this.props.id);

    onUploadFiles = (files, interactionId) => {
        const {
            uploadFile,
            setFilesLength,
            newEmailInteractionId,
            t,
            openMessageModal,
            id,
            maxFileSize,
        } = this.props;

        setFilesLength(id, files.length);

        files.forEach((file) => {
            const fileSize = file.size / 1024 / 1024;

            const maxFileSize = GET_MAX_FILE_SIZE_FROM_DICT('email');

            if (fileSize > maxFileSize) {
                this.fileModal = (
                    <MessageModal
                        danger
                        titleModal={t('error')}
                        contentModalText={t('errorFile', { size: maxFileSize })}
                    />
                );

                openMessageModal();
            } else {
                uploadFile(id, file, interactionId || newEmailInteractionId);
            }
        });
    };

    handlerDeleteFile() {
        const { newEmailInteractionId, getFilesList, id } = this.props;

        getFilesList(id, newEmailInteractionId);
    }

    sendEmailForm() {
        const { createNewEmail, appealInteractionId, id, setMailState, canCreateMail } = this.props;
        if (canCreateMail) {
            this.setState({ actionMailForm: EMAILNEW });
            createNewEmail(id, appealInteractionId, EMAILNEW);
            setMailState(id, { sendFormOpening: true, forward: false });
            // lock appeal on start email writing
            this.props.unlockAppealForm(LOCK_SOURCE);
        }
    }

    onClickReply = (emailId, parentInteractionId, email, action) => {
        const { id } = this.props;
        this.setState({ actionMailForm: EMAILREPLY });
        this.props.createNewEmail(id, parentInteractionId, action, email);
        this.props.setMailState(id, { sendFormOpening: true, forward: false });
        // lock appeal on start email writing
        this.props.unlockAppealForm(LOCK_SOURCE);
    };

    toggleHandlerPrintModal = (mailModalContent) => {
        if (mailModalContent) {
            return this.setState({ isPrintOpen: true, mailModalContent });
        }
        return this.setState({ isPrintOpen: false, mailModalContent: {} });
    };

    onClickForward = (emailId, parentInteractionId, email) => {
        const { id } = this.props;

        this.setState({ actionMailForm: EMAILFORWARD });
        this.props.createNewEmail(id, parentInteractionId, EMAILFORWARD, email);
        this.props.setMailState(id, { sendFormOpening: true, forward: true });

        // lock appeal on start email writing
        this.props.unlockAppealForm(LOCK_SOURCE);
    };

    onRestore = () =>
        this.props.setMailState(this.props.id, {
            standartView: true,
            minimized: false,
            maximized: false,
        });
    onMaximize = () =>
        this.props.setMailState(this.props.id, {
            maximized: true,
            minimized: false,
            standartView: false,
        });
    onMinimize = () =>
        this.props.setMailState(this.props.id, {
            maximized: false,
            minimized: true,
            standartView: false,
        });

    changeEditSignModeWithUpdate = () => {
        const { isEditSignMode, changeEditSignMode, updateSignatureList, id } = this.props;
        const { actionMailForm } = this.state;
        if (isEditSignMode) {
            updateSignatureList(id, actionMailForm);
            localStorage.removeItem('signatureEdit');
            return changeEditSignMode();
        }
        return changeEditSignMode();
    };

    onClickClose() {
        this.setState({ isDeleteConfirmOpen: true });
    }

    closeDeleteConfirm() {
        this.setShowCopy(false);
        this.setState({ isDeleteConfirmOpen: false });
    }

    onCloseConfirm() {
        const { getTemplateList, id, newEmailInteractionId } = this.props;
        this.closeDeleteConfirm();
        this.setState({ isEmailDeleting: true });

        baseService
            .post('delete_email', {
                data: { interactionId: newEmailInteractionId },
            })
            .then(({ success }) => {
                if (success) {
                    this.props.setMailState(id, {
                        sendFormOpening: false,
                        forward: false,
                        minimized: false,
                        standartView: false,
                        maximized: false,
                        mailBody: null,
                        formFields: null,
                        signature: null,
                    });
                    this.props.destroy(GET_FORM_NAME(id));
                    getTemplateList(id);
                    // remove lock source once form is closed (once source array is empty - appeal will be unlocked)
                    this.props.removeAppealLockSource(LOCK_SOURCE);
                }
                this.setState({ isEmailDeleting: false });
            })
            .catch((error) => {
                this.setState({ isEmailDeleting: false });
            });
    }

    makeSearchRequest = debounce((query) => this.requestEmails(query), SEARCH_TIMER_INTERVAL);

    onSearchMail = (event) => {
        const searchQuery = event.target.value;
        this.makeSearchRequest(searchQuery);
        this.props.updateSearchQuery(this.props.id, searchQuery);
    };

    setShowCopy = value => this.setState({showCopy: value});

    handleEmailsAdd = (emails = []) => {
        const { searchEmailModalContext, change, id } = this.props;
        if (searchEmailModalContext && searchEmailModalContext.field) {
            // change field emails;
            const uniqueEmails = [...new Set(emails)];
            change(GET_FORM_NAME(id), searchEmailModalContext.field, uniqueEmails);
        }
    }

    render() {
        const {
            emails,
            searchQuery,
            toggleMails,
            attachmentFiles,
            initialValues,
            isEditSignMode,
            mailBody,
            replyMailBody,
            t,
            contentLoading,
            showMessageModal,
            deleteFile,
            changeEditSignMode,
            id,
            sendFormOpening,
            minimized,
            maximized,
            // standartView,
            formValues,
            canCreateMail,
            newEmailInteractionId,
            canSearchContacts,
            showSearchEmailModal,
        } = this.props;
        const { isPrintOpen, mailModalContent, isDeleteConfirmOpen, isEmailDeleting, showCopy } = this.state;

        return (
            <Fragment>
                {isEmailDeleting && <Loader withContainer={true} />}
                <MailingHeader
                    searchQuery={searchQuery}
                    onQueryChange={this.onSearchMail}
                    onToggleAll={this.onToggleAllMails}
                    isToggleAllActive={toggleMails}
                />
                {contentLoading ? (
                    <Loader withContainer={true} />
                ) : (
                    <Fragment>
                        <div
                            className={cx('scrollbox', 'aria-mailing', 'commentContent')}
                            ref={this.scrollRef}
                        >
                            <div className={cx('scrollbox-content', 'commentContentScrollbox')}>
                                <div className={'mailing'}>
                                    {emails.map((email) => (
                                        <MailingItem
                                            key={email.id}
                                            openEmailSuccess={openEmailSuccess}
                                            {...email}
                                            attachmentFiles={attachmentFiles}
                                            toggleAllMails={toggleMails}
                                            onUploadFiles={this.onUploadFiles}
                                            onSendForm={this.onSendForm}
                                            onClickReply={this.onClickReply}
                                            onClickForward={this.onClickForward}
                                            onClickModalPrint={this.toggleHandlerPrintModal}
                                            id={email.id}
                                            copyTo={email.copyTo || []}
                                            isLockedByOther={this.props.isLockedByOther}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div
                            className={cx('appeal-mailing', {
                                expand: sendFormOpening,
                                withButton: !sendFormOpening,
                                minimized,
                            })}
                        >
                            {sendFormOpening && !initialValues && <Loader withContainer />}
                            {mailBody && !maximized && (
                                <div className={cx('formMail', { formMailShow: sendFormOpening })}>
                                    <MailingSendForm
                                        mailBody={mailBody}
                                        replyMailBody={replyMailBody}
                                        initialValues={initialValues}
                                        attachmentFiles={attachmentFiles}
                                        onUploadFiles={this.onUploadFiles}
                                        onSendForm={this.onSendForm}
                                        onClickTemplate={this.onClickTemplate}
                                        onRestore={this.onRestore}
                                        onMaximize={this.onMaximize}
                                        onMinimize={this.onMinimize}
                                        sendFormOpening={sendFormOpening}
                                        refresh={() => this.requestEmails(searchQuery)}
                                        // standartView={standartView}
                                        minimized={minimized}
                                        maximized={maximized}
                                        onClickClose={this.onClickClose}
                                        deleteFile={deleteFile}
                                        onDeleteFile={this.handlerDeleteFile}
                                        setMailState={this.props.setMailState}
                                        form={GET_FORM_NAME(id)}
                                        id={id}
                                        newEmailInteractionId={newEmailInteractionId}
                                        isLockedByOther={this.props.isLockedByOther}
                                        showCopy={showCopy}
                                        setShowCopy={this.setShowCopy}
                                        withContactSearch={canSearchContacts}
                                        key={isEditSignMode}
                                    />
                                </div>
                            )}
                            <button
                                className={cx('btn-save', {
                                    'btn-hidden': sendFormOpening,
                                    disabled: !canCreateMail || this.props.isLockedByOther,
                                })}
                                onClick={this.sendEmailForm}
                                disabled={this.props.isLockedByOther}
                            >
                                {t('write')}
                            </button>
                        </div>

                        <CSSTransition
                            in={showMessageModal}
                            classNames="fade"
                            appear={true}
                            enter={true}
                            exit={true}
                            timeout={500}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <ModalPortal
                                onClose={this.props.closeMessageModal}
                                className="modal-medium modal-center"
                            >
                                {this.fileModal}
                            </ModalPortal>
                        </CSSTransition>
                        {isPrintOpen && (
                            <UniversalPrintPopup
                                onClose={this.toggleHandlerPrintModal}
                                content={mailModalContent}
                                headerTitle={t('mailing.printMailingHeader')}
                                injectedStyles={injectedStyles}
                                PrintTemplate={PrintTemplate}
                            />
                        )}
                        {isEditSignMode && (
                            <EditSignModal onClose={this.changeEditSignModeWithUpdate} t={t} />
                        )}
                        {maximized && (
                            <ModalPortal
                                onClose={this.onClickClose}
                                t={t}
                                className="modal-center sign-modal modal-full"
                            >
                                <div className="modal-title">
                                    <ReactSVG
                                        className="icon-head"
                                        svgClassName=""
                                        src="data/svg/email-open.svg"
                                    />
                                    <div className="title-content">
                                        <span className="title-text">
                                            {_.get(formValues, 'subject', 'Тема:')}
                                        </span>
                                        <div className="title-actions">
                                            <ReactSVG
                                                className="wrapper-hovered"
                                                svgClassName="icon"
                                                src="data/svg/minimize.svg"
                                                onClick={this.onMinimize}
                                                title={t('signature.onMinimize')}
                                            />
                                            <div
                                                className="wrapper-hovered"
                                                onClick={this.onRestore}
                                                title={t('signature.onStandartView')}
                                            >
                                                {' '}
                                                <i className="icon-shrink2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formMail formMailShow">
                                    <MailingSendForm
                                        mailBody={mailBody}
                                        initialValues={initialValues}
                                        attachmentFiles={attachmentFiles}
                                        onUploadFiles={this.onUploadFiles}
                                        onSendForm={this.onSendForm}
                                        onClickTemplate={this.onClickTemplate}
                                        onRestore={this.onRestore}
                                        onMaximize={this.onMaximize}
                                        onMinimize={this.onMinimize}
                                        sendFormOpening={sendFormOpening}
                                        refresh={() => this.requestEmails(searchQuery)}
                                        // standartView={standartView}
                                        minimized={minimized}
                                        maximized={maximized}
                                        onClickClose={this.onClickClose}
                                        deleteFile={deleteFile}
                                        onDeleteFile={this.handlerDeleteFile}
                                        setMailState={this.props.setMailState}
                                        form={GET_FORM_NAME(id)}
                                        id={id}
                                        newEmailInteractionId={newEmailInteractionId}
                                        modalMode
                                        isLockedByOther={this.props.isLockedByOther}
                                        showCopy={showCopy}
                                        setShowCopy={this.setShowCopy}
                                        withContactSearch={canSearchContacts}
                                        key={`maximized_${isEditSignMode}`}
                                    />
                                </div>
                            </ModalPortal>
                        )}
                        {isDeleteConfirmOpen && (
                            <ModalPortal onClose={this.closeDeleteConfirm} className="modal-small">
                                <MessageModal
                                    titleModal={t('attention')}
                                    contentModalText={t('modalWindowsLabels.notSavedWarning')}
                                    dangerButton={true}
                                    dangerButtonText={t('close')}
                                    onClickDangerButton={this.onCloseConfirm}
                                    secondaryButton={true}
                                    secondaryButtonText={t('cancel')}
                                    onClickSecondaryButton={this.closeDeleteConfirm}
                                />
                            </ModalPortal>
                        )}
                    </Fragment>
                )}
                {
					showSearchEmailModal && <SearchEmailModal onSave={this.handleEmailsAdd}/>
				}
            </Fragment>
            
        );
    }
}
