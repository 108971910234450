import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/Common/common.scss';
import File from 'components/KnowledgeBase/common/File';
import { withTranslation } from 'react-i18next';
import { openMessageModal, closeMessageModal } from 'actions/ui';
import {connect} from "react-redux";
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { CSSTransition } from 'react-transition-group';
import { MAX_FILE_SIZE, GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';
import baseService from "../../../services/BaseService";

const mapStateToProps = (state) => ( {showMessageModal: state.ui.showMessageModal});

@withTranslation()
@connect(mapStateToProps, {openMessageModal,closeMessageModal})
class FileInput extends Component {
	constructor (props) {
		super(props);
		this.input = React.createRef();
	}
	
	deleteRemoteFile = fileId => {
		if(fileId) {
			const params = {
				data: {fileId}
			};
			
			return baseService.post('delete_file', params);
		}
	};
	
	saveLocalFile = (file, objectId) => {
		const params = {
			data: {
				data: {
					objectType: 'CLEVENT',
					objectId,
					fileName: file.name
				},
				file
			},
			file: true
		};
		
		return baseService.post('save_file', params)
			.then(resp => resp.result ? resp.result : null)
			.catch(resp => console.log(resp.errorMessage));
	};
	
	handleChange = async(event) => {
		const { input } = this.props;
		const fileList = event.currentTarget.files;
		if (fileList.length > 0) {
			const convertedFiles = await this.convertFileList(fileList);
			input.onChange([...input.value, ...convertedFiles]);
		}
		if (event.currentTarget && event.currentTarget.value) {
			event.currentTarget.value = null;
		}
	};
	
	convertFileList = async(fileList) => {
		const {t, openMessageModal} = this.props;
		if (!fileList || fileList.length === 0) return [];
		
		const convertedFiles = [];
		
		for (const file of fileList) {
			const fileSize = file.size / 1024 / 1024;

			const maxFileSize = GET_MAX_FILE_SIZE_FROM_DICT('calendar');
 
			if (fileSize > maxFileSize) {
				this.fileModal = (
					<MessageModal
						danger
						titleModal={t('error')}
						contentModalText={t('errorFile', { size: maxFileSize })}
					/>
				);
				
				openMessageModal();
			}
			else {
				const uploadedFile = await this.saveLocalFile(file, this.props.eventId);
				convertedFiles.push({ type: 'remote', file: {...uploadedFile, name: uploadedFile.fileName} });
			}
		}
		
		return convertedFiles;
	};
	
	deleteFile = fileIndex => {
		const { input } = this.props;
		const deletedFile = input.value[fileIndex];
		const updatedFiles = [
			...input.value.slice(0, fileIndex),
			...input.value.slice(fileIndex + 1)
		];
		if (deletedFile){
			this.deleteRemoteFile(deletedFile.file.id)
		}
		this.input.current.value = '';
		
		input.onChange(updatedFiles);
	};
	
	renderFile = ({ file, type }, index) => (
		<File
			key={`${file.size}-${index}`}
			file={file}
			type={type}
			index={index}
			onDelete={this.deleteFile}
			disabled={this.props.disabled}
		/>
	);
	
	render () {
		const { label, input, t, showMessageModal } = this.props;
		return (
			<div className='input-file'>
				<div className='input-label'>{label}</div>
				
				{input.value && input.value.map(this.renderFile)}
				
				<label className='fileUploader'>
					<input
						type='file'
						multiple
						accept='*'
						onChange={this.handleChange}
						ref={this.input}
						disabled={this.props.disabled}
					/>
					{
						!this.props.disabled &&
						<div className='btn-save'>{t('add')}</div>
					}
				</label>
				<CSSTransition
					in={showMessageModal}
					classNames='fade'
					appear={true}
					enter={true}
					exit={true}
					timeout={500}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					<ModalPortal
						onClose={this.props.closeMessageModal}
						className='modal-medium modal-center'
					>
						{this.fileModal}
					</ModalPortal>
				</CSSTransition>
			</div>
		);
	}
}

FileInput.propTypes = {
	label: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object
};

export default FileInput;
