import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        checkedAppealBinders: state.appeal.checkedBinders,
        checkedObjectBinders: state.appeal.checkedObjectBinders,
        checkedSearchModalEmailBinders: state.ui.checkedSearchModalEmailBinders,
    };
}

function CheckBoxGrid(props) {
    const { params, onChange, checkedAppealBinders, checkedObjectBinders, loadingState, type, checkedSearchModalEmailBinders } = props;
    const [checked, setChecked] = useState(false);

    function handleCheck(e, params) {
        setChecked(e.target.checked);
        onChange(e.target.checked, params);
    }

    useEffect(() => {
        const {
            data: { detailObjectId, email },
        } = params;
        if (type === 'appeal' && checkedAppealBinders) {
            setChecked(false);
            checkedAppealBinders.map((item) => {
                if (item.object_id === detailObjectId) setChecked(true);
            });
		}

		if (type === 'objects' && checkedObjectBinders) {
            setChecked(false);
            checkedObjectBinders.map((item) => {
                if (item.object_id === detailObjectId) setChecked(true);
            });
		}

        if (type === 'search-email-modal' && checkedSearchModalEmailBinders) {
            setChecked(false);
            checkedSearchModalEmailBinders.map((item) => {
                if (item === email) setChecked(true);
            });
		}
		
    }, [loadingState, checkedAppealBinders, checkedObjectBinders, checkedSearchModalEmailBinders, params]);

    return (
        <label className="container">
            <input type="checkbox" checked={checked} onChange={(e) => handleCheck(e, params)} />
            <span className="check-mark" />
        </label>
    );
}
export default connect(mapStateToProps, null)(CheckBoxGrid);
