import React from 'react';
import './style.scss';

const Status = ({ value, t }) => {
	return (
		<div className='grid-show-status-component' title={t(`order_status.${value}`)}>
			<i className={`icon-circle object ${value}`} />
		</div>
	);
};

export default Status;
