import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { escapeBaseTag } from 'helpers';

import { withTranslation } from 'react-i18next';


function Collection  (props){
	const { data, t, highlight } = props;
	const [open, setOpen] = useState(false);
	
	const toggleContent = () => setOpen(prevState => !prevState );
	
	const getConfig = () => {
		return [
			{ title: t('appealHistory.eventType'), path: 'eventType' },
			{ title: t('appealHistory.oraxNum'), path: 'oraxNum', innerHTML: true },
			{ title: t('appealHistory.commentText'), path: 'commentText', shouldHighlight: true },
		];
	};
	
	const createMarkup = content => ({ __html: escapeBaseTag(content) });
	
	const renderRow = ({ title, path, innerHTML, shouldHighlight }) => {
		return (
			<div className='notification-row' key={path}>
				<span className='row-title'>{title}:</span>
				{
					innerHTML
						? <div className='row-content' dangerouslySetInnerHTML={createMarkup(data[path])} />
						: <div
							className='row-content'>{shouldHighlight ? highlight(data[path]) : data[path]}</div>
				}
			</div>
		);
	};
	
		return (
			<li className='history-item'>
				<i className='icon-external-link-square' />
				<div className='history-item-content'>
					<span className='history-title-text'>Нотифікація: </span>
					<span className='history-content-text'>{`${data.channelNum}`}</span>
					
					<div className='notification-content'>
						{
							!open && renderRow({ title: t('appealHistory.commentText'), path: 'commentText' })
						}
						{
							open &&
							<React.Fragment>
								{getConfig().map(renderRow)}
							</React.Fragment>
						}
						
						<button className='contentToggleButton' onClick={toggleContent}>
							<i className='icon-kebab-hor' />
						</button>
					
					</div>
				</div>
			</li>
		);
}

Collection.propTypes = {
	data: PropTypes.shape({
		actionName: PropTypes.string,
		body: PropTypes.string,
		id: PropTypes.number,
		subject: PropTypes.string,
		status: PropTypes.string,
		notifierFrom: PropTypes.string,
		notifierTo: PropTypes.string
	}),
	highlight: PropTypes.func
};

export default withTranslation()(Collection);
