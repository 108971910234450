import React, { useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form, Field, reduxForm, isDirty } from 'redux-form';
import { ComboBox } from 'ui-core-dashboard';
import styles from 'styles/modules/knowledgeBase.module.scss';
import FileInput from '../common/FileInput';
import PencilTextInput from 'components/Common/PencilTextInput';
import { connect } from 'react-redux';
import { setHistoryPushRequireSaveWithoutChangeConfirm } from "actions/ui";

import { EditorHTML, MainInputBox } from '../common';
import { ArticleBlockBox } from '../common/index';
import BaseService from '../../../services/BaseService';
import validate from './validation';

const FORM_NAME = 'edit-service-form';

const mapDispatchToProps = {
	setHistoryPushRequireSaveWithoutChangeConfirm
};

function mapStateToProps (state, props) {
	return {
		isDirty: isDirty(FORM_NAME)(state),
	};
}

function ServiceForm (props) {
	const { isEdit, handleSubmit, initialValues, setHistoryPushRequireSaveWithoutChangeConfirm, isDirty } = props;
	const { id, type } = props.match.params;
	const { t } = useTranslation();
	const icon = isEdit ? <i className={cx('icon-file', type === '1' ? styles.navigationFolder : styles.navigationFile)} /> : null;
	
	useEffect(() => {
		if (isDirty) {
			setHistoryPushRequireSaveWithoutChangeConfirm(true);
		}
	}, [isDirty]);

	useEffect(() => {
		if (initialValues.isFolder === 0) {
			props.initialize(initialValues);
		}
	}, [initialValues.isFolder]);
	
	async function onSubmit (values) {
		if (!isEdit) {
			const response = await BaseService.post('get_service_catalogue', {
				data: { parentSpecId: id, ...values, specName: values.name },
				jsonType: true,
			});
			setHistoryPushRequireSaveWithoutChangeConfirm(false);
			await props.history.push(`/specification/${response.result.id}/${values.isFolder}`);
			await props.requestData();
		} else {
			let data = { name: values.name, isFolder: type  };
			
			if (type === '0') {
				data = { ...data, fileInfo: { content: values.content, mimeType: 'text' } }
			}
			
			await BaseService.patch('get_service_catalogue_detail', {
				pathParams: { id: id },
				data,
				jsonType: true,
			});
			setHistoryPushRequireSaveWithoutChangeConfirm(false);
			await props.history.push(`/specification/${id}/${type}`);
			await props.requestData();
		}
		
		return true;
	}
	
	return (
		<Form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.editArticleFormSpec}
		>
			{
				!isEdit && (
					<ArticleBlockBox>
						<Field
							name='isFolder'
							component={ComboBox}
							placeholder={t('select')}
							label='Статус'
							options={[
								{ label: t('specification.folder'), value: 1 },
								{ label: t('specification.specification'), value: 0}
							]}
							required
						/>
					</ArticleBlockBox>
				)
			}
			
			<MainInputBox
				icon={icon}
			>
				<Field
					component={PencilTextInput}
					name='name'
					label={t('knowledgeBase.name')}
					placeholder={t('knowledgeBase.name')}
					autosize
					hideEditIcon
					required
					maxLength={255}
				/>
			</MainInputBox>
			{
				(isEdit && type === '0') && (
					<>
						<ArticleBlockBox>
							<Field
								name='content'
								component={EditorHTML}
								label={t('knowledgeBase.text')}
							/>
						</ArticleBlockBox>
						
						<ArticleBlockBox>
							<Field
								name='files'
								component={FileInput}
								label={t('knowledgeBase.file')}
								id={id}
							/>
						</ArticleBlockBox>
					</>
				)
				
			}
			{
			
			}
			<ArticleBlockBox>
				<button type='submit' className='btn btn-primary'>
					{t('save')}
				</button>
			</ArticleBlockBox>
		</Form>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: FORM_NAME,
	validate,
	enableReinitialize: true,
	keepDirtyOnReinitialize: false,
})(ServiceForm));
