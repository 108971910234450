import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from 'styles/modules/knowledgeBase.module.scss';
import ArticlesSearchInput from './ArticlesSearchInput';

@withTranslation()
class SidebarHeader extends React.PureComponent {
	
	render () {
		const { onSearchSubmit, t, addService, canEdit } = this.props;
		return (
			<div className={styles.sidebarHeader}>
				<ArticlesSearchInput onSubmit={onSearchSubmit} />
				{
					canEdit && (
						<button className='btn btn-primary' onClick={addService}>
							{t('create')}
						</button>
					)
				}
			</div>
		
		);
	}
}

SidebarHeader.propTypes = {
	onSearchSubmit: PropTypes.func,
	openFileExplorer: PropTypes.func,
};

export default SidebarHeader;
