import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { withTranslation } from 'react-i18next';

@withTranslation()
class ChangeItem extends React.Component {
	constructor () {
		super();
		this.state = {
			multiLine: false,
			open: false
		};
		this.isLink = this.constructor.isLink;
		this.contentRef = React.createRef();
		this.LINE_HEIGHT = 21;
		this.MAX_LINES_COUNT = 3;
	}
	
	componentDidMount () {
		const textHeight = this.contentRef.current.offsetHeight;
		if (textHeight / this.LINE_HEIGHT > this.MAX_LINES_COUNT) {
			this.setState({ multiLine: true });
		}
	}
	
	toggleContent = () => this.setState(prevState => ({ open: !prevState.open }));
	
	getPrefix = () => {
		const { data, t } = this.props;
		return data.oldValue ? `${t('appealHistory.updated')} - ` : '';
	};

	getMarkdownProps = value => {
        return {
            source: value,
            unwrapDisallowed: true, // still place content of disallowedTypes
            disallowedTypes: ['paragraph'] // remove all paragraphs <p></p>render
        };
    };

	
	render () {
		const { data, highlight } = this.props;
		const { multiLine, open } = this.state;

		return (
			<li className={cx('history-item', { multiLine: multiLine })}>
				<i className='icon-check' />
				<div className={cx('history-item-content', { limited: multiLine && !open })}
					 ref={this.contentRef}>
					<span className='history-title-text'>{this.getPrefix()}{highlight(data.attributeName)}: </span>
					
					<span className='history-content-text'>{highlight(<ReactMarkdown {...this.getMarkdownProps(data.newValue)} />)} </span>
					<span className='changes-before'>{highlight(<ReactMarkdown {...this.getMarkdownProps(data.oldValue)} />)}</span>
				</div>
				{multiLine &&
				<button className={cx('contentToggleButton', 'absolute')} onClick={this.toggleContent}>
					<i className='icon-kebab-hor' />
				</button>
				}
			</li>
		);
	}
}

ChangeItem.propTypes = {
	data: PropTypes.object,
	highlight: PropTypes.func
};

export default ChangeItem;
