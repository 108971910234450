import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ComboBox from '../../Common/ComboBox';
import baseService from "../../../services/BaseService";

function SidebarHeader (props) {
	const { t, disabled, setSelectValue, selectValue } = props;
	const selectConfig = { value: selectValue, onChange: onChangeSelect, onFocus: () => {}, onBlur: () => {} };
	const [nameCityOptions, setCityNameOptions] = useState([]);
	const [selectedCity, setSelectedCity] = useState(null);
	
	useEffect( ()=>{
		onChangeSelect(selectValue)
	},[selectedCity]);
	
	useEffect( ()=>{
		return () => {
			setSelectedCity(null);
			setCityNameOptions([]);
		}
	}, []);
	
	function onChangeSelect (value) {
		setSelectValue({...value, cityId: selectedCity && selectedCity.value });
	}
	
	const searchCityQuery = _.debounce(async query => {
		if (!query || query.length <= 1) return;
		
		const data = {
			filter:[{
				query: query,
				field: 'city'
			}],
			type:"city"
		};
		
		const resp = await baseService.post('object_search', { data, jsonType: true } );
		
		if (resp.result) {
			setCityNameOptions(resp.result.map(i => ({ value: i.id, label: i.objectName })));
		} else {
			setCityNameOptions([]);
		}
	}, 500);
	
	return (
		<>
		<div className='leftSidebarHeader'>
			<div className='issues-sidebar-title'>{t('references')}</div>
			<div className='sidebar-wrapper'>
				
				{
					!disabled && (
						<Link className='btn btn-primary right' to='/issue/create'>
							{t('create')}
						</Link>
					)
				}
			</div>
		</div>
			<div className='sidebarSubheader'>
				<div className='searchInputContainer'>
					<ComboBox
						className='searchInput'
						onInputChange={searchCityQuery}
						options={nameCityOptions}
						searchable={true}
						inputProps={{maxLength: 255 }}
						maxLength={255}
						onChange={setSelectedCity}
						value={selectedCity}
					/>
					{/*<i className='icon-search2' />*/}
				</div>
				<div className='combobox-wrapper' >
					<ComboBox
						input={selectConfig}
						options={[
							{ label: t('issues.active'), value: 'active' },
							{ label: t('issues.notActive'), value: 'not_active' },
							{ label: t('issues.showAll'), value: 'all' },
						]}
					/>
				</div>
			</div>
		</>
	)
}



SidebarHeader.propTypes = {
	onSearchSubmit: PropTypes.func,
	openFileExplorer: PropTypes.func,
};

export default withTranslation()(SidebarHeader);
