import React from 'react';
import PropTypes from 'prop-types';

import GridTable from './GridTable';
import Footer from './Footer';
import FormsWrapper from './FormsWrapper';
import './grid.scss';

export default class Grid extends React.Component {
	state = {
		...this.props,
		isAppealFormOpened: true
	};
	
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setState({ ...nextProps });
	};
	
	toggleAppealForm = () => {
		this.setState(prevState => ({ isAppealFormOpened: !prevState.isAppealFormOpened }));
	};
	
	filter = (val, key) => {
		let add = false;
		
		for (const i in val) {
			if (!add && val.hasOwnProperty(i)) {
				add = add || (val[i] + '').includes(key);
			}
		}
		
		return add;
	};
	
	filterData = key => {
		const { data } = this.props;
		
		this.setState({ data: data.filter(val => this.filter(val, key)) });
	};
	
	filterFields = field => {
		const { fields } = this.props.params;
		let newFields = {};
		
		if (field !== 'all_fields') {
			for (const i in fields) {
				if (i === field) {
					newFields[i] = fields[i];
				}
			}
		} else {
			newFields = this.props.params.fields;
		}
		
		this.setState({ params: { ...this.state.params, fields: newFields } });
	};
	
	selectItem = id => {
		const { data, params } = this.state;
		const selected = data.find(v => {
			const val = v[params.defaultId] || v.id;
			return val && val.toString() === id;
		});
		
		if (selected) {
			selected.id = selected[params.defaultId] || selected.id;
			
			if (selected.id || selected.id === 0) {
				if (this.props.params.selectItem) {
					this.props.params.selectItem(selected);
				}
				this.setState({ selected });
			}
		}
	};
	
	handleSort = ({ property, direction }) => {
		const { data, params: { sort } } = this.props;
		
		// if it's server side sorting
		if (sort && sort.onChange) return sort.onChange({ property, direction });
		// if it's simple sorting
		return this.setState({
			data: data.sort((a, b) => !isReversed ? (a[field] + '').localeCompare(b[field]) : ((a[field] + '').localeCompare(b[field]) * -1)),
			params: {
				...this.state.params,
				sort: { ...this.state.params.sort, property, direction }
			}
		});
	};
	
	handleCheck = e => {
		const { value, checked } = e.target;
		const { checkedItems, handleCheck } = this.props.params.groups;
		
		const items = checked
			? [...checkedItems, value]
			: checkedItems.filter(item => item !== value);
		
		handleCheck({ checkedItems: items });
		
		if (!checkedItems.length && items.length) {
			this.setState({ isAppealFormOpened: true });
		}
	};
	
	render = () => {
		const { params, data, selected, isAppealFormOpened, strings } = this.state;
		const { currentTab, modalEntity, t } = this.props;
		return (
			<div className='grid'>
				<GridTable
					params={params}
					data={data}
					selected={selected}
					currentTab={currentTab}
					modalEntity={modalEntity}
					sort={{
						...params.sort,
						handleSort: this.handleSort
					}}
					selectItem={this.selectItem}
					{...this.props.params.groups && {
						groups: {
							...this.props.params.groups,
							handleCheck: this.handleCheck
						}
					}}
					strings={strings}
					t={t}
				/>
				
				{params.footer && (
					<Footer
						params={params.footer}
						selected={selected}
						strings={strings}
					/>
				)}
				
				{this.props.params.groups && (
					<FormsWrapper
						{...this.props.params.groups}
						filterOnlyCheckedItems={this.filterOnlyCheckedItems}
						toggleAppealForm={this.toggleAppealForm}
						isAppealFormOpened={isAppealFormOpened}
					/>
				)}
			</div>
		);
	};
}

Grid.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	]),
	params: PropTypes.object,
	contentUpdate: PropTypes.func,
	strings: PropTypes.object
};
