import {
  SET_AUDIO_TRACK,
  SHOW_AUDIO_PLAYER,
  HIDE_AUDIO_PLAYER,
} from 'constants/actions';


export const setAudioTrack = ({ source, title, currentSecond, autoplay, hidden }) => ({
  type: SET_AUDIO_TRACK,
  source,
  title,
  currentSecond,
  autoplay,
  hidden
});

export const showAudioPlayer = () => ({
  type: SHOW_AUDIO_PLAYER
});

export const hideAudioPlayer = () => ({
  type: HIDE_AUDIO_PLAYER
});
