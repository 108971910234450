import moment from 'moment/moment';
import i18next from 'util/i18n';

export default function validate (values, { t }) {
	const errors = {};
	const error = (field) => i18next.t('validation.fromTo', { field });
	const CORRECT_DATE = i18next.t('validation.correctDate');
	const ONLY_NUMBERS = i18next.t('validation.onlyNumbers');
	const MAX_CHARS_255 = i18next.t('validation.maxChars', { length: '255' });
	
	const {
		INTERACTION_REQUEST_ID,
		CUSTOMER_ID,
		SF_TAX_ID,
		SF_INSTITUTION_ID,
		SF_ACCOUNT_IDS,
		SF_PHONE_NUMS,
		CONTACT_PERSON_ID,
		CONTACT_NAME,
		CUST_NAME,
		CONTACTS,
		SF_CONTACTS,
		SF_PHYADDRESS,
		SF_FULL_NAME,
	} = values;
	
	if (!INTERACTION_REQUEST_ID) {
		errors.INTERACTION_REQUEST_ID = '';
	} else if (!/^([0-9])/.test(INTERACTION_REQUEST_ID)) {
		errors.INTERACTION_REQUEST_ID = ONLY_NUMBERS;
	}
	
	if (!CUSTOMER_ID) {
		errors.CUSTOMER_ID = '';
	} else if (!/^([0-9])/.test(CUSTOMER_ID)) {
		errors.CUSTOMER_ID = ONLY_NUMBERS;
	}
	  else if (values['CUSTOMER_ID'].length > 254 ) {
		errors.CUSTOMER_ID = MAX_CHARS_255;
	}
	
	if (!SF_TAX_ID) {
		errors.SF_TAX_ID = '';
	} else if (!/^([0-9]{5,10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6})/.test(SF_TAX_ID)) {
		errors.SF_TAX_ID = 'Невірний код';
	}
	
	if (!SF_INSTITUTION_ID) {
		errors.SF_INSTITUTION_ID = '';
	} else if (!/^([0-9]{5,10})/.test(SF_INSTITUTION_ID)) {
		errors.SF_INSTITUTION_ID = i18next.t('validation.wrongCode');
	}
	
	if (!SF_ACCOUNT_IDS) {
		errors.SF_ACCOUNT_IDS = '';
	} else if (!/^([0-9])/.test(SF_ACCOUNT_IDS)) {
		errors.SF_ACCOUNT_IDS = i18next.t('validation.wrongId');
	}
	
	if (!SF_PHONE_NUMS) {
		errors.SF_PHONE_NUMS = '';
	} else if (!/^[0-9 ()\-+\.,;]{4,}/.test(SF_PHONE_NUMS)) {
		errors.SF_PHONE_NUMS = i18next.t('validation.wrongNumber');
	}
	if (!CONTACT_PERSON_ID) {
		errors.CONTACT_PERSON_ID = '';
	} else if (values['CONTACT_PERSON_ID'].length > 254 ) {
		errors.CONTACT_PERSON_ID = MAX_CHARS_255;
	}
	
	if (!CONTACT_NAME) {
		errors.CONTACT_NAME = '';
	} else if (values['CONTACT_NAME'].length > 254 ) {
		errors.CONTACT_NAME = MAX_CHARS_255;
	}
	
	if (!CUST_NAME) {
		errors.CUST_NAME = '';
	} else if (values['CUST_NAME'].length > 254 ) {
		errors.CUST_NAME = MAX_CHARS_255;
	}
	
	if (!CONTACTS) {
		errors.CONTACTS = '';
	} else if (values['CONTACTS'].length > 254 ) {
		errors.CONTACTS = MAX_CHARS_255;
	}
	
	if (!SF_CONTACTS) {
		errors.SF_CONTACTS = '';
	} else if (values['SF_CONTACTS'].length > 254 ) {
		errors.SF_CONTACTS = MAX_CHARS_255;
	}
	
	if (!SF_PHYADDRESS) {
		errors.SF_PHYADDRESS = '';
	} else if (values['SF_PHYADDRESS'].length > 254 ) {
		errors.SF_PHYADDRESS = MAX_CHARS_255;
	}
	
	if (!SF_FULL_NAME) {
		errors.SF_FULL_NAME = '';
	} else if (values['SF_FULL_NAME'].length > 254 ) {
		errors.SF_FULL_NAME = MAX_CHARS_255;
	}
	
	
	if (values['INTERACTION_REQUEST_FINISH_DATE@FROM'] && values['INTERACTION_REQUEST_FINISH_DATE@TO']) {
		if (moment(values['INTERACTION_REQUEST_FINISH_DATE@TO']).isBefore(values['INTERACTION_REQUEST_FINISH_DATE@FROM'])) {
			errors['INTERACTION_REQUEST_FINISH_DATE@FROM'] = error(i18next.t('filter.finishDate'));
		}
	}
	
	if (values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'] && values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO']) {
		if (moment(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO']).isBefore(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'])) {
			errors['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'] = error(i18next.t('filter.finishDate'));
		}
	}
	
	if (values['INTERACTION_START_DATE@FROM'] && values['INTERACTION_START_DATE@TO']) {
		if (moment(values['INTERACTION_START_DATE@TO']).isBefore(values['INTERACTION_START_DATE@FROM'])) {
			errors['INTERACTION_START_DATE@FROM'] = error(i18next.t('filter.validFrom'));
		}
	}
	
	if (values['INTERACTION_END_DATE@FROM'] && values['INTERACTION_END_DATE@TO']) {
		if (moment(values['INTERACTION_END_DATE@TO']).isBefore(values['INTERACTION_END_DATE@FROM'])) {
			errors['INTERACTION_END_DATE@FROM'] = error(i18next.t('filter.endDate'));
		}
	}
	
	if (values['SF_START_DATE@FROM'] && values['SF_START_DATE@TO']) {
		if (moment(values['SF_START_DATE@TO']).isBefore(values['SF_START_DATE@FROM'])) {
			errors['SF_START_DATE@FROM'] = error(i18next.t('filter.since'));
		}
	}
	
	if (values['START_DATE@FROM'] && values['START_DATE@TO']) {
		if (moment(values['START_DATE@TO']).isBefore(values['START_DATE@FROM'])) {
			errors['START_DATE@FROM'] = error(i18next.t('filter.since'));
		}
	}
	
	if (values['ATTR_8@FROM'] && values['ATTR_8@TO']) {
		if (moment(values['ATTR_8@TO']).isBefore(values['ATTR_8@FROM'])) {
			errors['ATTR_8@FROM'] = error(i18next.t('filter.callBackDate'));
		}
	}
	if (values['INTERACTION_ACCEPTED_DATE@FROM'] && values['INTERACTION_ACCEPTED_DATE@TO']) {
		if (moment(values['INTERACTION_ACCEPTED_DATE@TO']).isBefore(values['INTERACTION_ACCEPTED_DATE@FROM'])) {
			errors['INTERACTION_ACCEPTED_DATE@FROM'] = error(i18next.t('filter.lastInProgresDate'));
		}
	}
	if (values['CLEVENT_RANGE_START_DATE@FROM'] && values['CLEVENT_RANGE_START_DATE@TO']) {
		if (moment(values['CLEVENT_RANGE_START_DATE@TO']).isBefore(values['CLEVENT_RANGE_START_DATE@FROM'])) {
			errors['CLEVENT_RANGE_START_DATE@FROM'] = error(i18next.t('filter.eventDate'));
		}
	}

	if (values['ORD_TASK_PLAN_BEGIN_DT@FROM'] && values['ORD_TASK_PLAN_BEGIN_DT@TO']) {
		if (moment(values['ORD_TASK_PLAN_BEGIN_DT@TO']).isBefore(values['ORD_TASK_PLAN_BEGIN_DT@FROM'])) {
			errors['ORD_TASK_PLAN_BEGIN_DT@FROM'] = error(i18next.t('filter.eventDate'));
		}
	}

	if (values['ORD_TASK_PLAN_END_DT@FROM'] && values['ORD_TASK_PLAN_END_DT@TO']) {
		if (moment(values['ORD_TASK_PLAN_END_DT@TO']).isBefore(values['ORD_TASK_PLAN_END_DT@FROM'])) {
			errors['ORD_TASK_PLAN_END_DT@FROM'] = error(i18next.t('filter.eventDate'));
		}
	}

	if (values['ORD_TASK_FAKT_BEGIN_DT@FROM'] && values['ORD_TASK_FAKT_BEGIN_DT@TO']) {
		if (moment(values['ORD_TASK_FAKT_BEGIN_DT@TO']).isBefore(values['ORD_TASK_FAKT_BEGIN_DT@FROM'])) {
			errors['ORD_TASK_FAKT_BEGIN_DT@FROM'] = error(i18next.t('filter.eventDate'));
		}
	}

	if (values['ORD_TASK_FAKT_END_DT@FROM'] && values['ORD_TASK_FAKT_END_DT@TO']) {
		if (moment(values['ORD_TASK_FAKT_END_DT@TO']).isBefore(values['ORD_TASK_FAKT_END_DT@FROM'])) {
			errors['ORD_TASK_FAKT_END_DT@FROM'] = error(i18next.t('filter.eventDate'));
		}
	}

	if (values['SF_START_DATE@TO'] && !moment(values['SF_START_DATE@TO']).isValid()) {
		errors['SF_START_DATE@TO'] = CORRECT_DATE;
	}
	if (values['SF_START_DATE@FROM'] && !moment(values['SF_START_DATE@FROM']).isValid()) {
		errors['SF_START_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['START_DATE@TO'] && !moment(values['START_DATE@TO']).isValid()) {
		errors['START_DATE@TO'] = CORRECT_DATE;
	}
	if (values['START_DATE@FROM'] && !moment(values['START_DATE@FROM']).isValid()) {
		errors['START_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_END_DATE@FROM'] && !moment(values['INTERACTION_END_DATE@FROM']).isValid()) {
		errors['INTERACTION_END_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_END_DATE@TO'] && !moment(values['INTERACTION_END_DATE@TO']).isValid()) {
		errors['INTERACTION_END_DATE@TO'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_REQUEST_FINISH_DATE@TO'] && !moment(values['INTERACTION_REQUEST_FINISH_DATE@TO']).isValid()) {
		errors['INTERACTION_REQUEST_FINISH_DATE@TO'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_REQUEST_FINISH_DATE@FROM'] && !moment(values['INTERACTION_REQUEST_FINISH_DATE@FROM']).isValid()) {
		errors['INTERACTION_REQUEST_FINISH_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO'] && !moment(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO']).isValid()) {
		errors['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@TO'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'] && !moment(values['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM']).isValid()) {
		errors['INTERACTION_REQUEST_LAST_MODIFICATION_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_START_DATE@FROM'] && !moment(values['INTERACTION_START_DATE@FROM']).isValid()) {
		errors['INTERACTION_START_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_START_DATE@TO'] && !moment(values['INTERACTION_START_DATE@TO']).isValid()) {
		errors['INTERACTION_START_DATE@TO'] = CORRECT_DATE;
	}
	
	if (values['ATTR_8@TO'] && !moment(values['ATTR_8@TO']).isValid()) {
		errors['ATTR_8@TO'] = CORRECT_DATE;
	}
	
	if (values['ATTR_8@FROM'] && !moment(values['ATTR_8@FROM']).isValid()) {
		errors['ATTR_8@FROM'] = CORRECT_DATE;
	}
	if (values['INTERACTION_ACCEPTED_DATE@TO'] && !moment(values['INTERACTION_ACCEPTED_DATE@TO']).isValid()) {
		errors['INTERACTION_ACCEPTED_DATE@TO'] = CORRECT_DATE;
	}
	
	if (values['INTERACTION_ACCEPTED_DATE@FROM'] && !moment(values['INTERACTION_ACCEPTED_DATE@FROM']).isValid()) {
		errors['INTERACTION_ACCEPTED_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['CLEVENT_RANGE_START_DATE@FROM'] && !moment(values['CLEVENT_RANGE_START_DATE@FROM']).isValid()) {
		errors['CLEVENT_RANGE_START_DATE@FROM'] = CORRECT_DATE;
	}
	
	if (values['CLEVENT_RANGE_START_DATE@TO'] && !moment(values['CLEVENT_RANGE_START_DATE@TO']).isValid()) {
		errors['CLEVENT_RANGE_START_DATE@TO'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_PLAN_BEGIN_DT@TO'] && !moment(values['ORD_TASK_PLAN_BEGIN_DT@TO']).isValid()) {
		errors['ORD_TASK_PLAN_BEGIN_DT@TO'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_PLAN_BEGIN_DT@FROM'] && !moment(values['ORD_TASK_PLAN_BEGIN_DT@FROM']).isValid()) {
		errors['ORD_TASK_PLAN_BEGIN_DT@FROM'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_PLAN_END_DT@TO'] && !moment(values['ORD_TASK_PLAN_END_DT@TO']).isValid()) {
		errors['ORD_TASK_PLAN_END_DT@TO'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_PLAN_END_DT@FROM'] && !moment(values['ORD_TASK_PLAN_END_DT@FROM']).isValid()) {
		errors['ORD_TASK_PLAN_END_DT@FROM'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_FAKT_BEGIN_DT@TO'] && !moment(values['ORD_TASK_FAKT_BEGIN_DT@TO']).isValid()) {
		errors['ORD_TASK_FAKT_BEGIN_DT@TO'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_FAKT_BEGIN_DT@FROM'] && !moment(values['ORD_TASK_FAKT_BEGIN_DT@FROM']).isValid()) {
		errors['ORD_TASK_FAKT_BEGIN_DT@FROM'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_FAKT_END_DT@TO'] && !moment(values['ORD_TASK_FAKT_END_DT@TO']).isValid()) {
		errors['ORD_TASK_FAKT_END_DT@TO'] = CORRECT_DATE;
	}

	if (values['ORD_TASK_FAKT_END_DT@FROM'] && !moment(values['ORD_TASK_FAKT_END_DT@FROM']).isValid()) {
		errors['ORD_TASK_FAKT_END_DT@FROM'] = CORRECT_DATE;
	}
	
	return errors;
};

