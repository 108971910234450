import React, { useState, useMemo, useEffect, useRef } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { change } from "redux-form";
import { get, isEqual } from "lodash";

import { setFormForceInitValues } from "../../../modules/appeal/actions";

import Loader from "components/Loader";
import Header from "./Header";
import LinkAppealBlock from "../LinkAppeal/LinkAppealBlock";
import LinkObjectsBlock from "../LinkObjects/LinkObjectsBlock";
import DynamicForm from "../../Ordering/common/DynamicForm";

import { HeaderAppealInfo } from "./components";

import baseService from "../../../services/BaseService";
import { findPropertyInArray } from "helpers";
import { getAppealForm, getAppealHeaderForm } from "./config";

import { GET_MAX_FILE_SIZE_FROM_DICT } from "constants/index";

import "./styles.scss";

const REL_OBJECT_TYPES = ["SERVICE", "RESOURCE"];

const LOCK_SOURCE_FORM = "form";

const GET_FORM_NAME = id => `appeal-form-${id}`;
const GET_FORM_HEADER_NAME = id => `appeal-form-header-${id}`;

const mapStateToProps = (state, ownProps) => {
    const appeal = state.appeal && state.appeal.appeals && state.appeal.appeals[ownProps.appeal.id];
    return {
        formForceInitValues: appeal && appeal.formForceInitValues,
    };
};

const mapDispatchToProps = { change, setFormForceInitValues };

function Form(props) {
    const {
        formParams,
        checkRestrictions,
        shouldRenderField,
        t,
        appeal,
        priorityList,
        unlockAppealForm,
        destinations,
        appealDestinationId,
        appealDestinationName,
        // customer,
        selectedAppealAction = [],
        formValues,
        formErrors,
        formHeaderValues,
        change,
        linkedObjects,
        loadingDestinations,
        onDestinationOpen,
        canAddExecutors,
        resetActions,
        resetAppealType,
        isLoading,
        formForceInitValues,
        setFormForceInitValues,
        needReinitialize,
        setNeedReinitialize,
        shouldScrollToError,
        setShouldScrollToError,
    } = props;

    const maxFileSize = useMemo(() => GET_MAX_FILE_SIZE_FROM_DICT("form"), []);

    const appealFormlRef = useRef(null);

    const [blockOffset, setBlockOffset] = useState(null); // used to scroll to the error
    const [blockOffsetKey, setBlockOffsetKey] = useState(null); // used to scroll to the error
    const [formCollapse, setFormCollapse] = useState(null);
    const [isRefreshFormCollapse, setIsRefreshFormCollapse] = useState(false);

    const [isDestinationFeedbackLoading, setIsDestinationFeedbackLoading] = useState(false);
    const [isInfoContactsOnceLoaded, setIsInfoContactsOnceLoaded] = useState(false);

    const [forceInitialHeaderValues, setForceInitialHeaderValues] = useState(null);
    const [forceInitialValues, setForceInitialValues] = useState(null);

    const [initValuesTimeStamp, setInitValuesTimeStamp] = useState(0);
    const [initValuesHeaderTimeStamp, setInitValuesHeaderTimeStamp] = useState(0);

    const [infoContacts, setInfoContacts] = useState(null);

    const [prevAppealType, setPrevAppealType] = useState(null);
    const [prevSelectedContacts, setPrevSelectedContacts] = useState(null);

    // permissions start
    let shouldRenderLinkedObjects;
    const formRequiredObjects = get(formValues, "type.requiredObjects") || [];
    const isLinkedObjects = linkedObjects && linkedObjects.length > 0;
    if (appeal.requiredObjects.includes("SERVICE") || formRequiredObjects.includes("SERVICE") || isLinkedObjects) {
        shouldRenderLinkedObjects = true;
    }

    let shouldRenderProductInstance;
    if (![null, undefined].includes(formParams)) {
        shouldRenderProductInstance = findPropertyInArray(formParams.blocks, "key", "product_instance");
        shouldRenderProductInstance = shouldRenderProductInstance && shouldRenderProductInstance.action !== "del";
    }

    let shouldRenderFeedback;
    if (![null, undefined].includes(formParams)) {
        const isContactPersonKey = findPropertyInArray(formParams.blocks, "key", "contactPerson");
        const isRestricted = get(appeal, "restriction.destinationFeedback") === 0;
        shouldRenderFeedback = isContactPersonKey && !isRestricted;
    } else {
        shouldRenderFeedback = false;
    }
    // permissions end

    async function handleInfoContactChange(selectedContacts, prevSelectedContacts) {
        try {
            let method = "";
            let ids = [];
            if (selectedContacts.length >= prevSelectedContacts.length) {
                // select some contact
                method = "post";
                ids = selectedContacts.filter(id => !prevSelectedContacts.includes(id));
            }
            if (selectedContacts.length < prevSelectedContacts.length) {
                // unselect some contact
                method = "delete";
                ids = prevSelectedContacts.filter(id => !selectedContacts.includes(id));
            }
            setIsDestinationFeedbackLoading(true);
            const response = await baseService[method]("appeal_info_contacts", {
                pathParams: { appealId: appeal.id },
                data: { id: ids },
                jsonType: true,
            });
            setIsDestinationFeedbackLoading(false);
            if (!response.success) {
                setPrevSelectedContacts(prevSelectedContacts);
                change(GET_FORM_NAME(appeal.id), "destinationFeedback", prevSelectedContacts);
            }
        } catch (e) {
            setIsDestinationFeedbackLoading(false);
            console.error("Appeal/Form::handleInfoContactChange: ", e);
        }
    }

    async function getInfoContacts() {
        setIsDestinationFeedbackLoading(true);
        if (!isDestinationFeedbackLoading) {
            try {
                const { result, success } = await baseService.get("appeal_info_contacts", {
                    pathParams: { appealId: appeal.id },
                });
                if (success) {
                    setInfoContacts(result);
                }
            } catch (e) {
                console.error("Appeal/Form::getInfoContacts: ", e);
            }
        }
        setIsDestinationFeedbackLoading(false);
        setIsInfoContactsOnceLoaded(true);
    }

    function handleAppealLock() {
        unlockAppealForm(LOCK_SOURCE_FORM);
    }

    const appealType = useMemo(() => {
        return formValues && formValues.type;
    }, [formValues]);

    const selectedContacts = useMemo(() => {
        return formValues && formValues.destinationFeedback;
    }, [formValues]);

    // set init destination options (while destinations list yet is not triggered and some dest is being selected inside of appeal)
    const selectedDestinationOptions = useMemo(() => {
        if (appealDestinationId && appealDestinationName) {
            return [
                {
                    text: appealDestinationName,
                    leaf: true,
                    expanded: false,
                    object: { id: appealDestinationId, text: appealDestinationName },
                },
            ];
        }
        return [];
    }, [appealDestinationId, appealDestinationName]);

    const destinationFeedbackValue = useMemo(() => {
        if (!formValues) {
            return [];
        }
        return formValues.destinationFeedback || [];
    }, [formValues]);
    

    const appealHeaderForm = useMemo(() => {
        return getAppealHeaderForm({
            appeal,
            t,
            checkRestrictions,
            shouldRenderField,
            priorityList,
            appealInfoComponent: HeaderAppealInfo,
        });
    }, [appeal, priorityList]);

    const appealForm = useMemo(() => {
        const subjectActions = selectedAppealAction.find(i => i.key === "subject");
        const descriptionActions = selectedAppealAction.find(i => i.key === "description");
        const destinationActions = selectedAppealAction.find(i => i.key === "destination");
        const solutionActions = selectedAppealAction.find(i => i.key === "solution");

        return getAppealForm({
            t,
            formParams,
            shouldRenderField,
            subjectActions,
            checkRestrictions,
            descriptionActions,
            appeal,

            infoContacts,
            getInfoContacts,

            shouldRenderProductInstance,
            shouldRenderFeedback,
            destinationFeedbackValue,
            isDestinationFeedbackLoading,

            destinations,
            destinationActions,
            selectedDestinationOptions,
            onDestinationOpen,
            canAddExecutors,
            loadingDestinations,

            solutionActions,

            resetActions,
        });
    }, [
        selectedAppealAction,
        formParams,
        appeal,
        infoContacts,
        isDestinationFeedbackLoading,
        loadingDestinations,
        destinations,
    ]);

    useEffect(() => {
        setPrevAppealType(appealType);
        if (prevAppealType) {
            resetAppealType(appealType, prevAppealType);
        }
    }, [appealType]);

    useEffect(() => {
        setPrevSelectedContacts(selectedContacts);
        if (prevSelectedContacts) {
            if (!isEqual(selectedContacts, prevSelectedContacts)) {
                handleInfoContactChange(selectedContacts, prevSelectedContacts);
            }
        }
    }, [selectedContacts]);

    useEffect(() => {
        getInfoContacts();
    }, []);

    // needReinitialize is fired after appeal save (post save)
    useEffect(() => {
        if (needReinitialize && !formForceInitValues) {

            // set new header values based on appeal response;
            if (appeal && formHeaderValues) {
                const newInitHeaderValues = formHeaderValues;
                const priority = get(appeal, 'priority.id');
                const resolveDate = get(appeal, 'resolveDate.resolveDate');
                if (priority) {
                    newInitHeaderValues.priority = priority;
                }
                if (resolveDate) {
                    newInitHeaderValues.resolveDate = resolveDate;
                }
                setForceInitialHeaderValues(newInitHeaderValues);
            }

            if (formValues) {
                setForceInitialValues(formValues);
            }

            // reinit main and header
            setInitValuesHeaderTimeStamp(new Date().getTime());
            setInitValuesTimeStamp(new Date().getTime());

            setNeedReinitialize(appeal.id, false);
        }
        if (needReinitialize) {
            getInfoContacts();
        }
    }, [needReinitialize]);

    // set forceInitialValues, clearup them from store, and set them to local state
    useEffect(() => {
        if (formForceInitValues) {
            // reinit main
            const mergedValues = { ...formValues, ...formForceInitValues };
            setForceInitialValues(mergedValues);
            setInitValuesTimeStamp(new Date().getTime());

            setFormForceInitValues(appeal.id, null);
        }
    }, [formForceInitValues]);

    useEffect(() => {
        // reinit main and header once formParams are changed
        setInitValuesHeaderTimeStamp(new Date().getTime());
        setInitValuesTimeStamp(new Date().getTime());
    }, [formParams]);


    // reset forceInitialValues once they were set
    useEffect(() => {
        if (forceInitialValues) {
            setForceInitialValues(null);
        }
    }, [forceInitialValues]);

    useEffect(() => {
        if (forceInitialHeaderValues) {
            setForceInitialHeaderValues(null);
        }
    }, [forceInitialHeaderValues]);

    // handling scroll to error
    // formCollapse / collapseState is handled via [mainId][currentId][collapseState]
    function handleFormCollapse(id, collapseState) {
        // const collapse = collapseState && collapseState[id];
        if (collapseState) {
            setFormCollapse({ [id]: collapseState });
        }
    }
    useEffect(() => {
        if (shouldScrollToError) {
            // find each block which contains an error and uncollapse it
            // console.log({formErrors, formCollapse});
            // const blockKeys = [...new Set(errors.map((error) => getBlockKey(error.key)))];
            const errorBlocksKeys = appealForm
                .filter(block => block.widgets.find(widget => widget.key in formErrors))
                .map(block => block.key);
            const uniqueErrorBlocksKeys = [...new Set(errorBlocksKeys)];
            // console.log({formErrors, uniqueErrorBlocksKeys, appealForm, offsetKey: uniqueErrorBlocksKeys[0]});
            const newFormCollapse = { ...formCollapse[props.appeal.id][props.appeal.id] };
            uniqueErrorBlocksKeys.forEach(key => {
                newFormCollapse[key] = false;
            });
            // make sure to handl it via [mainId][currentId]
            setFormCollapse({ [props.appeal.id]: { [props.appeal.id]: newFormCollapse } });
            setBlockOffsetKey(uniqueErrorBlocksKeys[0]); // trigger scroll to first block with error
            setIsRefreshFormCollapse(true);
            setShouldScrollToError(false);
        }
    }, [shouldScrollToError]);

    useEffect(() => {
        if (!blockOffsetKey) return;
        setBlockOffset(null);
        setBlockOffsetKey(null);
        appealFormlRef.current.scrollTop = blockOffset;
    }, [blockOffset]);

    return (
        <div className={cx("ordering-component-ui-core-wrapper appeal-form-wrapper")}>
            {(isLoading || !isInfoContactsOnceLoaded) && <Loader />}
            <div className="appeal-form" ref={appealFormlRef}>
                {appeal && appealHeaderForm && isInfoContactsOnceLoaded && (
                    <DynamicForm
                        className="appeal-header-form"
                        t={t}
                        formName={GET_FORM_HEADER_NAME(props.appeal.id)}
                        forceInitialValues={forceInitialHeaderValues}
                        dynData={appealHeaderForm}
                        objectId={props.appeal.id}
                        objectType="INTERACTION_REQUEST"
                        // uploadFileSource="form"
                        onFieldChange={handleAppealLock}
                        destroyOnUnmount={false}
                        currentId={props.appeal.id}
                        mainId={props.appeal.id}
                        maxFileSize={maxFileSize}
                        deleteFileWithoutRequest
                        withoutLiveSaver
                        key={`appeal-form-header-${initValuesHeaderTimeStamp}`}
                    />
                )}
                <LinkObjectsBlock
                    visible={shouldRenderLinkedObjects}
                    id={appeal.id}
                    relObjectTypes={REL_OBJECT_TYPES}
                />
                <LinkAppealBlock id={props.appeal.id} />
                {appeal && appealForm && isInfoContactsOnceLoaded && (
                    <DynamicForm
                        t={t}
                        formName={GET_FORM_NAME(props.appeal.id)}
                        forceInitialValues={forceInitialValues}
                        dynData={appealForm}
                        objectId={props.appeal.id}
                        objectType="INTERACTION_REQUEST"
                        uploadFileSource="form"
                        onFieldChange={handleAppealLock}
                        destroyOnUnmount={false}
                        currentId={props.appeal.id}
                        mainId={props.appeal.id}
                        maxFileSize={maxFileSize}
                        blockOffsetKey={blockOffsetKey}
                        setBlockOffset={setBlockOffset}
                        formCollapse={formCollapse}
                        setFormCollapse={handleFormCollapse}
                        isRefreshFormCollapse={isRefreshFormCollapse}
                        setIsRefreshFormCollapse={setIsRefreshFormCollapse}
                        deleteFileWithoutRequest
                        withoutLiveSaver
                        key={`appeal-form-${initValuesTimeStamp}`}
                    />
                )}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
