import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/selectTree.module.scss';
import CustomersIcon from '../../SVG/CustomersIcon';

class SelectTreeOption extends Component {
	
	handleMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const { option, selectLink, parentNode, onSelect, searching, onSearch } = this.props;
		
		if (searching) {
			setTimeout(() => onSearch(option), 0);
		}
		
		if (option.placeholder) {
			onSelect(null, event, option);
			if (parentNode.input !== undefined && parentNode.input.input !== undefined && (typeof(parentNode.input.input.blur) === 'Function'))
				setTimeout(() => parentNode.input.input.blur(), 0);
			
		} else {
			if (option.leaf || searching)
				onSelect(option, event);
			else
				selectLink(option);
		}
	};
	
	handleMouseEnter = (event) => this.props.onFocus(this.props.option, event);
	
	handleMouseMove = (event) => {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
	};
	resolvePartyTypeIcon = (partyType) => {
		let partyIcon = null;
		if (partyType === 'BUSINESSUNIT') {
			partyIcon = <CustomersIcon />;
		}
		if (partyType === 'EMP') {
			partyIcon = <i className='icon-customer-icon' />;
		}
		return partyIcon;
	};
	
	render () {
		const { option, className, children, searching, partyType } = this.props;
		const optionClassName = cx(
			className,
			styles.link,
			(!searching && !option.leaf && !option.placeholder) && styles.hasChild,
			option.selectedLink && styles.selectedLink,
			option.parent && styles.parent,
			option.child && styles.child,
			option.back && styles.back
		);
		return (
			<div
				className={optionClassName}
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				title={option.name}
			>
				{this.resolvePartyTypeIcon(partyType)}
				{children}
			</div>
		);
	}
}

SelectTreeOption.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object
};

export default SelectTreeOption;

