import React from 'react';
import {connect} from "react-redux";
import ModalPortal from 'components/ModalPortal';
import LinkForm from './LinkForm';
import { closeAppealLinkModal } from 'actions/ui';
import './style.scss';

const mapStateToProps = (state) => ({
	showAppealLinkModal: state.ui.showAppealLinkModal,
});

const mapDispatchToProps = {
	closeAppealLinkModal
};

function LinkAppeal(props) {
	const { closeAppealLinkModal, appeal, showAppealLinkModal } = props;

	return(
		<> {showAppealLinkModal ?
				<ModalPortal
					onClose={closeAppealLinkModal}
					className='modal-medium modal-center link-appeal'
				>
					<LinkForm appeal={appeal} />
				</ModalPortal>
		 : null}
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAppeal);
