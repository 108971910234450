import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader/index';
import GridWrapper from '../../../../custom-hooks/GridWrapper';
import { ModalWindow } from 'ui-core-dashboard';
import MessageModal from '../../common/MessageModal';
import baseService from '../../../../services/BaseService';


import { setApi } from 'reducers/grid';



const JobList = (props) => {
    const { filter, updateApiGrid, t, gridRefresh, setGridRefresh, handleFilesDownload } = props;

    const [loadingState, setLoadingState] = useState(true);

    const [isModalProcessing, setIsModalProcessing] = useState(false);

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        itemId: null,
        message: '',
        primaryButton: t('confirm'),
        secondaryButtonWithBorderText: t('cancel')
    });

    const init = {
        key: 'exportJobsList',
        page: 1,
        start: 0,
        limit: 14,
        filter,
    };
    const gridKey = `${init.key}_${init.path || ''}`;

    function openConfirmModal({message, primaryButton, itemId, action}) {
        setConfirmModal(confirmModal => (
            {
                ...confirmModal,
                open: true,
                message,        
                primaryButton,
                action,
                itemId,
            }
        ));
    }

    function closeConfirmModal() {
        setConfirmModal(confirmModal => ({ ...confirmModal, open: false }));
    };

    function handleJobStop(e, id) {
        // e.stopPropagation();

        openConfirmModal({
            message: t('wizard.stopExportJobConfirm'),        
            primaryButton: t('wizard.stop'),
            action: 'stop',
            itemId: id
        });
    }

    function handleJobDelete(e, id) {
        // e.stopPropagation();

        openConfirmModal({
            message: t('wizard.deleteExportJobConfirm'),
            primaryButton: t('wizard.delete'),
            action: 'delete',
            itemId: id
        });
    }

    function handleJobAction(e, id, action) {
        // console.log({id, action});
        const method = action === 'stop' ? 'post' : 'delete';
        const key = action === 'stop' ? 'exportJobIdAction' : 'exportJobId';
        const params = { 
            pathParams: 
            { id, action }, 
            data: {
                "progress": null,
                "description": null,
                "errorMessage": null
            }, 
            jsonType: true 
        };
        setIsModalProcessing(true);
        baseService[method](key, params).then(({success}) => {
            setIsModalProcessing(false);
            if (success) {
                setGridRefresh(true);
                closeConfirmModal();
            }
        }).catch(e => {
            setIsModalProcessing(false);
            console.log("ExportWizardModal/JobList::handleJobAction: Error ", e);
        });
    }

    const getParams = () => ({
        selectItem: () => {},
        fields: {
            files: {
                component: (props) => {
                    const { data, value } = props;
                    if (Array.isArray(value) && value.length > 0) {
                        return (
                            <button
                                type='submit'
                                className={'btn-save'}
                                onClick={() => handleFilesDownload(value)}
                            >
                               <i className={`icon icon-download`} />
                            </button>
                        );
                    }
                    return null;
                },
            },
            btnStop: {
                name: "wizard.stop",
                component: (props) => {
                    const { data: { id, btnStop }} = props;
                    if (!btnStop) {
                        return null;
                    }
                    return (
                        <button
                            title={t('wizard.stop')}
                            type='submit'
                            className={'btn-save'}
                            onClick={(e) => handleJobStop(e, id)}
                        >
                            <i className={`icon icon-times`} />
                        </button>
                    );
                }
            },
            btnDelete: {
                name: "wizard.delete",
                component: (props) => {
                    const { data: { id, btnDelete }} = props;
                    if (!btnDelete) {
                        return null;
                    }
                    return (
                        <button
                            title={t('wizard.delete')}
                            type='submit'
                            className={'btn-save'}
                            onClick={e => handleJobDelete(e, id)}
                        >
                        <i className={`icon icon-delete`} />
                        </button>
                    );
                }
            },
        },
    });

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
        if (gridRefresh) {
            updateApiGrid({ filter, page: 1 }, gridKey);
            setGridRefresh(false);
        }
    }, [gridRefresh]);

    useEffect(() => {
        filter && updateApiGrid({ filter, page: 1 }, gridKey);
    }, [filter]);

    return (
        <div className="content-wrapper with-fixed-status">
            {loadingState && <Loader />}
            <GridWrapper
                tuner={() => init}
                initUpdate={false}
                gridParams={getParams()}
                onStart={onStart}
                onFinish={onFinish}
                destroyOnUnmount
            />
            {confirmModal.open &&
                <ModalWindow
                    onClose={closeConfirmModal}
                    className='modal-small ordering-component-ui-core-wrapper'
                >
                    {isModalProcessing && <Loader />}
                    <MessageModal
                        titleModal={t('attention')}
                        contentModalText={t(confirmModal.message)}
                        dangerButton
                        dangerButtonText={t(confirmModal.primaryButton)}
                        onClickDangerButton={(e) => handleJobAction(e, confirmModal.itemId, confirmModal.action)}
                        secondaryButtonWithBorder
                        secondaryButtonWithBorderText={t(confirmModal.secondaryButtonWithBorderText)}
                        onClickSecondaryButton={closeConfirmModal}
                    />
                </ModalWindow>}
        </div>
    );
};

export default connect(null, { updateApiGrid: setApi })(withRouter(JobList));
