import React, { useState, useEffect, useRef } from "react";
import ReactSVG from "react-svg";
import { Form, Field, FieldArray, submit, getFormValues, getFormSyncErrors } from "redux-form";
import { reduxFormWrapper } from "helpers";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import { Input, ComboBox, CheckBox } from "ui-core-dashboard";
import cx from "classnames";
import { connect } from "react-redux";
import Loader from "../Loader";
import baseService from "services/BaseService";
import { showNotification } from "actions/ui";
import CalendarContainer from "../CustomDatePicker/CalendarContainer";
import CustomDatePicker from "../CustomDatePicker";
import FileInput from "../FileInput";

import FieldController from "../FieldController";
import Rank from "../Rank";
import Radio from "../Radio";
import ComboBoxMultipleCheckbox from "../ComboBoxMultipleCheckbox";
import FormFieldArray from "../FormFieldArray";
import ContactArrayComponent from "../ContactArrayComponent";
import AddressField from "../AddressField";
import TreeSelectModalWithSearch from "../TreeSelectModalWithSearch";
import SelectTreeAdd from "../SelectTreeAdd";
import TextArea from "../TextArea";
import validate from "./validate";

import store from 'store';

import { get, isEqual } from "lodash";

import { DATE_VIEW_FORMAT, DATE_TIME_VIEW_FORMAT } from "./constants";

import { getValueFromStr, getInitialValuesFromWidgets } from "./helpers";

import "./styles.scss";

const mapStateToProps = (state, ownProps) => {
    return {
        form: ownProps.formName, // set form name from ownProps
        // destroyOnUnmount: "destroyOnUnmount" in ownProps ? ownProps.destroyOnUnmount : true,
        isDestroyOnUnmount: "destroyOnUnmount" in ownProps ? ownProps.destroyOnUnmount : true,
        formValues: getFormValues(ownProps.formName)(state),
        formErrors: getFormSyncErrors(ownProps.formName)(state),
        active: state.form && state.form[ownProps.formName] && state.form[ownProps.formName].active,
        initialValues: state.form[ownProps.formName] && state.form[ownProps.formName].initial,
        regFields: state.form[ownProps.formName] && state.form[ownProps.formName].registeredFields,
    };
};

const mapDispatchToProps = {
    submitForm: form => submit(form),
    showNotification,
};

const AUTOSAVE_TYPES = ["radio", "fileSingle", "fileMultiple", "rank"];

// contact and selectTreeAdd using "custom local FieldController" due to it's array type
const WIDGETS_WITHOUT_CONTROLLER = ["checkbox", "address", "contact", "selectTreeAdd", 'selectTreeModalWithSearch', 'customComponent'];

// TODO: maybe add support of LiveSaver to this widgets later in ui-core, date and datetime need to have updated onBluer behavior
// const WIDGETS_WITH_LOCAL_LIVE_SAVER = [
//     'date',
//     'datetime',
//     'radio',
//     'rank',
//     'fileMultiple',
//     'multiselect',
//     'address',
// ];

/*
    DynamicForm main props:
    1. formName => give DynamicForm custom form name to check it later in store
    2. formValues => used to ref fields work properly (get value of ref field and return options)
    3. liveSave => turns on saving every field on blur/tab/enter/buttons click etc.
    4. mainId => id of main data (used for form collapse manipulation and update useEffect)
    5. currentId => id of current data (used for form collapse manipulation and liveSubmit)
    6. blockOffsetKey => fieldKey to calculate offset and scroll to error if any
    7. setBlockOffset => return offset height to error

*/

// function renderFieldArray

const FieldArrayComponent = props => {
    const {
        t,
        name,
        fields,
        widgets,
        arrayKey,
        renderWidgets,
        formValues,
        disabled,
        onFieldsRemove,
        // meta: { error, name, submitFailed },
    } = props;

    const initValues = widgets.reduce((acc, curr) => {
        let widget = curr;
        if (typeof curr === "function") {
            widget = widget(formValues);
        }
        if (!widget) {
            return acc;
        }
        return {
            ...acc,
            [widget.key]: null,
        };
    }, {});

    function removeFields(index) {
        if (onFieldsRemove && typeof onFieldsRemove === "function") {
            onFieldsRemove(arrayKey, index, initValues);
        }
        fields.remove(index);
    }
    return (
        <>
            {fields.map((field, index) => {
                return (
                    <>
                        {renderWidgets(widgets, arrayKey, index, field)}
                        {!disabled && (
                            <button
                                type="button"
                                className="remove-button add-button"
                                title={t("remove")}
                                onClick={() => removeFields(index)}
                            >
                                <i className="icon-delete" />
                            </button>
                        )}
                    </>
                );
            })}
            {!disabled && (
                <button type="button" className="add-button" title={t("add")} onClick={() => fields.push(initValues)}>
                    + {t("add")}
                </button>
            )}
        </>
    );
};

const DynamicForm = props => {
    const {
        t,
        initialize,
        dynData,
        handleSubmit,
        submitForm,
        initialValues,
        forceInitialValues,
        formName,
        formValues,
        formCollapse,
        className,
        change,
        refreshDynParams,
        setFormCollapse,
        blockOffsetKey,
        setBlockOffset,
        mainId,
        currentId,
        objectId,
        objectTypeSpec,
        objectType, // used for file upload and some ext params request strict typing
        liveSave,
        active,
        liveSaveIdKey = "taskId",
        liveSaveUrlKey = "save_order_param",
        liveSaveJSON = false,
        liveSaveWithoutChangeNum = false,
        formErrors,
        isLiveSaveOnlyValid,
        maxInputLength = 256,
        maxTextAreaLength = 20000,
        setIsLiveSubmitting,
        withoutCollapse = false,
        onLiveSaveSuccess,
        onLiveSaveStart,
        onLiveSaveFinish,
        isOnceInitialized,
        readonly,
        portalPlacement,
        // portalTopLimit,
        // portalBottomLimit,
        // showNotification = () => {},
        showNotification,
        regFields,
        onActions,
        // portalRootId,

        onFieldChange,
        uploadFileSource,
        submitFailed,
        isDestroyOnUnmount,
        deleteFileWithoutRequest,
        maxFileSize,
        isRefreshFormCollapse,
        setIsRefreshFormCollapse
    } = props;


    const [collapseState, setCollapseState] = useState({});
    const [ajaxOptions, setAjaxOptions] = useState({});
    const [ajaxInitOptions, setAjaxInitOptions] = useState({});
    const [extOptions, setExtOptions] = useState({});
    const [heights, setHeights] = useState(null);
    const [extOptionsLoading, setExtOptionsLoading] = useState({});
    const [forceDirtyToPristine, setForceDirtyToPristine] = useState({}); // force widget to be not dirty

    const [resetKeyFields, setResetKeyFields] = useState({});
    const [resetKeySavedValues, setResetKeySavedValues] = useState({});

    const [lastDeletedArrayFieldName, setLastDeletedArrayFieldName] = useState(null);

    const enterDisabledTypes = ["multiline"];
    const dynDataLength = dynData.length;

    const refs = useRef(dynData.map(React.createRef));

    const convertOptions = options => options.map(option => ({ label: option.value, value: option.key }));

    const getRefOptions = (options, refValue) => {
        if (!refValue) return convertOptions(options); // return default if not refValue selected;
        const refOptions = convertOptions(options.filter(option => option.refVal && option.refVal.includes(refValue)));
        return refOptions;
    };

    const toggleCollapse = key => {
        const widgetsCollapsed = { ...collapseState }; // spread to clone collapseObj
        widgetsCollapsed[key] = !widgetsCollapsed[key]; // toggle value by key
        setCollapseState(widgetsCollapsed);
    };

    const setOptionsQuery = debounce((searchStr, attributeKey, blockKey, widget) => {
        if (!searchStr || searchStr.length < 2) {
            return;
        }
        const optionsCallback = newOptions => {
            const initOptions = ajaxInitOptions[attributeKey] || [];
            const mergedWithInitOptions = [...newOptions, ...initOptions];
            setAjaxOptions(ajaxOptions => ({
                ...ajaxOptions,
                [attributeKey]: mergedWithInitOptions,
            }));
            // in case of no initOptions provided, and !destroyOnUnmount => set _cached_ajax_options
            if (initOptions.length === 0 && !isDestroyOnUnmount) {
                change(`${attributeKey}_cached_ajax_options`, mergedWithInitOptions);
            }
        };
        // handle customAjaxOptions
        if (widget.customAjaxOptions && typeof widget.customAjaxOptions === "function") {
            widget.customAjaxOptions(searchStr, optionsCallback, attributeKey, formValues);
            return;
        }
        // handle default ajax
        const params = {
            data: { attributeKey, blockKey, searchStr, page: 1, start: 0, limit: 25 },
        };
        baseService.post("appeal_form_ajax", params).then(({ success, result }) => {
            if (success) {
                const newOptions = convertOptions(result);
                optionsCallback(newOptions);
            } else {
                console.log("Ajax options request failed");
            }
        });
    }, 300);

    const getBlockVersionByField = key => {
        let version = null;
        dynData.forEach(block => {
            block.widgets.forEach(widget => {
                if (widget.key === key) {
                    version = block.version;
                }
            });
        });
        return version;
    };

    const checkDynDataForExt = fieldKey => {
        let isExt = false;
        dynData.forEach(block =>
            block.widgets.forEach(widget => {
                if (widget.key === fieldKey && widget.rule === "#[callMethod]") isExt = true;
            })
        );
        return isExt;
    };

    // simplified logic - simply set last deletedArrayFieldName and prevent from clearing not equal values of such reset field
    const handleFieldsRemove = (name, index, initValues) => {
        setLastDeletedArrayFieldName(`${name}[`);
    };

    const getExtValue = fieldKey => {
        let extValue = "";
        const { options } = extOptions[fieldKey];
        const selectedOption = options.filter(option => +option.value === +formValues[fieldKey])[0];
        extValue = selectedOption && selectedOption.value ? `${selectedOption.label}:${selectedOption.value}` : "";
        return extValue;
    };

    const handleLiveSubmit = customLiveSubmit => (key, responseCallback, isStringifyAttrValue) => {
        if (isLiveSaveOnlyValid && formErrors && formErrors[key]) {
            responseCallback(false);
            return;
        }
        const isExtField = checkDynDataForExt(key);
        const blockVersion = getBlockVersionByField(key);
        let urlKey = liveSaveUrlKey;
        let data = {
            data: {
                [liveSaveIdKey]: currentId,
                attrCode: key,
                attrValue: isExtField ? getExtValue(key) : formValues[key] || "",
            },
            jsonType: liveSaveJSON,
        };
        if (isStringifyAttrValue) {
            data.data.attrValue = JSON.stringify(data.data.attrValue);
        }

        if (customLiveSubmit && typeof customLiveSubmit === "function") {
            const customData = customLiveSubmit(data);
            if (customData && customData.data) {
                data = customData.data;
            }
            if (customData && customData.liveSaveUrlKey) {
                urlKey = customData.liveSaveUrlKey;
            }
        }

        if (!liveSaveWithoutChangeNum) {
            data.data.changeNum = blockVersion;
        }

        if (setIsLiveSubmitting && typeof setIsLiveSubmitting === "function") {
            setIsLiveSubmitting(true);
        }
        if (onLiveSaveStart && typeof onLiveSaveStart === "function") {
            onLiveSaveStart({ urlKey, data, key });
        }
        baseService
            .post(urlKey, data)
            .then(({ success, result }) => {
                if (onLiveSaveFinish && typeof onLiveSaveFinish === "function") {
                    onLiveSaveFinish({ urlKey, data, key });
                }
                responseCallback(success);
                if (setIsLiveSubmitting && typeof setIsLiveSubmitting === "function") {
                    setIsLiveSubmitting(false);
                }
                if (success) {
                    // check for dynParams refresh
                    if (result && result.actions && onActions && typeof onActions === "function") {
                        onActions(result.actions);
                    }
                    if (result && result.actions && result.actions.includes("formReload") && refreshDynParams) {
                        refreshDynParams();
                    }
                    if (onLiveSaveSuccess && typeof onLiveSaveSuccess === "function") {
                        // console.log({result, data});
                        onLiveSaveSuccess({
                            result,
                            data,
                            extOptions: extOptions[key],
                            ajaxOptions: ajaxOptions[key],
                            urlKey,
                        });
                    }
                } else {
                    console.log("Save param request failed");
                }
            })
            .catch(e => {
                if (onLiveSaveFinish && typeof onLiveSaveFinish === "function") {
                    onLiveSaveFinish({ urlKey, data, key });
                }
                if (setIsLiveSubmitting && typeof setIsLiveSubmitting === "function") {
                    setIsLiveSubmitting(false);
                }
                console.error("DynamicForm::handleLiveSubmit: Error:", e);
            });
    };

    const handleFileUpload = (file, handleUploadResponse, inputKey, onConditionFileUpload, widgetUploadFileSource) => {
        const data = {
            data: {
                data: {
                    objectId: objectId || +currentId,
                    objectType: objectType || "TASK",
                    fileName: file.name,
                    attrCode: inputKey,
                },
                file,
            },
            file: true,
        };
        const fileSource = widgetUploadFileSource || uploadFileSource;
        // source=form payload param should be added in case files are being uploaded via dynamic params
        // to distinguish the file upload source
        if (fileSource) {
            data.data.data.source = fileSource;
        }
        return baseService.post("save_file", data).then(({ success, result }) => {
            if (success) {
                // in case of !destroyOnUnmount => set _cached_file_values and update inputKey files (to keep values in unmouned state)
                if (!isDestroyOnUnmount) {
                    // get formValues in such way, because component "formValues" props could be outdated and stale
                    // due to unmounted component
                    const state = store.getState();
                    const formValues = state && state.form && state.form[formName] && state.form[formName].values;
                    if (result) {
                        const prevCachedFiles = formValues[`${inputKey}_cached_file_values`] || [];
                        const uploadedFile = { key: result.id, value: result.fileName };
                        const mergedCachedFiles = [uploadedFile, ...prevCachedFiles];
                        change(`${inputKey}_cached_file_values`, mergedCachedFiles);
                    }
                    const prevValue = formValues && formValues[inputKey];
                    let prevFiles = [];
                    if (prevValue) {
                        if (typeof prevFiles === 'string') {
                            prevFiles = [prevValue];
                        }
                        if (Array.isArray(prevValue)) {
                            prevFiles = prevValue;
                        }
                    }
                    const newFiles = [result.id, ...prevFiles];
                    change(inputKey, newFiles);
                }
                handleUploadResponse(file, result.id || result, "", onConditionFileUpload);
            } else {
                console.log("Uploading file request failed");
                handleUploadResponse(file, null, "Error during file upload");
            }
        }).catch(e => {
            console.log("Uploading file request failed", e);
            handleUploadResponse(file, null, "Error during file upload");
        });
    };

    const handleFileDelete = (fileId, handleDeleteResponse) => {
        if (deleteFileWithoutRequest) {
            handleDeleteResponse(fileId);
        } else {
            const data = { data: { fileId } };
            baseService.post("delete_file", data).then(({ success }) => {
                if (success) {
                    handleDeleteResponse(fileId);
                } else {
                    console.log("Delete file request failed");
                    handleDeleteResponse(fileId, "Error during file delete");
                }
            });
        }
    };

    const getViewClass = view => {
        switch (view) {
            case "2column": {
                return "two-column";
            }
            case "3column": {
                return "three-column";
            }
            default: {
                return "form";
            }
        }
    };

    // savedFiles in format: "key|key"
    // fileValues in format [{key, value}]
    // cachedFileValues in format [{key, value}]
    // cachedFileValues is currently used to handle file upload from kb
    const getUploadedFiles = (formSavedFiles = [], fileValues, cachedFileValues) => {
        let savedFiles;
        if (formSavedFiles && Array.isArray(formSavedFiles)) {
            savedFiles = formSavedFiles.map(file => {
                if (typeof file === 'object') {
                    // get either id or key to make an array [{fileId}];
                    return file.id || file.key;
                }
                return file;
            });
        }
        let mergedValues = [];
        if (fileValues) {
            mergedValues = [...mergedValues, ...fileValues];
        }
        if (cachedFileValues) {
            mergedValues = [...mergedValues, ...cachedFileValues];
        }
        if (!savedFiles || savedFiles.length === 0) return [];

        if (cachedFileValues && cachedFileValues.length > 0) {
            // find unique files (to prevent duplicates due to cachedFileValues)
            const mergedKeys = mergedValues.map(val => val.key);
            mergedValues = [...new Set(mergedKeys)].map(key => mergedValues.find(val => val.key === key));
        }
        const uploadedFiles = mergedValues.filter(value => savedFiles.includes(value.key));
        return uploadedFiles;
    };

    const updateExtOptions = (fieldKey, newOptions) => {
        const field = extOptions[fieldKey];
        const { initialOptions, refKey } = field;
        const refValue = formValues[refKey];
        setExtOptions(options => ({
            ...options,
            [fieldKey]: {
                ...options[fieldKey],
                savedRefValue: refValue,
                options: newOptions || initialOptions,
            },
        }));
    };

    const handleFieldChange = (...args) => {
        if (onFieldChange && typeof onFieldChange === "function") {
            onFieldChange(...args);
        }
    };

    const handleExtParamsRequest = (fieldKey, params) => {
        baseService.post("get_external_params", params).then(({ success, result }) => {
            setExtOptionsLoading(extOptionsLoading => ({
                ...extOptionsLoading,
                [fieldKey]: false,
            }));
            if (success) {
                if (!isEmpty(result[fieldKey][0])) {
                    const resultOptions = result[fieldKey].map(option => ({
                        label: option.id,
                        value: option.value,
                    }));
                    updateExtOptions(fieldKey, resultOptions);
                }
            } else {
                console.log("External / REST options request failed");
            }
        });
    };

    const getSelectedExtOption = fieldKey => {
        const extFieldOptions = extOptions[fieldKey].options;
        const extFieldValue = formValues[fieldKey];
        return extFieldOptions.filter(option => option.value === extFieldValue)[0];
    };

    const getDirtyExtVals = () => {
        let obj = {};
        Object.keys(formValues).map(key => {
            if (extOptions[key]) {
                const selectedOption = getSelectedExtOption(key);
                obj = {
                    ...obj,
                    [key]:
                        selectedOption && selectedOption.value
                            ? `${selectedOption.label}:${selectedOption.value}`
                            : null,
                };
            }
        });
        return obj;
    };

    const getExtFilterRefVal = fieldKey => {
        if (extOptions[fieldKey] && extOptions[fieldKey].refKey) {
            const { refKey } = extOptions[fieldKey];
            if (extOptions[refKey]) {
                const selectedOption = getSelectedExtOption(refKey);
                return selectedOption && selectedOption.label;
            }
            return formValues[refKey];
        }
    };

    const handleExtSelectOpen = fieldKey => {
        setExtOptionsLoading(extOptionsLoading => ({ ...extOptionsLoading, [fieldKey]: true }));
        const params = {
            data: {
                dirtyVals: { ...formValues, ...getDirtyExtVals() },
                filterVal: getExtFilterRefVal(fieldKey) || formValues[fieldKey],
                objectId: objectId || currentId,
                objectType: objectType || "task",
                objectTypeSpec,
                objectVal: null,
                paramId: fieldKey,
            },
            jsonType: true,
        };
        handleExtParamsRequest(fieldKey, params);
    };

    // reset ref/extRef fields if parent field changed
    const handleExtRefChange = (fieldKey, refKey, rule) => {
        const currentValue = formValues[fieldKey];
        const currentRefValue = formValues[refKey];
        const { savedRefValue, initialOptions } = extOptions[fieldKey];
        const isExt = rule === "#[callMethod]";

        const isEmptySaved = [null, undefined, ""].includes(savedRefValue);
        const isEmptyCurrent = [null, undefined, ""].includes(currentRefValue);
        const isSavedOrCurrNotEmpty = !isEmptySaved || !isEmptyCurrent;

        const isEmptyCurrentValue = [null, undefined, ""].includes(currentValue);

        if (isSavedOrCurrNotEmpty && savedRefValue !== currentRefValue) {
            // handle case for changing ref field while current value is not selected yet => update ext options
            if (isEmptyCurrentValue) {
                if (isExt) {
                    updateExtOptions(fieldKey, []);
                } else {
                    updateExtOptions(fieldKey, getRefOptions(initialOptions, currentRefValue));
                }
                return;
            }
            // handle case when current value was selected and should be cleared up
            if (isExt) {
                change(fieldKey, "");
                if (savedRefValue) {
                    setForceDirtyToPristine(keys => ({ ...keys, [fieldKey]: true }));
                }
                updateExtOptions(fieldKey, []);
            } else {
                // additional check for ref options
                const refOptions = convertOptions(
                    initialOptions.filter(option => option.refVal && option.refVal.includes(currentRefValue))
                );
                const refValues = refOptions.map(option => option.value);
                // case: curValue is 10, refValue changed so curValue 10 is not matching any options ('1', '2', '3')
                if (refOptions.length !== 0 && !refValues.includes(currentValue)) {
                    change(fieldKey, "");
                    setForceDirtyToPristine(keys => ({ ...keys, [fieldKey]: true }));
                }
                // case: refValue is empty and currentValue is filled - cleared up
                if (
                    refValues.length === 0 &&
                    ![null, undefined, ""].includes(currentValue) &&
                    [null, undefined, ""].includes(currentRefValue)
                ) {
                    change(fieldKey, "");
                    setForceDirtyToPristine(keys => ({ ...keys, [fieldKey]: true }));
                }
                updateExtOptions(fieldKey, getRefOptions(initialOptions, currentRefValue));
            }
        }
    };

    // 'label:label:label:value' => 'label:label:label'
    const getLabelFromStr = stringValue => {
        if (!stringValue) return null;
        const arrayValue = stringValue.split(":");
        return arrayValue.slice(0, arrayValue.length - 1).join(":"); // get all accept last value and join it into string
    };

    const convertExtValues = values =>
        values.map(val => ({
            value: getValueFromStr(val.key),
            label: getLabelFromStr(val.value),
        }));

    // handle convert back string value into timestamp
    const handleDatePickerChange = ({ type, value, key }) => {
        // console.log({ type, value, key });
        const format = type === "datetime" ? DATE_TIME_VIEW_FORMAT : DATE_VIEW_FORMAT;

        const isValid = moment(value, format).format(format) === value;
        if (isValid) {
            const unixTS = moment(value, format).unix();
            setTimeout(() => change(key, `${unixTS * 1000}`), 0);
        }
    };

    const processResetWidgetKey = (resetKey, widgetKey) => {
        const resetFields = get(resetKeyFields, resetKey, []);
        if (!resetFields.includes(widgetKey)) {
            setResetKeyFields(prev => {
                const prevFields = get(prev, resetKey, []);
                return {
                    ...prev,
                    [resetKey]: [...prevFields, widgetKey],
                };
            });
        }
    };

    const renderField = data => {
        const {
            widgetType,
            key: widgetKey,
            blockKey,
            title,
            isReq,
            values,
            refKey,
            savedValue,
            rule,
            clearable,
            maxLength,
            handleContactChange,
            inputType = "text",
            hasExValue = false,
            extensions,
            resetKey,
            isUploadByCondition,
            uploadCondition,
            onConditionFileUpload,
            withMarkdown,
            autosize,
        } = data;

        const isExt = rule === "#[callMethod]";

        const disabled = readonly || data.disabled;

        const key = widgetKey;

        if (resetKey) {
            if (Array.isArray(resetKey)) {
                resetKey.forEach(resKey => processResetWidgetKey(resKey, widgetKey));
            } else {
                processResetWidgetKey(resetKey, widgetKey);
            }
        }

        const defaultProps = {
            key,
            name: key,
            label: title,
            disabled,
            required: isReq,
            rule,
            clearable,
            hasExValue,
            withMarkdown,
            showNotification,
            widgetType,
            onFieldChange
        };

        if (onFieldChange && typeof onFieldChange === "function") {
            defaultProps.onChange = handleFieldChange;
        }

        // additional disabled props to make input scrollable in disabled state
        const disabledInputProps = disabled ? { input: { value: (formValues && formValues[key]) || "" } } : {};

        const defaultPropsDate = {
            saveFormat: "x",
            locale: "uk",
            popperContainer: props => <CalendarContainer {...props} fieldKey={key} />,
            showMonthDropdown: true,
            showYearDropdown: true,
        };

        // either get initOptions, or get _cached_ajax_options
        const initAjaxOptions = ajaxInitOptions[key] && ajaxInitOptions[key].length > 0  ?  ajaxInitOptions[key] : formValues[`${key}_cached_ajax_options`];
        const defaultPropsAjax = {
            placeholder: t("searchPhrase"),
            openOnClick: false,
            options: ajaxOptions[key] && ajaxOptions[key].length ? ajaxOptions[key] : initAjaxOptions,
            onInputChange: query => setOptionsQuery(query, key, blockKey, data),
            filterOption: () => true, // options are filtered already by string on backend side, return all options by default
            noResultsText: t("noItems"),
            clearable: false,
            searchable: true,
        };

        let defaultPropsTreeModalWithSearch = {};

        if (widgetType === "selectTreeModalWithSearch") {
            defaultPropsTreeModalWithSearch = {
                placeholder: data.placeholder || t("placeholderSelectTreeModalWithSearch"),
                initNodeArray: data.initNodeArray,
                nodeArrayApiKey: data.nodeArrayApiKey, // "appeal_type",
                nodeArrayResultKey: data.nodeArrayResultKey, // "children",
            };
        }

        let defaultPropsSelectTreeAdd = {};

        if (widgetType === "selectTreeAdd") {
            defaultPropsSelectTreeAdd = {
                placeholder: data.placeholder || t("placeholderSelectTreeAdd"),
                options: data.options,
                unlockAppealForm: data.unlockAppealForm,
                onFieldRemove: data.onFieldRemove,
                additionDisabled: data.additionDisabled,
                isLoading: data.isLoading,
            };
        }

        let onDatePickerChange;
        if (["date", "datetime"].includes(widgetType)) {
            onDatePickerChange = (value, ...rest) => {
                if (defaultProps.onChange && typeof defaultProps.onChange === 'function') {
                    defaultProps.onChange(value, ...rest);
                }
                return handleDatePickerChange({ type: widgetType, value, key });
            };
        }

        switch (widgetType) {
            case "input": {
                return (
                    <Field
                        {...defaultProps}
                        {...disabledInputProps}
                        className={cx("input-field", { disabled })}
                        disabled={false} // always false, disable typing by class and disabledInpusProps
                        spellCheck={false} // remove red hightlight
                        maxLength={maxLength || maxInputLength}
                        type={inputType}
                        component={Input}
                    />
                );
            }
            case "select": {
                return (
                    <Field
                        {...defaultProps}
                        placeholder={t("dontSelect")}
                        noResultsText={t("noItems")}
                        clearValueText={t("clearValue")}
                        options={
                            refKey || rule === "#[callMethod]"
                                ? (extOptions[key] && extOptions[key].options) || []
                                : convertOptions(values)
                        }
                        onOpen={isExt ? () => handleExtSelectOpen(key) : null}
                        disabled={disabled || (isExt && refKey && formValues && !formValues[refKey])} // disabled check for ext ref selects
                        isLoading={extOptionsLoading[key]}
                        escapeClearsValue={!liveSave}
                        component={ComboBox}
                    />
                );
            }
            case "searchbox":
            case "ajaxCombo": {
                return (
                    <Field {...defaultProps} {...defaultPropsAjax} escapeClearsValue={!liveSave} component={ComboBox} />
                );
            }
            case "multiline": {
                return (
                    <Field
                        {...defaultProps}
                        textAreaClassName={data.textAreaClassName}
                        autosize={autosize}
                        maxLength={maxLength || maxTextAreaLength}
                        component={TextArea}
                    />
                );
            }
            case "date": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsDate}
                        viewFormat={DATE_VIEW_FORMAT}
                        yearDropdownItemNumber={108}
                        hasTimePicker={false}
                        onChange={onDatePickerChange}
                        component={CustomDatePicker}
                    />
                );
            }
            case "datetime": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsDate}
                        viewFormat={DATE_TIME_VIEW_FORMAT}
                        yearDropdownItemNumber={108}
                        onChange={onDatePickerChange}
                        component={CustomDatePicker}
                    />
                );
            }
            case "radio": {
                return <Field {...defaultProps} values={values} component={Radio} />;
            }
            case "rank": {
                return <Field {...defaultProps} values={values} component={Rank} />;
            }
            case "multiselect": {
                let onMultiSelectOpen = isExt ? () => handleExtSelectOpen(key) : null;
                if (!onMultiSelectOpen && data.onOpen && typeof data.onOpen === "function") {
                    onMultiSelectOpen = data.onOpen;
                }
                return (
                    <Field
                        {...defaultProps}
                        placeholder={t("dontSelect")}
                        noResultsText={t("noItems")}
                        clearValueText={t("clearValue")}
                        options={
                            refKey || rule === "#[callMethod]"
                                ? (extOptions[key] && extOptions[key].options) || []
                                : convertOptions(values)
                        }
                        onOpen={onMultiSelectOpen}
                        disabled={disabled || (isExt && refKey && formValues && !formValues[refKey])} // disabled check for ext ref selects
                        isLoading={extOptionsLoading[key] || data.isLoading}
                        escapeClearsValue={!liveSave}
                        component={ComboBoxMultipleCheckbox}
                        multi
                    />
                );
            }
            // fileMultiple
            case "fileMultiple": {
                return (
                    <Field
                        {...defaultProps}
                        t={t}
                        translate={{
                            size: t("files.size"),
                            maxSize: t("files.maxSize"),
                            ext: t("files.ext"),
                            extAllowed: t("files.extAllowed"),
                            button: t("add"),
                            attention: t("files.attention"),
                            confirmText: t("files.confirmText"),
                            yes: t("files.yes"),
                            no: t("files.no"),
                        }}
                        maxFileSize={maxFileSize || data.maxSize || 25}
                        extensions={[]}
                        handleUpload={handleFileUpload}
                        handleDelete={handleFileDelete}
                        loader={<Loader loaderContainerClass="container-loader" loaderClass="input-loader" />}
                        files={getUploadedFiles(formValues[key] || savedValue, values, formValues[`${key}_cached_file_values`])}
                        path="../mw/file?fileId="
                        multiple
                        isStringifyAttrValue
                        entityId={objectId || +currentId}
                        component={FileInput}
                    />
                );
            }

            case "fileSingle": {
                return (
                    <Field
                        {...defaultProps}
                        t={t}
                        translate={{
                            size: t("files.size"),
                            maxSize: t("files.maxSize"),
                            ext: t("files.ext"),
                            extAllowed: t("files.extAllowed"),
                            button: t("add"),
                            attention: t("files.attention"),
                            confirmText: t("files.confirmText"),
                            yes: t("files.yes"),
                            no: t("files.no"),
                        }}
                        maxFileSize={maxFileSize || data.maxSize || 25}
                        extensions={extensions || []}
                        handleUpload={handleFileUpload}
                        handleDelete={handleFileDelete}
                        loader={<Loader loaderContainerClass="container-loader" loaderClass="input-loader" />}
                        files={getUploadedFiles(formValues[key] || savedValue, values, formValues[`${key}_cached_file_values`])}
                        path="../mw/file?fileId="
                        isUploadByCondition={isUploadByCondition}
                        uploadCondition={uploadCondition}
                        onConditionFileUpload={onConditionFileUpload}
                        isStringifyAttrValue
                        entityId={objectId || +currentId}
                        component={FileInput}
                    />
                );
            }

            case "checkbox": {
                return <Field {...defaultProps} component={CheckBox} />;
            }
            case "address": {
                return <Field {...defaultProps} value={savedValue} savedValue={savedValue} component={AddressField} />;
            }

            case "contact": {
                return (
                    <FormFieldArray
                        {...defaultProps}
                        readOnly={disabled}
                        isSaveOnlyValid={isLiveSaveOnlyValid}
                        handleChangeValue={handleContactChange}
                        component={ContactArrayComponent}
                    />
                );
            }

            case "selectTreeModalWithSearch": {
                return (
                    <Field
                        {...defaultProps}
                        {...defaultPropsTreeModalWithSearch}
                        component={TreeSelectModalWithSearch}
                    />
                );
            }

            case "selectTreeAdd": {
                return (
                    <FormFieldArray
                        isSaveOnlyValid={isLiveSaveOnlyValid}
                        {...defaultProps}
                        {...defaultPropsSelectTreeAdd}
                        component={SelectTreeAdd}
                    />
                );
            }

            case "customComponent": {
                const Component = data && data.component;
                if (Component) {
                    return <Component />
                }
                return null;
            }

            default: {
                console.log("Widget type is not founded/supported:", widgetType);
                return null;
            }
        }
    };

    const getFieldControllerProps = data => {
        const {
            widgetType,
            // key: widgetKey,
            key,
            refKey,
            values,
            rule,
            clearable,
            customLiveSubmit,
        } = data;

        const disabled = readonly || data.disabled;

        let options = null;
        if (["select", "multiselect"].includes(widgetType)) {
            options =
                refKey || rule === "#[callMethod]"
                    ? (extOptions[key] && extOptions[key].options) || []
                    : convertOptions(values);
        }
        if (["searchbox", "ajaxCombo"].includes(widgetType)) {
            // either get initOptions, or get _cached_ajax_options
            const initAjaxOptions = ajaxInitOptions[key] && ajaxInitOptions[key].length > 0  ?  ajaxInitOptions[key] : formValues[`${key}_cached_ajax_options`];
            options = ajaxOptions[key] && ajaxOptions[key].length ? ajaxOptions[key] : initAjaxOptions;
        }

        const controllerProps = {
            widgetType,
            fieldKey: key,
            initValue: forceInitialValues ? get(forceInitialValues, key) : get(initialValues, key),
            value: get(formValues, key),
            error: get(formErrors, key, ""),
            change,
            loader: <Loader loaderContainerClass="container-loader" loaderClass="input-loader" />,
            autoSave: AUTOSAVE_TYPES.includes(widgetType) || false,
            isForceDirtyToPristine: get(forceDirtyToPristine, key) || false,
            onDirtyReset: () => setForceDirtyToPristine(keys => ({ ...keys, [key]: false })),
            enterDisabled: enterDisabledTypes.includes(widgetType),
            disabled,
            clearable,
            portalPlacement,

            showNotification,
            selectOptions: options,
            isSaveOnlyValid: isLiveSaveOnlyValid,
        };

        if (liveSave) {
            controllerProps.handleLiveSubmit = customLiveSubmit
                ? handleLiveSubmit(customLiveSubmit)
                : handleLiveSubmit();
        }
        return controllerProps;
    };

    const renderWidgets = (widgets, key, arrayIndex, field) => {
        return widgets.map(item => {
            let widget = item;
            if (typeof widget === "function") {
                widget = widget(formValues, arrayIndex);
            }
            if (!widget) {
                return;
            }
            // field => FieldArray logic
            const widgetKey = field ? `${field}.${widget.key}` : widget.key;
            return (
                <div
                    className={cx("widget-wrapper", {
                        hidden: widget.isHidden,
                        [widget.customWidth]: widget.customWidth,
                        [widget.customHeight]: widget.customHeight,
                    })}
                >
                    {/* {widget.disabled || WIDGETS_WITHOUT_CONTROLLER.includes(widget.widgetType) ? ( */}
                    {WIDGETS_WITHOUT_CONTROLLER.includes(widget.widgetType) ? (
                        renderField({ ...widget, field, blockKey: key, key: widgetKey })
                    ) : (
                        <FieldController {...getFieldControllerProps({ ...widget, key: widgetKey })}>
                            {renderField({ ...widget, field, blockKey: key, key: widgetKey })}
                        </FieldController>
                    )}
                </div>
            );
        });
    };

    const renderDynParams = (data, index) => {
        if (!collapseState) return;
        const { key, title: initTitle, widgets, view, description, filter, isHiddenBlock, disabled, isFieldArray } = data;
        let trimmedTitle = initTitle && initTitle.trim();
        let title = trimmedTitle === '*' ? null : trimmedTitle;
        const isOpened = !collapseState[key];
        const blockClass = isOpened ? "block-wrapper open" : "block-wrapper";
        const isTitleAvailable = title || filter === "IN" || filter === "OUT";
        const headerClass = isTitleAvailable ? "field-header" : "field-header no-title";
        const fieldsClass = isTitleAvailable
            ? `fields-wrapper ${getViewClass(view)}`
            : `fields-wrapper open no-title ${getViewClass(view)}`;

        // do not render empty widgets
        if (Array.isArray(widgets) && widgets.length === 0) {
            return;
        }

        if (withoutCollapse) {
            return (
                <div
                    className={isHiddenBlock ? "block-wrapper removed" : "block-wrapper displayed"}
                    style={{ zIndex: dynDataLength - index }}
                    key={key}
                >
                    <div
                        className={cx({ [fieldsClass]: fieldsClass, isFieldArray })}
                        ref={refs.current[index]}
                        key={`${key}_content`}
                    >
                        {/* adding blockKey value to widget params (for ajaxCombo reference request) */}
                        {isFieldArray ? (
                            <FieldArray
                                t={t}
                                name={key}
                                widgets={widgets}
                                arrayKey={key}
                                renderWidgets={renderWidgets}
                                formValues={formValues}
                                disabled={disabled}
                                onFieldsRemove={handleFieldsRemove}
                                component={FieldArrayComponent}
                            />
                        ) : (
                            renderWidgets(widgets, key)
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div key={key} data-key={key} className={blockClass} style={{ zIndex: dynDataLength - index }}>
                <div key={`${key}_header`} className={headerClass} onClick={() => isTitleAvailable && toggleCollapse(key)}>
                    <div className="title">
                        {/* <i className="icon icon-down" /> */}
                        <ReactSVG className="chevron-svg" src="/data/svg/chevron.svg" />
                        {filter === "IN" && <ReactSVG className="filter-svg" src="/data/svg/params-in.svg" />}
                        {filter === "OUT" && <ReactSVG className="filter-svg" src="/data/svg/params-out.svg" />}
                        {title}
                    </div>
                    {isOpened && description && <div className="description">{description}</div>}
                </div>
                <div
                    className={cx({ [fieldsClass]: fieldsClass, isFieldArray })}
                    ref={refs.current[index]}
                    key={`${key}_content`}
                >
                    {/* adding blockKey value to widget params (for ajaxCombo reference request) */}
                    {isFieldArray ? (
                        <FieldArray
                            t={t}
                            name={key}
                            widgets={widgets}
                            renderWidgets={renderWidgets}
                            arrayKey={key}
                            formValues={formValues}
                            disabled={disabled}
                            onFieldsRemove={handleFieldsRemove}
                            component={FieldArrayComponent}
                        />
                    ) : (
                        renderWidgets(widgets, key)
                    )}
                </div>
                {/* <div className="field-border" /> */}
            </div>
        );
    };

    useEffect(() => {
        const widgets = dynData.reduce((prev, curr) => [...prev, ...curr.widgets], []);
        if (forceInitialValues) {
            // const defaultValues = getDefaultValuesFromWidgets(widgets);
            const widgetValues = getInitialValuesFromWidgets(widgets, isOnceInitialized);
            const mergedValues = { ...forceInitialValues};
            // in some cases forceInitialValues does not include widgets saved or default values, check for them here
            // if there is no value in forceInitValue try to get it from widgetValues
            Object.keys(widgetValues).forEach(key => {
                const currentValue = mergedValues[key];
                const widgetValue = widgetValues[key];
                const isCurrentValueEmpty = [null, undefined, ""].includes(currentValue) || (Array.isArray(currentValue) && currentValue.length === 0);
                const isWidgetValueAvailable = ![null, undefined, ""].includes(widgetValue);
                // fallback to the widget value
                if (isCurrentValueEmpty && isWidgetValueAvailable) {
                    mergedValues[key] = widgetValue;
                }
            });
            initialize(mergedValues);
        } else {
            const isKeepOnUnmount = 'isDestroyOnUnmount' in props ? !props.isDestroyOnUnmount : false;
            const shouldInitialize = !(initialValues && isKeepOnUnmount);
            if (shouldInitialize) {
                const initializeValues = getInitialValuesFromWidgets(widgets, isOnceInitialized);
                initialize(initializeValues);
            }
        }

        // const extWidgets = widgets.filter(widget => widget.rule === '#[callMethod]');

        const refWidgets = widgets.filter(widget => widget.refKey);

        const refWidgetsOptions = refWidgets.reduce((prev, curr) => {
            const savedRefValue = widgets.filter(widget => widget.key === curr.refKey)[0].savedValue;
            const { rule, refKey, key, values } = curr;
            const isExt = rule === "#[callMethod]";
            return {
                ...prev, // set initialValues in redux form in format { key: value }
                [key]: {
                    rule,
                    refKey,
                    initialOptions: isExt ? convertExtValues(values) : values,
                    options: isExt ? convertExtValues(values) : getRefOptions(values, savedRefValue),
                    savedRefValue: isExt ? getValueFromStr(savedRefValue) : savedRefValue,
                },
            };
        }, {});

        const extWidgets = widgets.filter(widget => widget.rule === "#[callMethod]" && !widget.refKey);

        const extWidgetsOptions = extWidgets.reduce((prev, curr) => {
            const { rule, key, values } = curr;
            return {
                ...prev, // set initialValues in redux form in format { key: value }
                [key]: {
                    rule,
                    initialOptions: convertExtValues(values),
                    options: convertExtValues(values),
                },
            };
        }, {});

        const ajaxWidgets = widgets.filter(widget => widget.widgetType === "ajaxCombo");

        const ajaxWidgetsOptions = ajaxWidgets.reduce((prev, curr) => {
            const { key, values } = curr;
            return {
                ...prev,
                [key]: convertOptions(values),
            };
        }, {});

        setAjaxInitOptions(ajaxWidgetsOptions);

        setExtOptions({ ...refWidgetsOptions, ...extWidgetsOptions });

        // set collapsed from store or init local initial state (either pass it to store or leave local)
        if (
            setFormCollapse &&
            formCollapse &&
            !isEmpty(formCollapse[mainId]) &&
            !isEmpty(formCollapse[mainId][currentId])
        ) {
            setCollapseState(formCollapse[mainId][currentId]);
        } else {
            const initialCollapse = dynData.reduce((prev, curr) => ({ ...prev, [curr.key]: !!+curr.collapsed }), {});
            setCollapseState(initialCollapse);
        }

        // init hidden values widgets
        const hiddenValuesWidgets = widgets.filter(widget => widget.isHiddenByConfig && widget.hiddenValues);
        // console.log({hiddenValuesWidgets});
        hiddenValuesWidgets.forEach(widget => {
            change(widget.key, widget.hiddenValues);
        });
    }, []);


    // refresh collapseStatee in case isRefreshFormCollapse is provided
    useEffect(() => {
        if (
            setFormCollapse &&
            setIsRefreshFormCollapse &&
            typeof setIsRefreshFormCollapse === 'function' && 
            formCollapse &&
            !isEmpty(formCollapse[mainId]) &&
            !isEmpty(formCollapse[mainId][currentId])
        ) {
            setIsRefreshFormCollapse(false);
            setCollapseState(formCollapse[mainId][currentId]);
        }
    }, [isRefreshFormCollapse]);

    useEffect(() => {
        // listen to refs ready state (if ref.current === null then return)
        if (!heights && refs.current.some(ref => ref.current)) {
            const { current } = refs;
            setHeights(
                current.reduce((prev, curr) => {
                    // probably block with no widgets is rendered
                    if (!curr || !curr.current) {
                        return prev;
                    }
                    return {
                        ...prev,
                        [curr.current.parentElement.getAttribute("data-key")]: curr.current.offsetHeight + 12,
                    };
                }, {})
            );
        }
    });

    useEffect(() => {
        if (!isEmpty(collapseState) && setFormCollapse) {
            setFormCollapse(
                mainId,
                formCollapse ? { ...formCollapse[mainId], [currentId]: collapseState } : { [currentId]: collapseState }
            );
        }
    }, [collapseState]);

    useEffect(() => {
        if (blockOffsetKey) {
            const filteredBlock = refs.current.filter(ref => {
                return ref.current.parentElement.getAttribute("data-key") === blockOffsetKey;
            });
            // console.log({filteredBlock});
            const {
                current: { parentElement },
            } = filteredBlock[0];
            
            // console.log({parentElement, offsetTop: parentElement.offsetTop, height: parentElement.clientHeight});
            // setBlockOffset(parentElement.offsetTop + parentElement.clientHeight);
            setBlockOffset(parentElement.offsetTop);
        }
    }, [blockOffsetKey]);

    // useEffect for handling ref / ext options (updating ref ext options if parent option changed or cleared)
    useEffect(() => {
        if (formValues && !isEmpty(extOptions)) {
            Object.keys(extOptions).map(fieldKey => {
                const extOption = extOptions[fieldKey];
                if (extOption.refKey) {
                    const { refKey, savedValue, rule } = extOption;
                    const currValue = formValues[refKey];
                    if (savedValue !== currValue) {
                        return handleExtRefChange(fieldKey, refKey, rule);
                    }
                }
            });
        }
    }, [formValues]);

    // handle resetValues useEffects below:
    // used to clear some fields value once their "binded" field values are changed
    useEffect(() => {
        // update resetKeySavedValues
        if (formValues && !isEmpty(resetKeyFields)) {
            const newValues = {};
            Object.keys(resetKeyFields).forEach(key => {
                const val = get(formValues, key);
                newValues[key] = val;
            });
            setResetKeySavedValues(prev => ({ ...prev, ...newValues }));
        }
    }, [formValues, resetKeyFields]);

    useEffect(() => {
        // clear fields once resetKeyValue is changed
        if (formValues && !isEmpty(resetKeySavedValues)) {
            setTimeout(() => {
                Object.keys(resetKeyFields).forEach(resetKey => {
                    const fields = resetKeyFields[resetKey];
                    fields.forEach(widgetKey => {
                        // check if fields are still exist
                        if (!regFields[widgetKey] || !regFields[resetKey]) {
                            if (!regFields[resetKey]) {
                                // completely remove resetKey
                                setResetKeyFields(prev => {
                                    delete prev[resetKey];
                                    return { ...prev };
                                });
                                setResetKeySavedValues(prev => {
                                    delete prev[resetKey];
                                    return { ...prev };
                                });
                            } else if (!regFields[widgetKey]) {
                                // remove single widgetKey inside of resetKey array
                                setResetKeyFields(prev => {
                                    return {
                                        ...prev,
                                        [resetKey]: prev[resetKey].filter(item => item !== widgetKey),
                                    };
                                });
                            }
                            // console.log({resetKeyFields, resetKeySavedValues});
                        } else {
                            // field is still exist - make reset
                            const fieldValue = get(formValues, widgetKey);
                            if (fieldValue) {
                                const savedResetVal = get(resetKeySavedValues, resetKey);
                                const currentResetVal = get(formValues, resetKey);
                                const isValuesEqual = isEqual(savedResetVal, currentResetVal);
                                if (!isValuesEqual) {
                                    // previously was deleted a field array fields => wait until next cycle to finally compare sync values
                                    if (lastDeletedArrayFieldName && widgetKey.startsWith(lastDeletedArrayFieldName)) {
                                        setLastDeletedArrayFieldName(null);
                                    } else {
                                        change(widgetKey, null);
                                    }
                                }
                            }
                        }
                    });
                });
            }, 0);
        }
    }, [formValues]);

    useEffect(() => {
        if (submitFailed && !isEmpty(formErrors)) {
            if (props.touch && typeof props.touch === 'function') {
                props.touch(formName, ...Object.keys(formErrors));
            }
        }
    }, [submitFailed]);

    return (
        <Form
            onSubmit={handleSubmit(() => submitForm(formName))}
            className={!className ? "order-dynamic-form" : `order-dynamic-form ${className}`}
        >
            {dynData && !isEmpty(collapseState) && initialValues && dynData.map(renderDynParams)}
        </Form>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxFormWrapper({ validate })(DynamicForm));
