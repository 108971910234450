import {
    SEARCH_RESULTS_REQUEST,
    SEARCH_RESULTS_REQUEST_SUCCESS,
    SEARCH_RESULTS_REQUEST_FAILURE,
    SEARCH_QUERY_UPDATE,
    SEARCH_RESULTS_POPUP_OPEN,
    SEARCH_RESULTS_POPUP_CLOSE,
    SEARCH_TAB_HASH_UPDATE,
    SEARCH_PAGINATION_UPDATE,
	CLEAR_SEARCH_AND_SEARCH_FILTER,
} from 'constants/actions';

const initialState = {
    tabHash: '0',
    query: '',
    searches: {},
    
    results: {},
    isResultsLoading: false,
    total: 0,
    pagination: {},
    
    isResultsPopupOpen: false,
};

function searchReducer (state = initialState, action) {
    
    switch (action.type) {
    
    case SEARCH_RESULTS_REQUEST:
        return { ...state, results: { ...state.results, [action.entityType]: [] }, isResultsLoading: true };
    
    case SEARCH_RESULTS_REQUEST_SUCCESS:
        return {
            ...state,
            isResultsLoading: false,
            results: { ...state.results, [action.entityType]: action.results },
            total: action.total,
			idHashKeyRequest: action.idHashKeyRequest,
        };
    
    case SEARCH_RESULTS_REQUEST_FAILURE:
        return { ...state, isResultsLoading: false, results: {}, total: 0 };
    
	case SEARCH_QUERY_UPDATE:
        return { ...state, query: action.query, searches: { ...state.searches, [action.id]: action.query } };

    case CLEAR_SEARCH_AND_SEARCH_FILTER:
		const searches = { ...state.searches };
		const pagination = { ...state.pagination };
		delete pagination[action.idHash];
		delete searches[action.id];
		
		return { ...state, query: action.query, searches, pagination };
    
    case SEARCH_RESULTS_POPUP_OPEN:
        return { ...state, isResultsPopupOpen: true };
    
    case SEARCH_RESULTS_POPUP_CLOSE:
        return { ...state, isResultsPopupOpen: false };
    
    case SEARCH_TAB_HASH_UPDATE:
        return { ...state, tabHash: action.tabHash };
    
        case SEARCH_PAGINATION_UPDATE:
        return { ...state, pagination: { ...state.pagination, [action.id]: action.pagination } };
    
    default:
        return state;
    }
}

export default searchReducer;
