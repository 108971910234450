import React from 'react';
import PropTypes from 'prop-types';
import Notification from './Notification';

class SmartNotification extends React.Component {
	constructor (props) {
		super(props);
		this.close = this.close.bind(this);
		this.clear = this.clear.bind(this);
	}
	
	componentDidMount () {
		this.close();
	}
	
	componentWillUnmount () {
		this.clear();
	}
	
	close () {
		this.timeout = setTimeout(
			() => { this.props.onClose(); },
			this.props.timeout,
		);
	}
	
	clear () {
		clearTimeout(this.timeout);
	}
	
	render () {
		return (
			<div onMouseEnter={this.clear} onMouseLeave={this.close}>
				<Notification {...this.props} />
			</div>
		);
	}
}

SmartNotification.propTypes = {
	timeout: PropTypes.number.isRequired,
};

export default SmartNotification;
