import React from 'react';
import cx from 'classnames';
import styles from './appealTypeSelect.module.scss';
import PropTypes from 'prop-types';

class LeafNode extends React.PureComponent {
	
	constructor (props) {
		super(props);
		
		this.onClick = this.onClick.bind(this);
		this.onDoubleClick = this.onDoubleClick.bind(this);
	}
	
	onClick (event) {
		event.stopPropagation();
		this.props.onSelect(this.props.node);
	}
	
	onDoubleClick (event) {
		event.stopPropagation();
		this.props.onSubmit(this.props.node);
	}
	
	render () {
		const { node, searchMode, labelField, valueField } = this.props;
		
		const titleClassName = cx(styles.title, {
			[styles.searchMode]: searchMode
		});
		
		const labelClassName = cx({
			[styles.selected]: node.selected
		});
		
		return (
			<div id={node[valueField]} className={cx(styles.node, styles.leaf)}>
				<div className={titleClassName} onClick={this.onClick} onDoubleClick={this.onDoubleClick}>
					<i className='icon-file' />
					<label className={labelClassName}>
						{searchMode ? node.fullLabelPath : node[labelField]}
					</label>
				</div>
			</div>
		);
	}
}

LeafNode.propTypes = {
	open: PropTypes.bool,
	node: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	searchMode: PropTypes.bool
};

export default LeafNode;
