import {
	ORDERS_REQUEST,
	ORDERS_REQUEST_SUCCESS,
	ORDERS_REQUEST_FAILURE,
	ORDERS_FILTERS_REQUEST,
	ORDERS_FILTERS_REQUEST_SUCCESS,
	ORDERS_FILTERS_REQUEST_FAILURE,
	ORDERS_SET_ACTIVE_FILTER,
	ORDERS_SET_ACTIVE_FILTER_PAGE,
	ORDERS_FILTER_ATTRS_GET_SUPPOSE,
	ORDERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_ATTRS_GET_SUPPOSE_FAIL,
	ORDERS_ADD_FILTER_ATTRS,
	ORDERS_SAVE_FILTER_SUCCESS,
	ORDERS_SAVE_FILTER_FAIL,
	ORDERS_SAVE_FILTER,
	ORDERS_DELETE_FILTER,
	ORDERS_DELETE_FILTER_SUCCESS,
	ORDERS_DELETE_FILTER_FAIL,
	ORDERS_FILTER_PARAMS_GET_SUPPOSE,
	ORDERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_PARAMS_GET_SUPPOSE_FAIL,
	ORDERS_CLEAR_FILTER_ATTRS,
	ORDERS_CLEAR_FILTER_INFO,
	ORDERS_FILTER_DATA_GET_SUPPOSE,
	ORDERS_FILTER_DATA_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_DATA_GET_SUPPOSE_FAIL,
	ORDERS_OPEN_FILTER_PARAMS,
	ORDERS_CLOSE_FILTER_PARAMS,
	ORDERS_OPEN_NEW_FILTER_PARAMS,
	ORDERS_CLOSE_NEW_FILTER_PARAMS,
	ORDERS_CREATE_NEW_FILTER,
} from 'constants/actions';
import baseService from '../services/BaseService';

export const getRequest = (callback, api = {}, gridKey, errorCallback) => dispatch => {
	const { key, method = 'get', ...rest } = api;
	return baseService
		[method](key, rest)
		.then((response) => (callback(response, gridKey)))
		.catch((error) => {
			errorCallback && errorCallback(error);
			console.error(error);
		});
};

/* action creators */
export const setFilters = filters => ({
	type: ORDERS_FILTERS_REQUEST_SUCCESS,
	filters
});

export const setActiveFilterPage = (filterId, page) => ({
	type: ORDERS_SET_ACTIVE_FILTER_PAGE,
	filterId,
	page
});


const getAttrsSuppose = (query) => ({
	type: ORDERS_FILTER_ATTRS_GET_SUPPOSE,
	query
});

const getAttrsSupposeSuccess = (result) => ({
	type: ORDERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	result
});

const getAttrsSupposeFail = (error) => ({
	type: ORDERS_FILTER_ATTRS_GET_SUPPOSE_FAIL,
	error
});

export const getFilterAttrsSuppose = (params) => (dispatch) => {
	dispatch(getAttrsSuppose());
	baseService.get('attributes_ordering', params)
		.then(data => {
			dispatch(getAttrsSupposeSuccess(data));
		})
		.catch(err => dispatch(getAttrsSupposeFail(err)));
};

export const addFilterAttrs = (result) => ({
	type: ORDERS_ADD_FILTER_ATTRS,
	result
});

export const clearFilterAttrs = () => ({
	type: ORDERS_CLEAR_FILTER_ATTRS
});

const saveFilterSuccess = (lastSaved) => ({
	type: ORDERS_SAVE_FILTER_SUCCESS,
	lastSaved
});

const saveFilterFail = (error) => ({
	type: ORDERS_SAVE_FILTER_FAIL,
	error
});

const fireSaveFilter = () => ({
	type: ORDERS_SAVE_FILTER
});

export const saveFilter = (params, onSuccess) => (dispatch) => {
	dispatch(fireSaveFilter());
	
	baseService.post('save_orders_filter', params)
		.then(response => {
			if (onSuccess && response.result) onSuccess(response.result);
			dispatch(saveFilterSuccess(params.data.name));
		})
		.catch(err => dispatch(saveFilterFail(err)));
};

const deleteFilterSuccess = (id) => ({
	type: ORDERS_DELETE_FILTER_SUCCESS,
	id
});

const deleteFilterFail = (error) => ({
	type: ORDERS_DELETE_FILTER_FAIL,
	error
});

const fireDeleteFilter = () => ({
	type: ORDERS_DELETE_FILTER
});

export const deleteFilter = (params, onSuccess) => (dispatch) => {
	dispatch(fireDeleteFilter());
	
	baseService.post('delete_filter', params)
		.then(data => {
			dispatch(deleteFilterSuccess(data.result));
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess(data.result);
			}
		})
		.catch(err => {
			console.log(err);
			dispatch(deleteFilterFail(err))
		});
};

const getFilterParamsSuppose = (query) => ({
	type: ORDERS_FILTER_PARAMS_GET_SUPPOSE,
	query
});

const getFilterParamsSupposeSuccess = (result) => ({
	type: ORDERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterParamsSupposeFail = (error) => ({
	type: ORDERS_FILTER_PARAMS_GET_SUPPOSE_FAIL,
	error
});

export const clearFilterInfo = () => ({
	type: ORDERS_CLEAR_FILTER_INFO
});

export const getInfoFilterSuppose = (params) => (dispatch) => {
	dispatch(openFilterParams());
	baseService.get('info_orders_filter', params)
		.then(data => dispatch(getFilterParamsSupposeSuccess(data)))
		.catch(err => dispatch(getFilterParamsSupposeFail(err)));
};

const getFilterDataSuppose = () => ({
	type: ORDERS_FILTER_DATA_GET_SUPPOSE
});

const getFilterDataSupposeSuccess = (result) => ({
	type: ORDERS_FILTER_DATA_GET_SUPPOSE_SUCCESS,
	result
});

const getFilterDataSupposeFail = (error) => ({
	type: ORDERS_FILTER_DATA_GET_SUPPOSE_FAIL,
	error
});

export const getDataFilterSuppose = (params) => (dispatch) => {
	dispatch(getFilterDataSuppose());
	
	baseService.post(params.key, params)
		.then(data => {
			dispatch(getFilterDataSupposeSuccess(data));
		})
		.catch(err => dispatch(getFilterDataSupposeFail(err)));
};


export const setActiveFilter = (openedFilter) => ({
	type: ORDERS_SET_ACTIVE_FILTER,
	openedFilter
});

export const openFilterParams = () => ({
	type: ORDERS_OPEN_FILTER_PARAMS
});

export const closeFilterParams = () => ({
	type: ORDERS_CLOSE_FILTER_PARAMS
});

export const openNewFilterParams = () => ({
	type: ORDERS_OPEN_NEW_FILTER_PARAMS
});

export const closeNewFilterParams = () => ({
	type: ORDERS_CLOSE_NEW_FILTER_PARAMS
});

export const createNewFilter = () => ({
	type: ORDERS_CREATE_NEW_FILTER
});
