import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import { Field, Form, reduxForm } from 'redux-form';
import { ComboBox } from 'ui-core-dashboard';
import Loader from 'components/Loader';
import baseService from 'services/BaseService';
import ServicesList from './ServicesList';

const TOPIC = 'SERVICE_CATALOG_SPEC_INSTANCE';

const Services = (props) => {
    const {
        t,
        initialize,
        id
    } = props;

    const [filters, setFilters] = useState([]);
    const [isFiltersLoading, setIsFiltersLoading] = useState(true);
    const [activeFilter, setActiveFilter] = useState(null);

    const getFilters = () => {
        setIsFiltersLoading(true);
        baseService.get('filters', { data: { code: TOPIC } }).then(({ success, result }) => {
            setIsFiltersLoading(false);
            if (success) {
                const filters = result.map((item) => ({ value: item.value, label: item.name }));
                setFilters(filters);
                if (result.length > 0 && !activeFilter) {
                    setActiveFilter(result[0].value);
                    initialize({ filters: { value: result[0].value, label: result[0].name } });
                } else {
                    setActiveFilter('');
                }
            } else {
                setIsFiltersLoading(false);
                console.log('Request service appeals filters failed');
            }
        });
    };

    useEffect(() => {
        getFilters();
    }, []);

    return (
        <div className="ordering-tab-wrapper">
            {isFiltersLoading && <Loader />}
            <div className="ordering-tab-filter">
                <Form onSubmit={() => {}}>
                    <Field
                        key="filters"
                        name="filters"
                        clearable={false}
                        options={filters}
                        onChange={(e, value) => setActiveFilter(value)}
                        placeholder={t("dontSelect")}
                        noResultsText={t("noResultsFound")}
                        component={ComboBox}
                    />
                </Form>
            </div>
            {!isFiltersLoading && ![null, undefined].includes(activeFilter) && (
                <ServicesList id={id} filter={activeFilter} />
            )}
        </div>
    );
};

export default reduxForm({ form: 'subappeals-filter' })(Services);
