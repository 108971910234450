import {
    SET_CALL_RECEPTION_STATUS, OPEN_CALL_CONTROL_WIDGET, CLOSE_CALL_CONTROL_WIDGET,
    SET_POSSIBLE_CUSTOMERS, SET_LAST_SSE_MESSAGE, ADD_CREATED_APPEAL,
    SET_AUTHORIZATION_SUCCESS, ADD_NEW_INTERACTION, CLEAR_CALL_TEMP_STATE, CLOSE_INTERACTION,
    CLEAR_SESSION, SET_TIMER, SET_OPERATOR_ACTIONS, OPEN_USER_POPUP, OUT_OF_SERVICE,
    CLEAR_ERROR_MSG, SET_OUT_PHONE_ERROR_MSG, SET_IN_PHONE_ERROR_MSG, UPDATE_CREATED_APPEAL_NAME, SELECT_PHONE
} from 'constants/actions';
import {
    UNKNOWN_ERROR_MSG, LOST_CONNECTION_MSG, SERVER_ERROR_MSG,
    LOST_CONNECTION_TOTALY_MSG, SERVER_NOT_RESPONSE_MSG,
} from 'constants/errors';
import baseService from 'services/BaseService';
import URLS from 'config/urls';
import { SseEvents } from 'constants/index';
import { openUserPopup } from './ui';


export function updateCreatedAppealName (appeal) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_CREATED_APPEAL_NAME,
			payload: { appeal }
		});
	}
}

export function transferCall (params) {
    return (dispatch) => {
        return baseService.post('dialog_call', params)
            .then((response) => {
                if (errorCodesHandler(response.errorCode, dispatch)) {
                    return null;
                }
            })
            .catch(console.error);
    };
}

export function getOperatorStatus (userLogin) {
    return (dispatch) => {
        baseService.get('operator_status', { data: { userLogin }, json: true })
            .then((response) => {
                dispatch({
                    type: SET_OPERATOR_ACTIONS,
                    payload: response.result,
                });
            });
    };
}

export function setCallReceptionStatus (stateCode, userLogin) {
    return (dispatch) => {
        if (stateCode && userLogin) {
            baseService.post('set_operator_status', { data: { userLogin, stateCode } });
        }
        dispatch({
            type: SET_CALL_RECEPTION_STATUS,
            payload: { callReceptionStatus: stateCode },
        });
    };
}

export function setTimer (timer) {
    return {
        type: SET_TIMER,
        payload: timer,
    };
}

export const getPossibleCustomers = (phone, callback) => (dispatch) => {
    const params = {
        data: { phone, limit: 50 },
        jsonType: true,
    };

    return baseService.post('applicants_search', params)
        .then((response) => {
            if (response.success && Array.isArray(response.result)) {
                dispatch(setPossibleCustomers(response.result));
                if (callback && typeof callback === 'function') {
                    callback(response.result);
                }
            } else {
                throw new Error('Possible customers request error');
            }
        })
        .catch((error) => {
            console.error(error);
            dispatch(setPossibleCustomers([]));
        });
};

export const setAuthorizationSuccess = phoneNumber => (dispatch) => {
    dispatch({
        type: SET_AUTHORIZATION_SUCCESS,
        payload: { phoneNumber },
    });
};

export const clearCallState = () => ({
    type: CLEAR_CALL_TEMP_STATE,
});

export const clearSession = () => ({
    type: CLEAR_SESSION,
});

let eventSource;

export const closeCallEventStream = () => () => {
    if (eventSource) {
        eventSource.close();
        eventSource = null;
    }
};

export function closeEventStreamAndPopUp (pathPrefix) {
	return (dispatch) => {
        // console.log("*** PHONE IS REMOVED closeEventStreamAndPopUp ***");
		localStorage.removeItem('phoneOption');
		localStorage.removeItem('phone');
		localStorage.removeItem('pathPrefix');
		localStorage.removeItem('callStation');
		dispatch(closeCallEventStream());
		dispatch(clearSession());
		dispatch(openUserPopup());
		if(pathPrefix) return baseService.post('sn_logout', { pathParams: { pathPrefix } });
	}
}

export const subscribeToCalls = (callback, pathPref, phoneNumber, disconnectCallback) => (dispatch) => {
    eventSource && dispatch(closeCallEventStream());
    dispatch({ type: CLEAR_ERROR_MSG });
    const pathPrefix = pathPref || localStorage.getItem('pathPrefix');
    if (pathPrefix) {
		const hostname = window.location.origin;
		const sourceUrl = `${hostname}/sn/${pathPrefix}/${URLS.core.sse_path_stream.url}`;
		eventSource = new EventSource(sourceUrl);
	
		let lastSseEvent = null;
	
		eventSource.addEventListener('message', (message) => {
            const sseMessage = JSON.parse(message.data);
		
			if (sseMessage.errorCode === 'AССESS_DENIED') {
                // console.log("*** PHONE IS REMOVED subscribeToCalls ***");
				localStorage.removeItem('phoneOption');
				localStorage.removeItem('pathPrefix');
				localStorage.removeItem('phone');
				localStorage.removeItem('callStation');
				dispatch(closeCallEventStream());
				dispatch(clearSession());
				dispatch(openUserPopup());
				return;
			}
		
			if (callback) {
				callback()
			}
		
			if (lastSseEvent === sseMessage.event && sseMessage.event !== SseEvents.ALIVE) {
				return;
			}
		
			lastSseEvent = sseMessage.event;
		
			dispatch(setLastSseMessage({...sseMessage, timestamp: Date.now()}));
		
			switch (sseMessage.event) {
				case SseEvents.RINGING: {
					dispatch(getPossibleCustomers(sseMessage.phone));
					dispatch(openCallControlWidget());
					break;
				}
				case SseEvents.HANGUP: {
					dispatch(clearCallState());
					dispatch(closeInteraction());
					break;
				}
			
				case SseEvents.DISCONNECTION: {
					dispatch(closeCallEventStream());
					dispatch(clearSession());
					dispatch(openUserPopup());
					break;
				}
			
				case SseEvents.ALIVE: {
					baseService.get('sn_alive_request', {pathParams: { pathPrefix }})
						.then((response) => {
							if (errorCodesHandler(response.errorCode, dispatch)) {
								return null;
							}
							dispatch({type: CLEAR_ERROR_MSG});
                            const lsPhoneNumber = localStorage.getItem('phone');
							switch (response.result.status) {
								case 'Unauthorized': {
                                    // console.log("*** PHONE IS REMOVED SseEvents.ALIVE ***");
                                    // only clear ls values once phoneNumbers on subscribe and in ls are the same
                                    // could be a case when there is a subscription to another number in new tab
                                    // in such case do not clear saved ls values
                                    if (lsPhoneNumber === phoneNumber) {
                                        localStorage.removeItem('phoneOption');
                                        localStorage.removeItem('phone');
                                        localStorage.removeItem('pathPrefix');
                                        localStorage.removeItem('callStation');
                                    }
                                    if (typeof disconnectCallback === 'function') {
                                        disconnectCallback();
                                    }
									closeCallEventStream();
									dispatch({type: CLEAR_SESSION});
                                    dispatch({type: OPEN_USER_POPUP});
									break;
                                }
								case 'OUT_OF_SERVICE':
									dispatch({type: OUT_OF_SERVICE});
									dispatch({type: SET_IN_PHONE_ERROR_MSG, payload: LOST_CONNECTION_MSG});
									break;
                                default: 
							}
						})
						.catch(() => {
							errorCodesHandler('unknown_error', dispatch)
						});
					break;
				}
			
				default:
					break;
			}
		});
	}
};

export const setLastSseMessage = message => ({
    type: SET_LAST_SSE_MESSAGE,
    payload: { message },
});

export const setPossibleCustomers = customers => ({
    type: SET_POSSIBLE_CUSTOMERS,
    payload: { customers },
});

export const openCallControlWidget = () => ({
    type: OPEN_CALL_CONTROL_WIDGET,
});

export const closeCallControlWidget = () => ({
    type: CLOSE_CALL_CONTROL_WIDGET,
});

export const addCreatedAppeal = appeal => ({
    type: ADD_CREATED_APPEAL,
    payload: { appeal },
});

export function addNewInteraction (payload) {
	return (dispatch) => {
		dispatch({
			type: ADD_NEW_INTERACTION,
			payload,
		});
	};
}

export const removeInteraction = () => ({
    type: CLOSE_INTERACTION,
});

export const selectPhone = (option) => {
const { phoneNumber, pathPrefix, callStation, value, label } = option;
	// localStorage.setItem('callStation', callStation);
	// localStorage.setItem('phoneOption', JSON.stringify(option));
	
	return {
		type: SELECT_PHONE,
		payload: {pathPrefix, phoneNumber, callStation}
	}
};

export const closeInteraction = uniqueId => (dispatch, getState) => {
    const interactionId = getState().call.callInteractionId;

    dispatch(removeInteraction(uniqueId));

    if (!interactionId) return;

    const params = {
        data: { interactionId },
    };

    return baseService.post('interaction_close', params)
        .then()
        .catch(console.error);
};

function errorCodesHandler (error, dispatch) {
    function serverErorr () {
        dispatch({ type: OUT_OF_SERVICE });
        dispatch({ type: SET_IN_PHONE_ERROR_MSG, payload: SERVER_ERROR_MSG });
    }

    function closeConnectionAndOpenLogin () {
        // console.log("*** PHONE IS REMOVED closeConnectionAndOpenLogin ***");
    	localStorage.removeItem('phoneOption');
    	localStorage.removeItem('phone');
    	localStorage.removeItem('pathPrefix');
    	localStorage.removeItem('callStation');
        closeCallEventStream();
        dispatch({ type: CLEAR_SESSION });
        dispatch({ type: OPEN_USER_POPUP });
    }

    function serverNotResponse () {
        dispatch({ type: OUT_OF_SERVICE });
        dispatch({ type: SET_IN_PHONE_ERROR_MSG, payload: SERVER_NOT_RESPONSE_MSG });
    }

    const errorMap = {
        unknown_error: () => {
            closeConnectionAndOpenLogin();
            dispatch({ type: SET_OUT_PHONE_ERROR_MSG, payload: UNKNOWN_ERROR_MSG })
        },
        no_login_id: () => {
            dispatch({ type: OUT_OF_SERVICE });
            dispatch({ type: SET_IN_PHONE_ERROR_MSG, payload: UNKNOWN_ERROR_MSG });
        },
        finesse_offline: () => {
            if (eventSource) {
                dispatch({ type: OUT_OF_SERVICE });
                dispatch({ type: SET_IN_PHONE_ERROR_MSG, payload: LOST_CONNECTION_MSG });
            } else {
                dispatch({ type: CLEAR_SESSION });
                dispatch({ type: OPEN_USER_POPUP });
            }
        },
        finesse_alive_unknown_status: () => {
            closeConnectionAndOpenLogin();
            dispatch({ type: SET_OUT_PHONE_ERROR_MSG, payload: LOST_CONNECTION_TOTALY_MSG })
        },
        asterisk_connection_error: serverNotResponse,
        asterisk_sendAction_error: serverNotResponse,
        finesse_invalid_request: serverErorr,
        finesse_failed: serverErorr,
        finesse_uknown_error: serverErorr,
        invalid_request: serverErorr,
    };

    if (errorMap[error]) {
        errorMap[error]();
        return true;
    }
}
