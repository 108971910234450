import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import i18next from 'util/i18n';

export const REQUIRED_FIELD = () => i18next.t('validation.required');
export const PHONE_NUMBER = () => i18next.t('validation.phone');
export const DIGITS = () => i18next.t('validation.onlyDigits');
export const PHONE_NUMBER_UNIQUE = () => i18next.t('validation.phoneUnique');
export const EMAIL = () => i18next.t('enterCorrectEmail');
export const EMAIL_UNIQU = () => i18next.t('validation.emailUnique');

// export const emailRegexp = /^([-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-011\013\014\016-\177])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
// export const emailRegexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailRegexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\wа-яА-Я\wіІїЇєЄґҐ\-0-9]+\.)+[a-zA-Z\wа-яА-Я\wіІїЇєЄґҐ]{2,}))$/;

export const capitalizeProps= (data, propsNames) => {
	if (propsNames && Array.isArray(propsNames)) {
		propsNames.forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = capitalize(data[prop]);
			}
		});
	} else {
		Object.keys(data).forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = capitalize(data[prop]);
			}
		});
	}
};

export const trimProps = (data, propsNames) => {
	if (propsNames && Array.isArray(propsNames)) {
		propsNames.forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = data[prop].trim();
			}
		});
	} else {
		Object.keys(data).forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = data[prop].trim();
			}
		});
	}
};
export const leftTrimProps = (data, propsNames) => {
	if (propsNames && Array.isArray(propsNames)) {
		propsNames.forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = data[prop].trimLeft();
			}
		});
	} else {
		Object.keys(data).forEach(prop => {
			if (data[prop] && typeof(data[prop]) === 'string') {
				data[prop] = data[prop].trimLeft();
			}
		});
	}
};

export const validateEmail = email => emailRegexp.test(email);

export const Normalizers = {
	digitsOnly: (value, previousValue) => /^\d*$/.test(value) ? value : previousValue
};

export function emailValidation (email) {
	if (!emailRegexp.test(email)) {
		return i18next.t('validation.email');
	}
}

export function individualTaxpayerNumber (value) {
	if (value && !/^\d{8}$|^\d{10}$/.test(value)) {
		return i18next.t('validation.individualTax');
	}
	
	return undefined;
}

export function phoneValidation (phone) {
	if (phone && !/^[0-9]{3,12}$/.test(phone)) {
		return i18next.t('validation.phone');
	}
	
	return undefined;
}

export function certificateIdValidation (id) {
	if (id && !/^[0-9]{1,20}$/.test(id)) {
		return i18next.t('validation.certId');
	}
	
	return undefined;
}

export function fieldValidation(rule, widgetType){
	// additional date check
	if (!rule) return;
	const [ message, ...rest ] = rule.split('$');
	const regexp = rest.join("");
	const regex = new RegExp(`^${regexp}$`);
	return value => {
		if (!value) return;
		if (!regex.test(value)) {
			return message;
		}
	};
}

export function fieldLevelValidation (values, rules, errors, t) {
	// console.log({values, rules, errors});

	for (const fieldName in values) {
		// console.log({fieldName, values, rules});
		if (values.hasOwnProperty(fieldName)) {
			const validator = rules[fieldName];
			
			if (!validator) continue;
			
			const error = validator(values[fieldName], t);
			if (error) {
				errors[fieldName] = error;
			}
		}
	}
}

export function requiredFieldsValidation (values, config, errors) {
	// console.log({values, config, errors});
	config.forEach(field => {
		if (field.required) {
			const value = values[field.name];
			// console.log({value, field, values});
			if (typeof value === "string" && !value.trim()) {
				// console.log({REQUIRED: field.name, value});
				errors[field.name] = i18next.t('validation.required');
			} else if (typeof value === 'object' && isEmpty(value)) {
				errors[field.name] = i18next.t('validation.required');
			} else if ([null, undefined].includes(value)) {
				errors[field.name] = i18next.t('validation.required');
			}
			// else {
			// 	// old logic, could be removed later
			// 	// if (isEmpty(value) || value[0] === "undefined") {
			// 	if (isEmpty(value) || value[0] === undefined) {
			// 		console.log({REQUIRED: field.name, value});
			// 		errors[field.name] = i18next.t('validation.required');
			// 	}
			// }
		}
		// old logic
		// if (field.required && (isEmpty(values[field.name]) || values[field.name][0] === 'undefined')) {
		// 	errors[field.name] = i18next.t('validation.required');
		// }
	});
}

export function zipCodeValidation (value) {
	if (value && !/^\d{5}$/.test(value)) {
		return i18next.t('validation.zip');
	}
	
	return undefined;
}

export function arrayUniqueValues (values, error) {
	const objects = {};
	let result;
	
	values.forEach((i, key) => {
		if (objects[i]) {
			objects[i] = [...objects[i], key];
		} else {
			objects[i] = [key];
		}
	});
	
	Object.keys(objects).forEach((key) => {
		if (objects[key].length > 1) {
			if (!result) {
				result = {};
			}
			
			objects[key].forEach((key) => {
				result[key] = error;
			});
		}
	});
	
	return result;
}