import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'styles/modules/appealQuality.module.scss';

@withTranslation()
export default class Rank extends React.Component {
	constructor (props) {
		super(props);
		const rankVal = props.values.findIndex(item => item.key == props.input.value || props.rank);
		this.state = { rank: rankVal };
	}
	
	static getDerivedStateFromProps (nextProps, prevState) {
		const rankVal = nextProps.values.findIndex(item => item.key === nextProps.input.value);
		const { onChange, value } = nextProps.input;
		// change to prev value
		if (typeof value === 'boolean') {
			// change redux false/true value to null
			if (prevState.rank === -1) {
				onChange(null);
			} else {
				onChange(nextProps.values[prevState.rank].key);
			}
		}
		if (rankVal !== prevState.rankVal) {
			return { rank: rankVal };
		}
	}
	
	onRankChange = (number, value, e) => {
		const { rank } = this.state;
		if (!e.currentTarget.checked && +rank === +number) {
			number = -1;
			value = null;
		}
		const { onChange } = this.props.input;
		this.setState({ rank: number });
		onChange(value);
	};
	
	renderStarItem = (item, value) => {
		const { rank } = this.state;
		const { input, disabled, required } = this.props;
		const checked = (rank !== -1) ? rank < value + 1 ? true : false : false;
		
		return (
			<label className={styles.appealQualityStar} key={item.value.toString()} title={item.value}>
				<input
					{...input}
					type='checkbox'
					className={styles.appealQualityChecker}
					onChange={(e) => this.onRankChange(value, item.key, e)}
					value={item.key}
					checked={checked}
					disabled={disabled}
					{...required}
				/>
				<span>
          {(checked || disabled) ?
			  <svg className={styles.star} widths='19.69' height='19.98' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.93 13.72'>
				  <g id='uncheckedLayer_2' data-name='unchecked'>
					  <g id='checkedLayer_91' data-name='checkedLayer 91'>
						  <path
							  d='M13.46,4.92l-4.2-.63L7.37.35C7.27.12,7.13,0,7,0s-.3.12-.41.35L4.67,4.29l-4.2.63C.16,5,0,5.1,0,5.32a.67.67,0,0,0,.21.41L3.26,8.79l-.72,4.32a.66.66,0,0,0,0,.17.52.52,0,0,0,.09.31.3.3,0,0,0,.26.13.74.74,0,0,0,.34-.11l3.75-2,3.76,2a.68.68,0,0,0,.34.11.3.3,0,0,0,.25-.13.52.52,0,0,0,.09-.31.81.81,0,0,0,0-.17l-.72-4.32,3-3.06a.62.62,0,0,0,.22-.41c0-.22-.16-.35-.47-.4Z' />
					  </g>
				  </g>
			  </svg> :
			  <svg className={styles.star} width='19.69' height='19.98' xmlns='http://www.w3.org/2000/svg'
				   viewBox='0 0 16.71 15.94'>
				  <g id='checkedLayer_2' data-name='checked'>
					  <g id='checkedLayer_147' data-name='checkedLayer 147'>
						  <path
							  d='M12.14,14,8.36,12l-3.8,2,.73-4.23-3.07-3,4.24-.62,1.9-3.84,1.9,3.84,4.23.62-3.07,3Zm4-8.27-5-.74L8.85.41C8.72.14,8.56,0,8.36,0S8,.14,7.86.41L5.6,5l-5,.74c-.37.06-.56.21-.56.46a.75.75,0,0,0,.25.48l3.66,3.56-.87,5c0,.09,0,.16,0,.2a.61.61,0,0,0,.11.36.37.37,0,0,0,.32.14.86.86,0,0,0,.4-.12l4.51-2.37,4.51,2.37a.8.8,0,0,0,.4.12c.27,0,.41-.17.41-.5a1,1,0,0,0,0-.2l-.86-5,3.64-3.56a.7.7,0,0,0,.26-.48c0-.25-.18-.4-.56-.46Z' />
					  </g>
				  </g>
			  </svg>
          }
        </span>
			</label>
		);
	};
	
	render () {
		const { t, label, values, description, required, disabled, ...restProps } = this.props;
	
		return (
			<Fragment>
				<div className={styles.appealQualityRow} {...restProps}>
					<p className={cx(styles.appealQualityFormQuestion, this.props.meta.error && 'danger')}>
						{t(label)}
						{required && <span className='required-field'>*</span>}
					</p>
					{description && <p className={styles.appealQualityFormDescription}>{description}</p>}
					<div className={cx(styles.appealQualityGalactica, { 'disabled': disabled })}>
						{values.map(this.renderStarItem)}
					</div>
				</div>
			</Fragment>
		);
	}
}

Rank.propTypes = {
	onChange: PropTypes.func,
	values: PropTypes.array,
	name: PropTypes.string,
	rank: PropTypes.number,
	input: PropTypes.object,
	meta: PropTypes.object,
	required: PropTypes.bool,
	additionalInputClassName: PropTypes.string,
	errorPlaceholder: PropTypes.bool,
	
};
