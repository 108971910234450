import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from 'components/Common/Grid';
import Pagination from 'components/Common/Pagination';
import tabsParams from 'constants/tabsParams';
import { getCurrentTabCacheField } from 'helpers';
import { queriesContentUpdate } from 'actions/content';
import styles from 'styles/modules/gridWrapper.module.scss';
import moment from 'moment';
import tabsFormats from 'constants/tabsFormats';
import { withTranslation } from 'react-i18next';
import settingsService, { SORT_POSTFIX, FIELDS_POSTFIX } from 'services/settingsService';
import Loader from 'components/Loader';
import get from 'lodash/get';

const convertPersonsList = (persons,t) => persons.map(person => ({
  id: person.id,
  lastName: person.party.lastName,
  firstName: person.party.firstName,
  patronymic: person.party.patronymic,
  contacts: person.contacts.map(contact => contact.value).join(', '),
  language: person.lang_name,
	issues: person.links.map(link => link.issues.map(issue => t(`dictionary:contactIssue.${issue}`)).join(', ')),
  category: person.fixed,
  validFrom: person.validFrom,
  personStatus: person.actual,
    links: person.links.map(link => ({
        id: link.id,
		nameLinkedWith: link.entityDescription,
		idLinkedWith: link.entityId,
		validFrom: link.validFrom,
        actual: link.actual
        }))
}));

const convertLinksList = (links, t) => links.map(link => ({
  id: link.id,
  entityDescription: link.entityDescription,
	entityId: link.entityId,
  idLinkedWith: link.entityId,
  issues: link.issues.map(issue => t(`dictionary:contactIssue.${issue}`)).join(', '),
  validFrom: link.validFrom,
  actual: link.actual
}));

const convertContactList = (contacts, t) => contacts.map(contact => ({
  id: contact.id,
  type: t(`dictionary:contactMedia.${contact.type}`),
  value: contact.value
}));

const convertContractsList = contracts => contracts.map(contract => ({
  ...contract,
  contractStatus: contract.status,
  serviceGroups: contract.serviceGroups.join(', '),
  contractNumbers: contract.contracts.join(', ')
}));

const mapDispatchToProps = (dispatch) => ({
  queriesContentUpdate: (params) => dispatch(queriesContentUpdate(params))
});

@withTranslation()
@connect(null, mapDispatchToProps)
class GridWrapper extends React.Component {

  constructor(props){
    super(props);
    this.state = { searchedSort : [] }
  }

  getParams(data, tabs, match, fields, pagination) {
    const { t, finalFields, disabledChange, modalEntity } = this.props;
    const { searchedSort } = this.state;
    const queries = tabs ? getCurrentTabCacheField(tabs.current, 'queries') : null;
    const sort = queries.sort;
    const queryKey = tabs ? tabs.current.queryKey || tabs.current.name: null;
    const savedFields = settingsService.get(`${queryKey}${FIELDS_POSTFIX}`);
    const savedSort = settingsService.get(`${modalEntity || queryKey}${SORT_POSTFIX}`);
    const tabParams = tabsParams(t)[data.tabName];
    if (savedFields && !disabledChange) {
      Object.keys(savedFields).map(key => {
          savedFields[key] = { ...tabParams[key], ...savedFields[key] }
      });
    }
    
    return {
      sort: {
        property: sort ? sort.property : searchedSort.property || savedSort && savedSort.property,
        direction: sort ? sort.direction : searchedSort.direction || savedSort && savedSort.direction,
        onChange: this.onChange
      },
      fields: finalFields || savedFields || (match && tabParams) || (fields && tabsParams(t)[fields]),
      footer: {
        pagination:
            <Pagination
                page={this.props.page}
                {...queries}
                total={data.total}
                {...pagination}
                onChange={this.onPageChange}
            />
      },
      onChangeFields: this.onChangeFields,
      defaultId: this.props.defaultId,
      ...this.props.groups
    };
  }

  onChange = ({ property, direction }) => {
    const { tabs, disabledChange, page, modalEntity } = this.props;
    const { searchedSort } = this.state;
    const queryKey = tabs ? tabs.current.queryKey || tabs.current.name : null;
    
    let sort = { property: property, direction: direction };
	  if ((property === searchedSort.property) && searchedSort.direction === false && isReversed === "DESC") {
		  // imitate third click for default sorting column
		  sort = {};
	  }
	  if (queryKey && !disabledChange) {
	  	settingsService.set(`${modalEntity || queryKey}${SORT_POSTFIX}`, sort);
      }
    this.setState({ searchedSort : sort });
	  this.onPageChange({ sort, page });
  };

  onChangeFields = (fields) => {
    const { tabs, disabledChange, modalEntity } = this.props;
    const queryKey = tabs ? tabs.current.queryKey : null;
    if (queryKey && !disabledChange) {
      settingsService.set(`${modalEntity || queryKey}${FIELDS_POSTFIX}`, fields);
    }
    
    this.props.queriesContentUpdate && this.props.queriesContentUpdate({ fields, update: [] });
  };

  onPageChange = (data) => {
    const { section, tab } = this.props.match.params;
    // todo Временное решение для update. Необходимо найти способ, который бы удовлетворял любому условию
    let update;
    update = [];
    if (section) update = ['section'];
    if (tab) update = ['tab'];
    if (!update) {
      console.error('GridParams onPageChange will not work, var update is ', update);
    }
	  return this.props.onPageChange ? this.props.onPageChange(data) : this.props.queriesContentUpdate({
      ...data,
      update
    });
  };

  getFormatFunction = (fieldParams, rowValues) => value => {
    const dictionaryField = get(fieldParams, 'dictionaryField', '');
    const format = get(fieldParams, 'format', 'string');

    switch (format) {
      case tabsFormats.date: return value ? moment(+value).format('DD.MM.YYYY HH:mm') : '';
      case tabsFormats.number: return parseInt(value, 10);
      case tabsFormats.translate: return this.props.t(value);
      case tabsFormats.dictionary: return this.props.t(`dictionary:${dictionaryField}.${value}`);
      case tabsFormats.custom: return fieldParams.formatter(rowValues);
      case tabsFormats.string:
      default: return String(value);
    }
  };

  formatData = (data, params) => {
    if (!Array.isArray(data)) return data;
	  try {
        return data.map(row => {
            const newRow = {};
            
                Object.keys(row).forEach(key => {
                    const value = row[key];
                    const fieldParams = params.fields[key];
                
                    const formatValue = this.getFormatFunction(fieldParams, row);
                
                    if (typeof value === 'object') {
                    
                        const field = get(fieldParams, 'objectField', '');
                        if (field) {
                            newRow[key] = formatValue(get(value, field, ''));
                        } else {
                            newRow[key] = value;
                        }
                    
                    } else {
                        newRow[key] = formatValue(value);
                    }
                });
            
                return newRow;
            })
      }
	  catch(error){
        console.error(error)
      }
  };

  getResult = (result, tabName, match) => {
    if (match && match.params && match.params.entity) {
      return result;
    }
    switch (tabName) {
      case 'persons':
        return convertPersonsList(result,this.props.t);
      case 'contracts':
        return convertContractsList(result);
      case 'contact':
        return convertContactList(result, this.props.t);
      case 'link':
        return convertLinksList(result, this.props.t);
      default:
        return result;
    }
  };

  getStrings = () => {
    const { t } = this.props;

    return {
      'cancel': t('Cancel'),
      'select': t('Select'),
      'active': t('Active'),
      'disabled': t('Disabled'),
      'search': t('Search'),
      'edit': t('Edit').toUpperCase(),
      'add': t('add').toUpperCase(),
      'type': t('type'),
      'lastEditDate': t('lastEditDate'),
      'description': t('description'),
      'file': t('file'),
      'lastRedactor': t('lastRedactor')
    };
  };

  render() {
    const { data, tabs, match, fields, pagination, modalEntity, t} = this.props;
	
	  if (!data || !data.result || (!data.result.length  && data.total !== 0)) return <Loader withContainer={true}/>;

    const result = this.getResult(data.result, data.tabName, match);
    const params = this.getParams(data, tabs, match, fields, pagination);

    return (
      <div className={styles.gridWrapper}>
        <Grid
          data={this.formatData(result, params)}
          params={params}
          strings={this.getStrings()}
          currentTab={tabs.current}
          modalEntity={modalEntity}
          t={t}
        />
      </div>
    );
  }
}

GridWrapper.defaultProps = {
  pagination: {},
};

GridWrapper.propTypes = {
  data: PropTypes.object,
  queriesContentUpdate: PropTypes.func,
  tabs: PropTypes.object,
  match: PropTypes.object,
  fields: PropTypes.object,
  t: PropTypes.func,
  finalFields: PropTypes.object,
  // Custom pagination values
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
    page: PropTypes.number,
    itemsRangeDisplay: PropTypes.number,
  }),
};

export default GridWrapper;
