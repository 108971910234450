import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderMainCell ({ children }) {
	return <div className='headerMainCell'>{children}</div>;
}

HeaderMainCell.propTypes = {
	children: PropTypes.any
};
