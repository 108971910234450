import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "components/Loader/index";

import GridWrapper from "custom-hooks/GridWrapper";
import { setApi } from "reducers/grid";
import Diagram from "pages/Ordering/Orders/Diagram";

import Filters from "./Filters";

import baseService from "../../../../services/BaseService";
import { isEmpty } from "lodash";

const DEFAULT_FILTERS = [{ code: "query", name: "Пошук", search: false, type: "STRING" }];

const mapStateToProps = state => {
    return {
        grid: state.grid,
    };
};

const SpecList = props => {
    const {
        history,
        updateApiGrid,
        itemId,
        itemType,
        code,
        match: {
            params: { id: customerId },
        },
        fixedFilterObject,
        initSort,
        gridConfigCode,
        tabNum,
    } = props;

    // const DEFAULT_TYPE = code.toUpperCase();
    // const TYPE = gridConfigCode || DEFAULT_TYPE;
    const TYPE = code.toUpperCase();

    // const CODE = gridConfigCode || `CUSTOMER_${DEFAULT_TYPE}_TAB`;
    const CODE = gridConfigCode || `CUSTOMER_${TYPE}_TAB`;


    // console.log({tabNum, fixedFilterObject, initSort, gridConfigCode});

    const [loadingState, setLoadingState] = useState(true);
    const [isFiltersLoading, setIsFiltersLoading] = useState(true);
    const [mainFilters, setMainFilters] = useState([]);
    const [secondaryFilters, setSecondaryFilters] = useState([]);
    const [showSecondaryFilters, setShowSecondaryFilters] = useState(false);
    const [gridFilters, setGridFilters] = useState({});

    const init = {
        method: "post",
        key: "customer_services",
        code: CODE,
        type: "spec",
        pathParams: { customerId, objectType: TYPE },
        extra: itemId ? `${TYPE}_${itemType}_${itemId}.${customerId}` : `${TYPE}_${customerId}`,
        ...gridFilters,
        // isDynamic: true,
        configUrlKey: "config_grid",
        configKey: "service", // configKey: 'resource',
        // configCode: TYPE,
        configCode: gridConfigCode || TYPE,
    };
    if (tabNum) {
        init.extra = `${init.extra}_${tabNum}`;
    }
    if (initSort && initSort[0]) {
        init.sort = initSort;
        init.field = initSort[0].property;
        init.order = initSort[0].direction;
    }

    const gridKey = `${init.key}_${init.path || init.extra || ""}`;

    const getParams = () => ({
        selectItem: item => history.push(`/${code}/${item.id}`),
        // selectItem: (item) => history.push(`/resource/${item.id}`),
        fields: {
            hasFlow: {
                component: Diagram,
            },
        },
    });

    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    function toggleSecondaryFilters() {
        setShowSecondaryFilters(prev => !prev);
    }

    // to prevent grid double requests - get filters first, after init grid with filters data
    useEffect(() => {
        baseService
            .get("attributes", { data: { code: CODE, idHashKeyRequest: "" } })
            .then(({ result }) => {
                const allFilters = [...DEFAULT_FILTERS, ...result];
                let newMainFilters = allFilters;
                let newSecondaryFilters = [];
                if (newMainFilters.length > 4) {
                    newMainFilters = allFilters.slice(0, 4);
                    newSecondaryFilters = allFilters.slice(4, allFilters.length);
                }
                setMainFilters(newMainFilters);
                setSecondaryFilters(newSecondaryFilters);
                setIsFiltersLoading(false);
            })
            .catch(e => setIsFiltersLoading(false));
    }, []);

    useEffect(() => {
        // console.log({UPDATE_CUSTOMER_ID: customerId, gridFilters});
        customerId && !isEmpty(gridFilters) &&
            updateApiGrid(
                {
                    pathParams: { customerId, objectType: TYPE },
                    ...gridFilters,
                },
                gridKey
            );

    }, [customerId, gridFilters]);

    useEffect(() => {
        if (itemId) {
            setShowSecondaryFilters(false);
        }
    }, [itemId]);

    return (
        <div
            className={`content-wrapper ordering-component-ui-core-wrapper with-fixed-status with-filters ${showSecondaryFilters &&
                "with-secondary-filters"} ${secondaryFilters.length === 0 && "without-secondary-filters"}`}
        >
            {customerId && (
                <Filters
                    code={code}
                    id={itemId}
                    itemType={itemType}
                    customerId={customerId}
                    isLoading={isFiltersLoading}
                    mainFilters={mainFilters}
                    secondaryFilters={secondaryFilters}
                    gridFilters={gridFilters}
                    setGridFilters={setGridFilters}
                    toggleSecondaryFilters={toggleSecondaryFilters}
                    fixedFilterObject={fixedFilterObject}
                    tabNum={tabNum}
                    key={`${itemId}.${customerId}`}
                />
            )}
            {(loadingState || isFiltersLoading) && <Loader />}
            {!isEmpty(gridFilters) && (
                <GridWrapper
                    tuner={() => init}
                    initUpdate={false}
                    gridParams={getParams()}
                    onStart={onStart}
                    onFinish={onFinish}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps, { updateApiGrid: setApi })(withRouter(SpecList));
