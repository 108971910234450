import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import { connect } from "react-redux";
import cx from "classnames";
import { withTranslation } from "react-i18next";
import { matchPath, withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector, submit, change } from "redux-form";
import { convertDynamicFields } from "helpers";

import InputField from "components/Common/InputField";
import ComboBox from "components/Common/ComboBox";
import baseService from "services/BaseService";
import TextArea from "components/Common/TextArea";
import AddressForm from "../AddressBlock";
import { StreetModes } from "../../../../../constants";
import validate from "../validate";
import { closeCreateNewCustomerModal } from "actions/ui";
import { addCustomerToAppeal, getAppealFeedback, unlockAppealForm } from "modules/appeal/actions";
import { getContactPerson, getCustomer } from "actions/customer";
import {
    createAddressObject,
    physicalAddressValues,
    getDynamicFields,
    renderDynamicFields,
    renderField,
} from "../../../utils";
import { personalDataBlocks, custAddressBlocks, getMergedStaticBlocks, getModalFormDataFromState } from "../../../config";
// import i18next from 'util/i18n';
import Loader from "../../../../Loader";

function PersonForm(props) {
    const {
        setIsLoading,
        t,
        customerTypeName,
        customerTypeId,
        setDynConfig,
        incomePhoneNumber,
        custAddressBlock,
        juridicalDataBlock,
        personalDataBlock,
        modalFormData,
        formCode,
        isSubmitDisabled,
    } = props;
    const [mode, setStreetMode] = useState(StreetModes.AUTO);
    const [dynParamsCustomer, setDynParamsCustomer] = useState(null);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (!props.pristine) {
            props.enableWarning();
        }
    }, [props.pristine]);

    useEffect(() => {
        if (incomePhoneNumber) {
            props.change("homePhone", incomePhoneNumber);
        }
    }, [incomePhoneNumber]);

    useEffect(() => {
        async function fetchData() {
            setFetching(true);
            const responseCustomer = await getDynamicFields(customerTypeName, "CUSTOMER", customerTypeId);
            if (responseCustomer) {
                setFetching(false);
                setDynParamsCustomer(responseCustomer);
                if (Array.isArray(responseCustomer)) {
                    await setDynConfig(responseCustomer);
                }
            }
        }
        if (personalDataBlock && !fetching && !dynParamsCustomer) {
            fetchData();
        }
        return () => setDynParamsCustomer(null);
    }, [customerTypeName, personalDataBlock]);

    // update hiddenValues
    useEffect(() => {
        if (formCode) {
            const hiddenValuesFields = Object.keys(modalFormData)
                .flatMap(key => modalFormData[key].sortedAttrs)
                .filter(field => field.hidden && field.hiddenValues);
            hiddenValuesFields.forEach(field => {
                props.change(field.field, field.hiddenValues);
            });
        }
    }, [formCode]);

    async function submit(values) {
        const path = props.location.pathname;
        const splittedPath = path.split("/");
        const appealsIndex = splittedPath.findIndex(item => item === "appeals");
        const appealId = splittedPath[appealsIndex + 1];

        // await setIsLoading(true);

        const contactsType = [];
        const contactsValue = [];
        if (values.homePhone) {
            contactsType.push("homePhone");
            contactsValue.push(values.homePhone);
        }

        if (values.email) {
            contactsType.push("email");
            contactsValue.push(values.email);
        }

        const formFieldsConfig = [...props.config, { name: "buildingNumberInput" }, { name: "zipCodeInput" }];
        const prepearedDynParams = _.omitBy(values, (item, key) => formFieldsConfig.some(({ name }) => name === key));
        const dynParams = convertDynamicFields(prepearedDynParams);
        const params = {
            data: {
                party: {
                    lastName: values.lastName,
                    firstName: values.firstName,
                    patronymic: values.patronymic,
                    contactsType,
                    contactsValue,
                    dynParams,
                },
            },
            jsonType: true,
        };

        if (Object.keys(values).some(key => values[key] && physicalAddressValues.includes(key))) {
            params.data.address = [createAddressObject(values)];
        }

        if (isSubmitDisabled) {
            return;
        }

        await setIsLoading(true);

        const response = await baseService.post("create_individual_customer", params);

        if (response.success && response.result) {
            const { customerId } = response.result;
            setIsLoading(false);
            props.onClose();

            // console.log({customerId});
            if (typeof _.get(props.newCustomerModalContext, "callback") === "function") {
                const callback = _.get(props.newCustomerModalContext, "callback");
                await callback(customerId, 0);
            }
            if (_.get(props.newCustomerModalContext, "openNewTab") === false) {
                props.addCustomerToAppeal(appealId, customerId, 0);
                props.getCustomer({ customerId: customerId }, customerId);
                props.getAppealFeedback({ appealId, customerId });
            }

            if (_.get(props.newCustomerModalContext, "openNewTab") === true) {
                props.history.push(`/customer/${response.result.customerId}/appeals`);
            }
        } else {
            setIsLoading(false);
            throw new Error("Natural person creation error!");
        }

        await setIsLoading(false);
        await props.onClose();
    }

    return (
        <form onSubmit={props.handleSubmit(submit)} className="ordering-component-ui-core-wrapper">
            <div className="create-customer-form-container">
                <div className={cx("form-wrapper", { hidden: !personalDataBlock || personalDataBlock.hidden})}>
                    <header>{t(personalDataBlock && personalDataBlock.translationLabel)}</header>
                    {naturalPersonConfig(t, personalDataBlock).map(renderField)}
                    {personalDataBlock && (
                        <div className="additional-data">
                            {fetching && !dynParamsCustomer && (
                                <div className="loader-box">
                                    <Loader />
                                </div>
                            )}
                            {dynParamsCustomer && dynParamsCustomer.map(renderDynamicFields)}
                        </div>
                    )}
                </div>
                <div className={cx("form-wrapper", { hidden: !custAddressBlock || custAddressBlock.hidden })}>
                    <header>{t(custAddressBlock && custAddressBlock.translationLabel)}</header>
                    <AddressForm {...props} mode={mode} changeMode={setStreetMode} />
                </div>
            </div>

            <footer className="create-customer-footer">
                <button type="submit" disabled={isSubmitDisabled} className={cx("btn btn-primary", { disabled: isSubmitDisabled })}>
                    <i className="icon icon-check" />
                    {t("create")}
                </button>
            </footer>
        </form>
    );
}

function mapStateToProps(state, ownProps) {
    const { t, dynConfig, formCode } = ownProps;

    const modalFormData = getModalFormDataFromState(state, formCode);
    
    const {
        custAddress: custAddressBlock,
        juridicalData: juridicalDataBlock,
        personalData: personalDataBlock,
    } = modalFormData;
    // const formBlocks = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM || {} ;
    // const { custAddress: custAddressBlock, juridicalData: juridicalDataBlock, personalData: personalDataBlock } = formBlocks;

    const match = matchPath(window.location.hash.substring(1), {
        path: "/appeals/:appealId/:tab",
        exact: true,
        strict: false,
    });
    let conf = addressConf;
    const incomePhoneNumber = state.call.incomePhoneNumber;
    const formSelector = formValueSelector("person-form");
    const values = formSelector(
        state,
        "settlement",
        "street",
        "buildingNumber",
        "buildingSecondNumber",
        "zipCode",
        "apartmentNumber",
        "streetType",
        "streetName",
        "description",
        "buildingNumberInput",
        "zipCodeInput"
    );

    if (Object.keys(values).some(i => values[i])) {
        conf = addressConfPristine;
    }

    return {
        ...values,
        addrConf: conf(t, custAddressBlock) || [],
        match,
        incomePhoneNumber,
        config: [...naturalPersonConfig(t, personalDataBlock), ...(conf(t, custAddressBlock) || []), ...dynConfig],
        newCustomerModalContext: state.ui.newCustomerModalContext,
        custAddressBlock,
        juridicalDataBlock,
        personalDataBlock,
        modalFormData,
    };
}

const mapDispatchToProps = {
    submitForm: form => submit(form),
    onClose: closeCreateNewCustomerModal,
    getCustomer,
    getContactPerson,
    unlockAppealForm,
    addCustomerToAppeal,
    getAppealFeedback,
};

export default withRouter(
    withTranslation()(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(
            reduxForm({
                form: "person-form",
                validate,
            })(PersonForm)
        )
    )
);

// issues are only used inside of a ContactPerson
const naturalPersonConfig = (t, staticData) => {
    const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, personalDataBlocks, {}, "individual").filter(block => block.field !== "issues");
    return mergedStaticBlocks;
};

export const addressConf = (t, staticData) => {
    const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, custAddressBlocks, {}, "individual");
    return mergedStaticBlocks;
};

const addressConfPristine = (t, staticData) => {
    const mergedStaticBlocks = getMergedStaticBlocks(t, staticData, custAddressBlocks, {}, "individual");
    return mergedStaticBlocks;
};
