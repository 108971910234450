import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/appealTypeSelect.module.scss';
import LeafNode from './LeafNode';
import FolderNode from './FolderNode';
import { createCheckPropsFunction } from 'helpers';
import get from 'lodash/get';
import {
	closeNodeChildren,
	findNodeByField,
	getNodePath,
	openNodeAndSelect,
	searchMatchedLeafs,
	updateNode
} from './helpers';

class AppealTypesTree extends React.PureComponent {
	
	constructor (props) {
		super(props);
		
		this.state = {
			nodes: AppealTypesTree.getNormalizedNodes(props) || [],
			selectedLeaf: null,
			searchResults: []
		};
		
		this.renderNode = this.renderNode.bind(this);
		this.renderSearchNode = this.renderSearchNode.bind(this);
		this.toggleFolder = this.toggleFolder.bind(this);
		this.selectLeaf = this.selectLeaf.bind(this);
		this.selectSearchLeaf = this.selectSearchLeaf.bind(this);
		this.selectInitialValue = this.selectInitialValue.bind(this);
		this.scrollIntoView = this.scrollIntoView.bind(this);
	}
	
	componentDidMount () {
		this.selectInitialValue();
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		
		if (isPropChanged('nodeArray')) {
			this.setState({
				nodes: AppealTypesTree.getNormalizedNodes(this.props)
			});
		}
		
		if (isPropChanged('searchQuery')) {
			let nextSearchResults = [];
			
			if (this.props.searchQuery.length > 0) {
				nextSearchResults = searchMatchedLeafs(this.state.nodes, this.props.searchQuery, this.props);
			}
			
			this.setState({ searchResults: nextSearchResults });
		}
	}
	
	static getNormalizedNodes (props) {
		const { nodeArray, childrenField } = props;
		
		const normalizeNodes = (array, parent) => {
			if (!Array.isArray(array) || array.length === 0) return null;
			
			return array.map(node => {
				const normalizedNode = { ...node, parent, open: false };
				normalizedNode[childrenField] = normalizeNodes(node[childrenField], normalizedNode);
				
				return normalizedNode;
			});
		};
		
		return normalizeNodes(nodeArray, null);
	}
	
	selectInitialValue () {
		const { childrenField, valueField, initialValue, onSelectLeaf, leafField } = this.props;
		const { nodes } = this.state;
		
		if (initialValue) {
			const foundNode = findNodeByField(nodes, valueField, initialValue[valueField], childrenField);
			if (foundNode && foundNode[leafField]) {
				const updatedNodes = openNodeAndSelect(this.state.nodes, foundNode, this.props);
				
				onSelectLeaf(foundNode);
				this.setState({ nodes: updatedNodes }, () => this.scrollIntoView(foundNode[valueField]));
			}
		}
	}
	
	scrollIntoView (nodeValue) {
		const htmlNode = document.getElementById(nodeValue);
		if (!htmlNode) return;
		
		if (htmlNode.scrollIntoViewIfNeeded) {
			htmlNode.scrollIntoViewIfNeeded(false);
		} else {
			htmlNode.scrollIntoView(false);
		}
	}
	
	/**
	 * If opened - close node and it's children, if closed - open
	 * @param {Object} folderNode
	 */
	toggleFolder (folderNode) {
		const { childrenField, valueField } = this.props;
		
		const nodePath = getNodePath(folderNode, valueField);
		
		const toggleNode = node => {
			const updatedNode = { ...node };
			updatedNode.open = !node.open;
			return updatedNode.open ? updatedNode : closeNodeChildren(updatedNode, childrenField);
		};
		
		const updatedNodes = updateNode(this.state.nodes, nodePath, toggleNode, this.props);
		
		this.setState({ nodes: updatedNodes });
	}
	
	selectLeaf (leafNode) {
		const { valueField, selectedLeaf, onSelectLeaf } = this.props;
		
		let updatedNodes = this.state.nodes;
		let nextSelectedLeaf = null;
		
		const isSameNode = get(leafNode, valueField) === get(selectedLeaf, valueField);
		
		if (selectedLeaf) {
			const nodePath = getNodePath(selectedLeaf, valueField);
			const deselectNode = node => ({ ...node, selected: false });
			updatedNodes = updateNode(updatedNodes, nodePath, deselectNode, this.props);
		}
		
		if (leafNode && !isSameNode) {
			const nodePath = getNodePath(leafNode, valueField);
			const selectNode = node => ({ ...node, selected: true });
			updatedNodes = updateNode(updatedNodes, nodePath, selectNode, this.props);
			nextSelectedLeaf = leafNode;
		}
		
		onSelectLeaf(nextSelectedLeaf);
		this.setState({ nodes: updatedNodes });
	}
	
	renderNode (node) {
		const { valueField, labelField, leafField, childrenField, onSubmit } = this.props;
		
		const commonProps = { valueField, labelField, leafField, childrenField, node };
		
		if (node[leafField]) {
			return (
				<LeafNode
					key={node[valueField]}
					{...commonProps}
					onSelect={this.selectLeaf}
					onSubmit={onSubmit}
				/>
			);
		} else {
			return (
				<FolderNode
					key={node[valueField]}
					{...commonProps}
					onClick={this.toggleFolder}
					renderNode={this.renderNode}
				/>
			);
		}
	}
	
	selectSearchLeaf (leafNode) {
		const { valueField, onSelectLeaf } = this.props;
		
		const updatedSearchResults = this.state.searchResults.map(searchNode => {
			if (searchNode[valueField] === leafNode[valueField]) {
				return { ...searchNode, selected: !searchNode.selected };
			}
			
			if (searchNode.selected && searchNode[valueField] !== leafNode[valueField]) {
				return { ...searchNode, selected: false };
			}
			
			return searchNode;
		});
		
		this.setState({ searchResults: updatedSearchResults });
		onSelectLeaf(leafNode);
	}
	
	renderSearchNode (node) {
		const { valueField, labelField, leafField, childrenField, onSubmit } = this.props;
		const commonProps = { valueField, labelField, leafField, childrenField, node };
		
		return (
			<LeafNode
				key={node[valueField]}
				{...commonProps}
				onSelect={this.selectSearchLeaf}
				searchMode
				onSubmit={onSubmit}
			/>
		);
	}
	
	render () {
		const { searchQuery } = this.props;
		const { nodes, searchResults } = this.state;
		
		const searchModeEnabled = searchQuery.length > 0;
		
		return (
			<div className={styles.content}>
				{!searchModeEnabled && nodes.map(this.renderNode)}
				{searchModeEnabled && searchResults.map(this.renderSearchNode)}
			</div>
		);
	}
}

AppealTypesTree.propTypes = {
	nodeArray: PropTypes.array,
	valueField: PropTypes.string,
	labelField: PropTypes.string,
	leafField: PropTypes.string,
	childrenField: PropTypes.string,
	searchQuery: PropTypes.string,
	initialValue: PropTypes.object,
	selectedLeaf: PropTypes.object,
	onSelectLeaf: PropTypes.func
};

export default AppealTypesTree;
