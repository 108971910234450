import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from 'components/Loader/index';
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';

const AddressList = props => {
	const { updateApiGrid, gridRefresh, setGridRefresh, itemId, itemType, match: { params: { id: customerId } } } = props;
	const init = {
		key: 'customer_address',
		// extra: customerId,
		extra: itemId ? `address_${itemType}_${itemId}.${customerId}` : `address_${customerId}`,
		query: ''
	};
	const gridKey = `${init.key}_${init.path || init.extra || ''}`;
	
	const [loadingState, setLoadingState] = useState(true);
	
	const onStart = () => setLoadingState(true);
	
	const onFinish = () => setLoadingState(false);
	
	useEffect(() => {
		customerId && updateApiGrid({ customerId }, gridKey);
	}, [customerId]);
	
	useEffect(() => {
		if (gridRefresh) {
			customerId && updateApiGrid({ customerId }, gridKey);
			setGridRefresh(false);
		}
	}, [gridRefresh]);
	
	return (
		<div className='content-wrapper ordering-component-ui-core-wrapper'>
			{loadingState && <Loader />}
			<GridWrapper
				tuner={() => init}
				initUpdate={false}
				gridParams={{
					selectItem: props.selectItem,
				}}
				onStart={onStart}
				onFinish={onFinish}
			/>
		</div>
	);
};

export default connect(null, { updateApiGrid: setApi })(withRouter(AddressList));
