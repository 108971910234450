import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import SearchHeader from 'components/SearchHeader';
import MainTabs from 'components/MainTabs';
import UserInfo from 'components/UserInfo';
import NotificationHistory from 'components/NotificationHistory';
import { showNotificationPanel } from 'actions/ui';
import { preloadLazy } from 'helpers';
import permissions, {checkPermissions} from "../../config/permissions";

const callStations = {
	finesse: preloadLazy(import('components/Calls/Finesse')),
	asterisk: preloadLazy(import('components/Calls/Asterisk')),
	binotel: preloadLazy(import('components/Calls/Binotel')),
	vicidial: preloadLazy(import('components/Calls/Vicidial'))
};

const mapStateToProps = state => ({
	ui: state.ui,
	user: state.user,
	expandedSearch: state.headerSearch.expandedSearch,
	callStation: state.call.callStation,
	canCallOperations: checkPermissions(permissions.CallOperations.action_workPbx)
});

const mapDispatchToProps = dispatch => ({
	showNotificationPanel: () => dispatch(showNotificationPanel()),
});

@connect(mapStateToProps, mapDispatchToProps)
class Header extends React.Component {
	render () {
		const {
			user, history, expandedSearch,
			ui: {
				createMenu, create, showHiddenTabs, showCloseTabsMenu,
			},
			canCallOperations,
			callStation: storeCallStation
		} = this.props;
		
		const callStation = canCallOperations && (storeCallStation || localStorage.getItem('callStation'));
		const headerRightCls = cx('header-block-right', { 'expand-search': expandedSearch });
		const Calls = callStations[callStation];		
		return (
			<header className={cx('header-block', 'card-panel')}>
				<MainTabs
					ui={{
						createMenu, create, showHiddenTabs, showCloseTabsMenu,
					}}
					user={user}
				/>
				<div className={headerRightCls}>
					<SearchHeader
						user={user}
						expandedSearch={expandedSearch}
						history={history}
					/>
					<NotificationHistory />
					<UserInfo />
					<Suspense fallback={''}>
						{callStation && Calls ? <Calls /> : null}
					</Suspense>
				</div>
			</header>
		);
	}
}

Header.propTypes = {
	staticContext: PropTypes.bool,
	ui: PropTypes.object,
	user: PropTypes.object,
	expandedSearch: PropTypes.bool,
};

export default withRouter(Header);
