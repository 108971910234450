import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader/index';
import { isEmpty } from 'lodash';

import { configureFilterObject } from '../../SearchFilters/helpers';
import GridWrapper from '../../../custom-hooks/GridWrapper';
import { setApi } from '../../../reducers/grid';
import { setCurrentTab } from '../../../actions';
import { setAppealGridRefresh } from "../../../modules/appeal/actions"

const GRID_API_KEY = "appeal_preset"; // default grid (not used during form search)
const FILTER_GRID_API_KEY = "appeal"; // grid with opened filters on the right side

const init = (apiKey, gridConfigCode, activeFilter) => {
    const data = {
        key: apiKey,
        method: 'post',
        code: 'INTERACTION_REQUEST',
        filterObject: [],
        configUrlKey: "config_grid",
        configKey:"appeal",
        configCode: gridConfigCode || "INTERACTION_REQUEST",
        type: "appeal",
        extra: gridConfigCode,
        filterId: activeFilter,
    };
    if (apiKey !== GRID_API_KEY) {
        delete data.filterId;
        data.filterObject = [];
    }
    return data;
};

const mapStateToProps = (state) => ({
    filter: state.filter,
    filterList: state.filter.filterList,
    formValues: getFormValues('appeal-filters')(state),
    anyTouched:  state.form && state.form['appeal-filters'] && state.form['appeal-filters'].anyTouched,
    active: state.form && state.form['appeal-filters'] && state.form['appeal-filters'].active,
    gridRefresh: state.appeal.gridRefresh,
    grids: state.grid,
});

const AppealListGrid = (props) => {
    const { activeFilter, updateApiGrid, filterList, formValues, filter, anyTouched, active, gridRefresh, setAppealGridRefresh, gridConfigCode, match, setCurrentTab, grids, needPageReset,
        setNeedPageReset } = props;
    
    const [loadingState, setLoadingState] = useState(true);
    const [apiKey, setApiKey] = useState(GRID_API_KEY);
    const getParams = () => ({
        selectItem: props.selectItem,
    });
    const initData = init(apiKey, gridConfigCode, activeFilter);
    const gridKey = `${initData.key}_${initData.path || initData.extra || ""}`;

    const currentGridApi = grids && grids[gridKey] && grids[gridKey].api;
    
    const handleGridUpdate = () => {
        if (apiKey === FILTER_GRID_API_KEY && formValues) {
            const { filterName, isPublic, predefined, ...formData } = formValues;
            const filterObject = configureFilterObject(formData, filter.filterAttrsResults || []);
            // on filter open reset page to 1
            updateApiGrid(
                { key: apiKey, filterObject: filter.editFilterMode ? filterObject : [], page: 1 },
                gridKey,
            );
        }
        if (apiKey === GRID_API_KEY) {
            const isFilterIdEqual = currentGridApi && currentGridApi.filterId === activeFilter;
            const page = !needPageReset && isFilterIdEqual && currentGridApi && currentGridApi.page || 1;
            updateApiGrid({ key: apiKey, filterId: activeFilter, filterObject: [], page }, gridKey);
            setNeedPageReset(false);
        }
    };

    useEffect(() => {
        if (activeFilter && apiKey !== GRID_API_KEY) {
            setApiKey(GRID_API_KEY);
        } else {
            const isFilterIdEqual = currentGridApi && currentGridApi.filterId === activeFilter;
            const page = !needPageReset && isFilterIdEqual && apiKey === GRID_API_KEY && currentGridApi && currentGridApi.page || 1;
            updateApiGrid({ filterId: activeFilter, filterObject: [], page }, gridKey);
            setNeedPageReset(false);
        }
    }, [activeFilter]);

    useEffect(() => {
        if (formValues) {
            const { filterName, isPublic, predefined, ...formData } = formValues;
            if (filter && filter.newFilter && (anyTouched || active)) {
                let filterObject = [];
                if (!isEmpty(formData)) {
                    filterObject = configureFilterObject(formData, filter.filterAttrsResults || []);
                }
                // on filters values reset page to 1
                updateApiGrid({ filterObject, page: 1 }, gridKey);
            }
            // handling editing filter case
            if (filter && filter.editFilterMode && (anyTouched || active)) {
                if (apiKey !== FILTER_GRID_API_KEY) {
                    setApiKey(FILTER_GRID_API_KEY);
                } else {
                    let filterObject = [];
                    if (!isEmpty(formData)) {
                        filterObject = configureFilterObject(
                            formData,
                            filter.filterAttrsResults || [],
                            "YYYY-MM-DDTHH:mm"
                        );
                    }
                    // on filters values reset page to 1
                    updateApiGrid({ filterObject, page: 1 }, gridKey);
                }
            }
        }
    }, [formValues]);

    useEffect(() => {
        if (formValues) {
            if (filter && filter.newFilter && filter.showFilterParams && apiKey !== FILTER_GRID_API_KEY) {
                setApiKey(FILTER_GRID_API_KEY);
            }
            if (filter && !filter.showFilterParams && apiKey !== GRID_API_KEY) {
                setApiKey(GRID_API_KEY);
            }
        }
    }, [filter, formValues]);

    useEffect(() => {
        handleGridUpdate();
    }, [apiKey]);

    useEffect(() => {
        if (gridConfigCode) {
            setApiKey(GRID_API_KEY);
        }
    }, [gridConfigCode]);

    useEffect(() => {
        if (gridRefresh) {
            // trigger update
            handleGridUpdate();
            setAppealGridRefresh(false);
        }
    }, [gridRefresh]);

    const onStart = () => setLoadingState(true);

    const onFinish = () =>  {
        setLoadingState(false);
        // setCurrentTab({
        //     ...match,
        //     displayedName: "Appeal",
        //     name: () => `appeal`,
        //     tabType: `appeal`,
        // });
    };

    return (
        <div className="content-wrapper with-fixed-status">
            {loadingState && <Loader />}
            <GridWrapper
                tuner={() => init(apiKey, gridConfigCode, activeFilter)}
                initUpdate={false}
                gridParams={getParams()}
                onStart={onStart}
                onFinish={onFinish}
            />
        </div>
    );
};

export default connect(mapStateToProps, { updateApiGrid: setApi, setCurrentTab, setAppealGridRefresh })(
    withRouter(AppealListGrid),
);
