import i18next from 'util/i18n';

function validate (values) {
	const errors = {};
	
	if (!values.name) {
		errors.name = i18next.t('validation.required');
	}
	
	return errors;
}

export default validate;
