import { StreetModes, customerAddressFormConfigWithType } from 'constants/index';
import { zipCodeValidation } from 'util/form';
import i18next from 'util/i18n';

const comboBoxWithInputFields = ['buildingNumber', 'zipCode'];
const validate = (values) => {
	const errors = {};
	
	customerAddressFormConfigWithType.forEach(field => {
		
		if (field.required && !values[field.name]) {
			errors[field.name] = i18next.t('validation.required');
		}
		
		if (values.mode === StreetModes.MANUAL) {
			errors.street = undefined;
			
			if (!values.streetName) {
				errors.streetName = i18next.t('validation.required');
			}
			else if (field.inputProps && field.name === 'street' && values.streetName.length > field.inputProps.maxLength) {
				errors.streetName = i18next.t('validation.maxLen', { length: field.inputProps.maxLength });
			}
			
			if (!values.streetType) {
				errors.streetType = i18next.t('validation.required');
			}
		}
	});
	
	comboBoxWithInputFields.forEach(field => {
		if (errors[field] && values[`${field}Input`]) {
			errors[field] = undefined;
		}
	});
	
	if (values.zipCodeInput && zipCodeValidation(values.zipCodeInput)) {
		errors.zipCode = zipCodeValidation(values.zipCodeInput);
	}
	
	return errors;
};

export default validate;
