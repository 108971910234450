import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import MessageModal from 'components/MessageModal';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import ReactSVG from "react-svg";

@withTranslation()
class File extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessageModal: false,
        };
        this.input = React.createRef();
        this.closeMessageModal = this.closeMessageModal.bind(this);
        this.openMessageModal = this.openMessageModal.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    getPreviewFileLink = () => `../mw/file?fileId=${this.props.id}&download=0`;
    getDownloadFileLink = () => `../mw/file?fileId=${this.props.id}&download=1`;

    closeMessageModal() {
        this.setState({ showMessageModal: false });
    }

    onDeleteClick() {
        this.closeMessageModal();
        this.props.onDelete(this.props.index);
    }

    openMessageModal() {
        this.setState({ showMessageModal: true });
    }

    render() {
        const { name, disabled, t } = this.props;
        const { showMessageModal } = this.state;

        return (
            <div className="kb-file-wrapper">
                <CSSTransition
                    in={showMessageModal}
                    classNames="fade"
                    appear
                    enter
                    exit
                    timeout={500}
                    mountOnEnter
                    unmountOnExit
                >
                    <ModalPortal
                        onClose={this.closeMessageModal}
                        className="modal-small modal-center"
                    >
                        <MessageModal
                            titleModal={t('modalWindowsLabels.deleteFileTitle')}
                            contentModalText={`${t(
                                'modalWindowsLabels.deleteFileMessage',
                            )}: "${name}"`}
                            primaryButton
                            primaryButtonText={t('modalWindowsLabels.allow')}
                            onClickPrimaryButton={this.onDeleteClick}
                            secondaryButton
                            secondaryButtonText={t('modalWindowsLabels.disallow')}
                            onClickSecondaryButton={this.closeMessageModal}
                        />
                    </ModalPortal>
                </CSSTransition>
                <a className="file-preview" target="_blank" href={this.getPreviewFileLink()} title={name}>
                    {name}
                </a>
                <div className="kb-file-actions">
                    <a className="file-download" target="_blank" href={this.getDownloadFileLink()} title={name}>
                        <ReactSVG className="svg-download" src="/data/svg/download.svg"/>
                    </a>
                    {!disabled && <ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" onClick={this.openMessageModal} />}
                </div>
            </div>
        );
    }
}

File.propTypes = {
    type: PropTypes.oneOf(['local', 'remote']),
    name: PropTypes.string,
    index: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
};

export default File;
