import get from 'lodash/get';
import {
	CREATE_NEW_APPEAL, CREATE_NEW_APPEAL_SUCCESS, CREATE_NEW_APPEAL_ERROR,
	GET_APPEAL_TYPES_LIST_SUCCESS, GET_APPEAL_TYPES_LIST_ERROR, GET_DINAMIC_FORM_PARAMS,
	GET_DINAMIC_FORM_PARAMS_SUCCESS, GET_DINAMIC_FORM_PARAMS_ERROR,
	GET_APPEAL_STATUS_LIST, GET_APPEAL_STATUS_LIST_SUCCESS, GET_APPEAL_STATUS_LIST_ERROR,
	SAVE_APPEAL, SAVE_APPEAL_SUCCESS, SAVE_APPEAL_ERROR, APPEAL_DESTINATIONS_REQUEST, APPEAL_DESTINATIONS_NEED_REFRESH,
	APPEAL_DESTINATIONS_REQUEST_SUCCESS, APPEAL_DESTINATIONS_REQUEST_FAILURE, UNLOCK_APPEAL_FORM,
	UNLOCK_APPEAL_FORM_SUCCESS, UNLOCK_APPEAL_FORM_ERROR, LOCK_APPEAL_FORM, GET_APPEAL_PRIORITY_LIST,
	GET_APPEAL_PRIORITY_LIST_SUCCESS, GET_APPEAL_PRIORITY_LIST_ERROR, GET_APPEAL_FEEDBACK, GET_APPEAL_FEEDBACK_SUCCESS,
	GET_APPEAL_FEEDBACK_ERROR, CLEAR_APPEAL_FEEDBACK, APPEAL_REQUEST, APPEAL_REQUEST_SUCCESS,
	APPEAL_REQUEST_FAILURE, CLEAR_APPEAL_STATE, GET_APPEAL_ACTIONS, SET_APPEAL_ACTIONS,
	SET_FIRST_LEVEL_ACTION, SET_RESOLUTION_ACTION, SET_APPEAL_VERSION, REMOVE_REFRESH_APPEAL_MODAL,
	SET_MODAL_STATUS_LIST, SET_STATUS_MODAL_VISIBILITY, APPEAL_FETCHING, REMOVE_CUSTOMER_FROM_APPEAL,
	ADD_CUSTOMER_TO_APPEAL, CLEAR_ALL_APPEALS, SAVE_AND_CREATE_NEW, SET_REQ_DYN_PARAMS, SET_REQUIRED_CUSTOMER,
	UPDATE_VIEW_LINKS, SET_LINKED_APPEALS, SET_LINK_СHECKED_BINDERS, CHECK_LINK_BINDER, UNCHECK_LINK_BINDER, CLEAR_LINK_BINDERS, LINK_FILTERS_REQUEST_FAILURE,
	LINK_FILTERS_REQUEST_SUCCESS,
	SET_LINKED_OBJECTS, SET_OBJECT_LINK_СHECKED_BINDERS, CHECK_OBJECT_LINK_BINDER, UNCHECK_OBJECT_LINK_BINDER, CLEAR_OBJECT_LINK_BINDERS,
	SET_APPEAL_GRID_REFRESH, SET_APPEAL_STATUS_LIST_TEMPLATE_DATA, CLEAR_APPEAL_STATUS_LIST_TEMPLATE_DATA, SET_APPEAL_STATUS_LIST_INITIALIZED,
	SET_APPEAL_IS_SAVE_AND_CREATE_NEW, REMOVE_APPEAL_LOCK_SOURCE, UNLOCK_APPEAL_AND_CLEAR_SOURCE, UNLOCK_APPEAL_AND_CLEAR_SOURCE_SUCCESS, UNLOCK_APPEAL_AND_CLEAR_SOURCE_ERROR,
	UNLOCK_APPEAL_FORM_ALREADY_UNLOCKED,
	SET_APPEAL_ID_CENTRAL_PART_LOADING,
	SET_FORM_FORCE_INIT_VALUES
} from './constants';
import { UPDATE_COMMENT_SUCCESS, SET_MAIL_STATE } from 'constants/actions';

import {
	ADD_CREATED_APPEAL, UPDATE_CREATED_APPEAL_NAME, SET_CURRENT_TAB_NAME_BLOCKED,
	ADD_TABS, ADD_NEW_INTERACTION,
} from 'constants/actions';
import baseService from 'services/BaseService';
import { showNotification } from 'actions/ui';
import { NotificationTypes } from 'constants/index';
import i18next from 'util/i18n';

const MAIL_DEFAULT_STATE = {
	sendFormOpening: false,
	forward: false,
	minimized: false,
	standartView: false,
	maximized: false,
	mailBody: null,
	formFields: null,
	signature: null,
};


export function setRequiredCustomer (id, value) {
	return (dispatch) => {
		dispatch({
			type: SET_REQUIRED_CUSTOMER,
			value,
			id,
		});
	};
}

export function removeCustomerFromAppeal (id) {
	// to call destinations (reloadDestinations)
	return (dispatch) => {
		dispatch({
			type: APPEAL_DESTINATIONS_NEED_REFRESH,
			id,
		});
		dispatch({
			type: REMOVE_CUSTOMER_FROM_APPEAL,
			id,
		});
	};
}

export function addCustomerToAppeal (id, customerId, contactPersonId) {
	// to call destinations (reloadDestinations)
	return (dispatch) => {
		dispatch({
			type: APPEAL_DESTINATIONS_NEED_REFRESH,
			id,
		});
		dispatch({
			type: ADD_CUSTOMER_TO_APPEAL,
			id,
			customerId,
			contactPersonId,
		});
	};
}

export function saveFeedback (saveFeedbackData) {
	baseService.post('appeal_feedback_save', saveFeedbackData)
		.catch(console.error);
}


// is used in AppealMainContent for proper submit via StatusSelect (to keep track of value regarding createNew appeal or not)
export function setAppealIsSaveAndCreateNew (isSaveAndCreateNew, id) {
	return (dispatch) => {
		dispatch({
			type: SET_APPEAL_IS_SAVE_AND_CREATE_NEW,
			isSaveAndCreateNew,
			id,
		});
	};
}

export function setStatusModalVisibility (payload, id) {
	return (dispatch) => {
		dispatch({
			type: SET_STATUS_MODAL_VISIBILITY,
			payload,
			id,
		});
	};
}

export function setModalStatusLIst (payload, id) {
	return (dispatch) => {
		dispatch({
			type: SET_MODAL_STATUS_LIST,
			payload,
			id,
		});
	};
}

export function removeRefreshAppealModal (id) {
	return (dispatch) => {
		dispatch({
			type: REMOVE_REFRESH_APPEAL_MODAL,
			id,
		});
	};
}

export function getAppealVersion (id) {
	return (dispatch) => {
		baseService.get('get_appeal_version', { data: { id }, jsonType: true })
			.then((response) => {
				dispatch({
					type: SET_APPEAL_VERSION,
					payload: response.result.version,
					id,
				});
			});
	};
}

// called once appeal is closed (tab closed)
export function unlockAppeal (data) {
	baseService.get('unlock_appeal', { data, jsonType: true });
}

export function unlockAppealAndClearSource(id, forceClearSource) {
	// console.log({id, forceClearSource});
	return (dispatch, getState) => {
		const state = getState();
        const appeals = state.appeal && state.appeal.appeals;
		const appeal = appeals && appeals[id];
		const isUnlocking = appeal && appeal.isUnlocking;
		// prevent duplicate request
		if (isUnlocking) {
			return;
		}
		dispatch({
			type: UNLOCK_APPEAL_AND_CLEAR_SOURCE,
			id,
		});

		baseService.get('unlock_appeal', { data: { id }, jsonType: true })
		.then(data => {
			if (data.success) {
				dispatch({
					type: UNLOCK_APPEAL_AND_CLEAR_SOURCE_SUCCESS,
					id,
					version: data && data.result && data.result.version,
					forceClearSource,
				});
			}
		})
		.catch(error => dispatch({
			type: UNLOCK_APPEAL_AND_CLEAR_SOURCE_ERROR,
			error,
			id,
		}));
	};
}

// to check appeals and unlock them if there was no form change (in case of appeal save and tab switch)
export function unlockAppealAndClearSourceWithoutForm(id) {
    return (dispatch, getState) => {
        const state = getState();
        const appeals = state.appeal && state.appeal.appeals;
        if (appeals) {
            const appeal = appeals[id];
            if (appeal) {
                const isUnlocked = appeal.unlockedAppeal;
                const currentLockList = appeal.lockSourceList;
                if (isUnlocked && currentLockList && !currentLockList.includes("form")) {
                    dispatch(unlockAppealAndClearSource(id, true));
                }
            }
        }
    };
}

// to check appeals and unlock them if there was no form change (in case of tab switch and etc)
// export function unlockAppealAndClearSourceWithoutForm() {
// 	return (dispatch, getState) => {
// 		const state = getState();
// 		const appeals = state.appeal && state.appeal.appeals;
// 		if (appeals) {
// 			Object.keys(appeals).forEach(id => {
// 				const appeal = appeals[id];
// 				if (appeal) {
// 					// console.log({appeal, id});
// 					const isUnlocked = appeal.unlockedAppeal;
// 					const currentLockList = appeal.lockSourceList;
// 					// console.log({currentLockList, isUnlocked});
// 					if (isUnlocked && currentLockList && !currentLockList.includes("form")) {
// 						dispatch(unlockAppealAndClearSource(id, true));
// 					}
// 				}
// 			});
// 		}
// 	};
// }

export function setResolutionActions (data, id) {
	return (dispatch) => {
		dispatch({
			type: SET_RESOLUTION_ACTION,
			payload: data,
			id,
		});
	};
}

export function setFirstLevelActions (data, id) {
	return (dispatch) => {
		dispatch({
			type: SET_FIRST_LEVEL_ACTION,
			payload: data,
			id,
		});
	};
}

export function getAppealActions (data, destinations, seDefault) {
	return (dispatch) => {
		const requestData = {
			statusCode: data.status.id,
			curOperatorId: data.curOperatorId,
			routeCode: data.route.key,
			destinationIdList: destinations,
		};
		
		return baseService.post('get_appeal_actions', { data: requestData, jsonType: true })
			.then((response) => {
				dispatch({
					type: GET_APPEAL_ACTIONS,
					payload: response.result,
					id: data.id,
				});
				if (seDefault && response.result && response.result.length > 0) {
					const action = response.result.find(i => i.action === 'toSave');
					const setAppeal = setAppealAction({
						appealId: data.id,
						statusCode: action.toState,
						action: action.action,
					}, action.label);
					return setAppeal(dispatch);
				} else {
					return true;
				}
			}).catch();
	};
}

export function setAppealAction (data, label) {
	
	return (dispatch) => {
		return baseService.get('set_appeal_action', { data, jsonType: true })
			.then((response) => {
				dispatch({
					type: SET_APPEAL_ACTIONS,
					payload: response.result,
					label,
					id: data.appealId,
				});
				return true;
			});
	};
}

export function showValidationNotification (field = 'validation.addRequiredFields') {
	return (dispatch) => {
		dispatch(showNotification({
			type: NotificationTypes.APPEAL_CHANGE_FAILURE,
			options: { field: i18next.t(field) },
		}));
	};
}

export function clearAppealState (id) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_COMMENT_SUCCESS,
			appealId: id || 'all',
		});
		if (id) {
			dispatch({
				type: CLEAR_APPEAL_STATE,
				id,
			});
			dispatch({
				type: SET_MAIL_STATE,
				id,
				state: MAIL_DEFAULT_STATE
			});
		}
	};
}

export const getAppeal = (requestData, dirty, updateAppealInCall) => (dispatch, getState) => {
	if (!dirty) {
		dispatch({
			type: APPEAL_REQUEST,
			id: requestData.id,
		});
	}
	
	const params = {
		data: requestData
	};
	
	return baseService.get('appeal_by_id', params, true)
		.then(response => {
			let result = null;
			if (response.success && response.result) {
				result = { ...response.result, timestamp: response.timestamp };
				dispatch({
					type: APPEAL_REQUEST_SUCCESS,
					appealData: result,
				});
				
				// only add new interaction if there is no active interaction
				const interactionId = get(getState(), 'call.callInteractionId');
				if(updateAppealInCall && !interactionId){
					dispatch({
						type: ADD_NEW_INTERACTION,
						payload: result.interactionId
					});
				}
				
				if (updateAppealInCall && result.id.toString() !== result.regnum) {
					dispatch({
						type: UPDATE_CREATED_APPEAL_NAME,
						payload: {
							appeal: {
								id: result.id,
								name: result.regnum,
							},
						},
					});
				}
			} else {
				if (response.response.data.errorCode === -20403) {
					dispatch({
						type: APPEAL_REQUEST_FAILURE,
						appealIsBlocked: true,
						id: requestData.id,
					});
					dispatch({ type: SET_CURRENT_TAB_NAME_BLOCKED });
				} else {
					dispatch({
						type: APPEAL_REQUEST_FAILURE,
						id: requestData.id,
					});
				}
				
			}
			return result;
		})
		.catch(() => {
			dispatch({
				type: APPEAL_REQUEST_FAILURE,
				id: requestData.id,
			});
			return null;
		});
};

export const createInteraction = (interactionRequestData = { typeCode: 'PRECRM' }) => {
	const params = { data: interactionRequestData };
	
	return baseService.post('interaction', params)
		.then(response => {
			const interactionId = get(response, 'result[0].id');
			
			if (response.success && interactionId) {
				return interactionId;
			} else {
				throw new Error('Interaction request error');
			}
		});
};

export const createAppeal = appealRequestData => {
	const params = { data: appealRequestData, jsonType: true };
	return baseService.post('appeal_create', params)
		.then(response => {
			if (response.success && response.result) {
				return response.result;
			} else {
				throw new Error('Appeal creation request error');
			}
		});
};

export function createNewAppeal ({
		appealRequestData = {},
		interactionRequestData = { typeCode: 'PRECRM' } } = {},
		interactionId = null
	)
{
	return async (dispatch) => {
		
		dispatch({
			type: CREATE_NEW_APPEAL,
		});
		const data = {
				appealType: 0,
				classCode: 0,
				contactPersonId: null,
				customerId: null,
				repeatRequestId: null,
				paramKey:  [],
				paramVal:  [],
				interactionId,
				...interactionRequestData,
				...appealRequestData,
		};

		try {
			const createdAppeal = await createAppeal(data);
			dispatch({
				type: CREATE_NEW_APPEAL_SUCCESS,
			});
			if (interactionId) {
				dispatch({
					type: ADD_CREATED_APPEAL,
					payload: {
						appeal: {
							id: createdAppeal.id,
						},
					},
				});
			}
			
			return createdAppeal;
		} catch (e) {
			dispatch({
				type: CREATE_NEW_APPEAL_ERROR,
				error: 'Something went wrong',
			});
		}
	};
}

/**
 * @param requestData - { repeatRequestId, appealId, typeCode }
 * @param onSuccess - callback with created appeal as param
 * @returns Promise of request
 */
export const repeatAppeal = (requestData, onSuccess) => {
	
	const params = { data: requestData };
	
	return baseService.post('interaction', params)
		.then(response => {
			const interactionId = get(response, 'result[0].id');
			if (response.success && interactionId) {
				const params = {
					data: {
						interactionId,
						appealType: 0,
						classCode: 0,
						typeCode: requestData.typeCode,
						repeatRequestId: requestData.repeatRequestId
					},
					jsonType: true
				};
				
				return baseService.post('appeal_create', params);
			} else {
				throw new Error('mw/interaction/create error');
			}
		})
		.then(response => {
			if (response.success && response.result) {
				onSuccess && onSuccess(response.result);
			}
		})
		.catch(console.error);
};

/**
 * @param requestData - { repeatRequestId, appealId }
 * @param onSuccess - callback with created appeal as param
 * @returns Promise of request
 */
export const appointAppeal = (requestData, onSuccess) => {
	const params = { data: requestData, jsonType: true };
	
	return baseService.post('appeal_save', params)
		.then(response => {
			if (response.success) {
				onSuccess && onSuccess();
			}
		})
		.catch(console.error);
};

export function getAppealTypesList (id) {
	return (dispatch) => {
		const params = {
			data: {
				classCode: 0,
				node: 'root'
			}
		};
		
		return baseService.get('appeal_type', params)
			.then(data => {
				dispatch({
					type: GET_APPEAL_TYPES_LIST_SUCCESS,
					result: data,
					id,
				});
				return true;
			})
			.catch(error => {
				console.log(error, id);
				dispatch({
					type: GET_APPEAL_TYPES_LIST_ERROR,
					error,
					id,
				});
				return false;
			});
	};
}

export function getDynamicFormParams (data) {
	const {
		ctxObjectId, objectType, objectId, objectTypeId, objectTypeCode,
		prevObjectTypeId, openMode,
	} = data;
	
	return (dispatch) => {
		const params = {
			data: {
				ctxObjectType: 'INTERACTION_REQUEST',
				ctxObjectId,
				objectType,
				objectTypeId,
				objectId,
				objectTypeCode,
				openMode,
				curStepNum: '1',
				prevObjectTypeId
			}
		};
		
		dispatch({
			type: GET_DINAMIC_FORM_PARAMS,
			id: ctxObjectId
		});
		
		return baseService.get('appeal_form_params', params)
			.then(data => {
				dispatch({
					type: GET_DINAMIC_FORM_PARAMS_SUCCESS,
					result: data,
					id: ctxObjectId,
				});
				
				return data && data.result;
			})
			.catch(error => dispatch({
				type: GET_DINAMIC_FORM_PARAMS_ERROR,
				error,
				id: ctxObjectId,
			}));
	};
}

export const getAppealStatusList = (appealId, appealType = '', appealStatusFromTemplate, appealResolutionFromTemplate) => (dispatch) => {
	const params = {
		data: {
			appealType,
			appealId,
		},
	};
	
	dispatch({
		type: GET_APPEAL_STATUS_LIST,
		id: appealId,
	});
	
	return baseService.get('appeal_status_list', params)
		.then(data => {
			dispatch({
				type: GET_APPEAL_STATUS_LIST_SUCCESS,
				result: data.result,
				id: appealId,
				appealStatusFromTemplate,
				appealResolutionFromTemplate,
				statusListInitializedType: appealType,
			});
			
			return true;
		}).catch(error => dispatch({
			type: GET_APPEAL_STATUS_LIST_ERROR,
			error,
			id: appealId,
		}));
};

export const setAppealStatusListTemplateData = (appealId, appealStatusFromTemplate, appealResolutionFromTemplate) => ({
	type: SET_APPEAL_STATUS_LIST_TEMPLATE_DATA,
	id: appealId,
	appealStatusFromTemplate,
	appealResolutionFromTemplate,
});

export const clearAppealStatusListTemplateData = (appealId) => ({
	type: CLEAR_APPEAL_STATUS_LIST_TEMPLATE_DATA,
	id: appealId,
});

export const setInitializedStatusList = (appealId) => ({
	type: SET_APPEAL_STATUS_LIST_INITIALIZED,
	id: appealId,
});


export const saveAppeal = (requestData, appealNotificationId) => dispatch => {
	const id = requestData.appealId;
	dispatch({
		type: SAVE_APPEAL,
		id,
	});
	
	const params = {
		data: requestData,
		jsonType: true
	};
	
	return baseService.post('appeal_save', params)
		.then(response => {
			if (response.success) {
				dispatch({
					type: SAVE_APPEAL_SUCCESS,
					id,
				});
				const messages = response.result ? response.result.map(appeal => appeal.num).join(', ') : null;
				
				if (messages) {
					dispatch({
						type: ADD_TABS,
						payload: response.result
					});
				}
				dispatch(showNotification({
					type: NotificationTypes.SUCCESS,
					options: { message: `${i18next.t('tabs.appeals')} ${appealNotificationId} ${i18next.t('savedSuccess')} ${messages ? messages : ''}` }
				}));
			}
			
			return !!response.success;
		})
		.catch(error => {
			dispatch({
				type: SAVE_APPEAL_ERROR,
				error,
				id,
			});
		});
};

export const getAppealDestinations = (appealId, appealType) => (dispatch) => {
	dispatch({
		type: APPEAL_DESTINATIONS_REQUEST,
		id: appealId,
	});
	
	const params = {
		data: {
			appealType,
			appealId,
			key: 'ALL'
		}
	};
	
	return baseService.get('appeal_dest_list', params)
		.then(response => {
			if (response.success && response.result) {
				dispatch({
					type: APPEAL_DESTINATIONS_REQUEST_SUCCESS,
					destinations: response.result,
					id: appealId,
				});
				return response.result;
			} else {
				dispatch({
					type: APPEAL_DESTINATIONS_REQUEST_FAILURE,
					id: appealId,
				});
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: APPEAL_DESTINATIONS_REQUEST_FAILURE,
				id: appealId,
			});
		});
};

export function lockAppealForm (id) {
	return (dispatch) => {
		dispatch({
			type: LOCK_APPEAL_FORM,
			id,
		});
	};
}

export function removeAppealLockSource(id, lockSource) {
	// timeout is used to trigger remove lock source always after adding the new source
	return (dispatch, getState) => {
		const state = getState();
		const appeals = state.appeal && state.appeal.appeals;
		const currentLockSource = appeals && appeals[id] && appeals[id].lockSourceList;
		if (currentLockSource && currentLockSource.includes(lockSource)) {
			dispatch({
				type: REMOVE_APPEAL_LOCK_SOURCE,
				id,
				lockSource,
			});
		}
		// setTimeout(() => {
			// dispatch({
			// 	type: REMOVE_APPEAL_LOCK_SOURCE,
			// 	id,
			// 	lockSource,
			// });
		// },0);
	};
}

export function unlockAppealForm (id, lockSource, isUnlocked, onSuccess, onFailure) {
	return (dispatch, getState) => {
		if (isUnlocked) {
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess();
			}
			dispatch({
				type: UNLOCK_APPEAL_FORM_ALREADY_UNLOCKED,
				id,
				lockSource,
			});
		}

		if (!isUnlocked) {
			const params = {
				data: {
					id,
					mode: 'edit',
					duration: 100
				}
			};

			const state = getState();
			const appeals = state.appeal && state.appeal.appeals;
			const appeal = appeals && appeals[id];
			const isLocking = appeal.unlockAppeal;

			if (isLocking) {
				return;
			}
			
			dispatch({
				type: UNLOCK_APPEAL_FORM,
				id,
			});
			baseService.get('unlock_appeal_form', params)
			.then(data => {
				if (data.success) {
					if (onSuccess && typeof onSuccess === 'function') {
						onSuccess(data);
					}
					dispatch({
						type: UNLOCK_APPEAL_FORM_SUCCESS,
						version: data.result.version,
						id,
						lockSource,
					});
				} else {
					// parse error message from:
					// ORA-20001: Запит заблоковано користувачем develop/Vvptest1 ORA-06512: at "PRECRM_N.PKG_INTERACTION", line 3372 ORA-06512: at "PRECRM_N.PKG_INTERACTION", line 3372 ORA-06512: at "PRECRM_N.PKG_UI_INTERACTION", line 5554
					const re = /ORA-\d+:/;
					let error =  data ? data.errorMessage : '';
					if (re.test(error)) {
						const messageArray = error.split(re);
						error = messageArray[0] || messageArray[1];
					}
					dispatch({
						type: LOCK_APPEAL_FORM,
						id,
						// error: data && data.errorMessage,
						error,
					});
					if (onFailure && typeof onFailure === 'function') {
						onFailure(error);
					}
				}
			})
			.catch(error => {
				if (onFailure && typeof onFailure === 'function') {
					onFailure(error);
				}
				dispatch({
					type: UNLOCK_APPEAL_FORM_ERROR,
					error,
					id,
				});
			});
		}
	};
}

export function getAppealPriorityList () {
	return (dispatch) => {
		dispatch({
			type: GET_APPEAL_PRIORITY_LIST,
		});
		
		return baseService.get('appeal_priority_list')
			.then(data => {
				dispatch({
					type: GET_APPEAL_PRIORITY_LIST_SUCCESS,
					result: data,
				});
				return true;
			}).catch(error => {
				dispatch({
					type: GET_APPEAL_PRIORITY_LIST_ERROR,
					error,
				});
				return false;
			});
	};
}

export function getAppealFeedback ({ appealId, customerId }) {
	return (dispatch) => {
		dispatch({ type: GET_APPEAL_FEEDBACK, id: appealId });
		
		const params = {
			data: {
				appealId,
				customerId
			}
		};
		
		return baseService.get('appeal_feedback', params)
			.then(res => {
				dispatch({
					type: GET_APPEAL_FEEDBACK_SUCCESS,
					result: res.result,
					id: appealId,
				});
				return res.result;
			})
			.catch(error => {
				dispatch({
					type: GET_APPEAL_FEEDBACK_ERROR,
					error,
					id: appealId,
				});
				return false;
			});
	};
}

export function clearAppealFeedback (id) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_APPEAL_FEEDBACK,
			id,
		});
	};
}

export function appealFetching (id) {
	return (dispatch) => {
		dispatch({
			type: APPEAL_FETCHING,
			id,
		});
		return true;
	};
}

export function setSaveAndCreateNew (id, payload) {
	return (dispatch) => {
		dispatch({
			type: SAVE_AND_CREATE_NEW,
			id,
			payload,
		});
	};
}

export function clearAllAppeals () {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ALL_APPEALS,
		});
	};
}

export function setRequireDynParams (id, payload) {
	return (dispatch) => {
		dispatch({
			type: SET_REQ_DYN_PARAMS,
			id,
			payload,
		});
	};
}

export function updateAppealViewLinks (state) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_VIEW_LINKS,
			state,
		});
		return true;
	};
}

export function setLinkedAppeals (appeals) {
	return (dispatch) => {
		dispatch({
			type: SET_LINKED_APPEALS,
			appeals,
		});
		return true;
	};
}


export function setLinkBinders (checkedBinders) {
	return (dispatch) => {
		dispatch({
			type: SET_LINK_СHECKED_BINDERS,
			checkedBinders,
		});
		return true;
	};
}

export function checkBinder (binder) {
	return (dispatch) => {
		dispatch({
			type: CHECK_LINK_BINDER,
			binder,
		});
		return true;
	};
}

export function unCheckBinder (binder) {
	return (dispatch) => {
		dispatch({
			type: UNCHECK_LINK_BINDER,
			binder,
		});
		return true;
	};
}

export function setLinkedObjects (objects) {
	return (dispatch) => {
		dispatch({
			type: SET_LINKED_OBJECTS,
			objects,
		});
		return true;
	};
}

export function setObjectLinkBinders (checkedBinders) {
	return (dispatch) => {
		dispatch({
			type: SET_OBJECT_LINK_СHECKED_BINDERS,
			checkedBinders,
		});
		return true;
	};
}

export function clearLinkBinders () {
	return (dispatch) => {
		dispatch({
			type: CLEAR_LINK_BINDERS,
		});
		return true;
	};
}

export function checkObjectBinder (binder) {
	return (dispatch) => {
		dispatch({
			type: CHECK_OBJECT_LINK_BINDER,
			binder,
		});
		return true;
	};
}

export function unCheckObjectBinder (binder) {
	return (dispatch) => {
		dispatch({
			type: UNCHECK_OBJECT_LINK_BINDER,
			binder,
		});
		return true;
	};
}

export function clearObjectLinkBinders () {
	return (dispatch) => {
		dispatch({
			type: CLEAR_OBJECT_LINK_BINDERS,
		});
		return true;
	};
}

const requestFiltersFailure = () => ({
	type: LINK_FILTERS_REQUEST_FAILURE,
});


export const getFilters = (type, code) => dispatch => {
	
	return baseService.get('attributes', { data: {code: code || Codes[type]} })
		.then(response => {
			dispatch({
				type: LINK_FILTERS_REQUEST_SUCCESS,
				linkFilterList: response.result
			});
		})
	.catch(error => {
		console.error(error);
		dispatch(requestFiltersFailure());
		return null;
	});
};

export const setAppealGridRefresh = (gridRefresh) => ({
	type: SET_APPEAL_GRID_REFRESH,
	gridRefresh
});

export const setAppealIdCentralPartLoading = (id, isCentralPartLoading) => ({
	type: SET_APPEAL_ID_CENTRAL_PART_LOADING,
	id,
	isCentralPartLoading
});

export const setFormForceInitValues = (id, values) => ({
	type: SET_FORM_FORCE_INIT_VALUES,
	id,
	values
});



export const Codes = {
	appeal: 'INTERACTION_REQUEST_LINKS',
	objects: 'INTERACTION_REQUEST_SPECINSTANCE',
	order: 'ORDER_LINKS',
};
