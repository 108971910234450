import React, { Fragment } from "react";
import { withRouter, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import Proxy from "components/HOC/ContentTabProxy";
import Tab from "components/Tab";
import KnowledgeBase from "./KnowledgeBase";
import Comments from "./Comments/Comments";
import Mailing from "./Mailing/Mailing";
import AppealHistory from "./History";

import { RouteWithTabs, createCheckPropsFunction /*, getTabConfig*/ } from "helpers";
import { connect } from "react-redux";
import { resetAppealHistory } from "actions/session";
import { resetComments } from "actions/comments";
import { resetKnowledgeBase } from "actions/knowledgeBase";
import permissions, { checkPermissions } from "config/permissions";
import { createSelector } from "reselect";
import baseService from "../../services/BaseService";
import Loader from "../../components/Loader";

const DEFAULT_ICON_CLASS = "qwarter";

const mapStateToProps = state => {
    return {
        mailing: state.appeal.mailingUpTo,
        comments: state.appeal.commentsUpTo,
    };
};

const mapDispatchToProps = dispatch => ({
    resetAppealHistory: () => dispatch(resetAppealHistory()),
    resetComments: () => dispatch(resetComments()),
    resetKnowledgeBase: () => dispatch(resetKnowledgeBase()),
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            allowableTabs: null, 
            allowebleLabels: null, 
            isLoading: true,
			// iconClass: 'qwarter',
			iconClass: localStorage.getItem("tabsIconClass") === null ? DEFAULT_ICON_CLASS : localStorage.getItem('tabsIconClass'), // should be true by default
			tabsOpened: localStorage.getItem("tabsOpened") === null ? true : localStorage.getItem('tabsOpened') === 'true', // should be true by default
        };
    }
    componentDidMount() {
        this.resetPreviousTabState(this.props.match.params.tab);
        this.getAllowableTabs();
    }

    componentDidUpdate(prevProps) {
        const isPropChanged = createCheckPropsFunction(prevProps, this.props);

        if (isPropChanged("comments") && this.state.allowableTabs) {
            return this.setState(prevState => ({
                ...prevState,
                allowableTabs: this.addCount(prevState.allowableTabs, "comments"),
            }));
        }
        if (isPropChanged("mailing") && this.state.allowableTabs) {
            return this.setState(prevState => ({
                ...prevState,
                allowableTabs: this.addCount(prevState.allowableTabs, "mailing"),
            }));
        }
        if (isPropChanged("match.params.appealId") && prevProps.match.params.appealId) {
            this.getAllowableTabs();
        }
        if (isPropChanged("match.params.tab") && prevProps.match.params.tab) {
            this.resetPreviousTabState(prevProps.match.params.tab);
        }
    }

    componentWillUnmount() {
        this.setState({ allowableTabs: null, allowebleLabels: null, isLoading: true });
    }

    addCount(allowableTabs, counter) {
        const newCount = this.props[counter];
        return allowableTabs.map(item => {
            if (item.label === counter && newCount > item.countTotal) {
                return { ...item, countTotal: newCount, availabilityOfNew: false };
            }
            return item;
        });
    }

    getAllowableTabs = async () => {
        this.setState({ allowableTabs: null, allowebleLabels: null, isLoading: true });
        const { appealId } = this.props.match.params;
        const { tabsOpened } = this.state;
        const conf = await baseService.get("appeal_subtabs", { pathParams: { appealId } });

        if (conf.success) {
            let sortedConf = conf.result.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1));

            if (!checkPermissions(permissions.UserOperations.action_clientPartViewKnowledgeBase)) {
                sortedConf = sortedConf.filter(({ label }) => label !== "knowledgeBase");
            }
            if (!checkPermissions(permissions.EmailOperations.action_viewEmail)) {
                sortedConf = sortedConf.filter(({ label }) => label !== "mailing");
            }

            const isSomeCountAvailable = conf && conf.result.some(item => item.countTotal);

            // open tabs and set it width to the max (default one), but not affect a local storage change
            if (isSomeCountAvailable) {
                if (!tabsOpened) {
                    this.setState({ iconClass: DEFAULT_ICON_CLASS });
                    this.openTabs();
                }
            }

            return this.setState({
                allowableTabs: sortedConf,
                allowebleLabels: sortedConf.map(({ label }) => label),
                isLoading: false,
            });
        }
        return conf.success;
    };

    resetPreviousTabState = tabName => {
        switch (tabName) {
            case "knowledgeBase":
                return this.props.resetKnowledgeBase();
            case "comments":
                return this.props.resetComments();
            case "history":
                return this.props.resetAppealHistory();
        }
    };

    openTabs = () => this.setState({ tabsOpened: true });
	
	toggleTabs = () => {
		localStorage.setItem('tabsOpened', !this.state.tabsOpened);
		this.setState({ tabsOpened: !this.state.tabsOpened });
	}

    renderTab = props => {
        switch (props.match.params.tab) {
            case "knowledgeBase":
                return (
                    <KnowledgeBase
                        unlockAppealForm={this.props.unlockAppealForm}
                        resetAppealType={this.props.resetAppealType}
                        shouldRenderField={this.props.shouldRenderField}
                    />
                );
            case "comments":
                return (
                    <Comments
                        unlockAppealForm={this.props.unlockAppealForm}
                        removeAppealLockSource={this.props.removeAppealLockSource}
						isLockedByOther={this.props.isLockedByOther}
                    />
                );
            case "mailing":
                return (
                    <Mailing
                        unlockAppealForm={this.props.unlockAppealForm}
                        removeAppealLockSource={this.props.removeAppealLockSource}
						isLockedByOther={this.props.isLockedByOther}
                    />
                );
            case "history":
                return <AppealHistory />;
            default:
                return null;
        }
    };

    renderTabButton = ({ id, label, counting, countTotal, availabilityOfNew, orderNum, tabActive }) => {
        const count = counting ? (
            <label className="tab-counter">
                (<label className={cx({ availabilityOfNew })}>{countTotal}</label>)
            </label>
        ) : null;
        return <Tab val={label} counter={count} key={label} />;
    };

    getDefaultTabName = () => {
        const { allowableTabs } = this.state;
        const defaultName = allowableTabs.find(({ tabActive }) => tabActive);
        return defaultName.label;
    };

	iconHandler = () => {
        const newIconClass = this.state.iconClass === DEFAULT_ICON_CLASS ? "half" : DEFAULT_ICON_CLASS;
        this.setState({ iconClass: newIconClass });
        localStorage.setItem("tabsIconClass", newIconClass);
    }


    render() {
        const {
            match,
            location,
            // iconClass,
            iconHandler,
            data: { tabName },
        } = this.props;
        const { allowableTabs, allowebleLabels, isLoading, tabsOpened, iconClass } = this.state;
        const iconCls = tabsOpened ? iconClass : "closed";
        return (
            <div className={cx("tabs-wrapper", iconCls, { [`${tabName}ContentWrapper`]: tabName })}>
                {tabsOpened ? (
                    <Fragment>
                        <div className="tabs">
                            <div className="leftPart">{allowableTabs && allowableTabs.map(this.renderTabButton)}</div>
                            <div className="tab-header-buttons">
                                <i className={`icon-${iconClass}`} onClick={this.iconHandler} />
                                <i className="icon-right" onClick={this.toggleTabs} />
                            </div>
                        </div>
                        <Switch>
                            {allowableTabs && (
                                <RouteWithTabs
                                    path="/appeals/:appealId/:tab?"
                                    tabs={allowebleLabels}
                                    tabPath={"tab"}
                                    defaultTabName={this.getDefaultTabName()}
                                    match={match}
                                    location={location}
                                    render={this.renderTab}
                                />
                            )}
                        </Switch>
                    </Fragment>
                ) : (
                    <i className="icon-left" onClick={this.toggleTabs} />
                )}
            </div>
        );
    }
}

TabContent.propTypes = {
    match: PropTypes.object,
    iconClass: PropTypes.string,
    iconHandler: PropTypes.func,
    toggleTabs: PropTypes.func,
    data: PropTypes.object,
    refresh: PropTypes.func,
    unlockAppealForm: PropTypes.func,
};

export default Proxy(TabContent, {
    noRequest: true,
    tabType: data => data && `appeals_${data.appealId}`,
    name: data => data && `appeals_${data.appealId}`,
    queryKey: data => data && `appeals_${data.appealId}_${data.tab}`,
    storeName: data => data && data.tab,
});
