import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewCommentNote = ({ userName, appealId }) => {
	const { t } = useTranslation();
	
	return  (
		<div className={styles.currentAppealChangeNote}>
			<div className={styles.userAvatar} />
			<div className={styles.noteText}>
				<span className={styles.userName}>{userName}</span>
				<span>{t('notification.addComment')}&nbsp;
					<Link
						className={styles.appealID}
						to={`/appeals/${appealId}/`}
					>
						{appealId}
					</Link>
				</span>
			
			</div>
		</div>
	)
};

NewCommentNote.propTypes = {
	userName: PropTypes.string,
	avatar: PropTypes.string,
	appealId: PropTypes.number,
	customerId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

export default NewCommentNote;

