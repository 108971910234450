import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from 'components/Loader/index';
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';

const ContactPersonAppealList = props => {
	const { updateApiGrid, customerId, match: { params: { id: contactPersonId, serviceId, appealId, orderId, resourceId } }} = props;

	const itemId = serviceId || appealId || orderId || resourceId;
	// console.log({props});
	const init = {
		key: itemId ? 'person_appeals' : 'customer_appeals',
		extra: itemId ? `${itemId}_${customerId}.${contactPersonId}` : `person_${customerId}.${contactPersonId}`,
		query: '?'
	};

	const gridKey = `${init.key}_${init.path || init.extra || ''}`;
	
	const [loadingState, setLoadingState] = useState(true);
	
	const onStart = () => setLoadingState(true);
	
	const onFinish = () => setLoadingState(false);
	
	useEffect(() => {
		customerId && updateApiGrid({ customerId, contactPersonId }, gridKey);
	}, [customerId, contactPersonId]);
	
	return (
		<div className='content-wrapper with-fixed-status ordering-component-ui-core-wrapper'>
			{loadingState && <Loader />}
			<GridWrapper
				tuner={() => init}
				initUpdate={false}
				gridParams={{
					selectItem: props.selectItem,
				}}
				// destroyOnUnmount
				onStart={onStart}
				onFinish={onFinish}
			/>
		</div>
	);
};

export default connect(null, { updateApiGrid: setApi })(withRouter(ContactPersonAppealList));
