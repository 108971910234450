import moment from 'moment';
import get from 'lodash/get';
import i18next from 'util/i18n';

export function warn (values, props) {
	const warnings = {};
	warnings.blocks = {};
	
	const blocks = get(props, 'formParams.blocks');
	blocks && blocks.forEach(item => {
		if (item && item.widgets) {
			item.widgets.forEach(widget => {
				if (widget && !widget.isReq) {
					if (widget && (widget.widgetType === 'date' || widget.widgetType === 'datetime') && values.blocks && values.blocks[widget.key] && !moment(values.blocks[widget.key]).isValid()) {
						warnings.blocks[widget.key] = i18next.t('validation.correctDate');
					}
					if (widget && widget.rule && values.blocks) {
						const [ hint, ...rest ] = widget.rule.split('$');
						const regexp = rest.join("");
						
						const formattedRegexp = new RegExp(`^${regexp}$`);
						if (!formattedRegexp.test(values.blocks[widget.key])) {
							warnings.blocks[widget.key] = hint;
						}
					}
				}
			});
		}
	});
	
	return warnings;
}

export default function validate (values, props) {
	
	let errors = {};
	
	const blocks = get(props, 'questionParams.blocks');
	blocks && blocks.forEach(item => {
		if (item && item.widgets) {
			item.widgets.forEach(widget => {
				if (widget && widget.isReq) {
					if (widget && widget.isReq && (!values || !values[widget.key])) {
						
						errors = { ...errors, [widget.key]: i18next.t('validation.required') };
					}
					
					if (widget && (widget.widgetType === 'date' || widget.widgetType === 'datetime') &&
						values && values[widget.key] && !moment(values[widget.key]).isValid()) {
						errors = { ...errors, [widget.key]: i18next.t('validation.correctDate') };
					}
					
					if (widget && widget.rule && values) {
						const [ hint, ...rest ] = widget.rule.split('$');
						const regexp = rest.join("");
						
						const formattedRegexp = new RegExp(`^${regexp}$`);
						if (!formattedRegexp.test(values[widget.key])) {
							errors = { ...errors, [widget.key]: hint };
						}
					}
				}
			});
		}
	});
	
	return Object.keys(errors).length === 0 ? null : errors;
}

