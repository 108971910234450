import React from 'react';
import { editorToolbar } from 'constants/index';
import CKEditor from 'ckeditor4-react';
import cx from 'classnames';

const uri = window.location.href.split(/\?|#/)[0];
CKEditor.editorUrl = `${uri}/ckeditor/ckeditor.js`;

function EditorHTML ({ input, label, required = false, placeholder, disabled = false}) {
	function onChange (e) {
		input.onChange && input.onChange(e.editor.getData());
	}
	
	return (
		<div>
			<div className={cx('draftEditorBox', 'input-element', {disabled} )}>
				{
					label &&
					<div className='input-label'>
						{label}{required && <span className='required-field'>*</span>}
					</div>
				}
				<CKEditor
					onChange={onChange}
					config={editorToolbar}
					data={input.value}
					name={input.name}
					placeholder={placeholder}
					readOnly={disabled}
				/>
			</div>
		</div>
	);
	
}

export default EditorHTML;
