import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

@withTranslation()
class SelfCareItem extends React.Component {
	
	getOptionalParts = () => {
		const { data: { comment } } = this.props;
		return { comment };
	};
	
	render () {
		const { comment } = this.getOptionalParts();
		return (
			<li className='history-item'>
				<i className='icon-envelope' />
				<div className='history-item-content'>
					<span className='history-title-text'> - </span>
					<span className='history-content-text'>{comment}</span>
				</div>
			</li>
		);
	}
}

SelfCareItem.propTypes = {
	data: PropTypes.object
};

export default SelfCareItem;
