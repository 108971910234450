import {
	fieldLevelValidation,
	requiredFieldsValidation,
	individualTaxpayerNumber,
	certificateIdValidation
} from 'util/form';
import { organizationConfig } from './OrganizationForm';
import { leftTrimProps } from 'util/form';

const rules = {
	institutionId: individualTaxpayerNumber,
	taxid: certificateIdValidation,
};

function validate (values) {
	const errors = {};
	
	leftTrimProps(values);
	requiredFieldsValidation(values, organizationConfig, errors);
	fieldLevelValidation(values, rules, errors);
	return errors;
}

export default validate;
