import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from './Sidebar';
import IssueMain from './IssueMain';
import styles from 'styles/modules/knowledgeBase.module.scss';
import BaseService from '../../services/BaseService';
import permissions, { checkPermissions } from '../../config/permissions';
import i18next from 'util/i18n';

export const Context = React.createContext();

function Issue () {
	const [isMounted, setIsMounted] = useState(false);
	const [selectValue, setSelectValue] = useState({ label: i18next.t('issues.active'), value: 'active', cityId: null });
	const [issues, setIssues] = useState([]);
	const [loading, setLoading] = useState(false);
	const canEdit = checkPermissions(permissions.AppealOperations.action_editGlobalProblems);
	
	async function requestIssues() {
		await setLoading(true);
		const data = selectValue.cityId && { id: selectValue.cityId };
		const response =  await BaseService.get('issues_list', { pathParams: { type: selectValue.value }, data } );
		await setIssues(response.result);
		await setLoading(false);
	}

	useEffect(() => {
		setIsMounted(true);
	}, []);
	
	useEffect(() => {
		selectValue && isMounted && requestIssues();
	}, [selectValue]);
	
	
	return (
		<Context.Provider value={requestIssues}>
			<div className={styles.page}>
				<Sidebar
					setSelectValue={setSelectValue}
					issues={issues}
					loading={loading}
					selectValue={selectValue}
					disabled={!canEdit}
				/>
				<IssueMain />
			</div>
		</Context.Provider>
	)
}

export default connect()(Issue);
