import {
	ADD_CARD_TO_HISTORY,
	ADD_CARDS_TO_HISTORY,
	UPDATE_PHONES_LIST,
	CHANGE_ACCOUNT_MODE,
	CHANGE_MODAL_MODE
} from 'constants/actions';
import baseService from 'services/BaseService';


export function addCardToHistory ({ url, name }) {
	return {
		type: ADD_CARD_TO_HISTORY,
		payload: {url, name}
	}
}

export function addCardsToHistory (tabs) {
	return {
		type: ADD_CARDS_TO_HISTORY,
		payload: {tabs}
	}
}

export function getUserPhones(onSuccess) {
	return (dispatch) =>{
		return baseService.get('phone_accounts')
			.then(resp => {
				if (resp.success && Array.isArray(resp.result)){
					dispatch({
							type: UPDATE_PHONES_LIST,
							action:  resp.result
							});
				}
				if (onSuccess && typeof onSuccess === 'function') {
					onSuccess(resp.result);
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_PHONES_LIST,
					action: []
				});
				return null;
			});
	}
}
export function changeOpenedAccountModal() {
	return (dispatch) => dispatch({ type: CHANGE_MODAL_MODE })
}

export function setModalAccountEditMode(enabled = false) {
	return (dispatch) => dispatch({ type: CHANGE_ACCOUNT_MODE, enabled })
}
// export function updatePhonesList () {
// 	return (dispatch) =>{
// 		const userPhones = dispatch(getUserPhones());
//
// 		return {
// 			type: UPDATE_PHONES_LIST,
// 			payload: { userPhones }
// 		}
// 	}
//
// }
