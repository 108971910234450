import React, { useState, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import ArticleBlock from "./ArticleBlock";
import ArticleFiles from "./ArticleFiles";
import ArticleHeader from "./ArticleHeader";
import styles from "styles/modules/knowledgeBase.module.scss";
import BaseService from "../../../services/BaseService";
import DynamicForm from "../../../pages/Ordering/common/DynamicForm";
import ServiceForm from "./ServiceForm";
import Loader from "../../Loader";

import { GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';


function ServiceItemView(props) {
    const { t, match, history, isCollapsed, isLeaf } = props;
    const [item, setItem] = useState({});
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isDynParamsLoading, setIsDynParamsLoading] = useState(false);
    const [dynParams, setDynParams] = useState({});

    const maxFileSize = useMemo(() => GET_MAX_FILE_SIZE_FROM_DICT('spec'), []);

    let contentClassname = "";
    if (!isLeaf) {
        contentClassname = `${styles["full-width"]} ${styles.articleItemService}`;
    } else {
        contentClassname = isCollapsed
            ? `${styles["collapsed"]} ${styles.articleItemService}`
            : styles.articleItemService;
    }

    useEffect(() => {
        getServiceItem();
    }, [match.params.id, match.params.type, match.params.mode]);

    useEffect(() => {
        getDynamicForm();
        if (dynParams[match.params.id]) {
            setDynParams(prev => ({ ...prev, [match.params.id]: null }));
        }
        return () => {
            if (dynParams) {
                setDynParams(prev => ({ ...prev, [match.params.id]: null }));
            }
        };
    }, [match.params.id]);

    async function getDynamicForm() {
        setDynParams(prev => ({ ...prev, [match.params.id]: null }));
        setIsDynParamsLoading(true);
        // actual specification data
        const data = {
            objectType: "SPECIFICATION@inst",
            objectTypeId: match.params.id,
            objectTypeCode: "",
            openMode: "update",
            ctxObjectType: "",
            ctxObjectId: "",
            curStepNum: 1,
            overrideIsReq: "",
        };
        BaseService.get("appeal_form_params", { data })
            .then(response => {
                setIsDynParamsLoading(false);
                const { success, result } = response;
                if (success && result && result.blocks) {
                    setDynParams(prev => ({ ...prev, [match.params.id]: result.blocks }));
                } else {
                    // handle error here
                    console.error("ServiceItemView::getDynamicForm: Dyn params request failed, either no success or result.blocks is empty");
                }
            })
            .catch(e => {
                setIsDynParamsLoading(false);
                console.error("ServiceItemView::getDynamicForm: Error", e);
            });
    }

    async function getServiceItem() {
        await setIsLoading(true);
        const [serviceObject, files] = await Promise.all([
            BaseService.get("get_service_catalogue_detail", {
                pathParams: {
                    id: match.params.id,
                },
                data: {
                    componentCode: "description",
                    objectType: "SERVICE",
                    mimeType: "text",
                    isFolder: match.params.type,
                },
                jsonType: true,
            }),
            BaseService.get("get_service_catalogue_files", {
                data: {
                    objectId: match.params.id,
                    objectType: "SERVICE",
                    componentCode: "description",
                    mimeType: "text",
                    start: 0,
                    limit: 25,
                },
                jsonType: true,
            }),
        ]);

        setItem(serviceObject.result || {});
        setFiles(files.result);
        await setIsLoading(false);
    }

    return (
        <div className={contentClassname}>
            {isLoading && <Loader withContainer />}
            {match.params.mode ? (
                <ServiceForm
                    isEdit
                    initialValues={{ files, ...item }}
                    match={match}
                    history={history}
                    requestData={props.requestData}
                />
            ) : (
                <>
                    <ArticleHeader
                        title={item.name}
                        buttonName={t("Edit")}
                        onClickButton={() => {
                            props.history.push(`/specification/${match.params.id}/${match.params.type}/edit`);
                        }}
                        fileType={match.params.type}
                        canEdit={props.canEdit}
                    />
                    <ArticleBlock hidden={!item.content} title={t("knowledgeBase.text")} content={item.content} />
                    <ArticleFiles hidden={!files.length} title={t("knowledgeBase.file")} files={files} />
                    <div className={`${styles.specificationDynamicForm} ordering-component-ui-core-wrapper`}>
                        {isDynParamsLoading && <Loader />}
                        {dynParams[match.params.id] && (
                            <DynamicForm
                                formName={`specification-main-dynamic-form-${match.params.id}`}
                                dynData={dynParams[match.params.id]}
                                currentId={match.params.id}
                                mainId={match.params.id}
                                maxFileSize={maxFileSize}
                                uploadFileSource="form"
                                liveSaveIdKey="id"
                                liveSaveUrlKey="save_dyn_param_specification"
                                liveSave
                                liveSaveJSON
                                isLiveSaveOnlyValid
                                t={t}
                                refreshDynParams={getDynamicForm}
                                key={match.params.id}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default withTranslation()(ServiceItemView);
