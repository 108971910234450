import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../common.scss';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import ErrorBlock from 'components/CustomerPage/Parameters/components/ErrorBlock.js';

function DateTimePickerCalendar (props) {
	const { changeOnBlur, onlyDate, label, disabled, meta, required, input, t, popperPlacement, popperClassName,
		dayFormat = 'DD.MM.YYYY', dayAndTimeFormat = 'DD.MM.YYYY HH:mm', inputClass, maxDateCurrent, ...rest  } = props;
	
	const [startDate, setStartDate] = useState(moment());

	const handleChange = (date, event) => {
		event.preventDefault();
		event.stopPropagation();
		
		setStartDate(date);
	};

	useEffect( () => {
		input.onChange(startDate);
		}, [startDate]);

		const elementCls = cx('input-element datepicker', 'calendar-date-picker', {
			'error': meta && meta.touched && (meta.error || meta.warning)
		});

		const inputClassName = inputClass || cx('input-field', {
			'input-field__error': meta && meta.touched && (meta.error || meta.warning)
		});

		const popperCls = cx('datepicker-popper datepicker-portal', popperClassName);

		const selectedItem = (moment(input.value).isValid() && input.value) ? moment(input.value) : null;

		const showError = meta && meta.touched && (meta.error || meta.warning);

		return (
			<div id={rest.id} className={elementCls}>
				{label &&
				<div className='input-label'>
					{t(`customerInfoLabel.${label}`, { defaultValue: label })}
					{required && <span className='required-field'>*</span>}
				</div>
				}
				<DatePicker
					className={inputClassName}
					dateFormat={onlyDate ? dayFormat : dayAndTimeFormat}
					popperClassName={popperCls}
					popperPlacement={popperPlacement || 'bottom-end'}
					calendarClassName='calendar'
					disabled={disabled}
					name={input.name}
					onBlur={e => false}
					onChange={handleChange}
					autoComplete='off'
					shouldCloseOnSelect={false}
					showMonthDropdown
					showYearDropdown
					scrollableYearDropdown
					yearDropdownItemNumber={108}
					// maxDate={maxDateCurrent ? moment() : null}
					selected={startDate}
					startDate={startDate}
					inline
				/>

				{showError && <ErrorBlock error={meta.error} />}
			</div>
		);
}

DateTimePickerCalendar.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	label: PropTypes.string,
	required: PropTypes.bool
};

export default withTranslation()(DateTimePickerCalendar);
