import { EventSourcePolyfill } from 'event-source-polyfill';

import {
    SET_CURRENT_TAB,
    CONTENT_SET,
    CONTENT_LOADING,
    CONTENT_ERROR,
    USER_INIT,
    UPDATE_CURRENT_TAB_URL,
    CLEAR_ERROR_MSG,
} from 'constants/actions';
import { SseEvents } from 'constants/index';

import commonService from 'services/CommonService';
import baseService from 'services/BaseService';
import settingsService, { SORT_POSTFIX } from 'services/settingsService';
import { getFilterSuppose } from './filter';
import store from '../store';

import * as URLS from '../config/urls';
import { showNotification } from './ui';
import {
    // setNotificationHistoryEventSource,
    setNotificationHistoryStreamState,
    enableNotificationHistoryStream,
    disableNotificationHistoryStream,
    incrementNotificationHistoryTotal,
} from '../reducers/notificationHistory';
import { NotificationTypes } from '../constants';
import moment from 'moment';

const getUserFromStorage = (id) => JSON.parse(localStorage.getItem(id));

const setUserToStorage = (id, data) => localStorage.setItem(id, JSON.stringify(data));

const HEARTBEAT_TIMEOUT = 300000; // 300 seconds / 5 minutes
const PING_INTERVAL = 120000; // 120 seconds / 2  minutes
const STREAM_RECONNECT_TIMEOUT = 5000; // 5 seconds;

export const initUser = (data) => ({
    type: USER_INIT,
    info: data,
});

export const getUserInfo = () => (dispatch) => {
    baseService.get('user_info').then(({ success, result }) => {
        if (success && result) {
            dispatch(
                initUser({
                    ...result.user,
                    jwt: result.jwt,
                    user_photo: result.avatar,
                    identity: result.identity,
                    userPhones: result.userPhones,
                    operations: result.operations || [],
                    settings: getUserFromStorage(result.user.id) || {},
                }),
            );
            dispatch(subscribeToMessages());
        }
    });
};

const getUniqueId = () => 'id' + new Date().getTime();

// let pingInterval;
let eventSource;
let pingAmount = 0;
let pongAmount = 0;
let streamFailedCount = 0;
let streamReconnectTimeout;

const ping = (jwt) => (dispatch) => {
    pingAmount++;
    const requestId = getUniqueId();
    const pingPongDiff = pingAmount - pingAmount;

    if (eventSource && pingPongDiff >= 2) {
        eventSource.close();
        dispatch(disableNotificationHistoryStream('error'));
        pingAmount = 0;
        pongAmount = 0;
        streamFailedCount = 0;
        dispatch(subscribeToMessages());
    } else {
        baseService
            .post('sse_notifications_ping', {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                },
                data: {
                    requestId,
                },
                jsonType: true,
            })
            .then(({ result, success }) => {
                if (success && result.requestId === requestId) {
                    pongAmount++;
                }
            });
    }
};

export const subscribeToMessages = () => (dispatch, getState) => {
    const jwt = getState().user.jwt;
    // let eventSource;
    dispatch({ type: CLEAR_ERROR_MSG });
    dispatch(setNotificationHistoryStreamState('connecting'));

    // const EVENT_SOURCE_ID = new Date().getTime();

    // remove old listeners / intervals
    if (eventSource) {
        eventSource.removeEventListener("error", eventSource.$onErrorListener);
        eventSource.removeEventListener("message", eventSource.$onMessageListener);
        clearInterval(eventSource.$pingInterval);
    }
    if (streamReconnectTimeout) {
        clearTimeout(streamReconnectTimeout);
        streamReconnectTimeout = null;
    }

    // init new EventSource
    eventSource = new EventSourcePolyfill(URLS.core.sse_notifications.url, {
        headers: {
            Authorization: 'Bearer ' + jwt,
        },
        heartbeatTimeout: HEARTBEAT_TIMEOUT,
    });

    eventSource.$onErrorListener = onError;
    eventSource.$onMessageListener = onMessage;
    // eventSource.$eventSourceId = EVENT_SOURCE_ID;


    eventSource.addEventListener('error', eventSource.$onErrorListener);
    eventSource.addEventListener('message', eventSource.$onMessageListener);

    function onError() {
        clearTimeout(streamReconnectTimeout);
        streamReconnectTimeout = null;
        
        streamFailedCount++;
        if (streamFailedCount === 5) {
            dispatch(disableNotificationHistoryStream());
            return;
        }

        dispatch(disableNotificationHistoryStream('error'));
            console.log(
                `Actions:index::eventSource.onError: Connection failed, setting reconnect timeout of ${STREAM_RECONNECT_TIMEOUT} ms. Recconect try number: ${streamFailedCount}`,
            );
            streamReconnectTimeout = setTimeout(() => {
                dispatch(subscribeToMessages());
            }, STREAM_RECONNECT_TIMEOUT);
    }

    function onMessage(message) {
        const sseMessage = JSON.parse(message.data);

        let date;
        if (sseMessage && sseMessage.DATE && parseInt(sseMessage.DATE)) {
            date = moment(parseInt(sseMessage.DATE)).format('MM/DD/YYYY HH:mm');
        }


        if (sseMessage.errorCode === 'AССESS_DENIED') {
            return dispatch(
                showNotification({
                    type: NotificationTypes.SSE_ERROR,
                    options: {
                        message: sseMessage.errorCode,
                        date
                    },
                }),
            );
        }

        // lastSseEvent = sseMessage.event;

        let subject, body;

        if (sseMessage.MESSAGE) {
            const splitted = sseMessage.MESSAGE.split('SUBJECT:\n').filter((item) => item);
            if (splitted.length > 0) {
                [subject, body] = splitted[0].split('BODY\n:');
            }
        }

        // dispatch(setLastSseNotifMessage({...sseMessage, timestamp: Date.now()}));
        switch (sseMessage.event) {
            case SseEvents.PONG: {
                // pongAmount++;
                break;
            }
            case SseEvents.HELLO: {
                streamFailedCount = 0;
                if (eventSource.$pingInterval) {
                    clearInterval(eventSource.$pingInterval);
                }
                eventSource.$pingInterval = setInterval(() => ping(jwt)(dispatch), PING_INTERVAL);
                ping(jwt)(dispatch);
                dispatch(enableNotificationHistoryStream(eventSource));
                break;
            }
            case SseEvents.INSTMESS: {
                dispatch(incrementNotificationHistoryTotal());
                return dispatch(
                    showNotification({
                        type: NotificationTypes.SSE_NOTIFICATION,
                        options: {
                            message: sseMessage.MESSAGE,
                            subject,
                            body,
                            date,
                        },
                    }),
                );
            }
            case SseEvents.NOTIFICATION: {
                dispatch(incrementNotificationHistoryTotal());
                return dispatch(
                    showNotification({
                        type: NotificationTypes.SSE_NOTIFICATION,
                        options: {
                            message: sseMessage.MESSAGE,
                            subject,
                            body,
                            date
                        },
                    }),
                );
            }

            case SseEvents.WARNING: {
                dispatch(incrementNotificationHistoryTotal());
                return dispatch(
                    showNotification({
                        type: NotificationTypes.SSE_WARNING,
                        options: {
                            message: sseMessage.MESSAGE,
                            subject,
                            body,
                            date
                        },
                    }),
                );
            }

            case SseEvents.ERROR: {
                dispatch(incrementNotificationHistoryTotal());
                return dispatch(
                    showNotification({
                        type: NotificationTypes.SSE_ERROR,
                        options: {
                            message: sseMessage.MESSAGE,
                            subject,
                            body,
                            date
                        },
                    }),
                );
            }

            default:
                dispatch(incrementNotificationHistoryTotal());
                return dispatch(
                    showNotification({
                        type: NotificationTypes.SSE_NOTIFICATION,
                        options: {
                            message: sseMessage.MESSAGE,
                            subject,
                            body,
                            date
                        },
                    }),
                );
        }
    }
};

/**  TO DO expand this function to other user settings  ** */
export const setUserSettings = () => {
    const { id } = store.getState().user;
    const grids = store.getState().grid;
    const gridFields = {};

    Object.keys(grids).forEach((v) => {
        if (v !== 'gridKey' && grids[v] && grids[v].custom) {
            gridFields[v] = { ...grids[v].params.fields };
        }
    });

    setUserToStorage(id, { gridFields });
};

export const setUserInfo = (data) => {
    const params = {
        path: 'lang',
        data: { lang: data.lang_default },
    };

    baseService.post('user_settings', params);

    return { type: USER_INIT, info: data };
};

export function setCurrentTab(data) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_TAB,
            ...data,
        });
    };
}

export const updateCurrentTabUrl = (currentUrl, displayedName) => ({
    type: UPDATE_CURRENT_TAB_URL,
    currentUrl,
    displayedName,
});

const contentLoading = (storeName) => ({
    type: CONTENT_LOADING,
    storeName,
});

const contentError = (data) => ({
    type: CONTENT_ERROR,
    ...data,
});

export const initProxy = (api, options, url) => (dispatch) => {
    if (!api.key) return null;

    const isPost = api.method && api.method.toLowerCase() === 'post';
    const storeName = options.storeName(options.params);
    const queryKey = options.queryKey && options.queryKey(options.params);

    // Load sort settings.
    const cachedSortSettings = settingsService.get(`${queryKey}${SORT_POSTFIX}`);
    const apiData = { ...api };
    if (apiData.data.limit) {
        apiData.data = {
            ...apiData.data,
            page: apiData.data.page || 1,
            start: apiData.data.start || 0,
        };
    }

    if (cachedSortSettings) {
        apiData.data = { ...apiData.data, sort: cachedSortSettings };
    }

    dispatch(contentLoading());
    commonService
        .initProxy(api.key, apiData, isPost)
        .then((data) => {
            const dispatchData = { type: CONTENT_SET, url, api: apiData };
            // debugger
            dispatchData.query = {
                ...apiData.data,
                total: data && data.total,
            };

            // this hack need to save filter state after CONTENT_UPDATE
            if (dispatchData.query.filter && dispatchData.query.filter.length) {
                delete dispatchData.query.filter;
            }

            // mainTab name (tabs reducer)
            if (options.name) {
                dispatchData.name = options.name({
                    ...(data && data.result),
                    ...options.params,
                    api,
                });
            }

            // mainTab type (unique tab) (tabs reducer)
            if (options.tabType) {
                dispatchData.tabType = options.tabType(options.params);
            }

            // key for determination of request data
            if (queryKey) {
                dispatchData.queryKey = queryKey;
            }

            dispatchData.storeName = storeName;
            if (data) {
                data.tabName = storeName;
                data.result && dispatch(getFilterSuppose(data));
            }
            dispatchData.data = data;

            // debugger;
            dispatch(dispatchData);
        })
        .catch((error) => {
            // If aborted.
            if (error.name === 'AbortError') {
                return;
            }

            // If wrong.
            dispatch(contentError({ error, storeName }));
            console.error(`initProxy: ${error.message}`);
        });
};
