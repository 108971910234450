import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LiveSearch from 'components/LiveSearch/LiveSearch';
import { calculateSearchNumber } from 'helpers';
import {
	getHeaderSearchSuppose,
	clearSearchResult,
	expandSearch,
	reduceSearch,
} from 'actions/headerSearch';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { toggleBlockAddingPopup } from 'actions/tabs';
import { updateSearchQuery } from 'actions/search';
import { MAX_TABS_COUNT, KeyCodes } from 'constants/index';
// import debounce from 'lodash/debounce';
import permissions, { checkPermissions } from '../../config/permissions';
import { useTranslation } from 'react-i18next';
import useDebounce from "custom-hooks/useDebounce";

function SearchHeader (props) {
	const {
		expandedSearch, searchResults, query, facets, tabs,
		canSearchCustomer, canSearchContactPersons, updateSearchQuery, canSearchEvents, canSearchTasks, canSearchServices, canSearchResource, expandSearch, getHeaderSearchSuppose, clearSearchResult, reduceSearch,
	} = props;
	const searchComponent = useRef(null);
	const search = useRef(null);
	const [value, setValue] = useState('');
	const { debouncedValue, setDebouncedValue } = useDebounce(value, SEARCH_TIMER_INTERVAL);
	const [showLiveSearch, setShowLiveSearch] = useState(false);
	const { t } = useTranslation();
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	
	useEffect(() => {
		if (searchResults.length && debouncedValue) {
			setShowLiveSearch(true);
		}
	}, [searchResults.length]);
	
	// const triggerChange = debounce((value) => {
	// 	const data = {
	// 		query: value,
	// 		page: 1,
	// 		start: 0,
	// 		limit: 8,
	// 	};
	// 	if (value.trim().length > 2) {
	// 		getHeaderSearchSuppose({ data });
	// 	} else if (searchResults) {
	// 		clearSearchResult();
	// 	}
	// }, SEARCH_TIMER_INTERVAL);

	useEffect(() => {
		const data = {
			query: debouncedValue,
			page: 1,
			start: 0,
			limit: 8,
		};
		if (debouncedValue.trim().length > 2) {
			getHeaderSearchSuppose({ data });
		} else if (searchResults) {
			clearSearchResult();
		}
	}, [debouncedValue]);
	
	function onSearchKeyDown (e) {
		if (e.keyCode === KeyCodes.ENTER) {
			const { tabs, toggleBlockAddingPopup, history: { push } } = props;
			if (tabs.tabs.length < MAX_TABS_COUNT) {
				const query = e.currentTarget.value;
				if (query.trim().length > 2) {
					const hash = calculateSearchNumber(tabs);
					updateSearchQuery(query, hash);
					push(`/search/appeal/${hash}`);
				}
			} else {
				toggleBlockAddingPopup();
			}
			setValue(null);
			setDebouncedValue(null);
			hideSearch();
		} else if (e.keyCode === KeyCodes.ESCAPE) {
			hideSearch();
			handleSearchClear();
		}
	}
	
	function handleClickOutside (e) {
		if (searchResults.length && searchComponent.target && !searchComponent.target.contains(e.target)) {
			hideSearch();
		}
	}
	
	function hideSearch () {
		if (search && search.current && search.current.blur) {
			search.current.blur();
		}
		reduceSearch();
		clearSearchResult();
		setValue('');
		setDebouncedValue('');
		setShowLiveSearch(false);
	}
	
	function onClickSearchBlock (e) {
		expandSearch(!!e.target.type);
		search.current.focus();
	}
	
	function handleSearchClear () {
		reduceSearch();
		setValue('');
		setDebouncedValue('');
	}
	
	function handleBlur () {
		setTimeout(() => {
			handleSearchClear();
			hideSearch();
		}, 300);
	}
	
	function handleCloseClick (e) {
		e.preventDefault();
		hideSearch();
		handleSearchClear();
	}
	
	function handleChange (evt) {
		const { value } = evt.currentTarget;
		setValue(value);
		// setShowLiveSearch(true);
		// triggerChange(value);
	}
	
	return (
		<div
			className={`header-block-item search ${expandedSearch ? 'expand' : ''}`}
			onClick={onClickSearchBlock}
			onBlur={handleBlur}
			ref={searchComponent}
		>
			<i className='icon-search' />
			<input
				type='text'
				className='search-input'
				ref={search}
				maxLength='255'
				value={value}
				placeholder={t('Search')}
				onKeyDown={onSearchKeyDown}
				onChange={handleChange}
			/>
			
			<i
				className={`icon-close ${expandedSearch ? '' : 'hidden'}`}
				onClick={handleCloseClick}
			/>
			
			{showLiveSearch && searchResults.length > 0 && (
				<LiveSearch
					searchResults={searchResults}
					query={query}
					hideSearch={hideSearch}
					facets={facets}
					tabs={tabs}
					className='header-live-search'
					canSearchContactPersons={canSearchContactPersons}
					canSearchCustomer={canSearchCustomer}
					canSearchEvents={canSearchEvents}
					canSearchServices={canSearchServices}
					canSearchResource={canSearchResource}
					canSearchTasks={canSearchTasks}
					updateSearchQuery={updateSearchQuery}
				/>
			)}
		
		</div>
	);
}

function mapStateToProps (state) {
	return {
		tabs: state.tabs,
		searchResults: state.headerSearch.searchResults,
		query: state.headerSearch.query,
		facets: state.headerSearch.facets,
		canSearchCustomer: checkPermissions(permissions.SearchOperations.search_customer),
		canSearchEvents: checkPermissions(permissions.CalendarOperations.view_calendar_event),
		canSearchContactPersons: checkPermissions(permissions.SearchOperations.search_contactPersons),
		canSearchServices: checkPermissions(permissions.SearchOperations.search_spec),
		canSearchResource: checkPermissions(permissions.SearchOperations.search_res),
        canSearchTasks: checkPermissions(permissions.SearchOperations.search_order_task),
	};
}

const mapDispatchToProps = {
	expandSearch,
	reduceSearch,
	getHeaderSearchSuppose,
	clearSearchResult,
	toggleBlockAddingPopup,
	updateSearchQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);

SearchHeader.propTypes = {
	computedMatch: PropTypes.object,
	location: PropTypes.object,
	expandedSearch: PropTypes.bool,
	expandSearch: PropTypes.func,
	reduceSearch: PropTypes.func,
	getHeaderSearchSuppose: PropTypes.func,
	searchResults: PropTypes.array,
	clearSearchResult: PropTypes.func,
	query: PropTypes.string,
	facets: PropTypes.object,
	tabs: PropTypes.array,
	history: PropTypes.object,
	toggleBlockAddingPopup: PropTypes.func,
};
