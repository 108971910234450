import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewAppealNote = ({ appealId, subject, description, date, userName }) => {
	const { t } = useTranslation();
	
	return (
		<div className={styles.newAppealNote}>
			<i className='icon-call2' />
			<header>{t('notification.getAppeal')}</header>
			<div>
				<Link className={styles.appealID} to={`/appeals/${appealId}/`}>ID {appealId}</Link>
				<span> {subject}</span>
				<p>{t('notification.appealDescription')} {description}</p>
				<span className={styles.author}>{moment(date).format('DD/MM/YY HH:mm')}, {t('notification.from')} {userName}</span>
			</div>
		</div>
	)
};

NewAppealNote.propTypes = {
	appealId: PropTypes.number,
	customerId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	subject: PropTypes.string,
	description: PropTypes.string,
	date: PropTypes.number,
	userName: PropTypes.string
};

export default NewAppealNote;
