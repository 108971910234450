import store from '../store';

const CustomerOperations = 'CustomerOperations';
const ContactPersonOperations = 'ContactPersonOperations';
const action_create_contactPerson = 'action_create_contactPerson';
const action_edit_issues_contactPerson = 'action_edit_issues_contactPerson';
const action_edit_contactPerson = 'action_edit_contactPerson';
const action_edit_contact_contactPerson = 'action_edit_contact_contactPerson';
const action_createCustomer = 'action_createCustomer';
const action_editCustomer = 'action_editCustomer';
const view_customer = 'view_customer';
const view_contactPersons = 'view_contactPersons';
const view_contacts = 'view_contacts';
const view_addresses = 'view_addresses';
const action_manageAddresses = 'action_manageAddresses';
const action_manageContactPersons = 'action_manageContactPersons';
const view_accounts = 'view_accounts';

const SearchOperations = 'SearchOperations';
const search_contactPersons = 'search_contactPersons';
const search_customer = 'search_customer';
const search_spec = 'search_spec';
const search_res = 'search_res';
const search_order_task = 'search_order_task';
const search_export = 'search_export';
const search_messageContacts = 'search_messageContacts';


const AdminGrantOperations = 'AdminGrantOperations';
const view_permissions = 'view_permissions';
const action_manage_access = 'action_manage_access';

const UserOperations = 'UserOperations';
const action_clientPartViewKnowledgeBase = 'action_clientPartViewKnowledgeBase';
const action_clientPartEditKnowledgeBase = 'action_clientPartEditKnowledgeBase';

const CallOperations = 'CallOperations';
const record_listening = 'record_listening';
const action_workPbx = 'action_workPbx';

const FileOperations = 'FileOperations';
const generate_file = 'generate_file';

const AppealOperations = 'AppealOperations';
const action_createAppeal = 'action_createAppeal';
const action_createAppealRepeat = 'action_createAppealRepeat';
const action_createAppealDuplet = 'action_createAppealDuplet';
const action_viewGlobalProblems = 'action_viewGlobalProblems';
const action_editGlobalProblems = 'action_editGlobalProblems';
const action_createAppealGroupFromClient = 'action_createAppealGroupFromClient';
const action_closeAppealGroup = 'action_closeAppealGroup';

const DashboardOperations = 'DashboardOperations';
const dashboard_workWithDashboard = 'dashboard_workWithDashboard';

const FilterOperations = 'FilterOperations';
const create_public_filter = 'create_public_filter';

const InteractionOperations = 'InteractionOperations';
const action_AddAssessments = 'action_AddAssessments';
const action_unlinkContactPersons = 'action_unlinkContactPersons';

const OrderOperations = 'OrderOperations';
const view_order = 'view_order';
const view_Item = 'view_Item';
const action_createOrder = 'action_createOrder';

const ServiceOperations = 'ServiceOperations';
const view_service = 'view_service';
const add_service = 'add_service';
const edit_service = 'edit_service';
const view_resource = 'view_resource';
const add_resource = 'add_resource';
const edit_resource = 'edit_resource';


const SpecOperations = 'SpecOperations';
const view_Spec = 'view_Spec';
const action_manageSpec = 'action_manageSpec';

const LinkOperations = 'LinkOperations';
const actionViewLinks = 'actionViewLinks';
const actionEditLinks = 'actionEditLinks';
const actionDeleteLinks = 'actionDeleteLinks';

const BillOperations = 'BillOperations';
const view_BillInfo = 'view_BillInfo';

const HistoryOperations = 'HistoryOperations';
const view_history = 'view_history';

const EmailOperations = 'EmailOperations';
const action_viewEmail = 'action_viewEmail';
const action_sendEmail = 'action_sendEmail';
const action_createEmail = 'action_createEmail';

const CalendarOperations = 'CalendarOperations';
const view_calendar = 'view_calendar';
const edit_calendar = 'edit_calendar';
const view_calendar_event = 'view_calendar_event';
const edit_calendar_event = 'edit_calendar_event';

const CliSpecOperations = 'CliSpecOperations';

const AdminOperations = 'AdminOperations';
const role_admin = 'role_admin';

const BuOperations = 'BuOperations';
const view_bu = 'view_bu';

const StaticParamOperations = 'StaticParamOperations';
const action_manageBlockParam = 'action_manageBlockParam';

const permissions = {
    [AdminGrantOperations]: {
        [view_permissions]: `${AdminGrantOperations}.${view_permissions}`,
        [action_manage_access]: `${AdminGrantOperations}.${action_manage_access}`,
    },
    [UserOperations]: {
        [action_clientPartViewKnowledgeBase]: `${UserOperations}.${action_clientPartViewKnowledgeBase}`,
        [action_clientPartEditKnowledgeBase]: `${UserOperations}.${action_clientPartEditKnowledgeBase}`,
    },
    [CallOperations]: {
        [record_listening]: `${CallOperations}.${record_listening}`,
        [action_workPbx]: `${CallOperations}.${action_workPbx}`,
    },
    [CustomerOperations]: {
        [action_createCustomer]: `${CustomerOperations}.${action_createCustomer}`,
        [action_editCustomer]: `${CustomerOperations}.${action_editCustomer}`,
        [view_customer]: `${CustomerOperations}.${view_customer}`,
        [view_contactPersons]: `${CustomerOperations}.${view_contactPersons}`,
        [view_contacts]: `${CustomerOperations}.${view_contacts}`,
        [action_manageContactPersons]: `${CustomerOperations}.${action_manageContactPersons}`,
        [view_addresses]: `${CustomerOperations}.${view_addresses}`,
        [action_manageAddresses]: `${CustomerOperations}.${action_manageAddresses}`,
        [action_unlinkContactPersons]: `${CustomerOperations}.${action_unlinkContactPersons}`,
        [view_Item]: `${CustomerOperations}.${view_Item}`,
        [view_accounts]: `${CustomerOperations}.${view_accounts}`,
        [action_viewGlobalProblems]: `${CustomerOperations}.${action_viewGlobalProblems}`
    },
    [SearchOperations]: {
        [search_contactPersons]: `${SearchOperations}.${search_contactPersons}`,
        [search_customer]: `${SearchOperations}.${search_customer}`,
        [search_spec]: `${SearchOperations}.${search_spec}`,
        [search_res]: `${SearchOperations}.${search_res}`,
        [search_order_task]: `${SearchOperations}.${search_order_task}`,
        [search_export]: `${SearchOperations}.${search_export}`,
        [search_messageContacts]: `${SearchOperations}.${search_messageContacts}`,
    },
    [FileOperations]: {
        [generate_file]: `${FileOperations}.${generate_file}`,
    },
    [AppealOperations]: {
        [action_createAppeal]: `${AppealOperations}.${action_createAppeal}`,
        [action_createAppealRepeat]: `${AppealOperations}.${action_createAppealRepeat}`,
        [action_createAppealDuplet]: `${AppealOperations}.${action_createAppealDuplet}`,
        [action_viewGlobalProblems]: `${AppealOperations}.${action_viewGlobalProblems}`,
        [action_editGlobalProblems]: `${AppealOperations}.${action_editGlobalProblems}`,
        [action_createAppealGroupFromClient]: `${AppealOperations}.${action_createAppealGroupFromClient}`,
        [action_closeAppealGroup]: `${AppealOperations}.${action_closeAppealGroup}`
    },
    [DashboardOperations]: {
        [dashboard_workWithDashboard]: `${DashboardOperations}.${dashboard_workWithDashboard}`,
    },
    [FilterOperations]: {
        [create_public_filter]: `${FilterOperations}.${create_public_filter}`,
    },
    [InteractionOperations]: {
        [action_AddAssessments]: `${InteractionOperations}.${action_AddAssessments}`,
    },
    [OrderOperations]: {
        [view_order]: `${OrderOperations}.${view_order}`,
        [action_createOrder]: `${OrderOperations}.${action_createOrder}`
    },
    [ServiceOperations]: {
        [view_service]: `${ServiceOperations}.${view_service}`,
        [add_service]: `${ServiceOperations}.${add_service}`,
        [edit_service]: `${ServiceOperations}.${edit_service}`,

        [view_resource]: `${ServiceOperations}.${view_resource}`,
        [add_resource]: `${ServiceOperations}.${add_resource}`,
        [edit_resource]: `${ServiceOperations}.${edit_resource}`,
    },
    [SpecOperations]: {
        [view_Spec]: `${SpecOperations}.${view_Spec}`,
        [action_manageSpec]: `${SpecOperations}.${action_manageSpec}`,
    },
	[LinkOperations]: {
		[actionViewLinks]: `${LinkOperations}.${actionViewLinks}`,
		[actionEditLinks]: `${LinkOperations}.${actionEditLinks}`,
		[actionDeleteLinks]: `${LinkOperations}.${actionDeleteLinks}`,
	},
    [ContactPersonOperations]: {
		[action_create_contactPerson]: `${ContactPersonOperations}.${action_create_contactPerson}`,
		[action_edit_issues_contactPerson]: `${ContactPersonOperations}.${action_edit_issues_contactPerson}`,
		[action_edit_contactPerson]: `${ContactPersonOperations}.${action_edit_contactPerson}`,
		[action_edit_contact_contactPerson]: `${ContactPersonOperations}.${action_edit_contact_contactPerson}`,
	},
    [BillOperations]: {
		[view_BillInfo]: `${BillOperations}.${view_BillInfo}`,
	},
    [HistoryOperations]: {
		[view_history]: `${HistoryOperations}.${view_history}`,
	},
    [EmailOperations]: {
		[action_viewEmail]: `${EmailOperations}.${action_viewEmail}`,
		[action_sendEmail]: `${EmailOperations}.${action_sendEmail}`,
		[action_createEmail]: `${EmailOperations}.${action_createEmail}`,
	},
	[CalendarOperations]: {
		[view_calendar]: `${CalendarOperations}.${view_calendar}`,
		[edit_calendar]: `${CalendarOperations}.${edit_calendar}`,
		[view_calendar_event]: `${CalendarOperations}.${view_calendar_event}`,
		[edit_calendar_event]: `${CalendarOperations}.${edit_calendar_event}`,
	},
		[CliSpecOperations]: {
		[view_Spec]: `${CliSpecOperations}.${view_Spec}`
    },
    [AdminOperations]: {
        [role_admin]: `${AdminOperations}.${role_admin}`
    },
    [BuOperations]: {
        [view_bu]: `${BuOperations}.${view_bu}`
    },
    [StaticParamOperations]: {
        [action_manageBlockParam]: `${StaticParamOperations}.${action_manageBlockParam}`,
    }
};

export function checkPermissions (permission) {
    return store.getState().user.operations.includes(permission);
}

export default permissions;
