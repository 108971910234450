import { emailValidation, phoneValidation, fieldLevelValidation, requiredFieldsValidation, fieldValidation } from 'util/form';
import { leftTrimProps } from 'util/form';

const rules = {
	email: emailValidation,
	workPhone: phoneValidation,
};

function validate (values, { config = [], dynConfig }) {
	const errors = {};
	const widgets = [];
	dynConfig.forEach(param => widgets.push(...param.widgets));
	const required = widgets.map(widget => (!widget.disabled && { name: widget.key, required: widget.isReq }));
	const dynRules = widgets.reduce((prev, curr) => {
		return {
			...prev,
			[`${curr.key}`]: !curr.disabled && fieldValidation(curr.rule, curr.widgetType)
		};
	}, {});
	const allConfig = [...config.filter(staticWidget => !staticWidget.hidden), ...required];
	const allRules = {...rules, ...dynRules};
	
	leftTrimProps(values);
	requiredFieldsValidation(values, allConfig, errors);
	fieldLevelValidation(values, allRules, errors);
	
	return errors;
}

export default validate;
