import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { RouteWithTabs, buildThreePath } from 'helpers';
import { getFilters } from '../../../modules/appeal/actions';

import CalendarContainer from 'components/Common/DatePicker/CalendarContainer';
import {Field, Form, getFormValues, reduxForm } from 'redux-form';
import DatePicker from 'components/Common/DatePicker/index';

// import { ComboBox, Input, DatePicker } from 'ui-core-dashboard';
// import { ComboBox, Input } from 'ui-core-dashboard';
import { ComboBox } from 'ui-core-dashboard';
import Input from 'components/Common/InputField';

import MultiSelectWithSearch from 'components/Common/MultiSelectWithSearch/ReduxFormDecorator';
import { renderDefaultFields, renderDateField } from 'components/FiltersForm/helpers';

import { bindActionCreators } from 'redux';
import { SEARCH_TIMER_INTERVAL } from 'constants/actions';
import { withTranslation } from 'react-i18next';
import i18next from 'util/i18n';

import moment from "moment/moment";

function validate (values) {
	// let errors = [];
	let errors = {};
	const error = (field) => i18next.t('validation.fromTo', { field });
	
	if (values['INTERACTION_START_DATE@TO'] && !moment(values['INTERACTION_START_DATE@TO']).isValid()) {
		errors['INTERACTION_START_DATE@TO'] = i18next.t('validation.correctDate');
	}
	if (values['INTERACTION_START_DATE@FROM'] && !moment(values['INTERACTION_START_DATE@FROM']).isValid()) {
		errors['INTERACTION_START_DATE@FROM'] = i18next.t('validation.correctDate');
	}
	if (values['INTERACTION_START_DATE@FROM'] && values['INTERACTION_START_DATE@TO']) {
		if (moment(values['INTERACTION_START_DATE@TO']).isBefore(values['INTERACTION_START_DATE@FROM'])) {
			errors['INTERACTION_START_DATE@FROM'] = error(i18next.t('filter.validFrom'));
		}
	}
	
	return errors;
}

const mapDispatchToProps = dispatch => bindActionCreators({
	getFilters,
}, dispatch);


const mapStateToProps = (state, props) => ({
	form: props.form,
	filterList: state.appeal.linkFilterList || [],
	filterPresetList: state.appeal.filterPresetList || [],
	formFilterValues: getFormValues(props.form)(state),
});


function SearchFilterBlock (props){
	const { t, formFilterValues, form, filterList, isFilterListLoading, filterTopic, filterPresetList, setAppealsQuery  } = props;
	
	useEffect(() =>{
		props.getFilters(filterTopic);
	},[]);
	
	const defaultPropsDate = {
		saveFormat: 'YYYY-MM-DDTHH:mm', locale: 'uk', popperContainer: CalendarContainer,
		showMonthDropdown: true, showYearDropdown: true, viewFormat: 'DD.MM.YYYY HH:mm'
	};
	function getComponentByType (type, search) {
		let components = {
			LIST: ComboBox,
			NUMBER: Input,
			STRING: Input,
			DATE: DatePicker
		};
		
		if (search) {
			components = {
				THREE: MultiSelectWithSearch,
				LIST: MultiSelectWithSearch,
			};
		}
		
		return components[type] || Input;
	}
	
	function convertFieldConfigToProps (fieldConfig) {
		
		const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
		const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
		let options = fieldConfig.type === 'THREE' ? { children: buildThreePath(fieldConfig.dict, false, { children: 'children' }) } : getOptions(fieldConfig.dict || []);
		
		let config = {
			key: fieldConfig.id,
			component: getComponentByType(fieldConfig.type, fieldConfig.search),
			name: fieldConfig.code,
			label: t(`${fieldConfig.name}`),
			placeholder: t('dontSelect'),
			type: fieldConfig.type,
			options,
			multi,
			tree: fieldConfig.type === 'THREE',
			errorPlaceholder: true,
			// valueField: 'key',
			valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
            keySelectField: 'key',
			leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			onBlur: e => e.preventDefault() // prevent trigger form change on blur (with exact same value)
		};
		
		return config;
	}
	
	function onFilterChange (e) {
		console.log(e.target);
	}
	
	
	function renderField (fieldConfig) {
		let fieldProps = convertFieldConfigToProps(fieldConfig);
		
		if (fieldConfig.type === 'DATE') {
			fieldProps = { ...fieldProps, ...defaultPropsDate };
			return renderDateField(t, fieldConfig, fieldProps, formFilterValues);
		}

		return (
			<Field {...fieldProps} />
		);
	}
	
	return (
		<div className={cx('filter-form-wrapper appeal-linked-appeals-form', 'ordering-component-ui-core-wrapper')}>
			<Form className={'second-form-block'} >
				<div className='search-wrapper'>
					<Field
						key='appealQuery'
						name='appealQuery'
						label={t('Search')}
						component={Input}
						placeholder={t('Search')}
						onBlur={e => props && props.input && props.input.onBlur && props.input.onBlur(undefined)}
						onChange={setAppealsQuery}
					/>
				</div>
				{filterList.length ? filterList.map(renderField) : null}
			</Form>
		</div>
	)
	
}
SearchFilterBlock.propTypes = {
	topicCode: PropTypes.string,
	typeCode: PropTypes.string
};

export default reduxForm({ form: 'modal-link-filter-search', validate, destroyOnUnmount: true, enableReinitialize: true })(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchFilterBlock))
);

