import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/loader.module.scss';
import cx from 'classnames';

export default function Loader ({ withContainer, loaderClass, loaderContainerClass }) {
	const LoaderContent = (
		<div className={cx(styles.overlay, loaderContainerClass)}>
			<svg className={cx(styles.loader, loaderClass)} viewBox='0 0 50 50'>
				<circle
					className={styles.circle}
					cx='25'
					cy='25'
					r='20'
					fill='none'
					strokeWidth='3.6'
				/>
			</svg>
		</div>
	);
	return withContainer ? (
		<div className={styles.loaderContainer}>{LoaderContent}</div>
	) : (
		LoaderContent
	);
}

Loader.defaultProps = {
	withContainer: false,
	loaderClass: '',
};

Loader.propTypes = {
	withContainer: PropTypes.bool,
	loaderClass: PropTypes.string,
};
