import React from 'react';
import PropTypes from 'prop-types';
import { KeyCodes } from 'constants/index';
import ModalPortal from 'components/ModalPortal';
import Loader from '../Loader';
import modalStyles from './modal.module.scss';
import styles from './appealTypeSelect.module.scss';
import { withTranslation } from 'react-i18next';
import SearchBlock from './SearchBlock';
import TypesTree from './TypesTree';
import cx from 'classnames';

@withTranslation()
class TreeModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            submittedQuery: '',
            selectedNode: null,
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onQueryChange = this.onQueryChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress(event) {
        if (event.keyCode === KeyCodes.ESCAPE) {
            this.props.onClose();
        }
    }

    onQueryChange(event) {
        this.setState({ query: event.target.value });
    }

    onSearchSubmit() {
        if (this.state.query !== this.state.submittedQuery) {
            this.setState((state) => ({ submittedQuery: state.query }));
        }
    }

    onSelect(newSelectedNode) {
        const { valueField } = this.props;

        if (
            this.state.selectedNode &&
            newSelectedNode &&
            this.state.selectedNode[valueField] === newSelectedNode[valueField]
        )
            return;

        this.setState({ selectedNode: newSelectedNode });
    }

    onSubmitClick() {
        this.props.onSubmit(this.state.selectedNode);
    }

    render() {
        const { onClose, t, title = t('appeal.type'), loading } = this.props;
        const { query, submittedQuery, selectedNode } = this.state;

        const submitButtonClassName = cx('btn btn-primary', {
            ['btn-disabled']: !selectedNode,
        });

        return (
            <ModalPortal onClose={onClose} className="modal-center">
                <div className={styles.appealTypeModal}>
                    <div className={modalStyles.modalHeader}>
                        <div className={modalStyles.modalTitle}>{title}</div>
                    </div>

                    <SearchBlock
                        value={query}
                        onChange={this.onQueryChange}
                        onSubmit={this.onSearchSubmit}
                        placeholder={t('Search')}
                    />

                    {loading ? (
                        <Loader />
                    ) : (
                        <TypesTree
                            {...this.props}
                            searchQuery={submittedQuery}
                            selectedLeaf={selectedNode}
                            onSelectLeaf={this.onSelect}
                        />
                    )}

                    <footer className={styles.footer}>
                        <button
                            type="button"
                            className={submitButtonClassName}
                            disabled={!selectedNode}
                            onClick={this.onSubmitClick}
                        >
                            {t('appeal.select')}
                        </button>
                    </footer>
                </div>
            </ModalPortal>
        );
    }
}

TreeModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default TreeModal;
