import {
	CLIENT_LIVESEARCH_GET_SUPPOSE,
	CLEAR_CLIENT_LIVESEARCH,
	CLIENT_GET_SUPPOSE,
	CLIENT_GET_SUPPOSE_SUCCESS,
	CLIENT_GET_SUPPOSE_FAILURE,
	CLEAR_CLIENT_GET_SUPPOSE,
	OPEN_ADVANCED_SEARCH_MODAL,
	CLOSE_ADVANCED_SEARCH_MODAL
} from 'constants/actions';

const initialState = {
	loading: false,
	clients: [],
	total: 0,
	query: '',
	callback: null,
	showAdvancedSearchModal: false
};

export default (state = initialState, action) => {
	
	switch (action.type) {
	
	case CLIENT_LIVESEARCH_GET_SUPPOSE:
		return { ...state, query: action.query };
	
	case CLEAR_CLIENT_LIVESEARCH:
		return { ...state, query: '' };
	
	case CLIENT_GET_SUPPOSE:
		return { ...state, loading: true, clients: [], total: 0 };
	
	case CLIENT_GET_SUPPOSE_SUCCESS:
		return { ...state, loading: false, clients: action.clients, total: action.total };
	
	case CLIENT_GET_SUPPOSE_FAILURE:
		return { ...state, loading: false, clients: [], total: 0 };
	
	case CLEAR_CLIENT_GET_SUPPOSE:
		return { ...state, clients: [], total: 0 };
	
	case OPEN_ADVANCED_SEARCH_MODAL:
		return { ...state, showAdvancedSearchModal: true, callback: action.callback };
	
	case CLOSE_ADVANCED_SEARCH_MODAL:
		return { ...state, showAdvancedSearchModal: false, callback: null };
	
	default:
		return state;
	}
};
