import React from 'react';
import { Field, reset, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
	getFilterAttrsSuppose,
	saveFilter,
	getInfoFilterSuppose,
	addFilterAttrs,
	clearFilterAttrs,
	closeFilterParams,
	getDataFilterSuppose,
	editFilterNameSuccess,
} from 'actions/filter';
import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import DatePicker from 'components/Common/DatePicker/index';
import styles from 'styles/modules/filters.module.scss';
import get from 'lodash/get';
import SelectTree from 'components/Common/SelectTree/SelectTree';
import { reduxFormWrapper, buildThreePath } from 'helpers';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { configureFilterObject } from '../SearchFilters/helpers';
import permissions, { checkPermissions } from '../../config/permissions';
import validate from './validate';
import MultiSelectWithSearch from '../Common/MultiSelectWithSearch/ReduxFormDecorator';
import BaseService from 'services/BaseService';

import { renderDateField } from "components/FiltersForm/helpers";


const mapStateToProps = (state) => ({
	filter: state.filter,
	showMessageModal: state.ui.showMessageModal,
	formValues: getFormValues('appeal-filters')(state),
	saved: state.filter.saved,
	appealTypes: get(state, 'filter.appealTypes[0]'),
	optionsHistory: state.filter.optionsHistory,
	isCanCreatePublicFilter: checkPermissions(permissions.FilterOperations.create_public_filter),
	editFilterMode: state.filter.editFilterMode,
	filterList: state.filter.filterList
});

const mapDispatchToProps = (dispatch) => ({
	closeFilterParams: () => dispatch(closeFilterParams()),
	getFilterAttrsSuppose: (params) => dispatch(getFilterAttrsSuppose(params)),
	saveFilter: (params, onSuccess) => dispatch(saveFilter(params, onSuccess)),
	getInfoFilterSuppose: (params) => dispatch(getInfoFilterSuppose(params)),
	addFilterAttrs: (result) => dispatch(addFilterAttrs(result)),
	clearFilterAttrs: () => dispatch(clearFilterAttrs()),
	editFilterNameSuccess: (params) => dispatch(editFilterNameSuccess(params)),
	getDataFilterSuppose: (params) => dispatch(getDataFilterSuppose(params))
});

@withTranslation()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxFormWrapper({ enableReinitialize: true, validate })
class FiltersForm extends React.Component {
	constructor (props) {
		super(props);
		this.state = { iEditNameMode: false };
		this.asyncParams = this.asyncParams.bind(this);
	}
	
	componentDidMount () {
		const {getFilterAttrsSuppose, filterId, getInfoFilterSuppose, clearFilterAttrs, form} = this.props;
		getFilterAttrsSuppose();
		if (form === 'appeal-filters') {
			if (filterId) {
				const data = {
					id: filterId,
					code: 'INTERACTION_REQUEST'
				};
				getInfoFilterSuppose({
					data: data
				});
				
				clearFilterAttrs();
			}
			let state = {};
			this.props.filter.filterAttrsResults.filter((f) => f.dict_search).forEach((i) => {
				state = {...state, [i.code]: []};
			});
		}
	}
	
	asyncParams (search, code, id) {
		if (!search) {
			this.setState({ [code]: [] });
		} else {
			BaseService.post('async_search_attributes', { data: { id, search }, jsonType: true }).then((resp) => {
				this.setState({
					[code]: resp.result.map(field => ({
						label: field.value || '',
						value: field.id || ''
					}))
				});
			});
		}
	}
	
	componentDidUpdate (prevProps) {
		const { filter, form } = this.props;
		
		if (prevProps.filterId !== this.props.filterId) {
			if (!filter.newFilter) {
				const { getInfoFilterSuppose, clearFilterAttrs, dispatch } = this.props;
				
				const data = {
					id: this.props.filterId,
					code: 'INTERACTION_REQUEST'
				};
				getInfoFilterSuppose({
					data: data
				});
				
				clearFilterAttrs();
				dispatch(reset('params-modal'));
				dispatch(reset(form));
			}
		}
	}
	
	renderDefaultFields = () => {
		const { predefined, filter, filterId, isCanCreatePublicFilter, t, editFilterMode } = this.props;
		const disabled = editFilterMode ? false : !filter.newFilter;
		return [
			<Field
				component={InputField}
				required
				name='filterName'
				label={t('filters.filterName')}
				disabled={disabled}
				maxLength={200}
			/>,
			isCanCreatePublicFilter &&
			<Field
				component={CheckBoxToggle}
				name='isPublic'
				label={t('filters.isPublic')}
				disabled={disabled}
			/>
		
		];
	};
	
	getComponentByType = (type, search) => {
		let components = {
			LIST: ComboBox,
			NUMBER: InputField,
			STRING: InputField,
			DATE: DatePicker
		};
		
		if (search) {
			components = {
				THREE: MultiSelectWithSearch,
				LIST: MultiSelectWithSearch,
			};
		}
		
		return components[type] || InputField;
	};
	
	convertFieldConfigToProps = fieldConfig => {
		const { predefined, filter, filterId, editFilterMode } = this.props;
		let disabled = filterId ? true : (predefined && !filter.newFilter);
		disabled = editFilterMode ? false : disabled;
		const getOptions = dict => dict.map(field => ({ label: field.value.trim(), value: field.key }));
		const multi = (['LIST', 'THREE'].includes(fieldConfig.type) && fieldConfig.multiset_allowed === 'Y') || undefined;
		let options = fieldConfig.type === 'THREE' ? { children: buildThreePath(fieldConfig.dict, false, { children: 'children' }) } : getOptions(fieldConfig.dict || []);
		
		let config = {
			key: fieldConfig.id,
			component: this.getComponentByType(fieldConfig.type, fieldConfig.search),
			name: fieldConfig.code,
			label: fieldConfig.name,
			type: fieldConfig.type,
			options,
			multi,
			disabled,
			tree: fieldConfig.type === 'THREE',
			errorPlaceholder: true,
			// valueField: 'key',
			// leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
			valueField: fieldConfig.type === 'THREE' ? 'value' : 'key',
            keySelectField: 'key',
            leafField: fieldConfig.type === 'THREE' ? 'leaf' : 'children',
		};
		
		if (fieldConfig.dict_search) {
			const {disallow_select_all} = fieldConfig;
			
			config = {
				...config,
				disallow_select_all,
				options: this.state[fieldConfig.code] || [],
				async: {
					callback: this.asyncParams,
					code: fieldConfig.code,
					id: fieldConfig.id,
				}
			};
		}
		
		return config;
	};
	
	renderAppealTypeInput = ({ name }) => {
		const { t, appealTypes, filterId } = this.props;
		return (
			<Field
				name={name}
				key={name}
				label={t('filter.typeFullName')}
				placeholder={t('appeal.selectPlaceholder')}
				component={SelectTree}
				t={t}
				initialOptions={get(appealTypes, 'children', [])}
				disabled={!!filterId}
			/>
		);
	};
	
	// renderDateField = (fieldConfig, fieldProps) => {
	// 	const { t } = this.props;
		
	// 	if (fieldConfig.def_condition === 'BTW') {
	// 		const fromDateProps = {
	// 			...fieldProps,
	// 			name: `${fieldConfig.code}@FROM`,
	// 			label: `${fieldProps.label} ${t('filters.from')}:`,
	// 			key: `${fieldConfig.id}@FROM`
	// 		};
	// 		const toDateProps = {
	// 			...fieldProps,
	// 			name: `${fieldConfig.code}@TO`,
	// 			label: `${fieldProps.label} ${t('filters.to')}:`,
	// 			key: `${fieldConfig.id}@TO`
	// 		};
			
	// 		return (
	// 			<div key={fieldConfig.name} className={styles.wrapElements}>
	// 				<Field {...fromDateProps} />
	// 				<Field {...toDateProps} />
	// 			</div>
	// 		);
	// 	}
		
	// 	return <Field {...fieldProps} />;
	// };
	
	renderField = fieldConfig => {
		const { formValues, t } = this.props;
		const fieldProps = this.convertFieldConfigToProps(fieldConfig);
		
		if (fieldConfig.type === 'DATE') {
			// return this.renderDateField(fieldConfig, fieldProps);
			return renderDateField(t, fieldConfig, fieldProps, formValues);
		}
		
		return (
			<Field {...fieldProps} />
		);
	};
	
	closeFilterParams = (e) => {
		const { closeFilterParams } = this.props;
		e.preventDefault();
		e.stopPropagation();
		closeFilterParams();
	};
	
	handleSubmit = (value) => {
		const { formValues, initialValues, filters, filter: { filterAttrsResults = [] }, saveFilter, editFilterMode,  filterId, setNeedUpdate } = this.props;
		const filterObject = configureFilterObject(value, filterAttrsResults);
		const data = {
			query: '',
			filterSetId: editFilterMode ? filterId : '',
			filterObject,
			name: value.filterName,
			isPublic: Boolean(value.isPublic).toString(),
			code: 'INTERACTION_REQUEST',
		};
		
		const filtersNames = filters.filter(el => el.name === value.filterName);
		
		if (filtersNames.length && !editFilterMode) {
			if (filtersNames[0].predefined) {
				this.props.openModal('savePredefined');
			} else if (!editFilterMode){
				!isEqual(formValues, initialValues) && this.props.openModal('saveUser', data);
			}
		} else if (filterObject.length > 0) {
			const onSuccess = savedFilter => {
				this.props.history.push(`/appeal/filter/${savedFilter}`)
				setNeedUpdate(true);
			};
			saveFilter({ data }, onSuccess);
		}
		return false;
	};
	
	renderBtn () {
		const { filter, editFilterMode, t } = this.props;
		
		if (filter.newFilter || editFilterMode) {
			return (
				<div className={styles.buttonsWrapper}>
					<button className='btn btn-primary'>
						{t('filters.save')}
					</button>
				</div>
			);
		}
	}
	
	render () {
		const { handleSubmit, filter } = this.props;
		
		const fields = filter.filterAttrsResults || [];
		
		return (
			<div className={styles.wrapper}>
				<span className={styles.btnClose} onClick={this.closeFilterParams}>
				  <i className='icon icon-close' />
				</span>
				<div className='scrollbox'>
					<div className='scrollbox-content'>
						<form
							className={styles.appealFormWrapper}
							onSubmit={handleSubmit(this.handleSubmit)}
						>
							<div className={styles.formFields}>
								{this.renderDefaultFields()}
								{fields.map(this.renderField)}
							</div>
							{this.renderBtn()}
						</form>
					</div>
				</div>
			</div>
		);
	}
}

FiltersForm.propTypes = {
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	submit: PropTypes.func,
	filter: PropTypes.object,
	form: PropTypes.string,
	closeFilterParams: PropTypes.func,
	getFilterAttrsSuppose: PropTypes.func,
	isCanCreatePublicFilter: PropTypes.bool
};

export default FiltersForm;
