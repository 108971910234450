import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderButton ({ onClick, children, ...props }) {
	return (
		<div className='headerButton' onClick={onClick} {...props}>{children}</div>
	);
}

HeaderButton.propTypes = {
	children: PropTypes.any,
	onClick: PropTypes.func
};
