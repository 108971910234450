import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'components/ModalPortal';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import Loader from 'components/Loader';
import ReactSVG from "react-svg";
import EditSignForm from "./EditSignForm";
import DefaultsForm from "./DefaultsForm";
import baseService from './../../../services/BaseService';
import {KeyCodes} from "../../../constants";
import {connect} from "react-redux";
import { getFormValues } from 'redux-form';

const mapStateToProps = (state, props) => {
	return {
		signFormValues: getFormValues('modal-edit-sign-form')(state),
		defaultsformValues: getFormValues('modal-defaults-sign-form')(state),
	}
};

const mapDispatchToProps = {

};


const EditSignModal = props =>{
	const { t, onClose, signFormValues, defaultsformValues } = props;
	const [signList, setSignList] = useState([]);
	const [isCreating, setIsCreating] = useState(false);
	const [creactingLabel, setCreatingLabel] = useState('');
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [current, setCurrent] = useState({id: null, value: null, label: null});
	
	useEffect(() => {
		setLoading(true);
		getSignatures();
		return () => {
			setSignList([]);
			setCreatingLabel('');
			setCurrent({id: null, value: null, label: null});
		}
	},[]);
	
	useEffect(() => {
		if (signFormValues && current.id) {
			const value = _.get(signFormValues, 'label','').trim() || current.label;
			const preparedSign = signFormValues && {
				...current,
				value: _.get(signFormValues, 'signature',''),
				label:  value,
				action: current.action
			};
			const signatures = JSON.parse(localStorage['signatureEdit']);
			const signaturesEdited = signatures.map(item => (item.id === current.id) ? preparedSign : item);
			setSignList(signaturesEdited);
			localStorage.setItem(
				'signatureEdit',
				JSON.stringify(signaturesEdited)
			);
		}
	},[signFormValues]);
	
	
	async function getSignatures (){
		const resp = await baseService.get('signatures');
		if (resp.result){
			setSignList(resp.result);
			localStorage.setItem('signatureEdit',JSON.stringify(resp.result));
		}
		setLoading(false);
	}
	
	function editSign(){
		const {label, id, value} = this;
		setCurrent({label, id, value});
	}
	
	function doneEditLabel(e) {
		e && e.preventDefault();
		addSign();
		setIsCreating(false);
		setCreatingLabel('');
	}
	
	function onKeyPerssHandler(e) {
		if (e.keyCode === KeyCodes.ESCAPE) {
			e.preventDefault();
			e.stopPropagation();
			setCreatingLabel('');
			return setIsCreating(false);
		}
		if (e.keyCode === KeyCodes.ENTER) {
			e.preventDefault();
			e.stopPropagation();
			doneEditLabel();
		}
	}
	
	async function addSign (){
		if (creactingLabel.trim()) {
			setIsCreating(true);
			const data = {label: creactingLabel};
			const resp = await baseService.post('sign_create', {data, jsonType: true});
			if (resp.result) {
				setLoading(true);
				getSignatures();
			}
		}
	}
	
	async function onRemove (e){
		e.stopPropagation();
		const {id} = this;
		setLoading(true);
		const resp = await baseService.delete('sign_edit', { pathParams: { id } });
		if (resp.success){
			const newSignList = signList.filter( sign => sign.id !== id);
			setSignList(newSignList);
			localStorage.setItem('signatureEdit',JSON.stringify(newSignList));
			setLoading(false);
			setCurrent({id: null, value: null, label: null});
		}
	}
	
	function renderList (item, index){
		const active = current.id === item.id;
		return(
				<li className={cx('sign-list-item', {active})} onClick={editSign.bind(item)} key={index.toString()}>
					<span className='sign-label' title={item.label}>{item.label ? item.label : 'пусто'}</span>
					<ReactSVG
						src='data/svg/icon-trash.svg'
						className={cx('remove-hovered-icon',{active})}
						onClick={onRemove.bind(item)}
					/>
				</li>
		)
	}
	
	async function onSubmit (e){
		e.preventDefault();
		setIsSubmitting(true);
			const data = signList.map(item => {
				if ( defaultsformValues['forNewMail'] && defaultsformValues['forNewMail']=== item.id ) return {
					...item,
					action: ["EMAILNEW"]
				};
				return {...item, action: null};
			})
				.map(item => {
					if ( defaultsformValues['forNewMail'] && defaultsformValues['forForwardEtc'] === item.id ) return {
						...item,
						action: item.action ? [...item.action, "EMAILREPLYALL", "EMAILREPLY", "EMAILFORWARD"] : ["EMAILREPLYALL", "EMAILREPLY", "EMAILFORWARD"]
					};
					return item;
				});
		const resp = await baseService.post('signatures', {data: {signature: {template: [...data]}}, jsonType: true});
		if (resp.success) {
			setIsSubmitting(true);
			onClose();
		}
	}
	
	return (
		<CSSTransition
			in={true}
			classNames='fade'
			appear={true}
			enter={true}
			exit={true}
			timeout={500}
			mountOnEnter={true}
			unmountOnExit={true}
		>
			<ModalPortal
				onClose={onClose}
				className='modal-center sign-modal'
			>
				<div className='modal-title'>
					<ReactSVG className='icon-head' svgClassName='' src='data/svg/icon-pen.svg'/>
					<div className='title-content'>
						{t('signature.signatures')}
					</div>
				</div>
					{isSubmitting ? <Loader /> :
					<div className='modal-content row'>
							<div className={cx('col', 'list-wrapper')}>
							{loading ? <Loader withContainer/> :
								<ul className='sign-list'>
									{isCreating && <li className='creating-sign-item'><input
										autoFocus={true}
										type='text'
										placeholder={t('signature.newTitle')}
										className='creating-input'
										maxlength={255}
										value={creactingLabel}
										onChange={(e) => setCreatingLabel(e.target.value)}
										onKeyDown={onKeyPerssHandler}
									/>
									<ReactSVG className='icon-head' svgClassName='' src='data/svg/icon-done.svg' onClick={doneEditLabel}/>
									</li>}
									{signList.length ? signList.map((item, idx) => renderList(item, idx)) : null}
								</ul>
							}
							<div className='button-wrap'>
								<button className={cx('btn-add', {disabled: loading})} onClick={() => setIsCreating(true)}>+ {t('signature.add')}</button>
							</div>
						</div>
						<div className={cx('col', 'form')}>
							<EditSignForm current={current} />
							<DefaultsForm signList={signList} onSubmit={onSubmit}/>
						</div>
					</div>
				}
			</ModalPortal>
		</CSSTransition>
	);
}

EditSignModal.propTypes = {
	onClose: PropTypes.func,
	t: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSignModal)
