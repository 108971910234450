import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    getFormValues,
    getFormSyncErrors,
} from 'redux-form';

import { setActiveGridKey } from 'reducers/grid';

import { get } from "lodash";

import DynamicForm from "../../common/DynamicForm";

import Loader from "../../../../components/Loader";

import JobList from './JobList';

import baseService from '../../../../services/BaseService';


import "./styles.scss";

const CODE = "FILTER_EXPORT";

const CONFIG_EXPORT_FILTER= "wizard-export-filter";


const mapStateToProps = (state) => ({
    formValues: getFormValues(CONFIG_EXPORT_FILTER)(state),
    formErrors: getFormSyncErrors(CONFIG_EXPORT_FILTER)(state),
});

const mapDispatchToProps = {
    setActiveGridKey
};
const Jobs = (props) => {
    const { t, formValues, formErrors, wizardGridKey, setActiveGridKey, gridRefresh, setGridRefresh, handleFilesDownload } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [staticInitValues, setStaticInitValues] = useState({});
    const staticInitValuesLength = Object.keys(staticInitValues).length;

    const filter = get(formValues, 'exportFilter');

    const [filtersOptions, setFiltersOptions] = useState([]);

    const configExportForm = (t) => [
        {
            view: '1column',
            collapsed: false,
            title: "export_filters",
            key: 'export-filters',
            widgets: [
                {
                    key: 'exportFilter',
                    values: filtersOptions,
                    widgetType: 'select',
                },
            ],
        },
    ];

    function getFilters() {
        const params = { pathParams: { code: CODE } };
        setIsLoading(true);
        baseService.get("exportJobsFilter", params).then(({result, success}) => {
            setIsLoading(false);
            if (success) {
                const options = result.map(item => ({key: item.code, value: item.label}));
                const defaultValue = result.find(item => item.default);
                setFiltersOptions(options);
                if (defaultValue) {
                    setStaticInitValues(prev => ({...prev, exportFilter: defaultValue.code}));
                }
            }
        }).catch(e => {
            setIsLoading(false);
            console.log("ExportWizardModal/Jobs::getFilters: Error ", e);
        });
    }

    useEffect(() => {
        getFilters();
        return () => {
            // set back active grid with wizardgridkey
            if (wizardGridKey) {
                setActiveGridKey(wizardGridKey);
            }
        };
    }, []);
    return (
        <div className="export-modal-jobs ordering-tab-wrapper">
            {isLoading && <Loader />}
            <div className='export-modal-jobs__filters'>
                <DynamicForm
                    t={t}
                    formName={CONFIG_EXPORT_FILTER}
                    forceInitialValues={staticInitValues}
                    dynData={configExportForm(t)}
                    withoutLiveSaver
                    withoutCollapse
                    key={staticInitValuesLength}
                />
            </div>
            {filter && <JobList t={t} filter={filter} gridRefresh={gridRefresh} setGridRefresh={setGridRefresh} handleFilesDownload={handleFilesDownload} />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Jobs));
