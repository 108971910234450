import React, { Fragment, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { getRequest } from '../../actions/orders';
import { showNotification } from '../../actions/ui';
import { NotificationTypes } from 'constants/index';
import Loader from 'components/Loader';

import './style.scss';

const Expander = (props) => {
    const [opened, setOpened] = useState(false);
    const [data, setData] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [actions, setActions] = useState(null);
    // const [actions, setActions] = useState([{name: "Скинути сесію", action: "test"}, {name: "Запит", action: "test2"} ]);
    const [isActionsLoading, setIsActionsLoading] = useState(false);
    // const { opened, data, actions } = state;
    const wrapHeight = data && data.length ? 'full' : 'min';

    const openedStyle = opened ? 'opened' : '';

    const loadingStyle = isActionsLoading || isDataLoading ? 'loading' : '';

    const {
        data: { detailObjectType, detailObjectId },
        getRequest,
        requestkey,
        requestSource,
        requestUrlKey,
        requestActionsUrlKey,
        showNotification,
    } = props;

    const requestId = requestkey ? props.data[requestkey] : detailObjectId;

    useEffect(() => {
        setOpened(false);
        setData(null);
        setActions(null);
    }, [requestId]);

    const handleDataResponse = ({ success, result }) => {
        setIsDataLoading(false);
        if (success) {
            setData(result);
        } else {
            throw new Error('Request for order type options failed');
        }
    };

    const handleActionsResponse = ({ success, result }) => {
        setIsActionsLoading(false);
        if (success) {
            setActions(result);
        } else {
            throw new Error('Request for actions params failed');
        }
    };

    const onExpandCollapse = (e) => {
        e.stopPropagation();

        setOpened((prev) => !prev);

        try {
            if (!data) {
                setIsDataLoading(true);
                getRequest(handleDataResponse, {
                    key: requestUrlKey || 'get_detail_in_grid',
                    pathParams: { id: requestId, type: detailObjectType, source: requestSource },
                });
            }
            if (!actions && requestActionsUrlKey) {
                setIsActionsLoading(true);
                getRequest(handleActionsResponse, {
                    key: requestActionsUrlKey,
                    pathParams: { id: requestId, source: requestSource },
                });
            }
        } catch (e) {
            setIsDataLoading(false);
            console.error("Expander::onExpandCollapse: Error ", e);
        }
    };

    const handleActionResponse = ({ success, result }) => {
        if (success) {
            if (result && result.message) {
                showNotification({
                    type: NotificationTypes.SUCCESS,
                    options: {
                        message: result.message,
                    },
                });
            }
        } else {
            throw new Error('Request for action failed');
        }
    };

    const handleAction = (action) => {
        getRequest(handleActionResponse, {
            key: 'post_proxy_detail_action_in_grid',
            method: 'post',
            pathParams: { id: requestId, action, source: requestSource },
        });
    };

    const renderAction = ({ action, name }) => {
        return (
            <button class="btn btn-primary" onClick={() => handleAction(action)}>
                {name}
            </button>
        );
    };

    return (
        <Fragment>
            <div className="expand-data" onClick={onExpandCollapse}>
                <i className={`icon-arrow-right ${opened ? 'open' : ''}`} />
            </div>

            <div
                className={`ex-wrapper ${opened ? wrapHeight : ''} ${loadingStyle} ${openedStyle}`}
                onClick={(e) => e.stopPropagation()}
            >
                {opened && (
                    <div className="ex-data">
                        {(isActionsLoading || isDataLoading) && (
                            <Loader
                                loaderClass="expander-loader"
                                loaderContainerClass="expander-loader-container"
                            />
                        )}
                        {!isActionsLoading &&
                            !isDataLoading &&
                            data &&
                            data.map((val, index) => {
                                const { name, value } = val;
                                const trimmedName = name.trim() + ':';
                                return (
                                    <div className="ex-block" key={index}>
                                        <div className="in-row">
                                            <ReactMarkdown source={trimmedName} />
                                        </div>
                                        <div className="in-row-value">
                                            <ReactMarkdown source={value} />
                                        </div>
                                    </div>
                                );
                            })}
                        {!isActionsLoading && !isDataLoading && actions && (
                            <div class="ex-wrapper-actions">{actions.map(renderAction)}</div>
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default connect(null, { getRequest, showNotification })(Expander);
