const createStorageService = storage => {
  return {
    set: (key, object) => {
      if (!key) return false;

      let value;
      try {
        value = JSON.stringify(object);
      } catch (error) {
        return false;
      }

      storage.setItem(key, value);
      return true;
    },

    get: (key) => {
      if (!key) return undefined;

      let object;
      try {
        object = JSON.parse(storage.getItem(key));
      } catch (error) {
        return undefined;
      }
      return object;
    },

    remove: (key) => localStorage.removeItem(key),
    // clear: () => localStorage.clear()
  };
};

export const SORT_POSTFIX = '_sort';
export const FIELDS_POSTFIX = '_fields';
export const SETTINGS_POSTFIX = '_settings';

export const sessionStorageService = Object.freeze(createStorageService(sessionStorage));

export default Object.freeze(createStorageService(localStorage));
