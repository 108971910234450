import React from "react";
import ReactSVG from "react-svg";
import { withTranslation } from "react-i18next";

import "./styles.scss";

const ContactPersonCard = props => {
    const { t, data, onCardSelect } = props;
    const { type, partyType, content } = data;

    const clientType = type === 'contact_person' ? 'contactPerson' : partyType;
    return (
        <div className="customer-card" onClick={() => onCardSelect(data)}>
            <div className="customer-card-header">
                <div className="customer-card-header-type">
                    <ReactSVG className="type-icon" src={`/data/svg/customer/${clientType}.svg`} />
                    <span>{t(`customer.${clientType}`)}</span>
                </div>
            </div>
            <div className="customer-card-content">{content}</div>
        </div>
    );
};

export default withTranslation()(ContactPersonCard);
