import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const ButtonSet = ({ children, noPadding, centered, ...props }) => {
    return (
        <div className={cx('button-set', { noPadding, centered })} {...props}>
            {children}
        </div>
    );
};

ButtonSet.propTypes = {
    children: PropTypes.any,
    noPadding: PropTypes.bool,
    centered: PropTypes.bool,
    props: PropTypes.any
};

export default ButtonSet;
