import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ModalWindow } from 'ui-core-dashboard';
import Tabs from '../common/Tabs';

import { get } from "lodash";

import baseService from '../../../services/BaseService';

import { closeExportWizardModal } from '../../../reducers/exportWizard';

import { clear as clearComponatorData } from "reducers/componator";

import { subTabsConfig } from './config';


const mapStateToProps = (state) => {
    const gridKey = get(state, "grid.gridKey");
    return { gridKey };
};

const mapDispatchToProps = {
    closeExportWizardModal,
    clearComponatorData
};

let checkTimeout = null;

const ExportWizardModal = (props) => {
    const { closeExportWizardModal, t, gridKey, clearComponatorData } = props;

    const [activeTab, setActiveTab] = useState(subTabsConfig[0].tab);
    const [initGridKey, setInitGridKey] = useState(null);

    const [gridRefresh, setGridRefresh] = useState(false);



    function downloadFileByUrl(url) {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    function handleFilesDownload(files) {
        files.forEach(file => {
            const url = `/mw/file?fileId=${file.file_uid}&download=1`;
            downloadFileByUrl(url);
        });
    }


    function checkJobs() {
        baseService.get("exportJobCheck").then(response => {
            const { result, success } = response;
            checkTimeout = clearTimeout(checkTimeout);

            if (success) {
                if (result.esc) {
                    // checkTimeout = clearTimeout(checkTimeout);
                    console.log(`ExportWizardModal::checkJobs: Stop check (esc).`);
                } else {
                    if (result.jobs && Array.isArray(result.jobs)) {
                        result.jobs.forEach(job => {
                            // check for status "complete" => and download files for that
                            if (job.status === "complete" && Array.isArray(job.files) && job.files.length > 0) {
                                handleFilesDownload(job.files);
                                setGridRefresh(true);
                            }
                        });
                    }
                    if (result.timeout && !checkTimeout) {
                        // console.log("**** SET TIMEOUT ***");
                        console.log(`ExportWizardModal::checkJobs: Refetch again in  ${result.timeout} seconds.`);
                        checkTimeout = setTimeout(checkJobs, result.timeout * 1000);
                    }
                }
            }
        }).catch(e => {
            console.log("ExportWizardModal::checkJobs: Error ", e);
        });
    }



    useEffect(() => {
        if (activeTab === 'jobs' && !checkTimeout) {
            // console.log("*** CHECK JOBS ***");
            checkJobs();
        }
    }, [activeTab]);


    useEffect(() => {
        // used to keep track of wizard grid key once JobList grid is unmounted (tab change/clos), to correctly receive grid data
        if (gridKey) {
            setInitGridKey(gridKey);
        }
        return () => {
            if (checkTimeout) {
                // clear check on window close
                console.log(`ExportWizardModal::unmounted: Stop check.`);
                checkTimeout = clearTimeout(checkTimeout);
            }
            // reset companator
            clearComponatorData();
        };
    }, []);

    return (
        <>
            <ModalWindow
                onClose={closeExportWizardModal}
                className="ordering-component-ui-core-wrapper"
            >
                <header className="modal-window-header">
                    <div className="modal-window-title">{t('filters.export')}</div>
                </header>
                <div className="export-wizard-modal-content ordering-tab-wrapper">
                    {initGridKey && <Tabs
                        config={subTabsConfig}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        withoutHistory
                        onCancel={closeExportWizardModal}
                        wizardGridKey={initGridKey}
                        gridRefresh={gridRefresh}
                        setGridRefresh={setGridRefresh}
                        handleFilesDownload={handleFilesDownload}
                    />}
                </div>
            </ModalWindow>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExportWizardModal));
