import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const ButtonIcon = ({
    hidden,
    disabled,
    onClick,
    left,
    right,
    icon,
    loading,
    type,
    className,
    ...props
}) => {
    return hidden ? null : (
            <button
                className={cx('button-icon', className, { left, right, loading })}
                onClick={onClick}
                disabled={disabled}
                type={type}
                {...props}
            >
                <i hidden={!icon} className={icon} />
            </button>
        );
};

ButtonIcon.propTypes = {
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    left: PropTypes.bool,
    className: PropTypes.string,
    right: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    props: PropTypes.any,
};

export default ButtonIcon;
