import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';

const BillingCardsList = props => {
    const { updateApiGrid, pApartId } = props;
    const [loadingState, setLoadingState] = useState(true);
	const init = {
		key: 'billing_cards',
		query: '?'
	};
	const gridKey = `${init.key}_${init.path || init.extra || ''}`;
	
    const onStart = () => setLoadingState(true);

    const onFinish = () => setLoadingState(false);

    useEffect(() => {
		onStart();
		pApartId && updateApiGrid({ pApartId, limit: 1000  }, gridKey);
    }, [pApartId]);
    
     useEffect(() => {
		 pApartId && updateApiGrid({ pApartId, limit: 1000 }, gridKey);
    }, []);

    return (
        <div className='content-wrapper ordering-component-ui-core-wrapper with-fixed-status'>
			{pApartId && loadingState && <Loader />}
			{pApartId && <GridWrapper
				tuner={() => init}
				initUpdate={false}
				onStart={onStart}
				onFinish={onFinish}
			/>}
        </div>
    );
};

export default connect(null, { updateApiGrid: setApi })(withRouter(BillingCardsList));
