import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import LinkObjectsListGrid from './LinkObjectsListGrid';
import SearchFilterBlock from './SearchFilterBlock';
import { connect } from 'react-redux';
import { ComboBox, TextArea } from 'ui-core-dashboard';
import { closeObjectsLinkModal } from 'actions/ui';
import { Form, reduxForm, Field, getFormSyncErrors, getFormValues, initialize } from 'redux-form';
import useDebounce from '../../../custom-hooks/useDebounce';
import isEmpty from 'lodash/isEmpty';
import { getRequest } from '../../../actions/orders';
import {
    setObjectLinkBinders,
    checkObjectBinder,
    unCheckObjectBinder,
    clearObjectLinkBinders,
} from '../../../modules/appeal/actions';
import { updateAppealViewLinks } from 'modules/appeal/actions';
import permissions, { checkPermissions } from 'config/permissions';
import Pill from 'components/Common/ArrayPillsInput/Pill';
import _ from 'lodash';
import cx from 'classnames';
import Loader from '../../../components/Loader';

const OBJECT_TYPE = 'INTERACTION_REQUEST';
const REL_OBJECT_TYPE = 'SERVICE';
const REL_OBJECT_TYPES = ['SERVICE', 'RESOURCE'];

const mapStateToProps = (state, props) => ({
    newLinkModalContext: state.ui.newLinkModalContext,
    formValues: getFormValues('link-objects-form')(state),
    linkFilterList: state.appeal.linkFilterList,
    filterValues: getFormValues('modal-link-filter-search')(state),
    formErrors: getFormSyncErrors('link-objects-form')(state),
    canEditLinkAppeal: checkPermissions(permissions.LinkOperations.actionEditLinks),
    checkedObjectBinders: state.appeal.checkedObjectBinders,
});

const mapDispatchToProps = {
    closeObjectsLinkModal,
    updateAppealViewLinks,
    getRequest,
    setObjectLinkBinders,
    checkObjectBinder,
    unCheckObjectBinder,
    clearObjectLinkBinders,
    initialize,
};

function validate(values) {
    const isEmpty = _.get(values, 'link_type') === null;

    const errors = isEmpty ? { link_type: "Обов'язкове поле" } : [];
    return errors;
}

function LinkForm(props) {
    const {
        t,
        formValues,
        formErrors,
        pristine,
        canEditLinkAppeal,
        updateAppealViewLinks,
        id,
        customerId,
        closeObjectsLinkModal,
        getRequest,
        initialize,
        checkedObjectBinders,
        checkObjectBinder,
        unCheckObjectBinder,
        clearObjectLinkBinders,
        setObjectLinkBinders,
        objectType,
        relObjectTypes,
        code,
        type,
        filterId,
        customGridKey,
        customConfigCode,
        onLinkEditSuccess = () => {}
    } = props;
    const [appealQuery, setAppealQuery] = useState('');
    // const [filterObject, setFilterObject] = useState([]);

    const [linkTypeOptions, setLinkTypeOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const [bindersLoading, setBindersLoading] = useState(false);
    const { debouncedValue: debouncedAppealQuery } = useDebounce(appealQuery, 300);

    useEffect(() => {
        const linkTypeParams = {
            key: 'appeal_link_types',
            method: 'get',
            pathParams: { objectType: objectType || REL_OBJECT_TYPE },
        };
        getRequest(handleLinkTypeOptions, linkTypeParams);

        const bindersParams = {
            key: 'objects_links',
            method: 'get',
            pathParams: { objectType: objectType || OBJECT_TYPE, id },
            data: { relObjectTypes: relObjectTypes || REL_OBJECT_TYPES, },
        };

        if (Array.isArray(relObjectTypes || REL_OBJECT_TYPES)) {
            bindersParams.paramsSerializerKey = 'relObjectTypes';
        }

        setBindersLoading(true);
        getRequest(handleBinders, bindersParams, '', () => setBindersLoading(false));

        return () => {
            clearObjectLinkBinders();
            setAppealQuery('');
        };
    }, []);

    useEffect(() => {
        if (isEmpty(formErrors) && checkedObjectBinders.length && _.get(formValues, 'link_type')) {
            setReadyToSubmit(true);
        } else {
            setReadyToSubmit(false);
        }
    }, [checkedObjectBinders, formErrors, formValues, pristine]);

    const handleLinkTypeOptions = ({ success, result }) => {
        // handle getRequest action response
        if (success) {
            const convertedOptions = result.reduce((prev, cur) => {
                return [...prev, { label: cur.label, value: cur.id.toString() }];
            }, []);
            setLinkTypeOptions(convertedOptions);
            convertedOptions.length && initialize({ link_type: convertedOptions[0].value });
        } else {
            throw new Error('Request for order type options failed');
        }
    };

    const onSuccess = ({ success }) => {
        if (success) {
            setIsLoading(false);
            updateAppealViewLinks(true);
            if (onLinkEditSuccess && typeof onLinkEditSuccess === 'function') {
                onLinkEditSuccess();
            }
            return closeObjectsLinkModal();
        }
        setIsLoading(false);
        throw new Error('Request for order type options failed');
    };

    const emptySubmit = () => {};

    const onCheckHandler = (binder) => {
        if (binder.checked) {
            return checkObjectBinder(binder);
        }
        return unCheckObjectBinder(binder);
    };

    const setAppealsQuery = (e, query) => {
        // start search from at least one symbol and clear query if not
        if (!query || !query.length) return setAppealQuery('');

        // set new appealQuery so grid will update
        setAppealQuery(query);
    };

    function removeBinder(binder, index) {
        binder.checked = false;
        onCheckHandler(binder);
    }

    const handleBinders = ({ success, result }) => {
        setBindersLoading(false);
        if (success) {
            const binders = result.map((item) => ({
                object_id: item.objectId,
                object_type: item.objectType,
                regnum: item.objectId.toString(),
                checked: true,
            }));
            setObjectLinkBinders(binders);
        }
    };

    const onSave = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const data = { ...formValues, binders: checkedObjectBinders };
        const params = {
            key: 'objects_links',
            method: 'post',
            data,
            pathParams: { objectType: objectType || OBJECT_TYPE, id },
            jsonType: true,
        };
        canEditLinkAppeal && getRequest(onSuccess, params);
    };

    return (
        <div className="appeal-window">
            <div className="appeal-window-header">
                <div className="appeal-window-title">{t('appealObjects.header')}</div>
            </div>

            <div className="appeal-window-form-wrapper">
                <Form onSubmit={emptySubmit} className="filters-field-form">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="fields-wrapper ">
                            <div className="top-form ordering-component-ui-core-wrapper">
                                <Field
                                    key="link_type"
                                    name="link_type"
                                    label={t('appealObjects.type')}
                                    component={ComboBox}
                                    noResultsText={t('noItems')}
                                    placeholder={t('dontSelect')}
                                    clearable={false}
                                    searchable={false}
                                    required
                                    disabled={!canEditLinkAppeal}
                                    options={linkTypeOptions}
                                />
                                <hr className="divider" />
                                <SearchFilterBlock
                                    setAppealsQuery={setAppealsQuery}
                                    filterTopic={'objects'}
                                    code={code}
                                />
                            </div>
                            <div className="customer-ordering-grid">
                                <LinkObjectsListGrid
                                    customConfigCode={customConfigCode}
                                    customGridKey={customGridKey}
                                    customerId={customerId}
                                    gridSearch={debouncedAppealQuery}
                                    onCheck={onCheckHandler}
                                    // filterObject={filterObject}
                                    code={code}
                                    type={type}
                                    filterId={filterId}
                                />
                            </div>
                            <div className="appeal-link-bottom-block">
                                <div className="inline-fields">
                                    <div className="inputWrapper">
                                        <div className="input-label">
                                            {t('appealObjects.selected')}
                                        </div>
                                        <div className="input-field-binders-container email-input-container">
                                            {checkedObjectBinders.map((params, idx) => (
                                                <Pill
                                                    value={params.object_id}
                                                    id={idx}
                                                    remove={() => removeBinder(params, idx)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <Field
                                    key="comment"
                                    name="comment"
                                    label={t('appealObjects.comment')}
                                    component={TextArea}
                                    noResultsText={t('noItems')}
                                    placeholder={t('text')}
                                    maxLength={1000}
                                />
                                <button
                                    className={cx('btn', 'btn-primary', {
                                        'btn-disabled': !readyToSubmit,
                                    })}
                                    onClick={onSave}
                                    disabled={!readyToSubmit}
                                >
                                    {t('appeal.save')}
                                </button>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm({ form: 'link-objects-form', validate })(withTranslation()(LinkForm)));
