import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import styles from 'styles/modules/knowledgeBase.module.scss';
import Loader from 'components/Loader';
import cn from 'classnames';
import CalendarFilterForm from './CalendarFilterForm';
import EventForm from './EventForm';

function Sidebar (props) {
	const { loading, eventMode, setDynParams, setAllowModes, isGridView, eventFormConfig } = props;
	
	const handleSubmit = (values) =>{
	};
	
	return (
		<div className={cn(styles.sidebarBox, 'issues-sidebar', 'calendar-sidebar', {eventMode})}>
			<div className='issues-container'>
				{loading && <Loader/>}
				{!loading && eventMode ?
				<EventForm setDynParams={setDynParams} setAllowModes={setAllowModes} eventFormConfig={eventFormConfig} />:
				<CalendarFilterForm handleSubmit={handleSubmit} isGridView={isGridView}/>}
			</div>
		</div>
	);
}


export default Sidebar;
