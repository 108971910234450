import React, { useCallback, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ClickOutsideHolder from 'components/ClickOutsideHolder';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import ReactSVG from 'react-svg';
import { debounce } from 'lodash';
import CheckBoxToggle from '../../components/Common/CheckBoxToggle';
import NotificationIndicator from './Indicator';
import Loader from '../Loader';
import TelegramDevices from './TelegramDevices';
import moment from 'moment';

function Notifications(props) {
    const {
        close,
        open,
        total,
        notifications,
        toggleReadStatus,
        onDelete,
        t,
        onScrollDown,
        isFetching,
        streamState,
        toggleNotificationHistoryStream,
    } = props;
    const [scrollDimensions, setScrollDimensions] = useState(null);
    const [showAdwance, setShowAdwance] = useState(false);
    const debouncedSetScrollDimensions = useCallback(
        debounce((dimensions) => setScrollDimensions(dimensions), 100),
        [],
    );
    const handleScroll = (event) => {
        const { target } = event;
        const { scrollTop, scrollHeight } = target;
        const { height } = target.getBoundingClientRect();
        debouncedSetScrollDimensions({
            scrollTop: Math.floor(scrollTop),
            scrollHeight: Math.floor(scrollHeight - height - 100),
        });
    };

    const toggleShowAdvance = () => {
        setShowAdwance((prev) => !prev);
    };

    function handleClose(e) {
        let isIgnoreCloseOutside = false;

        // check for ignore-clickoutside classlist to prevent from closing notification panel on modal close / confirm
        const path = e ? e.path || (e.composedPath && e.composedPath()) : [];
        for (let i = 0; i < path.length; i++) {
            // if (path[i].getAttribute && path[i].getAttribute("data-ignore-clickoutside")) {
            //     isIgnoreCloseOutside = path[i].getAttribute("data-ignore-clickoutside") === 'true';
            //     // isFieldControllerParent = path[i].getAttribute("data-ignore-notifications-click-outside")
            //     break;
            // }
            if (path[i].classList && path[i].classList.contains("ignore-clickoutside")) {
                isIgnoreCloseOutside = true;
                return;
            }
        }
        if (isIgnoreCloseOutside) {
            return;
        }
        close();
    }

    useEffect(() => {
        if (scrollDimensions) {
            const { scrollTop, scrollHeight } = scrollDimensions;
            if (scrollTop >= scrollHeight) {
                onScrollDown();
            }
        }
    }, [scrollDimensions]);

    useEffect(() => {
        setShowAdwance(false);
    }, [open]);

    const entity = { INTERACTION_REQUEST: 'appeals', TASK: 'order' };

    const getMarkdownProps = (value) => {
        return {
            source: value,
            escapeHtml: false,
            unwrapDisallowed: true, // still place content of disallowedTypes
            disallowedTypes: ['paragraph'], // remove all paragraphs <p></p>render
            className: 'notification-title-text',
        };
    };

    return (
        <ClickOutsideHolder
            className={`history-outside-holder ${open ? 'open' : ''}`}
            onClickOutside={handleClose}
        >
            <div className="history" onScroll={handleScroll}>
                {!notifications ? (
                    <Loader loaderContainerClass="history-loader-overlay"/>
                ) : (
                    <>
                        <div className="header">
                            <div className="notificator-wrapper">
                                <NotificationIndicator streamState={streamState} indicator={total} />
                            </div>
                            <span className="header-title">{t('notification.title')}</span>
                            <i className="icon icon-advance" onClick={toggleShowAdvance}></i>
                        </div>
                        <>
                            {showAdwance && (
                                <>
                                    <div className="notificator-settings">
                                        <CheckBoxToggle
                                            input={{ value: ["connected", "connecting"].includes(streamState) }}
                                            label={t('popupMessage')}
                                            onChange={toggleNotificationHistoryStream}
                                        />
                                    </div>
                                    <TelegramDevices />
                                </>
                            )}
                            {!showAdwance && (
                                <>
                                    <div className="notification-block">
                                        {notifications.length > 0 &&
                                            notifications.map(
                                                ({
                                                    marked_read,
                                                    msgUserLogId,
                                                    body,
                                                    subject,
                                                    objectType,
                                                    objectID,
                                                    actionDate
                                                }) => (
                                                    <div
                                                        className={cx('notification', {
                                                            marked_read,
                                                        })}
                                                        key={msgUserLogId}
                                                    >
                                                        <div className="notification-title">
                                                            <div className="notification-icons">
                                                                {entity[objectType] && (
                                                                    <ReactSVG
                                                                        svgClassName="tab-icon"
                                                                        src={`/data/svg/navigation/${entity[objectType]}.svg`}
                                                                    />
                                                                )}
                                                            </div>
                                                            {entity[objectType] ? (
                                                                <Link
                                                                    to={`/${entity[objectType]}/${objectID}/`}
                                                                >
                                                                    {' '}
                                                                    <ReactMarkdown
                                                                        {...getMarkdownProps(
                                                                            subject,
                                                                        )}
                                                                    />
                                                                </Link>
                                                            ) : (
                                                                <ReactMarkdown
                                                                    {...getMarkdownProps(subject)}
                                                                />
                                                            )}
                                                            <i
                                                                className="icon-close"
                                                                onClick={() =>
                                                                    onDelete(msgUserLogId, false)
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="notification-body"
                                                            onClick={() =>
                                                                toggleReadStatus(
                                                                    msgUserLogId,
                                                                    marked_read,
                                                                )
                                                            }
                                                        >
                                                            <ReactMarkdown
                                                                {...getMarkdownProps(body)}
                                                            />
                                                        </div>
                                                        { actionDate && <div className='notification-date'>{moment(parseInt(actionDate)).format('MM/DD/YYYY HH:mm')}</div> }
                                                    </div>
                                                ),
                                            )}
                                        {isFetching && (
                                            <div className="notification-preloader">
                                                <Loader withContainer />
                                            </div>
                                        )}
                                    </div>
                                    <div className="bottom">
                                        <button
                                            className="close-button"
                                            type="button"
                                            onClick={() => toggleReadStatus(null, false)}
                                        >
                                            {t('notification.closeAll')}
                                        </button>
                                        <button
                                            className="close-button btn-delete"
                                            type="button"
                                            onClick={() => onDelete(null, false)}
                                        >
                                            {t('notification.removeAll')}
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    </>
                )}
            </div>
        </ClickOutsideHolder>
    );
}
export default withTranslation()(Notifications);
