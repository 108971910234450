import React, { Fragment, useState, useEffect, useMemo } from "react";
import cx from "classnames";
import moment from "moment";

import { withTranslation } from "react-i18next";
import { b64DecodeUnicode } from "helpers";
import Popup from "components/Popup";
import { EMAILREPLY, EMAILREPLYALL } from "./utils";
import ReactSVG from "react-svg";
import permissions, { checkPermissions } from "config/permissions";

function MailingItem(props) {
    const {
        subject,
        date,
        from = [],
        to = [],
        copyTo = [],
        attachments,
        t,
        id,
        interactionId,
        emailType,
        onClickReply,
        mailBody,
        onClickForward,
        toggleAllMails,
        mailbox,
        isLockedByOther,
    } = props;
    const hasAttachments = attachments && attachments.length > 0;

    const replyAllEmails = useMemo(() => {
        const filteredTo = to.filter(email => email !== mailbox);
        // return [...new Set(filteredTo.concat(copyTo))];
        return [...new Set(filteredTo.concat(copyTo).concat(from))];

    }, [to, mailbox, copyTo, from]);

    const [isOpen, setIsOpen] = useState(false);
    const [mailMenuIsOpen, setMailMenuIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(toggleAllMails);
    }, [toggleAllMails]);

    function open() {
        if (!isOpen) {
            setIsOpen(true);
        }
    }

    function closeMenu() {
        setMailMenuIsOpen(false);
    }

    function openMenu() {
        if (checkPermissions(permissions.EmailOperations.action_createEmail)) {
            setMailMenuIsOpen(true);
        }
    }

    function close() {
        if (isOpen) {
            setIsOpen(false);
        }
    }

    async function onReply() {
        if (checkPermissions(permissions.EmailOperations.action_createEmail)) {
            await onClickReply(
                id,
                interactionId,
                {
                    mailBody: b64DecodeUnicode(mailBody),
                    emailType,
                    subject,
                    from,
                    to,
                    copyTo,
                    mailbox,
                },
                EMAILREPLY
            );

            await closeMenu();
        }
    }

    async function onPrint() {
        const mail = { mailbox, subject, to, from, copyTo, date, mailBody: b64DecodeUnicode(mailBody), attachments };
        await props.onClickModalPrint(mail);
        await closeMenu();
    }

    async function onReplyAll() {
        await onClickReply(
            id,
            interactionId,
            {
                mailBody: b64DecodeUnicode(mailBody),
                emailType,
                subject,
                from,
                to,
                copyTo,
                mailbox,
            },
            EMAILREPLYALL
        );

        await closeMenu();
    }

    async function onForward() {
        await onClickForward(id, interactionId, {
            mailBody: b64DecodeUnicode(mailBody),
            from,
            to,
            copyTo,
            emailType,
            subject,
            mailbox,
            date: moment(date).format("DD MMMM YYYY, HH:mm"),
        });

        await closeMenu();
    }

    return (
        <div className={cx("borderedBlock", isOpen ? "isOpen" : "emailHover")} data-id={id}>
            <div className="mailHeader" onClick={open}>
                <div className={cx("mailTitle", isOpen && "top")}>
                    <div className="avatarWrapper">
                        <i className="icon-user" />
                    </div>
                    <div>
                        {emailType === "outgoing" && !isOpen && (
                            <div className="lightText">
                                {t("toWhom")}: {to.join(", ")}
                            </div>
                        )}
                        {emailType !== "outgoing" && !isOpen && (
                            <div className="lightText">
                                {t("from")}: {from}
                            </div>
                        )}
                        {isOpen && (
                            <Fragment>
                                <div className="lightText">
                                    {t("toWhom")}: {to.join(", ")}
                                </div>
                                <div className="lightText">
                                    {t("from")}: {from}
                                </div>
                                <div className="lightText">
                                    {t("copy")}: {copyTo.join(", ")}
                                </div>
                            </Fragment>
                        )}
                        <div className="mailTopic">{subject}</div>
                    </div>
                </div>
                <div className="mailIconsWrapper">
                    {hasAttachments && <ReactSVG svgClassName="icon-attach" src="data/svg/attach.svg" />}
                    <div className="lightText">{moment(date).format("DD.MM.YYYY, HH:mm:ss")}</div>

                    {isOpen && (
                        <Fragment>
                            <i
                                className={cx("icon-send", { disabled: isLockedByOther })}
                                disabled={isLockedByOther}
                                onClick={onReply}
                            />
                            <i className="icon-print" onClick={onPrint} />
                            <span className="divider" />
                            <i onClick={openMenu} className="icon-kebab-vert relative">
                                {mailMenuIsOpen ? (
                                    <Popup onClickOutside={closeMenu} place="mail-menu">
                                        <button
                                            type="button"
                                            className={cx("mailButton", { disabled: isLockedByOther })}
                                            disabled={isLockedByOther}
                                            onClick={onReply}
                                        >
                                            <i className="icon-send" />
                                            {t("answer")}
                                        </button>
                                        {replyAllEmails.length > 1 && (
                                            <button
                                                type="button"
                                                className={cx("mailButton", { disabled: isLockedByOther })}
                                                disabled={isLockedByOther}
                                                onClick={onReplyAll}
                                            >
                                                <i className="icon-send-all" />
                                                {t("answerAll")}
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className={cx("mailButton", { disabled: isLockedByOther })}
                                            disabled={isLockedByOther}
                                            onClick={onForward}
                                        >
                                            <i className="icon-arrow-alt-right" />
                                            {t("forwardEmail")}
                                        </button>
                                    </Popup>
                                ) : null}
                            </i>
                            <i onClick={close} className="icon-up" />
                        </Fragment>
                    )}
                    {!isOpen && <i className="icon-down" />}
                </div>
            </div>
            {isOpen && (
                <div className={cx("mailText", isOpen && "openedMailText")}>
                    <span dangerouslySetInnerHTML={{ __html: b64DecodeUnicode(mailBody) }} />
                </div>
            )}
            <div className={cx("mailBody", isOpen && "openedMailBody")}>
                {hasAttachments && (
                    <div>
                        <hr className="fileSeparator" />
                        <div className="filesWrapper">
                            <div className="filesList">
                                {attachments.map(({ name, id }) => (
                                    <div className="kb-file-wrapper" key={id}>
                                        <a
                                            className="file-preview"
                                            target="_blank"
                                            href={`../mw/file?fileId=${id}&download=0`}
                                            title={name}
                                        >
                                            {name}
                                        </a>
                                        <div className="kb-file-actions">
                                            <a
                                                className="file-download"
                                                target="_blank"
                                                href={`../mw/file?fileId=${id}&download=1`}
                                                title={name}
                                            >
                                                <ReactSVG className="svg-download" src="/data/svg/download.svg" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="filesButtons">
                                <a
                                    href={`../mw/appeal/file/getFiles?typeCode=MAILATTACHMENT&id=${interactionId}`}
                                    download
                                    className="filesButton"
                                >
                                    {t("comments.uploadAll")}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withTranslation()(MailingItem);
