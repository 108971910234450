import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function HeaderSecondaryCell ({ children, separated }) {
	return (
		<div className={cx('headerSecondaryCell', { 'separatedCell': separated })}>{children}</div>
	);
}

HeaderSecondaryCell.propTypes = {
	children: PropTypes.any,
	separated: PropTypes.bool
};
