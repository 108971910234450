import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const StyledRadio = props => {
	const { name, value, checked, onChange, title, input, disabled, required, error, ...restProps } = props;

	
	// logic to clear radio input when the same value is clicked again
	function handleClick(event) {
		if (input && input.value === value) {
			const { target } = event;
			target.checked = false;
			target.value = null;
			event.target.value = null;
			target.click();
			target.blur();
			onChange(event);
		}
	}
	
	return (
		<label className={cx('styled-radio-container', { 'disabled': disabled, error })}>
			<input type='radio'
				   {...restProps}
				   {...input}
				   className='styled-radio'
				   onClick={handleClick}
				   onChange={onChange}
				   name={name}
				   value={value}
				   checked={checked}
				   disabled={disabled}
				   {...required}
			/>
			<span className='styled-radio-mark' />
			<span className='styled-radio-title'>{title}</span>
		</label>
	);
};

StyledRadio.propTypes = {
	name: PropTypes.string,
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	title: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

export default StyledRadio;
