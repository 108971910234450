import React, {useEffect} from 'react';
import { reduxForm, Field, initialize } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Input, TextArea } from 'ui-core-dashboard';
import {EditorHTML} from "../../../components/KnowledgeBase/common";


const validate = ({ label }) => ({
	name: label === '' ? 'Обов\'язкове поле' : undefined,
});

function EditSignForm(props) {
	const {current, initialize, t} = props;
	
	useEffect( () =>{
		current && initialize({label: current.label, signature: current.value});
	}, [current]);
	
	return(
		<form className='top-form ordering-component-ui-core-wrapper'>
			<Field
				id='label-id'
				name='label'
				key='label'
				component={Input}
				placeholder={current.id ? t('name') : ''}
				required={true}
				maxLength={200}
				disabled={!current.id}
			/>
			
			<Field
				id='signature-id'
				name='signature'
				key='signature'
				component={EditorHTML}
				required={false}
				disabled={!current.id}
			/>
		</form>
	)
}

export default reduxForm({ form: 'modal-edit-sign-form', validate, destroyOnUnmount: true, enableReinitialize: true })(
	(withTranslation()(EditSignForm))
);
