import React from 'react';
import PropTypes from 'prop-types';
import { ModalWindow } from 'ui-core-dashboard';
import { useTranslation } from 'react-i18next';

import BPMNView from '../BPMNView';

import './styles.scss';


const BPMNModal = ({ id, title, isOpen, toggleModalVisibility }) => {
    const { t } = useTranslation();

    const handleClose = () => {
        if (isOpen) toggleModalVisibility(false);
    };

    return (
        <ModalWindow
            isOpen={isOpen}
            onClose={handleClose}
            className='bpmn-modal'
            safetyMode={false}
        >
            <header className='modal-window-title'>
                {title || `${t('The process diagram for task')}: ${id}`}
            </header>
            <BPMNView id={id} />
        </ModalWindow>
    );
};

BPMNModal.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    toggleModalVisibility: PropTypes.func
};
BPMNModal.defaultProps = {
    id: null,
    title: '',
    isOpen: false
};

export default BPMNModal;
