import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

@withTranslation()
class DefaultItem extends React.Component {
	
	getOptionalParts = () => {
		const { data: { originator: { channelName } }, comment  } = this.props;
		return { channelName, comment };
	};
	render () {
		const { channelName, comment } = this.getOptionalParts();
		return (
			<li className='history-item'>
				<i className='icon-envelope' />
				<div className='history-item-content'>
					<span className='history-title-text'>{channelName}</span>
					<span className='history-content-text'>{comment}</span>
				</div>
			</li>
		);
	}
}

DefaultItem.propTypes = {
	data: PropTypes.object
};

export default DefaultItem;
