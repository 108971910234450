import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import LinkAppealListGrid from './LinkAppealListGrid';
import SearchFilterBlock from './SearchFilterBlock';
import {connect } from "react-redux";
import { ComboBox, TextArea } from 'ui-core-dashboard';
import { closeAppealLinkModal } from 'actions/ui';
import { Form, reduxForm, Field, getFormSyncErrors, getFormValues, initialize } from 'redux-form';
import useDebounce from "../../../custom-hooks/useDebounce";
import isEmpty from "lodash/isEmpty";
import { getRequest } from "../../../actions/orders";
import { setLinkBinders, checkBinder, unCheckBinder, clearLinkBinders } from "../../../modules/appeal/actions";
import { updateAppealViewLinks } from "modules/appeal/actions";
import permissions, { checkPermissions } from 'config/permissions';
import Pill from 'components/Common/ArrayPillsInput/Pill';
import _ from 'lodash';
import cx from 'classnames';
import Loader from "../../../components/Loader";

const OBJECT_TYPE = "INTERACTION_REQUEST";

const mapStateToProps = (state, props) => ({
	newLinkModalContext: state.ui.newLinkModalContext,
	formValues: getFormValues('link-appeals-form')(state),
	linkFilterList: state.appeal.linkFilterList,
	filterValues: getFormValues('modal-link-filter-search')(state),
	formErrors: getFormSyncErrors('link-appeals-form')(state),
	canEditLinkAppeal: checkPermissions(permissions.LinkOperations.actionEditLinks),
	checkedBinders: state.appeal.checkedBinders,
});

const mapDispatchToProps = {
	closeAppealLinkModal,
	updateAppealViewLinks,
	getRequest,
	setLinkBinders,
	checkBinder,
	unCheckBinder,
	clearLinkBinders,
	initialize,
};

function validate (values) {
	const isEmpty = _.get(values, 'link_type') === null ;

	const errors = isEmpty ? { link_type: 'Обов\'язкове поле' } : [];
	return errors;
}

function LinkForm(props) {
	const { t, formValues, formErrors,  pristine, canEditLinkAppeal, updateAppealViewLinks,  appeal: {id},
		closeAppealLinkModal, getRequest, initialize, checkedBinders, checkBinder, unCheckBinder, clearLinkBinders, setLinkBinders
	} = props;
	const [appealQuery, setAppealQuery] = useState('');
	const [filterObject, setFilterObject] = useState([]);

	const [linkTypeOptions, setLinkTypeOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [bindersLoading, setBindersLoading] = useState(false);
	const { debouncedValue: debouncedAppealQuery } = useDebounce(appealQuery, 300);
	
	useEffect(() => {
		const linkTypeParams = {
			key: 'appeal_link_types',
			method: 'get',
			pathParams: { objectType: OBJECT_TYPE}
		};

		getRequest(handleLinkTypeOptions, linkTypeParams);

		const bindersParams = { 
			key: 'objects_links',
			method: 'get',
			pathParams: { objectType: OBJECT_TYPE, id }, 
			data: { relObjectTypes: OBJECT_TYPE }  
		}
		setBindersLoading(true);
		getRequest(handleBinders, bindersParams, "", () => setBindersLoading(false));
		
		return () => {
			clearLinkBinders();
			setAppealQuery('');
		}	
	}, []);
	
	useEffect(() => {
		if (isEmpty(formErrors) &&  checkedBinders.length && _.get(formValues, 'link_type')) {
			setReadyToSubmit(true);
		} else {
			setReadyToSubmit(false);
		}
		
	}, [checkedBinders, formErrors, formValues, pristine]);
	
	
	const handleLinkTypeOptions = ({ success, result }) => {
		// handle getRequest action response
		if (success) {
			const convertedOptions = result.reduce((prev, cur) => {
				return [...prev, { 'label': cur.label, 'value': cur.id.toString() }];
			}, []);
			setLinkTypeOptions(convertedOptions);
			convertedOptions.length && initialize({link_type: convertedOptions[0].value})
		} else {
			throw new Error('Request for order type options failed');
		}
		
	};
	
	const onSuccess = ({ success })  => {
		setIsLoading(false);
		if (success) {
			updateAppealViewLinks(true);
			return closeAppealLinkModal();
		}
		throw new Error('Request for order type options failed');
	};
	
	const emptySubmit = () => {	};
	
	const onCheckHandler = (binder) => {
		if(binder.checked) {
			return checkBinder(binder);
		}
		return unCheckBinder(binder);
	};
	
	const setAppealsQuery = (e, query) => {
		// start search from at least one symbol and clear query if not
		if (!query || !query.length) return setAppealQuery('');
		
		// set new appealQuery so grid will update
		setAppealQuery(query);
	};
	
	
	function removeBinder (binder, index) {
		binder.checked = false;
		onCheckHandler(binder)
	}

	const handleBinders = ({ success, result}) => {
		setBindersLoading(false);
		if (success) {
			const binders = result.map(item => ({
				object_id: item.objectId,
				object_type: item.objectType,
				regnum: item.objectNum.toString(),
				checked: true
			}));
			setLinkBinders(binders);
		}
	}
	
	const onSave = (e) => {
		e.preventDefault();
		setIsLoading(true);

		const data = {...formValues, binders: checkedBinders };
		const params = {
			key: 'objects_links',
			method: 'post',
			data,
			pathParams: { objectType: OBJECT_TYPE, id },
			jsonType:true
		};
		canEditLinkAppeal && getRequest(onSuccess, params);
		
	};

	return(
		<div className='appeal-window'>
			<div className='appeal-window-header'>
				<div className='appeal-window-title'>{t('appealLink.header')}</div>
			</div>
			
			<div className='appeal-window-form-wrapper'>
				
				<Form onSubmit={emptySubmit} className='filters-field-form'>
					{isLoading || bindersLoading ? <Loader /> : <div className='fields-wrapper '>
						<div className='top-form ordering-component-ui-core-wrapper'>
							<Field
								key='link_type'
								name='link_type'
								label={t('appealLink.type')}
								component={ComboBox}
								noResultsText={t('noItems')}
								placeholder={t('dontSelect')}
								clearable={false}
								searchable={false}
								required
								disabled={!canEditLinkAppeal}
								options={linkTypeOptions}
							/>
						<hr className='divider'/>
							<SearchFilterBlock
								setAppealsQuery={setAppealsQuery}
								filterTopic={'appeal'}
							/>
					</div>
						<div className='customer-ordering-grid'>
							<LinkAppealListGrid
								gridSearch={debouncedAppealQuery}
								onCheck={onCheckHandler}
								filterObject={filterObject}/>
						</div>
						<div className='appeal-link-bottom-block'>
						<div className='inline-fields'>
							<div className='inputWrapper'><div className="input-label">{t('appealLink.selected')}</div>
								<div className='input-field-binders-container email-input-container'>
										{checkedBinders.map((params, idx) => (
											<Pill value={params.regnum} id={idx} remove={ () => removeBinder(params, idx)}  />
										))}
								</div>
							</div>
						</div>
							<Field
								key='comment'
								name='comment'
								label={t('appealLink.comment')}
								component={TextArea}
								noResultsText={t('noItems')}
								placeholder={t('text')}
								maxLength={1000}
							/>
							<button className={cx('btn', 'btn-primary',{ 'btn-disabled': !readyToSubmit }) } onClick={onSave} disabled={!readyToSubmit}>
							{t('appeal.save')}
						</button>
						</div>
					</div>}
				</Form>
			</div>
		</div>
		
	)
}
export default connect(mapStateToProps, mapDispatchToProps)
(reduxForm({ form: 'link-appeals-form', validate })
(withTranslation()(LinkForm)));
