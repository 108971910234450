import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { CustomerTypes } from 'constants/index';
import { reduxFormWrapper } from 'helpers';
import FormWrapper from '../FormWrapper';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import validate from './validate';
import InputField from 'components/Common/InputField';
import ComboBox from 'components/Common/ComboBox';
// import i18next from 'util/i18n';

export const naturalPersonConfig = t => ([
	{ name: 'lastName', label: t('persons.lastName'), component: InputField, maxLength: 50 },
	{ name: 'firstName', label: t('persons.firstName'), component: InputField, required: true, maxLength: 50 },
	{ name: 'patronymic', label: t('persons.patronymic'), component: InputField, maxLength: 50 },
	{ name: 'phone', label: t('filter.phone'), component: InputField, required: true, maxLength: 19, errorPlaceholder: true },
	{ name: 'email', label: t('searchclient.email'), component: InputField, maxLength: 255, errorPlaceholder: true }
]);

export const organization = t => ([
	...naturalPersonConfig(t),
	{
		name: 'issues',
		label: t('customerInfoLabel.issues'),
		component: ComboBox,
		required: true,
		multi: true,
		errorPlaceholder: true,
		placeholder: t('appealForm.dontSelect')
	}
]);

const getConfig = (customerType, t) => {
	const configs = {
		[CustomerTypes.NATURAL_PERSON]: naturalPersonConfig(t),
		[CustomerTypes.ORGANIZATION]: organization(t)
	};
	
	return configs[customerType] || [];
};

const mapStateToProps = (state, ownProps) => ({
	config: getConfig(ownProps.customerType, ownProps.t),
	phoneNumber: state.call.incomePhoneNumber
});

@withTranslation()
@connect(mapStateToProps)
@reduxFormWrapper({ form: 'person-form', validate })
class PersonForm extends React.Component {
	
	constructor (props) {
		super(props);
		this.issues = this.convertDictionaryObject(props.t('dictionary:contactIssue', { returnObjects: true }));
	}
	
	componentDidMount () {
		this.setPhoneNumberIfCallExists();
	}
	
	componentDidUpdate (prevProps) {
		if (!this.props.pristine && prevProps.pristine) {
			this.props.enableWarning({ personForm: true });
		} else if (this.props.pristine && !prevProps.pristine) {
			this.props.enableWarning({ personForm: false });
		}
	}
	
	setPhoneNumberIfCallExists = () => {
		if (this.props.phoneNumber) {
			this.props.change('phone', this.props.phoneNumber);
		}
	};
	
	convertDictionaryObject = dictionaryObject => Object.entries(dictionaryObject).map(([prop, value]) => ({
		value: prop,
		label: value
	}));
	
	getFieldPropsWithOptions = fieldProps => {
		switch (fieldProps.name) {
		case 'issues':
			return { ...fieldProps, options: this.issues.slice(1) };
		
		default:
			return fieldProps;
		}
	};
	
	renderField = fieldConfig => {
		return (
			<Field key={fieldConfig.name} {...this.getFieldPropsWithOptions(fieldConfig)} />
		);
	};
	
	getTitle = () => {
		const { t } = this.props;
		
		const titles = {
			[CustomerTypes.NATURAL_PERSON]: t('personalData'),
			[CustomerTypes.ORGANIZATION]: t('searchclient.contactPersonName')
		};
		
		return titles[this.props.customerType] || '';
	};
	
	render () {
		const { handleSubmit, customerType, config } = this.props;
		return (
			<FormWrapper
				title={this.getTitle()}
				onSubmit={handleSubmit}
				customerType={customerType}
			>
				{config.map(this.renderField)}
			</FormWrapper>
		);
	}
}

PersonForm.propTypes = {
	customerType: PropTypes.string,
	onSubmit: PropTypes.func,
	phoneNumber: PropTypes.string
};

export default PersonForm;
