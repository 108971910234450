import React from 'react';
import PropTypes from 'prop-types';
import { createCheckPropsFunction } from 'helpers';
import { withTranslation } from 'react-i18next';
import OptionTypesTreeModal from './OptionTreeTypeMultiSelect/OptionTypesTreeModal';

@withTranslation()
class OptionsTreeContainer extends React.Component {
	
	constructor (props) {
		super(props);
		const { tree } = OptionsTreeContainer.getNormalizedOptions(props);
		
		this.state = {
			tree,
			currentNode: tree,
		};
		
		this.onSelect = this.onSelect.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}
	
	componentDidUpdate (prevProps) {
		const isPropChanged = createCheckPropsFunction(prevProps, this.props);
		
		if (isPropChanged('options')) {
			const { tree } = OptionsTreeContainer.getNormalizedOptions(this.props);
			
			this.setState({ tree, currentNode: tree });
		}
		
		if (prevProps.isOpen && !this.props.isOpen) {
			this.setState(state => ({ currentNode: state.tree }));
		}
		
	}
	
	static getNormalizedOptions (props) {
		const { options, childrenField } = props;
		
		const normalizedTree = { ...options, parent: null, isRoot: true };
		
		const normalizeArray = (array, parent) => {
			if (!Array.isArray(array) || array.length === 0) return null;
			
			return array.map((node, key) => {
				
				let buildPath = function (parent) {
					for (key in parent) {
						if (key == 'name')
							if (parent.path == undefined) return parent[key];
							else return parent.path + '/ ' + parent[key];
					}
				};
				node['path'] = buildPath(parent);
				node['key'] = node.id;
				
				const normalizedNode = { ...node, parent };
				normalizedNode[childrenField] = normalizeArray(node[childrenField], normalizedNode);
				
				return normalizedNode;
			});
		};
		
		normalizedTree[childrenField] = normalizeArray(normalizedTree[childrenField], normalizedTree);
		
		return { tree: normalizedTree };
	}
	
	onSelect (options) {
		const { valueField, labelField } = this.props;
		
		let normalizeOptions = options.map(option => {
			return {
				id: option.id,
				key: option.key,
				value: option[valueField],
				label: option[labelField],
				name: option[labelField],
				path: option.path
			};
		});
		
		return this.props.onSelect(normalizeOptions);
	};
	
	closeModal () {
		return this.props.onClose();
	}
	
	render () {
		const { isOpen, valueField, keySelectField, labelField, leafField, childrenField, t, title = t('appeal.type') } = this.props;
		const { currentNode } = this.state;
		if (!isOpen) return null;
		
		const options = currentNode.children || [];
		
		return (
			<div className={'options-container'}>
				<OptionTypesTreeModal
					nodeArray={options}
					valueField={valueField}
					keySelectField={keySelectField}
					labelField={labelField}
					leafField={leafField}
					childrenField={childrenField}
					initialValue={this.props.selectedOptions}
					onClose={this.closeModal}
					onSubmit={this.onSelect}
					multi={true}
					title={title}
				/>
			</div>
		);
	}
}

OptionsTreeContainer.defaultProps = {
	options: {},
	selectedOptions: [],
	valueField: 'id',
	labelField: 'name',
	leafField: 'leaf',
	childrenField: 'children'
};

OptionsTreeContainer.propTypes = {
	options: PropTypes.object,
	valueField: PropTypes.string,
	labelField: PropTypes.string,
	leafField: PropTypes.string,
	childrenField: PropTypes.string,
	
	isOpen: PropTypes.bool,
	containerRef: PropTypes.object,
	selectedOptions: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		label: PropTypes.string
	})),
	onSelect: PropTypes.func,
	onRemove: PropTypes.func
};

export default OptionsTreeContainer;
