import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/notification.module.scss';

const SuccessNote = ({ message }) => (
	<div className={styles.successNote}>
		<i className='icon-check-circle' />
		<span>{message}</span>
	</div>
);

SuccessNote.defaultProps = {
	message: 'Успішно'
};

SuccessNote.propTypes = {
	message: PropTypes.string
};

export default SuccessNote;
