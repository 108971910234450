import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import ReactDatePicker from 'react-datepicker';

import ClickOutsideHolder from '../ClickOutsideHolder';

import CustomInput from './CustomInput';
import ErrorField from './ErrorField';
import { KEY_CODES } from './helpers';

import 'react-datepicker/dist/react-datepicker.css';

class CustomDatePicker extends React.Component {
    state = {
        showTimeSelect: false,
        // this state is needed to prevent calendar from reopening during scroll, once Calendar
        // was actually closed by the user
        // wasClosedByUser: false,
    };

    componentDidUpdate = (prevProps, prevState) => {
        // const isOpen = this.calendar && this.calendar.state && this.calendar.state.open;

        // if (isOpen && this.state.wasClosedByUser) {
        //     this.setState({ wasClosedByUser: false} );
        // }

        if (!prevState.showTimeSelect && this.state.showTimeSelect) {
            this.calendar.setOpen(true);
        } else if (prevState.showTimeSelect && !this.state.showTimeSelect) {
            this.calendar.setOpen(false);
            this.calendar.cancelFocusInput();
            // this.setState({ wasClosedByUser: true });
        }
        // // isCalendarVisible was true, but now false => probably scrolled => close Calendar
        // if (prevProps && prevProps.isCalendarVisible && !this.props.isCalendarVisible) {
        //     this.calendar.setOpen(false);
        // }
        // // isCalendarVisible was false, but now true => probably scrolled back => open Calendar if it was not closed by the user yet
        // if (prevProps && !prevProps.isCalendarVisible && this.props.isCalendarVisible && !this.state.wasClosedByUser) {
        //     this.calendar.setOpen(true);
        // }
    };

    getCustomInput = () => {
        const { isClearable } = this.props;
        return <CustomInput onChangeRaw={this.handleChangeRaw} isClearable={isClearable} />;
    };

    getTimeCaption = () => {
        const { locale } = this.props;

        switch (locale) {
            case 'ru':
                return 'Время';
            case 'uk':
                return 'Час';
            case 'en':
                return 'Time';
            default:
                return 'UNKNOWN';
        }
    };

    handleChange = (value) => {
        const { hasTimePicker, input, label, field, handleChangeValue } = this.props;
        const { name, onChange } = input;
        const saveFormat = this.valueToSaveFormat(value);
        if (!saveFormat) return;
        onChange(saveFormat);

        if (hasTimePicker) return this.setState({ showTimeSelect: !this.state.showTimeSelect });
        if (!saveFormat || !handleChangeValue) return;
        handleChangeValue(name, field, saveFormat, label);
    };

    handleChangeRaw = (value) => this.props.input.onChange(value);

    onClickOutside = (e) => {
        const fieldKey = this.props.input.name;
        let isDatePickerModalParent = false;
        const path = e ? e.path || (e.composedPath && e.composedPath()) : [];
        for (let i = 0; i < path.length; i++) {
            if (path[i].getAttribute && path[i].getAttribute('data-field-key')) {
                isDatePickerModalParent = path[i].getAttribute('data-field-key') === fieldKey;
                break;
            }
        }
        if (isDatePickerModalParent) {
            return;
        }
        this.setState({ showTimeSelect: false });
        if (this.calendar) {
            this.calendar.setOpen(false);
            this.calendar.cancelFocusInput();
            // this.setState({ wasClosedByUser: true });
        }
    };

    onKeyDown = ({ keyCode }) => {
        if (keyCode === KEY_CODES.ESC) {
            this.onClickOutside();
        }
    };

    handleBlur = (ev) => {
        this.onClickOutside();
        const value = ev && ev.target && ev.target.value;
        const { error } = this.props.meta;
        // console.log({ value });
        if (!value) return;
        if (error) return;
        const saveFormat = this.eventValueToSaveFormat(value);
        // console.log({ saveFormat });
        this.props.input.onBlur(saveFormat || value);
    };

    valueToSaveFormat = (value) => value && value.isValid() && value.format(this.props.saveFormat);

    eventValueToSaveFormat = (value) => {
        const newValue = moment(value, this.props.viewFormat);
        return this.valueToSaveFormat(newValue);
    };

    render() {
        const {
            saveFormat,
            viewFormat,
            placeholder,
            label,
            meta,
            input,
            isClearable,
            isCalendarVisible = true,
            ...rest
        } = this.props;
        const { value, ...restInput } = input;
        const elementCls = cx('input-element datepicker', {
            disabled: this.props.disabled,
            'input-element__error': meta && meta.touched && meta.error,
        });

        let selected;
        let inputValue;
        if (value) {
            inputValue = value;
            // the third parameter means that the format and input match exactly
            const momentValue = moment(value, saveFormat, true);
            if (momentValue.isValid()) {
                selected = momentValue;
                inputValue = selected.format(viewFormat);
            }
        }

        // console.log({props: this.props});

        const popperClassName = isCalendarVisible ? "visible" : "hidden";

        return (
            <ClickOutsideHolder onClickOutside={this.onClickOutside}>
                <div className={elementCls}>
                    {label && (
                        <div className="input-label">
                            {label}
                            {rest.required && <span className="required-field">*</span>}
                        </div>
                    )}
                    <ReactDatePicker
                        value={inputValue}
                        selected={selected}
                        className="input-field"
                        showTimeSelect={this.state.showTimeSelect}
                        showTimeSelectOnly={this.state.showTimeSelect}
                        popperClassName={`datepicker-popper datepicker-portal ${popperClassName}`}
                        popperPlacement="bottom-end"
                        calendarClassName="calendar"
                        dateFormat={viewFormat}
                        timeCaption={this.getTimeCaption()}
                        timeFormat="HH.mm"
                        placeholderText={placeholder}
                        customInput={this.getCustomInput()}
                        {...restInput}
                        {...rest}
                        isClearable={false}
                        onKeyDown={this.onKeyDown}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        autoComplete="off"
                        ref={(node) => (this.calendar = node)}
                    />
                    <ErrorField meta={meta} />
                </div>
            </ClickOutsideHolder>
        );
    }
}

CustomDatePicker.displayName = 'CustomDatePicker';

CustomDatePicker.propTypes = {
    locale: PropTypes.string,
    saveFormat: PropTypes.string,
    viewFormat: PropTypes.string,
    hasTimePicker: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    isClearable: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
    locale: 'en',
    saveFormat: 'X', // Unix Timestamp
    viewFormat: 'DD.MM.YYYY HH:mm', // 31.12.2018 24:60
    hasTimePicker: true,
    input: null,
    meta: null,
    label: '',
    isClearable: true,
};

export default CustomDatePicker;
