import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import styles from 'styles/modules/knowledgeBase.module.scss';
import MessageModal from '../../MessageModal';
import ModalPortal from '../../ModalPortal';
import BaseService from '../../../services/BaseService';
import { setHistoryPushRequireSaveWithoutChangeConfirm } from "actions/ui";


@connect(null, { setHistoryPushRequireSaveWithoutChangeConfirm })
class HeaderMenu extends PureComponent {
	constructor (props) {
		super(props);
		this.state = {
			opened: false,
			confirmIsOpen: false,
		};
		this.closePopup = this.closePopup.bind(this);
		this.togglePopup = this.togglePopup.bind(this);
		this.closeConfirm = this.closeConfirm.bind(this);
		this.toggleConfirm = this.toggleConfirm.bind(this);
		this.deleteModalOpen = this.deleteModalOpen.bind(this);
	}
	
	closePopup () {
		this.setState({ opened: false });
	}
	
	togglePopup () {
		this.setState(state => ({ opened: !state.opened }));
	}
	
	closeConfirm () {
		this.setState({ confirmIsOpen: false });
	}
	
	toggleConfirm () {
		this.closePopup();
		this.setState({ confirmIsOpen: !this.state.confirmIsOpen });
	}
	
	async deleteModalOpen () {
		const { id, type } = this.props.match.params;
		const response = await BaseService.delete('get_service_catalogue_detail', {
			pathParams: { id },
			data: { isFolder: type, type: 'SERVICE' },
			jsonType: true,
		});
		
		this.closePopup();
		this.closeConfirm();
		
		if (response.success) {
			this.props.setHistoryPushRequireSaveWithoutChangeConfirm(false);
			this.props.history.push(`/specification/${this.props.selectedNode.parentId || this.props.parentFolderId}/1`);

			await this.props.requestData();
		}
	}
	
	render () {
		const { t, match, canEdit } = this.props;
		return (
			<div className={styles.headerMenu}>
				<div className={styles.headerMenuButtonWrapper}>
					{
						(parseInt(match.params.id) && canEdit) ? (
							<button className={styles.headerMenuButton} onClick={this.togglePopup}>
								<i className='icon-kebab-vert' />
							</button>
						) : null
					}
				</div>
				{(this.state.opened && canEdit) && (
					<Popup
						onClickOutside={this.closePopup}
						place={styles.headerPopup}
					>
						<div onClick={this.toggleConfirm} className='popup-link'>{t(`knowledgeMenu.Remove`)}</div>
					</Popup>
				)}
				{
					this.state.confirmIsOpen ? (
						<ModalPortal
							onClose={this.closeConfirm}
							className='modal-small'
						>
							<MessageModal
								titleModal={t('filters.titleRemoveModal')}
								contentModalText={t('knowledgeBase.titleRemoveFilter')}
								dangerButton={true}
								dangerButtonText={t('filters.remove')}
								onClickDangerButton={this.deleteModalOpen}
								secondaryButton={true}
								secondaryButtonText={t('filters.cancel')}
								onClickSecondaryButton={this.closeConfirm}
							/>
						</ModalPortal>
					) : null
				}
			</div>
		);
	};
}

HeaderMenu.propTypes = {
	options: PropTypes.array,
	t: PropTypes.object,
};

export default HeaderMenu;
