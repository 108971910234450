import React from 'react';
import PropTypes from 'prop-types';

export default function SoundTitle ({ children, ...props }) {
	return (
		<div
			className='soundTitle'
			draggable={false}
			{...props}
		>
			{children}
		</div>
	);
}

SoundTitle.propTypes = {
	children: PropTypes.any
};
