import React from 'react';
import MessageModal from 'components/MessageModal/index';
import CheckBoxToggle from 'components/Common/CheckBoxToggle';
import { Field } from 'redux-form';
import { Input } from 'ui-core-dashboard';
import styles from 'styles/modules/filters.module.scss';
import cx from "classnames";
import moment from "moment";


export function getInitialFilterValues (props, activeFilter, filters) {
	const { newFilter, filterAttrsResults = [], infoFilterParams, checkedFilterAttrs } = props;
	// const currentFilter = activeFilter && filters.find(filter => +filter.value === +activeFilter);
	let currentFilter;
	// search for current filter in filters and subfilters levels
    if (filters && activeFilter) {
        filters.forEach(filter => {
            if (+filter.value === +activeFilter) {
                currentFilter = filter;
                return;
            }
            // check active filters in subfilters
            if (filter.filters) {
                filter.filters.forEach(subFilter => {
                    if (+subFilter.value === +activeFilter) {
                        currentFilter = subFilter;
                    }
                });
            }
        });
    }

	function nameFilter () {
		if (newFilter) {
			let count = Object.values(filters).length + 1;
			return `Фiльтр ${count + 1}`;
		}
		return currentFilter ? currentFilter.name : '';
	}
	
	function setInitialValues () {
		if (newFilter) {
			return {
				filterName: nameFilter(),
				isPublic: false,
				predefined: false
			};
		}
		
		const paramsIdArr = infoFilterParams && infoFilterParams.map(el => el.paramId);
		const checkParams = Object.keys(checkedFilterAttrs).filter(el => checkedFilterAttrs[el]);
		
		let paramsNameArray = [];
		
		if (checkParams.length) {
			paramsNameArray = filterAttrsResults.filter(el => paramsIdArr.includes(el.id) && checkParams.includes(el.code));
		} else {
			paramsNameArray = filterAttrsResults.filter(el => paramsIdArr.includes(el.id));
		}
		
		const initObj = {};
		
		paramsNameArray.forEach(field => {
			const fieldName = field.code;
			if (field.type === 'THREE') {
				const options = buildThreePath(field.dict, false, { children: 'children' });
				if (infoFilterParams.length && rootAppealType) {
					const currentAppealType = [];
					infoFilterParams.map((i) => {
						
						const item = searchTree(options, 'children', result => {
							return i.value === result.key;
						});
						
						if (item) {
							currentAppealType.push({
								value: item.key,
								label: item.name,
								path: item.path,
							});
						}
					});
					
					if (currentAppealType) {
						initObj[fieldName] = currentAppealType;
					}
				}
			} else if (field.type === 'DATE') {
				const foundValues = infoFilterParams.filter(elem => elem.paramId === field.id);
				
				if (field.def_condition === 'EQU' && foundValues.length > 0) {
					initObj[fieldName] = foundValues[0].value;
					
				} else if (field.def_condition === 'BTW' && foundValues.length > 0) {
					
					foundValues.forEach(date => {
						const ending = date.conditionCode === 'LEQ' ? '@TO' : '@FROM';
						initObj[`${fieldName}${ending}`] = date.value;
					});
				}
				
			} else if ((field.type === 'LIST') && (field.multiset_allowed === 'Y')) {
				const listValue = infoFilterParams.filter(elem => elem.paramId === field.id);
				initObj[fieldName] = listValue.map(el => el.value);
				if (field.search && !field.dict_search) {
					initObj[fieldName] = listValue.map(el => {
							const option = field.dict.find((item) => {
								return el.value === item.key;
							});
							return {
								value: option ? option.key : null,
								label: option ? option.value : '',
							};
						}
					);
				}
				
				if (field.dict_search) {
					initObj[fieldName] = listValue.map(el => {
						return {
							value: el.paramId,
							label: el.label,
						};
					});
				}
			} else {
				initObj[fieldName] = infoFilterParams.filter(elem => elem.paramId === field.id)[0].value;
			}
		});
		
		return {
			filterName: nameFilter(),
			isPublic: currentFilter ? !currentFilter.custom : true,
			...initObj
		};
	}
	
	return setInitialValues();
}

export	function renderModalBody (t, type, data, onClickDelete, onClickSave, closeMessageModal) {
	switch (type) {
		case 'savePredefined': {
			return (
				<MessageModal
					titleModal={t('filters.alreadyExisting')}
					contentModalText={t('filters.predefinedExisting')}
				/>)
		}
		case 'saveUser': {
			return (
				<MessageModal
					titleModal={t('filters.alreadyExisting')}
					contentModalText={t('filters.saveModalQuestion')}
					primaryButton={true}
					primaryButtonText={t('filters.save')}
					onClickPrimaryButton={() => onClickSave(data)}
					secondaryButton={true}
					secondaryButtonText={t('filters.cancel')}
					onClickSecondaryButton={closeMessageModal}
				/>)
		}
		case 'delete': {
			return (
				<MessageModal
					titleModal={t('filters.titleRemoveModal')}
					contentModalText={t('filters.titleRemoveFilter')}
					dangerButton={true}
					dangerButtonText={t('filters.remove')}
					onClickDangerButton={onClickDelete}
					secondaryButton={true}
					secondaryButtonText={t('filters.cancel')}
					onClickSecondaryButton={closeMessageModal}
				/>)
		}
		default: {
			return (
				<MessageModal
					titleModal={t('filters.emptyTemplate')}
					contentModalText={t('filters.emptyTemplate')}
				/>)
		}
	}
}

export function renderDefaultFields (filter, predefined, isCanCreatePublicFilter){
	const disabled = !filter.newFilter ? true : (predefined && !filter.newFilter);
	return [
		<Field
			component={Input}
			name='filterName'
			label='Назва фільтру'
			disabled={disabled}
			maxLength={200}
		/>,
		isCanCreatePublicFilter &&
		<Field
			component={CheckBoxToggle}
			name='isPublic'
			label='Загальний для всіх'
			disabled={disabled}
		/>
	];
}

export function renderDateField (t, fieldConfig, fieldProps, formValues, reduxChange) {
	if (fieldConfig.def_condition === 'BTW') {
		let fromDateAdditionalProps = {};
		let toDateAdditionalProps = {};
		if (formValues) {
			const fromName = `${fieldConfig.code}@FROM`;
			const fromValueInitial = formValues && formValues[fromName];
			let fromValue = fromValueInitial;
			if (fromValue && !fromValue.isValid) {
				// convert fromValue to the moment object (probably initialized from filters);
				fromValue = moment(fromValueInitial);
			}
			const toName = `${fieldConfig.code}@TO`;
			const toValueInitial = formValues && formValues[toName];
			let toValue = toValueInitial;
			if (toValueInitial && !toValueInitial.isValid) {
				// convert toValue to the moment object (probably initialized from filters);
				toValue = moment(toValueInitial);
			}
	
			const isSameDay = fromValue && toValue && fromValue.isSame(toValue, 'day');
			const fromTime = isSameDay && fromValue;
			const toTime = isSameDay && toValue;
	
			const isMaxTimeBigger = isSameDay && toTime.diff(fromTime);
			if (isMaxTimeBigger < 0 && reduxChange && typeof reduxChange === 'function') {
				reduxChange(toName, fromTime);
			}

			fromDateAdditionalProps = {
				maxDate: toValue,
				maxTime: toTime,
				minTime: isSameDay && fromValue.clone().startOf('day')
			};
			toDateAdditionalProps = {
				minDate: fromValue,
				minTime: fromTime,
				maxTime: isSameDay && toValue.clone().endOf('day')
			};
		}
		const fromDateProps = {
			...fromDateAdditionalProps,
			...fieldProps,
			name: `${fieldConfig.code}@FROM`,
			label: `${fieldProps.label} ${t('filters.from')}:`,
			key: `${fieldConfig.id}@FROM`,
		};
		const toDateProps = {
			...toDateAdditionalProps,
			...fieldProps,
			name: `${fieldConfig.code}@TO`,
			label: `${fieldProps.label} ${t('filters.to')}:`,
			key: `${fieldConfig.id}@TO`,
		};
		
		return (
			<div key={fieldConfig.name} className={cx(styles.wrapElements,'date-picker-from-to-wrapper')}>
				<Field {...fromDateProps} />
				<Field {...toDateProps} />
			</div>
		);
	}
	return <Field {...fieldProps} />;
}
