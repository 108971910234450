import {
	KNOWLEDGE_BASE_ITEM_REQUEST,
	KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET,
	KNOWLEDGE_BASE_ITEM_REQUEST_SUCCESS,
	KNOWLEDGE_BASE_ITEM_REQUEST_FAILURE,
	SET_BREADCRUMBS,
	KNOWLEDGE_BASE_RESET,
	KNOWLEDGE_BASE_REQUEST,
	KNOWLEDGE_BASE_REQUEST_SUCCESS,
	KNOWLEDGE_BASE_REQUEST_FAILURE,
	KNOWLEDGE_BASE_SEARCH_QUERY_UPDATE,
	KNOWLEDGE_BASE_SEARCH_MODE_ENABLE,
	KNOWLEDGE_BASE_SEARCH_MODE_DISABLE,
	KNOWLEDGE_BASE_ITEM_CLEAR
} from 'constants/actions';
import baseService from 'services/BaseService';

/********  Knowledge base request  ********/

const requestKnowledgeBase = appealId => ({
	type: KNOWLEDGE_BASE_REQUEST,
	appealId
});

const requestKnowledgeBaseSuccess = knowledgeBase => ({
	type: KNOWLEDGE_BASE_REQUEST_SUCCESS,
	knowledgeBase
});

const requestKnowledgeBaseFailure = () => ({
	type: KNOWLEDGE_BASE_REQUEST_FAILURE
});

export const getKnowledgeBase = (requestData, appealId) => dispatch => {
	dispatch(requestKnowledgeBase(appealId));
	
	const params = { data: requestData };
	
	return baseService.get('knowledge_base', params)
		.then(response => {
			if (response.success && response.result) {
				dispatch(requestKnowledgeBaseSuccess(response.result));
			} else {
				dispatch(requestKnowledgeBaseFailure());
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(requestKnowledgeBaseFailure());
		});
};

/********  Knowledge base search query update  ********/

export const updateSearchQuery = newQuery => ({
	type: KNOWLEDGE_BASE_SEARCH_QUERY_UPDATE,
	query: newQuery
});

/********  Knowledge base search mode update  ********/

export const enableSearchMode = () => ({
	type: KNOWLEDGE_BASE_SEARCH_MODE_ENABLE
});

export const disableSearchMode = () => ({
	type: KNOWLEDGE_BASE_SEARCH_MODE_DISABLE
});

/********  Knowledge base item request  ********/

const requestKnowledgeBaseItemSuccess = selectedItem => ({
	type: KNOWLEDGE_BASE_ITEM_REQUEST_SUCCESS,
	selectedItem
});

const requestKnowledgeBaseItemFailure = () => ({
	type: KNOWLEDGE_BASE_ITEM_REQUEST_FAILURE
});

const requestKnowledgeBaseItem = () => ({
	type: KNOWLEDGE_BASE_ITEM_REQUEST
});

export const getKnowledgeBaseItem = requestData => dispatch => {
	const params = { data: requestData };
	
	dispatch(requestKnowledgeBaseItem());
	
	return baseService.get('kb_detail', params)
		.then(response => {
			if (response.success && response.result && response.result.length > 0) {
				dispatch(getKnowledgeItemDynParams(response.result[0]));
			} else {
				dispatch(requestKnowledgeBaseItemFailure());
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(requestKnowledgeBaseItemFailure());
		});
};

export const getKnowledgeItemDynParams = requestData => dispatch => {
	dispatch(requestKnowledgeBaseItemSuccess(requestData));
	const data = {
		ctxObjectType: 'KNOWLEDGE_BASE',
		ctxObjectId: requestData.id || '',
		objectType: 'appeal',
		objectTypeId: requestData.requestTypeId,
		objectId: requestData.id,
		objectTypeCode: requestData.requestTypeCode,
		openMode: 'read',
		curStepNum: 1,
	};
	
	const params = { data };
	
	if (requestData.requestTypeId && requestData.requestTypeCode){
		return baseService.get('appeal_form_params', params)
			.then(response => {
				
				if (response.success && response.result.blocks) {
					return dispatch({
						type: KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET,
						dynParams: response.result.blocks
					});
				} else {
					dispatch({
						type: KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET,
						dynParams: null
					});
					throw new Error('Knowledge base item response is empty!');
				}
			})
			.catch(error => {
				console.error(error);
				dispatch(requestKnowledgeBaseFailure());
			});
	}
	else {
		return dispatch({
			type: KNOWLEDGE_BASE_ITEM_DYNPARAMS_SET,
			dynParams: null
		});
	}
};

export const clearKnowledgeBaseItem = () => ({
	type: KNOWLEDGE_BASE_ITEM_CLEAR
});

/************************************/

export const setBreadcrumbs = newBreadcrumbs => ({
	type: SET_BREADCRUMBS,
	breadcrumbs: newBreadcrumbs
});

export const resetKnowledgeBase = () => ({
	type: KNOWLEDGE_BASE_RESET
});
