import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'components/Common/DatePicker';
import moment from 'moment';
import get from 'lodash/get';
import cx from 'classnames';

class CompactDatePickerInput extends PureComponent {
	
	state = {
		isOpened: false
	};
	
	componentDidMount () {
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	componentWillUnmount () {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	
	// Arrow control methods:
	
	openDropdown = () => this.setState(({ isOpened: true }));
	
	closeDropdown = () => this.setState(({ isOpened: false }));
	
	toggleDropdown = () => this.setState(state => ({ isOpened: !state.isOpened }));
	
	// Event handlers:
	
	handleFocus = (event) => {
		this.openDropdown();
		this.props.onFocus && this.props.onFocus(event);
	};
	
	handleBlur = (event) => {
		this.closeDropdown();
		this.props.onBlur && this.props.onBlur(event);
	};
	
	handleClickOutside = (event) => {
		if (this.element && !this.element.current.contains(event.target)) {
			this.closeDropdown();
		}
	};
	
	handleSelectOption = (value) => {
		this.closeDropdown();
		this.props.input.onChange && this.props.input.onChange(value);
	};
	
	preventEvent = (event) => {
		event.preventDefault();
		this.toggleDropdown();
	};
	
	arrowRender = isOpen => isOpen ? <i className='icon-up' /> : <i className='icon-down' />;
	
	render = () => {
		const { label, input, placeholder = 'Не вибрано', value, require, disabledKeyDown, showErrors, ...restProps } = this.props;
		const { isOpened } = this.state;
		const meta = get(restProps, 'meta');
		const className = get(restProps, 'className', '').replace(/compactDatePickerInput/i, '');
		const showError = showErrors && meta && (meta.error || meta.warning);
		return (
			<div className={cx('compactSelectWrapper', {showError}, className)} ref={this.element}>
				<div className='compactSelectLabel'>
					{label}{require && <span className='requiredField'>*</span>}
				</div>
				
				<div className='compactSelectBox'>
					{disabledKeyDown ?
						<label
							{...restProps}
							className='compactDatePickerInput'
						>
							{typeof value === 'number' ? moment.unix(value).format('DD.MM.YYYY HH:mm') : value}
						</label>:
					<input
						className='compactDatePickerInput'
						placeholder={placeholder}
						{...restProps}
						value={typeof value === 'number' ? moment.unix(value).format('DD.MM.YYYY HH:mm') : value}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
					/>}
					<div className='compactSelectArrow'>{this.arrowRender(isOpened)}</div>
				</div>
				{showError && <div className='error-date-range error-text error-hint'>{meta.error}</div>}
			
			</div>
		);
	};
}

CompactDatePickerInput.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	input: PropTypes.object,
	value: PropTypes.any,
	require: PropTypes.bool
};

export default ({ label, placeholder, required, ...rest }) => (
	<DatePicker
		{...rest}
		dayFormat='DD.MM.YYYY'
		dayAndTimeFormat='DD.MM.YYYY HH:mm'
		customInput={<CompactDatePickerInput label={label} placeholder={placeholder} require={required} {...rest} />}
		inputClass={'compactDatePickerInput'}
	/>
);
