import React, { useEffect, useState } from "react";
import ReactSVG from "react-svg";
import { withTranslation } from "react-i18next";
import moment from "moment/moment";
import cx from "classnames";
import baseService from "../../../services/BaseService";
import Loader from "../../Loader";
import QRCode from "react-qr-code";
import { ModalWindow } from "ui-core-dashboard";
import MessageModal from "../../MessageModal";

const USER_BOT = "user";

function TelegramDevices(props) {
    const { t } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isBotLoading, setIsBotLoading] = useState({});

    const [bots, setBots] = useState([]);
    const [qrCode, setQrCode] = useState({});

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        itemId: null,
        itemName: "",
        message: t("deleteTelegramDeviceConfirm"),
        primaryButton: t("remove"),
        secondaryButton: t("cancel"),
    });

    function fetchBots(type) {
        setIsLoading(true);
        return baseService
            .get("bots", { pathParams: { type } })
            .then(({ result, success }) => {
                setIsLoading(false);
                if (success) {
                    setBots(result);
                }
                // console.log({ result });
            })
            .catch(e => {
                setIsLoading(false);
                console.error("TelegramDevices::fetchBot: Error during request: ", e);
            });
    }

    function onQrCodeClose(botName) {
        setQrCode(prev => ({ ...prev, [botName]: "" }));
        fetchBots(USER_BOT);
    }

    function handleAddDevice(botName) {
        // Get /mw/v2/bot/telegram/url/<botName>
        setIsBotLoading(prev => ({ ...prev, [botName]: true }));
        return baseService
            .get("addBot", { pathParams: { botName } })
            .then(({ result, success }) => {
                setIsBotLoading(prev => ({ ...prev, [botName]: false }));
                if (success) {
                    if (result && result.url) {
                        setQrCode(prev => ({ ...prev, [botName]: result.url }));
                    }
                }
            })
            .catch(e => {
                setIsBotLoading(prev => ({ ...prev, [botName]: false }));
                console.error("TelegramDevices::handleAddDevice: Error during request: ", e);
            });
    }

    function closeConfirmModal(e) {
        if (e) {
            console.log({ e });
        }
        setConfirmModal(prev => ({
            ...prev,
            open: false,
            itemId: null,
            itemName: "",
            botName: "",
        }));
    }

    function openConfirmModal(botName, itemId, itemName) {
        setConfirmModal(prev => ({
            ...prev,
            open: true,
            botName,
            itemId,
            itemName,
        }));
    }

    function handleRemoveDevice(botName, id) {
        closeConfirmModal();
        setIsBotLoading(prev => ({ ...prev, [botName]: true }));
        return baseService
            .delete("deleteBot", { pathParams: { botName, id } })
            .then(({ success }) => {
                setIsBotLoading(prev => ({ ...prev, [botName]: false }));
                if (success) {
                    fetchBots(USER_BOT);
                }
            })
            .catch(e => {
                setIsBotLoading(prev => ({ ...prev, [botName]: false }));
                console.error("TelegramDevices::handleRemoveDevice: Error during request: ", e);
            });
    }

    function renderDevice(device, index, botName) {
        const { id, name, createDate } = device;
        return (
            <div
                className="telegram-device"
                title={`${name} ${moment(createDate).format("DD.MM.YY HH:mm:ss")}`}
                key={id}
            >
                <div className="telegram-device__name">
                    {name} <span>({moment(createDate).format("DD.MM.YY HH:mm:ss")})</span>
                </div>
                <div className="telegram-device__delete" onClick={() => openConfirmModal(botName, id, name)}>
                    <ReactSVG className="svg-delete" src="/data/svg/delete-can.svg" />
                </div>
            </div>
        );
    }

    function renderBot(item) {
        const { bot, devices, added, prov } = item;
        return (
            <div className="telegram-bot" key={bot}>
                {(isLoading || isBotLoading[bot]) && <Loader loaderContainerClass="device-loader-overlay" />}
                <div className="telegram-bot__header">
                    <div className="telegram-bot__header-name">{bot}</div>
                    {added && (
                        <button
                            type="button"
                            disabled={qrCode[bot]}
                            className={cx("btn-save telegram-bot__header-add", { disabled: qrCode[bot] })}
                            onClick={() => handleAddDevice(bot)}
                        >
                            +{t("add")}
                        </button>
                    )}
                </div>
                {qrCode[bot] && (
                    <div className="telegram-bot__qr-code">
                        <button
                            type="button"
                            className="btn-save telegram-bot__qr-code-close"
                            onClick={() => onQrCodeClose(bot)}
                        >
                            <i className="icon-close" />
                        </button>
                        <a target="_blank" href={qrCode[bot]}>
                            <QRCode value={qrCode[bot]} />
                        </a>
                    </div>
                )}
                <div className="telegram-bot__devices">
                    {devices && devices.length > 0 && devices.map((item, index) => renderDevice(item, index, bot))}
                </div>
            </div>
        );
    }

    useEffect(() => {
        fetchBots(USER_BOT);
    }, []);

    return (
        <>
            <div className="telegram-devices">
                {/* initial loader, next ones displayed in bots containers */}
                {isLoading && bots.length === 0 && <Loader loaderContainerClass="bot-loader-overlay" />}
                {bots && bots.length > 0 && bots.map(renderBot)}
                {/* {qrCode && <div className="qr-code">{qrCode}</div>} */}
                {confirmModal.open && (
                    <ModalWindow
                        onClose={closeConfirmModal}
                        className="modal-small ordering-component-ui-core-wrapper ignore-clickoutside"
                    >
                        <MessageModal
                            titleModal={t("deleteConfirm")}
                            contentModalText={`${t(confirmModal.message)}: ${confirmModal.itemName}?`}
                            dangerButton
                            dangerButtonText={t(confirmModal.primaryButton)}
                            onClickDangerButton={() => handleRemoveDevice(confirmModal.botName, confirmModal.itemId)}
                            secondaryButton
                            secondaryButtonText={t(confirmModal.secondaryButton)}
                            onClickSecondaryButton={closeConfirmModal}
                        />
                    </ModalWindow>
                )}
            </div>
        </>
    );
}

export default withTranslation()(TelegramDevices);
