import React, { useState, useEffect, Fragment } from 'react';
import { getFormSyncErrors, submit, getFormValues } from 'redux-form';
import { withRouter, matchPath } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { CustomerTypes, StreetModes } from 'constants/index';
import styles from 'styles/modules/createNewCustomer.module.scss';
import ObjectForm from '../../ObjectForm';
import ComboBox from 'components/Common/ComboBox';
import PersonForm from './PersonForm/index';
import OrganizationForm from './OrganizationForm/index';
import AddressForm from './AddressForm/index';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import baseService from 'services/BaseService';
import { closeCreateNewCustomerModal } from 'actions/ui';
import { getCustomer, getContactPerson } from 'actions/customer';
import { unlockAppealForm, addCustomerToAppeal, getAppealFeedback } from 'modules/appeal/actions';
import { isOrganization } from 'helpers';
import Loader from 'components/Loader/index';

function CustomerFormManager (props) {
	const [customerType, setCustomerType] = useState();
	const [customerTypes, setCustomerTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	
	async function getCustomerTypes () {
		setIsLoading(true);
		const response = await baseService.get('customer_types');
		
		if (response.result) {
			const types = response.result.map(i => ({
				label: i.partyName,
				value: i.partyType,
				isDefault: i.isDefault
			}));
			
			await Promise.all([
				setCustomerTypes(types),
				setCustomerType(types.find((type) => type.isDefault).value),
				setIsLoading(false),
			]);
		}
	}
	
	useEffect(() => {
		getCustomerTypes();
	}, []);
	
	function handleChangeCustomerType (option) {
		option && setCustomerType(option.value);
	}
	
	function isValuesCorrect () {
		const { personErrors, organizationErrors, physicalAddressErrors, juridicalAddressErrors } = props;
		
		let errors = [personErrors, physicalAddressErrors];
		
		if (isOrganization(customerType)) {
			errors = [...errors, organizationErrors, juridicalAddressErrors];
		}
		
		for (const errorObject of errors) {
			if (isEmpty(errorObject)) continue;
			
			if (!Object.values(errorObject).every(isEmpty)) return false;
		}
		
		return true;
	}
	
	function onOrganizationSubmit () {
		const {
			submitForm, physicalAddressValues, personValues, juridicalAddressValues, organizationValues,
			match, newCustomerModalContext,
		} = props;
		const path = props.location.pathname;
		const splittedPath = path.split("/");
		const appealsIndex = splittedPath.findIndex(item => item ==='appeals');
		const appealId = splittedPath[appealsIndex + 1];
		// const appealId = _.get(match, 'params.appealId');

		// console.log({appealId});
		
		/** To highlight errors **/
		submitForm('person-form');
		submitForm('organization-form');
		submitForm('physical-address-form');
		submitForm('juridical-address-form');
		
		if (!isValuesCorrect()) return;
		
		setIsLoading(true);
		const contactsType = [];
		const contactsValue = [];
		if (personValues.phone) {
			contactsType.push('workPhone');
			contactsValue.push(personValues.phone);
		}
		if (personValues.email) {
			contactsType.push('email');
			contactsValue.push(personValues.email);
		}
		
		const params = {
			data: {
				party: {
					shortName: organizationValues.shortName,
					businessType: _.get(organizationValues, 'businessType.value'),
					ownershipType: _.get(organizationValues, 'ownershipType.value'),
					institutionId: organizationValues.institutionId,
					taxid: organizationValues.taxid,
				},
				contactPerson: {
					lastName: personValues.lastName,
					firstName: personValues.firstName,
					patronymic: personValues.patronymic,
					contactsType,
					contactsValue,
					issues: personValues.issues,
				},
				address: [
					createAddressObject(physicalAddressValues),
					createAddressObject(juridicalAddressValues, 'juridical'),
				]
			},
			jsonType: true,
		};
		
		return baseService.post('create_organization', params)
			.then(response => {
				if (response.success && response.result) {
					const { customerId, personId } = response.result;
					setIsLoading(false);
					props.onClose();
					
					if (_.get(newCustomerModalContext, 'openNewTab') === false) {
						props.addCustomerToAppeal(appealId, customerId, personId);
						props.getCustomer({ customerId: customerId }, customerId);
						props.getContactPerson({ id: personId }, customerId);
						props.getAppealFeedback({ appealId, customerId });
					}
					
					if (_.get(newCustomerModalContext, 'openNewTab') === true) {
						props.history.push(`/customer/${response.result.customerId}/appeals`);
					}
					
					return response.result;
				} else {
					setIsLoading(false);
					throw new Error('Natural person creation error!');
				}
			});
	}
	
	function onNaturalPersonSubmit () {
		const { submitForm, personValues, physicalAddressValues, newCustomerModalContext } = props;

		const path = props.location.pathname;
		const splittedPath = path.split("/");
		const appealsIndex = splittedPath.findIndex(item => item ==='appeals');
		const appealId = splittedPath[appealsIndex + 1];
		// const appealId = _.get(match, 'params.appealId');
		submitForm('person-form');
		submitForm('physical-address-form');
		
		if (!isValuesCorrect()) return;
		
		setIsLoading(true);

		const contactsType = [];
		const contactsValue = [];
		if (personValues.phone) {
			contactsType.push('homePhone');
			contactsValue.push(personValues.phone);
		}
		
		if (personValues.email) {
			contactsType.push('email');
			contactsValue.push(personValues.email);
		}
		
		const params = {
			data: {
				party: {
					lastName: personValues.lastName,
					firstName: personValues.firstName,
					patronymic: personValues.patronymic,
					contactsType,
					contactsValue,
				},
				address: [
					createAddressObject(physicalAddressValues),
				]
			},
			jsonType: true,
		};
		
		return baseService.post('create_individual_customer', params)
			.then(response => {
				if (response.success && response.result) {
					const { customerId } = response.result;
					setIsLoading(false);
					props.onClose();
					
					if (_.get(newCustomerModalContext, 'openNewTab') === false) {
						props.addCustomerToAppeal(appealId, customerId, 0);
						props.getCustomer({ customerId: customerId }, customerId);
						props.getAppealFeedback({ appealId, customerId });
						// props.unlockAppealForm(appealId);
					}
					
					if (_.get(newCustomerModalContext, 'openNewTab') === true) {
						props.history.push(`/customer/${response.result.customerId}/appeals`);
					}
					
					return response.result;
				} else {
					setIsLoading(false);
					throw new Error('Natural person creation error!');
				}
			});
	}
	
	function onSubmit () {
		switch (customerType) {
		case CustomerTypes.NATURAL_PERSON:
			return onNaturalPersonSubmit();
		case CustomerTypes.ORGANIZATION:
			return onOrganizationSubmit();
		}
	}
	
	function emptySubmit () {}
	
	const { t, enableWarning } = props;
	
	if (!customerType) return null;

	
	return (
		<div>
			{isLoading && <Loader />}
			
			<header className={styles.createCustomerHeader}>
				<div className={styles.createCustomerTitle}>{t('newCustomer')}</div>
			</header>
			
			<div className={styles.customerTypeZone}>
				<ComboBox
					options={customerTypes}
					label={t('customerType')}
					value={customerType}
					onChange={handleChangeCustomerType}
				/>
			</div>
			
			{
				customerType === 'serviceobject' ? (
					<ObjectForm t={t} setIsLoading={setIsLoading} enableWarning={enableWarning}
								onClose={props.onClose} />) : (
					<Fragment>
						<div className={styles.formContainer}>
							<PersonForm customerType={customerType} onSubmit={emptySubmit}
										enableWarning={enableWarning} />
							{
								isOrganization(customerType) &&
								<OrganizationForm customerType={customerType} onSubmit={emptySubmit}
												  enableWarning={enableWarning} />
							}
							<AddressForm
								customerType={customerType}
								onSubmit={emptySubmit}
								form={'physical-address-form'}
								addressType='physical'
								enableWarning={enableWarning}
								initialValues={{ mode: StreetModes.AUTO }}
							/>
							{
								isOrganization(customerType) &&
								<AddressForm
									customerType={customerType}
									onSubmit={emptySubmit}
									form='juridical-address-form'
									addressType='juridical'
									enableWarning={enableWarning}
									initialValues={{ mode: StreetModes.AUTO }}
								/>
							}
						</div>
						
						<footer className={styles.createCustomerFooter}>
							<button className='btn btn-primary' onClick={onSubmit}>
								<i className='icon icon-check' />
								{t('create')}
							</button>
						</footer>
					</Fragment>
				)
			}
		</div>
	);
}

function mapStateToProps (state) {
	const match = matchPath(window.location.hash.substring(1), {
		path: '/appeals/:appealId/:tab',
		exact: true,
		strict: false,
	});
	
	return {
		personErrors: getFormSyncErrors('person-form')(state),
		organizationErrors: getFormSyncErrors('organization-form')(state),
		physicalAddressErrors: getFormSyncErrors('physical-address-form')(state),
		juridicalAddressErrors: getFormSyncErrors('juridical-address-form')(state),
		personValues: getFormValues('person-form')(state),
		organizationValues: getFormValues('organization-form')(state),
		physicalAddressValues: getFormValues('physical-address-form')(state),
		juridicalAddressValues: getFormValues('juridical-address-form')(state),
		match,
		newCustomerModalContext: state.ui.newCustomerModalContext,
	};
}

const mapDispatchToProps = {
	submitForm: form => submit(form),
	onClose: closeCreateNewCustomerModal,
	getCustomer,
	getContactPerson,
	unlockAppealForm,
	addCustomerToAppeal,
	getAppealFeedback,
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerFormManager)));

function createAddressObject (value, type = 'physical') {
	return {
		'parentId': _.get(value, 'settlement.id'),
		'parentType': 'location',
		'streetId': _.get(value, 'street.value'),
		'name': _.get(value, 'street.label') || value.streetName,
		'streetTypeId': _.get(value, 'street.type') || _.get(value, 'streetType.value'),
		'addressType': type,
		'buildingNumber': _.get(value, 'buildingNumber.label') || value.buildingNumberInput,
		'apartmentNumber': value.apartmentNumber,
		'description': value.description,
		'zipCode': _.get(value, 'zipCode.label') || value.zipCodeInput,
		'buildingSecondNumber': value.buildingSecondNumber,
	};
}
