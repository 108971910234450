import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";


import store from './store';
import App from './App';
import './util/i18n';

import { LS_TAB_HISTORY_KEY } from 'constants/index';


import { getTabs } from "./reducers/tabs";


const rootEl = document.getElementById('root');

window.addEventListener('beforeunload', function (event) {
    try {
        const state = store.getState();
        const tabs = getTabs(state);
        if (tabs) {
            localStorage.setItem(LS_TAB_HISTORY_KEY, JSON.stringify(tabs));
        }
    } catch (e) {
        console.error("window::beforeunload: Error during setting tabs to LocalStorage: ", e);
    }
    event.stopImmediatePropagation();
});

ReactDOM.render(
	<Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
	</Provider>,
    rootEl,
);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        ReactDOM.render(
			<Provider store={store}>
				<NextApp />
			</Provider>,
            rootEl,
        );
    });
}
