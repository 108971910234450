import React, { useEffect, useState } from 'react';
import { reduxForm, Field, initialize, getFormValues } from "redux-form";
import { Input, ComboBox } from 'ui-core-dashboard';
import { connect } from "react-redux";
import { changeOpenedAccountModal, getUserPhones } from 'actions/user';
import { selectPhone } from 'actions/call';

import { withTranslation } from 'react-i18next';
import baseService from 'services/BaseService';
import { DIGITS, REQUIRED_FIELD } from "../../../util/form";
import cx from 'classnames';
import Loader from "../../Loader";

function mapStateToProps(state, ownProps) {
	const formName = ownProps.form;
	const { editPhoneId } = ownProps;
	const initialState = editPhoneId && state.user.userPhones.find(({id}) => id === editPhoneId);
	return {
		formValues: getFormValues(formName)(state),
		initialState,
	}
}

const mapDispatchToProps = {
 		changeOpenedAccountModal,
		getUserPhones,
		selectPhone
};

function AccountForm(props) {
	const { t, editPhoneId, form, formValues, initialState, initialize, userPhones } = props;
	const [options, setOptions] = useState([]);
	const [phoneTitle, setPhoneTitle] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	
	useEffect( () =>{
	 async function getOptions (){
		const resp = await baseService.get('get_call_location_list');
		
		if(resp.success && Array.isArray(resp.result)) {
			return setOptions(resp.result.map(item => ({label: item.pbxlabel, title: item.description, value: item.id})));
		}
		return [];
	}
	getOptions();
	
	 return () => setOptions(null);
	},[]);
	
	useEffect( ()=>{
		if(formValues && options.length && formValues['phone']){
			setPhoneTitle(options.find(({ value, title }) => value === formValues['phone']))
		}
	},[formValues]);
	
	useEffect( ()=>{
		if(!props.pristine){
			return props.setEnableWarning(true);
		}
		return props.setEnableWarning(false);
	},[props.pristine]);
	
	useEffect( ()=>{
		if(initialState){
			const { name, phone, pbxId, pbxName} = initialState;
			initialize({name, phone, pbxId})
		}
	},[initialState]);
	
	async function onClick(e) {
		e.preventDefault();
		setIsLoading(true);
		let request;
		if (editPhoneId) {
			request = await baseService.put('edit_phone_account', { pathParams: { id: editPhoneId }, data: { ...formValues }, jsonType: true } );
		}
		else {
			request = await baseService.post('phone_accounts', { data: { ...formValues }, jsonType: true });
		}
		if (request.success){
			setIsLoading(false);
			const onGetUserPhones = (userPhones) => {
				const userPhone = userPhones.find(phone => phone.id === editPhoneId);
				const newPhoneOption = { pathPrefix: userPhone.pathPrefix, phoneNumber: userPhone.phone, callStation: userPhone.pbxName };
				// be sure to "reselect" phone number on success change
				props.selectPhone(newPhoneOption);
			}
			props.getUserPhones(onGetUserPhones);
			return	props.changeOpenedAccountModal();
		}
		
		return setIsLoading(false);
	}
	
	return (
		<div className='account-modal-wrapper ordering-component-ui-core-wrapper'>
			<header className='modal-header'>{editPhoneId ? t('call.editAccount') : t('call.addAccount')}</header>
			
			<form className='create-customer-form-container modal-content'>
				{isLoading ? <Loader/> :
					<div className='form-wrapper'>
						<Field
							id='account-name'
							name='name'
							key='name'
							component={Input}
							label={t('name')}
							required={false}
							placeholder={t('dontSelect')}
							maxLength={200}
						/>
						<Field
							id='account-pbxId'
							name='pbxId'
							key='pbxId'
							component={ComboBox}
							label={t('call.line')}
							required={true}
							placeholder={t('dontSelect')}
							options={options}
							title={phoneTitle}
							removable={true}
						/>
						<Field
							id='account-phone'
							name='phone'
							key='phone'
							component={Input}
							label={t('call.number')}
							required={true}
							placeholder={t('dontSelect')}
							maxLength={10}
						/>
						<footer className='create-account-form-footer'>
							<button type='button'
									className={cx('btn', 'btn-primary', {disabled: ![props.pristine] || props.invalid})}
									onClick={onClick}>
								{t('save')}
							</button>
						</footer>
					</div>
				}
			</form>
		</div>
	)
}

export default reduxForm({ validate, destroyOnUnmount: true })(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AccountForm))
);

function validate(values) {
	const errors = {};
	if (!values.pbxId || values.pbxId === ''){
		errors['pbxId'] = REQUIRED_FIELD();
	}
	
	if (values.phone){
		if(!/^[0-9]*$/.test(values.phone)) errors['phone'] = DIGITS();
	}
	if (!values.phone || values.phone === ''){
		errors['phone'] = REQUIRED_FIELD();
	}
	return errors;
}
