import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Sidebar from './Sidebar/index';
import CalendarHeader from './CalendarHeader';
import cx from 'classnames';
import './style.scss';
import styles from 'styles/modules/knowledgeBase.module.scss';
import CalendarDetail from './CalendarDetail';
import Loader from '../../components/Loader/index';
import { clearOpenedEvent } from '../../modules/calendar/actions';

const EVENT_FORM_CONFIG = [
    { name: 'title', required: true},
    { name: 'eventType', required: true},
    { name: 'rrule', required: true},
    { name: 'valarm', required: true},
    { name: 'location', required: true},
];

function CalendarEvent(props) {
    const { clearOpenedEvent } = props;
    const [dynParams, setDynParams] = useState({ blocks: [] });
    const [allowModes, setAllowModes] = useState([]);
    const handleSubmit = (values) => {};

    const [isDeleting, setIsDeleting] = useState(false);
    const [isCreating, setIsCreating] = useState(false);


    useEffect(() => {
        return () => {
            clearOpenedEvent();
        };
    }, []);

    return (
        <div className={cx('event-page')}>
            <CalendarHeader
                eventMode={true}
                dynParams={dynParams}
                allowModes={allowModes}
                setIsDeleting={setIsDeleting}
                eventFormConfig={EVENT_FORM_CONFIG}
                setIsCreating={setIsCreating}
            />
            <div className="main-calendar-content">
                {(isDeleting || isCreating) && <Loader />}
                <Sidebar
                    eventMode={true}
                    submitForm={handleSubmit}
                    setDynParams={setDynParams}
                    setAllowModes={setAllowModes}
                    eventFormConfig={EVENT_FORM_CONFIG}
                />
                <div className={cx(styles.mainBox, 'issue-main', 'calendar-main')}>
                    <CalendarDetail eventMode={true} />
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    clearOpenedEvent,
};

export default connect(null, mapDispatchToProps)(CalendarEvent);
