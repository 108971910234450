import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { openedFilterAppeal, setActiveFilterPage, getFilterSupposeSuccess } from "actions/filter";
import MainContent from "./MainContent/index";
import "styles/modules/cardContent.module.scss";
import { clearAppealState } from "modules/appeal/actions";
import { clearEntireCustomerState } from "actions/customer";
import { destroy } from "redux-form";
import { withTranslation } from "react-i18next";
import SidebarFilters from "../../pages/Ordering/common/SidebarFilters";

const appealPageForms = [
    "appeal-form",
    "customerInfoFilled",
    "customerInfoEmpty",
    "personInfoFilled",
    "personInfoEmpty",
];

const mapStateToProps = state => ({
    filters: state.filter.filterList,
    saved: state.filter.saved,
    deleted: state.filter.deleted,
    openedFilter: state.filter.openedFilter,
    tabs: state.tabs,
    grid: state.grid,
});

const mapDispatchToProps = dispatch => ({
    openedFilterAppeal: openedFilter => dispatch(openedFilterAppeal(openedFilter)),
    clearAppealState: () => dispatch(clearAppealState()),
    setActiveFilterPage: (activeFilter, currentPage) => dispatch(setActiveFilterPage(activeFilter, currentPage)),
    clearEntireCustomerState: () => dispatch(clearEntireCustomerState()),
    resetRedundantForms: () => dispatch(destroy(...appealPageForms)),
    getFilterSupposeSuccess: result => dispatch(getFilterSupposeSuccess(result)),
});

const Appeal = props => {
    const {
        t,
        getFilterSupposeSuccess,
        openedFilterAppeal,
        filters,
        clearAppealState,
        clearEntireCustomerState,
        resetRedundantForms,
    } = props;

    const [needUpdate, setNeedUpdate] = useState(false);
    const [needPageReset, setNeedPageReset] = useState(false);

    // trigger page reset once new filter is opened by the user select
    function handleSetActiveFilter(filterId, withPageReset) {
        openedFilterAppeal(filterId);
        if (withPageReset) {
            setNeedPageReset(true);
        }
    }

    useEffect(() => {
        clearAppealState();
        clearEntireCustomerState();
        resetRedundantForms();
    }, []);

    return (
        <div className="content-wrapper ordering-component-ui-core-wrapper">
            <div className="left-side-wrapper">
                <div className="left-side-content">
                    <SidebarFilters
                        titleKey="tabs.appeal"
                        storeKey="filter"
                        historyKey="appeal"
                        filtersCode="INTERACTION_REQUEST"
                        needUpdate={needUpdate}
                        setNeedUpdate={setNeedUpdate}
                        setFilters={getFilterSupposeSuccess}
                        setActiveFilter={handleSetActiveFilter}
                    />
                </div>
            </div>
            <MainContent {...props} filters={filters} needUpdate={needUpdate} setNeedUpdate={setNeedUpdate} needPageReset={needPageReset} setNeedPageReset={setNeedPageReset} />
        </div>
    );
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Appeal)));

Appeal.propTypes = {
    data: PropTypes.object,
    saved: PropTypes.bool,
    deleted: PropTypes.bool,
    filters: PropTypes.array,
    openedFilter: PropTypes.string,
    openedFilterAppeal: PropTypes.func,
    history: PropTypes.object,
    refresh: PropTypes.func,
};
