import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from 'styles/modules/notification.module.scss';
import cx from 'classnames';

const CurrentAppealChangeNote = ({ userName, onClose }) => {
	const { t } = useTranslation();
	
	return (
		<div className={styles.currentAppealChangeNote}>
			<div className={styles.userAvatar} />
			<div className={styles.noteText}>
				<span className={styles.userName}>{userName}</span>
				<span>{t('notification.addChanges')}</span>
			</div>
			<button className={cx('btn', 'btn-primary')} onClick={onClose}>{t('notification.understand')}</button>
		</div>
	)
};

CurrentAppealChangeNote.propTypes = {
	userName: PropTypes.string,
	avatar: PropTypes.string,
	onClose: PropTypes.func
};

export default CurrentAppealChangeNote;

