import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/index';
import BillingFiltersBlock from './BillingFiltersBlock';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridWrapper from 'custom-hooks/GridWrapper';
import { setApi } from 'reducers/grid';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import moment from "moment/moment";
import _ from "lodash";

const filterFormName = 'billing_recalculations';

const mapStateToProps = state => ({
	filterValues: getFormValues(filterFormName)(state),
	filerSyncError: getFormSyncErrors(filterFormName)(state),
});

const BillingRecalculationsList = props => {
	const { updateApiGrid, pApartId, filterValues, filerSyncError } = props;
	const [loadingState, setLoadingState] = useState(false);
	
	const init = {
		key: 'billing_recalculations',
		query: '?'
	};
	const gridKey = `${init.key}_${init.path || init.extra || ''}`;
	
	const acc = _.get(filterValues, 'acc', null);
	const dateBegin = _.get(filterValues, 'dateBegin', null);
	const dateEnd = _.get(filterValues, 'dateEnd', null);
	const dateBeginFormatted = dateBegin ? moment(dateBegin).format('DD.MM.YYYY') : dateBegin;
	const dateEndFormatted = dateEnd ? moment(dateEnd).format('DD.MM.YYYY') : dateEnd;
	const isReady = !filerSyncError.length && acc && dateBeginFormatted && dateEndFormatted;
	
	const onStart = () => setLoadingState(true);
	
	const onFinish = () => setLoadingState(false);
	
	useEffect(() => {
		if (isReady) {
			onStart();
			updateApiGrid({ acc, dateBegin: dateBeginFormatted, dateEnd: dateEndFormatted, limit: 1000 }, gridKey);
		}
	}, [filterValues]);
	
	useEffect(() => {
		if (isReady) {
			onStart();
			updateApiGrid({ acc, dateBegin:dateBeginFormatted, dateEnd: dateEndFormatted, limit: 1000 }, gridKey);
		}
		
	}, []);
	
	return (
        <>
			<BillingFiltersBlock form={filterFormName} pApartId={pApartId} tabKey={init.key} acc={acc}/>
			{isReady &&
			<div className='content-wrapper ordering-component-ui-core-wrapper with-fixed-status customer-ordering-grid'>
				{loadingState && <Loader />}
				<GridWrapper
					tuner={() => init}
					initUpdate={false}
					onStart={onStart}
					onFinish={onFinish}
				/>
			</div>}
        </>
	);
};

export default connect(mapStateToProps, { updateApiGrid: setApi })(withRouter(BillingRecalculationsList));

