import React, { useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import cx from 'classnames';
import MainBox from './MainBox';
import MainHeader from './MainHeader';
import Breadcrumbs from './Breadcrumbs';
import MainContentWrapper from './MainContentWrapper';
import HeaderMenu from './HeaderMenu';
import { withTranslation } from 'react-i18next';
import ServiceItemView from './ServiceItemView';
import ServiceForm from './ServiceForm';
import Tabs from '../../../pages/Ordering/common/Tabs';

import { subTabsConfig } from './Tabs/config';

import './Tabs/styles.scss';

function Main(props) {
    const {
        catalogList,
        knowledgeBaseLoading,
        t,
        knowledgeItem,
        match,
        updateDirectoriesStructure,
        selectedNode,
        requestKnowledgeBase,
        history,
        breadcrumbs,
        requestData,
        canEdit,
        parentFolderId,
        match: {
            params: { id, type },
        },
    } = props;

    const [activeTab, setActiveTab] = useState(subTabsConfig[0].tab);
    const [isCollapsed, setIsCollapsed] = useState(false);
    // const [activeTab, setActiveTab] = useState("objects");

    function handleTabsToggle() {
        setIsCollapsed((prev) => !prev);
    }

    function redirect(route) {
        if (!route.id && !route.isFolder) {
            history.push('/specification');
        } else {
            history.push(`/specification/${route.id}/${route.isFolder}`);
        }
    }

    return (
        <MainBox>
            <MainHeader>
                <Breadcrumbs onClick={redirect} routes={breadcrumbs} />
                <HeaderMenu
                    match={match}
                    history={history}
                    t={t}
                    knowledgeItem={knowledgeItem}
                    canEdit={canEdit}
                    updateDirectoriesStructure={updateDirectoriesStructure}
                    requestKnowledgeBase={requestKnowledgeBase}
                    selectedNode={selectedNode}
                    requestData={requestData}
                    parentFolderId={parentFolderId}
                />
            </MainHeader>
            {catalogList.length === 0 && !knowledgeBaseLoading && <div className="specification-placeholder"/>}
            {catalogList.length > 0 && !knowledgeBaseLoading && (
                <MainContentWrapper
                    className="ordering-component-ui-core-wrapper"
                    knowledgeBase={catalogList}
                >
                    <Switch>
                        <Route
                            path="/specification/:id/:type/:mode(edit)?"
                            exact
                            render={(p) => <ServiceItemView {...props} {...p} isCollapsed={isCollapsed} isLeaf={id && +type === 0} />}
                        />
                        <Route
                            path="/specification/:id/:type/add"
                            exact
                            render={(p) => (
                                <ServiceForm {...props} {...p} isCollapsed={isCollapsed} isLeaf={id && +type} initialValues={{ isFolder: 0 }} />
                            )}
                        />
                        <Route path="/specification" render={() => <div className="specification-placeholder"/>} />
                        <Route path="*" render={() => <Redirect to="/specification" />} />
                    </Switch>
                    {id && +type === 0 && (
                        <Tabs
                            className={cx(
                                'knowledgeBase-subtabs-panel ordering-component-ui-core-wrapper',
                                { collapsed: isCollapsed },
                            )}
                            config={subTabsConfig}
                            objectType="SPECIFICATION"
                            id={id}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            selectedNode={selectedNode}
                            isCollapsed={isCollapsed}
                            onToggleTabs={handleTabsToggle}
                            key={id}
                        />
                    )}
                </MainContentWrapper>
            )}
        </MainBox>
    );
}

export default withTranslation()(withRouter(Main));
