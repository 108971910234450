import React, { useState, useEffect, useMemo } from 'react';
import ReactSVG from 'react-svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import Popup from 'components/Popup';
import Portal from "../../common/Portal"
import { isEmpty } from 'lodash';
import {
    getCustomerAddress,
    customerEdit,
    personDeleteContact,
    personSaveContact,
    getCustomer,
    getContactPerson,
    customerAddLiveSaving,
    customerRemoveLiveSaving,
    // contactPersonFieldEdit,
    // customerFieldEdit,
} from 'actions/customer';

import BaseService from '../../../../services/BaseService';
import DynamicForm from '../../common/DynamicForm';

import Loader from 'components/Loader';

import { clientScheme, CONTACT_CONFIG, getCustomWidgets } from './config';

import { GET_MAX_FILE_SIZE_FROM_DICT } from 'constants/index';

import './styles.scss';

const PERSON_NAME_EDIT_REFRESH_URLS = ['person_edit_multi'];
const CUSTOMER_NAME_EDIT_REFRESH_URLS = ['customer_edit', 'customer_edit_multi'];

// const MAIN_HEADER_HEIGHT = 48;
// const COLLAPSED_HEADER_HEIGHT = 88 + MAIN_HEADER_HEIGHT;
// const EXPANDED_HEADER_HEIGHT = 44 + MAIN_HEADER_HEIGHT;
// const INPUT_HEIGHT = 28;

function processDynParams({
    dynParams,
    client,
    isExpanded,
    clientType,
    handleContactChange,
    customerId,
    t,
    staticBlocks,
}) {
    // prepare custom widgets
    const customWidgets = getCustomWidgets({
        client,
        handleContactChange,
        clientType,
        customerId,
        t,
        staticBlocks,
    });

    const formBlocks = clientScheme[clientType].map((schemeBlock) => {
        // handle adding names block
        if (typeof schemeBlock === 'string' && schemeBlock === 'name') {
            const widgets = customWidgets[schemeBlock];
            // check if isHidden is defined inside of widget, if so - hide it based on that param, othervise based on isExpanded
            const nameWidgets = widgets.map((widget) => ({
                ...widget,
                isHidden: widget.isHiddenByConfig || !isExpanded,
            }));
            return [
                {
                    widgets: nameWidgets,
                },
            ];
        }
        // handle adding custom block
        if (typeof schemeBlock === 'string' && schemeBlock !== 'name') {
            const widget = customWidgets[schemeBlock];
            let isFilled = widget.savedValue;
            const isArray = Array.isArray(widget.savedValue);
            if (isArray) {
                const firstElement = widget.savedValue[0];
                if (!firstElement) {
                    isFilled = false;
                }
                if (firstElement) {
                    if (
                        typeof firstElement === 'object' &&
                        'value' in firstElement &&
                        [null, undefined, ''].includes(firstElement.value)
                    ) {
                        isFilled = false;
                    }
                }
            }

            const isRequired = widget.isReq;

            // if (isFilled || isExpanded) {
            if (isFilled || isExpanded || isRequired) {
                // return [{ widgets: [widget] }];
                return [{ widgets: [{ ...widget, isHidden: widget.isHiddenByConfig }] }];
            }
            return [{ widgets: [{ ...widget, isHidden: true }] }];
        }

        // handle dynBlocks
        const blocks = dynParams.filter(
            (block) => block.order >= schemeBlock.min && block.order <= schemeBlock.max,
        );

        // return only all if expaned or either required or filled with data
        return blocks.map((block) => {
            return {
                ...block,
                widgets: block.widgets.map((widget) => {
                    const isRequired = widget.isReq;
                    const isFilled = widget.savedValue;
                    if (isExpanded || isFilled || isRequired) {
                        // return widget;
                        return {
                            ...widget,
                            isHidden: widget.isHiddenByConfig,
                        };
                    }
                    return { ...widget, isHidden: true };
                }),
            };
        });
    });
    const filteredBlocks = formBlocks.filter((block) =>
        Array.isArray(block) ? block.some((item) => item.widgets.length > 0) : block,
    );
    return filteredBlocks.flatMap((block) => block);
}

const mapStateToProps = (state, props) => {
    // const staticBlocks = state.staticBlocks.blocks && state.staticBlocks.blocks.find(block => block.code === 'FORM');
    const formBlocksOrigin = state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM;
    const formBlocksDefault =
        state.staticBlocks && state.staticBlocks.formBlocks && state.staticBlocks.formBlocks.FORM_DEFAULT;
    const formBlocksDefaultStaticUI =
        (state.staticBlocks && state.staticBlocks.fallbackFormBlocks && state.staticBlocks.fallbackFormBlocks.FORM) ||
        {};
    // const {
    //     custAddress: custAddressBlock,
    //     juridicalData: juridicalDataBlock,
    //     personalData: personalDataBlock,
    // } = formBlocks;
    return {
        staticBlocks: { origin: formBlocksOrigin, default: formBlocksDefault, defaultStaticUI: formBlocksDefaultStaticUI },
        formValues: getFormValues(props.formName)(state),
    };
};

const mapDispatchToProps = {
    getCustomerAddress,
    customerEdit,
    personSaveContact,
    personDeleteContact,
    getCustomer,
    // contactPersonFieldEdit,
    // customerFieldEdit,
    getContactPerson,

    customerAddLiveSaving,
    customerRemoveLiveSaving
};

const ClientInfo = (props) => {
    const {
        client,
        clientType,
        customerId,
        t,
        getCustomerAddress,
        customerEdit,
        personSaveContact,
        personDeleteContact,
        formName,
        formValues,
        actions,
        readonly,
        getCustomer,
        getContactPerson,
        outerLoading,
        staticBlocks,
        // contactPersonFieldEdit,
        // customerFieldEdit,
        onAppealDestinationsReload,
        onCustomerProblemReload,
        dynFormObjectType,
        customerAddLiveSaving,
        customerRemoveLiveSaving
    } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOnceInit, setIsOnceInit] = useState(false);
    const [dynParams, setDynParams] = useState(null);
    const [processedDynParams, setProcessedDynParams] = useState(null);
    const [dynFormKey, setDynFormKey] = useState(null);

    const [wait, setWait] = useState(false);

    const [isActionsOpen, setIsActionsOpen] = useState(false);

    const shouldRequestAddress = useMemo(() => Boolean(client.primaryAddress), [client]);

    const maxFileSize = useMemo(() => GET_MAX_FILE_SIZE_FROM_DICT('form'), []);

    const toggleExpand = () => setIsExpanded((prev) => !prev);

    const toggleActionsPopup = () => setIsActionsOpen((prev) => !prev);

    const handlePopupAction = (action) => {
        toggleActionsPopup();
        if (action.action && typeof action.action === 'function') {
            action.action();
        }
    };

    const requestCustomerAddress = () => {
        return getCustomerAddress({ customerId: customerId }, customerId);
    };

    const fetchDynParams = () => {
        setIsLoading(true);
        BaseService.get('dinamic_params', {
            data: {
                objectType:
                    clientType === 'contactPerson' ? 'CONTACT_PERSON@INST' : 'CUSTOMER@inst',
                objectTypeId: client.id, // null,
                objectTypeCode: '',
                // objectId: null, // id,
                openMode: 'update',
                ctxObjectType: 'CUSTOMER',
                ctxObjectId: clientType === 'contactPerson' ? customerId : '',
                curStepNum: 1,
                overrideIsReq: '',
            },
        })
            .then(({ result, success }) => {
                setIsLoading(false);
                if (success) {
                    setDynParams(result.blocks);
                } else {
                    console.log('ClientInfo::fetchDynParams: Error during fetching dynamic params');
                }
            })
            .catch((e) => {
                setIsLoading(false);
            });
    };

    const configureArrayFieldRequestData = (payload, fieldConfig, id) => ({
        customerId: id,
        field: fieldConfig.field,
        id: payload.value.map((element) => element.id),
        value: payload.value.map((element) => element.value),
    });

    // customer CONTACT CHANGE HANDLE
    const handleContactChangeCustomer = (payload, onSubmitFinish) => {
        if (wait) {
            return false;
        }

        setWait(true);

        const fieldConfig = CONTACT_CONFIG[payload.name];

        if (fieldConfig.required && !payload.value) return false;

        const requestData = configureArrayFieldRequestData(payload, fieldConfig, client.id);

        customerEdit({
            data: requestData,
            onError: () => {
                setWait(false);
                onSubmitFinish();
            },
            onSuccess: () => {
                setWait(false);
                onSubmitFinish();
                setDynFormKey(client && client.timestamp);
                // console.log("*** SUCCESS ***", client);
            },
            id: client.id,
        });
    };

    // contactPerson CONTACT CHANGE HANDLE
    const handleContactChangeContactPerson = (payload, onSubmitFinish) => {
        const fieldConfig = CONTACT_CONFIG[payload.name];

        if (fieldConfig.required && !payload.value) return false;
        if (wait) {
            return false;
        }
        setWait(true);

        if (payload.options.action === 'save') {
            const requestData = {
                personId: client.id,
                id: payload.options.fieldValue.id,
                type: fieldConfig.type,
                value: payload.options.fieldValue.value,
            };

            personSaveContact({
                data: requestData,
                onError: () => {
                    setWait(false);
                    onSubmitFinish();
                },
                onSuccess: () => {
                    setWait(false);
                    onSubmitFinish();
                    setDynFormKey(client && client.timestamp);
                    // console.log("*** SUCCESS ***", client);
                },
                id: client.id, //customerId,
            });
        } else if (payload.options.action === 'delete') {
            const requestData = {
                contactId: payload.options.fieldValue.id,
            };

            personDeleteContact({
                data: requestData,
                onError: () => {
                    setWait(false);
                    onSubmitFinish();
                },
                onSuccess: () => {
                    setWait(false);
                    onSubmitFinish();
                },
                id: client.id,
            });
        }
    };

    const handleLiveSaveStart = data => {
        const { key } = data;
        // console.log({customerId, id: client.id, key});
        customerAddLiveSaving(client.id, key);
    };

    const handleLiveSaveFinish = data => {
        const { key } = data;
        // console.log({customerId, id: client.id, key});
        customerRemoveLiveSaving(client.id, key);
    };


    const handleLiveSaveSuccess = (data) => {
        const {
            key,
            data: {
                data: { attrCode, attrValue },
            },
            ajaxOptions,
            urlKey,
        } = data;
        const isContactPersonNameEdit = PERSON_NAME_EDIT_REFRESH_URLS.includes(urlKey);
        const isCustomerNameEdit = CUSTOMER_NAME_EDIT_REFRESH_URLS.includes(urlKey);
        if (isContactPersonNameEdit) {
            getContactPerson({ id: client.id }, customerId, true);
        }
        if (isCustomerNameEdit) {
            getCustomer({ customerId: client.id }, customerId, true);
        }
        const updatedDynParams = dynParams.map((block) => {
            return {
                ...block,
                widgets: block.widgets.map((widget) => {
                    if (widget.key === attrCode) {
                        let values = widget.values;
                        const ajaxOption =
                            ajaxOptions && ajaxOptions.find((option) => option.value === attrValue);
                        if (widget.widgetType === 'ajaxCombo' && ajaxOption) {
                            values = [...values, { key: attrValue, value: ajaxOption.label }];
                        }
                        const savedValue = ![null, undefined].includes(attrValue)
                            ? attrValue
                            : widget.defaultValue;
                        return {
                            ...widget,
                            savedValue,
                            values,
                        };
                    }
                    return widget;
                }),
            };
        });
        setDynParams(updatedDynParams);
    };

    const handleDynFormActions = (actions) => {
        // console.log({ actions, onAppealDestinationsReload });
        // check for appealDestinationsReload
        // if it is inside => invoke onAppealDestinationsReload callback
        if (
            actions &&
            Array.isArray(actions) &&
            actions.includes('appealDestinationsReload') &&
            onAppealDestinationsReload &&
            typeof onAppealDestinationsReload === 'function'
        ) {
            onAppealDestinationsReload();
        }

        if (
            actions &&
            Array.isArray(actions) &&
            actions.includes('customerProblemReload') &&
            onCustomerProblemReload &&
            typeof onCustomerProblemReload === 'function'
        ) {
            onCustomerProblemReload(client.id);
        }
    };

    // update dynParams
    const handleDynParamsProcessing = () => {
        const handleContactChange =
            clientType === 'contactPerson'
                ? handleContactChangeContactPerson
                : handleContactChangeCustomer;

        const processedDynParams = processDynParams({
            dynParams,
            client,
            isExpanded,
            clientType,
            handleContactChange,
            customerId,
            t,
            staticBlocks,
        });
        setProcessedDynParams(processedDynParams);
    };

    useEffect(() => {
        if (dynParams && client) {
            handleDynParamsProcessing();
        }
    }, [dynParams, isExpanded, client]);

    useEffect(() => {
        setDynFormKey(client.timestamp);
    }, [client]);

    useEffect(() => {
        if (formValues && !isOnceInit) {
            setIsOnceInit(true);
        }
    }, [formValues]);

    useEffect(() => {
        if (shouldRequestAddress) {
            requestCustomerAddress();
        }
    }, [shouldRequestAddress]);

    useEffect(() => {
        fetchDynParams();
        return () => {
            setDynParams(null);
        };
    }, []);
    return (
        <div className={cx('customer-panel__client', { expanded: isExpanded })}>
            {(outerLoading || isLoading) && <Loader />}
            <div className="customer-panel__client-header">
                <div className="customer-panel__client-header-info">
                    <div className="customer-panel__client-header-type">
                        <ReactSVG
                            className="type-icon"
                            src={`/data/svg/customer/${clientType}.svg`}
                        />
                        <span>{t(`customer.${clientType}`)}</span>
                    </div>

                    <div className="customer-panel__client-header-actions">
                        <div className="button-hover-circle" onClick={toggleExpand}>
                            {/* {!isExpanded && (
                                <ReactSVG
                                    className="button-icon"
                                    src={`/data/svg/customer/expand.svg`}
                                />
                            )}
                            {isExpanded && (
                                <ReactSVG
                                    className="button-icon"
                                    src={`/data/svg/customer/collapse.svg`}
                                />
                            )} */}
                            <ReactSVG
                                className="button-icon"
                                src={`/data/svg/customer/${isExpanded ? 'collapse' : 'expand'}.svg`}
                            />
                        </div>
                        {actions.length > 0 && (
                            <div
                                className={
                                    isActionsOpen
                                        ? 'button-hover-circle active'
                                        : 'button-hover-circle'
                                }
                            >
                                <ReactSVG
                                    className="button-icon"
                                    src={`/data/svg/customer/kebab.svg`}
                                    onClick={toggleActionsPopup}
                                />
                                {isActionsOpen && (
                                    <Portal
                                        // dataFieldKey={`contact-suggestions-${input.name}`}
                                        className="customer-panel-portal"
                                        position={{top: 101, left: 144}}
                                    >
                                        <Popup
                                            place="customer-panel-popup"
                                            onClickOutside={toggleActionsPopup}
                                        >
                                            {actions.map((action) => (
                                                <button
                                                    onClick={() => handlePopupAction(action)}
                                                    className="popup-link"
                                                    key={action.title}
                                                >
                                                    {action.title}
                                                </button>
                                            ))}
                                        </Popup>
                                    </Portal>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {!isExpanded && (
                    <div className="customer-panel__client-header-name">
                        {client.name || client.party.fullName}
                    </div>
                )}
            </div>
            <div className="customer-panel__form">
                {processedDynParams && (
                    <DynamicForm
                        formName={formName}
                        dynData={processedDynParams}
                        currentId={client.id}
                        liveSaveIdKey="id"
                        liveSaveUrlKey={
                            clientType === 'contactPerson'
                                ? 'save_dyn_param_contact_person'
                                : 'save_dyn_param_customer'
                        }
                        uploadFileSource="form"
                        maxFileSize={maxFileSize}
                        // liveSaveWithoutChangeNum
                        liveSave
                        liveSaveJSON
                        onLiveSaveStart={handleLiveSaveStart}
                        onLiveSaveFinish={handleLiveSaveFinish}
                        onLiveSaveSuccess={handleLiveSaveSuccess}
                        refreshDynParams={fetchDynParams}
                        withoutCollapse
                        isLiveSaveOnlyValid
                        // destroyOnUnmount={false}
                        isOnceInitialized={isOnceInit}
                        readonly={readonly}
                        objectType={dynFormObjectType}
                        // readonly={true}
                        portalPlacement="right"
                        onActions={handleDynFormActions}
                        t={t}
                        key={dynFormKey}
                    />
                )}
            </div>
            <div className="customer-panel__toggle" onClick={toggleExpand}>
                <ReactSVG
                    className="toggle-icon"
                    src={`/data/svg/customer/${isExpanded ? 'collapse' : 'expand'}.svg`}
                />
                {isExpanded ? t('collapse') : t('expand')}
            </div>
        </div>
    );
};

export default withRouter(
    withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ClientInfo)),
);
