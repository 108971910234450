import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { KeyCodes } from 'constants/index';
import ErrorBlock from './ErrorBlock';
import InputLabel from './InputLabel';
import { isValidRequiredRegExpWithMessage } from '../validate';

@withTranslation()
class SimpleInput extends React.Component {
	
	handleKeyDown = event => {
		const { meta, input } = this.props;
		
		switch (event.keyCode) {
		
		case KeyCodes.ENTER: {
			this.saveValue();
			break;
		}
		
		case KeyCodes.ESCAPE: {
			/*** return to initial value ***/
			if (meta.initial !== input.value) {
				input.onChange(meta.initial);
			}
			break;
		}
		}
	};
	
	saveValue = () => {
		const { meta, input, rule, required } = this.props;
		
		if (meta.initial !== input.value && !isValidRequiredRegExpWithMessage(input.value, input.name, rule, required) ) {
			this.props.handleChangeValue({
				value: input.value,
				name: input.name
			});
		}
	};
	
	handleBlur = () => {
		const { input, onBlur } = this.props;
		
		input.onBlur();
		onBlur();
		
		this.saveValue();
	};
	
	handleFocus = () => {
		this.props.input.onFocus();
		this.props.onFocus();
	};
	
	onRemove = event => {
		event.stopPropagation();
		
		this.props.handleChangeValue({
			value: null,
			name: this.props.input.name
		});
	};
	
	handleChange = event => {
		this.props.input.onChange(event.target.value);
	};
	
	render () {
		const { label, disabled, input, meta, required, maxLength = '', rule, id } = this.props;
		const showErrorBlock = meta && meta.touched && !meta.active && meta.error && (required || input.value !== '');
		const showErrorNoValid = !meta.active && isValidRequiredRegExpWithMessage(input.value, input.name, rule, required);
		// most of displaying logic is located in SCSS
		const showRemoveButton = input.value !== '' && !disabled && !required;
		
		const containerClassName = cx(
			'input-element',
			'simpleInputContainer',
			meta.active && 'active',
			disabled && 'disabled',
			rule && 'dynamic_field'
		);
		
		const inputClassName = cx(
			'input-field',
			(showErrorBlock || showErrorNoValid) && 'input-field__error'
		);
		
		return (
			<div className='fieldArrayContainer' id={id}>
				<InputLabel label={label} />
				
				<div className={containerClassName} title={input.value || ''}>
					<input
						value={input.value || ''}
						disabled={disabled}
						className={inputClassName}
						onKeyDown={this.handleKeyDown}
						onBlur={this.handleBlur}
						onFocus={this.handleFocus}
						onChange={this.handleChange}
						autoComplete='off'
						maxLength={maxLength}
					/>
					
					{
						showRemoveButton &&
						<button
							type='button'
							title='Remove'
							className='removeButton'
							onClick={this.onRemove}
						>
							<span className='icon-close' />
						</button>
					}
					
					{showErrorBlock && <ErrorBlock error={meta.error} /> }
					{!showErrorBlock && showErrorNoValid && <ErrorBlock error={showErrorNoValid} />}
				</div>
			</div>
		
		);
	}
}

function defaultFunc () {}

SimpleInput.defaultProps = {
	onFocus: defaultFunc,
	onBlur: defaultFunc,
	onRemove: defaultFunc
};

SimpleInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	input: PropTypes.any,
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func
};

export default SimpleInput;
