import {
	CHANGE_CHECKED_KNOWLEDGE,
	UPDATE_DIRECTORIES_STRUCTURE,
	DISABLE_SEARCH_MODE,
	ENABLE_SEARCH_MODE,
	UPDATE_KNOWLEDGES_SEARCH_QUERY,
	SUBMIT_KNOWLEDGE_SEARCH,
	CLEAR_KNOWLEDGE_BASE_TEMP_STATE,
	
	KNOWLEDGE_ITEM_REQUEST,
	KNOWLEDGE_ITEM_REQUEST_SUCCESS,
	KNOWLEDGE_ITEM_REQUEST_FAILURE,
	KNOWLEDGE_ITEM_MODIFY,
	KNOWLEDGE_ITEM_DYNPARAMS_SET,
	
	KNOWLEDGES_REQUEST,
	KNOWLEDGES_REQUEST_SUCCESS,
	KNOWLEDGES_REQUEST_FAILURE,
	
	SET_KNOWLEDGE_ITEM_TO_CREATE,
	CLEAR_KNOWLEDGE_ITEM_TO_CREATE,
	
	STATUS_LIST_REQUEST,
	STATUS_LIST_REQUEST_SUCCESS,
	STATUS_LIST_REQUEST_FAILURE,
} from 'constants/actions';
import baseService from 'services/BaseService';

export function deleteArticle (data, callback) {
	return () => {
		baseService.post('article_delete', { data, jsonType: true })
			.then(() => {
				callback();
			});
	};
}

/********  Status list request  ********/

export const requestStatusList = () => ({
	type: STATUS_LIST_REQUEST
});

export const requestStatusListSuccess = statusList => ({
	type: STATUS_LIST_REQUEST_SUCCESS,
	statusList
});

export const requestStatusListFailure = () => ({
	type: STATUS_LIST_REQUEST_FAILURE
});

export const getStatusList = () => dispatch => {
	dispatch(requestStatusList());
	
	return baseService.get('kb_status_list')
		.then(response => {
			if (response.success && Array.isArray(response.result)) {
				dispatch(requestStatusListSuccess(response.result));
			} else {
				throw new Error('Status list result is empty');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(requestStatusListFailure());
		});
};

/********  Knowledge base request  ********/

export const requestKnowledges = (reloadSearch) => ({
	type: KNOWLEDGES_REQUEST,
	reloadSearch,
});

export const requestKnowledgesSuccess = knowledgeBase => ({
	type: KNOWLEDGES_REQUEST_SUCCESS,
	knowledgeBase
});

export const requestKnowledgesFailure = () => ({
	type: KNOWLEDGES_REQUEST_FAILURE
});

export const getKnowledges = (requestData, reloadSearch) => dispatch => {
	dispatch(requestKnowledges(reloadSearch));
	
	const params = { data: requestData };
	
	return baseService.get('knowledge_base', params)
		.then(response => {
			const knowledgeBase = response.result || [];
			dispatch(requestKnowledgesSuccess(knowledgeBase));
			return knowledgeBase;
		})
		.catch(error => {
			console.error(error);
			dispatch(requestKnowledgesFailure());
			return [];
		});
};

/********  Knowledge item request  ********/

export const modifyKnowledgeItem = () => ({
	type: KNOWLEDGE_ITEM_MODIFY
});

export const requestKnowledgeItem = () => ({
	type: KNOWLEDGE_ITEM_REQUEST
});

export const requestKnowledgeItemSuccess = knowledgeItem => ({
	type: KNOWLEDGE_ITEM_REQUEST_SUCCESS,
	knowledgeItem
});

export const requestKnowledgeItemFailure = () => ({
	type: KNOWLEDGE_ITEM_REQUEST_FAILURE
});

export const getKnowledgeItem = (requestData, openMode) => dispatch => {
	dispatch(requestKnowledgeItem());
	
	const params = { data: requestData };
	const openMode = openMode;
	return baseService.get('kb_detail', params)
		.then(response => {
			if (response.success && Array.isArray(response.result) && response.result[0]) {
				dispatch(getKnowledgeItemDynParams({...response.result[0], openMode}));
			} else {
				throw new Error('Knowledge base item response is empty!');
			}
		})
		.catch(error => {
			console.error(error);
			dispatch(requestKnowledgeItemFailure());
		});
};

export const getKnowledgeItemDynParams = requestData => dispatch => {
	dispatch(requestKnowledgeItemSuccess(requestData));
	if (requestData.requestTypeId && requestData.requestTypeCode){
	
		const openMode = requestData.openMode ? requestData.openMode : 'create@norequire';
		const data = {
				ctxObjectType: 'KNOWLEDGE_BASE',
				ctxObjectId: requestData.id || '',
				objectType: 'appeal',
				objectTypeId: requestData.requestTypeId,
				objectId: requestData.id,
				objectTypeCode: requestData.requestTypeCode,
				openMode,
				curStepNum: 1,
			};
		
		const params = { data };
	
		return baseService.get('appeal_form_params', params)
			.then(response => {
				
				if (response.success && response.result.blocks) {
					return dispatch({
						type: KNOWLEDGE_ITEM_DYNPARAMS_SET,
						dynParams: response.result.blocks
					});
				} else {
					dispatch({
						type: KNOWLEDGE_ITEM_DYNPARAMS_SET,
						dynParams: null
					});
					throw new Error('Knowledge base item response is empty!');
				}
			})
			.catch(error => {
				console.error(error);
				dispatch(requestKnowledgeItemFailure());
			});
	}
	else {
 		return dispatch({
			type: KNOWLEDGE_ITEM_DYNPARAMS_SET,
			dynParams: null
		});
	}
};

/*************************************************/

export const changeCheckedKnowledge = (knowledgeId, breadcrumbs) => ({
	type: CHANGE_CHECKED_KNOWLEDGE,
	knowledgeId,
	breadcrumbs
});

export const updateDirectoriesStructure = (directoriesStructure) => ({
	type: UPDATE_DIRECTORIES_STRUCTURE,
	directoriesStructure
});

export const clearKnowledgeBaseTempState = () => ({
	type: CLEAR_KNOWLEDGE_BASE_TEMP_STATE
});

export const enableSearchMode = () => ({
	type: ENABLE_SEARCH_MODE
});

export const disableSearchMode = () => ({
	type: DISABLE_SEARCH_MODE
});

export const updateSearchQuery = searchQuery => ({
	type: UPDATE_KNOWLEDGES_SEARCH_QUERY,
	searchQuery
});

export const submitKnowledgeSearch = () => ({
	type: SUBMIT_KNOWLEDGE_SEARCH
});

export const setItemToCreate = ({ itemType, parentId }) => ({
	type: SET_KNOWLEDGE_ITEM_TO_CREATE,
	itemType,
	parentId
});

export const clearItemToCreate = () => ({
	type: CLEAR_KNOWLEDGE_ITEM_TO_CREATE
});
