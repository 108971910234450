import {
	ORDERS_REQUEST,
	ORDERS_REQUEST_SUCCESS,
	ORDERS_REQUEST_FAILURE,
	ORDERS_FILTERS_REQUEST,
	ORDERS_FILTERS_REQUEST_SUCCESS,
	ORDERS_FILTERS_REQUEST_FAILURE,
	ORDERS_SET_ACTIVE_FILTER,
	ORDERS_SET_ACTIVE_FILTER_PAGE,
	ORDERS_FILTER_ATTRS_GET_SUPPOSE,
	ORDERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_ATTRS_GET_SUPPOSE_FAIL,
	ORDERS_ADD_FILTER_ATTRS,
	ORDERS_SAVE_FILTER_SUCCESS,
	ORDERS_SAVE_FILTER_FAIL,
	ORDERS_SAVE_FILTER,
	ORDERS_DELETE_FILTER,
	ORDERS_DELETE_FILTER_SUCCESS,
	ORDERS_DELETE_FILTER_FAIL,
	ORDERS_FILTER_PARAMS_GET_SUPPOSE,
	ORDERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_PARAMS_GET_SUPPOSE_FAIL,
	ORDERS_FILTER_ATTRS_CLEAR_RESULT,
	ORDERS_CLEAR_FILTER_ATTRS,
	ORDERS_CLEAR_FILTER_INFO,
	ORDERS_FILTER_DATA_GET_SUPPOSE,
	ORDERS_FILTER_DATA_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_DATA_GET_SUPPOSE_FAIL,
	ORDERS_OPEN_FILTER_PARAMS,
	ORDERS_CLOSE_FILTER_PARAMS,
	ORDERS_OPEN_NEW_FILTER_PARAMS,
	ORDERS_CLOSE_NEW_FILTER_PARAMS,
	ORDERS_CREATE_NEW_FILTER,
	ORDERS_FILTER_LIST_GET_SUPPOSE,
	ORDERS_FILTER_LIST_GET_SUPPOSE_SUCCESS,
	ORDERS_FILTER_LIST_GET_SUPPOSE_FAIL,
	ORDERS_FILTER_LIST_CLEAR_RESULT,
	ORDERS_ADD_FILTER_OPTION,
	ORDERS_SET_OLD_FILTER_DATA,
	ORDERS_CLEAR_NEW_FILTER_OPTION,
	ORDERS_CLEAR_FILTER_DATA,
	ORDERS_APPLY_EXISTING_FILTER,
	ORDERS_SET_SELECT_OPTIONS_FOR_FILTER,
} from 'constants/actions';

/* initial state */
const initialState = {
	filters: null,
	
	loading: false,
	loaded: false,
	filterAttrsResults: [],
	checkedFilterAttrs: {},
	saving: false,
	saved: false,
	deleting: false,
	infoLoading: false,
	infoLoaded: false,
	infoFilterParams: [],
	filterList: [],
	loadingFilterList: false,
	loadedFilterList: false,
	lastSaved: '',
	dataLoading: false,
	dataLoaded: false,
	dataFilterResult: null,
	openedFilter: null,
	showFilterParams: false,
	newFilter: false,
	showDeleteFilterModal: false,
	newFilterOption: null,
	oldFilterData: null,
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case ORDERS_FILTERS_REQUEST_SUCCESS:
			return { ...state, filters: action.filters };
			
		case ORDERS_SET_ACTIVE_FILTER:
			return { ...state, openedFilter: action.openedFilter };
			
		case ORDERS_SET_ACTIVE_FILTER_PAGE:
			const filters = state.filters;
			const currentIndex = filters.findIndex(filter => filter.filterId === action.filterId);
			const currentFilter = {...filters.find(filter => filter.filterId === action.filterId), page: action.page};
			filters[currentIndex] = currentFilter;

			return { ...state, filters };
			
		case ORDERS_FILTER_ATTRS_GET_SUPPOSE:
			return {
				...state,
				loading: true,
				loaded: false
			};
		
		case ORDERS_FILTER_ATTRS_GET_SUPPOSE_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				filterAttrsResults: action.result.result
			};
		
		case ORDERS_FILTER_ATTRS_GET_SUPPOSE_FAIL:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.err
			};

		case ORDERS_FILTER_ATTRS_CLEAR_RESULT:
			return {
				...state,
				filterAttrsResults: []
			};
		
		case ORDERS_ADD_FILTER_ATTRS:
			
			return {
				...state,
				checkedFilterAttrs: action.result.result
			};
		
		case ORDERS_CLEAR_FILTER_ATTRS:
			return {
				...state,
				checkedFilterAttrs: {}
			};
		
		case ORDERS_SAVE_FILTER:
			return {
				...state,
				saving: true,
				saved: false
			};
		
		case ORDERS_SAVE_FILTER_SUCCESS:
			const { lastSaved } = action;
			return {
				...state,
				saving: false,
				saved: true,
				newFilter: false,
				lastSaved
			};
		case ORDERS_SAVE_FILTER_FAIL:
			return {
				...state,
				saving: false,
				saved: false,
				error: action.err
			};
		
		case ORDERS_DELETE_FILTER:
			return {
				...state,
				deleting: true,
				deleted: false
			};
		
		case ORDERS_DELETE_FILTER_SUCCESS:
			let stateFilters = state.filters;
			const currentDeletedIndex = stateFilters.findIndex(filter => filter.filterId === +action.id);
			stateFilters.splice(currentDeletedIndex, 1);
			return {
				...state,
				deleting: false,
				deleted: true,
				id: action.id,
				stateFilters
			};
		
		case ORDERS_DELETE_FILTER_FAIL:
			return {
				...state,
				deleting: false,
				deleted: false,
				error: action.err
			};
		
		case ORDERS_FILTER_PARAMS_GET_SUPPOSE:
			return {
				...state,
				infoLoading: true,
				infoLoaded: false
			};
		
		case ORDERS_FILTER_PARAMS_GET_SUPPOSE_SUCCESS:
			return {
				...state,
				infoLoading: false,
				infoLoaded: true,
				infoFilterParams: action.result.result,
				saved: false
			};
		
		case ORDERS_FILTER_PARAMS_GET_SUPPOSE_FAIL:
			return {
				...state,
				infoLoading: false,
				infoLoaded: false,
				error: action.err
			};
		
		case ORDERS_CLEAR_FILTER_INFO:
			return {
				...state,
				infoFilterParams: []
			};
			
		case ORDERS_FILTER_LIST_GET_SUPPOSE:
			return {
				...state,
				loadingFilterList: true,
				loadedFilterList: false
			};
		
		case ORDERS_FILTER_LIST_GET_SUPPOSE_SUCCESS:
			return {
				...state,
				loadingFilterList: false,
				loadedFilterList: true,
				filterList: action.result.result
			};
		
		case ORDERS_FILTER_LIST_GET_SUPPOSE_FAIL:
			return {
				...state,
				loadingFilterList: false,
				loadedFilterList: false,
				error: action.err
			};
		case ORDERS_FILTER_LIST_CLEAR_RESULT:
			return {
				...state,
				filterList: []
			};
		
		case ORDERS_ADD_FILTER_OPTION:
			return {
				...state,
				filterList: [
					...state.filterList,
					action.payload
				],
				newFilterOption: action.payload
			};
		
		case ORDERS_SET_OLD_FILTER_DATA:
			return {
				...state,
				oldFilterData: action.payload
			};
		
		case ORDERS_CLEAR_NEW_FILTER_OPTION:
			return {
				...state,
				newFilterOption: null
			};
		
		case ORDERS_FILTER_DATA_GET_SUPPOSE:
			return {
				...state,
				dataLoading: true,
				dataLoaded: false
			};
		
		case ORDERS_FILTER_DATA_GET_SUPPOSE_SUCCESS:
			
			return {
				...state,
				dataLoading: false,
				dataLoaded: true,
				dataFilterResult: action.result.result
			};
		
		case ORDERS_FILTER_DATA_GET_SUPPOSE_FAIL:
			return {
				...state,
				dataLoading: false,
				dataLoaded: false,
				error: action.err
			};
		
		case ORDERS_CLEAR_FILTER_DATA:
			return {
				...state,
				dataFilterResult: null
			};
		
		case ORDERS_OPEN_FILTER_PARAMS:
			return {
				...state,
				showFilterParams: true,
				newFilter: false
			};
		
		case ORDERS_CLOSE_FILTER_PARAMS:
			return {
				...state,
				showFilterParams: false
			};
		
		case ORDERS_OPEN_NEW_FILTER_PARAMS:
			return {
				...state,
				showFilterParams: true,
				newFilter: true
			};
		
		case ORDERS_CLOSE_NEW_FILTER_PARAMS:
			return {
				...state,
				showFilterParams: false,
				newFilter: false
			};
		
		case ORDERS_CREATE_NEW_FILTER:
			return {
				...state,
				newFilter: true
			};
		
		case ORDERS_APPLY_EXISTING_FILTER:
			return {
				...state,
				newFilter: false
			};

		case ORDERS_SET_SELECT_OPTIONS_FOR_FILTER:
			const { options, optionsHistory } = action;
			return {
				...state,
				selectOptions: options,
				optionsHistory
			};
		
		default:
			return state;
	}
};
