export const findType = (typeId, parentArr, siblings, treeParam = 'children', valueField = (v) => v.id) => {
	let type;
	let siblingsArr;
	const findTypeById = (id, arr) => (
		arr.forEach(el => {
			if (valueField(el) === id) {
				type = el;
				siblingsArr = arr;
			} else {
				(el[treeParam]) && findTypeById(id, el[treeParam]);
			}
		}));
	findTypeById(typeId, parentArr);
	return (siblings ? siblingsArr : type);
};

export const findParent = (el, arr, treeParam = 'children', valueField = (v) => v.id) => {
	let parent;
	const findParentForElem = (elem, parentArr) => (
		parentArr.forEach(item => {
			if (item[treeParam]) {
				if (item[treeParam].find(child => valueField(child) === valueField(elem))) {
					parent = item;
				} else {
					findParentForElem(elem, item[treeParam]);
				}
			}
		}));
	findParentForElem(el, arr);
	return parent;
};