import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserInfo } from 'actions/index';
import './styles.scss';
import UserMenu from './UserMenu';

const mapDispatchToProps = { getUserInfo };

@connect(null, mapDispatchToProps)
class UserInfo extends PureComponent {
	componentDidMount () {
		this.props.getUserInfo();
	}
	
	render () {
		return (
			<div className='userBoxContainer'>
				<UserMenu />
			</div>
		);
	}
}

UserInfo.propTypes = {
	showLangMenu: PropTypes.bool,
	getUserInfo: PropTypes.func,
};

export default UserInfo;
