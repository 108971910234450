import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useParams, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import baseService from "../../services/BaseService";
import ReactSVG from "react-svg";
import Popup from "../../components/Popup/index";
import { getFormValues, reduxForm, Field, change, formValueSelector, getFormSyncErrors, SubmissionError, updateSyncErrors } from "redux-form";
import { getUserCalendars, deleteCalendarEvent } from "../../modules/calendar/actions";
import { connect } from "react-redux";
import ComboBoxUniversal from "../../components/Common/ComboBoxUniversal";
import ModalPortal from "../../components/ModalPortal";
import MessageModal from "../../components/MessageModal";
import { CSSTransition } from "react-transition-group";
import get from "lodash/get";
import moment from "moment";
import cx from "classnames";
import { convertDynamicFields } from "../../helpers";
import HistoryTab from "../../components/HistoryTab";

import validateEventForm from './CalendarDetail/validate';


import styles from "styles/modules/modal.module.scss";
import _ from "lodash";
import UniversalPrintPopup from "../../components/Common/UniversalPrintPopup";
import injectedStyles from "./PrintPopup/injectedStyles";
import PrintTemplate from "./PrintPopup/PrintTemplate";
import { requiredFieldsValidation } from "../../util/form";
import i18next from "util/i18n";
import permissions, { checkPermissions } from "config/permissions";
import { exportUniversalCsv } from "../../actions/filter";
import { openExportWizardModal } from "../../reducers/exportWizard";
import { setHistoryPushRequireSaveWithoutChangeConfirm } from "actions/ui";


const validate = values => {
    let errors = {};
    const config = [{ name: "calendar", required: true }];
    requiredFieldsValidation(values, config, errors);

    if (values["calendar"] && !values["calendar"].value) {
        errors["calendar"] = i18next.t("validation.required");
    }

    return errors;
};

function CalendarHeader(props) {
    const {
        t,
        disabled,
        eventMode,
        eventData,
        userCalendars,
        calendarsLoading,
        getUserCalendars,
        exportUniversalCsv,
        deleteCalendarEvent,
        history,
        calendar,
        block,
        formErrors,
        formHeaderErrors,
        formValues,
        rrule,
        status,
        start,
        end,
        description,
        title,
        allDay,
        locationForm,
        url,
        isGridView,
        attendees,
        valarm,
        eventType,
        display,
        eid: eidAttr,
        dynParams,
        allowModes = [],
        setIsDeleting,
        openExportWizardModal,
        isExportAllowed,
        eventFormConfig,
        eventFormDynParams,
        setIsCreating,
        setHistoryPushRequireSaveWithoutChangeConfirm
    } = props;

    const { eid: eidParam, mode } = useParams();
    const eid = eidParam || eidAttr;
    const cid = get(calendar, "value", "");
    const eventId = get(eventData, "eventId", null);
    const [loading, setLoading] = useState(false);
    const [isActivePopup, setIsActivePopup] = useState(false);
    const [acceptOpenedModal, setAcceptOpenedModal] = useState(false);
    const [isHistoryModal, setIsHistoryModal] = useState(false);
    const [isPrintOpen, setIsPrintOpen] = useState(false);
    // const [popupOptions, setPopupOptions] = useState([{ title: "exportToFile", value: "exportToFile" }]);
    const [popupOptions, setPopupOptions] = useState(
        isExportAllowed ? [{ title: "exportToFile", value: "exportToFile" }] : []
    );
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState("");
    const isCanEventsEdit = checkPermissions(permissions.CalendarOperations.edit_calendar_event);
    const eventFormErrors = formValues && eventFormConfig && eventFormDynParams && validateEventForm(formValues, {
        ...props,
        config: eventFormConfig,
        dynParams: eventFormDynParams,
    });

    const btnSaveClass = cx("btn btn-primary right save-btn", {
        disabled: !isCanEventsEdit || loading || !_.isEmpty(formErrors) || !_.isEmpty(formHeaderErrors) || !_.isEmpty(eventFormErrors),
    });

    const btnCreateClass = cx("btn btn-primary right", { disabled: !isCanEventsEdit });

    useEffect(() => {
        eventData && props.change("calendar", eventData.calendar);
    }, [eventData]);

    useEffect(() => {
        if (eventMode) {
            setPopupOptions([
                // {title: 'delete', value: 'delete', action: onRemoveEvent},
                { title: "delete", value: "delete", action: () => openDeleteConfirm("delete") },
                { title: "print", value: "print", action: () => setIsPrintOpen(true) },
                { title: "history", value: "history", action: () => setIsHistoryModal(true) },
            ]);
        }
        if (!eventMode && isExportAllowed) {
            setPopupOptions([{ title: "exportToFile", value: "exportToFile", action: openExportWizardModal }]);
        }
    }, [eventMode]);

    useEffect(() => {
        !userCalendars && getUserCalendars();
    }, []);

    const handleSubmit = async () => {
        const allowedMode = mode || allowModes[0].code;

        // const validation = validateEventForm(formValues, {
		// 	...props,
        //     config: eventFormConfig,
        //     dynParams: eventFormDynParams,
		// });

        // if (!_.isEmpty(validation)) {
        //     console.log("CalendarHeader::handleSubmit: Validation error ", validation);
        //     throw new SubmissionError(validation);
        // } 
        

        const location =
            Array.isArray(locationForm) &&
            locationForm.map(item => (item.className ? { value: null, label: item.label } : item));
        const params = {
            data: {
                cid,
                status: status.code || status.value,
                start: moment(start).isValid() && moment(start).format("x"),
                end: moment(end).isValid() && moment(end).format("x"),
                description,
                title,
                display,
                allDay,
                location,
                url,
                attendees,
                rrule: typeof rrule.value !== "undefined" ? rrule.value : rrule,
                valarm: valarm.value,
                eventType: eventType.value || eventType.id,
                mode: allowedMode,
                dynamicFields: convertDynamicFields(block),
            },
            jsonType: true,
        };

        setLoading(true);
        setIsCreating(true);
        const result = await baseService.put("calendar_event", { pathParams: { eid }, ...params });

        if (result.success && result.result) {
            setHistoryPushRequireSaveWithoutChangeConfirm(false);
            setLoading(false);
            setIsCreating(false);
            closeEventHandler();
        } else if (result.errorMessage) {
            setLoading(false);
            setIsCreating(false);
        }
        setIsCreating(false);
    };

    const openDeleteConfirm = type => setIsDeleteConfirmOpen(type);
    const closeDeleteConfirm = () => setIsDeleteConfirmOpen("");

    const closeEventHandler = () => {
        setIsDeleting(false);
        setTimeout(() => {
            history.push("/calendar");
        }, 0);
        // if (history.length < 3) return history.push("/calendar");
        // return history.goBack();
    };

    const onCloseConfirm = () => {
        const parsedMode = mode || "create";
        closeDeleteConfirm();
        setHistoryPushRequireSaveWithoutChangeConfirm(false);
        setIsDeleting(true);
        if (parsedMode === "create") {
            deleteCalendarEvent(eid, cid, parsedMode, closeEventHandler);
        } else {
            closeEventHandler();
        }
    };

    const onRemoveEvent = () => {
        const allowedMode = mode || allowModes[0].code || "create";
        if (!isCanEventsEdit) return;
        if (allowModes.length > 1) {
            return setAcceptOpenedModal(true);
        } else {
            setHistoryPushRequireSaveWithoutChangeConfirm(false);
            closeDeleteConfirm();
            setIsDeleting(true);
            deleteCalendarEvent(eid, cid, allowedMode, closeEventHandler);
        }
    };

    const onModalDeleteCalendarEvent = allowedMode => {
        setHistoryPushRequireSaveWithoutChangeConfirm(false);
        closeDeleteConfirm();
        setIsDeleting(true);
        deleteCalendarEvent(eid, cid, allowedMode, closeEventHandler);
        // deleteCalendarEvent(eid, code, cid, closeEventHandler);
    };

    const getOpenModesForModal = () => {
        switch (allowModes.length) {
            case 2:
                return {
                    primaryButton: true,
                    secondaryButton: true,
                    primaryButtonText: allowModes[0].label,
                    onClickPrimaryButton: () => onModalDeleteCalendarEvent(allowModes[0].code),
                    secondaryButtonText: allowModes[1].label,
                    onClickSecondaryButton: () => onModalDeleteCalendarEvent(allowModes[1].code),
                };
            case 3:
                return {
                    dangerButton: true,
                    primaryButton: true,
                    secondaryButton: true,
                    primaryButtonText: allowModes[0].label,
                    onClickPrimaryButton: () => onModalDeleteCalendarEvent(allowModes[0].code),
                    secondaryButtonText: allowModes[1].label,
                    onClickSecondaryButton: () => onModalDeleteCalendarEvent(allowModes[1].code),
                    dangerButtonText: allowModes[2].label,
                    onClickDangerButton: () => onModalDeleteCalendarEvent(allowModes[2].code),
                };
            default:
                return {
                    primaryButton: true,
                    primaryButtonText: t("close"),
                    onClickPrimaryButton: () => setAcceptOpenedModal(false),
                };
        }
    };

    // const exportToFile = () => {
    // 	const params = {
    // 		type: 'clevent',
    // 		query: '',
    // 	};

    // 	return exportUniversalCsv(params);
    // };

    const refreshClickHandler = () => {
        props.update();
    };

    const isGridViewClickHandler = () => {
        localStorage.setItem("isGridView", !isGridView);
        props.setIsGridView(!isGridView);
    };

    const onOptionClick = action => {
        action();
        setIsActivePopup(false);
    };

    const checkRestrictions = field => {
        if (eventData) {
            const { restriction } = eventData;
            return restriction && restriction[field] && restriction[field] === 1;
        }
    };

    return (
        <>
            <div className={cx("leftSidebarHeader", "calendar-header", { eventMode })}>
                <div className={cx("calendar-sidebar-title", "ordering-component-ui-core-wrapper")}>
                    <ReactSVG svgClassName="tab-icon" src={`/data/svg/navigation/calendar.svg`} />
                    {eventMode ? (
                        <>
                            <span className="cid-calendar-title">{t("calendar.eventInCalendar")}:</span>
                            <Field
                                component={ComboBoxUniversal}
                                name="calendar"
                                placeholder={t("filters.noSelected")}
                                noResultsText={t("noResultsFound")}
                                searchable={false}
                                clearable={false}
                                hasExValue={true}
                                className={"combo-universal header-input"}
                                isLoading={calendarsLoading}
                                options={userCalendars}
                                disabled={checkRestrictions("cname")}
                            />
                        </>
                    ) : (
                        <span>{t("calendar.calendarEvent")}</span>
                    )}
                </div>

                <div className="sidebar-wrapper actions">
                    {/*{!eventMode &&*/}
                    {/*<div className='searchInputContainer'>*/}
                    {/*<input type='text' className='searchInput' placeholder={t('calendar.searchEvent')}*/}
                    {/*onChange={(e) => console.log(e.target.value)}/>*/}
                    {/*<i className='icon-search2'/>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {!disabled && !eventMode && (
                        <Link className={btnCreateClass} to="/calendar/event">
                            {t("calendar.createEvent")}
                        </Link>
                    )}
                    {eventMode && (
                        <>
                            <span className="cancel-link" onClick={() => openDeleteConfirm("close")}>
                                {t("cancel")}
                            </span>
                            <button onClick={handleSubmit} className={btnSaveClass}>
                                {t("save")}
                            </button>
                        </>
                    )}
                    <span className="status-divider">|</span>
                    {!eventMode && (
                        <div className="btn-add wrapper-hovered" onClick={isGridViewClickHandler}>
                            {isGridView ? (
                                <ReactSVG
                                    svgClassName="tab-icon btn-cal-icon"
                                    src={`/data/svg/navigation/calendar.svg`}
                                />
                            ) : (
                                <i className="icon-list" />
                            )}
                        </div>
                    )}
                    {!eventMode && (
                        <div className="btn-add wrapper-hovered" onClick={refreshClickHandler}>
                            <i className="icon-replay" />
                        </div>
                    )}
                    {(eventMode || isGridView) && (popupOptions && popupOptions.length) > 0 && (
                        <div className="btn-add wrapper-hovered" onClick={() => setIsActivePopup(!isActivePopup)}>
                            <i className="icon-kebab-vert" />
                        </div>
                    )}
                    {eventMode && (
                        <button
                            className="btn-add wrapper-hovered close-btn"
                            onClick={() => openDeleteConfirm("close")}
                        >
                            <i class="icon-close" title={t("close")}></i>
                        </button>
                    )}
                    {isActivePopup && (eventMode || isGridView) && (
                        <Popup onClickOutside={() => setIsActivePopup(false)} place={"grid-popup calendar-popup"}>
                            {popupOptions.map(option => (
                                <div
                                    className="popup-link"
                                    key={option.value}
                                    onClick={() => onOptionClick(option.action)}
                                >
                                    {t(`calendar.${option.title}`)}
                                </div>
                            ))}
                        </Popup>
                    )}
                </div>
            </div>
            <CSSTransition
                in={acceptOpenedModal}
                classNames="fade"
                appear
                enter
                exit
                timeout={300}
                mountOnEnter
                unmountOnExit
            >
                <ModalPortal onClose={() => setAcceptOpenedModal(false)} className="modal-small modal-center">
                    <MessageModal
                        titleModal={t("calendar.askEventModalTitle")}
                        contentModalText={t("calendar.askActionEventModalDescription")}
                        {...getOpenModesForModal()}
                    />
                </ModalPortal>
            </CSSTransition>
            {eventId && (
                <CSSTransition
                    in={isHistoryModal}
                    classNames="fade"
                    appear
                    enter
                    exit
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                >
                    <ModalPortal onClose={() => setIsHistoryModal(false)} className="modal-large modal-center">
                        <div className={styles.modalWrapper}>
                            <div className={styles.modalHeader}>
                                <div className={styles.modalTitle}>
                                    {t("calendar.modalHistoryTitle")} ( {title} )
                                </div>
                            </div>
                            <div className={cx(styles.modalContent, "modal-content-clients", "modal-content-history")}>
                                <HistoryTab id={eventId} tabProps={{}} objectType={"CLEVENT"} isModal />
                            </div>
                        </div>
                    </ModalPortal>
                </CSSTransition>
            )}
            {isPrintOpen && (
                <UniversalPrintPopup
                    onClose={() => setIsPrintOpen(false)}
                    content={formValues}
                    headerTitle={t("calendar.PrintPopupTitle") + ` ( ${title} )`}
                    PrintTemplate={PrintTemplate}
                    dynParams={dynParams}
                    injectedStyles={injectedStyles}
                />
            )}
            {isDeleteConfirmOpen && (
                <ModalPortal onClose={closeDeleteConfirm} className="modal-small">
                    <MessageModal
                        titleModal={t("attention")}
                        contentModalText={
                            isDeleteConfirmOpen === "close"
                                ? t("modalWindowsLabels.notSavedWarning")
                                : t("modalWindowsLabels.deleteWarning")
                        }
                        dangerButton={true}
                        dangerButtonText={isDeleteConfirmOpen === "close" ? t("close") : t("remove")}
                        onClickDangerButton={isDeleteConfirmOpen === "close" ? onCloseConfirm : onRemoveEvent}
                        secondaryButton={true}
                        secondaryButtonText={t("cancel")}
                        onClickSecondaryButton={closeDeleteConfirm}
                    />
                </ModalPortal>
            )}
        </>
    );
}

CalendarHeader.propTypes = {
    onSearchSubmit: PropTypes.func,
    getUserCalendars: PropTypes.func,
    deleteCalendarEvent: PropTypes.func,
};

const mapStateToProps = state => ({
    calendar: formValueSelector("event-header-form")(state, "calendar"),
    formErrors: getFormSyncErrors("event-form")(state),
    formHeaderErrors: getFormSyncErrors("event-header-form")(state),
    formValues: getFormValues("event-form")(state),
    locationForm: formValueSelector("event-form")(state, "location"),
    ...formValueSelector("event-form")(
        state,
        "rrule",
        "status",
        "start",
        "end",
        "description",
        "title",
        "allDay",
        "url",
        "attendees",
        "valarm",
        "eventType",
        "display",
        "cid",
        "eid",
        "block",
        "allowModes"
    ),
    eventData: state.calendar.openedEvent,
    userCalendars: state.calendar.userCalendars,
    calendarsLoading: state.calendar.calendarsLoading,
    isExportAllowed: checkPermissions(permissions.SearchOperations.search_export),
	eventFormDynParams: state.calendar.dynParams,
});

const mapDispatchToProps = dispatch => ({
    openExportWizardModal: () => dispatch(openExportWizardModal()),
    getUserCalendars: () => dispatch(getUserCalendars()),
    exportUniversalCsv: params => dispatch(exportUniversalCsv(params)),
    deleteCalendarEvent: (eid, cid, mode, callback) => dispatch(deleteCalendarEvent(eid, cid, mode, callback)),
    setHistoryPushRequireSaveWithoutChangeConfirm: isRequired => dispatch(setHistoryPushRequireSaveWithoutChangeConfirm(isRequired))
    
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "event-header-form",
        validate,
        destroyOnUnmount: true,
    })(withTranslation()(withRouter(CalendarHeader)))
);
